import { Flex, MenuItem, Tag, useModal } from '@candisio/design-system';
import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { BDSPromotionModal } from 'components/ProductPromotions/Provisions/BDSPromotionModal';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { useExportPaymentConditionsGenericMutation } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useDatev } from 'orgConfig/datev';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface PaymentConditionsKebabMenuProps {
  /** Called when “import” action is selected */
  onImportAction?: () => void;
  /** Called when “import history” action is selected */
  onImportHistoryAction?: () => void;
  /** Called when “export” action is selected */
  onExportAction?: () => void;
}

export const PaymentConditionsKebabMenu = ({
  onImportAction,
  onImportHistoryAction,
  onExportAction,
}: PaymentConditionsKebabMenuProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { isActiveIntegration: shouldUseSapPaymentConditions } = useSap();
  const { open, isOpen, close } = useModal();

  const [paymentConditionsExportFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.paymentConditionsExport,
  ]);

  const [exportPaymentConditions] = useExportPaymentConditionsGenericMutation();

  const { bdsBought } = useDatev(); // BDS-checked

  const showBdsPromotionModal = !bdsBought;

  const items: MenuItem[] = [];

  if (!shouldUseSapPaymentConditions) {
    items.push({
      id: 'import',
      label: t('paymentConditions.contextMenu.import'),
      onAction: onImportAction,
    });
    items.push({
      id: 'history',
      label: t('paymentConditions.contextMenu.history'),
      showSeparator: true,
      onAction: onImportHistoryAction,
    });
    items.push({
      id: 'export-generic',
      label: t('paymentConditions.contextMenu.exportGeneric'),
      onAction: async () => {
        const response = await exportPaymentConditions();
        if (response.data?.exportPaymentConditionsGeneric.url)
          window.open(response.data.exportPaymentConditionsGeneric.url);
      },
    });
  }

  if (paymentConditionsExportFF && !shouldUseSapPaymentConditions) {
    items.push({
      id: 'export',
      label: t('paymentConditions.contextMenu.export'),
      onAction: onExportAction,
    });
  }

  if (showBdsPromotionModal && !shouldUseSapPaymentConditions) {
    items.push({
      id: 'exportRewe',
      label: t('paymentConditions.contextMenu.exportRewe'),
      renderItem: (item: MenuItem) => (
        <Flex alignItems="center" gap="space10">
          {item.label}
          <Tag color="purple" variant="secondary">
            {t('common:tag.addOn')}
          </Tag>
        </Flex>
      ),
      onAction: () => {
        open();
      },
    });
  }

  return (
    <>
      {items.length > 0 && (
        <KebabMenu
          items={items}
          label={t('paymentConditions.contextMenu.label')}
        />
      )}
      {isOpen && (
        <BDSPromotionModal
          utmSource={PROVISIONS_UTM_SOURCE.SETTINGS_DATEV_INTEGRATION_DUO}
          isOpen={isOpen}
          close={close}
        />
      )}
    </>
  );
};

import { FieldPolicy, TypePolicies } from '@apollo/client';
import {
  PayableDocumentConnection,
  PayableDocumentsEsResponse,
} from 'generated-types/graphql.types';
import {
  paginationWithRecordConnectionsMerge,
  paginationWithRecordConnectionsMergeNoNodes,
} from '../pagination-results-merge';

/**
 * @deprecated
 */
const payablePaginationDeprecated: FieldPolicy<PayableDocumentConnection> = {
  keyArgs: ['sort'],

  merge: paginationWithRecordConnectionsMerge('PayableDocumentConnection'),
};

const payablePagination: FieldPolicy<PayableDocumentsEsResponse> = {
  keyArgs: ['sort'],

  merge: paginationWithRecordConnectionsMergeNoNodes(
    'PayableDocumentsEsResponse'
  ),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      payableDocumentsPagination: payablePaginationDeprecated,
      payableDocumentsEs: payablePagination,
    },
  },
};

import { PerDiemDaysInput } from 'generated-types/graphql.types';
import { ReimbursementItemSchemaOutput } from '../toolkit/reimbursementItemsFormSchema';

export const sanitisePerDiemDays = (values: ReimbursementItemSchemaOutput) => {
  if (values.reimbursementItemType === 'perDiem') {
    const days = values.days;

    const sanitizedDays = (days ?? []).reduce<PerDiemDaysInput[]>(
      (acc, value) => {
        let initialDay: PerDiemDaysInput = {
          id: value.dayId,
          providedBreakfast: false,
          providedDinner: false,
          providedLunch: false,
        };

        if (value.meals.includes('breakfast')) {
          initialDay = { ...initialDay, providedBreakfast: true };
        }
        if (value.meals.includes('lunch')) {
          initialDay = { ...initialDay, providedLunch: true };
        }
        if (value.meals.includes('dinner')) {
          initialDay = { ...initialDay, providedDinner: true };
        }

        return acc.concat(initialDay);
      },
      []
    );

    return sanitizedDays;
  }
};

import { FileField } from '@candisio/design-system';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

export interface FormFileFieldProps<TFormValues extends FieldValues> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** File type(s) to accept */
  accept?: string;
  /** Select file button text */
  buttonLabel: string;
  /** Is field disabled? */
  disabled?: boolean;
  /** Field label */
  label?: string;
  /** Placeholder text shown when no file is selected */
  placeholder: string;
  /** Is field read only? */
  readOnly?: boolean;
}

/**
 * File field for React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 */
export const FormFileField = <TFormValues extends FieldValues>({
  accept,
  buttonLabel,
  control,
  disabled,
  label,
  name,
  placeholder,
  readOnly: readOnlyProp,
}: FormFileFieldProps<TFormValues>) => {
  const { field, fieldState, formState } = useController({ name, control });
  const { ref, onChange, value, ...inputProps } = field;

  const errorMessage: string | undefined =
    fieldState.error?.message ??
    // @ts-ignore
    fieldState.error?.type?.message ??
    // @ts-ignore
    fieldState.error?.size?.message;

  const hasError = errorMessage !== undefined;

  const readOnly = readOnlyProp || formState.isSubmitting;

  return (
    <FileField
      buttonLabel={buttonLabel}
      disabled={disabled}
      // @TODO TGB-310 FileInput component should be separately exported
      input={{
        accept,
        onChange: e => onChange(e.target.files?.[0]),
        ...inputProps,
      }}
      inputRef={ref}
      label={label}
      message={errorMessage}
      placeholder={placeholder}
      readOnly={readOnly}
      variant={hasError ? 'error' : 'default'}
    />
  );
};

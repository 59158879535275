import {
  Button,
  Flex,
  Switch,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import {
  DatevConnectionType,
  useDisconnectDatevClientMutation,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { DcsContainer } from '../../DcsContainers/DcsContainer/DcsContainer';
import { DETAILS_MAX_WIDTH } from '../../consts';
import { useDatevAuthorizeForClients } from '../../datevAuthHooks';
import { refetchDatevDataWithNoToken } from '../../gql';
import { EXPORT_TYPE_IMAGES } from '../../images';
import { DatevClient } from '../../types';
import { getChecks } from '../states';
import { CheckProps } from '../types';
import { InfoList } from './InfoList';

type DataBookingServiceProps = {
  client: DatevClient;
  language: string;
  isProvisionsLedgerConfigured?: boolean;
  setIsDatevReconnecting: (flag: boolean) => void;
};

// Data Booking Service aka BDS
export const DataBookingService = ({
  language,
  isProvisionsLedgerConfigured,
  setIsDatevReconnecting,
}: DataBookingServiceProps) => {
  const { exportProvisionsFF, bdsSetupCompleted } = useDatev(); // BDS-checked

  const [t] = useTranslation();
  const history = useHistory();
  const organizationSlug = useOrganizationId();

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  const [reconnectDatev, { loading: loadingDatevReconnection }] =
    useDatevAuthorizeForClients();

  const [disconnectDatevClient, { loading: disconnectLoading }] =
    useDisconnectDatevClientMutation({
      refetchQueries: [
        ...refetchDatevDataWithNoToken,
        { query: getFullOrganizationQuery },
      ],
    });

  const onDisconnectDatevClient = useCallback(async () => {
    await disconnectDatevClient();
  }, [disconnectDatevClient]);

  const onDisconnect = async () => {
    setIsDatevReconnecting(true);
    await onDisconnectDatevClient();
    await reconnectDatev(DatevConnectionType.DatevRewe);
  };

  const [checks, setChecks] = useState<CheckProps>(
    exportProvisionsFF ? [getChecks(t).provisionsAccountAdded.loading] : []
  );

  useEffect(() => {
    if (bdsSetupCompleted) {
      setChecks(
        exportProvisionsFF
          ? [
              getChecks(t).isBDSConnected['success'],
              getChecks(t).provisionsAccountAdded[
                isProvisionsLedgerConfigured ? 'success' : 'error'
              ],
            ]
          : [getChecks(t).isBDSConnected['success']]
      );
    } else {
      setChecks([getChecks(t).isBDSConnected.error]);
    }
  }, [t, exportProvisionsFF, isProvisionsLedgerConfigured, bdsSetupCompleted]);

  return (
    <DcsContainer
      icon={EXPORT_TYPE_IMAGES.bds[language]}
      iconHeight="128px"
      iconWidth="100.8px"
      iconAlt={t('settings.datev.imageAltTexts.bds')}
      title={t('settings.datev.connect.exportTypes.bds.title')}
      details={
        <>
          <Text maxWidth={DETAILS_MAX_WIDTH}>
            {t('settings.datev.connect.exportTypes.bds.info')}
          </Text>
          <InfoList checks={checks} />
          {!bdsSetupCompleted && (
            <Flex alignItems="start">
              <Button
                variant="secondary"
                disabled={loadingDatevReconnection || disconnectLoading}
                onClick={() => onDisconnect()}
                loading={loadingDatevReconnection || disconnectLoading}
              >
                {t('settings.datev.connect.exportTypes.bds.reconnect')}
              </Button>
            </Flex>
          )}
          {bdsSetupCompleted &&
            !isProvisionsLedgerConfigured &&
            exportProvisionsFF && (
              <Flex alignItems="start">
                <Button
                  variant="secondary"
                  disabled={loadingDatevReconnection || disconnectLoading}
                  onClick={() =>
                    history.push(
                      `/${organizationSlug}${Routes.SETTINGS}${Routes.PROVISIONS}`
                    )
                  }
                  loading={loadingDatevReconnection || disconnectLoading}
                >
                  {t(
                    'settings.datev.connect.exportTypes.bds.addProvisionsAccount'
                  )}
                </Button>
              </Flex>
            )}
        </>
      }
      actions={
        <>
          {/* @ts-expect-error  https://candis.atlassian.net/browse/DS-735 */}
          <Switch
            {...triggerProps}
            ref={triggerRef}
            name="data booking service"
            label="switch data booking service on and off"
            checked={bdsSetupCompleted}
            disabled
          />

          {isOpen && (
            <Tooltip {...tooltipProps} ref={tooltipRef}>
              {bdsSetupCompleted ? (
                <Text>
                  {t(
                    'settings.datev.connect.exportTypes.bds.disabledExplanation'
                  )}
                </Text>
              ) : (
                <Text>
                  {t(
                    'settings.datev.connect.exportTypes.bds.disabledExplanationErroredState'
                  )}
                </Text>
              )}
            </Tooltip>
          )}
        </>
      }
    />
  );
};

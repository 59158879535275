import { ComponentProps, CSSProperties, forwardRef } from 'react';
import { useFocusVisible } from 'react-aria';
import styles from './AvatarCircle.module.css';
import clsx from 'clsx';

export interface AvatarCircleProps extends ComponentProps<'div'> {
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  img?: string;
  children?: React.ReactNode;
}

/**
 * The AvatarCircle component is used to create different Avatar variations.
 */
export const AvatarCircle = forwardRef<HTMLDivElement, AvatarCircleProps>(
  (
    { size = 'medium', img, children, className, style, ...restProps },
    forwardedRef
  ) => {
    const { isFocusVisible } = useFocusVisible();

    return (
      <div
        className={clsx(styles['avatar-circle'], className)}
        data-size={size}
        data-focus-visible={isFocusVisible}
        style={
          {
            '--avatar-background-image': img ? `url(${img})` : undefined,
            ...style,
          } as CSSProperties
        }
        ref={forwardedRef}
        {...restProps}
      >
        {children}
      </div>
    );
  }
);

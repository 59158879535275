import { Button, Flex, Grid } from '@candisio/design-system';
import { useIsElementVisible } from 'hooks/useIsElementVisible';
import { AnimatePresence, motion } from 'motion/react';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useLocation } from 'react-router-dom-v5-compat';
import { useScrollToTarget } from '../hooks/useScrollToTarget';
import { scrollToTarget } from '../utils/utils';
import { CardsAndRequestsOverviewWidgetContainer } from './CardsAndRequestsOverview/CardsAndRequestOverviewWidgetsContainer';
import { CreditCardTipsWidget } from './CreditCardTipsWidget';
import { FinancialOverviewContainer } from './FinancialOverview/FinancialOverviewContainer';
import { MissingInvoicesWidgetContainer } from './MissingInvoicesWidget/MissingInvoicesWidgetContainer';
import {
  RecurringPaymentsWidget,
  recurringPaymentsId,
} from './RecurringPayments/RecurringPaymentsWidget';
import { SpentOverviewWidget } from './SpentOverview/SpentOverviewWidget';
import { TeaserWidget } from './TeaserWidget';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useTranslation } from 'react-i18next';

const recurringPaymentsScrollKey = 'recurring_payments_scroll';

const MotionFlex = motion.create(Flex);

/**@deprecated TSY-19 */
export const CreditCardsInsights = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { showAllExtraFeatures } = useCreditCardsSetup();
  const { hash } = useLocation();

  const { isIntersecting, ref } = useIsElementVisible();
  const [isScrollClicked, setIsScrollClicked] = useLocalStorage(
    recurringPaymentsScrollKey,
    false
  );

  useScrollToTarget(hash);

  const scrollToRecurringpayments = () => {
    setIsScrollClicked(true);
    scrollToTarget(recurringPaymentsId);
  };

  const isScrollButtonVisible =
    !isIntersecting && !isScrollClicked && showAllExtraFeatures;

  return (
    <>
      <Grid gap="space16">
        <Grid templateColumns="auto minmax(395px, 630px)" gap="space16">
          <CardsAndRequestsOverviewWidgetContainer />
          <FinancialOverviewContainer />
        </Grid>
        <Grid templateColumns="3fr 1fr" gap="space16" templateRows="500px">
          <SpentOverviewWidget />
          <MissingInvoicesWidgetContainer />
        </Grid>
        {showAllExtraFeatures ? (
          <Grid templateColumns="3fr 1fr" gap="space16" alignItems="start">
            <RecurringPaymentsWidget ref={ref} />
            <CreditCardTipsWidget />
          </Grid>
        ) : (
          <Grid justifyItems="center">
            <TeaserWidget />
          </Grid>
        )}
        <AnimatePresence>
          {isScrollButtonVisible ? (
            <MotionFlex
              position="sticky"
              bottom={0}
              justifyContent="center"
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              paddingBottom="space8"
            >
              <Button
                color="blue"
                onClick={scrollToRecurringpayments}
                icon="arrowDown"
              >
                {t('insights.recurringPaymentsWidget.scrollButton')}
              </Button>
            </MotionFlex>
          ) : null}
        </AnimatePresence>
      </Grid>
    </>
  );
};

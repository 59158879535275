import { useToastMessage } from 'components/Toast/useToastMessage';
import { useDeleteReimbursementItemMutation } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsErrorsQuery,
  reimbursementItemsListQuery,
  reimbursementItemsSplitBookingsQuery,
} from '../toolkit/queries';

export const useDeleteExpense = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { error, success } = useToastMessage();

  const [deleteExpense, { loading }] = useDeleteReimbursementItemMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: reimbursementCaseByIdQuery,
        variables: {
          id: reimbursementId,
        },
      },
      {
        query: reimbursementItemsListQuery,
        variables: { filters: { reimbursementCaseId: reimbursementId } },
      },
      {
        query: reimbursementItemsErrorsQuery,
        variables: { filters: { reimbursementCaseId: reimbursementId } },
      },
      {
        query: reimbursementHistoryQuery,
        variables: {
          id: reimbursementId,
        },
      },
      {
        query: reimbursementItemsSplitBookingsQuery,
        variables: {
          filters: { reimbursementCaseId: reimbursementId },
        },
      },
    ],
  });

  const handleDeleteExpense = async (id: string) => {
    const { data, errors } = await deleteExpense({
      variables: { id },
    });

    const { deleted } = data?.deleteReimbursementItem ?? {};

    if (errors?.length || !deleted) {
      error(t('reimbursementView.createErr'));

      return;
    }

    success(t('reimbursementView.middleSection.form.deleteSuccess'));

    return 'success';
  };

  return {
    deletePending: loading,
    deleteExpense: handleDeleteExpense,
  };
};

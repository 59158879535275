import { Icon, Text } from '@candisio/design-system';
import { ExpenseItemFileAddedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { NavigateToItemWrapper } from '../NavigateToItemWrapper';

export const ExpenseItemFileAdded = ({
  resourceId,
  reimbursementItemMetadata,
  clientInfo,
}: ExpenseItemFileAddedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const headline = expenseTitle
    ? t('timeline.expenseChanged', { title: expenseTitle })
    : t('timeline.expenseChangedNoTitle');

  const isMobileClient = clientInfo?.name
    ? clientInfo?.name.toLowerCase().includes('mobile')
    : false;

  const translation = isMobileClient
    ? t('reimbursementView.middleSection.form.hospitality.file.addedOnMobile')
    : t('reimbursementView.middleSection.form.hospitality.file.addedEvent');

  return (
    <NavigateToItemWrapper id={resourceId}>
      <Text>{headline}</Text>
      <div className="pt-2">
        <Text fontWeight="regular" color="gray500">
          {t('reimbursementView.middleSection.form.hospitality.file.label')}
        </Text>
        <div className="flex gap-1 flex-row">
          {isMobileClient && <Icon icon="mobileUpload" size="space14" />}
          <Text>{translation}</Text>
        </div>
      </div>
    </NavigateToItemWrapper>
  );
};

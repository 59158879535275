import { Modal, Tag } from '@candisio/design-system';
import type { PromotionDetailsModalContentProps } from 'components/NewPromotions/PromotionCard/PromotionDetailsModalContent/PromotionDetailsModalContent';
import { PromotionDetailsModalContent } from 'components/NewPromotions/PromotionCard/PromotionDetailsModalContent/PromotionDetailsModalContent';
import { usePromotionDetailsModalContent } from 'components/NewPromotions/hooks/usePromotionDetailsModalContent';
import { EventLocationsBase } from 'providers/AnalyticsProvider/events';
import { useTranslation } from 'react-i18next';

interface SearchFilterUpsellModalProps {
  isOpen: boolean;
  onClose: () => void;
  isBaseEntitlement: boolean;
}

export function SearchFilterUpsellModal({
  isOpen,
  onClose,
  isBaseEntitlement,
}: SearchFilterUpsellModalProps) {
  const [t] = useTranslation();
  const promotionId = isBaseEntitlement
    ? 'powerSearchBase'
    : 'powerSearchLegacy';

  const { promotionDetails } = usePromotionDetailsModalContent(promotionId);

  const trackOption: PromotionDetailsModalContentProps['trackOption'] = {
    eventLocation: EventLocationsBase.GLOBAL_SEARCH,
    feature: promotionId,
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        width="1000px"
        background="gray100"
        padding="0"
        titleInfo={
          <Tag color="purple" variant="secondary">
            {t('tag.addOn')}
          </Tag>
        }
        closeLabel={t('common.close')}
        style={{
          background: 'linear-gradient(90deg, #ffffff 60%, #f8f6f5 40%)',
        }}
      >
        <PromotionDetailsModalContent
          promotionDetails={promotionDetails}
          trackOption={trackOption}
        />
      </Modal>
    </>
  );
}

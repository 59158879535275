import { BlobReader, BlobWriter, ZipWriter } from '@zip.js/zip.js';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  DocumentDownloadedProperties,
  TrackingEvents,
} from 'providers/AnalyticsProvider/events';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils/downloadFile';
import { PDFDetails } from '../utils';

export type DownloadItem = Pick<PDFDetails, 'name' | 'url'> & {
  isAttachment?: boolean;
};

const download = async (url: string, name: string) => {
  const file = await fetch(url);
  if (!file.ok) {
    throw new Error('Failed to download file');
  }

  const blob = await file.blob();

  const dlUrl = URL.createObjectURL(blob);
  downloadFile(dlUrl, name);
  URL.revokeObjectURL(dlUrl);
};

/**
 * Adds suffixes to duplicate file names. eg. (1)
 * Puts attachments in a subfolder
 */
const fileNameCreator = (attachmentFolderName: string) => {
  const taken: Map<string, number[]> = new Map();
  return (name: string, isAttachment: boolean) => {
    const lastDot = name.lastIndexOf('.');

    const ext = lastDot > 0 ? name.substring(lastDot + 1) : '';
    let fileName = name;
    if (ext) {
      fileName = name.replace(new RegExp(`(\.${ext})$`), '');
    }

    if (taken.has(name)) {
      const max = Math.max(...(taken.get(name) ?? [0]));
      const next = max + 1;
      fileName = `${fileName} (${next})`;
      taken.set(name, [...(taken.get(name) ?? []), next]);
    } else {
      taken.set(name, [0]);
    }

    if (isAttachment) {
      fileName = `${attachmentFolderName}/${fileName}`;
    }

    if (ext) {
      fileName = `${fileName}.${ext}`;
    }

    return fileName;
  };
};

const downloadAll = async (
  attachmentFolderName: string,
  files: DownloadItem[],
  signal: AbortSignal
) => {
  const zipFileWriter = new BlobWriter('application/zip');
  const zipWriter = new ZipWriter(zipFileWriter, {
    bufferedWrite: true,
    signal,
  });

  const createFileName = fileNameCreator(attachmentFolderName);
  for (const file of files) {
    const response = await fetch(file.url, { signal });
    if (!response.ok) {
      throw new Error('Failed to download file');
    }
    const fileBlob = await response.blob();

    const fileName = createFileName(file.name, file.isAttachment ?? false);
    await zipWriter.add(fileName, new BlobReader(fileBlob), { signal });
  }

  const zipBlob = await zipWriter.close();

  // First file is always the main PDF file.
  const zipFileName = `${files[0].name.replace(/(\.pdf)$/, '')}.zip`;
  const dlUrl = URL.createObjectURL(zipBlob);
  downloadFile(dlUrl, zipFileName);
  URL.revokeObjectURL(dlUrl);
};

export const useDownload = () => {
  const [t] = useTranslation();
  const { error } = useToastMessage();
  const [loading, setLoading] = useState(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const { track } = useAnalytics();

  /**
   * No loading state here, the file is already in the browser so it's instant
   * The loading state just causes a flicker
   */
  const downloadSingle = useCallback(
    async (
      url: string,
      name: string,
      documentId: string,
      trackingEventLocation: DocumentDownloadedProperties['event_location']
    ) => {
      try {
        await download(url, name);
        track(TrackingEvents.DOCUMENT_DOWNLOADED, {
          event_location: trackingEventLocation,
          document_id: documentId,
        });
      } catch (_err) {
        error(t('document.downloadFailed'));
      }
    },
    [error, t, track]
  );

  const downloadZip = useCallback(
    async (
      files: DownloadItem[],
      documentId: string,
      trackingEventLocation: DocumentDownloadedProperties['event_location']
    ) => {
      const attachmentFolderName = t(
        'document.tabs.oldAttachments.attachments'
      );

      setLoading(true);
      const controller = new AbortController();
      setAbortController(controller);
      try {
        await downloadAll(attachmentFolderName, files, controller.signal);
        track(TrackingEvents.DOCUMENT_DOWNLOADED, {
          event_location: trackingEventLocation,
          document_id: documentId,
        });
      } catch (_err) {
        if (controller?.signal.aborted) {
          // Abort causes a promise rejection, but we don't need to show an error
          return;
        }

        error(t('document.downloadFailed'));
      } finally {
        setLoading(false);
        setAbortController(null);
      }
    },
    [error, t, track]
  );

  useEffect(() => {
    return () => {
      if (abortController && !abortController.signal.aborted) {
        abortController.abort();
      }
    };
  }, [abortController]);

  return { downloadSingle, downloadZip, loading };
};

import {
  PerDiemStep,
  ReimbursementItemStatus,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import moment from 'moment';
import { DeepPartial } from 'react-hook-form';
import { RefinementCtx, ZodIssueCode, z } from 'zod';
import { isEndingDateSmaller } from '../utils/isEndingDateSmaller';
import { isSameDate } from '../utils/isSameDate';
import { isStartingDateGreater } from '../utils/isStartingDateGreater';
import { isEarlierTimeSameDay } from '../utils/isEarlierTimeSameDay';
import { isInTheFuture } from '../utils/isInTheFuture';

const MIN_DATE = '2025-01-01';

const AMOUNTS = {
  MIN: 0.01,
  MIN_TIP: 0,
  MAX: 999999,
  MAX_TIP: 999,
} as const;

const fileSchema = z.object({
  name: z.string(),
  url: z.string(),
  id: z.string(),
});

const guestFieldsSchema = z.object({
  internalGuests: z.string().nullish(),
  externalGuests: z.string().nullish(),
});

const amountsSchema = z.object({
  totalAmount: z.number().min(AMOUNTS.MIN).max(AMOUNTS.MAX).nullish(),
  receiptAmount: z.number().min(AMOUNTS.MIN).max(AMOUNTS.MAX).nullish(),
  tipAmount: z.number().min(AMOUNTS.MIN_TIP).max(AMOUNTS.MAX_TIP).nullish(),
});

// Common fields that both expense types share
const commonExpenseFields = {
  reimbursementItemId: z.string().uuid(),
  reason: z.string().trim().nullish(),
  files: z.array(fileSchema).min(1),
  invoiceNumber: z.string().trim().nullish(),
  isExpenseExcluded: z.boolean(),
  itemStatus: z.nativeEnum(ReimbursementItemStatus),
  isExtractedDataAccepted: z.boolean(),
  expenseDate: z.string().trim().nullish(),
};

// Form schemas
const baseGeneralExpenseFormSchema = z.object({
  ...commonExpenseFields,
  reimbursementItemType: z.literal('general'),
  totalAmount: amountsSchema.shape.totalAmount,
  extractedData: z
    .object({
      invoiceNumber: z.string().optional(),
      expenseDate: z.string().optional(),
      totalAmount: z.number().nullish(),
    })
    .optional(),
});

const baseHospitalityFormSchema = z.object({
  ...commonExpenseFields,
  reimbursementItemType: z.literal('hospitality'),
  location: z.string().trim().nullish(),
  ...guestFieldsSchema.shape,
  ...amountsSchema.shape,
  extractedData: z
    .object({
      invoiceNumber: z.string().optional(),
      location: z.string().optional(),
      expenseDate: z.string().optional(),
      receiptAmount: z.number().nullish(),
      tipAmount: z.number().nullish(),
    })
    .optional(),
});

const segmentFormSchema = z.object({
  segmentId: z.string().uuid(),
  startDate: z.string().nullish(),
  startTime: z.string().time().nullish(),
  endDate: z.string().nullish(),
  endTime: z.string().time().nullish(),
  location: z.string().nullish(),
});

const segments = z.array(segmentFormSchema).min(1);

const validateMandatorySegmentFields = (
  segments: Segment[],
  ctx: RefinementCtx
) => {
  segments.forEach((segment, index) => {
    Object.keys(segmentFormSchema.shape).forEach(field => {
      if (!segment?.[field as keyof Segment]) {
        ctx.addIssue({
          code: 'invalid_type',
          expected: 'string',
          received: 'null',
          path: [index, field],
        });
      }
    });
  });
};

const validateStartDate = (segments: Segment[], ctx: RefinementCtx) => {
  segments.forEach((segment, index) => {
    if (isInTheFuture(segment.startDate)) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: [index, 'startDate'],
        params: {
          translationKey:
            'reimbursementView.middleSection.form.hospitality.date.errorMaxDate',
        },
      });
    }
    if (isStartingDateGreater(segment.startDate, segment.endDate)) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        params: {
          translationKey:
            'reimbursementView.middleSection.form.perDiem.customErrorMessages.startDate',
        },
        path: [index, 'startDate'],
      });
    }

    if (index === 0) {
      return;
    }

    const previousSegment = segments?.[index - 1];

    if (!previousSegment || !previousSegment.endDate) {
      return;
    }

    // Check if the starting date of the segment is equal to ending date of
    // previous segment
    if (!isSameDate(previousSegment.endDate, segment.startDate)) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        params: {
          translationKey:
            'reimbursementView.middleSection.form.perDiem.customErrorMessages.segmentsDateNotTheSame',
        },
        path: [index, 'startDate'],
      });
    }
  });
};

const validateEndDate = (segments: Segment[], ctx: RefinementCtx) => {
  segments.forEach((segment, index) => {
    if (isInTheFuture(segment.endDate)) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: [index, 'endDate'],
        params: {
          translationKey:
            'reimbursementView.middleSection.form.hospitality.date.errorMaxDate',
        },
      });
    }
    if (!segment.startDate || !segment.endDate) {
      return;
    }

    if (isEndingDateSmaller(segment.startDate, segment.endDate)) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        params: {
          translationKey:
            'reimbursementView.middleSection.form.perDiem.customErrorMessages.endDate',
        },
        path: [index, 'endDate'],
      });
    }
  });
};

const validateLocation = (segments: Segment[], ctx: RefinementCtx) => {
  segments.forEach((segment, index) => {
    if (moment(segment.endDate) < moment(MIN_DATE)) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        params: {
          translationKey:
            'reimbursementView.middleSection.form.perDiem.customErrorMessages.noLocations',
        },
        path: [index, 'location'],
      });
    }
    if (index === 0) {
      return;
    }

    const previousSegment = segments?.[index - 1];

    if (!previousSegment) {
      return;
    }

    if (segment.location === previousSegment.location) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        params: {
          translationKey:
            'reimbursementView.middleSection.form.perDiem.customErrorMessages.sameLocation',
        },
        path: [index, 'location'],
      });
    }
  });
};

const validateStartTime = (segments: Segment[], ctx: RefinementCtx) => {
  segments.forEach((segment, index) => {
    if (index === 0) {
      return;
    }

    const previousSegment = segments?.[index - 1];

    if (!previousSegment) {
      return;
    }

    if (segment?.startTime !== previousSegment?.endTime) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        params: {
          translationKey:
            'reimbursementView.middleSection.form.perDiem.customErrorMessages.segmentsTimeNotTheSame',
        },
        path: [index, 'startTime'],
      });
    }
  });
};

const validateEndTime = (segments: Segment[], ctx: RefinementCtx) => {
  segments.forEach((segment, index) => {
    const startingDateTravel = segment.startDate;
    const startingTimeTravel = segment.startTime;
    const endingDateTravel = segment.endDate;
    const endingTimeTravel = segment.endTime;

    const startingDateTime = new Date(
      `${startingDateTravel},${startingTimeTravel}`
    );
    const endingDateTime = new Date(`${endingDateTravel},${endingTimeTravel}`);

    if (isEarlierTimeSameDay(startingDateTime, endingDateTime)) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        params: {
          translationKey:
            'reimbursementView.middleSection.form.perDiem.customErrorMessages.sameDateButEarlier',
        },
        path: [index, 'endTime'],
      });
    }
  });
};

const segmentsFormSchema = segments.superRefine((segments, ctx) => {
  validateMandatorySegmentFields(segments, ctx);
  validateStartDate(segments, ctx);
  validateEndDate(segments, ctx);
  validateLocation(segments, ctx);
  validateStartTime(segments, ctx);
  validateEndTime(segments, ctx);
});

const meal = z.enum(['breakfast', 'lunch', 'dinner']);

const day = z.object({
  dayId: z.string().uuid(),
  date: z.string(),
  meals: z.array(meal),
});

const days = z.array(day).min(1);

const perDiemFormSchema = z.object({
  reimbursementItemId: z.string().uuid(),
  reimbursementItemType: z.literal('perDiem'),
  segments: segmentsFormSchema,
  days,
  isExpenseExcluded: z.boolean(),
  itemStatus: z.nativeEnum(ReimbursementItemStatus),
  currentStep: z.nativeEnum(PerDiemStep),
});

// Validation functions
const validateDate = (ctx: RefinementCtx, values: ReimbursementItemSchema) => {
  if (values.reimbursementItemType === 'perDiem') {
    return;
  }

  if (!values.expenseDate) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'string',
      received: 'null',
      path: ['expenseDate'],
    });
    return;
  }

  const valueMoment = moment(values.expenseDate, moment.ISO_8601);
  const now = moment.tz('Europe/Berlin');
  const minDate = now.clone().subtract(1, 'year').toDate();

  if (valueMoment.isBefore(minDate)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      path: ['expenseDate'],
      params: {
        translationKey:
          'reimbursementView.middleSection.form.hospitality.date.errorMinYear',
      },
    });
  }

  if (isInTheFuture(values.expenseDate)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      path: ['expenseDate'],
      params: {
        translationKey:
          'reimbursementView.middleSection.form.hospitality.date.errorMaxDate',
      },
    });
  }
};

const validateHospitalitySpecificFields = (
  ctx: RefinementCtx,
  values: ReimbursementItemSchema
) => {
  if (values.reimbursementItemType !== 'hospitality') return;

  if (!values.location) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'string',
      received: 'null',
      path: ['location'],
    });
  }

  if (!values.internalGuests && !values.externalGuests) {
    ['internalGuests', 'externalGuests'].forEach(field => {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: [field],
      });
    });
  }
};

const validateAmounts = (
  ctx: RefinementCtx,
  values: ReimbursementItemSchema
) => {
  if (values.reimbursementItemType !== 'perDiem' && isNil(values.totalAmount)) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'number',
      received: 'null',
      path: ['totalAmount'],
    });
  }

  if (
    values.reimbursementItemType === 'hospitality' &&
    isNil(values.receiptAmount)
  ) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'number',
      received: 'null',
      path: ['receiptAmount'],
    });
  }
};

const validateCommonFields = (
  ctx: RefinementCtx,
  values: ReimbursementItemSchema
) => {
  if (values.reimbursementItemType !== 'perDiem' && !values.reason) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'string',
      received: 'null',
      path: ['reason'],
    });
  }

  if (values.reimbursementItemType !== 'perDiem' && !values.invoiceNumber) {
    ctx.addIssue({
      code: 'invalid_type',
      expected: 'string',
      received: 'null',
      path: ['invoiceNumber'],
    });
  }
};

// Main schema
const reimbursementItem = z
  .discriminatedUnion('reimbursementItemType', [
    baseHospitalityFormSchema,
    baseGeneralExpenseFormSchema,
    perDiemFormSchema,
  ])
  .superRefine((values, ctx) => {
    if (values.isExpenseExcluded) return;
    validateDate(ctx, values);
    validateAmounts(ctx, values);
    validateCommonFields(ctx, values);
    validateHospitalitySpecificFields(ctx, values);
  });

export const reimbursementItemsFormSchema = z.object({
  reimbursementItems: z.array(reimbursementItem),
});

// Types
export type ReimbursementItemsFormOutput = z.infer<
  typeof reimbursementItemsFormSchema
>;
export type Segment = Partial<z.infer<typeof segmentFormSchema>>;
export type ReimbursementItemSchema = z.infer<typeof reimbursementItem>;
export type ReimbursementItemSchemaOutput = z.infer<typeof reimbursementItem>;
export type ReimbursementItemsFormValues =
  DeepPartial<ReimbursementItemsFormOutput>;
export type ReimbursementItemFormPerDiemDay = z.infer<typeof day>;
export type ReimbursementItemFormPerDiemMeal = z.infer<typeof meal>;

import { Paragraph, Text } from '@candisio/design-system';
import {
  ExpenseItemExpenseDateUpdatedEventDataFragment,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { NavigateToItemWrapper } from '../NavigateToItemWrapper';

export const ExpenseItemFieldUpdatedDateUpdated = ({
  resourceId,
  properties,
  reimbursementItemMetadata,
}: ExpenseItemExpenseDateUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { newExpenseDate } = properties;

  const newFormattedDate = newExpenseDate
    ? DateFormatters.compact(new Date(newExpenseDate))
    : undefined;

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const headline = expenseTitle
    ? t('timeline.expenseChanged', { title: expenseTitle })
    : t('timeline.expenseChangedNoTitle');

  const dateTranslation =
    reimbursementItemMetadata?.type === ReimbursementItemType.HospitalityExpense
      ? t('reimbursementView.middleSection.form.hospitality.date.label')
      : t('reimbursementView.middleSection.form.general.date.label');

  return (
    <NavigateToItemWrapper id={resourceId}>
      <Text>{headline}</Text>
      <div className="pt-2">
        <Text fontWeight="regular" color="gray500">
          {dateTranslation}
        </Text>
        <Paragraph>{newFormattedDate ?? t('timeline.empty')}</Paragraph>
      </div>
    </NavigateToItemWrapper>
  );
};

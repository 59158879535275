import {
  DocumentWithIncompletePaymentData,
  PaidDocumentDataFragment,
  PayableDocument,
  PayableDocumentEdge,
  SepaExportableDocument,
  SepaTransferPaymentDataFragment,
  SepaTransferPaymentHistoryDataFragment,
  PayableDocumentEs,
} from 'generated-types/graphql.types';

export type SepaExportableEntry = Omit<SepaExportableDocument, 'contact'> & {
  __typename: 'SepaExportableDocument';
  contact: string;
};

export type NonSepaExportableEntry = Omit<
  DocumentWithIncompletePaymentData,
  'contact'
> & {
  __typename: 'DocumentWithIncompletePaymentData';
  contact: string;
};

/**
 * Flatten out the GraphQL type to work only with the required contact info
 */
export type SimplifiedPayableDocument =
  | SepaExportableEntry
  | NonSepaExportableEntry;

export type SimplifiedPayableDocumentEs = Omit<PayableDocumentEs, 'contact'> & {
  contact: string;
  currency: string;
};
/**
 * Flatten out the GraphQL type to work only with the required creditor info
 */
export type SimplifiedPaidDocument = Omit<
  PaidDocumentDataFragment,
  'documentId' | 'creditor'
> & {
  id: string;
  contact: string;
};

export type SimplifiedPayment = Omit<
  SepaTransferPaymentDataFragment,
  'paidDocuments'
> & {
  paidDocuments: SimplifiedPaidDocument[];
};

// aliasing the Fragment for consistency
export type SimplifiedPaymentHistoryEntry =
  SepaTransferPaymentHistoryDataFragment;

export enum PaymentStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
}

export type PayableDocumentProps = (PayableDocument & PayableDocumentEdge)[];

import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import { usePath } from 'hooks/usePath';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom-v5-compat';
import { useCreditCardCountData } from 'views/CreditCards/hooks/useCreditCardCountData';
import styles from './CardInUseTile.module.css';
import { Count } from '../../shared/Count/Count';
import { IconBadge } from 'components/IconBadge/IconBadge';

export const CardInUseTile = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { totalNumberOfActiveCards } = useCreditCardCountData();

  const href = usePath({
    pathname: '/credit_cards/dashboard/card_manager_overview/:creditCardId?',
    search: {
      toolbarFilter: 'InUse',
    },
  });

  return (
    <SwimlaneTile asChild>
      <NavLink data-cy="cardInUseTile" to={href} className={styles.content}>
        <IconBadge icon="creditCardOutline" />
        <div className="flex flex-col gap-1">
          <SwimlaneTileDescription>
            {totalNumberOfActiveCards === 1
              ? t('insights.requestsOverview.cardsInUse')
              : t('insights.requestsOverview.cardsInUse_plural')}
          </SwimlaneTileDescription>
          <Count count={totalNumberOfActiveCards} />
        </div>
      </NavLink>
    </SwimlaneTile>
  );
};

import { useQuery } from '@apollo/client';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import {
  CardIssuerCardSortField,
  CardStatus,
  CardIssuerTransactionSortField,
  SortDirection,
  TransactionStatus,
  CardIssuerTransactionEdge,
  CardIssuerCardEdgeDataFragment,
} from 'generated-types/graphql.types';
import {
  getManagerCreditCardInsightsQuery,
  getCardholderCreditCardInsightsQuery,
} from '../queries';

interface CreditCardInsightsData {
  cardRequests: {
    edges: CardIssuerCardEdgeDataFragment[];
    pageInfo: {
      totalCount: number;
    };
  };
  limitRequests: {
    edges: CardIssuerCardEdgeDataFragment[];
    pageInfo: {
      totalCount: number;
    };
  };
  declinedTransactions: {
    edges: CardIssuerTransactionEdge[];
    pageInfo: {
      totalCount: number;
    };
  };
}

interface ManagerCreditCardInsightsQuery extends CreditCardInsightsData {}

interface CardholderCreditCardInsightsQuery extends CreditCardInsightsData {}

export const pendingCardRequestsStatues = [CardStatus.Requested];

export const pendingLimitRequestsStatues = [
  CardStatus.Active,
  CardStatus.Locked,
  CardStatus.LockedPin,
  CardStatus.Pending,
  CardStatus.Processing,
];

const cardRequestFilters = {
  hasPendingRequests: true,
  statuses: pendingCardRequestsStatues,
} as const;

const cardLimitFilters = {
  hasPendingRequests: true,
  statuses: pendingLimitRequestsStatues,
} as const;

export const useCreditCardInsights = () => {
  const { canManageCards } = usePermissionsForCreditCards();

  const queryVariables = {
    cardInput: {
      limit: 6,
    },
    cardRequestFilters,
    cardLimitFilters,
    cardSortBy: {
      field: CardIssuerCardSortField.IssuingDatePrimaryRequestedDateSecondary,
      direction: SortDirection.Desc,
    },
    transactionInput: {
      limit: 6,
    },
    transactionFilters: {
      status: [TransactionStatus.Declined],
    },
    transactionSortBy: {
      field: CardIssuerTransactionSortField.TransactionCreatedAt,
      direction: SortDirection.Desc,
    },
  };

  const {
    data: managerData,
    loading: managerLoading,
    previousData: previousManagerData,
  } = useQuery<ManagerCreditCardInsightsQuery>(
    getManagerCreditCardInsightsQuery,
    {
      variables: queryVariables,
      fetchPolicy: 'cache-and-network',
      skip: !canManageCards,
    }
  );

  const {
    data: cardholderData,
    loading: cardholderLoading,
    previousData: previousCardholderData,
  } = useQuery<CardholderCreditCardInsightsQuery>(
    getCardholderCreditCardInsightsQuery,
    {
      variables: queryVariables,
      fetchPolicy: 'cache-and-network',
      skip: canManageCards,
    }
  );

  const currentManagerData = managerData ?? previousManagerData;
  const currentCardholderData = cardholderData ?? previousCardholderData;

  const data = canManageCards ? currentManagerData : currentCardholderData;

  const isInitialLoading =
    (managerLoading && !managerData && !previousManagerData) ||
    (cardholderLoading && !cardholderData && !previousCardholderData);

  return {
    cardRequests: {
      data: data?.cardRequests?.edges?.map(edge => edge.node),
      totalCount: data?.cardRequests?.pageInfo?.totalCount,
    },
    limitRequests: {
      data: data?.limitRequests?.edges?.map(edge => edge.node),
      totalCount: data?.limitRequests?.pageInfo?.totalCount,
    },
    declinedTransactions: {
      data: data?.declinedTransactions?.edges?.map(edge => edge.node),
      totalCount: data?.declinedTransactions?.pageInfo?.totalCount,
    },
    isInitialLoading,
  };
};

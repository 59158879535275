import { CellProps, Grid, Tag, TagProps, Text } from '@candisio/design-system';
import { StatusIcon } from 'components/DocumentsTable/Cells/StatusCell';
import {
  EcmDocumentStatus,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { EInvoiceInfoIcon } from 'views/Inbox/DocumentProcessing/components/EInvoiceInfoIcon';
import { useEcmDocumentStatusOptions } from '../hooks/useEcmDocumentStatusOptions';
import {
  EcmDocumentStatusWithTypeFlags,
  EcmDocumentsTableData,
} from '../types';

const statuses: Record<EcmDocumentStatus | string, TagProps['color']> = {
  PROCESSING: 'yellow',
  NEW: 'gray',
  OPEN: 'yellow',
  REJECTED: 'red',
  APPROVED: 'green',
  EXPORTED: 'blue',
  EXPORTING: 'yellow',
  STORED: 'gray',
  ARCHIVED: 'gray',
  [ReimbursementCaseStatus.Check]: 'red',
  [ReimbursementCaseStatus.Review]: 'yellow',
  [ReimbursementCaseStatus.Draft]: 'gray',
  [ReimbursementCaseStatus.PartiallyExported]: 'yellow',
  [ReimbursementCaseStatus.Approving]: 'yellow',
};

type Value = EcmDocumentStatusWithTypeFlags | undefined;

/**
 * A cell that displays the status of an ECM document.
 */
export const StatusCell = ({
  value,
}: CellProps<EcmDocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { ecmDocumentStatusTranslationMap } = useEcmDocumentStatusOptions();

  if (
    !value?.status ||
    !ecmDocumentStatusTranslationMap?.[value.status] ||
    !statuses?.[value?.status]
  ) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  return (
    <Grid
      alignItems="center"
      autoFlow="column"
      justifyContent="start"
      gap="space4"
    >
      <Tag color={statuses[value?.status]} variant="secondary">
        {ecmDocumentStatusTranslationMap[value.status]}
      </Tag>
      {value.isSensitive && (
        <StatusIcon
          label={t('contractStatus.sensitive')}
          icon="lockFilled"
          color="gray500"
        />
      )}
      {value.isEInvoice && <EInvoiceInfoIcon isEInvoice={value.isEInvoice} />}
    </Grid>
  );
};

import { Grid } from '@candisio/design-system';
import { ReimbursementsPromotion } from 'components/ProductPromotions/Reimbursement/ReimbursementsPromotion';
import { EventLocationsBase } from 'providers/AnalyticsProvider/events';
import { ReimbursementCaseStatus } from 'types/ReimbursementCaseStatusEnum';
import { ReimbursementTableColumnKeys } from './toolkit/types';

const tableColumns: ReimbursementTableColumnKeys[] = [
  'status',
  'requesterOfTheReimbursement',
  'title',
  'createdAt',
  'grossAmount',
];

export const ReimbursementsDashboardPromotion = () => {
  return (
    <Grid height="100%" padding="space24 space24">
      <ReimbursementsPromotion
        tableColumns={tableColumns}
        status={ReimbursementCaseStatus.Check}
        eventLocation={EventLocationsBase.REIMBURSEMENT_DASHBOARD}
      />
    </Grid>
  );
};

import { HighlightedTruncatedText } from '@candisio/design-system';
import { SplitsCell, SplitsCellValue, getSplitsRenderType } from './SplitsCell';
import { DocumentsTableData } from '../../types';
import { CellContext } from '@tanstack/react-table';
import { getCellHighlights } from 'components/WithHighlightsCell/next/WithHighlightsCell';

export const SplitsCellHighlightable = (
  cellProps: Omit<
    CellContext<DocumentsTableData, SplitsCellValue>,
    'getValue'
  > & {
    value: SplitsCellValue;
  }
) => {
  const { value } = cellProps;
  const renderType = getSplitsRenderType(value);

  // Splits are not highlightable yet
  const isSplit = renderType !== 'single';
  const highlights = getCellHighlights(cellProps);
  if (!highlights || isSplit) {
    return <SplitsCell {...cellProps} />;
  }

  return <HighlightedTruncatedText highlight={highlights} />;
};

import { Link } from '@candisio/design-system';
import { EmailInboxMessageAttachmentError } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

const getTooltipTranslationKeys = (
  err: EmailInboxMessageAttachmentError
): {
  error_info: string;
  url: string;
} => {
  switch (err.__typename) {
    case 'MaxAttachmentSizeExceeded':
      return {
        error_info:
          'settings.mailSync.details.tabs.metadata.attachments.errorInfo.maxSizeExceeded.errorInfoText',
        url: 'settings.mailSync.details.tabs.metadata.attachments.errorInfo.maxSizeExceeded.helpCenterUrl',
      };
    case 'AttachmentDocumentRemovedError':
      return {
        error_info:
          'settings.mailSync.details.tabs.metadata.attachments.errorInfo.documentRemoved.errorInfoText',
        url: 'settings.mailSync.details.tabs.metadata.attachments.errorInfo.documentRemoved.helpCenterUrl',
      };
    case 'AttachmentDocumentNotFoundError':
      return {
        error_info:
          'settings.mailSync.details.tabs.metadata.attachments.errorInfo.documentNotFound.errorInfoText',
        url: 'settings.mailSync.details.tabs.metadata.attachments.errorInfo.documentNotFound.helpCenterUrl',
      };
    case 'EmailMessageVirusDetected':
      return {
        error_info:
          'settings.mailSync.details.tabs.metadata.attachments.errorInfo.virusDetected.errorInfoText',
        url: 'settings.mailSync.details.tabs.metadata.attachments.errorInfo.virusDetected.helpCenterUrl',
      };
    case 'UnprocessableAttachment':
      return {
        error_info:
          'settings.mailSync.details.tabs.metadata.attachments.errorInfo.unprocessable.errorInfoText',
        url: 'settings.mailSync.details.tabs.metadata.attachments.errorInfo.unprocessable.helpCenterUrl',
      };
    case 'UnsupportedAttachmentType':
      return {
        error_info:
          'settings.mailSync.details.tabs.metadata.attachments.errorInfo.unsupported.errorInfoText',
        url: 'settings.mailSync.details.tabs.metadata.attachments.errorInfo.unsupported.helpCenterUrl',
      };
    case 'UnprocessableXmlAttachment':
      return {
        error_info:
          'settings.mailSync.details.tabs.metadata.attachments.errorInfo.unprocessableXml.errorInfoText',
        url: 'settings.mailSync.details.tabs.metadata.attachments.errorInfo.unprocessableXml.helpCenterUrl',
      };
    default:
      return {
        error_info:
          'settings.mailSync.details.tabs.metadata.attachments.errorInfo.unprocessable.errorInfoText',
        url: 'settings.mailSync.details.tabs.metadata.attachments.errorInfo.unprocessable.helpCenterUrl',
      };
  }
};

export const AttachmentErrorTooltipContent = ({
  err,
}: {
  err: EmailInboxMessageAttachmentError;
}) => {
  const [t] = useTranslation();
  const { error_info, url } = getTooltipTranslationKeys(err);

  return (
    <div className="flex flex-col gap-1">
      {t(error_info)}
      <Link external href={t(url)}>
        {t(
          'settings.mailSync.details.tabs.metadata.attachments.errorInfo.moreInfo'
        )}
      </Link>
    </div>
  );
};

import {
  DocumentBookingAssociation,
  useGetDocumentBookingAssociationsByInvoiceIdQuery,
} from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import { useCallback, useMemo } from 'react';
import { useSapPurchaseOrders } from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/hooks/useSapPurchaseOrders';
import { getBookingItemQuantity } from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/utils';
import { useAssociatedDocuments } from 'views/DocumentDetails/components/PurchaseOrderSection/hooks/useAssociatedDocuments';
import { useIsSapGoodsInvoice } from 'views/utils/useShouldShowField';
/**
 *
 * @param documentId
 * @returns getBookingAssociationData
 * quantity and expenseType are obtained from DocumentBookingAssociationData
 *
 * quantity is undefined if it's not required(service type purchase order or it's not sap purchase order), otherwise it's at least 1
 */

export const useGetBookingAssociation = (documentId?: string) => {
  const { shouldUseSapGoodsReceipts } = useSap();
  const { associatedDocumentsLength } = useAssociatedDocuments(documentId);

  const { purchaseOrders } = useSapPurchaseOrders(documentId);

  const isGoodsItemsConnected = useIsSapGoodsInvoice(documentId ?? '');

  const { data: bookingAssociations } =
    useGetDocumentBookingAssociationsByInvoiceIdQuery({
      variables: { invoiceId: documentId ?? '' },
      skip:
        !documentId ||
        (shouldUseSapGoodsReceipts
          ? !associatedDocumentsLength
          : !purchaseOrders.length),
      fetchPolicy: 'cache-and-network',
    });

  const bookingAssociationsData = useMemo(
    () =>
      (bookingAssociations?.getDocumentBookingAssociationsByInvoiceId ??
        []) as DocumentBookingAssociation[],
    [bookingAssociations?.getDocumentBookingAssociationsByInvoiceId]
  );

  const getBookingAssociationData = useCallback(
    (bookingId?: string) => {
      if (!isGoodsItemsConnected || !bookingId) {
        return {
          quantity: undefined,
          sapExpenseType: undefined,
          unitPrice: undefined,
        };
      }
      const bookingAssoc = bookingAssociationsData.find(
        booking => booking?.bookingId === bookingId
      );

      return {
        quantity: getBookingItemQuantity(bookingId, bookingAssociationsData),
        sapExpenseType: bookingAssoc?.additionalExpenseInfo?.expenseType,
        unitPrice: bookingAssoc?.unitPrice,
      };
    },
    [isGoodsItemsConnected, bookingAssociationsData]
  );

  return {
    isQuantityRequired: isGoodsItemsConnected,
    getBookingAssociationData,
  };
};

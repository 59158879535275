import { MessageBox } from '@candisio/design-system';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useLocation } from 'react-router-dom';
import { CREATE_REIMBURSEMENT_ERROR } from 'views/Reimbursement/hooks/useCreateReimbursement';
import { CreateReimbursementDuplicateErrorContent } from '../CreateReimbursementDuplicateErrorContent';

export const ReimbursementCreateDuplicateError = () => {
  const { state } = useLocation<{ error: string }>();

  const hasDuplicateError =
    state?.error === CREATE_REIMBURSEMENT_ERROR.CONTACT_NAME_DUPLICATE;

  if (!hasDuplicateError) {
    return null;
  }

  return (
    <div className="w-3xl">
      <MessageBox
        additionalContent={<CreateReimbursementDuplicateErrorContent />}
        variant="error"
        message=""
      />
    </div>
  );
};

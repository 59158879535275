import { Maybe } from '@graphql-tools/utils';
import {
  FileUploadAttempt,
  UploadFileItem,
  useFileUploadsQuery,
} from 'generated-types/graphql.types';
import { useGetFileUploadAttempts } from '../useGetFileUploadAttempts';

export type ItemEntry = {
  isDeleted?: Maybe<boolean>;
} & (
  | {
      entry: UploadFileItem;
      type: 'local';
    }
  | {
      entry: FileUploadAttempt;
      type: 'remote';
    }
);

export const useHistoryListItems = () => {
  const { data = [] } = useGetFileUploadAttempts();
  const {
    data: { fileUploads = [] } = {},
  } = useFileUploadsQuery();

  const itemMap: Map<string, ItemEntry> = new Map(
    fileUploads
      .filter(entry => entry.fileId)
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .map(entry => [
        entry.fileId as string,
        { entry, type: 'local', isDeleted: entry.isDeleted ?? false },
      ])
  );

  for (const entry of data) {
    const localEntry = itemMap.get(entry.file.id);

    itemMap.set(entry.file.id, {
      entry,
      type: 'remote',
      isDeleted:
        localEntry?.type === 'local' ? localEntry?.entry.isDeleted : false,
    });
  }

  return Array.from(itemMap.values()).filter(entry => !entry.isDeleted);
};

import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useArchiveReimbursementCasesCount } from 'views/Reimbursement/hooks/useArchiveReimbursementCasesCount';
import { NavigationIcon } from '../../NavigationIcon';
import { NavIconProps } from '../../../hooks/useNavigationIcons';
import { openReimbursementStatuses } from 'views/Reimbursement/hooks/useDefaultQueryParameters';

export const MenuTravel = (iconProps: NavIconProps) => {
  const currentUser = useCurrentUser();

  const {
    count: countReimbursements,
    loadingCount: loadingCountReimbursements,
  } = useArchiveReimbursementCasesCount({
    filters: {
      statuses: openReimbursementStatuses,
      targetMembershipIds: currentUser?.id ? [currentUser?.id] : undefined,
    },
  });

  return (
    <NavigationIcon
      {...iconProps}
      count={countReimbursements}
      countLoading={loadingCountReimbursements}
    />
  );
};

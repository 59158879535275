import { FieldContainer, Input as TextInput } from '@candisio/design-system';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useState } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

export interface AccountsReceivableNumberFieldProps<
  TFormValues extends FieldValues,
> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Field label */
  label?: string;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
}

/**
 * Accounts receivable number field for React Hook Form.
 */
export const AccountsReceivableNumberField = <TFormValues extends FieldValues>({
  control,
  disabled,
  label,
  name,
  placeholder,
  readOnly: readOnlyProp,
}: AccountsReceivableNumberFieldProps<TFormValues>) => {
  const { friendlyFormatAccountsNumber, removeSpacesConditionally } =
    useAccountingNumberFormatters();

  const { field, fieldState, formState } = useController({ control, name });
  const { onChange, value, onBlur, ...fieldProps } = field;

  const [friendlyValue, setFriendlyValue] = useState(
    value ? friendlyFormatAccountsNumber(value) : ''
  );

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;

  const readOnly = readOnlyProp || formState.isSubmitting;

  return (
    <FieldContainer
      disabled={disabled}
      label={label}
      message={errorMessage}
      readOnly={readOnly}
      variant={hasError ? 'error' : 'default'}
    >
      <TextInput
        disabled={disabled}
        onChange={e => {
          const result = e.target.value !== '' ? e.target.value : null;

          setFriendlyValue(result ?? '');
          onChange(result && removeSpacesConditionally(result));
        }}
        placeholder={placeholder}
        value={friendlyValue}
        readOnly={readOnly}
        onBlur={e => {
          onBlur();
          setFriendlyValue(
            friendlyFormatAccountsNumber(e.target.value) ?? null
          );
        }}
        message={errorMessage}
        variant={hasError ? 'error' : 'default'}
        showMessageOnFocus={hasError}
        {...fieldProps}
      />
    </FieldContainer>
  );
};

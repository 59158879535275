import { memo } from 'react';
import { ItemProps } from 'react-virtuoso';
import { Row } from '@tanstack/react-table';
import clsx from 'clsx';
import styles from '../Table.module.css';
import { ROW_OVERLAY_CLASS_NAME } from '../handleUpdateRowOverlayPosition';
import { BaseTableDataType } from './types';

interface TableRowProps<T extends BaseTableDataType> {
  onRowClick?: (row: T) => void;
  overlay?: (props: { data: T }) => JSX.Element;
}

function TableRowInner<T extends BaseTableDataType>({
  onRowClick,
  children,
  overlay: Overlay,
  item,
  style,
  'data-index': dataIndex,
  'data-item-index': dataItemIndex,
  'data-known-size': dataKnownSize,
  'data-item-group-index': dataItemGroupIndex,
}: TableRowProps<T> & ItemProps<Row<T>>) {
  return (
    <tr
      data-index={dataIndex}
      data-item-index={dataItemIndex}
      data-known-size={dataKnownSize}
      data-item-group-index={dataItemGroupIndex}
      data-clickable={Boolean(onRowClick)}
      data-selected={item.original.selected}
      onClick={onRowClick ? () => onRowClick?.(item.original) : undefined}
      style={style}
    >
      {children}
      {Overlay && (
        <div
          className={clsx(styles['row-overlay'], ROW_OVERLAY_CLASS_NAME)}
          data-selected={item.original.selected}
        >
          <Overlay data={item.original} />
        </div>
      )}
    </tr>
  );
}

export const TableRow = memo(TableRowInner, (prev, next) => {
  return prev.item === next.item;
}) as unknown as typeof TableRowInner;

import { Button, Tag, Text } from '@candisio/design-system';
import styles from './TravelPromotion.module.css';
import { ReactNode } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

interface CollapsibleCardProps {
  tagText: string;
  isCollapsed: boolean;
  heading: string;
  description: string;
  footer?: ReactNode;
  variant: 'blue' | 'purple';
  onCollapse: () => void;
}

export const CollapsibleCard = ({
  tagText,
  isCollapsed,
  description,
  heading,
  footer,
  variant,
  onCollapse,
}: CollapsibleCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  return (
    <div
      className={clsx(
        styles['collapsible-card'],
        styles[`collapsible-card--${variant}`]
      )}
      data-collapsed={isCollapsed}
    >
      <div className={styles['header-wrapper']}>
        <Tag color={variant} variant="secondary">
          {tagText}
        </Tag>
        <Button
          icon={isCollapsed ? 'caretDown' : 'caretUp'}
          size="small"
          onClick={onCollapse}
          variant="tertiary"
          color={variant}
        >
          {t('reimbursementView.middleSection.promotion.showMore')}
        </Button>
      </div>
      <Text
        fontSize="xxlarge"
        fontStyle="normal"
        lineHeight="33px"
        fontWeight="bold"
      >
        {heading}
      </Text>
      <div className="flex flex-col gap-4">
        <Text fontSize="basic">{description}</Text>
        {footer}
      </div>
    </div>
  );
};

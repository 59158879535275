import clsx from 'clsx';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { ReactNode } from 'react';
import { DropEvent, DropItem, FileDropItem } from 'react-aria';
import { fileArrayToFileList } from 'utils/fileArrayToFileList';
import styles from './UploadDropZone.module.css';
import { useUploadDropZone } from './useUploadDropZone';

type UploadDropZoneProps = {
  children:
    | ReactNode
    | (({
        isDropValid,
        isTarget,
        isVisible,
      }: {
        isTarget: boolean;
        isDropValid: boolean;
        isVisible: boolean;
      }) => ReactNode);
  className?: string;
  isDisabled?: boolean;
  isRestrictedContent?: boolean;
  onDrop: (files: FileList) => void;
};

const isFile = (item: DropItem): item is FileDropItem => {
  return item.kind === 'file';
};

export const UploadDropZone = ({
  children,
  className,
  isDisabled,
  isRestrictedContent,
  onDrop,
}: UploadDropZoneProps) => {
  const documentUploadReworkFF = useCandisFeatureFlags(
    FEATURE_FLAGS.documentUploadRework
  );
  const handleDrop = async (event: DropEvent) => {
    const droppedFiles = Array.from(event.items)
      .filter(isFile)
      .map(item => item.getFile())
      .filter(Boolean);

    const files = await Promise.all(droppedFiles);
    const fileList = fileArrayToFileList(files);

    onDrop(fileList);
  };

  const { dropProps, isDropValid, isTarget, isVisible, ref } =
    useUploadDropZone({
      isDisabled,
      isRestrictedContent,
      onDrop: handleDrop,
    });

  if (!documentUploadReworkFF) {
    return null;
  }

  return (
    <div
      className={clsx(styles.zone, className)}
      data-cy="dropzone"
      data-error={!isDropValid}
      data-targetted={isTarget}
      data-visible={isVisible}
      ref={ref}
      {...dropProps}
    >
      {isVisible && (
        <div className="place-items-center flex flex-col gap-2">
          {children instanceof Function
            ? children({ isDropValid, isTarget, isVisible })
            : children}
        </div>
      )}
    </div>
  );
};

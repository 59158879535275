class Faker {
  private firstNames: string[] = [
    'Hans',
    'Inge',
    'Günther',
    'Heike',
    'Peter',
    'Monika',
    'Klaus',
    'Elke',
    'Jürgen',
    'Renate',
    'Wolfgang',
    'Brigitte',
    'Dieter',
    'Ursula',
    'Horst',
    'Erika',
    'Manfred',
    'Helga',
    'Rolf',
    'Christa',
    'Werner',
    'Hannelore',
    'Bernd',
    'Annemarie',
    'Herbert',
    'Gisela',
    'Friedrich',
    'Margot',
  ];
  private lastNames: string[] = [
    'Müller',
    'Schmidt',
    'Schneider',
    'Fischer',
    'Weber',
    'Meyer',
    'Wagner',
    'Becker',
    'Schulz',
    'Hoffmann',
    'Koch',
    'Richter',
    'Klein',
    'Wolf',
    'Schröder',
    'Neumann',
    'Braun',
    'Krüger',
    'Hofmann',
    'Hartmann',
    'Lange',
    'Schmitt',
    'Werner',
    'Friedrich',
    'Jung',
    'Berger',
    'Martin',
    'Krause',
  ];
  private companyNames: string[] = [
    'SAP SE',
    'Amazon Services Europe S.à r.l.',
    'LinkedIn Ireland Unlimited Company',
    'Amazon Web Services',
    'Deutsche Telekom',
    'Atlassian',
    'IHK Berlin',
    'Getränkemarkt Sprudel',
    'Zalando SE',
    'Miro',
    'Delivery Hero',
    'M2L Agency GmbH',
    'Personio',
    'Grammarly',
    'SendCloud GmbH',
    'DPD Deutschland GmbH',
    'Babbel',
    'DB Fernverkehr AG/DB Regio AG',
    'ResearchGate',
    'DeepL',
    'Solarwatt',
    'Deutsche Post AG',
    'Trivago',
    'Google Cloud EMEA Limited',
    'Salesforce',
  ];

  // Generate a random number between min and max (inclusive)
  number(min: number = 1000, max: number = 99999): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Generate a random string
  string(): string {
    return Math.random().toString(36).slice(2, 24);
  }

  // Generate a random UUID
  uuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
      const random = (Math.random() * 16) | 0;
      const value = char === 'x' ? random : (random & 0x3) | 0x8;
      return value.toString(16);
    });
  }

  // Generate a random first name
  firstName(): string {
    return this.firstNames[Math.floor(Math.random() * this.firstNames.length)];
  }

  // Generate a indexed first name
  indexedFirstName(index: number): string {
    return this.firstNames[index % this.firstNames.length];
  }

  // Generate a indexed first name from the end
  indexedFirstNameFromEnd(index: number): string {
    return this.firstNames[
      (this.firstNames.length -
        1 -
        (index % this.firstNames.length) +
        this.firstNames.length) %
        this.firstNames.length
    ];
  }

  // Generate a random last name
  lastName(): string {
    return this.lastNames[Math.floor(Math.random() * this.lastNames.length)];
  }

  // Generate a indexed last name
  indexedLastName(index: number): string {
    return this.lastNames[index % this.lastNames.length];
  }

  // Generate an indexed last name starting from the end
  indexedLastNameFromEnd(index: number): string {
    return this.lastNames[
      (this.lastNames.length -
        1 -
        (index % this.lastNames.length) +
        this.lastNames.length) %
        this.lastNames.length
    ];
  }

  // Generate a random name (first name + last name)
  name(): string {
    return `${this.firstName()} ${this.lastName()}`;
  }

  // Generate a random company name
  companyName(): string {
    return this.companyNames[
      Math.floor(Math.random() * this.companyNames.length)
    ];
  }

  // Generate a random date object
  date(): Date {
    return new Date(Date.now() - Math.floor(Math.random() * 1e10));
  }
}

export const faker = new Faker();

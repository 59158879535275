import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  DocumentType,
  EcmFilterInput,
  useCreatePdfZipFileForEcmDocumentsMutation,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { refetchDocumentDataFileRecords } from 'views/Archive/Documents/queries';
import { getArchiveDocumentZipDownloadErrorMessage } from 'views/Archive/Documents/utils';
import { useOrganizationData } from 'views/Settings/Organization/hooks/useOrganizationData';
import { usePrepareEcmFiltersSortingFromUrl } from './usePrepareEcmFiltersSortingFromUrl';

type DefaultFilters = EcmFilterInput & {
  documentType: Array<DocumentType> | undefined;
};

interface UseDownloadDocumentFileEcmParams {
  defaultFilters?: DefaultFilters;
  isTableLoading: boolean;
  handleClosePdfDownloadModal: () => void;
}

export const useDownloadDocumentFilesEcm = ({
  defaultFilters,
  isTableLoading,
  handleClosePdfDownloadModal,
}: UseDownloadDocumentFileEcmParams) => {
  const [t] = useTranslation();
  const { success, error, info } = useToastMessage();

  const { organizationSlug } = useOrganizationData();

  const history = useHistory();

  const [
    triggerCreateDocumentZipFileForDocuments,
    { loading: createDocumentZipFileLoading },
  ] = useCreatePdfZipFileForEcmDocumentsMutation();

  const { filters, sort, search, searchInput } =
    usePrepareEcmFiltersSortingFromUrl();

  const onPrepareDownloadDocument = useCallback(async () => {
    if (isTableLoading) {
      info(t('archive:download.waitForLoading'));
    }

    const results = await triggerCreateDocumentZipFileForDocuments({
      refetchQueries: refetchDocumentDataFileRecords,
      variables: {
        filters: defaultFilters ? { ...filters, ...defaultFilters } : filters,
        sort,
        search,
        searchInput,
      },
    });

    const data = results.data?.createPDFZipFileForEcmDocuments;
    const type = data?.__typename;
    const errorType =
      type === 'DownloadDocumentPDFsError' ? data?.kind : undefined;

    if (type === 'DownloadDocumentPDFsResult') {
      handleClosePdfDownloadModal();
      success(t('archive:download.pdFZipDownloadInitiated'));
      history.push(
        `/${organizationSlug}${Routes.DOCUMENT_DATA_DOWNLOAD_HISTORY}${window.location.search}`
      );
      return;
    }

    if (errorType) {
      const errorMessageTranslationKey =
        getArchiveDocumentZipDownloadErrorMessage({
          errorType,
        });

      error(t(errorMessageTranslationKey));
    }
  }, [
    isTableLoading,
    triggerCreateDocumentZipFileForDocuments,
    defaultFilters,
    filters,
    sort,
    search,
    info,
    t,
    handleClosePdfDownloadModal,
    success,
    error,
    history,
    organizationSlug,
    searchInput,
  ]);

  return {
    createDocumentZipFileLoading,
    onPrepareDownloadDocument,
  };
};

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useAssociateGoodsReceiptsToDocumentMutation } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GoodsReceiptsTableData } from 'views/Archive/GoodsReceipts/types';
import { getRefetchQueries } from '../utils/getRefetchQueries';
import { useApolloClient } from '@apollo/client';

interface UseLinkGoodsReceiptsToDocumentProps {
  documentId: string;
  onCompleted?: () => void;
}

export const useLinkGoodsReceiptsToDocument = ({
  documentId,
  onCompleted: onLinkingCompleted,
}: UseLinkGoodsReceiptsToDocumentProps) => {
  const { success, error } = useToastMessage();
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const client = useApolloClient();
  const [linkGoodsReceiptsToDocumentMutation, { loading: isLinkingLoading }] =
    useAssociateGoodsReceiptsToDocumentMutation({
      awaitRefetchQueries: true,
      refetchQueries: result => {
        if (result.data?.associateGoodsReceiptsToDocument.length) {
          return getRefetchQueries(documentId);
        }
        return [];
      },
      onCompleted: data => {
        if (data?.associateGoodsReceiptsToDocument?.length) {
          client.cache.evict({ fieldName: 'getSapDocuments' });
          client.cache.evict({ fieldName: 'getDocumentForDraft' });
          onLinkingCompleted?.();
        }
      },
    });

  const linkGoodsReceipts = useCallback(
    async (goodsReceipts: GoodsReceiptsTableData[]) => {
      if (documentId && goodsReceipts.length) {
        const selectedGoodsReceiptIds = goodsReceipts.map(gr => gr.id);
        const selectedGoodsReceiptNumbers = goodsReceipts.map(
          po => po.receiptNumber
        );

        const { data, errors } = await linkGoodsReceiptsToDocumentMutation({
          variables: {
            documentId,
            goodsReceiptsIds: selectedGoodsReceiptIds,
          },
        });

        if (data?.associateGoodsReceiptsToDocument?.length) {
          success(
            t('actions.goodsReceiptLinkingSuccess', {
              receiptNumber: selectedGoodsReceiptNumbers.join(', '),
              count: selectedGoodsReceiptNumbers.length,
            })
          );
        } else if (errors?.length) {
          error(
            t('actions.goodsReceiptLinkingError', {
              receiptNumber: selectedGoodsReceiptNumbers.join(', '),
              count: selectedGoodsReceiptNumbers.length,
            })
          );
        }
      }
    },
    [documentId, success, t, error, linkGoodsReceiptsToDocumentMutation]
  );

  return {
    linkGoodsReceipts,
    isLinkingLoading,
  };
};

import { Grid, Link, Separator, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const CardholderContent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid>
      <Grid padding="space16" gap="space4">
        <Text fontSize="xlarge">
          {t('successPage.cardholder.firstArticle')}
        </Text>
        <Link
          external
          fontSize="basic"
          href={t('successPage.cardholder.article.link')}
        >
          {t('successPage.cardholder.article.text')}
        </Link>
      </Grid>
      <Separator />

      <Grid padding="space16" gap="space4">
        <Text fontSize="xlarge">
          {t('successPage.cardholder.secondArticle')}
        </Text>
        <Link
          external
          fontSize="basic"
          href={t('successPage.cardholder.helpCenter.link')}
        >
          {t('successPage.cardholder.helpCenter.text')}
        </Link>
      </Grid>
    </Grid>
  );
};

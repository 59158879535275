import { useApprovalTabNavigationData } from './useApprovalTabNavigationData';
import { useApprovalTabNavigationDataDeprecated } from './useApprovalTabNavigationDataDeprecated';
import { useEcm } from 'orgConfig/ecm/useEcm';

export const useApprovalData = ({ skip = false }: { skip?: boolean } = {}) => {
  const { useNewApprovalQuery } = useEcm();

  const deprecatedData = useApprovalTabNavigationDataDeprecated({
    skip: useNewApprovalQuery || skip,
  });

  const data = useApprovalTabNavigationData({
    skip: !useNewApprovalQuery || skip,
  });

  return useNewApprovalQuery
    ? {
        ...data,
        isLoading: data.approvalDocumentsBasicsQueryResult.loading,
        approvalDocumentsBasicsQueryResult:
          data.approvalDocumentsBasicsQueryResult.data
            ?.approvalInvoiceDocuments,
      }
    : {
        ...deprecatedData,
        isLoading: deprecatedData.approvalDocumentsBasicsQueryResult.loading,
        approvalDocumentsBasicsQueryResult:
          deprecatedData.approvalDocumentsBasicsQueryResult.data
            ?.listApprovalsDocuments,
      };
};

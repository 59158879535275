import { useGetPath } from 'hooks/usePath';
import { useNavigate } from 'react-router-dom-v5-compat';
import {
  CREATE_REIMBURSEMENT_ERROR,
  useCreateReimbursement,
} from './useCreateReimbursement';

export const useCreateReimbursementWithRedirect = () => {
  const navigate = useNavigate();
  const getPath = useGetPath();

  return useCreateReimbursement({
    onSuccess: reimbursementId => {
      const path = getPath({
        pathname: '/reimbursements_dashboard/:reimbursementId',
        params: {
          reimbursementId,
        },
      });

      navigate(path);
    },
    onFailure: errors => {
      const hasDuplicateContactError = errors.some(error =>
        error.message.includes('CONTACT_NAME_DUPLICATE')
      );

      if (!hasDuplicateContactError) {
        return;
      }

      const path = getPath({
        pathname: '/reimbursements_dashboard',
      });

      navigate(
        { pathname: path },
        {
          state: { error: CREATE_REIMBURSEMENT_ERROR.CONTACT_NAME_DUPLICATE },
          replace: true,
        }
      );
    },
  });
};

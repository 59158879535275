import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';

export const useBaselinePromotionSeenState = () => {
  const promoId = TOUR_POPOVER_IDS.BASELINE_SEARCH_PROMOTION_SEEN;
  const { isPromotionSeen, setPromotionIsSeen } =
    useSyncedPromoPreference(promoId);
  return {
    baselineSearchPromotionSeen: isPromotionSeen,
    setPromotionIsSeen,
  };
};

import { Box, Grid, Link, Paragraph, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ExchangeRatePanel = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid gap="space16" paddingY="space8">
      <Box>
        <Text color="gray500" fontWeight="xsmall">
          {t('dashboard.drawer.exchangeRatePanel.title')}
        </Text>
        <Paragraph color="gray500" fontSize="xsmall">
          {t('dashboard.drawer.exchangeRatePanel.description')} -{' '}
          <Link external href={t('dashboard.drawer.exchangeRatePanel.linkURL')}>
            {t('dashboard.drawer.exchangeRatePanel.linkText')}
          </Link>
        </Paragraph>
      </Box>
    </Grid>
  );
};

import { Button, Flex, Grid, Modal } from '@candisio/design-system';
import { User } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { InvitationEmailPreview } from './InvitationEmailPreview';
import { SendEmailActionCard } from './SendEmailActionCard';

export interface MobileAppInvitationModalProps {
  onCancel: () => void;
  visible?: boolean;
  user: Pick<User, 'avatarUrl' | 'email' | 'name' | 'id'>;
}

export const MobileAppInvitationModal = ({
  user,
  onCancel,
  visible = false,
}: MobileAppInvitationModalProps) => {
  const [t] = useTranslation();

  return (
    <Modal
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
      }}
      onClose={onCancel}
      isOpen={visible}
      padding="0"
      width="940px"
      height="598px"
    >
      <Flex>
        <Grid
          height="598px"
          width="50%"
          background="gray200"
          gap="space24"
          padding="space36"
          borderRadius="8px 0 0 8px"
        >
          <InvitationEmailPreview memberName={user.name} />
        </Grid>
        <Grid
          height="598px"
          width="50%"
          background="gray100"
          borderRadius="0 8px 8px 0"
        >
          <SendEmailActionCard onClose={onCancel} user={user} />
        </Grid>
        <Button
          icon="close"
          position="absolute"
          top="space12"
          label={t('common.close')}
          right="space12"
          variant="tertiary"
          onClick={onCancel}
        />
      </Flex>
    </Modal>
  );
};

import { DocumentsTableData } from '../../types';
import { SplitsCellHighlightable } from './SplitsCellHighlightable';
import { GLACellValue, useFormattedGLA } from '../../Cells/GLACell';
import { CellContext } from '@tanstack/react-table';

export const GLACellHighlightable = (
  props: CellContext<DocumentsTableData, GLACellValue>
) => {
  const formattedGLA = useFormattedGLA(props.getValue());
  return <SplitsCellHighlightable {...props} value={formattedGLA} />;
};

import { splitBookingsSchema } from 'components/Form/SplitBookingsForm/schema';
import {
  determineIsFieldEditable,
  determineIsFieldHidden,
} from 'components/Form/SplitBookingsForm/toolkit/utils';
import {
  IsFieldEditable,
  SplitBookingFields,
  SplitBookingsFormFieldOptions,
  SplitBookingsFormSchemaOptions,
  SplitDS,
} from 'components/Form/SplitBookingsForm/types';
import { useSplitBookingsContext } from 'containers/SplitBookings/SplitBookingsContext';
import { useSap } from 'orgConfig/sap';

type UseAutoFocusFieldProps = {
  isFieldEditable: IsFieldEditable;
  fieldOptions: SplitBookingsFormFieldOptions;
  schemaContext: SplitBookingsFormSchemaOptions;
};

export const SCROLL_COUNT_THRESHOLD = 5;

export const useAutoFocusField = ({
  schemaContext,
  isFieldEditable,
  fieldOptions,
}: UseAutoFocusFieldProps) => {
  const { shouldUseSapPurchaseOrder } = useSap();
  const { initialBookings, bookings, openedIndex } = useSplitBookingsContext();

  if (openedIndex > bookings.length - 1) {
    return null;
  }

  const isBookingFromPurchaseOrderLinking =
    bookings?.[openedIndex] &&
    shouldUseSapPurchaseOrder &&
    !!bookings[openedIndex].unitPrice &&
    !bookings[openedIndex].sapExpenseType;

  const isUnitPriceEditable = isFieldEditable(
    SplitBookingFields.unitPrice,
    openedIndex
  );

  if (isBookingFromPurchaseOrderLinking && isUnitPriceEditable) {
    return 'unitPrice';
  }

  // we always prioritize focusing the amount if editable
  const isAmountEditable = isFieldEditable(
    SplitBookingFields.amount,
    openedIndex
  );

  if (isAmountEditable) {
    return 'amount';
  }

  const schema = splitBookingsSchema(schemaContext);

  const booking = bookings[openedIndex];
  const res = schema.safeParse({
    ...booking,
    invoiceCorrection: (booking.amount ?? 0) < 0,
  });

  // @ts-ignore
  const fieldWithError = res.error?.issues[0].path[0] as keyof SplitDS;

  // if there is a field error, that's the second choice
  if (fieldWithError) {
    return fieldWithError;
  }

  const isEditable = determineIsFieldEditable({
    openedIndex,
    isFieldEditable,
    fieldOptions,
  });

  const isHidden = determineIsFieldHidden({
    fieldOptions,
    booking: initialBookings[openedIndex],
  });

  const fieldFocusHierarchy: Array<keyof SplitDS> = [
    'generalLedgerAccount',
    'taxCode',
    'costCenter',
    'costObject',
    'artistSocialInsuranceCode',
    'extraCostInfo',
    'postingText',
    'note',
    'dueDate',
    'quantity',
    'vatRate',
  ];

  // this field is never read only and always shows
  const fallback = 'taxPresentation';

  // first field that is shown and not read only
  return (
    fieldFocusHierarchy.find(f => isEditable[f] && !isHidden[f]) || fallback
  );
};

import { useGetFileUploadAttemptsQuery } from 'generated-types/graphql.types';

export const useGetFileUploadAttempts = () => {
  const { data, loading, refetch } = useGetFileUploadAttemptsQuery({
    fetchPolicy: 'cache-and-network',
  });

  return {
    data: data?.getFileUploadAttempts,
    loading,
    refetch,
  };
};

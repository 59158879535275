import { ErrorMessages as BaseErrorMessages } from 'utils/zodFormValidation/types';
import { reimbursementItemsFormSchema } from './reimbursementItemsFormSchema';

type ExcludeKeys =
  | 'reimbursementItems'
  | 'reimbursementItems.0.reimbursementItemId'
  | 'reimbursementItems.0.files'
  | 'reimbursementItems.0.reimbursementItemId'
  | 'reimbursementItems'
  | 'reimbursementItems.0.reimbursementItemType'
  | 'reimbursementItems.0.files'
  | 'reimbursementItems.0.itemStatus'
  | 'reimbursementItems.0.isExpenseExcluded'
  | 'reimbursementItems.0.isExtractedDataAccepted'
  | 'reimbursementItems.0.extractedData'
  | 'reimbursementItems.0.extractedData.invoiceNumber'
  | 'reimbursementItems.0.extractedData.expenseDate'
  | 'reimbursementItems.0.extractedData.receiptAmount'
  | 'reimbursementItems.0.extractedData.tipAmount'
  | 'reimbursementItems.0.extractedData.totalAmount'
  | 'reimbursementItems.0.extractedData.location'
  | 'reimbursementItems.0.segments'
  | 'reimbursementItems.0.segments.0.segmentId'
  | 'reimbursementItems.0.days.0.date'
  | 'reimbursementItems.0.days.0.dayId'
  | 'reimbursementItems.0.days.0.meals'
  | 'reimbursementItems.0.days'
  | 'reimbursementItems.0.currentStep';

/** Gets hospitality form validation error messages */
export const reimbursementItemsFormErrorMessages: BaseErrorMessages<
  typeof reimbursementItemsFormSchema,
  ExcludeKeys
> = {
  'reimbursementItems.0.files.0.id': {
    label: 'reimbursementView.middleSection.form.hospitality.file.label',
  },
  'reimbursementItems.0.files.0.name': {
    label: 'reimbursementView.middleSection.form.hospitality.file.label',
  },
  'reimbursementItems.0.files.0.url': {
    label: 'reimbursementView.middleSection.form.hospitality.file.label',
  },
  'reimbursementItems.0.reason': {
    label: 'reimbursementView.middleSection.form.hospitality.reason.label',
  },
  'reimbursementItems.0.totalAmount': {
    label: 'reimbursementView.middleSection.form.hospitality.totalAmount.label',
  },
  'reimbursementItems.0.receiptAmount': {
    label:
      'reimbursementView.middleSection.form.hospitality.receiptAmount.label',
  },
  'reimbursementItems.0.tipAmount': {
    label: 'reimbursementView.middleSection.form.hospitality.tipAmount.label',
  },
  'reimbursementItems.0.location': {
    label: 'reimbursementView.middleSection.form.hospitality.location.label',
  },
  'reimbursementItems.0.internalGuests': {
    label:
      'reimbursementView.middleSection.form.hospitality.internalGuests.label',
    custom: {
      translationKey:
        'reimbursementView.middleSection.form.hospitality.guestErr',
    },
  },
  'reimbursementItems.0.externalGuests': {
    label:
      'reimbursementView.middleSection.form.hospitality.externalGuests.label',
    custom: {
      translationKey:
        'reimbursementView.middleSection.form.hospitality.guestErr',
    },
  },
  'reimbursementItems.0.expenseDate': {
    label: 'reimbursementView.middleSection.form.general.date.label',
  },
  'reimbursementItems.0.invoiceNumber': {
    label: 'reimbursementView.middleSection.form.general.invoiceNumber.label',
  },
  'reimbursementItems.0.segments.0.endDate': {
    label: 'reimbursementView.middleSection.form.perDiem.segmentFields.endDate',
  },
  'reimbursementItems.0.segments.0.endTime': {
    label: 'reimbursementView.middleSection.form.perDiem.segmentFields.endTime',
  },
  'reimbursementItems.0.segments.0.location': {
    label:
      'reimbursementView.middleSection.form.perDiem.segmentFields.location.label',
  },
  'reimbursementItems.0.segments.0.startDate': {
    label:
      'reimbursementView.middleSection.form.perDiem.segmentFields.startDate',
  },
  'reimbursementItems.0.segments.0.startTime': {
    label:
      'reimbursementView.middleSection.form.perDiem.segmentFields.startTime',
  },
};

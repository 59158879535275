import { useOrganizationMembershipsQuery } from 'generated-types/graphql.types';
import {
  MembershipStatusType,
  membershipsQueryFilter,
} from 'hooks/useUsersFieldOptions';

interface UseOrganizationMembershipsProps {
  status?: MembershipStatusType;
}

export const useOrganizationMemberships = ({
  status,
}: UseOrganizationMembershipsProps = {}) => {
  const { data, loading } = useOrganizationMembershipsQuery({
    variables: status ? membershipsQueryFilter[status] : undefined,
  });

  const organizationMemberships = data?.organizationMemberships ?? [];

  return { loading, organizationMemberships };
};

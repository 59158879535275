import {
  APPROVED_STATUSES,
  CLOSED_STATUSES,
  DRAFT_STATUSES,
  OPEN_STATUSES,
} from 'views/Reimbursement/hooks/useReimbursementDashboardInsights';

export const summaryStatusMap = {
  approved: APPROVED_STATUSES,
  closed: CLOSED_STATUSES,
  draft: DRAFT_STATUSES,
  open: OPEN_STATUSES,
};

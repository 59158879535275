import moment from 'moment';

export const isEndingDateSmaller = (
  startingDate?: string,
  endingDate?: string,
  timeZone: string = 'Europe/Berlin'
) => {
  const momentStartingDate = moment.tz(startingDate, timeZone);
  const momentEndingDate = moment.tz(endingDate, timeZone);

  return momentEndingDate.isBefore(momentStartingDate);
};

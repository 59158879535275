import {
  CustomEmptyStateProps,
  SelectionOptions,
} from '@candisio/design-system';
import { AssociateCard } from 'components/Transactions/Table/AssociateCard/AssociateCard';
import { useLocalSortAndFilters } from 'hooks/table/useLocalSortAndFilters';
import { AnimatePresence } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoodsReceiptsTable } from 'views/Archive/GoodsReceipts/GoodsReceiptsTable/GoodsReceiptsTable';
import { useGetGoodsReceiptsTableFilterOptions } from 'views/Archive/GoodsReceipts/hooks/useGetTableFilterOptions';
import { GoodsReceiptsTableData } from 'views/Archive/GoodsReceipts/types';
import { LinkingFooterSource } from './LinkingFooter';
import { goodsReceiptsTableColumns } from './consts';
import { GoodsReceiptsEmptyState } from 'views/Archive/GoodsReceipts/GoodsReceiptsTable/EmptyState';
import { goodsReceiptsTableAvailableFilters } from 'views/Archive/GoodsReceipts/utils';
import { GoodsReceiptStatus } from 'generated-types/graphql.types';
import { useBookingsFormContext } from 'views/DocumentDetails/BookingsFormContext';
import { useLinkGoodsReceiptsToDocument } from './hooks/useLinkGoodsReceiptsToDocument';
import { AssociatedGoodsReceipt } from '../types';

type GoodsReceiptListProps = {
  documentId: string;
  accountsPayableNumber?: string | null;
  searchValue: string;
  maxSelectableItems: number;
  linkedGoodsReceipts: AssociatedGoodsReceipt[];
  onResetSearch: () => void;
  closeModal: () => void;
};

export const GoodsReceiptsList = ({
  documentId,
  accountsPayableNumber,
  searchValue,
  maxSelectableItems,
  linkedGoodsReceipts,
  onResetSearch,
  closeModal,
}: GoodsReceiptListProps) => {
  const [selectedGoodsReceipts, setSelectedGoodsReceipts] = useState<
    GoodsReceiptsTableData[]
  >([]);
  const [showMaxCountWarning, setShowMaxCountWarning] = useState(false);
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const filterOptions = useGetGoodsReceiptsTableFilterOptions();
  const { filters, sortBy, onSort, onFilter } =
    useLocalSortAndFilters<GoodsReceiptsTableData>();

  const previouslyLinkedGRCurrency = linkedGoodsReceipts[0]?.amount?.currency;

  const alreadyLinkedGoodsReceiptNumbers = useMemo(() => {
    return linkedGoodsReceipts.map(({ receiptNumber }) => receiptNumber);
  }, [linkedGoodsReceipts]);

  const { setShouldResetForm } = useBookingsFormContext();
  const { linkGoodsReceipts, isLinkingLoading } =
    useLinkGoodsReceiptsToDocument({
      documentId,
      onCompleted: () => {
        setShouldResetForm?.(true);
      },
    });

  const selectedRowIds = useMemo(
    () => selectedGoodsReceipts.map(({ id }) => id),
    [selectedGoodsReceipts]
  );
  const getRowStatus = useCallback(
    (row: GoodsReceiptsTableData) => {
      if (
        alreadyLinkedGoodsReceiptNumbers?.includes(row?.receiptNumber as string)
      ) {
        return {
          isSelectable: false,
          disabledReason: t('goodsReceipts.disabledReason.alreadyLinked'),
        };
      }
      if (row.status !== GoodsReceiptStatus.Open) {
        return {
          isSelectable: false,
          disabledReason: t('goodsReceipts.disabledReason.closed'),
        };
      }
      if (
        previouslyLinkedGRCurrency &&
        row.amount?.currency !== previouslyLinkedGRCurrency
      ) {
        return {
          isSelectable: false,
          disabledReason: t('goodsReceipts.disabledReason.currencyMismatch'),
        };
      }

      return {
        isSelectable: true,
        disabledReason: '',
      };
    },
    [t, alreadyLinkedGoodsReceiptNumbers, previouslyLinkedGRCurrency]
  );

  const selectionOptions: SelectionOptions<GoodsReceiptsTableData> =
    useMemo(() => {
      return {
        selectCheckboxProps: _row => {
          const { isSelectable, disabledReason } = getRowStatus(_row);
          if (!isSelectable) {
            return {
              isDisabled: true,
              disabledReason,
            };
          }
          return { isVisible: true };
        },
        onSelectionRowChanged: goodsReceipts => {
          if (isLinkingLoading || goodsReceipts.length > maxSelectableItems) {
            setShowMaxCountWarning(true);
            return;
          }
          setShowMaxCountWarning(false);
          setSelectedGoodsReceipts(goodsReceipts);
        },
        selectedRowsIds: [], // no need to store selecteds here with dependency on selectedGoodsReceipts
      };
    }, [isLinkingLoading, maxSelectableItems, getRowStatus]);

  const visibleColumns = useMemo(
    () =>
      accountsPayableNumber
        ? goodsReceiptsTableColumns.filter(col => col !== 'contact')
        : goodsReceiptsTableColumns,
    [accountsPayableNumber]
  );

  const handleGoodsReceiptsLinkToDocument = async () => {
    if (!selectedGoodsReceipts.length || isLinkingLoading) {
      return;
    }
    await linkGoodsReceipts(selectedGoodsReceipts);
    closeModal();
  };

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) => (
    <GoodsReceiptsEmptyState
      isTableFiltered={Boolean(filters.length)}
      isUsingSearchQuery={Boolean(searchValue)}
      resetFilters={resetFilters}
      resetSearch={onResetSearch}
    />
  );

  const defaultFilters = useMemo(() => {
    if (accountsPayableNumber) {
      return [
        ...filters,
        {
          id: goodsReceiptsTableAvailableFilters.contact,
          value: [accountsPayableNumber],
        },
      ];
    }
    return filters;
  }, [filters, accountsPayableNumber]);

  return (
    <>
      <GoodsReceiptsTable
        columns={visibleColumns}
        filterOptions={filterOptions}
        defaultFilters={defaultFilters}
        defaultSortBy={sortBy}
        searchValue={searchValue}
        onFilter={onFilter}
        onSort={onSort}
        selectionOptions={selectionOptions}
        customEmptyState={emptyState}
        showFooterText
        isSingleSelect={!accountsPayableNumber}
        selectedRowsIds={selectedRowIds}
      />
      <LinkingFooterSource>
        <AnimatePresence>
          {selectedGoodsReceipts.length > 0 && (
            <AssociateCard
              onAssociate={handleGoodsReceiptsLinkToDocument}
              footerText={t(
                'addPurchaseOrderModal.footer.footerWhenDocumentSelected'
              )}
              ctaText={t('addPurchaseOrderModal.footer.buttonCTA')}
              flex="none"
              width="100%"
              position="absolute"
              bottom={0}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: '50%', opacity: 0 },
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              loading={isLinkingLoading}
              warningText={
                !!accountsPayableNumber && showMaxCountWarning
                  ? t('addPurchaseOrderModal.footer.allowedSelectCount', {
                      maxCount: maxSelectableItems,
                    })
                  : undefined
              }
            />
          )}
        </AnimatePresence>
      </LinkingFooterSource>
    </>
  );
};

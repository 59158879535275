import baseStyled, {
  css as baseCss,
  ThemedCssFunction,
  ThemedStyledInterface,
  // biome-ignore lint/nursery/noRestrictedImports: <explanation>
} from 'styled-components';
import { Theme } from './Theme.interface';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
export { keyframes } from 'styled-components';

/**
 * @deprecated Please use the design system instead.
 * @see https://candisio.github.io/design-system
 */
export const styled = baseStyled as ThemedStyledInterface<Theme>;
/**
 * @deprecated Please use the design system instead.
 * @see https://candisio.github.io/design-system
 */
export const css = baseCss as ThemedCssFunction<Theme>;

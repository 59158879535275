import {
  Box,
  BoxProps,
  Flex,
  Grid,
  Icon,
  IconKey,
  Tag,
  Text,
  useTheme,
} from '@candisio/design-system';
import { motion } from 'motion/react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom-v5-compat';

type UserMenuItemProps = {
  onClick: () => void;
  title: string;
  description?: string;
  routerLink?: { pathname?: string; search?: string; hash?: string };
  icon?: IconKey;
  backgroundColor?: BoxProps['background'];
  dataTestId?: string;
  newBadge?: boolean;
};

const MotionBox = motion.create(Box);
const MotionFlex = motion.create(Flex);

export const UserMenuItem = memo(
  ({
    onClick,
    title,
    description,
    routerLink,
    icon,
    backgroundColor,
    newBadge,
    dataTestId,
  }: UserMenuItemProps) => {
    const [t] = useTranslation();
    const LinkComponent = routerLink ? Link : 'button';
    const { colors } = useTheme();

    const motionFlexVariants = {
      initial: {
        background: backgroundColor ? colors.bluebg : 'transparent',
        outline: 'none',
      },
      hover: {
        outline: 'none',
        background: backgroundColor ? colors.blue200 : colors.bluebg,
      },
    };

    return (
      <Grid as="li">
        <MotionFlex
          as={LinkComponent}
          alignItems="center"
          gap="space12"
          textDecoration="none"
          justifyContent={newBadge ? 'space-between' : undefined}
          borderRadius="0.5rem"
          outline="none"
          border="none"
          cursor="pointer"
          data-testid={dataTestId}
          padding={icon || description ? 'space12' : 'space8'}
          width="100%"
          to={routerLink ?? undefined}
          variants={motionFlexVariants}
          initial="initial"
          animate="initial"
          whileHover="hover"
          whileFocus="hover"
          role={LinkComponent === 'button' ? 'button' : 'link'}
          type={LinkComponent === 'button' ? 'button' : undefined}
          onClick={onClick}
        >
          {icon && (
            <MotionFlex
              background="white"
              color={backgroundColor ? 'blue700' : 'gray800'}
              padding="space10"
              borderRadius="0.5rem"
              border="space2 solid gray100"
              justifyContent="center"
              alignItems="center"
            >
              <Icon icon={icon} size="space20" />
            </MotionFlex>
          )}
          <Flex
            direction="column"
            fontSize="small"
            textAlign="left"
            flexGrow={1}
          >
            <MotionBox
              fontSize="basic"
              fontWeight="semibold"
              color={backgroundColor ? 'blue700' : 'gray800'}
            >
              {title}
            </MotionBox>
            {description && <Text color="gray500">{description}</Text>}
          </Flex>
          {newBadge && (
            <Flex alignItems="center">
              <Tag color="blue">{t('tag.new')}</Tag>
            </Flex>
          )}
        </MotionFlex>
      </Grid>
    );
  }
);

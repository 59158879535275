import {
  PerDiemItem,
  PerDiemStep,
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';
import moment from 'moment';
import { isNil } from 'lodash';
import { formatFromCents } from 'views/CreditCards/utils/formatFromCents';
import { Day, mapDay } from './mapDay';

type Segment = {
  id: string;
  startAt?: string | null;
  endAt?: string | null;
  location?: {
    id: string;
  } | null;
};

type PerDiemData = Pick<
  PerDiemItem,
  'id' | 'type' | 'status' | 'totalAmount' | 'currentStep'
> & {
  days: Day[];
  segments?: Segment[] | null;
};

export const dateTimeToTimeObject = (dateTimeString: string) => {
  return moment(dateTimeString).utc().format('HH:mm:ss');
};

type PerDiemSegment = {
  id: string;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  locationId?: string;
};

interface MappedPerDiemDay {
  dayId: string;
  date: string;
  meals: Array<'breakfast' | 'lunch' | 'dinner'>;
}

export interface PerDiem {
  id: string;
  type: ReimbursementItemType;
  status: ReimbursementItemStatus;
  totalAmount: number | null;
  currentStep: PerDiemStep;
  days?: MappedPerDiemDay[];
  segments?: PerDiemSegment[];
}

export const mapToPerDiem = (data: PerDiemData): PerDiem => {
  const converter = getDateConverted();

  return {
    id: data.id,
    status: data.status,
    type: ReimbursementItemType.PerDiem,
    currentStep: data.currentStep,
    totalAmount: !isNil(data.totalAmount?.amount)
      ? formatFromCents(data.totalAmount.currency, data.totalAmount.amount)
      : null,
    segments: (data.segments ?? [])?.map(segment => ({
      id: segment.id,
      locationId: segment.location?.id ?? undefined,
      startDate: segment.startAt
        ? converter.dateTimeStringToDateString(segment.startAt)
        : undefined,
      endDate: segment.endAt
        ? converter.dateTimeStringToDateString(segment.endAt)
        : undefined,
      startTime: segment.startAt
        ? dateTimeToTimeObject(segment.startAt)
        : undefined,
      endTime: segment.endAt ? dateTimeToTimeObject(segment.endAt) : undefined,
    })),
    days: (data.days ?? []).map(mapDay),
  };
};

import { AssociatedDocument, Maybe } from 'generated-types/graphql.types';

export const getAssociatedDocuments = (
  associatedDocuments: Maybe<Maybe<AssociatedDocument>[]>
) => {
  const purchaseOrderNumbers: string[] = [];
  const goodsReceiptNumbers: string[] = [];
  if (associatedDocuments?.length) {
    associatedDocuments.forEach(doc => {
      if (!doc) {
        return;
      }
      if (doc.__typename === 'PurchaseOrder') {
        purchaseOrderNumbers.push(doc.orderNumber);
        if (doc.goodsReceipts?.length) {
          doc.goodsReceipts.forEach(goodsReceipt => {
            goodsReceiptNumbers.push(goodsReceipt?.receiptNumber ?? '');
          });
        }
      } else if (doc.__typename === 'GoodsReceipt') {
        goodsReceiptNumbers.push(doc.receiptNumber);
        if (doc.purchaseOrders?.length) {
          doc.purchaseOrders.forEach(purchaseOrder => {
            purchaseOrderNumbers.push(purchaseOrder?.orderNumber ?? '');
          });
        }
      }
    });
  }
  const uniquePurchaseOrderNumbers = Array.from(new Set(purchaseOrderNumbers));
  const uniqueGoodsReceiptNumbers = Array.from(new Set(goodsReceiptNumbers));

  return {
    purchaseOrderNumbers: uniquePurchaseOrderNumbers,
    goodsReceiptNumbers: uniqueGoodsReceiptNumbers,
  };
};

import { DocumentNode, QueryHookOptions } from '@apollo/client';
import { Query } from 'generated-types/graphql.types';
import { debounce } from 'lodash';
import {
  PagePaginationVariables,
  PaginationResponse,
  usePagePagination,
} from 'providers/GraphQLProvider/Pagination/usePagePagination';
import { useMemo, useRef, useState } from 'react';
import { DeepPartial } from 'utility-types';
import { DEFAULT_DEBOUNCE_TIME } from './useDebouncedSearch';
import { useInfiniteScroll } from './useInfiniteScroll';

export type DebouncedSearchPaginationResponse = PaginationResponse & {
  records: [];
};

interface UseDebouncedSearchPaginationParams<
  QueryType extends DeepPartial<Query>,
  QueryVariablesType extends PagePaginationVariables,
  QueryKeyType extends keyof Omit<QueryType, '__typename'> = keyof Omit<
    QueryType,
    '__typename'
  >,
  MemoizedFn extends (searchInput?: string) => QueryVariablesType = (
    args?: string
  ) => QueryVariablesType,
> {
  query: DocumentNode;
  queryRootKey: QueryKeyType & string;
  computeVariables?: MemoizedFn;
  options?: QueryHookOptions<QueryType, QueryVariablesType>;
  initialSearchValue?: string;
}

export const useDebouncedSearchPagination = <
  QueryType extends DeepPartial<Query>,
  QueryVariablesType extends PagePaginationVariables,
  QueryKeyType extends keyof Omit<QueryType, '__typename'> = keyof Omit<
    QueryType,
    '__typename'
  >,
  MemoizedFn extends (searchInput?: string) => QueryVariablesType = (
    args?: string
  ) => QueryVariablesType,
>({
  query,
  queryRootKey,
  computeVariables,
  options,
  initialSearchValue = '',
}: UseDebouncedSearchPaginationParams<
  QueryType,
  QueryVariablesType,
  QueryKeyType,
  MemoizedFn
>) => {
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [hasMore, setHasMore] = useState(true);
  const previousData = useRef<QueryType>();

  const { data, loading, onLoadMore, ...rest } = usePagePagination<
    QueryType,
    QueryVariablesType
  >(query, queryRootKey, {
    variables: computeVariables ? computeVariables(searchValue) : undefined,
    onCompleted: data => {
      options?.onCompleted?.(data);
      previousData.current = data;
      const response = data?.[queryRootKey] as
        | DebouncedSearchPaginationResponse
        | undefined;

      const numberOfRecords = response?.records?.length ?? 0;
      const totalRecordsCount = response?.pageInfo?.totalCount ?? 0;

      setHasMore(numberOfRecords < totalRecordsCount);
    },
    ...options,
  });

  const { onScroll } = useInfiniteScroll({
    hasMore,
    next: onLoadMore,
    loading,
  });

  const loadMore = () => {
    if (!hasMore || loading) return;

    onLoadMore();
  };

  // return previous data if we are still fetching next results
  const resultData = loading ? previousData.current : data;

  const handleDebounceSearch = useMemo(() => {
    return debounce(setSearchValue, DEFAULT_DEBOUNCE_TIME);
  }, []);

  return {
    data: resultData,
    loading,
    onScroll,
    handleDebounceSearch,
    hasMore,
    loadMore,
    ...rest,
  };
};

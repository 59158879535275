import { Card, Grid, Skeleton } from '@candisio/design-system';
import { motion } from 'motion/react';

const MotionSkeleton = motion.create(Skeleton);

const skeletonVariants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

export const GridListSkeleton = () => (
  <Grid
    background="gray0"
    gap="space8"
    width="100%"
    height="100%"
    padding="space8 space16 0"
    position="absolute"
    templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
    data-cy="filterable-list-skeleton"
  >
    {Array.from({ length: 20 }, (_, index) => (
      <Card key={index} border="1px solid gray250" padding="0px">
        <Grid padding="space16" gap="space8">
          <MotionSkeleton
            variants={skeletonVariants}
            height="space20"
            width="space48"
          />
        </Grid>
        <MotionSkeleton
          variants={skeletonVariants}
          height="space48"
          width="100%"
        />
      </Card>
    ))}
  </Grid>
);

import {
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';
import { isEmpty, isNil } from 'lodash';
import { ReimbursementItemSchema } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { GeneralExpense } from 'views/Reimbursement/utils/mapToGeneralExpense';
import { HospitalityExpense } from 'views/Reimbursement/utils/mapToHospitalityExpense';
import { PerDiem } from 'views/Reimbursement/utils/mapToPerDiem';
import { v4 as uuidV4 } from 'uuid';

type SupportedReimbursementItem = GeneralExpense | HospitalityExpense | PerDiem;

const isHospitalityExpense = (
  item: SupportedReimbursementItem
): item is HospitalityExpense => {
  return item.type === ReimbursementItemType.HospitalityExpense;
};

const isGeneralExpense = (
  item: SupportedReimbursementItem
): item is GeneralExpense => {
  return item.type === ReimbursementItemType.GeneralExpense;
};

const defaultSegment = {
  segmentId: uuidV4(),
  location: null,
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
};

const toDefaultValues = (
  item: SupportedReimbursementItem
): ReimbursementItemSchema => {
  const converter = getDateConverted();

  if (isHospitalityExpense(item)) {
    return {
      reimbursementItemId: item.id,
      reason: item.reason ?? '',
      reimbursementItemType: 'hospitality',
      itemStatus: item.status,
      invoiceNumber: item.invoiceNumber ?? '',
      isExtractedDataAccepted: item.isExtractedDataAccepted,
      isExpenseExcluded: item.status === ReimbursementItemStatus.Excluded,
      expenseDate: item.expenseDate
        ? converter.dateTimeStringToDateString(item.expenseDate)
        : '',
      totalAmount: !isNil(item.totalAmount) ? item.totalAmount : null,
      receiptAmount:
        'receiptAmount' in item && !isNil(item.receiptAmount)
          ? item.receiptAmount
          : null,
      location: 'location' in item && item.location ? item.location : '',
      tipAmount:
        'tipAmount' in item && !isNil(item.tipAmount) ? item.tipAmount : null,
      internalGuests:
        'internalGuests' in item && item.internalGuests
          ? item.internalGuests
          : null,
      externalGuests:
        'externalGuests' in item && item.externalGuests
          ? item.externalGuests
          : null,
      files:
        item.files?.map(file => ({
          id: file.id ?? '',
          name: file.name ?? '',
          url: file.url ?? '',
        })) ?? [],
      extractedData: !isEmpty(item.extractedData)
        ? {
            invoiceNumber: item.extractedData?.invoiceNumber ?? '',
            location:
              'location' in item.extractedData
                ? (item.extractedData.location ?? '')
                : '',
            expenseDate: item.extractedData?.expenseDate
              ? converter.dateTimeStringToDateString(
                  item.extractedData.expenseDate
                )
              : '',
            receiptAmount:
              'receiptAmount' in item.extractedData
                ? item.extractedData.receiptAmount || null
                : null,
            tipAmount:
              'tipAmount' in item.extractedData
                ? item.extractedData.tipAmount || null
                : null,
          }
        : undefined,
    };
  }

  if (isGeneralExpense(item)) {
    return {
      reimbursementItemId: item.id,
      reason: item.reason ?? '',
      reimbursementItemType: 'general',
      itemStatus: item.status,
      isExtractedDataAccepted: item.isExtractedDataAccepted,
      isExpenseExcluded: item.status === ReimbursementItemStatus.Excluded,
      totalAmount: item.totalAmount ?? null,
      invoiceNumber: item.invoiceNumber ?? '',
      expenseDate: item.expenseDate
        ? converter.dateTimeStringToDateString(item.expenseDate)
        : '',
      files: (item.files ?? []).map(file => ({
        id: file.id ?? '',
        name: file.name ?? '',
        url: file.url ?? '',
      })),
      extractedData: !isEmpty(item.extractedData)
        ? {
            invoiceNumber: item.extractedData?.invoiceNumber ?? '',
            expenseDate: item.extractedData?.expenseDate
              ? converter.dateTimeStringToDateString(
                  item.extractedData.expenseDate
                )
              : '',
            totalAmount:
              'totalAmount' in item.extractedData
                ? item.extractedData.totalAmount || null
                : null,
          }
        : undefined,
    };
  }

  return {
    reimbursementItemId: item.id,
    reimbursementItemType: 'perDiem',
    itemStatus: item.status,
    isExpenseExcluded: item.status === ReimbursementItemStatus.Excluded,
    days: item.days?.length ? item.days : [],
    currentStep: item.currentStep,
    segments: item.segments?.length
      ? item.segments.map(segment => ({
          segmentId: segment.id,
          location: segment.locationId ?? null,
          startDate: segment.startDate ?? null,
          endDate: segment.endDate ?? null,
          startTime: segment.startTime ?? null,
          endTime: segment.endTime ?? null,
        }))
      : [defaultSegment],
  };
};

interface UseExpensesFormDefaultValuesParams {
  reimbursementItems: (GeneralExpense | HospitalityExpense | PerDiem)[];
}

export const useExpensesFormDefaultValues = ({
  reimbursementItems,
}: UseExpensesFormDefaultValuesParams) => {
  const defaultValues = {
    reimbursementItems: reimbursementItems.map(toDefaultValues),
  };

  return defaultValues;
};

import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { TruncatedText } from '@candisio/design-system/next';
import { useTranslation } from 'react-i18next';
import { Amount } from 'components/DocumentsTable/types';
import { amountFormat } from 'utils/format';
import { CellContext } from '@tanstack/react-table';
import type { WithHighlights as WithHighlightsType } from 'components/WithHighlightsCell/types';

export const AmountCell = ({
  children,
}: {
  children: string | null;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);

  if (!children) {
    return (
      <span className="float-right ml-auto text-end">
        {t('emptyCellPlaceholder')}
      </span>
    );
  }

  return <TruncatedText className="ml-auto text-end">{children}</TruncatedText>;
};

type Value = Amount | undefined;

export const AmountCellDocument = <T extends WithHighlightsType>(
  props: CellContext<T, Value>
) => {
  const value = props.getValue();
  const formattedAmount = amountFormat(value?.amount, value?.currency, {
    convertToBasicMonetaryUnit: value?.isBasicMonetaryUnit ?? false,
  });

  return <AmountCell>{formattedAmount}</AmountCell>;
};

import { Icon } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { NavLink, NavLinkProps } from 'react-router-dom-v5-compat';
import { SwimlaneTile } from '../SwimlaneTile';
import styles from './SwimlaneTileShowAll.module.css';

export const SwimlaneTileShowAll = ({ to }: { to: NavLinkProps['to'] }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.COMMON);

  return (
    <SwimlaneTile
      asChild
      className={styles.tile}
      variant="gray"
      data-cy="showallTile"
    >
      <NavLink to={to}>
        <span className={styles.link}>
          {t('common.showAll')} <Icon icon="arrowRight" size={16} />
        </span>
      </NavLink>
    </SwimlaneTile>
  );
};

import { useNavigate } from 'react-router-dom-v5-compat';
import { StatementDetailsContainer } from './StatementDetailsContainer';
import { StatementDetailsDrawerLayout } from './StatementDetailsDrawerLayout';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';

export const StatementDetailsDrawer = () => {
  const navigate = useNavigate();
  const { statementId } = useParams<{ statementId: string }>();

  const isOpen = !!statementId;

  const goBack = () => {
    navigate(-1);
  };

  return (
    <StatementDetailsDrawerLayout onClose={goBack} isOpen={isOpen}>
      <StatementDetailsContainer />
    </StatementDetailsDrawerLayout>
  );
};

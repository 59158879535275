import { create } from 'zustand';
import { AtomicStore, AtomicStoreReturn } from './createAtomicStore.types';

export const createAtomicStore = <T>(initialValue: T): AtomicStoreReturn<T> => {
  const store = create<AtomicStore<T>>(set => ({
    setValue: nextValue =>
      set(({ value: currentValue }) => ({
        value:
          nextValue instanceof Function ? nextValue(currentValue) : nextValue,
      })),
    value: initialValue,
  }));

  return [
    () => store(state => state.value),
    store.getState().setValue,
    () => store.getState().setValue(initialValue),
  ];
};

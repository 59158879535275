import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { DocumentSubmitWorkflowStepErrorsFragment } from 'generated-types/graphql.types';
import i18next from 'i18next';
import { getTranslationContext } from 'orgConfig';
import { FieldPath } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getProjectCodeReadableName } from 'views/Settings/ProjectCodes/utils/readableName';

type DocumentApprovalErrors = Partial<
  Record<FieldPath<DocumentApprovalFormValues> | 'root', string>
>;

export const getDocumentApprovalErrorMessages = (
  t: i18next.TFunction,
  response: DocumentSubmitWorkflowStepErrorsFragment
): DocumentApprovalErrors | undefined => {
  if (!response?.__typename) {
    return;
  }

  const errorMessages: DocumentApprovalErrors = {};

  // There's not a nicer way to do this without losing the type saftey of only
  // having access to the properties on the fragments on `documentWorkflowStepSubmitUnionResponse`
  for (const error of response.errors) {
    switch (error.__typename) {
      case 'NotCurrentWorkflowStepError':
        errorMessages.root = t(
          'document.approveDocument.submitErrors.workflowStep.notCurrentStep'
        );
        break;

      case 'WorkflowStepAlreadyApprovedError':
        errorMessages.root = t(
          'document.approveDocument.submitErrors.workflowStep.alreadyApproved',
          { name: error.approvedByUser.name }
        );
        break;

      case 'BookingsError':
        switch (error.error.__typename) {
          case 'BookingKeyArchivedError':
            errorMessages[`bookings.${error.index}.taxCode`] = t(
              'document.approveDocument.submitErrors.bookingKey.isArchived',
              { name: error.error.bookingKey.readableName }
            );
            break;

          case 'CostCenterArchivedError':
            errorMessages[`bookings.${error.index}.costCenter.value`] = t(
              'document.approveDocument.submitErrors.costCenter.isArchived',
              {
                name: error.error.costCenter.readableName,
                context: getTranslationContext(),
              }
            );

            break;

          case 'CostCenterRequiredError':
            errorMessages[`bookings.${error.index}.costCenter.value`] = t(
              'document.approveDocument.submitErrors.costCenter.isRequired',
              { context: getTranslationContext() }
            );
            break;

          case 'CostObjectArchivedError':
            errorMessages[`bookings.${error.index}.costObject.value`] = t(
              'document.approveDocument.submitErrors.costObject.isArchived',
              {
                name: error.error.costObject.readableName,
                context: getTranslationContext(),
              }
            );
            break;

          case 'CostObjectRequiredError':
            errorMessages[`bookings.${error.index}.costObject.value`] = t(
              'document.approveDocument.submitErrors.costObject.isRequired',
              { context: getTranslationContext() }
            );

            break;

          case 'ExtraCostInfoArchivedError':
            errorMessages[`bookings.${error.index}.extraCostInfo.value`] = t(
              'document.approveDocument.submitErrors.extraCostInfo.isArchived',
              { name: error.error.extraCostInfo.readableName }
            );
            break;

          case 'GeneralLedgerArchivedError':
            errorMessages[
              `bookings.${error.index}.generalLedgerAccount.value`
            ] = t(
              'document.approveDocument.submitErrors.generalLedgerAccount.isArchived',
              { name: error.error.generalLedgerAccount.readableName }
            );
            break;

          case 'DocumentAcquisitionReversalValidationError': {
            errorMessages[`bookings.${error.index}.taxCode`] = t(
              'document.requestApproval.submitErrors.bookingKey.notAcquisitionReverse'
            );

            break;
          }
          case 'ProjectCodeDoesNotExistError': {
            errorMessages[`bookings.${error.index}.projectCode.value`] = t(
              'document.requestApproval.submitErrors.projectCode.doesNotExist'
            );

            break;
          }
          case 'ProjectCodeArchivedError': {
            errorMessages[`bookings.${error.index}.projectCode.value`] = t(
              'document.requestApproval.submitErrors.projectCode.isArchived',
              { name: getProjectCodeReadableName(error.error.projectCode) }
            );
            break;
          }

          default:
            if (!errorMessages.root) {
              errorMessages.root = error.error.message;
            }

            break;
        }

        break;

      default:
        if (!errorMessages.root) {
          errorMessages.root = error.message;
        }
    }
  }

  return Object.keys(errorMessages).length ? errorMessages : undefined;
};

export const useDocumentApprovalErrorMessages = () => {
  const [t] = useTranslation();

  const getDocumentApprovalErrors = (
    response: DocumentSubmitWorkflowStepErrorsFragment
  ) => getDocumentApprovalErrorMessages(t, response);

  return { getDocumentApprovalErrors };
};

import { ToggleButton } from 'react-aria-components';
import styles from './ToggleButtonGroup.module.css';
import { ReactNode } from 'react';
import clsx from 'clsx';

interface ToggleButtonItemProps {
  id: string;
  children: ReactNode;
  className?: string;
}

export const ToggleButtonItem = ({
  id,
  children,
  className,
  ...props
}: ToggleButtonItemProps) => {
  return (
    <ToggleButton
      {...props}
      id={id}
      className={clsx(styles['toggle-button'], className)}
    >
      {children}
    </ToggleButton>
  );
};

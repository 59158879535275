import { CSSObject, Interpolation } from '@emotion/react';
import { isEmpty } from 'lodash';
import { CSSProperties, ElementType, forwardRef } from 'react';
import { mergeProps, useHover } from 'react-aria';
import {
  Box as PolymorphicBox,
  PolymorphicComponentProps,
} from 'react-polymorphic-box';
import {
  BorderProps,
  ColorProps,
  LayoutProps,
  PaddingProps,
  TransitionProps,
  TypographyProps,
} from '../../types';
import { useMapTokensToCSS } from './useMapTokensToCSS';

const DEFAULT_ELEMENT = 'div';

interface BoxStyleProps
  extends LayoutProps,
    BorderProps,
    ColorProps,
    PaddingProps,
    TransitionProps,
    TypographyProps {
  /** Properties applied when box is hovered */
  hover?: BoxStyleProps;
}

interface BoxOwnProps extends BoxStyleProps {
  /** HTML element to render */
  as?: keyof JSX.IntrinsicElements;
  /** Mouse cursor to display  */
  cursor?: CSSProperties['cursor'];
}

export type BoxProps<TElement extends ElementType = typeof DEFAULT_ELEMENT> =
  PolymorphicComponentProps<TElement, BoxOwnProps>;

type BoxType = <TElement extends ElementType = typeof DEFAULT_ELEMENT>(
  props: BoxProps<TElement>
) => React.ReactElement | null;

/**
 *
 * @deprecated Use CSS modules or tailwind instead. @example `<div className="relative">...</div>`
 *
 * Box is a general-purpose, low-level container with no specific semantics that
 * can be used for custom styling purposes.
 */
export const Box = forwardRef(
  <TElement extends ElementType>(
    props: BoxProps<TElement>,
    ref: typeof props.ref
  ) => {
    const {
      alignSelf,
      background,
      border,
      borderBottom,
      borderBottomLeftRadius,
      borderBottomRadius,
      borderBottomRightRadius,
      borderLeft,
      borderRadius,
      borderRight,
      borderTop,
      borderTopLeftRadius,
      borderTopRadius,
      borderTopRightRadius,
      bottom,
      boxShadow,
      color,
      cursor,
      flex,
      flexBasis,
      flexGrow,
      flexShrink,
      fontFamily,
      fontSize,
      fontStyle,
      fontWeight,
      gridArea,
      gridColumn,
      gridColumnEnd,
      gridColumnStart,
      gridRow,
      gridRowEnd,
      gridRowStart,
      height,
      hover = {},
      inset,
      justifySelf,
      left,
      lineHeight,
      listStyle,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      opacity,
      order,
      overflow,
      overflowWrap,
      overflowX,
      overflowY,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingX,
      paddingY,
      position = 'relative',
      right,
      scrollbarGutter = 'auto',
      textAlign,
      textDecoration,
      textTransform,
      top,
      transition,
      transitionDelay,
      transitionDuration,
      transitionTimingFunction,
      verticalAlign,
      visibility,
      whiteSpace,
      width,
      wordBreak,
      zIndex,
      ...restProps
    } = useMapTokensToCSS(props);

    // map these props directly to the CSS equivalent
    const baseStyle: CSSObject = {
      alignSelf,
      background,
      border,
      borderBottom,
      borderLeft,
      borderRight,
      borderTop,
      bottom,
      boxShadow,
      color,
      cursor,
      flex,
      flexBasis,
      flexGrow,
      flexShrink,
      fontFamily,
      fontSize,
      fontStyle,
      fontWeight,
      gridArea,
      gridColumn,
      gridColumnEnd,
      gridColumnStart,
      gridRow,
      gridRowEnd,
      gridRowStart,
      height,
      inset,
      justifySelf,
      left,
      lineHeight,
      listStyle,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      opacity,
      order,
      overflow,
      overflowWrap,
      overflowX,
      overflowY,
      position,
      right,
      scrollbarGutter,
      textAlign,
      textDecoration,
      textTransform,
      top,
      transition,
      transitionDelay,
      transitionDuration,
      transitionTimingFunction,
      verticalAlign,
      visibility,
      whiteSpace,
      width,
      wordBreak,
      zIndex,
    };

    const hoverStyle: CSSObject = { ':hover': useMapTokensToCSS(hover) };

    const { isHovered, hoverProps } = useHover({ isDisabled: isEmpty(hover) });

    // more specific border radius props take precedence:
    // 1 corner > 2 corners > all corners
    const borderRadiusStyle: Interpolation<any> = [
      {
        borderRadius,
      },
      borderBottomRadius !== undefined && {
        borderBottomRightRadius: borderBottomRadius,
        borderBottomLeftRadius: borderBottomRadius,
      },
      borderTopRadius !== undefined && {
        borderTopRightRadius: borderTopRadius,
        borderTopLeftRadius: borderTopRadius,
      },
      {
        borderBottomLeftRadius: borderBottomLeftRadius,
        borderBottomRightRadius: borderBottomRightRadius,
        borderTopLeftRadius: borderTopLeftRadius,
        borderTopRightRadius: borderTopRightRadius,
      },
    ];

    // more specific padding props take precedence:
    // 1 side > 2 sides > all sides
    const paddingStyle: Interpolation<any> = [
      { padding },
      paddingX !== undefined && {
        paddingLeft: paddingX,
        paddingRight: paddingX,
      },
      paddingY !== undefined && {
        paddingTop: paddingY,
        paddingBottom: paddingY,
      },
      {
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
      },
    ];

    const mergedProps = mergeProps(hoverProps, restProps) || {};

    // @TODO better CSS reset
    const cssReset: CSSObject = {
      boxSizing: 'border-box',
      margin: 'var(--box-margin, 0)',
    };

    return (
      <PolymorphicBox
        css={[
          cssReset,
          baseStyle,
          borderRadiusStyle,
          paddingStyle,
          isHovered && hoverStyle,
        ]}
        as={DEFAULT_ELEMENT}
        ref={ref}
        {...mergedProps}
      />
    );
  }
) as BoxType;

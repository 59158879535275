import { Warning } from 'components/Icons/Warning';
import {
  CharacterCount,
  CharacterCounterWrapper,
  CharacterWarningText,
} from './style';

type Props = {
  characterLength: number;
  maxCharacters?: number;
  maxCharactersWarning?: string;
};

export const CharacterCounter = ({
  characterLength,
  maxCharacters,
  maxCharactersWarning,
}: Props) => {
  if (!maxCharacters) {
    return null;
  }

  const showMaxCharactersWarning = characterLength > maxCharacters;

  return (
    <CharacterCounterWrapper showWarning={showMaxCharactersWarning}>
      {maxCharactersWarning ? (
        <CharacterWarningText>
          <Warning />
          {maxCharactersWarning}
        </CharacterWarningText>
      ) : null}
      <CharacterCount>{`${characterLength}/${maxCharacters}`}</CharacterCount>
    </CharacterCounterWrapper>
  );
};

import {
  FileUploadAttempt,
  FileUploadAttemptStatus,
  useDeleteFileUploadAttemptMutation,
  useSoftDeleteFileUploadByFileIdMutation,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { UploadHistoryItemError } from './UploadHistoryItemError';
import { UploadHistoryItemPending } from './UploadHistoryItemPending';
import { UploadHistoryItemSuccess } from './UploadHistoryItemSuccess';

type Props = {
  item: FileUploadAttempt;
};

export const UploadHistoryItem = ({ item }: Props) => {
  const [t] = useTranslation();
  const [deleteUploadAttempt] = useDeleteFileUploadAttemptMutation({
    refetchQueries: ['getFileUploadAttempts'],
  });
  const [softDeleteByFileId] = useSoftDeleteFileUploadByFileIdMutation();

  const handleDelete = () => {
    softDeleteByFileId({ variables: { id: item.file.id } });
    deleteUploadAttempt({
      variables: {
        input: {
          id: item.id,
        },
      },
    });
  };

  if (item.wasDeleted || item.status === FileUploadAttemptStatus.Failed) {
    const error = item.wasDeleted
      ? t('common:deleted')
      : (item.errorMessages?.[0] ?? '');

    return (
      <UploadHistoryItemError
        fileName={item.file.name}
        error={error}
        onRemove={handleDelete}
      />
    );
  }

  if (item.status === FileUploadAttemptStatus.DocumentDataExtracted) {
    return <UploadHistoryItemSuccess item={item} onRemove={handleDelete} />;
  }

  return (
    <UploadHistoryItemPending fileName={item.file.name} status={item.status} />
  );
};

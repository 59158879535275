import { New_DatevSettingsQuery } from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { BDSTestConnectionContainer } from './DcsContainers/BDSTestConnectionContainer';
import { ConnectToDatevContainer } from './DcsContainers/ConnectToDatevContainer/ConnectToDatevContainer';
import { ConnectedClientDetailsContainer } from './DcsContainers/ConnectedClientDetailsContainer/ConnectedClientDetailsContainer';
import { SelectClientContainer } from './DcsContainers/SelectClientContainer/SelectClientContainer';
import { useDatevAuthorizeForClients } from './datevAuthHooks';

interface DatevConnectionContainerProps {
  hasConnectedClient: boolean | undefined;
  hasAuthTokenForReadClients: boolean | undefined;
  settingsData: New_DatevSettingsQuery | undefined;
  isDatevReconnecting?: boolean;
}

export const DatevConnectionContainer = ({
  hasConnectedClient,
  hasAuthTokenForReadClients,
  settingsData,
  isDatevReconnecting,
}: DatevConnectionContainerProps) => {
  const [authorizeForClients, { loading: authLoadingForClients }] =
    useDatevAuthorizeForClients();

  const { bdsConnected } = useDatev();

  return (
    <>
      {!hasConnectedClient &&
        !hasAuthTokenForReadClients &&
        !isDatevReconnecting && (
          <ConnectToDatevContainer
            settingsData={settingsData}
            authLoadingForClients={authLoadingForClients}
            authorizeForClients={authorizeForClients}
          />
        )}
      {!hasConnectedClient && hasAuthTokenForReadClients && (
        <SelectClientContainer
          settingsData={settingsData}
          onReconnect={authorizeForClients}
          reconnecting={authLoadingForClients}
        />
      )}
      {hasConnectedClient &&
        (bdsConnected ? (
          <BDSTestConnectionContainer settingsData={settingsData} />
        ) : (
          <ConnectedClientDetailsContainer settingsData={settingsData} />
        ))}
    </>
  );
};

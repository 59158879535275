import { useGetTransactionHistoryQuery } from 'generated-types/graphql.types';

export const useTransactionHistory = (transactionId: string) => {
  const { loading, data } = useGetTransactionHistoryQuery({
    variables: { id: transactionId },
    fetchPolicy: 'cache-and-network',
  });

  const transaction = data?.getCardIssuerTransactionById;

  return { loading, transaction };
};

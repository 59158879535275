import { Button, Link, useModal } from '@candisio/design-system';
import { EInvoiceValidationDetailsModal } from 'components/EInvoiceErrorDetails/EInvoiceValidationDetailsModal';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';
import { useTranslation } from 'react-i18next';

export interface EInvoiceErrorDetailsButtonProps {
  buttonType: 'link' | 'button';
  documentId: string;
}

export const EInvoiceErrorDetailsButton = ({
  buttonType,
  documentId,
}: EInvoiceErrorDetailsButtonProps) => {
  const [t] = useTranslation();

  const { track } = useAnalytics();

  const {
    modalProps,
    open: openEInvoiceValidationProtocolModal,
    close: closeEInvoiceValidationProtocolModal,
  } = useModal();

  const onOpenEInvoiceValidationProtocolModal = () => {
    track(TrackingEvents.ERROR_VIEWED, {
      event_location: 'e_invoice_error',
      document_id: documentId,
    });
    openEInvoiceValidationProtocolModal();
  };

  return (
    <>
      {buttonType === 'link' ? (
        <Link onClick={onOpenEInvoiceValidationProtocolModal}>
          {t('document.compact.history.invalidEInvoice.showDetails')}
        </Link>
      ) : (
        <Button
          size="medium"
          variant="secondary"
          onClick={onOpenEInvoiceValidationProtocolModal}
        >
          {t('document.compact.history.invalidEInvoice.showDetails')}
        </Button>
      )}
      <EInvoiceValidationDetailsModal
        modalProps={modalProps}
        documentId={documentId}
        onCloseModal={closeEInvoiceValidationProtocolModal}
      />
    </>
  );
};

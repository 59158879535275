import { Button, Grid, Tag, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { customStyle } from './GeneralExpenseInvoiceData';
import { extractedDataHospitalitySummary } from './utils/extractedDataSummary';
import styles from './ReimbursementItemsSummary.module.css';
import clsx from 'clsx';

type ExtractedData = ReturnType<typeof extractedDataHospitalitySummary>;

const DATA_FIELDS: Array<{ key: keyof ExtractedData; label: string }> = [
  {
    key: 'location',
    label: 'reimbursementView.middleSection.form.extractedData.location',
  },
  {
    key: 'expenseDate',
    label: 'reimbursementView.middleSection.form.extractedData.date',
  },
  {
    key: 'invoiceNumber',
    label: 'reimbursementView.middleSection.form.extractedData.invoiceNumber',
  },
  {
    key: 'receiptAmount',
    label: 'reimbursementView.middleSection.form.extractedData.receiptAmount',
  },
  {
    key: 'tipAmount',
    label: 'reimbursementView.middleSection.form.extractedData.tipAmount',
  },
  {
    key: 'totalAmount',
    label: 'reimbursementView.middleSection.form.extractedData.grossAmount',
  },
];

interface HospitalityExpenseInvoiceDataProps {
  canEditInvoiceData: boolean;
  onEditClick: () => void;
  extractedData?: ExtractedData;
}

export const HospitalityExpenseInvoiceData = ({
  canEditInvoiceData,
  onEditClick,
  extractedData,
}: HospitalityExpenseInvoiceDataProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const classes = clsx(
    'p-4',
    'border',
    'border-gray-250',
    styles.extractedData,
    canEditInvoiceData ? 'rounded-b-none' : 'rounded-b-[theme(radius.medium)]'
  );

  return (
    <div>
      <div className={classes}>
        <Tag color="blue" variant="secondary" size="small">
          {t('reimbursementView.middleSection.form.extractedData.tag')}
        </Tag>

        {DATA_FIELDS.map(({ key, label }) => {
          const data = extractedData?.[key];
          if (!data) return null;

          return (
            <Grid key={key}>
              <TruncatedText color="gray600">{t(label)}</TruncatedText>
              <TruncatedText fontSize="basic">{data}</TruncatedText>
            </Grid>
          );
        })}
      </div>

      {canEditInvoiceData && (
        <div className={styles.editAction}>
          <Button
            icon="edit"
            size="small"
            variant="tertiary"
            onClick={onEditClick}
            style={customStyle}
          >
            {t('reimbursementView.middleSection.form.extractedData.edit')}
          </Button>
        </div>
      )}
    </div>
  );
};

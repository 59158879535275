import { Children, ReactNode, cloneElement, isValidElement } from 'react';
import { WrappedStateMode } from './types';

type Props = {
  mode: WrappedStateMode;
  renderProps?: any;
  children: ReactNode;
};

export const AccessModeWrapper = ({
  children,
  mode,
  renderProps = {},
}: Props) => {
  return (
    <>
      {Children.map(children, child => {
        if (!isValidElement(child)) {
          return child;
        }

        return cloneElement(child, {
          [mode]: true,
          ...renderProps,
        });
      })}
    </>
  );
};

import {
  DocumentCurrency,
  GeneralExpenseItem,
  HospitalityExpenseItem,
  ReimbursementItemStatus,
  ReimbursementItemType,
  ReimbursementItemsSplitBookingsQuery,
  useReimbursementItemsSplitBookingsQuery,
  PerDiemItem,
} from 'generated-types/graphql.types';
import { formatIntegerAmountToDecimal } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';

export interface BookingItem {
  id: string;
  splitAmount: number;
  vatRate: number | undefined;
  taxCode: string | undefined;
  costCenter: string | undefined;
  costObject: string | undefined;
}

interface ReimbursementItemSplitBooking {
  id: string;
  title: string | undefined;
  status: ReimbursementItemStatus;
  type: ReimbursementItemType;
  totalAmount: number;
  currency: DocumentCurrency;
  bookings: BookingItem[];
}

type AllItemsFragments =
  ReimbursementItemsSplitBookingsQuery['reimbursementItemsSplitBookings'][number];

type ExpenseFragment =
  | GeneralExpenseItem
  | HospitalityExpenseItem
  | PerDiemItem;

export const useReimbursementSplitBookingsData = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUseReimbursement } = useReimbursement();

  const shouldSkipQuery = !reimbursementId || !canUseReimbursement;

  const { data, loading: loadingSplits } =
    useReimbursementItemsSplitBookingsQuery({
      variables: { filters: { reimbursementCaseId: reimbursementId } },
      skip: shouldSkipQuery,
      fetchPolicy: 'network-only',
    });

  const fetchedItems = data?.reimbursementItemsSplitBookings ?? [];

  const filteredExpensesItems = fetchedItems.filter(
    (item: AllItemsFragments): item is ExpenseFragment => {
      return (
        item.__typename === 'GeneralExpenseItem' ||
        item.__typename === 'HospitalityExpenseItem' ||
        item.__typename === 'PerDiemItem'
      );
    }
  );

  const reimbursementItemSplitBookings: ReimbursementItemSplitBooking[] = (
    filteredExpensesItems ?? []
  ).map(item => ({
    id: item.id,
    type: item.type,
    status: item.status,
    title: item.title ?? undefined,
    currency: item.totalAmount?.currency ?? DocumentCurrency.Eur,
    totalAmount: !isNil(item.totalAmount?.amount)
      ? formatIntegerAmountToDecimal(
          item.totalAmount?.amount,
          item.totalAmount?.precision
        )
      : 0,
    bookings: (item.bookings ?? []).map(booking => ({
      id: booking.id,
      splitAmount: booking.amount?.value ?? 0,
      vatRate: booking.vatRate?.value ?? undefined,
      taxCode: booking.bookingKey?.value.readableName ?? undefined,
      costCenter: booking.costCenter
        ? booking.costCenter.value.readableName
        : undefined,
      costObject: booking.costObject
        ? booking.costObject.value.readableName
        : undefined,
    })),
  }));

  return {
    loadingSplits,
    reimbursementItemSplitBookings,
  };
};

import { Image } from '@candisio/design-system';
import { CardBrand, CardType } from 'generated-types/graphql.types';
import { cardTypes } from 'views/CreditCards/CardManagerView/components/CreditCardsTable/Cells/CardNumberCell';

export const CreditCardImage = ({
  type,
  brand,
}: { type?: CardType; brand?: CardBrand }) => {
  if (!type || !brand) {
    return null;
  }

  const isCandisBranded = brand === CardBrand.Candis;

  return (
    <Image
      src={isCandisBranded ? cardTypes[type].candis : cardTypes[type].pliant}
      height="2rem"
      alt=""
      data-cy={isCandisBranded ? 'candis-card-image' : 'pliant-card-image'}
    />
  );
};

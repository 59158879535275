import {
  Box,
  Button,
  Drawer,
  Flex,
  Heading,
  Link,
} from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useOtherIntegration } from 'orgConfig/other';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TaxCodeChange } from 'views/Settings/TaxCodes/components/TaxCodeDetails/TaxCodeChange';
import { useTaxCodes } from 'views/Settings/TaxCodes/useTaxCodes';
import { useTaxCodesNavigation } from 'views/Settings/TaxCodes/useTaxCodesNavigation';
import { useSap } from '../../../../../orgConfig/sap';
import { refetchBookingKeyQueries } from '../../utils';

export interface BookingKeyDetailsProps {
  hasActiveBookingAccounts: boolean;
  goToBookingAccounts: () => void;
  isOpen?: boolean;
}

export const TaxCodesDetails = ({
  hasActiveBookingAccounts,
  goToBookingAccounts,
}: BookingKeyDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TAX_CODES);
  const { success, dismiss } = useToastMessage();
  const [tCommon] = useTranslation();
  const {
    handleCreate,
    handleUpdate,
    archiveBookingKey,
    activateBookingKey,
    archiveLoading,
    activateLoading,
    bookingKeyLoading,
    bookingKey,
  } = useTaxCodes();

  const { navigateBackToList, navigateToBookingKey, bookingKeyId } =
    useTaxCodesNavigation();

  const { isActiveIntegration: shouldUseSapTaxCodes } = useSap();
  const { shouldUseCoreDataApiTaxCodes } = useOtherIntegration();

  const isCreateAction = bookingKeyId === 'create' || !bookingKeyId;

  const handleStatus = useCallback(
    async (isArchived: boolean) => {
      if (!bookingKey?.id) return;

      const mutationProps = {
        variables: {
          id: bookingKey?.id,
        },
        refetchQueries: refetchBookingKeyQueries(),
      };

      if (!isArchived) {
        await activateBookingKey(mutationProps);
      } else {
        await archiveBookingKey(mutationProps);
      }

      const { taxCode, id } = bookingKey;

      const translationKey = isArchived
        ? 'form.actions.toasts.archivedSuccess'
        : 'form.actions.toasts.activatedSuccess';

      const content = (
        <Trans t={t} i18nKey={translationKey} values={{ taxCode }}>
          Booking key
          <Link
            onClick={(_e: MouseEvent<HTMLAnchorElement>) => {
              dismiss();
              navigateToBookingKey(id, isArchived);
            }}
          >
            {{ taxCode } as any}
          </Link>
          status was changed.
        </Trans>
      );

      success(content, 7);
      navigateBackToList();
    },
    [
      activateBookingKey,
      archiveBookingKey,
      bookingKey,
      dismiss,
      navigateBackToList,
      navigateToBookingKey,
      success,
      t,
    ]
  );

  const isTaxCodeArchived = Boolean(bookingKey?.id && bookingKey?.isArchived);
  const isFormReadonly =
    isTaxCodeArchived || shouldUseSapTaxCodes || shouldUseCoreDataApiTaxCodes;

  const titleForEditableViews = isCreateAction ? 'details.add' : 'details.edit';

  const titleName = isFormReadonly ? 'details.view' : titleForEditableViews;

  return (
    <Drawer isOpen={!!bookingKeyId} onClose={navigateBackToList}>
      <Flex
        borderBottom="1px solid gray300"
        alignItems="center"
        justifyContent="space-between"
        padding="space16 space40 space8"
      >
        <Heading as="h3">{t(titleName)}</Heading>
        <Button
          icon="close"
          label={tCommon('common.close')}
          onClick={navigateBackToList}
          zIndex={10}
          variant="tertiary"
        />
      </Flex>
      <Box paddingX="space40" paddingY="space24" height="100%">
        <TaxCodeChange
          loading={bookingKeyLoading}
          onChange={isCreateAction ? handleCreate : handleUpdate}
          onStatusChange={handleStatus}
          updateStatusLoading={archiveLoading || activateLoading}
          bookingKey={bookingKey}
          hasActiveBookingAccounts={hasActiveBookingAccounts}
          goToBookingAccounts={goToBookingAccounts}
        />
      </Box>
    </Drawer>
  );
};

import { useGetProjectCodeImportHistoryByIdQuery } from 'generated-types/graphql.types';
import { useMemo } from 'react';
import {
  ImportHistoryDetailsData,
  ProjectCodeImportHistoryItemData,
} from '../types';

type ProjectCodeImportItem = {
  item: ProjectCodeImportHistoryItemData;
  errorData: ImportHistoryDetailsData[];
};

export const useProjectCodeImportItemError = (id?: string) => {
  const { data, loading } = useGetProjectCodeImportHistoryByIdQuery({
    variables: {
      id: id ?? '',
    },
    skip: !id,
  });

  const importHistoryItem: ProjectCodeImportItem = useMemo(() => {
    if (!data?.importHistoryById)
      return {
        item: {
          id: '',
          username: '',
          createdAt: '',
          failedCounts: 0,
          successfullyCreatedEntitiesCount: 0,
          successfullyUpdatedEntitiesCount: 0,
          apiErrorMessage: '',
        },
        errorData: [],
      };

    const failedImportItem = data.importHistoryById;

    const errorData = (failedImportItem.failedEntities || [])
      .map(failedEntity => {
        if (failedEntity?.__typename === 'ProjectCodeFailedEntity') {
          return {
            id: failedEntity.rawEntity?.code || '',
            code: failedEntity.rawEntity?.code || '',
            name: failedEntity.rawEntity?.name || '',
            error: failedEntity.errorDetails?.[0]?.formattedErrorMessage || '',
          };
        }
        return null;
      })
      .filter(Boolean) as ImportHistoryDetailsData[];

    return {
      item: {
        id: failedImportItem?._id || '',
        username: failedImportItem?.createdBy?.name || '',
        createdAt: failedImportItem?.createdAt || '',
        failedCounts: failedImportItem?.failedEntities?.length || 0,
        successfullyCreatedEntitiesCount:
          failedImportItem?.successfullyCreatedEntitiesCount || 0,
        successfullyUpdatedEntitiesCount:
          failedImportItem?.successfullyUpdatedEntitiesCount || 0,
        apiErrorMessage:
          failedImportItem?.importErrorDetails?.formattedErrorMessage || '',
      },
      errorData,
    };
  }, [data]);

  return { importHistoryItem, loading };
};

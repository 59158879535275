import { Grid, HeaderProps, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { EcmDocumentsTableData } from '../EcmDocumentsTable/types';

export const TagsHeader = ({
  isFilteredOrSorted,
}: HeaderProps<EcmDocumentsTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  return (
    <Grid autoFlow="column" gap="space5" alignItems="center">
      <TruncatedText
        wordBreak="break-all"
        color={isFilteredOrSorted ? 'gray800' : 'gray500'}
      >
        {t('columns.tags')}
      </TruncatedText>
    </Grid>
  );
};

import { CSSObject, css } from '@emotion/react';
import React from 'react';
import { useTheme } from '../../Theme';
import { Box, BoxProps } from '../Box';

const DEFAULT_ELEMENT = 'div';

export type ScrollBoxProps<
  TElement extends React.ElementType = typeof DEFAULT_ELEMENT,
> = BoxProps<TElement> & {
  scrollDirection?: 'x' | 'y' | 'both' | 'none';
  applyHack?: boolean;
};

type ScrollBoxType = <
  TElement extends React.ElementType = typeof DEFAULT_ELEMENT,
>(
  props: ScrollBoxProps<TElement>
) => React.ReactElement | null;

/**
 * A Box component that applies our custom scrollbar styling and shows
 * scrollbars only on hover.
 *
 * [Storybook]{@link (https://candisio.github.io/design-system/?path=/docs/atoms-scrollbox)}
 *
 * @param {'x' | 'y' | 'both' | 'none'} scrollDirection Sets the direction of scrolling
 */
export const ScrollBox = React.forwardRef(
  (
    {
      scrollDirection = 'both',
      applyHack = false,
      ...restProps
    }: ScrollBoxProps<ScrollBoxType>,
    ref: React.ElementRef<ScrollBoxType>
  ) => {
    const { colors, space, radii } = useTheme();

    // implementation based on suggestions found here: https://stackoverflow.com/questions/19230289/use-transition-on-webkit-scrollbar/57483486#57483486
    const hoverScrollbar: CSSObject = {
      transition: 'background 0.2s',
      borderColor: 'transparent',

      '&::-webkit-scrollbar': {
        borderColor: 'inherit',
        borderRadius: radii.small,
        width: space.space8,
        height: space.space8,
        borderBottomStyle: 'inset',
        borderBottomWidth: space.space8,
        borderRightStyle: 'inset',
        borderRightWidth: space.space8,

        backgroundColor: '#00000000',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: radii.small,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'inherit',
        borderColor: 'transparent',
        transition: 'background-color 0.2s',
        borderRadius: radii.small,
        borderBottomStyle: 'inset',
        borderBottomWidth: space.space8,
        borderRightStyle: 'inset',
        borderRightWidth: space.space8,
      },
      '&::-webkit-scrollbar-corner': {
        backgroundColor: 'transparent',
      },

      '&:hover': {
        borderColor: 'transparent',

        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#0000001A',
        },
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#00000033',
      },
    };

    const firefoxScrollOverrides = css`
      @supports (-moz-appearance: none) {
        scrollbar-width: thin;
        scrollbar-color: ${colors.gray300};
      }
    `;

    const firefoxScrollOverridesHack = css`
      // cursed: Chrome browser renders scrollbars outside component's border
      // overriding scrollbar padding here to keep the appearance in line
      @supports (-moz-appearance: none) {
        padding-right: ${space.space8};
      }
    `;

    return (
      <Box
        as={DEFAULT_ELEMENT}
        css={[
          hoverScrollbar,
          firefoxScrollOverrides,
          applyHack && firefoxScrollOverridesHack,
        ]}
        overflow={scrollDirection === 'both' ? 'auto' : 'hidden'}
        overflowX={scrollDirection === 'x' ? 'auto' : undefined}
        overflowY={scrollDirection === 'y' ? 'auto' : undefined}
        ref={ref}
        {...restProps}
      />
    );
  }
) as ScrollBoxType;

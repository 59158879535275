import {
  CustomEmptyStateProps,
  Flex,
  Grid,
  useTheme,
} from '@candisio/design-system';
import { AdvancedSearchField } from 'components/AdvancedSearchField/AdvancedSearchField';
import { BaselineSearchPromotionContainer } from 'components/BaselineSearchPromotion/BaselineSearchPromotionContainer';
import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import {
  DocumentsTable,
  defaultSortByFields,
} from 'components/DocumentsTable/DocumentsTable';
import {
  ColumnWidths,
  DocumentTableColumnKeys,
  DocumentsTableData,
} from 'components/DocumentsTable/types';
import { ConfigurationsMenu } from 'components/Table/Configurations/ConfigurationsMenu';
import { Maybe } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { appendParamsToQueryString } from 'utils/url-helper';
import { ApprovalsEmptyStateMobilePromo } from 'views/Approvals/Invoices/ApprovalsEmptyStateMobilePromo';
import { availableFilters } from 'views/Archive/ArchiveDocumentsTable/consts';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import { ApprovalEmptyState } from './ApprovalEmptyState';
import { FilterButtonsContainer } from './FilterButton/FilterButtonsContainer';
import { useApprovalDocumentsData } from './hooks/useApprovalDocumentsData';
import { useApprovalDocumentsDataDeprecated } from './hooks/useApprovalDocumentsDataDeprecated';
import { useGetApprovalDocumentsTableConfigurations } from './hooks/useGetApprovalDocumentsTableConfigurations';
import { useShouldShowMobileAppPromotion } from './hooks/useShouldShowMobileAppPromotion';

const useSearchParamAllDone = (totalCount: Maybe<number>, loading: boolean) => {
  const history = useHistory();
  const { filters: gqlFilters } = useUrlBasedSortAndFilter({
    availableFilters,
  });

  useEffect(() => {
    const isAllDone = qs.parse(window.location.search).allDone;
    const hasActiveFilter = Object.values(gqlFilters).some(v => !isNil(v));

    if (totalCount === 0 && !hasActiveFilter && !isAllDone && !loading) {
      history.push({
        search: qs.stringify({ allDone: true }),
      });
    }
  }, [totalCount, history, gqlFilters, loading]);
};

type Props = RouteComponentProps<{}>;
type ApprovalTableComponentProps = Props & {
  approvalDocumentsDataProps:
    | ReturnType<typeof useApprovalDocumentsData>
    | ReturnType<typeof useApprovalDocumentsDataDeprecated>;
  shouldEnableSortByWithSearchQuery: boolean;
};

export const ApprovalsTableContainer = (props: Props) => {
  const { useNewApprovalQuery } = useEcm();

  return useNewApprovalQuery ? (
    <ApprovalsTable {...props} />
  ) : (
    <ApprovalsTableDeprecated {...props} />
  );
};

const ApprovalsTable = (props: Props) => {
  const approvalDocumentsDataProps = useApprovalDocumentsData();
  return (
    <ApprovalsTableComponent
      {...props}
      approvalDocumentsDataProps={approvalDocumentsDataProps}
      shouldEnableSortByWithSearchQuery={true}
    />
  );
};

/**
 * @deprecated
 */
const ApprovalsTableDeprecated = (props: Props) => {
  const approvalDocumentsDataProps = useApprovalDocumentsDataDeprecated();

  return (
    <ApprovalsTableComponent
      {...props}
      approvalDocumentsDataProps={approvalDocumentsDataProps}
      shouldEnableSortByWithSearchQuery={false}
    />
  );
};

export const ApprovalsTableComponent = ({
  location: { search },
  approvalDocumentsDataProps,
  shouldEnableSortByWithSearchQuery,
}: ApprovalTableComponentProps) => {
  const [t] = useTranslation();
  const { space } = useTheme();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();

  const { showConsistentSortAndFiltering } = useEcm();

  const { searchParams } = useMutateSearchParams();

  const requesterFilter = searchParams.get('requester');

  const approversFilter = searchParams.get('approvers');

  const {
    approvalsTableData,
    filters,
    filterOptions,
    filtersLoading,
    hasMoreData,
    isTableEmpty,
    isTableFiltered,
    loading,
    onFilter,
    onLoadMore,
    onSort,
    showLoadingMoreSpinner,
    sortBy,
    handleDebounceSearch,
    totalCount,
    searchQuery,
  } = approvalDocumentsDataProps;

  const {
    availableDocumentColumnIds,
    configurationsTable,
    isLoadingConfigs,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useGetApprovalDocumentsTableConfigurations({ filters, sortBy });

  const { shouldShowMobileAppPromotion } = useShouldShowMobileAppPromotion({
    isTableEmpty,
  });

  useSearchParamAllDone(totalCount, loading);

  const columnWidths: ColumnWidths = {
    contact: space.space256,
    grossAmount: space.space128,
    invoiceNumber: space.space200,
    invoiceDate: space.space200,
    requester: space.space128,
    approvers: space.space128,
    requestedAt: space.space200,
    discountDateWPercentage: space.space200,
    iban: space.space200,
    paymentInfo: space.space200,
  };

  const infiniteScrollOptions = {
    dataLength: approvalsTableData?.length,
    hasMore: hasMoreData,
    showLoadingMoreSpinner: !loading && showLoadingMoreSpinner,
    next: onLoadMore,
  };

  const getPath = useCallback(
    ({ id, cursor }: DocumentsTableData) => {
      const searchWithCursor = appendParamsToQueryString(search, {
        cursor: cursor ?? null,
      });

      const pathForOneApprovalDocumentWithCursor = `/${organizationSlug}${Routes.APPROVALS}/${id}?${searchWithCursor}`;

      return pathForOneApprovalDocumentWithCursor;
    },
    [search, organizationSlug]
  );

  const cellWrapper = useMemo(() => getCellWrapperLink(getPath), [getPath]);
  const cellStyle = () => ({
    padding: 'unset',
  });

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) => {
    return shouldShowMobileAppPromotion ? (
      <ApprovalsEmptyStateMobilePromo />
    ) : (
      <ApprovalEmptyState
        isTableEmpty={isTableEmpty}
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    );
  };

  const tableKey = `table-filters-${filtersLoading}-${availableDocumentColumnIds.join(
    '-'
  )}`;

  const sortByFields: DocumentTableColumnKeys[] = showConsistentSortAndFiltering
    ? [
        ...defaultSortByFields,
        'invoiceNumber',
        'purchaseOrderNumber',
        'artistSocialInsuranceCode',
        'costObject',
        'costCenter',
        'extraCostInfo',
        'deliveryDate',
      ]
    : defaultSortByFields;

  return (
    <Flex height="100%" overflow="hidden" direction="column" key={tableKey}>
      <Grid
        templateColumns="minmax(12rem, 24rem) 1fr"
        borderTopRadius="basic"
        overflow="hidden"
        background="white"
        borderBottom="1px solid gray200"
        padding="space12"
        gap="space8"
      >
        <AdvancedSearchField
          clearTooltip={t('archive:search.clearTooltip')}
          placeholder={t('archive:search.placeholder')}
          initialValue={searchQuery}
          onQueryChange={query => {
            handleDebounceSearch(query);
          }}
          inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
        />
        <Grid
          templateColumns="repeat(2, auto)"
          gap="space4"
          alignItems="center"
        >
          <FilterButtonsContainer />
          <Flex gap="space8" alignItems="center" justifySelf="end">
            <ConfigurationsMenu
              configurationItems={configurationsTable}
              isLoading={isLoadingConfigs}
              onUpdateConfigurations={handleUpdateConfigurations}
              onResetConfigurations={handleResetTableConfigurations}
            />
          </Flex>
        </Grid>
      </Grid>
      <DocumentsTable
        // If the filter on the menu button above changes we manually need to remount the table
        // Otherwise the filters inside the columns will not update
        key={`${requesterFilter}${approversFilter}`}
        columns={availableDocumentColumnIds}
        columnWidths={columnWidths}
        customEmptyState={customEmptyState}
        cellWrapper={cellWrapper}
        getCellStyles={cellStyle}
        data={approvalsTableData}
        defaultFilters={filters}
        defaultSortBy={sortBy}
        filterOptions={filterOptions}
        isUsingSearchQuery={!!searchQuery}
        shouldEnableSortByWithSearchQuery={shouldEnableSortByWithSearchQuery}
        infiniteScrollOptions={infiniteScrollOptions}
        isLoading={loading}
        minWidth="100%"
        onEndReached={onLoadMore}
        onFilter={onFilter}
        onSort={onSort}
        borderTopRadius="none"
        overscan={100}
        width="max-content"
        sortByFields={sortByFields}
      />
      <BaselineSearchPromotionContainer />
    </Flex>
  );
};

import { Box, Grid, TruncatedText } from '@candisio/design-system';
import { StatusTag } from 'components/StatusTag';
import { DocumentStatus } from 'generated-types/graphql.types';
import { ReactElement } from 'react';

export const DocumentDetailsCardHeader = ({
  title,
  backgroundColor,
  status,
  secondaryTitle,
}: {
  title: string;
  backgroundColor: string;
  status: DocumentStatus;
  secondaryTitle?: ReactElement;
}) => {
  return (
    <Grid
      autoFlow="column"
      alignItems="center"
      minHeight="space32"
      justifyContent="space-between"
      fontWeight="bold"
      color="white"
      paddingX="space12"
      paddingY="space8"
      borderTopLeftRadius="basic"
      borderTopRightRadius="basic"
      background={backgroundColor}
    >
      <Grid
        gap="space8"
        autoFlow="column"
        wordBreak="normal"
        paddingLeft="space4"
        alignItems="center"
      >
        <StatusTag status={status} variant="primary" />
        <TruncatedText>{title}</TruncatedText>
      </Grid>
      {secondaryTitle && <Box paddingRight="space8">{secondaryTitle}</Box>}
    </Grid>
  );
};

import { FieldPolicy, TypePolicies } from '@apollo/client';
import { PageBasedRolesList } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const rolesRecords: FieldPolicy<PageBasedRolesList> = {
  keyArgs: ['filters', 'queries', 'sortBy'],
  merge: simplePaginationMergeWithoutDuplicates('PageBasedRolesList'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      roles: rolesRecords,
    },
  },
};

import { Card, Grid, Heading, Link, Text } from '@candisio/design-system';
import { useGetPath } from 'hooks/usePath';
import { useOtherIntegration } from 'orgConfig/other';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const CreditCardSettlementsBanner = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const otherIntegration = useOtherIntegration();
  const tOptions = otherIntegration.isActive ? { context: 'other' } : '';

  const getPath = useGetPath();

  const link = getPath({
    pathname: '/credit_cards/dashboard/statements/:statementId?',
  });

  return (
    <Card
      padding="space16"
      background="bluebg"
      borderRadius="medium"
      alignSelf="start"
    >
      <Grid gap="space16">
        <Heading as="h3">{t('settlementsBanner.title', tOptions)}</Heading>
        <Text fontSize="basic">{t('settlementsBanner.desc', tOptions)}</Text>
        <Link href={link} icon="arrowRight" fontSize="basic">
          {t('settlementsBanner.linkText')}
        </Link>
      </Grid>
    </Card>
  );
};

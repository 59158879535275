import { Button, Grid, Text } from '@candisio/design-system';
import { ActionBar } from 'components/Sidebar/ActionBar';
import { Sidebar as SideBarElement } from 'components/Sidebar/Sidebar';
import { ExportStatus } from 'generated-types/graphql.types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { ExportContext } from 'views/Integrations/Export/Context';
import { DatevPanel } from 'views/Integrations/Export/toolkit/components/DatevPanel';
import { ExportingProgressBar } from 'views/Integrations/Export/toolkit/components/ExportDetails/components';
import { ExportInfo } from 'views/Integrations/Export/toolkit/components/ExportInfo';
import { DatevDcoImage } from 'views/Integrations/Export/toolkit/components/common';
import { useDatevSettings } from 'views/Integrations/Export/toolkit/hooks/useDatevSettings';
import { ExportDetails, Sidebar } from 'views/Integrations/Export/types';
import { ExportHistorySummary } from '../../containers/ExportableEntitiesSummary/History';

export interface DatevExportDetailsProps {
  exportDetails: ExportDetails;
}

export const DatevExportDetails = ({
  exportDetails,
}: DatevExportDetailsProps) => {
  const state = useContext(ExportContext);
  const [t] = useTranslation();
  const { datevSettings } = useDatevSettings();

  const datevClient = datevSettings?.client;

  return (
    <SideBarElement
      heading={t('export.exportForm.exportDatev.title', {
        date: DateFormatters.compact(exportDetails?.createdAt || new Date()),
      })}
    >
      <Grid templateRows="auto auto 1fr" height="100%">
        <ExportInfo>
          <DatevDcoImage />
          <Grid>
            <Text fontSize="basic">
              {t('export.exportForm.selectExportType.types.datev')}
            </Text>
            {datevClient && (
              <Text fontSize="small">
                {t('exports:datevClientInfo', {
                  name: datevClient.name,
                  id: datevClient.id,
                })}
              </Text>
            )}
          </Grid>
        </ExportInfo>
        {exportDetails.status !== ExportStatus.Exporting ? (
          <ExportHistorySummary />
        ) : (
          <div />
        )}

        <DatevPanel
          onExport={() => {
            state.setSidebar(Sidebar.INIT_NEW_EXPORT);
            state.setExportType(exportDetails.type);
            state.setIsReExport(true);
          }}
          additionalActions={[
            <Button
              key="1"
              variant="tertiary"
              onClick={() => {
                window.open(
                  t('export.exportForm.exportDatev.openInDatev.url'),
                  '_blank'
                );
              }}
            >
              {t('export.exportForm.exportDatev.openInDatev.text')}
            </Button>,
          ]}
          nonIncidentElement={
            <ActionBar>
              {exportDetails.status === ExportStatus.Exporting ? (
                <ExportingProgressBar />
              ) : (
                <>
                  <Button
                    onClick={() =>
                      window.open(
                        t('export.exportForm.exportDatev.openInDatev.url'),
                        '_blank'
                      )
                    }
                  >
                    {t('export.exportForm.exportDatev.openInDatev.text')}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      state.setSidebar(Sidebar.INIT_NEW_EXPORT);
                      state.setExportType(exportDetails.type);
                      state.setIsReExport(true);
                    }}
                  >
                    {t('export.exportForm.exportDatev.exportAgain')}
                  </Button>
                </>
              )}
            </ActionBar>
          }
        />
      </Grid>
    </SideBarElement>
  );
};

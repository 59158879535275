import gql from 'graphql-tag';
import {
  pageInfoDataFragment,
  transactionListFragment,
} from 'views/queries/fragments';
import { cardManagerDataFragment } from 'providers/EntityLoader/queries';
import {
  CardLimitChangeRequest,
  physicalCardRequestFragment,
  singleUseVirtualCardRequestFragment,
  virtualCardRequestFragment,
} from 'containers/credit-cards/components/CreditCardsSection/gql';

export const getManagerCreditCardInsightsQuery = gql`
  query getManagerCreditCardInsights(
    $cardInput: CardIssuerPaginationInput!
    $cardRequestFilters: CardIssuerCardsForCardManagerFiltersInput
    $cardLimitFilters: CardIssuerCardsForCardManagerFiltersInput
    $cardQueries: CardIssuerCardsQueryInput
    $cardSortBy: CardIssuerCardsSortInput
    $transactionInput: CardIssuerTransactionPaginationInput!
    $transactionFilters: CardIssuerTransactionFilterInput
    $transactionSortBy: CardIssuerTransactionSortInput
    $transactionQueries: CardIssuerTransactionsQueryInput
  ) {
    # Card Requests Data
    cardRequests: getCardIssuerCardsForCardManager(
      input: $cardInput
      filters: $cardRequestFilters
      sortBy: $cardSortBy
      queries: $cardQueries
    ) {
      edges {
        ...CardIssuerCardEdgeData
      }
      pageInfo {
        ...PageInfoData
      }
    }

    # Limit Requests Data
    limitRequests: getCardIssuerCardsForCardManager(
      input: $cardInput
      filters: $cardLimitFilters
      sortBy: $cardSortBy
      queries: $cardQueries
    ) {
      edges {
        ...CardIssuerCardEdgeData
      }
      pageInfo {
        ...PageInfoData
      }
    }
    
    # Declined Transactions
    declinedTransactions: archiveCardIssuerTransactions(
      input: $transactionInput
      filters: $transactionFilters
      sortBy: $transactionSortBy
      queries: $transactionQueries
    ) {
      edges {
        cursor
        node {
          ...transactionListData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${cardManagerDataFragment}
  ${pageInfoDataFragment}
  ${transactionListFragment}
  ${virtualCardRequestFragment}
  ${singleUseVirtualCardRequestFragment}
  ${physicalCardRequestFragment}
  ${CardLimitChangeRequest}
`;

export const getCardholderCreditCardInsightsQuery = gql`
  query getCardholderCreditCardInsights(
    $cardInput: CardIssuerPaginationInput!
    $cardRequestFilters: CardIssuerCardsForCardholderFiltersInput
    $cardLimitFilters: CardIssuerCardsForCardholderFiltersInput
    $cardQueries: CardIssuerCardsQueryInput
    $cardSortBy: CardIssuerCardsSortInput
    $transactionInput: CardIssuerTransactionPaginationInput!
    $transactionFilters: CardIssuerTransactionFilterInput
    $transactionSortBy: CardIssuerTransactionSortInput
    $transactionQueries: CardIssuerTransactionsQueryInput
  ) {
    # Card Requests Data
    cardRequests: getCardIssuerCardsForCardholder(
      input: $cardInput
      filters: $cardRequestFilters
      sortBy: $cardSortBy
      queries: $cardQueries
    ) {
      edges {
        ...CardIssuerCardEdgeData
      }
      pageInfo {
        ...PageInfoData
      }
    }

    # Limit Requests Data
    limitRequests: getCardIssuerCardsForCardholder(
      input: $cardInput
      filters: $cardLimitFilters
      sortBy: $cardSortBy
      queries: $cardQueries
    ) {
      edges {
        ...CardIssuerCardEdgeData
      }
      pageInfo {
        ...PageInfoData
      }
    }
    
    # Declined Transactions
    declinedTransactions: archiveCardIssuerTransactions(
      input: $transactionInput
      filters: $transactionFilters
      sortBy: $transactionSortBy
      queries: $transactionQueries
    ) {
      edges {
        cursor
        node {
          ...transactionListData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${cardManagerDataFragment}
  ${pageInfoDataFragment}
  ${transactionListFragment}
  ${virtualCardRequestFragment}
  ${singleUseVirtualCardRequestFragment}
  ${physicalCardRequestFragment}
  ${CardLimitChangeRequest}
`;

import { Reorder } from 'motion/react';
import { ReorderableConfiguration } from './ConfigurationsMenu';
import { ReorderableItem } from './ReorderableItem';

interface ReorderableItemsProps {
  reorderableItems: ReorderableConfiguration[];
  onCheck: (configurableItem: ReorderableConfiguration) => void;
  onRedorderItems: (items: ReorderableConfiguration[]) => void;
}

export const ReorderableItems = ({
  reorderableItems,
  onCheck,
  onRedorderItems,
}: ReorderableItemsProps) => {
  return (
    <Reorder.Group
      axis="y"
      layoutScroll
      style={{
        listStyle: 'none',
        padding: '0px',
      }}
      values={reorderableItems}
      onReorder={onRedorderItems}
    >
      {reorderableItems.map(item => (
        <ReorderableItem key={item.id} item={item} onCheck={onCheck} />
      ))}
    </Reorder.Group>
  );
};

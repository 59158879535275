import { ContractsPromotionArchive } from 'components/ProductPromotions/Contracts/ContractsPromotionArchive';
import { EcmPromotionArchive } from 'components/ProductPromotions/ECM/EcmPromotionArchive';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { compiledRoutes, Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useSap } from 'orgConfig/sap';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useEffect } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { Redirect, Switch } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { Route } from 'router/SentryRouting';
import { hasRole } from 'utils/authorization';
import { ApprovalsView } from 'views/Approvals/ApprovalsView';
import { ArchiveDocumentsContainer as ArchiveDocuments } from 'views/Archive/Documents/ArchiveDocuments';
import { EcmContracts } from 'views/Archive/EcmContracts/EcmContracts';
import { EcmDocuments } from 'views/Archive/EcmDocuments/EcmDocuments';
import { EcmDocumentsDeprecated } from 'views/Archive/EcmDocuments/EcmDocumentsDeprecated';
import { GoodsReceipts } from 'views/Archive/GoodsReceipts/GoodsReceipts';
import { GoodsReceiptsImportHistory } from 'views/Archive/GoodsReceipts/GoodsReceiptsImportHistory/GoodsReceiptsImportHistory';
import { PurchaseOrders } from 'views/Archive/PurchaseOrders/PurchaseOrders';
import { PurchaseOrdersImportHistory } from 'views/Archive/PurchaseOrders/PurchaseOrdersImportHistory/PurchaseOrdersImportHistory';
import { ArchiveReimbursementsContainer } from 'views/Archive/Reimbursements/ArchiveReimbursementsContainer';
import { Transactions } from 'views/Archive/Transactions/Transactions';
import { AuthorizedAuthDatev } from 'views/AuthDatev';
import { Contacts } from 'views/Contacts';
import { ContactImportHistory } from 'views/Contacts/ContactImportHistory/ContactImportHistory';
import { Dashboard } from 'views/Dashboard';
import { AllInsightsWidgets } from 'views/Dashboard/AllInsightsWidgets';
import { DocumentDetails } from 'views/DocumentDetails';
import { DocumentDownloadHistory } from 'views/DocumentDownloadHistory/DocumentDownloadHistory';
import { Duplicate } from 'views/Duplicate';
import { EcmDocument } from 'views/EcmDocument/EcmDocument';
import { EcmReimbursementItem } from 'views/EcmDocument/EcmReimbursementItem';
import { DocumentProcessing } from 'views/Inbox/DocumentProcessing';
import { EcmInboxSensitiveDocuments } from 'views/Inbox/EcmSensitiveDocuments/EcmInboxSensitiveDocuments';
import { EcmInboxSensitiveDocumentsPromo } from 'views/Inbox/EcmSensitiveDocuments/EcmInboxSensitiveDocumentsPromo';
import { InboxContracts } from 'views/Inbox/InboxContracts/InboxContracts';
import { InboxContractsPromo } from 'views/Inbox/InboxContracts/InboxContractsPromo';
import { InboxDocumentsContainer } from 'views/Inbox/InboxDocuments';
import { InboxOtherDocuments } from 'views/Inbox/InboxOtherDocuments/InboxOtherDocuments';
import { InboxReimbursementsContainer } from 'views/Inbox/Reimbursements/InboxReimbursementsContainer';
import { InboxTransactions } from 'views/Inbox/Transactions';
import { ManageRelationshipsView } from 'views/ManageRelationships/ManageRelationshipsView';
import { Payments } from 'views/Payments';
import { ReimbursementContainer } from 'views/Reimbursement/ReimbursementContainer';
import { Settings } from 'views/Settings';
import { Organization } from 'views/Settings/Organization/Organization';
import { TransactionAssociationAdminRequester } from 'views/TransactionAssociation/TransactionAssociationAdminRequester';
import { TransactionAssociationApprover } from 'views/TransactionAssociation/TransactionAssociationApprover';
import { TransactionDownloadHistoryView } from 'views/TransactionDownloadHistory/TransactionDownloadHistoryView';
import { IntegrationName } from '../../generated-types/graphql.types';
import { useFullOrganization } from '../../providers/OrganizationProvider';
import { useInitialExportUrl } from '../Integrations/Export/Manifest';
import { ExportViewBuilder } from '../Integrations/Export/ViewBuilder';
import {
  useMenuBottomLinks,
  useMenuTopLinks,
} from './components/Sidebar/menuLinks';
import { CreditCardRoutes } from './CreditCardsRoutes';
import { ReimbursementDashboardContainer } from 'views/Reimbursement/ReimbursementDashboardContainer';

interface AppRoutesProps {
  path: string;
  selectedOrganization: string | null;
}

export const AppRoutes = ({ path, selectedOrganization }: AppRoutesProps) => {
  const currentUser = useCurrentUser();

  const organization = useFullOrganization();
  const { isAccountant, isAdmin, isOnlyApprover } = useUserRoles();

  // Determines which document view to show when opening
  // an ECM document from Archive view
  const [searchParams] = useSearchParams();
  const {
    canAccessEcm,
    showEcm,
    showEcmContractManagement,
    showEcmSensitiveDocuments,
    showDocumentRelations,
  } = useEcm();

  const isInvoice = searchParams.get('isInvoice');

  const { shouldUseSapPurchaseOrder } = useSap();
  const { canUseReimbursement } = useReimbursement();

  const [llmClassificationFF, newAllDocumentsQueryFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.llmClassification,
    FEATURE_FLAGS.useNewAllDocumentsQuery,
  ]);

  const integrationName = organization?.integrationName;

  const exportViewDef = useInitialExportUrl();

  useEffect(() => {
    localStorage.setItem(
      'CANDIS_INTEGRATION',
      integrationName === IntegrationName.Sap ? 'SAP' : ''
    );
  }, [integrationName]);

  const menuTopLinks = useMenuTopLinks();
  const menuBottomLinks = useMenuBottomLinks();

  const visibleMenuTopLinks = menuTopLinks.filter(
    item => item.showMenuItem !== false
  );

  const visibleMenuBottomLinks = menuBottomLinks.filter(
    item => item.showMenuItem !== false
  );

  const allMenuLinks = [...visibleMenuTopLinks, ...visibleMenuBottomLinks];

  const hasPermissionsToVisit = (route: string) => {
    const { allowedRoles } = allMenuLinks.find(l => l.url === route) || {};

    const canView =
      currentUser &&
      allowedRoles &&
      allowedRoles.some(role => hasRole(currentUser, role));

    return canView;
  };

  const exportPath = exportViewDef.url;

  const EcmDocumentsView = newAllDocumentsQueryFF
    ? EcmDocuments
    : EcmDocumentsDeprecated;

  const isAllowedToSeeReimbursement =
    canUseReimbursement &&
    (hasPermissionsToVisit(compiledRoutes.reimbursementsDashboardRoute) ||
      hasPermissionsToVisit(compiledRoutes.inboxRoute) ||
      hasPermissionsToVisit(compiledRoutes.archiveRoute) ||
      hasPermissionsToVisit(compiledRoutes.approvalsRoute));

  return (
    <Switch>
      {showDocumentRelations && (
        <Route
          exact
          path={[
            `${path}${compiledRoutes.manageRelationshipsArchive}`,
            `${path}${compiledRoutes.manageRelationshipsInbox}`,
            `${path}${compiledRoutes.manageRelationshipsApprovals}`,
            `${path}${compiledRoutes.manageRelationshipsEcmArchive}`,
            `${path}${compiledRoutes.manageRelationshipsCompare}`,
          ]}
          component={ManageRelationshipsView}
        />
      )}
      {hasPermissionsToVisit(compiledRoutes.inboxRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.inboxRoute}`}
          component={InboxDocumentsContainer}
        />
      )}
      {hasPermissionsToVisit(compiledRoutes.inboxRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.inboxTransactions}`}
          component={InboxTransactions}
        />
      )}
      {hasPermissionsToVisit(compiledRoutes.inboxRoute) && canAccessEcm && (
        <Route
          exact
          path={`${path}${compiledRoutes.inboxSensitiveContractsECM}`}
          component={
            showEcmSensitiveDocuments
              ? EcmInboxSensitiveDocuments
              : EcmInboxSensitiveDocumentsPromo
          }
        />
      )}
      {llmClassificationFF &&
        hasPermissionsToVisit(compiledRoutes.inboxRoute) &&
        canAccessEcm && (
          <Route
            exact
            path={`${path}${compiledRoutes.inboxContractsECM}`}
            component={
              showEcmContractManagement ? InboxContracts : InboxContractsPromo
            }
          />
        )}
      {llmClassificationFF &&
        hasPermissionsToVisit(compiledRoutes.inboxRoute) &&
        canAccessEcm && (
          <Route
            exact
            path={`${path}${compiledRoutes.inboxOtherDocumentsECM}`}
            component={InboxOtherDocuments}
          />
        )}
      {hasPermissionsToVisit(compiledRoutes.inboxRoute) &&
        canAccessEcm &&
        showEcmSensitiveDocuments && (
          <Route
            exact
            path={`${path}${compiledRoutes.inboxSensitiveContractECM}`}
            component={EcmDocument}
          />
        )}
      {llmClassificationFF &&
        hasPermissionsToVisit(compiledRoutes.inboxRoute) &&
        canAccessEcm &&
        showEcmContractManagement && (
          <Route
            exact
            path={`${path}${compiledRoutes.inboxContractECM}`}
            component={EcmDocument}
          />
        )}
      {llmClassificationFF &&
        hasPermissionsToVisit(compiledRoutes.inboxRoute) &&
        canAccessEcm && (
          <Route
            exact
            path={`${path}${compiledRoutes.inboxOtherDocumentECM}`}
            component={EcmDocument}
          />
        )}
      {hasPermissionsToVisit(compiledRoutes.inboxRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.inboxTransactionAssociationRoute}`}
          component={
            isOnlyApprover
              ? TransactionAssociationApprover
              : TransactionAssociationAdminRequester
          }
        />
      )}

      {hasPermissionsToVisit(compiledRoutes.inboxRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.inboxReimbursementsRoute}`}
          component={InboxReimbursementsContainer}
        />
      )}

      {hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveReimbursementsRoute}`}
          component={ArchiveReimbursementsContainer}
        />
      )}
      <Route
        exact
        path={`${path}${compiledRoutes.documentProcessingRoute}`}
        component={DocumentProcessing}
      />
      <Route
        path={`${path}${compiledRoutes.resolveDuplicateRoute}`}
        component={Duplicate}
      />
      {hasPermissionsToVisit(compiledRoutes.contactsRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.contactImportHistoryRoute}`}
          component={ContactImportHistory}
        />
      )}
      <Route
        exact
        path={`${path}${compiledRoutes.documentDataDownloadHistoryRoute}`}
        component={DocumentDownloadHistory}
      />
      <Route
        exact
        path={`${path}${compiledRoutes.transactionsDownloadHistory}`}
        component={TransactionDownloadHistoryView}
      />
      {hasPermissionsToVisit(compiledRoutes.contactsRoute) && (
        <Route
          path={`${path}${compiledRoutes.editContactRoute}`}
          component={Contacts}
        />
      )}
      {hasPermissionsToVisit(exportPath) && (
        <Route
          path={`${path}${compiledRoutes.exportsRoute}`}
          component={ExportViewBuilder}
        />
      )}
      {(isAdmin || isAccountant) && (
        <Route
          path={`${path}${compiledRoutes.settingsRoute}`}
          component={Settings}
        />
      )}
      <Route
        exact
        path={`${path}${compiledRoutes.allWidgetsRoute}`}
        component={AllInsightsWidgets}
      />
      {hasPermissionsToVisit(compiledRoutes.approvalsRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.approvalsRoute}`}
          render={props => <ApprovalsView {...props} activeTab="Invoices" />}
        />
      )}

      <Route
        exact
        path={`${path}${compiledRoutes.approvalsReimbursements}`}
        render={props => (
          <ApprovalsView {...props} activeTab="Reimbursements" />
        )}
      />

      <Route
        exact
        path={`${path}${compiledRoutes.documentApprovalRoute}`}
        component={DocumentProcessing}
      />
      <Route
        exact
        path={`${path}${compiledRoutes.dashboardRoute}`}
        component={Dashboard}
      />
      <Route
        exact
        path={`${path}${compiledRoutes.dashboardWidgetRoute}`}
        component={Dashboard}
      />
      <Route
        exact
        path={`${path}${compiledRoutes.dashboardCreditCardRoute}`}
        component={Dashboard}
      />
      {hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveTransactionAssociationRoute}`}
          component={
            isOnlyApprover
              ? TransactionAssociationApprover
              : TransactionAssociationAdminRequester
          }
        />
      )}
      {hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveRoute}`}
          component={ArchiveDocuments}
        />
      )}
      {shouldUseSapPurchaseOrder &&
        hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
          <Route
            exact
            path={`${path}${compiledRoutes.allPurchaseOrders}`}
            component={PurchaseOrders}
          />
        )}
      {shouldUseSapPurchaseOrder &&
        hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
          <Route
            exact
            path={`${path}${compiledRoutes.arhiveGoodsReceiptsTableRoute}`}
            component={GoodsReceipts}
          />
        )}
      {shouldUseSapPurchaseOrder &&
        hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
          <Route
            exact
            path={`${path}${compiledRoutes.allPurchaseOrdersImportHistoryRoute}`}
            component={PurchaseOrdersImportHistory}
          />
        )}
      {shouldUseSapPurchaseOrder &&
        hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
          <Route
            exact
            path={`${path}${compiledRoutes.goodsReceiptsImportHistoryRoute}`}
            component={GoodsReceiptsImportHistory}
          />
        )}
      {hasPermissionsToVisit(compiledRoutes.archiveRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.allTransactions}`}
          component={Transactions}
        />
      )}
      {!showEcmContractManagement && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveEcmContractsRoute}`}
          component={ContractsPromotionArchive}
        />
      )}
      {showEcmContractManagement && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveEcmContractsRoute}`}
          component={EcmContracts}
        />
      )}
      {showEcmContractManagement && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveEcmContractRoute}`}
          // We reuse the EcmDocument component to display a contract in the single document view, because contracts are also ECM documents.
          component={EcmDocument}
        />
      )}
      <Route
        exact
        path={`${path}${compiledRoutes.archiveEcmDocumentsRoute}`}
        component={showEcm ? EcmDocumentsView : EcmPromotionArchive}
      />
      {showEcm && canUseReimbursement && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveEcmExpenseItem}`}
          component={EcmReimbursementItem}
        />
      )}
      {showEcm && (
        <Route
          exact
          path={`${path}${compiledRoutes.archiveEcmDocumentRoute}`}
          component={isInvoice ? DocumentDetails : EcmDocument}
        />
      )}

      <Route
        exact
        path={`${path}${compiledRoutes.documentArchiveRoute}`}
        component={DocumentDetails}
      />
      <Route
        exact
        path={`${path}${compiledRoutes.authorizeDatevRoute}`}
        component={AuthorizedAuthDatev}
      />
      {(isAdmin || isAccountant) && (
        <Route
          path={`${path}${compiledRoutes.editOrganizationRoute}`}
          component={Organization}
        />
      )}

      {hasPermissionsToVisit(compiledRoutes.paymentsRoute) &&
        organization?.payment.isActive && (
          <Route
            path={`${path}${compiledRoutes.paymentsRoute}`}
            component={Payments}
          />
        )}

      <Route
        exact
        path={[
          `${path}${Routes.CREDIT_CARDS_DASHBOARD}`,
          `${path}${compiledRoutes.insightsCardsDrawerRoute}`,
          `${path}${compiledRoutes.creditCardsManagerOverviewRoute}`,
          `${path}${compiledRoutes.creditCardsCardholderOverviewRoute}`,
          `${path}${compiledRoutes.creditCardsStatements}`,
          `${path}${compiledRoutes.recurringPaymentsRoute}`,
          `${path}${compiledRoutes.recurringPaymentsModalRoute}`,
        ]}
        render={() => <CreditCardRoutes path={path} />}
      />

      {hasPermissionsToVisit(compiledRoutes.reimbursementsDashboardRoute) && (
        <Route
          exact
          path={`${path}${compiledRoutes.reimbursementsDashboardRoute}`}
          component={ReimbursementDashboardContainer}
        />
      )}

      {isAllowedToSeeReimbursement && (
        <Route
          exact
          path={[
            `${path}${compiledRoutes.reimbursementDashboardRoute}`,
            `${path}${compiledRoutes.inboxReimbursementRoute}`,
            `${path}${compiledRoutes.approvalsReimbursement}`,
            `${path}${compiledRoutes.archiveReimbursementRoute}`,
          ]}
          component={ReimbursementContainer}
        />
      )}

      <Route
        path={path}
        component={() => (
          <Redirect
            to={`/${selectedOrganization}${compiledRoutes.dashboardRoute}`}
          />
        )}
      />
    </Switch>
  );
};

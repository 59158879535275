import { FieldPolicy, TypePolicies } from '@apollo/client';
import { ListImportHistoryEntriesResult } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const listImportHistoryPaginationFieldPolicy: FieldPolicy<ListImportHistoryEntriesResult> =
  {
    keyArgs: ['type'],
    merge: simplePaginationMergeWithoutDuplicates(
      'ListImportHistoryEntriesResult'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      importHistories: listImportHistoryPaginationFieldPolicy,
    },
  },
};

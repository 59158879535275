import { Box, Flex, Icon, TruncatedText } from '@candisio/design-system';
import { useGetPath } from 'hooks/usePath';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import styles from './PendingRequests.module.css';

interface PendingRequestsProps {
  creditCardRequests: number;
  limitRequests: number;
}

export const PendingRequests = ({
  creditCardRequests,
  limitRequests,
}: PendingRequestsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const navigate = useNavigate();
  const { isCardManager } = useUserRoles();
  const getPath = useGetPath();

  const handleClick = () => {
    navigate(
      getPath({
        pathname:
          '/credit_cards/dashboard/card_manager_overview/:creditCardId?',
      })
    );
  };

  return (
    <Box
      className={styles.tile}
      aria-disabled={!isCardManager}
      onClick={isCardManager ? handleClick : undefined}
    >
      <Flex gap="space16">
        {creditCardRequests > 0 && (
          <Flex alignItems="start" direction="column">
            <Flex alignItems="center" gap="space4">
              <Icon icon="creditCardOutline" color="yellow700" size="space14" />
              <TruncatedText color="yellow700" fontSize="xlarge">
                {String(creditCardRequests)}
              </TruncatedText>
            </Flex>
            <TruncatedText color="yellow700" fontSize="basic">
              {t('insights.requestsOverview.cardRequests', {
                count: creditCardRequests,
              })}
            </TruncatedText>
          </Flex>
        )}
        {limitRequests > 0 && (
          <Flex alignItems="start" direction="column">
            <Flex alignItems="center" gap="space4">
              <Icon icon="edit" color="yellow700" size="space14" />
              <TruncatedText color="yellow700" fontSize="xlarge">
                {String(limitRequests)}
              </TruncatedText>
            </Flex>
            <TruncatedText color="yellow700" fontSize="basic">
              {t('insights.requestsOverview.limitRequests', {
                count: limitRequests,
              })}
            </TruncatedText>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

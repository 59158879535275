import { FetchResult } from '@apollo/client';
import {
  Box,
  Button,
  Grid,
  Modal,
  Text,
  useModal,
} from '@candisio/design-system';
import {
  User,
  useAddAuthenticatorDeviceProtectedMutation,
  useAddAuthenticatorDeviceMutation,
  useAuthenticatorDeviceQuery,
  useVerifyAuthenticatorDeviceMutation,
  AddAuthenticatorDeviceProtectedMutation,
  AddAuthenticatorDeviceMutation,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetupAuthenticatorAppModal } from './SetupAuthenticatorAppModal';
import { VerifiedDevice } from './VerifiedDevice';
import { useReauthenticationDispatch } from 'providers/ReauthenticationProvider/ReauthenticationProvider';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

interface AuthenticatorAppCardProps {
  onCloseModal: () => void;
}

// Type guard for protected mutation response
function isAddAuthenticatorDeviceProtectedResponse(
  response: FetchResult<
    AddAuthenticatorDeviceProtectedMutation | AddAuthenticatorDeviceMutation
  >
): response is FetchResult<AddAuthenticatorDeviceProtectedMutation> {
  return 'addAuthenticatorDeviceProtected' in (response.data ?? {});
}

export const AuthenticatorAppCard = ({
  onCloseModal,
}: AuthenticatorAppCardProps) => {
  const [t] = useTranslation();
  const currentUser = useCurrentUser() as User;
  const { isOpen, modalProps, triggerProps } = useModal();
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const dispatch = useReauthenticationDispatch();

  const reauthenticatorBeforeAddAuthenticatorAppFF = useCandisFeatureFlags(
    FEATURE_FLAGS.reauthenticatorBeforeAddAuthenticatorApp
  );

  const defaultDeviceName = t(
    'header.profile.tabs.update.accountSecurity.authenticatorAppCard.defaultDeviceName',
    { firstName: currentUser.firstName }
  );

  const { data } = useAuthenticatorDeviceQuery();
  const [
    addAuthenticatorDeviceProtectedMutation,
    {
      data: addAuthenticatorDeviceProtectedData,
      loading: addAuthenticatorDeviceProtectedLoading,
    },
  ] = useAddAuthenticatorDeviceProtectedMutation();

  const [
    addAuthenticatorDeviceMutation,
    {
      data: addAuthenticatorDeviceData,
      loading: addAuthenticatorDeviceLoading,
    },
  ] = useAddAuthenticatorDeviceMutation();

  const verifiedDeviceName = data?.authenticatorDevice?.name;

  const [verifyAuthenticatorDevice, { loading: verifyDeviceLoading }] =
    useVerifyAuthenticatorDeviceMutation({
      refetchQueries: ['authenticatorDevice'],
      awaitRefetchQueries: true,
      onCompleted: data => {
        if (data.verifyAuthenticatorDevice.verified) {
          setShowSuccessScreen(true);
        }
      },
    });

  const handleVerifyAuthenticatorDevice = async ({
    otpCode,
    deviceName,
  }: {
    deviceName?: string;
    otpCode: string;
  }) => {
    const { data } = await verifyAuthenticatorDevice({
      variables: {
        deviceId: reauthenticatorBeforeAddAuthenticatorAppFF
          ? addAuthenticatorDeviceProtectedData?.addAuthenticatorDeviceProtected
              .__typename === 'PendingAuthenticatorDeviceVerification'
            ? addAuthenticatorDeviceProtectedData
                .addAuthenticatorDeviceProtected.id
            : ''
          : (addAuthenticatorDeviceData?.addAuthenticatorDevice.id ?? ''),
        token: otpCode,
        name: deviceName,
      },
    });

    return {
      isVerified: Boolean(data?.verifyAuthenticatorDevice.verified),
    };
  };

  const handleAddAuthenticatorDevice = async () => {
    const mutation = reauthenticatorBeforeAddAuthenticatorAppFF
      ? addAuthenticatorDeviceProtectedMutation
      : addAuthenticatorDeviceMutation;

    const response = await mutation({
      variables: {
        name: defaultDeviceName,
      },
    });

    if (response.errors?.length) {
      console.error('Error adding authenticator device:', response.errors);
      return;
    }

    if (
      reauthenticatorBeforeAddAuthenticatorAppFF &&
      isAddAuthenticatorDeviceProtectedResponse(response) &&
      response.data?.addAuthenticatorDeviceProtected.__typename ===
        'RecentAuthnRequiredError'
    ) {
      const { requiredAuthType, validForSeconds } =
        response.data.addAuthenticatorDeviceProtected;

      dispatch({
        action: 'showModal',
        authType: requiredAuthType,
        validForSeconds,
        onReauthenticated: handleAddAuthenticatorDevice,
      });

      return;
    }
  };

  const qrCodeImage = reauthenticatorBeforeAddAuthenticatorAppFF
    ? addAuthenticatorDeviceProtectedData?.addAuthenticatorDeviceProtected
        .__typename === 'PendingAuthenticatorDeviceVerification'
      ? addAuthenticatorDeviceProtectedData.addAuthenticatorDeviceProtected
          .secretQRDataUrl
      : undefined
    : addAuthenticatorDeviceData?.addAuthenticatorDevice.secretQRDataUrl;

  const totpSecret = reauthenticatorBeforeAddAuthenticatorAppFF
    ? addAuthenticatorDeviceProtectedData?.addAuthenticatorDeviceProtected
        .__typename === 'PendingAuthenticatorDeviceVerification'
      ? addAuthenticatorDeviceProtectedData.addAuthenticatorDeviceProtected
          .secret
      : undefined
    : addAuthenticatorDeviceData?.addAuthenticatorDevice.secret;

  return (
    <Box as="div" borderRadius="medium" padding="space16" background="gray100">
      <Grid gap="space16">
        <Text fontSize="basic" fontWeight="semibold">
          {t(
            'header.profile.tabs.update.accountSecurity.authenticatorAppCard.title'
          )}
        </Text>
        {verifiedDeviceName ? (
          <VerifiedDevice
            onContactSupport={onCloseModal}
            deviceName={verifiedDeviceName}
          />
        ) : (
          <Button
            {...triggerProps}
            onClick={(ev: MouseEvent<HTMLButtonElement>) => {
              void handleAddAuthenticatorDevice();
              triggerProps?.onClick?.(ev);
            }}
            loading={
              reauthenticatorBeforeAddAuthenticatorAppFF
                ? addAuthenticatorDeviceProtectedLoading
                : addAuthenticatorDeviceLoading
            }
            width="max-content"
            color="blue"
            icon="plus"
            size="small"
            variant="secondary"
          >
            {t('header.profile.tabs.update.accountSecurity.addMfa')}
          </Button>
        )}
        {isOpen && qrCodeImage && totpSecret && (
          <Modal
            {...modalProps}
            shouldCloseOnBlur={true}
            isOpen={isOpen}
            closeLabel={t('common.close')}
            width="40rem"
            height="48rem"
            title={t(
              'header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.title'
            )}
          >
            <SetupAuthenticatorAppModal
              deviceName={verifiedDeviceName ?? defaultDeviceName}
              onSubmit={handleVerifyAuthenticatorDevice}
              qrCodeImage={qrCodeImage}
              showSuccessScreen={showSuccessScreen}
              submitting={verifyDeviceLoading}
              totpSecret={totpSecret}
            />
          </Modal>
        )}
      </Grid>
    </Box>
  );
};

import { ComponentProps } from 'react';
import styles from './HighlightedText.module.css';
import clsx from 'clsx';

export interface HighlightedTextProps extends ComponentProps<'span'> {
  isHighlighted: boolean;
}

export const HighlightedText = ({
  isHighlighted,
  children,
  className,
  ...restProps
}: HighlightedTextProps) => {
  const Element = isHighlighted ? 'mark' : 'span';

  return (
    <Element
      {...restProps}
      className={clsx(
        styles['highlighted-text'],
        {
          'bg-yellow-400': isHighlighted,
        },
        className
      )}
    >
      {children}
    </Element>
  );
};

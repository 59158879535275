import { Grid } from '@candisio/design-system';
import {
  DatevClientExportType,
  New_DatevClient,
  useActivateDocumentTypeMutation,
  useDeactivateDocumentTypeMutation,
  useNew_DatevSettingsQuery,
  useNew_UpdateDatevExportTypeMutation,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useDatev } from 'orgConfig/datev';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from 'views/Settings/utils';
import { SettingsLayout } from '../../components/SettingsLayout/SettingsLayout';
import { BdsSetupWizard } from './BdsSetupWizard';
import { DatevConnectionContainer } from './DatevConnectionContainer';
import { DatevHelpInformation } from './DatevHelpInformation/DatevHelpInformation';
import { DocumentTypesTable } from './DocumentTypesTable';
import { ExportTypes } from './ExportTypes';
import { DATEV_EXPORT_TYPES, menuHeightInPx } from './consts';
import { BdsSetupPanel } from './containers/BdsSetupPanel/BdsSetupPanel';
import { getAvailableDocumentCategories, getDatevSettings } from './gql';
import { isCashLedgersMissingConfig } from './utils';

export const refetchDatevSettingsQueries = [
  { query: getAvailableDocumentCategories },
  { query: getDatevSettings },
];

export const DatevSettings = () => {
  const [t] = useTranslation();
  const creditCardsSetup = useCreditCardsSetup();
  const { bdsBought, bdsConnected, bdsBoughtButNotConnected } = useDatev();
  const [isDatevReconnecting, setIsDatevReconnecting] = useState(false);
  const [bdsSetupWizardFF, bdsPermissionCheckFlowFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.bdsSetupWizard,
    FEATURE_FLAGS.bdsPermissionCheckFlow,
  ]);

  const { data: settingsData } = useNew_DatevSettingsQuery({
    fetchPolicy: 'network-only',
  });

  const datevClient = useMemo(() => {
    return settingsData?.new_datevSettings?.client;
  }, [settingsData]);

  const hasAuthTokenForReadClients =
    settingsData?.new_datevSettings?.hasAuthTokenForReadClients;

  const hasAuthTokenForExport =
    settingsData?.new_datevSettings?.hasAuthTokenForExportDocuments;

  const isProvisionsLedgerConfigured = Boolean(
    settingsData?.new_datevSettings?.provisionsLedger?.provisionAccountNumber &&
      settingsData.new_datevSettings.provisionsLedger.otherAssetsAccountNumber
  );

  const documentTypes = useMemo(
    () => settingsData?.new_datevSettings?.documentTypes || [],
    [settingsData?.new_datevSettings?.documentTypes]
  );

  const isDxsoCashLedgerConfigured =
    settingsData?.new_datevSettings?.client?.isDxsoCashLedgerConfigured;

  const [isCashLedgerSwitchTriggered, setIsCashLedgerSwitchTriggered] =
    useState<boolean>(false);

  useEffect(() => {
    if (isDxsoCashLedgerConfigured) {
      setIsCashLedgerSwitchTriggered(true);
    }
  }, [isDxsoCashLedgerConfigured]);

  const [updateDatevExportType] = useNew_UpdateDatevExportTypeMutation();
  const onUpdateDatevExportType = useCallback(
    (exportType: DatevClientExportType) => {
      void updateDatevExportType({
        variables: { exportType },
        refetchQueries: refetchDatevSettingsQueries,
      });
    },
    [updateDatevExportType]
  );

  const isDatevConnected = datevClient && datevClient.clientNumber;

  useEffect(() => {
    if (isDatevConnected) {
      const exportTypesElement = document.getElementById(DATEV_EXPORT_TYPES);
      const mainContent = document.getElementById('main_content');

      if (exportTypesElement && mainContent) {
        mainContent.scrollTo({
          top: exportTypesElement.offsetTop - menuHeightInPx,
          behavior: 'smooth',
        });
      }
    }
  }, [isDatevConnected]);

  const [activateDocumentType] = useActivateDocumentTypeMutation();
  const [deactivateDocumentType] = useDeactivateDocumentTypeMutation();
  const onToggleDocumentTypeIsActive = useCallback(
    (name: string, category: string, isActive: boolean) => {
      if (isActive) {
        void activateDocumentType({
          variables: { documentTypeId: { name, category } },
          refetchQueries: refetchDatevSettingsQueries,
        });
      } else {
        void deactivateDocumentType({
          variables: { documentTypeId: { name, category } },
          refetchQueries: refetchDatevSettingsQueries,
        });
      }
    },
    [activateDocumentType, deactivateDocumentType]
  );

  const onChangeExportType = useCallback(
    (exportType: DatevClientExportType) => {
      return onUpdateDatevExportType(exportType);
    },
    [onUpdateDatevExportType]
  );

  const cashLedgerDocTypesMissingConfig =
    isCashLedgersMissingConfig(documentTypes);

  const bdsConnectedButConnectionNotVerified =
    bdsConnected && bdsPermissionCheckFlowFF
      ? !datevClient?.isBdsPermissionVerified
      : false;

  const hasConnectedClient =
    hasAuthTokenForExport && isNewDatevClient(datevClient);

  return (
    <SettingsLayout
      title={t('settings.datev.datevSettingstitle')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <Grid gap="space16" height="max-content">
        <Grid gap="space24">
          {bdsBoughtButNotConnected && !bdsSetupWizardFF && (
            <Grid rowGap="space24">
              <BdsSetupPanel />
            </Grid>
          )}

          {bdsSetupWizardFF && bdsBought && (
            <Grid rowGap="space24">
              <BdsSetupWizard loading={!settingsData} />
            </Grid>
          )}

          <Grid
            rowGap="space24"
            templateColumns={`${SETTINGS_VIEW_DEFAULT_WIDTH} 1fr`}
          >
            <DatevConnectionContainer
              hasConnectedClient={hasConnectedClient}
              hasAuthTokenForReadClients={hasAuthTokenForReadClients}
              settingsData={settingsData}
              isDatevReconnecting={isDatevReconnecting}
            />
          </Grid>
          <Grid rowGap="space24" paddingTop="space32">
            {isNewDatevClient(datevClient) &&
              hasAuthTokenForExport &&
              !bdsConnectedButConnectionNotVerified && (
                <Grid gap="space24">
                  <ExportTypes
                    exportType={datevClient.exportType}
                    cashLedgerDocTypesMissingConfig={
                      cashLedgerDocTypesMissingConfig
                    }
                    client={datevClient}
                    onChangeExportType={onChangeExportType}
                    setIsCashLedgerSwitchTriggered={
                      setIsCashLedgerSwitchTriggered
                    }
                    isCreditCardActivated={creditCardsSetup.isInUse}
                    isProvisionsLedgerConfigured={isProvisionsLedgerConfigured}
                    setIsDatevReconnecting={setIsDatevReconnecting}
                  />
                  <DocumentTypesTable
                    documentTypes={documentTypes}
                    onToggleIsActive={onToggleDocumentTypeIsActive}
                    exportType={datevClient.exportType}
                    isCashLedgerSwitchTriggered={isCashLedgerSwitchTriggered}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
      <DatevHelpInformation
        displayBDSInfoPanel={!!hasConnectedClient && !bdsBought}
      />
    </SettingsLayout>
  );
};

const isNewDatevClient = (client: any): client is New_DatevClient =>
  client?.__typename === 'new_DatevClient';

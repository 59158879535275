import { Box, Grid } from '@candisio/design-system';
import { HookFormDayField } from 'components/HookFormFields/HookFormDayField';
import { HookFormPercentField } from 'components/HookFormFields/HookFormPercentField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import {
  PaymentConditionsFormValues,
  paymentConditionsFormCoreDataApiSchema,
  paymentConditionsFormErrors,
} from 'views/Settings/PaymentConditions/CoreDataApi/paymentConditionsFormFormShema';
import {
  DATE_OFFSET,
  DISCOUNT_PERCENTAGE,
} from 'views/Settings/PaymentConditions/PaymentConditionsDrawer/PaymentConditionsForm/paymentConditionsFormSchema';

export interface PaymentConditionsFormProps {
  /** Initial values when the form is first rendered  */
  defaultValues?: PaymentConditionsFormValues;
  /** Layout variant of the form */
  layout?: 'default' | 'compact';
}

const fallbackDefaultValues: PaymentConditionsFormValues = {
  dueDateOffset: null,
  discountPercentage: null,
  discountOffset: null,
};

export const PaymentConditionsForm = ({
  defaultValues,
  layout = 'default',
}: PaymentConditionsFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const form = useForm<PaymentConditionsFormValues>({
    defaultValues: defaultValues ?? fallbackDefaultValues,
    resolver: zodResolver({
      zodSchema: paymentConditionsFormCoreDataApiSchema,
      errorMessages: paymentConditionsFormErrors,
      translationNamespace: 'settings',
    }),
  });

  const dueDateOffsetField = (
    <Box>
      <HookFormDayField
        name="dueDateOffset"
        label={t('paymentConditions.form.fields.dueDateOffset.label')}
        placeholder={t(
          'paymentConditions.form.fields.dueDateOffset.placeholder'
        )}
        minValue={DATE_OFFSET.MIN}
        maxValue={DATE_OFFSET.MAX}
        readOnly
        disabled
      />
    </Box>
  );

  const discountPercentageField = (
    <Box>
      <HookFormPercentField
        name="discountPercentage"
        label={t('paymentConditions.form.fields.discountPercentage.label')}
        placeholder={t(
          'paymentConditions.form.fields.discountPercentage.placeholder'
        )}
        minValue={DISCOUNT_PERCENTAGE.MIN}
        maxValue={DISCOUNT_PERCENTAGE.MAX}
        readOnly
        disabled
      />
    </Box>
  );

  const discountOffsetField = (
    <Box>
      <HookFormDayField
        name="discountOffset"
        label={t('paymentConditions.form.fields.discountOffset.label')}
        placeholder={t(
          'paymentConditions.form.fields.discountOffset.placeholder'
        )}
        minValue={DATE_OFFSET.MIN}
        maxValue={DATE_OFFSET.MAX}
        readOnly
        disabled
      />
    </Box>
  );

  return (
    <FormProvider {...form}>
      <Grid as="form" gap="space16">
        <Box>
          <HookFormTextField
            name="conditionName"
            readOnly
            disabled
            label={t('paymentConditions.form.fields.conditionName.label')}
          />
        </Box>
        {layout === 'compact' ? (
          <Grid columns={3} gap="space16">
            {dueDateOffsetField}
            {discountPercentageField}
            {discountOffsetField}
          </Grid>
        ) : (
          <>
            {dueDateOffsetField}
            <Grid templateColumns="1fr 2fr" gap="space16">
              {discountPercentageField}
              {discountOffsetField}
            </Grid>
          </>
        )}
      </Grid>
    </FormProvider>
  );
};

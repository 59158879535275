import {
  Box,
  Button,
  Grid,
  Icon,
  Text,
  useTheme,
} from '@candisio/design-system';
import { BackButton } from 'components/BackButton/BackButton';
import { DocumentDetailsCardContainer } from 'components/DocumentDetailsCard/DocumentDetailsCardContainer';
import { DocumentViewSwitcherProps } from 'components/DocumentViewer/DocumentViewSwitcher';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  DocumentStatus,
  useDeleteDocumentMutation,
  useDeleteOriginalMutation,
  useKeepDuplicateMutation,
} from 'generated-types/graphql.types';
import { GQLError } from 'gql';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDocumentFile } from 'hooks/useDocumentFile';
import { useFetchDocNavigationData } from 'hooks/useFetchDocNavigationData/useFetchDocNavigationData';
import { AppRouteParams, DocumentProcessingRouteParams, Routes } from 'models';
import { MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  Path,
  generatePath,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { useShowError } from 'utils/error_message';
import { validationErrorsExtractor } from 'utils/forms';
import { useFiltersAndSortFromUrl } from 'utils/url-helper';
import { useTransactionAssociationNavigation } from 'views/TransactionAssociation/utils';
import { ConfirmationPopoverDeprecated } from '../../components/ConfirmationPopover/ConfirmationPopoverDeprecated';
import { documentQueries } from '../Inbox/DocumentProcessing/queries';
import { List, getRefetchListQueries } from '../queries';
import { DUPLICATE_SEARCH_PARAMS } from './consts';
import {
  DocumentLayout,
  DocumentViewerStyled,
  DuplicateLayout,
  DuplicateRowLayout,
} from './styles';

interface RouteParams {
  organizationSlug: string;
  documentId: string;
  originalDocumentId: string;
  transactionId?: string;
}

export const Duplicate = () => {
  const [t] = useTranslation();
  const { success } = useToastMessage();
  const showError = useShowError();
  const { colors } = useTheme();

  const [firstDocumentView, setFirstDocumentView] =
    useState<DocumentViewSwitcherProps['view']>('pdf');

  const [secondDocumentView, setSecondDocumentView] =
    useState<DocumentViewSwitcherProps['view']>('pdf');

  const { documentId, organizationSlug, originalDocumentId, transactionId } =
    useParams<RouteParams>();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const keepDuplicateText = t(
    'document.requestApproval.duplicationAlert.resolutions.keepDuplicate'
  );

  const deletedDuplicateText = t(
    'document.requestApproval.duplicationAlert.resolutions.deletedDuplicate'
  );

  const deletedOriginalText = t(
    'document.requestApproval.duplicationAlert.resolutions.deletedOriginal'
  );

  const documentCursor = searchParams.get(
    DUPLICATE_SEARCH_PARAMS.DOCUMENT_CURSOR
  );

  const transactionCursor = searchParams.get(
    DUPLICATE_SEARCH_PARAMS.TRANSACTION_CURSOR
  );

  const filtersAndSortFromUrl = useFiltersAndSortFromUrl();

  const { nextDocumentLink, prevDocumentLink } = useFetchDocNavigationData({
    cursor: documentCursor,
    organizationSlug,
    route: Routes.INBOX,
  });

  const { nextTransactionLink, prevTransactionLink } =
    useTransactionAssociationNavigation({
      cursor: transactionCursor,
      organizationSlug,
      routeType: Routes.INBOX,
    });

  const inboxLink = transactionId
    ? generateTransactionLink({
        transactionId,
        organizationSlug,
        cursor: transactionCursor,
        filtersAndSortFromUrl,
      })
    : generateDocumentLink({
        documentId,
        organizationSlug,
        cursor: documentCursor,
        filtersAndSortFromUrl,
      });

  const allDoneDocumentLink: Partial<Path> = {
    pathname: generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.INBOX}`,
      { organizationSlug }
    ),
    search: new URLSearchParams({ allDone: 'true' }).toString(),
  };

  const allDoneTransactionLink: Partial<Path> = {
    pathname: generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}`,
      { organizationSlug }
    ),
    search: new URLSearchParams({ allDone: 'true' }).toString(),
  };

  const cycleInboxTransactionLink: string | Partial<Path> =
    nextTransactionLink ?? prevTransactionLink ?? allDoneTransactionLink;

  const cycleInboxDocumentLink: Partial<Path> =
    nextDocumentLink ?? prevDocumentLink ?? allDoneDocumentLink;

  const cycleInboxLink = transactionId
    ? cycleInboxTransactionLink
    : cycleInboxDocumentLink;

  const wrapperStyle = {
    position: 'absolute',
    bottom: 0,
    alignSelf: 'center',
    width: '100%',
  };

  const sharedProps = {
    organizationSlug,
    wrapperStyle,
  };

  const counterQueries = useCounterQueries();
  const mutationOptions = {
    refetchQueries: [...getRefetchListQueries(List.INBOX), ...counterQueries],
  };

  const mutationOptionsOnKeep = {
    refetchQueries: [
      ...getRefetchListQueries(List.INBOX),
      ...counterQueries,
      {
        query: documentQueries.forDraftForm,
        variables: {
          id: documentId,
        },
      },
    ],
  };

  const [deleteDuplicate] = useDeleteDocumentMutation(mutationOptions);
  const onDeleteDuplicate = useCallback(
    async (docId: string) => {
      try {
        await deleteDuplicate({
          variables: {
            id: docId,
          },
        });

        success(deletedDuplicateText);
        navigate(cycleInboxLink);
      } catch (e) {
        showError(e as GQLError);

        return validationErrorsExtractor(e);
      }
    },
    [
      deleteDuplicate,
      success,
      deletedDuplicateText,
      navigate,
      cycleInboxLink,
      showError,
    ]
  );

  const [keepDuplicate] = useKeepDuplicateMutation(mutationOptionsOnKeep);
  const onKeepDuplicate = useCallback(
    async (docId: string) => {
      try {
        await keepDuplicate({
          variables: {
            docId,
          },
        });

        success(keepDuplicateText);
        navigate(inboxLink);
      } catch (e) {
        showError(e as GQLError);

        return validationErrorsExtractor(e);
      }
    },
    [keepDuplicate, success, keepDuplicateText, navigate, inboxLink, showError]
  );

  const [deleteOriginal] = useDeleteOriginalMutation(mutationOptionsOnKeep);
  const onDeleteOriginal = useCallback(
    async (docId: string) => {
      try {
        await deleteOriginal({
          variables: {
            docId,
          },
        });

        success(deletedOriginalText);
        navigate(inboxLink);
      } catch (e) {
        showError(e as GQLError);

        return validationErrorsExtractor(e);
      }
    },
    [
      deleteOriginal,
      deletedOriginalText,
      inboxLink,
      navigate,
      showError,
      success,
    ]
  );

  const { documentFile: duplicateDocumentFile } = useDocumentFile({
    documentId,
  });

  const { documentFile: originalDocumentFile } = useDocumentFile({
    documentId: originalDocumentId,
  });

  return (
    <DuplicateLayout>
      <DuplicateRowLayout>
        <Box padding="space5 space16 0">
          <BackButton
            onClick={() => {
              navigate(inboxLink);
            }}
          >
            {t('documentDetailsCard.backToList')}
          </BackButton>
        </Box>
      </DuplicateRowLayout>
      <DuplicateRowLayout>
        <DocumentLayout style={{ margin: '0 1rem' }} id="compare-duplicate">
          <DocumentViewerStyled
            documentId={originalDocumentId}
            documentFile={originalDocumentFile}
            view={firstDocumentView}
            setView={setFirstDocumentView}
            hideAttachmentSection
          />
          <DocumentDetailsCardContainer
            documentId={originalDocumentId}
            header={{
              title: t('documentDetailsCard.documentFileViewerHeader.original'),
              color: colors.gray400,
            }}
            {...sharedProps}
            renderActions={doc => {
              if (
                doc.status === DocumentStatus.New ||
                doc.status === DocumentStatus.Rejected
              ) {
                return [
                  <ConfirmationPopoverDeprecated
                    key="delete-original-button"
                    title={t('areYouSure')}
                    onConfirm={() => void onDeleteOriginal(originalDocumentId)}
                    closeText={t('no')}
                    confirmText={t('yes')}
                    placement="top"
                    trigger={({ triggerProps, triggerRef }) => (
                      <Button
                        size="small"
                        variant="secondary"
                        color="red"
                        data-cy="delete-original-button"
                        {...triggerProps}
                        ref={triggerRef}
                      >
                        {t('documentDetailsCard.actions.deleteOriginal')}{' '}
                      </Button>
                    )}
                  ></ConfirmationPopoverDeprecated>,
                ];
              }

              if (
                doc.status === DocumentStatus.Approved ||
                doc.status === DocumentStatus.Open
              ) {
                return [
                  <ConfirmationPopoverDeprecated
                    key="delete-original-button"
                    title={t('areYouSure')}
                    onConfirm={() => void onDeleteOriginal(originalDocumentId)}
                    closeText={t('no')}
                    confirmText={t('yes')}
                    placement="top"
                    trigger={({ triggerProps, triggerRef }) => (
                      <Button
                        size="small"
                        variant="secondary"
                        color="red"
                        data-cy="delete-original-button"
                        {...triggerProps}
                        ref={triggerRef}
                      >
                        {t('documentDetailsCard.actions.deleteOriginal')}{' '}
                      </Button>
                    )}
                  ></ConfirmationPopoverDeprecated>,
                  <Button
                    key="reset-original"
                    size="small"
                    variant="secondary"
                    // @TODO explicit MouseEvent<HTMLButtonElement> type should not be required
                    // See: https://github.com/microsoft/TypeScript/issues/44596
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      navigate(
                        `/${organizationSlug}${Routes.INBOX}/${originalDocumentId}`
                      );
                    }}
                  >
                    {t('documentDetailsCard.actions.resetOriginal')}
                  </Button>,
                ];
              }

              if (
                doc.status === DocumentStatus.Exported ||
                doc.status === DocumentStatus.Exporting
              ) {
                return [
                  <Grid
                    height="space32"
                    key="export-info"
                    templateColumns="auto 1fr"
                    alignItems="center"
                    gap="space8"
                  >
                    <Icon icon="infoOutline" color="blue500" size="space20" />
                    <Text fontSize="small" color="gray600">
                      {t('documentDetailsCard.actions.exportedInfo')}
                    </Text>
                  </Grid>,
                ];
              }

              return [];
            }}
          />
        </DocumentLayout>
        <DocumentLayout style={{ margin: '0 1rem' }} id="compare-original">
          <DocumentViewerStyled
            documentId={documentId}
            view={secondDocumentView}
            setView={setSecondDocumentView}
            documentFile={duplicateDocumentFile}
            hideAttachmentSection
          />
          <DocumentDetailsCardContainer
            documentId={documentId}
            header={{
              title: t(
                'documentDetailsCard.documentFileViewerHeader.duplicate'
              ),
              secondaryTitle: t(
                'documentDetailsCard.documentFileViewerHeader.duplicateInfoText'
              ),
              color: colors.yellow500,
            }}
            {...sharedProps}
            wrapperStyle={sharedProps.wrapperStyle}
            renderActions={() => {
              return [
                <Button
                  key="keep-duplicate-button"
                  width="20%"
                  size="small"
                  variant="secondary"
                  data-cy="keep-duplicate-button"
                  onClick={() => void onKeepDuplicate(documentId)}
                >
                  {t('documentDetailsCard.actions.keep')}
                </Button>,
                <ConfirmationPopoverDeprecated
                  key="delete-duplicate-button"
                  title={t('areYouSure')}
                  onConfirm={() => void onDeleteDuplicate(documentId)}
                  closeText={t('no')}
                  confirmText={t('yes')}
                  placement="top"
                  trigger={({ triggerProps, triggerRef }) => (
                    <Button
                      size="small"
                      color="red"
                      data-cy="delete-duplicate-button"
                      {...triggerProps}
                      ref={triggerRef}
                    >
                      {t('documentDetailsCard.actions.deleteDuplicate')}
                    </Button>
                  )}
                ></ConfirmationPopoverDeprecated>,
              ];
            }}
          />
        </DocumentLayout>
      </DuplicateRowLayout>
    </DuplicateLayout>
  );
};

const generateDocumentLink = ({
  organizationSlug,
  documentId,
  cursor,
  filtersAndSortFromUrl,
}: {
  organizationSlug: string;
  documentId: string;
  cursor: string | null;
  filtersAndSortFromUrl: URLSearchParams;
}): Partial<Path> => {
  const pathname = generatePath(
    `/:${AppRouteParams.organizationSlug}${Routes.INBOX}/:${DocumentProcessingRouteParams.documentId}`,
    { organizationSlug, documentId }
  );

  const searchParams = new URLSearchParams(filtersAndSortFromUrl);
  if (cursor) {
    searchParams.set('cursor', cursor);
  }

  return { pathname, search: searchParams.toString() };
};

const generateTransactionLink = ({
  organizationSlug,
  transactionId,
  cursor,
  filtersAndSortFromUrl,
}: {
  organizationSlug: string;
  transactionId: string;
  cursor: string | null;
  filtersAndSortFromUrl: URLSearchParams;
}): Partial<Path> => {
  const pathname = generatePath(
    `/:${AppRouteParams.organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}/:transactionId`,
    { organizationSlug, transactionId }
  );

  const searchParams = new URLSearchParams(filtersAndSortFromUrl);
  if (cursor) {
    searchParams.set('cursor', cursor);
  }

  return { pathname, search: searchParams.toString() };
};

import { DATE_FORMAT_DOTTED } from 'constants/dateFormat';
import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from 'providers/OrganizationProvider';

type DateRangeOptions = {
  days: number;
  timezone?: string;
  format?: string;
};

type DateRange = {
  fromDate: moment.Moment;
  toDate: moment.Moment;
  fromDateISOString: string;
  toDateISOString: string;
  fromDateFormatted: string;
  toDateFormatted: string;
};

/**
 * Returns the date range for the last `n` days in a given timezone.
 *
 * @param {DateRangeOptions} options - Configuration options
 * @returns {DateRange} - Date range object with various formats
 */
export const getDateRangeForLastNDays = ({
  days,
  timezone = DEFAULT_TIMEZONE,
  format = DATE_FORMAT_DOTTED,
}: DateRangeOptions): DateRange => {
  const fromDate = moment
    .tz(moment().subtract(days, 'days').startOf('day'), timezone)
    .utc();

  const toDate = moment.tz(new Date(), timezone).utc();

  const dateRange: DateRange = {
    fromDate,
    toDate,
    fromDateISOString: fromDate.toISOString(),
    toDateISOString: toDate.toISOString(),
    fromDateFormatted: fromDate.format(format),
    toDateFormatted: toDate.format(format),
  };

  return dateRange;
};

/**
 * Returns the date range for the next `n` days in a given timezone.
 *
 * @param {DateRangeOptions} options - Configuration options
 * @returns {DateRange} - Date range object with various formats
 */
export const getDateRangeForNextNDays = ({
  days,
  timezone = DEFAULT_TIMEZONE,
  format = DATE_FORMAT_DOTTED,
}: Partial<DateRangeOptions>): DateRange => {
  const fromDate = moment.tz(new Date(), timezone).utc();
  const toDate = moment.tz(new Date(), timezone).add(days, 'days').utc();

  const dateRange: DateRange = {
    fromDate,
    toDate,
    fromDateISOString: fromDate.toISOString(),
    toDateISOString: toDate.toISOString(),
    fromDateFormatted: fromDate.format(format),
    toDateFormatted: toDate.format(format),
  };

  return dateRange;
};

import { Flex, FlexProps, Heading, Link } from '@candisio/design-system';
import { Maybe } from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface SuccessExportTableHeadingProps {
  variant: 'success';
  count: number;
  code: 'success';
}

interface WarningExportTableHeadingProps {
  variant: 'warning';
  count: number;
  code: WarningCode;
}

interface ErrorExportTableHeadingProps {
  variant: 'error';
  count: number;
  code: ErrorCode;
}
interface InProgressExportTableHeadingProps {
  variant: 'inProgress';
  count: number;
  code: 'inProgress';
}

export type ExportTableHeadingProps =
  | SuccessExportTableHeadingProps
  | WarningExportTableHeadingProps
  | ErrorExportTableHeadingProps
  | InProgressExportTableHeadingProps;

export type Variant = ExportTableHeadingProps['variant'];
export type Code = ExportTableHeadingProps['code'];

const colorVariant: Record<Variant, string> = {
  success: 'none',
  warning: 'yellowbg',
  error: 'redbg',
  inProgress: 'none',
} as const;

const HeadingWrapper = ({ children }: { children: ReactNode }) => (
  <Flex direction="column" gap="space8">
    {children}
  </Flex>
);

const InfoMessage = ({
  children,
  variant,
  ...rest
}: {
  children: ReactNode;
  variant: Variant;
} & FlexProps) => {
  if (variant === 'success') return null;

  const backgroundColor = colorVariant[variant];

  return (
    <Flex
      background={backgroundColor}
      padding="space4 space8"
      borderRadius="medium"
      justifyContent="space-between"
      rowGap="space4"
      {...rest}
    >
      {children}
    </Flex>
  );
};

const errorsTranslationMap = {
  E101: 'errors.e101',
  E102: 'errors.e102',
  E103: 'errors.e103',
  E104: 'errors.e104',
  E105: 'errors.e105',
  E106: 'errors.e106',
  E107: 'errors.e107',
  E108: 'errors.e108',
  E109: 'errors.e109',
  E110: 'errors.e110',
  E111: 'errors.e111',
  E112: 'errors.e112',
  E113: 'errors.e113',
  E114: 'errors.e114',
  E115: 'errors.e115',
  E116: 'errors.e116',
  E117: 'errors.e117',
  E118: 'errors.e118',
  E119: 'errors.e119',
  E120: 'errors.e120',
  E121: 'errors.e121',
  E122: 'errors.e122',
  E123: 'errors.e123',
  E124: 'errors.e124',
  E125: 'errors.e125',
  UNKNOWN: 'errors.unknown',
} as const;

const errorsInfoLinksMap = {
  E101: 'errorsInfoLinks.e101',
  E102: 'errorsInfoLinks.e102',
  E103: 'errorsInfoLinks.e103',
  E104: 'errorsInfoLinks.e104',
  E105: 'errorsInfoLinks.e105',
  E106: 'errorsInfoLinks.e106',
  E107: 'errorsInfoLinks.e107',
  E108: 'errorsInfoLinks.e108',
  E109: 'errorsInfoLinks.e109',
  E110: 'errorsInfoLinks.e110',
  E111: 'errorsInfoLinks.e111',
  E112: 'errorsInfoLinks.e112',
  E113: 'errorsInfoLinks.e113',
  E114: 'errorsInfoLinks.e114',
  E115: 'errorsInfoLinks.e115',
  E116: 'errorsInfoLinks.e116',
  E117: 'errorsInfoLinks.e117',
  E118: 'errorsInfoLinks.e118',
  E119: 'errorsInfoLinks.e119',
  E120: 'errorsInfoLinks.e120',
  E121: 'errorsInfoLinks.e121',
  E122: 'errorsInfoLinks.e122',
  E123: 'errorsInfoLinks.e123',
  E124: 'errorsInfoLinks.e124',
  E125: 'errorsInfoLinks.e125',
  UNKNOWN: 'errorsInfoLinks.unknown',
} as const;

export type ErrorCode = keyof typeof errorsTranslationMap;

export const isErrorCode = (code: Maybe<string>): code is ErrorCode =>
  !!code && code in errorsTranslationMap;

const warningsTranslationMap = {
  P101: 'warnings.p101',
  P102: 'warnings.p102',
  P103: 'warnings.p103',
  UNKNOWN: 'warnings.unknown',
} as const;

const warningsInfoLinksMap = {
  P101: 'warningsInfoLinks.p101',
  P102: 'warningsInfoLinks.p102',
  P103: 'warningsInfoLinks.p103',
  UNKNOWN: 'warningsInfoLinks.unknown',
} as const;

export type WarningCode = keyof typeof warningsTranslationMap;

export const isWarningCode = (code: Maybe<string>): code is WarningCode =>
  !!code && code in warningsTranslationMap;

const SuccessTableHeading = ({ count }: SuccessExportTableHeadingProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  return (
    <HeadingWrapper>
      <Heading as="h3">{t('table.header.success.title', { count })}</Heading>
    </HeadingWrapper>
  );
};

const PartiallyExportedTableHeading = ({
  code,
  count,
}: WarningExportTableHeadingProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  const warningInfoMessage = warningsTranslationMap[code];
  const warningInfoLink = warningsInfoLinksMap[code];

  return (
    <HeadingWrapper>
      <Flex alignItems="center" justifyContent="space-between">
        <Heading as="h3">{t('table.header.warning.title', { count })}</Heading>
        <Link
          external
          href={t(warningInfoLink)}
          style={{ minWidth: 'fit-content' }}
        >
          {t('table.header.linkText')}
        </Link>
      </Flex>
      <InfoMessage variant="warning">{t(warningInfoMessage)}</InfoMessage>
    </HeadingWrapper>
  );
};

const ErrorTableHeading = ({ code, count }: ErrorExportTableHeadingProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);
  const { isActive: isSapActive } = useSap();

  const errorInfoMessage = isSapActive
    ? 'sapErrors.generic'
    : errorsTranslationMap[code];

  const errorInfoLink = errorsInfoLinksMap[code];

  return (
    <HeadingWrapper>
      <Flex alignItems="center" justifyContent="space-between">
        <Heading as="h3">{t('table.header.error.title', { count })}</Heading>
        {!isSapActive && (
          <Link
            external
            href={t(errorInfoLink)}
            style={{ minWidth: 'fit-content' }}
          >
            {t('table.header.linkText')}
          </Link>
        )}
      </Flex>
      <InfoMessage variant="error">{t(errorInfoMessage)}</InfoMessage>
    </HeadingWrapper>
  );
};

export const ExportTableHeading = (props: ExportTableHeadingProps) => {
  if (props.variant === 'success') {
    return <SuccessTableHeading {...props} />;
  }

  if (props.variant === 'warning') {
    return <PartiallyExportedTableHeading {...props} />;
  }

  if (props.variant === 'error') {
    return <ErrorTableHeading {...props} />;
  }

  return null;
};

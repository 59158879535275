import { Grid, Link, Text } from '@candisio/design-system';
import { Collapse } from 'components/History/items/Collapse';
import { RouterLink } from 'components/RouterLink/RouterLink';
import {
  ExportType,
  ReimbursementCaseItemExportStatusUpdatedEventDataFragment,
} from 'generated-types/graphql.types';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { buildDatevDocumentLink } from 'utils/build_datev_link_for_document';
import { useDefineExportView } from 'views/Integrations/Export/Manifest';
import { Sidebar, View } from 'views/Integrations/Export/types';

const mappedExportTypeTranslations: Record<ExportType, string> = {
  ADDISON_FORMAT:
    'common:export.exportForm.selectExportType.types.addison_format',
  API: 'common:export.exportForm.selectExportType.types.candis_api',
  CANDIS_CSV: 'common:export.exportForm.selectExportType.types.candis_csv',
  DATEV: 'common:export.exportForm.selectExportType.types.datev',
  DATEV_BDS: 'common:export.exportForm.selectExportType.types.datev_bds',
  DATEV_CSV: 'common:export.exportForm.selectExportType.types.datev_csv',
  DATEV_DCO: '',
  DATEV_DXSO: '',
  DATEV_DXSO_ZIP:
    'common:export.exportForm.selectExportType.types.datev_dxso_zip',
  SAP_B1: 'common:export.exportForm.selectExportType.types.sap_b1',
  SAP_CSV: 'common:export.exportForm.selectExportType.types.sap_csv',
  ZIP: 'common:export.exportForm.selectExportType.types.zip',
};

export const ReimbursementCaseItemExportStatusUpdated = ({
  properties,
  reimbursementItemMetadata,
}: ReimbursementCaseItemExportStatusUpdatedEventDataFragment) => {
  const [t] = useTranslation();
  const orgId = useOrganizationId();
  const failedReimbursementItems = properties.failedItems ?? [];

  const titleReimbursement = reimbursementItemMetadata?.title;

  const viewDef = useDefineExportView({
    exportId: properties.exportGuid,
    sidebar: Sidebar.HISTORY,
    view: View.HISTORY,
    entity: 'REIMBURSEMENT_ITEMS',
  });

  const exportLink = `/${orgId}${viewDef.url}`;

  if (failedReimbursementItems.length > 0) {
    return (
      <Grid gap="space4">
        <Text>
          {t('reimbursement:timeline.exportError', {
            title: titleReimbursement,
            count: failedReimbursementItems.length,
          })}
        </Text>
        {properties.exportGuid && orgId && (
          <RouterLink to={exportLink}>
            {t('reimbursement:timeline.openHistory')}
          </RouterLink>
        )}
      </Grid>
    );
  }

  const exportReimbursementItems = properties.successfulItems ?? [];
  const exportType = properties.exportType;

  const datevLinks = exportReimbursementItems
    .map(item => ({
      link: item?.datevLocationParams
        ? buildDatevDocumentLink({
            clientNumber: item.datevLocationParams.datevClientNumber,
            consultantNumber: item.datevLocationParams.datevConsultantNumber,
            documentExportGuid: item.datevLocationParams.entityExportGuid,
          })
        : null,
      title: item?.reimbursementItemTitle,
    }))
    .filter(item => item.link !== null);

  return (
    <Grid gap="space8">
      <Grid>
        <Text>
          {t('reimbursement:timeline.exportSuccess', {
            title: titleReimbursement,
            exportType: exportType
              ? t(mappedExportTypeTranslations[exportType])
              : '',
          })}
        </Text>
        {properties.exportGuid && orgId && (
          <RouterLink to={exportLink}>
            {t('reimbursement:timeline.openHistory')}
          </RouterLink>
        )}
      </Grid>
      <Collapse>
        {datevLinks.length > 0 && (
          <Grid>
            <Text>{t('reimbursement:timeline.openExpensesInDatev')}</Text>
            <Grid gap="space4">
              {datevLinks.map((item, index) => (
                <Link href={item.link ?? ''} external key={index}>
                  {t('reimbursement:timeline.linkExpenseDatevText', {
                    title: item.title,
                  })}
                </Link>
              ))}
            </Grid>
          </Grid>
        )}
      </Collapse>
    </Grid>
  );
};

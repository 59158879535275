import { PulseIndicator } from 'components/PulseIndicator/PulseIndicator';
import { useDocumentUploadErrors } from '../hooks/useDocumentUploadErrors';

export const MenuErrorIndicator = () => {
  const hasErrors = useDocumentUploadErrors();

  if (!hasErrors) return null;

  return <PulseIndicator status="urgent" source="documentUploadErrors" />;
};

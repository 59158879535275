import { Flex, Link, Text } from '@candisio/design-system';
import {
  EInvoiceError,
  GetEInvoiceValidationProtocolDataQuery,
  Locale,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

type EInvoiceValidationErrorsCardProps = {
  eInvoiceValidationErrors?: NonNullable<
    GetEInvoiceValidationProtocolDataQuery['getDocument']
  >['eInvoiceErrors'];
  currentUserLocale?: Locale | null;
};

export const EInvoiceValidationErrorsCard = ({
  eInvoiceValidationErrors,
  currentUserLocale,
}: EInvoiceValidationErrorsCardProps) => {
  const [t] = useTranslation();

  const isOutdatedFormatError =
    eInvoiceValidationErrors?.length === 1 &&
    (eInvoiceValidationErrors[0].message === EInvoiceError.OutdatedFormat ||
      eInvoiceValidationErrors[0].code === EInvoiceError.OutdatedFormat);

  return (
    <Flex direction="column" gap="space16" paddingTop="space32">
      <Text fontSize="large" fontWeight="semibold">
        {t(
          'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.errorsTitle'
        )}
      </Text>
      {eInvoiceValidationErrors?.map(error => {
        return (
          <Flex
            background="gray0"
            border="1px solid gray250"
            padding="space12"
            direction="column"
            borderRadius="small"
            wordBreak="break-word"
            gap="space12"
            key={error.message}
          >
            {error.code && (
              <Text fontSize="basic" fontWeight="semibold">
                {error.code}
              </Text>
            )}
            {error.message && (
              <Text>
                {isOutdatedFormatError
                  ? t(
                      'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.outdatedFormatDetailedError'
                    )
                  : error.message}
              </Text>
            )}
            {error.location && (
              <Text wordBreak="break-all">{error.location}</Text>
            )}
            {error.codeHelpCenterUrl && (
              <Link
                href={
                  currentUserLocale === 'EN'
                    ? error.codeHelpCenterUrl.en
                    : error.codeHelpCenterUrl.de
                }
                external
              >
                {t(
                  'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.learnMore.helpCenterArticle.title'
                )}
              </Link>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

import { defaultTheme } from '@candisio/design-system';
import { MentionsInput as ReactMentionsInput } from 'react-mentions';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

export const MentionsInput = styled(ReactMentionsInput)<{
  ['data-show-document-relations']?: boolean;
  value: string;
}>`
  min-height: 40px;

  textarea {
    border: 1px solid ${defaultTheme.colors.gray300};
    border-radius: 8px;
    line-height: 1.6;
    color: ${defaultTheme.colors.gray800};

    &:focus,
    &:focus-within {
      box-shadow: ${({
        'data-show-document-relations': showDocumentRelations,
      }) =>
        showDocumentRelations ? 'none' : defaultTheme.shadows.elevatedShadow2};
      outline: none;
    }

    &::placeholder {
      font-size: ${defaultTheme.fontSizes.basic};
      line-height: 1.6;
      color: ${defaultTheme.colors.gray450};
      font-style: ${({
        'data-show-document-relations': showDocumentRelations,
      }) => (showDocumentRelations ? 'initial' : 'italic')};
    }

    ${({ 'data-show-document-relations': showDocumentRelations }) =>
      showDocumentRelations &&
      `
        &:hover:not(:focus) {
          background: ${defaultTheme.colors.gray50} !important;
        }
        &:focus,
        &:focus-within {
          border-color: ${defaultTheme.colors.blue500};
        }
    `}
  }
`;

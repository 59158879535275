import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { getTranslationContext } from 'orgConfig';
import { useSap } from 'orgConfig/sap';
import { useTranslation } from 'react-i18next';

export const Note = () => {
  const [t] = useTranslation();
  const { isActiveIntegration: isSap } = useSap();

  const commonProps = {
    name: 'bookings[0].note',
    label: t('document.requestApproval.inputs.note.label', {
      context: getTranslationContext(),
    }),
    readOnly: true,
  };

  return isSap ? (
    <HookFormTextField {...commonProps} />
  ) : (
    <HookFormTextareaField {...commonProps} />
  );
};

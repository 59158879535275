import { Flex, Icon, Image, Paragraph, Text } from '@candisio/design-system';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE, i18n } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import wireless from '../../media/wireless.svg';

const variant = (t: i18n.TFunction): Record<CardType, JSX.Element> => ({
  BLACK: (
    <Flex alignItems="start" gap="space4">
      <Icon
        height="120px"
        icon="logo"
        width="120px"
        color="#382B3B"
        style={{ marginLeft: '-10px' }}
      />
      <Image alt="wireless logo" src={wireless} style={{ marginTop: '15px' }} />
    </Flex>
  ),
  PHYSICAL: (
    <Flex alignItems="start" gap="space4">
      <Icon
        height="95px"
        icon="logo"
        width="105px"
        color="#382B3B"
        style={{ marginLeft: '-10px' }}
      />
      <Image alt="wireless logo" src={wireless} style={{ marginTop: '15px' }} />
    </Flex>
  ),
  SINGLE_USE: (
    <Flex
      gap="space16"
      alignItems="self-end"
      color="yellow500"
      justifyContent="space-between"
    >
      <Icon
        height="85px"
        icon="logo"
        width="85px"
        style={{ marginLeft: '-10px' }}
      />
      <Text
        color="yellow500"
        wordBreak="break-word"
        fontSize="basic"
        textAlign="left"
        style={{ marginBottom: '8px' }}
      >
        <Paragraph>1x</Paragraph>
        {`${t('dashboard.drawer.card.virtual')}`}
      </Text>
    </Flex>
  ),
  VIRTUAL: (
    <Flex
      gap="space16"
      color="blue500"
      alignItems="self-end"
      justifyContent="space-between"
    >
      <Icon
        height="85px"
        icon="logo"
        width="85px"
        style={{ marginLeft: '-10px' }}
      />
      <Text
        color="blue500"
        wordBreak="break-word"
        fontSize="basic"
        style={{ marginBottom: '8px' }}
      >
        {`${t('dashboard.drawer.card.virtual')}`}
      </Text>
    </Flex>
  ),
});

interface HeaderProps {
  cardType: CardType;
}

export const Header = ({ cardType }: HeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const headerVariant = variant(t);

  return headerVariant[cardType];
};

import {
  Box,
  Flex,
  Link,
  Modal,
  type ModalProps,
  ScrollBox,
  Spinner,
  Text,
} from '@candisio/design-system';
import { EInvoiceValidationDetailsModalFooter } from 'components/EInvoiceErrorDetails/EInvoiceValidationDetailsModalFooter';
import { EInvoiceValidationErrorsCard } from 'components/EInvoiceErrorDetails/EInvoiceValidationErrorsCard';
import { useEInvoiceValidationDetails } from 'components/EInvoiceErrorDetails/useEInvoiceValidationDetails';
import { useTranslation } from 'react-i18next';

type EInvoiceValidationProtocolModalProps = {
  onCloseModal: () => void;
  modalProps: ModalProps;
  documentId: string;
};

export const EInvoiceValidationDetailsModal = ({
  onCloseModal,
  modalProps,
  documentId,
}: EInvoiceValidationProtocolModalProps) => {
  const [t] = useTranslation();

  const {
    eInvoiceValidationErrors,
    isDataLoading,
    handleCopy,
    intro,
    requestNewInvoice,
    format,
    currentUserLocale,
  } = useEInvoiceValidationDetails({ documentId });

  return (
    <Modal
      background="gray200"
      scrollDirection="none"
      padding={0}
      overflow="hidden"
      height="max-content"
      width="70vw"
      maxWidth="50rem"
      minHeight="40rem"
      onClose={onCloseModal}
      closeLabel={t('common.close')}
      title={t(
        'common:document.compact.history.invalidEInvoice.validationProtocolModal.title'
      )}
      {...modalProps}
    >
      {isDataLoading ? (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="space32" />
        </Flex>
      ) : (
        <Flex direction="column" overflow="hidden" height="100%">
          <Flex
            flexGrow="1"
            gap="space16"
            overflow="hidden"
            paddingTop="space8"
            paddingBottom="0"
            whiteSpace="pre-wrap"
          >
            <Box
              width="100%"
              height="100%"
              maxWidth="100%"
              whiteSpace="pre-wrap"
            >
              <ScrollBox
                scrollDirection="y"
                height="100%"
                padding="space8 space32 space16"
              >
                <Flex direction="column" gap="space16" fontSize="small">
                  <Text>{intro}</Text>
                  <Text>
                    {requestNewInvoice}{' '}
                    <Link
                      href={t(
                        'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.learnMore.helpCenterArticle.url'
                      )}
                      external
                    >
                      {t(
                        'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.learnMore.helpCenterArticle.title'
                      )}
                    </Link>
                  </Text>
                  <Text>{format}</Text>
                </Flex>
                {Boolean((eInvoiceValidationErrors?.length ?? 0) > 0) && (
                  <EInvoiceValidationErrorsCard
                    eInvoiceValidationErrors={eInvoiceValidationErrors}
                    currentUserLocale={currentUserLocale}
                  />
                )}
              </ScrollBox>
            </Box>
          </Flex>
          <EInvoiceValidationDetailsModalFooter
            documentId={documentId}
            eInvoiceValidationErrors={eInvoiceValidationErrors}
            handleCopy={handleCopy}
          />
        </Flex>
      )}
    </Modal>
  );
};

import { Swimlane } from 'components/Swimlane/Swimlane';
import { SwimlaneHeader } from 'components/Swimlane/SwimlaneHeader';
import { SwimlaneHeaderCount } from 'components/Swimlane/SwimlaneHeaderCount';
import { SwimlaneTiles } from 'components/Swimlane/SwimlaneTiles';
import { SwimlaneTileExpenseContent } from './content/SwimlaneTileExpenseContent';
import { ArchiveReimbursementCase } from 'views/Reimbursement/hooks/useReimbursementDashboardInsights';
import { ReimbursementTileShowAll } from './content/ReimbursementTileShowAll';
import { ReimbursementCaseEmptyTile } from './content/ReimbursementCaseEmptyTile';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { PromoExpenseMobile } from './promos/PromoExpenseMobile';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { PromoExpenseUpcomingTravel } from './promos/PromoExpenseUpcomingTravel';

export const ReimbursementOpenSwimlane = ({
  isVisible,
  openReimbursementCases,
  totalCount,
}: {
  isVisible: boolean;
  openReimbursementCases?: ArchiveReimbursementCase[];
  totalCount?: number;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const {
    isPromotionSeen: isMobilePromoSeen,
    setPromotionIsSeen: setMobilePromoIsSeen,
  } = useSyncedPromoPreference(TOUR_POPOVER_IDS.EXPENSE_MOBILE);

  const {
    isPromotionSeen: isUpcomingTravelPromoSeen,
    setPromotionIsSeen: setUpcomingTravelPromoIsSeen,
  } = useSyncedPromoPreference(TOUR_POPOVER_IDS.EXPENSE_UPCOMING_TRAVEL);

  if (!isVisible) {
    return null;
  }

  const showMobilePromo = !isMobilePromoSeen && !openReimbursementCases?.length;
  const showUpcomingPromo =
    !isUpcomingTravelPromoSeen && !!openReimbursementCases?.length;

  return (
    <Swimlane data-cy="openSwimlane">
      <SwimlaneTiles
        contentTileCount={totalCount}
        promoTileCount={showUpcomingPromo || showMobilePromo ? 2 : undefined}
      >
        {({
          contentTileVisibleCount,
          isShowAllTileVisible,
          isPromoTileVisible,
        }) => {
          return (
            <>
              <SwimlaneHeader
                icon="walletClock"
                label={t('dashboard.swimlanes.open')}
              >
                <SwimlaneHeaderCount
                  count={contentTileVisibleCount}
                  totalCount={totalCount}
                />
              </SwimlaneHeader>
              {contentTileVisibleCount === 0 && (
                <>
                  <ReimbursementCaseEmptyTile status="open" />
                  {showMobilePromo && (
                    <PromoExpenseMobile onClose={setMobilePromoIsSeen} />
                  )}
                </>
              )}
              {openReimbursementCases &&
                openReimbursementCases.length > 0 &&
                Array.from({ length: contentTileVisibleCount }, (_, index) => (
                  <SwimlaneTileExpenseContent
                    key={openReimbursementCases[index].id}
                    expenseCase={openReimbursementCases[index]}
                  />
                ))}

              {isShowAllTileVisible && (
                <ReimbursementTileShowAll status="open" />
              )}

              {isPromoTileVisible && showUpcomingPromo && (
                <PromoExpenseUpcomingTravel
                  onClose={setUpcomingTravelPromoIsSeen}
                />
              )}
            </>
          );
        }}
      </SwimlaneTiles>
    </Swimlane>
  );
};

import { DiscountDateWithPercentage } from 'components/DocumentsTable/types';

export const calculateCashDiscount = (
  discounts?: DiscountDateWithPercentage[]
): DiscountDateWithPercentage | undefined => {
  if (!discounts || discounts.length === 0) {
    return undefined;
  }

  let totalDiscountAmount: number = 0;

  for (const discount of discounts) {
    if (discount.discountAmount) {
      totalDiscountAmount += discount.discountAmount;
    }
  }

  return {
    // All discounts have the same payment date which is earliest split dueDate
    discountPaymentDate: discounts[0].discountPaymentDate,
    discountAmount: totalDiscountAmount,
    discountPercentage: discounts[0].discountPercentage,
  };
};

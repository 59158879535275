import { Button, Flex, Heading } from '@candisio/design-system';
import {
  BILLING_HASH,
  CREDIT_CARD_SEARCH_PARAM_ON_VIEW,
  ISSUE_NEW_CARD_HASH,
} from 'containers/credit-cards/constants';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { Routes } from 'models';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { useCreditCardsAccess } from './CreditCardsTabs/useCreditCardsAccess';
import { exportCardId, statementCardId } from './utils/utils';

export const CreditCardsHeader = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const navigate = useNavigate();
  const location = useLocation();
  const organizationSlug = useOrganizationId();
  const { canIssueCards } = usePermissionsForCreditCards();

  const creditCardsStatementsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsStatements
  );

  const settingsCCLink = `/${organizationSlug}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`;

  const openIssueNewCardModal = () => {
    const existingSearchParams = new URLSearchParams(location.search);
    existingSearchParams.set(CREDIT_CARD_SEARCH_PARAM_ON_VIEW, 'true');
    navigate({
      hash: ISSUE_NEW_CARD_HASH,
      search: existingSearchParams.toString(),
    });
  };

  const openOrganizationSettingsDrawer = () => {
    navigate({ hash: BILLING_HASH });
  };

  const navigateToCreditCardStatementCardInSettings = () => {
    navigate({
      pathname: settingsCCLink,
      hash: statementCardId,
    });
  };

  const navigateToCreditCardExportCardInSettings = () => {
    navigate({
      pathname: settingsCCLink,
      hash: exportCardId,
    });
  };

  const { hasAccess } = useCreditCardsAccess();

  return (
    <Flex justifyContent="space-between">
      <Heading as="h1">{t('creditCardPage.title')}</Heading>
      <Flex gap="space8" alignItems="center">
        {hasAccess && (
          <Button variant="secondary" onClick={openOrganizationSettingsDrawer}>
            {t('creditCardPage.organizationSettings')}
          </Button>
        )}
        {hasAccess && (
          <Button
            variant="secondary"
            onClick={navigateToCreditCardExportCardInSettings}
          >
            {t('creditCardPage.exportSettingsLink')}
          </Button>
        )}
        {!creditCardsStatementsFF && hasAccess && (
          <Button
            variant="secondary"
            onClick={navigateToCreditCardStatementCardInSettings}
          >
            {t('creditCardPage.creditCardSettingLink')}
          </Button>
        )}

        <Button icon="plus" alignSelf="center" onClick={openIssueNewCardModal}>
          {canIssueCards
            ? t('creditCardPage.issueNewCardCTA')
            : t('transactions:carousel.requestNewCard')}
        </Button>
      </Flex>
    </Flex>
  );
};

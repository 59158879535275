import { Link, Text } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom-v5-compat';
import { APPROVING_EDIT_MODE } from 'views/Reimbursement/context/ReimbursementFormsProvider';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { REIMBURSEMENT_VIEW_ROUTES } from 'views/Reimbursement/hooks/useReimbursementNavigation';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/utils/constants';

interface ReimbursementSuccessMessageWithDeepLinkProps {
  translationKey: string;
  showDeepLink?: boolean;
  reimbursement?: Reimbursement;
}

export const REIMBURSEMENT_SUCCESS_ACTION_MESSAGE =
  'reimbursement-success-action-message';
export const ReimbursementSuccessMessageWithDeepLink = ({
  showDeepLink = true,
  translationKey,
  reimbursement,
}: ReimbursementSuccessMessageWithDeepLinkProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { searchParams } = useMutateSearchParams();
  const organizationSlug = useOrganizationId();
  const cursor = searchParams.get(REIMBURSEMENT_URL_PARAM.CURSOR);

  const { id, title } = reimbursement ?? {};

  const buildReimbursementArchiveUrl = () => {
    if (!id) return;

    if (cursor) {
      searchParams.set(REIMBURSEMENT_URL_PARAM.CURSOR, cursor);
    }

    searchParams.delete(REIMBURSEMENT_URL_PARAM.VIEW);
    searchParams.delete(APPROVING_EDIT_MODE.PARAM);
    const pathname = generatePath(REIMBURSEMENT_VIEW_ROUTES[Routes.ARCHIVE], {
      organizationSlug,
      reimbursementId: id,
    });

    const params = searchParams.toString();
    return params ? `${pathname}?${params}` : pathname;
  };

  return (
    <Trans
      t={t}
      data-testid={REIMBURSEMENT_SUCCESS_ACTION_MESSAGE}
      parent={Text}
      i18nKey={translationKey}
      values={showDeepLink && { linkText: title }}
    >
      Expense collection
      {showDeepLink && (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={buildReimbursementArchiveUrl()}
        >
          {title}
        </Link>
      )}
      successfully submitted
    </Trans>
  );
};

interface ToastMessageWithLinkProps {
  key: string;
  showDeepLink?: boolean;
}

export const useSuccessToastWithDeepLink = (reimbursement?: Reimbursement) => {
  const { success } = useToastMessage();

  const toastMessageWithLink = useCallback(
    ({ key, showDeepLink = true }: ToastMessageWithLinkProps) =>
      success(
        <ReimbursementSuccessMessageWithDeepLink
          translationKey={key}
          showDeepLink={showDeepLink}
          reimbursement={reimbursement}
        />
      ),
    [reimbursement, success]
  );

  return toastMessageWithLink;
};

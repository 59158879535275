import {
  Card,
  Grid,
  Link,
  Paragraph,
  Skeleton,
  Tag,
} from '@candisio/design-system';
import { useCheckPendingStatus } from 'containers/credit-cards/hooks/useCheckPendingStatus';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import { isEmpty } from 'lodash';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface ProcessingInfoBoxProps {
  cardId: string;
}

export const ProcessingInfoBox = ({ cardId }: ProcessingInfoBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { availablePendingActions, loading } = useCheckPendingStatus(cardId);
  const { canManageCards } = usePermissionsForCreditCards();
  const orgSlug = useOrganizationId();

  const translations = useMemo(() => {
    const walletLink = `/${orgSlug}${Routes.DASHBOARD}?cardId=${cardId}`;

    return {
      cardholder: {
        mobilePhoneNumberMissing: t(
          'creditCardFormDataPreview.cardholder.missingPhoneNumber'
        ),
        notAcceptedUsageAgreement: (
          <Trans
            t={t}
            parent="span"
            i18nKey="creditCardFormDataPreview.cardholder.usageAgreement"
          >
            You need to accept usage agreement in the
            <Link external href={walletLink}>
              {t('creditCardFormDataPreview.cardholder.wallet')}
            </Link>
          </Trans>
        ),
      },
      cardManager: {
        mobilePhoneNumberMissing: t(
          'creditCardFormDataPreview.cardManager.missingPhoneNumber'
        ),
        notAcceptedUsageAgreement: t(
          'creditCardFormDataPreview.cardManager.usageAgreement'
        ),
      },
    };
  }, [cardId, orgSlug, t]);

  if (loading) {
    return <Skeleton height="space80" width="100%" borderRadius="medium" />;
  }

  if (!isEmpty(availablePendingActions.missingPhoneNumber)) {
    return (
      <Card background="bluebg">
        <Grid gap="space8">
          <Tag color="blue">
            {t('creditCardFormDataPreview.cardManager.pending')}
          </Tag>
          <Paragraph fontSize="basic">
            {canManageCards
              ? translations.cardManager.mobilePhoneNumberMissing
              : translations.cardholder.mobilePhoneNumberMissing}
          </Paragraph>
        </Grid>
      </Card>
    );
  }

  if (!isEmpty(availablePendingActions.notAcceptedUsageAgreement)) {
    return (
      <Card background="bluebg">
        <Grid gap="space8">
          <Tag color="blue">
            {t('creditCardFormDataPreview.cardManager.pending')}
          </Tag>
          <Paragraph fontSize="basic">
            {canManageCards
              ? translations.cardManager.notAcceptedUsageAgreement
              : translations.cardholder.notAcceptedUsageAgreement}
          </Paragraph>
        </Grid>
      </Card>
    );
  }

  return (
    <Card background="bluebg">
      <Grid gap="space8">
        <Tag color="blue">
          {t('creditCardFormDataPreview.cardManager.issuing')}
        </Tag>
        <Paragraph fontSize="basic">
          {t('creditCardFormDataPreview.cardManager.issuingText')}
        </Paragraph>
      </Grid>
    </Card>
  );
};

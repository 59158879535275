import {
  Button,
  Card,
  Flex,
  Grid,
  Image,
  Separator,
  Text,
} from '@candisio/design-system';
import cardIcon from 'assets/credit-cards/icon-card.svg';
import { CardType } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CardLimitRequestInfoBox } from './CardLimitRequestInfoBox';

export const CardLimitFormHeader = ({
  cardType,
  onClick,
  showInfo,
  hasPendingLimitRequest,
}: {
  cardType?: CardType;
  onClick: () => void;
  showInfo: boolean;
  hasPendingLimitRequest: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { isCardManager } = useUserRoles();

  return (
    <Grid gap="space16">
      <Button
        justifySelf="start"
        icon="arrowLeft"
        variant="tertiary"
        onClick={onClick}
      >
        {t('ccRequestForm.goBackCTA')}
      </Button>
      {!isCardManager && showInfo && (
        <CardLimitRequestInfoBox
          hasPendingLimitRequest={hasPendingLimitRequest}
        />
      )}
      <Card padding={0} background="gray100" borderBottomRadius="none">
        <Grid
          templateColumns="auto 1fr"
          padding="space16"
          gap="space12"
          alignItems="center"
        >
          <Image src={cardIcon} alt="card icon" />
          <Flex alignItems="baseline" gap="space8">
            <Text fontSize="large">
              {t('creditCardFormDataPreview.cardLimits.title')}
            </Text>
            <Text>
              {t(
                cardType === CardType.SingleUse
                  ? 'creditCardFormDataPreview.cardLimits.singleUseSubtitle'
                  : 'creditCardFormDataPreview.cardLimits.subtitle'
              )}
            </Text>
          </Flex>
        </Grid>
        <Separator />
      </Card>
    </Grid>
  );
};

import { PropsWithChildren } from 'react';
import styles from './power-search-toolbar.module.css';
import clsx from 'clsx';

interface PowerSearchToolbarProps extends PropsWithChildren {
  className?: string;
}

export const PowerSearchToolbar = ({
  className,
  ...props
}: PowerSearchToolbarProps) => {
  return (
    <div className={clsx(styles.powerSearchToolbar, className)} {...props} />
  );
};

import {
  HookFormWorkflowField,
  HookFormWorkflowFieldProps,
} from 'components/HookFormFields/HookFormWorkFlowField';
import { FieldValues } from 'react-hook-form';

export const ReimbursementWorkflowField = <TFormValues extends FieldValues>({
  name,
  ...restProps
}: HookFormWorkflowFieldProps<TFormValues>) => {
  return <HookFormWorkflowField name={name} {...restProps} />;
};

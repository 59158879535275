import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { GeneralExpenseInvoiceData } from './GeneralExpenseInvoiceData';
import { extractedDataSummary } from './utils/extractedDataSummary';
import { FieldNames } from './utils/generateGeneralExpenseFormFieldNames';
import {
  ExtractionStatus,
  ExtractionStatusType,
} from './utils/invoiceDataExtractionHelpers';
import styles from './ReimbursementItemsSummary.module.css';

interface ExtractedGeneralExpenseInvoiceDataProps {
  fields: FieldNames;
  isExcluded: boolean;
  formIndex: number;
  isExpenseFormEditable: boolean;
  extractedData?: ReturnType<typeof extractedDataSummary>;
  extractionStatus: ExtractionStatusType;
  clearableField: string | undefined;
  onCleareableField: (field?: string) => () => void;
  onUpdateExpense: (index: number) => () => Promise<void>;
  onSetValue: UseFormSetValue<ReimbursementItemsFormOutput>;
}
export const ExtractedGeneralExpenseInvoiceData = ({
  fields,
  isExcluded,
  formIndex,
  extractionStatus,
  extractedData,
  onSetValue,
  onUpdateExpense,
  clearableField,
  onCleareableField,
  isExpenseFormEditable,
}: ExtractedGeneralExpenseInvoiceDataProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const handleEditClick = async () => {
    onSetValue(fields.isExtractedDataAccepted, false);
    await onUpdateExpense(formIndex)();
  };

  const canEditInvoiceData = isExpenseFormEditable && !isExcluded;

  if (extractionStatus === ExtractionStatus.FULL_EXTRACTION) {
    return (
      <GeneralExpenseInvoiceData
        extractedData={extractedData}
        onEditClick={handleEditClick}
        canEditInvoiceData={canEditInvoiceData}
      />
    );
  }

  return (
    <div className="grid gap-2">
      <div className={styles.extractedData}>
        {!extractedData?.expenseDate && (
          <HookFormDatePickerField
            name={fields.expenseDate}
            readOnly={isExcluded}
            label={t('reimbursementView.middleSection.form.general.date.label')}
            onBlur={onUpdateExpense(formIndex)}
            clearable={clearableField === fields.expenseDate}
            onFocus={onCleareableField(fields.expenseDate)}
          />
        )}
        {!extractedData?.invoiceNumber && (
          <HookFormTextField
            name={fields.invoiceNumber}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.general.invoiceNumber.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.general.invoiceNumber.placeholder'
            )}
            clearable={clearableField === fields.invoiceNumber}
            onFocus={onCleareableField(fields.invoiceNumber)}
            inputProps={{
              onBlur: onUpdateExpense(formIndex),
            }}
          />
        )}
        {!extractedData?.totalAmount && (
          <HookFormAmountField
            name={fields.totalAmount}
            readOnly={isExcluded}
            label={t(
              'reimbursementView.middleSection.form.general.totalAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.general.totalAmount.placeholder'
            )}
            currency="EUR"
            minValue={0}
            clearable={clearableField === fields.totalAmount}
            onFocus={onCleareableField(fields.totalAmount)}
            onBlur={onUpdateExpense(formIndex)}
          />
        )}
      </div>
      <GeneralExpenseInvoiceData
        extractedData={extractedData}
        onEditClick={handleEditClick}
        canEditInvoiceData={canEditInvoiceData}
      />
    </div>
  );
};

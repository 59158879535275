import {
  HookFormUsersField,
  HookFormUsersFieldProps,
} from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { FieldValues } from 'react-hook-form';

export const ReimbursementApproversField = <TFormValues extends FieldValues>({
  name,
  ...restProps
}: HookFormUsersFieldProps<TFormValues>) => {
  return <HookFormUsersField name={name} {...restProps} />;
};

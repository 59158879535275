import {
  Cell,
  CustomEmptyStateProps,
  Filter,
  FilterOptionsAccessor,
  Flex,
  SelectionOptions,
  Table,
  TableProps,
  useTheme,
} from '@candisio/design-system';
import { BaselineSearchPromotionContainer } from 'components/BaselineSearchPromotion/BaselineSearchPromotionContainer';
import { AmountCellDocument } from 'components/DocumentsTable/Cells/AmountCell';
import { TagsCell } from 'components/DocumentsTable/Cells/TagsCell';
import { UserWithStatusCell } from 'components/DocumentsTable/Cells/UserWithStatusCell';
import { amountCellProps } from 'components/Table/Cells/Amount';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { DateRangeFilter } from 'components/Table/Filters/DateRangeFilter/DateRangeFilter';
import { FilterWithSearchAndPagination } from 'components/Table/Filters/FilterWithSearchAndPagination/FilterWithSearchAndPagination';
import { paginationFiltersHooks } from 'components/Table/Filters/FilterWithSearchAndPagination/hooks/paginationFiltersHook';
import { DocumentType } from 'generated-types/graphql.types';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CellProps,
  Column,
  Filters,
  Row,
  SortingRule,
  TableState,
} from 'react-table';
import { useMemoizedState } from 'utils/useMemoizedState';
import { useEcmContractTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmContractTypeItems';
import { useEcmDocumentTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmDocumentTypeItems';
import { TagsHeader } from '../DocumentsTable/TagsHeader';
import { ContractStatusCell } from './Cells/ContractStatusCell';
import { DateCell } from './Cells/DateCell';
import { DocumentNameCell } from './Cells/DocumentNameCell';
import { RelativeDateCell } from './Cells/RelativeDateCell';
import { StatusCell } from './Cells/StatusCell';
import { TruncatedTextCell } from './Cells/TruncatedTextCell';
import {
  EcmDocumentsTableToolbar,
  EcmDocumentsTableToolbarProps,
} from './EcmDocumentsTableToolbar';
import { Header } from './Header';
import { useEcmContractStatusOptions } from './hooks/useEcmContractStatusOptions';
import { useEcmDocumentStatusOptions } from './hooks/useEcmDocumentStatusOptions';
import { EcmDocumentsTableData } from './types';
import { WithHighlightsCell } from 'components/WithHighlightsCell/WithHighlightsCell';
import { AmountCellHighlightable } from 'components/DocumentsTable/Cells/AmountCellHighlightable';
import { PaginationFiltersHooksType } from './hooks/customPaginationAllDocumentsFilterHooks';
import { CustomFilter } from './CustomFilter';

export interface EcmDocumentsTableProps {
  data: Array<EcmDocumentsTableData>;
  isSingleSelect?: boolean;
  isTableFiltered?: boolean;
  columns?: Array<keyof EcmDocumentsTableData>;
  selectedDocumentsCount: number;
  configurationsTable: Configuration[];
  searchQuery?: string;
  onEndReached?: (index: number) => void;
  onSort?: (sortBy: SortingRule<EcmDocumentsTableData>[]) => void;
  onFilter?: (filters: Filters<EcmDocumentsTableData>) => void;
  onSearchChange: (searchQuery: string) => void;
  onUpdateConfigurations: (values: Configuration[]) => void;
  onResetTableConfigurations?: () => void;
  onRowClick?: (
    id: string,
    documentType: DocumentType,
    cursor?: string,
    reimbursementCaseId?: string
  ) => void;
  selectionOptions?: SelectionOptions<EcmDocumentsTableData>;
  customEmptyState?: (props: CustomEmptyStateProps) => JSX.Element | null;
  defaultFilters?: TableState<EcmDocumentsTableData>['filters'];
  defaultSortBy?: TableState<EcmDocumentsTableData>['sortBy'];
  isLoading?: boolean;
  isLoadingConfigurations?: boolean;
  tableFooter?: ReactNode;
  defaultEmptyStateContent?: ReactNode;
  context: EcmDocumentsTableToolbarProps['context'];
  rowOverlay?: TableProps<EcmDocumentsTableData>['rowOverlay'];
  cellWrapper?: TableProps<EcmDocumentsTableData>['cellWrapper'];
  getCellStyles?: TableProps<EcmDocumentsTableData>['getCellStyles'];
  customPaginationFilterHooks?: PaginationFiltersHooksType;
  filterOptions?: FilterOptionsAccessor<EcmDocumentsTableData>;
  sortByFields?: (keyof EcmDocumentsTableData)[];
  hideDownload?: EcmDocumentsTableToolbarProps['hideDownload'];
}

export const defaultSortByFields: (keyof EcmDocumentsTableData)[] = [
  'documentStatus',
  'documentDate',
  'documentType',
  'startDate',
  'endDate',
  'terminationDate',
  'terminationReminderDate',
];

export const EcmDocumentsTable = ({
  data,
  columns: columnsProp,
  onEndReached,
  onFilter,
  onRowClick,
  onSort,
  onSearchChange,
  onUpdateConfigurations,
  onResetTableConfigurations,
  selectionOptions,
  customEmptyState: CustomEmptyState,
  tableFooter,
  defaultFilters,
  isSingleSelect = false,
  defaultSortBy,
  isLoading = false,
  isLoadingConfigurations = false,
  isTableFiltered = false,
  configurationsTable,
  searchQuery = '',
  selectedDocumentsCount,
  defaultEmptyStateContent,
  filterOptions,
  context,
  cellWrapper,
  getCellStyles,
  sortByFields = defaultSortByFields,
  hideDownload,
  customPaginationFilterHooks,
  ...restProps
}: EcmDocumentsTableProps) => {
  const { showDocumentTags } = useEcm();

  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { space } = useTheme();
  const {
    ecmDocumentTypeTranslationMap,
    ecmDocumentTypeFilterOptions,
    isLoading: ecmDocumentTypeFilterOptionsIsLoading,
  } = useEcmDocumentTypeOptions();

  const { getContractTypeLabel, ecmContractTypeFilterItems } =
    useEcmContractTypeOptions();

  const { ecmContractStatusFilterOptions } = useEcmContractStatusOptions();
  const { ecmDocumentStatusFilterOptions } = useEcmDocumentStatusOptions();

  // Cache `columns` prop value to prevent the table state from getting
  // reinitialized on each render.
  const shownColumns = useMemoizedState(columnsProp);

  const handleRowClick = onRowClick
    ? (row: Row<EcmDocumentsTableData>) => {
        const { cursor, id, documentType, invoiceId, reimbursementCaseId } =
          row.original;

        // If the document is an invoice, we need to use `invoiceId` to fetch correct data
        const documentId =
          invoiceId && documentType === DocumentType.Invoice ? invoiceId : id;

        onRowClick(
          documentId,
          documentType as DocumentType,
          cursor,
          reimbursementCaseId
        );
      }
    : undefined;

  const defaultFilterOptions = useMemo(
    (): FilterOptionsAccessor<EcmDocumentsTableData> => ({
      documentDate: true,
      documentType: {
        data: ecmDocumentTypeFilterOptions,
        isLoading: ecmDocumentTypeFilterOptionsIsLoading,
      },
      documentStatus: {
        data: ecmDocumentStatusFilterOptions,
      },
      contractStatus: {
        data: ecmContractStatusFilterOptions,
      },
      contractType: {
        data: ecmContractTypeFilterItems,
      },
      ...filterOptions,
    }),
    [
      ecmDocumentTypeFilterOptions,
      ecmDocumentTypeFilterOptionsIsLoading,
      ecmDocumentStatusFilterOptions,
      ecmContractStatusFilterOptions,
      ecmContractTypeFilterItems,
      filterOptions,
    ]
  );

  const defaultColumn = useMemo(
    (): Partial<Column<EcmDocumentsTableData>> => ({
      Cell: (props: CellProps<EcmDocumentsTableData>) => {
        return (
          <WithHighlightsCell
            cellProps={props}
            FallbackCell={TruncatedTextCell}
          />
        );
      },
      Filter: ({ column, handleUpdateIsFilterBeingUsed }) => {
        const filterOption =
          defaultFilterOptions[column.id as keyof EcmDocumentsTableData];

        if (typeof filterOption === 'object' && 'data' in filterOption) {
          const isLoading = filterOption.isLoading;

          return (
            <Filter<EcmDocumentsTableData>
              column={column}
              options={filterOption.data}
              isLoading={isLoading}
              applyFilterButton={t('filters.apply')}
              resetFilterButton={t('filters.reset')}
              searchFieldPlaceholder={t('filters.searchPlaceholder')}
              filterLabel={t('filters.label')}
              onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
            />
          );
        }

        if (paginationFiltersHooks[column.id]) {
          return (
            <FilterWithSearchAndPagination
              column={column}
              onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
            />
          );
        }

        return null;
      },
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
    }),
    [defaultFilterOptions, t]
  );

  const isFilterContactCustom = !!customPaginationFilterHooks?.['contact'];

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const columns: Array<Column<EcmDocumentsTableData>> = useMemo(() => {
    const shouldSortByField = (field: keyof EcmDocumentsTableData) =>
      sortByFields.includes(field);

    const allColumns: Array<Column<EcmDocumentsTableData>> = [
      {
        accessor: 'documentStatus',
        Cell: StatusCell,
        disableSortBy: !shouldSortByField('documentStatus'),
      },
      {
        accessor: 'contractType',
        Cell: ({ value, ...restProps }) => {
          return (
            <Cell value={getContractTypeLabel(value?.id)} {...restProps} />
          );
        },
        disableSortBy: !shouldSortByField('contractType'),
        width: space.space200,
      },
      {
        accessor: 'contractStatus',
        Cell: ContractStatusCell,
        disableSortBy: !shouldSortByField('contractStatus'),
      },
      {
        accessor: 'contact',
        disableSortBy: !shouldSortByField('contact'),
        ...(isFilterContactCustom && {
          Filter: props => {
            return (
              <CustomFilter
                {...props}
                customPaginationFilterHooks={customPaginationFilterHooks}
              />
            );
          },
        }),
        width: space.space200,
      },
      {
        accessor: 'amount',
        Cell: props => (
          <AmountCellHighlightable<EcmDocumentsTableData>
            cellProps={props}
            FallbackCell={AmountCellDocument<EcmDocumentsTableData>}
          />
        ),
        disableFilters: true,
        ...amountCellProps,
        width: space.space200,
      },

      {
        accessor: 'costCenter',
        disableSortBy: !shouldSortByField('costCenter'),
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
      },
      {
        accessor: 'createdAt',
        Cell: DateCell,
        sortType: 'datetime',
        disableFilters: true,
        disableSortBy: !shouldSortByField('createdAt'),
        width: space.space48,
      },
      {
        accessor: 'documentDate',
        Cell: DateCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <DateRangeFilter
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        sortType: 'datetime',
        disableSortBy: !shouldSortByField('documentDate'),
      },
      {
        accessor: 'documentName',
        Cell: props => (
          <WithHighlightsCell
            cellProps={props}
            FallbackCell={DocumentNameCell}
          />
        ),
        disableFilters: true,
        disableSortBy: !shouldSortByField('documentName'),
        width: space.space200,
      },
      {
        accessor: 'documentNumber',
        disableSortBy: !shouldSortByField('documentNumber'),
        disableFilters: true,
        width: space.space200,
      },
      {
        accessor: 'documentType',
        Cell: ({ value, ...restProps }) => {
          return (
            <Cell
              value={ecmDocumentTypeTranslationMap[value as DocumentType]}
              {...restProps}
            />
          );
        },
        width: '200px',
        disableSortBy: !shouldSortByField('documentType'),
      },
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      ...(showDocumentTags
        ? [
            {
              accessor: 'tags',
              /** @ts-expect-error TODO: React upgrade props types mismatch */
              Cell: TagsCell,
              disableSortBy: !shouldSortByField('tags'),
              /** @ts-expect-error TODO: React upgrade props types mismatch */
              Header: TagsHeader,
              width: 260,
              Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
                <FilterWithSearchAndPagination
                  column={column}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              ),
            } satisfies Column<EcmDocumentsTableData>,
          ]
        : []),
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      {
        accessor: 'notes',
        disableSortBy: !shouldSortByField('notes'),
        disableFilters: true,
      },
      {
        accessor: 'startDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <DateRangeFilter
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        disableSortBy: !shouldSortByField('startDate'),
      },
      {
        accessor: 'endDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <DateRangeFilter
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        disableSortBy: !shouldSortByField('endDate'),
      },
      {
        accessor: 'terminationDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: DateCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <DateRangeFilter
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        disableSortBy: !shouldSortByField('terminationDate'),
      },
      {
        accessor: 'terminationReminderDate',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: RelativeDateCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <DateRangeFilter
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        disableSortBy: !shouldSortByField('terminationReminderDate'),
      },
      {
        accessor: 'responsiblePerson',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: UserWithStatusCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        disableSortBy: !shouldSortByField('responsiblePerson'),
      },
      {
        accessor: 'notifyPerson',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: UserWithStatusCell,
        Filter: ({ column, handleUpdateIsFilterBeingUsed }) => (
          <FilterWithSearchAndPagination
            column={column}
            onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
          />
        ),
        disableSortBy: !shouldSortByField('notifyPerson'),
      },
    ];

    if (!shownColumns) {
      return allColumns;
    }

    return shownColumns
      .map(columnId => allColumns.find(col => col.accessor === columnId))
      .filter(Boolean) as Array<Column<EcmDocumentsTableData>>;
  }, [
    space.space200,
    space.space48,
    showDocumentTags,
    shownColumns,
    getContractTypeLabel,
    ecmDocumentTypeTranslationMap,
    sortByFields,
    defaultFilterOptions,
    isFilterContactCustom,
  ]);

  const initialState = useMemo(
    () => ({
      filters: defaultFilters ?? [],
      sortBy: defaultSortBy ?? [],
    }),
    [defaultFilters, defaultSortBy]
  );

  const tableKey = useMemo(
    () =>
      `ecm-documents-table-${Array<any>()
        .concat(columnsProp)
        .concat(defaultFilterOptions)
        .join('-')}`,
    [columnsProp, defaultFilterOptions]
  );
  return (
    <Flex
      height="100%"
      overflow="hidden"
      direction="column"
      // Do not remove this please. Unfortunately we need to make sure that when we remove or add columns
      // we unmount and re-mount the component so that the filter/sort state inside the table is reset.
      // If we do not do that we will end up left with filters/sorts inside the table state for columns that are not rendered.
      key={tableKey}
    >
      <EcmDocumentsTableToolbar
        context={context}
        isLoading={isLoading}
        isLoadingConfigurations={isLoadingConfigurations}
        isTableFiltered={isTableFiltered}
        configurationsTable={configurationsTable}
        searchQuery={searchQuery}
        selectedDocumentsCount={selectedDocumentsCount}
        onSearchChange={onSearchChange}
        onUpdateConfigurations={onUpdateConfigurations}
        onResetTableConfigurations={onResetTableConfigurations}
        hideDownload={hideDownload}
      />

      <Table<EcmDocumentsTableData>
        columns={columns}
        width="max-content"
        minWidth="100%"
        data={data}
        defaultColumn={defaultColumn}
        onEndReached={onEndReached}
        initialState={initialState}
        onRowClick={handleRowClick}
        onRowSelected={selectionOptions?.onSelectionRowChanged}
        selectedRowIds={selectionOptions?.selectedRowsIds}
        customEmptyState={CustomEmptyState}
        onFilter={onFilter}
        isSingleSelect={isSingleSelect}
        onSort={onSort}
        selectionOptions={selectionOptions}
        isLoading={isLoading}
        defaultEmptyStateContent={defaultEmptyStateContent}
        borderTopRadius="unset"
        cellWrapper={cellWrapper}
        getCellStyles={getCellStyles}
        {...restProps}
      />
      <BaselineSearchPromotionContainer />
    </Flex>
  );
};

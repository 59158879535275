import {
  eInvoiceErrorTranslationKey,
  eInvoiceFormatTranslationKey,
} from 'components/DocumentHistory/history-components/InvalidEInvoiceDetectedComponent';
import { useEInvoiceValidationDetails } from 'components/EInvoiceErrorDetails/useEInvoiceValidationDetails';
import { getEmailAddresses } from 'components/EmailEditor/utils';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useGetDocumentForDraftQuery,
  useGetOrganizationEmailAccountsQuery,
  useSendEmailToSupplierMutation,
} from 'generated-types/graphql.types';
import { useAttachments } from 'hooks/useAttachments/useAttachments';
import { useDocumentFile } from 'hooks/useDocumentFile';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEInvoiceErrorDetailsForContactSupplier } from 'views/Inbox/DocumentProcessing/components/ContactSupplier/utils';
import { ContactSupplierFormOutput } from './contactSupplierFormSchema';

type ContactSupplierFormProps = {
  documentId: string;
  onCloseModal: () => void;
  selectedContactEmail?: string | null;
};

export const useContactSupplier = ({
  documentId,
  onCloseModal,
  selectedContactEmail,
}: ContactSupplierFormProps) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();

  const { eInvoiceValidationErrors } = useEInvoiceValidationDetails({
    documentId,
  });

  const currentUser = useCurrentUser();
  const { data, loading: emailAccountsDataLoading } =
    useGetOrganizationEmailAccountsQuery();

  const { data: documentData, loading: documentDataLoading } =
    useGetDocumentForDraftQuery({
      variables: { id: documentId },
    });

  const eInvoiceError = documentData?.getDocument?.eInvoice?.error;

  const shouldUseEInvoiceDraftText =
    eInvoiceError?.length || eInvoiceValidationErrors?.length;

  const eInvoiceErrorTranslation = t(
    eInvoiceErrorTranslationKey(eInvoiceError)
  );

  const {
    documentFile: mainDocumentFile,
    documentAttachments,
    isDocumentFileLoading,
  } = useDocumentFile({ documentId });

  const isDataLoading =
    emailAccountsDataLoading || documentDataLoading || isDocumentFileLoading;

  const { attachments } = useAttachments({
    documentFile: mainDocumentFile,
    documentId,
    documentAttachments,
  });

  const organization = useFullOrganization();

  const emailAccounts = data?.organization?.emailAccounts?.records?.filter(
    r => r?.isEnabled && r?.organizationId === organization?.id
  );

  const emailAccount = emailAccounts && emailAccounts[0];

  const defaultSubjectText = t(
    'common:contactSupplierFromCandis.draftText.default.subject',
    {
      invoiceNumber: documentData?.getDocument?.invoiceNumber?.value,
    }
  );

  const eInvoiceErrorDetails = useEInvoiceErrorDetailsForContactSupplier({
    eInvoiceError,
    eInvoiceErrors: eInvoiceValidationErrors,
  });

  const eInvoiceFormatTranslated = t(
    eInvoiceFormatTranslationKey(documentData?.getDocument?.eInvoice?.format)
  );

  const defaultEmailText = [
    t('common:contactSupplierFromCandis.draftText.default.greetings'),
    t('common:contactSupplierFromCandis.draftText.default.content', {
      invoiceNumber: documentData?.getDocument?.invoiceNumber?.value,
    }),
    t('common:contactSupplierFromCandis.draftText.default.details', {
      onBehalfOf: currentUser?.email,
      organizationName: organization?.name,
      reason: documentData?.getDocument?.invoiceNumber?.value,
    }),
    t('common:contactSupplierFromCandis.draftText.default.closing', {
      importEmail: emailAccount?.address,
      fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
      organizationName: organization?.name,
      userEmail: currentUser?.email,
    }),
    t('common:contactSupplierFromCandis.draftText.default.footer'),
  ].join('<br/><br/>');

  const eInvoiceEmailText = [
    t('common:contactSupplierFromCandis.draftText.eInvoice.greetings'),
    t('common:contactSupplierFromCandis.draftText.eInvoice.content', {
      reason: eInvoiceErrorTranslation,
    }),
    t('common:contactSupplierFromCandis.draftText.eInvoice.format.label', {
      type: eInvoiceFormatTranslated,
    }),
    t('common:contactSupplierFromCandis.draftText.eInvoice.details', {
      onBehalfOf: currentUser?.email,
      organizationName: organization?.name,
      issue: eInvoiceErrorDetails,
    }),
    t('common:contactSupplierFromCandis.draftText.eInvoice.closing', {
      importEmail: emailAccount?.address,
      fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
      organizationName: organization?.name,
      userEmail: currentUser?.email,
    }),
    t('common:contactSupplierFromCandis.draftText.eInvoice.footer'),
  ].join('<br/><br/>');

  const emailText = shouldUseEInvoiceDraftText
    ? eInvoiceEmailText
    : defaultEmailText;

  const [sendEmail] = useSendEmailToSupplierMutation({
    refetchQueries: ['getDocumentHistory'],
  });

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const onSubmit = async (formData: ContactSupplierFormOutput) => {
    setIsFormSubmitting(true);
    const { errors, data } = await sendEmail({
      variables: {
        emailInput: {
          recipients: getEmailAddresses(formData.emailTo).map(email => ({
            email,
          })),
          subject: formData.subject,
          htmlBody: formData.htmlText,
        },
        documentId,
      },
    });

    const emailSentSuccessfully = Boolean(
      !errors &&
        data?.sendEmailToSupplier?.__typename === 'SendEmailResponse' &&
        data?.sendEmailToSupplier?._id
    );

    if (emailSentSuccessfully) {
      success(t('common:contactSupplierFromCandis.sendEmailSuccess'));
      onCloseModal();
    } else {
      error(t('common:contactSupplierFromCandis.sendEmailError'));
    }

    setIsFormSubmitting(false);
  };

  return {
    onSubmit,
    subjectText: defaultSubjectText,
    emailText,
    mainDocumentFile,
    attachments,
    isDataLoading,
    isFormSubmitting,
    defaultContactEmail:
      selectedContactEmail ??
      documentData?.getDocument?.extractedContact?.email?.value,
  };
};

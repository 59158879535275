import { Destination } from '../types';

const _MARKETING_AND_ANALYTICS_CATEGORIES = [
  'A/B Testing',
  'Analytics',
  'Attribution',
  'Email',
  'Enrichment',
  'Heatmaps & Recordings',
  'Raw Data',
  'Realtime Dashboards',
  'Referrals',
  'Surveys',
  'Video',
];

const ADVERTISING_CATEGORIES = ['Advertising', 'Tag Managers'];

const FUNCTIONAL_CATEGORIES = [
  'CRM',
  'Customer Success',
  'Deep Linking',
  'Helpdesk',
  'Livechat',
  'Performance Monitoring',
  'Personalization',
  'SMS & Push Notifications',
  'Security & Fraud',
];

export interface NormalisedDestinations {
  marketingDestinations: Destination[];
  advertisingDestinations: Destination[];
  functionalDestinations: Destination[];
}

// https://github.com/segmentio/consent-manager/blob/master/src/consent-manager/container.tsx#L53
export const normaliseDestinations = (
  destinations: Destination[]
): NormalisedDestinations => {
  const marketingDestinations: Destination[] = [];
  const advertisingDestinations: Destination[] = [];
  const functionalDestinations: Destination[] = [];

  for (const destination of destinations) {
    if (ADVERTISING_CATEGORIES.find(c => c === destination.category)) {
      advertisingDestinations.push(destination);
    } else if (FUNCTIONAL_CATEGORIES.find(c => c === destination.category)) {
      functionalDestinations.push(destination);
    } else {
      // Fallback to marketing
      marketingDestinations.push(destination);
    }
  }

  return {
    marketingDestinations,
    advertisingDestinations,
    functionalDestinations,
  };
};

import {
  ReimbursementItemFormPerDiemDay,
  ReimbursementItemFormPerDiemMeal,
} from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';

export const getMealsSelectorVariant = ({
  days,
  id,
  endIndex,
  startIndex,
}: {
  days: ReimbursementItemFormPerDiemDay[];
  id: string;
  startIndex?: number;
  endIndex?: number;
}) => {
  const slicedDays = days.slice(startIndex, endIndex);
  const daysWithSelectedMeal = slicedDays.filter(day =>
    day.meals.includes(id as ReimbursementItemFormPerDiemMeal)
  ).length;

  if (daysWithSelectedMeal === slicedDays.length) {
    return 'allSelected';
  }
  if (daysWithSelectedMeal !== slicedDays.length && daysWithSelectedMeal > 0) {
    return 'someSelected';
  }
  return 'noneSelected';
};

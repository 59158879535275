import { FilterOptionsAccessor } from '@candisio/design-system';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';

export const useApprovalReimbursementsFilterOptions = () => {
  const filterOptions: FilterOptionsAccessor<ReimbursementTableRow> = {
    createdAt: true,
    approvalRequestedAt: true,
  };

  return filterOptions;
};

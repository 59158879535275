import {
  Button,
  ButtonProps,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { ConfirmationPopover } from 'components/ConfirmationPopover/ConfirmationPopover';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { acceptedFileType } from 'views/TransactionAssociation/component/InvoiceUploadContainer/useDragAndDrop';

const ACCEPTED_FILE_TYPE = 'application/pdf';

interface ReplaceInvoiceProps {
  id: string;
  name: string;
  disabled?: boolean;
  onChange: (file: File) => Promise<void>;
  onInvoiceUpdate?: () => Promise<void>;
  isFileChanging: boolean;
  onIsFileChanging: (val: boolean) => void;
}

export const ReplaceInvoice = ({
  id,
  name,
  disabled,
  onChange,
  onInvoiceUpdate,
  isFileChanging,
  onIsFileChanging,
}: ReplaceInvoiceProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { error } = useToastMessage();
  const { track } = useAnalytics();

  const fileUploadRef = useRef<HTMLInputElement>(null);
  const { isOpen, triggerProps, triggerRef, popoverProps, popoverRef, close } =
    usePopover();

  const isDisabled = disabled || isFileChanging;

  const confirmButtonProps: ButtonProps = {
    color: 'blue',
    loading: isFileChanging,
    disabled: isDisabled,
  };

  const handleFileChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    const file = evt.target.files?.[0];

    if (!file) return;

    if (file.type !== acceptedFileType) {
      error(t('reimbursementView.middleSection.form.fileNotSupportedError'));
      return;
    }

    try {
      onIsFileChanging(true);

      await onChange(file);
      await onInvoiceUpdate?.();
    } finally {
      onIsFileChanging(false);

      track(TrackingEvents.REIMBURSEMENT_DOCUMENT_UPLOADED, {
        reimbursement_id: reimbursementId,
      });
    }
  };

  const handleClick = () => {
    if (!fileUploadRef.current || isDisabled) {
      return;
    }

    fileUploadRef.current.click();
  };

  return (
    <>
      <Button
        size="xsmall"
        alignSelf="center"
        variant="tertiary"
        loading={isFileChanging}
        disabled={isDisabled}
        {...triggerProps}
        ref={triggerRef}
      >
        <TruncatedText>
          {t('reimbursementView.middleSection.form.replaceInvoiceCta')}
        </TruncatedText>
      </Button>
      <input
        type="file"
        id={id}
        name={name}
        disabled={isDisabled}
        ref={fileUploadRef}
        onChange={handleFileChange}
        accept={ACCEPTED_FILE_TYPE}
        hidden
      />

      {!isDisabled && isOpen && (
        <ConfirmationPopover
          {...popoverProps}
          ref={popoverRef}
          width="320px"
          padding="space18"
          onClose={close}
          onConfirm={handleClick}
          confirmButtonProps={confirmButtonProps}
          title={t(
            'reimbursementView.middleSection.form.replaceInvoiceConfirm.desc'
          )}
          confirmText={t(
            'reimbursementView.middleSection.form.replaceInvoiceConfirm.yes'
          )}
          closeText={t(
            'reimbursementView.middleSection.form.replaceInvoiceConfirm.no'
          )}
        />
      )}
    </>
  );
};

import { CustomEmptyStateProps, Flex } from '@candisio/design-system';
import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import { queryParameter } from 'components/Table/consts';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useCallback, useMemo } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';
import { Filters, SortingRule } from 'react-table';
import { useCardIssuerCards } from '../hooks/useCardIssuerCards';
import { useCardIssuerCardsFilters } from '../hooks/useCardIssuerCardsFilters';
import { CreditCardsTableData } from '../types';
import { availableFilters } from '../utils/utils';
import { CreditCardsTable } from './components/CreditCardsTable';
import {
  ToolbarFilter,
  toolBarFilterParam,
} from './components/CreditCardsTable/CreditCardsTableToolbar';
import { EmptyState } from './components/CreditCardsTable/EmptyState';
import { useGetCreditCardsTableConfigs } from './components/CreditCardsTable/hooks/useGetCreditCardsTableConfigs';
import { useSubscribeToCreditCard } from 'containers/credit-cards/CreditCardCarousel/hooks/useSubscribeToCreditCard';

export const CreditCardsTableTab = () => {
  const { updateSearchParam } = useMutateSearchParams();
  const { organizationSlug } = useParams<{
    organizationSlug: string;
  }>();

  const { searchParams } = useMutateSearchParams();
  const toolbarFilter: ToolbarFilter = (searchParams.get(toolBarFilterParam) ??
    'InUse') as ToolbarFilter;

  const queryStringFilter = searchParams.get(queryParameter) ?? '';

  const { search } = useLocation();
  const queryStringfromUrl = qs.parse(search);
  const { sortBy, filters, onFilter, onSort } =
    useUrlBasedSortAndFilter<CreditCardsTableData>({
      availableFilters,
    });

  const {
    creditCardsTableData,
    loading,
    onLoadMore,
    isTableEmpty,
    isTableFiltered,
    handleDebounceSearch,
  } = useCardIssuerCards({ filters, sortBy, toolbarFilter });

  const {
    isLoadingConfigs,
    isResetPending,
    availableCreditCardsColumnIds,
    configurationsTable,
    handleResetTableConfigurations,
    handleUpdateConfigurations,
  } = useGetCreditCardsTableConfigs({ filters, sortBy });

  // This hook will subscribe to any change in the cards making it possible to update the card in real time
  // and without the need of refetching

  useSubscribeToCreditCard();

  const { filterOptions, isFilterLoading } = useCardIssuerCardsFilters();

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <EmptyState
        isTableFiltered={isTableFiltered}
        isTableEmpty={isTableEmpty}
        resetFilters={resetFilters}
      />
    ) : null;

  const handleFilter = useMemo(() => {
    return (filterVal: Filters<CreditCardsTableData>) => {
      onFilter(filterVal);
    };
  }, [onFilter]);

  const handleSort = useMemo(() => {
    return (sortVal: SortingRule<CreditCardsTableData>[]) => {
      onSort(sortVal);
    };
  }, [onSort]);

  const getPath = useCallback(
    (row: CreditCardsTableData) => {
      const pathname = `/${organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}/${row.id}`;

      return {
        search: qs.stringify({
          ...queryStringfromUrl,
          onCreditCardsView: 'true',
        }),
        pathname,
      };
    },
    [organizationSlug, queryStringfromUrl]
  );

  const cellWrapper = useMemo(() => getCellWrapperLink(getPath), [getPath]);
  const cellStyle = () => ({
    padding: 'unset',
  });

  return (
    <Flex overflow="hidden" direction="column" height="100%">
      <CreditCardsTable
        isLoadingConfigs={isLoadingConfigs || isResetPending}
        data={creditCardsTableData}
        key={`credit-card_table-${isFilterLoading}-${toolbarFilter}-${availableCreditCardsColumnIds.join(
          '-'
        )}`}
        loading={loading}
        defaultFilters={filters}
        filterOptions={filterOptions}
        defaultSortBy={sortBy}
        onFilter={handleFilter}
        customEmptyState={emptyState}
        onEndReached={onLoadMore}
        onSort={handleSort}
        search={queryStringFilter}
        onSearch={search => {
          handleDebounceSearch(search);
          updateSearchParam(queryParameter, search);
        }}
        minWidth="100%"
        width="max-content"
        configurationsTable={configurationsTable}
        columns={availableCreditCardsColumnIds}
        onUpdateColumnsConfigurations={handleUpdateConfigurations}
        onResetConfigurations={handleResetTableConfigurations}
        cellWrapper={cellWrapper}
        getCellStyles={cellStyle}
      />
    </Flex>
  );
};

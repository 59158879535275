import {
  Box,
  Flex,
  Grid,
  Icon,
  Modal,
  ScrollBox,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { CommentContainer } from 'components/Comment/CommentContainer';
import { DocumentHistoryContainer } from 'components/DocumentHistory/DocumentHistoryContainer';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { PdfInvoiceSkeleton } from 'components/DocumentViewer/PdfViewer/PdfInvoiceSkeleton';
import { DetailsLayout } from 'components/Layouts/DetailsLayout';
import {
  LeftSectionContentWrapper,
  LeftSectionOuterWrapper,
} from 'components/Layouts/styles';
import { ListNavigator } from 'components/ListNavigator/ListNavigator';
import { Loader } from 'components/Loader';
import {
  TransactionDetailsCardSummary,
  TransactionsDetailsCardContainer,
} from 'components/Transactions/TransactionDetailsCard/TransactionDetailsCard';
import {
  Document,
  DocumentStatus,
  EcmDocumentStatus,
  InvoiceAssociationStatus,
  Money,
} from 'generated-types/graphql.types';

import clsx from 'clsx';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { RelatedDocumentContainer } from 'components/DocumentViewer/RelatedDocumentContainer';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';
import { DocumentHistoryComponent } from 'containers/document-summary/DocumentSummarySection';
import { useAttachments } from 'hooks/useAttachments/useAttachments';
import { useDocumentFile } from 'hooks/useDocumentFile';
import { Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isPaidDocument } from 'utils/document_payment_data';
import {
  ExpandingDrawer,
  MAX_DRAWER_WIDTH,
} from 'views/DocumentDetails/ExpandingDrawer';
import { DocumentDetailsPromoContainer } from 'views/DocumentDetails/components/TransactionSection/DocumentDetailsPromo';
import { AttachmentsSegment } from 'views/Inbox/DocumentProcessing/components/AttachmentsSegment';
import { DocumentHistoryTabsContainer } from 'views/Inbox/DocumentProcessing/components/DocumentHistoryTabs/DocumentHistoryTabsContainer';
import { DocumentSummaryInformation } from 'views/Inbox/DocumentProcessing/components/DocumentSummaryInformation';
import { Attachments } from 'views/Inbox/DocumentProcessing/components/OldAttachments';
import { DocumentTransactionDetails } from 'views/Inbox/Transactions/DocumentTransactionDetails';
import { DocumentTransactionProcessing } from 'views/Inbox/Transactions/DocumentTransactionProcessing';
import {
  DocumentDetailsPanel,
  TransactionViewEmptyState,
} from '../DocumentDetails/components/DocumentDetailsPanel';
import {
  TransactionAssociationProvider,
  useTransactionAssociationContext,
} from './Context';
import { DocumentsWithoutTransactionContainer } from './DocumentsWithoutTransaction/DocumentsWithoutTransaction';
import { InvoiceUploadDisabledState } from './InvoiceState/InvoiceUploadDisabledState';
import { RightInformationContent } from './RightInformationContent';
import { ReadOnlyFormFromDeprecated } from './RightInformationContent/ReadOnlyAccountingDataForm/ReadOnlyFormDeprecated';
import { ReadOnlyTransactionFormContainer } from './RightInformationContent/ReadOnlyAccountingDataForm/ReadOnlyTransactionFormContainer';
import { TransactionReadOnlySideBar } from './RightInformationContent/TransactionReadOnlySidebar';
import { CommentTransactionContainer } from './component/CommentTransactionContainer';
import { InvoiceLayout } from './component/InvoiceUploadContainer';
import { InvoiceUploaderContainer } from './component/InvoiceUploadContainer/InvoiceUploaderContainer';
import { TransactionHistoryContainer } from './component/TransactionHistoryContainer';
import { useTransactionHistory } from './component/TransactionHistoryContainer/useTransactionHistory';

const TitleWithInfo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ placement: 'bottom' });

  return (
    <Flex alignItems="center" gap="space8">
      <Text>{t('transactionAssociation.documentsModal.title')}</Text>
      <Flex {...triggerProps} ref={triggerRef}>
        <Icon icon="infoCircle" color="gray700" size="space12" />

        {isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            <Text fontWeight="regular" color="gray800" fontSize="basic">
              {t('transactionAssociation.documentsModal.titleInfo')}
            </Text>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

export const TransactionAssociationAdminRequesterComponentDeprecated = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const {
    transactionId,
    handleBackToList,
    prevTransactionLink,
    prevTransactionPage,
    nextTransactionLink,
    nextTransactionPage,
    transactionListCount: count,
    isLoadingNavigationData,
    isLoadingCurrentCardTransaction,
    document,
    documentId,
    linkedDocumentStatus,
    attachmentListItems,
    selectedPdf,
    mainDocumentFile,
    attachments,
    attachPermissions,
    ownerTxMembershipId,
    isSectionLoading,
    setSelectedPdf,
    canUserLinkInvoice,
    handleUploaderChange,
    cycleTransactions,
    isNoInvoiceNeededToggleActive,
    setDocumentsNoTxModalIsOpen,
    routeType,
    organizationSlug,
    isArchivedTx,
    onToggleEditDocumentMode,
    showAllExtraFeatures,
    transaction,
    isTransactionExported,
    closeModal,
    documentsNoTxModalIsOpen,
    view,
    setView,
  } = useTransactionAssociationContext();

  const listNavigatorTitle: string = isLoadingNavigationData
    ? t('transactionAssociation.listNavigator.loading')
    : t('transactionAssociation.listNavigator.title', { count });

  const attachmentsTabTitle = t('common:document.tabs.oldAttachments.title');
  const protocolTabTitle = t('common:document.tabs.history');

  return (
    <DetailsLayout
      key={transactionId}
      leftSection={
        <LeftSectionOuterWrapper>
          <ListNavigator
            backToListText={t('common:document.backToDocumentList')}
            arrowLeftTooltip={t(
              'transactionAssociation.listNavigator.prevTransaction'
            )}
            arrowRightTooltip={t(
              'transactionAssociation.listNavigator.nextTransaction'
            )}
            onBackToList={handleBackToList}
            onPrev={prevTransactionLink ? prevTransactionPage : undefined}
            onNext={nextTransactionLink ? nextTransactionPage : undefined}
            listNavigatorCounter={listNavigatorTitle}
            loading={isLoadingNavigationData}
          />
          <LeftSectionContentWrapper>
            <DocumentDetailsPanel
              loadingDoc={isLoadingCurrentCardTransaction}
              document={document as Document}
              emptyState={<TransactionViewEmptyState />}
            />
            <TransactionsDetailsCardContainer
              transactionId={transactionId}
              documentId={documentId}
              linkedDocumentStatus={linkedDocumentStatus}
            />
            {documentId && (
              <DocumentHistoryTabsContainer
                key="tabs"
                defaultSelectedKey={protocolTabTitle}
                chatBox={<CommentContainer documentId={documentId} />}
                tabItems={[
                  {
                    title: protocolTabTitle,
                    children: (
                      <DocumentHistoryContainer documentId={documentId} />
                    ),
                  },
                  {
                    title: attachmentsTabTitle,
                    children: (
                      <Attachments
                        aria-labelledby="attachments"
                        documentId={documentId}
                        items={attachmentListItems}
                        selectedFile={selectedPdf}
                        documentFile={mainDocumentFile}
                        attachments={attachments}
                        canAttachFiles={attachPermissions}
                      />
                    ),
                  },
                ]}
              />
            )}
            {!documentId && (
              <DocumentHistoryTabsContainer
                key="tabs"
                defaultSelectedKey={protocolTabTitle}
                disabledTitles={[attachmentsTabTitle]}
                chatBox={
                  <CommentTransactionContainer
                    transactionId={transactionId}
                    ownerTxMembershipId={ownerTxMembershipId}
                  />
                }
                tabItems={[
                  {
                    title: protocolTabTitle,
                    children: (
                      <TransactionHistoryContainer
                        transactionId={transactionId}
                      />
                    ),
                  },
                  {
                    title: attachmentsTabTitle,
                    tabTooltip: t('transactionAssociation.tabs.disabled'),
                  },
                ]}
              />
            )}
          </LeftSectionContentWrapper>
        </LeftSectionOuterWrapper>
      }
      middleSection={
        isSectionLoading ? (
          <Box paddingTop="72px" paddingX="space24" height="100%">
            <PdfInvoiceSkeleton />
          </Box>
        ) : (
          <>
            {documentId ? (
              <Box
                height="100%"
                width="100%"
                paddingX="space16"
                paddingTop="space32"
                overflowY="auto"
              >
                <DocumentViewer
                  documentId={documentId ?? ''}
                  attachments={attachments}
                  canAttachFiles={attachPermissions}
                  documentFile={mainDocumentFile}
                  selectedFile={selectedPdf}
                  onSelectDocument={setSelectedPdf}
                  view={view}
                  setView={setView}
                />
              </Box>
            ) : (
              <InvoiceLayout canUserLinkInvoice={canUserLinkInvoice}>
                {canUserLinkInvoice ? (
                  <InvoiceUploaderContainer
                    txId={transactionId}
                    onChange={handleUploaderChange}
                    cycleTransaction={cycleTransactions}
                    isNoInvoiceNeededToggleActive={
                      isNoInvoiceNeededToggleActive
                    }
                    setDocumentsNoTxModalIsOpen={setDocumentsNoTxModalIsOpen}
                  />
                ) : (
                  <InvoiceUploadDisabledState />
                )}
              </InvoiceLayout>
            )}
          </>
        )
      }
      rightSection={
        isSectionLoading ? (
          <Loader />
        ) : documentId ? (
          routeType === Routes.INBOX ? (
            <DocumentTransactionProcessing
              documentId={documentId}
              transactionId={transactionId}
              organizationSlug={organizationSlug}
              cycleTransaction={cycleTransactions}
              isArchivedTx={isArchivedTx}
            />
          ) : (
            <DocumentTransactionDetails
              documentId={documentId}
              organizationSlug={organizationSlug}
              cycleTransactions={cycleTransactions}
              onToggleEditDocumentMode={onToggleEditDocumentMode}
            />
          )
        ) : showAllExtraFeatures ? (
          // We need to force the component to re-render when the transactionId changes
          <TransactionReadOnlySideBar
            key={transactionId}
            isInvoicedNotNeeded={
              transaction?.invoiceAssociationStatus ===
              InvoiceAssociationStatus.NotNeeded
            }
            isTxExported={isTransactionExported ?? false}
          >
            <ReadOnlyTransactionFormContainer
              isTransactionExported={isTransactionExported ?? false}
            />
          </TransactionReadOnlySideBar>
        ) : isNoInvoiceNeededToggleActive ? (
          <ReadOnlyFormFromDeprecated
            transaction={transaction}
            isTxExported={isTransactionExported}
          />
        ) : (
          <RightInformationContent
            isLoadingCurrentCardTransaction={isSectionLoading}
          />
        )
      }
    >
      <Modal
        closeLabel={t('transactionAssociation.documentsModal.close')}
        background="gray200"
        width="80vw"
        title={<TitleWithInfo />}
        minHeight="60vh"
        padding="0"
        overflow="hidden"
        shouldCloseOnBlur
        onClose={closeModal}
        isOpen={documentsNoTxModalIsOpen}
      >
        <DocumentsWithoutTransactionContainer
          transactionId={transactionId}
          closeModal={closeModal}
        />
      </Modal>
    </DetailsLayout>
  );
};

export const TransactionAssociationAdminRequesterComponent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const {
    transactionId,
    handleBackToList,
    prevTransactionLink,
    prevTransactionPage,
    nextTransactionLink,
    nextTransactionPage,
    transactionListCount: count,
    isLoadingNavigationData,
    isLoadingCurrentCardTransaction,
    document,
    documentId,
    selectedPdf,
    mainDocumentFile,
    attachments,
    attachPermissions,
    ownerTxMembershipId,
    isSectionLoading,
    setSelectedPdf,
    canUserLinkInvoice,
    handleUploaderChange,
    cycleTransactions,
    isNoInvoiceNeededToggleActive,
    setDocumentsNoTxModalIsOpen,
    routeType,
    organizationSlug,
    isArchivedTx,
    onToggleEditDocumentMode,
    showAllExtraFeatures,
    transaction,
    isTransactionExported,
    closeModal,
    documentsNoTxModalIsOpen,
    linkedDocumentStatus,
    view,
    setView,
  } = useTransactionAssociationContext();

  const globalDocumentId = document?.globalDocumentId ?? '';
  const invoiceNumber = document?.invoiceNumber?.value ?? undefined;
  const invoiceDate = document?.invoiceDate?.value ?? undefined;
  const documentAmount = document?.amount?.value ?? undefined;
  const documentCurrency = document?.currency?.value ?? undefined;
  const contactName =
    document?.contact?.value?.name?.value ??
    document?.extractedContact?.name?.value;

  const documentStatus: EcmDocumentStatus | DocumentStatus =
    document?.status ?? EcmDocumentStatus.New;

  const isEInvoice = Boolean(document?.isEInvoice);
  const eInvoice = document?.eInvoice;
  const eInvoiceValidationErrors = document?.eInvoiceValidationErrors;

  const hasDocumentAmount =
    documentCurrency !== undefined && documentAmount !== undefined;

  const amount = useMemo((): Money | null => {
    if (!hasDocumentAmount) return null;

    return {
      __typename: 'Money',
      amount: documentAmount,
      currency: documentCurrency,
      precision: 2,
    };
  }, [documentAmount, documentCurrency, hasDocumentAmount]);

  const { documentFile, isDocumentFileLoading, documentAttachments } =
    useDocumentFile({ documentId, shouldPoll: isEInvoice });

  const useAttachmentsResult = useAttachments({
    documentFile,
    documentId,
    documentAttachments,
  });

  const listNavigatorTitle: string = isLoadingNavigationData
    ? t('transactionAssociation.listNavigator.loading')
    : t('transactionAssociation.listNavigator.title', { count });

  const attachmentsTabTitle = t('common:document.tabs.oldAttachments.title');

  const { isComparisonView } = useComparisonView();

  const [selectedRelationId, setSelectedRelationId] = useState<
    string | undefined
  >();

  return (
    <DetailsLayout
      key={transactionId}
      leftSection={
        <>
          <LeftSectionOuterWrapper>
            <ListNavigator
              backToListText={t('common:document.backToDocumentList')}
              arrowLeftTooltip={t(
                'transactionAssociation.listNavigator.prevTransaction'
              )}
              arrowRightTooltip={t(
                'transactionAssociation.listNavigator.nextTransaction'
              )}
              onBackToList={handleBackToList}
              onPrev={prevTransactionLink ? prevTransactionPage : undefined}
              onNext={nextTransactionLink ? nextTransactionPage : undefined}
              listNavigatorCounter={listNavigatorTitle}
              loading={isLoadingNavigationData}
            />
            <LeftSectionContentWrapper>
              <ScrollBox scrollDirection="none" scrollbarGutter="stable">
                <DocumentSummaryInformation
                  transactionSection={
                    <>
                      <DocumentDetailsPromoContainer />
                      <Grid border="1px solid gray300" borderRadius="medium">
                        <TransactionDetailsCardSummary
                          transaction={transaction}
                          documentId={documentId}
                          documentStatus={linkedDocumentStatus}
                          loading={isLoadingCurrentCardTransaction}
                        />
                      </Grid>
                    </>
                  }
                  amount={amount}
                  attachPermissions={useAttachmentsResult.attachPermissions}
                  attachments={useAttachmentsResult.attachments}
                  contactName={contactName}
                  documentStatus={documentStatus}
                  eInvoice={eInvoice}
                  eInvoiceValidationErrors={eInvoiceValidationErrors}
                  invoiceDate={invoiceDate}
                  invoiceNumber={invoiceNumber}
                  isEInvoice={isEInvoice}
                  documentFile={documentFile}
                  documentId={documentId}
                  isLoading={isLoadingCurrentCardTransaction}
                  onAttachmentClick={setSelectedPdf}
                  selectedPdf={selectedPdf}
                  isPaid={document ? isPaidDocument(document) : undefined}
                  selectedContactEmail={
                    document?.contact?.value?.email ?? undefined
                  }
                  withRelationships={false}
                />
              </ScrollBox>

              <ScrollBox scrollDirection="y" scrollbarGutter="stable">
                {documentId && (
                  <DocumentHistoryComponent documentId={documentId} />
                )}
                {!documentId && (
                  <TransactionHistory
                    transactionId={transactionId}
                    ownerTxMembershipId={ownerTxMembershipId}
                  />
                )}
              </ScrollBox>
            </LeftSectionContentWrapper>
          </LeftSectionOuterWrapper>
          <Grid maxWidth={MAX_DRAWER_WIDTH} height="100cqh" overflow="hidden">
            <ExpandingDrawer drawer="ATTACHMENTS" title={attachmentsTabTitle}>
              <AttachmentsSegment
                documentId={documentId}
                documentFile={mainDocumentFile}
                attachments={attachments}
                attachPermissions={attachPermissions}
                onAttachmentClick={setSelectedPdf}
                selectedPdf={selectedPdf}
              />
            </ExpandingDrawer>
          </Grid>
        </>
      }
      middleSection={
        isSectionLoading ? (
          <Box paddingTop="72px" paddingX="space24" height="100%">
            <PdfInvoiceSkeleton />
          </Box>
        ) : (
          <>
            {documentId ? (
              <Grid
                height="100%"
                overflowY="auto"
                paddingTop="space32"
                columnGap={isComparisonView ? 'space16' : '0'}
                templateColumns={isComparisonView ? '1fr 1fr' : '0fr 1fr'}
              >
                <RelatedDocumentContainer
                  mainDocumentId={globalDocumentId}
                  selectedPdfId={selectedRelationId}
                  setSelectedPdfId={setSelectedRelationId}
                />
                <Box height="100%" overflowY="auto">
                  <DocumentViewer
                    documentId={documentId}
                    documentFile={mainDocumentFile}
                    selectedFile={selectedPdf}
                    attachments={attachments}
                    onSelectDocument={setSelectedPdf}
                    canAttachFiles={attachPermissions}
                    isLoadingPdf={isDocumentFileLoading}
                    hideAttachmentSection={isComparisonView}
                    view={view}
                    setView={setView}
                  />
                </Box>
              </Grid>
            ) : (
              <InvoiceLayout canUserLinkInvoice={canUserLinkInvoice}>
                {canUserLinkInvoice ? (
                  <InvoiceUploaderContainer
                    txId={transactionId}
                    onChange={handleUploaderChange}
                    cycleTransaction={cycleTransactions}
                    isNoInvoiceNeededToggleActive={
                      isNoInvoiceNeededToggleActive
                    }
                    setDocumentsNoTxModalIsOpen={setDocumentsNoTxModalIsOpen}
                  />
                ) : (
                  <InvoiceUploadDisabledState />
                )}
              </InvoiceLayout>
            )}
          </>
        )
      }
      rightSection={
        isSectionLoading ? (
          <Loader />
        ) : documentId ? (
          routeType === Routes.INBOX ? (
            <DocumentTransactionProcessing
              documentId={documentId}
              transactionId={transactionId}
              organizationSlug={organizationSlug}
              cycleTransaction={cycleTransactions}
              isArchivedTx={isArchivedTx}
            />
          ) : (
            <DocumentTransactionDetails
              documentId={documentId}
              organizationSlug={organizationSlug}
              cycleTransactions={cycleTransactions}
              onToggleEditDocumentMode={onToggleEditDocumentMode}
            />
          )
        ) : showAllExtraFeatures ? (
          // We need to force the component to re-render when the transactionId changes
          <TransactionReadOnlySideBar
            key={transactionId}
            isInvoicedNotNeeded={
              transaction?.invoiceAssociationStatus ===
              InvoiceAssociationStatus.NotNeeded
            }
            isTxExported={isTransactionExported ?? false}
          >
            <ReadOnlyTransactionFormContainer
              isTransactionExported={isTransactionExported ?? false}
            />
          </TransactionReadOnlySideBar>
        ) : isNoInvoiceNeededToggleActive ? (
          <ReadOnlyFormFromDeprecated
            transaction={transaction}
            isTxExported={isTransactionExported}
          />
        ) : (
          <RightInformationContent
            isLoadingCurrentCardTransaction={isSectionLoading}
          />
        )
      }
    >
      <Modal
        closeLabel={t('transactionAssociation.documentsModal.close')}
        background="gray200"
        width="80vw"
        title={<TitleWithInfo />}
        minHeight="60vh"
        padding="0"
        overflow="hidden"
        shouldCloseOnBlur
        onClose={closeModal}
        isOpen={documentsNoTxModalIsOpen}
      >
        <DocumentsWithoutTransactionContainer
          transactionId={transactionId}
          closeModal={closeModal}
        />
      </Modal>
    </DetailsLayout>
  );
};

export const TransactionAssociationAdminRequester = () => {
  const { showDocumentRelationsImproved } = useEcm();

  return (
    <TransactionAssociationProvider>
      {showDocumentRelationsImproved ? (
        <TransactionAssociationAdminRequesterComponent />
      ) : (
        <TransactionAssociationAdminRequesterComponentDeprecated />
      )}
    </TransactionAssociationProvider>
  );
};

export const TransactionHistory = ({
  transactionId,
  ownerTxMembershipId,
}: { transactionId: string; ownerTxMembershipId: string | undefined }) => {
  const { loading, transaction } = useTransactionHistory(transactionId);
  const [t] = useTranslation();
  const timelineEntriesCount = transaction?.timeline?.length ?? 0;
  const showCountSuffix = !loading && timelineEntriesCount > 0;
  const countSuffix = showCountSuffix ? ` (${timelineEntriesCount})` : '';
  const charsAfterEllipsis = countSuffix.length && countSuffix.length - 1;
  const historyTitle = t('document.tabs.protocol') + countSuffix;

  const title = (
    <TruncatedText
      charsAfterEllipsis={charsAfterEllipsis}
      fontWeight="semibold"
    >
      {historyTitle}
    </TruncatedText>
  );

  return (
    <div className="grid pb-4 h-full">
      <CollapsibleCard
        borderRadius="medium"
        overflow="visible"
        id="document-summary-protocol-open"
        headerProps={{ background: 'gray0' }}
        header={title}
        data-testid={t('document.tabs.protocol')}
        defaultOpen
        isStatic
        height="100%"
      >
        <div
          className={clsx(
            'bg-white overflow-hidden pb-2 rounded-[inherit] h-full'
          )}
        >
          <div className="py-4 px-2">
            <CommentTransactionContainer
              transactionId={transactionId}
              ownerTxMembershipId={ownerTxMembershipId}
            />
          </div>
          <TransactionHistoryContainer transactionId={transactionId} />
        </div>
      </CollapsibleCard>
    </div>
  );
};

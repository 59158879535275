import { Icon, Tooltip, useTooltip } from '@candisio/design-system';
import { PowerSearchToggleButtonProps } from '../types';
import { ToggleButtonItem } from 'components/ToggleButtonGroup/ToggleButton';
import styles from './power-search-toggles.module.css';

export const PowerSearchToggleButton = ({
  id,
  icon,
  children,
  variant = 'default',
}: PowerSearchToggleButtonProps) => {
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      delay: 0,
    });

  return (
    <>
      {/* need to wrap with div for tooltip to work as expected */}
      <div ref={triggerRef} {...triggerProps}>
        <ToggleButtonItem
          aria-label={children}
          id={id}
          className={styles['power-search-toggle-button']}
          data-variant={variant}
        >
          <Icon icon={icon} />
        </ToggleButtonItem>
      </div>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {children}
        </Tooltip>
      )}
    </>
  );
};

import { Icon } from '@candisio/design-system/next';
import { StatusIcon } from 'components/DocumentsTable/next/components/StatusCell';
import { TagWithTooltip } from 'components/TagWithTooltip';
import { GetDocumentForDraftQuery } from 'generated-types/graphql.types';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface EInvoiceInfoIconProps {
  /** E-Invoice flag */
  isEInvoice?: NonNullable<
    GetDocumentForDraftQuery['getDocument']
  >['isEInvoice'];
  /** E-Invoice data */
  eInvoice?: NonNullable<GetDocumentForDraftQuery['getDocument']>['eInvoice'];
  /** Icon label */
  iconLabel?: ReactNode;
  /** Show warning description */
  showWarningDescription?: boolean;
}

export const EInvoiceInfoIcon = ({
  isEInvoice,
  eInvoice,
  iconLabel,
  showWarningDescription,
}: EInvoiceInfoIconProps) => {
  const [t] = useTranslation();

  const eInvoiceWarningMessage = (
    <span>
      {t('common:document.compact.history.invalidEInvoice.warning.message')}
    </span>
  );

  if (eInvoice?.error) {
    if (showWarningDescription) {
      return (
        <div className="flex relative gap-1 items-center">
          <StatusIcon
            label={iconLabel ?? t('documents-table:statuses.eInvoice')}
            icon="eInvoice"
            className="text-gray-600"
            hasTooltip
          />
          <TagWithTooltip
            color="yellow"
            variant="secondary"
            label={
              <>
                <Icon icon="warning" className="text-yellow-500" />
                <span>
                  {t(
                    'common:document.compact.history.invalidEInvoice.warning.title'
                  )}
                </span>
              </>
            }
            description={eInvoiceWarningMessage}
          />
        </div>
      );
    }

    return (
      <div className="flex relative gap-1 items-center">
        <StatusIcon
          label={iconLabel ?? t('documents-table:statuses.eInvoice')}
          icon="eInvoice"
          className="text-gray-600"
          hasTooltip
        />
        <StatusIcon
          label={eInvoiceWarningMessage}
          icon="warning"
          className="text-yellow-500"
        />
      </div>
    );
  }

  if (isEInvoice) {
    return (
      <StatusIcon
        label={iconLabel ?? t('documents-table:statuses.eInvoice')}
        icon="eInvoice"
        className="text-gray-600"
        hasTooltip
      />
    );
  }
};

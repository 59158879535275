import { CreditCardsTableTab } from './CreditCardsTableTab';
import { CreditCardsIssueNewCardModal } from '../CreditCardsInsights/CreditCardsIssueNewCardModal';
import { CreditCardsDetailsDrawer } from '../CreditCardDetailsDrawer';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { OrganizationSettingsDrawer } from '../CreditCardsInsights/OrganizationSettingsDrawer/OrganizationSettingsDrawer';

export const CreditCardsTableView = () => {
  return (
    <CreditCardsProvider>
      <>
        <CreditCardsTableTab />
        <CreditCardsIssueNewCardModal />
        <CreditCardsDetailsDrawer />
      </>
      <OrganizationSettingsDrawer />
    </CreditCardsProvider>
  );
};

import { useCreditCardCountData } from 'views/CreditCards/hooks/useCreditCardCountData';
import { useCreditCardRequestCountData } from 'views/CreditCards/hooks/useCreditCardRequestCountData';
import { useGetCountExpiringSoonCards } from 'views/CreditCards/hooks/useGetCountExpiringSoonCards';
import { useGetTransactionDeclinedInLastWeek } from 'views/CreditCards/hooks/useGetTransactionDeclinedInLastWeek';
import { CardsAndRequestsOverviewWidget } from './CardsAndRequestsOverviewWidget';
import { CardsAndRequestsOverviewWidgetLoading } from './CardsAndRequestsOverviewWidgetLoading';
import { CardsAndRequestsOverviewWidgetNoTodos } from './CardsAndRequestsOverviewWidgetNoTodos';

export const CardsAndRequestsOverviewWidgetContainer = () => {
  const { countExpiringCards, loading: isLoadingExpiringCards } =
    useGetCountExpiringSoonCards();

  const {
    declinedTransactionsCount,
    loading: isLoadingDeclinedTransactions,
    lastWeekFilter,
  } = useGetTransactionDeclinedInLastWeek();

  const {
    loading: isLoadingRequestCounter,
    pendingCardRequests,
    cardLimitChangeRequests,
  } = useCreditCardRequestCountData();

  const { loading, totalNumberOfActiveCards } = useCreditCardCountData();

  const isLoadingCounters =
    isLoadingExpiringCards ||
    isLoadingDeclinedTransactions ||
    isLoadingRequestCounter ||
    loading;

  const allTodos =
    countExpiringCards +
    declinedTransactionsCount +
    pendingCardRequests +
    cardLimitChangeRequests;

  if (isLoadingCounters) {
    return <CardsAndRequestsOverviewWidgetLoading />;
  }

  if (allTodos === 0) {
    return (
      <CardsAndRequestsOverviewWidgetNoTodos
        activeCards={totalNumberOfActiveCards}
      />
    );
  }

  return (
    <CardsAndRequestsOverviewWidget
      cardLimitChangeRequests={cardLimitChangeRequests}
      countExpiringCards={countExpiringCards}
      declinedTransactionsCount={declinedTransactionsCount}
      pendingCardRequests={pendingCardRequests}
      totalNumberOfActiveCards={totalNumberOfActiveCards}
      lastWeekFilter={lastWeekFilter}
    />
  );
};

import {
  CalendarDate,
  DateValue,
  createCalendar,
  isToday,
  startOfWeek,
  today,
} from '@internationalized/date';
import clsx from 'clsx';
import { forwardRef, useMemo, useRef } from 'react';
import {
  AriaButtonProps,
  mergeProps,
  useButton,
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { CalendarState, useCalendarState } from 'react-stately';
import { MenuButton } from '../../Molecules/MenuButton';
import { useTheme } from '../../Theme';
import { space } from '../../Theme/themeValues';
import {
  LayoutProps,
  StandardButtonHTMLAttributes,
  StandardHTMLAttributes,
} from '../../types';
import { Box } from '../Box';
import { Button } from '../Button';
import { parseValue } from '../DateInput';
import { Grid } from '../Grid';
import { MenuProps } from '../Menu';

const CALENDAR_NUM_OF_WEEKS = 6;

export interface CalendarProps
  extends LayoutProps,
    Omit<StandardHTMLAttributes<HTMLDivElement>, 'onChange'> {
  /** Should calendar receive focus on render? */
  autoFocus?: boolean;
  /** (Uncontrolled) initial value of the calendar in ISO 8601 date format */
  defaultValue?: string;
  /** Is calendar disabled? */
  disabled?: boolean;
  /** Unique identifier of the calendar */
  id?: string;
  /** Locale to use to format dates */
  locale?: string;
  /** Called when the value is changed by the user */
  onChange?: (value: string) => void;
  /** Is calendar read only? */
  readOnly?: boolean;
  /** (Controlled) value of the calendar in ISO 8601 date format */
  value?: string;
  /** Label for today button */
  todayLabel?: string;
  /** Label for next month button */
  nextMonthLabel?: string;
  /** Label for previous month button */
  prevMonthLabel?: string;
  /** Minimum date that can be selected */
  minValue?: DateValue;
  /** Maximum date that can be selected */
  maxValue?: DateValue;
}

/** An input for picking a date from a calendar */
export const Calendar = forwardRef<HTMLDivElement, CalendarProps>(
  (
    {
      autoFocus,
      defaultValue: defaultValueProp,
      disabled,
      id,
      value: valueProp,
      locale = 'en-DE',
      onChange,
      readOnly,
      todayLabel = 'Today',
      nextMonthLabel = 'Next month',
      prevMonthLabel = 'Previous month',
      minValue,
      maxValue,
      ...restProps
    },
    forwardedRef
  ) => {
    const { space, calendar } = useTheme();
    const value = valueProp ? parseValue(valueProp) : undefined;
    const defaultValue = defaultValueProp
      ? parseValue(defaultValueProp)
      : undefined;

    const state = useCalendarState({
      autoFocus,
      createCalendar,
      defaultValue,
      isDisabled: disabled,
      isReadOnly: readOnly,
      locale,
      onChange: (value: DateValue | null) => {
        const date = value?.toString() ?? '';

        return onChange?.(date);
      },
      value,
      minValue,
      maxValue,
    });

    const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
      {
        autoFocus,
        defaultValue,
        id,
        isDisabled: disabled,
        isReadOnly: readOnly,
        value,
        minValue,
        maxValue,
      },
      state
    );

    const valueAsDate = state.focusedDate.toDate(state.timeZone);
    const monthFormatter = new Intl.DateTimeFormat(locale, { month: 'long' });
    const yearFormatter = new Intl.DateTimeFormat(locale, { year: 'numeric' });

    const monthItems: MenuProps['items'] = Array.from(
      { length: 12 },
      (_, index) => {
        const monthOffset = index - 6;
        const month = state.focusedDate.add({ months: monthOffset });
        const monthAsDate = month.toDate(state.timeZone);

        return {
          id: String(month.month),
          label: monthFormatter.format(monthAsDate),
        };
      }
    );

    const yearItems: MenuProps['items'] = Array.from(
      { length: 16 },
      (_, index) => {
        const yearOffset = index - 10;
        const year = state.focusedDate.add({ years: yearOffset });
        const yearAsDate = year.toDate(state.timeZone);

        return {
          id: String(year.year),
          label: yearFormatter.format(yearAsDate),
        };
      }
    );

    return (
      <Grid
        css={[calendar.grid]}
        {...mergeProps(calendarProps, restProps)}
        ref={forwardedRef}
      >
        <Grid
          autoFlow="column"
          css={[calendar.header]}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            gap="space2"
            templateColumns={`auto ${space.space80}`}
            alignItems="center"
            justifyItems="center"
          >
            <Grid
              gap="space2"
              templateColumns={`auto auto ${space.space96}`}
              alignItems="center"
              justifyItems="center"
            >
              <ArrowButton
                direction="left"
                label={prevMonthLabel}
                {...prevButtonProps}
              />
              <ArrowButton
                direction="right"
                label={nextMonthLabel}
                {...nextButtonProps}
              />
              <MenuButton
                items={monthItems}
                onChange={([newValue]) => {
                  if (newValue) {
                    const newDate = state.focusedDate.set({
                      month: Number(newValue),
                    });

                    state.setFocusedDate(newDate);
                  }
                }}
                selectionMode="single"
                value={[String(state.focusedDate.month)]}
                variant="tertiary"
              >
                {monthFormatter.format(valueAsDate)}
              </MenuButton>
            </Grid>
            <MenuButton
              items={yearItems}
              onChange={([newValue]) => {
                if (newValue) {
                  const newDate = state.focusedDate.set({
                    year: Number(newValue),
                  });

                  state.setFocusedDate(newDate);
                }
              }}
              selectionMode="single"
              value={[String(state.focusedDate.year)]}
              variant="tertiary"
            >
              {yearFormatter.format(valueAsDate)}
            </MenuButton>
          </Grid>
          <Button
            onClick={() => {
              const todaysDate = today(state.timeZone);
              state.setValue(todaysDate);
              state.setFocusedDate(todaysDate);
            }}
            variant="tertiary"
          >
            {todayLabel}
          </Button>
        </Grid>
        <CalendarGrid locale={locale} state={state} />
      </Grid>
    );
  }
);

interface ArrowButtonProps extends AriaButtonProps {
  direction: 'left' | 'right';
  label: string;
}

const ArrowButton = forwardRef<HTMLButtonElement, ArrowButtonProps>(
  (
    { direction = 'right', label, onBlur, onFocus, onPress, isDisabled },
    forwardedRef
  ) => {
    const ref = useRef(null);
    const { buttonProps } = useButton(
      { 'aria-label': label, isDisabled, onBlur, onFocus, onPress },
      ref
    );

    return (
      <Button
        icon={direction === 'left' ? 'caretLeft' : 'caretRight'}
        label={label}
        variant="tertiary"
        {...(buttonProps as StandardButtonHTMLAttributes<HTMLButtonElement>)}
        ref={mergeRefs([ref, forwardedRef])}
      />
    );
  }
);

interface UseDaysOfWeekOptions {
  locale: string;
  state: CalendarState;
}

/**
 * Gets a list of days of the week, in “short” format according to the specified
 * locale.
 */
const useDaysOfWeek = ({ locale, state }: UseDaysOfWeekOptions) => {
  const daysOfWeek = useMemo(() => {
    const dayFormatter = new Intl.DateTimeFormat(locale, { weekday: 'short' });

    const weekStart = startOfWeek(today(state.timeZone), locale);

    return Array.from({ length: 7 }, (_, index) => {
      const day = weekStart.add({ days: index });
      const dayAsDate = day.toDate(state.timeZone);

      return dayFormatter.format(dayAsDate);
    });
  }, [locale, state.timeZone]);

  return daysOfWeek;
};

interface CalendarGridProps {
  locale: string;
  state: CalendarState;
}

const CalendarGrid = forwardRef<HTMLTableElement, CalendarGridProps>(
  ({ locale, state }, ref) => {
    const { gridProps, headerProps } = useCalendarGrid({}, state);

    const { calendar } = useTheme();

    const daysOfWeek = useDaysOfWeek({ state, locale });

    return (
      <Box as="table" css={[calendar.table]} {...gridProps} ref={ref}>
        <Box as="thead" {...headerProps}>
          <Box as="tr">
            {daysOfWeek.map(day => (
              <Box as="th" key={day}>
                {day}
              </Box>
            ))}
          </Box>
        </Box>
        <Box as="tbody">
          {Array.from({ length: CALENDAR_NUM_OF_WEEKS }, (_, weekIndex) => (
            <Box as="tr" key={weekIndex}>
              {state
                .getDatesInWeek(weekIndex)
                .map((date, dayIndex) =>
                  date ? (
                    <CalendarCell
                      key={dayIndex}
                      date={date}
                      state={state}
                      isFirstDayInWeek={dayIndex === 0}
                      isLastDayInWeek={
                        dayIndex === state.getDatesInWeek(weekIndex).length - 1
                      }
                      isFirstWeekInMonth={weekIndex === 0}
                      isLastWeekInMonth={
                        weekIndex === CALENDAR_NUM_OF_WEEKS - 1
                      }
                    />
                  ) : (
                    <Box as="td" key={dayIndex} />
                  )
                )}
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
);

interface CalendarCellProps {
  date: CalendarDate;
  state: CalendarState;
  isFirstDayInWeek: boolean;
  isLastDayInWeek: boolean;
  isLastWeekInMonth: boolean;
  isFirstWeekInMonth: boolean;
}

const CalendarCell = forwardRef<HTMLTableCellElement, CalendarCellProps>(
  (
    {
      date,
      state,
      isFirstDayInWeek,
      isLastDayInWeek,
      isFirstWeekInMonth,
      isLastWeekInMonth,
    },
    forwardedRef
  ) => {
    const { calendar } = useTheme();

    const ref = useRef(null);
    const { cellProps, buttonProps, isSelected, formattedDate, isDisabled } =
      useCalendarCell({ date }, state, ref);

    const marginStyle = {
      marginTop: isFirstWeekInMonth ? space.space4 : 'initial',
      marginBottom: isLastWeekInMonth ? space.space4 : 'initial',
      marginLeft: isFirstDayInWeek ? space.space4 : 'initial',
      marginRight: isLastDayInWeek ? space.space4 : 'initial',
    };

    const borderRadiusStyle = {
      borderTopLeftRadius:
        isFirstWeekInMonth && isFirstDayInWeek
          ? `${space.space8} ${space.space8}`
          : 'initial',
      borderTopRightRadius:
        isFirstWeekInMonth && isLastDayInWeek
          ? `${space.space8} ${space.space8}`
          : 'initial',
      borderBottomLeftRadius:
        isLastWeekInMonth && isFirstDayInWeek
          ? `${space.space8} ${space.space8}`
          : 'initial',
      borderBottomRightRadius:
        isLastWeekInMonth && isLastDayInWeek
          ? `${space.space8} ${space.space8}`
          : 'initial',
    };

    return (
      <Box as="td" {...cellProps} ref={forwardedRef} css={borderRadiusStyle}>
        <Grid placeContent="center">
          <Grid
            css={[
              calendar.table.tbody.td.default,
              isDisabled && calendar.table.tbody.td.disabled,
              isSelected && calendar.table.tbody.td.selected,
              marginStyle,
            ]}
            placeContent="center"
            className={clsx('focus:shadow-focus', {
              'hover:bg-blue-bg': !isDisabled,
            })}
            {...buttonProps}
            ref={ref}
          >
            {formattedDate}
            {isToday(date, state.timeZone) && (
              <Box css={[calendar.todayMarker]} />
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
);

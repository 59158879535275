// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import { ApprovalsLayout } from './ApprovalsLayout';
import { ApprovalsHeaderContainer } from './Header/ApprovalsHeaderContainer';
import { Tab } from './Tabs/AppprovalsTabs';
import { ApprovalsTabsContainer } from './Tabs/ApprovalsTabsContainer';

export type ApprovalsViewProps = RouteComponentProps<{}> & {
  activeTab: Tab;
};

export const ApprovalsView = (props: ApprovalsViewProps) => {
  return (
    <ApprovalsLayout
      header={<ApprovalsHeaderContainer activeTab={props.activeTab} />}
      tabs={<ApprovalsTabsContainer {...props} />}
    />
  );
};

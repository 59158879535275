import { Grid } from '@candisio/design-system';
import { LottieAnimation } from 'components/Lottie/Lottie';
import { AnimatePresence, motion } from 'motion/react';
import { ReactNode } from 'react';

interface EmptyStateProps {
  animationData?: {};
  /** Trans component or unstyled translations */
  children?: ReactNode;
  imageUrl?: string;
}

const MotionGrid = motion.create(Grid);

const gridVariants = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.6,
    },
  },
};

export const EmptyState = ({
  animationData,
  children,
  imageUrl,
}: EmptyStateProps) => (
  <AnimatePresence>
    <MotionGrid
      color="gray800"
      fontSize="basic"
      gap="space8"
      lineHeight="paragraph"
      justifyItems="center"
      textAlign="center"
      alignContent="center"
      background="gray0"
      height="100%"
      variants={gridVariants}
      initial="hide"
      animate="show"
      exit="hide"
    >
      {imageUrl ? (
        <Grid justifyItems="center">
          <img src={imageUrl} alt="" />
        </Grid>
      ) : null}
      {animationData ? <LottieAnimation options={{ animationData }} /> : null}
      <Grid>{children}</Grid>
    </MotionGrid>
  </AnimatePresence>
);

import gql from 'graphql-tag';

export const inAppNotificationData = gql`
  fragment InAppNotificationData on InAppNotification {
    __typename
    id
    isRead
    notificationCreatedAt
    organizationId
  }
`;

const cardRequestApprovedInAppNotificationData = gql`
  fragment CardRequestApprovedInAppNotificationData on CardRequestApprovedInAppNotification {
    __typename
    cardRequestType
    resolutionComment
    limit {
      value
      precision
      currency
    }
    transactionLimit {
      value
      precision
      currency
    }
    cardManager {
      avatarUrl
      name
    }
    cardId
    cardRefNum
    cardRequestStatusChangedAt
  }
`;

const cardRequestCreatedInAppNotificationData = gql`
  fragment CardRequestCreatedInAppNotificationData on CardRequestCreatedInAppNotification {
    __typename
    cardRequestCreatedAt
    cardRequestType
    requester {
      name
      avatarUrl
    }
    cardId
    cardRefNum
    newRequestedLimit {
      value
      precision
      currency
    }
    newRequestedTransactionLimit {
      value
      precision
      currency
    }
  }
`;

const cardRequestRejectedInAppNotificationData = gql`
  fragment CardRequestRejectedInAppNotificationData on CardRequestRejectedInAppNotification {
    __typename
    organizationId
    cardRequestStatusChangedAt
    cardRequestType
    resolutionComment
    limit {
      value
      precision
      currency
    }
    transactionLimit {
      value
      precision
      currency
    }
    cardManager {
      avatarUrl
      name
    }
    cardRefNum
  }
`;

const documentCommentCreatedInAppNotificationData = gql`
  fragment DocumentCommentCreatedInAppNotificationData on DocumentCommentCreatedInAppNotification {
    __typename
    documentId
    globalDocumentId
    documentType
    commentText
    commentCreatedAt
    documentNumberCommentCreated: documentNumber
    invoiceType
    invoiceContact
    invoiceAmount {
      value
      currencyCode
    }
    invoiceStatus
    fileName
    organizationId
    author {
      name
      avatarUrl
    }
  }
`;

const transactionDeclinedInAppNotificationData = gql`
  fragment TransactionDeclinedInAppNotificationData on TransactionDeclinedInAppNotification {
    __typename
    transactionId
    merchantName
    cardRefNum
    organizationId
    transactionCreatedAt
    declineReason {
      title {
        de
        en
      }
    }
    notificationCreatedAt
    transactionAmount {
      value
      precision
      currency
    }
  }
`;

const documentRejectedInAppNotificationData = gql`
  fragment DocumentRejectedInAppNotificationData on DocumentRejectedInAppNotification {
    __typename
    documentId
    commentText
    rejectedAt
    invoiceContact
    documentNumber
    invoiceAmount {
      value
      currencyCode
      __typename
    }
    rejectedBy {
      name
      avatarUrl
    }
    fileName
    organizationId
  }
`;

const transactionCommentCreatedInAppNotificationData = gql`
  fragment TransactionCommentCreatedInAppNotificationData on TransactionCommentCreatedInAppNotification {
    __typename
    transactionId
    commentText
    commentCreatedAt
    cardId
    cardRefNum
    cardLabel
    merchantName
    transactionAmount {
      value
      currency
      precision
    }
    author {
      name
      avatarUrl
    }
    organizationId
  }
`;

const reimbursementCaseRejectedInAppNotificationData = gql`
  fragment ReimbursementCaseRejectedInAppNotificationData on ReimbursementCaseRejectedInAppNotification {
    __typename
    reimbursementCaseId
    reimbursementCaseTitle
    commentText
    reimbursementCaseTotalAmount {
      value
      precision
      currency
    }
    rejectedBy {
      name
      avatarUrl
    }
    rejectedAt
  }
`;

const reimbursementCaseItemExcludedInAppNotificationData = gql`
  fragment ReimbursementCaseItemExcludedInAppNotificationData on ReimbursementCaseItemExcludedInAppNotification {
    __typename
    title
    type
    reimbursementCaseId
    reimbursementCaseTitle
    totalAmount {
      value
      precision
      currency
    }
    updatedBy {
      name
      avatarUrl
    }
    updatedAt
  }
`;

const reimbursementCaseAmendmentRequestedInAppNotificationData = gql`
  fragment ReimbursementCaseAmendmentRequestedInAppNotificationData on ReimbursementCaseAmendmentRequestedInAppNotification {
    __typename
    reimbursementCaseId
    reimbursementCaseTitle
    commentText
    reimbursementCaseTotalAmount {
      value
      precision
      currency
    }
    reviewedBy {
      name
      avatarUrl
    }
    reviewedAt
  }
`;

const reimbursementCaseApprovedInAppNotificationData = gql`
  fragment ReimbursementCaseApprovedInAppNotificationData on ReimbursementCaseApprovedInAppNotification {
    __typename
    reimbursementCaseId
    reimbursementCaseTitle
    approvedBy {
      name
      avatarUrl
    }
    approvedAt
  }
`;

const reimbursementCaseCommentCreatedInAppNotification = gql`
  fragment ReimbursementCaseCommentCreatedInAppNotificationData on ReimbursementCaseCommentCreatedInAppNotification {
    __typename
    reimbursementCaseId
    reimbursementCaseTitle
    commentText
    author {
      name
      avatarUrl
    }
    commentCreatedAt
    reimbursementCaseTotalAmount {
      value
      precision
      currency
    }
  }
`;

export const inAppNotificationsSubscription = gql`
  subscription inAppNotification {
    inAppNotification {
      __typename
      id
      isRead
      notificationCreatedAt
      organizationId
      ...InAppNotificationData
      ...CardRequestApprovedInAppNotificationData
      ...CardRequestCreatedInAppNotificationData
      ...CardRequestRejectedInAppNotificationData
      ...DocumentCommentCreatedInAppNotificationData
      ...DocumentRejectedInAppNotificationData
      ...TransactionCommentCreatedInAppNotificationData
      ...TransactionDeclinedInAppNotificationData
      ...ReimbursementCaseRejectedInAppNotificationData
      ...ReimbursementCaseItemExcludedInAppNotificationData
      ...ReimbursementCaseAmendmentRequestedInAppNotificationData
      ...ReimbursementCaseApprovedInAppNotificationData
      ...ReimbursementCaseCommentCreatedInAppNotificationData
    }
  }
  ${inAppNotificationData}
  ${cardRequestApprovedInAppNotificationData}
  ${cardRequestCreatedInAppNotificationData}
  ${cardRequestRejectedInAppNotificationData}
  ${documentCommentCreatedInAppNotificationData}
  ${documentRejectedInAppNotificationData}
  ${transactionCommentCreatedInAppNotificationData}
  ${transactionDeclinedInAppNotificationData}
  ${reimbursementCaseRejectedInAppNotificationData}
  ${reimbursementCaseItemExcludedInAppNotificationData}
  ${reimbursementCaseAmendmentRequestedInAppNotificationData}
  ${reimbursementCaseApprovedInAppNotificationData}
  ${reimbursementCaseCommentCreatedInAppNotification}
`;

export const getInAppNotificationsPageBasedQuery = gql`
  query inAppNotificationsPageBased(
    $input: InAppNotificationPaginationInput
    $filters: InAppNotificationFilterInput
    $dateRangeFilters: InAppNotificationDateFilterInput
    $sortBy: InAppNotificationSortInput
  ) {
    inAppNotifications(
      input: $input
      filters: $filters
      dateRangeFilters: $dateRangeFilters
      sortBy: $sortBy
    )
      @connection(
        key: "inAppNotificationsPageBased"
        filter: ["filters", "dateRangeFilters", "sortBy"]
      ) {
      records {
        __typename
        id
        isRead
        notificationCreatedAt
        organizationId
        ...InAppNotificationData
        ...CardRequestApprovedInAppNotificationData
        ...CardRequestCreatedInAppNotificationData
        ...CardRequestRejectedInAppNotificationData
        ...DocumentCommentCreatedInAppNotificationData
        ...DocumentRejectedInAppNotificationData
        ...TransactionCommentCreatedInAppNotificationData
        ...TransactionDeclinedInAppNotificationData
        ...ReimbursementCaseRejectedInAppNotificationData
        ...ReimbursementCaseItemExcludedInAppNotificationData
        ...ReimbursementCaseAmendmentRequestedInAppNotificationData
        ...ReimbursementCaseApprovedInAppNotificationData
        ...ReimbursementCaseCommentCreatedInAppNotificationData
      }
      pageInfo {
        __typename
        totalCount
        currentPage
        pageCount
        pageSize
      }
    }
  }
  ${inAppNotificationData}
  ${cardRequestApprovedInAppNotificationData}
  ${cardRequestCreatedInAppNotificationData}
  ${cardRequestRejectedInAppNotificationData}
  ${documentCommentCreatedInAppNotificationData}
  ${documentRejectedInAppNotificationData}
  ${transactionCommentCreatedInAppNotificationData}
  ${transactionDeclinedInAppNotificationData}
  ${reimbursementCaseRejectedInAppNotificationData}
  ${reimbursementCaseItemExcludedInAppNotificationData}
  ${reimbursementCaseAmendmentRequestedInAppNotificationData}
  ${reimbursementCaseApprovedInAppNotificationData}
  ${reimbursementCaseCommentCreatedInAppNotification}
`;

export const getOrganizationsByMembershipQuery = gql`
  query organizationsByMembership($membershipId: ID!) {
    organizationsByMembership(membershipId: $membershipId) {
      id
      name
      slug
    }
  }
`;

export const getInAppNotificationsCountQuery = gql`
  query inAppNotificationsCount(
    $filters: InAppNotificationFilterInput
    $dateRangeFilters: InAppNotificationDateFilterInput
  ) {
    inAppNotificationsCount(
      filters: $filters
      dateRangeFilters: $dateRangeFilters
    )
  }
`;

export const toggleInAppNotifications = gql`
  mutation toggleInAppNotifications(
    $input: ToggleInAppNotificationsInput!
    $filters: InAppNotificationFilterInput
    $dateRangeFilters: InAppNotificationDateFilterInput
  ) {
    toggleInAppNotifications(
      input: $input
      filters: $filters
      dateRangeFilters: $dateRangeFilters
    )
  }
`;

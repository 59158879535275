import { Box, Flex, Grid, Spinner } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useGetSapB1Credentials } from 'hooks/useGetSAPB1Credentials';
import { useSap } from 'orgConfig/sap';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from 'views/Settings/utils';
import { LOCALE_NAME_SPACE } from '../../../../providers/LocaleProvider';
import { SettingsLayout } from '../../components/SettingsLayout/SettingsLayout';
import { SapConnectionConfiguration } from './components/SapConnectionConfiguration';
import { SapConnectionSummary } from './components/SapConnectionSummary';
import SapLogo from './images/logo.svg';

const LoadingWrapper = ({ children }: { children: ReactElement }) => (
  <Flex justifyContent="center" textAlign="center" paddingY="5rem">
    {children}
  </Flex>
);

const SapSettingsBase = ({ MainContent }: { MainContent: ReactElement }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);
  const { shouldUseSapPurchaseOrder } = useSap();

  return (
    <SettingsLayout
      title={t('settings.header.title')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <Box>{MainContent}</Box>
      <Grid alignContent="start" gap="space32">
        <InfoPanel
          logo={{ src: SapLogo }}
          sections={t('settings.infoPanel.sections', { returnObjects: true })}
          externalLink={t('settings.infoPanel.externalLink', {
            returnObjects: true,
          })}
        />
        {shouldUseSapPurchaseOrder && (
          <InfoPanel
            title={t('settings.infoPanelFreight.title')}
            sections={t('settings.infoPanelFreight.sections', {
              returnObjects: true,
            })}
          />
        )}
      </Grid>
    </SettingsLayout>
  );
};

const SapSettingsLoading = () => {
  return (
    <SapSettingsBase
      MainContent={
        <LoadingWrapper>
          <Spinner size="space32" color="gray500" />
        </LoadingWrapper>
      }
    />
  );
};

const SapSettingsSuccess = ({ isConfigured }: { isConfigured: boolean }) => {
  return (
    <SapSettingsBase
      MainContent={
        isConfigured ? <SapConnectionSummary /> : <SapConnectionConfiguration />
      }
    />
  );
};

export const SapSettings = () => {
  const { data, loading } = useGetSapB1Credentials();
  const isConfigured = Boolean(data?.sapB1Credentials?.companyDbId);

  if (loading) {
    return <SapSettingsLoading />;
  }

  return <SapSettingsSuccess isConfigured={isConfigured} />;
};

import { Box, TruncatedText } from '@candisio/design-system';
import { TransactionAmount } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { amountFormatNonNull } from 'utils/format';

export const AmountText = ({
  value,
  isRefundType,
  variant,
  ...rest
}: Omit<ComponentProps<typeof TruncatedText>, 'children'> & {
  value: TransactionAmount;
  isRefundType: boolean;
  variant: 'mainCurrency' | 'secondaryCurrency';
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  if (!value) {
    return <Box>-</Box>;
  }

  const formattedAmount = amountFormatNonNull(value.value, value.currency, {
    convertToBasicMonetaryUnit: true,
  });

  const transalationKey =
    variant === 'secondaryCurrency'
      ? 'transactionDetailsCard.billingAmountWithPrefixPaidInForeignCurrency'
      : 'transactionDetailsCard.billingAmountWithPrefix';

  const amountTranslation = t(transalationKey, {
    prefix: isRefundType ? '+' : undefined,
    amount: formattedAmount,
  });

  return <TruncatedText {...rest}>{amountTranslation}</TruncatedText>;
};

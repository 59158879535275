import { LeftSection } from './LeftSection';
import { MiddleSectionContainer } from './MiddleSectionContainer';
import { RightSectionContainer } from './RightSectionContainer';
import { useExpenseFormsHelpers } from './components/MiddleSection/utils/useExpenseFormsHelpers';
import { ReimbursementLayout } from './components/ReimbursementLayout';
import { ReimbursementItemsFormOutput } from './toolkit/reimbursementItemsFormSchema';

interface ReimbursementProps {
  defaultValues: ReimbursementItemsFormOutput;
  isLoading: boolean;
  nextReimbursementLink?: string;
  prevReimbursementLink?: string;
  reimbursementCount?: number;
  cycleReimbursements: () => void;
}

export const Reimbursement = ({
  defaultValues,
  isLoading,
  nextReimbursementLink,
  prevReimbursementLink,
  cycleReimbursements,
  reimbursementCount,
}: ReimbursementProps) => {
  const formHelpers = useExpenseFormsHelpers({ defaultValues });

  return (
    <ReimbursementLayout
      leftSection={
        <LeftSection
          formHelpers={formHelpers}
          isLoading={isLoading}
          reimbursementCount={reimbursementCount}
          nextReimbursementLink={nextReimbursementLink}
          prevReimbursementLink={prevReimbursementLink}
        />
      }
      middleSection={
        <MiddleSectionContainer
          isLoading={isLoading}
          formHelpers={formHelpers}
        />
      }
      rightSection={
        <RightSectionContainer
          isLoading={isLoading}
          onCycleReimbursments={cycleReimbursements}
          getExpenses={formHelpers.formMethods.getValues}
        />
      }
    />
  );
};

import { ComponentProps, forwardRef } from 'react';
import { IconKey } from '../../../Particles/Spritemap';
import clsx from 'clsx';
import styles from './Icon.module.css';

export interface IconProps extends ComponentProps<'svg'> {
  icon: IconKey;
}

/**
 * Icon display a chosen icon from our design system
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-media-icon}
 *
 * @param {IconKey} icon Icon from our collection
 */
export const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ icon, className, ...restProps }, ref) => {
    return (
      <svg
        className={clsx(styles['icon'], className)}
        viewBox="0 0 16 16"
        fill="currentColor"
        {...restProps}
        ref={ref}
      >
        <use href={`#icon-${icon}`} />
      </svg>
    );
  }
);

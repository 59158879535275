import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  PerDiemStep,
  useAddPerDiemSegmentMutation,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { ReimbursementItemSchemaOutput } from '../toolkit/reimbursementItemsFormSchema';
import {
  perDiemItemDetailsQuery,
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsListQuery,
  reimbursementItemsSplitBookingsQuery,
} from '../toolkit/queries';
import { ReimbursementRouteParams } from './useReimbursementNavigation';
import { sanitizePerDiemFormInput } from '../utils/sanitizePerDiemFormInput';

export const useAddPerDiemSegment = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<ReimbursementRouteParams>();

  const { error } = useToastMessage();

  const [addSegment, { loading: isAddSegmentPending }] =
    useAddPerDiemSegmentMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: reimbursementCaseByIdQuery,
          variables: {
            id: reimbursementId,
          },
        },
        {
          query: reimbursementItemsListQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
        {
          query: reimbursementItemsSplitBookingsQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
        {
          query: reimbursementHistoryQuery,
          variables: { id: reimbursementId },
        },
        {
          query: perDiemItemDetailsQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
      ],
    });

  const handleAddPerDiemSegment = useCallback(
    async (values: ReimbursementItemSchemaOutput) => {
      if (!values.reimbursementItemId) return;

      const { errors } = await addSegment({
        variables: {
          id: values.reimbursementItemId,
          input: {
            reimbursementCaseId: reimbursementId,
            currentStep: PerDiemStep.Segments,
            ...sanitizePerDiemFormInput(values),
          },
        },
      });

      if (errors?.length) {
        error(t('reimbursementView.createErr'));

        return;
      }
    },
    [error, reimbursementId, t, addSegment]
  );

  return {
    isAddSegmentPending,
    addPerDiemSegment: handleAddPerDiemSegment,
  };
};

import { UseFormReturn } from 'react-hook-form';
import { generateSegmentFieldNames } from '../components/MiddleSection/utils/generatePerDiemFieldNames';
import { ReimbursementItemsFormOutput } from '../toolkit/reimbursementItemsFormSchema';

interface UseGetPerDiemSegmentValuesParams {
  formIndex: number;
  segmentsLength: number;
  formMethods: UseFormReturn<ReimbursementItemsFormOutput>;
}

export const useGetPerDiemSegmentValues = ({
  formIndex,
  segmentsLength,
  formMethods,
}: UseGetPerDiemSegmentValuesParams) => {
  const { getValues } = formMethods;
  const getPerDiemSegmentValues = () => {
    const firstSegmentFieldsNames = generateSegmentFieldNames(formIndex, 0);
    const lastSegmentFielsdNames = generateSegmentFieldNames(
      formIndex,
      segmentsLength - 1
    );
    const startDateTravel = getValues(firstSegmentFieldsNames.startDate);
    const startTimeTravel = getValues(firstSegmentFieldsNames.startTime);
    const endDateTravel = getValues(lastSegmentFielsdNames.endDate);
    const endTimeTravel = getValues(lastSegmentFielsdNames.endTime);

    return { startDateTravel, startTimeTravel, endDateTravel, endTimeTravel };
  };

  return getPerDiemSegmentValues;
};

import { Button } from '@candisio/design-system';
import { Icon, TruncatedText } from '@candisio/design-system/next';
import { DocumentType, FileUploadAttempt } from 'generated-types/graphql.types';
import { useAppLayout } from 'providers/AppLayoutProvider';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom-v5-compat';
import { DOCUMENT_TYPE_TRANSLATION_MAP } from 'views/Settings/DocumentTypes/toolkit/consts';
import DocumentIcon from '../../assets/document.svg?react';
import styles from './UploadHistoryItem.module.css';
import { useUploadItemPath } from '../../hooks/useUploadItemPath';

type Props = {
  item: FileUploadAttempt;
  onRemove: () => void;
};

export const UploadHistoryItemSuccess = ({ item, onRemove }: Props) => {
  const [t] = useTranslation();
  const path = useUploadItemPath(item);
  const { setLayoutValue } = useAppLayout();

  return (
    <div className={styles.wrapper}>
      <NavLink
        to={path}
        className={styles.item}
        onClick={() => setLayoutValue('docUploadPopover', { open: false })}
      >
        <div className="relative">
          <DocumentIcon
            className={styles.documentIcon}
            data-variant="success"
          />
          <Icon
            className="absolute bottom-1 left-2.5 text-green-600 size-4"
            icon="checkCircle"
          />
        </div>
        <div className="flex flex-col grow gap-0.5">
          <span className={styles.title}>
            <TruncatedText>{item.file.name}</TruncatedText>
          </span>
          <span className={styles.progressText}>
            {t(
              DOCUMENT_TYPE_TRANSLATION_MAP[item.documentType as DocumentType]
            )}
          </span>
        </div>
      </NavLink>
      <Button
        className={styles.removeButton}
        icon="close"
        onClick={onRemove}
        variant="tertiary"
      />
    </div>
  );
};

import { SlotProps } from '@candis/utils';
import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { ReactNode } from 'react';
import styles from './ReimbursementSummaryTile.module.css';

type Props = SlotProps & {
  children: ReactNode;
};

export const ReimbursementSummaryTile = (props: Props) => {
  return <SwimlaneTile className={styles.tile} variant="white" {...props} />;
};

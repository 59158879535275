import {
  DocumentClassifiedEventDataFragment,
  DocumentType,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { useEcmDocumentTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmDocumentTypeItems';

export const DocumentClassifiedComponent = ({
  newDocumentType,
}: DocumentClassifiedEventDataFragment) => {
  const [t] = useTranslation();
  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();
  if (!newDocumentType) return null;

  const documentTypeTranslated =
    ecmDocumentTypeTranslationMap[newDocumentType as DocumentType];

  const i18nKey = 'document.compact.history.documentClassified';

  return t(i18nKey, {
    documentType: documentTypeTranslated,
  });
};

import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  CoreDataTypes,
  useGenerateCoreDataImportErrorFileByIdMutation,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils/downloadFile';

interface CandisApiCoreDataImportHistory {
  coreDataType: CoreDataTypes;
  processId: string;
}

export const useCoreDataImportErrorFileGeneration = ({
  coreDataType,
  processId,
}: CandisApiCoreDataImportHistory) => {
  const [
    generateCodeDataImportErrorCSVFile,
    { loading: isCsvErrorFileGenerationLoading },
  ] = useGenerateCoreDataImportErrorFileByIdMutation();

  const [t] = useTranslation();

  const { success, error, info } = useToastMessage();

  const handleCSVDownload = useCallback(async (): Promise<
    { status: 'error' } | undefined
  > => {
    if (isCsvErrorFileGenerationLoading) {
      info(t('insights:download.waitForLoading'));
    }

    const results = await generateCodeDataImportErrorCSVFile({
      variables: {
        type: coreDataType,
        processId,
      },
    });

    if (!results.data?.generateCoreDataImportErrorFileById?.url) {
      error(t('insights:download.errorWhenDownloading'));

      return { status: 'error' };
    } else {
      try {
        const fileUrl = results.data?.generateCoreDataImportErrorFileById?.url;

        const fileName =
          results.data?.generateCoreDataImportErrorFileById?.name;

        downloadFile(fileUrl, fileName);
        success(t('insights:download.successfullyDownloaded'));
      } catch (_err) {
        error(t('insights:download.errorWhenDownloading'));

        return { status: 'error' };
      }
    }
  }, [
    isCsvErrorFileGenerationLoading,
    generateCodeDataImportErrorCSVFile,
    coreDataType,
    processId,
    info,
    t,
    error,
    success,
  ]);

  return {
    handleCSVDownload,
    isCsvErrorFileGenerationLoading,
  };
};

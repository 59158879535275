import { getContractStatus } from 'components/EcmDocumentsTable/hooks/useEcmContractsData';

import { useSearchInput } from 'components/AdvancedSearchFieldFilterOverlay/useSearchInput';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import {
  DocumentType,
  EcmDocumentStatus,
  EcmHighlightableFields,
  GetEcmContractsQuery,
  GetEcmContractsQueryVariables,
} from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce, isEmpty as isValueEmpty, merge } from 'lodash';
import {
  EcmDocumentsPaginationParams,
  useEcmPagination,
} from 'providers/GraphQLProvider/Pagination/useEcmPagination';
import { useMemo, useState } from 'react';
import { getEcmContractsQuery } from 'views/Archive/EcmContracts/queries';
import { parseHighlight } from 'components/WithHighlightsCell/parseHighlight';

export interface UseEcmContractsDataReturn {
  data: EcmDocumentsTableData[];
  handleDebounceSearch: (searchQuery: string) => void;
  isEmpty: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
  documentsCount: number;
}

export const useInboxContractsData = ({
  params,
}: {
  params: EcmDocumentsPaginationParams;
}): UseEcmContractsDataReturn => {
  const [debounceSearchValue, setDebounceSearchValue] = useState(''); // TODO default from query params
  const { searchInputVariable } = useSearchInput();

  const contractsQueryParams: GetEcmContractsQueryVariables = {
    search: debounceSearchValue,
    filter: {
      documentStatus: [EcmDocumentStatus.New],
      documentType: [DocumentType.Contract],
    },
    searchInput: searchInputVariable,
  };

  const mergedParams = merge(contractsQueryParams, params);

  const {
    data,
    loading: isLoading,
    onLoadMore,
  } = useEcmPagination<GetEcmContractsQuery, any>(
    getEcmContractsQuery,
    'getEcmDocuments',
    {
      variables: mergedParams,
    }
  );

  const handleDebounceSearch = useMemo(() => {
    return debounce(setDebounceSearchValue, DEFAULT_DEBOUNCE_TIME);
  }, []);

  const ecmDocumentsList = data?.getEcmDocuments;

  const isEmpty = !isLoading && data?.getEcmDocuments?.edges?.length === 0;

  const contractsList = useMemo(
    () =>
      ecmDocumentsList?.edges
        ? ecmDocumentsList.edges.map(
            ({
              record,
              cursor,
              highlights: highlightsResponse,
            }): EcmDocumentsTableData => {
              const responsiblePerson = record?.responsiblePerson?.primary;
              const notifyPerson = record?.notifyPerson?.primary;

              const startDate = record?.startDate
                ? new Date(record?.startDate)
                : undefined;

              const endDate = record?.endDate
                ? new Date(record?.endDate)
                : undefined;

              const terminationReminderDate = record?.terminationReminderDate
                ? new Date(record?.terminationReminderDate)
                : undefined;

              const value = record.amount?.amount;
              const currency = record.amount?.currency;
              const amount =
                value && currency
                  ? { amount: value, currency, isBasicMonetaryUnit: true }
                  : undefined;

              const highlights = parseHighlight<keyof EcmDocumentsTableData>(
                highlightsResponse ?? [],
                highlightFieldToColumIdMapper
              );

              return {
                cursor,
                id: record?.id ?? '',
                globalDocumentId: record?.globalDocumentId ?? '',
                contact: record?.contactName ?? '',
                contractStatus: getContractStatus({
                  startDate,
                  endDate,
                  terminationReminderDate,
                  isSensitive: record?.isSensitive ?? false,
                }),
                invoiceId: record?.invoiceId ?? '',
                amount,
                notes: record?.notes ?? '',
                documentName: record?.documentName ?? '',
                documentNumber: record?.documentNumber ?? '',
                costCenter: record?.costCenterName ?? '',
                contractType: record?.documentSubCategory ?? undefined,
                startDate,
                endDate,
                terminationDate: record?.terminationDate
                  ? new Date(record?.terminationDate)
                  : undefined,
                terminationReminderDate,
                responsiblePerson: !isValueEmpty(responsiblePerson)
                  ? responsiblePerson
                  : undefined,
                notifyPerson: !isValueEmpty(notifyPerson)
                  ? notifyPerson
                  : undefined,
                tags: record?.tags,
                highlights,
              };
            }
          )
        : [],
    [ecmDocumentsList?.edges]
  );

  const documentsCount = ecmDocumentsList?.pageInfo?.totalCount ?? 0;

  return {
    data: contractsList,
    handleDebounceSearch,
    isEmpty,
    isLoading,
    onLoadMore,
    documentsCount,
  };
};

const highlightFieldToColumIdMapper: Partial<
  Record<EcmHighlightableFields, keyof EcmDocumentsTableData>
> = {
  [EcmHighlightableFields.Amount]: 'amount',
  [EcmHighlightableFields.ContactName]: 'contact',
  [EcmHighlightableFields.CostCenter]: 'costCenter',
  [EcmHighlightableFields.DocumentName]: 'documentName',
  [EcmHighlightableFields.DocumentNumber]: 'documentNumber',
  [EcmHighlightableFields.Notes]: 'notes',
  [EcmHighlightableFields.RawContent]: 'rawContentHighlight',
};

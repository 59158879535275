import { useState } from 'react';
import { prefix } from 'utils/localStorage';

export const getLocalStorageKey = (key: string) => `${prefix}-${key}`;

export const parseValueFromLocalStorage = <T>(key: string): T | undefined => {
  const item = window.localStorage.getItem(key);

  return item ? JSON.parse(item) : undefined;
};

/**
 * Hook to read/write local storage.
 *
 * Based on: https://usehooks.com/useLocalStorage/
 */
export const useLocalStorage = <TValue>(key: string, initialValue: TValue) => {
  const keyWithPrefix = getLocalStorageKey(key);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<TValue>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(keyWithPrefix);

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);

      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: TValue | ((val: TValue) => TValue)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(
          keyWithPrefix,
          JSON.stringify(valueToStore)
        );
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  const removeKey = () => {
    window.localStorage.removeItem(keyWithPrefix);
  };

  return [storedValue, setValue, removeKey] as const;
};

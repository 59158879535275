import { Flex } from '@candisio/design-system';
import { useCreditCardsLedgerData } from 'views/Settings/useCreditCardsLedger';
import { useDatevBookingAccountValidation } from 'views/Settings/Integrations/DATEV/useDatevBookingAccountValidation';
import { BookingAccountForm } from './BookingAccountForm/BookingAccountForm';
import { TransitAccountForm } from './TransitAccountForm';

export const CreditCardExportingForm = () => {
  const {
    creditCardsLedger: { bookingAccount, transitAccount },
  } = useCreditCardsLedgerData();

  const { maxLength: accountLength } =
    useDatevBookingAccountValidation('bookingAccount');

  return (
    <Flex gap="space16" direction="row">
      <BookingAccountForm
        accountLength={accountLength}
        bookingAccount={bookingAccount ?? ''}
      />
      <TransitAccountForm
        accountLength={accountLength}
        transitAccount={transitAccount ?? ''}
      />
    </Flex>
  );
};

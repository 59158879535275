import {
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Tag,
  Text,
} from '@candisio/design-system';
import { friendlyFormatIBAN } from 'ibantools';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface IbanListViewItemProps {
  /** IBAN in electronic format (no spaces) */
  iban: string;
  /** Bank BIC/SWIFT code */
  swiftCode?: string | null;
  /** Bank logo image URL */
  logoUrl?: string;
  /** Is this the default IBAN? */
  isDefault?: boolean;
  /** Friendly name for IBAN */
  name: string;
  /** Called when IBAN should be set as default */
  onSetAsDefault?: () => Promise<void>;
}

/** Displays individual IBAN details in BankAccountListView */
export const BankAccountListViewItem = ({
  iban,
  logoUrl,
  isDefault = false,
  name,
  onSetAsDefault,
}: IbanListViewItemProps) => {
  const [t] = useTranslation('payments');
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);

  /*TODO show swiftCode in the list ?*/

  return (
    <Grid
      gap="space16"
      templateColumns="auto 1fr"
      alignItems="center"
      height="space64"
      padding="space8 space20"
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      borderBottom="1px solid gray200"
      style={{ cursor: 'pointer' }}
    >
      {logoUrl ? (
        <Image src={logoUrl} width="space24" height="space24" alt="" />
      ) : (
        <Icon icon="bank" size="space24" />
      )}
      <Grid autoFlow="column" justifyContent="space-between">
        <Grid>
          <Flex gap="space8" alignItems="center">
            <Text fontSize="basic" fontWeight="semibold">
              {name}
            </Text>
            {isDefault && (
              <Tag color="blue" size="small" variant="secondary">
                {t('bankAccounts.default')}
              </Tag>
            )}
          </Flex>
          <Text color="gray500">{friendlyFormatIBAN(iban)}</Text>
        </Grid>
        {!isDefault && isHovered && (
          <Button
            loading={loading}
            onClick={async () => {
              setLoading(true);
              await onSetAsDefault?.();
              setLoading(false);
            }}
            variant="secondary"
            size="small"
          >
            {t('bankAccounts.setAsDefault')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

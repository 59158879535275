import { Icon, IconKey } from '@candisio/design-system';
import styles from './IconBadge.module.css';
import clsx from 'clsx';
import { HTMLProps } from 'react';

type CardIconType = HTMLProps<HTMLDivElement> & {
  icon: IconKey;
  variant?: 'gray' | 'red' | 'green' | 'pink';
};

export const IconBadge = ({
  variant = 'gray',
  icon,
  className,
  ...props
}: CardIconType) => {
  const classes = clsx(styles.icon, className);

  return (
    <div className={classes} data-variant={variant} {...props}>
      <Icon icon={icon} size={16} />
    </div>
  );
};

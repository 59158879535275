import { AvatarStack, Grid, Text } from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { motion } from 'motion/react';
import { renderQueryHighlight } from 'views/utils/renderQueryHighlight';
import { Workflow } from '../hooks/useWorkflowData';

interface WorkflowTemplateItemProps {
  onSelect: (id: string) => void;
  workflow: Workflow;
  queryString: string;
}

const MotionGrid = motion.create(Grid);

export const WorkflowTemplateItem = ({
  onSelect,
  workflow,
  queryString,
}: WorkflowTemplateItemProps) => {
  return (
    <MotionGrid
      autoFlow="column"
      borderBottom="1px solid gray200"
      padding="space12 space20"
      templateColumns="1fr 90px"
      gap="space16"
      justifyContent="start"
      alignItems="center"
      style={{ cursor: 'pointer' }}
      onClick={() => onSelect(workflow.id)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeOut', duration: 0.25 }}
    >
      <Grid>
        <Text
          as="h3"
          fontSize="basic"
          fontWeight="semibold"
          lineHeight="heading"
        >
          {renderQueryHighlight({
            value: workflow.name,
            queryString,
          })}
        </Text>
        <Text color="gray500" fontSize="small" lineHeight="paragraph">
          {renderQueryHighlight({
            value: workflow.description ?? '',
            queryString,
          })}
        </Text>
      </Grid>
      <Grid justifyContent="start">
        <AvatarStack limit={4} size="small">
          {(workflow.users ?? []).map(user => (
            <AvatarWithStatusContainer
              key={user.id}
              name={user.name}
              userId={user.id}
              img={user.avatarUrl}
            />
          ))}
        </AvatarStack>
      </Grid>
    </MotionGrid>
  );
};

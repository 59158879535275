import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import { grossToNet, netToGross } from 'containers/SplitBookings/toolkit/utils';
import { useCallback } from 'react';
import { TableFormRow } from '../schema/bookingsFormSchema';
import { ProcessingFormBookingItem } from '../types';

/**
 *
 * @returns
 * converters to transform booking items between processing form schema and bookings form schema/shape
 * TODO both schemas should be same, refactor processingFormSchmea to use bookingSchema for bookings array or vice versa
 */

export const useGetBookingTransformers = () => {
  const toProcessingFormBooking = useCallback(
    (b: TableFormRow): ProcessingFormBookingItem => {
      const { invoiceCorrection, ...booking } = b;
      return {
        ...booking,
        generalLedgerAccount: {
          value: booking.generalLedgerAccount?.value,
          inputValue: booking.generalLedgerAccount?.inputValue ?? undefined,
        },
        projectCode: {
          value: booking.projectCode?.value,
          inputValue: booking.projectCode?.inputValue ?? undefined,
        },
        costCenter: {
          value: booking.costCenter?.value,
          inputValue: booking.costCenter?.inputValue ?? undefined,
        },
        costObject: {
          value: booking.costObject?.value,
          inputValue: booking.costObject?.inputValue ?? undefined,
        },
        extraCostInfo: {
          value: booking.extraCostInfo?.value,
          inputValue: booking.extraCostInfo?.inputValue ?? undefined,
        },
        quantity: booking.quantity ?? undefined,
        amount:
          b.taxPresentation === TaxPresentation.Gross
            ? b.amount
            : netToGross(b.amount ?? 0, b.vatRate ?? 0),
      };
    },
    []
  );

  const toBooking = useCallback(
    (b: ProcessingFormBookingItem): TableFormRow => {
      return {
        ...b,
        taxPresentation: b.taxPresentation ?? TaxPresentation.Gross,
        amount: ((b.taxPresentation || TaxPresentation.Gross) ===
        TaxPresentation.Gross
          ? b.amount
          : grossToNet(b.amount ?? 0, b.vatRate ?? 0)) as number,
        invoiceCorrection: (b.amount || 0) < 0,
      };
    },
    []
  );
  return { toProcessingFormBooking, toBooking };
};

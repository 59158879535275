import debounce from 'debounce-promise';
import {
  CostCenterTypes,
  useProcessingFormCostCenterFieldItemsByIdsQuery,
  useProcessingFormCostCenterFieldItemsQuery,
} from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { toAccountingDataFieldItem } from 'views/Inbox/DocumentProcessing/toAccountingDataFieldItem';

export interface UseCostCenterFieldItemsOptions {
  skip?: boolean;
  type: CostCenterTypes;
  ids?: string[];
}

/** Fetches items for general ledger account field */
export const useCostCenterFieldItems = ({
  skip,
  type,
  ids,
}: UseCostCenterFieldItemsOptions) => {
  const byType = useProcessingFormCostCenterFieldItemsQuery({
    variables: { type },
    skip,
  });

  const byIds = useProcessingFormCostCenterFieldItemsByIdsQuery({
    variables: { ids },
    skip: !ids,
  });

  const { data, refetch, fetchMore } = ids ? byIds : byType;

  const items = (data?.costCentersPagination?.records ?? []).map(
    toAccountingDataFieldItem
  );

  const refetchItems = useMemo(() => debounce(refetch, 500), [refetch]);

  const pageCount = data?.costCentersPagination.pageInfo.pageCount ?? 1;
  const currentPage = data?.costCentersPagination.pageInfo.currentPage ?? 1;
  const nextPage = currentPage + 1;

  const fetchMoreItems = async () => {
    if (nextPage <= pageCount) {
      await fetchMore({ variables: { page: nextPage } });
    }
  };

  return { items, refetchItems, fetchMoreItems };
};

import {
  AriaTabListProps,
  Grid,
  Item,
  TabItem,
  TabPanel,
  Tabs,
  useTabListState,
} from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { Key, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCreditCardsOverviewCount } from './useCreditCardsOverviewCount';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { Routes } from 'models';

export enum CreditCardsTab {
  Dashboard = 'Dashboard',
  Table = 'Table',
  Statements = 'Statements',
  RecurringPayments = 'RecurringPayments',
}

interface CreditCardsTabsPanelProps extends AriaTabListProps<TabItem> {
  'aria-label': string;
}

const CreditCardsTabsPanel = (props: CreditCardsTabsPanelProps) => {
  const state = useTabListState(props);

  return (
    <>
      <Grid templateRows="auto 1fr" flexGrow={1}>
        <Tabs {...props} state={state} />

        <TabPanel
          key={state.selectedItem?.key}
          state={state}
          height="100%"
          overflow="hidden"
        />
      </Grid>
    </>
  );
};

export const CreditCardsTabs = ({
  defaultSelectedKey,
}: {
  defaultSelectedKey: CreditCardsTab;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const navigate = useNavigate();
  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const [creditCardsStatementsFF, dashboardRedesignCreditCardsFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.creditCardsStatements,
      FEATURE_FLAGS.dashboardRedesignCreditCards,
    ]);

  const { isCountVisible, totalRequests } = useCreditCardsOverviewCount();

  const tabItems: TabItem[] = useMemo(() => {
    const tabs: TabItem[] = [
      {
        key: CreditCardsTab.Dashboard,
        children: null,
        title: t('tabs.dashboard'),
      },
      {
        key: CreditCardsTab.Table,
        children: null,
        title: t('tabs.totalOverview'),
        badge: isCountVisible ? String(totalRequests) : undefined,
      },
    ];

    if (dashboardRedesignCreditCardsFF) {
      tabs.push({
        key: CreditCardsTab.RecurringPayments,
        children: null,
        title: t('insights.recurringPaymentsWidget.title'),
      });
    }

    if (creditCardsStatementsFF) {
      tabs.push({
        key: CreditCardsTab.Statements,
        children: null,
        title: t('statements.heading'),
      });
    }

    return tabs;
  }, [
    t,
    isCountVisible,
    totalRequests,
    dashboardRedesignCreditCardsFF,
    creditCardsStatementsFF,
  ]);

  const navigateToUrl = (item: Key) => {
    if (item === CreditCardsTab.Table) {
      navigate(
        `/${organizationSlug}${Routes.CREDIT_CARDS_CARD_MANAGER_OVERVIEW}`
      );
      return;
    }

    if (item === CreditCardsTab.Dashboard) {
      navigate(`/${organizationSlug}${Routes.CREDIT_CARDS_DASHBOARD}`);
      return;
    }

    if (item === CreditCardsTab.Statements) {
      navigate(
        `/${organizationSlug}${Routes.CREDIT_CARDS_DASHBOARD}${Routes.STATEMENTS}`
      );
      return;
    }

    if (item === CreditCardsTab.RecurringPayments) {
      navigate(`/${organizationSlug}${Routes.CREDIT_CARDS_RECURRING_PAYMENTS}`);
      return;
    }
  };

  return (
    <CreditCardsTabsPanel
      aria-label="creditCardsTabs"
      defaultSelectedKey={defaultSelectedKey}
      selectedKey={defaultSelectedKey}
      items={tabItems}
      onSelectionChange={navigateToUrl}
      children={item => (
        <Item key={item.key} title={item.title} textValue={item.title}>
          {item.children}
        </Item>
      )}
    />
  );
};

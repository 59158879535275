import { createTeleporter } from 'react-teleporter';

/**
 * Enables to teleport different footer components to the same target
 * it is single source so only one footer at a time is displayed
 */
const LinkingFooter = createTeleporter();

export const LinkingFooterSource = LinkingFooter.Source;

export const LinkingFooterTarget = LinkingFooter.Target;

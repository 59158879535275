import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { isPaidDocumentState } from 'utils/document_payment_data';
import { amountFormat } from 'utils/format';
import { getRemainingDays } from 'utils/getRemainingDays';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';
import { DocumentsTableData } from '../../types';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { CellContext } from '@tanstack/react-table';
import { remainingDaysToClassName } from 'components/EcmDocumentsTable/EcmDocumentsTableV2/Cells/RelativeDateCell';
import clsx from 'clsx';

export const DiscountDateWPercentageCell = ({
  getValue,
  row,
}: CellContext<
  DocumentsTableData,
  DocumentsTableData['discountDateWPercentage']
>) => {
  const { showEcm, showConsistentSortAndFiltering } = useEcm();
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const value = getValue();
  if (isNil(value)) {
    return null;
  }

  const discountPercentage = value.discountPercentage;
  let discountAmount = undefined;
  if (
    showEcm &&
    showConsistentSortAndFiltering &&
    Number.isFinite(value.discountAmount)
  ) {
    discountAmount = value.discountAmount;
  } else {
    // TODO: When the FF is fully rolled out, we can remove the code below.
    const documentAmount = row.original.grossAmount?.amount;
    discountAmount =
      discountPercentage !== undefined && documentAmount !== undefined
        ? roundToCurrencyPrecision((documentAmount / 100) * discountPercentage)
        : undefined;
  }

  const documentCurrency = row.original.grossAmount?.currency;
  const isPaid = isPaidDocumentState(row.original.paymentStatus);
  const discountPaymentDate = value.discountPaymentDate;

  if (
    documentCurrency === undefined ||
    discountAmount === undefined ||
    discountPercentage === undefined ||
    discountPaymentDate === undefined
  ) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  const remainingDays = getRemainingDays(discountPaymentDate);
  const remainingDaysColor = !isPaid
    ? remainingDaysToClassName(remainingDays)
    : undefined;

  return (
    <span
      className={clsx({
        'text-red-700': remainingDaysColor === 'red',
        'text-yellow-700': remainingDaysColor === 'yellow',
        'text-green-700': remainingDaysColor === 'green',
      })}
    >
      {t('dueDateWithCashDiscount', {
        discountAmount: amountFormat(discountAmount, documentCurrency),
        discountPaymentDate: DateFormatters.compact(
          new Date(discountPaymentDate)
        ),
      })}
    </span>
  );
};

import { Tooltip, useTooltip } from '@candisio/design-system';
import { TruncatedText } from '@candisio/design-system/next';
import moment from 'moment';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { getRemainingDays } from 'utils/getRemainingDays';
import { EcmDocumentsTableData } from '../../types';
import clsx from 'clsx';
import { CellContext } from '@tanstack/react-table';

type Value = Date | undefined;

/**
 * Moment takes care of calculating time relative to today and
 * uses i18n to show correct translation when user switches languages.
 *
 * https://momentjs.com/docs/#/displaying/from/
 */
const getRemainingTime = (dueDate: Date): string => {
  const today = moment().startOf('day');

  return moment(dueDate).from(today);
};

export const RelativeDateCell = ({
  getValue,
}: CellContext<EcmDocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { tooltipProps, tooltipRef, triggerProps, triggerRef, isOpen } =
    useTooltip({ placement: 'top' });
  const value = getValue();

  if (!value) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  const remainingDays = getRemainingDays(value);
  // if remaining time is less than a day, show "Today"
  const remainingTime =
    remainingDays === 0 ? t('relativeDateCell.today') : getRemainingTime(value);

  const remainingDaysColor = remainingDaysToClassName(remainingDays);

  return (
    <>
      <TruncatedText
        ref={triggerRef}
        {...triggerProps}
        className={clsx({
          'text-red-700': remainingDaysColor === 'red',
          'text-yellow-700': remainingDaysColor === 'yellow',
          'text-green-700': remainingDaysColor === 'green',
        })}
      >
        {remainingTime}
      </TruncatedText>

      {isOpen && (
        <Tooltip ref={tooltipRef} {...tooltipProps}>
          {DateFormatters.compact(value)}
        </Tooltip>
      )}
    </>
  );
};

export const remainingDaysToClassName = (
  days: number
): 'red' | 'yellow' | 'green' => {
  return days <= 3 ? 'red' : days <= 14 ? 'yellow' : 'green';
};

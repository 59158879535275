import { Flex, MessageBox, Text } from '@candisio/design-system';
import { EInvoiceErrorDetailsButton } from 'components/EInvoiceErrorDetails/EInvoiceErrorDetailsButton';
import { useEInvoiceValidationDetails } from 'components/EInvoiceErrorDetails/useEInvoiceValidationDetails';
import { useTranslation } from 'react-i18next';

type InvalidEInvoiceDetectedComponentProps = {
  documentId: string;
};

export const InvalidEInvoiceWarning = ({
  documentId,
}: InvalidEInvoiceDetectedComponentProps) => {
  const [t] = useTranslation();

  const { isDataLoading, eInvoice } = useEInvoiceValidationDetails({
    documentId,
  });

  if (!eInvoice?.error || isDataLoading) {
    return null;
  }

  return (
    <MessageBox
      message=""
      variant="warning"
      isLoading={isDataLoading}
      additionalContent={
        <Flex
          direction="column"
          width="100%"
          gap="space16"
          paddingBottom="space4"
        >
          <Flex direction="column" gap="space8">
            <Text fontSize="basic" fontWeight="semibold">
              {t(
                'common:document.compact.history.invalidEInvoice.warning.headline'
              )}
            </Text>
            <Text>
              {t(
                'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.requestNewInvoice'
              )}
            </Text>
          </Flex>
          <EInvoiceErrorDetailsButton
            buttonType="button"
            documentId={documentId}
          />
        </Flex>
      }
    />
  );
};

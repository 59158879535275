import { Button, ButtonProps } from '@candisio/design-system';
import { HTMLProps } from 'react';
import styles from './SwimlaneTilePromoCTAButton.module.css';

type SwimlaneTilePromoButtonProps = HTMLProps<
  HTMLButtonElement | HTMLAnchorElement
> &
  Omit<ButtonProps, 'as'> & {
    as?: 'a' | 'button';
  };

export const SwimlaneTilePromoCTAButton = ({
  children,
  color = 'blue',
  as = 'button',
  ...restProps
}: SwimlaneTilePromoButtonProps) => {
  return (
    <Button
      variant="secondary"
      size="small"
      width="max-content"
      className={styles.button}
      color={color}
      as={as}
      {...restProps}
    >
      {children}
    </Button>
  );
};

import { Text } from '@candisio/design-system';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CardItem } from './Item';

export const CardLimitsItem = ({
  cardType,
  onSetMode,
}: {
  cardType?: CardType;
  onSetMode: (mode: DrawerMode) => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const handleClick = () => {
    onSetMode('cardLimits');
  };

  return (
    <CardItem icon="creditCardOutline" onClick={handleClick}>
      <Text fontSize="large">
        {t('creditCardFormDataPreview.cardLimits.title')}
      </Text>
      <Text fontSize="small">
        {t(
          cardType === CardType.SingleUse
            ? 'creditCardFormDataPreview.cardLimits.singleUseSubtitle'
            : 'creditCardFormDataPreview.cardLimits.subtitle'
        )}
      </Text>
    </CardItem>
  );
};

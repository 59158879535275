import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  GridProps,
  Image,
  Tag,
  Text,
  useTheme,
} from '@candisio/design-system';
import creditCards from 'containers/credit-cards/components/CreditCardsSection/media/mini card.png';
import {
  RouteTypeProps,
  creditCardInfoHash,
} from 'containers/credit-cards/components/CreditCardsSection/utils';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { CreditCardInfoModal, demoLinks } from './CreditCardInfoModal';

interface CreditCardNotAdoptedInfoBannerProps {
  showTitle?: boolean;
  routeType: RouteTypeProps;
  leftAlign?: GridProps['left'];
}

export const CreditCardNotAdoptedInfoBanner = ({
  showTitle,
  routeType,
  leftAlign,
}: CreditCardNotAdoptedInfoBannerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const navigate = useNavigate();
  const location = useLocation();
  const { colors } = useTheme();

  const openModal = location.hash === creditCardInfoHash;

  const handleClick = () => {
    navigate({
      pathname: location.pathname,
      hash: `${creditCardInfoHash}`,
    });
  };

  return (
    <Grid gap="space16" alignSelf="start">
      {showTitle && (
        <Text fontSize="xlarge">{t('ccInformationalCard.headerTitle')}</Text>
      )}
      <Card background="gray50">
        <Grid
          left={leftAlign ?? '-36px'}
          autoFlow="column"
          alignItems="center"
          gap="space64"
        >
          <Image src={creditCards} alt="cards" height="300px" />
          <Grid gap="space24" alignItems="start">
            <Flex direction="column" gap="space4">
              <Box alignSelf="start">
                <Tag variant="secondary" color="purple">
                  {t('common:tag.addOn')}
                </Tag>
              </Box>
              <Text fontSize="xxlarge" lineHeight="paragraph">
                {t('ccInformationalCard.newTitle')}
              </Text>
            </Flex>
            <Grid
              autoFlow="column"
              gap="space16"
              width="max-content"
              justifyContent="start"
              alignItems="center"
            >
              <Button color="purple" onClick={handleClick}>
                {t('ccInformationalCard.pryCtaBtn')}
              </Button>
              <Button
                as="a"
                variant="secondary"
                target="_blank"
                style={{ color: colors.gray800 }}
                href={t(demoLinks[routeType])}
              >
                {t('ccInformationalCard.secCtaBtn')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <CreditCardInfoModal
        isOpen={openModal}
        onClose={() => {
          navigate(
            {
              pathname: location.pathname,
            },
            { replace: true }
          );
        }}
        routeType={routeType}
      />
    </Grid>
  );
};

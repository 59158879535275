import { Button, Flex, Grid, useTheme } from '@candisio/design-system';
import {
  CandisApiCoreDataImportHistoryEntry,
  CoreDataTypes,
} from 'generated-types/resolvers-types';
import { motion } from 'motion/react';
import { useHover } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { ImportData } from 'views/CoreDataImportHistory/elements/CoreDataImportHistoryListItem/components/ImportData';
import { ImportStatus } from 'views/CoreDataImportHistory/elements/CoreDataImportHistoryListItem/components/ImportStatus';
import { ImportStatusIcon } from 'views/CoreDataImportHistory/elements/CoreDataImportHistoryListItem/components/ImportStatusIcon';
import { useCoreDataImportErrorFileGeneration } from 'views/CoreDataImportHistory/toolkit/hooks/useCoreDataImportErrorFileGeneration';

export type CoreDataImportHistoryEntry = Pick<
  CandisApiCoreDataImportHistoryEntry,
  | 'failedCandisAPICoreDataImportCount'
  | 'type'
  | 'processId'
  | 'status'
  | 'successfulCandisAPICoreDataImportCount'
  | 'createdAt'
  | 'updatedAt'
  | 'operation'
>;

interface ImportHistoryProps {
  item: CoreDataImportHistoryEntry;
  coreDataType: CoreDataTypes;
}

const MotionButton = motion.create(Button);
const MotionGrid = motion.create(Grid);

export const CoreDataImportHistoryListItem = ({
  item,
  coreDataType,
}: ImportHistoryProps) => {
  const [t] = useTranslation();
  const { space } = useTheme();
  const { hoverProps, isHovered } = useHover({});

  const hasErrors = Boolean((item.failedCandisAPICoreDataImportCount ?? 0) > 0);

  const { isCsvErrorFileGenerationLoading, handleCSVDownload } =
    useCoreDataImportErrorFileGeneration({
      coreDataType,
      processId: item.processId ?? '',
    });

  return (
    /** @ts-expect-error framer-motion upgrade type mismatch */
    <MotionGrid
      gap="space16"
      height="57px"
      padding="space8 space20"
      templateColumns={`${space.space18} auto auto`}
      {...hoverProps}
    >
      <ImportStatusIcon hasErrors={hasErrors} />

      <Grid
        color="gray800"
        fontSize="basic"
        fontWeight="semibold"
        justifySelf="start"
        lineHeight="paragraph"
        gap="space4"
      >
        <ImportStatus item={item} />
        <ImportData item={item} />
      </Grid>

      {isHovered && (
        <Flex gap="space16" justifyContent="end">
          {hasErrors && (
            <MotionButton
              icon="arrowDown"
              iconPosition="left"
              variant="secondary"
              color="gray"
              disabled={isCsvErrorFileGenerationLoading}
              onClick={handleCSVDownload}
            >
              {t('importHistoryList.downloadErrorCta.error')}
            </MotionButton>
          )}
        </Flex>
      )}
    </MotionGrid>
  );
};

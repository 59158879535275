import { useGetFileUploadAttemptsQuery } from 'generated-types/graphql.types';
import { useEffect } from 'react';
import { getHasProcessingState } from './useHasProcessingState';

export const useFileUploadPolling = () => {
  const { data, startPolling, stopPolling } = useGetFileUploadAttemptsQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const hasProcessingState = getHasProcessingState(
      data?.getFileUploadAttempts
    );
    if (hasProcessingState) {
      startPolling(5000);
    } else {
      stopPolling();
    }
  }, [data?.getFileUploadAttempts, startPolling, stopPolling]);
};

import { useDocumentRelationshipsActions } from 'containers/document-relationships/useDocumentRelationshipsActions';
import {
  AggregatedEcmDocumentFragment,
  DocumentCurrency,
  DocumentFragment,
  type DocumentRelationshipsQuery,
  DocumentStatus,
  DocumentType,
  EcmDocumentContractStatus,
  EcmDocumentStatus,
  GetDocumentForDraftQuery,
  Money,
  User,
  useDocumentRelationshipsQuery,
} from 'generated-types/graphql.types';
import { omit } from 'lodash';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useMemo } from 'react';
import { removePdfFileExtension } from '../Ecm/removePdfFileExtension';

export type Person = Pick<User, 'name' | 'avatarUrl'>;

type MoneyAmount = Pick<Money, 'amount' | 'currency' | 'precision'>;

export interface RelatedDocument {
  relationId?: string;
  id: string;
  globalDocumentId?: string;
  amount: MoneyAmount | null;
  name: string | null;
  number: string | null;
  status: DocumentStatus | EcmDocumentStatus | EcmDocumentContractStatus | null;
  date: string | null;
  type: DocumentType;
  isOverdue?: boolean;
  isSensitive?: boolean;
  isEInvoice?: NonNullable<
    GetDocumentForDraftQuery['getDocument']
  >['isEInvoice'];
  eInvoice?: NonNullable<GetDocumentForDraftQuery['getDocument']>['eInvoice'];
  eInvoiceValidationErrors?: NonNullable<
    GetDocumentForDraftQuery['getDocument']
  >['eInvoiceValidationErrors'];
  createdBy?: Person;
  notifyPerson?: Person[];
  responsiblePerson?: Person[];
  contractTypeId?: string | null;
  notes?: string | null;
  documentFileId?: string | null;
  terminationReminderDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  attachments?: string[];
  isPaid?: boolean;
}

const mapAggregatedDocumentToRelatedDocument = (
  {
    amount,
    contactName,
    createdBy,
    fileSource,
    documentNumber,
    documentStatus,
    documentDate,
    isSensitive,
    notifyPerson,
    responsiblePerson,
    type,
    documentName,
    notes,
    globalDocumentId,
    terminationReminderDate,
    startDate,
    endDate,
  }: AggregatedEcmDocumentFragment,
  relationId: string,
  id: string
): RelatedDocument => {
  const numberField =
    type === DocumentType.Contract
      ? (documentName ?? removePdfFileExtension(fileSource?.name ?? ''))
      : documentNumber;

  return {
    relationId,
    id,
    globalDocumentId,
    amount: omit(amount, '__typename') ?? null,
    name: contactName ?? null,
    number: numberField ?? null,
    status: documentStatus ?? null,
    date: documentDate ?? null,
    isSensitive: isSensitive ?? false,
    createdBy: createdBy ?? undefined,
    isOverdue: false,
    isEInvoice: false,
    eInvoice: null,
    eInvoiceValidationErrors: null,
    notifyPerson: notifyPerson?.primary ?? undefined,
    responsiblePerson: responsiblePerson?.primary ?? undefined,
    type,
    notes: notes ?? null,
    documentFileId: fileSource?.id ?? null,
    terminationReminderDate,
    startDate,
    endDate,
  };
};

const mapDocumentToRelatedDocument = (
  {
    status,
    amount,
    currency,
    contact,
    invoiceDate,
    invoiceNumber,
    isEInvoice,
    eInvoice,
    eInvoiceValidationErrors,
    isOverdue,
    bookings,
    documentFile,
    globalId,
    attachments,
  }: DocumentFragment,
  relationId: string,
  id: string
): RelatedDocument => {
  const money = !amount?.value
    ? null
    : {
        amount: amount?.value ?? 0,
        currency: currency?.value ?? DocumentCurrency.Eur,
        precision: 2,
      };

  const note = bookings?.find(b => b.note)?.note;

  return {
    relationId,
    id,
    globalDocumentId: globalId ?? '',
    amount: money,
    name: contact?.value.name.value ?? null,
    number: invoiceNumber?.value ?? null,
    status: status ?? null,
    date: invoiceDate?.value ?? null,
    isSensitive: false,
    isEInvoice: Boolean(isEInvoice),
    eInvoice: eInvoice ?? null,
    eInvoiceValidationErrors: eInvoiceValidationErrors ?? null,
    isOverdue: isOverdue ?? false,
    type: DocumentType.Invoice,
    notes: note?.value ?? null,
    documentFileId: documentFile?.id ?? null,
    attachments: attachments?.map(a => a.id) ?? [],
  };
};

type DocumentLink =
  DocumentRelationshipsQuery['documentRelationships']['relationships'][number];

const toDocumentRelationEntry = ({
  id: relationId,
  linkedDocument,
}: DocumentLink): RelatedDocument | undefined => {
  if (!relationId || !linkedDocument) return;
  if (linkedDocument.__typename === 'RelatedDocumentError') return;
  if (linkedDocument.__typename !== 'RelatedDocument') return;
  if (!linkedDocument.document) return;

  const { id, document } = linkedDocument;

  if (document.__typename === 'AggregatedEcmDocument')
    return mapAggregatedDocumentToRelatedDocument(document, relationId, id);

  if (document.__typename === 'Document')
    return mapDocumentToRelatedDocument(document, relationId, id);
};

export const useDocumentRelations = (globalDocumentId: string) => {
  const { showDocumentRelations } = useEcm();

  const variables = { input: { globalDocumentId } };

  const { data, previousData, loading, error, refetch } =
    useDocumentRelationshipsQuery({
      variables,
      fetchPolicy: 'cache-and-network',
      skip: !showDocumentRelations || !globalDocumentId,
    });

  const {
    handleLinkDocuments,
    isLinkingDocumentsPending,
    handleUnlinkDocuments,
    isUnlinkingDocumentsPending,
  } = useDocumentRelationshipsActions(refetch);

  const {
    documentRelationships: { relationships = [] },
  } = data ?? previousData ?? { documentRelationships: {} };

  const relatedDocuments = useMemo(
    () =>
      relationships
        .map(toDocumentRelationEntry)
        .filter(
          (r): r is RelatedDocument => r !== undefined
        ) satisfies RelatedDocument[],
    [relationships]
  );

  const hasError = !!error;
  const isEmpty = !hasError && relatedDocuments.length === 0;

  const isInitialLoadPending = relationships.length === 0 && loading;

  return {
    hasError,
    isLoading: isInitialLoadPending,
    isEmpty,
    relatedDocuments,
    handleLinkDocuments,
    isLinkingDocumentsPending,
    handleUnlinkDocuments,
    isUnlinkingDocumentsPending,
  };
};

import { Flex, useTheme } from '@candisio/design-system';
import { motion } from 'motion/react';
import { ReactNode } from 'react';

const MotionFlex = motion.create(Flex);

export const ButtonWrapper = ({ children }: { children: ReactNode }) => {
  const { colors } = useTheme();

  return (
    <MotionFlex
      background={`linear-gradient(90deg, transparent 10%, ${colors.bluebg} 30%)`}
      direction="row"
      gap="space16"
      height="100%"
      justifyContent="center"
      padding="space8 space20 space8 space64"
      position="absolute"
      right="0"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.1, duration: 0.3 }}
    >
      {children}
    </MotionFlex>
  );
};

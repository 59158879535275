import { CSSObject } from '@emotion/react';
import React, { ElementRef, useRef } from 'react';
import { mergeProps, useFocusRing, useHover, usePress } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { PolymorphicComponentProps } from 'react-polymorphic-box';
import { IconKey } from '../../Particles/Spritemap';
import { useTheme } from '../../Theme';
import { AnimationCSSObject } from '../../Utilities/AnimationBox';
import { SwitchAnimationBox } from '../../Utilities/SwitchAnimationBox';
import { IconSize, LayoutProps } from '../../types';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import { Tooltip, useTooltip } from '../Tooltip';
import { fontWeights } from '../../Theme/themeValues';

const DEFAULT_ELEMENT = 'button';

export type ButtonSize = 'xxsmall' | 'xsmall' | 'small' | 'medium';
export type ButtonIconPosition = 'left' | 'right';

export const ButtonColors = [
  'gray',
  'yellow',
  'green',
  'red',
  'whiteBlue',
  'blue',
  'purple',
] as const;

export type ButtonState =
  | 'default'
  | 'hovered'
  | 'pressed'
  | 'disabled'
  | 'focused'
  | 'loading';

type DeprecatedButtonColor =
  | 'default'
  | 'warning'
  | 'success'
  | 'error'
  | 'info'
  | 'util'
  | 'promo';

export type ValidButtonColor = (typeof ButtonColors)[number];

export type ButtonColor = DeprecatedButtonColor | ValidButtonColor;

export type Variant = 'primary' | 'secondary' | 'tertiary' | 'special';
export type Shape = 'rounded' | 'squared';

type GrayTertiaryType = {
  color: 'gray';
  variant: 'tertiary';
  hasOpacity?: boolean;
};

interface ButtonOwnProps extends LayoutProps {
  as?: 'button' | 'a';
  children?: React.ReactNode;
  color?: ButtonColor;
  disabled?: boolean;
  icon?: IconKey | JSX.Element;
  iconPosition?: ButtonIconPosition;
  iconSize?: IconSize;
  spinnerSize?: IconSize;
  isPressed?: boolean;
  label?: string;
  loading?: boolean;
  size?: ButtonSize;
  variant?: Variant;
  shape?: Shape;
}

export type ButtonProps<
  TElement extends React.ElementType = typeof DEFAULT_ELEMENT,
> =
  | (PolymorphicComponentProps<TElement, ButtonOwnProps> & GrayTertiaryType)
  | PolymorphicComponentProps<TElement, ButtonOwnProps>;

type ButtonType = <TElement extends React.ElementType = typeof DEFAULT_ELEMENT>(
  props: ButtonProps<TElement>
) => React.ReactElement | null;

/**
 * `Button` renders the `children` provided inside a HTML `<button>` element with an optional Icon. An icon-only Button is rendered when no children are provided.
 * Please note that not every property is always available, e.g. properties are restricted if the Button contains only one Icon and vice versa.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-forms-button}
 *
 * @param {React.ReactNode} [children] - Children (Don't apply to icon-only-buttons!)
 * @param {ButtonColor} [color] - Button role
 * @param {boolean} [disabled] - Disabled state
 * @param {IconKey} [icon] - Available icons
 * @param {IconPosition} [iconPosition] - Icon position
 * @param {IconSize} [iconSize] - Override the size of the icon
 * @param {IconSize} [spinnerSize] - Override the size of the spinner
 * @param {boolean} [isPressed] - Pressed/toggled state
 * @param {string} [label] - Label for Tooltip (Must be applied to icon-only-buttons!)
 * @param {Size} [size] - Button sizes (Only apply to icon-only-buttons!)
 * @param {boolean} [loading] - Loading state
 * @param {Variant} [variant] - Button variants
 * @param {hasOpacity} [variant] - Opacity only applied for gray tertiary buttons (used in input fields)
 */
export const Button = React.forwardRef<ButtonType, ButtonProps<ButtonType>>(
  function Button(
    {
      children,
      color = 'gray',
      hasOpacity,
      disabled,
      icon,
      iconPosition = 'left',
      iconSize,
      spinnerSize,
      isPressed: isToggled = false,
      label,
      loading,
      size = 'medium',
      type = 'button',
      variant = 'primary',
      shape = 'rounded',
      onClick,
      ...restProps
    },
    forwardedRef
  ) {
    const { button, space, timingFunctions, lineHeights } = useTheme();

    const ref = useRef<ElementRef<ButtonType>>(null);

    const { isFocusVisible, focusProps } = useFocusRing();
    const { isHovered, hoverProps } = useHover({
      isDisabled: disabled || loading,
    });

    const { isPressed, pressProps } = usePress({
      isDisabled: disabled || loading,
    });

    const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
      useTooltip({ placement: 'top' });

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
      // Further down you will see that we don't want to use the HTML
      // `disabled` attribute (see comment for explanation).
      //
      // To make sure that click events cannot be triggered in those cases, we
      // need to manually unset them here.
      if (disabled || loading) {
        e.preventDefault(); // prevent parent form from submitting...

        return; // ...and don’t call onClick
      }

      // The exact type of the `onClick` prop depends on the `as` prop.
      onClick?.(e);
    };

    const hasChildren = children !== undefined && children !== null;

    const animationDuration = 250;

    const baseStyle: CSSObject = {
      appearance: 'none',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      display: 'inline-flex',
      gap: space.space4,
      justifyContent: 'center',
      alignItems: 'center',
      transition: `all ${animationDuration}ms ${timingFunctions.ease}`,
      fontFamily: 'inherit',
      fontWeight: fontWeights.semibold,
      lineHeight: lineHeights.paragraph,
      // unset default button styles
      '&:focus': {
        outline: 'none',
      },
    };

    // CSS reset for anchor element
    const anchorStyle: CSSObject = {
      textDecoration: 'none',
    };

    const loadingBaseStyle: CSSObject = {
      cursor: 'wait',
    };

    const disabledBaseStyle: CSSObject = {
      cursor: 'not-allowed',
    };

    const animation: AnimationCSSObject = {
      enter: {
        svg: {
          opacity: 0,
        },
      },
      enterActive: {
        svg: {
          opacity: 1,
          transition: `opacity ${animationDuration}ms ${timingFunctions.ease}`,
        },
      },
      exit: {
        svg: {
          opacity: 1,
        },
      },
      exitActive: {
        svg: {
          opacity: 0,
          transition: `opacity ${animationDuration}ms ${timingFunctions.ease}`,
        },
      },
    };

    const IconAndChildren = () => {
      const animatedIcon = (loading || icon) && (
        <SwitchAnimationBox trigger={loading} animation={animation}>
          <Flex fontWeight={fontWeights.regular}>
            {loading ? (
              <Spinner
                size={spinnerSize ?? button[size].spinnerSize}
                color="inherit"
              />
            ) : typeof icon === 'string' ? (
              <Icon icon={icon} size={iconSize ?? button[size].iconSize} />
            ) : (
              icon
            )}
          </Flex>
        </SwitchAnimationBox>
      );

      if (iconPosition === 'right') {
        return (
          <>
            {children}
            {animatedIcon}
          </>
        );
      }

      // else icons on left or no icon
      return (
        <>
          {animatedIcon}
          {children}
        </>
      );
    };

    const shapeStyles = button[shape];
    const iconAndChildren = IconAndChildren();
    let variantRoleStyles;
    if (variant === 'special') {
      if (color === 'promo') variantRoleStyles = button.special.promo;
      else variantRoleStyles = button.special.default;
    } else {
      variantRoleStyles = button[variant][color];
    }

    const {
      default: defaultStyle,
      hovered: hoveredStyle,
      pressed: pressedStyle,
      focused: focusedStyle,
      disabled: disabledStyle,
      loading: loadingStyle,
    } = variantRoleStyles;

    const isGrayTertiaryButtonWithOpacity =
      variant === 'tertiary' && color === 'gray' && hasOpacity;

    return (
      <>
        <Box
          as={DEFAULT_ELEMENT}
          aria-label={label}
          aria-pressed={isToggled}
          css={[
            baseStyle,
            defaultStyle,
            shapeStyles,
            hasChildren ? button[size].default : button[size].iconOnly,
            disabled && [disabledBaseStyle, disabledStyle],
            loading && [loadingBaseStyle, loadingStyle],
            icon && hasChildren && button[size].iconPosition[iconPosition],
            isHovered && { ':hover': hoveredStyle },
            (isToggled || isPressed) && pressedStyle,
            isFocusVisible && { ':focus': focusedStyle },
            restProps.as === 'a' && anchorStyle,
            isGrayTertiaryButtonWithOpacity && button.hasOpacity,
          ]}
          type={type}
          {...mergeProps(
            triggerProps,
            focusProps,
            pressProps,
            hoverProps,
            restProps as Record<string, any>
          )}
          ref={mergeRefs([ref, triggerRef, forwardedRef])}
          // using aria instead of `disabled`, allowing disabled buttons to receive focus, so e.g. screen readers don't skip it
          aria-disabled={disabled || loading}
          onClick={handleClick}
        >
          {iconAndChildren}
        </Box>
        {label && isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {label}
          </Tooltip>
        )}
      </>
    );
  }
) as ButtonType;

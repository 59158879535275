import { Tooltip, useTooltip } from '@candisio/design-system';
import { Icon, IconProps } from '@candisio/design-system/next';
import { StatusTag } from 'components/StatusTag';
import { DocumentEInvoiceField } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { EInvoiceInfoIcon } from 'views/Inbox/DocumentProcessing/components/EInvoiceInfoIcon';
import { DocumentsTableData, Status } from '../../types';
import clsx from 'clsx';
import { CellContext } from '@tanstack/react-table';

interface StatusIconProps extends Omit<IconProps, 'color'> {
  label: ReactNode;
  hasTooltip?: boolean;
}

export const StatusIcon = ({
  icon,
  label,
  hasTooltip = true,
  className,
  ...restProps
}: StatusIconProps) => {
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      closeDelay: 500,
    });

  return (
    <>
      <Icon
        {...(hasTooltip && triggerProps)}
        className={clsx('size-4', className)}
        aria-label={typeof label === 'string' ? label : ''}
        ref={triggerRef}
        icon={icon}
        {...restProps}
      />
      {hasTooltip && isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {label}
        </Tooltip>
      )}
    </>
  );
};

const StatusIcons = ({
  isOverdue,
  isDuplicate,
  isWaitingForClarification,
  hasAttachments,
  isEInvoice,
  eInvoice,
}: {
  isOverdue?: boolean;
  isDuplicate?: boolean;
  isWaitingForClarification?: boolean;
  hasAttachments?: boolean;
  isEInvoice?: boolean;
  eInvoice?: DocumentEInvoiceField | null;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const [visualiseWaitingForClarificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.visualiseWaitingForClarification,
  ]);

  if (
    !isOverdue &&
    !isDuplicate &&
    !isWaitingForClarification &&
    !hasAttachments &&
    !isEInvoice &&
    !eInvoice
  ) {
    return null;
  }

  return (
    <div className="grid relative grid-flow-col gap-1">
      <EInvoiceInfoIcon isEInvoice={isEInvoice} eInvoice={eInvoice} />
      {isOverdue && (
        <StatusIcon
          label={t('statuses.overdue')}
          icon="clock"
          className="text-red-500"
        />
      )}
      {visualiseWaitingForClarificationFF && isWaitingForClarification && (
        <StatusIcon
          label={t('statuses.waitingForClarification')}
          icon="mail"
          className="text-gray-800"
        />
      )}
      {isDuplicate && (
        <StatusIcon
          label={t('statuses.duplicate')}
          icon="duplicate"
          className="text-yellow-500"
        />
      )}
      {hasAttachments && (
        <StatusIcon
          label={t('attachments')}
          icon="attachment"
          className="text-gray-600"
        />
      )}
    </div>
  );
};

type CellValue = Status | undefined;

type CellProps = CellContext<DocumentsTableData, CellValue>;

export const StatusCell = ({ getValue }: CellProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const value = getValue();

  if (!value) return <span>{t('emptyCellPlaceholder')}</span>;

  const {
    isOverdue,
    isDuplicate,
    isWaitingForClarification,
    hasAttachments,
    status,
    isEInvoice,
    eInvoice,
  } = value;

  return (
    <div className="grid relative items-center grid-flow-col justify-start gap-2">
      <StatusTag status={status} />
      <StatusIcons
        isOverdue={isOverdue}
        isDuplicate={isDuplicate}
        isWaitingForClarification={isWaitingForClarification}
        hasAttachments={hasAttachments}
        isEInvoice={isEInvoice}
        eInvoice={eInvoice}
      />
    </div>
  );
};

import { IntegrationName } from 'generated-types/graphql.types';
import { z } from 'zod';
import { nonEmptyString } from './nonEmptyString';
import { preprocessStringToNumber } from './preprocessStringToNumber';

/**
 * DATEV adjacent
 */
export const DatevAdjacentAccountsPayableNumberRequirements = {
  MinNumber: 10000,
  MaxNumber: 999999999,
} as const;

export const DatevAccountsPayableNumberRequirements = {
  MinNumber: 70000,
  MaxNumber: 999999999,
  FirstDigit: {
    MinNumber: 7,
    MaxNumber: 9,
  },
} as const;

/** IntegrationName: DATEV */
const datevSchema = z
  .number()
  .int()
  .min(DatevAccountsPayableNumberRequirements.MinNumber)
  .max(DatevAccountsPayableNumberRequirements.MaxNumber)
  .transform(String);

/** IntegrationName: DATEV_ADJACENT */
export const datevAdjacentSchema = z
  .number()
  .int()
  .min(DatevAdjacentAccountsPayableNumberRequirements.MinNumber)
  .max(DatevAdjacentAccountsPayableNumberRequirements.MaxNumber)
  .transform(String);

export function createDatevSchema(glaLength?: number) {
  return datevSchema.superRefine((value, ctx) => {
    const firstDigit = parseInt(value.toString()[0]);
    const length = value.length;

    const firstDigitValidation =
      firstDigit >=
        DatevAccountsPayableNumberRequirements.FirstDigit.MinNumber &&
      firstDigit <= DatevAccountsPayableNumberRequirements.FirstDigit.MaxNumber;

    if (!firstDigitValidation) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey: 'accountsPayableNumberField.errors.firstDigitInvalid',
          firstDigitMin:
            DatevAccountsPayableNumberRequirements.FirstDigit.MinNumber,
          firstDigitMax:
            DatevAccountsPayableNumberRequirements.FirstDigit.MaxNumber,
        },
      });
    } else if (glaLength) {
      if (length !== glaLength + 1) {
        const glaLengthAsZeroes = '0'.repeat(glaLength);
        const minNumberWithGla = parseInt(
          DatevAccountsPayableNumberRequirements.FirstDigit.MinNumber +
            glaLengthAsZeroes
        );

        const glaLengthAsNines = '9'.repeat(glaLength);
        const maxNumberWithGla = parseInt(
          DatevAccountsPayableNumberRequirements.FirstDigit.MaxNumber +
            glaLengthAsNines
        );

        ctx.addIssue({
          code: 'custom',
          params: {
            translationKey:
              'accountsPayableNumberField.errors.glaLengthInvalid',
            minNumberWithGla,
            maxNumberWithGla,
          },
        });
      }
    } else {
      return;
    }
  });
}

// z.coerce uses `Number(value)` which converts invalid values to numbers
// Eg z.coerce.number().parse('070001') => 70001
// Using preprocess instead to prevent the above from happening.
export const datevAccountsPayableNumberSchema = (
  schema:
    | ReturnType<typeof createDatevSchema>
    | typeof datevSchema = datevAdjacentSchema
) => z.preprocess(preprocessStringToNumber, schema);

/**
 * SAP
 * Max 15 characters
 * Only alphanumeric characters
 */

export const SapAccountsPayableNumberRequirements = {
  MinLength: 1,
  MaxLength: 15,
  Regex: /^\S*$/,
} as const;

const sapAccountsPayableNumberSchema = z
  .string()
  .min(SapAccountsPayableNumberRequirements.MinLength)
  .max(SapAccountsPayableNumberRequirements.MaxLength)
  .regex(SapAccountsPayableNumberRequirements.Regex);

/** IntegrationName: OTHER */
export const otherAccountsPayableNumberSchema = (
  shouldRequireAccountsPayableNumber?: boolean
) =>
  shouldRequireAccountsPayableNumber
    ? nonEmptyString
    : nonEmptyString.nullish();

export const accountsPayableNumberSchema = (
  integration: IntegrationName,
  glaLength?: number,
  shouldRequireAccountsPayableNumber?: boolean
) => {
  switch (integration) {
    case IntegrationName.Sap:
      return sapAccountsPayableNumberSchema.nullish();

    case IntegrationName.Other:
      return otherAccountsPayableNumberSchema(
        shouldRequireAccountsPayableNumber
      );

    case IntegrationName.DatevAdjacent:
      return datevAccountsPayableNumberSchema(datevAdjacentSchema).nullish();

    case IntegrationName.Datev:
    default:
      return datevAccountsPayableNumberSchema(
        createDatevSchema(glaLength)
      ).nullish();
  }
};

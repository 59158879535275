import { Text } from '@candisio/design-system';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CardItem } from './Item';

interface ReplacementCardItemProps {
  onClick: (mode: DrawerMode) => void;
}

export const ReplacementCardItem = ({ onClick }: ReplacementCardItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <CardItem icon="creditCardOutline" onClick={() => onClick('replaceCard')}>
      <Text fontSize="large">
        {t('creditCardFormDataPreview.replaceCard.title')}
      </Text>
      <Text fontSize="small">
        {t('creditCardFormDataPreview.replaceCard.subtitle')}
      </Text>
    </CardItem>
  );
};

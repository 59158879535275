import {
  Locale,
  useUpdateCurrentUserMutation,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { currentUserQuery } from 'providers/CurrentUserProvider/queries';
import { useCallback } from 'react';

export const useUpdateCurrentUserLocale = () => {
  const user = useCurrentUser();
  const [updateCurrentUser, { loading }] = useUpdateCurrentUserMutation({
    refetchQueries: [currentUserQuery],
  });

  const updateLocale = useCallback(
    (locale: Locale) => {
      updateCurrentUser({
        variables: {
          input: {
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            locale,
          },
        },
      });
    },
    [updateCurrentUser, user?.firstName, user?.lastName]
  );

  return {
    locale: user?.locale,
    isPending: loading,
    updateLocale,
  };
};

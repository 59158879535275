import { Spinner, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import {
  CheckmarkIcon,
  ErrorIcon,
  InfoIcon,
} from '../../../../../components/Icons/DefaultIcons';
import { useCurrentUser } from '../../../../../providers/CurrentUserProvider';
import {
  LOCALE_NAME_SPACE,
  i18n,
} from '../../../../../providers/LocaleProvider';
import { useFullOrganization } from '../../../../../providers/OrganizationProvider';

export const fieldTranslationKeys = {
  serviceLayerUrl: 'settings.form.fields.serviceLayerUrl.label',
  companyDbId: 'settings.form.fields.companyDbId.label',
  username: 'settings.form.fields.username.label',
  password: 'settings.form.fields.password.label',
  version: 'settings.form.fields.version.label',
};

export const useModalConfig = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);
  const currentUser = useCurrentUser();
  const organization = useFullOrganization();

  const userEmail = currentUser?.email;
  const userName = currentUser?.name;
  const orgName = organization?.name;

  return {
    modalTitle: t('settings.sapAdminEmail.modalTitle'),
    subjectText: t('settings.sapAdminEmail.subjectText', {
      companyName: orgName,
    }),
    body: t('settings.sapAdminEmail.body', {
      companyName: orgName,
      email: userEmail,
      userName: userName,
    }),
    senderEmail: userEmail,
    senderName: userName,
  };
};

export const infoIcon = <InfoIcon flex="none" size="space16" />;
const loadingIcon = <Spinner color="blue700" size="space12" />;
const successIcon = <CheckmarkIcon flex="none" size="space16" />;
const errorIcon = <ErrorIcon flex="none" size="space16" />;

export const getChecks = (t: i18n.TFunction) => ({
  errorCheck: {
    key: 'form-error',
    icon: errorIcon,
    text: (
      <>
        <Text color="red500">{t('settings.checks.error')}</Text>
        <br />
        <br />
        <Text color="red500">
          {t('settings.form.fields.serviceLayerUrl.infoDetails')}
        </Text>
      </>
    ),
    state: 'loaded',
  },
  successCheck: {
    key: 'form-success',
    icon: successIcon,
    text: <Text color="green500">{t('settings.checks.success')}</Text>,
    state: 'loaded',
  },
  loadingCheck: {
    key: 'form-loading',
    icon: loadingIcon,
    text: <Text color="gray500">{t('settings.checks.loading')}</Text>,
    state: 'loading',
  },
});

import { Button, Card, CardProps, Grid, Text } from '@candisio/design-system';
import { motion } from 'motion/react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export interface AttachCardProps extends CardProps {
  isActionPending: boolean;
  canAttach: boolean;
  count: number;
  onAttach?: () => void;
  onDeselect?: () => void;
}

export const AttachCard = motion.create(
  forwardRef<HTMLDivElement, AttachCardProps>(
    (
      { isActionPending, canAttach, count, onAttach, onDeselect, ...restProps },
      ref
    ) => {
      const [t] = useTranslation();

      return (
        <Card
          background="blue100"
          corners="all"
          boxShadow="elevatedShadow5"
          paddingX="space36"
          maxHeight="space80"
          ref={ref}
          {...restProps}
        >
          <Grid
            alignItems="center"
            autoFlow="column"
            justifyContent="space-between"
          >
            <Grid alignItems="center" autoFlow="column" gap="space8">
              <Text>
                {t('document.attachments.documentsSelected', { count })}
              </Text>
              <Button variant="tertiary" size="small" onClick={onDeselect}>
                {t('document.attachments.deselectPdf')}
              </Button>
            </Grid>
            <Button
              onClick={onAttach}
              disabled={!canAttach}
              loading={isActionPending}
            >
              {t('document.attachments.attachDocuments', { count })}
            </Button>
          </Grid>
        </Card>
      );
    }
  )
);

import { Icon } from '@candisio/design-system';
import { InfoPopupButton } from 'components/Icons/InfoPopupButton';
import { ExportStatus } from 'generated-types/graphql.types';

export const ExportSummaryTitle = ({
  title,
  tooltip,
  status,
  disabled,
}: {
  title: string;
  tooltip?: string;
  status?: ExportStatus;
  disabled?: boolean;
}) => {
  const renderExportStatusIcon = (status: ExportStatus) => {
    if (status === ExportStatus.Exported)
      return (
        <Icon
          size="space16"
          icon="checkCircle"
          color={disabled ? 'gray500' : 'green700'}
        />
      );
    if (status === ExportStatus.PartialyExported)
      return (
        <Icon
          size="space16"
          icon="warning"
          color={disabled ? 'gray500' : 'yellow700'}
        />
      );
    if (status === ExportStatus.Failed)
      return (
        <Icon
          size="space16"
          icon="failCircle"
          color={disabled ? 'gray500' : 'red700'}
        />
      );

    return null;
  };

  return (
    <div className="flex items-center gap-1">
      {status && renderExportStatusIcon(status)}
      {title}
      {tooltip && (
        <>
          <InfoPopupButton message={tooltip} key="provisionsTooltip" />
        </>
      )}
    </div>
  );
};

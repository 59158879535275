import {
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const Container = (props: FlexProps) => {
  return <Flex direction="column" gap="space12" {...props} />;
};

const Title = (props: TextProps) => {
  return (
    <Text fontWeight="semibold" color="white" fontSize="xlarge" {...props} />
  );
};

const Subtitle = (props: TextProps) => {
  return <Text color="white" {...props} />;
};

const CTA = (props: ButtonProps) => {
  return <Button variant="primary" color="whiteBlue" {...props} />;
};

interface BaselineSearchPromotionMessageProps {
  onClick: () => void;
}

const RowMessage = ({ onClick }: BaselineSearchPromotionMessageProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  return (
    <Container>
      <Title>{t('baselineSearch.slide.row.title')}</Title>
      <Subtitle fontSize="xsmall">
        {t('baselineSearch.slide.row.subtitle')}
      </Subtitle>
      <CTA onClick={onClick} icon="arrowRight" iconPosition="right">
        {t('baselineSearch.slide.row.cta')}
      </CTA>
    </Container>
  );
};

interface ColumnMessageProps extends BaselineSearchPromotionMessageProps {
  isLastSlide: boolean;
}

const ColumnMessage = ({ onClick, isLastSlide }: ColumnMessageProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  return (
    <Container>
      <Title>{t('baselineSearch.slide.column.title')}</Title>
      <Subtitle fontSize="small">
        {t('baselineSearch.slide.column.subtitle')}
      </Subtitle>
      {isLastSlide ? (
        <CTA onClick={onClick}>{t('baselineSearch.slide.search.cta')}</CTA>
      ) : (
        <CTA onClick={onClick} icon="arrowRight" iconPosition="right">
          {t('baselineSearch.slide.column.cta')}
        </CTA>
      )}
    </Container>
  );
};

const SearchMessage = ({ onClick }: BaselineSearchPromotionMessageProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  return (
    <Container>
      <Title>{t('baselineSearch.slide.search.title')}</Title>
      <Subtitle fontSize="basic">
        <Trans t={t} i18nKey="baselineSearch.slide.search.subtitle">
          <Text as="strong" fontWeight="bold" />
          <Text as="strong" fontWeight="bold" />
        </Trans>
      </Subtitle>
      <CTA onClick={onClick}>{t('baselineSearch.slide.search.cta')}</CTA>
    </Container>
  );
};

export const BaselineSearchPromotionOverlayMessage = ({
  index,
  onClick,
  totalOverlays,
}: { index: number; onClick: () => void; totalOverlays: number }) => {
  switch (index) {
    case 0:
      return <RowMessage onClick={onClick} />;
    case 1:
      const isLastSlide = index === totalOverlays - 1;
      return <ColumnMessage onClick={onClick} isLastSlide={isLastSlide} />;
    case 2:
      return <SearchMessage onClick={onClick} />;
    default:
      return null;
  }
};

import { Button, Flex, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';

export interface ReimbursementNavigatorProps {
  loading: boolean;
  reimbursementCount?: number;
  prevReimbursementLink?: string;
  nextReimbursementLink?: string;
}

export const ReimbursementNavigator = ({
  loading = false,
  reimbursementCount,
  prevReimbursementLink,
  nextReimbursementLink,
}: ReimbursementNavigatorProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const navigate = useNavigate();

  const navigatorTitle = loading
    ? t('reimbursementView.leftSection.header.listNavigator.loading')
    : t('reimbursementView.leftSection.header.listNavigator.title', {
        count: reimbursementCount,
      });

  const nextReimbursementLabel = nextReimbursementLink
    ? t('reimbursementView.leftSection.header.listNavigator.next')
    : undefined;

  const prevReimbursementLabel = prevReimbursementLink
    ? t('reimbursementView.leftSection.header.listNavigator.prev')
    : undefined;

  const nextReimbursementPage = useCallback(() => {
    if (nextReimbursementLink) {
      navigate(nextReimbursementLink);
    }
  }, [navigate, nextReimbursementLink]);

  const prevReimbursementPage = useCallback(() => {
    if (prevReimbursementLink) {
      navigate(prevReimbursementLink);
    }
  }, [navigate, prevReimbursementLink]);

  const isCounterVisible = (reimbursementCount ?? 0) > 0;

  return (
    <Flex gap="space4" alignItems="center" justifyContent="end">
      {isCounterVisible && <TruncatedText>{navigatorTitle}</TruncatedText>}
      <Flex>
        <Button
          size="small"
          variant="tertiary"
          icon="arrowLeft"
          label={prevReimbursementLabel}
          onClick={prevReimbursementPage}
          disabled={loading || !prevReimbursementLink}
        />
        <Button
          size="small"
          variant="tertiary"
          icon="arrowRight"
          label={nextReimbursementLabel}
          onClick={nextReimbursementPage}
          disabled={loading || !nextReimbursementLink}
        />
      </Flex>
    </Flex>
  );
};

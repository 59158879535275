import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { SwimlaneTileAmount } from 'components/Swimlane/content/SwimlaneTileAmount';
import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Progressbar } from 'views/CreditCards/CreditCardsInsights/FinancialOverview/Progressbar';
import styles from './AvailableLimitTile.module.css';
import { useGetCreditCardFrequency } from './useGetCreditCardFrequency';
import { useGetCreditCardLimits } from './useGetCreditCardLimits';
import { InfoPopupButton } from 'components/Icons/InfoPopupButton';
import { Link } from '@candisio/design-system';

export const AvailableLimitTile = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { totalLimit, availableLimit } = useGetCreditCardLimits();
  const { transactionAmountFormatter } = useMoneyFormatter();

  const spendValue = (totalLimit?.value ?? 0) - (availableLimit?.value ?? 0);

  const readableFrequency = useGetCreditCardFrequency();

  return (
    <SwimlaneTile className={styles.content} cols={2}>
      <div>
        <div className="flex gap-1 items-center">
          <SwimlaneTileDescription>
            {t('organizationLimit.details.available')}
          </SwimlaneTileDescription>

          <InfoPopupButton
            data-cy="infoIcon"
            message={
              <div className="flex flex-col gap-2">
                <div>{t('organizationLimit.info.credit.text')}</div>
                <Link external href={t('organizationLimit.info.credit.url')}>
                  {t('organizationLimit.linkText')}
                </Link>
              </div>
            }
          />
        </div>

        <SwimlaneTileAmount>
          {transactionAmountFormatter(availableLimit)}
        </SwimlaneTileAmount>
      </div>

      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <SwimlaneTileDescription className={styles.grayText}>
            {t('organizationLimit.details.periodSettlementWithAmount', {
              settlementPeriod: readableFrequency,
              amount: transactionAmountFormatter(totalLimit),
            })}
          </SwimlaneTileDescription>
          <SwimlaneTileDescription className={styles.grayText}>
            {t('organizationLimit.details.spentWithAmount', {
              amount: transactionAmountFormatter({
                value: spendValue,
                currency: totalLimit?.currency ?? DocumentCurrency.Eur,
                precision: totalLimit?.precision ?? 2,
              }),
            })}
          </SwimlaneTileDescription>
        </div>

        <Progressbar
          available={availableLimit?.value ?? 0}
          total={totalLimit?.value ?? 0}
        />
      </div>
    </SwimlaneTile>
  );
};

import { Flex, Grid, Text, useTheme } from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { AdvancedSearchField } from 'components/AdvancedSearchField/AdvancedSearchField';
import { BaselineSearchPromotionContainer } from 'components/BaselineSearchPromotion/BaselineSearchPromotionContainer';
import {
  DocumentsTable,
  DocumentsTableProps,
  defaultSortByFields,
} from 'components/DocumentsTable/DocumentsTable';
import { DocumentsTableAdapter } from 'components/DocumentsTable/next/DocumentsTableAdapter';
import {
  ColumnWidths,
  DocumentTableColumnKeys,
  DocumentsTableData,
} from 'components/DocumentsTable/types';
import { ArchiveKebabMenu } from 'components/Menu/ArchiveKebabMenu/ArchiveKebabMenu';
import { ConfigurationsMenu } from 'components/Table/Configurations/ConfigurationsMenu';
import { UserRole } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ComponentProps, ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadMenuButtonContainer } from 'views/Archive/Documents/DownloadMenuButtonContainer';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import { availableFilters } from './consts';
import { useArchiveDocumentsFilters } from './hooks/useArchiveDocumentsFilters';
import { useArchiveDocumentsColumnsConfigs } from './hooks/useGetArchiveDocumentsColumnsConfigs';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { usePath } from 'hooks/usePath';
import { DocumentsTableLink } from 'components/DocumentsTable/next/components/DocumentsTableLink';

export type ArchiveDocumentsTableDSProps = {
  data: Array<DocumentsTableData>;
  hasMoreData: boolean;
  isLoading: boolean;
  isUsingSearchQuery: boolean;
  onEndReached: () => void;
  organizationSlug: string;
  searchFieldOnChange: (query: string) => void;
  searchFieldValue: string;
  selectedDocumentsCount: number;
  shouldEnableSortByWithSearchQuery: boolean;
  rowOverlay?: DocumentsTableProps['rowOverlay'];
  cellWrapper?: DocumentsTableProps['cellWrapper'];
  getCellStyles?: DocumentsTableProps['getCellStyles'];
};

export const ArchiveDocumentsTable = ({
  data: tableData,
  hasMoreData,
  isLoading,
  isUsingSearchQuery,
  onEndReached,
  organizationSlug,
  searchFieldOnChange,
  searchFieldValue,
  selectedDocumentsCount,
  shouldEnableSortByWithSearchQuery,
  rowOverlay,
  cellWrapper,
  getCellStyles,
}: ArchiveDocumentsTableDSProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const [wipTableRefactorForEcmAllDocumentsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.wipTableRefactorForEcmAllDocuments,
  ]);
  const { showConsistentSortAndFiltering } = useEcm();

  const { sortBy, onSort, filters, onFilter } =
    useUrlBasedSortAndFilter<DocumentsTableData>({
      availableFilters,
    });

  // biome-ignore lint/correctness/useExhaustiveDependencies: only want it to run once
  useEffect(() => {
    if (sortBy.length === 0 && showConsistentSortAndFiltering) {
      sortBy.push({
        id: 'invoiceDate',
        desc: true,
      });
    }
  }, []);

  const {
    availableDocumentColumnIds,
    configurationsTable,
    isLoadingConfigs,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useArchiveDocumentsColumnsConfigs({ filters, sortBy });

  const { space } = useTheme();

  const { filterOptions, filtersLoading } = useArchiveDocumentsFilters();

  const isTableFiltered = filters.length > 0;

  const emptyStateContent = (
    <Text textAlign="center" whiteSpace="pre-wrap">
      {isTableFiltered
        ? t('emptyState.content.filtered')
        : t('emptyState.content.empty')}
    </Text>
  );

  const columnWidths: ColumnWidths = {
    contact: space.space256,
    discountDateWPercentage: space.space256,
    iban: space.space200,
    paymentInfo: space.space200,
    accountingArea: space.space256,
    invoiceNumber: space.space200,
    documentType: space.space200,
    purchaseOrderNumber: space.space200,
    goodsReceiptNumber: space.space200,
    sapPurchaseOrderNumber: space.space200,
  };

  const showLoadingMoreSpinner = tableData.length > 1;

  const infiniteScrollOptions = {
    dataLength: tableData?.length,
    hasMore: hasMoreData,
    showLoadingMoreSpinner: !isLoading && showLoadingMoreSpinner,
    next: onEndReached,
  };

  const tableKey = `table-filters-${filtersLoading}-${availableDocumentColumnIds.join(
    '-'
  )}`;

  const sortByFields: DocumentTableColumnKeys[] = showConsistentSortAndFiltering
    ? [
        ...defaultSortByFields,
        'invoiceNumber',
        'purchaseOrderNumber',
        'artistSocialInsuranceCode',
        'costObject',
        'costCenter',
        'extraCostInfo',
        'documentType',
        'generalLedgerAccount',
        'deliveryDate',
        'paidAt',
      ]
    : defaultSortByFields;

  const initialSorting: ComponentProps<
    typeof DocumentsTableAdapter
  >['initialSorting'] = useMemo(
    () =>
      sortBy.length === 0 && showConsistentSortAndFiltering
        ? [
            {
              id: 'invoiceDate',
              desc: true,
            },
          ]
        : undefined,
    [sortBy, showConsistentSortAndFiltering]
  );

  return (
    <Flex height="100%" overflow="hidden" direction="column" key={tableKey}>
      <Grid
        templateColumns="minmax(12rem, 24rem) 1fr"
        gap="space8"
        alignItems="center"
        borderTopRadius="basic"
        overflow="hidden"
        background="white"
        borderBottom="1px solid gray200"
        padding="space12"
      >
        <AdvancedSearchField
          clearTooltip={t('archive:search.clearTooltip')}
          placeholder={t('archive:search.placeholder')}
          initialValue={searchFieldValue}
          onQueryChange={searchFieldOnChange}
          inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
        />
        <Grid justifySelf="end">
          <UserRoleAccessWrapper
            allowedRoles={[
              UserRole.Admin,
              UserRole.Requester,
              UserRole.Approver,
              UserRole.Accountant,
            ]}
            mode="hidden"
          >
            <Flex gap="space8" alignItems="center">
              <ConfigurationsMenu
                configurationItems={configurationsTable}
                isLoading={isLoadingConfigs}
                onUpdateConfigurations={handleUpdateConfigurations}
                onResetConfigurations={handleResetTableConfigurations}
              />
              <DownloadMenuButtonContainer
                isTableFiltered={isTableFiltered}
                isTableLoading={isLoading}
                selectedDocumentsCount={selectedDocumentsCount}
              />
              <UserRoleAccessWrapper
                allowedRoles={[
                  UserRole.Admin,
                  UserRole.Requester,
                  UserRole.Approver,
                  UserRole.Accountant,
                ]}
                mode="hidden"
              >
                <ArchiveKebabMenu organizationSlug={organizationSlug} />
              </UserRoleAccessWrapper>
            </Flex>
          </UserRoleAccessWrapper>
        </Grid>
      </Grid>
      {wipTableRefactorForEcmAllDocumentsFF ? (
        <DocumentsTableAdapter
          data={tableData}
          shownColumns={availableDocumentColumnIds}
          isLoading={isLoading}
          onLoadMore={onEndReached}
          rowOverlay={rowOverlay}
          sortBy={sortBy}
          onSort={onSort}
          CellWrapper={ArchiveDocumentsTableLink}
          columnWidths={newColumnWidths}
          initialSorting={initialSorting}
        />
      ) : (
        <DocumentsTable
          columns={availableDocumentColumnIds}
          columnWidths={columnWidths}
          borderTopRadius="none"
          data={tableData}
          defaultEmptyStateContent={emptyStateContent}
          defaultFilters={filters}
          defaultSortBy={sortBy}
          filterOptions={filterOptions}
          infiniteScrollOptions={infiniteScrollOptions}
          isLoading={isLoading}
          isUsingSearchQuery={isUsingSearchQuery}
          minWidth="100%"
          onEndReached={onEndReached}
          onFilter={onFilter}
          onSort={onSort}
          width="max-content"
          rowOverlay={rowOverlay}
          cellWrapper={cellWrapper}
          getCellStyles={getCellStyles}
          shouldEnableSortByWithSearchQuery={shouldEnableSortByWithSearchQuery}
          sortByFields={sortByFields}
        />
      )}

      <BaselineSearchPromotionContainer />
    </Flex>
  );
};

const newColumnWidths: ComponentProps<
  typeof DocumentsTableAdapter
>['columnWidths'] = {
  contact: 256,
  discountDateWPercentage: 256,
  iban: 200,
  paymentInfo: 200,
  accountingArea: 256,
  invoiceNumber: 200,
  documentType: 200,
  purchaseOrderNumber: 200,
  goodsReceiptNumber: 200,
  sapPurchaseOrderNumber: 200,
};

interface ArchiveDocumentsTableLinkProps {
  row: DocumentsTableData;
  children?: ReactNode;
}

const ArchiveDocumentsTableLink = ({
  row,
  children,
}: ArchiveDocumentsTableLinkProps) => {
  const [searchParams] = useSearchParams();
  searchParams.set('cursor', row.cursor ?? '');
  const path = usePath({
    pathname: '/archive/:documentId',
    params: {
      documentId: row.id,
    },
    search: searchParams.toString(),
  });

  return <DocumentsTableLink to={path}>{children}</DocumentsTableLink>;
};

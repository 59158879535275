import { Box } from '@candisio/design-system';
import { InboxViewContainer } from '../components/InboxViewContainer';
import { TabView } from '../models';
import { InboxContractsHeader } from './InboxContractsHeader';
import { ContractsPromotion } from 'components/ProductPromotions/Contracts/ContractsPromotionArchive';

export const InboxContractsPromo = () => {
  return (
    <>
      <InboxContractsHeader />
      <InboxViewContainer activeTab={TabView.INBOX_CONTRACTS}>
        <Box height="100%" padding="0 space32 space24 space32">
          <ContractsPromotion />
        </Box>
      </InboxViewContainer>
    </>
  );
};

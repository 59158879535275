import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { CreditCardsInsights } from './CreditCardsInsights';
import { CreditCardsInsightSwimlanes } from './CreditCardsInsightSwimlanes/CreditCardsInsightSwimlanes';
import { CreditCardsIssueNewCardModal } from './CreditCardsIssueNewCardModal';
import { TopupDrawerContainer } from './TopupDrawer/TopupDrawerContainer';
import { OrganizationSettingsDrawer } from './OrganizationSettingsDrawer/OrganizationSettingsDrawer';

export const CreditCardsInsightContainer = () => {
  const dashboardRedesignCreditCardsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.dashboardRedesignCreditCards
  );

  return (
    <>
      {dashboardRedesignCreditCardsFF ? (
        <CreditCardsInsightSwimlanes />
      ) : (
        <CreditCardsInsights />
      )}

      <CreditCardsIssueNewCardModal />
      <TopupDrawerContainer />
      <OrganizationSettingsDrawer />
    </>
  );
};

import { FieldPolicy, TypePolicies } from '@apollo/client';
import {
  ApprovalReimbursementCasesResponse,
  ArchiveReimbursementCasesResponse,
  InboxReimbursementCasesResponse,
} from 'generated-types/graphql.types';
import { paginationWithRecordConnectionsMergeNoNodes } from '../pagination-results-merge';

const getReimbursementsListCursorPagination = (
  __typename:
    | Required<ArchiveReimbursementCasesResponse>['__typename']
    | Required<InboxReimbursementCasesResponse>['__typename']
    | Required<ApprovalReimbursementCasesResponse>['__typename']
): FieldPolicy<
  | ArchiveReimbursementCasesResponse
  | InboxReimbursementCasesResponse
  | ApprovalReimbursementCasesResponse
> => {
  return {
    merge: paginationWithRecordConnectionsMergeNoNodes(__typename),
    keyArgs: ['filters', 'sorts', 'search', 'searchInput'],
  };
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      archiveReimbursementCases: getReimbursementsListCursorPagination(
        'ArchiveReimbursementCasesResponse'
      ),
      inboxReimbursementCases: getReimbursementsListCursorPagination(
        'InboxReimbursementCasesResponse'
      ),
      completedReimbursements: getReimbursementsListCursorPagination(
        'ArchiveReimbursementCasesResponse'
      ),
      openReimbursements: getReimbursementsListCursorPagination(
        'ArchiveReimbursementCasesResponse'
      ),
      approvalReimbursementCases: getReimbursementsListCursorPagination(
        'ApprovalReimbursementCasesResponse'
      ),
    },
  },
};

import { AppRouteParams, Routes } from 'models';
import { generatePath } from 'react-router-dom-v5-compat';
import { DocumentType } from 'generated-types/graphql.types';
import { NotificationProps } from '../getNotificationInfo';

export const getLinkToInvoice = (
  notification: NotificationProps<'DocumentRejectedInAppNotification'>
) => {
  const link = {
    pathname: generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}/:documentId`,
      {
        documentId: notification.documentId,
        organizationSlug: notification.organizationId,
      }
    ),
  };

  return { link };
};

export const getLinkToInvoiceOrECMDocument = (
  notification: NotificationProps<'DocumentCommentCreatedInAppNotification'>
) => {
  const isInvoice = notification.documentType === DocumentType.Invoice;

  const invoiceLink = {
    pathname: generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}/:documentId`,
      {
        documentId: notification.documentId,
        organizationSlug: notification.organizationId,
      }
    ),
  };

  const ecmDocumentLink = {
    pathname: generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.ECM_DOCUMENTS}/:globalDocumentId`,
      {
        globalDocumentId: notification.globalDocumentId,
        organizationSlug: notification.organizationId,
      }
    ),
  };

  const link = isInvoice ? invoiceLink : ecmDocumentLink;

  return { link };
};

import { Button, Text } from '@candisio/design-system';
import { Trans } from 'providers/LocaleProvider';

export const SidebarSegmentPromo = ({
  markAsSeen,
  i18nKey,
}: {
  markAsSeen: () => void;
  i18nKey: string;
}) => (
  <div className="relative bg-blue-bg rounded-medium p-3 leading-5 border border-blue-200 text-basic">
    <div className="float-right">
      <Button
        variant="tertiary"
        color="gray"
        icon="close"
        size="xsmall"
        onClick={markAsSeen}
      />
    </div>

    <Trans parent="span" i18nKey={i18nKey}>
      <Text fontWeight="semibold">Neu </Text>- Verknüpfungen besser organisieren
      mit der neuen Ansicht
    </Trans>
  </div>
);

import { CustomEmptyStateProps, Link, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TransactionsTableEmptyStateLayout } from './TransactionsTableEmptyStateLayout';

interface TransactionsTableEmptyStateProps extends CustomEmptyStateProps {
  isTableEmpty: boolean;
  isTableFiltered: boolean;
  areTransactionsSelected?: boolean;
}

const TableIsEmpty = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <Text textAlign="center">
      {t('addTransactionModal.emptyState.isEmpty')}
    </Text>
  );
};

const TableIsFilteredAndEmpty = ({
  resetFilters,
}: {
  resetFilters: () => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {t('addTransactionModal.emptyState.isFilteredAndEmpty')}
      </Text>
      <Link as="button" onClick={() => resetFilters()}>
        {t('addTransactionModal.emptyState.resetFilter')}
      </Link>
    </>
  );
};

const TransactionIsSelectedTableIsEmpty = ({
  resetFilters,
}: {
  resetFilters: () => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  return (
    <>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {t(
          'addTransactionModal.emptyState.isFilteredAndEmptyAndTransactionsAreSelected'
        )}
      </Text>
      <Link as="button" onClick={() => resetFilters()}>
        {t('addTransactionModal.emptyState.resetFilter')}
      </Link>
    </>
  );
};

export const TransactionsTableEmptyState = ({
  areTransactionsSelected,
  isTableEmpty,
  isTableFiltered,
  resetFilters,
}: TransactionsTableEmptyStateProps) => {
  const hasNoTransactions = isTableEmpty && !isTableFiltered;
  const isFilteredAndEmpty =
    isTableEmpty && isTableFiltered && !areTransactionsSelected;

  return (
    <TransactionsTableEmptyStateLayout isTableFiltered={isTableFiltered}>
      {hasNoTransactions ? (
        <TableIsEmpty />
      ) : isFilteredAndEmpty ? (
        <TableIsFilteredAndEmpty resetFilters={resetFilters} />
      ) : (
        <TransactionIsSelectedTableIsEmpty resetFilters={resetFilters} />
      )}
    </TransactionsTableEmptyStateLayout>
  );
};

import React, { ComponentProps } from 'react';
import { Icon } from '../../Atoms/Icon/next';
import { IconKey } from '../../Particles/Spritemap';
import { Spinner } from '../Spinner';
import styles from './Tag.module.css';
import clsx from 'clsx';

export type Color =
  | 'gray'
  | 'green'
  | 'red'
  | 'yellow'
  | 'blue'
  | 'purple'
  | 'pink';

type Size = 'xsmall' | 'small' | 'large';
type Variant = 'primary' | 'secondary' | 'callout' | 'info';

export interface TagProps extends ComponentProps<'div'> {
  color?: Color;
  loading?: boolean;
  variant?: Variant;
  size?: Size;
  icon?: IconKey;
}

/**
 * Tag component displays a given status.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-feedback-tag}
 *
 * @param {Color} color Color type
 * @param {boolean} loading Loading state
 * @param {Variant} variant Variant type, default is 'primary'
 * @param {Size} size Size type, default is 'small'
 * @param {IconKey} icon Icon type
 */
export const Tag = React.forwardRef<HTMLDivElement, TagProps>(
  (
    {
      children,
      color = 'gray',
      loading,
      icon,
      variant = 'primary',
      size = 'small',
      className,
      ...restProps
    },
    ref
  ) => {
    return (
      <span
        className={clsx(styles.tag, className)}
        data-variant={variant}
        data-size={size}
        data-color={color}
        ref={ref}
        {...restProps}
      >
        {loading && <Spinner />}
        {icon && <Icon icon={icon} />}
        {children}
      </span>
    );
  }
);

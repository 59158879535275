import { Button } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { isMoreThan90Days } from 'views/Reimbursement/utils/isMoreThan90Days';
import { isLessThan8Hours } from 'views/Reimbursement/utils/isLessThan8Hours';
import { useGetPerDiemSegmentValues } from 'views/Reimbursement/hooks/useGetPerDiemSegmentValues';

export type PerDiemSegmentActionsProps = {
  isAddSegmentLoading: boolean;
  isUpdatePerDiemStepPending: boolean;
  onAddSegmentClick: () => void;
  onCalculateAllowanceClick: (index: number) => Promise<void>;
  onSetTravelLengthError: (value: string | null) => void;
  formMethods: UseFormReturn<ReimbursementItemsFormOutput>;
  formIndex: number;
  segmentsLength: number;
};

interface TravelLengthUtilParams {
  startDateTravel: string | null | undefined;
  startTimeTravel: string | null | undefined;
  endDateTravel: string | null | undefined;
  endTimeTravel: string | null | undefined;
}

const isTravelLessThan8Hours = ({
  endDateTravel,
  endTimeTravel,
  startDateTravel,
  startTimeTravel,
}: TravelLengthUtilParams) => {
  if (
    !startDateTravel ||
    !startTimeTravel ||
    !endDateTravel ||
    !endTimeTravel
  ) {
    return;
  }

  const startingDateTime = new Date(`${startDateTravel},${startTimeTravel}`);
  const endingDateTime = new Date(`${endDateTravel},${endTimeTravel}`);

  if (isLessThan8Hours(startingDateTime, endingDateTime)) {
    return true;
  }

  return false;
};

const isTravelMoreThan90Days = ({
  endDateTravel,
  endTimeTravel,
  startDateTravel,
  startTimeTravel,
}: TravelLengthUtilParams) => {
  if (
    !startDateTravel ||
    !startTimeTravel ||
    !endDateTravel ||
    !endTimeTravel
  ) {
    return;
  }

  const startingDateTime = new Date(`${startDateTravel},${startTimeTravel}`);
  const endingDateTime = new Date(`${endDateTravel},${endTimeTravel}`);

  if (isMoreThan90Days(startingDateTime, endingDateTime)) {
    return true;
  }

  return false;
};

export const PerDiemSegmentActions = ({
  isAddSegmentLoading,
  isUpdatePerDiemStepPending,
  onAddSegmentClick,
  onCalculateAllowanceClick,
  onSetTravelLengthError,
  formMethods,
  formIndex,
  segmentsLength,
}: PerDiemSegmentActionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { trigger } = formMethods;

  const getPerDiemSegmentValues = useGetPerDiemSegmentValues({
    formIndex,
    formMethods,
    segmentsLength,
  });

  const isSegmentsValid = async () => {
    const isValid = await trigger(`reimbursementItems.${formIndex}.segments`);

    if (!isValid) {
      return false;
    }

    return true;
  };

  const handleAddSegment = async () => {
    const formValues = getPerDiemSegmentValues();

    const isMoreThan90Days = isTravelMoreThan90Days({ ...formValues });
    if (isMoreThan90Days) {
      onSetTravelLengthError(
        t(
          'reimbursementView.middleSection.form.perDiem.customErrorMessages.travelNeedsToBeMax90Days'
        )
      );
      return;
    }
    onSetTravelLengthError(null);
    const isAllSegmentsValid = await isSegmentsValid();

    if (!isAllSegmentsValid) {
      return;
    }

    onAddSegmentClick();
  };

  const handleCalculateAllowance = async () => {
    const formValues = getPerDiemSegmentValues();

    const isLessThan8Hours = isTravelLessThan8Hours({ ...formValues });
    if (isLessThan8Hours) {
      onSetTravelLengthError(
        t(
          'reimbursementView.middleSection.form.perDiem.customErrorMessages.travelNeedsToBe8Hours'
        )
      );
      return;
    }
    onSetTravelLengthError(null);

    const isMoreThan90Days = isTravelMoreThan90Days({ ...formValues });
    if (isMoreThan90Days) {
      onSetTravelLengthError(
        t(
          'reimbursementView.middleSection.form.perDiem.customErrorMessages.travelNeedsToBeMax90Days'
        )
      );
      return;
    }
    onSetTravelLengthError(null);
    const isAllSegmentsValid = isSegmentsValid();
    if (!isAllSegmentsValid) {
      return;
    }

    await onCalculateAllowanceClick(formIndex);
  };

  return (
    <div className="flex justify-between gap-4 pb-2 px-6">
      <Button
        onClick={handleAddSegment}
        icon="plus"
        variant="tertiary"
        size="medium"
        loading={isAddSegmentLoading}
        disabled={isAddSegmentLoading || isUpdatePerDiemStepPending}
      >
        {t('reimbursementView.middleSection.form.perDiem.ctaAddSegement')}
      </Button>

      <Button
        onClick={handleCalculateAllowance}
        iconPosition="right"
        icon="arrowRight"
        variant="secondary"
        size="medium"
        loading={isUpdatePerDiemStepPending}
        disabled={isAddSegmentLoading || isUpdatePerDiemStepPending}
      >
        {t(
          'reimbursementView.middleSection.form.perDiem.ctaCalculateAllowance'
        )}
      </Button>
    </div>
  );
};

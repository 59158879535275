import { AddNewActionBox } from 'components/AddNewActionBox/AddNewActionBox';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { useTranslation } from 'react-i18next';

interface AddContactActionBoxProps {
  onClick?: () => void;
  addContactLabel?: string;
  newContactTitle?: string;
  newContactDescription?: string;
  sapIsActive: boolean;
}

export function AddContactActionBox({
  newContactTitle,
  newContactDescription,
  sapIsActive,
  addContactLabel,
  onClick,
}: AddContactActionBoxProps) {
  const [t] = useTranslation();
  return (
    <AddNewActionBox background="gray0">
      <AddNewActionBox.Content>
        {newContactTitle && (
          <AddNewActionBox.Header>
            <AddNewActionBox.HeaderText>
              {newContactTitle}
            </AddNewActionBox.HeaderText>
          </AddNewActionBox.Header>
        )}
        <AddNewActionBox.Message>
          {newContactDescription ??
            t('document.requestApproval.newContact.description')}
        </AddNewActionBox.Message>
      </AddNewActionBox.Content>
      {sapIsActive ? (
        <SyncFromSap type={SAP_SYNC.ContactsProcessing} />
      ) : (
        <AddNewActionBox.Button onPointerDown={onClick}>
          {addContactLabel ?? t('document.requestApproval.newContact.add')}
        </AddNewActionBox.Button>
      )}
    </AddNewActionBox>
  );
}

import { ReactNode, useMemo } from 'react';
import { usePerDiemLocations } from 'views/Reimbursement/hooks/usePerDiemLocations';

export type PerDiemLocationItem = {
  key: string;
  children: ReactNode;
  textValue: string;
  countryIsoCode: string;
};

interface OptionProps {
  endDate: string;
}

export const usePerDiemLocationFieldOptions = ({ endDate }: OptionProps) => {
  const { locations, isLoading } = usePerDiemLocations({ endDate });

  // unique region names
  const groupHeaders = useMemo(
    () => [...new Set(locations.map(location => location.region))],
    [locations]
  );

  const locationToItem = useMemo(() => {
    return locations.map(
      ({ id, country, location: locationName, countryIsoCode }) => {
        const displayText = locationName
          ? `${country}, ${locationName}`
          : country;
        return {
          key: id,
          children: displayText,
          textValue: displayText,
          countryIsoCode,
        };
      }
    );
  }, [locations]);

  // group locations by region
  const groupedLists = useMemo(() => {
    const grouped = locations.reduce(
      (acc, location) => {
        const { region } = location;
        if (!acc[region]) {
          acc[region] = [];
        }

        const index = locationToItem.findIndex(
          item => item.key === location.id
        );
        if (index !== -1) {
          acc[region].push(locationToItem[index]);
        }

        return acc;
      },
      {} as Record<string, PerDiemLocationItem[]>
    );

    return Object.values(grouped);
  }, [locations, locationToItem]);

  return {
    defaultItems: locationToItem,
    groupedLists,
    groupHeaders,
    isLoading,
  };
};

import styles from './SwimlaneTileAmount.module.css';
import clsx from 'clsx';
import { HTMLProps } from 'react';

type SwimlaneTileAmountProps = HTMLProps<HTMLDivElement> & {
  variant?: 'blue' | 'green' | 'gray';
};

export const SwimlaneTileAmount = ({
  variant = 'gray',
  className,
  ...otherProps
}: SwimlaneTileAmountProps) => {
  const classes = clsx(styles.amount, className, {
    [styles['amount--blue']]: variant === 'blue',
    [styles['amount--green']]: variant === 'green',
  });

  return <p className={classes} {...otherProps} />;
};

import { queryParameter } from 'components/Table/consts';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { DownloadMenuButton } from '../shared/DownloadMenuButton';
import { useDownloadReimbursementsCSV } from './toolkit/hooks/useDownloadReimbursementsCSV';

interface DownloadMenuButtonReimbursementsContainerProps {
  selectedReimbursementsCount: number;
  isTableLoading: boolean;
  isTableFiltered: boolean;
}

export const DownloadMenuButtonReimbursementsContainer = ({
  isTableFiltered,
  isTableLoading,
  selectedReimbursementsCount,
}: DownloadMenuButtonReimbursementsContainerProps) => {
  const { searchParams } = useMutateSearchParams();

  const { handleCSVDownload, isHandleCSVDownloadPending } =
    useDownloadReimbursementsCSV({
      isTableLoading,
    });
  const isMenuButtonDisabled = isTableLoading || !selectedReimbursementsCount;

  const hasSearchQuery = searchParams.has(queryParameter);

  return (
    <DownloadMenuButton
      isDisabled={isMenuButtonDisabled}
      isTableFiltered={isTableFiltered}
      isTableSearched={hasSearchQuery}
      count={selectedReimbursementsCount}
      isLoading={isTableLoading || isHandleCSVDownloadPending}
      onClick={handleCSVDownload}
    />
  );
};

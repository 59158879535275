import { Button, Card, Grid, Paragraph } from '@candisio/design-system';
import { Routes } from 'models';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';

export const BankAccountCTA = () => {
  const [t] = useTranslation('payments');
  const navigate = useNavigate();
  const { organizationSlug } = useParams();

  return (
    <Card corners="bottom" background="gray100" padding="space16">
      <Grid gap="space8">
        <Paragraph fontSize="basic">{t('details.ibanCTA.title')}</Paragraph>
        <Paragraph>{t('details.ibanCTA.description')}</Paragraph>
        <Button
          onClick={() => {
            if (!organizationSlug) {
              return;
            }

            const path = generatePath(
              `/:organizationSlug${Routes.SETTINGS}${Routes.PAYMENTS}`,
              { organizationSlug }
            );

            navigate(path);
          }}
          variant="secondary"
        >
          {t('details.ibanCTA.addBankAccount')}
        </Button>
      </Grid>
    </Card>
  );
};

import { Tag } from '@candisio/design-system';
import { TruncatedText } from '@candisio/design-system/next';
import { CellContext } from '@tanstack/react-table';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const PaymentStatusCell = ({
  getValue,
}: CellContext<DocumentsTableData, DocumentsTableData['paymentStatus']>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const value = getValue();
  if (!(value === 'PaidDocumentState'))
    return <span>{t('emptyCellPlaceholder')}</span>;

  return (
    <Tag color="green" variant="secondary" className="w-fit">
      <TruncatedText>{t('isPaid.paid')}</TruncatedText>
    </Tag>
  );
};

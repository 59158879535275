import { useApolloClient } from '@apollo/client';
import { Grid, Paragraph, Text } from '@candisio/design-system';
import { FileDropZone } from 'components/FileDropZone/FileDropZone';
import { useAcceptedFileTypesForInvoice } from 'components/FileDropZone/useAcceptedFileTypesForInvoice';
import { RouterLink } from 'components/RouterLink/RouterLink';
import {
  DocumentType,
  useAddFileUploadMutation,
  useDocumentFileUploadUrlMutation,
  useFileUploadsQuery,
  useProcessDocumentMutation,
  useProcessEcmDocumentMutation,
  useProcessFileMutation,
  useUpdateFileUploadMutation,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { AppRouteParams, Routes } from 'models';
import { useDocumentUploadState } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { ChangeEvent, DragEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { tabViewToUrlMap } from 'views/Archive/components/utils';
import { delayRefetchedSensitiveContractsQueries } from 'views/Inbox/DocumentProcessing/components/Ecm/delayRefetchedSensitiveContractsQueries';
import {
  getEcmSensitiveDocumentsCountQuery,
  getEcmSensitiveDocumentsQuery,
} from 'views/Inbox/EcmSensitiveDocuments/gql';
import { List, useGetRefetchListQueries } from 'views/queries';
import { FileUploadsList } from './FileUploadsList';
import { fileUploadUtil } from './fileUploadUtil';

interface DocumentUploadModalContentProps {
  onModalClose: () => void;
}

export const DocumentUploadModalContent = ({
  onModalClose,
}: DocumentUploadModalContentProps) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const client = useApolloClient();
  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();
  const { isSensitive } = useDocumentUploadState();
  const navigate = useNavigate();

  const [addFile] = useAddFileUploadMutation();
  const [documentFileUploadUrl] = useDocumentFileUploadUrlMutation();
  const [processDocument] = useProcessDocumentMutation();
  const [processFile] = useProcessFileMutation();
  const [processEcmDocument] = useProcessEcmDocumentMutation();
  const [updateFile] = useUpdateFileUploadMutation();
  const counterQueries = useCounterQueries();
  const queriesToRefetch = useGetRefetchListQueries(List.INBOX_TABLE);
  const {
    data: { fileUploads = [] } = {},
  } = useFileUploadsQuery();

  const [llmClassificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.llmClassification,
  ]);

  const supportedFilesDescription = isSensitive
    ? t('documentUploadModal.uploadSensitiveContracts.supportedFiles')
    : t('documentUploadModal.supportedFiles');

  const isXmlDocumentUploadAllowed = !isSensitive;

  const switchToContractsTab = () => {
    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${tabViewToUrlMap.INBOX_ECM_SENSITIVE_DOCUMENTS}`,
      { organizationSlug }
    );

    // ensure document is extracted and shows up in contracts table
    setTimeout(() => navigate(pathname), 1500);
  };

  const onUploadFile = async (file: File) => {
    await fileUploadUtil(file, isXmlDocumentUploadAllowed, {
      addFile,
      documentFileUploadUrl,
      updateFile,
      onUploaded: async fileId => {
        if (isSensitive) {
          const { data: processRes, errors } = await processEcmDocument({
            variables: {
              input: {
                fileId: fileId,
                documentType: DocumentType.Contract,
                isSensitive: true,
              },
            },
            refetchQueries: [
              getEcmSensitiveDocumentsQuery,
              getEcmSensitiveDocumentsCountQuery,
            ],
            onCompleted: switchToContractsTab,
            onQueryUpdated: delayRefetchedSensitiveContractsQueries,
          });

          if (errors) {
            throw new Error(errors[0].message);
          }

          return { documentId: processRes?.processEcmDocument?.ecmDocumentId };
        }

        if (llmClassificationFF) {
          const { data: processFileResponse, errors } = await processFile({
            variables: {
              input: {
                fileId,
              },
            },
            refetchQueries: [...queriesToRefetch, ...counterQueries],
            onCompleted() {
              client.cache.evict({
                fieldName: 'listInboxDocuments',
              });
            },
          });

          if (errors) {
            throw new Error(errors[0].message);
          }

          return {
            documentId: processFileResponse?.processFile?.globalDocumentId,
          };
        }

        // TODO: Remove this block after removing the LLM Classification feature flag
        const { data: processRes, errors } = await processDocument({
          variables: {
            fileId: fileId,
          },
          refetchQueries: [...queriesToRefetch, ...counterQueries],
          onCompleted() {
            client.cache.evict({
              fieldName: 'listInboxDocuments',
            });
          },
        });

        if (errors) {
          throw new Error(errors[0].message);
        }

        return { documentId: processRes?.processDocument?.id };
      },
    });
  };

  const onFilesSelected = (
    _: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
    selectedFiles: File[]
  ) => {
    selectedFiles.forEach(onUploadFile);

    if (fileUploadRef.current) {
      fileUploadRef.current.value = '';
    }
  };

  const mailLink = generatePath(
    `/:organizationSlug${Routes.SETTINGS}${Routes.MAIL_SYNC}`,
    { organizationSlug }
  );

  const acceptedFileTypes = useAcceptedFileTypesForInvoice({
    isXmlDocumentUploadAllowed: !isSensitive,
  });

  return (
    <Grid rowGap="space16" paddingTop="space14">
      {isSensitive && (
        <Paragraph color="gray500" fontSize="small">
          {t('ecm:documentUploadModal.description')}
        </Paragraph>
      )}

      <FileDropZone
        onFilesSelected={onFilesSelected}
        accept={acceptedFileTypes}
        isXmlDocumentUploadAllowed={!isSensitive}
        icon={isSensitive ? 'eyeLock' : 'upload'}
        ref={fileUploadRef}
      >
        <Paragraph>
          <Trans
            i18nKey="documentUploadModal.browse"
            tOptions={{ context: isSensitive && 'contracts' }}
          >
            <Text fontWeight="bold">Browse</Text>
            to upload invoices.
          </Trans>
        </Paragraph>
        <Paragraph>
          <Trans i18nKey="documentUploadModal.dragAndDrop">
            or
            <Text fontWeight="bold">Drag & Drop</Text>
          </Trans>
        </Paragraph>
      </FileDropZone>

      <Paragraph color="gray500" fontSize="small">
        {supportedFilesDescription}
        {!isSensitive && (
          <>
            <br />
            <Trans i18nKey="documentUploadModal.emailImport">
              You can also
              <RouterLink to={mailLink} onClick={onModalClose}>
                import documents using the E-Mail import.
              </RouterLink>
            </Trans>
          </>
        )}
      </Paragraph>
      {fileUploads.length > 0 && (
        <FileUploadsList
          fileUploads={fileUploads}
          isSensitive={isSensitive}
          onModalClose={onModalClose}
        />
      )}
    </Grid>
  );
};

import {
  Grid,
  Icon,
  TextField,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { isNil } from 'lodash';
import { motion } from 'motion/react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

const MotionGrid = motion.create(Grid);

export interface SearchFieldProps {
  autoFocus?: boolean;
  clearTooltip?: string;
  inputMaxLength?: number;
  inputSize?: number;
  placeholder: string;
  initialValue?: string;
  readOnly?: boolean;
  onChange: (filter: string) => void;
  /** Value of search input (controlled). Use only if you want to modify value outside of SearchField. */
  value?: string;
}

export const SearchField = ({
  autoFocus,
  clearTooltip,
  inputMaxLength,
  inputSize,
  placeholder,
  initialValue,
  readOnly = false,
  onChange,
  value: valueControlled,
}: SearchFieldProps) => {
  const [value, setValue] = useState(initialValue);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want the value to be also controlled from the outside `controlledValue`
  useEffect(() => {
    if (isNil(valueControlled)) return;

    if (value !== valueControlled) {
      setValue(valueControlled);
    }
  }, [valueControlled]);

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  const transition = {
    default: { duration: 0.25, ease: 'easeInOut' },
  };

  const variants = {
    visible: { opacity: 1, scale: 1, visibility: 'visible' },
    hidden: { opacity: 0, scale: 0.6, visibility: 'hidden' },
    hover: { cursor: 'pointer', color: 'rgba(43, 26, 46, 0.6)' },
  };

  const [t] = useTranslation();

  const handleOnChange = (newValue: string) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Grid alignItems="center" alignContent="center">
      <TextField
        aria-label={t('search.label')}
        readOnly={readOnly}
        input={{
          autoFocus,
          style: { maxWidth: inputSize ? 'auto' : '85%' },
          size: inputSize,
          placeholder: placeholder,
          readOnly: readOnly,
          value: value,
          onChange: e => {
            handleOnChange(e.target.value);
          },
          maxLength: inputMaxLength,
        }}
      />
      <MotionGrid
        alignContent="center"
        justifySelf="end"
        padding="space12 space16"
        position="absolute"
        animate={(value?.length ?? 0) === 0 ? 'visible' : 'hidden'}
        transition={transition}
        /** @ts-expect-error framer-motion upgrade type mismatch with react-aria */
        variants={variants}
      >
        <Icon icon="search" size="space16" />
      </MotionGrid>
      <MotionGrid
        as="button"
        type="button"
        aria-label={t('search.clear')}
        alignContent="center"
        justifySelf="end"
        background="transparent"
        border="none"
        opacity="0"
        padding="space12 space16"
        position="absolute"
        animate={(value?.length ?? 0) > 0 ? 'visible' : 'hidden'}
        whileHover="hover"
        transition={transition}
        /** @ts-expect-error framer-motion upgrade type mismatch */
        variants={variants}
        {...triggerProps}
        onClick={() => handleOnChange('')}
        ref={triggerRef}
      >
        <Icon icon="close" size="space16" />
      </MotionGrid>
      {clearTooltip && isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {clearTooltip}
        </Tooltip>
      )}
    </Grid>
  );
};

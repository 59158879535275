import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { WistiaModalLink } from 'components/WistiaModal/WistiaModalLink';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const Cards = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <InfoPanel
      title={t('provisions.infoPanel.title')}
      sections={t('provisions.infoPanel.sections', { returnObjects: true })}
      externalLink={t('provisions.infoPanel.externalLink', {
        returnObjects: true,
      })}
      buttonTrigger={
        <WistiaModalLink
          wistiaId={t('provisions.videoModal.wistiaId')}
          isLink={true}
        >
          {t('provisions.videoModal.label')}
        </WistiaModalLink>
      }
    />
  );
};

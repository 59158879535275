import { ReactNode } from 'react';
import styles from '../RightSection.module.css';
import clsx from 'clsx';

interface FormLayoutProps {
  children: ReactNode;
  readonly?: boolean;
}

export const FormLayout = ({ children, readonly }: FormLayoutProps) => {
  const variant = readonly ? 'readonly' : 'active';
  return (
    <form
      className={clsx(
        styles['reimbursement-form'],
        styles[`reimbursement-form--${variant}`]
      )}
    >
      {children}
    </form>
  );
};

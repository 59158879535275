import { DocumentType, FileUploadAttempt } from 'generated-types/graphql.types';
import { usePath } from 'hooks/usePath';
import { Paths } from 'utils/generateTypesafePath.types';

export const useUploadItemPath = (item: FileUploadAttempt) => {
  const pathname: Paths = '/archive/stored_documents/:documentId';
  let documentId = item.globalDocumentId as string;
  let search;

  if (item.documentType === DocumentType.Invoice) {
    documentId = item.documentId as string;
    search = '?isInvoice=true';
  }

  return usePath({
    pathname,
    params: {
      documentId,
    },
    search,
  });
};

import styles from '../DaysStep.module.css';
import { Day } from '../Day';
import { ReimbursementItemFormPerDiemDay } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import stylesReducedDays from './ReducedDays.module.css';
import { Icon } from '@candisio/design-system/next';

interface CollapsibleDaysListProps {
  formIndex: number;
  collapsibleDays: (ReimbursementItemFormPerDiemDay & { id: string })[];
  isMiddleDaysHidden: boolean;
  onUpdatePerDiemDays: (index: number) => () => Promise<void>;
  onSetIsMiddleDayHidden: (value: boolean) => void;
}

export const CollapsibleDaysList = ({
  formIndex,
  collapsibleDays,
  isMiddleDaysHidden,
  onUpdatePerDiemDays,
  onSetIsMiddleDayHidden,
}: CollapsibleDaysListProps) => {
  return (
    <li
      className={stylesReducedDays['fade-slide-in']}
      data-visible={!isMiddleDaysHidden}
    >
      <ol className={styles['grouped-day-list']}>
        <li
          className={styles['day-item']}
          onClick={e => {
            e.stopPropagation();
            onSetIsMiddleDayHidden(true);
          }}
        >
          <Icon icon="caretDown" className="h-5 w-5" />
        </li>
        {collapsibleDays.map((day, index) => {
          return (
            <Day
              key={day.id}
              date={day.date}
              formIndex={formIndex}
              dayIndex={index + 1}
              onUpdatePerDiemDays={onUpdatePerDiemDays}
            />
          );
        })}
        <li
          className={styles['day-item']}
          onClick={e => {
            e.stopPropagation();
            onSetIsMiddleDayHidden(true);
          }}
        >
          <Icon icon="caretUp" className="h-5 w-5" />
        </li>
      </ol>
    </li>
  );
};

import { Spinner } from '@candisio/design-system';
import { FileState } from 'generated-types/graphql.types';
import { useHasProcessingState } from '../hooks/useHasProcessingState';
import { usePendingFileUploads } from '../hooks/usePendingFileUploads';

export const MenuSpinner = () => {
  const hasProcessingStatus = useHasProcessingState();
  const pendingUploads = usePendingFileUploads([FileState.Uploading]);

  if (!hasProcessingStatus && pendingUploads.length === 0) {
    return null;
  }

  return <Spinner color="currentColor" size={18} />;
};

import { Text } from '@candisio/design-system';
import { AccountingAreaUpdatedEvent } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export const DocumentAccountingAreaUpdatedEventComponent = ({
  accountingAreaName,
}: AccountingAreaUpdatedEvent) => {
  const [t] = useTranslation();

  if (!accountingAreaName)
    return (
      <Text>
        {t('document.compact.history.documentAccountingArea.removed')}
      </Text>
    );

  return (
    <Text>
      {t('document.compact.history.documentAccountingArea.updated', {
        accountingAreaName,
      })}
    </Text>
  );
};

import { TruncatedText } from '@candisio/design-system/next';
import { castArray } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentsTableData } from '../../types';
import { CellContext } from '@tanstack/react-table';

type Value = string | number | [string, ...string[]];

type CellProps = CellContext<DocumentsTableData, Value>;

export const Cell = ({ getValue, column }: CellProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const [tCommon] = useTranslation();
  const value = getValue();

  if (!value) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  const values = castArray(value);

  if (values.every(v => !v)) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  const allValuesAreTheSame = values.every(v => v === values[0]);

  if (values.length > 1 && !allValuesAreTheSame) {
    return (
      <TruncatedText className="text-gray-600 italic">
        {t('seeSplitBookings')}
      </TruncatedText>
    );
  }

  const cellContent =
    column.id === 'exportMethod'
      ? tCommon(`export.table.columns.exportMethod.methods.${value}`)
      : String(values[0]);

  return <TruncatedText className="max-w-[50ch]">{cellContent}</TruncatedText>;
};

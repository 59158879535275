import {
  Button,
  Flex,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Text,
} from '@candisio/design-system';

import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  CardIssuerTransactionInvoiceNotNeededReason,
  TransactionAmount,
  useSetTransactionInvoiceNotNeededMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Dispatch, SetStateAction, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import {
  getRefetchCardIssuerTransaction,
  getRefetchTransactionHistory,
} from 'views/TransactionAssociation/gql';
import { noInvoiceNeededOptions } from './util';
import {
  FormValues,
  NoInvoiceNeededFormSchemaOption,
  formErrorMessages,
  formSchema,
} from './validation';

interface NoInvoiceNeededFormProps {
  loading: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
  selectedValue: CardIssuerTransactionInvoiceNotNeededReason;
  setSelectedValue: Dispatch<
    SetStateAction<CardIssuerTransactionInvoiceNotNeededReason>
  >;
}

export const NoInvoiceNeededForm = ({
  loading,
  onClose,
  onSubmit,
  selectedValue,
  setSelectedValue,
}: NoInvoiceNeededFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const organizationSlug = useOrganizationId();

  const form = useForm<FormValues, NoInvoiceNeededFormSchemaOption>({
    mode: 'onBlur',
    shouldUnregister: true,
    resolver: zodResolver({
      zodSchema: formSchema(selectedValue),
      errorMessages: formErrorMessages(t),
    }),
  });

  return (
    <FormProvider {...form}>
      <Grid gap="space10" as="form" onSubmit={form.handleSubmit(onSubmit)}>
        <Text fontWeight="semibold" fontSize="large">
          {t('transactionAssociation.noInvoiceNeeded.popover.title')}
        </Text>
        <Text color="gray600" fontSize="small">
          {t('transactionAssociation.noInvoiceNeeded.popover.description')}{' '}
          <Link
            href={`/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}`}
            external
          >
            {t('transactionAssociation.noInvoiceNeeded.popover.link')}
          </Link>
        </Text>
        <RadioGroup
          aria-label="no invoice needed"
          defaultValue={
            CardIssuerTransactionInvoiceNotNeededReason.NeedsNoInvoice
          }
          onChange={value => {
            setSelectedValue(
              value as CardIssuerTransactionInvoiceNotNeededReason
            );
          }}
        >
          {noInvoiceNeededOptions.map(({ message, value }) => (
            <Radio key={message} value={value}>
              <Text fontSize="small">{t(message)}</Text>
            </Radio>
          ))}
        </RadioGroup>
        {selectedValue === CardIssuerTransactionInvoiceNotNeededReason.Other ? (
          <HookFormTextField<FormValues>
            name="otherReason"
            label=""
            autoFocus
            key={CardIssuerTransactionInvoiceNotNeededReason.Other}
            placeholder={t(
              'transactionAssociation.noInvoiceNeeded.popover.fieldPlaceholder'
            )}
          />
        ) : null}
        <Flex justifyContent="start" gap="space10" paddingTop="space16">
          <Button
            size="small"
            variant="secondary"
            onClick={onClose}
            disabled={loading}
          >
            {t('transactionAssociation.noInvoiceNeeded.popover.cancel')}
          </Button>
          <Button
            type="submit"
            size="small"
            loading={loading}
            disabled={loading}
          >
            {t('transactionAssociation.noInvoiceNeeded.popover.saveAndExport')}
          </Button>
        </Flex>
      </Grid>
    </FormProvider>
  );
};

export const NoInvoiceNeededContainer = ({
  onClose,
  txId,
  cycleTransaction,
}: Pick<NoInvoiceNeededFormProps, 'onClose'> & {
  txId?: string;
  transactionAmount?: TransactionAmount;
  cycleTransaction?: () => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();
  const counterQueries = useCounterQueries();
  const organizationSlug = useOrganizationId();

  const [selectedValue, setSelectedValue] =
    useState<CardIssuerTransactionInvoiceNotNeededReason>(
      CardIssuerTransactionInvoiceNotNeededReason.NeedsNoInvoice
    );

  const transactionId = txId ?? '';

  const [setTransactionInvoiceNotNeeded, { loading }] =
    useSetTransactionInvoiceNotNeededMutation();

  const handleSubmit = async ({ otherReason }: FormValues) => {
    const response = await setTransactionInvoiceNotNeeded({
      variables: {
        input: {
          transactionId,
          reason: selectedValue,
          comment: otherReason,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        ...counterQueries,
        ...getRefetchTransactionHistory(transactionId),
        ...getRefetchCardIssuerTransaction(transactionId),
      ],
      onCompleted: () => onClose(),
    });

    if (!response.errors?.length) {
      const content = (
        <Trans
          t={t}
          i18nKey="transactionAssociation.noInvoiceNeeded.popover.movedToExportsuccessMsg"
        >
          <Link
            external
            href={`/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}/${transactionId}`}
          >
            Transaction
          </Link>
          was successfully moved to export
        </Trans>
      );

      success(content);
      cycleTransaction?.();
    } else {
      error(
        t(
          'transactionAssociation.noInvoiceNeeded.popover.movedFromExportFailureMsg'
        )
      );
    }
  };

  return (
    <NoInvoiceNeededForm
      onClose={onClose}
      loading={loading}
      onSubmit={handleSubmit}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
    />
  );
};

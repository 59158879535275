import { Tag } from '@candisio/design-system';
import { TruncatedText } from '@candisio/design-system/next';
import { CellContext } from '@tanstack/react-table';
import { mapProvisionStatusToColors } from 'components/DocumentsTable/DocumentsTable';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider/consts';
import { useTranslation } from 'react-i18next';

export const ProvisionStatusCell = ({
  getValue,
}: CellContext<DocumentsTableData, DocumentsTableData['provisionStatus']>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  const value = getValue();

  if (!value) return null;

  return (
    <Tag
      color={mapProvisionStatusToColors[value]}
      variant="secondary"
      className="w-fit"
    >
      <TruncatedText>{t(`statuses.${value.toLowerCase()}`)}</TruncatedText>
    </Tag>
  );
};

import { DocumentCurrency } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { DateFormatters } from 'utils/date_formatter';
import { ExtractedData } from './invoiceDataExtractionHelpers';

export const extractedDataSummary = (data?: ExtractedData) => {
  const { transactionAmountFormatter } = useMoneyFormatter();

  return {
    invoiceNumber: data?.invoiceNumber ?? undefined,
    expenseDate: data?.expenseDate
      ? DateFormatters.compact(new Date(data.expenseDate))
      : undefined,
    totalAmount: !isNil(data?.totalAmount)
      ? transactionAmountFormatter({
          value: data.totalAmount,
          currency: DocumentCurrency.Eur,
          precision: 0,
        })
      : undefined,
  };
};

export const extractedDataHospitalitySummary = (
  totalAmount?: number | null,
  data?: ExtractedData
) => {
  const { transactionAmountFormatter } = useMoneyFormatter();

  return {
    invoiceNumber: data?.invoiceNumber ?? undefined,
    location: data?.location ?? undefined,
    expenseDate: data?.expenseDate
      ? DateFormatters.compact(new Date(data.expenseDate))
      : undefined,
    receiptAmount: !isNil(data?.receiptAmount)
      ? transactionAmountFormatter({
          value: data.receiptAmount,
          currency: DocumentCurrency.Eur,
          precision: 0,
        })
      : undefined,
    tipAmount: !isNil(data?.tipAmount)
      ? transactionAmountFormatter({
          value: data.tipAmount,
          currency: DocumentCurrency.Eur,
          precision: 0,
        })
      : undefined,
    totalAmount:
      !isNil(data?.receiptAmount) || !isNil(data?.tipAmount)
        ? transactionAmountFormatter({
            value: totalAmount ?? 0,
            currency: DocumentCurrency.Eur,
            precision: 0,
          })
        : undefined,
  };
};

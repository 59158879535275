import { Grid } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { InfoPopupButton } from 'components/Icons/InfoPopupButton';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const BillingEmailAddress = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid templateColumns="1fr auto" alignItems="center" gap="space4">
      <HookFormTextField
        name="billingEmailAddress"
        label={t(
          'insights.organizationSettingsDrawer.form.fields.billingEmailAddress.label'
        )}
      />
      <div className="pt-4">
        <InfoPopupButton
          message={t(
            'insights.organizationSettingsDrawer.form.fields.billingEmailAddress.tooltip'
          )}
        />
      </div>
    </Grid>
  );
};

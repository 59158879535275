import { Icon, IconKey } from '@candisio/design-system';
import { ReactNode } from 'react';
import styles from './Item.module.css';

type Props = { children: ReactNode; icon: IconKey; onClick: () => void };

export const CardItem = ({ children, icon, onClick }: Props) => {
  return (
    <button className={styles.item} onClick={onClick}>
      <div className={styles.item__wrapper}>
        <Icon className="text-gray-600" icon={icon} size="space20" />
        <div className={styles.item__content}>{children}</div>
      </div>
      <Icon icon="arrowRight" size="space16" />
    </button>
  );
};

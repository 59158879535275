import { useSearchInput } from 'components/AdvancedSearchFieldFilterOverlay/useSearchInput';
import { queryDebouncedParameter } from 'components/Table/consts';
import {
  EcmHighlightableFields,
  GetEcmDocumentsQuery as GetEcmDocumentsQueryGql,
} from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { DebouncedFunc, debounce } from 'lodash';
import {
  EcmDocumentsPaginationParams,
  useEcmPagination,
} from 'providers/GraphQLProvider/Pagination/useEcmPagination';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { GetEcmDocumentsQuery } from 'views/Archive/EcmDocuments/queries';
import { EcmDocumentsTableData } from '../types';
import { parseHighlight } from 'components/WithHighlightsCell/parseHighlight';

export interface UseEcmDocumentsDataReturn {
  data: EcmDocumentsTableData[];
  handleDebounceSearch: DebouncedFunc<Dispatch<SetStateAction<string>>>;
  hasNoData: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
  selectedDocumentsCount: number;
}

export interface UseEcmDocumentsDataParams {
  params?: Omit<EcmDocumentsPaginationParams, 'search'>;
  initialSearchQuery?: string;
  filterParamsSource?: 'local' | 'url';
  queryOptions?: Parameters<
    typeof useEcmPagination<GetEcmDocumentsQueryGql, any>
  >['2'];
  previewDocumentId?: string | null;
}

/**
 * Fetches ECM documents and maps to EcmDocumentsTableData
 */
export const useEcmDocumentsData = ({
  params,
  initialSearchQuery = '',
  filterParamsSource,
  queryOptions,
  previewDocumentId,
}: UseEcmDocumentsDataParams): UseEcmDocumentsDataReturn => {
  const [search, setSearch] = useSearchParams();
  const [debounceSearchValue, setDebounceSearchValue] =
    useState(initialSearchQuery);

  const { searchInputVariable } = useSearchInput();

  const {
    data,
    loading: isLoading,
    onLoadMore,
  } = useEcmPagination<GetEcmDocumentsQueryGql, any>(
    GetEcmDocumentsQuery,
    'getEcmDocuments',
    {
      variables: {
        search: debounceSearchValue,
        searchInput: searchInputVariable,
        ...params,
      },
      ...queryOptions,
    }
  );

  if (
    filterParamsSource === 'url' &&
    search.get(queryDebouncedParameter) !== debounceSearchValue
  ) {
    search.set(queryDebouncedParameter, debounceSearchValue);
    setSearch(search, { replace: true });
  }

  const handleDebounceSearch = useMemo(() => {
    return debounce(setDebounceSearchValue, DEFAULT_DEBOUNCE_TIME, {
      trailing: true,
    });
  }, []);

  const ecmDocumentsList = data?.getEcmDocuments;

  const hasNoData = !isLoading && ecmDocumentsList?.edges?.length === 0;

  const documentList = useMemo(
    () =>
      (ecmDocumentsList?.edges ?? []).map(
        ({
          record,
          cursor,
          highlights: highlightsResponse,
        }): EcmDocumentsTableData => {
          const highlights = parseHighlight<keyof EcmDocumentsTableData>(
            highlightsResponse ?? [],
            highlightFieldToColumIdMapper
          );
          return {
            cursor,
            id: record?.id ?? '',
            globalDocumentId: record?.globalDocumentId ?? '',
            documentType: record?.documentType ?? '',
            contact: record?.contactName ?? '',
            invoiceId: record?.invoiceId ?? '',
            notes: record?.notes ?? '',
            documentName: record?.documentName ?? '',
            documentNumber: record?.documentNumber ?? '',
            documentDate: record?.documentDate
              ? new Date(record?.documentDate)
              : undefined,
            documentStatus: {
              status: record?.documentStatus ?? undefined,
              isSensitive: record?.isSensitive ?? false,
              isEInvoice: record?.isEInvoice ?? false,
            },
            tags: record?.tags ?? [],
            selected: previewDocumentId
              ? record?.id === previewDocumentId ||
                record?.invoiceId === previewDocumentId
              : false,
            highlights,
          };
        }
      ),
    [ecmDocumentsList?.edges, previewDocumentId]
  );

  const selectedDocumentsCount = ecmDocumentsList?.pageInfo?.totalCount ?? 0;

  return {
    data: documentList,
    handleDebounceSearch,
    hasNoData,
    isLoading,
    onLoadMore,
    selectedDocumentsCount,
  };
};

const highlightFieldToColumIdMapper: Partial<
  Record<EcmHighlightableFields, keyof EcmDocumentsTableData>
> = {
  [EcmHighlightableFields.ContactName]: 'contact',
  [EcmHighlightableFields.DocumentName]: 'documentName',
  [EcmHighlightableFields.DocumentNumber]: 'documentNumber',
  [EcmHighlightableFields.Notes]: 'notes',
  [EcmHighlightableFields.RawContent]: 'rawContentHighlight',
};

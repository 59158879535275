import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { ReimbursementItemsSplitBookingsContainer } from './ReimbursementItemsSplitBookingsContainer';
import { BasicReimbursementItemsSplitBookingsContainer } from './BasicReimbursementItemsSplitBookingsContainer';

interface ReimbursementBookingsListSectionProps {
  isLoading: boolean;
  isCurrentUserCaseApprover: boolean;
  caseStatus?: ReimbursementCaseStatus;
}

export const ReimbursementBookingsListSection = ({
  isLoading,
  isCurrentUserCaseApprover,
  caseStatus,
}: ReimbursementBookingsListSectionProps) => {
  if (caseStatus === ReimbursementCaseStatus.Draft) {
    return (
      <BasicReimbursementItemsSplitBookingsContainer
        isLoading={isLoading}
        reimbursementCaseStatus={caseStatus}
        isCurrentUserCaseApprover={isCurrentUserCaseApprover}
      />
    );
  }

  return (
    <ReimbursementItemsSplitBookingsContainer
      isLoading={isLoading}
      reimbursementCaseStatus={caseStatus}
      isCurrentUserCaseApprover={isCurrentUserCaseApprover}
    />
  );
};

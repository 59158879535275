import { Paragraph, Text } from '@candisio/design-system';
import { Collapse } from 'components/History/items/Collapse';
import {
  ExpenseItemGeneralDataExtractedEventDataFragment,
  ExpenseItemHospitalityDataExtractedEventDataFragment,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { NavigateToItemWrapper } from '../NavigateToItemWrapper';

export type FieldConfig = {
  key:
    | keyof ExpenseItemHospitalityDataExtractedEventDataFragment['properties']
    | keyof ExpenseItemGeneralDataExtractedEventDataFragment['properties'];
  value: string | null;
  translationKey: string;
};

export const ExpenseItemHospitalityDataExtractedComponent = ({
  resourceId,
  properties,
}: ExpenseItemHospitalityDataExtractedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { transactionAmountFormatter } = useMoneyFormatter();

  const { expenseDate, invoiceNumber, tipAmount, receiptAmount, location } =
    properties;

  const formattedDate = expenseDate
    ? DateFormatters.compact(new Date(expenseDate))
    : null;

  const formattedTipAmount = !isNil(tipAmount?.amount)
    ? transactionAmountFormatter({
        currency: tipAmount.currency,
        precision: tipAmount.precision,
        value: tipAmount.amount,
      })
    : null;

  const formattedReceiptAmount = !isNil(receiptAmount?.amount)
    ? transactionAmountFormatter({
        value: receiptAmount.amount,
        currency: receiptAmount.currency,
        precision: receiptAmount.precision,
      })
    : null;

  const fields: FieldConfig[] = useMemo(
    () => [
      {
        key: 'location',
        value: location ?? null,
        translationKey:
          'reimbursementView.middleSection.form.hospitality.location.label',
      },
      {
        key: 'expenseDate',
        value: formattedDate,
        translationKey:
          'reimbursementView.middleSection.form.hospitality.date.label',
      },
      {
        key: 'invoiceNumber',
        value: invoiceNumber ?? null,
        translationKey:
          'reimbursementView.middleSection.form.hospitality.invoiceNumber.label',
      },
      {
        key: 'receiptAmount',
        value: formattedReceiptAmount,
        translationKey:
          'reimbursementView.middleSection.form.hospitality.receiptAmount.label',
      },
      {
        key: 'tipAmount',
        value: formattedTipAmount,
        translationKey:
          'reimbursementView.middleSection.form.hospitality.tipAmount.label',
      },
    ],
    [
      location,
      formattedDate,
      invoiceNumber,
      formattedReceiptAmount,
      formattedTipAmount,
    ]
  );

  const fieldsWithValues = fields.filter(field => field.value);
  const extractedFieldsCount = fieldsWithValues.length;

  if (extractedFieldsCount === 0) {
    return null;
  }

  const headline = t('timeline.extractedDataInfo', {
    count: extractedFieldsCount,
  });

  return (
    <NavigateToItemWrapper id={resourceId}>
      <Text>{headline}</Text>
      <Collapse>
        <div className="pt-2 grid gap-2">
          {fieldsWithValues.map(field => (
            <div key={field.key}>
              <Text fontWeight="regular" color="gray500">
                {t(field.translationKey)}
              </Text>
              <Paragraph>{field.value}</Paragraph>
            </div>
          ))}
        </div>
      </Collapse>
    </NavigateToItemWrapper>
  );
};

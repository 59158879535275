import { UploadDropZone } from 'components/UploadDropZone/UploadDropZone';
import { useFileUpload } from 'views/AppContainer/components/Header/components/DocumentUploadModal/useFileUpload';
import { DropZoneContent } from './DropZoneContent';

export const DropZone = ({
  isRestrictedContent,
}: { isRestrictedContent: boolean }) => {
  const { uploadFiles } = useFileUpload();

  return (
    <UploadDropZone onDrop={files => uploadFiles(files, isRestrictedContent)}>
      {({ isDropValid }) => (
        <DropZoneContent
          isDropValid={isDropValid}
          isRestrictedContent={isRestrictedContent}
        />
      )}
    </UploadDropZone>
  );
};

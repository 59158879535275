import { FilterProps } from 'react-table';
import { PaginationFiltersHooksType } from './hooks/customPaginationAllDocumentsFilterHooks';
import { FilterWithSearchAndPagination } from 'components/Table/Filters/FilterWithSearchAndPagination/FilterWithSearchAndPagination';

interface CustomFilterProps<TTableData extends object>
  extends FilterProps<TTableData> {
  customPaginationFilterHooks?: PaginationFiltersHooksType;
}

export const CustomFilter = <TTableData extends object>({
  customPaginationFilterHooks,
  column,
  handleUpdateIsFilterBeingUsed,
}: CustomFilterProps<TTableData>) => {
  return (
    <FilterWithSearchAndPagination
      column={column}
      onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
      customPaginationFilterHooks={customPaginationFilterHooks}
    />
  );
};

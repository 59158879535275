import { Item, SelectField, Tag, Text } from '@candisio/design-system';
import { SearchType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useDefaultSearchInput } from './useDefaultSearchInput';

interface SearchFilterTypeSelectItemsProps {
  id: string;
  name: string;
  upgrade?: boolean;
}

interface SearchFilterTypeSelectProps {
  query: string;
  value: string;
  onChange: (value: string) => void;
  items: SearchFilterTypeSelectItemsProps[];
}

export function SearchTypeSelect({
  items,
  query,
  value,
  onChange,
}: SearchFilterTypeSelectProps) {
  const { DEFAULT_SEARCH_TYPE } = useDefaultSearchInput();
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <SelectField
      label={t('searchFilter.popover.fields.searchType.label')}
      aria-label={t('searchFilter.popover.fields.searchType.placeholder')}
      select={{
        items,
        placeholder: t('searchFilter.popover.fields.searchType.placeholder'),
        children: item => {
          return (
            <Item key={item.id} textValue={item.name}>
              {item.name}
              {item.id === DEFAULT_SEARCH_TYPE && (
                <>
                  {!!query && <>&nbsp;‘{query}’</>}
                  <Text color="gray500">
                    &nbsp;({t('searchFilter.popover.fields.default')})
                  </Text>
                </>
              )}
              {item.upgrade && (
                <>
                  &nbsp;
                  <Tag color="purple" variant="secondary">
                    {t('common:tag.addOn')}
                  </Tag>
                </>
              )}
            </Item>
          );
        },
        defaultSelectedKey: value || DEFAULT_SEARCH_TYPE,
        onSelectionChange: v => onChange?.(v as SearchType),
      }}
    />
  );
}

import { CellProps } from 'react-table';
import { DocumentsTableData } from '../types';
import { SplitsCellHighlightable } from './SplitsCellHighlightable';
import { GLACellValue, useFormattedGLA } from './GLACell';

export const GLACellHighlightable = (
  props: CellProps<DocumentsTableData, GLACellValue>
) => {
  const formattedGLA = useFormattedGLA(props.value);
  return <SplitsCellHighlightable {...props} value={formattedGLA} />;
};

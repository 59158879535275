import { useApolloClient } from '@apollo/client';
import { Button, Card, Grid, Link, Text } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ConvertDocumentMutation,
  DocumentType,
  useConvertDocumentMutation,
} from 'generated-types/graphql.types';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { refetchClassificationQueries } from 'utils/refetchClassificationQueries';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { useGetPath } from 'hooks/usePath';

interface DocumentConversionWarningContainerProps {
  documentTypeLabel: string;
  globalDocumentId: string;
  documentType: string;

  /** Called when document type is changed between invoice and ecm */
  onDocumentTypeConversion?: () => void;
}

export const DocumentConversionWarningContainer = ({
  documentType,
  documentTypeLabel,
  globalDocumentId,
  onDocumentTypeConversion,
}: DocumentConversionWarningContainerProps) => {
  const [t] = useTranslation();
  const client = useApolloClient();
  const [convert, { loading }] = useConvertDocumentMutation();
  const { success, error, dismiss } = useToastMessage();
  const getPath = useGetPath();

  const handleConvertDocumentType = async () => {
    const result = await convert({
      variables: {
        input: {
          globalDocumentId,
          documentType,
        },
      },
      onCompleted: () => {
        refetchClassificationQueries(client);
      },
    });
    if (!result.data?.convertDocument.globalDocumentId) {
      error(t('ecm:documentConversion.messages.error'));
      return;
    }

    const path = getPathToConvertedDocument(result.data, getPath, documentType);

    success(
      <Text>
        <Trans
          i18nKey="ecm:documentConversion.messages.success"
          values={{ type: documentTypeLabel }}
        >
          Successfully converted to
          <RouterLink to={path} onClick={dismiss}>
            {{ type: documentTypeLabel } as any}
          </RouterLink>
        </Trans>
      </Text>
    );
    onDocumentTypeConversion?.();
  };

  return (
    <Card>
      <Grid gap="space24">
        <Grid gap="space8">
          <Text fontSize="medium" lineHeight="paragraph">
            {t('ecm:documentConversion.convertCta.title')}
          </Text>
          <Trans
            i18nKey="ecm:documentConversion.convertCta.description"
            parent="span"
          >
            <Text fontSize="basic" lineHeight="paragraph">
              All previously captured data in this form will be lost if document
              type is changed.
            </Text>{' '}
            <Link
              justifySelf="center"
              external
              href={t('ecm:documentConversion.convertCta.helpCenterUrl')}
              whiteSpace="nowrap"
              style={{ display: 'inline-block' }}
              fontSize="basic"
              lineHeight="paragraph"
            >
              Learn more
            </Link>
          </Trans>
        </Grid>
        <Grid gap="space16">
          <Button
            color="gray"
            variant="secondary"
            loading={loading}
            onClick={handleConvertDocumentType}
          >
            {t('ecm:documentConversion.convertCta.button', {
              documentTypeLabel,
            })}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

function getPathToConvertedDocument(
  result: ConvertDocumentMutation,
  getPath: ReturnType<typeof useGetPath>,
  documentType: string
) {
  if (result.convertDocument.invoiceId) {
    return getPath({
      pathname: '/inbox/:documentId',
      params: {
        documentId: result.convertDocument.invoiceId,
      },
    });
  }

  if (documentType === DocumentType.Contract) {
    return getPath({
      pathname: '/inbox/contracts/:documentId',
      params: {
        documentId: result.convertDocument.globalDocumentId,
      },
    });
  }

  return getPath({
    pathname: '/inbox/other_documents/:documentId',
    params: {
      documentId: result.convertDocument.globalDocumentId,
    },
  });
}

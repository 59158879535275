import { Row } from 'react-table';
import { ContactImportPreviewTableData } from 'views/Contacts/ContactImport/ContactImportPreviewTable/ContactImportPreviewTable';
import { FilterState } from 'views/Contacts/ContactImport/types';

export const transformFilterState = (filters: FilterState | null) => {
  const filterKeys = Object.keys(filters || {}) as (keyof FilterState)[];

  return filterKeys.map(key => {
    return {
      id: key,
      value: filters ? (filters[key] ?? '') : null,
    };
  });
};

export const genericFilter = (accessor: string) => {
  return (
    rows: Array<Row<ContactImportPreviewTableData>>,
    _columnIds: string[],
    filterValues: any[]
  ) => {
    const filteredRows = rows.filter(row => {
      if (!filterValues || filterValues.length === 0) {
        return true;
      }

      const columnValueStr = row.values[accessor];

      const isRowIncluded = filterValues.some(filterValue => {
        const isMatching = columnValueStr === filterValue;

        return isMatching;
      });

      return isRowIncluded;
    });

    return filteredRows;
  };
};

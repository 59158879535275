import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { useDateConverter } from 'hooks/useDateConverter';
import { useFormContext } from 'react-hook-form';
import { useGetBookingTransformers } from './useGetBookingTransformers';
import { ProcessingFormAccountingDataFieldItem } from 'components/Form/ProcessingForm/ProcessingFormAccountingFields';
import { useMemo } from 'react';
import { TableFormRow } from '../schema/bookingsFormSchema';

/**
 * Hook to get initial values for the form
 */

type InitialValuesProps = {
  documentId?: string;
  /** List of tax code field items */
  taxCodeFieldItems?: ProcessingFormAccountingDataFieldItem[];
};

export const useInitialValues = ({ taxCodeFieldItems }: InitialValuesProps) => {
  const processingForm = useFormContext<ProcessingFormValues>();
  const processingFormValues = processingForm?.getValues();
  const { dateStringToDate, dateToDateString } = useDateConverter();
  const { toBooking } = useGetBookingTransformers();

  // convert processing form values to bookings form values
  const bookings: TableFormRow[] = useMemo(
    () => (processingFormValues?.bookings ?? []).map(toBooking),
    [processingFormValues, toBooking]
  );

  const documentLevelValues = useMemo(
    () => ({
      currency: processingFormValues?.currency,
      grossAmount: processingFormValues?.grossAmount,
      invoiceDate: processingFormValues?.invoiceDate
        ? dateToDateString(dateStringToDate(processingFormValues?.invoiceDate))
        : undefined,
      roundingAmount: processingFormValues?.roundingAmount ?? undefined,
    }),
    [processingFormValues, dateStringToDate, dateToDateString]
  );

  return { documentLevelValues, bookings };
};

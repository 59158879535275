import { PermissionForMembershipDtoIdEnum } from '@candisio/iam-sdk';
import { PermissionsForMembership } from 'hooks/usePermissionsForMembership';
import { useGetCardById } from '../utils';

interface UsePermissionsByCreditCardIdProps {
  cardId: string;
}

interface PermissionsByCreditCardId {
  canLock: boolean;
  canUnlock: boolean;
  canTerminate: boolean;
  canReplace: boolean;
}

/**
 * Custom hook to retrieve and manage permissions by credit card ID.
 * It checks if the user has permissions to manage credit cards based on organization settings and feature flags.
 * @returns {UsePermissionsByCreditCardId} An object containing user permissions.
 */
export const usePermissionsByCreditCardId = ({
  cardId,
}: UsePermissionsByCreditCardIdProps): PermissionsByCreditCardId => {
  const { card } = useGetCardById({ cardId });

  const defaultPermissions = {
    canLock: false,
    canUnlock: false,
    canTerminate: false,
    canReplace: false,
  };

  if (!card) {
    return defaultPermissions;
  }

  const permissionsForMembership = (card?.permissionsForMembership ??
    []) as PermissionForMembershipDtoIdEnum[];

  const permissions = permissionsForMembership.reduce<PermissionsForMembership>(
    (acc, value) => {
      acc[value] = true;

      return acc;
    },
    {}
  );

  return {
    canLock: permissions?.['credit_card.lock'] ?? false,
    canUnlock: permissions?.['credit_card.unlock'] ?? false,
    canTerminate: permissions?.['credit_card.terminate'] ?? false,
    canReplace: permissions?.['credit_card.replace'] ?? false,
  };
};

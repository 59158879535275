import {
  useDeletePromoPreferenceMutation,
  useSavePromoPreferenceMutation,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { currentUserQuery } from 'providers/CurrentUserProvider/queries';
import { useCallback, useMemo } from 'react';

export const usePromoPreferences = () => {
  const user = useCurrentUser();
  const promoPreferences = user?.preferences?.promos ?? [];

  const getIsPromoSeen = useCallback(
    (id: string) => !!promoPreferences.find(({ promoId }) => promoId === id),
    [promoPreferences]
  );

  return useMemo(
    () => ({
      promoPreferences,
      getIsPromoSeen,
    }),
    [promoPreferences, getIsPromoSeen]
  );
};

export const useSavePromoPreferences = () => {
  const user = useCurrentUser();
  const userId = user?.id;
  const [savePromoPreferenceMutation, { loading }] =
    useSavePromoPreferenceMutation({
      refetchQueries: [
        {
          query: currentUserQuery,
          variables: {
            id: userId,
          },
        },
      ],
    });

  const savePromoPreferences = useCallback(
    (promoId: string) => {
      return savePromoPreferenceMutation({
        variables: {
          input: {
            promoId,
          },
        },
      });
    },
    [savePromoPreferenceMutation]
  );

  return { savePromoPreferences, loading };
};

export const useDeletePromoPreferences = () => {
  const user = useCurrentUser();
  const userId = user?.id;
  const [deletePromoPreferenceMutation] = useDeletePromoPreferenceMutation({
    refetchQueries: [
      {
        query: currentUserQuery,
        variables: {
          id: userId,
        },
      },
    ],
  });

  const deletePromoPreferences = useCallback(
    (promoId: string) => {
      return deletePromoPreferenceMutation({
        variables: {
          promoId,
        },
      });
    },
    [deletePromoPreferenceMutation]
  );

  return { deletePromoPreferences };
};

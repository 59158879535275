import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';

type Keys = 'cost_center' | 'cost_object' | 'general_ledger';

type SuggestionMap = {
  [K in Keys]: {
    no_suggestion: boolean;
    case_based_suggestion: boolean;
    expense_based_suggestion: boolean;
  };
};

export const useAccountingDataTrackingHelper = (
  reimbursement?: Reimbursement
) => {
  const {
    suggestedCostCenter,
    suggestedCostObject,
    suggestedGeneralLedgerAccount,
    isAdvancedAccountingEnabled,
  } = reimbursement ?? {};

  const getAccountingDataMetrics = (key: Keys) => {
    const expenseBasedCostCenter = isAdvancedAccountingEnabled ?? false;
    const caseBasedCostCenter = !isAdvancedAccountingEnabled
      ? !!suggestedCostCenter?.readableName
      : false;

    const expenseBasedCostObject = isAdvancedAccountingEnabled ?? false;
    const caseBasedCostObject = !isAdvancedAccountingEnabled
      ? !!suggestedCostObject?.readableName
      : false;

    const expenseBasedGeneralLedger = isAdvancedAccountingEnabled ?? false;
    const caseBasedGeneralLedger = !isAdvancedAccountingEnabled
      ? !!suggestedGeneralLedgerAccount?.readableName
      : false;

    const suggestions: SuggestionMap = {
      cost_center: {
        case_based_suggestion: caseBasedCostCenter,
        expense_based_suggestion: expenseBasedCostCenter,
        no_suggestion: !caseBasedCostCenter && !expenseBasedCostCenter,
      },
      cost_object: {
        case_based_suggestion: caseBasedCostObject,
        expense_based_suggestion: expenseBasedCostObject,
        no_suggestion: !expenseBasedCostObject && !caseBasedCostObject,
      },
      general_ledger: {
        case_based_suggestion: caseBasedGeneralLedger,
        expense_based_suggestion: expenseBasedGeneralLedger,
        no_suggestion: !caseBasedGeneralLedger && !expenseBasedGeneralLedger,
      },
    };

    return suggestions[key];
  };

  return { getAccountingDataMetrics };
};

import { Card } from '@candisio/design-system';
import { Key, useState } from 'react';
import { useTransactionsInsights } from 'views/CreditCards/hooks/useTransactionsInsights';
import { getMappedCardHolderInsights } from 'views/CreditCards/utils/getMappedCardHolderInsights';
import { MissingInvoicesWidget } from './MissingInvoicesWidget';
import {
  DateOptionMissingInvoicesWidget,
  dateFilterManifest,
  mappedDateRangeFilters,
} from './utils';

export const MissingInvoicesWidgetContainer = () => {
  const [dateOption, setDateOption] =
    useState<DateOptionMissingInvoicesWidget>('All');

  const currentDate = new Date();

  const dateRange = dateFilterManifest({ currentDate, dateOption });

  const dateRangeFilters = mappedDateRangeFilters(dateRange);

  const { data, loading } = useTransactionsInsights({ dateRangeFilters });
  const mappedCardholdersCCInsights = getMappedCardHolderInsights(
    data?.getTransactionsInsights?.cardholders
  );

  const handleDateOptionChange = (key: Key[]) => {
    const option = key.length
      ? (key[0] as DateOptionMissingInvoicesWidget)
      : dateOption;

    setDateOption(option);
  };

  return (
    <Card background="gray50">
      <MissingInvoicesWidget
        loading={loading}
        dateRange={dateRange}
        mappedCardholdersCCInsights={mappedCardholdersCCInsights}
        dateOption={dateOption}
        handleDateOptionChange={handleDateOptionChange}
      />
    </Card>
  );
};

import { Flex, Grid, Paragraph, Skeleton } from '@candisio/design-system';
import { Wallet } from 'containers/credit-cards/Wallet';
import { useGetCardVariant } from 'containers/credit-cards/components/CreditCardsSection/utils';
import {
  CardIssuerOrganizationOnboarding,
  CardStatus,
  useGetCardIssuerOrganizationOnboardingQuery,
} from 'generated-types/graphql.types';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const cardStatusesForWallet = [
  CardStatus.Active,
  CardStatus.Locked,
  CardStatus.Pending,
  CardStatus.Requested,
  CardStatus.Processing,
  CardStatus.LockedPin,
];

export const CreditCardRequestViewLoader = () => {
  return (
    <Flex direction="column" height="320px" gap="space12">
      <Skeleton width="100%" height="space32" borderRadius="medium" />
      <Grid alignItems="start" gap="space16" templateColumns="auto 1fr">
        <Skeleton width="180px" height="272px" borderRadius="medium" />
        <Flex direction="column" gap="space6">
          <Skeleton height="space64" width="100%" borderRadius="medium" />
          <Flex direction="column" gap="space14" paddingY="space16">
            <Skeleton height="space14" width="350px" borderRadius="medium" />
            <Skeleton height="space14" width="340px" borderRadius="medium" />
            <Skeleton height="space14" width="360px" borderRadius="medium" />
            <Skeleton height="space14" width="390px" borderRadius="medium" />
          </Flex>
          <Skeleton height="space32" width="125px" borderRadius="medium" />
        </Flex>
      </Grid>
    </Flex>
  );
};

export const CreditCardsSection = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const user = useCurrentUser();
  const creditCardsSetup = useCreditCardsSetup();
  const { data, loading } = useGetCardIssuerOrganizationOnboardingQuery();

  const cardIssuerOrganizationData =
    data?.getCardIssuerOrganizationOnboarding as CardIssuerOrganizationOnboarding;

  const { status } = cardIssuerOrganizationData || {};

  const cardVariant = useGetCardVariant({
    userName: user?.name ?? '',
    status,
  });

  if (loading) return <CreditCardRequestViewLoader />;

  if (
    !creditCardsSetup.isAddonBought ||
    !creditCardsSetup.showAllExtraFeatures ||
    creditCardsSetup.isOnboardingInProgress ||
    creditCardsSetup.isOnboardingNeeded
  ) {
    return (
      <Flex direction="column" gap="space8">
        {cardVariant}
        <Paragraph color="gray600">
          {t('dashboard.drawer.form.footerText')}
        </Paragraph>
      </Flex>
    );
  }

  return <Wallet userName={user?.name} />;
};

import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { ExpenseDetailsCardWithAction } from './ExpenseDetailsCardWithAction';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { PerDiemDetailsCardWithAction } from './PerDiemDetailsCardWithAction';
import { ReimbursementItemType } from 'generated-types/graphql.types';

interface ReimbursementItemSummaryWithActionContainerProps {
  totalAmount: string;
  creatingExpense: boolean;
  onGeneralExpenseClick: () => void;
  onHospitalityExpenseClick: () => void;
  isPromotion?: boolean;
  creatingPerDiem: boolean;
  onCreatePerDiem: () => void;
  reimbursementItems?: ReimbursementItemForList[];
}

export const ReimbursementItemSummaryWithActionContainer = ({
  totalAmount,
  creatingExpense,
  creatingPerDiem,
  onCreatePerDiem,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
  isPromotion,
  reimbursementItems,
}: ReimbursementItemSummaryWithActionContainerProps) => {
  const { canUsePerDiemReimbursement } = useReimbursement();

  const filteredExpenseItems = (reimbursementItems ?? []).filter(item =>
    [
      ReimbursementItemType.GeneralExpense,
      ReimbursementItemType.HospitalityExpense,
    ].includes(item.type)
  );

  const expenseDetailsProps = {
    totalAmount,
    creatingExpense,
    onGeneralExpenseClick,
    onHospitalityExpenseClick,
    isPromotion,
    expenseCount: filteredExpenseItems.length ?? 0,
    reimbursementItems: filteredExpenseItems,
  };

  return (
    <div className="grid gap-4">
      <ExpenseDetailsCardWithAction {...expenseDetailsProps} />
      {canUsePerDiemReimbursement && (
        <PerDiemDetailsCardWithAction
          creatingPerDiem={creatingPerDiem}
          onCreatePerDiem={onCreatePerDiem}
          reimbursementItems={reimbursementItems}
        />
      )}
    </div>
  );
};

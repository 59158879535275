import { Radio, RadioGroup, Tag } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import styles from './power-search-popover.module.css';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { SearchType } from 'generated-types/graphql.types';
import clsx from 'clsx';
import { Entitlements, useEntitlementsFlag } from 'hooks/useCandisFeatureFlags';
import type {
  PowerSearchPopoverProps,
  PowerSearchTypeOptionProps,
  PowerSearchTypeOptionRadioProps,
  PowerSearchTypeRadio,
} from '../types';
import { useSearchTypesLabel } from '../hooks/useSearchTypesLabel';
import { sortBy } from 'lodash';

export const PowerSearchPopover = ({
  query,
  searchType,
  onSearchTypeChange,
}: PowerSearchPopoverProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const searchTypeLabels = useSearchTypesLabel();
  const startsWithLabel = searchTypeLabels[SearchType.PrefixMatch];
  const containsLabel = searchTypeLabels[SearchType.Contains];
  const exactMatchLabel = searchTypeLabels[SearchType.ExactMatch];

  const entitlement = useEntitlementsFlag();
  const isLegacy = entitlement === Entitlements.LEGACY;

  const options: PowerSearchTypeRadio[] = [
    {
      id: SearchType.PrefixMatch,
      value: <OptionStartsWith query={query} label={startsWithLabel} />,
      type: isLegacy ? 'upgrade' : 'default',
    },
    {
      id: SearchType.Contains,
      value: <OptionContains query={query} label={containsLabel} />,
      type: isLegacy ? 'default' : null,
    },
    {
      id: SearchType.ExactMatch,
      value: <OptionExactMatch query={query} label={exactMatchLabel} />,
      type: isLegacy ? 'upgrade' : null,
    },
  ];

  const optionsSortedByDefault = sortBy(
    options,
    option => option.type !== 'default'
  );

  const label = t('powerSearch.filterPopover.label');

  return (
    // @INFO: tabIndex -1 makes sure focus-within state behaves as expected
    // else div being non interactive element active focus goes to body
    // and focus-within state is lost, closing the popover even when clicking
    // on the radio buttons
    <div tabIndex={-1} className={styles['power-search-popover-container']}>
      <RadioGroup
        aria-label={label}
        value={searchType}
        onChange={onSearchTypeChange}
        // @TODO: needs to open upgrade modal when type is upgrade
      >
        <label className={styles['power-search-popover-label']}>{label}</label>
        <div className={styles['power-search-popover-radio-group']}>
          {optionsSortedByDefault.map(option => (
            <PowerSearchTypeOption key={option.id} option={option} />
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

const PowerSearchTypeOption = ({ option }: PowerSearchTypeOptionRadioProps) => {
  return (
    <Radio value={option.id}>
      <div className={styles['power-search-popover-radio']}>
        <span
          className={clsx(
            'truncate',
            styles['power-search-popover-radio-text']
          )}
        >
          {option.value}
        </span>
        {option.type === 'upgrade' && <TagUpgrade />}
        {option.type === 'default' && <TagDefault />}
      </div>
    </Radio>
  );
};

const TagDefault = () => {
  const [t] = useTranslation();
  return (
    <Tag variant="info" className={styles['power-search-popover-radio-tag']}>
      {t('common:tag.default')}
    </Tag>
  );
};

const TagUpgrade = () => {
  const [t] = useTranslation();
  return (
    <Tag
      color="purple"
      variant="secondary"
      className={styles['power-search-popover-radio-tag']}
    >
      {t('common:tag.addOn')}
    </Tag>
  );
};

const OptionStartsWith = ({ query, label }: PowerSearchTypeOptionProps) => {
  if (!query) {
    return label;
  }
  return (
    <>
      {label} <strong>{query}...</strong>
    </>
  );
};

const OptionContains = ({ query, label }: PowerSearchTypeOptionProps) => {
  if (!query) {
    return label;
  }
  return (
    <>
      {label} <strong>...{query}...</strong>
    </>
  );
};

const OptionExactMatch = ({ query, label }: PowerSearchTypeOptionProps) => {
  if (!query) {
    return label;
  }
  return (
    <>
      {label} <strong>{query}</strong>
    </>
  );
};

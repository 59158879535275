import {
  ExportableEntityType,
  PaymentMethod,
} from 'generated-types/graphql.types';
import { ExportEntity, ExportEntityRow } from '../../types';

const isPaidViaCreditCard = (r: ExportEntity) =>
  r.paymentState?.isPaid &&
  'method' in r.paymentState &&
  r.paymentState?.method === PaymentMethod.CardTransaction;

export const mapExportRows = (rows: ExportEntityRow[]) => {
  const settlementEntities = rows.filter(
    r => r.type === ExportableEntityType.CardSettlement
  );

  const provisionEntities = rows.filter(
    r => r.type === ExportableEntityType.Provision
  );

  const reversalEntities = rows.filter(
    r => r.type === ExportableEntityType.ProvisionReversal
  );

  const transactionEntities = rows.filter(r => {
    const isDocument = r.type === ExportableEntityType.Document;
    const isTransaction = r.type === ExportableEntityType.CardTransaction;

    return (isDocument || isTransaction) && isPaidViaCreditCard(r);
  });

  const documentEntities = rows.filter(r => {
    const isDocument = r.type === ExportableEntityType.Document;
    const isTransaction = r.type === ExportableEntityType.CardTransaction;

    return (isDocument || isTransaction) && !isPaidViaCreditCard(r);
  });

  const reimbursementItemsEntities = rows.filter(
    row => row.type === ExportableEntityType.ReimbursementItem
  );

  return {
    documentEntities,
    transactionEntities,
    settlementEntities,
    provisionEntities,
    reversalEntities,
    reimbursementItemsEntities,
  };
};

import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from 'utils/zodFormValidation';
import {
  useShouldRequireGeneralLedgerAccounts,
  useShouldRequireTaxCodes,
} from 'views/utils/useShouldRequireField';
import {
  useShowCostCenterField,
  useShowCostObjectField,
} from 'views/utils/useShouldShowField';
import { reimbursementSplitBookingsFormErrorMessages } from '../toolkit/reimbursementSplitBookingsFormErrorMessages';
import {
  ReimbursementSplitBookingsFormValues,
  reimbursementSplitBookingsFormSchema,
} from '../toolkit/reimbursementSplitBookingsFormSchema';
import { getBookingsErrorsSummary } from '../utils/getBookingsErrorsSummary';
import { ReimbursementSplitBookingsContext } from './ReimbursementSplitBookingsContext';
import { useReimbursementFormsContext } from './ReimbursementFormsContext';
import { noop } from 'lodash';
import { Reimbursement } from '../hooks/useFormattedReimbursement';
import { useReimbursementSplitBookingsInitialSnapshot } from '../hooks/useReimbursementSplitBookingsInitialSnapshot';
import {
  getActionRules,
  getFieldRules,
  ReimbursementStatusProps,
} from '../components/RightSection/ReimbursementSplitBookings/hooks/useReimbursementBookingsFieldRules';
import { useReimbursementSplitBookingsFormData } from '../hooks/useReimbursementSplitBookingsFormInitialValues';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { useWorkflowForReimbursementCaseById } from '../hooks/useWorkflowForReimbursementCaseById';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useCurrentUser } from 'providers/CurrentUserProvider';

export type DrawerView = 'split-bookings';

export interface ActiveBooking {
  entryIndex: number;
  bookingId?: string;
  reimbursementItemId?: string;
}
interface ReimbursementSplitBookingsProviderProps {
  children: ReactNode;
  reimbursement?: Reimbursement;
}

export const ReimbursementSplitBookingsProvider = ({
  children,
  reimbursement,
}: ReimbursementSplitBookingsProviderProps) => {
  const [drawerView, setDrawerView] = useState<DrawerView>();
  const [activeBooking, setActiveBooking] = useState<ActiveBooking>({
    entryIndex: -1,
    bookingId: undefined,
    reimbursementItemId: undefined,
  });

  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const currentUserId = useCurrentUser()?.id ?? '';
  const { approvalMode } = useReimbursementFormsContext();
  const caseStatus = reimbursement?.status;
  const hasCostCenters = useShowCostCenterField();
  const hasCostObjects = useShowCostObjectField();
  const shouldRequireGeneralLedgerAccount =
    useShouldRequireGeneralLedgerAccounts();

  const shouldRequireTaxCode = useShouldRequireTaxCodes();
  const integration = useIntegrationSettings();

  const { reimbursementItemSplitBookings } =
    useReimbursementSplitBookingsFormData();

  const { mappedBookingsSnapshot } =
    useReimbursementSplitBookingsInitialSnapshot();

  const { workflow } = useWorkflowForReimbursementCaseById(reimbursementId);

  const step = workflow?.steps[0];
  const currentStepId = workflow?.currentStepId;

  const isApproverOnFirstWorkflowStep = Boolean(
    step &&
      step.id === currentStepId &&
      step.approvers.some(approver => approver.id === currentUserId)
  );

  const initialBookings = useMemo(
    () => reimbursementItemSplitBookings,
    [reimbursementItemSplitBookings]
  );

  const form = useForm<ReimbursementSplitBookingsFormValues>({
    context: {
      caseStatus,
      integration,
      approvalMode,
      hasCostCenters,
      hasCostObjects,
      shouldRequireTaxCode,
      shouldRequireGeneralLedgerAccount,
    },
    defaultValues: {
      reimbursementItemBookings: initialBookings,
    },
    values: {
      reimbursementItemBookings: initialBookings,
    },
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resetOptions: {
      keepErrors: true,
    },
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.REIMBURSEMENT,
      zodSchema: reimbursementSplitBookingsFormSchema,
      errorMessages: reimbursementSplitBookingsFormErrorMessages,
    }),
  });

  const updateActiveBooking = useCallback((updates: Partial<ActiveBooking>) => {
    setActiveBooking(prev => ({
      ...prev,
      ...updates,
    }));
  }, []);

  const triggerBookingsValidation = useCallback(() => {
    form.handleSubmit(noop)();
    void form.trigger();
  }, [form]);

  const bookingsErrorsSummary = getBookingsErrorsSummary(form.formState.errors);
  const hasBookingsErrors =
    approvalMode === 'approve' && !!bookingsErrorsSummary.invalidFormCount;

  //we force reset when split-bookings form is closed
  const resetDrawerAndForm = useCallback(() => {
    setDrawerView(undefined);
    form.reset({
      reimbursementItemBookings: initialBookings,
    });

    // re-trigger validation to remove errors
    // or persist errors when form is closed
    if (approvalMode === 'approve') {
      triggerBookingsValidation();
    }
  }, [form, initialBookings, triggerBookingsValidation, approvalMode]);

  const getSplitBookingsFormRules = useCallback(
    (props: ReimbursementStatusProps) => {
      const {
        status,
        itemStatus,
        isCurrentUserCaseApprover,
        isOnlyApproverRole,
        isApprovingEditMode,
        fieldOptions,
      } = props;

      const isCaseInApproving = status === ReimbursementCaseStatus.Approving;

      const entries = isCaseInApproving
        ? mappedBookingsSnapshot
        : reimbursementItemSplitBookings;

      const initialEntries = entries?.map(itm => itm?.bookings ?? []);

      const fieldRules = getFieldRules({
        fieldOptions,
        status,
        itemStatus,
        initialEntries,
        isCurrentUserCaseApprover,
        isOnlyApproverRole,
        isApprovingEditMode,
        isApproverOnFirstWorkflowStep,
      });

      const actionRules = getActionRules({
        status,
        fieldRules,
        isApprovingEditMode,
        isOnlyApproverRole,
      });

      return {
        fieldRules,
        actionRules,
      };
    },
    [
      mappedBookingsSnapshot,
      reimbursementItemSplitBookings,
      isApproverOnFirstWorkflowStep,
    ]
  );

  const contextValue = useMemo(
    () => ({
      formMethods: form,
      drawerView,
      setDrawerView,
      activeBooking,
      updateActiveBooking,
      resetDrawerAndForm,
      triggerBookingsValidation,
      getSplitBookingsFormRules,
      bookingsErrorsSummary,
      hasBookingsErrors,
    }),
    [
      form,
      drawerView,
      activeBooking,
      updateActiveBooking,
      resetDrawerAndForm,
      triggerBookingsValidation,
      getSplitBookingsFormRules,
      bookingsErrorsSummary,
      hasBookingsErrors,
    ]
  );

  return (
    <ReimbursementSplitBookingsContext.Provider value={contextValue}>
      {children}
    </ReimbursementSplitBookingsContext.Provider>
  );
};

import {
  AriaTabListProps,
  TabItem,
  TabPanel,
  Tabs,
  useTabListState,
  useTheme,
} from '@candisio/design-system';

interface NotificationTabsProps extends AriaTabListProps<TabItem> {
  'aria-label': string;
}

export const NotificationTabsPanel = ({ ...props }: NotificationTabsProps) => {
  const state = useTabListState(props);
  const { colors } = useTheme();

  return (
    <>
      <Tabs
        {...props}
        state={state}
        style={{
          padding: '0 16px',
          borderBottom: `1px solid ${colors.gray100}`,
        }}
        variant="opaque"
      />
      <TabPanel
        key={state.selectedItem?.key}
        state={state}
        height="100%"
        flexGrow={1}
        overflow="hidden"
      />
    </>
  );
};

import { Swimlane } from 'components/Swimlane/Swimlane';
import { SwimlaneHeader } from 'components/Swimlane/SwimlaneHeader';
import { SwimlaneHeaderCount } from 'components/Swimlane/SwimlaneHeaderCount';
import { SwimlaneTiles } from 'components/Swimlane/SwimlaneTiles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ReimbursementCaseStatus } from 'types/ReimbursementCaseStatusEnum';
import { ArchiveReimbursementCase } from 'views/Reimbursement/hooks/useReimbursementDashboardInsights';
import { ReimbursementCaseEmptyTile } from './content/ReimbursementCaseEmptyTile';
import { ReimbursementTileShowAll } from './content/ReimbursementTileShowAll';
import { SwimlaneTileExpenseContent } from './content/SwimlaneTileExpenseContent';
import { PromoExpenseRejected } from './promos/PromoExpenseRejected';
import { PromoExpenseTips } from './promos/PromoExpenseTips';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';

export const ReimbursementClosedSwimlane = ({
  closedReimbursementCases,
  isVisible,
  totalCount,
}: {
  closedReimbursementCases?: ArchiveReimbursementCase[];
  isVisible: boolean;
  totalCount?: number;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { isPromotionSeen, setPromotionIsSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.EXPENSE_REJECTED
  );

  if (!isVisible) {
    return null;
  }

  const hasAnyRejectedCase = closedReimbursementCases?.some(
    caseItem => caseItem.status === ReimbursementCaseStatus.Rejected
  );

  const showRejectedPromo = hasAnyRejectedCase && !isPromotionSeen;

  return (
    <Swimlane data-cy="closedSwimlane">
      <SwimlaneTiles
        contentTileCount={totalCount}
        promoTileCount={showRejectedPromo ? 1 : undefined}
      >
        {({
          contentTileVisibleCount,
          isShowAllTileVisible,
          isPromoTileVisible,
        }) => {
          return (
            <>
              <SwimlaneHeader
                icon="walletChecked"
                label={t('dashboard.swimlanes.closed')}
              >
                <SwimlaneHeaderCount
                  count={contentTileVisibleCount}
                  totalCount={totalCount}
                />
              </SwimlaneHeader>
              {contentTileVisibleCount === 0 && (
                <>
                  <ReimbursementCaseEmptyTile status="closed" />
                  <PromoExpenseTips />
                </>
              )}
              {closedReimbursementCases &&
                closedReimbursementCases.length > 0 &&
                Array.from({ length: contentTileVisibleCount }, (_, index) => (
                  <SwimlaneTileExpenseContent
                    key={closedReimbursementCases[index].id}
                    expenseCase={closedReimbursementCases[index]}
                  />
                ))}

              {isShowAllTileVisible && (
                <ReimbursementTileShowAll status="closed" />
              )}

              {isPromoTileVisible && showRejectedPromo && (
                <PromoExpenseRejected onClose={setPromotionIsSeen} />
              )}
            </>
          );
        }}
      </SwimlaneTiles>
    </Swimlane>
  );
};

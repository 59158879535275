import { ReimbursementsPromotion } from 'components/ProductPromotions/Reimbursement/ReimbursementsPromotion';
import { TabView } from 'views/Inbox/models';
import { ReimbursementTableColumnKeys } from 'views/Reimbursement/toolkit/types';
import { ArchiveViewLayout } from '../../../views/Archive/components/ArchiveViewLayout';
import { ReimbursementCaseStatus } from 'types/ReimbursementCaseStatusEnum';
import { EventLocationsBase } from 'providers/AnalyticsProvider/events';

const tableColumns: ReimbursementTableColumnKeys[] = [
  'status',
  'requesterOfTheReimbursement',
  'title',
  'grossAmount',
  'createdAt',
  'lastApprovedByMembership',
  'paidAt',
  'isPaid',
];

export const ReimbursementPromotionArchive = () => {
  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_REIMBURSEMENTS}>
      <ReimbursementsPromotion
        tableColumns={tableColumns}
        status={ReimbursementCaseStatus.Archived}
        eventLocation={EventLocationsBase.ARCHIVE}
      />
    </ArchiveViewLayout>
  );
};

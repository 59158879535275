import {
  Checkbox,
  Grid,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { SimplifiedPayableDocument } from '../types';
import { PaymentTableData } from './types';
import { xorBy } from 'lodash';

export const isDisabledRow = (
  record: SimplifiedPayableDocument,
  selectedCurrency?: DocumentCurrency
) =>
  record.__typename !== 'SepaExportableDocument' ||
  (!!selectedCurrency && record.currency !== selectedCurrency);

interface SelectionHeaderProps {
  toggleAllRowsSelected: () => void;
  selectedFlatRows: Row<PaymentTableData>[];
  selectableDocuments: SimplifiedPayableDocument[];
}

export const SelectionHeader = ({
  selectedFlatRows,
  selectableDocuments,
  toggleAllRowsSelected,
}: SelectionHeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  const hasSelectedRows = selectedFlatRows?.length !== 0;
  const hasSelectableDocuments = selectableDocuments.length !== 0;

  const areAllSelectableRowsSelected =
    selectedFlatRows?.length === selectableDocuments.length;

  return (
    <Grid>
      <Checkbox
        height="100%"
        width="100%"
        isDisabled={!hasSelectableDocuments}
        labelHidden
        onChange={toggleAllRowsSelected}
        isIndeterminate={!areAllSelectableRowsSelected && hasSelectedRows}
        isSelected={areAllSelectableRowsSelected && hasSelectableDocuments}
      >
        {t('table.headers.selected')}
      </Checkbox>
    </Grid>
  );
};

interface SelectionCellProps {
  row: Row<PaymentTableData>;
  setSelectedRows: Dispatch<SetStateAction<SimplifiedPayableDocument[]>>;
  selectableDocuments: SimplifiedPayableDocument[];
}

export const SelectionCell = ({
  row,
  setSelectedRows,
  selectableDocuments,
}: SelectionCellProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  const document = row.original.document as SimplifiedPayableDocument;
  const selectedCurrency = row.original.selectedCurrency;

  let warningText = t('table.warning.creditorIbanMissing');
  if (selectedCurrency && document.currency !== selectedCurrency) {
    warningText = t('table.warning.multipleCurrencies');
  }

  const handleSelect = () => {
    setSelectedRows(prevSelectedDocuments => {
      const diff = selectableDocuments.filter(doc => doc.id === row.id);
      return xorBy(prevSelectedDocuments, diff, 'id');
    });
  };

  return isDisabledRow(document, selectedCurrency) ? (
    <>
      <Grid inline {...triggerProps} ref={triggerRef}>
        <Checkbox isDisabled labelHidden />
      </Grid>

      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Text width="25rem">{warningText}</Text>
        </Tooltip>
      )}
    </>
  ) : (
    <Checkbox isSelected={row.isSelected} onChange={handleSelect} labelHidden />
  );
};

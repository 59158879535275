import {
  CalendarDate,
  createCalendar,
  DateValue,
  parseDate,
} from '@internationalized/date';
import {
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { mergeProps, useDateField, useDateSegment } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { DateFieldState, DateSegment, useDateFieldState } from 'react-stately';
import { useTheme } from '../../Theme';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { getReactAriaSecretProps } from '../../utils/getReactAriaSecretProps';
import { Box } from '../Box';
import { useDatePickerPlaceholderSync } from '../DatePicker/useDatePickerPlaceholderSync';
import { useFieldContext, Variant } from '../FieldContainer';
import { ClearButton } from '../FieldContainer/ClearButton';
import { StatusIndicator } from '../FieldContainer/StatusIndicator';
import { Flex } from '../Flex';
import { Grid } from '../Grid';

export const parseValue = (value: string): CalendarDate | null =>
  value === '' ? null : parseDate(value);

export interface DateInputProps
  extends LayoutProps,
    Omit<StandardHTMLAttributes<HTMLDivElement>, 'defaultValue' | 'onChange'> {
  /** Should input receive focus on render? */
  autoFocus?: boolean;
  /** Allow to clear value */
  clearable?: boolean;
  /** message to display in popover of clear button */
  clearLabel?: string;
  /** Locale to use to format date */
  locale?: string;
  /** (Uncontrolled) initial value of the input in ISO 8601 date format */
  defaultValue?: string;
  /** Is input disabled? */
  disabled?: boolean;
  /** Unique identifier of the input */
  id?: string;
  /** Called when the value is changed by the user */
  onChange?: (value: string) => void;
  /** Is input read only? */
  readOnly?: boolean;
  /** (Controlled) value of the input in ISO 8601 date format */
  value?: string;
  /** status variant i.e success, warning, error */
  variant?: Variant;
  /** message to display in popover of status */
  message?: ReactNode;
  /** Show message on focus*/
  showMessageOnFocus?: boolean;
  /** Should show status even read only */
  showStatusOnReadOnly?: boolean;
}

const INDEX_GAP_BETWEEN_SEGMENTS = 2; // segments: ["dd", "/", "mm", "/", "yyyy"]

/** An input for entering dates with the keyboard */
export const DateInput = forwardRef<HTMLDivElement, DateInputProps>(
  (
    {
      'aria-describedby': ariaDescribedby,
      'aria-labelledby': ariaLabelledby,
      autoFocus,
      clearable,
      clearLabel = 'Clear',
      defaultValue,
      disabled,
      id,
      locale = 'en-DE',
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyUp,
      readOnly,
      value,
      message,
      variant,
      showMessageOnFocus,
      showStatusOnReadOnly = false,
      ...restProps
    },
    forwardedRef
  ) => {
    const { secretProps, otherProps } = getReactAriaSecretProps(restProps);

    const { handleOpenChange, placeholderValue } =
      useDatePickerPlaceholderSync();

    const state = useDateFieldState({
      createCalendar,
      defaultValue:
        typeof defaultValue === 'string' ? parseValue(defaultValue) : undefined,
      granularity: 'day',
      isDisabled: disabled,
      isReadOnly: readOnly,
      locale,
      onChange: (value: DateValue | null) => {
        const isoString = value?.toString() ?? '';

        return onChange?.(isoString);
      },
      value: typeof value === 'string' ? parseValue(value) : undefined,
      shouldForceLeadingZeros: true,
      placeholderValue,
      onOpenChange: handleOpenChange,
    });

    const { inputProps, inputRef } = useFieldContext();

    const [inputFocused, setInputFocused] = useState(false);

    const ref = useRef(null);
    const { fieldProps } = useDateField(
      {
        'aria-describedby': ariaDescribedby ?? inputProps['aria-describedby'],
        'aria-labelledby': ariaLabelledby ?? inputProps['aria-labelledby'],
        autoFocus,
        granularity: 'day',
        id: id ?? inputProps.id,
        isDisabled: disabled,
        isReadOnly: readOnly,
        onBlur: event => {
          if (onBlur) {
            //@ts-ignore
            onBlur(event);
          }

          setInputFocused(false);
        },
        onFocus: event => {
          if (onFocus) {
            //@ts-ignore
            onFocus(event);
          }

          setInputFocused(true);
        },
        onKeyDown: onKeyDown as KeyboardEventHandler<Element>,
        onKeyUp: onKeyUp as KeyboardEventHandler<Element>,
        // React Aria passes a secret prop that, when present, allows focus
        // to be managed by `useDatePicker` in the DatePicker component instead
        // of `useDateField` here
        // https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/datepicker/src/useDateField.ts#L52
        ...secretProps,
      },
      state,
      ref
    );

    const canClear =
      clearable &&
      !disabled &&
      !readOnly &&
      // The user can clear the value if there’s at least one non-placeholder
      // segment (excluding separator segments)
      state.segments.some(
        ({ isPlaceholder, type }) => type !== 'literal' && !isPlaceholder
      );

    const segmentsRefs = useRef<HTMLDivElement[]>([]);

    const handleKeyDown = (
      event: { keyCode: number },
      segment: DateSegment,
      index: number
    ) => {
      const { keyCode } = event;

      // Keycode for delete key (46) or backspace key (8)
      if (keyCode === 46 || keyCode === 8) {
        const lastSegmentIndex = index - INDEX_GAP_BETWEEN_SEGMENTS;

        if (segment.isPlaceholder && lastSegmentIndex >= 0) {
          const lastSegment = segmentsRefs.current[
            lastSegmentIndex
          ] as HTMLDivElement;

          lastSegment?.focus();
        }
      }
    };

    const showClearButton = canClear;
    const showStatus = !disabled && !readOnly && (message || variant);

    const showFooter = showClearButton || showStatus;

    const showStatusWithoutFooter =
      showStatusOnReadOnly && (message || variant) && !showFooter;
    return (
      <Grid
        templateColumns="1fr auto"
        alignItems="center"
        gap={showFooter ? 'space8' : 'space0'}
      >
        <Grid
          autoFlow="column"
          alignItems="center"
          justifyContent="start"
          minHeight="space24" // match height of clear button
          minWidth="10ch" // account for 10 characters in date (DD/MM/YYYY)
          {...mergeProps(inputProps, fieldProps, otherProps)}
          ref={mergeRefs([ref, inputRef, forwardedRef])}
        >
          {state.segments.map((segment, index) => (
            <DateInputSegment
              key={index}
              disabled={disabled}
              readOnly={readOnly}
              index={index}
              segment={segment}
              state={state}
              onKeyDown={event => handleKeyDown(event, segment, index)}
              // @ts-expect-error: segmentsRefs.current[index] cannot not be null
              ref={el => (segmentsRefs.current[index] = el)}
            />
          ))}
        </Grid>

        {showStatusWithoutFooter && (
          <StatusIndicator
            message={message}
            // @ts-expect-error BE returns default variant, which we have no use fo here
            variant={variant}
            showMessage={showMessageOnFocus && inputFocused}
          />
        )}
        {showFooter && (
          <Flex
            gap={showClearButton && showStatus ? 'space8' : 'space0'}
            alignItems="center"
          >
            {!disabled && !readOnly && (
              <StatusIndicator
                message={message}
                // @ts-expect-error BE returns default variant, which we have no use fo here
                variant={variant}
                showMessage={showMessageOnFocus && inputFocused}
              />
            )}
            {canClear && (
              <ClearButton
                clearLabel={clearLabel}
                onClear={() => {
                  state.setValue(null);
                }}
              />
            )}
          </Flex>
        )}
      </Grid>
    );
  }
);

interface DateSegmentProps extends StandardHTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  readOnly?: boolean;
  segment: DateSegment;
  state: DateFieldState;
  index: number;
}

const DateInputSegment = forwardRef<HTMLDivElement, DateSegmentProps>(
  (
    { disabled, readOnly, index, segment, state, ...restProps },
    forwardedRef
  ) => {
    const { colors, textField } = useTheme();
    const ref = useRef(null);
    const { segmentProps } = useDateSegment(segment, state, ref);

    const isSeparatorAfterEmptyValue =
      segment.type === 'literal' && state.segments[index - 1].isPlaceholder;

    const textColor =
      isSeparatorAfterEmptyValue || segment.isPlaceholder
        ? textField.placeholderColor
        : textField.color;

    return (
      <Box
        css={{
          cursor: 'text',
          outline: 'none',
          fontVariantNumeric: 'tabular-nums',
          ':focus':
            // we don’t want the “focus-visible” behavior in this case
            { background: colors.blue200 },
          color: textColor,
          ...(disabled && textField.disabled),
          fontStyle: disabled ? 'italic' : 'initial',
          textTransform: 'uppercase',
        }}
        borderRadius="small"
        fontSize="basic"
        {...mergeProps(segmentProps, restProps, {
          tabIndex: disabled || readOnly ? -1 : undefined,
        })}
        ref={mergeRefs([ref, forwardedRef])}
      >
        {segment.text}
      </Box>
    );
  }
);

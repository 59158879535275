import { ReactNode } from 'react';
import { Row, flexRender } from '@tanstack/react-table';
import { CSSProperties } from 'react';
import { BaseTableDataType } from './types';

interface TableRowContentProps<T extends BaseTableDataType> {
  row: Row<T>;
  CellWrapper?: (props: { row: T; children?: ReactNode }) => ReactNode;
}

export const TableRowContentInner = <T extends BaseTableDataType>({
  row,
  CellWrapper,
}: TableRowContentProps<T>) => {
  return row.getVisibleCells().map(cell => {
    const cellContent = flexRender(
      cell.column.columnDef.cell,
      cell.getContext()
    );

    return (
      <td
        key={cell.id}
        style={
          {
            '--cell-size': `${cell.column.getSize()}px`,
          } as CSSProperties
        }
      >
        {CellWrapper ? (
          <CellWrapper row={row.original}>{cellContent}</CellWrapper>
        ) : (
          <div className="flex">{cellContent}</div>
        )}
      </td>
    );
  });
};

TableRowContentInner.displayName = 'TableRowContent';

// TODO: think about memo, since it breaks columnVisibility feature
export const TableRowContent = TableRowContentInner;

import { SSOPromotionOrganization } from 'components/ProductPromotions/SSO/SSOPromotionOrganization';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { OrganizationSSOConfiguration } from './components/OrganizationSSOConfiguration';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';

export const SSOConfiguration = () => {
  const { isAdmin } = useUserRoles();

  const [ssoConfigFF] = useCandisFeatureFlags([FEATURE_FLAGS.ssoConfig]);
  const { ft, show } = useFeatureToggle();
  const showFeature = show(ft.SSO) || ssoConfigFF;

  if (!isAdmin) return null;

  return showFeature ? (
    <OrganizationSSOConfiguration />
  ) : (
    <SSOPromotionOrganization />
  );
};

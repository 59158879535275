import { MouseEvent } from 'react';
import {
  Button,
  ButtonProps,
  Grid,
  mergeProps,
  usePopover,
} from '@candisio/design-system';
import { AcknowledgePopover } from 'components/AcknowledgePopover/AcknowledgePopover';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { useTranslation } from 'react-i18next';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';

export interface DeactivateMembershipButtonProps {
  involvedCount: number;
  onDeactivateMembership: () => void;
  disabled: boolean;
  loading: boolean;
  pendingCount: number;
  onForceDeactivateMembership: (membershipId: string) => void;
  selectedUser: User;
  isOrganizationOwner?: boolean;
}

export const DeactivateMembershipButton = ({
  involvedCount,
  onDeactivateMembership,
  disabled,
  loading,
  pendingCount,
  onForceDeactivateMembership,
  selectedUser,
  isOrganizationOwner,
}: DeactivateMembershipButtonProps) => {
  const [t] = useTranslation();
  const showAcknowledgePopover = pendingCount > 0 && involvedCount < 1;

  const { close, isOpen, triggerProps, triggerRef, popoverProps, popoverRef } =
    usePopover({
      placement: 'top',
    });

  const isButtonDisabled = disabled || isOrganizationOwner;

  const buttonProps: ButtonProps = {
    color: 'red',
    disabled: isButtonDisabled,
    loading,
    variant: 'tertiary',
    children: t('settings.teamMembers.form.actions.deactivateMembership'),
  };

  if (!isButtonDisabled && showAcknowledgePopover) {
    return (
      <Grid>
        <Button
          {...mergeProps(buttonProps, triggerProps)}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            triggerProps?.onClick?.(e);
            onDeactivateMembership();
          }}
          ref={triggerRef}
        />

        {isOpen && (
          <AcknowledgePopover
            {...popoverProps}
            ref={popoverRef}
            onConfirm={() => {
              onForceDeactivateMembership(selectedUser.id);
            }}
            onClose={close}
            acknowledgeText={t(
              'settings.teamMembers.form.involvement.hasPendingApprovalsMessage',
              { numberOfPendingApprovals: pendingCount }
            )}
            confirmButtonProps={{ loading }}
          />
        )}
      </Grid>
    );
  }

  return (
    <ConfirmationButton
      {...buttonProps}
      mainButtonLabel={
        isOrganizationOwner
          ? t(
              'settings.teamMembers.form.actions.deactivateMembershipRestriction'
            )
          : undefined
      }
      confirmationTitle={t(
        'settings.teamMembers.form.actions.deactivateMembershipAreYouSure'
      )}
      onConfirm={onDeactivateMembership}
    />
  );
};

import {
  ReimbursementCaseStatus,
  ReimbursementItemStatus,
} from 'generated-types/graphql.types';
import { BookingValues } from 'views/Reimbursement/toolkit/reimbursementSplitBookingsFormSchema';
import { BookingEntryKeys } from '../../utils/generateSplitBookingsFormFieldNames';
import { FormFieldOptions } from './useFormFieldOptions';

export interface ReimbursementStatusProps {
  status?: ReimbursementCaseStatus;
  itemStatus: ReimbursementItemStatus;
  isCurrentUserCaseApprover?: boolean;
  isOnlyApproverRole?: boolean;
  isApprovingEditMode?: boolean;
  fieldOptions: FormFieldOptions;
}

export interface ActionRules {
  canAddAndDeleteSplits: boolean;
  canAcceptAllSplits: boolean;
}

export interface FieldRule {
  isReadOnly: (
    props: Omit<ReimbursementStatusProps, 'fieldOptions'>,
    initialBooking: BookingValues | undefined
  ) => boolean;
}

export type FieldRules = Record<BookingEntryKeys, FieldRule>;
export type BookingFieldRules = Record<
  BookingEntryKeys,
  { isReadOnly: boolean }
>;

const READONLY_STATUSES = new Set([
  ReimbursementCaseStatus.Approving,
  ReimbursementCaseStatus.Approved,
  ReimbursementCaseStatus.Exported,
  ReimbursementCaseStatus.Archived,
  ReimbursementCaseStatus.Exporting,
  ReimbursementCaseStatus.PartiallyExported,
]);

const isFullyReadOnlyStatus = new Set([
  ReimbursementCaseStatus.Approved,
  ReimbursementCaseStatus.Exported,
  ReimbursementCaseStatus.Archived,
  ReimbursementCaseStatus.Exporting,
]);

const FIELD_VISIBILITY_MAP: Record<
  BookingEntryKeys,
  (options: FormFieldOptions) => boolean
> = {
  generalLedgerAccount: ({ generalLedgerAccount }) =>
    generalLedgerAccount.isVisible ?? false,
  taxCode: ({ taxCode }) => taxCode.isVisible ?? false,
  costCenter: ({ costCenter }) => costCenter.isVisible ?? false,
  costObject: ({ costObject }) => costObject.isVisible ?? false,
  artistSocialInsuranceCode: ({ artistSocialInsuranceCode }) =>
    artistSocialInsuranceCode.isVisible ?? false,
  extraCostInfo: ({ extraCostInfo }) => extraCostInfo.isVisible ?? false,
  postingText: ({ postingText }) => postingText.isVisible ?? false,
  // Always visible fields
  splitAmount: () => true,
  taxPresentation: () => true,
  vatRate: () => true,
  note: () => true,
} as const;

export const isFieldVisible = (
  fieldKey: BookingEntryKeys,
  options: FormFieldOptions
): boolean => {
  const visibilityRule = FIELD_VISIBILITY_MAP[fieldKey];
  return visibilityRule ? visibilityRule(options) : false;
};

const createConditionalReadOnlyRule =
  (
    hasFieldValue: (booking?: BookingValues) => boolean,
    isTaxOrAmountField?: boolean
  ): FieldRule['isReadOnly'] =>
  (
    { status, itemStatus, isCurrentUserCaseApprover, isOnlyApproverRole },
    initialBooking
  ) => {
    if (!status) return true;

    const shouldFieldBeReadOnly =
      READONLY_STATUSES.has(status) && isTaxOrAmountField;
    const isFullyReadOnly = isFullyReadOnlyStatus.has(status);
    const isCaseApproving = status === ReimbursementCaseStatus.Approving;
    const isCaseInReview = status === ReimbursementCaseStatus.Review;
    const isCaseInRejected = status === ReimbursementCaseStatus.Rejected;

    const isValidForApproval =
      isCaseApproving &&
      // If not a case approver, all fields should be readonly (return true)
      (!isCurrentUserCaseApprover ||
        // If is a case approver, check if field has value
        hasFieldValue(initialBooking));

    const isItemExportBlocked =
      status === ReimbursementCaseStatus.PartiallyExported &&
      itemStatus !== ReimbursementItemStatus.ReadyToExport;

    const hasViewOnlyRights =
      !!isOnlyApproverRole && (isCaseInReview || isCaseInRejected);

    return (
      shouldFieldBeReadOnly ||
      isFullyReadOnly ||
      isValidForApproval ||
      isItemExportBlocked ||
      hasViewOnlyRights
    );
  };

const fieldRules: FieldRules = {
  splitAmount: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking?.splitAmount,
      true
    ),
  },
  taxPresentation: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking?.taxPresentation,
      true
    ),
  },
  vatRate: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking?.vatRate,
      true
    ),
  },
  taxCode: {
    isReadOnly: createConditionalReadOnlyRule(booking => !!booking?.taxCode),
  },
  artistSocialInsuranceCode: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking?.artistSocialInsuranceCode
    ),
  },
  costCenter: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking?.costCenter?.value
    ),
  },
  costObject: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking?.costObject?.value
    ),
  },
  extraCostInfo: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking?.extraCostInfo?.value
    ),
  },
  generalLedgerAccount: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking?.generalLedgerAccount?.value
    ),
  },
  note: {
    isReadOnly: createConditionalReadOnlyRule(booking => !!booking?.note),
  },
  postingText: {
    isReadOnly: createConditionalReadOnlyRule(
      booking => !!booking?.postingText
    ),
  },
};

interface ReadOnlyStateParams
  extends Omit<GetFieldRulesProps, 'initialEntries' | 'fieldOptions'> {
  hasSingleBooking: boolean;
  baseFieldReadOnly: boolean;
}

const determineReadOnlyState = (props: ReadOnlyStateParams): boolean => {
  const {
    status,
    hasSingleBooking,
    isApprovingEditMode,
    isOnlyApproverRole,
    baseFieldReadOnly,
    isApproverOnFirstWorkflowStep,
  } = props;

  // We use base read-only state if case status is not Approving
  if (status !== ReimbursementCaseStatus.Approving) {
    return baseFieldReadOnly;
  }

  // In approving status, fields can be edited in two scenarios:
  // 1. In edit mode by non-approver-only users
  const canEditInApprovalMode = isApprovingEditMode && !isOnlyApproverRole;

  // 2. In default mode with single booking and user is an approver on the first workflow step
  const canEditInDefaultMode =
    !isApprovingEditMode && hasSingleBooking && isApproverOnFirstWorkflowStep;

  // If either edit condition is met, all booking field is editable
  if (canEditInApprovalMode || canEditInDefaultMode) {
    return false;
  }

  // Otherwise, we use the base read-only state
  return baseFieldReadOnly;
};

interface GetFieldRulesProps extends ReimbursementStatusProps {
  initialEntries: Array<BookingValues[]>;
  isApproverOnFirstWorkflowStep: boolean;
}

export const getFieldRules = ({
  status,
  initialEntries,
  fieldOptions,
  ...props
}: GetFieldRulesProps): Array<BookingFieldRules[]> => {
  if (!status) return [];

  const allFieldRules: Array<BookingFieldRules[]> = [];

  for (const bookings of initialEntries) {
    const bookingRules: BookingFieldRules[] = [];

    for (const booking of bookings) {
      const fieldEntries = {} as BookingFieldRules;

      const hasSingleBooking = bookings.length === 1;

      // we filter booking fields to only include those that:
      // 1. are defined in the fieldRules config
      // 2. are marked as visible in fieldOptions
      // this prevents rule inconsistencies from hidden fields
      const relevantBookingFields = Object.keys(booking).filter(
        key =>
          key in fieldRules &&
          isFieldVisible(key as BookingEntryKeys, fieldOptions)
      );

      for (const key of relevantBookingFields) {
        const rule = fieldRules[key as BookingEntryKeys];
        const baseFieldReadOnly = rule.isReadOnly(
          { status, ...props },
          booking
        );

        const isReadOnly = determineReadOnlyState({
          ...props,
          status,
          hasSingleBooking,
          baseFieldReadOnly,
        });

        fieldEntries[key as BookingEntryKeys] = { isReadOnly };
      }

      bookingRules.push(fieldEntries);
    }

    allFieldRules.push(bookingRules);
  }

  return allFieldRules;
};

interface GetActionRulesProps {
  fieldRules: BookingFieldRules[][];
  status?: ReimbursementCaseStatus;
  isApprovingEditMode?: boolean;
  isOnlyApproverRole?: boolean;
}

export const getActionRules = ({
  fieldRules,
  status,
  isApprovingEditMode,
  isOnlyApproverRole,
}: GetActionRulesProps): ActionRules[] => {
  const isDraftStatus = status === ReimbursementCaseStatus.Draft;
  const isInApprovingAndEditMode =
    !isOnlyApproverRole &&
    status === ReimbursementCaseStatus.Approving &&
    !!isApprovingEditMode;

  if (isDraftStatus || isInApprovingAndEditMode) {
    return Array(fieldRules.length).fill({
      canAcceptAllSplits: true,
      canAddAndDeleteSplits: !isDraftStatus,
    });
  }

  return fieldRules.map(ruleGroup => {
    if (!ruleGroup?.length) {
      return {
        canAcceptAllSplits: false,
        canAddAndDeleteSplits: false,
      };
    }

    const hasReadOnlyField = ruleGroup.some(rule =>
      Object.values(rule ?? {}).some(field => field.isReadOnly)
    );

    const hasEditableField = ruleGroup.some(rule =>
      Object.values(rule ?? {}).some(field => !field.isReadOnly)
    );

    return {
      canAcceptAllSplits: hasEditableField,
      canAddAndDeleteSplits: !hasReadOnlyField,
    };
  });
};

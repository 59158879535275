import { TruncatedText } from '@candisio/design-system/next';
import { InfoPopupButton } from 'components/Icons/InfoPopupButton';
import { IndicatorStatus } from 'views/AppContainer/components/Header/components/ProgressIndicator';

const mapStatusToColor = (status: any) => {
  switch (status) {
    case IndicatorStatus.ERROR:
      return 'text-red-500';
    case IndicatorStatus.WARNING:
      return 'text-yellow-500';
    case IndicatorStatus.DONE:
      return 'text-green-500';
    default:
      return 'text-gray-800';
  }
};

export interface StatusProps {
  status: IndicatorStatus;
  label: string;
  details?: string;
}

export const Status = ({ status, label, details }: StatusProps) => {
  return (
    <div className="flex items-center gap-1 justify-end">
      <TruncatedText className={mapStatusToColor(status)}>
        {label}
      </TruncatedText>
      {details && <InfoPopupButton message={details} />}
    </div>
  );
};

import { Item } from '@candisio/design-system';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { EmptySearchState } from 'components/FilterableList/components/EmptySearchState';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { escapeRegex } from 'utils/regex';
import { NoWorkflowTemplates } from './components/NoWorkflowTemplates';
import { WorkflowTemplateItem } from './components/WorkflowTemplateItem';
import { Workflow } from './hooks/useWorkflowData';

export enum SortBy {
  alphabetically = 'alphabetically',
  lastAdded = 'lastAdded',
}

export interface WorkflowTemplatesListViewProps {
  data: Workflow[];
  archiveCount?: number;
  isLoading: boolean;
  workflowUser?: string | undefined;
}

const sortWorkflows = (workflows: Workflow[], sortBy: SortBy) => {
  switch (sortBy) {
    case SortBy.alphabetically: {
      const collator = new Intl.Collator();

      return [...(workflows || [])]?.sort((a: Workflow, b: Workflow) =>
        collator.compare(a.name, b.name)
      );
    }

    case SortBy.lastAdded: {
      return [...(workflows || [])]?.sort((a: Workflow, b: Workflow) => {
        return (a.createdAt ?? '') < (b.createdAt ?? '') ? 1 : -1;
      });
    }

    default: {
      return workflows;
    }
  }
};

/**
 * Returns a filtered list of workflows where the each workflow contains the
 * query string in either its name, description or users
 */
const filterWorkflows = (workflows: Workflow[], queryString: string) => {
  const regExp = new RegExp(escapeRegex(queryString), 'i');

  return workflows.filter(workflow => {
    // name contains the query string
    if (workflow.name.match(regExp)) {
      return true;
    }

    // description contains the query string
    if (workflow.description?.match(regExp)) {
      return true;
    }

    // one or more users contains the query string
    const hasQueriedUsers = workflow.users?.some(user =>
      user.name?.match(regExp)
    );

    return hasQueriedUsers;
  });
};

export const WorkflowTemplatesListView = ({
  data,
  archiveCount,
  workflowUser,
  isLoading,
}: WorkflowTemplatesListViewProps) => {
  const [queryString, setQueryString] = useState('');
  const [searchParams] = useSearchParams();
  const org = useFullOrganization();
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.alphabetically);
  const navigate = useNavigate();
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  useEffect(() => {
    setQueryString(workflowUser ? workflowUser : '');
  }, [workflowUser]);

  const sortItems = [
    {
      id: SortBy.alphabetically,
      label: t('workflows.filters.sortAlphabetically'),
    },
    {
      id: SortBy.lastAdded,
      label: t('workflows.filters.lastAddedFirst'),
    },
  ];

  const sortButtonText = sortItems.find(
    item => sortBy.toString() === item.id
  )?.label;

  const handleSortBy = (value: Key[]) => {
    setSortBy(value.length ? (value[0] as SortBy) : sortBy);
  };

  const onSelect = (id: string) => {
    navigate({
      pathname: `/${org?.id}${Routes.SETTINGS}${Routes.WORKFLOWS}/${id}`,
      search: searchParams.toString(),
    });
  };

  const onCreate = () => {
    navigate({
      pathname: `/${org?.id}${Routes.SETTINGS}${Routes.WORKFLOWS}/create`,
    });
  };

  const onSearchFilter = (filter: string) => {
    setQueryString(filter);
  };

  const filteredWorkflows = filterWorkflows(data, queryString);
  const sortedWorkflows = sortWorkflows(filteredWorkflows, sortBy);

  const searchReset = () => {
    setQueryString('');
  };

  return (
    <FilterableList
      children={sortedWorkflows.map(workflow => (
        <Item key={workflow.id} textValue={workflow.name}>
          <WorkflowTemplateItem
            onSelect={onSelect}
            workflow={workflow}
            queryString={queryString}
          />
        </Item>
      ))}
      emptyDataState={
        <NoWorkflowTemplates archiveCount={archiveCount} onCreate={onCreate} />
      }
      emptySearchState={<EmptySearchState searchReset={searchReset} />}
      onCreate={{ value: onCreate, text: t('workflows.actions.new') }}
      isLoading={isLoading}
      menuButtons={[
        {
          actionValue: [sortBy],
          onClick: handleSortBy,
          text: sortButtonText ?? '',
          menuButtonItems: sortItems,
        },
      ]}
      searchField={{
        onSearchFilter,
        placeholder: t('workflows.search.placeholder'),
        searchQuery: queryString,
      }}
    />
  );
};

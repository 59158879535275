import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Icon,
  Link,
  Paragraph,
  Text,
} from '@candisio/design-system';
import { useSapB1SettingsQuery } from 'generated-types/graphql.types';
import { useGetSapB1Credentials } from 'hooks/useGetSAPB1Credentials';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { exportCardId, scrollToTarget } from 'views/CreditCards/utils/utils';
import { CheckList } from './CheckList/CheckList';
import { CreditCardExportingFormSap } from './CreditCardExportingFormSap/CreditCardExportingFormSap';
import { StateKey, type StateKey as StateKeyType } from './states';

type AvailablityObject = {
  isAvailable: boolean;
  stateKeySuccess: StateKeyType;
  stateKeyError: StateKeyType;
};

export const CreditCardExportingSectionSap = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { data, loading } = useGetSapB1Credentials();
  const { data: sapB1Settings, loading: settingsLoading } =
    useSapB1SettingsQuery();

  const location = useLocation();

  const [checks, setChecks] = useState<StateKeyType[]>([
    StateKey.checkingService,
  ]);

  const [showForm, setShowForm] = useState(false);

  const isCCIdAvailable = !!sapB1Settings?.sapB1Settings?.creditCardId;
  const isCCGLAAvailable =
    !!sapB1Settings?.sapB1Settings?.creditCardGeneralLedgerAccount;

  const isMoneyTransitAvailable =
    !!sapB1Settings?.sapB1Settings?.moneyInTransitGeneralLedgerAccount;

  const areCreditCardAccountsPresent =
    isCCIdAvailable && isCCGLAAvailable && isMoneyTransitAvailable;

  const availability: AvailablityObject[] = useMemo(
    () => [
      {
        isAvailable: isCCIdAvailable,
        stateKeySuccess: StateKey.isCreditCardIdSetSuccess,
        stateKeyError: StateKey.isCreditCardIdSetError,
      },
      {
        isAvailable: isCCGLAAvailable,
        stateKeySuccess: StateKey.isGeneralLedgerAccountConnectedSuccess,
        stateKeyError: StateKey.isGeneralLedgerAccountConnectedError,
      },
      {
        isAvailable: isMoneyTransitAvailable,
        stateKeySuccess: StateKey.isMoneyTransitSetSuccess,
        stateKeyError: StateKey.isMoneyTransitSetError,
      },
    ],
    [isCCIdAvailable, isCCGLAAvailable, isMoneyTransitAvailable]
  );

  useEffect(() => {
    if (loading || settingsLoading) return;

    if (!data?.sapB1Credentials) {
      // client is not connected
      return setChecks([StateKey.isClientConnectedError]);
    }

    // client is connected
    setShowForm(true);

    // all fields are present
    if (areCreditCardAccountsPresent) {
      return setChecks([StateKey.serviceConnected]);
    }

    // all fields are missing
    if (availability.every(a => !a.isAvailable)) {
      return setChecks([StateKey.isCreditCardSetError]);
    }

    // Some fields are missing
    const addChecks = availability.map(a =>
      a.isAvailable ? a.stateKeySuccess : a.stateKeyError
    );

    return setChecks(addChecks);
  }, [
    data,
    loading,
    availability,
    settingsLoading,
    areCreditCardAccountsPresent,
  ]);

  useEffect(() => {
    const hash = location.hash;
    if (hash !== '') scrollToTarget(hash);
  }, [location.hash]);

  let formElement = null;

  const isDataReady = !loading && !settingsLoading;

  if (isDataReady && showForm) formElement = <CreditCardExportingFormSap />;
  if (isDataReady && !showForm) formElement = <SapSettingsNavButton />;

  return (
    <Card
      id={exportCardId}
      padding="space16"
      borderRadius="medium"
      alignSelf="start"
    >
      <Grid gap="space14" paddingBottom="space12">
        {isDataReady && !areCreditCardAccountsPresent && (
          <MissingAccountMessage />
        )}
        <Heading as="h3">{t('creditCardExportingSap.title')}</Heading>
        <Paragraph>{t('creditCardExportingSap.description')}</Paragraph>
        <Flex>
          <Link external href={t('creditCardExportingSap.linkUrl')}>
            {t('creditCardExportingSap.linkText')}
          </Link>
        </Flex>
        <CheckList checks={checks} />
        {formElement}
      </Grid>
    </Card>
  );
};

function MissingAccountMessage() {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  return (
    <Grid
      autoFlow="column"
      background="redbg"
      gap="space4"
      paddingY="space12"
      paddingX="space16"
      borderRadius="medium"
    >
      <Icon icon="infoCircle" color="red500" size="space16" />
      <Text color="red500" fontSize="small">
        {t('creditCardExportingSap.missingAccountNumber')}
      </Text>
    </Grid>
  );
}

function SapSettingsNavButton() {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();

  // TODO: replace with a regular RRouter.Link
  const toSettings = useCallback(
    () => navigate(`/${organizationSlug}${Routes.SETTINGS}${Routes.SAP}`),
    [organizationSlug, navigate]
  );

  return (
    <Button
      width="max-content"
      size="small"
      variant="secondary"
      onClick={toSettings}
    >
      {t('creditCardExportingSap.accountNumbers.crossLink')}
    </Button>
  );
}

import {
  Children,
  cloneElement,
  ComponentProps,
  forwardRef,
  ReactElement,
} from 'react';
import { AvatarProps } from '../../Atoms/Avatar';
import { LayoutProps } from '../../types';
import { MorePlaceholder } from './MorePlaceholder';
import clsx from 'clsx';
import styles from './AvatarStack.module.css';

const TRANSITION_DELAY = 200;

type AvatarChildren = ReactElement<AvatarProps> | ReactElement<AvatarProps>[];

export interface AvatarStackProps extends ComponentProps<'div'> {
  children: AvatarChildren;
  limit?: number;
  size?: AvatarProps['size'];
  tooltipMaxHeight?: LayoutProps['height'];
}

/**
 * The AvatarStack is used when multiple Avatar components are displayed in a group / as a stack.
 *
 * @param {AvatarChildren} children Set of Avatars
 */
export const AvatarStack = forwardRef<HTMLDivElement, AvatarStackProps>(
  (
    {
      children,
      limit: limitProp = 4,
      size = 'medium',
      tooltipMaxHeight,
      className,
      ...restProps
    },
    ref
  ) => {
    const childrenArray = Children.toArray(
      children
    ) as ReactElement<AvatarProps>[];

    const limit = Math.max(limitProp, 2);

    let beforePlaceholder: ReactElement<AvatarProps>[] = childrenArray;
    let afterPlaceholder: ReactElement<AvatarProps>[] = [];

    if (childrenArray.length > limit) {
      beforePlaceholder = childrenArray.slice(0, limit - 1);
      afterPlaceholder = childrenArray.slice(limit - 1);
    }

    const hasMultipleAvatars = childrenArray.length > 1;
    // only delay the tooltip if we have more than one avatar
    const tooltipDelay = hasMultipleAvatars ? TRANSITION_DELAY : 0;

    const numItems = Math.min(childrenArray.length, limit);

    return (
      <div
        className={clsx(styles['avatar-stack'], className)}
        data-multiple={numItems > 1}
        {...restProps}
        ref={ref}
      >
        {beforePlaceholder.map(child => {
          // This clones each child passed in and adds the tooltipDelay property to it.
          return cloneElement(child, {
            tooltipDelay: tooltipDelay,
            size,
          });
        })}
        {afterPlaceholder.length > 0 && (
          <MorePlaceholder
            size={size}
            tooltipDelay={tooltipDelay}
            tooltipMaxHeight={tooltipMaxHeight}
          >
            {afterPlaceholder}
          </MorePlaceholder>
        )}
      </div>
    );
  }
);

import { useUploadDropZoneActivated } from 'components/UploadDropZone/state';
import { ReactNode } from 'react';
import { Button, FileTrigger } from 'react-aria-components';
import styles from './FilePicker.module.css';

type FilePickerProps = {
  acceptedFileTypes?: string[];
  allowsMultiple?: boolean;
  children: ReactNode;
  id?: string;
  isRestrictedContent?: boolean;
  onSelect: (files: FileList | null) => void;
};

export const FilePicker = ({
  acceptedFileTypes = ['application/pdf'],
  allowsMultiple = false,
  children,
  id,
  onSelect,
  isRestrictedContent,
}: FilePickerProps) => {
  const isDropZoneActive = useUploadDropZoneActivated();

  return (
    <label
      className={styles.fileupload}
      data-darkened={isRestrictedContent}
      data-underlay={isDropZoneActive}
      htmlFor={id}
    >
      <FileTrigger
        acceptedFileTypes={acceptedFileTypes}
        allowsMultiple={allowsMultiple}
        onSelect={onSelect}
      >
        <Button className="hidden" />
      </FileTrigger>
      {children}
    </label>
  );
};

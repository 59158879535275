import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

export const useCreditCardsAccess = () => {
  const { isAccountant, isAdmin, isCardManager } = useUserRoles();
  const hasAdminPrivileges = isCardManager || isAdmin || isAccountant;

  const { showAllExtraFeatures } = useCreditCardsSetup();

  const [creditCardsStatementsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.creditCardsStatements,
  ]);

  const hasAccess = showAllExtraFeatures && hasAdminPrivileges;

  const showInsightsTab = hasAccess;
  const showCardManagerCardOverviewTab = hasAccess;
  const showCardHolderOverviewTab = !hasAdminPrivileges && showAllExtraFeatures;
  const showStatementTab = hasAccess && creditCardsStatementsFF;
  const showRecurringPaymentsTab = hasAccess;

  return {
    hasAccess,
    showInsightsTab,
    showStatementTab,
    showCardManagerCardOverviewTab,
    showCardHolderOverviewTab,
    showRecurringPaymentsTab,
  };
};

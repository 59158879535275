import { CellContext } from '@tanstack/react-table';
import { HookFormAmountInput } from 'components/HookFormInputs/HookFormAmountInput';
import {
  BookingsFormValues,
  TableFormRow,
} from '../../schema/bookingsFormSchema';
import { useWatch } from 'react-hook-form';
import { useGetTaxAmountChange } from '../../hooks/useOnChangeFactory';

type Value = TableFormRow['taxAmount'];

export const TaxAmountCell: React.FC<CellContext<TableFormRow, Value>> = ({
  row: { index },
}) => {
  const currency = useWatch<BookingsFormValues, 'currency'>({
    name: 'currency',
  });

  const handleTaxAmountChange = useGetTaxAmountChange(index);
  return (
    <HookFormAmountInput
      name={`bookings.${index}.taxAmount`}
      dataCy={`bookings.${index}.taxAmount`}
      currency={currency}
      onChange={handleTaxAmountChange}
    />
  );
};

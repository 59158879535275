import { Modal } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export const PromotionModal = ({
  children,
  isModalOpen,
  closeModal,
  titleInfo,
  ...restProps
}: {
  children: ReactNode;
  isModalOpen: boolean;
  closeModal: () => void;
  titleInfo: ReactNode;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);

  return (
    <Modal
      width="1000px"
      background="gray100"
      padding="0"
      style={{
        background:
          'linear-gradient(90deg, rgba(255,255,255,1) 60%, rgba(248,246,245,1) 40%)',
      }}
      titleInfo={titleInfo}
      closeLabel={t('common:common.close')}
      isOpen={isModalOpen}
      onClose={closeModal}
      {...restProps}
    >
      {children}
    </Modal>
  );
};

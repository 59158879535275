import { Box, Button, Grid, GridProps } from '@candisio/design-system';
import {
  getLocalStorageKey,
  parseValueFromLocalStorage,
  useLocalStorage,
} from 'hooks/LocalStorage/useLocalStorage';
import { ReactNode, useCallback } from 'react';

type HeaderProps = Omit<GridProps, 'as'> & {
  hoverColor?: string;
  iconPosition?: 'left' | 'right';
};

const StyledHeader = ({
  children,
  hoverColor = 'gray100',
  isOpen,
  iconPosition = 'left',
  ...rest
}: HeaderProps & { isOpen: boolean }) => (
  <Grid
    autoFlow="column"
    gap="space8"
    justifyContent="start"
    alignItems="center"
    templateColumns={iconPosition === 'left' ? 'auto 1fr' : '1fr auto'}
    fontWeight="bold"
    fontSize="basic"
    height="44px"
    paddingX="space16"
    cursor="pointer"
    borderTopRadius="inherit"
    borderBottomRadius={isOpen ? 'none' : 'inherit'}
    hover={{ background: hoverColor }}
    {...rest}
  >
    {children}
  </Grid>
);

export interface CollapsibleCardProps extends Omit<GridProps, 'id'> {
  id: string;
  header: ReactNode | ((isOpen: boolean) => ReactNode);
  isStatic?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  headerProps?: HeaderProps;
  iconPosition?: 'left' | 'right';
}

const noop = () => {};

export function getCardOpenState<T>(id: string) {
  return parseValueFromLocalStorage<T>(getLocalStorageKey(id));
}

export const CollapsibleCard = ({
  children,
  header: headerProp,
  id,
  onOpenChange,
  headerProps,
  isStatic = false,
  defaultOpen = false,
  iconPosition = 'left',
  ...rest
}: CollapsibleCardProps) => {
  const [isOpen, setIsOpen] = useLocalStorage(id, defaultOpen);

  const toggleIsOpen = useCallback(() => {
    setIsOpen(prev => !prev);
  }, [setIsOpen]);

  const icon = (
    <Button
      icon={isOpen ? 'caretUp' : 'caretDown'}
      variant="tertiary"
      color="gray"
      size="xxsmall"
      iconSize="space16"
    />
  );

  const header =
    headerProp instanceof Function ? headerProp(isOpen) : headerProp;

  const open = isStatic || isOpen;
  return (
    <Grid
      overflow="hidden"
      rowGap={open ? '1px' : 'unset'}
      templateRows={open ? 'auto 1fr' : 'auto 0'}
      borderRadius="inherit"
      {...rest}
    >
      <StyledHeader
        onClick={toggleIsOpen}
        data-isopen={open}
        isOpen={open}
        iconPosition={iconPosition}
        {...headerProps}
        {...(isStatic && {
          onClick: noop,
          hover: undefined,
          cursor: 'auto',
        })}
      >
        {!isStatic && iconPosition === 'left' && icon}
        {header}
        {!isStatic && iconPosition !== 'left' && icon}
      </StyledHeader>
      <Box overflow="auto" borderBottomRadius="inherit">
        {children}
      </Box>
    </Grid>
  );
};

import { Button, Card, Flex, Grid, Tag, Text } from '@candisio/design-system';
import { useGetCardById } from 'containers/credit-cards/utils';
import { LinkedCardTransactionState } from 'generated-types/graphql.types';
import { useInboxTransactionCount } from 'hooks/useCounterQueries';
import { isNil } from 'lodash';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../../../models';
import { useCardholderId } from '../../../../../../providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from '../../../../../../providers/LocaleProvider';
import { useOrganizationId } from '../../../../../../providers/OrganizationProvider';
import { TRANSACTION_FILTER } from '../../../../../../views/Inbox/Transactions/useTransactionListFilters';
import { LimitChangeInfo } from '../../LimitChangeInfo';
import { ShowTransactions } from '../../ShowTransactions';
import { VirtualX1CardWasUsedInfo } from '../../VirtualX1CardWasUsedInfo';
type DefaultStateProps = {
  vendorDetails?: {
    vendorName?: string;
    transactionState?: LinkedCardTransactionState;
  };
  cardId: string;
  onCardDetails: () => void;
  onShowAll: () => void;
  onShowMissing: () => void;
  transactionsWithoutDocumentCount: number;
};

export const DefaultState = ({
  vendorDetails,
  cardId,
  onCardDetails,
  onShowAll,
  onShowMissing,
  transactionsWithoutDocumentCount,
}: DefaultStateProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const showHintCard =
    vendorDetails &&
    vendorDetails.transactionState ===
      LinkedCardTransactionState.CardCheckNotDone;

  return (
    <Grid gap="space8" height="260px">
      <LimitChangeInfo cardId={cardId} />
      <VirtualX1CardWasUsedInfo cardId={cardId} />
      <Flex
        direction="column"
        justifyContent="space-between"
        alignSelf={showHintCard ? 'normal' : 'end'}
      >
        {showHintCard && (
          <Card background="yellowbg" maxWidth="185px" padding="space12">
            <Grid gap="space4">
              <Tag color="yellow">{t('carousel.tag')}</Tag>
              <Text lineHeight="paragraph" fontWeight="semibold">
                {t('carousel.hint', {
                  vendorName: vendorDetails.vendorName,
                })}
              </Text>
            </Grid>
          </Card>
        )}
        <Flex direction="column" gap="space8">
          <ShowTransactions
            onShowAll={onShowAll}
            onShowMissing={onShowMissing}
            transactionsWithoutDocumentCount={transactionsWithoutDocumentCount}
          />
          <Button
            size="small"
            variant="secondary"
            alignSelf="end"
            icon="arrowRight"
            iconPosition="right"
            onClick={onCardDetails}
          >
            {t('carousel.details')}
          </Button>
        </Flex>
      </Flex>
    </Grid>
  );
};

export const DefaultContainer = ({ cardId }: { cardId: string }) => {
  const history = useHistory();
  const organizationSlug = useOrganizationId();
  const { cardholderId } = useCardholderId();
  const { card } = useGetCardById({ cardId });

  const linkedVendors = card?.linkedVendors;
  const numberOfvendors =
    !isNil(linkedVendors) && linkedVendors.pageInfo.totalCount > 0
      ? linkedVendors?.pageInfo.totalCount
      : 0;

  const vendorDetails =
    numberOfvendors > 0
      ? {
          vendorName: linkedVendors?.records
            .map(rec => rec.vendor.name)
            .join(', '),
          transactionState: linkedVendors?.records[0].linkedCards.find(
            ({ card }) => card.id === cardId
          )?.transactionState,
        }
      : undefined;

  const { inboxTransactionCount } = useInboxTransactionCount({
    cardholderIds: cardholderId ? [cardholderId] : undefined,
    cardIds: [cardId],
    skip: !cardholderId,
    fetchPolicy: 'cache-and-network',
  });

  const onShowAll = () => {
    history.push({
      pathname: `/${organizationSlug}${Routes.ARCHIVE}${Routes.TRANSACTIONS}`,
      search: qs.stringify({
        [TRANSACTION_FILTER.cardRefNum]: [cardId],
      }),
    });
  };

  const onShowMissing = () => {
    history.push({
      pathname: `/${organizationSlug}${Routes.INBOX}${Routes.TRANSACTIONS}`,
      search: qs.stringify({
        [TRANSACTION_FILTER.cardRefNum]: [cardId],
      }),
    });
  };

  const onCardDetails = () => {
    history.push({
      pathname: `/${organizationSlug}${Routes.DASHBOARD_CREDIT_CARD}/${cardId}`,
      search: history.location.search,
    });
  };

  return (
    <DefaultState
      vendorDetails={vendorDetails}
      cardId={cardId}
      onCardDetails={onCardDetails}
      onShowAll={onShowAll}
      onShowMissing={onShowMissing}
      transactionsWithoutDocumentCount={inboxTransactionCount}
    />
  );
};

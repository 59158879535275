import { Button, Drawer, Heading, useId } from '@candisio/design-system';
import { DocumentAccessSections } from 'components/DocumentAccess/DocumentAccesssSections';
import {
  DrawerLayout,
  DrawerLayoutProps,
} from 'components/DrawerLayout/DrawerLayout';
import {
  DocumentType,
  EcmStorageFormInitialDataQuery,
  GetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCanModifyAccess } from './toolkit/hooks/useCanModifyAccess';

export interface DocumentAccessDrawerProps
  extends Pick<DrawerLayoutProps, 'compact'> {
  isOpen: boolean;
  onCloseDrawer: () => void;
  globalDocumentId: string;
  documentType: DocumentType;
  isSensitiveDocument: boolean;
  accessModificationPermissions:
    | NonNullable<
        GetDocumentForDraftQuery['getDocument']
      >['accessModificationPermissions']
    | NonNullable<
        EcmStorageFormInitialDataQuery['getAggregatedEcmDocument']
      >['accessModificationPermissions'];
}

export const DocumentAccessDrawer = ({
  isOpen,
  onCloseDrawer,
  compact,
  globalDocumentId,
  documentType,
  isSensitiveDocument,
  accessModificationPermissions,
}: DocumentAccessDrawerProps) => {
  const [t] = useTranslation();
  const formId = useId();
  const [submitting, setSubmitting] = useState(false);
  const { canModifyDocumentAccess } = useCanModifyAccess({
    accessModificationPermissions,
  });

  return (
    <Drawer isOpen={isOpen} onClose={onCloseDrawer}>
      <DrawerLayout
        compact={compact}
        onClose={onCloseDrawer}
        header={
          <Heading as="h3">{t('common:documentAccess.drawer.title')}</Heading>
        }
        footer={
          <Button
            type="submit"
            form={formId}
            loading={submitting}
            disabled={submitting || !canModifyDocumentAccess}
          >
            {t('common.save')}
          </Button>
        }
      >
        <DocumentAccessSections
          documentType={documentType}
          formId={formId}
          accessModificationPermissions={accessModificationPermissions}
          globalDocumentId={globalDocumentId}
          isSensitiveDocument={isSensitiveDocument}
          onCloseDrawer={onCloseDrawer}
          setSubmitting={setSubmitting}
        />
      </DrawerLayout>
    </Drawer>
  );
};

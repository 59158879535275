import { Item } from '@candisio/design-system';
import { GridList, GridListProps } from 'components/GridList/GridList';
import { EmptyDataState } from 'components/GridList/components/EmptyDataState';
import { EmptySearchState } from 'components/GridList/components/EmptySearchState';
import { TeamsSortField } from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { SETTINGS_ROUTES } from 'views/Settings/types';
import { Team } from '../../toolkit/hooks/useGetTeams';
import { CREATE_TEAM_URL_PARAM } from '../../toolkit/hooks/useModalVisibility';
import { TeamDetails } from './TeamDetails';

interface TeamsListProps {
  teams: Team[];
  search: string;
  sort: TeamsSortField;
  isLoading?: boolean;
  onSearch: (search: string) => void;
  onLoadMore: () => void;
  onResetSearch: () => void;
  onSort: (sort: Key[]) => void;
}

export const TeamsList = ({
  teams,
  search,
  isLoading = false,
  sort,
  onSearch,
  onLoadMore,
  onResetSearch,
  onSort,
}: TeamsListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const navigate = useNavigate();
  const organizationSlug = useOrganizationId();
  const { searchParams } = useMutateSearchParams();

  const createTeamPath = generatePath(
    `/:${AppRouteParams.organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.TEAMS}/:id?`,
    { organizationSlug, id: CREATE_TEAM_URL_PARAM }
  );

  const handleCreateTeam = () => {
    navigate(createTeamPath);
  };

  const sortOptions = [
    { id: TeamsSortField.Name, label: t('teams.list.sorts.byName') },
    { id: TeamsSortField.CreatedAt, label: t('teams.list.sorts.createdAt') },
    { id: TeamsSortField.MemberCount, label: t('teams.list.sorts.byTeamSize') },
  ];

  const sortMenu: Record<TeamsSortField, string> = {
    NAME: t('teams.list.sorts.byName'),
    CREATED_AT: t('teams.list.sorts.createdAt'),
    MEMBER_COUNT: t('teams.list.sorts.byTeamSize'),
  };

  const filtersAndSortButtons: GridListProps['filtersAndSorts'] = [
    {
      onClick: onSort,
      text: sortMenu[sort],
      value: [sort],
      options: sortOptions,
    },
  ];

  const handleShowTeamDetails = (id: string) => {
    const editTeamUrl = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.TEAMS}/:id?`,
      { organizationSlug: organizationSlug, id }
    );

    navigate({ pathname: editTeamUrl, search: searchParams.toString() });
  };

  return (
    <GridList
      // Force list to re-mount when sort has changed so that we start again frm the initial item
      key={`isLoading-${sort}`}
      isLoading={isLoading}
      createText={t('teams.list.create')}
      search={search}
      clearSearchTooltip={t('teams.clearSearchTooltip')}
      filtersAndSorts={filtersAndSortButtons}
      emptySearchState={<EmptySearchState searchReset={onResetSearch} />}
      emptyDataState={
        <EmptyDataState
          translation="settings:teams.list.emptyState"
          url={createTeamPath}
        />
      }
      searchPlaceholder={t('teams.searchPlaceholder')}
      onCreate={handleCreateTeam}
      onSearch={onSearch}
      onEndReached={onLoadMore}
      children={teams.map((team, index) => (
        <Item key={index} textValue={team.name}>
          <TeamDetails
            team={team}
            search={search}
            onShowTeamDetails={handleShowTeamDetails}
          />
        </Item>
      ))}
    />
  );
};

import { ReactNode } from 'react';
import { sortByStringProp } from 'utils/sorting';

export const fetchPolicy = 'cache-and-network';
export const paginationRecordLimit = 20;

interface AccountingDataOption {
  id: string;
  readableName: string;
}

export interface PaginationWithSearchFilterHookParams {
  filteredValues: string[];
  searchStr: string;
}

export const toUniqueOptions = (filterOptions: FilterOption[]) => {
  const existingValues: string[] = [];

  return (filterOptions ?? []).reduce<Array<FilterOption>>((acc, option) => {
    if (existingValues.includes(option.id)) {
      return acc;
    }

    existingValues.push(option.id);

    return [...acc, option];
  }, []);
};

const toOption = (data: AccountingDataOption): FilterOption => ({
  label: data.readableName,
  id: data.id,
});

export const getOptionsFromNetworkData = (
  byKeysData?: AccountingDataOption[] | null,
  byNameData?: AccountingDataOption[] | null
): FilterOption[] => {
  const byKeys = [...(byKeysData ?? [])].sort(sortByStringProp('readableName'));
  const byName = [...(byNameData ?? [])].sort(sortByStringProp('readableName'));

  return [...byKeys, ...byName].map(toOption);
};

export type FilterOption = {
  id: string;
  label: ReactNode | string;
};

export type PaginationWithSearchFilterHook = ({
  filteredValues,
  searchStr,
}: PaginationWithSearchFilterHookParams) => {
  filterOptions: FilterOption[];
  loading?: boolean;
  loadMore?: () => void;
  handleDebounceSearch?: (inputStr: string) => void;
};

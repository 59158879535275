import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import {
  PromotionWrapper,
  TableWrapper,
  Wrapper,
} from 'components/ProductPromotions/Wrapper';
import { CREDIT_CARDS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { TransactionsTable } from 'components/Transactions/Table/TransactionsTable';
import { noop } from 'lodash';
import { useGetCustomPromotionDetails } from '../hooks/useGetCustomPromotionDetails';
import { transactionData } from './tableData';

const ctaButtonUrlMap = {
  Archive: CREDIT_CARDS_UTM_SOURCE.ARCHIVE,
  Inbox: CREDIT_CARDS_UTM_SOURCE.INBOX,
};

export const CreditCardsPromotionTransaction = ({
  routeType,
}: {
  routeType: 'Archive' | 'Inbox';
}) => {
  const { creditCardsPromotion } = usePromotionContent();
  const customPromotionDetails = useGetCustomPromotionDetails(
    'creditCards',
    ctaButtonUrlMap[routeType]
  );

  return (
    <Wrapper>
      <TableWrapper>
        <TransactionsTable
          data={transactionData}
          onEndReached={noop}
          onSearch={noop}
          onFilter={noop}
          isLoading={false}
          onSort={noop}
          borderTopRadius="none"
        />
      </TableWrapper>
      <PromotionWrapper>
        <PromotionsProvider promotions={[creditCardsPromotion]}>
          <PromotionCard
            promotion={creditCardsPromotion}
            closable={false}
            customPromotionDetails={customPromotionDetails}
            trackOption={{ eventLocation: ctaButtonUrlMap[routeType] }}
          />
        </PromotionsProvider>
      </PromotionWrapper>
    </Wrapper>
  );
};

import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import {
  DocumentRelationshipsQuery,
  EcmDocumentStatus,
  type GetDocumentForManageRelationshipsQuery,
  type GetEcmDocumentForManageRelationshipsQuery,
  type Maybe,
} from 'generated-types/graphql.types';

type DocumentLink =
  DocumentRelationshipsQuery['documentRelationships']['relationships'][number];

type LinkedDocument = DocumentLink['linkedDocument'];

type RelatedDocument = Extract<
  NonNullable<LinkedDocument>,
  { __typename?: 'RelatedDocument' }
>['document'];

type DocumentRelationship = Extract<
  RelatedDocument,
  { __typename?: 'Document' }
>;

type AggregatedEcmDocumentRelationship = Extract<
  RelatedDocument,
  { __typename?: 'AggregatedEcmDocument' }
>;

const mapFromDocumentType = (
  document: DocumentRelationship,
  id: Maybe<string>
): EcmDocumentsTableData => ({
  id: id ?? '',
  globalDocumentId: document.globalId ?? '',
  documentType: 'INVOICE',
  contact: document.contact?.value.name.value ?? '',
  invoiceId: id ?? '',
  notes: document.note?.value ?? '',
  documentName: document.documentFile?.name ?? '',
  documentNumber: document.invoiceNumber?.value ?? '',
  documentDate: document.createdAt ? new Date(document.createdAt) : undefined,
  documentStatus: {
    status: (document.status as unknown as EcmDocumentStatus) ?? undefined,
    isSensitive: false,
  },
  tags: document.tags ?? [],
});

const mapFromAggregatedEcmDocumentType = (
  document: AggregatedEcmDocumentRelationship,
  id: Maybe<string>
): EcmDocumentsTableData => ({
  id: id ?? '',
  globalDocumentId: document.globalDocumentId ?? '',
  documentType: document.type ?? '',
  contact: document.contactName ?? '',
  invoiceId: id ?? '',
  notes: document?.notes ?? '',
  documentName: document?.documentName ?? '',
  documentNumber: document?.documentNumber ?? '',
  documentDate: document?.documentDate
    ? new Date(document?.documentDate)
    : undefined,
  documentStatus: {
    status: document?.documentStatus ?? undefined,
    isSensitive: document?.isSensitive ?? false,
  },
  tags: document?.tags ?? [],
});

export const mapRelationshipToTableRows = (r: DocumentLink) => {
  if (!r.linkedDocument) return;

  if (r.linkedDocument?.__typename !== 'RelatedDocument') return;

  if (r.linkedDocument.document?.__typename === 'Document')
    return mapFromDocumentType(r.linkedDocument.document, r.linkedDocument.id);

  if (r.linkedDocument.document?.__typename === 'AggregatedEcmDocument')
    return mapFromAggregatedEcmDocumentType(
      r.linkedDocument.document,
      r.linkedDocument.id
    );

  return;
};

type SourceDocument =
  | GetDocumentForManageRelationshipsQuery['getDocument']
  | GetEcmDocumentForManageRelationshipsQuery['getAggregatedEcmDocument'];

export const mapSourceDocumentToTableRows = (document: SourceDocument) => {
  if (document?.__typename === 'Document') {
    const sourceDocument = mapFromDocumentType(document, document.id);

    sourceDocument.isDisabled = true;
    return sourceDocument;
  }

  if (document?.__typename === 'AggregatedEcmDocument') {
    const sourceDocument = mapFromAggregatedEcmDocumentType(
      document,
      document.id
    );

    sourceDocument.isDisabled = true;
    return sourceDocument;
  }
};

import { Locale } from 'generated-types/graphql.types';
import { Transition } from 'motion/react';
import columnDE from '../media/column - de.png';
import columnEN from '../media/column - en.png';
import overlayDE from '../media/overlay - de.png';
import overlayEN from '../media/overlay - en.png';
import rowDE from '../media/row - de.png';
import rowEN from '../media/row - en.png';

const enPromoImages = [rowEN, columnEN, overlayEN];
const dePromoImages = [rowDE, columnDE, overlayDE];

export const baselineSearchPromotionImages: Record<Locale, string[]> = {
  EN: enPromoImages,
  DE: dePromoImages,
};

export const maxBaselineSearchPromotionImages = 3;

export const sliderVariants = {
  incoming: {
    opacity: 0,
  },
  active: { opacity: 1 },
  exit: {
    opacity: 0.2,
  },
};

export const sliderTransition: Transition = { type: 'tween', duration: 0.8 };

export const popoverVariants = {
  animate: { y: 0, opacity: 1 },
  initial: { y: -10, opacity: 0 },
};

export const popoverTransition = { type: 'spring', duration: 2, bounce: 0.5 };

import {
  Box,
  Flex,
  Modal,
  ModalProps,
  Tag,
  Text,
} from '@candisio/design-system';
import { SearchField } from 'components/SearchField/SearchField';
import { debounce } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThreeWayMatchTable } from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/ThreeWayMatchTable/ThreeWayMatchTable';
import {
  ThreeWayMatchModalTab,
  ThreeWayMatchTableWrapper,
} from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/ThreeWayMatchTable/ThreeWayMatchTableWrapper';

export interface ThreeWayMatchModalProps {
  isOpen: boolean;
  modalProps: ModalProps;
}

export function ThreeWayMatchModal({
  modalProps,
  isOpen,
}: ThreeWayMatchModalProps) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const [activeTab, setActiveTab] = useState<ThreeWayMatchModalTab>('quantity');
  const [searchString, setSearchString] = useState('');

  const resetModalState = useMemo(
    () =>
      debounce(() => {
        setSearchString('');
        setActiveTab('quantity');
      }, 100),
    []
  );

  useEffect(() => {
    if (!isOpen) {
      resetModalState();
    }
  }, [isOpen, resetModalState]);

  return (
    <Modal
      {...modalProps}
      isOpen={isOpen}
      background="gray200"
      overflow="hidden"
      height="90vh"
      width="90vw"
      padding="space4 space32 space32 space32"
      shouldCloseOnBlur
      closeLabel={t('addPurchaseOrderModal.close')}
      titleInfo={
        <Flex alignItems="center" gap="space8">
          <Text fontSize="xxlarge">{t('threeWayMatch.modal.header')}</Text>
          <Tag color="blue" variant="secondary" className="h-4.5">
            {t('exports:sapPromotion.betaTag')}
          </Tag>
        </Flex>
      }
    >
      <ThreeWayMatchTableWrapper
        activeTab={activeTab}
        onTabChange={setActiveTab}
      >
        <Flex
          direction="column"
          borderTopRadius="basic"
          background="gray100"
          padding={0}
        >
          <Box padding={0} paddingX="space16" paddingY="space16" width="24rem">
            <SearchField
              clearTooltip={t('purchase-orders:search.clearTooltip')}
              placeholder={t('threeWayMatch.table.searchPlaceholder')}
              initialValue={searchString}
              onChange={setSearchString}
            />
          </Box>
        </Flex>
        <ThreeWayMatchTable activeTab={activeTab} searchString={searchString} />
      </ThreeWayMatchTableWrapper>
    </Modal>
  );
}

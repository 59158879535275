import { Grid, Tag, Text, TruncatedText } from '@candisio/design-system';
import clsx from 'clsx';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { CommentContainer } from 'components/Comment/CommentContainer';
import {
  DocumentHistoryContainer,
  DocumentHistoryContainerProps,
} from 'components/DocumentHistory/DocumentHistoryContainer';
import { useDocumentHistory } from 'components/DocumentHistory/useDocumentHistory';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { useTourPopover } from 'components/TourPopover/useTourPopover';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DocumentRelations,
  DocumentRelationsPromo,
  DocumentRelationsProps,
} from 'views/Inbox/DocumentProcessing/components/DocumentRelations';
import { useDocumentRelations } from 'views/Inbox/DocumentProcessing/components/hooks/useDocumentRelations';
import { useDocumentTransactions } from 'views/Inbox/DocumentProcessing/components/hooks/useDocumentTransactions';
import { ManageRelationshipsActivationPopover } from './ManageRelationshipsActivationPopover';

type DocumentSummaryContainerProps = DocumentRelationsProps &
  DocumentHistoryContainerProps;

export const DocumentSummarySection = ({
  globalDocumentId,
  documentId,
  documentName,
  documentStatus,
  canAddTransaction = false,
  readOnly = false,
}: DocumentSummaryContainerProps) => {
  const { showDocumentRelations } = useEcm();

  return (
    <>
      {!showDocumentRelations && <DocumentRelationsComponentPromo />}
      {showDocumentRelations && (
        <DocumentRelationsComponent
          globalDocumentId={globalDocumentId}
          documentId={documentId}
          documentName={documentName}
          documentStatus={documentStatus}
          canAddTransaction={canAddTransaction}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

const DocumentRelationsComponentPromo = memo(() => {
  const [t] = useTranslation();

  return (
    <CollapsibleCard
      borderRadius="medium"
      maxHeight="55cqh"
      overflow="hidden"
      id="document-summary-relationships-open"
      headerProps={{ background: 'gray0' }}
      header={<PromoCardHeader />}
      data-testid={t('document.tabs.relationships.title')}
      defaultOpen
    >
      <DocumentRelationsPromo />
    </CollapsibleCard>
  );
});

const PromoCardHeader = memo(() => {
  const [t] = useTranslation();

  return (
    <Grid templateColumns="auto auto 1fr" alignItems="center" gap="space8">
      <Text>{t('document.tabs.relationships.title')}</Text>
      <Tag color="purple" variant="secondary">
        {t('entitlements:promo.tag')}
      </Tag>
    </Grid>
  );
});

const DocumentRelationsComponent = memo(
  ({
    globalDocumentId,
    documentId,
    documentName,
    documentStatus,
    canAddTransaction,
    readOnly,
  }: DocumentRelationsProps) => {
    const [t] = useTranslation();

    const { linkedDocumentsCount, loadingLinkedDocuments } = useLinkedDocuments(
      documentId,
      globalDocumentId
    );

    return (
      <CollapsibleCard
        borderRadius="medium"
        maxHeight="55cqh"
        id="document-summary-relationships-open"
        headerProps={{ background: 'gray0' }}
        header={
          <CardHeader
            linkedDocumentsCount={linkedDocumentsCount}
            loadingLinkedDocuments={loadingLinkedDocuments}
          />
        }
        data-testid={t('document.tabs.relationships.title')}
      >
        <DocumentRelations
          globalDocumentId={globalDocumentId}
          documentId={documentId}
          documentName={documentName}
          documentStatus={documentStatus}
          canAddTransaction={canAddTransaction}
          readOnly={readOnly}
        />
      </CollapsibleCard>
    );
  }
);

export const DocumentHistoryComponent = memo(
  ({
    documentId,
    isEcmDocument,
    isSensitiveDocument,
  }: DocumentHistoryContainerProps) => {
    const [t] = useTranslation();
    const {
      documentData: documentHistoryData,
      loading: loadingDocumentHistory,
    } = useDocumentHistory(documentId);

    const [modifyDocumentAccessFF] = useCandisFeatureFlags([
      FEATURE_FLAGS.modifyDocumentAccess,
    ]);

    const { showDocumentRelationsImproved } = useEcm();

    const timelineEntriesCount = documentHistoryData?.timeline?.length ?? 0;
    const showCountSuffix = !loadingDocumentHistory && timelineEntriesCount > 0;
    const countSuffix = showCountSuffix ? ` (${timelineEntriesCount})` : '';

    const charsAfterEllipsis = countSuffix.length && countSuffix.length - 1;
    const historyTitle = t('document.tabs.protocol') + countSuffix;

    const title = (
      <TruncatedText
        charsAfterEllipsis={charsAfterEllipsis}
        fontWeight="semibold"
      >
        {historyTitle}
      </TruncatedText>
    );

    return (
      <div
        className={clsx('pb-4', {
          'flex-1': showDocumentRelationsImproved,
        })}
      >
        <CollapsibleCard
          borderRadius="medium"
          overflow="visible"
          id="document-summary-protocol-open"
          headerProps={{ background: 'gray0' }}
          header={title}
          data-testid={t('document.tabs.protocol')}
          defaultOpen
          isStatic={showDocumentRelationsImproved}
          height={showDocumentRelationsImproved ? '100%' : undefined}
        >
          <div
            className={clsx('overflow-hidden bg-white pb-2 rounded-[inherit]', {
              'h-full': showDocumentRelationsImproved,
            })}
          >
            <div className="py-4 px-2">
              <CommentContainer
                documentId={documentId}
                isEcmDocument={isEcmDocument}
                restrictMentions={
                  isSensitiveDocument && !modifyDocumentAccessFF
                }
              />
            </div>
            <DocumentHistoryContainer documentId={documentId} />
          </div>
        </CollapsibleCard>
      </div>
    );
  }
);

type CardHeaderProps = {
  linkedDocumentsCount: number;
  loadingLinkedDocuments: boolean;
};

const CardHeader = memo(
  ({ linkedDocumentsCount, loadingLinkedDocuments }: CardHeaderProps) => {
    const [t] = useTranslation();

    const { targetRef, targetProps, isOpen, popoverProps, popoverRef, close } =
      useTourPopover({
        id: TOUR_POPOVER_IDS.MANAGE_RELATIONSHIPS_SEEN,
        placement: 'right',
        delay: 2000,
      });

    const showCountSuffix = !loadingLinkedDocuments && linkedDocumentsCount > 0;
    const countSuffix = showCountSuffix ? ` (${linkedDocumentsCount})` : '';
    const charsAfterEllipsis = countSuffix.length && countSuffix.length - 1;

    const relationshipsTitle =
      t('document.tabs.relationships.title') + countSuffix;

    return (
      <>
        <Grid
          autoFlow="column"
          justifyContent="start"
          alignItems="center"
          gap="space8"
          {...targetProps}
          ref={targetRef}
        >
          <TruncatedText charsAfterEllipsis={charsAfterEllipsis}>
            {relationshipsTitle}
          </TruncatedText>
        </Grid>
        {isOpen && (
          <ManageRelationshipsActivationPopover
            onClose={close}
            popoverProps={popoverProps}
            popoverRef={popoverRef}
          />
        )}
      </>
    );
  }
);

const useLinkedDocuments = (documentId: string, globalDocumentId: string) => {
  const { relatedDocuments, isLoading: loadingRelations } =
    useDocumentRelations(globalDocumentId);

  const { transaction, isLoading: loadingTransactions } =
    useDocumentTransactions(documentId);

  const loadingLinkedDocuments = loadingRelations || loadingTransactions;

  const linkedDocumentsCount =
    Number(relatedDocuments?.length) + Number(Boolean(transaction));

  const value = useMemo(
    () => ({ linkedDocumentsCount, loadingLinkedDocuments }),
    [linkedDocumentsCount, loadingLinkedDocuments]
  );

  return value;
};

import {
  SplitBookingsFormFieldOptions,
  SplitBookingsFormPropsDS,
} from 'components/Form/SplitBookingsForm/types';
import { useCostCenterFieldOptions } from 'components/Form/hooks/useCostCenterFieldOptions';
import { useGeneralLedgerAccountFieldOptions } from 'components/Form/hooks/useGeneralLedgerAccountFieldOptions';
import { useProjectCodeFieldOptions } from 'components/Form/hooks/useGetProjectCodeFieldOptions';
import {
  ArtistSocialInsuranceCode,
  CostCenterTypes,
  useProcessingFormFieldItemsQuery,
} from 'generated-types/graphql.types';
import { toAccountingDataFieldItem } from 'views/Inbox/DocumentProcessing/toAccountingDataFieldItem';
import { useToArtistSocialInsuranceCodeFieldItem } from 'views/Inbox/DocumentProcessing/useToArtistSocialInsuranceCodeFieldItem';
import {
  useShowArtistSocialInsuranceCodeField,
  useShowCostCenterField,
  useShowCostObjectField,
  useShowExtraCostInfoField,
  useShowGeneralLedgerAccountField,
  useShowPostingTextField,
  useShowProjectCodeField,
  useShowTaxCodeField,
} from 'views/utils/useShouldShowField';

export const useSplitBookingsFieldOptions = ({
  defaultValues,
  shouldRequireQuantity = true,
}: {
  defaultValues: SplitBookingsFormPropsDS['initialValues'];
  shouldRequireQuantity?: boolean;
}): { fieldOptions: SplitBookingsFormFieldOptions; isLoading: boolean } => {
  const showTaxCodeField = useShowTaxCodeField();
  const showPostingTextField = useShowPostingTextField();
  const showCostCenterField = useShowCostCenterField();
  const showCostObjectField = useShowCostObjectField();
  const showExtraCostInfoField = useShowExtraCostInfoField();
  const showGeneralLedgerAccountField = useShowGeneralLedgerAccountField();
  const showArtistSocialInsuranceCodeField =
    useShowArtistSocialInsuranceCodeField();
  const showProjectCodeField = useShowProjectCodeField();
  const { data: fieldItemsData, loading } = useProcessingFormFieldItemsQuery();

  const toArtistSocialInsuranceCodeFieldItem =
    useToArtistSocialInsuranceCodeFieldItem();

  const defaultGLAs = (defaultValues?.bookings || [])
    .map(b => b?.generalLedgerAccount?.value)
    .filter(Boolean) as string[];

  const generalLedgerAccountFieldOptions = useGeneralLedgerAccountFieldOptions({
    skip: !showGeneralLedgerAccountField,
    ids:
      !showGeneralLedgerAccountField && defaultGLAs.length
        ? defaultGLAs
        : undefined,
  });

  const defaultCCs = (defaultValues?.bookings || [])
    .map(b => b?.costCenter?.value)
    .filter(Boolean) as string[];

  const costCenterFieldOptions = useCostCenterFieldOptions({
    skip: !showCostCenterField,
    type: CostCenterTypes.CostCenter,
    ids: !showCostCenterField && defaultCCs.length ? defaultCCs : undefined,
  });

  const defaultCOs = (defaultValues?.bookings || [])
    .map(b => b?.costObject?.value)
    .filter(Boolean) as string[];

  const defaultProjectCodes = (defaultValues?.bookings || [])
    .map(b => b?.projectCode?.value)
    .filter(Boolean) as string[];

  const costObjectFieldOptions = useCostCenterFieldOptions({
    skip: !showCostObjectField,
    type: CostCenterTypes.CostObject,
    ids: !showCostObjectField && defaultCOs.length ? defaultCOs : undefined,
  });

  const defaultCIs = (defaultValues?.bookings || [])
    .map(b => b?.extraCostInfo?.value)
    .filter(Boolean) as string[];

  const extraCostInfoFieldOptions = useCostCenterFieldOptions({
    skip: !showExtraCostInfoField,
    type: CostCenterTypes.ExtraCostInfo,
    ids: !showExtraCostInfoField && defaultCIs.length ? defaultCIs : undefined,
  });
  const projectCodeFieldOptions = useProjectCodeFieldOptions({
    skip: !showProjectCodeField,
    ids:
      !showProjectCodeField && defaultProjectCodes.length
        ? defaultProjectCodes
        : undefined,
  });

  const { taxCodes = [] } = fieldItemsData ?? {};

  const fieldOptions: SplitBookingsFormFieldOptions = {
    artistSocialInsuranceCode: {
      hidden:
        !showArtistSocialInsuranceCodeField &&
        !defaultValues?.bookings?.[0]?.artistSocialInsuranceCode,
      props: {
        defaultItems: Object.values(ArtistSocialInsuranceCode).map(
          toArtistSocialInsuranceCodeFieldItem
        ),
      },
    },
    costCenter: costCenterFieldOptions,
    costObject: costObjectFieldOptions,
    extraCostInfo: extraCostInfoFieldOptions,
    generalLedgerAccount: generalLedgerAccountFieldOptions,
    postingText: {
      hidden:
        !showPostingTextField && !defaultValues?.bookings?.[0]?.postingText,
      props: {},
    },
    taxCode: {
      hidden: !showTaxCodeField && !defaultValues?.bookings?.[0]?.taxCode,
      props: { defaultItems: taxCodes.map(toAccountingDataFieldItem) },
    },
    quantity: {
      hidden: !shouldRequireQuantity,
    },
    projectCode: projectCodeFieldOptions,
  };

  return { fieldOptions, isLoading: loading };
};

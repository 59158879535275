import { Text } from '@candisio/design-system';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ExpenseInvoiceInputField } from './ExpenseInvoiceInputField';
import { ExpenseInvoicePreviewContainer } from './ExpenseInvoicePreviewContainer';
import { BooleanMap } from './utils/useReimbursementItemsFormActions';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

interface InvoiceSectionProps {
  formIndex: number;
  showInvoicePreview: boolean;
  invoice: ExpenseInvoice | undefined;
  isCreatingInvoice?: BooleanMap;
  canManageReimbursementItems: boolean;
  onInvoiceChange: (file: File, index: number) => Promise<void>;
  handleInvoiceUpdate: () => Promise<void>;
  isExcluded: boolean;
}

export const InvoiceSection = ({
  formIndex,
  showInvoicePreview,
  invoice,
  isCreatingInvoice,
  canManageReimbursementItems,
  onInvoiceChange,
  handleInvoiceUpdate,
  isExcluded,
}: InvoiceSectionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  return (
    <div className="grid gap-1 h-fit">
      <Text lineHeight={1}>
        {t('reimbursementView.middleSection.form.invoice')}
      </Text>
      {showInvoicePreview && (
        <ExpenseInvoicePreviewContainer
          name={`reimbursementItems.${formIndex}.files`}
          invoice={invoice}
          height="700px"
          createInvoicePending={isCreatingInvoice?.[formIndex]}
          isReadOnly={!canManageReimbursementItems}
          id={`invoice-picker-reimbursementItems.${formIndex}.files`}
          onChange={async file => await onInvoiceChange(file, formIndex)}
          onInvoiceUpdate={handleInvoiceUpdate}
          isExcluded={isExcluded}
        />
      )}
      {!showInvoicePreview && (
        <ExpenseInvoiceInputField
          name={`reimbursementItems.${formIndex}.files`}
          id={`invoice-input-field-reimbursementItems.${formIndex}.files`}
          onChange={async file => await onInvoiceChange(file, formIndex)}
          onInvoiceUpdate={handleInvoiceUpdate}
        />
      )}
    </div>
  );
};

import { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useGetPath } from 'hooks/usePath';
import styles from 'components/CellWrapperLink/CellWrapperLink.module.css';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { queryParameter } from 'components/Table/consts';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';

interface LinkToContractProps {
  row: EcmDocumentsTableData;
  children?: ReactNode;
}

export const LinkToContract = ({ row, children }: LinkToContractProps) => {
  const organizationSlug = useOrganizationId();
  const { searchParams } = useMutateSearchParams();
  const getPathInternal = useGetPath();
  const path = useMemo(() => {
    if (!organizationSlug) return '';
    const searchQuery = searchParams.get(queryParameter) ?? '';

    if (row.cursor) searchParams.set('cursor', row.cursor);
    if (row.documentType) {
      searchParams.set('documentType', row.documentType);
    }
    if (searchQuery) {
      searchParams.set(queryParameter, searchQuery);
    }

    return getPathInternal({
      pathname: '/archive/contracts/:documentId',
      params: {
        documentId: row.id,
      },
      search: searchParams.toString(),
    });
  }, [organizationSlug, row, getPathInternal, searchParams]);

  return (
    <Link to={path} className={styles['cell-wrapper']}>
      {children}
    </Link>
  );
};

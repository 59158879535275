// callbacks to update dependent cells here

import { useFormContext, useWatch } from 'react-hook-form';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';
import { useSap } from 'orgConfig/sap';
import { netToGross } from 'containers/SplitBookings/toolkit/utils';
import { grossToNet } from 'containers/SplitBookings/toolkit/utils';
import { useBookingKeysActiveQuery } from 'generated-types/graphql.types';
import { BookingsFormValues, TableFormRow } from '../schema/bookingsFormSchema';
import { useCallback } from 'react';

type AmountValue = TableFormRow['amount'];
type NetAmountValue = TableFormRow['netAmount'];
type TaxAmountValue = TableFormRow['taxAmount'];
type TaxCodeValue = TableFormRow['taxCode'];

export const useGetOnAmountChange = (index: number) => {
  const { shouldUseSapNetAmount } = useSap();

  const { data: bookingKeysActiveData } = useBookingKeysActiveQuery({
    skip: !shouldUseSapNetAmount,
  });
  const bookingKeys = bookingKeysActiveData?.bookingKeysActive ?? [];

  const taxCode = useWatch<BookingsFormValues, `bookings.${number}.taxCode`>({
    name: `bookings.${index}.taxCode`,
    disabled: !shouldUseSapNetAmount, // disables subscription
  });

  const { setValue } = useFormContext<BookingsFormValues>();

  return useCallback(
    (value: AmountValue) => {
      if (!shouldUseSapNetAmount) {
        return;
      }
      const selectedBookingKey = bookingKeys.find(
        bookingKey => bookingKey.id === taxCode
      );

      const isAcquisitionReverse = selectedBookingKey?.isAcquisitionReverse;

      if (isAcquisitionReverse) {
        const taxAmount = 0;
        const netAmount = value ?? 0;
        setValue(`bookings.${index}.taxAmount`, taxAmount);
        setValue(`bookings.${index}.netAmount`, netAmount);

        return;
      }

      const vatRate = selectedBookingKey?.taxPercentage ?? 0;
      const netAmount = grossToNet(value ?? 0, vatRate);
      const taxAmount = roundToCurrencyPrecision((value ?? 0) - netAmount);
      setValue(`bookings.${index}.taxAmount`, taxAmount);
      setValue(`bookings.${index}.netAmount`, netAmount);
    },
    [bookingKeys, index, setValue, taxCode, shouldUseSapNetAmount]
  );
};

export const useGetNetAmountChange = (index: number) => {
  const { shouldUseSapNetAmount } = useSap();

  const { data: bookingKeysActiveData } = useBookingKeysActiveQuery({
    skip: !shouldUseSapNetAmount,
  });
  const bookingKeys = bookingKeysActiveData?.bookingKeysActive ?? [];

  const taxCode = useWatch<BookingsFormValues, `bookings.${number}.taxCode`>({
    name: `bookings.${index}.taxCode`,
    disabled: !shouldUseSapNetAmount, // disables subscription
  });

  const { setValue } = useFormContext<BookingsFormValues>();

  return useCallback(
    (value: NetAmountValue) => {
      const selectedCode = bookingKeys.find(
        bookingKey => bookingKey.id === taxCode
      );
      const netAmount = value ?? 0;
      let amount = netToGross(netAmount, selectedCode?.taxPercentage ?? 0);
      let taxAmount = roundToCurrencyPrecision(amount - netAmount);

      const isAcquisitionReverse = selectedCode?.isAcquisitionReverse;

      if (isAcquisitionReverse) {
        taxAmount = 0;
        amount = netAmount;
      }
      const isGoodsItem = false; // TODO will be added with goods invoice

      if (isGoodsItem) {
        // calculate unit price = netAmount / quantity
      } else {
        setValue(`bookings.${index}.taxAmount`, taxAmount);
        setValue(`bookings.${index}.amount`, amount);
      }
    },
    [bookingKeys, index, setValue, taxCode]
  );
};

export const useGetTaxAmountChange = (index: number) => {
  const { shouldUseSapNetAmount } = useSap();

  const netAmount = useWatch<
    BookingsFormValues,
    `bookings.${number}.netAmount`
  >({
    name: `bookings.${index}.netAmount`,
    disabled: !shouldUseSapNetAmount,
    exact: true,
  });
  const { setValue } = useFormContext<BookingsFormValues>();

  return useCallback(
    (value: TaxAmountValue) => {
      if (!shouldUseSapNetAmount) {
        return;
      }
      const amount = roundToCurrencyPrecision((value ?? 0) + (netAmount ?? 0));
      setValue(`bookings.${index}.amount`, amount);
    },
    [index, setValue, netAmount, shouldUseSapNetAmount]
  );
};

export const useGetTaxCodeChange = (index: number) => {
  const { shouldUseSapNetAmount } = useSap();
  const { data: bookingKeysActiveData } = useBookingKeysActiveQuery({
    skip: !shouldUseSapNetAmount,
  });
  const bookingKeys = bookingKeysActiveData?.bookingKeysActive ?? [];

  const netAmount = useWatch<
    BookingsFormValues,
    `bookings.${number}.netAmount`
  >({ name: `bookings.${index}.netAmount` });
  const { setValue } = useFormContext<BookingsFormValues>();

  return useCallback(
    (val: TaxCodeValue) => {
      if (!shouldUseSapNetAmount) {
        return;
      }
      const selectedCode = bookingKeys.find(
        bookingKey => bookingKey.id === val
      );

      let amount = netToGross(netAmount ?? 0, selectedCode?.taxPercentage ?? 0);
      const isAcquisitionReverse = selectedCode?.isAcquisitionReverse;

      if (isAcquisitionReverse) {
        amount = netAmount ?? 0;
      }

      const taxAmount = roundToCurrencyPrecision(amount - (netAmount ?? 0));
      setValue(`bookings.${index}.taxAmount`, taxAmount);
      setValue(`bookings.${index}.amount`, amount);
      // TODO (ask: why we update vat rate, even it is not visible in UI, ask BE team)
      setValue(`bookings.${index}.vatRate`, selectedCode?.taxPercentage ?? 0);
    },
    [bookingKeys, index, netAmount, setValue, shouldUseSapNetAmount]
  );
};

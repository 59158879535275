import {
  DocumentStatus,
  EcmDocumentStatus,
  AssociationSource,
} from 'generated-types/graphql.types';
import { createContext, useContext } from 'react';
import { ThreeWayMatchRowData } from './types';

export interface ThreeWayMatchContextType {
  newData: ThreeWayMatchRowData[];
  linkedDocumentCount: number;
  isLoading?: boolean;
  documentStatus?: DocumentStatus | EcmDocumentStatus;
  documentId?: string;
  contactName?: string;
  accountsPayableNumber?: string | null;
  documentAmount?: number | null;
  isPurchaseOrderEditable?: boolean;
  linkingSource?:
    | AssociationSource.GoodsReceipt
    | AssociationSource.PurchaseOrder;
}

export const ThreeWayMatchContext = createContext<ThreeWayMatchContextType>({
  newData: [],
  linkedDocumentCount: 0,
});

export const useThreeWayMatchContext = () => {
  return useContext(ThreeWayMatchContext);
};

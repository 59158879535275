import {
  Button,
  FieldContainer,
  Flex,
  Grid,
  Paragraph,
  Popover,
  Switch,
  Text,
  mergeProps,
  mergeRefs,
  usePopover,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface StorageFormSensitiveSwitchFieldProps<
  TFormValues extends FieldValues,
> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Field label */
  label: string;
  /** Called when we need to reset Switch's value */
  onReset?: () => void;
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean | undefined;
}

/**
 * Controlled switch field for React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 */
export const StorageFormSensitiveSwitchField = <
  TFormValues extends FieldValues,
>({
  control,
  disabled,
  label,
  name,
  onReset,
  isLoading,
  ...restProps
}: StorageFormSensitiveSwitchFieldProps<TFormValues>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { close, isOpen, open, popoverProps, popoverRef, triggerRef } =
    usePopover({ placement: 'bottom right' });

  const { field, formState } = useController({ control, name });

  const { ref, value, ...fieldProps } = field;

  const readOnly = formState.isSubmitting;

  const handleCancel = () => {
    onReset?.();
    close();
  };

  const handleConfirm = () => {
    close();
  };

  const handleOpen = () => {
    if (!value || disabled) {
      return;
    }

    open();
  };

  return (
    <FieldContainer
      transparent
      disabled={disabled}
      readOnly={readOnly}
      isLoading={isLoading}
    >
      <div className="grid items-center grid-cols-[1fr_auto] gap-2">
        <Text fontSize="small">{label}</Text>

        <Switch
          label={label}
          disabled={disabled}
          checked={value}
          onClick={handleOpen}
          {...mergeProps(fieldProps, restProps)}
          ref={mergeRefs([ref, triggerRef])}
        />

        {isOpen && (
          <Popover maxWidth="420px" ref={popoverRef} {...popoverProps}>
            <Grid gap="space16" templateRows="1fr auto">
              <Paragraph>
                {t('storageForm.fields.restrictedAccess.description')}
              </Paragraph>
              <Flex gap="space16" justifyContent="end">
                <Button variant="secondary" size="small" onClick={handleCancel}>
                  {t('storageForm.fields.restrictedAccess.cancelBtn')}
                </Button>
                <Button color="red" size="small" onClick={handleConfirm}>
                  {t('storageForm.fields.restrictedAccess.confirmBtn')}
                </Button>
              </Flex>
            </Grid>
          </Popover>
        )}
      </div>
    </FieldContainer>
  );
};

import {
  Icon,
  IconProps,
  Tooltip,
  mergeProps,
  mergeRefs,
  useTooltip,
} from '@candisio/design-system';
import { ReactNode, forwardRef } from 'react';
/**
 * @deprecated Use InfoButtonPopup instead
 *
 * Tooltip for sap amounts inputs
 */
export interface TooltipIconProps extends Omit<IconProps, 'icon'> {
  children: ReactNode;
  icon?: IconProps['icon'];
}

export const TooltipIcon = forwardRef<SVGSVGElement, TooltipIconProps>(
  (
    {
      children,
      size = 'space16',
      color = 'gray450',
      icon = 'infoOutline',
      ...restProps
    },
    forwardedRef
  ) => {
    const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
      useTooltip({
        delay: 0,
      });

    return (
      <>
        <Icon
          {...mergeProps(triggerProps, restProps)}
          ref={mergeRefs([triggerRef, forwardedRef])}
          icon={icon}
          color={color}
          size={size}
        />
        {isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {children}
          </Tooltip>
        )}
      </>
    );
  }
);

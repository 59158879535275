import { ReimbursementItemsQuery } from 'generated-types/graphql.types';
import { mapToGeneralExpense } from './mapToGeneralExpense';
import { mapToHospitalityExpense } from './mapToHospitalityExpense';
import { mapToPerDiem } from './mapToPerDiem';

type AllItemsFragments = ReimbursementItemsQuery['reimbursementItems'][number];

export const mapToReimbursementItem = (item: AllItemsFragments) => {
  if (item.__typename === 'GeneralExpenseItem') {
    return mapToGeneralExpense(item);
  }

  if (item.__typename === 'HospitalityExpenseItem') {
    return mapToHospitalityExpense(item);
  }

  if (item.__typename === 'PerDiemItem') {
    return mapToPerDiem(item);
  }
};

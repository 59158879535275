import { CollapsibleCard } from './CollapsibleCard';
import styles from './TravelPromotion.module.css';
import { PromotionTile } from './PromotionTile';
import { Entitlements, useEntitlementsFlag } from 'hooks/useCandisFeatureFlags';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { Button, Link } from '@candisio/design-system';
import { useState } from 'react';
import { PromotionModal } from 'components/NewPromotions/PromotionCard/PromotionModal/PromotionModal';
import { PromotionLabel } from 'components/NewPromotions/PromotionCard/PromotionHeader/PromotionHeader';
import { PromotionDetailsModalContent } from 'components/NewPromotions/PromotionCard/PromotionDetailsModalContent/PromotionDetailsModalContent';
import { usePromotionDetailsModalContent } from 'components/NewPromotions/hooks/usePromotionDetailsModalContent';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

type CollapsibleCardTranslations = {
  tag: string;
  heading: string;
  description: string;
  buttonCTA?: string;
  link?: {
    text: string;
    url: string;
  };
};

const TRAVEL_PROMOTION_KEY = 'travel-promotion';

export const TravelPromotion = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    TRAVEL_PROMOTION_KEY,
    false
  );

  const { promotionDetails } = usePromotionDetailsModalContent('travelBase');

  const [isPromotionModalOpen, setIsPromotionModalOpen] = useState(false);

  const entitlements = useEntitlementsFlag();

  const isBaseEntitlement = entitlements === Entitlements.BASE;

  const handleCollapse = () => {
    setIsCollapsed(prevState => !prevState);
  };

  const handleOpenModal = () => {
    setIsPromotionModalOpen(prevState => !prevState);
  };

  const translations: CollapsibleCardTranslations = isBaseEntitlement
    ? t('reimbursementView.middleSection.promotion.basePackage', {
        returnObjects: true,
      })
    : t('reimbursementView.middleSection.promotion.plusAndMaxPackage', {
        returnObjects: true,
      });

  return (
    <div className={styles.promotion} data-cy="travelPromotion">
      <CollapsibleCard
        isCollapsed={isCollapsed}
        onCollapse={handleCollapse}
        variant={isBaseEntitlement ? 'purple' : 'blue'}
        heading={translations.heading}
        description={translations.description}
        footer={
          isBaseEntitlement && (
            <div className="flex justify-start items-center gap-4">
              <Button color="purple" onClick={handleOpenModal}>
                {translations.buttonCTA}
              </Button>
              <Link
                color="purple"
                fontSize="basic"
                external
                href={translations.link?.url}
              >
                {translations.link?.text}
              </Link>
            </div>
          )
        }
        tagText={translations.tag}
      />
      <ul
        className={styles['collapsible-content']}
        data-collapsed={isCollapsed}
      >
        <PromotionTile
          icon="travel"
          description={t(
            'reimbursementView.middleSection.promotion.tiles.milage.description'
          )}
          heading={t(
            'reimbursementView.middleSection.promotion.tiles.milage.heading'
          )}
        />
        <PromotionTile
          icon="calendar"
          description={t(
            'reimbursementView.middleSection.promotion.tiles.perDiem.description'
          )}
          heading={t(
            'reimbursementView.middleSection.promotion.tiles.perDiem.heading'
          )}
        />
      </ul>
      <PromotionModal
        isModalOpen={isPromotionModalOpen}
        closeModal={handleOpenModal}
        titleInfo={
          <PromotionLabel
            label={{
              text: t('reimbursementView.middleSection.promotion.labelModal'),
            }}
          />
        }
        data-cy="promotionModal-travelBase"
      >
        <PromotionDetailsModalContent promotionDetails={promotionDetails} />
      </PromotionModal>
    </div>
  );
};

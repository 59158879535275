import { HighlightedTruncatedText } from '@candisio/design-system';
import { getCellHighlights } from 'components/WithHighlightsCell/getCellHighlights';
import { CellProps } from 'react-table';
import { SplitsCell, SplitsCellValue, getSplitsRenderType } from './SplitsCell';
import { DocumentsTableData } from '../types';

export const SplitsCellHighlightable = (
  cellProps: CellProps<DocumentsTableData, SplitsCellValue>
) => {
  const highlights = getCellHighlights(cellProps);
  const { value } = cellProps;

  const renderType = getSplitsRenderType(value);
  // Splits are not highlightable yet
  const isSplit = renderType !== 'single';

  if (!highlights || isSplit) {
    return <SplitsCell {...cellProps} />;
  }

  return <HighlightedTruncatedText highlight={highlights} />;
};

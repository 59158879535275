import { Swimlane } from 'components/Swimlane/Swimlane';
import { SwimlaneHeader } from 'components/Swimlane/SwimlaneHeader';
import { SwimlaneHeaderCount } from 'components/Swimlane/SwimlaneHeaderCount';
import { SwimlaneTiles } from 'components/Swimlane/SwimlaneTiles';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { DeclinedTransactionContentTile } from './DeclinedTransactionContentTile';
import { TransactionListDataFragment } from 'generated-types/graphql.types';
import { ShowAllDeclinedTransactions } from './ShowAllDeclinedTransactions';
import { PromoCreditCardDeclinedTransactions } from '../promos/PromoCreditCardDeclinedTransactions';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';

export const DeclinedTransactionsSwimlane = ({
  isVisible,
  declinedTransactions,
  totalCount = 0,
}: {
  isVisible: boolean;
  declinedTransactions?: TransactionListDataFragment[];
  totalCount?: number;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isPromotionSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.CREDIT_CARD_MISSING_INVOICES
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Swimlane data-cy="declinedTransactionsSwimlane">
      <SwimlaneTiles
        contentTileCount={totalCount}
        promoTileCount={!isPromotionSeen && totalCount > 0 ? 1 : undefined}
      >
        {({
          contentTileVisibleCount,
          isShowAllTileVisible,
          isPromoTileVisible,
        }) => (
          <>
            <SwimlaneHeader
              icon="creditCardCross"
              label={
                totalCount === 1
                  ? t('insights.swimlanes.declined.header.singular')
                  : t('insights.swimlanes.declined.header.plural')
              }
            >
              <SwimlaneHeaderCount
                count={contentTileVisibleCount}
                totalCount={totalCount}
              />
            </SwimlaneHeader>

            {declinedTransactions &&
              declinedTransactions.length > 0 &&
              Array.from({ length: contentTileVisibleCount }, (_, index) => (
                <DeclinedTransactionContentTile
                  key={declinedTransactions[index].id}
                  transaction={declinedTransactions[index]}
                />
              ))}

            {isShowAllTileVisible && <ShowAllDeclinedTransactions />}

            {isPromoTileVisible && <PromoCreditCardDeclinedTransactions />}
          </>
        )}
      </SwimlaneTiles>
    </Swimlane>
  );
};

import { useExtractGeneralExpenseInvoiceDataMutation } from 'generated-types/graphql.types';
import { useCallback } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { ReimbursementItemSchemaOutput } from '../toolkit/reimbursementItemsFormSchema';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsListQuery,
  reimbursementItemsSplitBookingsQuery,
} from '../toolkit/queries';
import {
  GeneralExpense,
  formattedGeneralExtractedData,
} from '../utils/mapToGeneralExpense';
import { sanitizeGeneralExpenseFormOutput } from '../utils/sanitizeGeneralExpenseFormOutput';

interface ExtractionDetails {
  isExtractionAccepted: boolean;
  extractedData?: GeneralExpense['extractedData'];
}

export const useExtractGeneralInvoiceData = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();

  const [extractGeneralInvoiceData, { loading: isGeneralInvoiceDataPending }] =
    useExtractGeneralExpenseInvoiceDataMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: reimbursementCaseByIdQuery,
          variables: { id: reimbursementId },
        },
        {
          query: reimbursementItemsListQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
        {
          query: reimbursementItemsSplitBookingsQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
        {
          query: reimbursementHistoryQuery,
          variables: { id: reimbursementId },
        },
      ],
    });

  const handleExtractGeneralInvoiceData = useCallback(
    async (
      formValues: ReimbursementItemSchemaOutput,
      onCompleted?: (data: ExtractionDetails) => void
    ) => {
      const { errors } = await extractGeneralInvoiceData({
        variables: {
          id: formValues.reimbursementItemId,
          input: {
            reimbursementCaseId: reimbursementId,
            ...sanitizeGeneralExpenseFormOutput(formValues),
            totalAmount: null,
            expenseDate: null,
            invoiceNumber: null,
          },
        },
        onCompleted: data => {
          const extractedData =
            data?.extractGeneralExpenseInvoiceData?.extractedData?.[0];
          const formattedExtractedData =
            formattedGeneralExtractedData(extractedData);

          const extractionDetails = {
            isExtractionAccepted:
              data?.extractGeneralExpenseInvoiceData.extractedDataAccepted ??
              false,
            extractedData: formattedExtractedData,
          };
          onCompleted?.(extractionDetails);
        },
      });

      if (errors?.length) {
        return { status: 'error' };
      }

      return { status: 'success' };
    },
    [reimbursementId, extractGeneralInvoiceData]
  );

  return {
    isGeneralInvoiceDataPending,
    extractGeneralInvoiceData: handleExtractGeneralInvoiceData,
  };
};

import { CellProps } from '@candisio/design-system';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ExportReimbursementItemsTableRow } from './types';
import { TruncatedText } from '@candisio/design-system/next';

const mappedTypesToTranslations: Record<ReimbursementItemType, string> = {
  GENERAL_EXPENSE: 'export.reimbursementItemType.generalExpense',
  HOSPITALITY_EXPENSE: 'export.reimbursementItemType.hospitalityExpense',
  MILEAGE: 'export.reimbursementItemType.milage',
  PER_DIEM: 'export.reimbursementItemType.perDiem',
};

export const TypeCell = ({
  value,
}: CellProps<ExportReimbursementItemsTableRow, ReimbursementItemType>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  if (!value) {
    return <span> - </span>;
  }

  const translation = mappedTypesToTranslations[value];

  return <TruncatedText>{t(translation)}</TruncatedText>;
};

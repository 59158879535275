import { Box, Button, Flex, Modal, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';

type ConfirmationModalProps = {
  visible: boolean;
  importing: boolean;
  successCount: number;
  onImport: () => void;
  onClose: () => void;
  numberOfImportedContacts?: number;
  numberOfContactsToBeImported?: number;
};

export const ContactImportConfirmationModal = ({
  visible,
  importing,
  successCount,
  onImport,
  onClose,
  numberOfContactsToBeImported,
  numberOfImportedContacts,
}: ConfirmationModalProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CONTACTS);

  const organizationName = useFullOrganization()?.name;

  const modalCtaDescription = (
    <Trans
      t={t}
      i18nKey="import.confirmation.info"
      values={{ successCount, organizationName }}
      count={successCount}
    >
      These
      {String(successCount)}
      contacts will be imported in
      <Text fontWeight="bold">{organizationName}</Text>
      You can then no longer delete them. Do you want to continue?
    </Trans>
  );

  const modalImportingDescription = t('import.confirmation.importingInfo', {
    count: numberOfContactsToBeImported,
    numberOfImportedContacts,
  });

  return (
    <Modal
      title={t('import.confirmation.title')}
      isOpen={visible}
      onClose={onClose}
    >
      <Box
        style={{
          paddingBottom: '2rem',
        }}
      >
        {importing ? modalImportingDescription : modalCtaDescription}
      </Box>
      <Flex direction="row-reverse" gap="space16">
        <Button onClick={onImport} loading={importing}>
          {t('import.confirmation.confirmButtonCTA')}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          {t('import.confirmation.cancelButtonCTA')}
        </Button>
      </Flex>
    </Modal>
  );
};

import { Flex, Text } from '@candisio/design-system';
import { ReactNode } from 'react';

export interface StatusProps {
  amount?: number;
  description: string;
  children?: ReactNode;
}

export const StatusCircle = ({
  amount,
  description,
  children,
}: StatusProps) => {
  return (
    <Flex gap="space4">
      <Text fontSize="basic">{amount}</Text>
      <Text fontSize="basic">
        {description}
        {children}
      </Text>
    </Flex>
  );
};

import {
  ContactPaymentMedium,
  ContactRelationshipType,
  IntegrationName,
} from 'generated-types/graphql.types';
import { isValidBIC, isValidIBAN } from 'ibantools';
import { isNil } from 'lodash';
import {
  ErrorMessages,
  ibanSchema,
  vatIdSchema,
} from 'utils/zodFormValidation';
import { bankAccountNumberSchema } from 'utils/zodFormValidation/Schemas/bankAccountNumberSchema';
import {
  isSwiftCountryCodeMatch,
  swiftCodeSchema,
} from 'utils/zodFormValidation/Schemas/swiftCodeSchema';
import {
  CheckAccountsNumber,
  accountsNumberSchemaWithDuplicateCheck,
} from 'views/Contacts/ContactDetails/ContactForm/accountsNumberSchema';
import { z } from 'zod';
import {
  isValidPostalCodeForGermany,
  isValidTaxNumberForGermany,
} from '../../../../utils/forms';
import { contactTypeSchema } from './ContactTypeFields/contactTypeSchema';
import { CheckContactName } from './contactNameSchema';
import { taxNumberSchema } from './taxNumberSchema';
import { GetContactEmployeeTypeByMembershipId } from '../useGetContactEmployeeTypeByMembershipId';

const DATEV_EMAIL_MAX_LENGTH = 60;

export const DATEV_CUSTOMER_NUMBER_MAX_LENGTH = 15;

export const customerNumberSchema = z
  .string()
  .max(DATEV_CUSTOMER_NUMBER_MAX_LENGTH);

export const contactEmailSchema = z
  .string()
  .max(DATEV_EMAIL_MAX_LENGTH)
  .email();

export const SEPA = 'SEPA';
export const NON_SEPA = 'NON_SEPA';

const bankInfoSchema = z
  .object({
    iban: ibanSchema.optional().nullable(),
    bankInfoType: z.enum([SEPA, NON_SEPA]).optional().nullable(),
    swiftCode: swiftCodeSchema.optional().nullable(),
    bankAccountNumber: bankAccountNumberSchema.optional().nullable(),
  })
  .superRefine(({ bankInfoType, iban, swiftCode, bankAccountNumber }, ctx) => {
    if (bankInfoType === 'SEPA' && swiftCode && isNil(iban)) {
      ctx.addIssue({
        code: 'custom',
        path: ['iban'],
        params: {
          translationKey:
            'settings.contacts.details.edit.bankInfo.ibanEmptySwiftPresent',
        },
      });

      return;
    }

    if (iban && iban.startsWith('GB') && !swiftCode) {
      ctx.addIssue({
        code: 'custom',
        path: ['swiftCode'],
        params: {
          translationKey:
            'settings.contacts.details.edit.bankInfo.swiftCodeRequiredForGBIban',
        },
      });

      return;
    }

    if (
      bankInfoType === 'SEPA' &&
      iban &&
      swiftCode &&
      isValidIBAN(iban) &&
      isValidBIC(swiftCode) &&
      !isSwiftCountryCodeMatch({ iban, swiftCode })
    ) {
      ctx.addIssue({
        code: 'custom',
        path: ['swiftCode'],
        params: {
          translationKey:
            'settings.contacts.details.edit.bankInfo.swiftCodeIbanCountryCodeMismatch',
        },
      });

      return;
    }

    if (bankInfoType === 'NON_SEPA') {
      if (swiftCode && !bankAccountNumber) {
        ctx.addIssue({
          code: 'invalid_type',
          path: ['bankAccountNumber'],
          expected: 'string',
          received: 'null',
        });
      } else if (!swiftCode && bankAccountNumber) {
        ctx.addIssue({
          code: 'invalid_type',
          path: ['swiftCode'],
          expected: 'string',
          received: 'null',
        });
      }
    }
  });

export const STREET_MAX_LENGTH = 36;
export const POSTAL_CODE_MAX_LENGTH = 10;
export const CITY_MAX_LENGTH = 30;
export const POST_OFFICE_BOX_MAX_LENGTH = 10;
export const CONTACT_PHONE_NUMBER_MAX_LENGTH = 60;
export const streetSchema = z.string().max(STREET_MAX_LENGTH);
export const postalCodeSchema = z.string().max(POSTAL_CODE_MAX_LENGTH);
export const citySchema = z.string().max(CITY_MAX_LENGTH);
export const countrySchema = z.string().max(2);
export const postOfficeBoxSchema = z.string().max(POST_OFFICE_BOX_MAX_LENGTH);
export const contactPhoneNumberSchema = z
  .string()
  .max(CONTACT_PHONE_NUMBER_MAX_LENGTH);
const addressInfoSchema = z
  .object({
    street: z.string().optional().nullable(),
    postalCode: postalCodeSchema.optional().nullable(),
    addressInfoType: z
      .enum(['STREET', 'POST_OFFICE_BOX'])
      .optional()
      .nullable(),
    city: citySchema.optional().nullable(),
    country: countrySchema.optional().nullable(),
    postOfficeBox: z.string().optional().nullable(),
  })
  .superRefine(
    ({ street, postOfficeBox, country, postalCode, addressInfoType }, ctx) => {
      if (addressInfoType === 'STREET') {
        if (!streetSchema.optional().nullable().safeParse(street).success) {
          ctx.addIssue({
            code: 'custom',
            path: ['street'],
            params: {
              translationKey: 'formValidation.fields.string.max.inclusive',
              maximum: STREET_MAX_LENGTH,
            },
          });
        }
      }

      if (addressInfoType === 'POST_OFFICE_BOX') {
        if (
          !postOfficeBoxSchema.optional().nullable().safeParse(postOfficeBox)
            .success
        ) {
          ctx.addIssue({
            code: 'custom',
            path: ['postOfficeBox'],
          });
        }
      }

      if (!isValidPostalCodeForGermany(country, postalCode)) {
        ctx.addIssue({
          code: 'custom',
          path: ['postalCode'],
          params: {
            translationKey:
              'settings.contacts.details.edit.errors.onlyNumbersAllowed',
          },
        });
      }
    }
  );

export const addIssueIfGermanTaxNumberIsInvalid = (
  country: string | null | undefined,
  taxNumber: string | null | undefined,
  context: z.RefinementCtx
) => {
  if (!isValidTaxNumberForGermany(country, taxNumber)) {
    context.addIssue({
      code: 'custom',
      path: ['taxNumber'],
      params: {
        translationKey:
          'settings.contacts.details.edit.taxNumber.invalidForGermany',
      },
    });
  }
};

export interface ContactFormSchemaOptions {
  checkAccountsPayableNumber?: CheckAccountsNumber;
  checkAccountsReceivableNumber?: CheckAccountsNumber;
  shouldRequireAccountsPayableNumber?: boolean;
  checkContactName?: CheckContactName;
  checkContactEmployeeTypeByMembershipId?: GetContactEmployeeTypeByMembershipId;
  integration?: IntegrationName;
  glaLength?: number;
}

/** Zod schema to validate contact form */
export const contactFormSchema = ({
  checkAccountsPayableNumber,
  checkAccountsReceivableNumber,
  shouldRequireAccountsPayableNumber,
  checkContactName,
  checkContactEmployeeTypeByMembershipId,
  integration = IntegrationName.Datev,
  glaLength,
}: ContactFormSchemaOptions = {}) => {
  const accountsPayableNumberSchema = accountsNumberSchemaWithDuplicateCheck({
    checkAccountsNumber: checkAccountsPayableNumber,
    integration,
    accountType: 'accountsPayable',
    glaLength,
    shouldRequireAccountsPayableNumber,
  });

  const accountsReceivableNumberSchema = accountsNumberSchemaWithDuplicateCheck(
    {
      checkAccountsNumber: checkAccountsReceivableNumber,
      integration,
      accountType: 'accountsReceivable',
      glaLength,
    }
  )
    .optional()
    .nullable();

  const commonSchemaFields = {
    name: z.string().min(1),
    customerNumber: customerNumberSchema.optional().nullable(),
    email: contactEmailSchema.optional().nullable(),
    phoneNumber: contactPhoneNumberSchema.optional().nullable(),
    iban: ibanSchema.optional().nullable(),
    taxNumber: taxNumberSchema.optional().nullable(),
    accountsPayableNumber: accountsPayableNumberSchema,
    accountsReceivableNumber: accountsReceivableNumberSchema,
    teamMember: z.string().optional().nullable(),
    vatId: vatIdSchema.optional().nullable(),
    createTransfer: z.boolean(),
    paymentMedium: z.nativeEnum(ContactPaymentMedium).optional().nullable(),
    paymentCondition: z.string().optional(),
    relationshipType: z.nativeEnum(ContactRelationshipType),
  };

  const customerRelationshipTypeContactSchema = z.object({
    ...commonSchemaFields,
    relationshipType: z.literal(ContactRelationshipType.Customer),
    accountsPayableNumber: z.string().optional().nullable(),
    customerNumber: z.string().optional().nullable(),
  });

  const supplierRelationshipTypeContactSchema = z.object({
    ...commonSchemaFields,
    relationshipType: z.literal(ContactRelationshipType.Supplier),
    accountsReceivableNumber: z.string().optional().nullable(),
  });

  const supplierCustomerRelationshipTypeContactSchema = z.object({
    ...commonSchemaFields,
    relationshipType: z.literal(ContactRelationshipType.SupplierCustomer),
  });

  const employeeRelationshipTypeContactSchema = z.object({
    ...commonSchemaFields,
    relationshipType: z.literal(ContactRelationshipType.Employee),
  });

  return z
    .discriminatedUnion('relationshipType', [
      customerRelationshipTypeContactSchema,
      supplierRelationshipTypeContactSchema,
      supplierCustomerRelationshipTypeContactSchema,
      employeeRelationshipTypeContactSchema,
    ])
    .and(bankInfoSchema)
    .and(addressInfoSchema)
    .and(
      contactTypeSchema({
        checkContactName,
      })
    )
    .superRefine(async (value, context) => {
      const accountsPayableNumber = value.accountsPayableNumber;
      const accountsReceivableNumber = value.accountsReceivableNumber;
      const relationshipType = value.relationshipType;

      if (
        accountsPayableNumber &&
        accountsReceivableNumber &&
        accountsPayableNumber === accountsReceivableNumber &&
        relationshipType === ContactRelationshipType.SupplierCustomer
      ) {
        context.addIssue({
          code: 'custom',
          path: ['accountsReceivableNumber'],
          params: {
            translationKey:
              'settings.contacts.details.edit.accountsReceivableNumber.errorUnique',
          },
        });
      }

      addIssueIfGermanTaxNumberIsInvalid(
        value.country,
        value.taxNumber,
        context
      );
    })
    .superRefine(async (value, context) => {
      if (value.contactType === 'EMPLOYEE' && !value.teamMember) {
        context.addIssue({
          code: 'custom',
          params: {
            translationKey:
              'settings.contacts.details.edit.teamMember.requiredError',
          },
          path: ['teamMember'],
        });
      }

      if (
        value.contactType === 'EMPLOYEE' &&
        value.teamMember &&
        checkContactEmployeeTypeByMembershipId
      ) {
        const isContactEmployeeTypeExisting =
          await checkContactEmployeeTypeByMembershipId(value.teamMember);

        if (isContactEmployeeTypeExisting?.foundExistingCountactId) {
          context.addIssue({
            params: {
              translationKey:
                'settings.contacts.details.edit.teamMember.contactEmployeeTypeExistsError',
            },
            code: 'custom',
            path: ['teamMember'],
          });
        }
      }
    });
};

export type ContactFormOutput = z.infer<ReturnType<typeof contactFormSchema>>;
export type ContactFormValues = Partial<ContactFormOutput>;
export type ContactFormErrorMessages = ErrorMessages<
  ReturnType<typeof contactFormSchema>
>;

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useIsCardManagerOnCreditCardsView } from 'containers/credit-cards/utils';
import {
  CardCategory,
  useRequestVirtualCardIssuerCardMutation,
} from 'generated-types/graphql.types';
import { GraphQLError } from 'graphql';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { isNil } from 'lodash';
import { useCreditCardsRefetchQueries } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { AccountingData } from '../types';
import { jumpToCreatedCreditCard } from '../utils/jumpToCreatedCreditCard';
import { sanitiseRequestVirtualCard } from '../utils/sanitiseRequestVirtualCard';
import { CreditCardData } from '../utils/types';
import { CREDIT_CARD_SEARCH_PARAM_ON_VIEW } from 'containers/credit-cards/constants';

export interface RequestVirtualCard extends CreditCardData {
  selectedCategory?: CardCategory;
  currentUserId?: string;
  accountingData?: AccountingData;
}

export interface UseRequestVirtualCardOutput {
  request: (values: RequestVirtualCard) => Promise<{
    status: string;
    errors?: readonly GraphQLError[] | undefined;
  }>;
  requesting: boolean;
  createdCardId?: string;
}

export const useRequestVirtualCard = ({
  onCardCreatedSuccess,
}: {
  onCardCreatedSuccess: (cardId: string) => void;
}): UseRequestVirtualCardOutput => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { error } = useToastMessage();
  const { searchParams } = useMutateSearchParams();
  const isCardManagerOnCreditCardsView = useIsCardManagerOnCreditCardsView();
  const isOnCreditCardsView =
    Boolean(searchParams.get(CREDIT_CARD_SEARCH_PARAM_ON_VIEW)) ?? false;

  const organizationId = useOrganizationId();
  const navigate = useNavigate();

  const [requestVirtualCardIssuerCard, { loading: requesting, client }] =
    useRequestVirtualCardIssuerCardMutation({
      onError: () => error(t('dashboard.genericErrorMessage')),
    });

  const { refetchQueries } = useCreditCardsRefetchQueries();

  return {
    request: async (values: RequestVirtualCard) => {
      const sanitisedValues = sanitiseRequestVirtualCard(values);
      const { data, errors } = await requestVirtualCardIssuerCard({
        variables: {
          input: sanitisedValues,
        },
        awaitRefetchQueries: true,
        refetchQueries: ({ data }) =>
          refetchQueries.cardIssuerCards(data?.requestVirtualCardIssuerCard.id),
        onCompleted: data => {
          if (isNil(data)) {
            return;
          }

          client.cache.evict({ fieldName: 'getCardIssuerCardsForCardholder' });

          const cardId = data.requestVirtualCardIssuerCard.id;

          onCardCreatedSuccess(cardId);

          const { creditCardLink } = jumpToCreatedCreditCard({
            cardId,
            organizationId,
          });

          if (!isCardManagerOnCreditCardsView && !isOnCreditCardsView) {
            navigate(creditCardLink);
          }
        },
      });

      if (errors?.length) {
        return { status: 'error', errors };
      }

      if (data) {
        return { status: 'success' };
      }

      return {
        status: 'error',
        errors,
      };
    },
    requesting,
  };
};

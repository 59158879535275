import { useGetContactEmployeeTypeByMembershipIdLazyQuery } from 'generated-types/graphql.types';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';

export type GetContactEmployeeTypeByMembershipId = (
  id: string | undefined | null
) => Promise<
  | {
      foundExistingCountactId: string;
    }
  | undefined
>;

export const useGetContactEmployeeTypeByMembershipId = () => {
  const [getContactEmployeeTypeByMembershipId] =
    useGetContactEmployeeTypeByMembershipIdLazyQuery({});

  const handleGetContactEmployeeTypeByMembershipId: GetContactEmployeeTypeByMembershipId =
    useCallback(
      async (id: string | undefined | null) => {
        if (!id) {
          return undefined;
        }
        const response = await getContactEmployeeTypeByMembershipId({
          variables: { membershipId: id },
          fetchPolicy: 'network-only',
        });

        const contact = response.data?.contactByEmployeeMembershipId;

        if (isEmpty(contact)) {
          return undefined;
        }

        return {
          foundExistingCountactId: contact.employeeMembership?.id ?? '',
        };
      },
      [getContactEmployeeTypeByMembershipId]
    );

  return { handleGetContactEmployeeTypeByMembershipId };
};

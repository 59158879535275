import clsx from 'clsx';
import styles from './SwimlaneTileTitle.module.css';
import { HTMLProps } from 'react';

type SwimlaneTileTitleProps = HTMLProps<HTMLParagraphElement>;

export const SwimlaneTileTitle = ({
  className,
  ...otherProps
}: SwimlaneTileTitleProps) => {
  return <p className={clsx(styles.title, className)} {...otherProps} />;
};

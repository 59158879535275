import { Button, Grid } from '@candisio/design-system';

import { ActionBar } from 'components/Sidebar/ActionBar';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  Money,
  useSepaTransferXmlLazyQuery,
} from 'generated-types/graphql.types';
import { DocumentCurrency } from 'generated-types/resolvers-types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SimplifiedPayment } from '../types';
import { PaymentSummary } from './PaymentSummary';
import { SepaImage } from './SepaImage';
import { sumCashDiscounts } from './utils/sumCashDiscounts';
import { downloadFile } from 'utils/downloadFile';

export interface PaymentProps {
  payment: SimplifiedPayment;
}

/**
 * Summary displayed when the user is checking a Payment entry
 */
export const Payment = ({ payment }: PaymentProps) => {
  const { success, error } = useToastMessage();

  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  const [sepaTransferXml, { loading }] = useSepaTransferXmlLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { paymentId: payment.id },
    onCompleted: ({ sepaTransferXml }) => {
      if (
        isNil(sepaTransferXml) ||
        sepaTransferXml.__typename === 'SepaXmlValidationError'
      ) {
        error(t('toastMessages.redownloadError'));

        return;
      }

      const file = new Blob([sepaTransferXml.content], {
        type: 'text/xml',
      });

      downloadFile(URL.createObjectURL(file), sepaTransferXml.fileName);

      success(t('toastMessages.redownloadSuccess'));
    },
  });

  // @TODO Start using dinero for these calculations

  /** Total paid amount after discount */
  const paymentAmount = payment.totalAmount;

  /** Total cash discount amount */
  const discountAmount: Money = {
    ...(payment.paidDocuments[0]?.discountAmount ?? {
      currency: DocumentCurrency.Eur,
      precision: 2,
    }),
    amount: sumCashDiscounts(
      payment.paidDocuments,
      new Date(payment.creationDate)
    ),
  };

  /** Total amount before discount */
  const grossAmount: Money = {
    ...paymentAmount,
    amount: paymentAmount.amount + discountAmount.amount,
  };

  return (
    <Grid alignContent="space-between" gap="space48" height="100%">
      <Grid gap="space24">
        <SepaImage />

        <PaymentSummary
          discountAmount={discountAmount}
          grossAmount={grossAmount}
          numDocuments={payment.paidDocuments.length}
          paymentAmount={paymentAmount}
          paymentCreator={{
            avatarUrl: payment.paymentInitiator.avatarUrl ?? undefined,
            date: new Date(payment.creationDate),
            name: payment.paymentInitiator.name,
          }}
        />
      </Grid>

      <ActionBar>
        <Grid>
          <Button loading={loading} onClick={() => sepaTransferXml()}>
            {t('details.redownload')}
          </Button>
        </Grid>
      </ActionBar>
    </Grid>
  );
};

import { ReactNode, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom-v5-compat';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useGetPath } from 'hooks/usePath';
import styles from 'components/CellWrapperLink/CellWrapperLink.module.css';
import { DocumentType } from 'generated-types/graphql.types';
import { EcmDocumentsTableData } from '../types';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';

interface LinkToDocumentProps {
  row: EcmDocumentsTableData;
  children?: ReactNode;
}

export const LinkToDocument = ({ row, children }: LinkToDocumentProps) => {
  const organizationSlug = useOrganizationId();
  const getPathInternal = useGetPath();
  const [searchParams] = useSearchParams();
  const path = useMemo(() => {
    if (!organizationSlug) return '';

    const isInvoice = row.documentType === DocumentType.Invoice;

    const documentId = row.invoiceId && isInvoice ? row.invoiceId : row.id;

    const isReimbursementItem = !!row.reimbursementCaseId;

    if (isInvoice) {
      searchParams.set('isInvoice', 'true');
    } else {
      searchParams.delete('isInvoice');
    }

    if (row.cursor) searchParams.set('cursor', row.cursor);

    if (isReimbursementItem) {
      searchParams.set(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
      return getPathInternal({
        pathname:
          '/archive/stored_documents/reimbursements/:reimbursementId/:reimbursementItemId',
        params: {
          reimbursementId: row.reimbursementCaseId ?? '',
          reimbursementItemId: row.id,
        },
        search: searchParams.toString(),
      });
    } else {
      return getPathInternal({
        pathname: '/archive/stored_documents/:documentId',
        params: {
          documentId,
        },
        search: searchParams.toString(),
      });
    }
  }, [organizationSlug, row, getPathInternal, searchParams]);

  return (
    <Link to={path} className={styles['cell-wrapper']}>
      {children}
    </Link>
  );
};

import { useMemo } from 'react';

/**
 * A hook that generates formatOptions based on the provided currency and fraction digits.
 */
export const useNumberFormatOptions = ({
  currency,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
}: {
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}) => {
  return useMemo(
    (): Intl.NumberFormatOptions => ({
      currency,
      maximumFractionDigits,
      minimumFractionDigits,
      style: currency ? 'currency' : 'decimal',
    }),
    [currency, maximumFractionDigits, minimumFractionDigits]
  );
};

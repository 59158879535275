import { createKeyStore } from './createKeyStore';
import { KeyStoreReturn } from './createKeyStore.types';
import {
  StoreProviderAndHook,
  createStoreProvider,
} from './createStoreProvider';

export const createKeyStoreProvider = <T extends object>(
  providerName?: string,
  hookName?: string
): StoreProviderAndHook<T, KeyStoreReturn<T>> => {
  return createStoreProvider(createKeyStore, providerName, hookName);
};

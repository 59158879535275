import {
  ApprovalReimbursementCasesEdge,
  ArchiveReimbursementCasesEdge,
  ArchiveReimbursementCasesListItem,
  DocumentCurrency,
  InboxReimbursementCasesEdge,
  ReimbursementCase,
} from 'generated-types/graphql.types';
import { ReimbursementTableRow } from '../toolkit/types';

type ReimbursementEdge = Omit<
  | ArchiveReimbursementCasesEdge
  | InboxReimbursementCasesEdge
  | ApprovalReimbursementCasesEdge,
  'node'
> & {
  node: Pick<
    ReimbursementCase,
    | 'id'
    | 'totalAmount'
    | 'isPaid'
    | 'status'
    | 'paidAt'
    | 'createdAt'
    | 'firstSubmittedForReviewAt'
    | 'title'
    | 'targetMembership'
    | 'lastApprovedByMembership'
    | 'contact'
  > &
    Pick<
      ArchiveReimbursementCasesListItem,
      'approvers' | 'requester' | 'requestedAt' | 'lastClosedAt'
    >;
};

export const mapToReimbursementsTableData = (
  reimbursementEdge: ReimbursementEdge
): ReimbursementTableRow => {
  const node = reimbursementEdge.node;

  return {
    grossAmount: {
      amount: node.totalAmount.amount,
      currency: node.totalAmount.currency as DocumentCurrency,
      precision: node.totalAmount.precision,
    },
    id: node.id,
    isPaid: 'isPaid' in node ? node.isPaid : false,
    status: node.status,
    cursor: reimbursementEdge.cursor,
    createdAt: node.createdAt ? new Date(node.createdAt) : undefined,
    firstSubmittedForReviewAt: node.firstSubmittedForReviewAt
      ? new Date(node.firstSubmittedForReviewAt)
      : undefined,
    lastApprovedByMembership: {
      avatarUrl: node.lastApprovedByMembership?.avatarUrl ?? undefined,
      firstName: node.lastApprovedByMembership?.firstName,
      lastName: node.lastApprovedByMembership?.lastName,
      membershipId: node.lastApprovedByMembership?.id,
    },
    paidAt: 'paidAt' in node && node.paidAt ? new Date(node.paidAt) : undefined,
    title: node.title ?? undefined,
    requesterOfTheReimbursement: {
      avatarUrl: node.targetMembership?.avatarUrl ?? undefined,
      firstName: node.targetMembership?.firstName,
      lastName: node.targetMembership?.lastName,
      membershipId: node.targetMembership?.id,
    },
    lastClosedAt:
      'lastClosedAt' in node && node.lastClosedAt
        ? new Date(node.lastClosedAt)
        : undefined,
    approvalRequestedAt:
      'requestedAt' in node && node.requestedAt
        ? new Date(node.requestedAt)
        : undefined,
    requester:
      'requester' in node && node.requester
        ? {
            avatarUrl: node.requester.avatarUrl ?? undefined,
            firstName: node.requester.firstName,
            lastName: node.requester.lastName,
            membershipId: node.requester.id,
          }
        : undefined,
    approvers:
      'approvers' in node && node.approvers?.length
        ? node.approvers.map(approver => ({
            avatarUrl: approver.avatarUrl ?? undefined,
            firstName: approver.firstName,
            lastName: approver.lastName,
            membershipId: approver.id,
          }))
        : undefined,
  };
};

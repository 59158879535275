import { Order, SortDirection, SortInput } from 'generated-types/graphql.types';
import { SortingRule } from 'react-table';
import { PaymentTableData } from '../PaymentTable/types';
import { SimplifiedPayment } from '../types';
import { DocumentsTableData } from 'components/DocumentsTable/types';

export const getSortField = (
  sortId: keyof PaymentTableData | (string & {})
): string => {
  if (sortId === 'paymentInfo') return 'creditorIban';

  return sortId;
};

export const columnIdsToAvailableGqlSort: Record<string, string> = {
  status: 'status',
  invoiceNumber: 'invoiceNumber',
  dueDateWithCashDiscount: 'dueDateWithCashDiscount',
  contact: 'contact',
  invoiceDueDate: 'invoiceDueDate',
  creditorIban: 'iban',
  amount: 'amount',
} as const;

/**
 * @deprecated
 */
export const mapToSortInputDeprecated = (
  sort: Array<SortingRule<SimplifiedPayment>>
): SortInput | undefined => {
  if (sort.length < 1) {
    return undefined;
  }

  const [firstSort] = sort;

  return {
    field: getSortField(firstSort.id),
    order: firstSort.desc ? Order.Desc : Order.Asc,
  };
};

export const mapToSortInput = (
  sort: Array<SortingRule<DocumentsTableData>>
) => {
  if (!sort.length) {
    return;
  }

  const [firstSort] = sort;

  const columnIdToBEField = columnIdsToAvailableGqlSort[firstSort.id];

  if (!columnIdToBEField) {
    return;
  }

  return {
    [columnIdToBEField]: firstSort.desc
      ? SortDirection.Desc
      : SortDirection.Asc,
  };
};

import { CardStatus, CardType } from 'generated-types/graphql.types';

export const CARD_EXPIRING_STATUES = [
  CardStatus.Active,
  CardStatus.Pending,
  CardStatus.Locked,
  CardStatus.LockedPin,
];

export const CARD_EXPIRING_TYPES = [
  CardType.Physical,
  CardType.Virtual,
  CardType.SingleUse,
];

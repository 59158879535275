import { Flex, Grid, TruncatedText } from '@candisio/design-system';
import { SplitBookingsLayout } from 'containers/SplitBookings/components/SplitBookingsLayout';
import { AccordionItem } from 'containers/SplitBookings/components/Summary/AccordionItem';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { ReimbursementSplitBookingsFormValues } from 'views/Reimbursement/toolkit/reimbursementSplitBookingsFormSchema';
import { mappedTypesToItems } from '../../LeftSection/ReimbursementItem';
import { generateSplitBookingEntryFieldNames } from '../utils/generateSplitBookingsFormFieldNames';
import { BookingFooterActions } from './BookingFooterActions';
import { BookingSummary } from './BookingSummary';
import { useReimbursementSplitBookingFormActions } from './hooks/useReimbursementItemBookingsFormActions';
import { BasicSplitBookingsForm } from './BasicSplitBookingsForm';
import { noop } from 'lodash';
import { useFormFieldOptions } from './hooks/useFormFieldOptions';

const ACCORDION_PREFIX = 'reimbursement-booking-accordion';

interface BasicSplitBookingsFormContainerProps {
  status?: ReimbursementCaseStatus;
}

export const BasicSplitBookingsFormContainer = ({
  status,
}: BasicSplitBookingsFormContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const [showErrors, setShowErrors] = useState(false);

  const fieldOptions = useFormFieldOptions();

  const {
    activeBooking,
    updateActiveBooking,
    formMethods,
    resetDrawerAndForm,
    getSplitBookingsFormRules,
  } = useReimbursementSplitBookingHelpers();

  const { entryIndex, bookingId } = activeBooking;
  const topFields = generateSplitBookingEntryFieldNames(entryIndex);

  const { control, getValues, watch } = formMethods;
  const [itemType, itemTitle, itemStatus] = getValues([
    topFields.reimbursementItemType,
    topFields.reimbursementItemTitle,
    topFields.reimbursementItemStatus,
  ]);
  const bookings = watch(topFields.bookings) ?? [];

  const { actionRules } = getSplitBookingsFormRules({
    status,
    itemStatus,
    fieldOptions,
  });

  const currentActionRules = actionRules[entryIndex] ?? {};

  const { append, remove } = useFieldArray<
    ReimbursementSplitBookingsFormValues,
    `reimbursementItemBookings.${number}.bookings`
  >({
    control,
    name: topFields.bookings,
  });

  const handleShowErrors = useCallback((val: boolean) => {
    setShowErrors(val);
  }, []);

  const { isUpdatingBooking, handleDeleteBooking, handleAcceptSplits } =
    useReimbursementSplitBookingFormActions({
      topFields,
      onAppendBooking: append,
      onRemoveBooking: remove,
      setShowErrors: handleShowErrors,
    });

  const hasOneBooking = bookings.length === 1;

  const canAddAndDeleteSplits = currentActionRules.canAddAndDeleteSplits;
  const ItemIcon = mappedTypesToItems[itemType]
    ? mappedTypesToItems[itemType].icon
    : undefined;

  // This use effect will make sure that if the expense is deleted from the middle section we will close the drawer
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (bookings.length === 0) {
      resetDrawerAndForm();
    }
  }, [bookings.length]);

  return (
    <FormProvider {...formMethods}>
      <SplitBookingsLayout
        key={`reimbursement-split-bookings-${entryIndex}`}
        title={
          <Flex gap="space8" alignItems="center">
            {ItemIcon && <ItemIcon />}
            {itemTitle && <TruncatedText>{itemTitle}</TruncatedText>}
          </Flex>
        }
      >
        <Grid as="form" height="100%">
          <Grid alignContent="space-between" gap="space16">
            <Flex direction="column">
              {hasOneBooking ? (
                <BasicSplitBookingsForm
                  hasOneBooking
                  entryIndex={entryIndex}
                  bookingIndex={0}
                  bookingId={bookings[0].bookingId}
                />
              ) : (
                bookings.map((booking, bookingIndex) => {
                  return (
                    <AccordionItem
                      key={booking.bookingId}
                      id={`${ACCORDION_PREFIX}-${bookingIndex}`}
                      open={booking.bookingId === bookingId}
                      onClick={() =>
                        updateActiveBooking({ bookingId: booking.bookingId })
                      }
                      summary={
                        <BookingSummary
                          splitNumber={bookingIndex + 1}
                          booking={booking}
                          onDelete={
                            canAddAndDeleteSplits
                              ? async () =>
                                  await handleDeleteBooking(bookingIndex)
                              : undefined
                          }
                        />
                      }
                    >
                      {booking.bookingId === bookingId && (
                        <BasicSplitBookingsForm
                          bookingId={bookingId}
                          entryIndex={entryIndex}
                          bookingIndex={bookingIndex}
                        />
                      )}
                    </AccordionItem>
                  );
                })
              )}
            </Flex>
            <BookingFooterActions
              caseStatus={status}
              actionRules={currentActionRules}
              isLoading={isUpdatingBooking}
              onCancelClick={resetDrawerAndForm}
              onAddNewSplit={noop}
              onAcceptBookings={async () => await handleAcceptSplits()}
              errorMessage={
                showErrors ? t('splitBookings.genericError') : undefined
              }
            />
          </Grid>
        </Grid>
      </SplitBookingsLayout>
    </FormProvider>
  );
};

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useDownloadPdfFilesForArchiveAllDocumentsMutation } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { refetchDocumentDataFileRecords } from 'views/Archive/Documents/queries';
import { getArchiveDocumentZipDownloadErrorMessage } from 'views/Archive/Documents/utils';
import { useOrganizationData } from 'views/Settings/Organization/hooks/useOrganizationData';
import { useEcmFiltersSortingFromUrlMap } from './useEcmFiltersSortingFromUrlMap';

interface UseDownloadPdfFilesForAllDocumentsProps {
  isTableLoading: boolean;
  handleClosePdfDownloadModal: () => void;
}

export const useDownloadPdfFilesForAllDocuments = ({
  isTableLoading,
  handleClosePdfDownloadModal,
}: UseDownloadPdfFilesForAllDocumentsProps) => {
  const [t] = useTranslation();
  const { success, error, info } = useToastMessage();

  const { organizationSlug } = useOrganizationData();

  const history = useHistory();

  const [
    downloadPdfFilesForAllDocuments,
    { loading: isDownloadPdfFilesPending },
  ] = useDownloadPdfFilesForArchiveAllDocumentsMutation();

  const { filter, sort, search, searchInput } =
    useEcmFiltersSortingFromUrlMap();

  const onDownloadPdfFilesForAllDocuments = useCallback(async () => {
    if (isTableLoading) {
      info(t('archive:download.waitForLoading'));
    }

    const results = await downloadPdfFilesForAllDocuments({
      refetchQueries: refetchDocumentDataFileRecords,
      variables: {
        filter,
        sort,
        search,
        searchInput,
      },
    });

    const data = results.data?.downloadPdfFilesForArchiveAllDocuments;
    const type = data?.__typename;
    const errorType =
      type === 'DownloadDocumentPDFsError' ? data?.kind : undefined;

    if (type === 'DownloadDocumentPDFsResult') {
      handleClosePdfDownloadModal();
      success(t('archive:download.pdFZipDownloadInitiated'));
      history.push(
        `/${organizationSlug}${Routes.DOCUMENT_DATA_DOWNLOAD_HISTORY}${window.location.search}`
      );
      return;
    }

    if (errorType) {
      const errorMessageTranslationKey =
        getArchiveDocumentZipDownloadErrorMessage({
          errorType,
        });

      error(t(errorMessageTranslationKey));
    }
  }, [
    isTableLoading,
    downloadPdfFilesForAllDocuments,
    search,
    info,
    t,
    handleClosePdfDownloadModal,
    success,
    error,
    filter,
    sort,
    history,
    organizationSlug,
    searchInput,
  ]);

  return {
    isDownloadPdfFilesPending,
    onDownloadPdfFilesForAllDocuments,
  };
};

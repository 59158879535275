import { useOrganizationId } from 'providers/OrganizationProvider';
import { useCallback } from 'react';
import { generateTypesafePath } from 'utils/generateTypesafePath';
import { GenerateTypesafePath, Paths } from 'utils/generateTypesafePath.types';

export const usePath = <Pathname extends Paths>(
  args: GenerateTypesafePath<Pathname>
) => {
  const orgId = useOrganizationId();
  return generateTypesafePath(args, orgId ?? '');
};

export const useGetPath = () => {
  const orgId = useOrganizationId();

  return useCallback(
    <Pathname extends Paths>(args: GenerateTypesafePath<Pathname>) =>
      generateTypesafePath(args, orgId ?? ''),
    [orgId]
  );
};

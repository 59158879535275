import { CustomEmptyStateProps, Link, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { EmptyStateLayout } from '../EmptyStateLayout';

export interface EmptyStateProps extends CustomEmptyStateProps {
  isTableEmpty: boolean;
  isTableFiltered: boolean;
  areTransactionsSelected?: boolean;
}

const TableIsEmpty = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Text textAlign="center">
      {t('cardManagerView.table.emptyState.isEmpty')}
    </Text>
  );
};

const TableIsFilteredAndEmpty = ({
  resetFilters,
}: {
  resetFilters?: () => void;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <>
      <Text textAlign="center" whiteSpace="pre-wrap">
        {t('cardManagerView.table.emptyState.isFilteredAndEmpty')}
      </Text>
      <Link as="button" onClick={() => resetFilters?.()}>
        {t('cardManagerView.table.emptyState.resetFilter')}
      </Link>
    </>
  );
};

export const EmptyState = ({
  isTableEmpty,
  isTableFiltered,
  resetFilters,
}: EmptyStateProps) => {
  const hasNoCreditCards = isTableEmpty && !isTableFiltered;
  const isFilteredAndEmpty = isTableEmpty && isTableFiltered;

  return (
    <EmptyStateLayout>
      {hasNoCreditCards ? (
        <TableIsEmpty />
      ) : (
        isFilteredAndEmpty && (
          <TableIsFilteredAndEmpty resetFilters={resetFilters} />
        )
      )}
    </EmptyStateLayout>
  );
};

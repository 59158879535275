import { Heading, Paragraph } from '@candisio/design-system';
import { DocumentAccessCard } from 'components/DocumentAccess/DocumentAcccessCard';
import { useTranslation } from 'react-i18next';
import { useDocumentAccessLevelsData } from './toolkit/hooks/useDocumentAccessLevelsData';

interface AccessByRoleCardContainerProps {
  globalDocumentId?: string;
}

export const ResponsiblePersonAccessCardContainer = ({
  globalDocumentId,
}: AccessByRoleCardContainerProps) => {
  const { accessByResponsiblePersonsData, loading } =
    useDocumentAccessLevelsData({
      globalDocumentId,
    });

  const [t] = useTranslation();

  return (
    <DocumentAccessCard
      accessData={accessByResponsiblePersonsData}
      loading={loading}
      title={
        <Heading as="h3">
          {t(
            'common:documentAccess.membership.access.byResponsiblePerson.title'
          )}
        </Heading>
      }
      description={
        <Paragraph fontWeight="400" color="gray600" whiteSpace="pre-wrap">
          {t(
            'common:documentAccess.membership.access.byResponsiblePerson.description'
          )}
        </Paragraph>
      }
    />
  );
};

import {
  GetDocumentBookingAssociationsByInvoiceIdQueryVariables,
  GetDocumentForDraftQueryVariables,
  GetDocumentHistoryQueryVariables,
  GetSapDocumentsQueryVariables,
} from 'generated-types/graphql.types';
import {
  documentQueries,
  getDocumentBookingAssociationsByInvoiceId,
  getSapDocumentsQuery,
} from 'views/Inbox/DocumentProcessing/queries';
import { documentHistoryQuery } from 'views/queries';

export const getRefetchQueries = (documentId: string) => [
  {
    query: documentQueries.forDraftForm,
    variables: { id: documentId } satisfies GetDocumentForDraftQueryVariables,
  },
  {
    query: getDocumentBookingAssociationsByInvoiceId,
    variables: {
      invoiceId: documentId,
    } satisfies GetDocumentBookingAssociationsByInvoiceIdQueryVariables,
  },
  {
    query: getSapDocumentsQuery,
    variables: { id: documentId } satisfies GetSapDocumentsQueryVariables,
  },
  {
    query: documentHistoryQuery,
    variables: { id: documentId } satisfies GetDocumentHistoryQueryVariables,
  },
];

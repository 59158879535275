import { z } from 'zod';

export const createPaymentSchema = z.object({
  // TODO understand why IBAN is a string and not ibanSchema
  iban: z.string(),
});

export const createPaymentErrorMessages = {
  iban: {
    label: 'details.ibanHeading',
  },
};

export type CreatePaymentFormValues = z.infer<typeof createPaymentSchema>;

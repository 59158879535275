import styles from '../DaysStep.module.css';
import { ReimbursementItemFormPerDiemDay } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { PerDiemMealsMultiSelectorButtons } from '../PerDiemMealsMultiSelectorButtons/PerDiemMealsMultiSelectorButtons';
import { perDiemMealbuttons } from '../../PerDiemForm';
import {
  GetMultiSelectorStatePerDiemParams,
  SelectAllColumnPerDiemParams,
} from 'views/Reimbursement/hooks/types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import stylesReducedDays from './ReducedDays.module.css';
import { Icon } from '@candisio/design-system/next';

interface ItemSummaryProps {
  onSetIsMiddleDayHidden: (value: boolean) => void;
  onSelectAllColumn: (params: SelectAllColumnPerDiemParams) => void;
  onGetMultiSelectorState: (
    params: GetMultiSelectorStatePerDiemParams
  ) => 'allSelected' | 'someSelected' | 'noneSelected';
  collapsibleDays: (ReimbursementItemFormPerDiemDay & { id: string })[];
  isMiddleDaysHidden?: boolean;
}

export const ItemSummary = ({
  collapsibleDays,
  isMiddleDaysHidden,
  onSetIsMiddleDayHidden,
  onSelectAllColumn,
  onGetMultiSelectorState,
}: ItemSummaryProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const numberOfCollapsibleDays = collapsibleDays.length;

  return (
    <li
      data-testid="collapsible-item-days-list"
      className={clsx(styles['day-item'], stylesReducedDays['fade-in'])}
      data-visible={!isMiddleDaysHidden ? true : false}
      onClick={e => {
        e.stopPropagation();
        onSetIsMiddleDayHidden(false);
      }}
    >
      <div className="flex items-center cursor-pointer">
        <Icon icon="sortInactive" className="w-5 h-5" />
        <span className="text-sm">
          {t('reimbursementView.middleSection.form.perDiem.days.hiddenDays', {
            count: numberOfCollapsibleDays,
          })}
        </span>
      </div>
      <PerDiemMealsMultiSelectorButtons
        buttons={perDiemMealbuttons}
        onSelectAll={onSelectAllColumn}
        onGetMultiSelectorState={onGetMultiSelectorState}
      />
    </li>
  );
};

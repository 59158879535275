import { Box } from '@candisio/design-system';
import { PromotionCard } from 'components/NewPromotions/PromotionCard/PromotionCard';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TagsInfoPanel } from 'views/Settings/DocumentTags/TagsInfoPanel';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from 'views/Settings/utils';
import { DOCUMENT_MANAGEMENT_UTM_SOURCE } from '../constants/utmSource';
import { useGetCustomPromotionDetails } from '../hooks/useGetCustomPromotionDetails';

export const DocumentTagsPromotionSettings = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { tagsPromotion } = usePromotionContent();
  const customPromotionDetails = useGetCustomPromotionDetails(
    'documentManagement',
    DOCUMENT_MANAGEMENT_UTM_SOURCE.SETTINGS_TAGS
  );

  return (
    <SettingsLayout
      title={t('documentTags.title')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
    >
      <Box height="24rem">
        <PromotionCard
          promotion={tagsPromotion}
          closable={false}
          customPromotionDetails={customPromotionDetails}
          trackOption={{
            eventLocation: DOCUMENT_MANAGEMENT_UTM_SOURCE.SETTINGS_TAGS,
          }}
        />
      </Box>
      <TagsInfoPanel />
    </SettingsLayout>
  );
};

import { Grid, Link } from '@candisio/design-system';

import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { InfoPopupButton } from 'components/Icons/InfoPopupButton';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  New_DatevDocumentType,
  useUpdateDocumentTypeBookingAccountMutation,
} from 'generated-types/graphql.types';
import { Trans } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import { refetchDatevSettingsQueries } from '../../../DatevSettings';
import { useDatevBookingAccountValidation } from '../../../useDatevBookingAccountValidation';

type BookingAccountFormData = {
  bookingAccount: string;
};

interface BookingAccountFormProps {
  documentType: New_DatevDocumentType;
}

export const BookingAccountForm = ({
  documentType,
}: BookingAccountFormProps) => {
  const [t] = useTranslation();
  const { success } = useToastMessage();

  const {
    schema,
    errorMessages,
    maxLength: accountLength,
  } = useDatevBookingAccountValidation('bookingAccount');

  const [updateBookingAccount] = useUpdateDocumentTypeBookingAccountMutation();
  const onUpdateBookingAccount = useCallback(
    async (name: string, category: string, bookingAccount?: string) => {
      const result = await updateBookingAccount({
        variables: { documentTypeId: { name, category }, bookingAccount },
        refetchQueries: refetchDatevSettingsQueries,
        awaitRefetchQueries: true,
      });

      return result;
    },
    [updateBookingAccount]
  );

  const form = useForm<BookingAccountFormData>({
    defaultValues: { bookingAccount: documentType.bookingAccount ?? '' },
    context: { t },
    resolver: zodResolver({
      zodSchema: schema({ t }),
      errorMessages: errorMessages({ t, label: 'bookingAccount' }),
    }),
    mode: 'onSubmit',
  });

  const getPlaceholderAccountNumber = (length: number): string =>
    `1${length > 1 ? new Array(length - 1).fill(0).join('') : ''}`;

  const handleSubmit = async ({
    bookingAccount: value,
  }: BookingAccountFormData) => {
    const bookingAccount = !value ? '' : value;

    const result = await onUpdateBookingAccount(
      documentType.name,
      documentType.category,
      bookingAccount
    );

    success(
      t(
        'settings.datev.connect.documentTypes.bookingAccount.toastMessages.updated',
        { docType: documentType.name }
      )
    );

    if (result.data) {
      form.resetField('bookingAccount', { defaultValue: bookingAccount });
    }
  };

  const isFormDirty = form.formState.isDirty;
  const isSubmitting = form.formState.isSubmitting;

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        onSubmit={form.handleSubmit(handleSubmit)}
        templateColumns={`${accountLength + 8}ch max-content max-content`}
        gap="space8"
        alignItems="center"
      >
        <HookFormTextField
          name="bookingAccount"
          aria-label={t(
            'settings.datev.connect.documentTypes.bookingAccount.ariaLabel'
          )}
          placeholder={t(
            'settings.datev.connect.documentTypes.bookingAccount.placeholder',
            {
              accountNumber: getPlaceholderAccountNumber(accountLength),
            }
          )}
          clearable={false}
        />
        <div className="flex items-center gap-2">
          <QuickSaveButtons
            isFormDirty={isFormDirty}
            resetField={() => form.resetField('bookingAccount')}
            isLoading={isSubmitting}
          />
          <InfoPopupButton
            message={
              <span>
                <Trans i18nKey="settings.datev.connect.documentTypes.bookingAccount.info">
                  This only works with RDS 1.0 enabled.
                  <Link
                    external
                    href={t(
                      'settings.datev.connect.documentTypes.bookingAccount.infoLink'
                    )}
                  >
                    Learn More
                  </Link>
                </Trans>
              </span>
            }
          />
        </div>
      </Grid>
    </FormProvider>
  );
};

import { useApolloClient } from '@apollo/client';
import { Link, Text } from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useStoreEcmDocumentMutation } from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDateConverter } from 'hooks/useDateConverter';
import { isNil } from 'lodash';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom-v5-compat';
import { refetchClassificationQueries } from 'utils/refetchClassificationQueries';
import { ecmErrorCodeMap } from 'views/Inbox/DocumentProcessing/components/Ecm/useUpdateEcmDocument';
import { mapStorageFormValues } from './mapStorageFormValues';
import { StorageFormValues } from './storageFormSchema';

export const DOCUMENT_ALREADY_STORED_ERROR = 'ECM document is already STORED';

interface UseStoreEcmDocumentOptions {
  globalDocumentId: string | undefined | null;
}

type StoreEcmDocumentResult =
  | { status: 'success'; id: string }
  | { status: 'error' };

type StoreEcmDocument = (
  values: StorageFormValues
) => Promise<StoreEcmDocumentResult>;

interface UseStoreEcmDocumentReturn {
  /** Store document */
  storeEcmDocument: StoreEcmDocument;
  /** Loading state */
  loading: boolean;
}

export const useStoreEcmDocument = ({
  globalDocumentId,
}: UseStoreEcmDocumentOptions): UseStoreEcmDocumentReturn => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { success, error } = useToastMessage();
  const organizationSlug = useOrganizationId();
  const { dateStringToDateTimeString } = useDateConverter();
  const client = useApolloClient();
  const counterQueries = useCounterQueries();
  const [mutation, { loading }] = useStoreEcmDocumentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [...counterQueries],
    onCompleted: () => {
      // make sure all related queries will be refetched
      refetchClassificationQueries(client);
    },
  });

  const storeEcmDocument: StoreEcmDocument = async formValues => {
    if (!globalDocumentId) {
      return { status: 'error' };
    }

    const mappedStorageFormValues = mapStorageFormValues(
      formValues,
      dateStringToDateTimeString
    );

    const { data, errors } = await mutation({
      variables: {
        input: {
          globalDocumentId,
          ...mappedStorageFormValues,
        },
      },
    });

    if (
      data?.storeEcmDocument.__typename === 'StoreEcmDocumentResponse' &&
      !isNil(data.storeEcmDocument.ecmDocumentId)
    ) {
      const id = data?.storeEcmDocument?.ecmDocumentId;

      const path = organizationSlug
        ? generatePath(
            `/:${AppRouteParams.organizationSlug}${Routes.ECM_DOCUMENTS}/:documentId`,
            { organizationSlug, documentId: id }
          )
        : undefined;

      success(
        <Text>
          <Trans
            i18nKey="ecm:storageForm.actions.documentStoredSuccessfully"
            values={{ name: mappedStorageFormValues.documentName }}
          >
            Document "
            <Link href={path}>
              {{ name: mappedStorageFormValues.documentName } as any}
            </Link>
            " stored successfully.
          </Trans>
        </Text>
      );

      return { status: 'success', id };
    } else {
      const hasDocumentStoredError = errors?.some(error =>
        error.message.includes(DOCUMENT_ALREADY_STORED_ERROR)
      );

      const errorCodes = errors?.map(error => error.extensions?.code);

      if (hasDocumentStoredError) {
        error(t('documentAlreadyStoredError'));
      } else {
        const message =
          errorCodes
            ?.map(code => ecmErrorCodeMap[code as string])
            .find(msg => msg) || 'genericErrorMessage';
        error(t(message));
      }
      return { status: 'error' };
    }
  };

  return { storeEcmDocument, loading };
};

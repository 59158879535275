import { useTooltip, Text, Tooltip } from '@candisio/design-system';
import { TruncatedText } from '@candisio/design-system/next';
import { NotificationProps } from '../getNotificationInfo';
import { DateFormatters } from 'utils/date_formatter';
import { Trans } from 'providers/LocaleProvider';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { Header } from './Header/Header';
import { ReimbursementItemType } from 'generated-types/graphql.types';

type NotificationKeys = {
  [K in ReimbursementItemType]: {
    header: string;
    toastHeader: string;
  } | null;
};

const KEYS: NotificationKeys = {
  GENERAL_EXPENSE: {
    header: 'notifications:events.reimbursementItemExcluded.header',
    toastHeader: 'notifications:events.reimbursementItemExcluded.header',
  },
  HOSPITALITY_EXPENSE: {
    header: 'notifications:events.reimbursementItemExcluded.header',
    toastHeader: 'notifications:events.reimbursementItemExcluded.header',
  },
  PER_DIEM: {
    header: 'notifications:events.reimbursementItemExcluded.perDiemHeader',
    toastHeader:
      'notifications:events.reimbursementItemExcluded.perDiemToastHeader',
  },
  MILEAGE: null,
};

export const ReimbursementCaseItemExcludedInAppNotification = ({
  updatedAt,
  updatedBy,
  totalAmount,
  title,
  type,
}: NotificationProps<'ReimbursementCaseItemExcludedInAppNotification'>) => {
  const { transactionAmountFormatter } = useMoneyFormatter();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });
  const updatedByName = updatedBy.name;
  const formattedAmount = transactionAmountFormatter({
    currency: totalAmount.currency,
    precision: totalAmount.precision,
    value: totalAmount.value,
  });

  const headerKey = KEYS[type]?.header ?? undefined;

  return (
    <div className="grid grid-rows-[auto 1fr] gap-1" style={{ height: '100%' }}>
      <Header>
        <Trans
          i18nKey={headerKey}
          values={{
            updatedByName,
            timeAgo: DateFormatters.fromNow(new Date(updatedAt)),
          }}
        >
          <TruncatedText className="text-gray-500" style={{ maxWidth: '15ch' }}>
            {updatedByName}
          </TruncatedText>
          <Text fontSize="small" color="gray500">
            excluded expense from reimbursement
          </Text>
          <Text
            fontSize="small"
            color="gray500"
            {...triggerProps}
            ref={triggerRef}
          >
            3 minutes ago
          </Text>
        </Trans>
      </Header>

      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {DateFormatters.compactDatetime(new Date(updatedAt))}
        </Tooltip>
      )}

      <Text color="gray800">{`${title} ${formattedAmount}`}</Text>
    </div>
  );
};

export const ReimbursementCaseItemExcludedInAppNotificationToast = ({
  updatedBy,
  totalAmount,
  title,
  type,
}: NotificationProps<'ReimbursementCaseItemExcludedInAppNotification'>) => {
  const updatedByName = updatedBy.name;
  const { transactionAmountFormatter } = useMoneyFormatter();

  const formattedAmount = transactionAmountFormatter({
    currency: totalAmount.currency,
    precision: totalAmount.precision,
    value: totalAmount.value,
  });

  const toasterHeaderKey = KEYS[type]?.toastHeader ?? undefined;

  return (
    <div className="grid grid-rows-[auto 1fr] gap-1" style={{ height: '100%' }}>
      <Header>
        <Trans
          i18nKey={toasterHeaderKey}
          values={{
            updatedByName,
          }}
        >
          <TruncatedText className="text-gray-800" style={{ maxWidth: '15ch' }}>
            {updatedByName}
          </TruncatedText>
          <Text color="gray500">excluded expense from reimbursement</Text>
        </Trans>
      </Header>

      <Text color="gray800">{`${title} ${formattedAmount}`}</Text>
    </div>
  );
};

import { useMemo } from 'react';
import { MIN_CONTENT_CARD_COUNT } from './constants';

const SHOW_EXTRA_TILE_THRESHOLD = 2;
const SINGLE_TILE = 1;

export const useSwimlaneTileRules = ({
  actionTileCount = 0,
  contentTileCount,
  contentTileLimit,
  promoTileCount = 0,
}: {
  contentTileCount: number;
  contentTileLimit: number;
  actionTileCount?: 0 | 1 | 2;
  promoTileCount?: 0 | 1 | 2;
}) => {
  return useMemo(() => {
    let contentTileVisibleCount = contentTileLimit;
    let isPromoTileVisible = promoTileCount > 0;
    let isShowAllTileVisible = false;

    // Reserve 2 slots for action card
    if (actionTileCount > 0) {
      contentTileVisibleCount -= actionTileCount;
    }

    // Reserve slots for promo card (1 or 2 slots), if there are available slots
    // If not, then we drop the promo card space
    if (
      isPromoTileVisible &&
      contentTileVisibleCount >= promoTileCount + SHOW_EXTRA_TILE_THRESHOLD
    ) {
      contentTileVisibleCount -= promoTileCount;
    } else {
      isPromoTileVisible = false;
    }

    // Check to see if there are enough remaining slots for the content cards
    // If not, then make pace for the Show All card (1 slot)
    if (contentTileCount > contentTileVisibleCount) {
      contentTileVisibleCount -= SINGLE_TILE;
      isShowAllTileVisible = true;
    }

    // We want to always display 1 content card
    contentTileVisibleCount = Math.max(
      contentTileVisibleCount,
      MIN_CONTENT_CARD_COUNT
    );

    // We only want to reserve space for the number of content tiles that we have, no more
    contentTileVisibleCount = Math.min(
      contentTileVisibleCount,
      contentTileCount
    );

    return {
      contentTileVisibleCount,
      isPromoTileVisible,
      isShowAllTileVisible,
      promoTileCount,
    };
  }, [actionTileCount, contentTileCount, contentTileLimit, promoTileCount]);
};

import { Cell, Table } from '@candisio/design-system';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { AssociationSource } from 'generated-types/graphql.types';
import { useThreeWayMatchContext } from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/ThreeWayMatchContext';
import { ThreeWayMatchAmountCell } from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/ThreeWayMatchTable/ThreeWayMatchAmountCell';
import { ThreeWayMatchTableHeader } from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/ThreeWayMatchTable/ThreeWayMatchTableHeader';
import { ThreeWayMatchModalTab } from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/ThreeWayMatchTable/ThreeWayMatchTableWrapper';
import { useGetMatchStateStyles } from '../hooks/useGetMatchStateStyles';
import { ThreeWayMatchRowData } from '../types';
import { ThreeWayMatchQuantityCell } from './ThreeWayMatchQuantityCell';

export interface ThreeWayMatchTableProps {
  activeTab: ThreeWayMatchModalTab;
  searchString: string;
}
const quantityColumnIds = [
  'purchaseOrderQuantity',
  'goodsReceiptQuantity',
  'invoiceQuantity',
];

const amountColumnIds = [
  'purchaseOrderAmount',
  'goodsReceiptAmount',
  'invoiceAmount',
];

const getColumns = (
  activeTab: ThreeWayMatchModalTab,
  visibleAmountColumnIds: string[]
): Array<Column<ThreeWayMatchRowData>> => {
  const defaultColumns: Column<ThreeWayMatchRowData>[] = [
    { accessor: 'articleNumber', width: '20%' },
    { accessor: 'description', width: '30%' },
  ];

  const customColumns: {
    [key in ThreeWayMatchModalTab]: Column<ThreeWayMatchRowData>[];
  } = {
    amount: visibleAmountColumnIds.map(
      id =>
        ({
          accessor: id,
          width: '24%',
          disableSortBy: true,
          Cell: ThreeWayMatchAmountCell,
        }) as Column<ThreeWayMatchRowData>
    ),
    quantity: quantityColumnIds.map(
      id =>
        ({
          accessor: id,
          width: '16%',
          disableSortBy: true,
          Cell: ThreeWayMatchQuantityCell,
        }) as Column<ThreeWayMatchRowData>
    ),
  };

  return [...defaultColumns, ...customColumns[activeTab]];
};

export function ThreeWayMatchTable({
  activeTab,
  searchString,
}: ThreeWayMatchTableProps) {
  const { newData: data, isLoading, linkingSource } = useThreeWayMatchContext();

  const visibleAmountColumnIds = useMemo(() => {
    return linkingSource === AssociationSource.GoodsReceipt
      ? amountColumnIds
      : amountColumnIds.filter(id => id !== 'goodsReceiptAmount');
  }, [linkingSource]);

  const columns = useMemo(() => {
    return getColumns(activeTab, visibleAmountColumnIds);
  }, [activeTab, visibleAmountColumnIds]);

  const getMatchingStyles = useGetMatchStateStyles();

  const defaultColumn = useMemo(
    (): Partial<Column<ThreeWayMatchRowData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header: ThreeWayMatchTableHeader,
      Cell,
      disableFilters: true,
    }),
    []
  );

  const tableData = useMemo(() => {
    return activeTab === 'quantity'
      ? data.filter(row => row.articleNumber !== '-') // hide additional expenses in quantity tab
      : data;
  }, [data, activeTab]);

  const filteredData = useMemo(() => {
    return searchString
      ? tableData.filter(
          row =>
            row.description
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            row.articleNumber.toLowerCase().includes(searchString.toLowerCase())
        )
      : tableData;
  }, [tableData, searchString]);

  const getCellBackgroundColor = (isMatched: boolean) => {
    return getMatchingStyles(isMatched ? 'success' : 'warning').cellBgColor;
  };

  return (
    <Table<ThreeWayMatchRowData>
      height="100%"
      borderBottomRadius="medium"
      borderTopRadius="none"
      defaultColumn={defaultColumn}
      columns={columns}
      data={filteredData}
      getCellStyles={cellInfo => {
        const { id } = cellInfo.column;
        const { isQuantityMatched, isAmountMatched } = cellInfo.row.original;

        if (quantityColumnIds.includes(id)) {
          return {
            backgroundColor: getCellBackgroundColor(isQuantityMatched),
          };
        }

        if (visibleAmountColumnIds.includes(id)) {
          return {
            backgroundColor: getCellBackgroundColor(isAmountMatched),
          };
        }

        return {};
      }}
      isLoading={isLoading}
    />
  );
}

import { CellContext } from '@tanstack/react-table';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { PaymentFrequencyType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { paymentFrequencyTranslation } from 'views/Settings/CreditCards/CreditCardSettlements/utils';

type Value = PaymentFrequencyType | 'UNKNOWN';
type CellProps = CellContext<DocumentsTableData, Value>;

export const PaymentFrequencyCell = ({ getValue }: CellProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const value = getValue();

  return <span>{t(paymentFrequencyTranslation[value])}</span>;
};

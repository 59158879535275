import { useFullOrganization } from 'providers/OrganizationProvider';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { FinancialOverviewCreditOrg } from './FinancialOverviewCreditOrg';
import { FinancialOverviewLoading } from './FinancialOverviewLoading';
import { FinancialOverviewMixedOrg } from './FinancialOverviewMixedOrg';
import { FinancialOverviewPrefundOrg } from './FinancialOverviewPrefundOrg';
import { useCreditCardLimits } from './hooks/useCreditCardLimits';
import { useGetPlannedPaymentDate } from './hooks/useGetPlannedPaymentDate';
import { TOP_UP_HASH } from 'containers/credit-cards/constants';

export const FinancialOverviewContainer = () => {
  const { data, loading } = useCreditCardLimits({
    fetchPolicy: 'cache-and-network',
  });

  const { plannedPaymentDate, loading: isLoadingPlannedPaymentDate } =
    useGetPlannedPaymentDate();

  const navigate = useNavigate();
  const location = useLocation();
  const organization = useFullOrganization();

  const paymentFrequency = organization?.creditCardsDetails?.paymentFrequency;

  if (loading || isLoadingPlannedPaymentDate) {
    return <FinancialOverviewLoading />;
  }

  const handleClick = () => {
    navigate({
      hash: TOP_UP_HASH,
      search: location.search,
    });
  };

  const orgLimits = {
    total: data?.total ?? 0,
    available: data?.available ?? 0,
    currency: data?.currency ?? 'EUR',
    precision: data?.precision ?? 2,
    paymentFrequency,
    plannedPaymentDate,
  };

  const isMixedOrg =
    orgLimits.available > orgLimits.total && orgLimits.total !== 0;

  const isPrefund = orgLimits.total === 0;

  if (isMixedOrg) {
    return <FinancialOverviewMixedOrg {...orgLimits} onClick={handleClick} />;
  }

  if (isPrefund) {
    return <FinancialOverviewPrefundOrg {...orgLimits} onClick={handleClick} />;
  }

  return <FinancialOverviewCreditOrg {...orgLimits} />;
};

import { TruncatedText } from '@candisio/design-system/next';
import { CellContext } from '@tanstack/react-table';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const MultipleValueCell = ({
  getValue,
}: CellContext<DocumentsTableData, string[] | undefined>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const value = getValue();
  if (!value || value.length === 0) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  return (
    <TruncatedText
      style={{
        wordBreak: 'break-word',
      }}
    >
      {value.filter(v => !!v).join(', ')}
    </TruncatedText>
  );
};

import { SwimlaneTilePromo } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromo';
import { SwimlaneTilePromoCTAButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCTAButton';
import {
  SwimlaneTilePromoContent,
  SwimlaneTilePromoContentBody,
} from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoContent';
import { SwimlaneTilePromoCloseButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCloseButton';
import { SwimlaneTilePromoImage } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoImage';
import { SwimlaneTileTitle } from 'components/Swimlane/content/SwimlaneTileTitle';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

export const PromoExpenseMobile = ({ onClose }: { onClose: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <SwimlaneTilePromo>
      <SwimlaneTilePromoImage
        src={t('dashboard.swimlanes.promotions.promoExpenseMobile.imageUrl')}
      />
      <SwimlaneTilePromoContent>
        <SwimlaneTilePromoCloseButton onClose={onClose} />
        <SwimlaneTilePromoContentBody>
          {/* Use !line-clamp-3 to improve its readability in German */}
          <SwimlaneTileTitle className="!line-clamp-3">
            {t('dashboard.swimlanes.promotions.promoExpenseMobile.title')}
          </SwimlaneTileTitle>

          <SwimlaneTilePromoCTAButton
            icon="arrowRight"
            iconPosition="right"
            as="a"
            target="_blank"
            href={t(
              'dashboard.swimlanes.promotions.promoExpenseMobile.ctaButton.link'
            )}
          >
            {t(
              'dashboard.swimlanes.promotions.promoExpenseMobile.ctaButton.text'
            )}
          </SwimlaneTilePromoCTAButton>
        </SwimlaneTilePromoContentBody>
      </SwimlaneTilePromoContent>
    </SwimlaneTilePromo>
  );
};

import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import { BookingValues } from '../toolkit/reimbursementSplitBookingsFormSchema';
import { ReimbursementBooking } from 'generated-types/graphql.types';

type FormattedBooking = Pick<
  ReimbursementBooking,
  | 'id'
  | 'amount'
  | 'artistSocialInsuranceCode'
  | 'generalLedgerAccount'
  | 'vatRate'
  | 'note'
  | 'costCenter'
  | 'costObject'
  | 'bookingKey'
  | 'postingText'
  | 'extraCostInfo'
>;

export const useFormattedBookings = () => {
  const formattedBooking = (booking: FormattedBooking): BookingValues => ({
    bookingId: booking.id,
    splitAmount: booking.amount?.value ?? 0,
    artistSocialInsuranceCode: booking.artistSocialInsuranceCode ?? null,
    vatRate: booking.vatRate?.value ?? null,
    note: booking.note?.value ?? null,
    postingText: booking.postingText ?? null,
    taxCode: booking.bookingKey?.value.id ?? null,
    taxPresentation:
      'taxPresentation' in booking
        ? (booking.taxPresentation as TaxPresentation)
        : TaxPresentation.Gross,
    extraCostInfo: booking.extraCostInfo
      ? {
          value: booking.extraCostInfo.value.id,
          inputValue: booking.extraCostInfo.value.readableName,
        }
      : null,
    generalLedgerAccount: booking.generalLedgerAccount
      ? {
          value: booking.generalLedgerAccount.value.id,
          inputValue: booking.generalLedgerAccount.value.readableName,
        }
      : null,
    costCenter: booking.costCenter
      ? {
          value: booking.costCenter.value.id,
          inputValue: booking.costCenter.value.readableName,
        }
      : null,
    costObject: booking.costObject
      ? {
          value: booking.costObject.value.id,
          inputValue: booking.costObject.value.readableName,
        }
      : null,
  });

  return formattedBooking;
};

import { EmptyDataText, EmptyDataWrapper } from 'components/EmptyViews/styles';
import {
  LottieAnimation,
  accountsPayableNumberNotMissing,
} from 'components/Lottie/Lottie';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const AccountsPayable = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.COMMON);

  return (
    <EmptyDataWrapper>
      <LottieAnimation
        options={{ animationData: accountsPayableNumberNotMissing }}
      />
      <EmptyDataText>
        {t(
          'settings.contacts.nodata.forShowWithoutAccountsPayableNumber.title'
        )}
      </EmptyDataText>
    </EmptyDataWrapper>
  );
};

import { checkVAT, germany } from 'jsvat-next';

const SPECIAL_CHARACTERS_VATID = ['.', '/', ' ', '-'];

const SPECIAL_CHARACTERS_REGEX = new RegExp(
  `[${SPECIAL_CHARACTERS_VATID.join('|')}]`,
  'g'
);

const hasSpecialCharacters = (vatId: string) => {
  return Boolean(vatId.match(SPECIAL_CHARACTERS_REGEX));
};

export const isValidVatId = (vatId: string) => {
  const hasSpecialChars = hasSpecialCharacters(vatId);

  return !hasSpecialChars && checkVAT(vatId, [germany]).isValid;
};

import { CellContext } from '@tanstack/react-table';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { useTranslation } from 'react-i18next';

type Value = boolean | undefined;

type CellProps = CellContext<DocumentsTableData, Value>;

export const InvoiceCorrectionCell = ({ getValue }: CellProps) => {
  const { t } = useTranslation();
  const value = getValue();

  const yesKey = 'documents-table:isInvoiceCorrection.yes';
  const noKey = 'documents-table:isInvoiceCorrection.no';

  const message = value ? t(yesKey) : t(noKey);

  return <span>{message}</span>;
};

import { Text } from '@candisio/design-system';
import { TruncatedText } from '@candisio/design-system/next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { EcmDocumentsTableData } from '../types';

type Value = string | undefined;

export type TruncatedTextCellProps = CellProps<EcmDocumentsTableData, Value>;

export const TruncatedTextCell = ({ value }: TruncatedTextCellProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  if (!value) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  return <TruncatedText>{value}</TruncatedText>;
};

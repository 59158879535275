import { Box, Flex, Grid, Heading } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { InboxViewContainer } from 'views/Inbox/components/InboxViewContainer';
import { TabView } from 'views/Inbox/models';
import { ReimbursementTableColumnKeys } from 'views/Reimbursement/toolkit/types';
import { ReimbursementsPromotion } from './ReimbursementsPromotion';
import { ReimbursementCaseStatus } from 'types/ReimbursementCaseStatusEnum';
import { EventLocationsBase } from 'providers/AnalyticsProvider/events';

const tableColumns: ReimbursementTableColumnKeys[] = [
  'status',
  'requesterOfTheReimbursement',
  'title',
  'createdAt',
  'grossAmount',
];

export const ReimbursementsPromotionInbox = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Grid templateRows="auto 1fr" overflow="hidden" height="100%">
      <Flex
        alignItems="center"
        gap="space16"
        padding="space20 space32 space16 space32"
      >
        <Heading as="h1">{t('inbox.heading')}</Heading>
      </Flex>
      <InboxViewContainer activeTab={TabView.INBOX_REIMBURSEMENTS}>
        <Box
          height="100%"
          overflow="hidden"
          paddingRight="space32"
          paddingLeft="space32"
        >
          <ReimbursementsPromotion
            tableColumns={tableColumns}
            status={ReimbursementCaseStatus.Check}
            eventLocation={EventLocationsBase.INBOX}
          />
        </Box>
      </InboxViewContainer>
    </Grid>
  );
};

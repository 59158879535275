import { Button, Text } from '@candisio/design-system';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { Trans } from 'providers/LocaleProvider';
import { useOptimisticPromoPreference } from '../../../../hooks/useOptimisticPromoPreference';

export const useDocumentDetailsPromo = () => {
  return useOptimisticPromoPreference(
    TOUR_POPOVER_IDS.NEW_DOCUMENT_DETAILS_CARD
  );
};

export const DocumentDetailsPromoContainer = () => {
  const { isSeen, markAsSeen } = useDocumentDetailsPromo();

  if (isSeen) return null;

  return <DocumentDetailsPromo onConfirm={markAsSeen} />;
};

interface DocumentDetailsPromoProps {
  onConfirm: () => void;
}

const DocumentDetailsPromo = ({ onConfirm }: DocumentDetailsPromoProps) => (
  <div className="relative bg-blue-bg rounded-medium p-3 border border-blue-200">
    <div className="float-right">
      <Button
        variant="tertiary"
        color="gray"
        icon="close"
        size="xsmall"
        onClick={onConfirm}
      />
    </div>

    <Trans
      parent="span"
      i18nKey={'ecm:promo.documentRelationsImproved.documentDetails.info'}
    >
      <Text fontWeight="semibold">Neu: </Text>
      Dokumentenrelevante Informationen gebündelt an einem Ort.
    </Trans>
  </div>
);

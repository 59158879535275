import { mapDocumentStatusToStatus } from 'components/DocumentViewer/AttachmentsTable/utils';
import { Amount, DocumentsTableData } from 'components/DocumentsTable/types';
import { parseHighlight } from 'components/WithHighlightsCell/parseHighlight';
import {
  ArchiveInvoiceDocumentBooking,
  ArchiveInvoiceDocumentEdge,
  ArchiveInvoicesHighlightableFields,
  ArtistSocialInsuranceCode,
  PaymentMethod,
  PurchaseOrderIntegrationType,
} from 'generated-types/graphql.types';
import { getAssociatedDocuments } from './getAssociatedSapDocuments';
import { calculateCashDiscount } from './calculateCashDiscount';

type MappedBooking = {
  costCenter?: string;
  costObject?: string;
  extraCostInfo?: string;
  artistSocialInsuranceCode?: string;
  generalLedgerAccount?: string;
  dueDate?: Date;
  note?: string;
  postingText?: string;
  discountAmount?: number;
  discountPercentage?: number;
  discountPaymentDate?: Date;
};

type AllBookingDetails = {
  costCenters: (string | undefined)[];
  costObjects: (string | undefined)[];
  extraCostInfos: (string | undefined)[];
  artistSocialInsuranceCodes: (string | undefined)[];
  generalLedgerAccounts: (string | undefined)[];
  dueDates: (Date | undefined)[];
  notes: (string | undefined)[];
  postingTexts: (string | undefined)[];
  discountAmounts: (number | undefined)[];
  discountPercentages: (number | undefined)[];
  discountPaymentDates: (Date | undefined)[];
};

const mapToBookings =
  (
    artistSocialInsuranceCodeTranslations: Record<
      ArtistSocialInsuranceCode,
      string
    >
  ) =>
  (
    booking: ArchiveInvoiceDocumentBooking | null | undefined
  ): MappedBooking => {
    if (!booking) {
      return {};
    }

    const artistSocialInsuranceCode = booking.artistSocialInsuranceCode
      ? artistSocialInsuranceCodeTranslations[booking.artistSocialInsuranceCode]
      : undefined;

    return {
      costCenter: booking.costCenter?.readableName ?? undefined,
      costObject: booking.costObject?.readableName ?? undefined,
      extraCostInfo: booking.extraCostInfo?.readableName ?? undefined,
      artistSocialInsuranceCode,
      dueDate: booking.dueDate ? new Date(booking.dueDate) : undefined,
      discountAmount: booking.discountAmount ?? undefined,
      discountPercentage: booking.discountPercentage ?? undefined,
      discountPaymentDate: booking.discountPaymentDate
        ? new Date(booking.discountPaymentDate)
        : undefined,
      note: booking.note ?? undefined,
      generalLedgerAccount:
        booking.generalLedgerAccount?.readableName ?? undefined,
      postingText: booking.postingText ?? undefined,
    };
  };

const reduceAllDetails = (
  allBookingDetails: AllBookingDetails,
  booking: MappedBooking
): AllBookingDetails => {
  const {
    costCenters,
    costObjects,
    extraCostInfos,
    artistSocialInsuranceCodes,
    discountAmounts,
    discountPaymentDates,
    discountPercentages,
    dueDates,
    generalLedgerAccounts,
    notes,
    postingTexts,
  } = allBookingDetails;

  return {
    costCenters: [...costCenters, booking.costCenter],
    costObjects: [...costObjects, booking.costObject],
    extraCostInfos: [...extraCostInfos, booking.extraCostInfo],
    artistSocialInsuranceCodes: [
      ...artistSocialInsuranceCodes,
      booking.artistSocialInsuranceCode,
    ],
    discountAmounts: [...discountAmounts, booking.discountAmount],
    discountPaymentDates: [
      ...discountPaymentDates,
      booking.discountPaymentDate,
    ],
    discountPercentages: [...discountPercentages, booking.discountPercentage],
    dueDates: [...dueDates, booking.dueDate],
    generalLedgerAccounts: [
      ...generalLedgerAccounts,
      booking.generalLedgerAccount,
    ],
    notes: [...notes, booking.note],
    postingTexts: [...postingTexts, booking.postingText],
  };
};

export const mapToArchiveInvoiceDocumentsTableData = (
  edges: ArchiveInvoiceDocumentEdge[],
  artistSocialInsuranceCodeTranslations: Record<
    ArtistSocialInsuranceCode,
    string
  >,
  previewDocumentId?: string | null
) => {
  const documentsTableData: DocumentsTableData[] = edges.map(edge => {
    const amount = edge.node.amount?.amount;
    const currency = edge.node.amount?.currency;
    const grossAmount: Amount | undefined =
      amount && currency
        ? { amount, currency, isBasicMonetaryUnit: true }
        : undefined;

    const bookings: MappedBooking[] = (edge.node.bookings ?? []).map(
      mapToBookings(artistSocialInsuranceCodeTranslations)
    );

    const accumulatorDetails: AllBookingDetails = {
      costCenters: [],
      costObjects: [],
      extraCostInfos: [],
      artistSocialInsuranceCodes: [],
      discountAmounts: [],
      discountPaymentDates: [],
      discountPercentages: [],
      dueDates: [],
      generalLedgerAccounts: [],
      notes: [],
      postingTexts: [],
    };

    const allBookingDetails = bookings.reduce(
      reduceAllDetails,
      accumulatorDetails
    );

    const highlights = parseHighlight<keyof DocumentsTableData>(
      edge.highlights ?? [],
      highlightFieldToColumIdMapper
    );
    const { purchaseOrderNumbers, goodsReceiptNumbers } =
      getAssociatedDocuments(edge.node.associatedDocuments);

    return {
      id: edge.node.id,
      status: {
        status: mapDocumentStatusToStatus(edge.node.status),
        isOverdue: !!edge.node.isOverdue,
        isDuplicate: !!edge.node.isDuplicate,
        isEInvoice: !!edge.node.eInvoice?.format,
        eInvoice: {
          format: edge.node.eInvoice?.format,
          error: edge.node.eInvoice?.error,
        },
      },
      contact: edge.node.contact?.name ?? undefined,
      accountsPayableNumber:
        edge.node.contact?.accountsPayableNumber ?? undefined,
      accountsReceivableNumber:
        edge.node.contact?.accountsReceivableNumber ?? undefined,
      createdAt: edge.node.createdAt
        ? new Date(edge.node.createdAt)
        : undefined,
      grossAmount,
      invoiceNumber: edge.node.invoiceNumber,
      invoiceDate: edge.node.invoiceDate
        ? new Date(edge.node.invoiceDate)
        : undefined,
      dueDate: allBookingDetails['dueDates'],
      costCenter: allBookingDetails['costCenters'],
      costObject: allBookingDetails['costObjects'],
      extraCostInfo: allBookingDetails['extraCostInfos'],
      postingText: allBookingDetails['postingTexts'],
      generalLedgerAccount: allBookingDetails['generalLedgerAccounts'],
      note: allBookingDetails['notes'],
      requester: edge.node.requester ?? undefined,
      approvers: edge.node.approvers ?? [],
      requestedAt: edge.node.requestedAt
        ? new Date(edge.node.requestedAt)
        : undefined,
      artistSocialInsuranceCode:
        allBookingDetails['artistSocialInsuranceCodes'],
      isPayable: edge.node.isPayable ?? false,
      cursor: edge.cursor,
      deliveryDate: edge.node.invoiceDeliveryDate
        ? new Date(edge.node.invoiceDeliveryDate)
        : undefined,
      discountDateWPercentage: calculateCashDiscount(
        bookings?.map(booking => ({
          discountPaymentDate: booking.discountPaymentDate,
          discountAmount: booking.discountAmount,
          discountPercentage: booking.discountPercentage,
        }))
      ),
      creditCardPayment:
        edge.node.payment?.isPaid === true &&
        edge.node.payment?.method === PaymentMethod.CardTransaction,
      purchaseOrderNumber: edge.node.purchaseOrderData?.orderNumber,
      hasPurchaseOrderLinked:
        edge.node.purchaseOrderData?.integrationType ===
        PurchaseOrderIntegrationType.Sap,
      tags: edge.node.tags,
      paymentInfo: {
        bankAccountNumber: undefined,
        iban: edge.node.iban ?? undefined,
        swiftCode: edge.node.swiftCode ?? undefined,
      },
      accountingArea: edge.node.accountingArea?.name ?? undefined,
      sapPurchaseOrderNumber: purchaseOrderNumbers,
      goodsReceiptNumber: goodsReceiptNumbers,
      selected: previewDocumentId === edge.node.id,
      fileName: edge.node.documentFile?.name ?? undefined,
      fileSize: edge.node.documentFile?.size ?? undefined,
      documentType: edge.node.invoiceCategoryType,
      paymentStatus: edge.node.payment?.isPaid
        ? 'PaidDocumentState'
        : 'UnpaidDocumentState',
      paidAt: edge.node.payment?.paidAt
        ? new Date(edge.node.payment.paidAt)
        : undefined,
      isInvoiceCorrection: edge.node.amount
        ? Boolean(edge.node.amount.amount < 0)
        : false,
      isWaitingForClarification: false, // Not implemented in new ES
      highlights,
    };
  });

  return documentsTableData;
};

const highlightFieldToColumIdMapper: Partial<
  Record<ArchiveInvoicesHighlightableFields, keyof DocumentsTableData>
> = {
  [ArchiveInvoicesHighlightableFields.AccountingArea]: 'accountingArea',
  [ArchiveInvoicesHighlightableFields.Amount]: 'grossAmount',
  [ArchiveInvoicesHighlightableFields.ContactAccountsPayableNumber]:
    'accountsPayableNumber',
  [ArchiveInvoicesHighlightableFields.ContactName]: 'contact',
  [ArchiveInvoicesHighlightableFields.CostCenter]: 'costCenter',
  [ArchiveInvoicesHighlightableFields.CostObject]: 'costObject',
  [ArchiveInvoicesHighlightableFields.DocumentNumber]: 'invoiceNumber',
  [ArchiveInvoicesHighlightableFields.ExtraCostInfo]: 'extraCostInfo',
  [ArchiveInvoicesHighlightableFields.FileName]: 'fileName',
  [ArchiveInvoicesHighlightableFields.GeneralLedgerAccount]:
    'generalLedgerAccount',
  [ArchiveInvoicesHighlightableFields.Iban]: 'paymentInfo',
  [ArchiveInvoicesHighlightableFields.Notes]: 'note',
  [ArchiveInvoicesHighlightableFields.PostingText]: 'postingText',
  [ArchiveInvoicesHighlightableFields.PurchaseOrderNumber]:
    'purchaseOrderNumber',
  [ArchiveInvoicesHighlightableFields.RawContent]: 'rawContentHighlight',
};

import { CreationModalMultiStep } from 'components/CreationModalMultiStep/CreationModalMultiStep';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { CheckTeamName } from '../../toolkit/hooks/useCheckTeamNameAvailability';
import { Member, Team } from '../../toolkit/hooks/useGetTeams';
import { teamDetailsFormErrorMessages } from '../../toolkit/utils/teamDetailsFormErrorMessages';
import {
  TeamDetailsFormOutput,
  TeamDetailsFormValues,
  TeamDetailsSchemaOptions,
  teamDetailsFormSchema,
} from '../../toolkit/utils/teamDetailsFormSchema';
import { TeamDetailsInfoCard } from '../components/TeamDetailsInfoCard';
import { TeamDetailsContainer } from './TeamDetailsContainer';
import { TeamMembersContainer } from './TeamMembersContainer';

export enum Step {
  TeamDetails,
  TeamMembers,
}

export type TeamAdmin = Team['teamAdmin'];

interface CreateTeamModalFormProps {
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  checkTeamName: CheckTeamName;
  onSubmit: (data: TeamDetailsFormOutput) => Promise<void>;
}

const defaultValues: TeamDetailsFormValues = {
  teamName: undefined,
  teamAdmin: undefined,
  teamMembers: undefined,
};

export const CreateTeamModalForm = ({
  isOpen,
  isSubmitting,
  checkTeamName,
  onClose,
  onSubmit,
}: CreateTeamModalFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const [step, setStep] = useState<Step>(Step.TeamDetails);
  const [teamAdmin, setTeamAdmin] = useState<TeamAdmin>();
  const [members, setMembers] = useState<Member[]>();

  const context: TeamDetailsSchemaOptions = {
    checkTeamName,
  };

  const form = useForm<TeamDetailsFormOutput>({
    defaultValues,
    mode: 'onTouched',
    context,
    resolver: zodResolver({
      zodSchema: teamDetailsFormSchema,
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
      errorMessages: teamDetailsFormErrorMessages,
    }),
  });

  const name = form.watch('teamName');

  const team: Team = useMemo(
    () => ({
      id: undefined,
      name,
      members,
      teamAdmin,
    }),
    [name, members, teamAdmin]
  );

  return (
    <FormProvider {...form}>
      <form>
        <CreationModalMultiStep
          onClose={onClose}
          isOpen={isOpen}
          currentStep={step}
          steps={t('teams.createTeam.steps', {
            returnObjects: true,
          })}
          leftSide={<TeamDetailsInfoCard team={team} />}
        >
          {step === Step.TeamDetails && (
            <TeamDetailsContainer
              onSetTeamAdmin={setTeamAdmin}
              onChangeStep={setStep}
            />
          )}
          {step === Step.TeamMembers && (
            <TeamMembersContainer
              isSubmitting={isSubmitting}
              onSetMembers={setMembers}
              onSubmit={form.handleSubmit(onSubmit)}
              onGoBack={() => setStep(Step.TeamDetails)}
            />
          )}
        </CreationModalMultiStep>
      </form>
    </FormProvider>
  );
};

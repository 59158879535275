import { ReactNode, memo } from 'react';
import styles from './XmlHeader.module.css';

export interface XmlHeaderProps {
  downloadSection?: ReactNode;
  additionalControls?: ReactNode;
}

export const XmlHeader = memo(
  ({ downloadSection, additionalControls }: XmlHeaderProps) => {
    return (
      <div className={styles['xml-header']}>
        {additionalControls}
        {downloadSection}
      </div>
    );
  }
);

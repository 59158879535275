import { useMemo } from 'react';
import { GeneralExpenseFieldsWithExtraction } from '../GeneralExpenseFieldsWithExtraction';
import { HospitalityExpenseFieldsWithExtraction } from '../HospitalityExpenseFieldsWithExtraction';
import { SummaryGeneralExpense } from '../SummaryGeneralExpense';
import { SummaryHospitalityExpense } from '../SummaryHospitalityExpense';

interface RightSectionViewProps {
  isExpenseFormEditable: boolean;
}

export const useHospitalityRightSectionView = ({
  isExpenseFormEditable,
}: RightSectionViewProps) => {
  return useMemo(() => {
    if (!isExpenseFormEditable) {
      return SummaryHospitalityExpense;
    }

    return HospitalityExpenseFieldsWithExtraction;
  }, [isExpenseFormEditable]);
};

export const useGeneralRightSectionView = ({
  isExpenseFormEditable,
}: RightSectionViewProps) => {
  return useMemo(() => {
    if (!isExpenseFormEditable) {
      return SummaryGeneralExpense;
    }

    return GeneralExpenseFieldsWithExtraction;
  }, [isExpenseFormEditable]);
};

import {
  queryParameter,
  searchScopeParameter,
  searchTypeParameter,
} from 'components/Table/consts';
import { Query } from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import debounce from 'lodash/debounce';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useEffect, useMemo, useState } from 'react';
import { mapToArchiveInvoiceDocumentsTableData } from 'views/utils/mapToArchiveInvoiceDocumentsTableData';
import { useArtistSocialInsuranceCodeTranslationsMap } from 'views/utils/useArtistSocialInsuranceCodeTranslationsMap';
import { useIncludePurchaseOrderData } from 'views/utils/useShouldShowField';
import { archiveInvoiceDocumentsTableQuery } from '../../Documents/queries';
import { useComputeQueryFilters } from './useComputeQueryFilters';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { useOtherIntegration } from 'orgConfig/other';

export interface ArchiveDocumentsDataParams {
  previewDocumentId?: string | null;
}

export const useArchiveDocumentsData = ({
  previewDocumentId,
}: ArchiveDocumentsDataParams) => {
  const { shouldUseCandisApi } = useOtherIntegration();

  const [searchParams, setSearchParam] = useSearchParams();
  const searchQuery = searchParams.get(queryParameter) ?? '';
  const [debounceSearchValue, setDebounceSearchValue] = useState(searchQuery);

  const includePurchaseOrderData = useIncludePurchaseOrderData();
  const { limit, filters, dateRangeFilters, sort, searchInput } =
    useComputeQueryFilters();

  const { data, loading, onLoadMore } = usePagination<
    Pick<Query, 'archiveInvoiceDocuments'>
  >(archiveInvoiceDocumentsTableQuery, 'archiveInvoiceDocuments', {
    variables: {
      limit,
      filters: { ...filters, ...dateRangeFilters },
      sort,
      query: debounceSearchValue ?? '',
      withPurchaseOrderData: includePurchaseOrderData,
      withAccountsReceivableNumber: shouldUseCandisApi,
      searchInput,
    },
  });

  const documentsList = data?.archiveInvoiceDocuments;

  const documentEdges = documentsList?.edges || [];
  const documentsCount = documentsList?.pageInfo?.totalCount;
  const hasMoreData = documentsList?.pageInfo?.hasNextPage ?? false;
  const isTableEmpty = documentEdges.length === 0 && !loading;
  const endCursor = documentsList?.pageInfo?.endCursor;
  const artistSocialInsuranceCodeTranslations =
    useArtistSocialInsuranceCodeTranslationsMap();
  const documentsTableData = mapToArchiveInvoiceDocumentsTableData(
    documentEdges,
    artistSocialInsuranceCodeTranslations,
    previewDocumentId
  );

  const selectedDocumentsCount =
    data?.archiveInvoiceDocuments?.pageInfo?.totalCount ??
    data?.archiveInvoiceDocuments?.edges?.length ??
    0;

  const handleDebounceSearch = useMemo(() => {
    return debounce(
      (value: string) => {
        setDebounceSearchValue(value);

        if (value) {
          searchParams.set(queryParameter, value);
        } else {
          searchParams.delete(queryParameter);
          searchParams.delete(searchTypeParameter);
          searchParams.delete(searchScopeParameter);
        }
        setSearchParam(searchParams, { replace: true });
      },
      DEFAULT_DEBOUNCE_TIME,
      {
        trailing: true,
      }
    );
  }, [searchParams, setSearchParam]);

  useEffect(() => {
    return () => {
      handleDebounceSearch.cancel();
    };
  }, [handleDebounceSearch]);

  return {
    documentsCount,
    documentsTableData,
    endCursor,
    handleDebounceSearch,
    hasMoreData,
    isTableEmpty,
    loading,
    onLoadMore,
    selectedDocumentsCount,
    searchQuery,
    isUsingSearchQuery: false,
  };
};

import {
  ImportHistoryStatus,
  ProjectCodesImportHistoryPaginationQueryVariables,
  Query,
} from 'generated-types/graphql.types';
import { usePagePagination } from 'providers/GraphQLProvider/Pagination/usePagePagination';
import { projectCodesImportHistoryPaginationQuery } from './gql';
import { useMemo } from 'react';
import { ProjectCodeImportHistoryItemData } from './types';

export const useProjectCodesImportHistoryData = () => {
  const { data, loading, onLoadMore } = usePagePagination<
    Pick<Query, 'importHistories'>,
    ProjectCodesImportHistoryPaginationQueryVariables
  >(projectCodesImportHistoryPaginationQuery, 'importHistories', {
    variables: {
      input: {
        page: 1,
        pageSize: 20,
      },
    },
  });

  const historyItems = data?.importHistories?.records || [];

  const tableData: ProjectCodeImportHistoryItemData[] = useMemo(() => {
    return historyItems
      .filter(
        historyItem => historyItem?.status !== ImportHistoryStatus.InProcess
      )
      ?.map(item => {
        return {
          id: item?._id || '',
          createdAt: item?.createdAt || '',
          username: item?.createdBy?.name || '',
          successfullyCreatedEntitiesCount:
            item?.successfullyCreatedEntitiesCount || 0,
          successfullyUpdatedEntitiesCount:
            item?.successfullyUpdatedEntitiesCount || 0,
          failedCounts: (item?.failedEntities || []).length,
          apiErrorMessage:
            item?.importErrorDetails?.formattedErrorMessage || '',
        };
      });
  }, [historyItems]);

  return {
    items: tableData,
    loading: loading,
    onLoadMore: onLoadMore,
  };
};

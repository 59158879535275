import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import { SwimlaneTilePromo } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromo';
import { SwimlaneTilePromoCTAButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCTAButton';
import {
  SwimlaneTilePromoContent,
  SwimlaneTilePromoContentBody,
} from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoContent';
import { SwimlaneTilePromoCloseButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCloseButton';
import { SwimlaneTilePromoImage } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoImage';
import { SwimlaneTileTitle } from 'components/Swimlane/content/SwimlaneTileTitle';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { WistiaVideoModalLauncher } from 'components/WistiaModal/WistiaModalLauncher';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';

export const PromoCreditCardExplained = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isPromotionSeen, setPromotionIsSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.CREDIT_CARD_EXPLAINED
  );

  if (isPromotionSeen) return null;

  return (
    <SwimlaneTilePromo>
      <SwimlaneTilePromoImage
        src={t(
          'insights.swimlanes.promotions.promoCreditCardExplained.imageUrl'
        )}
      />
      <SwimlaneTilePromoContent>
        <SwimlaneTilePromoCloseButton onClose={setPromotionIsSeen} />
        <SwimlaneTilePromoContentBody>
          <SwimlaneTileDescription>
            {t(
              'insights.swimlanes.promotions.promoCreditCardExplained.description'
            )}
          </SwimlaneTileDescription>
          <SwimlaneTileTitle>
            {t('insights.swimlanes.promotions.promoCreditCardExplained.title')}
          </SwimlaneTileTitle>

          <WistiaVideoModalLauncher
            wistiaId={t(
              'insights.swimlanes.promotions.promoCreditCardExplained.ctaButton.wistiaId'
            )}
          >
            <SwimlaneTilePromoCTAButton icon="arrowRight" iconPosition="right">
              {t(
                'insights.swimlanes.promotions.promoCreditCardExplained.ctaButton.text'
              )}
            </SwimlaneTilePromoCTAButton>
          </WistiaVideoModalLauncher>
        </SwimlaneTilePromoContentBody>
      </SwimlaneTilePromoContent>
    </SwimlaneTilePromo>
  );
};

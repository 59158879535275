import { Table } from '@candisio/design-system/next';
import { TruncatedText } from '@candisio/design-system/next';
import { EcmDocumentsTableData } from '../types';
import { useEcmContractTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmContractTypeItems';
import { ContractStatusCell } from './Cells/ContractStatusCell';
import { DateCell } from './Cells/DateCell';
import { DocumentType } from 'generated-types/graphql.types';
import { useEcmDocumentTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmDocumentTypeItems';
import { TagsCell } from './Cells/TagsCell';
import { UserWithStatusCell } from './Cells/UserWithStatusCell';
import { ComponentProps, useMemo } from 'react';
import { Header } from './Header';
import { StatusCell } from './Cells/StatusCell';
import { WithHighlightsCell } from 'components/WithHighlightsCell/next/WithHighlightsCell';
import { TruncatedTextCell } from './Cells/TruncatedTextCell';
import { AmountCellHighlightable } from 'components/DocumentsTable/Cells/next/AmountCellHighlightable';
import { AmountCellDocument } from './Cells/AmountCell';
import { DocumentNameCell } from './Cells/DocumentNameCell';
import { RelativeDateCell } from './Cells/RelativeDateCell';
import { createColumnHelper } from '@tanstack/react-table';
import { LinkToDocument } from './LinkToDocument';
import styles from './EcmDocumentsTableV2.module.css';

type TableProps = ComponentProps<typeof Table<EcmDocumentsTableData>>;

export interface EcmDocumentsTableV2Props {
  data: EcmDocumentsTableData[];
  onLoadMore: () => void;
  sorting?: TableProps['sorting'];
  setSorting?: TableProps['setSorting'];
  rowOverlay?: TableProps['rowOverlay'];
  isLoading?: TableProps['isLoading'];
  CellWrapper?: TableProps['CellWrapper'];
  shownColumns?: (keyof EcmDocumentsTableData)[];
}

const columnHelper = createColumnHelper<EcmDocumentsTableData>();

export const defaultSortByFields: (keyof EcmDocumentsTableData)[] = [
  'documentStatus',
  'documentDate',
  'documentType',
  'startDate',
  'endDate',
  'terminationDate',
  'terminationReminderDate',
  'amount',
  // the ones below are included based on FF showConsistentSortAndFiltering being enabled, hopefully we release the table after the FF is archived
  'contact',
  'documentNumber',
  'documentName',
  'contractType',
  'costCenter',
];

export const EcmDocumentsTableV2 = ({
  data,
  onLoadMore,
  setSorting,
  sorting,
  rowOverlay,
  isLoading,
  shownColumns,
  CellWrapper = LinkToDocument,
}: EcmDocumentsTableV2Props) => {
  const { getContractTypeLabel } = useEcmContractTypeOptions();
  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();

  const defaultColumn: TableProps['defaultColumn'] = useMemo(
    () => ({
      header: Header,
      cell: props => (
        <WithHighlightsCell
          cellProps={props}
          FallbackCell={TruncatedTextCell}
        />
      ),
    }),
    []
  );

  const columns = useMemo(() => {
    const shouldSortByField = (field: keyof EcmDocumentsTableData) =>
      defaultSortByFields.includes(field);

    return [
      columnHelper.accessor('documentStatus', {
        cell: ({ getValue }) => {
          const value = getValue();
          return (
            <StatusCell
              isEInvoice={value?.isEInvoice}
              isSensitive={value?.isSensitive}
              status={value?.status}
            />
          );
        },
        enableSorting: shouldSortByField('documentStatus'),
      }),
      columnHelper.accessor('contractType', {
        cell: ({ getValue }) => {
          const value = getValue();
          const id = value?.id ?? '';
          const label = getContractTypeLabel(id);
          return <TruncatedText>{label ?? '-'}</TruncatedText>;
        },
        size: 200,
        enableSorting: shouldSortByField('contractType'),
      }),
      columnHelper.accessor('contractStatus', {
        cell: ContractStatusCell,
        enableSorting: shouldSortByField('contractStatus'),
      }),
      columnHelper.accessor('contact', {
        size: 200,
        enableSorting: shouldSortByField('contact'),
      }),
      columnHelper.accessor('amount', {
        size: 200,
        cell: props => (
          <AmountCellHighlightable
            cellProps={props}
            FallbackCell={AmountCellDocument}
          />
        ),
        header: props => <Header {...props} className={styles['header']} />,
        enableSorting: shouldSortByField('amount'),
      }),
      columnHelper.accessor('costCenter', {
        enableSorting: shouldSortByField('costCenter'),
      }),
      columnHelper.accessor('createdAt', {
        cell: DateCell,
        enableSorting: shouldSortByField('createdAt'),
      }),

      columnHelper.accessor('documentDate', {
        cell: DateCell,
        enableSorting: shouldSortByField('documentDate'),
      }),
      columnHelper.accessor('documentName', {
        size: 200,
        cell: props => (
          <WithHighlightsCell
            cellProps={props}
            FallbackCell={DocumentNameCell}
          />
        ),
        enableSorting: shouldSortByField('documentName'),
      }),
      columnHelper.accessor('documentNumber', {
        size: 200,
        enableSorting: shouldSortByField('documentNumber'),
      }),
      columnHelper.accessor('documentType', {
        cell: ({ getValue }) => {
          return ecmDocumentTypeTranslationMap[getValue() as DocumentType];
        },
        size: 200,
        enableSorting: shouldSortByField('documentType'),
      }),
      columnHelper.accessor('tags', {
        cell: TagsCell,
        size: 200,
        enableSorting: shouldSortByField('tags'),
      }),
      columnHelper.accessor('notes', {
        enableSorting: shouldSortByField('notes'),
      }),
      columnHelper.accessor('startDate', {
        cell: DateCell,
        enableSorting: shouldSortByField('startDate'),
      }),
      columnHelper.accessor('endDate', {
        cell: DateCell,
        enableSorting: shouldSortByField('endDate'),
      }),
      columnHelper.accessor('terminationDate', {
        cell: DateCell,
        enableSorting: shouldSortByField('terminationDate'),
      }),
      columnHelper.accessor('terminationReminderDate', {
        cell: RelativeDateCell,
        enableSorting: shouldSortByField('terminationReminderDate'),
      }),
      columnHelper.accessor('responsiblePerson', {
        cell: UserWithStatusCell,
        enableSorting: shouldSortByField('responsiblePerson'),
      }),
      columnHelper.accessor('notifyPerson', {
        cell: UserWithStatusCell,
        enableSorting: shouldSortByField('notifyPerson'),
      }),
    ];
  }, [getContractTypeLabel, ecmDocumentTypeTranslationMap]);

  const columnVisibility: TableProps['columnVisibility'] = useMemo(() => {
    if (!shownColumns) return undefined;

    return columns.reduce(
      (acc, column) => {
        const key = column.accessorKey as keyof EcmDocumentsTableData;
        acc[key] = shownColumns.includes(key);
        return acc;
      },
      {} as Record<keyof EcmDocumentsTableData, boolean>
    );
  }, [shownColumns, columns]);

  return (
    <div className="flex flex-col h-full w-full relative overflow-hidden bg-white rounded-b-medium">
      <Table
        data={data}
        columns={columns}
        defaultColumn={defaultColumn}
        onEndReached={onLoadMore}
        CellWrapper={CellWrapper}
        sorting={sorting}
        setSorting={setSorting}
        rowOverlay={rowOverlay}
        isLoading={isLoading}
        columnVisibility={columnVisibility}
        columnOrder={shownColumns}
      />
    </div>
  );
};

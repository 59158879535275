import { Item } from '@candisio/design-system';
import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { HookFormUserFieldItem } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { HookFormUsersFieldOption } from 'components/HookFormFields/HookFormUsersField/HookFormUsersFieldOption';
import { FieldValues } from 'react-hook-form';

export interface HookFormUserFieldProps<TFormValues extends FieldValues>
  extends Omit<HookFormComboBoxFieldProps<TFormValues>, 'defaultItems'> {
  /** List of field items */
  defaultItems?: HookFormUserFieldItem[];
  dataCy?: string;
}

/** A Hook Form field for selecting a single user */
export const HookFormUserField = <TFormValues extends FieldValues>({
  defaultItems = [],
  name,
  dataCy,
  ...restProps
}: HookFormUserFieldProps<TFormValues>) => {
  return (
    <HookFormComboBoxField
      name={name}
      defaultItems={defaultItems}
      data-cy={dataCy}
      {...restProps}
    >
      {({ key, children, avatarUrl }) => (
        <Item key={key} textValue={children}>
          <HookFormUsersFieldOption avatarUrl={avatarUrl}>
            {children}
          </HookFormUsersFieldOption>
        </Item>
      )}
    </HookFormComboBoxField>
  );
};

import { ErrorMessages } from 'utils/zodFormValidation';
import { z } from 'zod';
import {
  MAX_CONTRACT_SUBCATEGORY_NAME_LENGTH,
  MIN_CONTRACT_SUBCATEGORY_NAME_LENGTH,
} from '../../toolkit/utils';

const duplicateNameIssue = {
  code: z.ZodIssueCode.custom,
  params: {
    translationKey: 'documentTags.drawer.toast.duplicateName',
  },
};

const defaultValues = {
  nameValidation: () => Promise.resolve(true),
};

export const contractSubCategoryDetailsFormSchema = ({
  initialName,
  nameValidation,
}: ContractSubCategoryDetailsFormSchemaOptions = defaultValues) =>
  z.object({
    name: z
      .string()
      .trim()
      .min(MIN_CONTRACT_SUBCATEGORY_NAME_LENGTH)
      .max(MAX_CONTRACT_SUBCATEGORY_NAME_LENGTH)
      .refine(name => {
        if (name === initialName) return true;

        return nameValidation(name);
      }, duplicateNameIssue),
    description: z.string().trim().max(250).nullish(),
  });

export type ContractSubCategoryDetailsFormOutput = z.infer<
  ReturnType<typeof contractSubCategoryDetailsFormSchema>
>;

export type ContractSubCategoryDetailsFormValues =
  Partial<ContractSubCategoryDetailsFormOutput>;

export type ContractSubCategoryDetailsErrorMessages = ErrorMessages<
  ReturnType<typeof contractSubCategoryDetailsFormSchema>
>;

export interface ContractSubCategoryDetailsFormSchemaOptions {
  initialName?: string;
  nameValidation: (name: string) => Promise<boolean>;
}

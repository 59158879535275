import clsx from 'clsx';
import { ComponentProps } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import styles from './DocumentsTableLink.module.css';

interface DocumentsTableLinkProps extends ComponentProps<typeof Link> {}

export const DocumentsTableLink = ({
  className,
  children,
  ...restProps
}: DocumentsTableLinkProps) => {
  return (
    <Link className={clsx(styles['link'], className)} {...restProps}>
      {children}
    </Link>
  );
};

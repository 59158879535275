import { Flex, Text, Grid, useTheme } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { StatusIcon } from 'components/HistoryList/components/StatusIcon';
import { ProjectCodeImportHistoryItemData } from './types';
import { ImportStatus } from './ImportStatus';

type ProjectCodeImportHistoryItemProps = {
  data: ProjectCodeImportHistoryItemData;
  showStatus?: boolean;
};

export const ProjectCodeImportHistoryItem = ({
  data,
  showStatus = true,
}: ProjectCodeImportHistoryItemProps) => {
  const [t] = useTranslation();
  const dateFormatter = DateFormatters.compactDatetime;
  const { space } = useTheme();

  return (
    <Grid
      width="100%"
      templateColumns={showStatus ? `${space.space18} auto auto` : 'auto auto'}
      gap="space16"
    >
      {showStatus && (
        <StatusIcon
          hasErrorFile={!!data.failedCounts || !!data.apiErrorMessage}
        />
      )}
      <Grid
        color="gray800"
        fontSize="basic"
        fontWeight="semibold"
        justifySelf="start"
        lineHeight="paragraph"
        gap="space4"
      >
        <ImportStatus item={data} />
        <Flex
          color="gray500"
          fontSize="small"
          fontWeight="regular"
          gap="space4"
        >
          {data.createdAt && (
            <Text>{dateFormatter(new Date(data.createdAt))}</Text>
          )}
          {data.username && (
            <>
              <Text>{t('importHistoryList.bulletPoint')}</Text>
              <Text>{data.username}</Text>
            </>
          )}
        </Flex>
      </Grid>
    </Grid>
  );
};

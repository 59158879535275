import { useRoleLabel } from 'components/DocumentAccess/toolkit/hooks/useRoleLabel';
import { getUniqueUsersById } from 'components/DocumentAccess/utils';
import {
  AccessLevelName,
  ActivityUser,
  useDocumentAccessLevelsQuery,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export enum AccessLevelRoleName {
  Admins = 'ADMINS',
  Accountants = 'ACCOUNTANTS',
  Requesters = 'REQUESTERS',
  Approvers = 'APPROVERS',
  Viewers = 'VIEWERS',
  Editors = 'EDITORS',
  Uploader = 'UPLOADER',
  CardHolder = 'CARD_HOLDER',
  ResponsibleAndNotifiedPersons = 'RESPONSIBLE_AND_NOTIFIED_PERSONS',
  ResponsibleNotifiedAndUploader = 'RESPONSIBLE_NOTIFIED_AND_UPLOADER',
  ResponsiblePersons = 'RESPONSIBLE_PERSONS',
}

export type AccessByData = {
  users: ActivityUser[];
  roleName: AccessLevelRoleName;
  roleTranslationKey: string;
  accessTranslationKey: string;
};

interface UseDocumentAccessLevelDataProps {
  globalDocumentId?: string;
}

export const useDocumentAccessLevelsData = ({
  globalDocumentId,
}: UseDocumentAccessLevelDataProps) => {
  const { data, loading } = useDocumentAccessLevelsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !globalDocumentId,
    variables: globalDocumentId ? { globalDocumentId } : undefined,
  });

  const [t] = useTranslation();

  const { getRoleLabelTranslationKey } = useRoleLabel();

  const documentAccessLevels =
    data?.documentAccessLevels?.__typename === 'AccessLevels'
      ? data.documentAccessLevels.levels
      : [];

  const accessLevels = documentAccessLevels.reduce(
    (acc, level) => {
      acc[level.name] = level.users ?? [];

      return acc;
    },
    {} as Partial<Record<AccessLevelName, ActivityUser[]>>
  );

  const uniqueResponsibleNotifiedUploaderUsers = getUniqueUsersById([
    ...(accessLevels[AccessLevelName.ResponsiblePersons] ?? []),
    ...(accessLevels[AccessLevelName.NotifiedPersons] ?? []),
    ...(accessLevels[AccessLevelName.Uploader] ?? []),
  ]);

  const uniqueResponsibleNotifiedUsers = getUniqueUsersById([
    ...(accessLevels[AccessLevelName.ResponsiblePersons] ?? []),
    ...(accessLevels[AccessLevelName.NotifiedPersons] ?? []),
  ]);

  // AccessByData
  const accessByRoleData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Admins] ?? [],
      roleName: AccessLevelRoleName.Admins,
      roleTranslationKey: getRoleLabelTranslationKey(
        AccessLevelRoleName.Admins,
        accessLevels[AccessLevelName.Admins] ?? []
      ),

      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
    {
      users: accessLevels[AccessLevelName.Accountants] ?? [],
      roleName: AccessLevelRoleName.Accountants,
      roleTranslationKey: getRoleLabelTranslationKey(
        AccessLevelRoleName.Accountants,
        accessLevels[AccessLevelName.Accountants] ?? []
      ),
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
    {
      users: accessLevels[AccessLevelName.Requesters] ?? [],
      roleName: AccessLevelRoleName.Requesters,
      roleTranslationKey: getRoleLabelTranslationKey(
        AccessLevelRoleName.Requesters,
        accessLevels[AccessLevelName.Requesters] ?? []
      ),
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessDataForSensitiveDocuments: AccessByData[] = [
    {
      users: uniqueResponsibleNotifiedUploaderUsers,
      roleName: AccessLevelRoleName.ResponsibleNotifiedAndUploader,
      roleTranslationKey: t(
        'documentAccess.membership.access.contracts.notifiedResponsibleOrUploader'
      ),
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessDataForContracts: AccessByData[] = [
    {
      users: uniqueResponsibleNotifiedUsers,
      roleName: AccessLevelRoleName.ResponsibleAndNotifiedPersons,
      roleTranslationKey: t(
        'documentAccess.membership.access.contracts.notifiedOrResponsiblePersons'
      ),
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessByResponsiblePersonsData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.ResponsiblePersons] ?? [],
      roleName: AccessLevelRoleName.ResponsiblePersons,
      // name from first user because there is only one user
      roleTranslationKey:
        accessLevels[AccessLevelName.ResponsiblePersons]?.[0]?.name ?? '',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessByApproversData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Approvers] ?? [],
      roleName: AccessLevelRoleName.Approvers,
      roleTranslationKey: getRoleLabelTranslationKey(
        AccessLevelRoleName.Approvers,
        accessLevels[AccessLevelName.Approvers] ?? []
      ),
      accessTranslationKey: 'documentAccess.membership.access.approve',
    },
  ];

  const accessByViewersData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Viewers] ?? [],
      roleName: AccessLevelRoleName.Viewers,
      roleTranslationKey: t('documentAccess.membership.access.viewers.title'),
      accessTranslationKey: 'documentAccess.membership.access.view',
    },
  ];

  const accessByEditorsData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Editors] ?? [],
      roleName: AccessLevelRoleName.Editors,
      roleTranslationKey: t('documentAccess.membership.access.editors.title'),
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessByUploaderData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Uploader] ?? [],
      roleName: AccessLevelRoleName.Uploader,
      // name from first user because there is only one user
      roleTranslationKey:
        accessLevels[AccessLevelName.Uploader]?.[0]?.name ?? '',
      accessTranslationKey: 'documentAccess.membership.access.view',
    },
  ];

  const accessByCardHolderData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.CardHolder] ?? [],
      roleName: AccessLevelRoleName.CardHolder,
      roleTranslationKey:
        accessLevels[AccessLevelName.CardHolder]?.[0]?.name ?? '',
      accessTranslationKey: 'documentAccess.membership.access.view',
    },
  ];

  const accessData = {
    accessByUploaderData,
    accessByCardHolderData,
    accessByRoleData,
    accessByApproversData,
    accessByViewersData,
    accessByEditorsData,
    accessDataForSensitiveDocuments,
    accessDataForContracts,
    accessByResponsiblePersonsData,
    loading,
  } as const;

  const pickId = (user: ActivityUser) => user.id;
  const membershipsWithIrrevocableAccess = new Set<string>([
    ...accessData.accessByRoleData.flatMap(role => role.users.map(pickId)),
    ...accessData.accessByApproversData.flatMap(role => role.users.map(pickId)),
    ...accessData.accessByUploaderData.flatMap(role => role.users.map(pickId)),
    ...accessData.accessByCardHolderData.flatMap(role =>
      role.users.map(pickId)
    ),
    ...accessData.accessDataForSensitiveDocuments.flatMap(role =>
      role.users.map(pickId)
    ),
    ...accessData.accessDataForContracts.flatMap(role =>
      role.users.map(pickId)
    ),
  ]);

  return {
    ...accessData,
    membershipsWithIrrevocableAccess: Array.from(
      membershipsWithIrrevocableAccess
    ),
  };
};

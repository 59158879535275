import { TableVirtuosoProps } from 'react-virtuoso';

export const ROW_OVERLAY_CLASS_NAME = 'row-overlay';

export const handleUpdateRowOverlayPosition: TableVirtuosoProps<
  unknown,
  unknown
>['scrollerRef'] = (ref: HTMLElement | Window | null) => {
  if (!(ref instanceof HTMLElement)) return;
  const virtuosoScroller = ref;
  const updatePlacement = updateOverlayPlacement(virtuosoScroller);

  // Update on mount
  updatePlacement();

  // Update whenever scrolling
  virtuosoScroller.addEventListener('scroll', updatePlacement);

  // Update whenever the table is resized
  const resizeObserver = new ResizeObserver(updatePlacement);
  resizeObserver.observe(virtuosoScroller);

  // Update when rows are added/removed
  const mutationObserver = new MutationObserver(updatePlacement);
  mutationObserver.observe(virtuosoScroller, {
    subtree: true,
    childList: true,
  });

  return () => {
    mutationObserver.disconnect();
    resizeObserver.disconnect();
    virtuosoScroller.removeEventListener('scroll', updatePlacement);
  };
};

const updateOverlayPlacement = (virtuosoScroller: HTMLElement) => () => {
  const tableWrapper =
    virtuosoScroller.querySelector<HTMLDivElement>(':scope > div');

  if (!virtuosoScroller || !tableWrapper) return;

  const { scrollLeft } = virtuosoScroller;
  const { offsetWidth } = tableWrapper;

  const rows = tableWrapper.querySelectorAll<HTMLDivElement>(
    `.${ROW_OVERLAY_CLASS_NAME}`
  );
  rows.forEach(row => {
    row.style.setProperty('--overlay-scroll-width', `${scrollLeft}px`);
    row.style.setProperty('--overlay-width', `${offsetWidth}px`);
  });
};

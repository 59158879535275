import { Link } from '@candisio/design-system';
import { TimelineEventWithMobileIndication } from 'components/DocumentHistory/history-components/TimelineEventWithMobileIndication';
import { isMobileClient } from 'components/DocumentHistory/history-components/utils';
import { FileUploadedEventDataFragment } from 'generated-types/graphql.types';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface FileUploadedProps extends FileUploadedEventDataFragment {
  documentId: string;
}

const FileImported = ({ file, documentId }: FileUploadedProps) => {
  const [t] = useTranslation();

  const { track } = useAnalytics();

  return (
    <>
      {file && (
        <>
          {t('document.compact.history.fileUpload.imported')}
          &nbsp;
          <Link
            external
            href={file.url ?? ''}
            download={file.name}
            onClick={() => {
              track(TrackingEvents.DOCUMENT_DOWNLOADED, {
                event_location: 'document_history',
                document_id: documentId ?? '',
              });
            }}
          >
            {file.name}
          </Link>
        </>
      )}
    </>
  );
};

const FileUploaded = ({ file, clientInfo, documentId }: FileUploadedProps) => {
  const fileName = file?.name ?? '';
  const fileUrl = file?.url ?? '';
  const { track } = useAnalytics();

  const isMobileUpload = isMobileClient(clientInfo?.name);

  if (isMobileUpload) {
    return (
      <TimelineEventWithMobileIndication
        iconName="mobileUpload"
        clientName={clientInfo?.name}
        eventComponent={
          <Link
            external
            href={fileUrl}
            download={fileName}
            onClick={() => {
              track(TrackingEvents.DOCUMENT_DOWNLOADED, {
                event_location: 'document_history',
                document_id: documentId ?? '',
              });
            }}
          >
            {fileName}
          </Link>
        }
        mobilePromoTranslation="common:document.compact.history.fileUpload.uploadedOn.mobile"
      />
    );
  } else {
    return (
      <Trans
        i18nKey="document.compact.history.fileUpload.uploadedOn.web"
        values={{ fileName }}
      >
        <Link
          external
          href={fileUrl}
          download={fileName}
          onClick={() => {
            track(TrackingEvents.DOCUMENT_DOWNLOADED, {
              event_location: 'document_history',
              document_id: documentId ?? '',
            });
          }}
        >
          {{ fileName } as any}
        </Link>{' '}
        uploaded
      </Trans>
    );
  }
};

export const FileUploadComponent = ({
  file,
  uploaderEmail,
  clientInfo,
  documentId,
}: FileUploadedProps) => {
  return (
    <>
      {/* uploaderEmail is outdated here, kept for compatibility */}
      {uploaderEmail ? (
        <FileImported file={file} documentId={documentId} />
      ) : (
        <FileUploaded
          file={file}
          clientInfo={clientInfo}
          documentId={documentId}
        />
      )}
    </>
  );
};

import { Item, ListView, ListViewProps } from '@candisio/design-system';
import { BankAccountListViewItem } from 'components/BankAccountListView/BankAccountListViewItem';

export interface BankAccountItem {
  key: string;
  iban: string;
  swiftCode?: string | null;
  name: string;
  isDefault?: boolean;
  logoUrl?: string;
}

export interface BankAccountListViewProps
  extends Omit<
    ListViewProps,
    'onAction' | 'selectedItem' | 'onSelectionChange'
  > {
  /** IBAN list items */
  items?: BankAccountItem[];
  /** Called when IBAN item action invoked */
  onAction?: (selectedItem: string) => void;
  /** Called when currently-selected IBAN item changes */
  onSelectionChange?: (id: string) => Promise<void>;
  /** Currently-selected IBAN item */
  selectedItem?: string;
}

export const BankAccountListView = ({
  items = [],
  onAction,
  onSelectionChange,
  selectedItem,
  ...restProps
}: BankAccountListViewProps) => {
  return (
    <ListView
      onAction={key => {
        onAction?.(key as string);
      }}
      {...restProps}
    >
      {items.map(item => (
        <Item key={item.key} textValue={item.name}>
          <BankAccountListViewItem
            iban={item.iban}
            swiftCode={item.swiftCode}
            logoUrl={item.logoUrl}
            isDefault={selectedItem === item.key}
            name={item.name}
            onSetAsDefault={async () => {
              await onSelectionChange?.(item.key);
            }}
          />
        </Item>
      ))}
    </ListView>
  );
};

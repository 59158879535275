import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardRequestCountData } from 'views/CreditCards/hooks/useCreditCardRequestCountData';
import { NavigationIcon } from '../../NavigationIcon';
import { NavIconProps } from '../../../hooks/useNavigationIcons';

export const MenuCreditCards = (iconProps: NavIconProps) => {
  const { isAdmin, isAccountant, isCardManager } = useUserRoles();

  const { loading, totalRequests } = useCreditCardRequestCountData();

  const creditCardsRoute =
    isAdmin || isCardManager || isAccountant
      ? Routes.CREDIT_CARDS_DASHBOARD
      : Routes.CREDIT_CARDS_CARDHOLDER_OVERVIEW;

  return (
    <NavigationIcon
      {...iconProps}
      route={creditCardsRoute}
      count={isCardManager ? totalRequests : undefined}
      countLoading={loading}
    />
  );
};

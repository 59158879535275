import { Flex, Grid, Icon, TruncatedText } from '@candisio/design-system';
import {
  DocumentCurrency,
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import styles from './ReimbursementItem.module.css';
import clsx from 'clsx';

interface ReimbursementItemProps {
  reimbursementItem: ReimbursementItemForList;
  onClick: () => void;
}

export const mappedTypesToItems: Record<
  ReimbursementItemType,
  {
    placeholder: string;
    icon: () => ReactNode;
  }
> = {
  GENERAL_EXPENSE: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.general',
    icon: () => (
      <div className={clsx(styles.icon, styles.green)}>
        <Icon icon="receipt" size="space18" />
      </div>
    ),
  },
  HOSPITALITY_EXPENSE: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality',
    icon: () => (
      <div className={clsx(styles.icon, styles.pink)}>
        <Icon icon="hospitality" size="space18" />
      </div>
    ),
  },
  PER_DIEM: {
    placeholder: 'reimbursementView.middleSection.form.perDiem.title',
    icon: () => (
      <div className={clsx(styles.icon, styles.blue)}>
        <Icon icon="calendarDays" size="space18" />
      </div>
    ),
  },
  MILEAGE: {
    placeholder: '',
    icon: () => <></>,
  },
};

export const ReimbursementItem = ({
  reimbursementItem,
  onClick,
}: ReimbursementItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { visibleItemId } = useReimbursementFormsContext();
  const { documentMoneyFormatter } = useMoneyFormatter();
  const { id, type, reason, totalAmount, status } = reimbursementItem;
  const { icon: Icon, placeholder } = mappedTypesToItems[type];
  const text = reason ? reason : t(placeholder);

  const amount = totalAmount
    ? totalAmount
    : documentMoneyFormatter({
        precision: 2,
        amount: 0,
        currency: DocumentCurrency.Eur,
      });

  const isItemVisible = visibleItemId === id;

  return (
    <Grid
      className={styles.item}
      data-highlighted={isItemVisible}
      onClick={onClick}
    >
      <Grid
        templateColumns="auto auto"
        alignItems="center"
        justifyItems="start"
        gap="space10"
      >
        <Flex alignItems="center" gap="space4">
          <Icon />
          <TruncatedText fontSize="basic" fontWeight="medium">
            {text}
          </TruncatedText>
        </Flex>
        <TruncatedText
          justifySelf="right"
          fontSize="basic"
          textDecoration={
            status === ReimbursementItemStatus.Excluded
              ? 'line-through'
              : undefined
          }
        >
          {amount}
        </TruncatedText>
      </Grid>
    </Grid>
  );
};

import { Item } from '@candisio/design-system';
import { EmptyState } from 'components/FilterableList/components/EmptyState';
import {
  FilterableList,
  FilterableListProps,
} from 'components/FilterableList/FilterableList';
import { nohistoryData } from 'components/Lottie/Lottie';
import { Trans } from 'providers/LocaleProvider';
import { ProjectCodeImportHistoryItem } from './ProjectCodeImportHistoryItem';
import { FilterableListItem } from 'components/FilterableList/components/FilterableListItem';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ProjectCodeImportHistoryItemData } from './types';
import { useProjectCodesImportHistoryData } from './useProjectCodesImportHistoryData';

export interface ProjectCodeImportHistoryListProps {
  isLoading: boolean;
  data: ProjectCodeImportHistoryItemData[];
  onEndReached?: FilterableListProps['onEndReached'];
}

export const ProjectCodeImportHistoryList = () => {
  const { items, loading, onLoadMore } = useProjectCodesImportHistoryData();
  const navigate = useNavigate();

  const getOnSelect = (item: ProjectCodeImportHistoryItemData) => {
    return item?.failedCounts
      ? () => navigate(`${item.id}`, { relative: 'path' })
      : undefined;
  };

  return (
    <FilterableList
      width="50rem"
      isLoading={loading}
      children={items.map(item => (
        <Item key={item.id} textValue={item.username}>
          <FilterableListItem itemId={item.id} onSelect={getOnSelect(item)}>
            <ProjectCodeImportHistoryItem data={item} />
          </FilterableListItem>
        </Item>
      ))}
      emptyDataState={
        !loading && (
          <EmptyState animationData={nohistoryData}>
            <Trans i18nKey="purchase-orders:history.noData">
              You do not have any imports yet.
            </Trans>
          </EmptyState>
        )
      }
      onEndReached={onLoadMore}
    />
  );
};

import { useFetchDocNavigationData } from 'hooks/useFetchDocNavigationData/useFetchDocNavigationData';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { useCallback, useEffect } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { scrollToTarget } from 'views/CreditCards/utils/utils';
import { Reimbursement } from 'views/Reimbursement/Reimbursement';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/utils/constants';
import { useExpensesFormDefaultValues } from 'views/Reimbursement/components/MiddleSection/utils/useExpensesFormDefaultValues';
import { ReimbursementFormsProvider } from 'views/Reimbursement/context/ReimbursementFormsProvider';
import { ReimbursementSplitBookingsProvider } from 'views/Reimbursement/context/ReimbursementSplitBookingsProvider';
import { useGetReimbursementExpenseItems } from 'views/Reimbursement/hooks/useGetReimbursementExpenseItems';
import { useReimbursementUIConfig } from 'views/Reimbursement/hooks/useReimbursementUIConfig';

export const EcmReimbursementItem = () => {
  const { organizationSlug, reimbursementId, reimbursementItemId } = useParams<{
    organizationSlug: string;
    reimbursementId: string;
    reimbursementItemId: string;
  }>();

  const { searchParams } = useMutateSearchParams();
  const navigate = useNavigate();
  const cursor = searchParams.get(REIMBURSEMENT_URL_PARAM.CURSOR);

  const { mappedItems, loadingItems } = useGetReimbursementExpenseItems();

  const defaultValues = useExpensesFormDefaultValues({
    reimbursementItems: mappedItems,
  });

  const defaultUIConfig = useReimbursementUIConfig();

  const {
    linkBackToList,
    navigationLoading,
    nextDocumentLink,
    prevDocumentLink,
  } = useFetchDocNavigationData({
    cursor,
    organizationSlug,
    route: Routes.ECM_DOCUMENTS,
  });

  // If there's no next document and no previous document, return to list
  const cycleDocument = useCallback(() => {
    if (nextDocumentLink) {
      navigate(nextDocumentLink);
    } else if (prevDocumentLink) {
      navigate(prevDocumentLink);
    } else {
      navigate(linkBackToList);
    }
  }, [linkBackToList, nextDocumentLink, prevDocumentLink, navigate]);

  const linkToNextEcmDocument = nextDocumentLink?.pathname
    ? `${nextDocumentLink?.pathname}?${nextDocumentLink?.search}`
    : undefined;

  const linkToPreviousEcmDocument = prevDocumentLink?.pathname
    ? `${prevDocumentLink?.pathname}?${prevDocumentLink?.search}`
    : undefined;

  const isLoading = loadingItems || navigationLoading;

  // Scroll to the reimbursement item only when everything has finished loading
  useEffect(() => {
    if (!isLoading) {
      scrollToTarget(reimbursementItemId);
    }
  }, [isLoading, reimbursementItemId]);

  return (
    <ReimbursementFormsProvider
      key={`loading-${reimbursementId}-${!!mappedItems.length}`}
      initialUIConfig={defaultUIConfig}
    >
      <ReimbursementSplitBookingsProvider>
        <Reimbursement
          isLoading={isLoading}
          defaultValues={defaultValues}
          cycleReimbursements={cycleDocument}
          nextReimbursementLink={linkToNextEcmDocument}
          prevReimbursementLink={linkToPreviousEcmDocument}
        />
      </ReimbursementSplitBookingsProvider>
    </ReimbursementFormsProvider>
  );
};

import { useTranslation } from 'react-i18next';
import { ApprovalStatus } from 'views/Inbox/models';
import checkImage from 'assets/icon-check.svg';
import closeImage from 'components/DocumentHistory/history-components/images/iconApproveNot.svg';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import {
  ItemApprovalStatus,
  RejectedIcon,
} from 'components/DocumentHistory/styles';

const ReimbursementCaseWorkflowStepRejectedComponent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <ItemApprovalStatus status={ApprovalStatus.DECLINED}>
      <RejectedIcon src={closeImage} alt={t('timeline.stepRejected')} />
      {t('timeline.stepRejected')}
    </ItemApprovalStatus>
  );
};

const ReimbursementCaseWorkflowStepApprovedComponent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <ItemApprovalStatus status={ApprovalStatus.APPROVED}>
      <img src={checkImage} alt={t('timeline.stepApproved')} />
      {t('timeline.stepApproved')}
    </ItemApprovalStatus>
  );
};

export const WORKFLOW_STEP_COMPONENTS = {
  [ApprovalStatus.APPROVED]: ReimbursementCaseWorkflowStepApprovedComponent,
  [ApprovalStatus.DECLINED]: ReimbursementCaseWorkflowStepRejectedComponent,
} as const;

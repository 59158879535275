import { MenuItem } from '@candisio/design-system';
import { KebabMenu } from 'components/KebabMenu/KebabMenu';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useOtherIntegration } from 'orgConfig/other';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';
import { useTranslation } from 'react-i18next';
import { CopyGuidItem } from './CopyGuidItem';

export interface DocumentKebabMenuProps {
  datevGuid?: string;
  documentUrl?: string;
  openInDatevLink?: string;
  documentId?: string;
}

interface MenuItemProps extends MenuItem {
  showMenuItem?: boolean;
}

export const DocumentKebabMenu = ({
  datevGuid,
  documentUrl,
  openInDatevLink,
  documentId,
}: DocumentKebabMenuProps) => {
  const [t] = useTranslation();
  const { success } = useToastMessage();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const { track } = useAnalytics();

  const items: MenuItemProps[] = [
    {
      id: 'open-in-datev',
      label: t('documentContextMenu.openInDatev'),
      onAction: () => {
        window.open(openInDatevLink, '_blank', 'noopener noreferrer');
      },
      disabled: openInDatevLink === undefined,
      showMenuItem: !shouldUseCoreDataApi,
    },
    {
      id: 'copy-guid',
      label: t('documentContextMenu.guidCopyText'),
      onAction: async () => {
        if (!datevGuid) {
          return;
        }

        await navigator.clipboard.writeText(datevGuid);
        success(t('documentContextMenu.guidCopied'));
      },
      disabled: datevGuid === undefined,
      renderItem: item => <CopyGuidItem {...item} />,
    },
    {
      id: 'download-invoice',
      label: t('documentContextMenu.downloadInvoiceText'),
      onAction: () => {
        window.open(documentUrl, '_blank', 'noopener noreferrer');
        track(TrackingEvents.DOCUMENT_DOWNLOADED, {
          event_location: 'document_kebab_menu',
          document_id: documentId ?? '',
        });
      },
      disabled: documentUrl === undefined,
    },
  ];

  const visibleItems = items.filter(item => item.showMenuItem !== false);

  return (
    <KebabMenu items={visibleItems} label={t('documentContextMenu.openMenu')} />
  );
};

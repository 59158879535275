import { PaginatedComboBoxFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { PurchaseOrderFieldItem } from '../PurchaseOrderItem';
import { useLinkPurchaseOrderToDocumentDeprecated } from './useLinkPurchaseOrderToDocument';
import { usePurchaseOrderFieldItems } from './usePurchaseOrderFieldItems';

export interface UsePurchaseOrderFieldOptionsResult {
  props?: PaginatedComboBoxFieldOptions<PurchaseOrderFieldItem>;
  linkPurchaseOrder: (
    purchaseOrderId: string,
    orderNumber?: string
  ) => Promise<void>;
}

export interface UsePurchaseOrderOptionsProps {
  documentId?: string;
  accountsPayableNumber?: string | null;
  documentAmount?: number | null;
  searchValue?: string;
}

/** Returns options for purchase order number field */
export const usePurchaseOrderFieldOptions = ({
  documentId = '',
  documentAmount,
  accountsPayableNumber,
  searchValue = '',
}: UsePurchaseOrderOptionsProps): UsePurchaseOrderFieldOptionsResult => {
  const { isLoading, fetchMoreItems, items, disabledKeys } =
    usePurchaseOrderFieldItems({
      documentAmount,
      accountsPayableNumber,
      searchValue,
    });

  const { linkPurchaseOrder, isLinkingLoading } =
    useLinkPurchaseOrderToDocumentDeprecated({
      documentId,
    });

  return {
    linkPurchaseOrder,
    props: {
      items,
      persistFilter: true,
      disabledKeys,
      onEndReached: fetchMoreItems,
      isLoading: isLoading || isLinkingLoading,
    },
  };
};

import { AvatarStack } from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import {
  BuiltinRoleName,
  MembershipStatus,
} from 'generated-types/graphql.types';
import { useGetUsers } from 'views/Settings/TeamMembers/hooks/useGetUsers';

export const CreateReimbursementDuplicateErrorAvatarStack = () => {
  const { users: admins } = useGetUsers({
    filters: {
      roleNames: [BuiltinRoleName.Admin],
      status: MembershipStatus.Active,
      emailVerified: true,
    },
  });

  return (
    <AvatarStack size="small" className="self-baseline">
      {admins.map(admin => (
        <AvatarWithStatusContainer
          key={admin.id}
          hasTooltip
          img={admin.avatarUrl}
          name={admin.name}
          size="small"
          userId={admin.id}
        />
      ))}
    </AvatarStack>
  );
};

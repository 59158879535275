import { Paragraph, Text } from '@candisio/design-system';
import { ExpenseItemInvoiceNumberUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { NavigateToItemWrapper } from '../NavigateToItemWrapper';

export const ExpenseItemInvoiceNumberUpdated = ({
  resourceId,
  properties,
  reimbursementItemMetadata,
}: ExpenseItemInvoiceNumberUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const headline = expenseTitle
    ? t('timeline.expenseChanged', { title: expenseTitle })
    : t('timeline.expenseChangedNoTitle');
  return (
    <NavigateToItemWrapper id={resourceId}>
      <Text>{headline}</Text>
      <div className="pt-2">
        <Text fontWeight="regular" color="gray500">
          {t(
            'reimbursementView.middleSection.form.hospitality.invoiceNumber.label'
          )}
        </Text>
        <Paragraph>
          {properties.newInvoiceNumber
            ? properties.newInvoiceNumber
            : t('timeline.empty')}
        </Paragraph>
      </div>
    </NavigateToItemWrapper>
  );
};

import { AssociationSource } from 'generated-types/graphql.types';
import { AssociatedDocumentsObject } from '../types';

export const getLinkingSource = (
  associatedDocuments: AssociatedDocumentsObject
) => {
  const { associatedGoodsReceipts, associatedPurchaseOrders } =
    associatedDocuments;
  const associatedDocumentsLength =
    associatedGoodsReceipts.length + associatedPurchaseOrders.length;

  if (!associatedDocumentsLength) return undefined;

  return associatedGoodsReceipts.length > 0
    ? AssociationSource.GoodsReceipt
    : AssociationSource.PurchaseOrder;
};

import {
  AriaTabListProps,
  Box,
  TabItem as DSTabItem,
  Tabs as DSTabs,
  Grid,
  ScrollBox,
  TabPanel,
  useTabListState,
} from '@candisio/design-system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface TabItem extends Pick<DSTabItem, 'title' | 'children'> {
  tabTooltip?: string;
}

interface TabsProps extends AriaTabListProps<TabItem> {
  chatBox?: ReactNode;
}

export const Tabs = ({ chatBox, ...props }: TabsProps) => {
  const [t] = useTranslation();
  const state = useTabListState(props);

  const showChatBox =
    chatBox && state.selectedKey === t('document.tabs.history');

  return (
    <Grid overflow="hidden" background="gray0" borderRadius="medium">
      <Box borderBottom="1px solid gray300">
        <DSTabs alignment="stretch" {...props} state={state} />
      </Box>
      {showChatBox && (
        <Box zIndex={2} paddingTop="space16" paddingX="space8">
          {chatBox}
        </Box>
      )}
      <ScrollBox
        scrollDirection="y"
        scrollbarGutter="stable"
        paddingTop={showChatBox ? 'space8' : 'space16'}
        paddingX="space8"
        paddingBottom="space8"
      >
        <TabPanel state={state} />
      </ScrollBox>
    </Grid>
  );
};

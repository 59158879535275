import { Flex, Tabs } from '@candisio/design-system';
import { useTabs } from 'components/Tabs/useTabs';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useThreeWayMatchContext } from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/ThreeWayMatchContext';
import { matchStates } from '../consts';

export type ThreeWayMatchModalTab = 'quantity' | 'amount';

const { success, warning } = matchStates;
interface ThreeWayMatchTableWrapperProps {
  children: ReactNode;
  activeTab: ThreeWayMatchModalTab;
  onTabChange: (threeWayMatchModalTabs: ThreeWayMatchModalTab) => void;
}

export function ThreeWayMatchTableWrapper({
  children,
  activeTab,
  onTabChange,
}: ThreeWayMatchTableWrapperProps) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { newData } = useThreeWayMatchContext();
  const isAnyQuantityMisMatch = newData.some(row => !row.isQuantityMatched);
  const isAnyAmountMisMatch = newData.some(row => !row.isAmountMatched);
  const { tabsProps } = useTabs({
    selectedKey: activeTab,
    items: [
      {
        title: t('threeWayMatch.tabs.quantity'),
        key: 'quantity',
        icon: isAnyQuantityMisMatch ? warning.icon : success.icon,
        iconColor: isAnyQuantityMisMatch ? warning.color : success.color,
      },
      {
        title: t('threeWayMatch.tabs.amount'),
        key: 'amount',
        icon: isAnyAmountMisMatch ? warning.icon : success.icon,
        iconColor: isAnyAmountMisMatch ? warning.color : success.color,
      },
    ],
    onSelectionChange: key => onTabChange(key as ThreeWayMatchModalTab),
  });

  return (
    <Flex height="100%" borderRadius="basic" direction="column">
      <Flex paddingBottom="space2" paddingLeft="space2">
        <Tabs {...tabsProps} style={{ mixBlendMode: 'multiply' }} />
      </Flex>
      <Flex flex={1} direction="column">
        {children}
      </Flex>
    </Flex>
  );
}

import { Grid, Skeleton } from '@candisio/design-system';
import { ProvisionsPromotionCoreDataPaymentConditionTable } from 'components/ProductPromotions/Provisions/ProvisionsPromotionCoreDataPaymentCondidtionTable';
import { dummyPaymentConditionsData } from 'components/ProductPromotions/Provisions/tableData';
import { useDatev } from 'orgConfig/datev';
import { useEffect } from 'react';
import { faker } from 'utils/faker';
import { useExportContext } from 'views/Integrations/Export/Context';
import { useExportPaymentConditionsFactory } from 'views/Integrations/Export/toolkit/hooks/useExportPaymentConditionsFactory';
import { ExportPaymentConditionsTable } from './PaymentConditionsTable';

const WARNING_TRESHOLD = 100;

export const PaymentConditionsContainer = () => {
  const { view, includeAllPaymentConditions } = useExportContext();
  const {
    isMasked,
    isAllShown,
    paymentConditions: { tableData, refetch, isLoading },
    count: { all, newAndUpdated },
  } = useExportPaymentConditionsFactory();

  const { bdsBought } = useDatev();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void refetch();
  }, [refetch, view]);

  const hasWarning = includeAllPaymentConditions
    ? all > WARNING_TRESHOLD
    : newAndUpdated > WARNING_TRESHOLD;

  if (isLoading) {
    return (
      <Grid gap="space10">
        {Array.from({ length: 15 }, () => (
          <Skeleton width="100%" height="space40" key={faker.string()} />
        ))}
      </Grid>
    );
  }

  if (!bdsBought)
    return (
      <ProvisionsPromotionCoreDataPaymentConditionTable
        data={tableData.length > 0 ? tableData : dummyPaymentConditionsData}
      />
    );

  return (
    <ExportPaymentConditionsTable
      data={tableData}
      hasWarning={hasWarning}
      isMasked={isMasked}
      showAll={isAllShown}
    />
  );
};

import { DocumentType } from 'generated-types/graphql.types';
import { useFetchDocNavigationData } from 'hooks/useFetchDocNavigationData/useFetchDocNavigationData';
import { AppRouteParams, DocumentProcessingRouteParams, Routes } from 'models';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  generatePath,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { RouteParams } from '../Context';
import { usePrefetchPdfData } from '../DocumentProcessing/util/usePrefetchPdfData';
import { usePrefetchQueries } from '../DocumentProcessing/util/usePrefetchQueries';
import { useGetPath } from 'hooks/usePath';

enum SearchParams {
  cursor = 'cursor',
  isArchived = 'isArchived',
}

const DOCUMENT_VIEW_PATH =
  `/:${AppRouteParams.organizationSlug}${Routes.INBOX}/:${DocumentProcessingRouteParams.documentId}` as const;

export const useGetOpenDocument = () => {
  const getPath = useGetPath();

  const navigate = useNavigate();
  const getOpenDocumentLink = useCallback(
    (
      documentId: string,
      type: DocumentType,
      appendSearchParams: boolean = false,
      additionalSearchParams: Record<string, string> = {}
    ) => {
      const search = appendSearchParams ? window.location.search : '';
      const searchParams = new URLSearchParams(search);

      // We need to set `isInvoice` search param to properly handle
      // invoice view in "All documents" view ("/stored_documents" route)
      if (type === DocumentType.Invoice) {
        searchParams.set('isInvoice', 'true');
      } else {
        searchParams.delete('isInvoice');
      }

      for (const [key, value] of Object.entries(additionalSearchParams)) {
        searchParams.set(key, value);
      }

      const path = getPath({
        pathname: '/archive/stored_documents/:documentId',
        params: {
          documentId,
        },
        search: searchParams.toString(),
      });

      return path;
    },
    [getPath]
  );

  return {
    getOpenDocumentLink,
    navigate,
  };
};

export const useDocumentNavigation = () => {
  const [t] = useTranslation();

  const {
    documentId,
    organizationSlug,
    type: routeType = Routes.ARCHIVE,
  } = useParams<RouteParams>();

  const LIST_VIEW_PATH =
    `/:${AppRouteParams.organizationSlug}${routeType}` as const;

  const originListViewPathname = generatePath(LIST_VIEW_PATH, {
    organizationSlug,
  });

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const currentDocumentCursor = searchParams.get(SearchParams.cursor);
  const isArchived = !!searchParams.get(SearchParams.isArchived);

  // TODO: this value is not always correct since isArchived parameter stays after cancel edit
  const [editRequestedDocumentMode, setEditRequestedDocumentMode] =
    useState(isArchived);

  const [isProcessedFormInEditMode, setIsProcessedFormInEditMode] =
    useState(false);

  let route: Routes = routeType;
  if (editRequestedDocumentMode && isArchived) route = Routes.ARCHIVE;
  if (editRequestedDocumentMode && !isArchived) route = Routes.APPROVALS;

  const {
    navigationLoading: isNavigationLoading,
    documentListCount,
    nextDocumentId,
    nextDocumentLink,
    prevDocumentId,
    prevDocumentLink,
    linkBackToList,
  } = useFetchDocNavigationData({
    cursor: currentDocumentCursor,
    organizationSlug,
    route,
  });

  usePrefetchQueries({ nextDocumentId, prevDocumentId });
  usePrefetchPdfData({ nextDocumentId, prevDocumentId });

  const handleBackToList = useCallback(() => {
    navigate(linkBackToList);
  }, [navigate, linkBackToList]);

  const gotoDocumentListView = useCallback(() => {
    navigate({
      pathname: originListViewPathname,
      search: searchParams.toString(),
    });
  }, [navigate, originListViewPathname, searchParams]);

  const gotoNextDocument = useMemo(() => {
    if (!nextDocumentLink) return undefined;

    return () => navigate(nextDocumentLink);
  }, [navigate, nextDocumentLink]);

  const gotoPrevDocument = useMemo(() => {
    if (!prevDocumentLink) return undefined;

    return () => navigate(prevDocumentLink);
  }, [navigate, prevDocumentLink]);

  const cycleDocument = useCallback(() => {
    if (gotoNextDocument) {
      gotoNextDocument();

      return;
    }

    if (gotoPrevDocument) {
      gotoPrevDocument();

      return;
    }

    gotoDocumentListView();
  }, [gotoNextDocument, gotoPrevDocument, gotoDocumentListView]);

  const enableEditMode = useCallback(() => {
    const pathname = generatePath(DOCUMENT_VIEW_PATH, {
      organizationSlug,
      documentId,
    });

    navigate({ pathname, search: searchParams.toString() });

    setEditRequestedDocumentMode(true);
    setIsProcessedFormInEditMode(true);
  }, [documentId, navigate, organizationSlug, searchParams]);

  const disableEditMode = useCallback(() => {
    setEditRequestedDocumentMode(false);
    setIsProcessedFormInEditMode(false);
  }, []);

  const listNavigatorTitle: string = isNavigationLoading
    ? t('loading')
    : t('document.details.listNavigatorTitle', { count: documentListCount });

  const result = useMemo(
    () => ({
      isNavigationLoading,
      listNavigatorTitle,
      editRequestedDocumentMode,
      isProcessedFormInEditMode,
      gotoDocumentListView,
      gotoNextDocument,
      gotoPrevDocument,
      cycleDocument,
      handleBackToList,
      enableEditMode,
      disableEditMode,
    }),
    [
      isNavigationLoading,
      listNavigatorTitle,
      editRequestedDocumentMode,
      isProcessedFormInEditMode,
      gotoDocumentListView,
      gotoNextDocument,
      gotoPrevDocument,
      cycleDocument,
      handleBackToList,
      enableEditMode,
      disableEditMode,
    ]
  );

  return result;
};

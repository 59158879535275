import { CellProps } from 'react-table';
import { WithHighlights } from './types';

export const getCellHighlights = <T extends WithHighlights>(
  cell: CellProps<T>
) => {
  const { row, column } = cell;
  const highlights = row.original?.highlights;
  const columnId = column.id;
  const columnHighlights = highlights?.[columnId] ?? undefined;

  return columnHighlights;
};

import {
  FilterOption,
  PaginationWithSearchFilterHook,
  fetchPolicy,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  IbanInfiniteScrollPaginationQuery,
  IbanInfiniteScrollPaginationQueryVariables,
  useContactsPaginationQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';
import { ibanInfiniteScrollPaginationQuery } from '../utils/queries';

export const useIbanFilter: PaginationWithSearchFilterHook = ({
  filteredValues,
  searchStr,
}) => {
  const computeVariables = useCallback(
    (inputString?: string): IbanInfiniteScrollPaginationQueryVariables => ({
      queries: {
        iban: inputString,
      },
    }),
    []
  );

  const {
    data: allContacts,
    loading: allContactsLoading,
    loadMore,
    handleDebounceSearch,
  } = useDebouncedSearchPagination<
    IbanInfiniteScrollPaginationQuery,
    IbanInfiniteScrollPaginationQueryVariables
  >({
    query: ibanInfiniteScrollPaginationQuery,
    queryRootKey: 'ibanPagination',
    computeVariables,
  });

  const { data: selectedContacts } = useContactsPaginationQuery({
    variables: {
      input: { page: 1, limit: filteredValues.length },
      filters: { iban: filteredValues },
    },
    fetchPolicy,
    skip: filteredValues.length === 0 || Boolean(searchStr),
  });

  const byIds = selectedContacts?.contactsPagination.records ?? [];
  const byAccounNumber = allContacts?.ibanPagination.records ?? [];

  const filterOptions: FilterOption[] = [...byIds, ...byAccounNumber]
    .filter(contact => Boolean(contact.iban))
    .map(contact => ({
      label: contact.iban?.value ?? '',
      id: contact.iban?.value ?? '',
    }));

  return {
    filterOptions: uniqBy(filterOptions, 'id'),
    handleDebounceSearch,
    loading: allContactsLoading,
    loadMore,
  };
};

import type {
  WithHighlightsCellProps,
  WithHighlights as WithHighlightsType,
} from './types';
import { getCellHighlights } from './getCellHighlights';
import { HighlightedTruncatedText } from '@candisio/design-system';

export const WithHighlightsCell = <T extends WithHighlightsType>({
  cellProps,
  FallbackCell,
}: WithHighlightsCellProps<T>) => {
  const highlights = getCellHighlights(cellProps);
  if (!highlights) {
    return <FallbackCell {...cellProps} />;
  }
  return (
    <HighlightedTruncatedText highlight={highlights} className="max-w-[50ch]">
      {String(cellProps.value)}
    </HighlightedTruncatedText>
  );
};

import { Grid } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { generateBookingsArrayFieldNames } from '../utils/generateSplitBookingsFormFieldNames';
import { PaginatedComboBoxField } from './Fields/PaginatedComboBoxField';
import { useFormFieldOptions } from './hooks/useFormFieldOptions';

interface BasicSplitBookingsFormProps {
  hasOneBooking?: boolean;
  bookingId: string;
  entryIndex: number;
  bookingIndex: number;
}

export const BasicSplitBookingsForm = ({
  hasOneBooking = false,
  bookingId,
  entryIndex,
  bookingIndex,
}: BasicSplitBookingsFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { costCenter, costObject, generalLedgerAccount } =
    useFormFieldOptions();

  const fields = generateBookingsArrayFieldNames(entryIndex, bookingIndex);

  return (
    <Grid
      id={bookingId}
      gap="space12"
      width="100%"
      background="gray50"
      padding="space16"
      borderRadius={hasOneBooking ? 'medium' : 'none'}
    >
      {generalLedgerAccount.isVisible && (
        <PaginatedComboBoxField
          name={fields.generalLedgerAccount}
          label={t('splitBookings.generalLedgerAccount.label')}
          {...generalLedgerAccount.options}
        />
      )}

      {costCenter.isVisible && (
        <PaginatedComboBoxField
          name={fields.costCenter}
          label={t('splitBookings.costCenter.label')}
          {...costCenter.options}
        />
      )}
      {costObject.isVisible && (
        <PaginatedComboBoxField
          name={fields.costObject}
          label={t('splitBookings.costObject.label')}
          {...costObject.options}
        />
      )}
    </Grid>
  );
};

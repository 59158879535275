import {
  Absence,
  CardIssuerTransactionDateFilterInput,
  InvoiceAssociationStatus,
  useGetTransactionsInsightsQuery,
} from 'generated-types/graphql.types';
import { DateRange } from '../CreditCardsInsights/MissingInvoicesWidget/utils';

export interface CardHolderCCInsightsWidgetData {
  dateRange?: DateRange;
  cardholder: {
    id: string;
    firstName?: string;
    membershipId?: string;
    membershipEmail?: string;
    lastName?: string;
    avatarUrl?: string;
    absence?: Absence;
    cardsWithMissingInvoices?: Array<{
      refNum?: string;
      invoicesWithoutTransaction: number;
      label?: string;
    }>;
  };
  invoicesWithoutTransaction: number;
}

export const useTransactionsInsights = ({
  dateRangeFilters,
}: {
  dateRangeFilters?: CardIssuerTransactionDateFilterInput;
}) => {
  return useGetTransactionsInsightsQuery({
    variables: {
      dateRangeFilters,
      filters: {
        invoiceAssociationStatus: [InvoiceAssociationStatus.Missing],
      },
    },
    fetchPolicy: 'cache-and-network',
  });
};

import {
  BdsPreRequisiteStep,
  useDatevBdsPreRequisitesQuery,
  useUpdateDatevBdsPreRequisitesMutation,
} from 'generated-types/graphql.types';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { getDatevBDSPreRequisites } from 'views/Settings/Integrations/DATEV/gql';

const BDS_SETUP_WIZARD_DISMISSED_LS_KEY = 'BDS_SETUP_WIZARD_DISMISSED';

export const useDatevBdsSetupSteps = () => {
  const { data } = useDatevBdsPreRequisitesQuery();
  const [updateDatevBDSPreRequisites] =
    useUpdateDatevBdsPreRequisitesMutation();

  const datevBDSPreRequisites = data?.datevBDSPreRequisites;
  const orgId = useOrganizationId();

  const dismissedLsKey = `${BDS_SETUP_WIZARD_DISMISSED_LS_KEY}-${orgId}`;

  const [wizardDismissed, setWizardDismissed] = useLocalStorage<boolean>(
    dismissedLsKey,
    false
  );

  const completeStep = async (
    step: BdsPreRequisiteStep,
    completed: boolean
  ) => {
    const threshold = completed ? 1 : -1;
    const allStepsCompleted =
      datevBDSPreRequisites?.length === completedStepsCount + threshold;

    await updateDatevBDSPreRequisites({
      variables: { step, completed },
      refetchQueries: [{ query: getDatevBDSPreRequisites }],
    });

    if (allStepsCompleted) {
      setTimeout(() => {
        setWizardDismissed(true);
      }, 500);
    }
  };

  const completedStepsCount =
    datevBDSPreRequisites?.filter(p => p.completed).length || 0;

  return {
    datevBDSPreRequisites,
    completedStepsCount,
    completeStep,
    wizardDismissed,
    setWizardDismissed,
  };
};

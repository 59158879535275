import { ScrollBox, defaultTheme } from '@candisio/design-system';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { CSSProp, css } from 'styled-components';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

export const PdfStyledWrapper = styled(ScrollBox)<{ pageCss?: CSSProp }>`
  .react-pdf__Page {
    background: transparent !important;
    display: grid;
    place-content: center;
    place-items: center;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto;
  }

  .react-pdf__Page > * {
    grid-column: 2;

    &:not(span),
    .endOfContent {
      cursor: grab;
    }
  }

  .react-pdf__Page__canvas {
    border-radius: ${defaultTheme.radii.medium};
    ${({ pageCss }) =>
      css`
        ${pageCss}
      `}
  }
`;

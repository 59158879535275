import {
  Box,
  Grid,
  InlineSkeleton,
  Link,
  Separator,
  mergeProps,
} from '@candisio/design-system';
import { EXTRACTED_CONTACT_ID } from 'components/Form/ProcessingForm/processingFormSchema';
import { HookFormPaginatedComboBoxField } from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { useSap } from 'orgConfig/sap';
import { Key, ReactElement, ReactNode } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddContactActionBox } from '../AddActionBox/AddContactActionBox';
import { StorageFormValues } from './storageFormSchema';
import { useStorageFormField } from './useStorageFormField';

export interface StorageFormContactFieldItem {
  key: string;
  children?: string;
  createTransfer?: boolean;
  iban?: string;
  taxNumber?: string;
  vatId?: string;
}

export interface StorageFormExtractedContactFieldItem {
  children?: string;
  createTransfer?: boolean;
  swiftCode?: string;
  bankAccountNumber?: string;
  iban?: string;
  taxNumber?: string;
  vatId?: string;
  email?: string;
  phoneNumber?: string;
  street?: string;
  city?: string;
  postOfficeBox?: string;
  postalCode?: string;
  countryISOCode?: string;
  customerNumber?: string;
}

export interface StorageFormContactFieldProps {
  /** Add contact button label */
  addContactLabel?: string;
  /** Field name */
  name: 'contact';
  /** Initial value for combo box input */
  defaultInputValue?: string;
  /** Edit button label */
  editLabel?: string;
  /** Loading state is passed to the field containers surrounding the input
   * fields to display their skeletons while the form data is loading
   * */
  isLoading?: boolean;
  /** Combo box items */
  items?: StorageFormContactFieldItem[];
  /** New contact card description */
  newContactDescription?: string;
  /** New contact card title */
  newContactTitle?: string;
  /** Field label */
  label: string;
  /** Called when add contact drawer should be shown */
  onAddContact?: () => void;
  /** Called when field value changes */
  onChange?: (newValue: Key | null) => void;
  /** Called when edit contact drawer should be shown */
  onEditContact?: () => void;
  /** Called when user searches for an item */
  onSearch?: (inputValue: string) => Promise<void>;
  /** Placeholder shown when no value is set */
  placeholder?: string;
  /** Custom render function for combo box dropdown */
  renderCustomDropdown?: (listbox: ReactElement) => ReactNode;
  /** Display input as read only */
  readOnly?: boolean;
}

/** Storage form contact field */
export const StorageFormContactField = ({
  addContactLabel,
  editLabel,
  isLoading,
  items,
  name,
  newContactDescription,
  newContactTitle,
  onAddContact,
  onEditContact,
  readOnly: readOnlyProp,
  ...restProps
}: StorageFormContactFieldProps) => {
  const [t] = useTranslation();
  const sap = useSap();
  const inputValue = useWatch<StorageFormValues, 'contact.inputValue'>({
    name: `${name}.inputValue`,
  });

  const {
    fieldProps: { readOnly: formFieldReadOnly, ...fieldProps },
  } = useStorageFormField(`${name}.value`);

  const readOnly = readOnlyProp || formFieldReadOnly;

  const isNew =
    inputValue.length > 0 && !items?.some(item => item.children === inputValue);

  const showNewContactActions = isNew && !readOnly;
  const showEditContactButton =
    !readOnly &&
    typeof inputValue === 'string' &&
    inputValue !== EXTRACTED_CONTACT_ID;

  const newContactActions = (
    <AddContactActionBox
      onClick={onAddContact}
      newContactTitle={newContactTitle}
      newContactDescription={newContactDescription}
      sapIsActive={sap.isActive}
      addContactLabel={addContactLabel}
    />
  );

  return (
    <Grid gap="space16">
      <HookFormPaginatedComboBoxField
        name={name}
        readOnly={readOnly}
        allowsCustomValue={true}
        allowsEmptyCollection={true}
        emptyListPlaceholder={newContactActions}
        items={items}
        renderCustomDropdown={listbox => (
          <Grid templateRows="1fr auto" maxHeight="inherit" overflow="hidden">
            <Box overflow="hidden">{listbox}</Box>
            {showNewContactActions && (
              <>
                <Separator />
                {newContactActions}
              </>
            )}
          </Grid>
        )}
        {...mergeProps(fieldProps, restProps)}
      />
      {showNewContactActions && newContactActions}
      {showEditContactButton && (
        <Link
          as="button"
          fontSize="small"
          lineHeight="1"
          onClick={onEditContact}
          position="absolute"
          right="space8"
          top="0"
          // @TODO DS: make lineHeight prop work for <Link as="button">
          style={{ lineHeight: '1' }}
        >
          <InlineSkeleton isLoading={isLoading}>
            {editLabel ?? t('common.edit')}
          </InlineSkeleton>
        </Link>
      )}
    </Grid>
  );
};

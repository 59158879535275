import { ibanSchema } from 'utils/zodFormValidation';
import { swiftCodeSchema } from 'utils/zodFormValidation/Schemas/swiftCodeSchema';
import { z } from 'zod';

export const bankAccountFormSchema = z.object({
  name: z.string(),
  iban: ibanSchema,
  swiftCode: swiftCodeSchema.optional().nullable(),
});
export type BankAccountFormValues = z.infer<typeof bankAccountFormSchema>;

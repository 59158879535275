import { Drawer } from '@candisio/design-system';
import { TargetMemberContainer } from './TargetMemberContainer';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';

interface ReimbursementTeamMemberDrawerProps {
  targetMembershipId: string | undefined;
}

export const ReimbursementTeamMemberDrawer = ({
  targetMembershipId,
}: ReimbursementTeamMemberDrawerProps) => {
  const { searchParams, setSearchParams } = useMutateSearchParams();

  const handleCloseTeamMemberDrawer = () => {
    searchParams.delete('teamMemberDrawer');
    setSearchParams(searchParams);
  };

  const isTeamMemberDrawerOpen = searchParams.get('teamMemberDrawer');

  return (
    <Drawer
      onClose={handleCloseTeamMemberDrawer}
      isOpen={!!isTeamMemberDrawerOpen}
    >
      <TargetMemberContainer
        targetMembershipId={targetMembershipId}
        onCloseDrawer={handleCloseTeamMemberDrawer}
        isOpen={!!isTeamMemberDrawerOpen}
      />
    </Drawer>
  );
};

import { Text } from '@candisio/design-system';
import { TruncatedText } from '@candisio/design-system/next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { EcmDocumentsTableData } from '../../types';
import { CellContext } from '@tanstack/react-table';

type Value = string | undefined;

/** Uses `TruncatedText` and sets `charsAfterEllipsis={4}` */
export const DocumentNameCell = ({
  getValue,
}: CellContext<EcmDocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const value = getValue();

  if (!value) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  return <TruncatedText charsAfterEllipsis={4}>{value}</TruncatedText>;
};

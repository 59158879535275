import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardRequestCountData } from '../hooks/useCreditCardRequestCountData';

export const useCreditCardsOverviewCount = () => {
  const { isCardManager } = useUserRoles();

  const { loading, totalRequests } = useCreditCardRequestCountData();
  const isCountVisible = !loading && Boolean(totalRequests) && isCardManager;

  return { isCountVisible, totalRequests };
};

import moment from 'moment';

interface DateTimeDisplayProps {
  dateTimeString: string;
  timezone?: string;
}

export const formatDateTimeWithPeriods = (dateTime: string): string => {
  return moment(dateTime).format('DD. MMM., HH:mm');
};

export const DateTimeDisplay = ({
  dateTimeString,
  timezone = 'CET',
}: DateTimeDisplayProps) => {
  const formattedDateTime = formatDateTimeWithPeriods(dateTimeString);

  return (
    <span className="text-sm font-medium whitespace-nowrap">
      {formattedDateTime} <span className="text-gray-500">{timezone}</span>
    </span>
  );
};

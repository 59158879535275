import { MultiSelectorButton } from './MultiSelectorButton';
import { ButtonGroup } from '../ButtonGroup';
import { ReactNode } from 'react';
import {
  GetMultiSelectorStatePerDiemParams,
  SelectAllColumnPerDiemParams,
} from 'views/Reimbursement/hooks/types';

interface PerDiemMealsMultiSelectorButtonsProps {
  buttons: Array<{ id: string; displayValue: ReactNode }>;
  onSelectAll: (params: SelectAllColumnPerDiemParams) => void;
  onGetMultiSelectorState: (
    params: GetMultiSelectorStatePerDiemParams
  ) => 'allSelected' | 'someSelected' | 'noneSelected';
}

export const PerDiemMealsMultiSelectorButtons = ({
  onSelectAll,
  onGetMultiSelectorState,
  buttons,
}: PerDiemMealsMultiSelectorButtonsProps) => {
  return (
    <ButtonGroup className="col-start-2">
      {buttons.map((button, index) => {
        return (
          <MultiSelectorButton
            {...button}
            key={index}
            onChange={onSelectAll}
            onGetMultiSelectorState={onGetMultiSelectorState}
          />
        );
      })}
    </ButtonGroup>
  );
};

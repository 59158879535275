import { useReimbursementItemsReadyForExportQuery } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { ExportReimbursementItemsTableRow } from '../../elements/table/reimbursementItems/types';
import {
  availableFiltersAndSorts,
  mapToSortInput,
} from '../../elements/table/reimbursementItems/utils';

const LIMIT = 1000;

export const useReimbursementItemsReadyForExport = () => {
  const { canUseReimbursement } = useReimbursement();

  const { sortBy } = useUrlBasedSortAndFilter<ExportReimbursementItemsTableRow>(
    {
      availableFilters: availableFiltersAndSorts,
    }
  );

  const { data, loading, refetch } = useReimbursementItemsReadyForExportQuery({
    variables: { limit: LIMIT, sort: mapToSortInput(sortBy) },
    skip: !canUseReimbursement,
  });

  const list = (data?.reimbursementItemsReadyForExport.node ?? []).map(
    item => ({
      ...item,
    })
  );

  return { data: list, loading, refetch };
};

import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { compiledRoutes, Routes } from 'models';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { Route } from 'router/SentryRouting';
import { CardholderOverview } from 'views/CreditCards/CardholderOverview/CardholderOverview';
import { CreditCardsTableView } from 'views/CreditCards/CardManagerView/CreditCardsTableView';
import {
  CreditCardCardOverviewLayout,
  CreditCardDashboardLayout,
} from 'views/CreditCards/CreditCardDashboardLayout';
import { CreditCardsInsightContainer } from 'views/CreditCards/CreditCardsInsights/CreditCardsInsightContainer';
import { CreditCardsIssueNewCardModal } from 'views/CreditCards/CreditCardsInsights/CreditCardsIssueNewCardModal';
import { OrganizationSettingsDrawer } from 'views/CreditCards/CreditCardsInsights/OrganizationSettingsDrawer/OrganizationSettingsDrawer';
import { RecurringPaymentsWidget } from 'views/CreditCards/CreditCardsInsights/RecurringPayments/RecurringPaymentsWidget';
import { CreditCardsStatements } from 'views/CreditCards/CreditCardsStatements/CreditCardsStatements';
import { CreditCardsTab } from 'views/CreditCards/CreditCardsTabs/CreditCardsTabs';
import { useCreditCardsAccess } from 'views/CreditCards/CreditCardsTabs/useCreditCardsAccess';

export const CreditCardRoutes = ({ path }: { path: string }) => {
  const {
    showInsightsTab,
    showCardHolderOverviewTab,
    showCardManagerCardOverviewTab,
    showStatementTab,
    showRecurringPaymentsTab,
  } = useCreditCardsAccess();

  const dashboardRedesignCreditCardsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.dashboardRedesignCreditCards
  );

  return (
    <>
      {/* Insights Tab */}
      {showInsightsTab && (
        <Route
          exact
          path={[
            `${path}${Routes.CREDIT_CARDS_DASHBOARD}`,
            `${path}${compiledRoutes.insightsCardsDrawerRoute}`,
            `${path}${compiledRoutes.recurringPaymentsModalRoute}`,
          ]}
          render={routeProps => (
            <CreditCardDashboardLayout
              selectedTab={CreditCardsTab.Dashboard}
              {...routeProps}
            >
              <CreditCardsInsightContainer />
            </CreditCardDashboardLayout>
          )}
        />
      )}

      {/* Card Overview Tab for Card Manager */}
      {showCardManagerCardOverviewTab && (
        <Route
          exact
          path={`${path}${compiledRoutes.creditCardsManagerOverviewRoute}`}
          render={routeProps => (
            <CreditCardDashboardLayout
              selectedTab={CreditCardsTab.Table}
              {...routeProps}
            >
              <CreditCardsTableView />
            </CreditCardDashboardLayout>
          )}
        />
      )}

      {/* Card Overview Tab for Card Holder */}
      {showCardHolderOverviewTab && (
        <Route
          exact
          path={`${path}${compiledRoutes.creditCardsCardholderOverviewRoute}`}
          render={routeProps => (
            <CreditCardCardOverviewLayout {...routeProps}>
              <CardholderOverview />
            </CreditCardCardOverviewLayout>
          )}
        />
      )}

      {/* Statement Tab */}
      {showStatementTab && (
        <Route
          exact
          path={`${path}${compiledRoutes.creditCardsStatements}`}
          render={routeProps => (
            <CreditCardDashboardLayout
              selectedTab={CreditCardsTab.Statements}
              {...routeProps}
            >
              <CreditCardsStatements />
            </CreditCardDashboardLayout>
          )}
        />
      )}

      {/* Recurring Payment Tab */}
      {dashboardRedesignCreditCardsFF && showRecurringPaymentsTab && (
        <Route
          exact
          path={`${path}${compiledRoutes.recurringPaymentsRoute}`}
          render={routeProps => (
            <CreditCardDashboardLayout
              selectedTab={CreditCardsTab.RecurringPayments}
              {...routeProps}
            >
              <RecurringPaymentsWidget />
              <OrganizationSettingsDrawer />
              <CreditCardsIssueNewCardModal />
            </CreditCardDashboardLayout>
          )}
        />
      )}
    </>
  );
};

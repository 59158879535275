export const CREDIT_CARDS_UTM_SOURCE = {
  DASHBOARD: 'dashboard',
  SETTINGS: 'settings',
  ARCHIVE: 'archive-table',
  INBOX: 'inbox-table',
  EXPORT_CARD_SETTLEMENT: 'export-card-settlement-table',
  EXPORT_APPROVAL: 'export-approval-table',
};

export const PROVISIONS_UTM_SOURCE = {
  SETTINGS: 'settings',
  APPROVALS_BANNER: 'approvals-banner',
  EXPORT_PROVISIONS: 'export-provisions-table',
  EXPORT_REVERSALS: 'export-reversals-table',
  EXPORT_SIDEBAR: 'export-sidebar',
  PAYMENT_TABLE: 'payment-table',
  SETTINGS_DATEV_INTEGRATION: 'settings-datev',
  SETTINGS_DATEV_INTEGRATION_DUO: 'settings-datev-duo',
  PAYMENT_SETTINGS: 'payment-settings',
  CONTACTS: 'contacts',
  EXPORT_CORE_DATA_CONTACTS: 'export-coredata-contacts-table',
  EXPORT_CORE_DATA_PAYMENT_CONDITIONS:
    'export-coredata-paymentconditions-table',
};

export const CONTRACT_UTM_SOURCE = {
  ARCHIVE: 'archive-contracts',
  STORAGE_FORM: 'storage-form-contracts',
  INBOX: 'inbox-contracts',
  SETTINGS_CONTRACT_TYPES: 'settings-contract-types',
};

export const DOCUMENT_MANAGEMENT_UTM_SOURCE = {
  ARCHIVE: 'archive-documents',
  DOCUMENTS_TABLE: 'documents-table',
  DOCUMENTS_RELATIONS: 'document-relations',
  INBOX_SENSITIVE_DOCUMENTS: 'inbox-sensitive-documents',
  INBOX_DOCUMENT_PROCESSING: 'inbox-document-processing',
  SETTINGS_TAGS: 'settings-tags',
  SETTINGS_DOCUMENT_TYPES: 'settings-document-tags',
  STORAGE: 'storage-document_management',
};

export const UPSELL_PLUS_UTM_SOURCE = {
  ABSENCE_FORM: 'absence-form',
  ARCHIVE: 'archive',
  PAYMENT_SETTINGS_SEPA_XML: 'payment-settings-sepa-xml',
};

export const UPSELL_MAX_UTM_SOURCE = {
  SSO_ORGANIZATION: 'sso-my_company',
  SSO_TEAM_MEMBER: 'sso-team_member',
  API_SETTINGS: 'api-settings',
};

import { ApolloQueryResult } from '@apollo/client';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import {
  DocumentStatus,
  ExportableDocument,
  ExportDocumentEs,
  Query,
} from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import {
  documentsForExportQuery,
  getDocumentsForExportQuery,
} from 'views/Integrations/Export/toolkit/queries';
import { defaultExportFilters as availableFilters } from '../components/DocumentsTable';
import { mapExportTableFiltersToGqlFilterInput } from '../utils/mapExportTableFiltersToGqlFilterInput';
import {
  mapExportTableSortToGqlSortInput,
  mapExportTableSortToGqlSortInputDeprecated,
} from '../utils/mapExportTableSortToGqlSortInput';

type DocumentForExportQueryType = Pick<Query, 'documentsForExport'>;
type DocumentForExportQueryTypeNewEs = Pick<Query, 'exportDocumentsEs'>;

/**
 * @deprecated
 */
export interface ExportTableDataResultDeprecated {
  list: ExportableDocument[];
  totalCount: number;
  isLoadingMore: boolean;
  hasMoreData: boolean;
  onLoadMore: () => void;
  endCursor?: string;
  refetch: () => Promise<ApolloQueryResult<DocumentForExportQueryType>>;
}

export interface ExportTableDataResult {
  list: ExportDocumentEs[];
  totalCount: number;
  isLoadingMore: boolean;
  hasMoreData: boolean;
  onLoadMore: () => void;
  endCursor?: string;
  refetch: () => Promise<ApolloQueryResult<DocumentForExportQueryTypeNewEs>>;
}

const fallbackSort = {
  field: 'invoiceDate',
  order: 'DESC',
};

export const MAX_ROWS = 40;

/**
 * @deprecated
 */
export const useExportTableDataDeprecated = (
  skip: boolean
): ExportTableDataResultDeprecated => {
  const { filters, sortBy } = useUrlBasedSortAndFilter<DocumentsTableData>({
    availableFilters,
  });

  const gqlFilters = mapExportTableFiltersToGqlFilterInput(filters);
  const gqlSort = mapExportTableSortToGqlSortInputDeprecated(
    sortBy ?? fallbackSort
  );

  const queryOptions = {
    variables: {
      limit: MAX_ROWS,
      filters: {
        ...gqlFilters,
        status: [DocumentStatus.Approved],
      },
      sort: gqlSort,
    },
    skip,
  };

  const computeVariables = (endCursor: string) => ({
    limit: MAX_ROWS,
    after: endCursor,
    filters: {
      ...gqlFilters,
      status: [DocumentStatus.Approved],
    },
    sort: gqlSort,
  });

  const {
    data,
    loading: isLoadingMore,
    onLoadMore,
    refetch,
  } = usePagination<DocumentForExportQueryType>(
    documentsForExportQuery,
    'documentsForExport',
    queryOptions,
    {
      computeVariables,
    }
  );

  const { pageInfo, node, edges } = data?.documentsForExport ?? {};
  const edgesList = edges ?? [];
  const list = (node ?? []).map((itm, index) => ({
    ...itm,
    ...edgesList[index],
    __typename: 'ExportableDocument' as const,
  }));

  const totalCount = pageInfo?.totalCount ?? 0;

  const hasMoreData = !!pageInfo?.hasNextPage;

  return {
    refetch,
    hasMoreData,
    list,
    isLoadingMore,
    onLoadMore,
    totalCount,
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    endCursor: pageInfo?.endCursor!,
  };
};

export const useExportTableData = (skip: boolean): ExportTableDataResult => {
  const { filters, sortBy } = useUrlBasedSortAndFilter<DocumentsTableData>({
    availableFilters,
  });

  const gqlFilters = mapExportTableFiltersToGqlFilterInput(filters);
  const gqlSort = mapExportTableSortToGqlSortInput(sortBy ?? fallbackSort);

  const queryOptions = {
    variables: {
      limit: MAX_ROWS,
      filters: gqlFilters,
      sort: gqlSort,
    },
    skip,
  };

  const computeVariables = (endCursor: string) => ({
    limit: MAX_ROWS,
    after: endCursor,
    filters: gqlFilters,
    sort: gqlSort,
  });

  const {
    data,
    loading: isLoadingMore,
    onLoadMore,
    refetch,
  } = usePagination<DocumentForExportQueryTypeNewEs>(
    getDocumentsForExportQuery,
    'exportDocumentsEs',
    queryOptions,
    {
      computeVariables,
    }
  );

  const { pageInfo, edges } = data?.exportDocumentsEs ?? {};
  const edgesList = edges ?? [];
  const list = (edgesList ?? []).map((itm, index) => ({
    ...itm,
    ...edgesList[index].node,
    __typename: 'ExportDocumentEs' as const,
  }));

  const totalCount = pageInfo?.totalCount ?? 0;

  const hasMoreData = !!pageInfo?.hasNextPage;

  return {
    refetch,
    hasMoreData,
    list,
    isLoadingMore,
    onLoadMore,
    totalCount,
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    endCursor: pageInfo?.endCursor!,
  };
};

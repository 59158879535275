import {
  DateFilterOption,
  DocumentDateFilterInput,
  DocumentFilterInput,
  DocumentStatus,
  Maybe,
  Order,
  QuerySearchDocumentsArgs,
  SortInput,
} from 'generated-types/graphql.types';
import { getFilterFromUrl } from 'utils/table/getFilterFromUrl';

export enum DOCUMENT_QUERY_PROPS {
  approvers = 'approvers',
  accountingAreaIds = 'accountingArea',
  costCenterIds = 'costCenter',
  costObjectIds = 'costObject',
  extraCostInfoIds = 'extraCostInfo',
  artistSocialInsuranceCodes = 'artistSocialInsuranceCode',
  generalLedgerAccountIds = 'generalLedgerAccount',
  name = 'name',
  omit = 'omit',
  query = 'query',
  requester = 'requester',
  sort = 'sort',
  status = 'status',
  isPaid = 'isPaid',
  isPayable = 'isPayable',
  hasTransactionLinked = 'hasTransactionLinked',
  searchType = 'searchType',
  searchScope = 'searchScope',
  // date fields
  createdAt = 'createdAt',
  invoiceDate = 'invoiceDate',
  invoiceDeliveryDate = 'invoiceDeliveryDate',
  invoiceDueDate = 'invoiceDueDate',
  paidAt = 'paidAt',
}

export type DocumentTableQueryParams = {
  [DOCUMENT_QUERY_PROPS.approvers]?: string[];
  [DOCUMENT_QUERY_PROPS.accountingAreaIds]?: string[];
  [DOCUMENT_QUERY_PROPS.costCenterIds]?: string[];
  [DOCUMENT_QUERY_PROPS.costObjectIds]?: string[];
  [DOCUMENT_QUERY_PROPS.extraCostInfoIds]?: string[];
  [DOCUMENT_QUERY_PROPS.artistSocialInsuranceCodes]?: string[];
  [DOCUMENT_QUERY_PROPS.generalLedgerAccountIds]?: string[];
  [DOCUMENT_QUERY_PROPS.name]?: string[];
  [DOCUMENT_QUERY_PROPS.omit]?: string[];
  [DOCUMENT_QUERY_PROPS.query]?: string;
  [DOCUMENT_QUERY_PROPS.requester]?: string[];
  [DOCUMENT_QUERY_PROPS.sort]?: string;
  [DOCUMENT_QUERY_PROPS.status]?: string[];
  [DOCUMENT_QUERY_PROPS.isPaid]?: string[];
  [DOCUMENT_QUERY_PROPS.isPayable]?: string[];
  [DOCUMENT_QUERY_PROPS.hasTransactionLinked]?: string[];
  [DOCUMENT_QUERY_PROPS.searchType]?: string;
  [DOCUMENT_QUERY_PROPS.searchScope]?: string;
};

export type DocumentTableDateQueryParams = {
  [DOCUMENT_QUERY_PROPS.createdAt]?: string[];
  [DOCUMENT_QUERY_PROPS.invoiceDate]?: string[];
  [DOCUMENT_QUERY_PROPS.invoiceDeliveryDate]?: string[];
  [DOCUMENT_QUERY_PROPS.invoiceDueDate]?: string[];
  [DOCUMENT_QUERY_PROPS.paidAt]?: string[];
};

export const FILTERS = {
  IS_OVERDUE: 'isOverdue',
  IS_DUPLICATE: 'isDuplicate',
  IS_WAITING_FOR_CLARIFICATION: 'isWaitingForClarification',
};

export const availableDocumentFilters = [
  DOCUMENT_QUERY_PROPS.approvers,
  DOCUMENT_QUERY_PROPS.accountingAreaIds,
  DOCUMENT_QUERY_PROPS.costCenterIds,
  DOCUMENT_QUERY_PROPS.costObjectIds,
  DOCUMENT_QUERY_PROPS.extraCostInfoIds,
  DOCUMENT_QUERY_PROPS.artistSocialInsuranceCodes,
  DOCUMENT_QUERY_PROPS.generalLedgerAccountIds,
  DOCUMENT_QUERY_PROPS.name,
  DOCUMENT_QUERY_PROPS.omit,
  DOCUMENT_QUERY_PROPS.query,
  DOCUMENT_QUERY_PROPS.requester,
  DOCUMENT_QUERY_PROPS.sort,
  DOCUMENT_QUERY_PROPS.status,
  DOCUMENT_QUERY_PROPS.isPaid,
  DOCUMENT_QUERY_PROPS.isPayable,
  DOCUMENT_QUERY_PROPS.hasTransactionLinked,
];

export const availableDocumentDateFilters = [
  DOCUMENT_QUERY_PROPS.createdAt,
  DOCUMENT_QUERY_PROPS.invoiceDate,
  DOCUMENT_QUERY_PROPS.invoiceDeliveryDate,
  DOCUMENT_QUERY_PROPS.invoiceDueDate,
  DOCUMENT_QUERY_PROPS.paidAt,
];

export const getGqlSearchFromUrl: (
  search: string
) => Maybe<Pick<QuerySearchDocumentsArgs, 'query'>> = search => {
  const params = getFilterFromUrl<DocumentTableQueryParams>(
    search,
    availableDocumentFilters
  );

  if (!params.query) {
    return null;
  }

  return {
    query: params.query,
  };
};

export const getGqlSortFromUrl: (search: string) => Maybe<SortInput> =
  search => {
    const query = getFilterFromUrl<DocumentTableQueryParams>(
      search,
      availableDocumentFilters
    );

    const [field, order] = (query.sort || '').split(':');

    if (!field || !order) {
      return null;
    }

    const sorts: any = {
      ascend: 'ASC' as Order,
      descend: 'DESC' as Order,
    };

    return {
      field,
      order: sorts[order],
    };
  };

export const getGqlDateFilterFromUrl: (
  search: string
) => DocumentDateFilterInput = search => {
  const query = getFilterFromUrl<DocumentTableDateQueryParams>(
    search,
    availableDocumentDateFilters
  );

  const convertToGqlDateRange = (date: string | null) => {
    if (!date) {
      return null;
    }

    const [dateFrom, dateTo] = date?.split('-') || [];

    return {
      dateFrom,
      dateTo,
      filterOption: DateFilterOption.Custom,
    };
  };

  const dateRangeFilters = [
    'paidAt',
    'createdAt',
    'invoiceDeliveryDate',
    'invoiceDate',
    'invoiceDueDate',
  ].reduce((acc, dateField) => {
    // @ts-ignore
    const val = (query[dateField] && query[dateField][0]) || null;

    return { ...acc, [dateField]: convertToGqlDateRange(val) };
  }, {});

  return dateRangeFilters;
};

export const getDateFilterFromUrl = (search: string) => {
  const query = getFilterFromUrl<DocumentTableDateQueryParams>(
    search,
    availableDocumentDateFilters
  );

  const dateFilterValues = {
    paidAt: (query.paidAt && query.paidAt[0]) || null,
    createdAt: (query.createdAt && query.createdAt[0]) || null,
    invoiceDeliveryDate:
      (query.invoiceDeliveryDate && query.invoiceDeliveryDate[0]) || null,
    invoiceDate: (query.invoiceDate && query.invoiceDate[0]) || null,
    invoiceDueDate: (query.invoiceDueDate && query.invoiceDueDate[0]) || null,
  };

  return dateFilterValues;
};

export const getGqlFilterFromUrl: (search: string) => DocumentFilterInput =
  search => {
    const query = getFilterFromUrl<DocumentTableQueryParams>(
      search,
      availableDocumentFilters
    );

    const isOverdue = (query.status || []).find(s => s === FILTERS.IS_OVERDUE);
    const isDuplicate = (query.status || []).find(
      s => s === FILTERS.IS_DUPLICATE
    );

    const isWaitingForClarification = (query.status || []).find(
      s => s === FILTERS.IS_WAITING_FOR_CLARIFICATION
    );

    const docStatuses = (query.status || []).filter(
      s =>
        s !== FILTERS.IS_OVERDUE &&
        s !== FILTERS.IS_DUPLICATE &&
        s !== FILTERS.IS_WAITING_FOR_CLARIFICATION
    ) as DocumentStatus[];

    let isPayable: boolean | null = null;
    if (
      (query.isPayable || []).length === 2 ||
      (query.isPayable || []).length === 0
    ) {
      isPayable = null;
    } else if (query.isPayable?.[0] === 'true') {
      isPayable = true;
    } else if (query.isPayable?.[0] === 'false') {
      isPayable = false;
    }

    let isPaid: boolean | null = null;
    if (
      (query.isPaid || []).length === 2 ||
      (query.isPaid || []).length === 0
    ) {
      isPaid = null;
    } else if (query.isPaid?.[0] === 'true') {
      isPaid = true;
    } else if (query.isPaid?.[0] === 'false') {
      isPaid = false;
    }

    let isPaidWithCC: boolean | null = null;
    if (
      (query.hasTransactionLinked || []).length === 2 ||
      (query.hasTransactionLinked || []).length === 0
    ) {
      isPaidWithCC = null;
    } else if (query.hasTransactionLinked?.[0] === 'true') {
      isPaidWithCC = true;
    } else if (query.hasTransactionLinked?.[0] === 'false') {
      isPaidWithCC = false;
    }

    const filter = {
      status: docStatuses.length ? docStatuses : null,
      contactIds: query.name || null,
      requesterIds: query.requester || null,
      approverIds: query.approvers || null,
      accountingAreaIds: query.accountingArea || null,
      costCenterIds: query.costCenter || null,
      costObjectIds: query.costObject || null,
      extraCostInfoIds: query.extraCostInfo || null,
      artistSocialInsuranceCodes: query.artistSocialInsuranceCode || null,
      generalLedgerAccountIds: query.generalLedgerAccount || null,
      isOverdue: isOverdue === FILTERS.IS_OVERDUE ? true : null,
      isDuplicate: isDuplicate === FILTERS.IS_DUPLICATE ? true : null,
      isWaitingForClarification:
        isWaitingForClarification === FILTERS.IS_WAITING_FOR_CLARIFICATION
          ? true
          : null,
      isPayable,
      isPaid,
      hasTransactionLinked: isPaidWithCC,
    };

    return filter;
  };

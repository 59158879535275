import { ContactType } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { getFullName } from 'views/Contacts/ContactDetails/ContactForm/ContactTypeFields/utils';
import { z } from 'zod';
import {
  ContactNameSchemaOptions,
  checkContactInternalNameAvailableValidation,
} from '../contactNameSchema';

/** Zod schema to validate contact type -related fields */
export const contactTypeSchema = ({
  checkContactName,
}: ContactNameSchemaOptions = {}) =>
  z
    .object({
      contactType: z.union([z.nativeEnum(ContactType), z.literal('EMPLOYEE')]),
      companyName: z.string().optional().nullable(),
      individualFirstName: z.string().optional().nullable(),
      individualLastName: z.string().optional().nullable(),
      notSpecifiedName: z.string().optional().nullable(),
    })
    .superRefine(
      async (
        {
          contactType,
          companyName,
          individualLastName,
          notSpecifiedName,
          individualFirstName,
        },
        ctx
      ) => {
        switch (contactType) {
          case ContactType.Company:
            if (isNil(companyName)) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'null',
                path: ['companyName'],
              });
            }

            const companyNameAvailable =
              await checkContactInternalNameAvailableValidation({
                name: companyName,
                checkContactName,
              });

            if (!companyNameAvailable.isAvailable) {
              const translationKey = companyNameAvailable?.isEmployeeType
                ? 'settings.contacts.details.edit.errors.duplicateEmployeeType'
                : 'settings.contacts.details.edit.errors.duplicate';

              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['companyName'],
                params: {
                  translationKey,
                },
              });
            }

            break;
          case ContactType.Individual:
            if (isNil(individualLastName)) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'null',
                path: ['individualLastName'],
              });
            }

            const fullName = getFullName(
              individualFirstName,
              individualLastName
            );

            const fullNameAvailable =
              await checkContactInternalNameAvailableValidation({
                name: fullName,
                checkContactName,
              });

            if (!fullNameAvailable.isAvailable) {
              const translationKey = fullNameAvailable?.isEmployeeType
                ? 'settings.contacts.details.edit.errors.duplicateEmployeeType'
                : 'settings.contacts.details.edit.errors.duplicate';
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['individualLastName'],
                params: {
                  translationKey,
                },
              });
            }

            break;
          case ContactType.NotSpecified:
            if (isNil(notSpecifiedName)) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'null',
                path: ['notSpecifiedName'],
              });
            }

            const notSpecifiedNameAvailable =
              await checkContactInternalNameAvailableValidation({
                name: notSpecifiedName,
                checkContactName,
              });

            if (!notSpecifiedNameAvailable.isAvailable) {
              const translationKey = notSpecifiedNameAvailable?.isEmployeeType
                ? 'settings.contacts.details.edit.errors.duplicateEmployeeType'
                : 'settings.contacts.details.edit.errors.duplicate';
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['notSpecifiedName'],
                params: {
                  translationKey,
                },
              });
            }

            break;
        }
      }
    );

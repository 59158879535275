import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  PerDiemStep,
  useUpdatePerDiemItemMutation,
} from 'generated-types/graphql.types';
import { debounce } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { ReimbursementItemSchemaOutput } from '../toolkit/reimbursementItemsFormSchema';
import {
  perDiemItemDetailsQuery,
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsListQuery,
  reimbursementItemsSplitBookingsQuery,
} from '../toolkit/queries';
import { ReimbursementRouteParams } from './useReimbursementNavigation';
import { sanitizePerDiemFormInput } from '../utils/sanitizePerDiemFormInput';
import { sanitisePerDiemDays } from '../utils/sanitizePerDiemDays';
import { ReimbursementSplitBookingsFormValues } from '../toolkit/reimbursementSplitBookingsFormSchema';
import { sanitizeReimbursementBookingInput } from '../utils/sanitizeReimbursementBookingInput';

const UPDATE_EXPENSE_DELAY = 1000;

export const useUpdatePerDiemItem = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<ReimbursementRouteParams>();

  const { error } = useToastMessage();

  const [updatePerDiem, { loading: isUpdatePerDiemPending }] =
    useUpdatePerDiemItemMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: reimbursementCaseByIdQuery,
          variables: {
            id: reimbursementId,
          },
        },
        {
          query: reimbursementItemsListQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
        {
          query: reimbursementItemsSplitBookingsQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
        {
          query: reimbursementHistoryQuery,
          variables: { id: reimbursementId },
        },
        {
          query: perDiemItemDetailsQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
      ],
    });

  const handleUpdatePerDiem = useCallback(
    async (
      values: ReimbursementItemSchemaOutput,
      currentStep?: PerDiemStep
    ) => {
      if (!values.reimbursementItemId) return;

      const { errors, data } = await updatePerDiem({
        variables: {
          id: values.reimbursementItemId,
          input: {
            reimbursementCaseId: reimbursementId,
            ...sanitizePerDiemFormInput(values, currentStep),
          },
        },
      });

      if (errors?.length) {
        error(t('reimbursementView.createErr'));

        return;
      }

      return data?.updatePerDiemItem;
    },
    [error, reimbursementId, t, updatePerDiem]
  );

  const handleUpdatePerDiemDays = useCallback(
    async (values: ReimbursementItemSchemaOutput) => {
      if (!values.reimbursementItemId) return;

      const sanitizedDays = sanitisePerDiemDays(values);

      const { errors } = await updatePerDiem({
        variables: {
          id: values.reimbursementItemId,
          input: {
            reimbursementCaseId: reimbursementId,
            days: sanitizedDays?.length ? sanitizedDays : [],
          },
        },
      });

      if (errors?.length) {
        error(t('reimbursementView.createErr'));
      }
    },
    [error, reimbursementId, t, updatePerDiem]
  );

  const handleUpdatePerDiemBookings = useCallback(
    async (
      values: ReimbursementSplitBookingsFormValues['reimbursementItemBookings'][number]
    ) => {
      const reimbursementItemId = values?.reimbursementItemId;
      const bookings = values?.bookings;

      if (!reimbursementItemId) return;

      const { errors } = await updatePerDiem({
        variables: {
          id: reimbursementItemId,
          input: {
            reimbursementCaseId: reimbursementId,
            bookings: bookings?.length
              ? bookings.map(sanitizeReimbursementBookingInput)
              : [],
          },
        },
      });

      if (errors?.length) {
        error(t('splitBookings.splitSaveError'));

        return;
      }

      return { status: 'success' };
    },
    [error, reimbursementId, t, updatePerDiem]
  );

  const debouncedUpdate = useMemo(() => {
    return debounce(handleUpdatePerDiem, UPDATE_EXPENSE_DELAY);
  }, [handleUpdatePerDiem]);

  const debouncedUpdatePerDiemDays = useMemo(() => {
    return debounce(handleUpdatePerDiemDays, UPDATE_EXPENSE_DELAY);
  }, [handleUpdatePerDiemDays]);

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
      debouncedUpdatePerDiemDays.cancel();
    };
  }, [debouncedUpdate, debouncedUpdatePerDiemDays]);

  return {
    isUpdatePerDiemPending,
    updatePerDiem: debouncedUpdate,
    updatePerDiemNotDebounced: handleUpdatePerDiem,
    updatePerDiemDays: debouncedUpdatePerDiemDays,
    updatePerDiemDaysNotDebounced: handleUpdatePerDiemDays,
    handleUpdatePerDiemBookings,
  };
};

import { Box, Flex, Tabs } from '@candisio/design-system';
import { useTabs } from 'components/Tabs/useTabs';
import { useCallback, useState } from 'react';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { GoodsReceiptsList } from './GoodsReceiptList';
import { LinkableSearchField } from './LinkableSearchField';
import { LinkingFooterTarget } from './LinkingFooter';
import { PurchaseOrderList } from './PurchaseOrderList';
import { allowedMaximumItemForLinking, tableTabs } from './consts';
import { LinkingRestrictedToType } from './LinkingRestrictedToType';
import { useLinkedDocumentSource } from './hooks/useLinkedDocumentSource';
import { AssociationSource } from 'generated-types/graphql.types';

type LinkingTableProps = {
  documentId: string;
  documentAmount?: number | null;
  contactName?: string;
  accountsPayableNumber?: string | null;
  disabled?: boolean;
  closeModal: () => void;
};
export const LinkingTable = ({
  documentId,
  documentAmount,
  contactName,
  accountsPayableNumber,
  closeModal,
}: LinkingTableProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const [activeTab, setActiveTab] = useState(tableTabs.GoodsReceipts);
  const [searchValue, setSearchValue] = useState('');

  const {
    source: previouslyLinkedDocumentType,
    linkedGoodsReceipts,
    linkedPurchaseOrders,
  } = useLinkedDocumentSource(documentId);

  const handleResetSearch = useCallback(() => {
    setSearchValue('');
  }, []);

  const { tabsProps } = useTabs({
    items: [
      {
        key: tableTabs.GoodsReceipts,
        title: t('addPurchaseOrderModal.tabs.goodsReceipts'),
      },
      {
        key: tableTabs.PurchaseOrders,
        title: t('addPurchaseOrderModal.tabs.purchaseOrders'),
      },
    ],
    onSelectionChange: key => {
      setActiveTab(key as tableTabs);
      handleResetSearch();
    },
    selectedKey: activeTab,
  });

  const showCannotLinkGoodsReceipt =
    previouslyLinkedDocumentType === AssociationSource.PurchaseOrder &&
    activeTab === tableTabs.GoodsReceipts;

  const showCannotLinkPurchaseOrder =
    previouslyLinkedDocumentType === AssociationSource.GoodsReceipt &&
    activeTab === tableTabs.PurchaseOrders;

  const showCannotLinkDocumentTypeState =
    showCannotLinkGoodsReceipt || showCannotLinkPurchaseOrder;

  return (
    <Box height="100%" overflow="hidden">
      <Flex
        direction="column"
        height="100%"
        overflow="hidden"
        paddingTop="space16"
        paddingBottom="space32"
        paddingX="space32"
      >
        <Flex paddingBottom="space2" paddingLeft="space2">
          <Tabs {...tabsProps} />
        </Flex>
        {!showCannotLinkDocumentTypeState && (
          <Flex
            borderTopRadius="basic"
            justifyContent="space-between"
            borderBottom="1px solid gray200"
            background="white"
            padding="space12"
          >
            <LinkableSearchField
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              linkType={activeTab}
              contactName={contactName}
            />

            <SyncFromSap
              type={
                activeTab === 'goodsReceipts'
                  ? SAP_SYNC.GoodsReceipts
                  : SAP_SYNC.PurchaseOrdersWithFilters
              }
              accountsPayableNumber={accountsPayableNumber ?? undefined}
            />
          </Flex>
        )}
        {showCannotLinkDocumentTypeState ? (
          <LinkingRestrictedToType
            linkedDocumentType={
              showCannotLinkGoodsReceipt ? 'purchaseOrder' : 'goodsReceipt'
            }
          />
        ) : (
          <>
            {activeTab === tableTabs.GoodsReceipts ? (
              <GoodsReceiptsList
                accountsPayableNumber={accountsPayableNumber}
                searchValue={searchValue}
                onResetSearch={handleResetSearch}
                documentId={documentId}
                closeModal={closeModal}
                maxSelectableItems={
                  accountsPayableNumber ? allowedMaximumItemForLinking : 1
                }
                linkedGoodsReceipts={linkedGoodsReceipts}
              />
            ) : (
              <PurchaseOrderList
                documentId={documentId}
                documentAmount={documentAmount}
                closeModal={closeModal}
                accountsPayableNumber={accountsPayableNumber}
                searchValue={searchValue}
                onResetSearch={handleResetSearch}
                maxSelectableItems={
                  accountsPayableNumber ? allowedMaximumItemForLinking : 1
                }
                linkedPurchaseOrders={linkedPurchaseOrders}
              />
            )}
          </>
        )}
      </Flex>
      <LinkingFooterTarget />
    </Box>
  );
};

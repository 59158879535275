import { Skeleton, Text } from '@candisio/design-system';

const MAX_DISPLAY_COUNT = 999;

export const CountDisplay = ({
  count,
  countLoading,
}: { count?: number; countLoading?: boolean }) => {
  if (countLoading) return <Skeleton width="space16" height="space16" />;

  return count ? (
    <Text
      fontSize="small"
      fontWeight="bold"
      lineHeight="paragraph"
      data-cy="count"
    >
      {`${Math.min(count, MAX_DISPLAY_COUNT)}${
        count > MAX_DISPLAY_COUNT ? '+' : ''
      }`}
    </Text>
  ) : null;
};

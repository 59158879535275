import {
  ToggleButtonGroup as ToggleButtonGroupReactAria,
  Key,
  ToggleButtonGroupProps as ToggleButtonGroupReactAriaProps,
} from 'react-aria-components';
import styles from './ToggleButtonGroup.module.css';
import { ReactNode } from 'react';

export interface ToggleButtonGroupProps
  extends Omit<
    ToggleButtonGroupReactAriaProps,
    'selectedKeys' | 'onChange' | 'children'
  > {
  selectedKeys?: Array<Key>;
  onChange?: (value: Array<Key>) => void;
  children: ReactNode;
}

export const ToggleButtonGroup = ({
  children,
  selectedKeys,
  onChange: onChangeProp,
  selectionMode = 'single',
  disallowEmptySelection = true,
  ...restProps
}: ToggleButtonGroupProps) => {
  const handleOnChange = (keys: Set<Key>) => {
    const newSelectedKeys = Array.from(keys);
    onChangeProp?.(newSelectedKeys);
  };

  return (
    <div className={styles['toggle-button-wrapper']}>
      <ToggleButtonGroupReactAria
        className={styles['react-aria-ToggleButtonGroup']}
        onSelectionChange={handleOnChange}
        selectedKeys={selectedKeys}
        disallowEmptySelection={disallowEmptySelection}
        selectionMode={selectionMode}
        {...restProps}
      >
        {children}
      </ToggleButtonGroupReactAria>
    </div>
  );
};

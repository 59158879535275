import { CellProps, Text, TruncatedText } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { ExportReimbursementItemsTableRow } from './types';

const mapExportMethodToTranslationKey: Record<string, string> = {
  accounting_documents:
    'export.table.columns.exportMethod.methods.accounting_documents',
  bds: 'export.table.columns.exportMethod.methods.bds',
  no_method: 'export.table.columns.exportMethod.methods.no_method',
  rds: 'export.table.columns.exportMethod.methods.rds',
  rds_1: 'export.table.columns.exportMethod.methods.rds_1',
  rds_1_0: 'export.table.columns.exportMethod.methods.rds_1_0',
  rds_1_plural: 'export.table.columns.exportMethod.methods.rds_1_plural',
  rds_plural: 'export.table.columns.exportMethod.methods.rds_plural',
  tbd: 'export.table.columns.exportMethod.methods.tbd',
};

export const ExportMethodCell = ({
  value,
}: CellProps<ExportReimbursementItemsTableRow, string | null | undefined>) => {
  const [t] = useTranslation();
  if (!value) {
    return <Text> - </Text>;
  }

  return (
    <TruncatedText>{t(mapExportMethodToTranslationKey[value])}</TruncatedText>
  );
};

import { Item, SelectField } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { useExportContext } from 'views/Integrations/Export/Context';
import { ExportType } from 'views/Integrations/Export/types';

const exportTypeTranslationsMap: Record<ExportType, string> = {
  ADDISON_FORMAT: 'export.exportForm.selectExportType.types.addison_format',
  CANDIS_CSV: 'export.exportForm.selectExportType.types.candis_csv',
  DATEV: 'export.exportForm.selectExportType.types.datev',
  DATEV_BDS: 'export.exportForm.selectExportType.types.datev_bds',
  DATEV_CSV: 'export.exportForm.selectExportType.types.datev_csv',
  DATEV_DXSO_ZIP: 'export.exportForm.selectExportType.types.datev_dxso_zip',
  SAP_B1: 'export.exportForm.selectExportType.types.sap_b1',
  SAP_CSV: 'export.exportForm.selectExportType.types.sap_csv',
  ZIP: 'export.exportForm.selectExportType.types.zip',
  API: 'export.exportForm.selectExportType.types.candis_api',
};

export const ExportTypeSelectField = () => {
  const [t] = useTranslation();
  const { exportType, setExportType, availableExportTypes } =
    useExportContext();

  const isOnlyOneOptionAvailable = availableExportTypes.length === 1;

  // no need to show the select field if there is only one option
  if (isOnlyOneOptionAvailable) {
    return null;
  }

  return (
    <SelectField
      select={{
        selectedKey: exportType || availableExportTypes[0],
        onSelectionChange: key => {
          setExportType(key as ExportType);
        },
        children: availableExportTypes.map(type => (
          <Item key={type}>{t(exportTypeTranslationsMap[type])}</Item>
        )),
      }}
      label={t('export.exportForm.selectExportType.label')}
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      placeholder={t('common:select')}
    />
  );
};

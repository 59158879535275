import {
  GoodsReceiptFilterInput,
  GoodsReceiptSortField,
  GoodsReceiptSortInput,
  SortDirection,
} from 'generated-types/graphql.types';
import { Filters, SortingRule } from 'react-table';
import {
  AvailableFilters,
  GoodsReceiptsTableColumnKeys,
  GoodsReceiptsTableData,
} from './types';

export const defaultSort: SortingRule<GoodsReceiptsTableData>[] = [
  {
    id: 'status',
    desc: true,
  },
];

type SortableKeys = Extract<
  GoodsReceiptsTableColumnKeys,
  'deliveryDate' | 'status' | 'receiptNumber' | 'supplierRefNumber' | 'amount'
>;

export const goodsReceiptsTableAvailableFilters: {
  [key in keyof AvailableFilters]-?: keyof AvailableFilters;
} = {
  status: 'status',
  contact: 'contact',
};

const mapSortIdsToGoodsReceiptSortFields: {
  [key in SortableKeys]: GoodsReceiptSortField;
} = {
  deliveryDate: GoodsReceiptSortField.DeliveryDate,
  status: GoodsReceiptSortField.Status,
  receiptNumber: GoodsReceiptSortField.ReceiptNumber,
  supplierRefNumber: GoodsReceiptSortField.SupplierRefNumber,
  amount: GoodsReceiptSortField.TotalAmount,
};

export const mapToSortInput = (
  sort: Array<SortingRule<GoodsReceiptsTableData>>
): GoodsReceiptSortInput | undefined => {
  if (sort.length < 1) {
    return undefined;
  }

  const [firstSort] = sort;

  const field =
    mapSortIdsToGoodsReceiptSortFields[firstSort.id as SortableKeys];

  if (!field) {
    return undefined;
  }

  return {
    field,
    direction: firstSort.desc ? SortDirection.Desc : SortDirection.Asc,
  };
};

export const mapToFilterInput = (
  filters: Filters<GoodsReceiptsTableData>
): GoodsReceiptFilterInput => {
  return filters.reduce<GoodsReceiptFilterInput>(
    (documentFilterInput, filter) => {
      if (filter.id === goodsReceiptsTableAvailableFilters.contact) {
        return { ...documentFilterInput, accountsPayableNumbers: filter.value };
      }

      if (filter.id === goodsReceiptsTableAvailableFilters.status) {
        return { ...documentFilterInput, statuses: filter.value };
      }

      return documentFilterInput;
    },
    {}
  );
};

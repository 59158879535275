import { useAssociatedDocuments } from '../../hooks/useAssociatedDocuments';
import { getLinkingSource } from '../../utils/getLinkingSource';

export const useLinkedDocumentSource = (documentId: string) => {
  const { associatedDocuments } = useAssociatedDocuments(documentId);

  const { associatedGoodsReceipts, associatedPurchaseOrders } =
    associatedDocuments;

  return {
    source: getLinkingSource(associatedDocuments),
    linkedPurchaseOrders: associatedPurchaseOrders,
    linkedGoodsReceipts: associatedGoodsReceipts,
  };
};

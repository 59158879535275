import moment from 'moment';

export const isEarlierTimeSameDay = (
  startingDate: Date,
  endingDate: Date,
  timeZone: string = 'Europe/Berlin'
) => {
  const startingDateMoment = moment.tz(startingDate, timeZone);
  const endingDateMoment = moment.tz(endingDate, timeZone);

  const millisecondDifference =
    endingDateMoment.valueOf() - startingDateMoment.valueOf();

  const hoursDifference = millisecondDifference / (1000 * 60 * 60);

  const isSameDay =
    startingDateMoment.format('ll') === endingDateMoment.format('ll');

  if (hoursDifference < 0 && isSameDay) {
    return true;
  }

  return false;
};

import { useRequestReimbursementCaseAmendmentMutation } from 'generated-types/graphql.types';
import { useCallback } from 'react';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
} from '../toolkit/queries';

export const useRequestAmendment = () => {
  const [
    requestAmendmentAction,
    { loading: isRequestAmendmentActionPending, client },
  ] = useRequestReimbursementCaseAmendmentMutation();

  const requestAmendment = useCallback(
    async (reimbursementId: string, comment: string) => {
      const response = await requestAmendmentAction({
        onCompleted: () => {
          // Instead of refetching the 3 different queries with different params we evict the cache
          client.cache.evict({ fieldName: 'approvalReimbursementCasesCount' });
        },
        refetchQueries: [
          'archiveReimbursementCasesCount',
          'inboxReimbursementCasesCount',
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementHistoryQuery,
            variables: { id: reimbursementId },
          },
        ],
        variables: {
          input: {
            caseId: reimbursementId,
            text: comment,
          },
        },
      });

      if (response.data?.requestReimbursementCaseAmendment.success) {
        return { status: 'success' };
      }

      return { status: 'error' };
    },
    [requestAmendmentAction, client]
  );
  return { requestAmendment, isRequestAmendmentActionPending };
};

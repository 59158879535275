import './segment.js';
import { SpriteMap } from '@candis/icons';
import * as Sentry from '@sentry/react';

import 'providers/LocaleProvider';
import { createRoot } from 'react-dom/client';
import { configure } from 'react-hotkeys';
import 'requestidlecallback';
import { appHistory } from 'router';
import { initSentry } from 'services/monitoring';
import { GlobalStyles } from 'styles/GlobalStyles';
import { App } from './App';
import { GlobalErrorFallbackPage } from './GlobalErrorFallbackPage/GlobalErrorFallbackPage';
import * as serviceWorker from './serviceWorker';
import 'polyfills';

// allows react-hotkeys usage with fields and updating of handlers
configure({ ignoreTags: [], ignoreKeymapAndHandlerChangesByDefault: false });

initSentry({ history: appHistory });

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <Sentry.ErrorBoundary
      fallback={<GlobalErrorFallbackPage />}
      beforeCapture={scope => {
        scope.setTag('app-crash', 'true');
      }}
    >
      <App history={appHistory} />
      <GlobalStyles />
    </Sentry.ErrorBoundary>
  );
}

const spriteMap = document.getElementById('sprite-map');
if (spriteMap) {
  const spriteMapRoot = createRoot(spriteMap);
  spriteMapRoot.render(<SpriteMap />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { HighlightedTruncatedText } from '@candisio/design-system';
import {
  WithHighlightsCellProps,
  getCellHighlights,
} from 'components/WithHighlightsCell/next/WithHighlightsCell';
import type { WithHighlights as WithHighlightsType } from 'components/WithHighlightsCell/types';
import { getAmountHighlights } from '../../utils/getAmountHighlights';
import { Amount } from 'components/DocumentsTable/types';

export const AmountCellHighlightable = <T extends WithHighlightsType>({
  cellProps,
  FallbackCell,
}: WithHighlightsCellProps<T>) => {
  const highlights = getCellHighlights(cellProps);
  if (!highlights) {
    return <FallbackCell {...cellProps} />;
  }
  const value = cellProps.getValue();
  const amountHighlight = getAmountHighlights(value as Amount, highlights);

  if (!amountHighlight) {
    return <FallbackCell {...cellProps} />;
  }

  return (
    <HighlightedTruncatedText
      highlight={amountHighlight}
      className="max-w-[50ch] text-end ml-auto"
    />
  );
};

import { useApolloClient } from '@apollo/client';
import {
  useGetCardIssuerCardholderByMembershipIdQuery,
  useGetCardIssuerCardholdersQuery,
  useGetCardIssuerCardsForCardholderQuery,
} from 'generated-types/graphql.types';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useUserId } from 'providers/OrganizationProvider';
import { useMemo } from 'react';
import { getCountCardRequestsQuery } from 'views/CreditCards/CreditCardsInsights/gql';
import { counterRequestsVariables } from 'views/CreditCards/hooks/useCreditCardRequestCountData';
import { getApproximateNumberOfRowForTable } from 'views/utils/pagination-helper';
import { cardStatusesForWallet } from '../../containers/credit-cards/components/CreditCardsSection';
import { getCardIssuerCardByIdQuery } from '../../containers/credit-cards/components/CreditCardsSection/gql';
import {
  getCardIssuerCardsForCardManagerQuery,
  getCardIssuerCardsForCardholderQuery,
} from './queries';

interface CardHolderProps {
  fetchCardholdersWithMissingInvoices?: boolean;
  fetchCardholdersWithTransactions?: boolean;
}

export const cardQueryArgs = {
  variables: {
    // XXX need to implement paginated query for filter dropdown?
    input: { limit: 500 },
    filters: { statuses: cardStatusesForWallet },
  },
};

export const useCardHolders = ({
  fetchCardholdersWithMissingInvoices,
  fetchCardholdersWithTransactions,
}: CardHolderProps = {}) => {
  const { data, loading } = useGetCardIssuerCardholdersQuery({
    variables: {
      input: { limit: 200 },
      filters: {
        hasTransactionWithMissingInvoice: fetchCardholdersWithMissingInvoices,
        hasTransactions: fetchCardholdersWithTransactions,
      },
    },
  });

  const cardHolders = data?.getCardIssuerCardholders.edges || [];

  return {
    cardHolders,
    loading,
  };
};

export const useCreditCards = (skip = false) => {
  const { data, loading } = useGetCardIssuerCardsForCardholderQuery({
    ...cardQueryArgs,
    fetchPolicy: 'cache-and-network',
    skip,
  });

  const currentCardHolderCards =
    data?.getCardIssuerCardsForCardholder.edges || [];

  const hasCreditCards = !!currentCardHolderCards?.length;

  return {
    currentCardHolderCards,
    loading,
    hasCreditCards,
  };
};

export const useCreditCardsRefetchQueries = () => {
  const client = useApolloClient();
  const refetchQueries = useMemo(
    () => ({
      cardIssuerCards: (cardId?: string) => [
        ...(cardId
          ? [
              {
                query: getCardIssuerCardByIdQuery,
                variables: { id: cardId },
              },
            ]
          : []),
        {
          query: getCardIssuerCardsForCardholderQuery,
          ...cardQueryArgs,
        },
        {
          query: getCountCardRequestsQuery,
          variables: counterRequestsVariables,
        },
      ],
      cardIssuerCardsCardManager: (cardId?: string) => [
        ...(cardId
          ? [
              {
                query: getCardIssuerCardByIdQuery,
                variables: { id: cardId },
              },
            ]
          : []),
        {
          query: getCardIssuerCardsForCardManagerQuery,
          variables: {
            input: {
              limit: getApproximateNumberOfRowForTable(),
            },
          },
        },
        {
          query: getCountCardRequestsQuery,
          variables: counterRequestsVariables,
        },
      ],
    }),
    []
  );

  const evictPaginationResults = () => {
    client.cache.evict({ fieldName: 'getCardIssuerCardsForCardManager' });
  };

  return { refetchQueries, evictPaginationResults };
};

export const useCardholderId = () => {
  const { isInUse } = useCreditCardsSetup();
  const membershipId = useUserId();
  const { data, loading } = useGetCardIssuerCardholderByMembershipIdQuery({
    variables: {
      membershipId: membershipId ?? '',
    },
    skip: !membershipId || !isInUse,
  });

  const cardholderId =
    data?.getCardIssuerCardholderByMembershipId?.id ?? undefined;

  return {
    cardholderId,
    loading,
  };
};

import {
  Button,
  Grid,
  Popover,
  Skeleton,
  Switch,
  Text,
  usePopover,
} from '@candisio/design-system';
import { omit } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReimbursementFormValues } from 'views/Reimbursement/toolkit/reimbursementFormSchema';
import styles from '../RightSection.module.css';

interface AdvancedAccountingDataSwitchProps {
  isLoading: boolean;
  onChange: () => void;
}

const LoadingSwitch = () => (
  <Grid templateColumns="65px 160px" gap="space4" placeItems="center">
    <Skeleton width="100%" height="28px" borderRadius="full" />
    <Skeleton width="100%" height="space14" borderRadius="medium" />
  </Grid>
);

export const AdvancedAccountingDataSwitch = ({
  isLoading,
  onChange: onChangeProp,
}: AdvancedAccountingDataSwitchProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const { field } = useController<
    ReimbursementFormValues,
    'isAdvancedAccountingEnabled'
  >({
    name: 'isAdvancedAccountingEnabled',
  });

  const { value, onChange } = field;

  const { isOpen, triggerProps, triggerRef, popoverProps, popoverRef } =
    usePopover({ isOpen: isConfirmationOpen });

  const handleChange = (isToggled: boolean) => {
    if (!isToggled) {
      setIsConfirmationOpen(true);
      return;
    }

    onChange(isToggled);
    onChangeProp();
  };

  if (isLoading) {
    return <LoadingSwitch />;
  }

  const handleConfirm = () => {
    onChange(false);
    onChangeProp();
    setIsConfirmationOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmationOpen(false);
  };

  return (
    <div className={styles['advanced-switch']}>
      <Switch
        {...omit(triggerProps, ['onClick', 'onBlur', 'onFocus'])}
        ref={triggerRef}
        name="isAdvancedAccountingEnabled"
        checked={value}
        label={t(
          'reimbursementView.rightSection.form.advancedAccounting.label'
        )}
        onChange={handleChange}
      />
      <Text>
        {t('reimbursementView.rightSection.form.advancedAccounting.label')}
      </Text>
      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef} width="22rem">
          <div className={`${styles['advanced-switch-popover']}`}>
            <Text>
              {t(
                'reimbursementView.rightSection.form.advancedAccounting.confirmationPopover.description'
              )}
            </Text>
            <div className={`${styles['actions']}`}>
              <Button variant="secondary" onClick={handleCancel}>
                {t(
                  'reimbursementView.rightSection.form.advancedAccounting.confirmationPopover.cancel'
                )}
              </Button>
              <Button color="red" onClick={handleConfirm}>
                {t(
                  'reimbursementView.rightSection.form.advancedAccounting.confirmationPopover.confirm'
                )}
              </Button>
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};

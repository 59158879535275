import { ReimbursementCasesFilterInput } from 'generated-types/graphql.types';
import { useDateConverter } from 'hooks/useDateConverter';
import { useMemo } from 'react';
import { Filters } from 'react-table';
import { ReimbursementTableRow } from '../toolkit/types';

export const REIMBURSEMENT_TABLE_FILTERS = {
  status: 'status',
} as const;

export const useMapToReimbursementsFilterInput = (
  filters: Filters<ReimbursementTableRow>,
  defaultBEFilters: ReimbursementCasesFilterInput = {}
): ReimbursementCasesFilterInput => {
  const { dateStringToIsoDateFilterFormat } = useDateConverter();
  const mappedFilterInput = useMemo(() => {
    return filters.reduce<ReimbursementCasesFilterInput>(
      (filterInput, filter) => {
        if (filter.id === 'status') {
          return {
            ...filterInput,
            statuses: filter.value,
          };
        }

        if (filter.id === 'title') {
          return {
            ...filterInput,
            titles: filter.value,
          };
        }

        if (filter.id === 'iban') {
          return {
            ...filterInput,
            ibans: filter.value,
          };
        }

        if (filter.id === 'requesterOfTheReimbursement') {
          return {
            ...filterInput,
            targetMembershipIds: filter.value,
          };
        }

        if (filter.id === 'lastApprovedByMembership') {
          return {
            ...filterInput,
            lastApprovedByIds: filter.value,
          };
        }

        if (filter.id === 'approvers') {
          return {
            ...filterInput,
            approversIds: filter.value,
          };
        }

        if (filter.id === 'requester') {
          return {
            ...filterInput,
            requesterIds: filter.value,
          };
        }

        if (filter.id === 'isPaid') {
          if (
            (filter.value ?? []).length === 2 ||
            (filter.value ?? []).length === 0
          ) {
            return {
              ...filterInput,
              isPaid: null,
            };
          } else if (filter.value?.[0] === 'Paid') {
            return {
              ...filterInput,
              isPaid: true,
            };
          } else {
            return {
              ...filterInput,
              isPaid: false,
            };
          }
        }

        if (filter.id === 'approvalRequestedAt') {
          const filterValue = filter.value as Array<string>;
          const [from, to] = filterValue[0].split('-');
          const fromIso = dateStringToIsoDateFilterFormat(from);
          const toIso = dateStringToIsoDateFilterFormat(to);
          return {
            ...filterInput,
            lastSubmittedToApprovalAt: {
              fromDate: fromIso || '',
              toDate: toIso || '',
            },
          };
        }

        if (filter.id === 'firstSubmittedForReviewAt') {
          const filterValue = filter.value as Array<string>;
          const [from, to] = filterValue[0].split('-');
          const fromIso = dateStringToIsoDateFilterFormat(from);
          const toIso = dateStringToIsoDateFilterFormat(to);
          return {
            ...filterInput,
            firstSubmittedToReviewAt: {
              fromDate: fromIso || '',
              toDate: toIso || '',
            },
          };
        }

        if (
          filter.id === 'paidAt' ||
          filter.id === 'createdAt' ||
          filter.id === 'lastClosedAt'
        ) {
          const filterValue = filter.value as Array<string>;
          const [from, to] = filterValue[0].split('-');
          const fromIso = dateStringToIsoDateFilterFormat(from);
          const toIso = dateStringToIsoDateFilterFormat(to);
          return {
            ...filterInput,
            [filter.id]: {
              fromDate: fromIso || '',
              toDate: toIso || '',
            },
          };
        }

        return filterInput;
      },
      defaultBEFilters
    );
  }, [filters, defaultBEFilters, dateStringToIsoDateFilterFormat]);

  return mappedFilterInput;
};

export const getOrganizationNameInitials = (input: string): string => {
  const sanitizedInput = input
    .replace(/[^\p{L}\p{N}\s]/gu, '')
    .toLocaleUpperCase();

  const words = sanitizedInput.split(' ');

  if (words.length <= 1) {
    return sanitizedInput.length < 2
      ? sanitizedInput
      : sanitizedInput.substring(0, 2);
  }

  return words
    .filter(word => word !== '')
    .slice(0, 2)
    .map(word => word.charAt(0))
    .join('');
};

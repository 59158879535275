import {
  Grid,
  Heading,
  Link,
  Spinner,
  Switch,
  Text,
} from '@candisio/design-system';
import { AnimatePresence, motion } from 'motion/react';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import {
  animationProps,
  spinnerAnimationProps,
} from '../ContactSettings/ContactSettingsForm';
import { StartValueField } from '../ContactSettings/StartValueField';
import {
  EmployeesAPNSettingsFormOutput,
  useEmployeesAPNSettingsFormSchema,
} from './employeesAPNSettingsFormSchema';
import { useSetEmployeesAccountsPayableNumber } from './useSetEmployeesAccountsPayableNumber';
import { isNil } from 'lodash';

const MotionGrid = motion.create(Grid);
const MotionSpinner = motion.create(Spinner);

export type EmployeesAPNSettingsFormProps = Omit<
  ReturnType<typeof useSetEmployeesAccountsPayableNumber>,
  'loadingInitialValues'
>;

export const EmployeesAPNSettingsForm = ({
  enabled,
  startValue,
  setEnabled,
  setStartValue,
  isSettingAccountsPayable: submitting,
}: EmployeesAPNSettingsFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.COMMON);

  const employeeContactSettingsFormSchema = useEmployeesAPNSettingsFormSchema();

  const form = useForm<EmployeesAPNSettingsFormOutput>({
    mode: 'onBlur',
    defaultValues: {
      startValue: !isNil(startValue) ? String(startValue) : '',
    },
    resolver: zodResolver({
      zodSchema: employeeContactSettingsFormSchema,
      translationNamespace: LOCALE_NAME_SPACE.COMMON,
      errorMessages: {
        startValue: {
          label: 'settings.contacts.employeeContact.startValueLabel',
        },
      },
    }),
  });

  const handleSubmit = async ({
    startValue,
  }: EmployeesAPNSettingsFormOutput) => {
    if (!startValue) return;

    const res = await setStartValue(startValue);
    if (res.data) {
      form.resetField('startValue', { defaultValue: startValue });
    }
  };

  return (
    <Grid gap="space16">
      <Grid
        justifyContent="space-between"
        alignItems="center"
        autoFlow="column"
      >
        <Heading as="h3">
          {t('settings.contacts.employeeContact.title')}
        </Heading>
        <Grid
          autoFlow="column"
          justifyContent="space-between"
          alignItems="center"
          gap="space8"
        >
          <AnimatePresence>
            {submitting && (
              <MotionSpinner size="space20" {...spinnerAnimationProps} />
            )}
          </AnimatePresence>
          <Switch
            label={t('settings.contacts.employeeContact.switchLabel')}
            checked={enabled}
            disabled={submitting}
            onChange={isSelected => {
              void setEnabled(isSelected);
            }}
          />
        </Grid>
      </Grid>
      <Grid gap="space16">
        <AnimatePresence initial={false}>
          {enabled && (
            <MotionGrid gap="space16" {...animationProps}>
              <FormProvider {...form}>
                <Grid
                  as="form"
                  autoFlow="column"
                  justifyContent="start"
                  alignItems="flex-end"
                  gap="space8"
                  onSubmit={form.handleSubmit(handleSubmit)}
                >
                  <StartValueField<EmployeesAPNSettingsFormOutput>
                    name="startValue"
                    label={t(
                      'settings.contacts.apnSuggestions.startValueLabel'
                    )}
                    readOnly={submitting}
                  />
                  <QuickSaveButtons
                    isFormDirty={form.formState.isDirty}
                    resetField={() => form.resetField('startValue')}
                    isLoading={submitting}
                  />
                </Grid>
              </FormProvider>
            </MotionGrid>
          )}
        </AnimatePresence>
        <Trans
          i18nKey="settings.contacts.employeeContact.description"
          parent="span"
        >
          <Text fontSize="small" color="gray800" lineHeight="paragraph"></Text>{' '}
          <Link
            href={t('settings.contacts.employeeContact.helpCenterLink')}
            external
          />
        </Trans>
      </Grid>
    </Grid>
  );
};

import { PDFDetails } from 'components/DocumentViewer/utils';
import { useEcmDocumentFileQuery } from 'generated-types/graphql.types';
import { useMemo } from 'react';

export const useEcmDocumentFile = ({ documentId }: { documentId: string }) => {
  const {
    data: documentFileData,
    error,
    loading: isDocumentFileLoading,
  } = useEcmDocumentFileQuery({
    variables: { id: documentId },
    skip: typeof documentId !== 'string' || !documentId.includes('-'),
    // Document file URL expires after 12 hours but the user’s session can
    // last much longer. If we use the default `cache-first` fetch policy we
    // might try to download the PDF from an expired URL.
    //
    // We use `cache-and-network` instead so that we can still show the PDF
    // viewer immediately but then rerender if the URL has changed.
    fetchPolicy: 'cache-and-network',
  });

  const documentData = documentFileData?.getAggregatedEcmDocument;

  const documentFile: PDFDetails = useMemo(
    () => ({
      name: documentData?.fileSource?.name ?? '',
      url: documentData?.fileSource?.url ?? '',
      size: documentData?.fileSource?.size ?? 0,
      id: documentData?.fileSource?.id ?? '',
    }),
    [documentData]
  );

  return { documentFile, isDocumentFileLoading, error };
};

import {
  Button,
  Grid,
  Paragraph,
  Popover,
  Text,
  usePopover,
  useTheme,
} from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  AssociationSource,
  useDisconnectAllAssociatedDocumentsMutation,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useThreeWayMatchContext } from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/ThreeWayMatchContext';
import { MatchState } from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/types';
import {
  documentQueries,
  getSapDocumentsQuery,
} from 'views/Inbox/DocumentProcessing/queries';
import { documentHistoryQuery } from 'views/queries';

interface UnlinkButtonProps {
  disabled: boolean;
  variant: MatchState;
}

export const UnlinkButton = ({
  disabled = false,
  variant,
}: UnlinkButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { success, error } = useToastMessage();
  const { isOnlyApprover } = useUserRoles();
  const { documentId, linkedDocumentCount, linkingSource } =
    useThreeWayMatchContext();

  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'bottom' });

  const { colors } = useTheme();

  const [linkingSourceBeforeUnlinking, setLinkingSourceBeforeUnlinking] =
    useState<AssociationSource | undefined>(linkingSource);

  const [unlinkAllAssocsFromInvoice, { loading: isUnlinkLoading }] =
    useDisconnectAllAssociatedDocumentsMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: documentHistoryQuery, variables: { id: documentId } },
        { query: getSapDocumentsQuery, variables: { id: documentId } },
        { query: documentQueries.forDraftForm, variables: { id: documentId } },
      ],
      onCompleted: () => {
        close();
        success(
          t(
            linkingSourceBeforeUnlinking === AssociationSource.GoodsReceipt
              ? 'actions.goodsReceiptUnlinkingSuccess'
              : 'actions.unlinkingSuccess'
          )
        );
        setLinkingSourceBeforeUnlinking(undefined);
      },
      onError: () => {
        error(
          t(
            linkingSourceBeforeUnlinking === AssociationSource.GoodsReceipt
              ? 'actions.goodsReceiptUnlinkingError'
              : 'actions.unlinkingError'
          )
        );
      },
    });

  const unlinkingDisabled = isUnlinkLoading || disabled || isOnlyApprover;

  const buttonLabel = useMemo(() => {
    if (variant === 'default') {
      return undefined;
    }

    return unlinkingDisabled
      ? t('threeWayMatch.indicator.disabledUnlinkingTooltip')
      : t('purchaseOrderCardContextMenu.unlinkDocument', {
          count: linkedDocumentCount,
        });
  }, [linkedDocumentCount, t, unlinkingDisabled, variant]);

  useEffect(() => {
    if (linkingSource) {
      setLinkingSourceBeforeUnlinking(linkingSource);
    }
  }, [linkingSource]);

  return (
    <>
      <Button
        icon="unlinkOutlined"
        variant="tertiary"
        label={buttonLabel}
        size="xsmall"
        style={{
          backgroundColor: colors.gray250,
        }}
        disabled={unlinkingDisabled}
        {...triggerProps}
        ref={triggerRef}
        name={t('purchaseOrderCardContextMenu.tooltip')}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          triggerProps?.onClick?.(e);
        }}
      />
      {!unlinkingDisabled && isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          width="365px"
          padding="space14"
        >
          <Grid gap="space16">
            <Grid gap="space8">
              <Text fontWeight="bold">{buttonLabel}</Text>
              <Paragraph>
                {t('purchaseOrderCardContextMenu.unlinkDocumentDescription', {
                  count: linkedDocumentCount,
                })}
              </Paragraph>
            </Grid>
            <Grid
              autoFlow="column"
              gap="space16"
              justifyContent="end"
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                e.stopPropagation()
              }
            >
              <Button
                variant="secondary"
                onClick={close}
                disabled={isUnlinkLoading}
                name={t('purchaseOrderCardContextMenu.cancelCTALabel')}
              >
                {t('purchaseOrderCardContextMenu.cancelCTALabel')}
              </Button>
              <Button
                color="error"
                loading={isUnlinkLoading}
                onClick={async () => {
                  if (!unlinkingDisabled && documentId) {
                    await unlinkAllAssocsFromInvoice({
                      variables: {
                        invoiceId: documentId,
                      },
                    });
                  }
                }}
                name={t('purchaseOrderCardContextMenu.confirmCTALabel')}
              >
                {t('purchaseOrderCardContextMenu.confirmCTALabel')}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};

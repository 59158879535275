import { Grid } from '@candisio/design-system';
import { ReactNode } from 'react';

interface ApprovalsLayoutProps {
  header: ReactNode;
  tabs: ReactNode;
}

export const ApprovalsLayout = ({ header, tabs }: ApprovalsLayoutProps) => {
  return (
    <Grid
      as="main"
      height="100%"
      overflow="hidden"
      gap="space16"
      templateRows="auto 1fr"
      padding="space20 space32 space24 space32"
    >
      {header}
      {tabs}
    </Grid>
  );
};

import { Box, Switch, Tooltip, useTooltip } from '@candisio/design-system';
import {
  DocumentType,
  useUpdateEcmDocumentTypePreferencesMutation,
} from 'generated-types/graphql.types';
import type { DocumentTypeSettingFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const disabledDocumentTypes = [DocumentType.Contract, DocumentType.Other];

interface DocumentTypesSettingIsVisibleSwitchProps {
  documentType: DocumentTypeSettingFragment;
}

export function DocumentTypesSettingIsVisibleSwitch({
  documentType,
}: DocumentTypesSettingIsVisibleSwitchProps) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const [isVisible, setIsVisible] = useState(documentType.isVisible);
  const [updateIsVisible] = useUpdateEcmDocumentTypePreferencesMutation();

  const toggleVisibility = async (isVisible: boolean) => {
    // @TODO: revisit optimisitc mutation
    setIsVisible(isVisible);
    await updateIsVisible({
      variables: {
        input: {
          name: documentType.name,
          isVisible,
        },
      },
      refetchQueries: ['ecmDocumentTypeSettings'],
    });
  };

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  const isDisabledType = disabledDocumentTypes.includes(
    documentType.name as DocumentType
  );
  const showTooltip = isOpen && isDisabledType;
  const tooltipTextMap: Record<string, string> = {
    [DocumentType.Contract]: t('documentTypes.switch.tooltip.contract'),
    [DocumentType.Other]: t('documentTypes.switch.tooltip.other'),
  };
  const tooltipText = tooltipTextMap[documentType.name] ?? '';

  const label = isVisible
    ? t('documentTypes.switch.label.on')
    : t('documentTypes.switch.label.off');

  return (
    <>
      <Box {...triggerProps} ref={triggerRef}>
        <Switch
          label={label}
          checked={isVisible}
          disabled={isDisabledType}
          onChange={toggleVisibility}
        />
      </Box>
      {showTooltip && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {tooltipText}
        </Tooltip>
      )}
    </>
  );
}

import { useContactNameFilter } from 'components/DocumentsTable/hooks/useContactNameFilter';
import { PaginationWithSearchFilterHook } from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import { ContactRelationshipType } from 'generated-types/graphql.types';

export type PaginationFiltersHooksType = {
  [columnName: string]: PaginationWithSearchFilterHook;
};

export const customPaginationAllDocumentsFilterHooks: PaginationFiltersHooksType =
  {
    contact: ({ filteredValues, searchStr }) =>
      useContactNameFilter({
        filteredValues,
        searchStr,
        relationshipType: Object.values(ContactRelationshipType),
      }),
  };

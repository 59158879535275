import { Flex, Grid } from '@candisio/design-system';
import { AdvancedSearchField } from 'components/AdvancedSearchField/AdvancedSearchField';
import {
  Configuration,
  ConfigurationsMenu,
} from 'components/Table/Configurations/ConfigurationsMenu';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';

interface ReimbursementTableToolbarProps {
  search: string;
  configurations: Configuration[];
  isLoading?: boolean;
  toolbarFilter?: ReactNode;
  downloadReimbursementsActions?: ReactNode;
  onUpdateConfigurations: (configurations: Configuration[]) => void;
  onResetTableConfigurations: () => void;
  onSearch: (querySearch: string) => void;
}

export const ReimbursementTableToolbar = ({
  configurations,
  search,
  isLoading,
  toolbarFilter,
  downloadReimbursementsActions,
  onSearch,
  onResetTableConfigurations,
  onUpdateConfigurations,
}: ReimbursementTableToolbarProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Grid
      templateColumns="minmax(12rem, 24rem) 1fr"
      borderTopRadius="basic"
      overflow="hidden"
      alignItems="center"
      background="white"
      gap="space8"
      borderBottom="1px solid gray200"
      padding="space12"
    >
      <AdvancedSearchField
        clearTooltip={t('archive:search.clearTooltip')}
        placeholder={t('archive:search.placeholder')}
        initialValue={search}
        onQueryChange={onSearch}
        inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
      />

      <Flex
        justifySelf={toolbarFilter ? undefined : 'right'}
        justifyContent={toolbarFilter ? 'space-between' : undefined}
        gap="space4"
        alignItems="center"
      >
        {toolbarFilter && toolbarFilter}
        <ConfigurationsMenu
          isLoading={isLoading}
          configurationItems={configurations}
          onUpdateConfigurations={onUpdateConfigurations}
          onResetConfigurations={onResetTableConfigurations}
        />
        {downloadReimbursementsActions && downloadReimbursementsActions}
      </Flex>
    </Grid>
  );
};

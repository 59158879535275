import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useTranslation } from 'react-i18next';
import { SessionActionCard } from 'views/Settings/ApiSettings/elements/Card/SessionActionCard';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';

export const ApiSettings = () => {
  const [t] = useTranslation();

  return (
    <SettingsLayout
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
      title={t('settings:apiSettings.title')}
      data-cy="settings-layout"
    >
      <SessionActionCard />
      <InfoPanel
        externalLink={t('settings:apiSettings.infoPanel.externalLink', {
          returnObjects: true,
        })}
        sections={t('settings:apiSettings.infoPanel.sections', {
          returnObjects: true,
        })}
        title={t('settings:apiSettings.infoPanel.title')}
      />
    </SettingsLayout>
  );
};

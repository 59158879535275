import { ScrollBox } from '@candisio/design-system';
import { History } from 'components/History/History';
import { HistoryLoading } from 'components/History/HistoryLoading';
import { Export, ExportType } from 'generated-types/graphql.types';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ExportContext } from 'views/Integrations/Export/Context';
import { defineExportView } from 'views/Integrations/Export/Manifest';
import {
  ExportQuery,
  paginationQueryOptions,
} from 'views/Integrations/Export/consts';
import {
  LoadMoreItemsButton,
  isLoadingInitialOrMoreData,
} from 'views/Integrations/Export/toolkit/components/LoadMoreItemsButton';
import { getPaginatedExportsQuery } from 'views/Integrations/Export/toolkit/queries';
import { Sidebar, View } from 'views/Integrations/Export/types';
import { Exported } from './components/Exported';

const HISTORY_PAGINATION_LIMIT = 10;

const ExportedItems = ({ exports }: { exports: Export[] }) => {
  const { exportId } = useContext(ExportContext);
  const navigate = useNavigate();
  const orgId = useOrganizationId();
  const [t] = useTranslation();

  const state = useContext(ExportContext);

  return (
    <>
      {exports.map((ex, index) => {
        const exportedByName =
          ex.type === ExportType.Api
            ? t('export.exportHistory.creator.candisApi')
            : (ex.creator?.name ?? '');

        const viewDef = defineExportView(
          {
            ...state,
            sidebar: Sidebar.HISTORY,
            view: View.HISTORY,
            exportId: ex.id,
            timeframe: null,
          },
          { orgId }
        );

        return (
          <Exported
            isLast={index === exports.length - 1}
            index={index}
            key={ex.id}
            isActive={exportId === ex.id}
            onGoToExport={() => navigate(viewDef.navigate)}
            createdAt={new Date(ex.createdAt)}
            exportedByName={exportedByName}
            status={ex.status}
            exportId={ex.id}
            exportableEntityInfo={ex.exportableEntityInfo ?? []}
          />
        );
      })}
    </>
  );
};

export const ExportHistory = () => {
  const [t] = useTranslation();

  const paginationResponse = usePagination<ExportQuery>(
    getPaginatedExportsQuery,
    '_exports',
    paginationQueryOptions
  );

  const { data } = paginationResponse;

  const exports = data?._exports.node ?? [];

  return (
    <ScrollBox data-cy="history-sidebar">
      <History>
        <ExportedItems exports={exports} />
        {isLoadingInitialOrMoreData(paginationResponse) && (
          <HistoryLoading
            itemCount={HISTORY_PAGINATION_LIMIT - 1}
            props={{ paddingTop: 'space16', paddingLeft: '28px' }}
          />
        )}
      </History>
      <LoadMoreItemsButton
        t={t}
        paginationLimit={HISTORY_PAGINATION_LIMIT}
        {...paginationResponse}
      />
    </ScrollBox>
  );
};

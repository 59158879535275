import { Box, Grid } from '@candisio/design-system';
import { PdfInvoiceSkeleton } from 'components/DocumentViewer/PdfViewer/PdfInvoiceSkeleton';
import { PdfViewer } from 'components/DocumentViewer/PdfViewer/PdfViewer';
import { PdfHeaderProps } from 'components/DocumentViewer/PdfViewer/ReactPdf/PdfHeader';
import { useCallback, useState } from 'react';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ReplaceInvoice } from './ReplaceInvoice';

interface ExpenseInvoicePreviewContainerProps {
  id: string;
  name: string;
  invoice?: ExpenseInvoice | null;
  createInvoicePending?: boolean;
  isReadOnly?: boolean;
  isExcluded?: boolean;
  height?: string;
  onChange: (file: File) => Promise<void>;
  onInvoiceUpdate?: () => Promise<void>;
}

export const ExpenseInvoicePreviewContainer = ({
  id,
  name,
  invoice,
  createInvoicePending,
  isReadOnly,
  isExcluded,
  height,
  onChange,
  onInvoiceUpdate,
}: ExpenseInvoicePreviewContainerProps) => {
  const [isFileChanging, setIsFileChanging] = useState(false);

  const isLoading = createInvoicePending || isFileChanging;

  const handleIsFileChanging = useCallback((val: boolean) => {
    setIsFileChanging(val);
  }, []);

  if (isLoading) {
    return (
      <Grid height="500px">
        <PdfInvoiceSkeleton />
      </Grid>
    );
  }

  if (!invoice) {
    return null;
  }

  const disabledControls: PdfHeaderProps['disabledControls'] = [
    'downloadFiles',
    'currentPage',
    'previousPage',
    'nextPage',
  ];

  return (
    <Grid
      gap="space24"
      background="gray0"
      maxHeight={height}
      overflow="hidden"
      padding="space8 space16"
      alignContent="start"
      borderRadius="medium"
      border="1px solid gray250"
    >
      {/** Overlay to blur the whole pdf viewer */}
      {isExcluded && (
        <Box
          height="100%"
          width="100%"
          position="absolute"
          opacity="0.4"
          background="white"
          zIndex={10}
        />
      )}
      <PdfViewer
        documentFile={invoice}
        maxHeight={height}
        disabledControls={disabledControls}
        initialScale={1}
        attachmentSection={
          <ReplaceInvoice
            id={id}
            name={name}
            disabled={isReadOnly || isLoading || isExcluded}
            onChange={onChange}
            onInvoiceUpdate={onInvoiceUpdate}
            isFileChanging={isFileChanging}
            onIsFileChanging={handleIsFileChanging}
          />
        }
      />
    </Grid>
  );
};

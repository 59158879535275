import { getDateRangeForNextNDays } from 'utils/getDateRange';
import {
  CARD_EXPIRING_STATUES,
  CARD_EXPIRING_TYPES,
} from 'views/CreditCards/CreditCardsInsights/constants/ExpiringCards';
import { CreditCardQueryParams } from 'views/CreditCards/utils/utils';

export const getCardExpiringSearchParams = () => {
  const { fromDateFormatted: dateFrom, toDateFormatted: dateTo } =
    getDateRangeForNextNDays({ days: 30 });

  return {
    [CreditCardQueryParams.expiryDate]: `${dateFrom}-${dateTo}`,
    [CreditCardQueryParams.status]: CARD_EXPIRING_STATUES,
    [CreditCardQueryParams.cardType]: CARD_EXPIRING_TYPES,
  };
};

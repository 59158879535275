import {
  Button,
  ButtonProps,
  MenuButton,
  MenuItem,
  Paragraph,
} from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useAppLayout } from 'providers/AppLayoutProvider';
import { useDocumentUploadDispatch } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useTranslation } from 'react-i18next';

const UPLOAD_DOCUMENTS_ID = 'dms-upload-documents';
const UPLOAD_SENSITIVE_CONTRACTS_ID = 'dms-upload-sensitive-contracts';

interface UploadDocumentSplitButtonProps {
  size?: ButtonProps['size'];
  variant?: ButtonProps['variant'];
}

interface UploadDocumentOptionProps {
  label: string;
}

// TGB-949 We want to handle how we break words with long german translation
const UploadDocumentOption = ({ label }: UploadDocumentOptionProps) => {
  return (
    <Paragraph whiteSpace="pre-wrap" wordBreak="break-word">
      {label}
    </Paragraph>
  );
};

export const useUploadDocumentOptions = (
  closeModal?: () => void
): MenuItem[] => {
  const [t] = useTranslation();
  const dispatch = useDocumentUploadDispatch();

  const handleShowUploadModal = () => {
    dispatch('showModal');
    closeModal?.();
  };

  const handleShowSensitiveModal = () => {
    dispatch('showSensitiveModal');
    closeModal?.();
  };

  return [
    {
      id: UPLOAD_DOCUMENTS_ID,
      label: t('documentUploadModal.uploadSensitiveContracts.allDocuments'),
      onAction: handleShowUploadModal,
      renderItem: () => (
        <UploadDocumentOption
          label={t('documentUploadModal.uploadSensitiveContracts.allDocuments')}
        />
      ),
    },
    {
      id: UPLOAD_SENSITIVE_CONTRACTS_ID,
      label: t(
        'documentUploadModal.uploadSensitiveContracts.sensitiveContracts'
      ),
      onAction: handleShowSensitiveModal,
      renderItem: () => (
        <UploadDocumentOption
          label={t(
            'documentUploadModal.uploadSensitiveContracts.sensitiveContracts'
          )}
        />
      ),
    },
  ];
};

export const UploadDocumentMenuButton = ({
  size = 'small',
  variant = 'primary',
}: UploadDocumentSplitButtonProps) => {
  const [t] = useTranslation();
  const documentUploadReworkFF = useCandisFeatureFlags(
    FEATURE_FLAGS.documentUploadRework
  );
  const uploadOptions = useUploadDocumentOptions();
  const { setLayoutValue } = useAppLayout();

  if (documentUploadReworkFF) {
    return (
      <Button
        icon="plus"
        onClick={() => setLayoutValue('docUploadPopover', { open: true })}
        size={size}
        variant={variant}
      >
        {t('documentUploadModal.title')}
      </Button>
    );
  }

  return (
    <MenuButton
      items={uploadOptions}
      size={size}
      icon="plus"
      iconPosition="left"
      variant={variant}
      menuPlacement="bottom right"
    >
      {t('documentUploadModal.title')}
    </MenuButton>
  );
};

import { TruncatedText } from '@candisio/design-system';
import { CardCategory } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface CategoryCellProps {
  value?: CardCategory;
}

export const categoryTranslationKeys: Record<CardCategory, string> = {
  ADVERTISING_AND_MARKETING:
    'ccRequestForm.categoryPage.categoryList.ADVERTISING_AND_MARKETING',
  COMPUTING_AND_SOFTWARE:
    'ccRequestForm.categoryPage.categoryList.COMPUTING_AND_SOFTWARE',
  EDUCATION_AND_TRAINING:
    'ccRequestForm.categoryPage.categoryList.EDUCATION_AND_TRAINING',
  ELECTRONICS_AND_IT_EQUIPMENT:
    'ccRequestForm.categoryPage.categoryList.ELECTRONICS_AND_IT_EQUIPMENT',
  ENTERTAINMENT_AND_WELLNESS:
    'ccRequestForm.categoryPage.categoryList.ENTERTAINMENT_AND_WELLNESS',
  FOOD_AND_DRINKS: 'ccRequestForm.categoryPage.categoryList.FOOD_AND_DRINKS',
  GIFTS_AND_VOUCHERS:
    'ccRequestForm.categoryPage.categoryList.GIFTS_AND_VOUCHERS',
  MATERIALS_AND_PACKAGING:
    'ccRequestForm.categoryPage.categoryList.MATERIALS_AND_PACKAGING',
  OFFICE_SUPPLIES_AND_EQUIPMENT:
    'ccRequestForm.categoryPage.categoryList.OFFICE_SUPPLIES_AND_EQUIPMENT',
  OTHER: 'ccRequestForm.categoryPage.categoryList.OTHER',
  SERVICES: 'ccRequestForm.categoryPage.categoryList.SERVICES',
  TRAVEL_AND_ACCOMMODATION:
    'ccRequestForm.categoryPage.categoryList.TRAVEL_AND_ACCOMMODATION',
};

export const CategoryCell = ({ value }: CategoryCellProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  if (!value) {
    return <TruncatedText>-</TruncatedText>;
  }

  const categoryTranslation = t(categoryTranslationKeys[value]);

  return (
    <TruncatedText wordBreak="break-word">{categoryTranslation}</TruncatedText>
  );
};

import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { ReimbursementsDashboardPromotion } from './ReimbursementsDashboardPromotion';
import { ReimbursementDashboard } from './ReimbursementDashboard';

export const ReimbursementDashboardContainer = () => {
  const { variant } = useReimbursementsPromotions();

  const isPromotionForReimbursementsVisible = variant !== 'noPromotion';

  if (isPromotionForReimbursementsVisible) {
    return <ReimbursementsDashboardPromotion />;
  }

  return <ReimbursementDashboard />;
};

import { Grid, Item, SelectField } from '@candisio/design-system';
import {
  optionsMonthsTranslations,
  useSelectOptionMonths,
} from 'components/utils/select-option-months';
import { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../../providers/LocaleProvider';
import { DateRangeProps } from '../../types';
import { yearOptions } from './Year';

const currentYear = new Date().getFullYear();

export const SelectionMonth = ({
  setReadableDateOption,
  invoiceDate,
}: DateRangeProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  // in JS January is 0, but we want to have a readable date, e.g. 01-2021 for January
  const currentMonth = new Date().getMonth() + 1;
  const currentMonthKey =
    currentMonth >= 10 ? `${currentMonth}` : `0${currentMonth}`;

  const selectOptionsMonths = useSelectOptionMonths();

  const [initialMonth, initialYear] = invoiceDate?.values.readable
    ? invoiceDate?.values.readable.split(' ')
    : [currentMonthKey, `${currentYear}`];

  const initialMonthAsKey = optionsMonthsTranslations.find(
    ({ key, translation }) =>
      t(translation) === initialMonth || key === initialMonth
  )?.key;

  const [selectedYear, setSelectedYear] = useState(String(initialYear));
  const [selectedMonth, setSelectedMonth] = useState(initialMonthAsKey);

  const handleSelectionChangeYear = (_selectedYear: Key) => {
    setSelectedYear(_selectedYear as string);
    setReadableDateOption(`${selectedMonth}-${_selectedYear}`);
  };

  const handleSelectionChangeMonth = (_selectedMonth: Key) => {
    setSelectedMonth(_selectedMonth as string);
    setReadableDateOption(`${_selectedMonth}-${selectedYear}`);
  };

  useEffect(() => {
    setReadableDateOption(`${selectedMonth}-${selectedYear}`);
  }, [setReadableDateOption, selectedMonth, selectedYear]);

  return (
    <Grid gap="space32" templateColumns="33% 50%" zIndex={1}>
      <SelectField
        label={t('dateRangePicker.inputs.year')}
        select={{
          children: item => <Item>{item.name}</Item>,
          items: yearOptions,
          name: 'year',
          onSelectionChange: handleSelectionChangeYear,
          defaultSelectedKey: selectedYear,
        }}
      />
      <SelectField
        label={t('dateRangePicker.inputs.month')}
        select={{
          children: item => <Item>{item.name}</Item>,
          items: selectOptionsMonths,
          name: 'month',
          onSelectionChange: handleSelectionChangeMonth,
          defaultSelectedKey: selectedMonth,
        }}
      />
    </Grid>
  );
};

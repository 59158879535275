import {
  Avatar,
  Box,
  Flex,
  Grid,
  Image,
  Tag,
  TruncatedText,
} from '@candisio/design-system';
import {
  TransactionStatus,
  TransactionType,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { mappedCategoriesIcons } from '../Table/util';
import { TransactionStatusTag } from '../TransactionStatusTag/TransactionStatusTag';
import { AmountText } from './AmountText/AmountText';
import { Transaction, getAmountTextStyle } from './utils';
import styles from './TransactionDetails.module.css';

interface TransactionDetailsProps {
  transaction?: Transaction;
}

export const TransactionDetailsReduced = ({
  transaction,
}: TransactionDetailsProps) => {
  if (!transaction) return null;

  const {
    type,
    status,
    billingAmount,
    merchantLegalName,
    merchantName,
    merchantLogoUrl,
    category,
  } = transaction;

  const isRefundType = type === TransactionType.Refund;

  const { color, textDecoration } = getAmountTextStyle(status, type);

  return (
    <Grid
      templateColumns="1.5rem 1fr auto"
      autoFlow="column"
      alignItems="center"
    >
      <Box>
        {merchantLogoUrl && (
          <Avatar name={merchantName} img={merchantLogoUrl} size="small" />
        )}
        {!merchantLogoUrl && category && (
          <Flex justifyContent="center">
            <Image src={mappedCategoriesIcons[category]} alt="" />
          </Flex>
        )}
      </Box>
      <Flex gap="space2">
        {merchantLegalName && (
          <TruncatedText
            maxWidth="20ch"
            overflowWrap="anywhere"
            whiteSpace="normal"
            color="gray800"
            fontWeight="semibold"
          >
            {merchantLegalName}
          </TruncatedText>
        )}
        {merchantName && merchantName !== merchantLegalName && (
          <TruncatedText
            maxWidth="12ch"
            color="gray500"
            overflowWrap="anywhere"
            whiteSpace="normal"
            fontWeight="400"
          >
            {`(${merchantName})`}
          </TruncatedText>
        )}
      </Flex>
      <Flex
        justifySelf="end"
        gap="space8"
        color={color}
        textDecoration={textDecoration}
      >
        {billingAmount && (
          <AmountText
            color="gray800"
            fontWeight="bold"
            fontSize="small"
            value={billingAmount}
            isRefundType={isRefundType}
            variant={'mainCurrency'}
          />
        )}
      </Flex>
    </Grid>
  );
};

const templateAreas = `
"           logo            .            .       amount       amount    amount" 1.75rem
"   merchantInfo merchantInfo merchantInfo merchantInfo merchantInfo    status"
"           date         date         date            .            .      type"
`;

export const TransactionDetails = ({
  transaction,
}: TransactionDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  if (!transaction) return null;

  const {
    type,
    status,
    transactionCreatedAt,
    transactionAmount,
    billingAmount,
    merchantLegalName,
    merchantName,
    merchantLogoUrl,
    category,
  } = transaction;

  const isTransactionPaidInForeignCurrency =
    !isNil(transactionAmount) && transactionAmount?.currency !== 'EUR';

  const isRefundType = type === TransactionType.Refund;

  const { color, textDecoration } = getAmountTextStyle(status, type);

  return (
    <Grid
      template={templateAreas}
      alignContent="center"
      alignItems="center"
      fontSize="small"
      autoColumns="1fr"
    >
      <Box gridArea="logo">
        {merchantLogoUrl && (
          <Avatar name={merchantName} img={merchantLogoUrl} size="xsmall" />
        )}
        {!merchantLogoUrl && category && (
          <Flex alignItems="center" justifyContent="start">
            <Image
              src={mappedCategoriesIcons[category]}
              alt=""
              width="space20"
            />
          </Flex>
        )}
      </Box>
      <Flex gridArea="merchantInfo" alignItems="center" gap="space2">
        {merchantLegalName && (
          <TruncatedText
            maxWidth="20ch"
            overflowWrap="anywhere"
            whiteSpace="normal"
            color="gray800"
            fontWeight="semibold"
          >
            {merchantLegalName}
          </TruncatedText>
        )}
        {merchantName && merchantName !== merchantLegalName && (
          <TruncatedText
            maxWidth="12ch"
            color="gray500"
            overflowWrap="anywhere"
            whiteSpace="normal"
            fontWeight="400"
          >
            {`(${merchantName})`}
          </TruncatedText>
        )}
      </Flex>
      {transactionCreatedAt && (
        <TruncatedText gridArea="date" wordBreak="break-word">
          {DateFormatters.compact(new Date(transactionCreatedAt))}
        </TruncatedText>
      )}
      {type && (
        <Tag
          className={styles['transaction-tag--type']}
          variant="secondary"
          color="gray"
          size="xsmall"
        >
          {t(`transactionDetailsCard.type.${type.toLocaleLowerCase()}`)}
        </Tag>
      )}
      {status && (
        <TransactionStatusTag
          className={styles['transaction-tag--status']}
          size="xsmall"
          status={status}
        />
      )}
      <Flex
        gridArea="amount"
        justifySelf="end"
        gap="space8"
        color={color}
        textDecoration={textDecoration}
      >
        {isTransactionPaidInForeignCurrency && (
          <AmountText
            value={transactionAmount}
            isRefundType={isRefundType}
            variant="mainCurrency"
          />
        )}
        {billingAmount && (
          <AmountText
            color="gray800"
            fontWeight="bold"
            value={billingAmount}
            isRefundType={isRefundType}
            variant={
              isTransactionPaidInForeignCurrency
                ? 'secondaryCurrency'
                : 'mainCurrency'
            }
          />
        )}
      </Flex>
    </Grid>
  );
};

export const TransactionDetailsDeprecated = ({
  transaction,
}: TransactionDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const {
    type,
    status,
    transactionCreatedAt,
    transactionAmount,
    billingAmount,
    merchantLegalName,
    merchantName,
    merchantLogoUrl,
    category,
  } = transaction || {};

  const isTransactionPaidInForeignCurrency =
    !isNil(transactionAmount) && transactionAmount?.currency !== 'EUR';

  const isRefundType = type === TransactionType.Refund;

  const { color, textDecoration } = getAmountTextStyle(status, type);

  return (
    <Flex direction="column">
      <Flex alignItems="center" justifyContent="space-between" gap="space8">
        <Flex color="gray700" gap="space12">
          {transactionCreatedAt && (
            <TruncatedText wordBreak="break-word">
              {DateFormatters.compactDatetime(new Date(transactionCreatedAt))}
            </TruncatedText>
          )}
        </Flex>
        <Flex gap="space4">
          {type ? (
            <Tag variant="secondary" color="gray">
              {t(`transactionDetailsCard.type.${type.toLocaleLowerCase()}`)}
            </Tag>
          ) : null}
          <TransactionStatusTag status={status as TransactionStatus} />
        </Flex>
      </Flex>
      <Flex
        fontSize="small"
        justifyContent="space-between"
        alignItems="center"
        fontWeight="semibold"
      >
        <Grid alignItems="center" templateColumns="auto 1fr" gap="space8">
          {merchantLogoUrl && (
            <Avatar name={merchantName} img={merchantLogoUrl} size="small" />
          )}
          {!merchantLogoUrl && category && (
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="full"
              border="1px solid gray200"
              padding="space2"
            >
              <Image src={mappedCategoriesIcons[category]} alt="" />
            </Flex>
          )}
          <Flex alignItems="center" gap="space2">
            {merchantLegalName && (
              <TruncatedText
                maxWidth="20ch"
                overflowWrap="anywhere"
                whiteSpace="normal"
              >
                {merchantLegalName}
              </TruncatedText>
            )}
            {merchantName && merchantName !== merchantLegalName && (
              <TruncatedText
                maxWidth="12ch"
                color="gray600"
                overflowWrap="anywhere"
                whiteSpace="normal"
              >
                {`(${merchantName})`}
              </TruncatedText>
            )}
          </Flex>
        </Grid>
        <Flex gap="space8" color={color} textDecoration={textDecoration}>
          {isTransactionPaidInForeignCurrency && (
            <AmountText
              value={transactionAmount}
              isRefundType={isRefundType}
              variant="mainCurrency"
            />
          )}
          {billingAmount && (
            <AmountText
              value={billingAmount}
              isRefundType={isRefundType}
              variant={
                isTransactionPaidInForeignCurrency
                  ? 'secondaryCurrency'
                  : 'mainCurrency'
              }
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

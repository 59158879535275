import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useActivateAccountingAreaMutation,
  useArchiveAccountingAreaMutation,
  useCreateAccountingAreaMutation,
  useUpdateAccountingAreaMutation,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { AccountingAreasDrawerProps } from 'views/Settings/AccountingAreas/elements/Drawer/AccountingAreasDrawer';
import { useGetAccountingAreaById } from 'views/Settings/AccountingAreas/toolkit/hooks/useGetAccountingAreaById';
import { useMapErrorMessage } from 'views/Settings/AccountingAreas/toolkit/hooks/useMapErrorMessages';

const queriesToRefetch = ['accountingAreas', 'countAccountingAreas'];

export const useMutateAccountingAreas = () => {
  const { success } = useToastMessage();

  const { mapErrorMessages } = useMapErrorMessage();

  const match = useMatch(
    `:organizationSlug${Routes.SETTINGS}${Routes.ACCOUNTING_AREAS}/:id`
  );

  const accountingAreaId = match?.params.id;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const closeDrawer = () => {
    navigate(
      {
        pathname: '..',
        search: searchParams.toString(),
      },
      {
        relative: 'path',
      }
    );
  };

  const isDrawerOpen = match !== null;

  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { accountingArea, accountingAreaLoading } = useGetAccountingAreaById({
    id: accountingAreaId,
  });

  const [createAccountingArea, { loading: creatingAccountingArea }] =
    useCreateAccountingAreaMutation({});

  const [updateAccountingArea, { loading: updatingAccountingArea }] =
    useUpdateAccountingAreaMutation();

  const [archiveAccountingArea, { loading: archivingAccountingArea }] =
    useArchiveAccountingAreaMutation();

  const [activateAccountingArea, { loading: activatingAccountingArea }] =
    useActivateAccountingAreaMutation();

  const handleCreate = async (values: {
    name: string;
    description: string;
  }) => {
    const result = await createAccountingArea({
      variables: {
        input: {
          name: values.name,
          description: values.description,
        },
      },
      refetchQueries: queriesToRefetch,
    });

    if (
      result.data?.createAccountingArea.__typename === 'AccountingAreaSuccess'
    ) {
      success(
        t('accountingAreas.drawer.form.actions.submit.toastMessage.success')
      );
    } else if (
      result.errors ||
      result.data?.createAccountingArea.__typename === 'AccountingAreaError'
    ) {
      mapErrorMessages({
        errorKind:
          result.data?.createAccountingArea.__typename === 'AccountingAreaError'
            ? result.data?.createAccountingArea.kind
            : undefined,
      });
    }

    closeDrawer();
  };

  const handleUpdate = async (values: {
    name: string;
    description: string;
  }) => {
    const result = await updateAccountingArea({
      variables: {
        input: {
          id: accountingAreaId ?? '',
          name: values.name,
          description: values.description,
        },
      },
      refetchQueries: queriesToRefetch,
    });

    if (
      result.data?.updateAccountingArea.__typename === 'AccountingAreaSuccess'
    ) {
      success(
        t('accountingAreas.drawer.form.actions.submit.toastMessage.success')
      );
    } else if (
      result.errors ||
      result.data?.updateAccountingArea.__typename === 'AccountingAreaError'
    ) {
      mapErrorMessages({
        errorKind:
          result.data?.updateAccountingArea.__typename === 'AccountingAreaError'
            ? result.data?.updateAccountingArea.kind
            : undefined,
      });
    }

    closeDrawer();
  };

  const handleSubmit: AccountingAreasDrawerProps['onSubmit'] = async values => {
    if (accountingAreaId && accountingAreaId !== 'create') {
      await handleUpdate(values);
    } else {
      await handleCreate(values);
    }
  };

  const handleArchive = async () => {
    const result = await archiveAccountingArea({
      variables: {
        id: accountingAreaId ?? '',
      },
      refetchQueries: queriesToRefetch,
    });

    const hasSuccess =
      result.data?.archiveAccountingArea.__typename === 'AccountingAreaSuccess';

    const hasError =
      result.errors ||
      result.data?.archiveAccountingArea.__typename === 'AccountingAreaError';

    if (hasSuccess) {
      success(
        t('accountingAreas.drawer.form.actions.archive.toastMessage.success')
      );
    } else if (hasError) {
      mapErrorMessages({
        errorKind:
          result.data?.archiveAccountingArea.__typename ===
          'AccountingAreaError'
            ? result.data?.archiveAccountingArea.kind
            : undefined,
      });
    }

    closeDrawer();
  };

  const handleActivate = async () => {
    const result = await activateAccountingArea({
      variables: {
        id: accountingAreaId ?? '',
      },
      refetchQueries: queriesToRefetch,
    });

    if (
      result.data?.activateAccountingArea.__typename === 'AccountingAreaSuccess'
    ) {
      success(
        t('accountingAreas.drawer.form.actions.activate.toastMessage.success')
      );
    } else if (
      result.errors ||
      result.data?.activateAccountingArea.__typename === 'AccountingAreaError'
    ) {
      mapErrorMessages({
        errorKind:
          result.data?.activateAccountingArea.__typename ===
          'AccountingAreaError'
            ? result.data?.activateAccountingArea.kind
            : undefined,
      });
    }

    closeDrawer();
  };

  return {
    accountingArea,
    accountingAreaLoading,
    creatingAccountingArea,
    updatingAccountingArea,
    archivingAccountingArea,
    activatingAccountingArea,
    handleArchive,
    handleActivate,
    handleSubmit,
    onCloseDrawer: closeDrawer,
    accountingAreaId,
    isDrawerOpen,
  };
};

import { ReactNode } from 'react';
import { LottieAnimation } from '../../../../Atoms/LottieAnimation';

export interface EmptyStateLayoutProps {
  /** Whether text we want to show in the emtpy state*/
  children?: ReactNode;
}

export const EmptyStateLayout = ({ children }: EmptyStateLayoutProps) => {
  return (
    <div className="grid text-gray-800 min-h-full justify-items-center gap-4 items-center">
      <LottieAnimation animationData={'nocontactData'} />
      <div className="max-w-64 items-center flex flex-col">{children}</div>
    </div>
  );
};

import {
  Cell,
  Filter,
  FilterOptionsAccessor,
  Flex,
  Grid,
  Table,
  Text,
  TruncatedText,
  useTheme,
} from '@candisio/design-system';
import { CheckmarkIcon, ErrorIcon } from 'components/Icons/DefaultIcons';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, TableState } from 'react-table';
import { sortByStringProp } from 'utils/sorting';
import { useMemoizedState } from 'utils/useMemoizedState';
import { PaymentConditionCell } from 'views/Settings/PaymentConditions/components/PaymentConditionCell';
import { Header } from './components/Header';
import { GeneralLedgerAccountsName } from './components/cells/GeneralLedgerAccountsName';
import { GeneralLedgerAccountsNumber } from './components/cells/GeneralLedgerAccountsNumber';
import { PaymentConditionsStatus } from './components/cells/PaymentConditionsStatus.tsx';
import { filterByObject, filterByValue } from './filters';
import { ImportPreviewTableData } from './types';

export interface ImportPreviewTableProps {
  columns: Array<keyof ImportPreviewTableData>;
  data: Array<ImportPreviewTableData>;
  defaultFilters?: TableState<ImportPreviewTableData>['filters'];
  filterOptions?: FilterOptionsAccessor<ImportPreviewTableData>;
  isLoading?: boolean;
}

export const ImportPreviewTable = ({
  columns: columnsProp,
  data,
  defaultFilters,
  filterOptions,
  isLoading,
}: ImportPreviewTableProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const shownColumns = useMemoizedState(columnsProp);
  const { space } = useTheme();

  const defaultColumn = useMemo(
    (): Partial<Column<ImportPreviewTableData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
      /** @ts-ignore TODO: React upgrade props types mismatch */
      Cell,
      Filter: filterOptions
        ? ({ column, handleUpdateIsFilterBeingUsed }) => {
            const filterOption =
              filterOptions[column.id as keyof ImportPreviewTableData];

            if (typeof filterOption === 'object' && 'data' in filterOption) {
              const isLoading = filterOption.isLoading;

              return (
                <Filter<ImportPreviewTableData>
                  column={column}
                  options={filterOption.data}
                  isLoading={isLoading}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                  applyFilterButton={t(
                    'importPreviewTable.filterPopover.applyFilterButton'
                  )}
                  resetFilterButton={t(
                    'importPreviewTable.filterPopover.resetFilterButton'
                  )}
                  searchFieldPlaceholder={t(
                    'importPreviewTable.filterPopover.searchFieldPlaceholder'
                  )}
                  filterLabel={t(
                    'importPreviewTable.filterPopover.filterLabel'
                  )}
                />
              );
            }

            return null;
          }
        : undefined,
      disableFilters: isNil(filterOptions),
      filter: filterByValue,
    }),
    [filterOptions, t]
  );

  const columns: Array<Column<ImportPreviewTableData>> = useMemo(() => {
    const allColumns: Array<Column<ImportPreviewTableData>> = [
      {
        accessor: 'code',
        Cell: row => {
          if (!row.row.original.code) {
            return <Text>{t('documents-table:emptyCellPlaceholder')}</Text>;
          }

          if (row.row.original.isDuplicate) {
            return (
              <Grid gap="space8" templateColumns="auto 1fr" alignItems="center">
                <ErrorIcon size="space16" />
                <TruncatedText>{row.row.original.code}</TruncatedText>
              </Grid>
            );
          }

          return (
            <Grid gap="space8" templateColumns="auto 1fr" alignItems="center">
              <CheckmarkIcon size="space16" />
              <TruncatedText>{row.row.original.code}</TruncatedText>
            </Grid>
          );
        },
      },
      {
        accessor: 'condition',
        /** @ts-expect-error TODO: React upgrade props types mismatch */
        Cell: PaymentConditionCell,
        disableSortBy: true,
      },
      {
        accessor: 'description',
        Cell,
        disableSortBy: true,
      },
      {
        accessor: 'generalLedgerAccountsName',
        Cell: GeneralLedgerAccountsName,
        filter: filterByObject,
        sortType: sortByStringProp('values.generalLedgerAccountsName.value'),
      },
      {
        accessor: 'generalLedgerAccountsNumber',
        Cell: GeneralLedgerAccountsNumber,
        filter: filterByObject,
        sortType: sortByStringProp('values.generalLedgerAccountsNumber.value'),
      },
      {
        accessor: 'name',
        Cell,
        width: '70%',
      },
      {
        accessor: 'number',
        Cell,
        disableSortBy: true,
      },
      {
        accessor: 'status',
        Cell: PaymentConditionsStatus,
        disableSortBy: true,
        Header: '',
        width: space.space20,
      },
      {
        accessor: 'taxCode',
        Cell,
        disableSortBy: true,
      },
    ];

    if (shownColumns && shownColumns.length > 0) {
      return shownColumns.reduce<Array<Column<ImportPreviewTableData>>>(
        (result, columnId) => {
          const column = allColumns.find(col => col.accessor === columnId);
          if (column) {
            result.push(column);
          }

          return result;
        },
        []
      );
    }

    return allColumns;
  }, [shownColumns, t, space]);

  return (
    <Flex height="100%" padding="0 space32 space32">
      <Table<ImportPreviewTableData>
        height="100%"
        data={data}
        defaultColumn={defaultColumn}
        columns={columns}
        initialState={{
          filters: defaultFilters ?? [],
        }}
        isLoading={isLoading}
        filterOptions={filterOptions}
      />
    </Flex>
  );
};

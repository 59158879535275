import {
  Flex,
  Grid,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { CardRequestType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { NotificationProps } from '../getNotificationInfo';

const translations: { [key in CardRequestType]?: { message: string } } = {
  ChangeCardLimitRequest: {
    message: 'events.cardRequestRejected.rejection.limit',
  },
  IssueNewPhysicalCardRequest: {
    message: 'events.cardRequestRejected.rejection.physicalCard',
  },
  IssueNewSingleUseVirtualCardRequest: {
    message: 'events.cardRequestRejected.rejection.singleUseCard',
  },
  IssueNewVirtualCardRequest: {
    message: 'events.cardRequestRejected.rejection.virtualCard',
  },
};

export const CardRequestRejectedInAppNotification = (
  props: NotificationProps<'CardRequestRejectedInAppNotification'>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);
  const {
    cardRequestType,
    cardRequestStatusChangedAt,
    cardManager,
    cardRefNum,
    resolutionComment,
  } = props;

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  const cardManagerName = cardManager.name;
  const translation = translations[cardRequestType];

  return (
    <Grid templateRows="auto 1fr auto" gap="space6" height="100%">
      <Grid autoFlow="column" alignItems="start" justifyContent="start">
        <Flex wrap="wrap" fontSize="small" gap="space4">
          <Trans
            i18nKey="notifications:events.cardRequestRejected.notification"
            values={{
              cardManagerName,
              timeAgo: DateFormatters.fromNow(
                new Date(cardRequestStatusChangedAt)
              ),
            }}
          >
            {/* @ts-expect-error we have to surpress typescript here because TruncatedText expects a string */}
            <TruncatedText color="gray500" maxWidth="15ch">
              {{ cardManagerName }}
            </TruncatedText>
            <Text color="gray450" {...triggerProps} ref={triggerRef}>
              3 minutes ago
            </Text>
          </Trans>
        </Flex>
        {isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {DateFormatters.compactDatetime(
              new Date(cardRequestStatusChangedAt)
            )}
          </Tooltip>
        )}
      </Grid>
      {translation && (
        <TruncatedText color="gray800" fontSize="basic" fontWeight="400">
          {t(translation.message, { cardRefNum: cardRefNum })}
        </TruncatedText>
      )}
      {resolutionComment ? (
        <TruncatedText fontSize="small" color="gray450">
          {resolutionComment}
        </TruncatedText>
      ) : null}
    </Grid>
  );
};

export const CardRequestRejectedInAppNotificationToast = (
  props: NotificationProps<'CardRequestRejectedInAppNotification'>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);
  const { cardRequestType, cardManager, cardRefNum } = props;

  const requesterName = cardManager.name;

  const translation = translations[cardRequestType];

  return (
    <Grid templateRows="auto auto" gap="space6" height="100%">
      <TruncatedText color="gray800" fontSize="small">
        {requesterName}
      </TruncatedText>
      {translation && (
        <TruncatedText color="gray800" fontSize="basic">
          {t(translation.message, { cardRefNum: cardRefNum })}
        </TruncatedText>
      )}
    </Grid>
  );
};

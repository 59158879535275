import { CellContext } from '@tanstack/react-table';
import { HookFormTextInput } from 'components/HookFormInputs/HookFormTextInput';
import { TableFormRow } from '../../schema/bookingsFormSchema';

type Value = TableFormRow['note'];

// TODO pass readOnly prop vias fieldOptions
export const NoteCell: React.FC<CellContext<TableFormRow, Value>> = ({
  row: { index },
}) => {
  return (
    <HookFormTextInput
      name={`bookings.${index}.note`}
      dataCy={`bookings.${index}.note`}
    />
  );
};

import { Card, Flex, Grid, Skeleton, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const CardToLinkDetailsLoading = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Card background="gray200">
      <Grid gap="space4">
        <Text fontSize="basic" fontWeight="semibold">
          {t('recurringPaymentsModal.linkCard.cardLimits')}
        </Text>
        <Flex direction="column" gap="space8" width="256px">
          <Flex gap="space16">
            <Flex direction="column">
              <Text fontSize="small" color="gray800">
                {t('recurringPaymentsModal.linkCard.available')}
              </Text>
              <Skeleton height="space24" width="space24" />
            </Flex>
            <Flex direction="column">
              <Text fontSize="small" color="gray600">
                {t('recurringPaymentsModal.linkCard.limit')}
              </Text>
              <Skeleton height="space24" width="space24" />
            </Flex>
          </Flex>
          <Skeleton height="space12" width="space200" />
          <Text fontSize="basic" color="gray600">
            {t('recurringPaymentsModal.linkCard.text')}
          </Text>
        </Flex>
      </Grid>
    </Card>
  );
};

import { motion } from 'motion/react';
import { ReactNode, SyntheticEvent } from 'react';

export const fadeFormInfos = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

export const slidePreview = {
  visible: { x: 0 },
  slideLeft: { x: -200, transition: { duration: 1 } },
};

interface FadeAnimationProps {
  executeAnimation: boolean;
  children: ReactNode;
}

export const FadeAnimation = ({
  executeAnimation,
  children,
}: FadeAnimationProps) => {
  return (
    <motion.div
      initial={fadeFormInfos.visible}
      animate={executeAnimation && fadeFormInfos.hidden}
    >
      {children}
    </motion.div>
  );
};

interface SlideAnimationProps {
  executeAnimation: boolean;
  handleSubmit: (
    event?: Partial<Pick<SyntheticEvent, 'preventDefault' | 'stopPropagation'>>
  ) => Promise<object | undefined> | undefined;
  children: ReactNode;
}

export const SlideAnimation = ({
  executeAnimation,
  handleSubmit,
  children,
}: SlideAnimationProps) => {
  return (
    <motion.div
      style={{ paddingTop: '44px' }}
      initial={slidePreview.visible}
      animate={executeAnimation && slidePreview.slideLeft}
      onAnimationComplete={async () => await handleSubmit()}
    >
      {children}
    </motion.div>
  );
};

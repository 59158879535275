import { useDeleteAttachmentsMutation } from 'generated-types/graphql.types';
import { Attachment as AttachmentEntity } from 'hooks/useAttachments/useAttachments';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { RouteParams } from 'views/Inbox/Context';
import { documentHistoryQuery } from 'views/queries';
import { documentQueries } from '../../queries';
import { Attachment } from '../AttachmentItem';

const useDeleteAttachments = (documentId: string) =>
  useDeleteAttachmentsMutation({
    refetchQueries: [
      {
        query: documentQueries.forDraftForm,
        variables: { id: documentId },
      },
      {
        query: documentHistoryQuery,
        variables: { id: documentId },
      },
    ],
  });

export const useDeleteAction = (
  item:
    | Pick<Attachment, 'originalDocumentId' | 'id' | 'label'>
    | Pick<AttachmentEntity, 'id' | 'originalDocumentId' | 'name'>,
  setIsDeletePending: (arg: boolean) => void
) => {
  const { documentId } = useParams<RouteParams>();
  const [deleteAttachments] = useDeleteAttachments(documentId);

  const handleDelete = async () => {
    setIsDeletePending(true);

    await deleteAttachments({
      variables: {
        input: {
          documentId,
          attachmentIds: [item.id],
        },
      },
    });

    setIsDeletePending(false);
  };

  return handleDelete;
};

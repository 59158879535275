import { Box, Button, Card, Flex, Text } from '@candisio/design-system';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { scale } from '../RecurringPaymentsList/CollapsibleHeader';

interface CollapsibleHeaderProps {
  showVendors: boolean;
  onClick: (card: 'vendorSuggestions' | 'recurringPayments') => void;
  convertedVendors?: number;
  vendorsToConvert?: number;
}

const MotionCard = motion.create(Card);

export const CollapsibleHeader = ({
  onClick,
  showVendors,
  convertedVendors,
  vendorsToConvert,
}: CollapsibleHeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const totalVendors = (convertedVendors ?? 0) + (vendorsToConvert ?? 0);

  const handleClick = () => {
    if (!convertedVendors) {
      return;
    }

    onClick('vendorSuggestions');
  };

  return (
    <MotionCard
      whileHover={convertedVendors ? scale : undefined}
      background="gray200"
      borderBottomRadius={showVendors ? 'none' : 'medium'}
      style={{ cursor: convertedVendors ? 'pointer' : 'default' }}
      onClick={handleClick}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="basic" fontWeight="bold">
          {t(
            'insights.recurringPaymentsWidget.vendorListSuggestion.vendorsToConvert',
            {
              count: vendorsToConvert,
            }
          )}
        </Text>
        <Flex alignItems="center" gap="space8">
          <Flex alignItems="center" gap="space8">
            <Text fontSize="basic">
              {t(
                'insights.recurringPaymentsWidget.vendorListSuggestion.progress'
              )}
            </Text>
            <Box
              width="100px"
              background="gray250"
              borderRadius="medium"
              height="space8"
            >
              <Box
                maxWidth="100%"
                borderRadius="medium"
                height="100%"
                width={`${((convertedVendors ?? 0) / totalVendors) * 100}%`}
                background="blue300"
              />
            </Box>
            <Text>
              {t(
                'insights.recurringPaymentsWidget.vendorListSuggestion.progressInNumber',
                {
                  firstCount: convertedVendors ?? 0,
                  secondCount: totalVendors,
                }
              )}
            </Text>
          </Flex>
          {convertedVendors ? (
            <Button
              icon={showVendors ? 'caretUp' : 'caretDown'}
              size="xsmall"
              variant="tertiary"
            />
          ) : null}
        </Flex>
      </Flex>
    </MotionCard>
  );
};

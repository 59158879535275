import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import { DefaultValues, FieldPath, UseFormReturn, get } from 'react-hook-form';
import { ProcessingFormMetadata } from './ProcessingFormMetadataContext';
import { useSetProcessingFormMetadata } from './ProcessingFormSetMetadataContext';
import { useSyncFormDefaultValuesWithPurchaseOrder } from './hooks/useSyncFormDefaultValuesWithPurchaseOrder';
import { ProcessingFormValues } from './processingFormSchema';

interface UseSyncProcessingFormDefaultValuesOptions {
  /** Latest processing form default metadata */
  defaultMetadata?: ProcessingFormMetadata;
  /** Latest processing form default values */
  defaultValues?: DefaultValues<ProcessingFormValues>;
  /** Processing form instance */
  form: UseFormReturn<ProcessingFormValues>;
  readOnly?: boolean;
}

/**
 * Keeps processing form in sync with the latest default values and metadata
 *
 * If the default value for a given field changes, we reset the field using the
 * new default value, but only if the field is not dirty.
 * https://github.com/orgs/react-hook-form/discussions/8233
 *
 * We update the metadata for the field accordingly.
 *
 * (This behavior is analogous to the `keepDirtyOnReinitialize` option of React
 * Final Form, if you‘re familiar with that library.)
 */
export const useSyncProcessingFormDefaultValues = ({
  defaultMetadata,
  defaultValues,
  form,
  readOnly,
}: UseSyncProcessingFormDefaultValuesOptions) => {
  const setMetadata = useSetProcessingFormMetadata();

  const prevDefaultValues = useRef(defaultValues);

  useEffect(() => {
    // Don’t do anything if the default values prop hasn't changed. In theory
    // this should just be a performance optimization. But in practice it’s also
    // required to prevent React Hook Form -related bugs...
    if (isEqual(prevDefaultValues.current, defaultValues)) {
      return;
    }

    // Get the list of registered field paths. This is needed because of the
    // nested data structure of the form values, where some nodes correspond to
    // form fields and other nodes are nested values within fields.
    const registeredFieldPaths = Array.from(
      // We’re living dangerously here, accessing a private property of the form
      // instance. This should be Fine™, since TypeScript will still warn us if
      // this private API changes.
      form.control._names.mount
    ) as FieldPath<ProcessingFormValues>[];

    // For our purposes here, we consider the bookings field array to be dirty
    // if the number of bookings has changed
    const bookingsIsDirty =
      form.formState.defaultValues?.bookings?.length !==
      form.getValues('bookings').length;

    const pristineFields = registeredFieldPaths.filter(path => {
      if (bookingsIsDirty) {
        return !path.startsWith('bookings');
      }

      return !form.getFieldState(path)?.isDirty;
    });

    pristineFields.forEach(path => {
      const defaultValue = get(defaultValues, path) ?? null;
      const previousDefaultValue =
        get(form.formState.defaultValues, path) ?? null;

      if (!isEqual(previousDefaultValue, defaultValue)) {
        // If the default value has changed, reset it with the new default value
        // and update its metadata accordingly
        form.resetField(path, { defaultValue: defaultValue });
        setMetadata(path, get(defaultMetadata, path));
      }
    });
  });

  useEffect(() => {
    prevDefaultValues.current = defaultValues;
  });
  useSyncFormDefaultValuesWithPurchaseOrder({
    defaultValues,
    form,
    isReadOnly: readOnly,
  });
};

import {
  Grid,
  Icon,
  Spinner,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface AutoSaveStatusProps {
  isSavePending: boolean;
  showRecentStyle: boolean;
  timestamp?: string;
  onRefetch: () => void;
}

export const AutoSaveStatus = ({
  isSavePending,
  showRecentStyle,
  timestamp,
  onRefetch,
}: AutoSaveStatusProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      delay: 200,
      onOpenChange: isOpen => {
        if (isOpen) {
          onRefetch();
        }
      },
    });

  return (
    <Grid justifySelf="end">
      {isSavePending && (
        <Grid templateColumns="auto 1fr" gap="space2" alignItems="center">
          <Spinner size="space16" color="gray500" />
          <Text>
            {t(
              'reimbursementView.rightSection.footerActions.saveIndicator.saving'
            )}
          </Text>
        </Grid>
      )}
      {!isSavePending && timestamp && (
        <Grid
          cursor="default"
          gap="space2"
          templateColumns="auto 1fr"
          alignItems="center"
          ref={triggerRef}
          {...triggerProps}
        >
          <Icon
            icon="checkCircle"
            size="space16"
            color={showRecentStyle ? 'green700' : 'gray500'}
          />
          <Text color={showRecentStyle ? 'green700' : 'gray500'}>
            {t(
              'reimbursementView.rightSection.footerActions.saveIndicator.saved'
            )}
          </Text>
          {isOpen && (
            <Tooltip ref={tooltipRef} {...tooltipProps}>
              <Text fontSize="basic">
                {t(
                  'reimbursementView.rightSection.footerActions.saveIndicator.minute',
                  {
                    time: timestamp,
                  }
                )}
              </Text>
            </Tooltip>
          )}
        </Grid>
      )}
    </Grid>
  );
};

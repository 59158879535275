import { Button, Paragraph, Text, useModal } from '@candisio/design-system';
import { PromotionDetailsModalContent } from 'components/NewPromotions/PromotionCard/PromotionDetailsModalContent/PromotionDetailsModalContent';
import { PromotionLabel } from 'components/NewPromotions/PromotionCard/PromotionHeader/PromotionHeader';
import { PromotionModal } from 'components/NewPromotions/PromotionCard/PromotionModal/PromotionModal';
import { PromotionInfoPanel } from 'components/NewPromotions/PromotionInfoPanel/PromotionInfoPanel';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { UPSELL_MAX_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useGetCustomPromotionDetails } from '../hooks/useGetCustomPromotionDetails';

export const SSOPromotionInfoPanel = ({
  closePromotion,
}: { closePromotion: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);

  const { triggerProps, isOpen, close } = useModal();

  const {
    upsellMaxPromotion: { label, id },
  } = usePromotionContent();

  const customPromotionDetails = useGetCustomPromotionDetails(
    'upsellMax',
    UPSELL_MAX_UTM_SOURCE.SSO_TEAM_MEMBER
  );

  return (
    <PromotionInfoPanel
      imageUrl={t('ssoInfoPanel.imageUrl')}
      closePromotion={closePromotion}
    >
      <PromotionLabel label={label} />
      <Text fontSize="large" color="gray800">
        {t('ssoInfoPanel.title')}
      </Text>
      <Paragraph fontSize="small" color="gray700" lineHeight="space20">
        {t('ssoInfoPanel.description')}
      </Paragraph>
      <Button
        color="purple"
        variant="primary"
        width="space128"
        {...triggerProps}
      >
        {t('ssoInfoPanel.button')}
      </Button>

      {isOpen && (
        <PromotionModal
          isModalOpen={isOpen}
          closeModal={close}
          titleInfo={<PromotionLabel label={label} />}
          data-cy={`promotionModal-${id}`}
        >
          <PromotionDetailsModalContent
            promotionDetails={customPromotionDetails}
          />
        </PromotionModal>
      )}
    </PromotionInfoPanel>
  );
};

import { useEffect } from 'react';
import { useReimbursementFormsContext } from '../context/ReimbursementFormsContext';

export const useReimbursementUpdatePending = (isUpdatePending: boolean) => {
  const { handleIsReimbursementUpdatePending } = useReimbursementFormsContext();

  useEffect(() => {
    if (isUpdatePending) {
      handleIsReimbursementUpdatePending(true);
    }

    return () => {
      handleIsReimbursementUpdatePending(false);
    };
  }, [handleIsReimbursementUpdatePending, isUpdatePending]);
};

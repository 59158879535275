import {
  Cell,
  CustomEmptyStateProps,
  Filter,
  FilterOptionsAccessor,
  Flex,
  Table,
  TableProps,
} from '@candisio/design-system';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { DateRangeFilter } from 'components/Table/Filters/DateRangeFilter/DateRangeFilter';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useCardholderId } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Filters, Row, SortingRule, TableState } from 'react-table';
import { CreditCardsTableData } from 'views/CreditCards/types';
import { creditCardsColumnsDefinition } from 'views/CreditCards/utils/utils';
import { Header } from './Cells/Header';
import { CreditCardsTableToolbar } from './CreditCardsTableToolbar';

export interface CreditCardsTableProps {
  isLoadingConfigs: boolean;
  columns?: Array<keyof CreditCardsTableData>;
  data: CreditCardsTableData[];
  search: string;
  configurationsTable: Configuration[];
  onUpdateColumnsConfigurations: (configurations: Configuration[]) => void;
  onResetConfigurations: () => void;
  onSearch: (search: string) => void;
  defaultFilters?: TableState<CreditCardsTableData>['filters'];
  defaultSortBy?: TableState<CreditCardsTableData>['sortBy'];
  customEmptyState?: (props: CustomEmptyStateProps) => JSX.Element | null;
  filterOptions?: FilterOptionsAccessor<CreditCardsTableData>;
  loading?: boolean;
  onFilter?: (filters: Filters<CreditCardsTableData>) => void;
  onRowClick?: (row: Row<CreditCardsTableData>) => void;
  onSort?: (sortBy: SortingRule<CreditCardsTableData>[]) => void;
  onEndReached: (index: number) => void;
  minWidth?: TableProps<CreditCardsTableData>['minWidth'];
  width?: TableProps<CreditCardsTableData>['width'];
  cellWrapper?: TableProps<CreditCardsTableData>['cellWrapper'];
  getCellStyles?: TableProps<CreditCardsTableData>['getCellStyles'];
}

export const CreditCardsTable = ({
  isLoadingConfigs,
  columns: shownColumns,
  data,
  defaultFilters,
  defaultSortBy,
  minWidth,
  width,
  filterOptions,
  loading,
  search,
  configurationsTable,
  onUpdateColumnsConfigurations,
  onResetConfigurations,
  onSearch,
  customEmptyState,
  onFilter,
  onRowClick,
  onSort,
  onEndReached,
  cellWrapper,
  getCellStyles,
}: CreditCardsTableProps) => {
  const { cardholderId } = useCardholderId();
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { showAllExtraFeatures } = useCreditCardsSetup();

  const defaultColumn = useMemo(
    (): Partial<Column<CreditCardsTableData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
      /** @ts-ignore TODO: React upgrade props types mismatch */
      Cell,
      Filter: filterOptions
        ? ({ column, handleUpdateIsFilterBeingUsed }) => {
            let pinnedOption = undefined;
            const filterOption =
              filterOptions[column.id as keyof CreditCardsTableData];

            if (cardholderId && column.id.includes('cardholderName')) {
              pinnedOption = {
                id: cardholderId,
                label: t('cardManagerView.table.columns.filters.pinnedOption'),
              };
            }

            if (typeof filterOption === 'object' && 'data' in filterOption) {
              return (
                <Filter
                  column={column}
                  options={filterOption.data}
                  pinnedOption={pinnedOption}
                  filterLabel={t('documents-table:filterLabel')}
                  applyFilterButton={t('documents-table:filterApply')}
                  resetFilterButton={t('documents-table:filterReset')}
                  searchFieldPlaceholder={t(
                    'documents-table:filterSearchPlaceholder'
                  )}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            if (filterOption === true) {
              return (
                <DateRangeFilter
                  column={column}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            return null;
          }
        : undefined,
      disableFilters: !Boolean(filterOptions),
    }),
    [cardholderId, filterOptions, t]
  );

  const columns: Array<Column<CreditCardsTableData>> = useMemo(() => {
    if (shownColumns && shownColumns.length > 0) {
      return shownColumns.reduce<Array<Column<CreditCardsTableData>>>(
        (result, columnId) => {
          const column = creditCardsColumnsDefinition({
            t,
            showAllExtraCreditCardFeatures: showAllExtraFeatures,
          }).find(col => col.accessor === columnId);

          if (column) {
            result.push(column);
          }

          return result;
        },
        []
      );
    }

    return creditCardsColumnsDefinition({
      t,
      showAllExtraCreditCardFeatures: showAllExtraFeatures,
    });
  }, [shownColumns, t, showAllExtraFeatures]);

  return (
    <Flex height="100%" overflow="hidden" direction="column">
      <CreditCardsTableToolbar
        onSearch={onSearch}
        onUpdateColumnsConfigurations={onUpdateColumnsConfigurations}
        onResetConfigurations={onResetConfigurations}
        configurationsTable={configurationsTable}
        search={search}
        isLoadingConfigs={isLoadingConfigs}
      />

      <Table
        onRowClick={onRowClick}
        columns={columns}
        data={data}
        defaultColumn={defaultColumn}
        onFilter={onFilter}
        borderTopRadius="none"
        customEmptyState={customEmptyState}
        onSort={onSort}
        isLoading={loading}
        minWidth={minWidth}
        onEndReached={onEndReached}
        width={width}
        initialState={{
          filters: defaultFilters ?? [],
          sortBy: defaultSortBy ?? [],
        }}
        cellWrapper={cellWrapper}
        getCellStyles={getCellStyles}
      />
    </Flex>
  );
};

import { Grid, TabPanel, Tabs, useTabListState } from '@candisio/design-system';
import { TabItem } from '@candisio/design-system';
import { AriaTabListProps } from '@react-types/tabs';
import { useEffect, useState } from 'react';
import {
  WidgetModalTab,
  getPreferencesWidgetfromUrl,
} from 'views/InsightsWidgets/utils';
import { FadeAnimation } from './styles';

interface ExampleTabsPWidgetModalTabsProps extends AriaTabListProps<TabItem> {
  'aria-label': string;
  executeAnimation: boolean;
}

export const WidgetModalTabs = (props: ExampleTabsPWidgetModalTabsProps) => {
  const state = useTabListState(props);
  //remove question mark at the beginning from search string to avoid parsing it into the object
  const { landingTab } = getPreferencesWidgetfromUrl(
    window.location.search.substring(1)
  );

  const [applyLandingTabFromURL, setApplyLandingTabFromURL] = useState(true);

  useEffect(() => {
    if (landingTab === WidgetModalTab.BUDGET && applyLandingTabFromURL) {
      state.setSelectedKey(WidgetModalTab.BUDGET);
      setApplyLandingTabFromURL(false);
    }
  }, [landingTab, state, applyLandingTabFromURL]);

  return (
    <FadeAnimation executeAnimation={props.executeAnimation}>
      <Grid gap="space32">
        <Tabs {...props} state={state} />

        <TabPanel key={state.selectedItem?.key} state={state} />
      </Grid>
    </FadeAnimation>
  );
};

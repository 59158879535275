import { Box, Tag } from '@candisio/design-system';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';

const PdfLabel = ({
  children,
  isMain = false,
}: { children: ReactNode; isMain?: boolean }) => (
  <Box paddingLeft="space8" paddingRight="space12">
    <Tag color={isMain ? 'gray' : 'blue'} variant="secondary">
      {children}
    </Tag>
  </Box>
);

export const usePdfLabel = (documentId: string | undefined) => {
  const [t] = useTranslation();

  const { isComparisonView, params } = useComparisonView();

  if (!isComparisonView) return null;

  const isMainDocument = params.documentId === documentId;
  const labelText = isMainDocument
    ? t('document.tabs.relationships.label.mainDocument')
    : t('document.tabs.relationships.label.relatedDocuments');

  return <PdfLabel isMain={isMainDocument}>{labelText}</PdfLabel>;
};

import { motion } from 'motion/react';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  delay?: number;
  enterAnimation?: boolean;
  exitAnimation?: boolean;
};

export const UploadHistoryListAnimatedItem = ({
  children,
  delay = 0,
  enterAnimation = true,
  exitAnimation = true,
}: Props) => {
  return (
    <motion.li
      initial={
        enterAnimation
          ? { height: 0, opacity: 0 }
          : { height: 'auto', opacity: 1 }
      }
      animate={{
        height: 'auto',
        opacity: 1,
        transition: { delay: delay * 0.1 },
      }}
      exit={exitAnimation ? { height: 0, opacity: 0 } : undefined}
      transition={{
        duration: 0.2,
        ease: 'easeInOut',
      }}
    >
      <div className="py-2">
        <motion.div
          initial={
            enterAnimation
              ? { opacity: 0, y: -8, scale: 0.9 }
              : {
                  opacity: 1,
                  y: 0,
                  scale: 1,
                }
          }
          animate={{
            opacity: 1,
            y: 0,
            scale: 1,
          }}
          exit={
            exitAnimation
              ? {
                  opacity: 0,
                  y: 8,
                  scale: 0.9,
                }
              : undefined
          }
          transition={{ duration: 0.15, ease: 'easeInOut' }}
        >
          {children}
        </motion.div>
      </div>
    </motion.li>
  );
};

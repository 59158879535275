import {
  Flex,
  Grid,
  InfoPanel,
  Item,
  Link,
  Text,
  Tooltip,
  TruncatedText,
  useTheme,
  useTooltip,
} from '@candisio/design-system';
import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import { useGetUserAbsence } from 'containers/absence/useGetUserAbsence';
import { useOrganizationsByMembershipList } from 'containers/notifications/hooks/useOrganizationsByMembershipList';
import { useGetMembershipsQuery } from 'generated-types/graphql.types';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { Key, useMemo } from 'react';
import { FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isOnlyAccountant, isOnlyRequester } from 'utils/authorization';
import { SubstituteItem, SubstituteItemProps } from './SubstituteItem';
import { AbsenceFormOutput } from './absenceFormSchema';

type SubstituteItems = (SubstituteItemProps & {
  children: string;
  key: Key;
})[];

export interface SubstituteSelectorContainerProps<
  TFormValues extends FieldValues,
> {
  /** Form field name */
  name: HookFormComboBoxFieldProps<TFormValues>['name'];
  /** User id to not be included in substitutes list */
  userId: string | undefined;
  /** whether the field is disabled */
  readOnly?: boolean;
}

export const SubstituteSelectorContainer = <TFormValues extends FieldValues>({
  name,
  userId,
  readOnly,
}: SubstituteSelectorContainerProps<TFormValues>) => {
  const [t] = useTranslation();
  const { data } = useGetMembershipsQuery();
  const { getUserAbsence } = useGetUserAbsence();

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
    });

  const { space } = useTheme();

  const { trigger } = useFormContext<AbsenceFormOutput>();

  const selectedSubstitute = useWatch({
    name,
  });

  const selectedOrganization = useFullOrganization();

  const { hasMoreThanOneOrganization } = useOrganizationsByMembershipList();

  const fromDate = useWatch<AbsenceFormOutput, 'fromDate'>({
    name: 'fromDate',
  });

  const toDate = useWatch<AbsenceFormOutput, 'toDate'>({
    name: 'toDate',
  });

  const items: SubstituteItems = useMemo(() => {
    if (!Array.isArray(data?.organizationMemberships)) return [];

    return (data?.organizationMemberships ?? [])
      .slice()
      .sort((left, right) => {
        return left.name.localeCompare(right.name);
      })
      .flatMap(m => {
        if (m.id === userId) return [];

        return {
          ...m,
          key: m.id,
          children: m.name,
          getAbsence: getUserAbsence,
          fromDate,
          toDate,
        };
      });
  }, [data?.organizationMemberships, userId, getUserAbsence, fromDate, toDate]);

  const cannotApprove = useMemo(
    () =>
      data?.organizationMemberships.flatMap(m => {
        const cannotApprove = isOnlyRequester(m) || isOnlyAccountant(m);

        return cannotApprove ? m.id : [];
      }) ?? [],
    [data?.organizationMemberships]
  );

  const revalidateDatesIfSubstituteChanges = async (): Promise<void> => {
    await trigger('fromDate');
    await trigger('toDate');
  };

  if (items.length < 1) return null;

  return (
    <Grid gap="space4" ref={triggerRef} {...triggerProps}>
      <HookFormComboBoxField
        id={name}
        readOnly={readOnly}
        label={
          hasMoreThanOneOrganization ? (
            <Flex
              gap="space8"
              justifyContent="start"
              alignItems="center"
              lineHeight="heading"
            >
              <Flex gap="space4">
                <TruncatedText
                  wordBreak="break-word"
                  style={{ pointerEvents: 'all' }}
                >
                  {t(
                    'header.profile.tabs.update.absence.form.substitute.labelWithOrgInfo',
                    {
                      currentOrganizationName: selectedOrganization?.name,
                    }
                  )}
                </TruncatedText>
                <Text color="gray500">{t('inputs.optionalInBrackets')}</Text>
              </Flex>
            </Flex>
          ) : (
            <Flex
              gap="space8"
              justifyContent="start"
              alignItems="center"
              lineHeight="heading"
            >
              <Flex gap="space4">
                <Text>
                  {t(
                    'header.profile.tabs.update.absence.form.substitute.label'
                  )}
                </Text>
                <Text color="gray500">{t('inputs.optionalInBrackets')}</Text>
              </Flex>
            </Flex>
          )
        }
        name={name}
        allowsEmptyCollection={false}
        disabledKeys={cannotApprove}
        defaultItems={items}
        onChange={revalidateDatesIfSubstituteChanges}
      >
        {({ key, children, ...itemProps }: SubstituteItems[0]) => (
          <Item key={key} textValue={children}>
            <SubstituteItem {...itemProps} />
          </Item>
        )}
      </HookFormComboBoxField>
      {selectedSubstitute && hasMoreThanOneOrganization && (
        <InfoPanel
          style={{
            // TODO: FC-313 allow setting padding on InfoPanel from design system
            padding: space.space8,
          }}
          size="normal"
          variant="information"
          message={t('header.profile.tabs.update.absence.form.substitute.info')}
        />
      )}
      <Link
        fontSize="small"
        href={t(
          'common:header.profile.tabs.update.absence.form.substitute.helpCenterLink.url'
        )}
        external
      >
        {t(
          'common:header.profile.tabs.update.absence.form.substitute.helpCenterLink.text'
        )}
      </Link>
      {readOnly && isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Text>
            {t(
              'header.profile.tabs.update.absence.form.substitute.disabledLabel'
            )}
          </Text>
        </Tooltip>
      )}
    </Grid>
  );
};

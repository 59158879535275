import { Link } from '@candisio/design-system';
import { InfoPopupButton } from 'components/Icons/InfoPopupButton';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const TableHeaderWithTooltip = () => {
  const [t] = useTranslation();

  return (
    <div className="flex items-center gap-1">
      {t('settings.datev.connect.documentTypes.bookingAccount.title')}

      <InfoPopupButton
        message={
          <span>
            <Trans i18nKey="settings.datev.connect.documentTypes.bookingAccount.columnHeaderInfo">
              This only works with RDS 1.0 enabled.
              <Link
                external
                href={t(
                  'settings.datev.connect.documentTypes.bookingAccount.columnHeaderInfoLink'
                )}
              >
                Learn More
              </Link>
            </Trans>
          </span>
        }
      />
    </div>
  );
};

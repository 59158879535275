import {
  Booking,
  Organization,
  useDocumentWorkflowStatusQuery,
  useGetDocumentForDraftQuery,
  useOrganizationQuery,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useUserInActiveStep } from 'utils/isUserInActiveFirstWFStep';

export const useCanFirstApproverSplitBookings = (documentId: string) => {
  const currentUser = useCurrentUser();

  const { data } = useDocumentWorkflowStatusQuery({
    variables: { id: documentId },
  });

  const { data: draftData } = useGetDocumentForDraftQuery({
    variables: { id: documentId },
  });

  const { data: organizationData } = useOrganizationQuery({
    variables: { id: documentId },
  });

  const { isUserInActiveFirstWFStep } = useUserInActiveStep();

  const isUserFirstApprover = isUserInActiveFirstWFStep(
    data?.getDocument,
    currentUser?.id
  );

  const {
    hasCostCenters,
    hasCostObjects,
    hasActiveBookingKeys,
    hasActiveBookingAccounts,
  } = organizationData?.organization || ({} as Organization);

  const isNoneOfAccountingDataFieldsConfigured =
    !hasCostCenters &&
    !hasCostObjects &&
    !hasActiveBookingKeys &&
    !hasActiveBookingAccounts;

  const isSingleBooking = draftData?.getDocument?.bookings?.length === 1;

  const { costCenter, costObject, bookingKey, generalLedgerAccount } =
    draftData?.getDocument?.bookings?.[0] || ({} as Booking);

  const isAllAccountingDataFieldsFilled =
    (!hasCostCenters || !isNil(costCenter?.value.id)) &&
    (!hasCostObjects || !isNil(costObject?.value.id)) &&
    (!hasActiveBookingKeys || !isNil(bookingKey?.value.id)) &&
    (!hasActiveBookingAccounts || !isNil(generalLedgerAccount?.value.id));

  return Boolean(
    isUserFirstApprover &&
      isSingleBooking &&
      !isAllAccountingDataFieldsFilled &&
      !isNoneOfAccountingDataFieldsConfigured
  );
};

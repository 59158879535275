import { Button, ButtonProps, Grid } from '@candisio/design-system';

export const WideButton = ({ children, icon, style, ...rest }: ButtonProps) => (
  <Button
    variant="special"
    shape="squared"
    icon={icon}
    style={{
      height: '2.25rem',
      paddingInline: '.75rem',
      paddingBlock: '.5rem',
      columnGap: '.25rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem',
      ...style,
    }}
    {...rest}
  >
    <Grid
      justifyContent="space-between"
      alignItems="center"
      autoFlow="column"
      width="100%"
    >
      {children}
    </Grid>
  </Button>
);

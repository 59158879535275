import { AppRouteParams } from 'models';
import { GenerateTypesafePath, Paths } from './generateTypesafePath.types';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { generatePath } from 'react-router-dom-v5-compat';

export const generateTypesafePath = <Pathname extends Paths>(
  pathInfo: GenerateTypesafePath<Pathname>,
  organizationId: string
) => {
  const {
    params,
    pathname,
    search,
    hash = '',
  } = pathInfo as GenerateTypesafePath<Pathname> & { params: object };

  let path = `/:${AppRouteParams.organizationSlug}${pathname}`;

  if (hash) {
    path += hash.startsWith('#') ? hash : `#${hash}`;
  }

  let result = generatePath(path, {
    [AppRouteParams.organizationSlug]: organizationId,
    ...params,
  });
  if (typeof search === 'string') {
    result += search.startsWith('?') ? search : `?${search}`;
  } else if (search) {
    result += `?${qs.stringify(search)}`;
  }

  return result;
};

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { AmountCell } from '../BookingsTable/TableCells/AmountCell';
import { useSap } from 'orgConfig/sap';
import { TaxAmountCell } from '../BookingsTable/TableCells/TaxAmountCell';
import { NoteCell } from '../BookingsTable/TableCells/NoteCell';
import { NetAmountCell } from '../BookingsTable/TableCells/NetAmountCell';
import { TableFormRow } from '../schema/bookingsFormSchema';
import { GeneralLedgerAccountCell } from '../BookingsTable/TableCells/GeneralLedgerAccountCell';
import { BookingsFormProps } from '../types';
import { useSelectCellProps } from './useSelectCellProps';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { CostCenterCell } from '../BookingsTable/TableCells/CostCenterCell';
import { CostObjectCell } from '../BookingsTable/TableCells/CostObjectCell';
import { ProjectCodeCell } from '../BookingsTable/TableCells/ProjectCode';
import { TaxCodeCell } from '../BookingsTable/TableCells/TaxCodeCell';
import { DeleteActionCell } from '../BookingsTable/TableCells/DeleteActionCell';

/**
 *
 * @returns visible columns and default column
 * prepare dynamic cell data here and pass via ColumnDef meta, never define dynamic data in the cell itself or pass via props
 */

// TODO will add visibility state to the table for hidden columns
// TODO will add readOnly prop to cells similar to determineIsFieldEditable
export const columnHelper = createColumnHelper<TableFormRow>();

type UseColumnsProps = {
  initialValues: BookingsFormProps['initialValues'];
};

export const useColumns = ({ initialValues }: UseColumnsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);
  const { shouldUseSapNetAmount, shouldUseSapProjectCodes } = useSap();

  const { generalLedgerAccount, costCenter, costObject, projectCode, taxCode } =
    useSelectCellProps({
      defaultValues: initialValues,
    });

  const defaultColumn = useMemo(
    (): Partial<ColumnDef<TableFormRow>> => ({
      header: 'Column',
      cell: props => props.row.index,
      footer: props => props.column.id,
      enableColumnFilter: false,
      minSize: 0,
    }),
    []
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('amount', {
        cell: AmountCell,
        header: t('inputs.grossAmount.label'),
      }),
      ...(shouldUseSapNetAmount
        ? [
            columnHelper.accessor('taxAmount', {
              cell: TaxAmountCell,
              header: t('inputs.taxAmount.label'),
            }),
            columnHelper.accessor('netAmount', {
              cell: NetAmountCell,
              header: t('inputs.netAmount.label'),
            }),
          ]
        : []),
      // TODO column label is different in DATEV
      columnHelper.accessor('note', {
        cell: NoteCell,
        header: t('inputs.note.label'),
      }),
      columnHelper.accessor('taxCode', {
        cell: TaxCodeCell,
        meta: { options: taxCode, readOnly: false },
        header: t('inputs.bookingKey.label'),
      }),
      columnHelper.accessor('generalLedgerAccount', {
        cell: GeneralLedgerAccountCell,
        meta: { options: generalLedgerAccount, readOnly: false },
        header: t('inputs.generalLedger.label'),
        size: 200,
      }),
      columnHelper.accessor('costCenter', {
        cell: CostCenterCell,
        meta: { options: costCenter, readOnly: false },
        header: t('inputs.costCenter.label'),
      }),
      columnHelper.accessor('costObject', {
        cell: CostObjectCell,
        meta: { options: costObject, readOnly: false },
        header: t('inputs.costObject.label'),
      }),

      ...(shouldUseSapProjectCodes
        ? [
            columnHelper.accessor('projectCode', {
              cell: ProjectCodeCell,
              meta: { options: projectCode, readOnly: false },
              header: t('inputs.projectCode.label'),
            }),
          ]
        : []),
      columnHelper.display({
        id: 'actions',
        cell: DeleteActionCell,
        size: 0,
      }),
    ],
    [
      shouldUseSapNetAmount,
      shouldUseSapProjectCodes,
      generalLedgerAccount,
      costCenter,
      costObject,
      projectCode,
      taxCode,
      t,
    ]
  );

  return { columns, defaultColumn };
};
// invoice corection is not visible in sap

import { Flex } from '@candisio/design-system';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { useDateConverter } from 'hooks/useDateConverter';
import { noop } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from '../../../../../providers/LocaleProvider';
import { DateRangeProps } from '../../types';
import { readableDateFormat } from '../util';

const DEFAULT_DATE_FROM = moment('2015-01-01');
const DEFAULT_DATE_TO = moment('2099-12-31');

export const SelectionCustom = ({
  setReadableDateOption,
  invoiceDate,
}: DateRangeProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const { dateToDateString } = useDateConverter();

  const startOfCurrentMonth = moment(new Date())
    .startOf('month')
    .format(readableDateFormat);

  const endOfCurrentMonth = moment(new Date())
    .endOf('month')
    .format(readableDateFormat);

  const [dateFrom, dateTo] = invoiceDate
    ? invoiceDate.values.readable.split('-')
    : [startOfCurrentMonth, endOfCurrentMonth];

  const form = useForm({
    defaultValues: {
      dateFrom: dateToDateString(moment(dateFrom, readableDateFormat).toDate()),
      dateTo: dateToDateString(moment(dateTo, readableDateFormat).toDate()),
    },
  });

  const watchedValues = form.watch();
  const { dateFrom: watchedDateFrom, dateTo: watchedDateTo } = watchedValues;

  useEffect(() => {
    const readableDateFrom = watchedDateFrom
      ? moment(watchedDateFrom).format(readableDateFormat)
      : DEFAULT_DATE_FROM.format('DD.MM.YYYY');

    const readableDateTo = watchedDateTo
      ? moment(watchedDateTo).format(readableDateFormat)
      : DEFAULT_DATE_TO.format('DD.MM.YYYY');

    setReadableDateOption(`${readableDateFrom}-${readableDateTo}`);
  }, [watchedDateFrom, watchedDateTo, setReadableDateOption]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(() => noop())}>
        <Flex gap="space8">
          <HookFormDatePickerField
            label={t('dateRangePicker.inputs.dateFrom')}
            name="dateFrom"
            onChange={date => form.setValue('dateFrom', date || '')}
          />

          <HookFormDatePickerField
            label={t('dateRangePicker.inputs.dateTo')}
            name="dateTo"
            onChange={date => form.setValue('dateTo', date || '')}
          />
        </Flex>
      </form>
    </FormProvider>
  );
};

import { useApprovalReimbursementCasesCountQuery } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';

export const useApprovalReimbursementsCounter = () => {
  const user = useCurrentUser();
  const { data, loading } = useApprovalReimbursementCasesCountQuery();

  const { data: requestedByMe, loading: isRequestedByMeLoading } =
    useApprovalReimbursementCasesCountQuery({
      variables: { filters: { requesterIds: [user?.id as string] } },
      skip: !user?.id,
    });

  const { data: assignedToMe, loading: isAssignedToMeLoading } =
    useApprovalReimbursementCasesCountQuery({
      variables: { filters: { approversIds: [user?.id as string] } },
      skip: !user?.id,
    });

  const counters = {
    approvalsRequestedByCurrentUserCount: requestedByMe
      ?.approvalReimbursementCasesCount.totalCount
      ? requestedByMe?.approvalReimbursementCasesCount.totalCount.toString()
      : undefined,
    approvalsAssignedToCurrentUserCount: assignedToMe
      ?.approvalReimbursementCasesCount.totalCount
      ? assignedToMe?.approvalReimbursementCasesCount.totalCount.toString()
      : undefined,
    allReimbursementsCount: data?.approvalReimbursementCasesCount.totalCount
      ? data?.approvalReimbursementCasesCount.totalCount.toString()
      : undefined,
  };
  return {
    loading: loading || isRequestedByMeLoading || isAssignedToMeLoading,
    approvalsRequestedByCurrentUserCount:
      counters.approvalsRequestedByCurrentUserCount,
    approvalsAssignedToCurrentUserCount:
      counters.approvalsAssignedToCurrentUserCount,
    allReimbursementsCount: counters.allReimbursementsCount,
  };
};

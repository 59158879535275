import { DocumentsTableData } from 'components/DocumentsTable/types';
import { queryParameter } from 'components/Table/consts';
import { Query } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { debounce } from 'lodash';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInboxDocumentsQuery } from 'views/queries';
import { mapToDocumentFilterInput } from 'views/utils/mapToDocumentFilterInput';
import { mapToDocumentSortInput } from 'views/utils/mapToDocumentSortInput';
import { mapToDocumentsTableData } from 'views/utils/mapToDocumentsTableData';
import { getApproximateNumberOfRowForTable } from 'views/utils/pagination-helper';
import { useIncludePurchaseOrderData } from 'views/utils/useShouldShowField';
import { availableFilters } from '../util';

/**
 * @deprecated
 */
export const useInboxDocumentsDataDeprecated = () => {
  const [t] = useTranslation();
  const pageSize = getApproximateNumberOfRowForTable();

  const { searchParams } = useMutateSearchParams();

  const searchQuery = searchParams.get(queryParameter) ?? '';
  const [debounceSearchValue, setDebounceSearchValue] = useState(searchQuery);

  const { filters, onFilter, onSort, sortBy } =
    useUrlBasedSortAndFilter<DocumentsTableData>({ availableFilters });

  const gqlFilters = mapToDocumentFilterInput(filters);
  const gqlSort = mapToDocumentSortInput(sortBy);

  const includePurchaseOrderData = useIncludePurchaseOrderData();

  const {
    data: { listInboxDocuments = {} } = {},
    loading: paginatedLoadingState,
    onLoadMore,
  } = usePagination<Pick<Query, 'listInboxDocuments'>>(
    getInboxDocumentsQuery,
    'listInboxDocuments',
    {
      variables: {
        limit: pageSize,
        filters: gqlFilters,
        ...(gqlSort ? { sort: gqlSort } : {}),
        query: debounceSearchValue,
        withPurchaseOrderData: includePurchaseOrderData,
      },
    }
  );

  const documentEdges = listInboxDocuments.edges ?? [];
  const documentsCount = listInboxDocuments.pageInfo?.totalCount;
  const isTableEmpty = documentEdges.length === 0 && !paginatedLoadingState;
  const hasMoreData = listInboxDocuments.pageInfo?.hasNextPage ?? false;
  const endCursor = listInboxDocuments?.pageInfo?.endCursor;
  const documentsTableData = mapToDocumentsTableData(documentEdges, t);
  const isTableFiltered = filters.length > 0;

  const handleDebounceSearch = useMemo(() => {
    return debounce(setDebounceSearchValue, DEFAULT_DEBOUNCE_TIME, {
      trailing: true,
    });
  }, []);

  useEffect(() => {
    return () => {
      handleDebounceSearch.cancel();
    };
  }, [handleDebounceSearch]);

  return {
    loading: paginatedLoadingState,
    onLoadMore,
    endCursor,
    isTableEmpty,
    hasMoreData,
    documentsCount,
    documentsTableData,
    filters,
    sortBy,
    onFilter,
    onSort,
    documentEdges,
    isTableFiltered,
    handleDebounceSearch,
    searchQuery,
  };
};

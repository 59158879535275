import {
  Button,
  Grid,
  Link,
  Switch,
  Text,
  useOverlayTriggerState,
} from '@candisio/design-system';
import {
  LedgerState,
  useDatevClientInfoQuery,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { motion } from 'motion/react';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import {
  useFullOrganization,
  useOrganizationId,
} from 'providers/OrganizationProvider';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { SendEmailModal } from '../../../components/SendEmailModal';
import { DcsContainer } from '../../DcsContainers/DcsContainer/DcsContainer';
import {
  DATEV_DOCUMENT_TYPES,
  DETAILS_MAX_WIDTH,
  delayForLoadingInMillSec,
} from '../../consts';
import { EXPORT_TYPE_IMAGES } from '../../images';
import { DatevClient } from '../../types';
import { getChecks } from '../states';
import { CheckProps } from '../types';
import {
  checkRequirement,
  computeModalProperties,
  evaluateToggle,
} from '../utils';
import { InfoList } from './InfoList';

type CashLedgerProps = {
  cashLedgerDocTypesMissingConfig: boolean;
  client: DatevClient;
  onDisabled: () => void;
  onEnabled: () => void;
  language: string;
  setIsCashLedgerSwitchTriggered: Dispatch<SetStateAction<boolean>>;
  clientIsConnectedToDXSO: boolean;
  isCreditCardActivated: boolean;
  creditCardMissingBookingAccount: boolean;
};

export const CashLedger = ({
  cashLedgerDocTypesMissingConfig,
  client,
  onDisabled,
  onEnabled,
  language,
  setIsCashLedgerSwitchTriggered,
  clientIsConnectedToDXSO,
  isCreditCardActivated,
  creditCardMissingBookingAccount,
}: CashLedgerProps) => {
  const isCashLedgerConfigured = !!client.isDxsoCashLedgerConfigured;

  const [t] = useTranslation();
  const currentUser = useCurrentUser();
  const org = useFullOrganization();
  const { isOpen, open, close } = useOverlayTriggerState({});
  const [showChecks, setShowChecks] = useState(false);
  const [showSuccess, setShowSuccess] = useState(
    isCreditCardActivated
      ? isCashLedgerConfigured && !creditCardMissingBookingAccount
      : isCashLedgerConfigured && !cashLedgerDocTypesMissingConfig
  );

  const [isToggleActive, setIsToggleActive] = useState(isCashLedgerConfigured);
  const [isLoading, setIsLoading] = useState(false);
  const [retries, setRetries] = useState(0);
  const organizationSlug = useOrganizationId();
  const history = useHistory();

  const [checks, setChecks] = useState<CheckProps>(
    showSuccess
      ? [getChecks(t).connected.success]
      : [
          getChecks(t).cash.loading,
          getChecks(t).cashAccountAdded.loading,
          ...(isCreditCardActivated
            ? [getChecks(t).creditCardAccountAdded.loading]
            : []),
        ]
  );

  const [optionalChecks, setOptionalChecks] = useState<CheckProps>([]);

  const {
    data,
    loading: datevClientInfoLoading,
    error,
    refetch: refetchDatevClientInfo,
  } = useDatevClientInfoQuery({
    skip: !showChecks,
    fetchPolicy: 'no-cache',
  });

  const onToggle = useCallback(
    (isActive: boolean) => {
      setOptionalChecks([]);
      setRetries(retries + 1);
      evaluateToggle({
        isActive,
        setIsToggleActive,
        setShowChecks,
        setShowSuccess,
        refetchDatevClientInfo,
        setIsLoading,
        delayForLoadingInMillSec: 3 * delayForLoadingInMillSec,
        onDisabled,
        setChecks,
      });
    },
    [retries, refetchDatevClientInfo, onDisabled]
  );

  useEffect(() => {
    setIsToggleActive(isCashLedgerConfigured);
    setIsCashLedgerSwitchTriggered(isCashLedgerConfigured);
    if (isCreditCardActivated) {
      creditCardMissingBookingAccount
        ? setShowChecks(isCashLedgerConfigured)
        : setShowSuccess(isCashLedgerConfigured);
    } else {
      cashLedgerDocTypesMissingConfig
        ? setShowChecks(isCashLedgerConfigured)
        : setShowSuccess(isCashLedgerConfigured);
    }
  }, [
    isCashLedgerConfigured,
    isCreditCardActivated,
    cashLedgerDocTypesMissingConfig,
    creditCardMissingBookingAccount,
    setIsCashLedgerSwitchTriggered,
  ]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isLoading) {
      setChecks([
        getChecks(t).cash.loading,
        getChecks(t).cashAccountAdded.loading,
        ...(isCreditCardActivated
          ? [getChecks(t).creditCardAccountAdded.loading]
          : []),
      ]);
    } else if ((data || error) && !datevClientInfoLoading) {
      const info = data?.datevClientInfo.ledgerState || {
        cash: LedgerState.NotFound,
      };

      const { cash } = info;
      setChecks([
        getChecks(t).cash[cash],
        cashLedgerDocTypesMissingConfig
          ? getChecks(t).cashAccountAdded.info
          : getChecks(t).cashAccountAdded.success,
        ...(isCreditCardActivated
          ? [
              creditCardMissingBookingAccount
                ? getChecks(t).creditCardAccountAdded.info
                : getChecks(t).creditCardAccountAdded.success,
            ]
          : []),
      ]);

      const allChecksDone = isCreditCardActivated
        ? cash === LedgerState.Found && !creditCardMissingBookingAccount
        : cash === LedgerState.Found && !cashLedgerDocTypesMissingConfig;

      checkRequirement({
        allChecksDone,
        shouldToggle: cash === LedgerState.Found,
        onEnabled,
        onDisabled,
        isToggleActive,
        setIsToggleActive,
        setShowChecks,
        setShowSuccess,
      });
    }
  }, [
    cashLedgerDocTypesMissingConfig,
    creditCardMissingBookingAccount,
    isCreditCardActivated,
    data,
    error,
    isLoading,
    isToggleActive,
    datevClientInfoLoading,
    t,
  ]);
  // If all the checks are met we show the actual result to the user so it doesn't disappear
  // and after 2 seconds we communicate the success message
  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setChecks([getChecks(t).connected.success]);
        setOptionalChecks([
          ...(showSuccess &&
          isCreditCardActivated &&
          cashLedgerDocTypesMissingConfig
            ? [getChecks(t).cashAccountAdded.info]
            : []),
        ]);
      }, 2000);
    }
  }, [showSuccess, isCreditCardActivated, cashLedgerDocTypesMissingConfig, t]);

  const showInfoList = showChecks || showSuccess;

  const { clientNumber, consultantNumber } = client;

  const modalProperties = computeModalProperties({
    t,
    checks,
    organizationName: org?.name,
    clientNumber,
    consultantNumber,
    currentUser,
  });

  const showSecondary = showChecks && !isLoading && !showSuccess;
  const datevCashLedgerConfigMissing =
    data?.datevClientInfo.ledgerState.cash !== LedgerState.Found;

  const showScrollTo =
    (showSecondary && cashLedgerDocTypesMissingConfig) ||
    optionalChecks.length > 0;

  const showGoTo =
    showSecondary && creditCardMissingBookingAccount && isCreditCardActivated;

  const showMailTo = showSecondary && datevCashLedgerConfigMissing;

  const keepOrDisposeLink = t(
    'settings.datev.connect.exportTypes.cashLedger.links.createOrRemoveCashLedger.url'
  );

  const sendEmailModalTitle = t(
    'settings.datev.connect.exportTypes.dxsoJobs.taxAdvisorEmail.modalTitle'
  );

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={
        clientIsConnectedToDXSO
          ? { y: 0, opacity: 1, height: 'auto' }
          : { y: '-100%', opacity: 0, height: 0 }
      }
    >
      <DcsContainer
        icon={EXPORT_TYPE_IMAGES.cashLedger[language]}
        iconAlt={t('settings.datev.imageAltTexts.cashLedger')}
        iconHeight="114.4px"
        iconWidth="100.8px"
        title={t('settings.datev.connect.exportTypes.cashLedger.title')}
        details={
          <Grid justifyItems="left" gap="space8">
            <Text maxWidth={DETAILS_MAX_WIDTH}>
              {t('settings.datev.connect.exportTypes.cashLedger.info')}
            </Text>
            <Link
              external
              href={t(
                'settings.datev.connect.exportTypes.cashLedger.linkToHelpCenter'
              )}
            >
              {t(
                'settings.datev.connect.exportTypes.cashLedger.linkToHelpCenterText'
              )}
            </Link>
            <Link external href={keepOrDisposeLink}>
              {t(
                'settings.datev.connect.exportTypes.cashLedger.links.createOrRemoveCashLedger.title'
              )}
            </Link>
            <Link
              external
              href={`/${organizationSlug}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`}
            >
              {t(
                'settings.datev.connect.exportTypes.cashLedger.crossLinkToCreditCards'
              )}
            </Link>
            {showInfoList && (
              <>
                <InfoList checks={checks} animate retries={retries} />
                <InfoList checks={optionalChecks} animate retries={retries} />
              </>
            )}
            {showMailTo ? (
              <Button
                variant="secondary"
                onClick={() => {
                  open();
                }}
              >
                {t('settings.datev.connect.exportTypes.cashLedger.sendEmail')}
              </Button>
            ) : (
              <Grid gap="space8">
                {showScrollTo && (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      const ele = document.getElementById(DATEV_DOCUMENT_TYPES);

                      const mainContent =
                        document.getElementById('main_content');

                      if (ele && mainContent) {
                        ele.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    {t(
                      'settings.datev.connect.exportTypes.cashLedger.scrollToDocTypes'
                    )}
                  </Button>
                )}
                {showGoTo && (
                  <Button
                    width="fit-content"
                    variant="secondary"
                    onClick={() =>
                      history.push(
                        `/${organizationSlug}${Routes.SETTINGS}${Routes.CREDIT_CARDS}`
                      )
                    }
                  >
                    {t(
                      'settings.datev.connect.exportTypes.cashLedger.goToCreditCardSettings'
                    )}
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        }
        actions={
          <Switch
            name={t('settings.datev.connect.exportTypes.cashLedger.nameLabel')}
            disabled={isLoading}
            label={t(
              'settings.datev.connect.exportTypes.cashLedger.switchLabel'
            )}
            checked={isToggleActive}
            onChange={onToggle}
          />
        }
      />

      <SendEmailModal
        modalTitle={sendEmailModalTitle}
        isOpen={isOpen}
        subjectText={modalProperties.subjectText}
        body={modalProperties.emailText}
        senderEmail={modalProperties.senderEmail}
        senderName={modalProperties.senderName}
        onCancel={close}
      />
    </motion.div>
  );
};

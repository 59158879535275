import { useUserRoles } from 'hooks/useUserRoles';
import { ReactNode } from 'react';

export const MenuDocumentUploadAccessWrapper = ({
  children,
}: { children: ReactNode }) => {
  const { isAdmin, isAccountant, isRequester } = useUserRoles();

  if (!isAdmin && !isRequester && !isAccountant) {
    return null;
  }

  return children;
};

import {
  BOOKING_ACCOUNT_IMPORT_ACTION,
  BookingAccountsImportState,
  ImportSteps,
  Reset,
  SetBookingAccounts,
  SetImportStep,
  ShowConfirmation,
  ShowPreview,
  UpdateFilters,
} from './types';

export const initialState: BookingAccountsImportState = {
  importStep: ImportSteps.SelectFile,
  parsedBookingAccounts: [],
  previewFilters: null,
  selectedFile: null,
  showSummary: false,
  showConfirmation: false,
};

export const reducer = (
  currentState: BookingAccountsImportState,
  update:
    | SetBookingAccounts
    | ShowPreview
    | ShowConfirmation
    | SetImportStep
    | UpdateFilters
    | Reset
) => {
  switch (update.action) {
    case BOOKING_ACCOUNT_IMPORT_ACTION.SET_BOOKING_ACCOUNTS:
      return {
        ...currentState,
        parsedBookingAccounts: update.payload.parsedBookingAccounts,
        selectedFile: update.payload.selectedFile,
        showSummary: true,
      };
    case BOOKING_ACCOUNT_IMPORT_ACTION.SHOW_PREVIEW:
      return {
        ...currentState,
        importStep: ImportSteps.Preview,
      };
    case BOOKING_ACCOUNT_IMPORT_ACTION.UPDATE_FILTERS:
      return {
        ...currentState,
        previewFilters: update.payload.filters,
      };
    case BOOKING_ACCOUNT_IMPORT_ACTION.SHOW_CONFIRMATION:
      return {
        ...currentState,
        showConfirmation: true,
      };
    case BOOKING_ACCOUNT_IMPORT_ACTION.BACK:
      return {
        ...currentState,
        importStep: ImportSteps.SelectFile,
      };
    case BOOKING_ACCOUNT_IMPORT_ACTION.RESET:
      return initialState;
    default:
      return currentState;
  }
};

export const PREVIEW_DELAY = 1200;
export const ERROR_PREVIEW_DELAY = PREVIEW_DELAY * 6;

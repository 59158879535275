import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  MembershipStatus,
  User,
  useOrganizationMembershipsQuery,
  useSendMobileAppInvitationEmailMutation,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

interface SendMobileAppInvitationEmailProps {
  user: Pick<User, 'avatarUrl' | 'email' | 'name' | 'id'>;
  onClose?: () => void;
  isCheckboxChecked: boolean;
}

export const useSendMobileAppInvitationEmail = ({
  user,
  onClose,
  isCheckboxChecked,
}: SendMobileAppInvitationEmailProps) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();

  const { data } = useOrganizationMembershipsQuery({
    variables: {
      filters: { status: MembershipStatus.Active },
    },
  });

  const activeTeamMembers = data?.organizationMemberships ?? [];

  const activeTeamMemberMembershipIds = activeTeamMembers.map(teamMember => {
    return { membershipId: teamMember.id };
  });

  const activeTeamMemberCount = activeTeamMembers.length;

  const selectedUserObject = [{ membershipId: user.id }];

  const [mobileAppInvitation, { loading: isMobileAppInvitationEmailLoading }] =
    useSendMobileAppInvitationEmailMutation();

  const handleSendEmail = async () => {
    try {
      const result = await mobileAppInvitation({
        variables: {
          input: {
            recipients: isCheckboxChecked
              ? activeTeamMemberMembershipIds
              : selectedUserObject,
          },
        },
      });

      if (result.errors) {
        error(
          t('settings:teamMembers.inviteTeamMember.actionCard.errorMessage')
        );
      } else {
        success(
          isCheckboxChecked
            ? t(
                'settings:teamMembers.inviteTeamMember.actionCard.successMessage',
                {
                  memberCount: activeTeamMemberCount,
                }
              )
            : t(
                'settings:teamMembers.inviteTeamMember.actionCard.successMessage_singular',
                {
                  memberName: user.name,
                }
              )
        );
      }
    } catch (_err) {
      error(t('settings:teamMembers.inviteTeamMember.actionCard.errorMessage'));
    }

    onClose?.();
  };

  return {
    handleSendEmail,
    isMobileAppInvitationEmailLoading,
    activeTeamMemberCount,
  };
};

import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { useOptimisticPromoPreference } from 'hooks/useOptimisticPromoPreference';

export const useAttachmentsSidebarPromo = () => {
  return useOptimisticPromoPreference(TOUR_POPOVER_IDS.ATTACHMENTS_SIDEBAR);
};

export const useRelationsSidebarPromo = () => {
  return useOptimisticPromoPreference(TOUR_POPOVER_IDS.RELATIONS_SIDEBAR);
};

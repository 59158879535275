import { Icon, Switch, Text } from '@candisio/design-system';
import { useAcceptedFileTypesForInvoice } from 'components/FileDropZone/useAcceptedFileTypesForInvoice';
import { FilePicker } from 'components/FilePicker/FilePicker';
import { useAppLayout } from 'providers/AppLayoutProvider';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFileUpload } from 'views/AppContainer/components/Header/components/DocumentUploadModal/useFileUpload';
import { DropZone } from './DropZone';
import styles from './FileUpload.module.css';

export const FileUpload = () => {
  const { useLayoutValue } = useAppLayout();
  const [isRestrictedContent, setRestictedContent] = useState(
    useLayoutValue('docUploadPopover').isRestrictedContent ?? false
  );
  const { uploadFiles } = useFileUpload();
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const acceptedFileTypes = useAcceptedFileTypesForInvoice({
    isXmlDocumentUploadAllowed: !isRestrictedContent,
  });

  return (
    <FilePicker
      acceptedFileTypes={acceptedFileTypes}
      allowsMultiple
      isRestrictedContent={isRestrictedContent}
      onSelect={files => uploadFiles(files, isRestrictedContent)}
    >
      <DropZone isRestrictedContent={isRestrictedContent} />
      <div className={styles['content-wrapper']}>
        <Icon icon="menuUpload" size={28} />
        <Text>
          {isRestrictedContent ? (
            <Trans
              i18nKey="navigation:documentUpload.dropZone.restricted.title"
              components={{ strong: <strong /> }}
            />
          ) : (
            <Trans
              i18nKey="navigation:documentUpload.dropZone.nonRestricted.title"
              components={{ strong: <strong /> }}
            />
          )}
        </Text>
      </div>
      <div className={styles['switch-wrapper']}>
        <Icon icon="eyeLock" size={12} />
        <Text>{t('documentUpload.dropZone.restrictSwitchButton')}</Text>
        <Switch
          className={styles.switch}
          defaultChecked={isRestrictedContent}
          label=""
          onChange={setRestictedContent}
        />
      </div>
    </FilePicker>
  );
};

import { BankAccountItem } from 'components/BankAccountListView/BankAccountListView';
import { BankAccountFormValues } from 'components/BankAccountListView/bankAccountFormSchema';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  IbanErrorKind,
  useCreateIbanMutation,
  useDeleteIbanMutation,
  useSetIbanAsOrgDefaultMutation,
  useUpdateIbanMutation,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { listIbansQuery } from 'views/Settings/PaymentsSettings/queries';
import { useBankAccountURLState } from 'views/Settings/PaymentsSettings/useBankAccountURLState';
import { useOrganizationOwnIbansData } from 'views/Settings/PaymentsSettings/useOrganizationOwnIbansData';

export const useBankAccounts = () => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const { ibanItems, isIbansListDataLoading } = useOrganizationOwnIbansData();

  const [createIbanMutation, { loading: creating }] = useCreateIbanMutation({
    refetchQueries: [{ query: listIbansQuery }],
  });

  const [updateIbanMutation, { loading: updating }] = useUpdateIbanMutation();
  const [deleteIbanMutation, { loading: deleting }] = useDeleteIbanMutation({
    refetchQueries: [{ query: listIbansQuery }],
  });

  const [setIbanAsOrgDefaultMutation] = useSetIbanAsOrgDefaultMutation();

  const handleSubmit = async (
    values: BankAccountFormValues,
    selectedItem?: BankAccountItem
  ) => {
    if (selectedItem) {
      const result = await updateIbanMutation({
        variables: {
          id: selectedItem.key,
          iban: values.iban,
          swiftCode: values.swiftCode,
          name: values.name,
        },
      });
      if (result.data?.updateIban.__typename === 'Iban') {
        success(t('payments:bankAccounts.success.update'));
        setOpenId(null);
      } else if (result.data?.updateIban.__typename === 'IbanError') {
        const errorTranslationKey = extractBankAccountError(
          result.data?.updateIban.kind ?? undefined
        );
        error(t(errorTranslationKey));
      }
    } else {
      const result = await createIbanMutation({
        variables: {
          name: values.name,
          iban: values.iban,
          swiftCode: values.swiftCode,
        },
      });

      if (result.data?.createIban.__typename === 'Iban') {
        success(t('payments:bankAccounts.success.create'));
        setOpenId(null);
      } else if (result.data?.createIban.__typename === 'IbanError') {
        const errorTranslationKey = extractBankAccountError(
          result.data?.createIban.kind ?? undefined
        );
        error(t(errorTranslationKey));
      }
    }
  };

  const handleDelete = async (id: string | null) => {
    if (!id) return;
    await deleteIbanMutation({ variables: { id } });
    setOpenId(null);
  };

  const handleSetDefault = async (id: string) => {
    await setIbanAsOrgDefaultMutation({
      variables: { id },
      refetchQueries: [{ query: listIbansQuery }],
    });
  };

  const extractBankAccountError = (kind?: IbanErrorKind) => {
    switch (kind) {
      case IbanErrorKind.Duplicate:
        return 'payments:bankAccounts.error.duplicate';
      case IbanErrorKind.Incomplete:
        return 'payments:bankAccounts.error.incomplete';
      case IbanErrorKind.Forbidden:
        return 'payments:bankAccounts.error.forbidden';
      case IbanErrorKind.Unknown:
        return 'payments:bankAccounts.error.unknown';
      default:
        return 'payments:bankAccounts.error.unknown';
    }
  };

  const { isOpen, openId, setOpenId } = useBankAccountURLState();

  const onDrawerClose = () => {
    setOpenId(null);
  };

  const selectedItem =
    openId !== 'new' ? ibanItems?.find(item => item.key === openId) : undefined;

  const defaultValues = selectedItem
    ? {
        name: selectedItem.name,
        iban: selectedItem.iban,
        swiftCode: selectedItem.swiftCode,
      }
    : undefined;

  return {
    ibanItems,
    isIbansListDataLoading,
    creating,
    updating,
    deleting,
    handleSubmit,
    handleDelete,
    handleSetDefault,
    extractBankAccountError,
    isOpen,
    openId,
    setOpenId,
    onDrawerClose,
    selectedItem,
    defaultValues,
  };
};

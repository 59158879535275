import { Tag } from '@candisio/design-system';
import { ProvisionsPromotionSettings } from 'components/ProductPromotions/Provisions/ProvisionsPromotionSettings';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { Cards } from './components/Cards';
import { DatevProvisionSettings } from './components/DatevProvisionSettings/DatevProvisionSettings';

export const ProvisionsSettings = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { bdsBought } = useDatev();

  return (
    <SettingsLayout
      title={t('provisions.header')}
      width={SETTINGS_VIEW_DEFAULT_WIDTH}
      titleTag={
        !bdsBought && (
          <Tag color="purple" variant="secondary">
            {t('common:tag.addOn')}
          </Tag>
        )
      }
    >
      {!bdsBought ? (
        <ProvisionsPromotionSettings />
      ) : (
        <DatevProvisionSettings />
      )}
      <Cards />
    </SettingsLayout>
  );
};

import { TruncatedText } from '@candisio/design-system/next';
import { castArray, isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentsTableData } from '../../types';
import { CellContext } from '@tanstack/react-table';

export type SplitsCellValue = (undefined | string)[] | undefined;

export const SplitsCell = ({
  value,
}: Omit<CellContext<DocumentsTableData, SplitsCellValue>, 'getValue'> & {
  value: SplitsCellValue;
}): JSX.Element => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const render = getSplitsRenderType(value);

  if (render === 'split') {
    return (
      <TruncatedText className="text-gray-400 italic">
        {t('seeSplitBookings')}
      </TruncatedText>
    );
  }

  if (value && render === 'single') {
    return <TruncatedText>{String(value[0])}</TruncatedText>;
  }

  return t('emptyCellPlaceholder');
};

type RenderType = 'empty' | 'single' | 'split';

export const getSplitsRenderType = (value: SplitsCellValue): RenderType => {
  if (isNil(value) || !value[0]) {
    return 'empty';
  }

  const values = castArray(value);
  if (values.every(v => !v)) {
    return 'empty';
  }

  const allValuesAreTheSame = values.every(v => v === values?.[0]);
  if (values.length > 1 && !allValuesAreTheSame) {
    return 'split';
  }

  return 'single';
};

import moment from 'moment';

const MAX_DAYS = 90;

export const isMoreThan90Days = (
  startingDate: Date,
  endingDate: Date,
  timeZone: string = 'Europe/Berlin'
) => {
  const startingDateMoment = moment.tz(startingDate, timeZone);
  const endingDateMoment = moment.tz(endingDate, timeZone);

  const differenceInDays = endingDateMoment.diff(startingDateMoment, 'days');

  if (differenceInDays > MAX_DAYS) {
    return true;
  }

  return false;
};

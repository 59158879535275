import { AccessLevelRoleName } from 'components/DocumentAccess/toolkit/hooks/useDocumentAccessLevelsData';
import { ActivityUser } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export const useRoleLabel = () => {
  const [t] = useTranslation();

  const getRoleLabelTranslationKey = (
    role: AccessLevelRoleName,
    users: ActivityUser[]
  ) => {
    const firstUserName = users[0]?.name;
    const lowerCaseRole = role.toLowerCase();

    switch (users.length) {
      case 0:
        return '';
      case 1:
        return t('documentAccess.membership.builtinRoles.label.one', {
          firstUserName,
          role: t(`documentAccess.membership.builtinRoles.${lowerCaseRole}`),
        });
      case 2:
        return t('documentAccess.membership.builtinRoles.label.two', {
          firstUserName,
          role: t(`documentAccess.membership.builtinRoles.${lowerCaseRole}`),
        });
      default:
        return t('documentAccess.membership.builtinRoles.label.multiple', {
          firstUserName,
          role: t(`documentAccess.membership.builtinRoles.${lowerCaseRole}`),
        });
    }
  };

  return { getRoleLabelTranslationKey };
};

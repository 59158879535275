import { Flex, Grid, TruncatedText } from '@candisio/design-system';
import { TRANSLATION } from './ApprovingStatusFooterActionBox';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { AutoSaveStatusContainer } from './AutoSaveStatusContainer';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { ReactNode } from 'react';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';

interface FooterHeaderProps {
  reimbursementItems: ReimbursementItemForList[];
  refetchReimbursement?: () => void;
  refetchReimbursementItems: () => void;
  isSavePending?: boolean;
  reimbursement?: Reimbursement;
  toggleAction?: ReactNode;
}

export const FooterHeader = ({
  isSavePending,
  reimbursement,
  reimbursementItems,
  refetchReimbursement,
  refetchReimbursementItems,
  toggleAction,
}: FooterHeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Grid columns={2} alignItems="center">
      <TruncatedText
        color="gray500"
        fontWeight="semibold"
        textTransform="uppercase"
      >
        {t(TRANSLATION.TITLE)}
      </TruncatedText>
      <Flex justifySelf="end">
        {toggleAction && toggleAction}
        <AutoSaveStatusContainer
          isSavePending={isSavePending}
          reimbursement={reimbursement}
          reimbursementItems={reimbursementItems}
          refetchReimbursement={refetchReimbursement}
          refetchReimbursementItems={refetchReimbursementItems}
        />
      </Flex>
    </Grid>
  );
};

import { IS_PROD, DEVTOOLS_KEY } from 'constants/env';
import { useLocation } from 'react-router-dom-v5-compat';
import { UserMenuItem } from '../UserProfile/UserMenuItem';

export const QaUtilsMenuItem = ({ onOpen }: { onOpen?: () => void }) => {
  const { search } = useLocation();

  if (IS_PROD) {
    return null;
  }

  return (
    <UserMenuItem
      onClick={onOpen}
      routerLink={{ hash: DEVTOOLS_KEY, replace: true, search }}
      title="Devtools"
    />
  );
};

import { ReimbursementPromotionArchive } from 'components/ProductPromotions/Reimbursement/ReimbursementPromotionArchive';
import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { ArchiveReimbursements } from './ArchiveReimbursements';
import { useArchiveReimbursementsData } from './toolkit/hooks/useArchiveReimbursementsData';
import { useArchiveReimbursementsFilterOptions } from './toolkit/hooks/useArchiveReimbursementsFilterOptions';
import { useArchiveReimbursementsTableConfigurations } from './toolkit/hooks/useArchiveReimbursementsTableConfigurations';
import { DownloadMenuButtonReimbursementsContainer } from './DownloadMenuButtonReimbursementsContainer';

export const ArchiveReimbursementsContainer = () => {
  const {
    filters,
    isLoadingReimbursements,
    handleDebounceSearch,
    reimbursements,
    reimbursementsCount,
    sortBy,
    isTableEmpty,
    isTableFiltered,
    onFilter,
    onLoadMore,
    onSort,
  } = useArchiveReimbursementsData();

  const filterOptions = useArchiveReimbursementsFilterOptions();

  const {
    availableReimbursementsColumnIds,
    configurationsTable,
    isResetPending,
    isSavingConfigurations,
    handleResetTableConfigurations,
    handleUpdateConfigurations,
  } = useArchiveReimbursementsTableConfigurations({ filters, sortBy });

  const { variant } = useReimbursementsPromotions();

  const isPromotionForReimbursementsVisible = variant !== 'noPromotion';

  if (isPromotionForReimbursementsVisible) {
    return <ReimbursementPromotionArchive />;
  }

  const isLoading =
    isLoadingReimbursements || isResetPending || isSavingConfigurations;

  return (
    <ArchiveReimbursements
      reimbursementsCount={reimbursementsCount}
      downloadReimbursementsActions={
        <DownloadMenuButtonReimbursementsContainer
          isTableFiltered={!!filters.length}
          isTableLoading={isLoading}
          selectedReimbursementsCount={reimbursementsCount}
        />
      }
      reimbursements={reimbursements}
      filters={filters}
      sortBy={sortBy}
      isLoadingReimbursements={isLoading}
      onDebounceSearch={handleDebounceSearch}
      visibleColumnIds={availableReimbursementsColumnIds}
      isTableEmpty={isTableEmpty}
      filterOptions={filterOptions}
      isTableFiltered={isTableFiltered}
      isLoadingConfigurations={isSavingConfigurations || isResetPending}
      configurations={configurationsTable}
      onUpdateConfigurations={handleUpdateConfigurations}
      onFilter={onFilter}
      onSort={onSort}
      onLoadMore={onLoadMore}
      onResetTableConfigurations={handleResetTableConfigurations}
    />
  );
};

import { defaultTheme } from '@candisio/design-system';
import { AddNewActionBox } from 'components/AddNewActionBox/AddNewActionBox';
import { useTranslation } from 'react-i18next';

interface AddDocumentTagActionBoxProps {
  onClick: () => void;
}

export function AddDocumentTagActionBox({
  onClick,
}: AddDocumentTagActionBoxProps) {
  const [t] = useTranslation();
  return (
    <AddNewActionBox
      borderTop={`1px solid ${defaultTheme.colors.gray250}`}
      borderTopRadius="0"
    >
      <AddNewActionBox.Content>
        <AddNewActionBox.Message>
          {t('documentTags.field.addNew.subtitle')}
        </AddNewActionBox.Message>
      </AddNewActionBox.Content>
      <AddNewActionBox.Button onPointerDown={onClick}>
        {t('documentTags.field.addNew.button')}
      </AddNewActionBox.Button>
    </AddNewActionBox>
  );
}

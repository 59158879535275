import type { Variants } from 'motion/react';

export const transition = {
  default: { duration: 0.25, ease: 'easeInOut' },
};

export const variants: Variants = {
  visible: { opacity: 1, scale: 1, visibility: 'visible' },
  hidden: { opacity: 0, scale: 0.6, visibility: 'hidden' },
  hover: { cursor: 'pointer', color: '#2b1a2e99' /* gray800 + 60% opacity */ },
};

/**
 * shared layout route for import history list and error table
 */
import { ImportHistoryLayout } from 'components/ImportHistoryLayout/ImportHistoryLayout';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom-v5-compat';
import { ProjectCodeImportHistoryRouteParams as RouteParams } from './types';

export const ProjectCodesImportHistoryLayout = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const params = useParams<RouteParams>();

  return (
    <ImportHistoryLayout
      title={t(
        params?.id
          ? 'projectCodes.importHistory.errorTable.title'
          : 'projectCodes.importHistory.title'
      )}
      backButtonLabel={t(
        params?.id
          ? 'projectCodes.importHistory.errorTable.backButton'
          : 'projectCodes.importHistory.backButton'
      )}
    >
      <Outlet />
    </ImportHistoryLayout>
  );
};

interface UseAcceptedFileTypesForInvoiceProps {
  isXmlDocumentUploadAllowed?: boolean;
}

export const useAcceptedFileTypesForInvoice = ({
  isXmlDocumentUploadAllowed,
}: UseAcceptedFileTypesForInvoiceProps) => {
  const acceptedFileTypes = isXmlDocumentUploadAllowed
    ? ['application/pdf', 'application/xml', 'text/xml']
    : ['application/pdf'];

  return acceptedFileTypes;
};

import * as Sentry from '@sentry/react';
import isEqual from 'lodash/isEqual';
import { memo } from 'react';
import { PdfViewerError } from './PdfViewerError';
import {
  ReactPdfThumbnail,
  ReactPdfThumbnailProps,
} from './ReactPdf/ReactPdfThumbnail';

export const PdfThumbnailViewer = memo(
  (props: ReactPdfThumbnailProps) => (
    <Sentry.ErrorBoundary fallback={<PdfViewerError />}>
      <ReactPdfThumbnail {...props} />
    </Sentry.ErrorBoundary>
  ),
  isEqual
);

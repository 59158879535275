import styles from './SwimlaneHeaderCount.module.css';

type Props = {
  count?: number;
  totalCount?: number;
};

export const SwimlaneHeaderCount = ({ count, totalCount }: Props) => {
  if (!count || !totalCount) {
    return null;
  }

  return <span className={styles.count}>{`${count} of ${totalCount}`}</span>;
};

import {
  useReimbursementItemsQuery,
  ReimbursementItemsQuery,
  GeneralExpenseItem,
  HospitalityExpenseItem,
  PerDiemItem,
} from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { mapToReimbursementItem } from '../utils/mapToReimbursementItem';

type AllItemsFragments = ReimbursementItemsQuery['reimbursementItems'][number];

type ReturnMapperType = ReturnType<typeof mapToReimbursementItem>;

type ExpenseFragment =
  | GeneralExpenseItem
  | HospitalityExpenseItem
  | PerDiemItem;

export const useGetReimbursementExpenseItems = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUseReimbursement } = useReimbursement();

  const { data, loading: loadingItems } = useReimbursementItemsQuery({
    variables: { filters: { reimbursementCaseId: reimbursementId } },
    fetchPolicy: 'network-only',
    skip: !reimbursementId || !canUseReimbursement,
  });

  const filteredExpensesItems = (data?.reimbursementItems ?? []).filter(
    (item: AllItemsFragments): item is ExpenseFragment => {
      return (
        item.__typename === 'GeneralExpenseItem' ||
        item.__typename === 'HospitalityExpenseItem' ||
        item.__typename === 'PerDiemItem'
      );
    }
  );

  const mappedItems = (filteredExpensesItems ?? [])
    .map(item => mapToReimbursementItem(item))
    .filter((item): item is Exclude<ReturnMapperType, undefined> => !!item);

  return { mappedItems, loadingItems };
};

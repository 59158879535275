import { useArchiveOrDeleteReimbursementCaseMutation } from 'generated-types/graphql.types';

export const useDeleteReimbursement = () => {
  const [deleteReimbursement, { loading, client }] =
    useArchiveOrDeleteReimbursementCaseMutation({
      refetchQueries: [
        'archiveReimbursementCasesCount',
        'inboxReimbursementCasesCount',
      ],
      onCompleted: () => {
        client.cache.evict({ fieldName: 'inboxReimbursementCases' });
        client.cache.evict({ fieldName: 'archiveReimbursementCases' });

        // Instead of refetching the 3 different queries with different params we evict the cache
        client.cache.evict({ fieldName: 'approvalReimbursementCasesCount' });
      },
    });

  const handleDeleteReimbursement = async (id: string) => {
    const { data, errors } = await deleteReimbursement({
      variables: { id },
    });

    const { deleted } = data?.archiveOrDeleteReimbursementCase ?? {};

    if (errors?.length || !deleted) {
      return 'error';
    }

    return 'success';
  };

  return {
    deletePending: loading,
    deleteReimbursement: handleDeleteReimbursement,
  };
};

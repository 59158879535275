import {
  AvatarStack,
  Grid,
  Item,
  SelectField,
  Text,
} from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { ActivityUser } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

interface RoleAccessCardProps {
  users?: ActivityUser[] | null;
  roleTranslationKey: string;
  accessTranslationKey: string;
}

export const DocumentAccessUsersRow = ({
  users,
  roleTranslationKey,
  accessTranslationKey,
}: RoleAccessCardProps) => {
  const [t] = useTranslation();

  if (!users?.length) {
    return null;
  }

  return (
    <Grid gap="space8" templateColumns="70fr 30fr">
      <Grid
        gap="space8"
        autoFlow="column"
        alignItems="center"
        templateColumns="auto 1fr"
      >
        <AvatarStack limit={6} size="small" tooltipMaxHeight="400px">
          {users.map(user => (
            <AvatarWithStatusContainer
              key={user.id}
              name={user.name}
              userId={user.id}
              img={user.avatarUrl ?? undefined}
            />
          ))}
        </AvatarStack>
        <Text>{roleTranslationKey}</Text>
      </Grid>
      <Grid minWidth="max-content">
        <SelectField
          readOnly
          select={{
            selectedKey: t(accessTranslationKey),
            children(item) {
              return <Item>{item.name}</Item>;
            },
            items: [
              {
                id: t(accessTranslationKey),
                name: t(accessTranslationKey),
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  );
};

import clsx from 'clsx';
import { ToggleButton } from 'react-aria-components';
import styles from '../DaysStep.module.css';
import { ReactNode } from 'react';

interface ButtonItemProps {
  displayValue: ReactNode;
  id: string;
}
export const ButtonItem = ({ displayValue, id }: ButtonItemProps) => {
  return (
    <ToggleButton
      id={id}
      className={clsx(
        styles['toggle-button'],
        styles[`toggle-button--regular`]
      )}
    >
      {displayValue}
    </ToggleButton>
  );
};

import { CSSObject, css as emotionCss, keyframes } from '@emotion/react';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import mergeRefs from 'react-merge-refs';
import { Row } from 'react-table';
import { ItemProps } from 'react-virtuoso';
import { BaseTableDataType } from '../../Molecules/Table/Table';
import { useTheme } from '../../Theme/useTheme';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { Box } from '../Box';
import { Tooltip, useTooltip } from '../Tooltip';

export interface TableRowPropsVS<TableDataType extends BaseTableDataType>
  extends StandardHTMLAttributes<HTMLTableRowElement>,
    LayoutProps {
  disabled?: boolean;
  disabledReason?: string;
  readOnly?: boolean;
  highlight?: boolean;
  selected?: boolean;
  isRowClickable?: boolean;
  overlay?: (props: { data: TableDataType }) => JSX.Element;
  item?: ItemProps<Row<TableDataType>>['item'];
}

/**
 * Wraps all cells rendered into row of Table.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-data-table}
 *
 * @param {boolean} [disabled] Whether the row should have disabled styling and prevent all interaction
 * @param {boolean} [readOnly] Whether the row should have readonly styling
 */
export const TableRow = React.forwardRef<
  HTMLTableRowElement,
  TableRowPropsVS<any>
>(
  (
    {
      children,
      disabled,
      disabledReason,
      readOnly,
      highlight,
      isRowClickable,
      overlay: Overlay,
      selected,
      ...restProps
    },
    ref
  ) => {
    const { colors } = useTheme();

    const animationCSS: CSSObject = useMemo(() => {
      const highlightAnimation = keyframes({
        '0%': {
          background: colors.gray100,
        },
        '100%': {
          background: 'initial',
        },
      });

      return {
        animation: `${highlightAnimation} 5s linear`,
      };
    }, [colors]);

    const overlayCSS = useMemo(
      () =>
        emotionCss(`
          &:hover {
            .overlay {
              display: block;
            }
          }

          .overlay {
              display: none;
              pointer-events: none;
          }

          &:has(button[data-preview-highlights="true"]) {
            background: ${colors.gray100}
          }         
      `),
      [colors.gray100]
    );

    // we are only unsetting event handlers here, if you are looking for (disabled) row styles, please look into TableBody
    if (disabled) {
      delete restProps.onClick;
      delete restProps.onPointerDown;
      delete restProps.onMouseDown;
      delete restProps.onClickCapture;
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const css = useMemo(
      () => [
        { cursor: isRowClickable ? 'pointer' : 'default' },
        highlight ? animationCSS : [],
        Overlay ? overlayCSS : [],
      ],
      [restProps.onClick, highlight]
    );

    const [isHovered, setIsHovered] = useState(false);

    const { isOpen, tooltipProps, triggerProps, triggerRef, tooltipRef } =
      useTooltip({
        placement: 'bottom',
        isOpen: isHovered,
        passiveTrigger: true,
      });

    const [overlayWidth, setOverlayWidth] = useState(0);
    const [overlayScrollWidth, setOverlayScrollWidth] = useState(0);

    // This functions makes sure the overlay is placed over visible content regardless of scroll position
    const updateOverlayPlacement = useCallback(() => {
      const virtuosoScroller = document.querySelector<HTMLDivElement>(
        '[data-virtuoso-scroller=true]'
      );

      const tableWrapper = document.querySelector<HTMLDivElement>(
        '[data-virtuoso-scroller=true] > div'
      );

      if (!virtuosoScroller || !tableWrapper) return;

      const { scrollLeft } = virtuosoScroller;
      const { offsetWidth } = tableWrapper;

      setOverlayScrollWidth(scrollLeft);
      setOverlayWidth(offsetWidth);
    }, []);

    useLayoutEffect(() => {
      if (isHovered || selected) updateOverlayPlacement();
    }, [isHovered, selected, updateOverlayPlacement]);

    return (
      <Box
        css={css}
        as="tr"
        aria-disabled={disabled}
        aria-readonly={readOnly}
        ref={mergeRefs([ref, triggerRef])}
        {...triggerProps}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onFocus={() => setIsHovered(true)}
        onBlur={() => setIsHovered(false)}
        {...restProps}
        style={{
          ...(selected ? { background: colors.bluebg } : {}),
          ...restProps.style,
        }}
      >
        {disabled && isOpen && disabledReason && (
          <Tooltip maxWidth="40ch" {...tooltipProps} ref={tooltipRef}>
            {disabledReason}
          </Tooltip>
        )}
        {children}
        {Overlay && (
          <Box
            className="overlay"
            position="absolute"
            top={0}
            left={overlayScrollWidth}
            height="100%"
            width={overlayWidth}
            style={{
              ...(selected ? { display: 'block' } : {}),
            }}
          >
            <Overlay data={restProps.item?.original} />
          </Box>
        )}
      </Box>
    );
  }
);

import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import styles from './LimitRequestContentTile.module.css';
import { usePath } from 'hooks/usePath';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { NavLink } from 'react-router-dom-v5-compat';
import { Tag } from '@candisio/design-system';
import { CardInfo } from '../shared/CardInfo/CardInfo';
import { Category } from '../shared/Category/Category';
import { categoryTranslationKeys } from 'views/CreditCards/CardManagerView/components/CreditCardsTable/Cells/CategoryCell';
import { CardIssuerCardEdgeDataFragment } from 'generated-types/graphql.types';

export const LimitRequestContentTile = ({
  request,
}: {
  request: CardIssuerCardEdgeDataFragment['node'];
}) => {
  const { cardholder, category, id, limit, refNum, pendingRequest } = request;
  const { transactionAmountFormatter } = useMoneyFormatter();

  const href = usePath({
    pathname: '/credit_cards/dashboard/cards/:creditCardId',
    params: {
      creditCardId: id,
    },
  });

  const name = `${cardholder.firstName} ${cardholder.lastName}`;

  return (
    <SwimlaneTile asChild variant="white" data-cy="limitRequestTile">
      <NavLink to={href} className={styles.column}>
        <Tag color="yellow" variant="secondary">
          New limit
        </Tag>
        <div className={styles.details}>
          <CardInfo name={name} refNum={refNum} />
          {category && (
            <Category category={categoryTranslationKeys[category]} />
          )}
          <div className="flex gap-1">
            {limit && (
              <p className={styles.amount}>
                {transactionAmountFormatter(limit)}
              </p>
            )}
            {pendingRequest?.requestedTransactionLimit && (
              <p className={styles.requestedAmount}>
                {transactionAmountFormatter(
                  pendingRequest?.requestedTransactionLimit
                )}
              </p>
            )}
          </div>
        </div>
      </NavLink>
    </SwimlaneTile>
  );
};

import { HighlightedTruncatedText } from '@candisio/design-system';
import { getCellHighlights } from 'components/WithHighlightsCell/getCellHighlights';
import type {
  WithHighlightsCellProps,
  WithHighlights as WithHighlightsType,
} from 'components/WithHighlightsCell/types';
import { getAmountHighlights } from '../utils/getAmountHighlights';

export const AmountCellHighlightable = <T extends WithHighlightsType>({
  cellProps,
  FallbackCell,
}: WithHighlightsCellProps<T>) => {
  const highlights = getCellHighlights(cellProps);
  if (!highlights) {
    return <FallbackCell {...cellProps} />;
  }
  const { value } = cellProps;
  const amountHighlight = getAmountHighlights(value, highlights);

  if (!amountHighlight) {
    return <FallbackCell {...cellProps} />;
  }

  return (
    <HighlightedTruncatedText
      highlight={amountHighlight}
      className="max-w-[50ch] text-end ml-auto"
    />
  );
};

import { Color } from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const useReimbursementTags = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const tags: Record<ReimbursementCaseStatus, { text: string; color: Color }> =
    {
      APPROVED: { text: t('dashboard.list.tags.approved'), color: 'green' },
      APPROVING: { text: t('dashboard.list.tags.approving'), color: 'yellow' },
      ARCHIVED: { text: t('dashboard.list.tags.archived'), color: 'gray' },
      CHECK: { text: t('dashboard.list.tags.check'), color: 'red' },
      DRAFT: { text: t('dashboard.list.tags.draft'), color: 'gray' },
      EXPORTED: { text: t('dashboard.list.tags.exported'), color: 'blue' },
      REJECTED: { text: t('dashboard.list.tags.rejected'), color: 'red' },
      REVIEW: { text: t('dashboard.list.tags.review'), color: 'yellow' },
      PARTIALLY_EXPORTED: {
        text: t('dashboard.list.tags.partiallyExported'),
        color: 'yellow',
      },
      EXPORTING: { text: t('dashboard.list.tags.exporting'), color: 'yellow' },
    };

  return tags;
};

import React from 'react';
import clsx from 'clsx';
import styles from './ScrollBox.module.css';

export type ScrollBoxNextProps = React.HTMLAttributes<HTMLDivElement> & {
  scrollDirection?: 'x' | 'y' | 'both' | 'none';
  applyHack?: boolean;
};
/**
 * A div component that applies custom scrollbar styling and shows
 * scrollbars only on hover using Tailwind CSS and CSS modules.
 *
 * @param {'x' | 'y' | 'both' | 'none'} scrollDirection Sets the direction of scrolling
 */
export const ScrollBox = React.forwardRef<HTMLDivElement, ScrollBoxNextProps>(
  (
    { scrollDirection = 'both', applyHack = false, className, ...restProps },
    ref
  ) => {
    return (
      <div
        className={clsx(
          styles.scrollBox,
          {
            'overflow-auto': scrollDirection === 'both',
            'overflow-x-auto overflow-y-hidden': scrollDirection === 'x',
            'overflow-y-auto overflow-x-hidden': scrollDirection === 'y',
            'overflow-hidden': scrollDirection === 'none',
          },
          applyHack && styles.firefoxHack,
          className
        )}
        ref={ref}
        {...restProps}
      />
    );
  }
);

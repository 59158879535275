import {
  DocumentTypesFieldItem,
  isSupportedDocumentType,
} from 'components/Form/hooks/useDocumentTypeFieldOptions';
import {
  DocumentType,
  useAvailableDocumentTypesQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { documentTypeTranslationKeys } from '../../useToTypeFieldItem';
import { useEcmDocumentTypeOptions } from './useEcmDocumentTypeItems';

export const useGlobalDocumentTypeFieldOptions = () => {
  const [t] = useTranslation();
  const [llmClassificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.llmClassification,
  ]);

  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();

  const { data } = useAvailableDocumentTypesQuery({
    variables: {
      includeInactiveTypes: false,
    },
    skip: !llmClassificationFF,
  });

  const [invoiceTypesArray, documentTypesArray] = useMemo(() => {
    const invoiceTypesArray: DocumentTypesFieldItem[] = [];
    const documentTypesArray: DocumentTypesFieldItem[] = [];

    (data?.availableDocumentTypes ?? []).forEach(item => {
      if (item.documentCategory) {
        const translationKey =
          documentTypeTranslationKeys[item.documentCategory.documentType];

        const translatedLabel = translationKey
          ? t(translationKey)
          : item.documentCategory.documentType;

        invoiceTypesArray.push({
          key: item.documentCategory.documentType,
          label: item.documentCategory.isDefault
            ? translatedLabel
            : item.documentCategory.documentType,
          value: item,
        });
      } else if (isSupportedDocumentType(item.documentType))
        documentTypesArray.push({
          key: item.documentType,
          value: item,
          label:
            ecmDocumentTypeTranslationMap[item.documentType as DocumentType],
        });
    });

    return [invoiceTypesArray, documentTypesArray];
  }, [data?.availableDocumentTypes, ecmDocumentTypeTranslationMap, t]);

  const groupedItems = useMemo(
    () => [invoiceTypesArray, documentTypesArray],
    [documentTypesArray, invoiceTypesArray]
  );

  const newItems = useMemo(() => {
    return [...invoiceTypesArray, ...documentTypesArray];
  }, [documentTypesArray, invoiceTypesArray]);

  return {
    newItems,
    groupedItems,
  };
};

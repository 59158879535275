import { Swimlane } from 'components/Swimlane/Swimlane';
import { SwimlaneHeader } from 'components/Swimlane/SwimlaneHeader';
import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { SwimlaneTiles } from 'components/Swimlane/SwimlaneTiles';
import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import { SwimlaneTileShowAll } from 'components/Swimlane/content/SwimlaneTileShowAll';
import { usePath } from 'hooks/usePath';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DateOptionMissingInvoicesWidget,
  dateFilterManifest,
  mappedDateRangeFilters,
} from '../../MissingInvoicesWidget/utils';
import { useMissingInvoices } from '../hooks/useMissingInvoices';
import { MissingInvoiceContentTile } from './MissingInvoiceContentTile';
import { MissingInvoiceFilterButton } from './MissingInvoiceFilterButton/MissingInvoiceFilterButton';
import { PromoCreditCardMissingInvoices } from '../promos/PromoCreditCardMissingInvoices';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';

export const MissingInvoicesSwimlane = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const [dateOption, setDateOption] =
    useState<DateOptionMissingInvoicesWidget>('All');

  const dateRange = dateFilterManifest({ currentDate: new Date(), dateOption });

  const { missingInvoices } = useMissingInvoices({
    dateRangeFilters: mappedDateRangeFilters(dateRange),
  });

  const onOptionChange = (option: DateOptionMissingInvoicesWidget[]) => {
    if (!option.length) {
      return;
    }

    setDateOption(option[0]);
  };

  const showAllLink = usePath({ pathname: '/inbox/transactions' });

  const { isPromotionSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.CREDIT_CARD_MISSING_INVOICES
  );

  return (
    <Swimlane data-cy="missingInvoicesSwimlane">
      <SwimlaneTiles
        contentTileCount={missingInvoices.length}
        promoTileCount={
          !isPromotionSeen && missingInvoices.length > 0 ? 2 : undefined
        }
      >
        {({
          contentTileVisibleCount,
          isShowAllTileVisible,
          isPromoTileVisible,
        }) => (
          <>
            <SwimlaneHeader
              icon="creditCardCloud"
              label={t('insights.missingInvoicesWidget.title')}
            >
              <MissingInvoiceFilterButton
                dateOption={dateOption}
                onOptionChange={onOptionChange}
              />
            </SwimlaneHeader>

            {/* TODO: Define empty state */}
            {contentTileVisibleCount === 0 && (
              <SwimlaneTile
                className="items-end"
                data-cy="missingInvoicesSwimlaneEmptyState"
              >
                <SwimlaneTileDescription>
                  {t('insights.swimlanes.missingInvoices.empty')}
                </SwimlaneTileDescription>
              </SwimlaneTile>
            )}

            {missingInvoices.length > 0 &&
              Array.from({ length: contentTileVisibleCount }, (_, index) => (
                <MissingInvoiceContentTile
                  key={missingInvoices[index].cardholder.id}
                  cardholderInsight={missingInvoices[index]}
                  dateRange={dateRange}
                  dateOption={dateOption}
                />
              ))}

            {isShowAllTileVisible && <SwimlaneTileShowAll to={showAllLink} />}

            {isPromoTileVisible && <PromoCreditCardMissingInvoices />}
          </>
        )}
      </SwimlaneTiles>
    </Swimlane>
  );
};

import { defaultTheme } from '@candisio/design-system';
import { motion } from 'motion/react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

export const SelectFileFormContainer = styled.div`
  gap: 2rem;
  flex-direction: column;
  flex: 1;
  padding: 0 2rem 2rem;
  display: flex;
  overflow: auto;
`;

export const HalfWidth = styled.div`
  gap: 2rem;
  display: flex;
  flex-direction: column;
  width: 50%;
`;

type ProgressLabelProps = {
  labelColor?: string;
};

export const ProgressLabel = styled.div<ProgressLabelProps>`
  padding: 0.5rem;
  color: ${({ labelColor }) => labelColor || defaultTheme.colors.blue500};
  text-align: center;
  line-height: 1rem;
  position: relative;
`;

export const SelectFileDescriptionLayout = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
})`
  display: grid;
  gap: 1rem;
  width: 60%;

  p {
    color: ${defaultTheme.colors.gray800};
    margin: 0;
  }
`;

export const SelectFileInfoLinksWrapper = styled.div`
  a:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const StyledForm = styled(motion.form).attrs({
  layout: 'position',
  noValidate: true,
})`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Generation = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { delay: 0.5 } },
})`
  display: grid;
  gap: 2rem;
`;

export const RowData = styled.span`
  color: ${p =>
    p['aria-disabled']
      ? defaultTheme.colors.gray600
      : defaultTheme.colors.gray800};
`;

export const InfoListWrapper = styled(motion.ul).attrs({
  animate: 'visible',
  variants: {
    visible: {
      transition: { staggerChildren: 1.2, delayChildren: 1.2 },
    },
  },
})`
  flex: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: ${defaultTheme.fontSizes.basic};
  font-weight: 500;
  white-space: pre-line;
`;

export const InfoItem = styled(motion.li).attrs({
  variants: {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  },
})`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  line-height: 1rem;
`;

export const ConfirmationInfoSection = styled.div`
  padding-bottom: 2rem;
`;

export const ConfirmationButtonContainer = styled.section`
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
`;

import { CustomEmptyStateProps, Link, Text } from '@candisio/design-system';
import { AttachmentsTableEmptyStateLayout } from 'components/DocumentViewer/AttachmentsTable/AttachmentsTableEmptyStateLayout';
import { useTranslation } from 'react-i18next';

interface AttachmentsTableEmptyStateProps extends CustomEmptyStateProps {
  areDocumentsSelected: boolean;
  isTableEmpty: boolean;
  isTableFiltered: boolean;
  selectedDocumentsLength: number;
}

export const AttachmentsTableEmptyState = ({
  areDocumentsSelected,
  isTableEmpty,
  isTableFiltered,
  resetFilters,
  selectedDocumentsLength,
}: AttachmentsTableEmptyStateProps) => {
  const [t] = useTranslation();

  return (
    <AttachmentsTableEmptyStateLayout isTableFiltered={isTableFiltered}>
      {isTableEmpty && !isTableFiltered ? (
        <Text textAlign="center">
          {t('document.attachments.table.emptyState.isEmpty')}
        </Text>
      ) : isTableEmpty && isTableFiltered && !areDocumentsSelected ? (
        <>
          <Text textAlign="center" whiteSpace="pre-wrap">
            {t('document.attachments.table.emptyState.isFilteredAndEmpty')}
          </Text>
          <Link as="button" onClick={() => resetFilters()}>
            {t('document.attachments.table.emptyState.resetFilter')}
          </Link>
        </>
      ) : (
        <>
          <Text textAlign="center" whiteSpace="pre-wrap">
            {t(
              'document.attachments.table.emptyState.isFilteredAndEmptyAndDocumentsAreSelected',
              { count: selectedDocumentsLength }
            )}
          </Text>
          <Link as="button" onClick={() => resetFilters()}>
            {t('document.attachments.table.emptyState.resetFilter')}
          </Link>
        </>
      )}
    </AttachmentsTableEmptyStateLayout>
  );
};

import { Grid } from '@candisio/design-system';
import { PendingSettlements } from './PendingSettlements/PendingSettlements';
import { UpcomingSettlement } from './UpcomingSettlement/UpcomingSettlement';
import { CreditCardsIssueNewCardModal } from '../CreditCardsInsights/CreditCardsIssueNewCardModal';
import { OrganizationSettingsDrawer } from '../CreditCardsInsights/OrganizationSettingsDrawer/OrganizationSettingsDrawer';
import { useCreditCardLimits } from '../CreditCardsInsights/FinancialOverview/hooks/useCreditCardLimits';
import { StatementsListContainer } from './StatementsList/StatementsListContainer';
import { StatementDetailsDrawer } from './StatementDetailsDrawer/StatementDetailsDrawer';

export const CreditCardsStatements = () => {
  const { data } = useCreditCardLimits();
  const isPrefundOrg = data?.total === 0;

  return (
    <>
      <Grid templateColumns="3fr 1fr" gap="space16" height="100%">
        <Grid>
          <StatementsListContainer />
        </Grid>
        {!isPrefundOrg && (
          <Grid gap="space16" templateRows="auto 1fr">
            <UpcomingSettlement />
            <PendingSettlements />
          </Grid>
        )}
      </Grid>
      <StatementDetailsDrawer />
      <OrganizationSettingsDrawer />
      <CreditCardsIssueNewCardModal />
    </>
  );
};

import {
  FilterOption,
  PaginationWithSearchFilterHook,
  fetchPolicy,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  ContactFilterOptionsField,
  ContactFilterOptionsInfiniteScrollPaginationQuery,
  ContactFilterOptionsInfiniteScrollPaginationQueryVariables,
  ContactsFilterInput,
  useContactsPaginationQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';
import { contactFilterOptionsInfiniteScrollPaginationQuery } from '../utils/queries';

const IBAN_FILTER_PREFIX = 'IBAN';
const BAN_FILTER_PREFIX = 'BAN';
const FILTER_SEPARATOR = '_';
type IbanFilterPrefix = typeof IBAN_FILTER_PREFIX;
type BANFilterPrefix = typeof BAN_FILTER_PREFIX;
type SeparatorType = typeof FILTER_SEPARATOR;
export type IbanOrBanFilter =
  | `${IbanFilterPrefix}${SeparatorType}${string}`
  | `${BANFilterPrefix}${SeparatorType}${string}`;

export const toIbanFilter = (iban?: string): IbanOrBanFilter | '' =>
  iban ? `IBAN${FILTER_SEPARATOR}${iban}` : '';

export const toBanFilter = (ban?: string | null): IbanOrBanFilter | '' =>
  ban ? `BAN${FILTER_SEPARATOR}${ban}` : '';

export const toIbanOrBanContactFilterInput = (
  filterValues: IbanOrBanFilter[]
): ContactsFilterInput['ibanOrBankAccountNumber'] => {
  return filterValues.map(filterValue => {
    if (filterValue.startsWith(IBAN_FILTER_PREFIX)) {
      return {
        iban: filterValue.replace(
          `${IBAN_FILTER_PREFIX}${FILTER_SEPARATOR}`,
          ''
        ),
      };
    } else {
      return {
        bankAccountNumber: filterValue.replace(
          `${BAN_FILTER_PREFIX}${FILTER_SEPARATOR}`,
          ''
        ),
      };
    }
  });
};

export const usePaymentInfoFilter: PaginationWithSearchFilterHook = ({
  filteredValues,
  searchStr,
}) => {
  const computeVariables = useCallback(
    (
      inputString?: string
    ): ContactFilterOptionsInfiniteScrollPaginationQueryVariables => ({
      query: {
        field: ContactFilterOptionsField.IbanOrBankAccountNumber,
        query: inputString?.replaceAll(' ', ''), // IBANs are usually prettified with spaces, we remove them cause BE searches on normalized IBAN numbers
      },
    }),
    []
  );

  const {
    data: allContacts,
    loading: allContactsLoading,
    loadMore,
    handleDebounceSearch,
  } = useDebouncedSearchPagination<
    ContactFilterOptionsInfiniteScrollPaginationQuery,
    ContactFilterOptionsInfiniteScrollPaginationQueryVariables
  >({
    query: contactFilterOptionsInfiniteScrollPaginationQuery,
    queryRootKey: 'contactFilterOptionsPagination',
    computeVariables,
  });

  const parsedFilteredValues = toIbanOrBanContactFilterInput(
    filteredValues as IbanOrBanFilter[]
  );

  const { data: selectedContacts } = useContactsPaginationQuery({
    variables: {
      input: { page: 1, limit: parsedFilteredValues?.length },
      filters: { ibanOrBankAccountNumber: parsedFilteredValues },
    },
    fetchPolicy,
    skip: parsedFilteredValues?.length === 0 || Boolean(searchStr),
  });

  const byIds = selectedContacts?.contactsPagination.records ?? [];
  const byAccountNumber =
    allContacts?.contactFilterOptionsPagination.records ?? [];

  const filterOptions: FilterOption[] = [...byIds, ...byAccountNumber]
    .filter(
      contact => Boolean(contact.iban) || Boolean(contact.bankAccountNumber)
    )
    .map(contact => {
      const hasIban = Boolean(contact.iban?.value);
      if (hasIban) {
        const ibanFilter = toIbanFilter(contact.iban?.value);

        return {
          label: contact.iban?.value ?? '',
          id: ibanFilter,
        };
      } else {
        const banFilter = toBanFilter(contact.bankAccountNumber);

        return {
          label: contact.bankAccountNumber ?? '',
          id: banFilter,
        };
      }
    });

  return {
    filterOptions: uniqBy(filterOptions, 'id'),
    handleDebounceSearch,
    loading: allContactsLoading,
    loadMore,
  };
};

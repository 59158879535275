import { Link } from '@candisio/design-system';
import { CheckmarkIcon, ErrorIcon } from 'components/Icons/DefaultIcons';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { InfoItem, InfoListWrapper } from '../styles';
import { FilterState } from '../types';
import {
  ParsedBookingAccount,
  ValidationErrors,
} from '../utils/parseBookingAccountCSV';
import { errorReports } from './error-reports';

export interface InfoListProps {
  animate?: boolean;
  bookingAccounts: ParsedBookingAccount[];
  errors: string[];
  onUpdateFilter: (filter: FilterState) => void;
}

export const InfoList = ({
  animate = false,
  bookingAccounts,
  errors,
  onUpdateFilter,
}: InfoListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);
  const hasError = errors ? errors.length > 0 : false;

  return (
    <InfoListWrapper initial={animate ? 'hidden' : false}>
      {hasError ? (
        errors.map(error => (
          <InfoItem key={error}>
            <ErrorIcon size="space20" />
            <span>{error}</span>
          </InfoItem>
        ))
      ) : (
        <>
          <InfoItem>
            <CheckmarkIcon size="space20" />
            <span>
              {t('import.preview.foundBookingAccounts', {
                count: bookingAccounts.length,
              })}
            </span>
          </InfoItem>
          {Object.entries(errorReports).map(
            ([errorKey, { infoKey, onLinkClick, linkKey }]) => {
              const count = bookingAccounts.filter(ba =>
                ba.errors.includes(errorKey as ValidationErrors)
              ).length;

              if (!count) {
                return null;
              }

              return (
                <InfoItem key={errorKey}>
                  <ErrorIcon size="space20" />
                  <span>
                    {t(infoKey, { count })}
                    {count !== bookingAccounts.length && (
                      <Link
                        as="button"
                        onClick={() => {
                          onUpdateFilter(onLinkClick(bookingAccounts));
                        }}
                        style={{ marginLeft: '0.25rem' }}
                      >
                        {t(linkKey, { count })}
                      </Link>
                    )}
                  </span>
                </InfoItem>
              );
            }
          )}
        </>
      )}
    </InfoListWrapper>
  );
};

import { UploadFileItem } from 'generated-types/graphql.types';
import { uploadedFilesQuery } from './queries';

export const resolvers = {
  Mutation: {
    addFileUpload: (
      _root: any,
      variables: { file: UploadFileItem },
      { cache }: any
    ) => {
      const { file } = variables;
      const getCurrentFiles = cache.readQuery({
        query: uploadedFilesQuery,
      });

      const data = {
        fileUploads: getCurrentFiles.fileUploads.concat([
          {
            __typename: 'FileUploadItem',
            errorDetails: '',
            ...file,
          },
        ]),
      };

      cache.writeQuery({
        query: uploadedFilesQuery,
        data,
      });
    },

    updateFileUpload: (
      _root: any,
      variables: { file: UploadFileItem },
      { cache }: any
    ) => {
      const { file } = variables;
      const currentFiles = cache.readQuery({
        query: uploadedFilesQuery,
      });

      if (currentFiles?.fileUploads) {
        const updatedFileUploads = currentFiles.fileUploads.map(
          (fileItem: UploadFileItem) =>
            fileItem.id === file.id
              ? { ...file, __typename: 'FileUploadItem' }
              : fileItem
        );

        cache.writeQuery({
          query: uploadedFilesQuery,
          data: {
            fileUploads: updatedFileUploads,
          },
        });
      }
    },

    removeAll: (_root: any, _variables: {}, { cache }: any) => {
      cache.writeQuery({
        query: uploadedFilesQuery,
        data: { fileUploads: [] },
      });
    },

    removeFileUploadById: (
      _root: any,
      variables: { id: string },
      { cache }: any
    ) => {
      const currentFiles = cache.readQuery({
        query: uploadedFilesQuery,
      });

      if (currentFiles?.fileUploads) {
        const updatedFileUploads = currentFiles.fileUploads.filter(
          (fileItem: UploadFileItem) => fileItem.id !== variables.id
        );

        cache.writeQuery({
          query: uploadedFilesQuery,
          data: {
            fileUploads: updatedFileUploads,
          },
        });
      }
    },

    softDeleteFileUploadByFileId: (
      _root: any,
      variables: { id: string },
      { cache }: any
    ) => {
      const currentFiles = cache.readQuery({
        query: uploadedFilesQuery,
      });

      if (currentFiles?.fileUploads) {
        const updatedFileUploads = currentFiles.fileUploads.map(
          (fileItem: UploadFileItem) =>
            fileItem.fileId === variables.id
              ? { ...fileItem, isDeleted: true }
              : fileItem
        );

        cache.writeQuery({
          query: uploadedFilesQuery,
          data: {
            fileUploads: updatedFileUploads,
          },
        });
      }
    },
  },
};

import { Button, Flex, Grid, Icon, Text } from '@candisio/design-system';
import { FilePicker } from 'components/FilePicker/FilePicker';
import { UploadDropZone } from 'components/UploadDropZone/UploadDropZone';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isOnlyApprover } from 'utils/authorization';
import { useDraggedFileStatus } from 'views/TransactionAssociation/hooks/useDraggedFileStatus';
import { acceptedFileType, useDragAndDrop } from './useDragAndDrop';
import { getColorCode } from './util';

type InvoiceDropZoneProps = {
  id: string;
  isSwitchToggled: boolean;
  onFileSelected: (files: File[]) => void;
  setDocumentsNoTxModalIsOpen?: (state: boolean) => void;
};

export const InvoiceDropZone = ({
  id,
  isSwitchToggled,
  onFileSelected,
  setDocumentsNoTxModalIsOpen,
}: InvoiceDropZoneProps) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const documentUploadReworkFF = useCandisFeatureFlags(
    FEATURE_FLAGS.documentUploadRework
  );

  const {
    draggedFileCount,
    isAcceptedFileType,
    isFileDraggable,
    onFileDrop,
    onDragLeave,
    onDragOver,
    onDropZoneClick,
    onFilesChanged,
  } = useDragAndDrop({ fileUploadRef, onFileSelected });

  const { statusText, canUserDropFile } = useDraggedFileStatus(
    draggedFileCount,
    isAcceptedFileType
  );

  const handleFileSelect = (files: FileList | null) => {
    if (files) {
      onFileSelected(Array.from(files));
    }
  };

  return (
    <Grid
      gap="space24"
      draggable={isSwitchToggled ? false : isFileDraggable}
      onDrop={isSwitchToggled ? undefined : onFileDrop}
      onDragOver={isSwitchToggled ? undefined : onDragOver}
      onClick={isSwitchToggled ? undefined : onDropZoneClick}
      onDragLeave={isSwitchToggled ? undefined : onDragLeave}
    >
      {documentUploadReworkFF ? (
        <FilePicker id="filepicker" onSelect={handleFileSelect}>
          <div className="flex flex-col items-center gap-2 py-6">
            <Icon size={28} icon="menuUpload" />
            <span>
              <Trans
                i18nKey="transactions:transactionAssociation.invoiceEmptyState.documentUpload.dropZone"
                components={{ strong: <strong /> }}
              />
            </span>
          </div>
          <UploadDropZone onDrop={handleFileSelect}>
            <Icon size={28} icon="menuUpload" />
            <Trans
              i18nKey="transactions:transactionAssociation.invoiceEmptyState.documentUpload.dropZoneDragDrop"
              components={{ strong: <strong /> }}
            />
          </UploadDropZone>
        </FilePicker>
      ) : (
        <>
          <Flex
            borderRadius="medium"
            minHeight="185px"
            paddingX="space20"
            paddingY="space18"
            paddingTop={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            opacity={isSwitchToggled ? '30%' : 1}
            background={
              isFileDraggable
                ? getColorCode('background', canUserDropFile)
                : 'gray200'
            }
            border="none"
            style={{
              cursor: isSwitchToggled ? 'default' : 'pointer',
            }}
          >
            <Icon
              size="60px"
              icon={canUserDropFile ? 'upload' : 'warning'}
              color={
                isFileDraggable
                  ? getColorCode('color', canUserDropFile)
                  : 'gray500'
              }
            />

            <Flex
              direction="column"
              alignItems="center"
              gap="space8"
              fontSize="basic"
              color={canUserDropFile ? 'gray500' : 'red500'}
              textAlign="center"
            >
              <Text
                as="label"
                htmlFor={id}
                onClick={
                  // @TODO explicit MouseEvent<HTMLSpanElement> type shoud not be
                  // required.
                  // See: https://github.com/microsoft/TypeScript/issues/44596
                  (e: MouseEvent<HTMLSpanElement>) => e.preventDefault()
                }
              >
                {statusText()}
              </Text>
            </Flex>
          </Flex>
        </>
      )}
      <input
        id={id}
        type="file"
        data-cy="file-upload"
        disabled={isSwitchToggled}
        accept={acceptedFileType}
        ref={fileUploadRef}
        onChange={onFilesChanged}
        hidden
      />
      <UploadDocumentButton
        isSwitchToggled={isSwitchToggled}
        onDropZoneClick={onDropZoneClick}
        setDocumentsNoTxModalIsOpen={setDocumentsNoTxModalIsOpen}
      />
    </Grid>
  );
};

const UploadDocumentButton = ({
  isSwitchToggled,
  onDropZoneClick,
  setDocumentsNoTxModalIsOpen,
}: {
  isSwitchToggled: boolean;
  onDropZoneClick: () => void;
  setDocumentsNoTxModalIsOpen?: (state: boolean) => void;
}) => {
  const [tTransaction] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const currentUser = useCurrentUser();

  const isUserAppover = isOnlyApprover(currentUser);

  return (
    <>
      {isUserAppover ? (
        <Button
          // @TODO explicit MouseEvent<HTMLButtonElement> type should not be required
          // See: https://github.com/microsoft/TypeScript/issues/44596
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onDropZoneClick();
          }}
          icon="upload"
          size="small"
          justifySelf="center"
        >
          {tTransaction('transactionAssociation.invoiceEmptyState.ctaButton')}
        </Button>
      ) : (
        <Flex gap="space16" alignItems="center" justifyContent="center">
          <Button
            size="small"
            disabled={isSwitchToggled}
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              onDropZoneClick();
            }}
          >
            {tTransaction(
              'transactionAssociation.invoiceEmptyState.linkFromDisk'
            )}
          </Button>
          <Text color="gray500" fontSize="basic">
            {tTransaction('transactionAssociation.invoiceEmptyState.or')}
          </Text>
          <Button
            size="small"
            variant="secondary"
            disabled={isSwitchToggled}
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              if (setDocumentsNoTxModalIsOpen) {
                setDocumentsNoTxModalIsOpen(true);
              }
            }}
          >
            {tTransaction(
              'transactionAssociation.invoiceEmptyState.linkFromCandis'
            )}
          </Button>
        </Flex>
      )}
    </>
  );
};

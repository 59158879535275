import { useCreditCardLimitOrgQuery } from 'generated-types/graphql.types';

export const useGetCreditCardLimits = () => {
  const { data, loading } = useCreditCardLimitOrgQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { totalLimit, availableLimit } =
    data?.organization?.creditCardsDetails || {};

  return { totalLimit, availableLimit, loading };
};

import { ReactNode } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { Key } from 'react-aria-components';
import { ButtonItem } from './ButtonItem';
import { ButtonGroup } from '../ButtonGroup';

interface PerDiemMealsButtonGroupProps<TFormValues extends FieldValues> {
  buttons: Array<{
    id: string;
    displayValue: ReactNode;
  }>;
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Called when field value changes */
  onChange?: (value: Array<Key>) => void;
}

export const PerDiemMealsButtonGroup = <TFormValues extends FieldValues>({
  buttons,
  name,
  control,
  onChange: onChangeProp,
}: PerDiemMealsButtonGroupProps<TFormValues>) => {
  const { field } = useController({ control, name });

  const { onChange, value, ...restProps } = field;

  const handleChange = (keys: Set<Key>) => {
    const newSelectedKeys = Array.from(keys);
    onChange(newSelectedKeys);
    onChangeProp?.(newSelectedKeys);
  };

  return (
    <ButtonGroup
      onChange={handleChange}
      value={(value ?? []).length ? value : []}
      {...restProps}
    >
      {buttons.map((button, index) => (
        <ButtonItem {...button} key={index} />
      ))}
    </ButtonGroup>
  );
};

import { Box, Grid, Icon } from '@candisio/design-system';
import { motion } from 'motion/react';
import { ReactNode } from 'react';

const MotionIcon = motion.create(Icon);
const MotionGrid = motion.create(Grid);
const MotionBox = motion.create(Box);

export const AnimatedAccordion = ({
  collapsed,
  children,
}: {
  collapsed: boolean;
  children?: ReactNode;
}) => {
  return (
    <MotionGrid
      initial={false}
      animate={collapsed ? 'collapsed' : 'open'}
      variants={{
        open: { opacity: 1, height: 'max-content' },
        collapsed: { opacity: 0, height: 0 },
      }}
      transition={{
        height: {
          duration: 0.5,
        },
        opacity: {
          duration: 0.25,
          delay: 0.15,
        },
        ease: 'easeInOut',
      }}
      overflow="hidden"
      borderBottomRadius="inherit"
    >
      {children}
    </MotionGrid>
  );
};

export const AnimatedCaret = ({ open }: { open: boolean }) => {
  return (
    <MotionIcon
      icon="caretDown"
      size="space24"
      color="gray500"
      animate={open ? 'open' : 'closed'}
      variants={{
        open: { rotate: 180 },
        closed: { rotate: 0 },
      }}
      transition={{
        rotate: { duration: 0.25 },
        ease: 'easeInOut',
      }}
    />
  );
};

export const AnimatedProgressBar = ({ progress }: { progress: number }) => {
  return (
    <Box background="gray250" height={8}>
      <MotionBox
        initial={{ width: 0 }}
        animate={{ width: `${progress}%` }}
        transition={{
          width: {
            duration: 0.5,
            ease: 'easeInOut',
          },
        }}
        height="100%"
        background="green500"
      ></MotionBox>
    </Box>
  );
};

import { Button, Flex, Grid, Spinner } from '@candisio/design-system';
import { AnimatePresence, Variants, motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const MotionButton = motion.create(Button);
const MotionFlex = motion.create(Flex);
const MotionGrid = motion.create(Grid);

const elementVariants: Variants = {
  hidden: {
    visibility: 'hidden' as const,
    width: 0,
    opacity: 0,
    transition: { duration: 0.2, delay: 0.1 },
  },
  visible: {
    visibility: 'visible' as const,
    width: 'inherit',
    opacity: 1,
    transition: { duration: 0.3 },
  },
};

const buttonVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0.8,
    transition: { duration: 0.2 },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.2 },
  },
};

interface CTAButtonsProps {
  isFormDirty: boolean;
  resetField: () => void;
  isLoading?: boolean;
}

export const QuickSaveButtons = ({
  isFormDirty,
  resetField,
  isLoading = false,
}: CTAButtonsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <AnimatePresence>
      {isFormDirty && (
        <MotionFlex
          variants={elementVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          gap="space8"
        >
          {!isLoading ? (
            <MotionButton
              variants={buttonVariants}
              initial="hidden"
              animate="visible"
              icon="check"
              type="submit"
              color="green"
              variant="tertiary"
              label={t('organisation.actions.save')}
            />
          ) : (
            <MotionGrid
              variants={buttonVariants}
              animate="visible"
              width="space36"
              height="space36"
              alignItems="center"
              justifyContent="center"
              borderRadius="full"
            >
              <Spinner size="space20" />
            </MotionGrid>
          )}
          {!isLoading && (
            <MotionButton
              variants={buttonVariants}
              initial="hidden"
              animate="visible"
              width="36px"
              height="36px"
              type="reset"
              color="red"
              variant="tertiary"
              onClick={resetField}
              icon="close"
              label={t('organisation.actions.cancel')}
            />
          )}
        </MotionFlex>
      )}
    </AnimatePresence>
  );
};

import { ProcessingFormAccountingDataFieldItem } from 'components/Form/ProcessingForm/ProcessingFormAccountingFields';
import {
  IsFieldEditable,
  Split,
  SplitBookingFields,
  SplitBookingsFormFieldOptions,
  SplitDS,
  TaxPresentation,
} from 'components/Form/SplitBookingsForm/types';
import { maximumPrecisionForSapItemInvoices } from 'constants/sap';
import { grossToNet, netToGross } from 'containers/SplitBookings/toolkit/utils';
import {
  BookingKeyDataFragment,
  IntegrationName,
  Maybe,
} from 'generated-types/graphql.types';
import { isDate, isEqual } from 'lodash';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';

type DetermineIsFieldEditableProps = {
  openedIndex: number;
  isFieldEditable: IsFieldEditable;
  fieldOptions: SplitBookingsFormFieldOptions;
};
export const determineIsFieldEditable = ({
  openedIndex,
  isFieldEditable,
  fieldOptions,
}: DetermineIsFieldEditableProps): Record<keyof SplitDS, boolean> => {
  return {
    taxPresentation: true,
    id: false,
    amount: isFieldEditable(SplitBookingFields.amount, openedIndex),
    vatRate: isFieldEditable(SplitBookingFields.vatRate, openedIndex),
    quantity: isFieldEditable(SplitBookingFields.quantity, openedIndex),
    dueDate: isFieldEditable(SplitBookingFields.dueDate, openedIndex),
    generalLedgerAccount:
      isFieldEditable(SplitBookingFields.generalLedgerAccountId, openedIndex) &&
      !fieldOptions?.generalLedgerAccount?.props?.readOnly,
    taxCode:
      isFieldEditable(SplitBookingFields.bookingKeyId, openedIndex) &&
      !fieldOptions?.taxCode?.props?.readOnly,
    costCenter:
      isFieldEditable(SplitBookingFields.costCenterId, openedIndex) &&
      !fieldOptions?.costCenter?.props?.readOnly,
    costObject:
      isFieldEditable(SplitBookingFields.costObjectId, openedIndex) &&
      !fieldOptions?.costObject?.props?.readOnly,
    extraCostInfo:
      isFieldEditable(SplitBookingFields.extraCostInfoId, openedIndex) &&
      !fieldOptions?.extraCostInfo?.props?.readOnly,
    artistSocialInsuranceCode:
      isFieldEditable(
        SplitBookingFields.artistSocialInsuranceCode,
        openedIndex
      ) && !fieldOptions?.artistSocialInsuranceCode?.props?.readOnly,
    note: isFieldEditable(SplitBookingFields.note, openedIndex),
    postingText:
      isFieldEditable(SplitBookingFields.postingText, openedIndex) &&
      !fieldOptions?.postingText?.props?.readOnly,
    netAmount: isFieldEditable(SplitBookingFields.netAmount, openedIndex),
    taxAmount: isFieldEditable(SplitBookingFields.taxAmount, openedIndex),
    sapExpenseType: false,
    unitPrice: isFieldEditable(SplitBookingFields.unitPrice, openedIndex),
    projectCode:
      isFieldEditable(SplitBookingFields.projectCodeId, openedIndex) &&
      !fieldOptions?.projectCode?.props?.readOnly,
  };
};

type DetermineIsFieldHiddenProps = {
  booking?: SplitDS;
  fieldOptions: SplitBookingsFormFieldOptions;
};

export const determineIsFieldHidden = ({
  booking,
  fieldOptions,
}: DetermineIsFieldHiddenProps): Record<keyof SplitDS, boolean> => {
  return {
    generalLedgerAccount:
      !!(
        fieldOptions?.generalLedgerAccount?.hidden &&
        !booking?.generalLedgerAccount?.value
      ) || !!booking?.sapExpenseType,
    taxCode: !!(fieldOptions?.taxCode?.hidden && !booking?.taxCode),
    costCenter: !!(
      fieldOptions?.costCenter?.hidden && !booking?.costCenter?.value
    ),
    costObject: !!(
      fieldOptions?.costObject?.hidden && !booking?.costObject?.value
    ),
    artistSocialInsuranceCode: !!(
      fieldOptions?.artistSocialInsuranceCode?.hidden &&
      !booking?.artistSocialInsuranceCode
    ),
    extraCostInfo: !!(
      fieldOptions?.extraCostInfo?.hidden && !booking?.extraCostInfo?.value
    ),
    postingText: !!(fieldOptions.postingText?.hidden && !booking?.postingText),
    taxPresentation: false,
    amount: false,
    vatRate: false,
    quantity:
      (fieldOptions.quantity?.hidden || !!booking?.sapExpenseType) ?? false,
    note: false,
    dueDate: false,
    id: false,
    taxAmount: false,
    netAmount: false,
    sapExpenseType: true,
    unitPrice: false,
    projectCode: !!fieldOptions?.projectCode?.hidden,
  };
};

type OnChangeFactoryProps = {
  bookings: SplitDS[];
  openedIndex: number;
  setBookings: (bookings: SplitDS[]) => void;
  setAmount: (value: number) => void;
  setTaxAmount: (value: number) => void;
  setNetAmount: (value: number) => void;
  setVatRate: (value: number) => void;
  bookingKeys: BookingKeyDataFragment[];
  integration: IntegrationName;
  setUnitPrice?: (value: number) => void;
};

export const onChangeFactory = ({
  bookings,
  openedIndex,
  setBookings,
  setAmount,
  bookingKeys,
  setNetAmount,
  setTaxAmount,
  setVatRate,
  integration,
  setUnitPrice,
}: OnChangeFactoryProps) => {
  const onChangeComboBoxField = <T extends unknown>(
    existingItems: ProcessingFormAccountingDataFieldItem[] | undefined,
    field: keyof SplitDS,
    value: T
  ) => {
    const item = (existingItems || []).find(item => item.key === value);

    const updatedBookings = bookings.map((booking, index) => {
      if (index !== openedIndex) return booking;

      return item
        ? {
            ...booking,
            [field]: {
              value: item.key,
              inputValue: item.children ?? '',
            },
          }
        : // TMI-968 when the value is pre-filled, but not part of the existingItems
          value
          ? booking
          : // if value is not present, we want to unset the field
            {
              ...booking,
              [field]: value,
            };
    });

    setBookings(updatedBookings);
  };

  const onChangeField = <T extends unknown>(field: keyof SplitDS, value: T) => {
    const updatedBookings = bookings.map((booking, index) =>
      index === openedIndex
        ? {
            ...booking,
            [field]: value,
          }
        : booking
    );

    setBookings(updatedBookings);
  };

  const updateBooking = (partialBooking: Partial<SplitDS>) => {
    const updatedBookings = bookings.map((booking, index) =>
      index === openedIndex
        ? {
            ...booking,
            ...partialBooking,
          }
        : booking
    );

    setBookings(updatedBookings);
  };

  const calculateUpdatedAmount = ({
    amount,
    currentTaxPresentation,
    newTaxPresentation,
    vatRate,
  }: {
    amount: Maybe<number>;
    currentTaxPresentation: TaxPresentation | undefined;
    newTaxPresentation: TaxPresentation | null;
    vatRate: Maybe<number>;
  }) => {
    const isNetToGross =
      currentTaxPresentation === TaxPresentation.Net &&
      newTaxPresentation === TaxPresentation.Gross;

    const isGrossToNet =
      currentTaxPresentation === TaxPresentation.Gross &&
      newTaxPresentation === TaxPresentation.Net;

    let updatedAmount = amount;

    if (isNetToGross) {
      updatedAmount =
        vatRate != null ? netToGross(amount ?? 0, vatRate) : amount;
    } else if (isGrossToNet) {
      updatedAmount =
        vatRate != null ? grossToNet(amount ?? 0, vatRate) : amount;
    }

    return updatedAmount;
  };

  const onChangeTaxPresentationReadonly = (value: TaxPresentation | null) => {
    for (const [index, booking] of bookings.entries()) {
      if (index === openedIndex) {
        const { amount, taxPresentation, vatRate } = booking;

        const updatedAmount = calculateUpdatedAmount({
          amount,
          currentTaxPresentation: taxPresentation,
          newTaxPresentation: value,
          vatRate,
        });

        setAmount(updatedAmount ?? 0);
      }
    }
  };

  const onChangeTaxCode = (taxCode: string | null) => {
    const booking = bookings[openedIndex];
    const selectedCode = bookingKeys.find(
      bookingKey => bookingKey.id === taxCode
    );

    let amount, netAmount;
    const isAcquisitionReverse = selectedCode?.isAcquisitionReverse;

    if (isAcquisitionReverse) {
      netAmount = booking.netAmount ?? 0;
      amount = netAmount;
    } else if (integration === IntegrationName.Sap) {
      netAmount = booking.netAmount ?? 0;
      amount = netToGross(netAmount, selectedCode?.taxPercentage ?? 0);
    } else {
      amount = booking.amount ?? 0;
      netAmount = grossToNet(amount, selectedCode?.taxPercentage ?? 0);
    }

    const taxAmount = roundToCurrencyPrecision(amount - netAmount);

    setNetAmount(netAmount);
    setAmount(amount);
    setTaxAmount(taxAmount);
    setVatRate(selectedCode?.taxPercentage ?? 0);
    updateBooking({
      taxCode: taxCode,
      vatRate: selectedCode?.taxPercentage,
      netAmount,
      amount,
      taxAmount,
    });
  };

  const updateAmounts = ({
    netAmount,
    taxAmount,
    amount,
  }: {
    netAmount: number;
    taxAmount: number;
    amount: number;
  }) => {
    setTaxAmount(taxAmount);
    setNetAmount(netAmount);
    setAmount(amount);
    updateBooking({
      netAmount,
      taxAmount,
      amount,
    });
  };

  const onChangeGrossAmount = (amount: number | null) => {
    const booking = bookings[openedIndex];
    const selectedCode = bookingKeys.find(
      bookingKey => bookingKey.id === booking.taxCode
    );

    const isAcquisitionReverse = selectedCode?.isAcquisitionReverse;

    if (isAcquisitionReverse) {
      const taxAmount = 0;
      const netAmount = amount ?? 0;
      updateAmounts({ taxAmount, amount: amount ?? 0, netAmount });

      return;
    }

    const vatRate = booking.vatRate ?? selectedCode?.taxPercentage ?? 0;
    const netAmount = grossToNet(amount ?? 0, vatRate);
    const taxAmount = roundToCurrencyPrecision((amount ?? 0) - netAmount);
    updateAmounts({ netAmount, amount: amount ?? 0, taxAmount });
  };

  const onChangeVatRate = (vatRate: number | null) => {
    const booking = bookings[openedIndex];
    const netAmount = grossToNet(booking.amount ?? 0, vatRate ?? 0);
    const taxAmount = roundToCurrencyPrecision(
      (booking.amount ?? 0) - netAmount
    );

    setNetAmount(netAmount);
    setTaxAmount(taxAmount);
    setVatRate(vatRate ?? 0);
    updateBooking({
      vatRate,
      netAmount,
      taxAmount,
    });
  };

  const onChangeNetAmount = (value: number | null) => {
    const booking = bookings[openedIndex];
    const selectedCode = bookingKeys.find(
      bookingKey => bookingKey.id === booking.taxCode
    );
    const netAmount = value ?? 0;
    let amount = netToGross(netAmount, selectedCode?.taxPercentage ?? 0);
    let taxAmount = roundToCurrencyPrecision(amount - netAmount);

    const isAcquisitionReverse = selectedCode?.isAcquisitionReverse;

    if (isAcquisitionReverse) {
      taxAmount = 0;
      amount = netAmount;
    }
    const isGoodsItem = !!booking.quantity && !booking.sapExpenseType;

    if (isGoodsItem) {
      // calculate unit price = netAmount / quantity
      const unitPrice =
        netAmount && booking.quantity
          ? roundToCurrencyPrecision(
              netAmount / booking.quantity,
              maximumPrecisionForSapItemInvoices
            )
          : 0;
      setTaxAmount(taxAmount);
      setNetAmount(netAmount);
      setAmount(amount);
      setUnitPrice?.(unitPrice);
      updateBooking({
        unitPrice,
        taxAmount,
        amount,
        netAmount,
      });
    } else {
      updateAmounts({ taxAmount, amount, netAmount });
    }
  };

  const onChangeUnitPrice = (value: number | null) => {
    const booking = bookings[openedIndex];
    const selectedCode = bookingKeys.find(
      bookingKey => bookingKey.id === booking.taxCode
    );

    const unitPrice = value ?? 0;
    const netAmount = roundToCurrencyPrecision(
      unitPrice * (booking.quantity ?? 0)
    );

    const amount = netToGross(netAmount, selectedCode?.taxPercentage ?? 0);
    const taxAmount = roundToCurrencyPrecision(amount - netAmount);
    setTaxAmount(taxAmount);
    setNetAmount(netAmount);
    setAmount(amount);
    updateBooking({
      taxAmount,
      amount,
      netAmount,
      unitPrice: roundToCurrencyPrecision(
        unitPrice,
        maximumPrecisionForSapItemInvoices
      ),
    });
  };

  const onChangeQuantity = (value: number | null) => {
    const booking = bookings[openedIndex];
    const selectedCode = bookingKeys.find(
      bookingKey => bookingKey.id === booking.taxCode
    );

    const quantity = value ?? 0;
    const unitPrice = roundToCurrencyPrecision(
      booking.unitPrice ?? 0,
      maximumPrecisionForSapItemInvoices
    );
    const netAmount = roundToCurrencyPrecision(unitPrice * quantity);
    const amount = netToGross(netAmount, selectedCode?.taxPercentage ?? 0);
    const taxAmount = roundToCurrencyPrecision(amount - netAmount);
    setTaxAmount(taxAmount);
    setNetAmount(netAmount);
    setAmount(amount);
    updateBooking({ taxAmount, amount, netAmount, quantity });
  };

  const onChangeTaxAmount = (value: number | null) => {
    const booking = bookings[openedIndex];
    const taxAmount = value ?? 0;
    const netAmount = booking.netAmount ?? 0;
    const amount = roundToCurrencyPrecision(taxAmount + netAmount);
    updateAmounts({ taxAmount, amount, netAmount });
  };

  return {
    onChangeComboBoxField,
    onChangeField,
    onChangeTaxPresentationReadonly,
    onChangeTaxCode,
    onChangeGrossAmount,
    onChangeVatRate,
    onChangeNetAmount,
    onChangeTaxAmount,
    onChangeQuantity,
    onChangeUnitPrice,
  };
};

export const bookingsHaveSameDueDate = (bookingsArr: Split[]): boolean =>
  bookingsArr
    .map(({ dueDate }) => dueDate)
    .filter(isDate)
    .every((dueDate, idx, arr) => {
      if (bookingsArr.length !== arr.length) {
        return false;
      }

      return idx !== 0 ? isEqual(dueDate, arr[idx - 1]) : true;
    });

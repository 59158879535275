import {
  Button,
  Card,
  Flex,
  MenuButton,
  MenuItem,
  Skeleton,
  Text,
} from '@candisio/design-system';
import { VendorsForRecurringPaymentsSortField } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key, MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface CollapsibleHeaderProps {
  totalRecurringPayments: number;
  showSuggestedVendors: boolean;
  numOfVendorSuggestions: number;
  sortBy: VendorsForRecurringPaymentsSortField;
  isLoading: boolean;
  onCreatePaymentClick: () => void;
  onSortClick: (value: Key[]) => void;
  onClick: (card: 'vendorSuggestions' | 'recurringPayments') => void;
}

const MotionCard = motion.create(Card);

export const scale = { scale: 1.003 };

export const CollapsibleHeader = ({
  totalRecurringPayments,
  showSuggestedVendors,
  numOfVendorSuggestions,
  sortBy,
  isLoading,
  onCreatePaymentClick,
  onSortClick,
  onClick,
}: CollapsibleHeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { isCardManager } = useUserRoles();

  const title = totalRecurringPayments
    ? t('insights.recurringPaymentsWidget.recurringPayments.title', {
        count: totalRecurringPayments,
      })
    : t('insights.recurringPaymentsWidget.recurringPayments.emptyList');

  const isListExpandable = numOfVendorSuggestions && totalRecurringPayments > 0;

  const handleClick = () => {
    if (!isListExpandable) {
      return;
    }

    onClick('recurringPayments');
  };

  const handleCreatePaymentClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCreatePaymentClick();
  };

  const sortItems: Iterable<MenuItem> = useMemo(() => {
    return [
      {
        id: VendorsForRecurringPaymentsSortField.Name,
        label: t('recurringPaymentList.sort.byVendorName'),
      },
      {
        id: VendorsForRecurringPaymentsSortField.CardRefNum,
        label: t('recurringPaymentList.sort.byCardNumber'),
      },
      {
        id: VendorsForRecurringPaymentsSortField.CardholderFullName,
        label: t('recurringPaymentList.sort.byCardholder'),
      },
      {
        id: VendorsForRecurringPaymentsSortField.RecentlyLinked,
        label: t('recurringPaymentList.sort.byNewestFirst'),
      },
    ];
  }, [t]);

  const sortButtonText = Array.from(sortItems).find(
    item => item.id === sortBy
  )?.label;

  return (
    <MotionCard
      background="gray200"
      whileHover={isListExpandable ? scale : undefined}
      borderBottomRadius={
        totalRecurringPayments > 0 && showSuggestedVendors ? 'none' : 'medium'
      }
      style={{ cursor: isListExpandable ? 'pointer' : 'default' }}
      onClick={handleClick}
    >
      <Flex alignItems="center" justifyContent="space-between">
        {isLoading ? (
          <Skeleton height="space32" width="space128" />
        ) : (
          <Text fontSize="basic" fontWeight="bold">
            {title}
          </Text>
        )}

        <Flex alignItems="center" gap="space10">
          <MenuButton
            items={sortItems}
            selectionMode="single"
            size="small"
            variant="tertiary"
            value={[sortBy]}
            onChange={value => onSortClick(value)}
          >
            {sortButtonText}
          </MenuButton>
          {isCardManager && (
            <Button
              icon="plus"
              size="small"
              onClick={(e: MouseEvent<HTMLButtonElement>) =>
                handleCreatePaymentClick(e)
              }
            >
              {t(
                'insights.recurringPaymentsWidget.recurringPayments.createPaymentCTA'
              )}
            </Button>
          )}

          {isListExpandable ? (
            <Button
              size="xsmall"
              variant="tertiary"
              icon={showSuggestedVendors ? 'caretUp' : 'caretDown'}
            />
          ) : null}
        </Flex>
      </Flex>
    </MotionCard>
  );
};

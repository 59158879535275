import { useApolloClient } from '@apollo/client';
import {
  CostCenterTypes,
  CostCentersGetForDuplicationCheckQuery,
  QueryCostCentersGetForDuplicationCheckArgs,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { costCentersGetForDuplicationCheck } from 'views/Settings/CostCenters/components/CostCenterImport/gql';
import { ZodIssueCode, z } from 'zod';

export const useCostCenterSchema = (costCenterCodeToBeUpdated?: string) => {
  const client = useApolloClient();
  const [t] = useTranslation();

  const formSchema = z.object({
    code: z.string(),
    type: z.enum([
      CostCenterTypes.CostCenter,
      CostCenterTypes.ExtraCostInfo,
      CostCenterTypes.CostObject,
    ]),
    name: z.string().nullish(),
    approvers: z.array(z.string()),
  });

  const schema = formSchema.superRefine(async ({ code, type }, ctx) => {
    const trimmedCode = code.trim();

    if (trimmedCode === costCenterCodeToBeUpdated) return;

    const result = await client.query<
      CostCentersGetForDuplicationCheckQuery,
      QueryCostCentersGetForDuplicationCheckArgs
    >({
      query: costCentersGetForDuplicationCheck,
      variables: {
        costCenterCodes: [trimmedCode],
        costCenterType: type,
      },
      fetchPolicy: 'no-cache',
    });

    const duplicates = result.data.costCentersGetForDuplicationCheck;

    if (duplicates.includes(trimmedCode)) {
      ctx.addIssue({
        path: ['code'],
        code: ZodIssueCode.custom,
        message: t('settings.costCenter.details.form.errors.duplicate'),
      });
    }

    const alphanumericRegEx = /^[a-zA-Z0-9_]*$/;

    if (type === CostCenterTypes.ExtraCostInfo) {
      if (code.length > 5) {
        ctx.addIssue({
          path: ['code'],
          code: ZodIssueCode.custom,
          message: t('settings.costCenter.details.form.errors.maxChars'),
        });
      } else if (!alphanumericRegEx.test(code)) {
        ctx.addIssue({
          path: ['code'],
          code: ZodIssueCode.custom,
          message: t('settings.costCenter.details.form.errors.noSpecialChars'),
        });
      }
    }
  });

  const errorMessages = {
    code: {
      label: 'settings.costCenter.details.form.code.label',
    },
    name: { label: 'settings.costCenter.details.form.name.label' },
    type: { label: 'settings.costCenter.details.form.type.label' },
    approvers: {
      label: 'settings.costCenter.details.form.approvers.label',
    },
  };

  return {
    schema,
    errorMessages,
  };
};

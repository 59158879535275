import {
  useCountPendingDocumentsAndReimbursementsByMembershipLazyQuery,
  useCountPendingDocumentsByMembershipLazyQuery,
} from 'generated-types/graphql.types';
import { useFetchOnWindowFocus } from 'hooks/useFetchOnWidowFocus';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';

interface UsePendingCountsProps {
  membershipId: string;
  setPendingCount: (value: number) => void;
}

export const usePendingCounts = ({
  membershipId,
  setPendingCount,
}: UsePendingCountsProps) => {
  const { canUseReimbursement } = useReimbursement();

  const [
    fetchCountPendingDocuments,
    { loading: pendingDocumentsLoading, previousData },
  ] = useCountPendingDocumentsByMembershipLazyQuery({
    variables: { membershipId },
    fetchPolicy: 'network-only',
    // We need to always make a fresh request to the server.
    // Since the user will open a new tab and resolve involvement
    // it would be bad UX to have the user refresh the page afterwards
    // so we refetch the queries when the tab regains focus
    onCompleted: data => {
      const pendingDocumentsCount =
        data?.countPendingDocumentsByMembership.count ?? 0;

      setPendingCount(pendingDocumentsCount);
    },
  });

  const [
    fetchCountPendingDocumentsAndReimbursements,
    {
      loading: loadingDocumentsAndReimbursements,
      previousData: prevDataDocumentsAndReimbursements,
    },
  ] = useCountPendingDocumentsAndReimbursementsByMembershipLazyQuery({
    variables: { membershipId },
    fetchPolicy: 'network-only',
    // We need to always make a fresh request to the server.
    // Since the user will open a new tab and resolve involvement
    // it would be bad UX to have the user refresh the page afterwards
    // so we refetch the queries when the tab regains focus
    onCompleted: data => {
      const pendingDocumentsCount = data?.pendingDocuments.count ?? 0;

      const pendingReimbursementsCount = data?.pendingReimbursements.count ?? 0;

      const totalCount = pendingDocumentsCount + pendingReimbursementsCount;

      setPendingCount(totalCount);
    },
  });

  useFetchOnWindowFocus({
    fetch: canUseReimbursement
      ? fetchCountPendingDocumentsAndReimbursements
      : fetchCountPendingDocuments,
  });

  return {
    pendingDocumentsLoading:
      (pendingDocumentsLoading &&
        typeof previousData?.countPendingDocumentsByMembership ===
          'undefined') ||
      (loadingDocumentsAndReimbursements &&
        typeof prevDataDocumentsAndReimbursements?.pendingReimbursements ===
          'undefined' &&
        typeof prevDataDocumentsAndReimbursements?.pendingDocuments ===
          'undefined'),
  };
};

import { Grid, Modal } from '@candisio/design-system';
import { AccountingInfoFormContainer } from 'containers/credit-cards/CreateCreditCard/AccountingInfoStep/AccountingInfoFormContainer';
import { CardSection } from 'containers/credit-cards/CreateCreditCard/CardSection/CardSection';
import { SuccessStepCardSection } from 'containers/credit-cards/CreateCreditCard/CardSection/SuccessStepCardSection';
import { CardUsageFormContainer } from 'containers/credit-cards/CreateCreditCard/CardUsageStep/CardUsageFormContainer';
import {
  useCreateCreditCardModalSetState,
  useCreateCreditCardModalState,
} from 'containers/credit-cards/CreateCreditCard/CreateCreditCardModalDataProvider';
import { CreateCreditCardModalHeader } from 'containers/credit-cards/CreateCreditCard/CreateCreditCardModalHeader';
import { SelectMememberStepContainer } from 'containers/credit-cards/CreateCreditCard/SelectMemberStepContainer';
import { SuccessStep } from 'containers/credit-cards/CreateCreditCard/SuccessStep/SuccessStep';
import { useModalInitialState } from 'containers/credit-cards/CreateCreditCard/utils/utils';
import { useHasAlreadyPhysicalCard } from 'containers/credit-cards/CreditCardDrawer/Tabs/Request/hooks/useHasAlreadyPhysicalCard';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { VendorNameForm } from '../../CreatePayment/VendorNameForm';
import { LimitsAndCardType } from './LimitsAndCardType';
import { LinkExisitingCardToVendorFormContainer } from './LinkExistingCardToVendorFormContainer';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useNavigate } from 'react-router-dom-v5-compat';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';

interface RecurringPaymentsModalProps {
  suggestedAmount: number;
  onSetSuggestedAmount: (value: number) => void;
}

export const RecurringPaymentsModal = ({
  suggestedAmount,
  onSetSuggestedAmount,
}: RecurringPaymentsModalProps) => {
  const initialState = useModalInitialState();
  const navigate = useNavigate();
  const setModalState = useCreateCreditCardModalSetState();
  const { formStep, teamMemberId } = useCreateCreditCardModalState();
  const { vendorId } = useParams<{ vendorId: string }>();
  const { hasPhysicalCard } = useHasAlreadyPhysicalCard(teamMemberId);
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const handleClose = () => {
    setModalState(initialState);

    // Since the suggested amount it's coming from the list and not directly from the vendor query
    // . . . we need to make sure we reset it every time we close the modal otherwise we would run into some race condition
    onSetSuggestedAmount(0);
    navigate(-1);
  };

  const handleCardCreatedSuccess = (cardId: string) => {
    setModalState(prevState => ({
      ...prevState,
      formStep: 'Success',
      createdCreditCardId: cardId,
      cardDetails: {
        ...prevState.cardDetails,
      },
    }));
  };

  // Based on the step, the modal has different custom layouts. This is why the code is WET and we have different return statements.

  if (formStep === 'CreateVendor') {
    return (
      <Modal
        background="gray200"
        isOpen={true}
        width="42.5rem"
        closeLabel={t('modal.close')}
        isDismissable={true}
        onClose={handleClose}
        title={t('recurringPaymentsModal.linkCard.modalTitle')}
      >
        <VendorNameForm
          onNextStep={() =>
            setModalState(prevState => ({ ...prevState, formStep: 'LinkCard' }))
          }
        />
      </Modal>
    );
  }

  if (formStep === 'LinkCard') {
    return (
      <Modal
        isOpen={true}
        width="76.75rem"
        height="48rem"
        padding="2px"
        paddingBottom="0px"
        background="gray200"
        isDismissable={true}
        onClose={handleClose}
      >
        <LinkExisitingCardToVendorFormContainer
          onAddRecurringPayment={() =>
            setModalState(prevState => ({
              ...prevState,
              formStep: 'TeamMember',
            }))
          }
          onClose={handleClose}
        />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={!isNil(vendorId)}
      padding="2px"
      width="76.75rem"
      height="48rem"
      onClose={handleClose}
    >
      <Grid
        templateColumns={formStep === 'Success' ? '50% 50%' : '30% 70%'}
        height="100%"
      >
        {formStep === 'Success' ? <SuccessStepCardSection /> : <CardSection />}
        <Grid
          background="gray50"
          paddingTop="space16"
          paddingBottom="0px"
          height="100%"
          overflow="hidden"
          templateRows="auto 1fr"
        >
          <CreateCreditCardModalHeader onClose={handleClose} />
          {formStep === 'TeamMember' && <SelectMememberStepContainer />}
          {formStep === 'CardTypeAndLimits' && (
            <LimitsAndCardType
              hasPhysicalCard={hasPhysicalCard}
              suggestedAmount={suggestedAmount}
            />
          )}
          {formStep === 'CardUsage' && <CardUsageFormContainer />}
          {formStep === 'AccountingInfo' && (
            <AccountingInfoFormContainer
              onCardCreatedSuccess={handleCardCreatedSuccess}
              suggestedAmount={suggestedAmount}
            />
          )}
          {formStep === 'Success' && <SuccessStep onClose={handleClose} />}
        </Grid>
      </Grid>
    </Modal>
  );
};

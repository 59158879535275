import {
  DocumentStatus,
  EcmDocumentStatus,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { isEmpty } from 'lodash';
import { Routes } from 'models';
import { useLocation } from 'react-router-dom-v5-compat';
import { ThreeWayMatchContext } from './ThreeWayMatch/ThreeWayMatchContext';
import { ThreeWayMatchWrapper } from './ThreeWayMatch/ThreeWayMatchWrapper';
import { useInboxContext } from 'views/Inbox/Context';
import { DOCUMENT_TYPES } from 'views/Inbox/DocumentProcessing/consts';
import { useDocumentTypeContext } from '../DocumentTypeContext';
import { useGetThreeWayMatchData } from './ThreeWayMatch/hooks/useGetThreeWayMatchData';
import { useSapPurchaseOrders } from './ThreeWayMatch/hooks/useSapPurchaseOrders';
import { PurchaseOrderSectionDeprecated } from './PurchaseOrderSectionDeprecated';
import { useSap } from 'orgConfig/sap';
import { useAssociatedDocuments } from './hooks/useAssociatedDocuments';
import { PurchaseOrderSection } from './PurchaseOrderSection';
import { getLinkingSource } from './utils/getLinkingSource';

interface PurchaseOrderSectionContainerProps {
  loading: boolean;
  documentId: string;
  documentAmount?: number | null;
  documentStatus?: DocumentStatus | EcmDocumentStatus;
  contactName?: string;
  accountsPayableNumber?: string | null;
}

export const PurchaseOrderSectionContainer = ({
  loading,
  documentAmount,
  documentStatus,
  documentId,
  contactName,
  accountsPayableNumber,
}: PurchaseOrderSectionContainerProps) => {
  const { shouldUseSapGoodsReceipts } = useSap();
  const { isOnlyApprover } = useUserRoles();
  const location = useLocation();
  const { result: newResult, isLoading: threeWayMatchLoading } =
    useGetThreeWayMatchData(documentId);
  // TODO TPX after release sap_goods_receipts will deprecate useSapPurchaseOrders hook
  const { purchaseOrders, isLoading: poListLoading } =
    useSapPurchaseOrders(documentId);

  const {
    associatedDocuments,
    associatedDocumentsLength,
    isLoading: isAssocDocsLoading,
  } = useAssociatedDocuments(documentId);

  const {
    useDocumentNavigationResult: { isNavigationLoading },
  } = useInboxContext();
  const { documentTypeOld, documentType } = useDocumentTypeContext();

  const isCreditMemoDocument =
    documentTypeOld === DOCUMENT_TYPES.EINGANGSGUTSCHRIFT ||
    documentType?.documentCategory?.documentType ===
      DOCUMENT_TYPES.EINGANGSGUTSCHRIFT;

  const isApprovalFormInEditMode =
    (documentStatus === DocumentStatus.Open ||
      documentStatus === DocumentStatus.Approved) &&
    location.pathname.includes(Routes.INBOX);

  // if form is in edit mode, then purchase order is editable
  const isPurchaseOrderEditable =
    !isOnlyApprover &&
    (documentStatus === DocumentStatus.New ||
      documentStatus === DocumentStatus.Rejected ||
      isApprovalFormInEditMode);

  const hidePurchaseOrderSection =
    !isPurchaseOrderEditable &&
    (shouldUseSapGoodsReceipts
      ? !associatedDocumentsLength
      : isEmpty(purchaseOrders)); // TODO TPX afer release will remove this check

  const isLoading = loading || poListLoading || isAssocDocsLoading;

  if (hidePurchaseOrderSection || isNavigationLoading) return null;
  const linkingSource = getLinkingSource(associatedDocuments);

  // TODO TPX will remove to check purchaseOrders data after full migration
  const linkedDocumentCount = shouldUseSapGoodsReceipts
    ? associatedDocumentsLength
    : purchaseOrders.length;

  return (
    <ThreeWayMatchContext.Provider
      value={{
        newData: newResult,
        isLoading: threeWayMatchLoading,
        linkedDocumentCount,
        documentStatus,
        documentId,
        contactName,
        accountsPayableNumber,
        documentAmount,
        isPurchaseOrderEditable,
        linkingSource,
      }}
    >
      <ThreeWayMatchWrapper>
        {shouldUseSapGoodsReceipts ? (
          <PurchaseOrderSection associatedDocuments={associatedDocuments} />
        ) : (
          <PurchaseOrderSectionDeprecated
            documentId={documentId}
            documentAmount={documentAmount}
            contactName={contactName}
            accountsPayableNumber={accountsPayableNumber}
            purchaseOrders={purchaseOrders}
            isLoading={!!isLoading}
            isCreditMemoDocument={isCreditMemoDocument}
          />
        )}
      </ThreeWayMatchWrapper>
    </ThreeWayMatchContext.Provider>
  );
};

import { Flex } from '@candisio/design-system';
import {
  DocumentTableColumnKeys,
  DocumentsTableData,
} from 'components/DocumentsTable/types';
import { Fragment } from 'react';
import {
  Code,
  Variant,
} from 'views/Integrations/Export/toolkit/components/ExportTableHeading';
import { ExportedDocumentsTable } from 'views/Integrations/Export/toolkit/components/ExportedDocumentsTable';

type GroupedTableProps = {
  groupedData: {
    [variant: string]: {
      [code: string]: DocumentsTableData[];
    };
  };
  columns: DocumentTableColumnKeys[];
};

export const GroupedTable = ({ groupedData, columns }: GroupedTableProps) => {
  return (
    <Flex rowGap="space32" flexGrow="1" direction="column">
      {Object.keys(groupedData).map(key => (
        <Fragment key={key}>
          {Object.entries(groupedData[key]).map(([code, tableData]) => (
            <ExportedDocumentsTable
              key={code}
              columns={columns}
              data={tableData}
              variant={key as Variant}
              code={code as Code}
            />
          ))}
        </Fragment>
      ))}
    </Flex>
  );
};

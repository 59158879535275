import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGetHandleUpdateConfigurations } from 'hooks/table/useGetHandleUpdateConfigurations';
import { useGetTableConfigurations } from 'hooks/table/useGetTableConfigurations';
import { useUpdateColumnsBE } from 'hooks/table/useUpdateColumnsBE';
import { useResetTableConfigurations } from 'hooks/useResetTableConfigurations';
import { useSaveTableConfigurations } from 'hooks/useSaveTableConfigurations';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';
import { defaultColumnsConfigurations, goodsReceiptsTableId } from '../consts';
import { GoodsReceiptsTableColumnKeys, GoodsReceiptsTableData } from '../types';

type Params = {
  filters: Filters<GoodsReceiptsTableData>;
  sortBy: SortingRule<GoodsReceiptsTableData>[];
};

export const useGetGoodsReceiptsTableConfigs = ({
  filters,
  sortBy,
}: Params) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const { success, error } = useToastMessage();
  const { configurations } = useGetTableConfigurations({
    tableId: goodsReceiptsTableId,
    defaultColumnsConfigurations,
  });

  const { isSavingConfigurations, saveTableConfigurations } =
    useSaveTableConfigurations();

  const { isResetPending, resetTableConfigurations } =
    useResetTableConfigurations();

  const handleSaveTableConfigurations = async (
    configurations: Configuration[]
  ) => {
    const response = await saveTableConfigurations({
      configurations,
      tableId: goodsReceiptsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.successMessage'));
    } else {
      error(t('configurations.errorMessage'));
    }
  };

  const handleResetTableConfigurations = async () => {
    const response = await resetTableConfigurations({
      tableId: goodsReceiptsTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.resetSuccessMessage'));
    } else {
      error(t('configurations.resetErrorMessage'));
    }
  };

  const { handleUpdateConfigurations } = useGetHandleUpdateConfigurations({
    filters,
    sortBy,
    updateConfigurationsTable: handleSaveTableConfigurations,
  });

  const visibleColumnIds = configurations
    .filter(config => config.isVisible)
    .map(config => config.id as GoodsReceiptsTableColumnKeys);

  useUpdateColumnsBE({
    tableId: goodsReceiptsTableId,
    defaultColumnsConfigurations,
  });

  return {
    visibleColumnIds,
    columnConfigurations: configurations,
    isSavingConfigurations,
    isResetPending,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  };
};

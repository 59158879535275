import {
  ProjectCodeDataFragment,
  ProjectCodeInfiniteScrollPaginationQuery,
  ProjectCodeInfiniteScrollPaginationQueryVariables,
  ProjectCodeSortField,
  SortDirection,
  useCountProjectCodesQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { projectCodeInfiniteScrollPagination } from '../gql';

interface ProjectCodeListData {
  activeCount: number;
  archivedCount: number;
  projectCodeData: ProjectCodeDataFragment[];
  isCountLoading: boolean;
  handleDebounceSearch: (inputStr: string) => void;
  isLoading: boolean;
  onEndReached: () => void;
}

export const useProjectCodeListData = ({
  showArchived,
  sortBy,
}: {
  showArchived: boolean;
  sortBy: ProjectCodeSortField;
}): ProjectCodeListData => {
  const { data: activeCount, loading: activeCountLoading } =
    useCountProjectCodesQuery({
      variables: {
        filters: {
          isArchived: false,
        },
      },
    });

  const { data: archivedCount, loading: archivedCountLoading } =
    useCountProjectCodesQuery({
      variables: {
        filters: {
          isArchived: true,
        },
      },
    });

  const computeVariables = useCallback(
    (inputStr?: string): ProjectCodeInfiniteScrollPaginationQueryVariables => ({
      filters: {
        isArchived: showArchived,
      },
      sortBy: {
        direction:
          sortBy === ProjectCodeSortField.CreatedAt
            ? SortDirection.Desc
            : SortDirection.Asc,
        field: sortBy,
      },
      queries: {
        readableName: inputStr,
      },
    }),
    [showArchived, sortBy]
  );

  const {
    data: projectCodeData,
    handleDebounceSearch,
    loading: isLoading,
    loadMore: onEndReached,
  } = useDebouncedSearchPagination<
    ProjectCodeInfiniteScrollPaginationQuery,
    ProjectCodeInfiniteScrollPaginationQueryVariables
  >({
    query: projectCodeInfiniteScrollPagination,
    queryRootKey: 'projectCodePagination',
    computeVariables,
  });

  return {
    activeCount: activeCount?.countProjectCodes ?? 0,
    archivedCount: archivedCount?.countProjectCodes ?? 0,
    projectCodeData: projectCodeData?.projectCodePagination?.records ?? [],
    handleDebounceSearch,
    isLoading,
    isCountLoading: activeCountLoading || archivedCountLoading,
    onEndReached,
  };
};

import { useState } from 'react';
import { Modal, useModal } from '@candisio/design-system';
import { AnimatePresence } from 'motion/react';
import { PromoPartOne } from './components/PromoPartOne';
import { PromoPartTwo } from './components/PromoPartTwo';
import { PromoPartThree } from './components/PromoPartThree';

export const DocumentClassificationPromoModal = ({
  isOpen,
  close,
  modalProps,
}: Pick<ReturnType<typeof useModal>, 'isOpen' | 'close' | 'modalProps'>) => {
  const [step, setStep] = useState<1 | 2 | 3>(1);

  let content;
  switch (step) {
    case 1:
      content = (
        <PromoPartOne key="part-1" onNext={() => setStep(2)} onClose={close} />
      );
      break;
    case 2:
      content = (
        <PromoPartTwo key="part-2" onNext={() => setStep(3)} onClose={close} />
      );
      break;
    case 3:
      content = (
        <PromoPartThree key="part-3" onNext={() => close()} onClose={close} />
      );
      break;
    default:
      content = (
        <PromoPartOne key="part-1" onNext={() => setStep(2)} onClose={close} />
      );
  }

  return (
    <Modal
      padding={0}
      isOpen={isOpen}
      onClose={close}
      height="75vh"
      maxHeight="720px"
      minHeight="642px"
      width="60vw"
      maxWidth="1100px"
      minWidth="1020px"
      borderRadius="medium"
      overflow="hidden"
      isDismissable={false}
      {...modalProps}
    >
      <AnimatePresence mode="popLayout">{content}</AnimatePresence>
    </Modal>
  );
};

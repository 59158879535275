import { ReactNode, forwardRef } from 'react';
import { Box } from '../../Atoms/Box';

export interface ListProps {
  children?: ReactNode;
}

export const List = forwardRef<HTMLUListElement, ListProps>(
  ({ children, ...restProps }, ref) => {
    return (
      <Box as="ul" listStyle="none" padding="0" {...restProps} ref={ref}>
        {children}
      </Box>
    );
  }
);

import {
  MembershipStatus,
  useOrganizationMembershipsLazyQuery,
} from 'generated-types/graphql.types';
import { useCallback } from 'react';

export const useGetTeamMemberByContactNameQuery = () => {
  const [organizationMemberships] = useOrganizationMembershipsLazyQuery({});

  const handleGetTeamMemberByContactName = useCallback(
    async (name: string | undefined) => {
      const response = await organizationMemberships({
        variables: {
          filters: {
            emailVerified: true,
            status: MembershipStatus.Active,
            name,
          },
        },
      });

      const teamMembers = response.data?.organizationMemberships;

      if ((teamMembers ?? []).length > 1) {
        return undefined;
      }

      const foundMemberIdByContactName = teamMembers?.[0]?.id;

      return foundMemberIdByContactName;
    },
    [organizationMemberships]
  );

  return { handleGetTeamMemberByContactName };
};

import { FilterOptionsAccessor } from '@candisio/design-system';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountingAreasList } from 'views/Settings/AccountingAreas/toolkit/hooks/useAccountingAreasList';
import { useStatusFilters } from './consts';
import { useEcm } from 'orgConfig/ecm/useEcm';

export const useApprovalDocumentsDataFilters = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const statusFilters = useStatusFilters();

  const {
    allAccountingAreasListData,
    listDataLoading: accountingAreasLoading,
  } = useAccountingAreasList();

  const { useNewApprovalQuery } = useEcm();

  const accountingAreas = useMemo(() => {
    return allAccountingAreasListData?.map(area => ({
      id: area.id,
      label: area.name,
    }));
  }, [allAccountingAreasListData]);

  const filterOptions: FilterOptionsAccessor<DocumentsTableData> =
    useMemo(() => {
      return {
        invoiceDate: useNewApprovalQuery,
        dueDate: useNewApprovalQuery,
        deliveryDate: useNewApprovalQuery,
        createdAt: useNewApprovalQuery,
        discountDateWPercentage: useNewApprovalQuery,
        requestedAt: useNewApprovalQuery,
        tags: useNewApprovalQuery,
        status: {
          data: statusFilters?.map(statusFilter => ({
            id: statusFilter.id,
            label: t(statusFilter.label),
          })),
        },
        accountingArea: {
          data: accountingAreas,
          isLoading: accountingAreasLoading,
        },
        creditCardPayment: {
          data: [
            {
              id: 'true',
              label: t('creditCardPaymentFilter.creditCardPayment'),
            },
            {
              id: 'false',
              label: t('creditCardPaymentFilter.noCreditCardPayment'),
            },
          ],
        },
      };
    }, [
      statusFilters,
      t,
      accountingAreas,
      accountingAreasLoading,
      useNewApprovalQuery,
    ]);

  return {
    filterOptions,
    filtersLoading: accountingAreasLoading,
  };
};

import { ReactNode } from 'react';
import styles from './SwimlaneTilePromoContent.module.css';

export const SwimlaneTilePromoContent = ({
  children,
}: { children: ReactNode }) => {
  return <div className={styles.content}>{children}</div>;
};

export const SwimlaneTilePromoContentBody = ({
  children,
}: { children: ReactNode }) => {
  return <div className={styles.contentBody}>{children}</div>;
};

import {
  Card,
  Flex,
  Grid,
  Heading,
  Skeleton,
  Text,
  TextField,
} from '@candisio/design-system';

import {
  ConnectionStatus,
  SapB1Settings,
} from 'generated-types/resolvers-types';
import { useGetSapB1Credentials } from 'hooks/useGetSAPB1Credentials';
import i18next from 'i18next';
import { useSap } from 'orgConfig/sap';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useSapB1SettingsQuery,
  useVerifySapB1CredentialsQuery,
} from '../../../../../generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from '../../../../../providers/LocaleProvider';
import { InfoList } from '../../DATEV/ExportTypes/components/InfoList';
import { CheckProp } from '../../DATEV/ExportTypes/types';
import { DisconnectButton, ReconnectButton } from './Buttons';
import { SapFormValues } from './Form/SapConnectionForm';
import { fieldTranslationKeys, getChecks, infoIcon } from './utils';

const SapConnectionSummaryBase = ({
  isDisconnectDisabled = false,
  isReconnectDisabled = false,
  MainContent,
  checks = [],
}: {
  isDisconnectDisabled?: boolean;
  isReconnectDisabled?: boolean;
  MainContent: ReactElement;
  checks?: CheckProp[];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);
  const { shouldUseSapUdfCheck: isSapUdfCheckEnabled } = useSap();

  return (
    <Card>
      <Grid gap="space16">
        <Grid gap="space16">
          <Flex justifyContent="space-between" alignItems="center">
            <Heading as="h3">{t('settings.form.description')}</Heading>
            <Flex gap="space8">
              {isSapUdfCheckEnabled && (
                <ReconnectButton isDisabled={isReconnectDisabled} />
              )}
              <DisconnectButton isDisabled={isDisconnectDisabled} />
            </Flex>
          </Flex>
          {MainContent}
          {(checks || []).length ? <InfoList checks={checks} animate /> : null}
        </Grid>
      </Grid>
    </Card>
  );
};

const SapConnectionSummarySuccess = ({
  values,
  checks,
}: {
  values: Omit<SapFormValues, 'password'> & Pick<SapB1Settings, 'sapB1Version'>;
  checks: CheckProp[];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  const fields = [
    {
      label: t(fieldTranslationKeys.serviceLayerUrl),
      value: values.serviceLayerUrl,
    },
    {
      label: t(fieldTranslationKeys.companyDbId),
      value: values.companyDbId,
    },
    {
      label: t(fieldTranslationKeys.username),
      value: values.username,
    },
    {
      label: t(fieldTranslationKeys.version),
      value: values.sapB1Version ?? '',
    },
  ];

  return (
    <SapConnectionSummaryBase
      checks={checks}
      isDisconnectDisabled={false}
      isReconnectDisabled={false}
      MainContent={
        <Grid columns={2} columnGap="space20" rowGap="space8">
          {fields.map(f => {
            return (
              <TextField
                key={f.label}
                input={{
                  value: f.value,
                }}
                label={f.label}
                readOnly
              />
            );
          })}
        </Grid>
      }
    />
  );
};

const SapConnectionSummaryLoading = ({
  checks,
}: {
  checks: CheckProp[];
}) => {
  return (
    <SapConnectionSummaryBase
      checks={checks}
      isDisconnectDisabled={true}
      isReconnectDisabled={true}
      MainContent={<Skeleton height="3rem" width="90%" />}
    />
  );
};

const SapConnectionSummaryError = ({
  errorMessage,
  resolutionMessage,
  values,
  isDisconnectDisabled = true,
  isReconnectDisabled = true,
}: {
  errorMessage?: string;
  resolutionMessage?: string;
  values?: Omit<SapFormValues, 'password'> &
    Pick<SapB1Settings, 'sapB1Version'>;
  isDisconnectDisabled?: boolean;
  isReconnectDisabled?: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  const fields = values
    ? [
        {
          label: t(fieldTranslationKeys.serviceLayerUrl),
          value: values.serviceLayerUrl,
        },
        {
          label: t(fieldTranslationKeys.companyDbId),
          value: values.companyDbId,
        },
        {
          label: t(fieldTranslationKeys.username),
          value: values.username,
        },
        {
          label: t(fieldTranslationKeys.version),
          value: values.sapB1Version ?? '',
        },
      ]
    : [];

  // A custom error check that follows the same format as getChecks(t).errorCheck
  const errorCheck = {
    key: 'connection-error',
    icon: getChecks(t).errorCheck.icon,
    text: (
      <Text color="red500">
        {errorMessage || t('settings.summary.credentialsNotFoundError')}
      </Text>
    ),
    state: 'loaded',
  };

  // Create a separate check for the resolution message if it exists
  const checks = [errorCheck];
  if (resolutionMessage) {
    checks.push({
      key: 'resolution-message',
      icon: infoIcon,
      text: <Text color="gray500">{resolutionMessage}</Text>,
      state: 'loaded',
    });
  }

  return (
    <SapConnectionSummaryBase
      isDisconnectDisabled={isDisconnectDisabled}
      isReconnectDisabled={isReconnectDisabled}
      MainContent={
        <Grid gap="space16">
          {values && (
            <Grid columns={2} columnGap="space20" rowGap="space8">
              {fields.map(f => (
                <TextField
                  key={f.label}
                  input={{
                    value: f.value,
                  }}
                  label={f.label}
                  readOnly
                />
              ))}
            </Grid>
          )}
        </Grid>
      }
      checks={checks}
    />
  );
};

const SapConnectionSummaryWithUdf = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  const { data, loading: loadingData } = useGetSapB1Credentials();

  const { data: sapB1Settings, loading: sapB1SettingsLoading } =
    useSapB1SettingsQuery();

  const { data: verify, loading: loadingVerify } =
    useVerifySapB1CredentialsQuery({
      // XXX we need to ensure that the credentials are verified properly every time the users visits this page
      // since the validity of the configuration could change in the external system
      fetchPolicy: 'no-cache',
    });

  const config = data?.sapB1Credentials;

  const isLoading = loadingData || loadingVerify || sapB1SettingsLoading;

  if (isLoading) {
    return <SapConnectionSummaryLoading checks={[getChecks(t).loadingCheck]} />;
  }

  const hasError =
    !config?.companyDbId || !config?.username || !config?.serviceLayerUrl;

  if (hasError) {
    return <SapConnectionSummaryError />;
  }

  const connectionStatus =
    sapB1Settings?.sapB1Settings?.connectionDetails?.connectionStatus;
  const connectionError =
    sapB1Settings?.sapB1Settings?.connectionDetails?.connectionError;

  const values = {
    companyDbId: config.companyDbId,
    username: config.username,
    serviceLayerUrl: config.serviceLayerUrl,
    sapB1Version: sapB1Settings?.sapB1Settings?.sapB1Version,
  };

  if (
    connectionStatus &&
    connectionStatus !== ConnectionStatus.ConnectedSuccessfully
  ) {
    const currentLanguage = i18next.language === 'de' ? 'de' : 'en';
    const errorMessage =
      connectionError?.errorMessage[currentLanguage] ||
      t('settings.summary.connection.errors.unexpected');
    const resolutionMessage =
      connectionError?.resolutionMessage[currentLanguage];

    return (
      <SapConnectionSummaryError
        errorMessage={errorMessage}
        resolutionMessage={resolutionMessage}
        values={values}
        isDisconnectDisabled={false}
        isReconnectDisabled={false}
      />
    );
  }

  const checks = verify?.verifySapB1Credentials?.code
    ? [getChecks(t).errorCheck]
    : [getChecks(t).successCheck];

  return <SapConnectionSummarySuccess values={values} checks={checks} />;
};

const SapConnectionSummaryWithoutUdf = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);

  const { data, loading: loadingData } = useGetSapB1Credentials();

  const { data: sapB1Settings, loading: sapB1SettingsLoading } =
    useSapB1SettingsQuery();

  const { data: verify, loading: loadingVerify } =
    useVerifySapB1CredentialsQuery({
      // XXX we need to ensure that the credentials are verified properly every time the users visits this page
      // since the validity of the configuration could change in the external system
      fetchPolicy: 'no-cache',
    });

  const config = data?.sapB1Credentials;

  const isLoading = loadingData || loadingVerify || sapB1SettingsLoading;

  if (isLoading) {
    return <SapConnectionSummaryLoading checks={[getChecks(t).loadingCheck]} />;
  }

  const hasError =
    !config?.companyDbId || !config?.username || !config?.serviceLayerUrl;

  if (hasError) {
    return <SapConnectionSummaryError />;
  }

  const values = {
    companyDbId: config.companyDbId,
    username: config.username,
    serviceLayerUrl: config.serviceLayerUrl,
    sapB1Version: sapB1Settings?.sapB1Settings?.sapB1Version,
  };

  const checks = verify?.verifySapB1Credentials?.code
    ? [getChecks(t).errorCheck]
    : [getChecks(t).successCheck];

  return <SapConnectionSummarySuccess values={values} checks={checks} />;
};

export const SapConnectionSummary = () => {
  const { shouldUseSapUdfCheck: isSapUdfCheckEnabled } = useSap();

  return isSapUdfCheckEnabled ? (
    <SapConnectionSummaryWithUdf />
  ) : (
    <SapConnectionSummaryWithoutUdf />
  );
};

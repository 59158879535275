import { Color, Tag, Text, Tooltip, useTooltip } from '@candisio/design-system';
import { Locale, TransactionStatus } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

export const mapTransactionStatusToColors: Record<TransactionStatus, Color> = {
  [TransactionStatus.Confirmed]: 'green',
  [TransactionStatus.Pending]: 'yellow',
  [TransactionStatus.Declined]: 'red',
  [TransactionStatus.Reversed]: 'gray',
};

export const mapTransactionStatusToTranslationKeys: Record<
  TransactionStatus,
  string
> = {
  [TransactionStatus.Confirmed]: 'transactions:transactionStatus.confirmed',
  [TransactionStatus.Pending]: 'transactions:transactionStatus.pending',
  [TransactionStatus.Declined]: 'transactions:transactionStatus.declined',
  [TransactionStatus.Reversed]: 'transactions:transactionStatus.reversed',
};

export const TransactionStatusTag = ({
  status,
  declinedReason,
  ...rest
}: Omit<ComponentProps<typeof Tag>, 'color' | 'variant'> & {
  status: TransactionStatus;
  declinedReason?: {
    en: string | undefined;
    de: string | undefined;
  };
}) => {
  const [t] = useTranslation();
  const currentUser = useCurrentUser();

  const locale = currentUser?.locale;

  const mappedDeclinedDescriptions: Record<Locale, string | undefined> = {
    DE: declinedReason?.de,
    EN: declinedReason?.en,
  };

  const declinedReasonLocalized =
    locale && declinedReason ? mappedDeclinedDescriptions[locale] : undefined;

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({ passiveTrigger: true });

  const color = mapTransactionStatusToColors[status];
  const textValue = mapTransactionStatusToTranslationKeys[status];

  const declinedReasonKey = `transactions:${declinedReasonLocalized}`;

  return (
    <>
      <Tag
        {...triggerProps}
        ref={triggerRef}
        color={color}
        variant="secondary"
        {...rest}
      >
        {t(textValue)}
      </Tag>
      {isOpen && status === TransactionStatus.Declined && declinedReasonKey && (
        <Tooltip {...tooltipProps} ref={tooltipRef} width="space256">
          <Text>{t(declinedReasonKey)}</Text>
        </Tooltip>
      )}
    </>
  );
};

import {
  Card,
  Flex,
  Grid,
  Tag,
  Text,
  TruncatedText,
  useTheme,
} from '@candisio/design-system';
import {
  DocumentCurrency,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { useReimbursementTags } from 'views/Reimbursement/hooks/useReimbursementTags';
import { EmptyReimbursementDetailsCard } from './EmptyReimbursementDetailsCard';
import { ReimbursementItemsCard } from './ReimbursementItemsCard';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/utils/constants';

interface ReimbursementDetailsProps {
  title?: string;
  totalAmount?: string;
  status?: ReimbursementCaseStatus;
  creatingReimbursementItem: boolean;
  items: ReimbursementItemForList[];
  onHospitalityExpenseClick: () => void;
  onGeneralExpenseClick: () => void;
  reimbursementItemsCount: number;
}

export const ReimbursementDetails = ({
  title,
  totalAmount,
  status,
  creatingReimbursementItem,
  items,
  reimbursementItemsCount,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
}: ReimbursementDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const tags = useReimbursementTags();
  const { documentMoneyFormatter } = useMoneyFormatter();
  const { space } = useTheme();

  const { searchParams } = useMutateSearchParams();

  const isItemsViewActive = !!searchParams.get(REIMBURSEMENT_URL_PARAM.VIEW);

  if (!status || !reimbursementItemsCount) {
    return <EmptyReimbursementDetailsCard />;
  }

  const fallbackAmount = documentMoneyFormatter({
    precision: 2,
    amount: 0,
    currency: DocumentCurrency.Eur,
  });

  return (
    <Card padding="space12">
      <Grid gap="space12">
        <Grid gap="space12">
          <Flex justifyContent="space-between" gap="space16">
            <TruncatedText
              fontSize="large"
              fontWeight="semibold"
              height="fit-content"
            >
              {title ?? t('dashboard.list.reimbursementItemPlaceholder')}
            </TruncatedText>
            <Tag variant="secondary" color={tags[status].color}>
              {tags[status].text}
            </Tag>
          </Flex>
          {Boolean(items.length) && isItemsViewActive && (
            <ReimbursementItemsCard
              items={items}
              creatingReimbursementItem={creatingReimbursementItem}
              onGeneralExpenseClick={onGeneralExpenseClick}
              onHospitalityExpenseClick={onHospitalityExpenseClick}
            />
          )}
          <Grid
            autoFlow="column"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Text fontSize={space.space14}>
              {t('reimbursementView.leftSection.filledDetails.description')}
            </Text>
            <Text fontSize={space.space14}>
              {totalAmount ?? fallbackAmount}
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

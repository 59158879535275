import { Box, Flex, Grid, Spinner } from '@candisio/design-system';
import { DocumentApprovalsFormProvider } from 'components/Form/DocumentApprovalsForm/DocumentApprovalsFormProvider';
import { Actions } from 'components/Form/DocumentApprovalsForm/elements/Actions';
import { SplitButton } from 'components/Form/DocumentApprovalsForm/elements/SplitButton';
import {
  DocumentApprovalFormSchemaOptions,
  DocumentApprovalFormValues,
  documentApprovalFormSchema,
} from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { AccountingArea } from 'components/Form/DocumentApprovalsForm/toolkit/fields/AccountingArea';
import { NetAmount } from 'components/Form/DocumentApprovalsForm/toolkit/fields/NetAmount';
import { PurchaseOrderNumber } from 'components/Form/DocumentApprovalsForm/toolkit/fields/PurchaseOrderNumber';
import { TaxAmount } from 'components/Form/DocumentApprovalsForm/toolkit/fields/TaxAmount';
import { TaxCode } from 'components/Form/DocumentApprovalsForm/toolkit/fields/TaxCode';
import { useApprovalState } from 'components/Form/DocumentApprovalsForm/toolkit/hooks/useApprovalState';
import { useCanFirstApproverSplitBookings } from 'components/Form/DocumentApprovalsForm/toolkit/hooks/useCanFirstApproverSplitBookings';
import { useSplitBookingsProps } from 'components/Form/DocumentApprovalsForm/toolkit/hooks/useSplitBookingsProps';
import { useSubmitData } from 'components/Form/DocumentApprovalsForm/toolkit/hooks/useSubmitData';
import { useWatchApprovalForm } from 'components/Form/DocumentApprovalsForm/toolkit/hooks/useWatchApprovalForm';
import {
  ApprovalsFormFieldOptions,
  FieldConditions,
} from 'components/Form/DocumentApprovalsForm/toolkit/types';
import {
  getSmartFieldStatus,
  toSplitDS,
} from 'components/Form/DocumentApprovalsForm/toolkit/utils';
import { RoundingAmount } from 'components/Form/DocumentForm/toolkit/fields/RoundingAmount';
import {
  determineIsFieldEditable,
  determineIsFieldHidden,
} from 'components/Form/SplitBookingsForm/toolkit/utils';
import {
  AcceptedValuesDS,
  SmartFields,
} from 'components/Form/SplitBookingsForm/types';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { SectionSeparator } from 'components/SectionSeparator/SectionSeparator';
import { SplitBookings } from 'containers/SplitBookings/SplitBookings';
import {
  sumNetAmounts,
  sumTaxAmounts,
} from 'containers/SplitBookings/toolkit/utils';
import {
  DocumentCurrency,
  GetDocumentForDraftQuery,
  GetDocumentQuery,
} from 'generated-types/graphql.types';
import { useDateConverter } from 'hooks/useDateConverter';
import { DocumentStatusFrontend } from 'models';
import { ReactNode, useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isNilOrEmpty } from 'utils/isNilOrEmpty';
import { zodResolver } from 'utils/zodFormValidation';
import { useBookingsFormContext } from 'views/DocumentDetails/BookingsFormContext';
import { ProcessingFormOverlay } from 'views/Inbox/DocumentProcessing/components/AddContact/ProcessingFormOverlay';
import {
  useIsSapGoodsInvoice,
  useShowInvoiceCorrectionField,
  useShowPurchaseOrderNumberField,
} from 'views/utils/useShouldShowField';
import { getProcessingFormErrorMessages } from '../ProcessingForm/processingFormErrorMessages';
import { CashDiscountFieldValue } from '../types';
import { useGetCalculateRoundingAmount } from '../utils';
import { BankingDetails } from './elements/BankingDetails';
import { AccountsPayable } from './toolkit/fields/AccountsPayable';
import { AccountsReceivable } from './toolkit/fields/AccountsReceivable';
import { ArtistSocial } from './toolkit/fields/ArtistSocial';
import { CashDiscount } from './toolkit/fields/CashDiscount';
import { Contact } from './toolkit/fields/Contact';
import { InvoiceCorrection } from './toolkit/fields/Correction';
import { CostCenter } from './toolkit/fields/CostCenter';
import { CostObject } from './toolkit/fields/CostObject';
import { InvoiceDates } from './toolkit/fields/Dates';
import { DocumentType } from './toolkit/fields/DocumentType';
import { DueDate } from './toolkit/fields/DueDate';
import { ExtraCostInfo } from './toolkit/fields/ExtraCostInfo';
import { GeneralLedgerAccount } from './toolkit/fields/GeneralLedgerAccount';
import { GrossAmount } from './toolkit/fields/GrossAmount';
import { Note } from './toolkit/fields/Note';
import { InvoiceNumber } from './toolkit/fields/Number';
import { PostingText } from './toolkit/fields/PostingText';
import { ProjectCode } from './toolkit/fields/ProjectCode';
import { SplitBookingsList } from './toolkit/fields/SplitBookingsList';
import { VatRate } from './toolkit/fields/VatRate';
import { useAmountFieldsTooltip } from './toolkit/hooks/useAmountFieldsTooltip';
import { useSyncApprovalFormDefaultValues } from './toolkit/hooks/useSyncApprovalFormDefaultValues';

type DocumentApprovalsFormProps = {
  document: NonNullable<
    GetDocumentForDraftQuery['getDocument'] | GetDocumentQuery['getDocument']
  >;
  fieldConditions?: FieldConditions;
  formContext: DocumentApprovalFormSchemaOptions;
  initialValues: DocumentApprovalFormValues;
  smartFields?: SmartFields | null;
  toggleEditMode?: () => void;
  isLoading: boolean;
  fieldOptions: ApprovalsFormFieldOptions;
  cycleDocument: () => void;
  tagsField?: ReactNode;
};

export const DocumentApprovalsForm = ({
  cycleDocument,
  document,
  fieldConditions,
  formContext,
  initialValues,
  smartFields = null,
  toggleEditMode,
  isLoading,
  fieldOptions,
  tagsField,
}: DocumentApprovalsFormProps) => {
  const [t] = useTranslation();
  const [defaultSplitIndex, setDefaultSplitIndex] = useState(-1);
  const [mode, setMode] = useState<'default' | 'split-bookings'>('default');
  const [readonly, setReadonly] = useState(false);

  const canSplitBookings = useCanFirstApproverSplitBookings(document.id);
  const isFormReadOnly = !canSplitBookings;
  const { setData: setBookingsFormData } = useBookingsFormContext();
  const { dateToDateString } = useDateConverter();

  const showInvoiceCorrection = useShowInvoiceCorrectionField();
  const showPurchaseOrderNumber = useShowPurchaseOrderNumberField();
  const isGoodsPurchaseOrderLinked = useIsSapGoodsInvoice(document.id);

  const form = useForm<DocumentApprovalFormValues>({
    context: formContext,
    defaultValues: initialValues,
    mode: 'onTouched',
    resolver: zodResolver({
      zodSchema: documentApprovalFormSchema,
      errorMessages: getProcessingFormErrorMessages(),
    }),
  });

  const bookings = form.getValues().bookings;

  const bookingsFieldArray = useFieldArray<
    DocumentApprovalFormValues,
    'bookings'
  >({
    control: form.control,
    name: 'bookings',
  });

  const splits = bookingsFieldArray.fields;
  const hasMultipleBookings = splits.length > 1;

  const {
    onAcceptBookings,
    onDiscardBookings,
    isFieldEditable,
    purchaseOrderType,
  } = useSplitBookingsProps({ document });

  const {
    showFormField,
    isUserInFirstActiveWorkflowStep,
    isUserInLastActiveWorkflowStep,
    hasPrevBookingsSplits,
    showVatField,
  } = useApprovalState({ document, bookings });

  const { onSubmit } = useSubmitData({
    document,
    bookings,
    cycleDocument,
    isUserInLastActiveWorkflowStep,
  });

  const {
    cashDiscount,
    contactId,
    currency,
    hasTransactions,
    invoiceDate,
    invoiceDeliveryDate,
    invoicePostingDate,
    accountingArea,
  } = initialValues ?? {};

  const {
    showAccountsPayableNumberField,
    showAccountsReceivableNumberField,
    showDueDateField,
    shouldUseSapPurchaseOrder,
    showCashDiscountFields,
    showDeliveryDate,
    showPostingDate,
    shouldUseSapNetAmount,
  } = fieldConditions ?? {};

  const isEditable = determineIsFieldEditable({
    openedIndex: 0,
    isFieldEditable,
    fieldOptions,
  });

  const isHidden = determineIsFieldHidden({
    fieldOptions,
    booking: toSplitDS(bookings[0]),
  });

  const handleSubmit = form.handleSubmit(async values => {
    const result = await onSubmit(values);
    if (result?.status === 'error' && result.submitErrors) {
      Object.entries(result.submitErrors).forEach(([path, message], index) => {
        form.setError(
          path as keyof DocumentApprovalFormValues,
          { message },
          { shouldFocus: index === 0 }
        );
      });
    }
  });

  const sfsFieldIsChanged = {
    taxCode: !!form.formState.dirtyFields.bookings?.[0]?.taxCode,
    costCenter: !!form.formState.dirtyFields.bookings?.[0]?.costCenter,
    costObject: !!form.formState.dirtyFields.bookings?.[0]?.costObject,
    generalLedgerAccount:
      !!form.formState.dirtyFields.bookings?.[0]?.generalLedgerAccount,
  };

  const fieldProps = {
    taxCode: getSmartFieldStatus({
      ...smartFields?.bookingKey,
      isDirty: sfsFieldIsChanged.taxCode,
      showSFS:
        smartFields?.bookingKey?.id != null &&
        smartFields?.bookingKey?.id === splits[0].taxCode,
    }),
    costCenter: getSmartFieldStatus({
      ...smartFields?.costCenter,
      isDirty: sfsFieldIsChanged.costCenter,
      showSFS:
        smartFields?.costCenter?.id != null &&
        smartFields?.costCenter?.id === splits[0].costCenter.value,
    }),
    costObject: getSmartFieldStatus({
      ...smartFields?.costObject,
      isDirty: sfsFieldIsChanged.costObject,
      showSFS:
        smartFields?.costObject?.id != null &&
        smartFields?.costObject?.id === splits[0].costObject.value,
    }),
    generalLedgerAccount: getSmartFieldStatus({
      ...smartFields?.generalLedgerAccount,
      isDirty: sfsFieldIsChanged.generalLedgerAccount,
      showSFS:
        smartFields?.generalLedgerAccount?.id != null &&
        smartFields?.generalLedgerAccount?.id ===
          splits[0].generalLedgerAccount.value,
    }),
  };

  useWatchApprovalForm(form);

  const watchedBookings = useWatch({
    control: form.control,
    name: 'bookings',
  });

  useEffect(() => {
    if (shouldUseSapPurchaseOrder) {
      setBookingsFormData(
        watchedBookings.map(split => ({
          bookingId: split?.bookingId ?? '',
          price: {
            amount: split.unitPrice ?? split.netAmount ?? split.amount,
            currency: currency as DocumentCurrency,
            precision: 0,
          },
          quantity: split.quantity || 0,
          description: split.note || '',
        }))
      );
    }
  }, [
    shouldUseSapPurchaseOrder,
    watchedBookings,
    currency,
    setBookingsFormData,
  ]);

  const calculateRoundingAmount = useGetCalculateRoundingAmount();
  useSyncApprovalFormDefaultValues({
    form,
    defaultValues: initialValues,
  });

  const handleRoundingAmount = ({
    values,
    bookings,
  }: {
    values: Partial<AcceptedValuesDS>;
    bookings: DocumentApprovalFormValues['bookings'];
  }) => {
    if (!shouldUseSapNetAmount) {
      return;
    }

    void calculateRoundingAmount({
      currency: values.currency as DocumentCurrency,
      grossAmount: values.amount ?? 0,
      bookings,
    }).then(roundingAmount => {
      form.setValue('roundingAmount', roundingAmount ?? 0);
    });
  };

  const handleAcceptBookings = (acceptedValues: AcceptedValuesDS) => {
    const newBookings = onAcceptBookings(acceptedValues);
    handleRoundingAmount({
      values: acceptedValues,
      bookings: newBookings,
    });
    bookingsFieldArray?.replace(newBookings);
    void form.trigger('bookings');
    setMode('default');

    // update the sum of net and tax amounts
    if (shouldUseSapNetAmount) {
      form.setValue('netAmount', sumNetAmounts(newBookings));
      form.setValue('taxAmount', sumTaxAmounts(newBookings));
    }
  };

  const tooltips = useAmountFieldsTooltip(watchedBookings, currency);

  return isLoading ? (
    <Grid alignContent="center" height="100%" justifyContent="center">
      <Spinner size="space64" />
    </Grid>
  ) : (
    <DocumentApprovalsFormProvider
      bookingsFieldArray={bookingsFieldArray}
      form={form}
    >
      <ProcessSidebar
        documentId={document.id}
        globalDocumentId={document.globalDocumentId ?? undefined}
        id="approval_sidebar"
        accessModificationPermissions={document.accessModificationPermissions}
        documentStatus={document.status ?? DocumentStatusFrontend.Draft}
        actions={
          <Actions
            onReadonly={setReadonly}
            isRejected={form.getValues().rejected}
            isSubmitting={form.formState.isSubmitting}
            setRejectionState={rejected => form.setValue('rejected', rejected)}
            toggleEditMode={toggleEditMode}
            workflow={document.workflow ?? undefined}
            onSubmit={() => {
              void handleSubmit();
            }}
          />
        }
      >
        <Grid
          as="form"
          gap="space16"
          alignContent="start"
          height="100%"
          padding="0 space8 60px"
        >
          {!hasTransactions && <DocumentType />}
          <Contact contactId={contactId ?? ''} />
          {showAccountsPayableNumberField && <AccountsPayable />}
          {showAccountsReceivableNumberField && <AccountsReceivable />}

          {tagsField}

          <InvoiceDates
            deliveryDate={invoiceDeliveryDate}
            invoiceDate={invoiceDate}
            invoicePostingDate={invoicePostingDate}
            showDeliveryDate={showDeliveryDate}
            showPostingDate={showPostingDate}
          />
          <InvoiceNumber />
          {showPurchaseOrderNumber && <PurchaseOrderNumber />}
          {showInvoiceCorrection && <InvoiceCorrection />}
          {shouldUseSapNetAmount ? (
            <>
              <Grid autoFlow="column" gap="space8">
                <NetAmount currency={currency} />
              </Grid>
              <Flex gap="space8">
                <TaxAmount
                  flex={1}
                  currency={currency}
                  info={tooltips.taxAmount}
                />
                {!hasMultipleBookings && (
                  <>
                    {!isHidden.taxCode ? (
                      <TaxCode
                        flex={1}
                        key={splits[0].id}
                        {...fieldOptions?.taxCode?.props}
                        integration={formContext.integration}
                        documentDirection={formContext.documentDirection}
                        readOnly={!isEditable.taxCode}
                        {...fieldProps.taxCode}
                      />
                    ) : (
                      showVatField && <VatRate key={splits[0].id} />
                    )}
                  </>
                )}
              </Flex>
              <Grid columns={shouldUseSapNetAmount ? 2 : 1} gap="space8">
                <GrossAmount currency={currency} />
                {shouldUseSapNetAmount && (
                  <RoundingAmount currency={currency} />
                )}
              </Grid>
            </>
          ) : (
            <Grid autoFlow="column" gap="space8">
              <GrossAmount currency={currency} />
              {showVatField && <VatRate />}
            </Grid>
          )}
          {showFormField && (
            <SplitBookingsList
              onSplitDocument={splitIndex => {
                setDefaultSplitIndex(splitIndex ?? -1);
                setMode('split-bookings');
              }}
              isGoodsPurchaseOrderLinked={isGoodsPurchaseOrderLinked}
            />
          )}

          {!hasPrevBookingsSplits && isUserInFirstActiveWorkflowStep && (
            <SplitButton
              isFormReadOnly={isFormReadOnly}
              onClick={() => setMode('split-bookings')}
            />
          )}

          {!showFormField && (
            <Grid gap="space16" key={splits[0].id}>
              <Box paddingY="space8">
                <SectionSeparator
                  header={t('document.requestApproval.section.preAccounting')}
                />
              </Box>

              {!fieldOptions.accountingArea?.hidden && (
                <AccountingArea
                  {...fieldOptions?.accountingArea?.props}
                  readOnly={!isNilOrEmpty(accountingArea?.value)}
                />
              )}

              {!isHidden.generalLedgerAccount && (
                <GeneralLedgerAccount
                  {...fieldOptions?.generalLedgerAccount?.props}
                  readOnly={!isEditable.generalLedgerAccount}
                  {...fieldProps.generalLedgerAccount}
                />
              )}

              {!shouldUseSapNetAmount && !isHidden.taxCode && (
                <TaxCode
                  {...fieldOptions?.taxCode?.props}
                  integration={formContext.integration}
                  documentDirection={formContext.documentDirection}
                  readOnly={!isEditable.taxCode}
                  {...fieldProps.taxCode}
                />
              )}

              {!isHidden.costCenter && (
                <CostCenter
                  {...fieldOptions?.costCenter?.props}
                  readOnly={!isEditable.costCenter}
                  {...fieldProps.costCenter}
                />
              )}

              {!isHidden.costObject && (
                <CostObject
                  {...fieldOptions?.costObject?.props}
                  readOnly={!isEditable.costObject}
                  {...fieldProps.costObject}
                />
              )}
              {!isHidden.projectCode && (
                <ProjectCode
                  {...fieldOptions?.projectCode?.props}
                  readOnly={!isEditable.projectCode}
                />
              )}

              {!isHidden.artistSocialInsuranceCode && (
                <ArtistSocial
                  {...fieldOptions?.artistSocialInsuranceCode?.props}
                  readOnly={!isEditable.artistSocialInsuranceCode}
                />
              )}

              {!isHidden.extraCostInfo && (
                <ExtraCostInfo
                  {...fieldOptions?.extraCostInfo?.props}
                  readOnly={!isEditable.extraCostInfo}
                />
              )}

              {!isHidden.postingText && (
                <PostingText
                  readOnly={readonly}
                  currentValueLength={
                    form.getValues().bookings[0].postingText?.length
                  }
                />
              )}

              <Note
                readOnly={readonly}
                currentValueLength={form.getValues().bookings[0].note?.length}
              />
            </Grid>
          )}

          {(!hasTransactions || showDueDateField || showCashDiscountFields) && (
            <Box paddingY="space8">
              <SectionSeparator
                header={t('document.requestApproval.section.payment')}
              />
            </Box>
          )}

          {!hasTransactions && <BankingDetails />}

          {showDueDateField && (
            <DueDate dueDate={bookings ? bookings[0].dueDate : undefined} />
          )}

          {showCashDiscountFields && (
            <CashDiscount
              cashDiscount={(cashDiscount as CashDiscountFieldValue) || {}}
              currency={currency}
            />
          )}
        </Grid>
      </ProcessSidebar>
      <ProcessingFormOverlay
        isOpen={mode === 'split-bookings'}
        onClose={() => setMode('default')}
      >
        <SplitBookings
          purchaseOrderType={purchaseOrderType}
          isGoodsPurchaseOrderLinked={isGoodsPurchaseOrderLinked}
          documentDirection={formContext.documentDirection}
          initialOpenIndex={defaultSplitIndex}
          isFieldEditable={isFieldEditable}
          mode="approve"
          onAcceptBookings={handleAcceptBookings}
          onDiscardBookings={() => {
            if (!document.bookings || document.bookings?.length === 0) {
              setMode('default');

              return;
            }

            const bookings = onDiscardBookings();
            handleRoundingAmount({
              values: { currency, amount: initialValues.grossAmount },
              bookings,
            });
            bookingsFieldArray?.replace(bookings);
            void form.trigger('bookings');
            setMode('default');
          }}
          readOnly={isFormReadOnly}
          shouldShowField={() => true}
          initialValues={{
            bookings: form.getValues().bookings.map(toSplitDS),
            grossAmount: initialValues.grossAmount,
            currency: initialValues.currency,
            invoiceDate: initialValues.invoiceDate
              ? dateToDateString(initialValues.invoiceDate)
              : null,
            roundingAmount: initialValues.roundingAmount,
          }}
        />
      </ProcessingFormOverlay>
    </DocumentApprovalsFormProvider>
  );
};

import { queryParameter } from 'components/Table/consts';
import {
  ContactRelationshipType,
  ContactSortField,
  ContactsInfiniteScrollPaginationQuery,
  ContactsInfiniteScrollPaginationQueryVariables,
  SortDirection,
  useCountWithoutAccountsPayableNumberContactsQuery,
} from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce } from 'lodash';
import { usePagePagination } from 'providers/GraphQLProvider/Pagination/usePagePagination';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { Filters, SortingRule } from 'react-table';
import { contactsInfiniteScrollPaginationQuery } from 'views/Contacts/queries';
import { CONTACT_SEARCH_PARAM } from 'views/Contacts/types';
import { ContactsTableData } from '../ContactTableDS';
import { mapToContactsInputFilter } from '../utils/mapToContactsInputFilter';
import { mapToContactsSortInput } from '../utils/mapToContactsSortInput';
import { mapToContactsTableData } from '../utils/mapToContactsTableData';

interface UseContactTableDSDataParams {
  sortBy: SortingRule<ContactsTableData>[];
  filters: Filters<ContactsTableData>;
  mergedContactId?: string;
}

const defaultSort = {
  direction: SortDirection.Asc,
  field: ContactSortField.Name,
};

export enum TabFilterParamsNew {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

/** Use this hook when working with Contacts Table that has the `archiving` options enabled.
 * To be renamed back to `useContactTableDSData` after FF is archived
 */
export const useContactTableDSDataNew = ({
  sortBy,
  filters,
  mergedContactId,
}: UseContactTableDSDataParams) => {
  const [searchParams] = useSearchParams();
  const searchQueryParam = searchParams.get(queryParameter) ?? '';
  const [search, setSearch] = useState(searchQueryParam);
  const [t] = useTranslation();

  const computeFilters = mapToContactsInputFilter(filters);
  const computeSort = mapToContactsSortInput(sortBy);

  const { data: activeContactsData, loading: activeContactsLoading } =
    usePagePagination<
      ContactsInfiniteScrollPaginationQuery,
      ContactsInfiniteScrollPaginationQueryVariables
    >(contactsInfiniteScrollPaginationQuery, 'contactsPagination', {
      variables: {
        filters: {
          isArchived: false,
        },
      },
    });

  const { data: archivedContactsData, loading: archivedContactsLoading } =
    usePagePagination<
      ContactsInfiniteScrollPaginationQuery,
      ContactsInfiniteScrollPaginationQueryVariables
    >(contactsInfiniteScrollPaginationQuery, 'contactsPagination', {
      variables: {
        filters: {
          isArchived: true,
        },
      },
    });

  const activeCount =
    activeContactsData?.contactsPagination.pageInfo.totalCount ?? 0;

  const archivedCount =
    archivedContactsData?.contactsPagination.pageInfo.totalCount ?? 0;

  const useTabKey = () => {
    const isArchived = searchParams.get('isArchived') === 'true';

    if (isArchived) {
      return TabFilterParamsNew.ARCHIVED;
    }

    return TabFilterParamsNew.ACTIVE;
  };

  const activeTab = useTabKey();

  const {
    data: allContacts,
    loading: allContactsLoading,
    onLoadMore,
    refetchCurrentPage,
  } = usePagePagination<
    ContactsInfiniteScrollPaginationQuery,
    ContactsInfiniteScrollPaginationQueryVariables
  >(contactsInfiniteScrollPaginationQuery, 'contactsPagination', {
    variables: {
      sortBy: sortBy?.length ? computeSort : defaultSort,
      filters: {
        ...computeFilters,
        hasAccountsPayableNumber: searchParams.get(
          CONTACT_SEARCH_PARAM.noAccountsPayableNumber
        )
          ? false
          : undefined,
        isArchived:
          searchParams.get(CONTACT_SEARCH_PARAM.isArchived) === 'true',
        relationshipType: searchParams.get(
          CONTACT_SEARCH_PARAM.relationshipType
        )
          ? (searchParams.getAll(
              CONTACT_SEARCH_PARAM.relationshipType
            ) as ContactRelationshipType[])
          : undefined,
      },
      queries: {
        name: search,
      },
    },
  });

  const countWithoutAccountsPayableNumberContactsQuery =
    useCountWithoutAccountsPayableNumberContactsQuery({});

  const contactsWithoutAccountsPayableNumberCount =
    countWithoutAccountsPayableNumberContactsQuery.data?.countContacts ?? 0;

  const contactRecords = useMemo(
    () => allContacts?.contactsPagination.records ?? [],
    [allContacts?.contactsPagination.records]
  );

  const contactsTableData = useMemo(
    () => mapToContactsTableData(contactRecords, t, mergedContactId),
    [contactRecords, t, mergedContactId]
  );

  const contactCount = allContacts?.contactsPagination.pageInfo.totalCount;

  const handleDebounceSearch = useMemo(() => {
    return debounce(setSearch, DEFAULT_DEBOUNCE_TIME, { leading: true });
  }, []);

  return {
    onLoadMore,
    allContactsLoading,
    activeTab,
    contactCount,
    activeCount,
    activeContactsLoading,
    archivedCount,
    archivedContactsLoading,
    contactsTableData,
    contactRecords,
    search,
    refetchCurrentPage,
    handleDebounceSearch,
    contactsWithoutAccountsPayableNumberCount,
  };
};

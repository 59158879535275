import { useSubmitReimbursementCaseForReviewMutation } from 'generated-types/graphql.types';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsErrorsQuery,
} from '../toolkit/queries';

export const useSubmitReimbursementCaseForReview = () => {
  const [submitReimbursmentCaseForReview, { loading, client }] =
    useSubmitReimbursementCaseForReviewMutation();

  return {
    submitReimbursmentCaseForReview: async (reimbursementId: string) => {
      const response = await submitReimbursmentCaseForReview({
        variables: { id: reimbursementId },
        onCompleted: () => {
          // Instead of refetching the 3 different queries with different params we evict the cache
          client.cache.evict({ fieldName: 'approvalReimbursementCasesCount' });
        },
        refetchQueries: [
          'archiveReimbursementCasesCount',
          'inboxReimbursementCasesCount',
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementItemsErrorsQuery,
            variables: { filters: { reimbursementCaseId: reimbursementId } },
          },
          {
            query: reimbursementHistoryQuery,
            variables: { id: reimbursementId },
          },
        ],
      });

      if (response.data?.submitReimbursementCaseForReview.success === true) {
        return {
          status: 'success',
        };
      }

      return {
        status: 'error',
      };
    },
    isSubmitReimbursmentCaseForReviewPending: loading,
  };
};

import { useEffect, useState } from 'react';

export const useXmlContent = (xmlUrl?: string) => {
  const [xmlContent, setXmlContent] = useState<string>('');

  useEffect(() => {
    if (xmlUrl) {
      fetch(xmlUrl)
        .then(response => response.text())
        .then(data => setXmlContent(data))
        .catch(error => console.error('Error fetching XML:', error));
    }
  }, [xmlUrl]);

  return xmlContent;
};

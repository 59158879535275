import { Grid, Image, Link, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export const QrCode = ({
  qrCodeImage,
  toggleShow,
}: {
  qrCodeImage: string;
  toggleShow: () => void;
}) => {
  const [t] = useTranslation();

  return (
    <Grid gap="space8">
      <Text>
        {t(
          'header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.scanQrCodeInstruction'
        )}
      </Text>
      <Grid gap="space8" templateColumns="max-content auto">
        <QrCodeImage image={qrCodeImage} />
        <Grid
          templateRows="repeat(2, max-content)"
          gap="space4"
          alignContent="center"
        >
          {t(
            'header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.showTotpSecret.text'
          )}
          <Link width="max-content" as="button" onClick={toggleShow}>
            {t(
              'header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.showTotpSecret.cta'
            )}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

const QrCodeImage = ({ image }: { image: string }) => {
  const [t] = useTranslation();

  return (
    <Grid
      as="div"
      borderRadius="small"
      border="space4 solid black"
      padding="space8"
      height="fit-content"
    >
      <Image
        alt={t(
          'header.profile.tabs.update.accountSecurity.authenticatorAppCard.modal.qrCodeImageAltText'
        )}
        src={image}
      />
    </Grid>
  );
};

import { useCostCenterFieldOptions } from 'components/Form/hooks/useCostCenterFieldOptions';
import { useGeneralLedgerAccountFieldOptions } from 'components/Form/hooks/useGeneralLedgerAccountFieldOptions';
import { CostCenterTypes } from 'generated-types/graphql.types';
import {
  useArtistInsuranceCodeFieldOptions,
  useTaxCodesFieldOptions,
} from 'views/CreditCards/hooks/AccountingInfoFormFieldData';
import {
  AccountingFieldItem,
  Options,
  PaginatedOptions,
} from 'views/Reimbursement/hooks/types';
import {
  useShowArtistSocialInsuranceCodeField,
  useShowCostCenterField,
  useShowCostObjectField,
  useShowExtraCostInfoField,
  useShowGeneralLedgerAccountField,
  useShowPostingTextField,
  useShowTaxCodeField,
} from 'views/utils/useShouldShowField';

export type FormFieldOptions = {
  costCenter: PaginatedOptions<AccountingFieldItem>;
  costObject: PaginatedOptions<AccountingFieldItem>;
  generalLedgerAccount: PaginatedOptions<AccountingFieldItem>;
  extraCostInfo: PaginatedOptions<AccountingFieldItem>;
  taxCode: Options<AccountingFieldItem>;
  artistSocialInsuranceCode: Options<AccountingFieldItem>;
  postingText: Options<AccountingFieldItem>;
};

export const useFormFieldOptions = (): FormFieldOptions => {
  const showTaxCodeField = useShowTaxCodeField();
  const showCostCenterField = useShowCostCenterField();
  const showCostObjectField = useShowCostObjectField();
  const showExtraCostInfoField = useShowExtraCostInfoField();
  const showGeneralLedgerAccountField = useShowGeneralLedgerAccountField();
  const showArtistSocialInsuranceCodeField =
    useShowArtistSocialInsuranceCodeField();

  const showPostingTextField = useShowPostingTextField();

  const costCenter = useCostCenterFieldOptions({
    skip: !showCostCenterField,
    type: CostCenterTypes.CostCenter,
  });

  const costObjects = useCostCenterFieldOptions({
    skip: !showCostObjectField,
    type: CostCenterTypes.CostObject,
  });

  const extraCostInfo = useCostCenterFieldOptions({
    skip: !showExtraCostInfoField,
    type: CostCenterTypes.ExtraCostInfo,
  });

  const generalLedgerAccounts = useGeneralLedgerAccountFieldOptions({
    skip: !showGeneralLedgerAccountField,
  });

  const taxCode = useTaxCodesFieldOptions();

  const artistSocialInsuranceCode = useArtistInsuranceCodeFieldOptions();

  const fieldOptions: FormFieldOptions = {
    costCenter: {
      isVisible: showCostCenterField,
      options: costCenter?.props,
    },
    costObject: {
      isVisible: showCostObjectField,
      options: costObjects?.props,
    },
    generalLedgerAccount: {
      isVisible: showGeneralLedgerAccountField,
      options: generalLedgerAccounts?.props,
    },
    extraCostInfo: {
      isVisible: showExtraCostInfoField,
      options: extraCostInfo?.props,
    },
    taxCode: {
      isVisible: showTaxCodeField,
      options: taxCode,
    },
    artistSocialInsuranceCode: {
      isVisible: showArtistSocialInsuranceCodeField,
      options: artistSocialInsuranceCode,
    },
    postingText: {
      isVisible: showPostingTextField,
    },
  };

  return fieldOptions;
};

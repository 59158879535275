import { Flex, Grid, Text } from '@candisio/design-system';
import { ReactElement } from 'react';
import { useCheckItem, type StateKey } from '../states';

type Check = {
  text: string;
  icon: ReactElement;
};

export const CheckList = ({ checks }: { checks: StateKey[] }) => {
  const getCheckItemProps = useCheckItem();

  return (
    <Grid
      as="ul"
      listStyle="none"
      gap="space4"
      padding="0"
      justifyContent="left"
    >
      {checks.map(stateKey => (
        <CheckItem key={stateKey} {...getCheckItemProps(stateKey)} />
      ))}
    </Grid>
  );
};

const CheckItem = ({ icon, text }: Check) => (
  <Flex as="li" alignItems="center" gap="space4">
    {icon}
    <Text>{text}</Text>
  </Flex>
);

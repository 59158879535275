import { useToastMessage } from 'components/Toast/useToastMessage';
import { useUpdateOrganizationContactInformationMutation } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { OrganizationContactInformationFormData } from './organizationContactInformationSchema';

export const useOrganizationContactInformationUpdate = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success, error } = useToastMessage();

  const [updateOrganizationContactInformation, { loading: isSaving }] =
    useUpdateOrganizationContactInformationMutation();

  const handleOrganizationContactInformationUpdate = async (
    formOutput: OrganizationContactInformationFormData
  ) => {
    try {
      const { data, errors } = await updateOrganizationContactInformation({
        variables: {
          input: {
            vatId: formOutput.vatId,
            taxNumber: formOutput.taxNumber,
          },
        },
        refetchQueries: ['getOrganizations'],
      });

      if (
        data?.organizationContactInformation.__typename ===
        'OrganizationContactInformation'
      ) {
        success(t('organisation.messages.contactInformation.success'));
      } else if (
        data?.organizationContactInformation.__typename ===
          'OrganizationContactInformationErrors' ||
        errors
      ) {
        error(t('organisation.messages.contactInformation.error'));
      }
    } catch (_err) {
      error(t('organisation.messages.contactInformation.error'));
    }
  };

  return {
    handleOrganizationContactInformationUpdate,
    isSaving,
  };
};

import { amountFormat } from 'utils/format';
import { Amount } from '../types';
import { HighlightResult } from '@candisio/design-system';

export const getAmountHighlights = (
  value: Amount,
  highlights: HighlightResult,
  locale?: string
): HighlightResult | null => {
  const formattedAmount =
    amountFormat(Number(value?.amount), value?.currency, {
      convertToBasicMonetaryUnit: value?.isBasicMonetaryUnit ?? false,
      locale,
    }) ?? '';

  const textToHighlight = getTextToHighlight(highlights);

  if (!textToHighlight) {
    return null;
  }

  return getRegexMatch(textToHighlight, formattedAmount);
};

const getTextToHighlight = (highlights: HighlightResult) => {
  const isNegativeAmount = highlights.text.startsWith('-');
  const highlightText = highlights.text.slice(
    highlights.matches[0].offset - (isNegativeAmount ? 1 : 0),
    highlights.matches[0].length + (isNegativeAmount ? 1 : 0)
  );

  return highlightText;
};

const getRegexMatch = (highlightedText: string, formattedAmount: string) => {
  const highlight: HighlightResult = {
    text: formattedAmount,
    matches: [],
  };

  // cleaned highlighted text with only digits
  const highlightTextDigits = highlightedText.replace(/\D/g, '');

  // Create a pattern that matches the sequence of digits, allowing for non-digit characters in between
  // This pattern uses lookahead assertions to find digits without consuming them
  // Then matches any character (digit or non-digit) in between
  const regexPattern = [...highlightTextDigits]
    .map((digit, index) => {
      if (index === highlightTextDigits.length - 1) {
        return digit;
      }
      return `${digit}\\D*`;
    })
    .join('');

  const regex = new RegExp(regexPattern, 'g');

  let match;
  while ((match = regex.exec(formattedAmount)) !== null) {
    highlight.matches.push({
      offset: match.index,
      length: match[0].length,
    });

    // Move the regex's lastIndex to avoid infinite loops on zero-length matches
    if (match.index === regex.lastIndex) {
      regex.lastIndex++;
    }
  }

  return highlight;
};

import { Grid } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { noop } from 'lodash';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { MiddleSectionSkeleton } from './MiddleSectionSkeleton';
import { ReimbursementItemsSectionContainer } from './components/MiddleSection/ReimbursementItemsSectionContainer';
import { ReimbursementItemsFormsContainer } from './components/MiddleSection/ReimbursementItemsFormsContainer';
import { useExpenseFormsHelpers } from './components/MiddleSection/utils/useExpenseFormsHelpers';
import { SectionLayout } from './components/SectionLayout';
import { useFormValidationTrigger } from './hooks/useFormValidationTrigger';
import { useGetReimbursementById } from './hooks/useGetReimbursementById';
import { useReimbursementExpenseItemsList } from './hooks/useReimbursementExpenseItemsList';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/utils/constants';
import { usePerDiemItemDetails } from './hooks/usePerDiemItemDetails';

const FORM_KEY = 'expenseForms';
interface MiddleSectionContainerProps {
  formHelpers: ReturnType<typeof useExpenseFormsHelpers>;
  isLoading: boolean;
}

export const MiddleSectionContainer = ({
  formHelpers,
  isLoading,
}: MiddleSectionContainerProps) => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { searchParams } = useMutateSearchParams();
  const isItemsViewActive = searchParams.get(REIMBURSEMENT_URL_PARAM.VIEW);
  const { reimbursementItems } = useReimbursementExpenseItemsList();
  const { perDiemItemDetails, isLoadingPerDiemItem } = usePerDiemItemDetails();

  const { reimbursement } = useGetReimbursementById({
    reimbursementId,
  });

  const { forms, formMethods } = formHelpers;

  useFormValidationTrigger(
    FORM_KEY,
    () => void formMethods.handleSubmit(noop)()
  );

  if (isLoading) {
    return (
      <SectionLayout>
        <MiddleSectionSkeleton isItemsViewActive={!!isItemsViewActive} />
      </SectionLayout>
    );
  }

  const showItemsView = Boolean(isItemsViewActive && forms.length);

  return (
    <SectionLayout overflow="hidden">
      <Grid
        height="100%"
        paddingTop="40.5px"
        overflowY="auto"
        templateRows={showItemsView ? 'auto' : 'auto 1fr'}
      >
        {showItemsView ? (
          <ReimbursementItemsFormsContainer
            status={reimbursement?.status}
            formHelpers={formHelpers}
            perDiemItemDetails={perDiemItemDetails}
            isLoadingPerDiemItem={isLoadingPerDiemItem}
          />
        ) : (
          <ReimbursementItemsSectionContainer
            formHelpers={formHelpers}
            reimbursement={reimbursement}
            reimbursementItems={reimbursementItems}
          />
        )}
      </Grid>
    </SectionLayout>
  );
};

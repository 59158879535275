import { Card, Flex, Link, Paragraph, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export const CandisApiInfo = () => {
  const [t] = useTranslation();

  return (
    <Card
      boxShadow="noShadow"
      width="100%"
      padding="space16"
      background="bluebg"
    >
      <Flex direction="column" justifyContent="space-between" gap="space8">
        <Text fontWeight="semibold" fontSize="large">
          {t('settings:candisAPI.infoCard.title')}
        </Text>
        <Paragraph fontSize="basic">
          {t('settings:candisAPI.infoCard.content')}
        </Paragraph>
        <Link
          external
          variant="primary"
          color="blue"
          href={t('settings:candisAPI.infoCard.linkUrl')}
          width="fit-content"
        >
          {t('settings:candisAPI.infoCard.linkText')}
        </Link>
      </Flex>
    </Card>
  );
};

import { FieldPolicy, TypePolicies } from '@apollo/client';
import {
  ArchiveAllDocumentsResponse,
  GetEcmDocumentsResponse,
} from 'generated-types/graphql.types';
import {
  ecmSimplePaginationMergeWithoutDuplicates,
  paginationWithRecordConnectionsMergeNoNodes,
} from './pagination-results-merge';

const ecmDocumentsPaginationFieldPolicy: FieldPolicy<GetEcmDocumentsResponse> =
  {
    merge: ecmSimplePaginationMergeWithoutDuplicates('GetEcmDocumentsResponse'),
    keyArgs: ['filter', 'search', 'sort', 'searchInput'],
  };

const archiveAllDocumentsPaginationFieldPolicy: FieldPolicy<ArchiveAllDocumentsResponse> =
  {
    merge: paginationWithRecordConnectionsMergeNoNodes(
      'ArchiveAllDocumentsResponse'
    ),
    keyArgs: ['filter', 'search', 'sort', 'searchInput'],
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      getEcmDocuments: ecmDocumentsPaginationFieldPolicy,
      archiveAllDocuments: archiveAllDocumentsPaginationFieldPolicy,
    },
  },
};

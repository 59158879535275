import { Grid, Icon, Spinner, Text } from '@candisio/design-system';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { WideButton } from 'components/WideButton';
import {
  DocumentStatus,
  DocumentType,
  EcmDocumentStatus,
  GetDocumentForDraftQuery,
  Money,
} from 'generated-types/graphql.types';
import { Attachment } from 'hooks/useAttachments/useAttachments';
import { CanAddAttachments } from 'hooks/useAttachments/useCanAddAttachments';
import { useGlobalDocumentId } from 'hooks/useGlobalDocumentId';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawerState } from 'views/DocumentDetails/useDrawerState';
import { useInboxContext } from 'views/Inbox/Context';
import { useDisabledAttachmentButtonLabelText } from '../util/useDisabledAttachmentButtonLabelText';
import { ContactSupplierLink } from './ContactSupplier/ContactSupplierLink';
import { DocumentSummaryCardListContainer } from './DocumentSummaryCard';
import { DocumentSummaryInformationDeprecated } from './DocumentSummaryInformationDeprecated';
import { MainDocumentSummaryCard } from './MainDocumentSummaryCard';
import {
  RelatedDocument,
  useDocumentRelations,
} from './hooks/useDocumentRelations';
import { isPaidDocument } from 'utils/document_payment_data';
import { PulseIndicator } from 'components/PulseIndicator/PulseIndicator';
import {
  useAttachmentsSidebarPromo,
  useRelationsSidebarPromo,
} from './sidebarPromoHooks';

export const DocumentSummaryInformationAdapter = ({
  transactionSection,
}: {
  transactionSection?: ReactNode;
}) => {
  const {
    documentId,
    amount,
    invoiceDate,
    invoiceNumber,
    documentStatus,
    selectedContactEmail,
    contactName,
    isEInvoice,
    eInvoice,
    document,
    eInvoiceValidationErrors,
    isDocumentFileLoading,
    isDocumentDataLoading,
    mainDocumentFile: documentFile,
    useAttachmentsResult: {
      attachments,
      attachPermissions,
      selectedPdf,
      setSelectedPdf,
    },
  } = useInboxContext();

  const { showDocumentRelationsImproved } = useEcm();

  const DocumentSummaryInformationComponent = showDocumentRelationsImproved
    ? DocumentSummaryInformation
    : DocumentSummaryInformationDeprecated;

  return (
    <DocumentSummaryInformationComponent
      documentId={documentId}
      amount={amount}
      invoiceDate={invoiceDate}
      invoiceNumber={invoiceNumber}
      documentStatus={documentStatus}
      selectedContactEmail={selectedContactEmail}
      contactName={contactName}
      isEInvoice={isEInvoice}
      eInvoiceValidationErrors={eInvoiceValidationErrors}
      eInvoice={eInvoice}
      isLoading={isDocumentFileLoading || isDocumentDataLoading}
      documentFile={documentFile}
      attachments={attachments}
      attachPermissions={attachPermissions}
      selectedPdf={selectedPdf}
      onAttachmentClick={setSelectedPdf}
      transactionSection={transactionSection}
      isPaid={document ? isPaidDocument(document) : undefined}
    />
  );
};

export type DocumentSummaryInformationProps = {
  documentId: string;
  amount: Money | null;
  invoiceDate: string | undefined;
  invoiceNumber: string | undefined;
  /** E-Invoice flag */
  isEInvoice?: NonNullable<
    GetDocumentForDraftQuery['getDocument']
  >['isEInvoice'];
  /** E-Invoice validation errors */
  eInvoiceValidationErrors: NonNullable<
    GetDocumentForDraftQuery['getDocument']
  >['eInvoiceValidationErrors'];
  eInvoice?: NonNullable<GetDocumentForDraftQuery['getDocument']>['eInvoice'];
  documentStatus: DocumentStatus | EcmDocumentStatus | undefined;
  selectedContactEmail?: string;
  contactName: string | undefined;
  isLoading: boolean;
  documentFile: PDFDetails;
  attachments: Attachment[];
  attachPermissions: CanAddAttachments;
  selectedPdf: PDFDetails;
  onAttachmentClick: (..._args: any) => void;
  transactionSection?: ReactNode;
  isPaid?: boolean;
  withAttachments?: boolean;
  withRelationships?: boolean;
};

const EmptyDocumentSummary = () => {
  const [t] = useTranslation();
  return (
    <div className="grid gap-1.5 p-3 pb-2 w-full">
      <Text fontWeight="semibold" fontSize="large">
        {t('transactions:transactionAssociation.documentDetailsEmptyInfoTitle')}
      </Text>
      <span className="flex gap-2 bg-gray-50 rounded-medium p-7  items-center justify-center">
        <Icon size="space16" icon="infoCircle" color="gray500" />
        <Text color="gray500" lineHeight="160%">
          {t('transactions:transactionAssociation.documentDetailsEmptyInfo')}
        </Text>
      </span>
    </div>
  );
};

export const DocumentSummaryInformation = ({
  documentId: id,
  amount,
  invoiceDate,
  invoiceNumber,
  isEInvoice,
  eInvoice,
  eInvoiceValidationErrors,
  documentStatus,
  selectedContactEmail,
  contactName,
  isLoading,
  attachments,
  transactionSection,
  attachPermissions,
  isPaid,
  withAttachments = true,
  withRelationships = true,
}: DocumentSummaryInformationProps) => {
  const document: RelatedDocument = {
    id: id,
    amount,
    name: contactName ?? null,
    isEInvoice,
    eInvoice,
    eInvoiceValidationErrors,
    number: invoiceNumber ?? null,
    status: documentStatus ?? null,
    date: invoiceDate ?? null,
    type: DocumentType.Invoice,
    isPaid,
  };

  const canAttach = attachPermissions.fromDisk || attachPermissions.fromCandis;
  const isAttachmentsSegmantDisabled = !canAttach && attachments.length === 0;

  return (
    <DocumentSummaryCardListContainer borderRadius="medium" background="gray0">
      {!id && !isLoading ? (
        <EmptyDocumentSummary />
      ) : (
        <MainDocumentSummaryCard
          borderTopRadius="medium"
          isLoading={isLoading}
          document={document}
          action={
            <ContactSupplierLink
              selectedContactEmail={selectedContactEmail}
              eInvoiceErrorCount={eInvoiceValidationErrors?.length ?? 0}
              trackingEventLocation="document_summary_card"
              documentId={id}
            />
          }
        />
      )}
      <Grid padding="space12" paddingTop="0" gap="space8">
        {transactionSection}
        {withAttachments && (
          <DocumentAttachmentsButton
            attachmentsCount={attachments.length ?? 0}
            canAttach={canAttach}
            disabled={isAttachmentsSegmantDisabled}
            isLoading={isLoading}
          />
        )}
        {withRelationships && <DocumentRelationsButton documentId={id} />}
      </Grid>
    </DocumentSummaryCardListContainer>
  );
};

const DocumentAttachmentsButton = ({
  canAttach,
  attachmentsCount,
  disabled,
  isLoading,
}: {
  canAttach: boolean;
  disabled: boolean;
  attachmentsCount: number;
  isLoading: boolean;
}) => {
  const [t] = useTranslation();
  const { isSeen: isPromotionSeen, markAsSeen } = useAttachmentsSidebarPromo();
  const {
    toggleDrawer: toggleAttachmentDrawer,
    isDrawerOpen: isAttachmentDrawerOpen,
  } = useDrawerState('ATTACHMENTS');
  const disabledLabel = useDisabledAttachmentButtonLabelText(canAttach);

  const attachmentsTitle = t('document.tabs.oldAttachments.title');

  const attachmentContent = !isLoading ? (
    <Text fontSize="small" style={{ fontVariantNumeric: 'tabular-nums' }}>
      {attachmentsCount}
    </Text>
  ) : (
    <Spinner right="-.25rem" />
  );

  useEffect(() => {
    const markAsSeenIfDrawerOpenedWithNoAttachments = () => {
      if (isPromotionSeen || attachmentsCount > 0 || !isAttachmentDrawerOpen)
        return;
      markAsSeen();
    };

    markAsSeenIfDrawerOpenedWithNoAttachments();
  }, [isPromotionSeen, attachmentsCount, isAttachmentDrawerOpen, markAsSeen]);

  return (
    <WideButton
      onClick={toggleAttachmentDrawer}
      isPressed={isAttachmentDrawerOpen}
      disabled={disabled}
      label={disabledLabel}
      icon={
        <Icon icon="attachment" size="space16" style={{ marginTop: '-2px' }} />
      }
    >
      <Text>{attachmentsTitle}</Text>
      {isPromotionSeen ? (
        attachmentContent
      ) : (
        <div className="grid gap-2 grid-flow-col">
          {attachmentContent}
          <PulseIndicator
            left="space4"
            status="default"
            source="attachments-promotion"
          />
        </div>
      )}
    </WideButton>
  );
};

const DocumentRelationsButton = ({ documentId }: { documentId: string }) => {
  const [t] = useTranslation();
  const relationsTitle = t('document.tabs.relationships.title');
  const { isSeen: isPromotionSeen, markAsSeen } = useRelationsSidebarPromo();
  const {
    toggleDrawer: toggleRelationsDrawer,
    isDrawerOpen: isRelationsDrawerOpen,
  } = useDrawerState('RELATIONSHIPS');
  const { showDocumentRelations } = useEcm();

  const globalDocumentId = useGlobalDocumentId(documentId);

  const {
    isLoading: isLoadingRelations,
    hasError,
    relatedDocuments,
  } = useDocumentRelations(globalDocumentId);
  const canShowRelationsCounter = !isLoadingRelations && !hasError;

  useEffect(() => {
    const markAsSeenIfDrawerOpenedWithNoRelations = () => {
      if (
        isPromotionSeen ||
        isLoadingRelations ||
        relatedDocuments.length > 0 ||
        !isRelationsDrawerOpen
      )
        return;

      markAsSeen();
    };

    markAsSeenIfDrawerOpenedWithNoRelations();
  }, [
    isPromotionSeen,
    relatedDocuments.length,
    isRelationsDrawerOpen,
    markAsSeen,
    isLoadingRelations,
  ]);
  const relationsContent = (
    <>
      {isLoadingRelations && <Spinner right="-.25rem" />}
      {canShowRelationsCounter && (
        <Text fontSize="small" style={{ fontVariantNumeric: 'tabular-nums' }}>
          {relatedDocuments.length}
        </Text>
      )}
    </>
  );

  const relationsContentWithPromo = (
    <div className="grid gap-2 grid-flow-col">
      {relationsContent}
      <PulseIndicator
        left="space4"
        status="default"
        source="relations-promotion"
      />
    </div>
  );

  return (
    <WideButton
      onClick={toggleRelationsDrawer}
      isPressed={isRelationsDrawerOpen}
      color={!showDocumentRelations ? 'promo' : undefined}
      icon={
        <Icon
          icon="linkOutlined"
          size="space16"
          style={{ marginTop: '-1px' }}
        />
      }
    >
      <Text>{relationsTitle}</Text>
      {!showDocumentRelations ? (
        <PulseIndicator
          status="promo"
          source="relations-promotion"
          left="space4"
        />
      ) : isPromotionSeen ? (
        relationsContent
      ) : (
        relationsContentWithPromo
      )}
    </WideButton>
  );
};

import {
  ProcessingFormContactFieldItem,
  ProcessingFormExtractedContactFieldItem,
} from 'components/Form/ProcessingForm/ProcessingFormContactField';
import {
  ExtractedContact,
  ProcessingFormContactDataFragment,
} from 'generated-types/graphql.types';

/** Maps `Contact` to `ProcessingFormContactFieldItem` */
export const toContactFieldItem = (
  contact: ProcessingFormContactDataFragment
): ProcessingFormContactFieldItem => {
  return {
    key: contact.id,
    children: contact.name?.value,
    accountsPayableNumber: contact.accountsPayableNumber ?? undefined,
    accountsReceivableNumber: contact.accountsReceivableNumber ?? undefined,
    createTransfer: contact?.createTransfer?.value ?? false,
    iban: contact.iban?.value ?? undefined,
    swiftCode: contact?.swiftCode ?? undefined,
    paymentCondition: contact.paymentCondition?.id,
    taxNumber: contact.taxNumber?.value,
    vatId: contact.vatId?.value,
    email: contact.email ?? undefined,
    bankAccountNumber: contact.bankAccountNumber ?? undefined,
  };
};

/** Maps `ExtractedContact` to `ProcessingFormExtractedContactFieldItem` */
export const toExtractedContactFieldItem = (
  contact: ExtractedContact
): ProcessingFormExtractedContactFieldItem => {
  return {
    children: contact.name?.value,
    createTransfer: contact?.createTransfer?.value ?? false,
    iban: contact.iban?.value ?? undefined,
    bankAccountNumber: contact.bankAccountNumber?.value,
    swiftCode: contact.swiftCode?.value,
    taxNumber: contact.taxNumber?.value,
    vatId: contact.vatId?.value,
    email: contact.email?.value,
    phoneNumber: contact.phoneNumber?.value,
    street: contact.street?.value,
    city: contact.city?.value,
    postalCode: contact.postalCode?.value,
    postOfficeBox: contact.postOfficeBox?.value,
    countryISOCode: contact.countryISOCode?.value,
    customerNumber: contact.customerNumber?.value,
  };
};

import { Icon, Image } from '@candisio/design-system';
import upAnimatedIcon from 'assets/upload-indicators/upload-animation.svg';
import {
  CheckmarkIcon,
  ErrorIcon,
  WarningIcon,
} from 'components/Icons/DefaultIcons';
import { useMemo } from 'react';
import { IndicatorContainer } from './styles';

export enum IndicatorStatus {
  PROCESSING = 'processing',
  ERROR = 'error',
  IDLE = 'idle',
  DONE = 'done',
  WARNING = 'warning',
}

type UploadIndicatorProps = {
  status: IndicatorStatus;
  direction?: 'top' | 'bottom';
};

export const indicatorStatusTranslationMap: Record<
  Exclude<IndicatorStatus, 'idle'>,
  string
> = {
  done: 'done',
  processing: 'processing',
  warning: 'warning',
  error: 'error',
};

const createIndicator = ({
  status,
  direction,
}: UploadIndicatorProps): JSX.Element => {
  const directionalStyle =
    direction === 'bottom' ? { transform: 'rotateZ(180deg)' } : undefined;

  switch (status) {
    case IndicatorStatus.PROCESSING:
      return <Image alt="" src={upAnimatedIcon} style={directionalStyle} />;
    case IndicatorStatus.IDLE:
      return (
        <Icon
          icon={direction === 'bottom' ? 'arrowDown' : 'arrowUp'}
          size="space16"
        />
      );
    case IndicatorStatus.WARNING:
      return <WarningIcon size="space16" />;
    case IndicatorStatus.DONE:
      return <CheckmarkIcon size="space16" />;
    default:
      return <ErrorIcon size="space16" />;
  }
};

export const ProgressIndicator = ({
  status,
  direction,
  ...rest
}: UploadIndicatorProps) => {
  const indicator = useMemo(
    () =>
      createIndicator({
        status,
        direction,
      }),
    [status, direction]
  );

  return <IndicatorContainer {...rest}>{indicator}</IndicatorContainer>;
};

import { useGetDocumentGlobalIdQuery } from 'generated-types/graphql.types';

export const useGlobalDocumentId = (invoiceId: string) => {
  const isGlobalId = invoiceId.includes('-');

  const { data: documentData } = useGetDocumentGlobalIdQuery({
    variables: { id: invoiceId },
    skip: isGlobalId,
  });

  const globalDocumentId = isGlobalId
    ? invoiceId
    : (documentData?.getDocument?.globalDocumentId ?? '');

  return globalDocumentId;
};

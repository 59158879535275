import { useSearchInput } from 'components/AdvancedSearchFieldFilterOverlay/useSearchInput';
import {
  availableEcmDateFilters,
  availableEcmDocumentFilters,
  ecmDocumentFieldsToGQLFieldsNameMap,
  sortableEcmFields,
} from 'components/EcmDocumentsTable/constants';
import { queryParameter } from 'components/Table/consts';
import {
  ArchiveAllDocumentsFilterInput,
  ArchiveAllDocumentsSortInput,
  ArchiveAllDocumentsSortOrder,
} from 'generated-types/graphql.types';
import { useDateConverter } from 'hooks/useDateConverter';
import { useSearchParams } from 'react-router-dom-v5-compat';

export const useEcmFiltersSortingFromUrlMap = () => {
  const [searchParams] = useSearchParams();
  const { dateStringToIsoDateFilterFormat } = useDateConverter();

  const filter = availableEcmDocumentFilters
    .filter(filter => searchParams.has(filter))
    .reduce<ArchiveAllDocumentsFilterInput>((acc, filter) => {
      const mappedFieldName = ecmDocumentFieldsToGQLFieldsNameMap[filter];

      if (availableEcmDateFilters.find(dateFilter => dateFilter === filter)) {
        const [fromDate, toDate] = searchParams.get(filter)?.split('-') ?? [];

        return {
          ...acc,
          [mappedFieldName]: {
            fromDate: dateStringToIsoDateFilterFormat(fromDate),
            toDate: dateStringToIsoDateFilterFormat(toDate),
          },
        };
      }

      return {
        ...acc,
        [mappedFieldName]: searchParams.getAll(filter),
      };
    }, {});

  const sort = Array.from(
    searchParams.entries()
  ).reduce<ArchiveAllDocumentsSortInput>(
    (acc: ArchiveAllDocumentsSortInput, [key, value]) => {
      if (key !== 'sort') return acc;

      const [fieldName, order] = value.split(':');

      const sanitizedSortableField = sortableEcmFields.find(
        sortableField => sortableField === fieldName
      );

      if (!sanitizedSortableField) {
        return acc;
      }

      const mappedFieldName =
        ecmDocumentFieldsToGQLFieldsNameMap[sanitizedSortableField];

      acc[mappedFieldName as keyof ArchiveAllDocumentsSortInput] =
        order === 'ascend'
          ? ArchiveAllDocumentsSortOrder.Asc
          : ArchiveAllDocumentsSortOrder.Desc;

      return acc;
    },
    {}
  );

  const search = searchParams.get(queryParameter);
  const { searchInputVariable: searchInput } = useSearchInput();

  return { filter, sort, search, searchInput };
};

import { Button, Tag } from '@candisio/design-system';
import { DocumentAccessDrawer } from 'components/DocumentAccess/DocumentAccessDrawer';
import {
  DocumentType,
  EcmStorageFormInitialDataQuery,
  GetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DOCUMENT_ACCESS_BUTTON_TEST_ID = 'document-access-button';

export interface DocumentAccessButtonProps {
  globalDocumentId: string;
  documentType: DocumentType;
  isSensitiveDocument: boolean;
  accessModificationPermissions:
    | NonNullable<
        GetDocumentForDraftQuery['getDocument']
      >['accessModificationPermissions']
    | NonNullable<
        EcmStorageFormInitialDataQuery['getAggregatedEcmDocument']
      >['accessModificationPermissions'];
}
export const DocumentAccessButton = ({
  globalDocumentId,
  documentType,
  isSensitiveDocument,
  accessModificationPermissions,
}: DocumentAccessButtonProps) => {
  const [isDocumentAccessDrawerOpen, setIsDocumentAccessDrawerOpen] =
    useState(false);

  const [t] = useTranslation();

  return (
    <>
      <Button
        variant="secondary"
        size="small"
        data-testid={DOCUMENT_ACCESS_BUTTON_TEST_ID}
        onClick={() => setIsDocumentAccessDrawerOpen(true)}
      >
        {t('common:documentAccess.button.label')}
        <Tag variant="callout" color="blue">
          {t('common:documentAccess.button.tag')}
        </Tag>
      </Button>
      <DocumentAccessDrawer
        isOpen={isDocumentAccessDrawerOpen}
        accessModificationPermissions={accessModificationPermissions}
        onCloseDrawer={() => setIsDocumentAccessDrawerOpen(false)}
        globalDocumentId={globalDocumentId}
        documentType={documentType}
        isSensitiveDocument={isSensitiveDocument}
      />
    </>
  );
};

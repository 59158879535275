import {
  DocumentBookingAssociation,
  useGetDocumentBookingAssociationsByInvoiceIdQuery,
} from 'generated-types/graphql.types';
import { getThreeWayMatchResult } from 'utils/three-way-match';
import { useBookingsFormContext } from 'views/DocumentDetails/BookingsFormContext';
import { ThreeWayMatchRowData } from '../types';
import {
  getBookingsData,
  getGoodsReceiptsData,
  getPurchaseOrdersData,
} from '../utils';
import { useMemo } from 'react';
import { useSapPurchaseOrders } from './useSapPurchaseOrders';
import { useAssociatedDocuments } from '../../hooks/useAssociatedDocuments';
import { useSap } from 'orgConfig/sap';
import { getLinkingSource } from '../../utils/getLinkingSource';

type ThreeWayMatchProps = {
  isLoading?: boolean;
  result: ThreeWayMatchRowData[];
};

/**
 * till sap_goods_reecipts release this hook will support both versions
 * TODO: TPX will remove purchaseOrders after release
 */

export const useGetThreeWayMatchData = (
  documentId?: string
): ThreeWayMatchProps => {
  const { shouldUseSapGoodsReceipts } = useSap();
  // TODO TPX will remove this after sap_goods_receipts release
  const { purchaseOrders, isLoading: loadingPOs } =
    useSapPurchaseOrders(documentId);

  const { data: bookingFormData } = useBookingsFormContext();
  const {
    associatedDocuments,
    associatedDocumentsLength,
    isLoading: isAssociatedDocsLoading,
  } = useAssociatedDocuments(documentId);
  const { associatedPurchaseOrders, associatedGoodsReceipts } =
    associatedDocuments;

  const hasAnyAssociatedDocuments = Boolean(associatedDocumentsLength);

  const cannotHaveBookingAssociations = shouldUseSapGoodsReceipts
    ? !hasAnyAssociatedDocuments
    : !purchaseOrders.length;

  const { data: bookingAssociations, loading: loadingBookingAssoc } =
    useGetDocumentBookingAssociationsByInvoiceIdQuery({
      variables: { invoiceId: documentId || '' },
      skip: !Boolean(documentId) || cannotHaveBookingAssociations,
    });

  const isLoading =
    isAssociatedDocsLoading || loadingBookingAssoc || loadingPOs;

  const associatedGoodsReceiptsData = useMemo(() => {
    if (associatedGoodsReceipts.length === 0) {
      return associatedPurchaseOrders.flatMap(po => po?.goodsReceipts ?? []);
    }
    return associatedGoodsReceipts;
  }, [associatedGoodsReceipts, associatedPurchaseOrders]);

  const associatedPurchaseOrdersData = useMemo(() => {
    if (associatedPurchaseOrders.length === 0) {
      return (
        associatedGoodsReceipts.flatMap(gr => gr?.purchaseOrders ?? []) ?? []
      );
    }
    return associatedPurchaseOrders;
  }, [associatedGoodsReceipts, associatedPurchaseOrders]);

  // TODO: TPX will remove this after release sap_goods_receipts
  const linkedPurchaseOrdersDeprecated = useMemo(
    () =>
      purchaseOrders.map(po => ({
        id: po?._id ?? '',
        orderNumber: po?.orderNumber ?? '',
        postingDate: po?.postingDate ?? '',
        amount: po?.amount,
        status: po?.status,
        purchaseOrderType: po?.purchaseOrderType,
        goodsLineItems: (po?.goodsLineItems ?? []).map(item => ({
          description: item.description,
          quantity: item.quantity,
          unitPrice: item.unitPrice,
          itemNumber: item.itemNumber,
          identifier: `${po?._id ?? ''}-${item.lineNumber}`,
          orderNumber: po?.orderNumber ?? '',
          purchaseOrderId: po?._id ?? '',
        })),
      })),
    [purchaseOrders]
  );

  // TODO: TPX will remove this after release sap_goods_receipts
  const linkedGoodsReceiptsDeprecated = useMemo(
    () =>
      purchaseOrders
        .flatMap(po => po?.goodsReceipts ?? [])
        .map(receipt => ({
          id: receipt?._id ?? '',
          receiptNumber: receipt?.receiptNumber ?? '',
          deliveryDate: receipt?.deliveryDate ?? '',
          status: receipt?.status,
          amount: receipt?.amount,
          goodsLineItems: (receipt?.goodsLineItems ?? []).map(item => ({
            description: item.description,
            quantity: item.quantity,
            itemNumber: item.itemNumber,
            identifier: `${receipt?._id ?? ''}-${item.lineNumber}`,
            receiptNumber: receipt?.receiptNumber ?? '',
            unitPrice: item.unitPrice,
          })),
        })),
    [purchaseOrders]
  );

  if (isLoading) {
    return {
      isLoading: true,
      result: [],
    };
  }

  const bookingAssociationsData =
    (bookingAssociations?.getDocumentBookingAssociationsByInvoiceId ??
      []) as DocumentBookingAssociation[];

  const purchaseOrdersInfo = bookingAssociationsData.flatMap(
    bookingAssociation =>
      bookingAssociation?.purchaseOrdersInfo?.map(po => ({
        articleId: po.articleId,
        purchaseOrderId: po.purchaseOrderId,
        purchaseOrderLineNumber: po.purchaseOrderLineNumber,
        quantity: po.quantity,
        unitPrice: po.unitPrice,
        bookingId: bookingAssociation?.bookingId,
      })) ?? []
  );

  const goodsReceiptsInfo = bookingAssociationsData.flatMap(
    bookingAssociation =>
      (bookingAssociation?.goodsReceiptsInfo ?? [])?.map(gr => ({
        articleId: gr.articleId,
        goodsReceiptId: gr.goodsReceiptId,
        goodsReceiptLineNumber: gr.goodsReceiptLineNumber,
        linkedPurchaseOrderId: gr.linkedPurchaseOrderId ?? '',
        quantity: gr.quantity,
        unitPrice: gr.unitPrice,
        bookingId: bookingAssociation?.bookingId,
      }))
  );

  const bookingsData = getBookingsData(
    bookingFormData,
    bookingAssociationsData
  );

  // TODO: TPX will remove these two data source support after release sap_goods_receipts and only use associatedPurchaseOrdersData
  const enrichedPurchaseOrdersData = shouldUseSapGoodsReceipts
    ? associatedPurchaseOrdersData
    : linkedPurchaseOrdersDeprecated;
  const enrichedGoodsReceiptsData = shouldUseSapGoodsReceipts
    ? associatedGoodsReceiptsData
    : linkedGoodsReceiptsDeprecated;

  const purchaseOrdersData = getPurchaseOrdersData(
    enrichedPurchaseOrdersData,
    purchaseOrdersInfo
  );

  const goodsReceiptsData = getGoodsReceiptsData(
    enrichedGoodsReceiptsData,
    goodsReceiptsInfo
  );
  const linkingSource = getLinkingSource(associatedDocuments);
  const result = getThreeWayMatchResult({
    bookingsData,
    purchaseOrdersData,
    goodsReceiptsData,
    linkingSource,
  });

  return {
    result,
  };
};

import { Card, Flex, Grid, Image, Paragraph } from '@candisio/design-system';
import { CreditCardDetailsProps } from 'containers/credit-cards/CreditCard/types';
import {
  overlayVariant,
  statuseWithOverlay,
} from 'containers/credit-cards/utils';
import { CardType } from 'generated-types/graphql.types';
import { CardStatus } from 'generated-types/resolvers-types';
import { motion } from 'motion/react';
import { Overlay } from '../Overlay';
import visaWhite from '../media/visa-light.svg';
import { defaultBackgroundCandis, variants } from '../utils';
import { Details } from './sections/Details';
import { Header } from './sections/Header';

type CandisLayoutProps = {
  initialState?: CardStatus;
  cardType: CardType;
  cardDetails: CreditCardDetailsProps;
  showFooter: boolean;
};

export const CandisLayout = ({
  initialState,
  cardType,
  cardDetails,
  showFooter,
}: CandisLayoutProps) => {
  const hasOverlay = initialState && statuseWithOverlay.includes(initialState);

  const overlay = hasOverlay ? (
    <Overlay overlayType={overlayVariant[initialState]} />
  ) : null;

  return (
    <Card
      position="relative"
      background={defaultBackgroundCandis}
      borderRadius="medium"
      width="180px"
      height="280px"
      color="white"
      data-testid="candis-credit-card"
      boxShadow="noShadow"
    >
      <motion.div
        style={{ height: '100%' }}
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        {overlay}
        <Grid templateRows=".5fr 1.5fr auto" height="100%">
          <Header cardType={cardType} />
          <Flex direction="column" justifyContent="center">
            <Details cardDetails={cardDetails} />
          </Flex>
          {showFooter && (
            <Flex direction="column" alignItems="end">
              <Image
                alt="visa logo"
                src={visaWhite}
                width="66px"
                height="24px"
              />
              <Paragraph fontSize="7px" fontWeight="semibold">
                PLATINUM BUSINESS
              </Paragraph>
            </Flex>
          )}
        </Grid>
      </motion.div>
    </Card>
  );
};

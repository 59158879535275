import { useApolloClient } from '@apollo/client';
import debounce from 'debounce-promise';
import {
  ContactByAccountsNumberQuery,
  ContactByAccountsNumberQueryVariables,
} from 'generated-types/graphql.types';
import { useCallback, useMemo } from 'react';
import { contactByAccountsNumberQuery } from 'views/queries/contacts';
import {
  AccountType,
  CheckAccountsNumber,
} from './ContactForm/accountsNumberSchema';

const DEBOUNCE_MS = 500;

export interface UseCheckAccountsNumberOptions {
  originalPayableValue?: string | null;
  originalReceivableValue?: string | null;
  contactId?: string;
}

export const useCheckAccountsNumber = ({
  originalPayableValue,
  originalReceivableValue,
  contactId,
}: UseCheckAccountsNumberOptions = {}) => {
  const apolloClient = useApolloClient();

  const checkAccountsNumber = useCallback<CheckAccountsNumber>(
    async (value, accountType: AccountType = 'accountsPayable') => {
      const valueAsString = value?.toString();

      if (
        valueAsString &&
        valueAsString !==
          (accountType === 'accountsPayable'
            ? originalPayableValue
            : originalReceivableValue)
      ) {
        const { data } = await apolloClient.query<
          ContactByAccountsNumberQuery,
          ContactByAccountsNumberQueryVariables
        >({
          query: contactByAccountsNumberQuery,
          variables: { accountsNumber: valueAsString },
          fetchPolicy: 'network-only',
        });

        const contact = data.contactByAccountsNumber;

        if (contact?.__typename === 'Contact' && contactId !== contact?.id) {
          if (
            contact.accountsPayableNumber &&
            contact.accountsPayableNumber === valueAsString
          ) {
            return {
              isAvailable: false,
              contactName: contact.name.value,
              numberUsed: 'accountsPayable',
            };
          } else if (
            contact.accountsReceivableNumber &&
            contact.accountsReceivableNumber === valueAsString &&
            contactId !== contact?.id
          ) {
            return {
              isAvailable: false,
              contactName: contact.name.value,
              numberUsed: 'accountsReceivable',
            };
          }
        }
      }

      return { isAvailable: true, contactName: undefined };
    },
    [apolloClient, contactId, originalPayableValue, originalReceivableValue]
  );

  const checkAccountsPayableNumber = useMemo(
    () =>
      debounce(async (value: string) => {
        return checkAccountsNumber(value, 'accountsPayable');
      }, DEBOUNCE_MS),
    [checkAccountsNumber]
  );

  const checkAccountsReceivableNumber = useMemo(
    () =>
      debounce(async (value: string) => {
        return checkAccountsNumber(value, 'accountsReceivable');
      }, DEBOUNCE_MS),
    [checkAccountsNumber]
  );

  return { checkAccountsReceivableNumber, checkAccountsPayableNumber };
};

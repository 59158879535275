import { FieldPolicy, TypePolicies } from '@apollo/client';
import { CardIssuerStatementsConnectionPageBased } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const cardIssuerStatementRecords: FieldPolicy<CardIssuerStatementsConnectionPageBased> =
  {
    keyArgs: ['filters', 'dateRangeFilters', 'sortBy'],
    merge: simplePaginationMergeWithoutDuplicates(
      'CardIssuerStatementsConnectionPageBased'
    ),
  };

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      cardIssuerStatementsPageBased: cardIssuerStatementRecords,
    },
  },
};

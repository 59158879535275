import { CellProps, Tag, Text } from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';
import { mappedReimbursementStatuses } from 'views/Reimbursement/utils/constants';

export const ReimbursementStatusCell = ({
  value,
}: CellProps<ReimbursementTableRow, ReimbursementCaseStatus>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  if (!value) {
    return <Text>{t('table:emptyCellPlaceholder')}</Text>;
  }

  const statusVariant = mappedReimbursementStatuses[value];

  return (
    <Tag color={statusVariant.color} variant="secondary">
      {t(statusVariant.key)}
    </Tag>
  );
};

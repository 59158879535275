import { CellContext } from '@tanstack/react-table';
import { HookFormAmountInput } from 'components/HookFormInputs/HookFormAmountInput';
import {
  BookingsFormValues,
  TableFormRow,
} from '../../schema/bookingsFormSchema';
import { useWatch } from 'react-hook-form';
import { useGetOnAmountChange } from '../../hooks/useOnChangeFactory';

type Value = TableFormRow['amount'];

export const AmountCell: React.FC<CellContext<TableFormRow, Value>> = ({
  row: { index },
}) => {
  const currency = useWatch<BookingsFormValues, 'currency'>({
    name: 'currency',
  });
  const handleAmountChange = useGetOnAmountChange(index);
  return (
    <HookFormAmountInput
      name={`bookings.${index}.amount`}
      dataCy={`bookings.${index}.amount`}
      currency={currency}
      onChange={val => handleAmountChange(val as Value)}
    />
  );
};

import { SwimlaneTilePromo } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromo';
import { SwimlaneTilePromoCTAButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCTAButton';
import {
  SwimlaneTilePromoContent,
  SwimlaneTilePromoContentBody,
} from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoContent';
import { SwimlaneTilePromoCloseButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCloseButton';
import { SwimlaneTileTitle } from 'components/Swimlane/content/SwimlaneTileTitle';
import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const PromoExpenseRejected = ({ onClose }: { onClose: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <SwimlaneTilePromo size="small">
      <SwimlaneTilePromoContent>
        <SwimlaneTilePromoCloseButton onClose={onClose} />
        <SwimlaneTilePromoContentBody>
          <SwimlaneTileDescription>
            {t(
              'dashboard.swimlanes.promotions.promoExpenseRejected.description'
            )}
          </SwimlaneTileDescription>
          <SwimlaneTileTitle>
            {t('dashboard.swimlanes.promotions.promoExpenseRejected.title')}
          </SwimlaneTileTitle>
          <SwimlaneTilePromoCTAButton
            icon="arrowRight"
            iconPosition="right"
            as="a"
            target="_blank"
            href={t(
              'dashboard.swimlanes.promotions.promoExpenseRejected.ctaButton.link'
            )}
          >
            {t(
              'dashboard.swimlanes.promotions.promoExpenseRejected.ctaButton.text'
            )}
          </SwimlaneTilePromoCTAButton>
        </SwimlaneTilePromoContentBody>
      </SwimlaneTilePromoContent>
    </SwimlaneTilePromo>
  );
};

import {
  EcmDocumentPermission,
  useEcmDocumentPermissionsQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { CamelizeSnakeCase } from 'types/camelize';

export type PermissionForEcmDocument = Partial<
  // Not to be confused with other "CAN" prefixes, this means "can" perform the action
  Record<CamelizeSnakeCase<`CAN_${EcmDocumentPermission}`>, boolean>
>;

/**
 * Returns an object with the actions the current membership can perform
 * on the ECM Document.
 */
export const useEcmDocumentPermissions = ({
  id,
  skip,
}: { id: string; skip?: boolean }): PermissionForEcmDocument => {
  const modifyDirectEditAccessFF = useCandisFeatureFlags(
    FEATURE_FLAGS.modifyEcmDocumentEditAccess
  );

  const { data } = useEcmDocumentPermissionsQuery({
    variables: { id },
    skip: !id || skip,
    // If we need the permissions for an ECM Document, we have most likely
    // already fetched the AggregatedEcmDocument.
    fetchPolicy: 'cache-first',
  });

  const { isOnlyApprover } = useUserRoles();

  const permissions = new Set<`${EcmDocumentPermission}`>(
    data?.getAggregatedEcmDocument?.permissionsForMembership ?? []
  );

  // These two are fallback while we transition to the new permissions system
  const isDocumentReadOnly = data?.getAggregatedEcmDocument.isReadOnly ?? true;
  const canManageAccess =
    data?.getAggregatedEcmDocument.accessModificationPermissions.viewAccess ??
    false;

  if (!modifyDirectEditAccessFF) {
    return {
      canDelete: !isDocumentReadOnly && !isOnlyApprover,
      canManageAccess: canManageAccess,
      canManageTags: !isDocumentReadOnly,
      canUpdateData: !isDocumentReadOnly,
      canAddRelationship: !isDocumentReadOnly,
      canRemoveRelationship: !isDocumentReadOnly,
    };
  }

  return {
    canDelete:
      permissions.has('DELETE') || (!isDocumentReadOnly && !isOnlyApprover),
    canManageAccess: permissions.has('MANAGE_ACCESS') || canManageAccess,
    canManageTags: permissions.has('MANAGE_TAGS') || !isDocumentReadOnly,
    canUpdateData: permissions.has('UPDATE_DATA') || !isDocumentReadOnly,
    canAddRelationship:
      permissions.has('ADD_RELATIONSHIP') || !isDocumentReadOnly,
    canRemoveRelationship:
      permissions.has('REMOVE_RELATIONSHIP') || !isDocumentReadOnly,
  };
};

import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { useState, useCallback } from 'react';

export const useOptimisticPromoPreference = (promoId: TOUR_POPOVER_IDS) => {
  const { isPromotionSeen, setPromotionIsSeen } =
    useSyncedPromoPreference(promoId);
  const [isSeenLocal, setIsSeenLocal] = useState(isPromotionSeen);

  const handleConfirm = useCallback(() => {
    setIsSeenLocal(true);
    setPromotionIsSeen(true);
  }, [setPromotionIsSeen]);

  return {
    isSeen: isPromotionSeen || isSeenLocal,
    markAsSeen: handleConfirm,
  };
};

import gql from 'graphql-tag';

export const expenseItemBookingFragment = gql`
  fragment ExpenseItemBookingData on ExpenseItemBooking {
    costCenter {
      readableName
    }
    costObject {
      readableName
    }
    extraCostInfo {
      readableName
    }
    artistSocialInsuranceCode
    generalLedgerAccount {
      readableName
    }
    bookingKey {
      readableName
    }
    amount
    vatRate
    note
    postingText
    taxAmount
    netAmount
  }
`;

export const reimbursementCaseTitleUpdatedEventFragment = gql`
  fragment ReimbursementCaseTitleUpdatedEventData on ReimbursementCaseTitleUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldTitle
      newTitle
    }
  }
`;

export const expenseItemTitleUpdatedEventFragment = gql`
  fragment ExpenseItemTitleUpdatedEventData on ExpenseItemTitleUpdatedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldTitle
      newTitle
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseAmendmentRequestedEventFragment = gql`
  fragment ReimbursementCaseAmendmentRequestedEventData on ReimbursementCaseAmendmentRequestedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      requestedAt
      comment {
        author {
          name
        }
        id
        text
      }
    }
  }
`;

export const reimbursementCaseApprovedEventFragment = gql`
  fragment ReimbursementCaseApprovedEventData on ReimbursementCaseApprovedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      approvedAt
    }
  }
`;

export const reimbursementCaseCreatedEventFragment = gql`
  fragment ReimbursementCaseCreatedEventData on ReimbursementCaseCreatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      createdAt
    }
  }
`;

export const reimbursementCaseSubmittedForReviewEventFragment = gql`
  fragment ReimbursementCaseSubmittedForReviewEventData on ReimbursementCaseSubmittedForReviewEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      submittedAt
    }
  }
`;

export const reimbursementCaseTargetMembershipUpdatedEventFragment = gql`
  fragment ReimbursementCaseTargetMembershipUpdatedEventData on ReimbursementCaseTargetMembershipUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldTargetMembership {
        name
      }
      newTargetMembership {
        name
      }
    }
  }
`;

export const reimbursementCaseNoteUpdatedEventFragment = gql`
  fragment ReimbursementCaseNoteUpdatedEventData on ReimbursementCaseNoteUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldNote
      newNote
    }
  }
`;

export const expenseItemTotalAmountUpdatedEventFragment = gql`
  fragment ExpenseItemTotalAmountUpdatedEventData on ExpenseItemTotalAmountUpdatedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldTotalAmount
      newTotalAmount
      oldCurrency
      newCurrency
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemFileAddedEventFragment = gql`
  fragment ExpenseItemFileAddedEventData on ExpenseItemFileAddedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      fileId
    }
    reimbursementItemMetadata {
      title
      type
    }
    clientInfo {
      name
    }
  }
`;

export const expenseItemFileRemovedEventFragment = gql`
  fragment ExpenseItemFileRemovedEventData on ExpenseItemFileRemovedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      fileId
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemExpenseDateUpdatedEventFragment = gql`
  fragment ExpenseItemExpenseDateUpdatedEventData on ExpenseItemExpenseDateUpdatedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldExpenseDate
      newExpenseDate
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseCommentAddedEventFragment = gql`
  fragment ReimbursementCaseCommentAddedEventData on ReimbursementCaseCommentAddedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      comment {
        text
      }
    }
  }
`;

export const expenseItemArchivedEventFragment = gql`
  fragment ExpenseItemArchivedEventData on ExpenseItemArchivedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      archivedAt
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemHospitalityExternalGuestsUpdatedEventFragment = gql`
  fragment ExpenseItemHospitalityExternalGuestsUpdatedEventData on ExpenseItemHospitalityExternalGuestsUpdatedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldExternalGuests
      newExternalGuests
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemHospitalityInternalGuestsUpdatedEventFragment = gql`
  fragment ExpenseItemHospitalityInternalGuestsUpdatedEventData on ExpenseItemHospitalityInternalGuestsUpdatedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldInternalGuests
      newInternalGuests
    }
    reimbursementItemMetadata {
      title
    }
  }
`;

export const expenseItemHospitalityLocationUpdatedEventFragment = gql`
  fragment ExpenseItemHospitalityLocationUpdatedEventData on ExpenseItemHospitalityLocationUpdatedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldLocation
      newLocation
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemHospitalityReceiptAmountUpdatedEventFragment = gql`
  fragment ExpenseItemHospitalityReceiptAmountUpdatedEventData on ExpenseItemHospitalityReceiptAmountUpdatedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldReceiptAmount
      newReceiptAmount
      oldCurrency
      newCurrency
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemHospitalityTipAmountUpdatedEventFragment = gql`
  fragment ExpenseItemHospitalityTipAmountUpdatedEventData on ExpenseItemHospitalityTipAmountUpdatedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      oldTipAmount
      newTipAmount
      oldCurrency
      newCurrency
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemBookingAddedEventFragment = gql`
  fragment ExpenseItemBookingAddedEventData on ExpenseItemBookingAddedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      currency
      booking {
        ...ExpenseItemBookingData
      }
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
  ${expenseItemBookingFragment}
`;

export const expenseItemBookingRemovedEventFragment = gql`
  fragment ExpenseItemBookingRemovedEventData on ExpenseItemBookingRemovedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      booking {
        ...ExpenseItemBookingData
      }
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
  ${expenseItemBookingFragment}
`;

export const expenseItemBookingUpdatedEventFragment = gql`
  fragment ExpenseItemBookingUpdatedEventData on ExpenseItemBookingUpdatedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      currency
      newBooking {
        ...ExpenseItemBookingData
      }
      oldBooking {
        ...ExpenseItemBookingData
      }
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
  ${expenseItemBookingFragment}
`;

export const reimbursementCaseResetApprovalEventFragment = gql`
  fragment ReimbursementCaseResetApprovalEventData on ReimbursementCaseResetApprovalEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      resetAt
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseItemAddedEventFragment = gql`
  fragment ReimbursementCaseItemAddedEventData on ReimbursementCaseItemAddedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      expenseItemType
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseItemRemovedEventFragment = gql`
  fragment ReimbursementCaseItemRemovedEventData on ReimbursementCaseItemRemovedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      expenseItemType
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseItemIncludedEventFragment = gql`
  fragment ReimbursementCaseItemIncludedEventData on ReimbursementCaseItemIncludedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      expenseItemType
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseItemExcludedEventFragment = gql`
  fragment ReimbursementCaseItemExcludedEventData on ReimbursementCaseItemExcludedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      expenseItemType
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemInvoiceNumberUpdatedEventFragment = gql`
  fragment ExpenseItemInvoiceNumberUpdatedEventData on ExpenseItemInvoiceNumberUpdatedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    properties {
      newInvoiceNumber
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseItemExportStatusUpdatedEventFragment = gql`
  fragment ReimbursementCaseItemExportStatusUpdatedEventData on ReimbursementCaseItemExportStatusUpdatedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      exportGuid
      exportType
      failedItems {
        reimbursementItemId
        reimbursementItemTitle
        reimbursementItemType
      }
      successfulItems {
        datevLocationParams {
          datevClientNumber
          datevConsultantNumber
          entityExportGuid
        }
        reimbursementItemId
        reimbursementItemTitle
        reimbursementItemType
      }
    }
    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const reimbursementCaseSubmittedForApprovalEventFragment = gql`
  fragment ReimbursementCaseSubmittedForApprovalEventData on ReimbursementCaseSubmittedForApprovalEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      submittedAt
      approvers {
        name
      }
    }
  }
`;

export const reimbursementCaseTriggeredWorkflowEventFragment = gql`
  fragment ReimbursementCaseTriggeredWorkflowEventData on ReimbursementCaseTriggeredWorkflowEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      submittedAt
      workflow {
        name
        description
      }
    }
  }
`;

export const reimbursementCaseWorkflowStepApprovedEventFragment = gql`
  fragment ReimbursementCaseWorkflowStepApprovedEventData on ReimbursementCaseWorkflowStepApprovedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      approvedBy {
        name
      }
      approvedAt
    }
  }
`;

export const reimbursementCaseWorkflowStepRejectedEventFragment = gql`
  fragment ReimbursementCaseWorkflowStepRejectedEventData on ReimbursementCaseWorkflowStepRejectedEvent {
    user {
      id
      name
      avatarUrl
    }
    properties {
      rejectedBy {
        name
      }
      rejectedAt
    }
  }
`;

export const reimbursementCaseRejectedEventFragment = gql`
  fragment ReimbursementCaseRejectedEventData on ReimbursementCaseRejectedEvent {
    user {
      id
      name
      avatarUrl
    }
    clientInfo {
      name
    }
    properties {
      rejectedAt
      rejectedBy {
        name
      }
      comment {
        text
      }
    }

    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemHospitalityDataExtractedEventFragment = gql`
  fragment ExpenseItemHospitalityDataExtractedEventData on ExpenseItemHospitalityDataExtractedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    clientInfo {
      name
    }
    properties {
      invoiceNumber
      expenseDate
      sourceFileId
      location
      receiptAmount {
        amount
        currency
        precision
      }
      tipAmount {
        amount
        currency
        precision
      }
    }

    reimbursementItemMetadata {
      title
      type
    }
  }
`;

export const expenseItemGeneralDataExtractedEventFragment = gql`
  fragment ExpenseItemGeneralDataExtractedEventData on ExpenseItemGeneralDataExtractedEvent {
    resourceId
    user {
      id
      name
      avatarUrl
    }
    clientInfo {
      name
    }
    properties {
      invoiceNumber
      expenseDate
      sourceFileId
      totalAmount {
        amount
        currency
        precision
      }
    }

    reimbursementItemMetadata {
      title
      type
    }
  }
`;

import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { useEffect } from 'react';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';

interface UseFormEffectsProps {
  status?: ReimbursementCaseStatus;
  hasErrors: boolean;
  remainingAmount: number;
  handleSetRemainingAmount: (remainingAmount: number) => void;
  handleShowErrors: (show: boolean) => void;
}

export const useFormEffects = ({
  status,
  hasErrors,
  remainingAmount,
  handleSetRemainingAmount,
  handleShowErrors,
}: UseFormEffectsProps) => {
  const { setApprovalMode } = useReimbursementFormsContext();

  const shouldEnforceFastApproveValidations =
    status === ReimbursementCaseStatus.PartiallyExported;

  useEffect(() => {
    handleSetRemainingAmount(remainingAmount);
  }, [remainingAmount, handleSetRemainingAmount]);

  useEffect(() => {
    if (!hasErrors) {
      handleShowErrors(false);
    }
  }, [hasErrors, handleShowErrors]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (shouldEnforceFastApproveValidations) {
      setApprovalMode('approve');
    }
  }, []);
};

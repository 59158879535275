import { Avatar, Flex, Grid, Text } from '@candisio/design-system';
import { AvatarUpload } from 'components/AvatarUpload/AvatarUpload';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { Locale, Maybe } from 'generated-types/graphql.types';
import { AnimatePresence } from 'motion/react';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useTranslation } from 'react-i18next';
import { VerifyEmailCard } from './VerifyCard';

export interface UserProfileUpdateFormProps {
  avatar?: Maybe<string>;
  name?: string;
  readOnly?: {
    firstName?: boolean;
    lastName?: boolean;
  };
  newEmail?: string;
  onCancelChangeEmailRequest: () => Promise<void>;
  onRemoveAvatar: () => Promise<void>;
  onResendVerificationEmail: () => Promise<void>;
  onUploadAvatar: (f: File) => Promise<Response | undefined>;
  removeAvatarLoading?: boolean;
  uploadAvatarLoading?: boolean;
  userId?: string;
  cancelChangeEmailIsLoading: boolean;
  resendChangeEmailIsLoading: boolean;
  readableMembershipRoles: string[];
}

export const UserProfileUpdateForm = ({
  avatar,
  name,
  newEmail,
  onCancelChangeEmailRequest,
  onRemoveAvatar,
  onResendVerificationEmail,
  onUploadAvatar,
  readOnly,
  removeAvatarLoading,
  uploadAvatarLoading,
  cancelChangeEmailIsLoading,
  resendChangeEmailIsLoading,
  readableMembershipRoles,
}: UserProfileUpdateFormProps) => {
  const [t] = useTranslation();
  const hasPendingChangeEmailRequest = Boolean(newEmail);

  const { canUseReimbursement } = useReimbursement();

  const locales = Object.values(Locale).map(value => ({
    children: t(`locales.${value}`),
    key: value,
  }));

  const isNotLastRole = (index: number) =>
    index !== readableMembershipRoles.length - 1;

  return (
    <Grid gap="space24">
      <AvatarUpload
        onRemove={onRemoveAvatar}
        onUpload={onUploadAvatar}
        hasAvatar={Boolean(avatar)}
        removeAvatarLoading={removeAvatarLoading}
        uploadAvatarLoading={uploadAvatarLoading}
      >
        <Avatar
          name={name}
          img={avatar ?? undefined}
          size="large"
          hasTooltip={false}
        />
      </AvatarUpload>
      <Flex
        gap="space8"
        justifySelf="center"
        wrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        {readableMembershipRoles.map((role, index) => (
          <Flex
            key={role}
            gap="space8"
            fontSize="basic"
            color="gray500"
            alignItems="center"
          >
            <Text>{role}</Text>
            {isNotLastRole(index) && <div>•</div>}
          </Flex>
        ))}
      </Flex>
      <Grid
        background="gray100"
        borderRadius="8px"
        padding="space16"
        rowGap="space12"
        columnGap={canUseReimbursement ? 'space16' : 'space32'}
        templateColumns="1fr 1fr 1fr 1fr"
        templateRows="auto auto auto"
      >
        <Grid gridRow="1" gridColumn="1/3">
          <HookFormTextField
            readOnly={readOnly?.firstName}
            disabled={readOnly?.firstName}
            label={t('header.profile.tabs.update.form.firstName.label')}
            name="firstName"
          />
        </Grid>
        <Grid gridRow="1" gridColumn="3/5">
          <HookFormTextField
            readOnly={readOnly?.lastName}
            disabled={readOnly?.lastName}
            name="lastName"
            label={t('header.profile.tabs.update.form.lastName.label')}
          />
        </Grid>
        <Grid gridRow="2" gridColumn="1/5" justifyContent="stretch">
          <AnimatePresence>
            {!hasPendingChangeEmailRequest && (
              <HookFormTextField
                name="email"
                label={t('header.profile.tabs.update.form.email.label')}
                inputProps={{
                  type: 'email',
                }}
              />
            )}
          </AnimatePresence>
          <VerifyEmailCard
            newEmail={newEmail}
            hasPendingChangeEmailRequest={Boolean(hasPendingChangeEmailRequest)}
            onCancelChangeEmailRequest={onCancelChangeEmailRequest}
            onResendVerificationEmail={onResendVerificationEmail}
            resendChangeEmailIsLoading={resendChangeEmailIsLoading}
            cancelChangeEmailIsLoading={cancelChangeEmailIsLoading}
          />
        </Grid>
        <Grid gridRow="3" gridColumn="1/3">
          <HookFormSelectField
            name="locale"
            label={t('header.profile.tabs.update.form.language.label')}
            items={locales}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

import { Grid, Text, Tooltip, useTooltip } from '@candisio/design-system';
import { getColorByOrganization, getOrganizationNameInitials } from '../utils';

export const OrganizationAvatar = ({
  organizationName,
  showTooltip,
}: {
  organizationName: string | undefined;
  showTooltip?: boolean;
}) => {
  const backgroundColor = getColorByOrganization(organizationName ?? '');

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'right',
      passiveTrigger: true,
      delay: 500,
    });

  return (
    <>
      <Grid
        alignContent="center"
        background={backgroundColor}
        borderRadius="small"
        height="40px"
        flexShrink={0}
        justifyContent="center"
        alignItems="center"
        padding="space4"
        width="40px"
        ref={triggerRef}
        {...triggerProps}
      >
        <Text fontSize="xlarge" color="gray0">
          {getOrganizationNameInitials(organizationName ?? '')}
        </Text>
      </Grid>

      {showTooltip && isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {organizationName}
        </Tooltip>
      )}
    </>
  );
};

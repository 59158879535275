import {
  Avatar,
  Box,
  Flex,
  Grid,
  TruncatedText,
} from '@candisio/design-system';
import { CandisLayout } from 'containers/credit-cards/CreditCard/Candis/CandisLayout';
import { motion } from 'motion/react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useGetVendorForRecurringPaymentsById } from 'views/CreditCards/hooks/useGetVendorForRecurringPaymentsById';
import { SuccessStepHeadline } from '../SuccessStep/SuccessStepHeadline';
import { useCreditCardPlaceholderDetails } from '../hooks/useCreditCardPlaceholderDetails';
import { getSlideInVariants } from '../utils';

const MotionBox = motion.create(Box);

export const SuccessStepCardSection = () => {
  const { vendorId } = useParams<{ vendorId?: string }>();

  const { vendorDetails } = useGetVendorForRecurringPaymentsById({ vendorId });

  const { cardDetails } = useCreditCardPlaceholderDetails();

  const { animateFromLeft } = getSlideInVariants();

  return (
    <Grid
      background="gray200"
      alignContent="start"
      placeContent="center"
      placeItems="center"
      padding="space24"
      gap="space32"
    >
      <Grid gap="space40">
        <Grid gap="space16">
          {vendorDetails.id && vendorDetails.name && (
            <Flex gap="space4">
              <Avatar
                img={vendorDetails.logoUrl}
                hasTooltip={false}
                name={vendorDetails.name}
                size="medium"
              />
              <TruncatedText
                maxWidth="50ch"
                fontSize="xxlarge"
                lineHeight="paragraph"
              >
                {vendorDetails.name}
              </TruncatedText>
            </Flex>
          )}
          <MotionBox
            animate="visible"
            variants={animateFromLeft}
            initial={animateFromLeft.hidden}
          >
            <CandisLayout
              showFooter={true}
              cardType={cardDetails.cardType}
              cardDetails={cardDetails}
            />
          </MotionBox>
        </Grid>
      </Grid>
      <SuccessStepHeadline />
    </Grid>
  );
};

import { Grid, ScrollBox } from '@candisio/design-system';
import {
  XmlHeader,
  XmlHeaderProps,
} from 'components/DocumentViewer/XmlViewer/XmlHeader';
import { useXmlContent } from 'components/DocumentViewer/XmlViewer/useXmlContent';
import ReactXMLViewer from 'react-xml-viewer';
import styles from './XmlViewer.module.css';

export interface XmlViewerProps {
  xmlUrl?: string;
  downloadSection?: XmlHeaderProps['downloadSection'];
  additionalControls?: XmlHeaderProps['additionalControls'];
}

export const XmlViewer = ({
  xmlUrl,
  additionalControls,
  downloadSection,
}: XmlViewerProps) => {
  const xmlContent = useXmlContent(xmlUrl);

  return (
    <Grid height="100%" templateRows="min-content minmax(0, auto)" gap="space8">
      <XmlHeader
        downloadSection={downloadSection}
        additionalControls={additionalControls}
      />
      <ScrollBox>
        <div className={styles['wrapper']}>
          <ReactXMLViewer xml={xmlContent} collapsible />
        </div>
      </ScrollBox>
    </Grid>
  );
};

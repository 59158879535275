import { Grid, mergeProps, mergeRefs } from '@candisio/design-system';
import {
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  forwardRef,
  useRef,
} from 'react';
import { AriaMenuProps, useListBox } from 'react-aria';
import { Item, useListState } from 'react-stately';
import { Virtuoso } from 'react-virtuoso';
import { CCFilterOption } from '../../utils/paginationFilterHooks';
import { ListItemIconMemoized } from './ListItemIconMemoized';

/** @ts-expect-error TODO: React upgrade props types mismatch */
interface VirtualizedListBoxProps extends HTMLAttributes<HTMLUListElement> {
  /** Where the focus should be set */
  autoFocus?: AriaMenuProps<CCFilterOption>['autoFocus'];
  /** List of menu items */
  items?: AriaMenuProps<CCFilterOption>['items'];
  /** Menu element's unique identifier */
  id?: AriaMenuProps<CCFilterOption>['id'];
  /** Identifies the element (or elements) that labels the menu */
  'aria-labelledby'?: AriaMenuProps<CCFilterOption>['aria-labelledby'];
  /** Type of selection allowed among the menu items*/
  selectionMode?: AriaMenuProps<CCFilterOption>['selectionMode'];
  /** Keys of the currently selected items in the menu */
  selectedKeys?: AriaMenuProps<CCFilterOption>['selectedKeys'];
  /** Called when the selection changes */
  onSelectionChange?: AriaMenuProps<CCFilterOption>['onSelectionChange'];
  /** MenuItems components or item render function */
  children?: AriaMenuProps<CCFilterOption>['children'];
  //** Function used to load more items with infinite scroll */
  loadMore?: () => void;
  //** Options to show in the virtualized list */
  sortedOptions: CCFilterOption[];
  onSetCurrentFilters: Dispatch<SetStateAction<string[]>>;
  currentFilters: string[];
}

const defaultRenderChildren = (item: CCFilterOption) => {
  return <Item key={item.id}>{item.label}</Item>;
};

const ITEM_HEIGHT = 40;

export const VirtualizedListBox = forwardRef<
  HTMLUListElement,
  VirtualizedListBoxProps
>(
  (
    {
      autoFocus = false,
      id,
      items,
      'aria-labelledby': ariaLabelledBy,
      selectionMode = 'multiple',
      selectedKeys,
      children = defaultRenderChildren,
      loadMore,
      sortedOptions,
      onSelectionChange,
      onSetCurrentFilters,
      currentFilters,
      ...restProps
    },
    forwardRef
  ) => {
    const state = useListState({
      children,
      items,
      selectionMode,
    });

    const ref = useRef(null);
    const { listBoxProps } = useListBox(
      {
        id,
        autoFocus,
        items,
        'aria-labelledby': ariaLabelledBy,
      },
      state,
      ref
    );

    const itemsCollection = Array.from(state.collection);

    return (
      <Grid
        id={id}
        as="ul"
        {...mergeProps(listBoxProps, restProps)}
        ref={mergeRefs([ref, forwardRef])}
        paddingY="space16"
        // TODO: figure out a better way to scale the height
        height={`${
          ITEM_HEIGHT * sortedOptions.length > 264
            ? 264
            : ITEM_HEIGHT * sortedOptions.length
        }px`}
        minHeight="space200"
        maxHeight="264px"
        padding="0"
      >
        <Virtuoso
          endReached={loadMore}
          overscan={200}
          style={{ height: '100%', overflowX: 'hidden' }}
          data={sortedOptions}
          totalCount={sortedOptions.length}
          itemContent={_index => {
            return (
              <ListItemIconMemoized
                state={state}
                key={itemsCollection[_index].key}
                item={itemsCollection[_index]}
                currentFilters={currentFilters}
                onSetCurrentFilters={onSetCurrentFilters}
              />
            );
          }}
        />
      </Grid>
    );
  }
);

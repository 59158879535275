import { Paragraph, Text } from '@candisio/design-system';
import { ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragment } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { amountFormat } from 'utils/format';
import { NavigateToItemWrapper } from '../NavigateToItemWrapper';

export const ExpenseItemHospitalityReceiptAmountUpdated = ({
  resourceId,
  properties,
  reimbursementItemMetadata,
}: ExpenseItemHospitalityReceiptAmountUpdatedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const headline = expenseTitle
    ? t('timeline.expenseChanged', { title: expenseTitle })
    : t('timeline.expenseChangedNoTitle');

  return (
    <NavigateToItemWrapper id={resourceId}>
      <Text>{headline}</Text>
      <div className="pt-2">
        <Text fontWeight="regular" color="gray500">
          {t(
            'reimbursementView.middleSection.form.hospitality.receiptAmount.label'
          )}
        </Text>
        <Paragraph>
          {!isNil(properties.newReceiptAmount)
            ? amountFormat(
                properties.newReceiptAmount,
                properties.newCurrency,
                { convertToBasicMonetaryUnit: true }
              )
            : t('timeline.empty')}
        </Paragraph>
      </div>
    </NavigateToItemWrapper>
  );
};

import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Text,
  useModal,
} from '@candisio/design-system';
import { PromotionDetailsModalContent } from 'components/NewPromotions/PromotionCard/PromotionDetailsModalContent/PromotionDetailsModalContent';
import { PromotionLabel } from 'components/NewPromotions/PromotionCard/PromotionHeader/PromotionHeader';
import { PromotionModal } from 'components/NewPromotions/PromotionCard/PromotionModal/PromotionModal';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { UPSELL_MAX_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { useGetCustomPromotionDetails } from 'components/ProductPromotions/hooks/useGetCustomPromotionDetails';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const SSOPromotionOrganization = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const {
    upsellMaxPromotion: { label, id },
  } = usePromotionContent();

  const customPromotionDetails = useGetCustomPromotionDetails(
    'upsellMax',
    UPSELL_MAX_UTM_SOURCE.SSO_ORGANIZATION
  );

  const { triggerProps, isOpen, close } = useModal();

  return (
    <Card height="10.37rem" paddingBottom="space32" data-cy="sso-promotion">
      <Grid height="100%" gap="space30">
        <Grid gap="space16">
          <Flex gap="space8">
            <Heading as="h3">{t('sso.title')}</Heading>
            <PromotionLabel label={label} />
          </Flex>
          <Text>{t('sso.paragraph')}</Text>
        </Grid>

        <Button variant="secondary" size="small" {...triggerProps} width="30%">
          {t('sso.buttons.configure')}
        </Button>

        {isOpen && (
          <PromotionModal
            isModalOpen={isOpen}
            closeModal={close}
            titleInfo={<PromotionLabel label={label} />}
            data-cy={`promotionModal-${id}`}
          >
            <PromotionDetailsModalContent
              promotionDetails={customPromotionDetails}
            />
          </PromotionModal>
        )}
      </Grid>
    </Card>
  );
};

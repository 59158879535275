import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Image,
  Paragraph,
  Text,
} from '@candisio/design-system';
import candisLogo from 'assets/logo.svg';
import { HelpCenterPanel } from 'containers/credit-cards/components/HelpCenterPanel';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import pliantLogo from '../../CreditCardsInitialState/media/pliant-logo.png';
import companyDataImage from './toolkit/assets/company-data-img.svg';
import requesterDataImage from './toolkit/assets/request-data-img.svg';

export interface OverviewStepProps {
  onNextStep: () => void;
}

export const OverviewStep = ({ onNextStep }: OverviewStepProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Grid gap="space20">
      <Text color="gray800" fontSize="xlarge" fontWeight="semibold">
        {t('modal.form.overviewForm.subTitle')}
      </Text>
      <Flex gap="space16" textAlign="center">
        <Card boxShadow="noShadow">
          <Flex direction="column" alignItems="center" gap="space24">
            <Flex width="100%" justifyContent="center" alignItems="center">
              <Box>
                <Paragraph>
                  {t('modal.form.overviewForm.firstInfoCard.subTitle')}
                </Paragraph>
                <Paragraph fontSize="large">
                  {t('modal.form.overviewForm.firstInfoCard.title')}
                </Paragraph>
              </Box>
              <Image
                alt="candis logo image"
                src={candisLogo}
                position="absolute"
                right="space8"
              />
            </Flex>
            <Image alt="company data image" src={companyDataImage} />
            <Text fontSize="basic">
              {t('modal.form.overviewForm.firstInfoCard.description')}
            </Text>
          </Flex>
        </Card>
        <Card boxShadow="noShadow">
          <Flex direction="column" alignItems="center" gap="space24">
            <Flex width="100%" justifyContent="center" alignItems="center">
              <Box>
                <Paragraph>
                  {t('modal.form.overviewForm.secondInfoCard.subTitle')}
                </Paragraph>
                <Paragraph fontSize="large">
                  {t('modal.form.overviewForm.secondInfoCard.title')}
                </Paragraph>
              </Box>{' '}
              <Image
                alt="pliant logo image"
                src={pliantLogo}
                height="space32"
                width="space64"
                position="absolute"
                right="space2"
              />
            </Flex>
            <Image alt="company data image" src={requesterDataImage} />
            <Text fontSize="basic">
              {t('modal.form.overviewForm.secondInfoCard.description')}
            </Text>
          </Flex>
        </Card>
      </Flex>
      <Flex direction="column" gap="space24">
        <HelpCenterPanel />
        <Flex gap="space16" justifyContent="end">
          <Button onClick={onNextStep}>
            {t('modal.form.overviewForm.ctaNextButton')}
          </Button>
        </Flex>
      </Flex>
    </Grid>
  );
};

import {
  Box,
  Grid,
  Tooltip,
  UseTooltipConfig,
  useTooltip,
} from '@candisio/design-system';
import { HookFormDayField } from 'components/HookFormFields/HookFormDayField';
import { HookFormIntegerField } from 'components/HookFormFields/HookFormIntegerField';
import { HookFormPercentField } from 'components/HookFormFields/HookFormPercentField';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { PaymentConditionsDrawerProps } from '../PaymentConditionsDrawer';
import { paymentConditionsFormErrors } from './paymentConditionsFormErrors';
import {
  DATE_OFFSET,
  DISCOUNT_PERCENTAGE,
  PaymentConditionsFormOutput,
  PaymentConditionsFormValues,
  paymentConditionsFormSchema,
} from './paymentConditionsFormSchema';

export interface PaymentConditionsFormProps {
  /** Form action */
  action: PaymentConditionsDrawerProps['action'];
  /** Called when form is submitted */
  onSubmit: (values: PaymentConditionsFormOutput) => void;
  /** Unique identifier of this form */
  formId: string;
  /** Initial values when the form is first rendered  */
  defaultValues?: PaymentConditionsFormValues;
  /** Form is read-only */
  readOnly?: boolean;
  /** Layout variant of the form */
  layout?: 'default' | 'compact';
}

const fallbackDefaultValues: PaymentConditionsFormValues = {
  dueDateOffset: null,
  discountPercentage: null,
  discountOffset: null,
};

export const PaymentConditionsForm = ({
  action,
  defaultValues,
  formId,
  layout = 'default',
  onSubmit: onSubmitProp,
  readOnly,
}: PaymentConditionsFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { isActiveIntegration: shouldUseSapPaymentConditions } = useSap();
  const tooltipData: UseTooltipConfig = {
    placement: 'top',
    isDisabled: !shouldUseSapPaymentConditions,
  };

  const numberToolTip = useTooltip(tooltipData);
  const dueDateOffsetToolTip = useTooltip(tooltipData);
  const discountPercentageToolTip = useTooltip(tooltipData);
  const discountOffsetToolTip = useTooltip(tooltipData);

  const sapProps = shouldUseSapPaymentConditions
    ? {
        readOnly: true,
        disabled: true,
      }
    : {};

  const form = useForm<PaymentConditionsFormValues>({
    defaultValues: defaultValues ?? fallbackDefaultValues,
    resolver: zodResolver({
      zodSchema: paymentConditionsFormSchema,
      errorMessages: paymentConditionsFormErrors,
      translationNamespace: 'settings',
    }),
    // Validation will trigger on the first blur event. After that, it will
    // trigger on every change event.
    mode: 'onTouched',
  });

  const onSubmit = form.handleSubmit(values => {
    onSubmitProp(
      // at this point we know that `values` satisfies the
      // `PaymentConditionsFormOutput` type
      values as PaymentConditionsFormOutput
    );
  });

  const dueDateOffsetField = (
    <Box
      ref={dueDateOffsetToolTip.triggerRef}
      {...(shouldUseSapPaymentConditions
        ? dueDateOffsetToolTip.triggerProps
        : {})}
    >
      <HookFormDayField
        autoFocus
        name="dueDateOffset"
        label={t('paymentConditions.form.fields.dueDateOffset.label')}
        placeholder={t(
          'paymentConditions.form.fields.dueDateOffset.placeholder'
        )}
        minValue={DATE_OFFSET.MIN}
        maxValue={DATE_OFFSET.MAX}
        readOnly={readOnly}
        {...sapProps}
      />
    </Box>
  );

  const discountPercentageField = (
    <Box
      ref={discountPercentageToolTip.triggerRef}
      {...(shouldUseSapPaymentConditions
        ? discountPercentageToolTip.triggerProps
        : {})}
    >
      <HookFormPercentField
        name="discountPercentage"
        label={t('paymentConditions.form.fields.discountPercentage.label')}
        placeholder={t(
          'paymentConditions.form.fields.discountPercentage.placeholder'
        )}
        minValue={DISCOUNT_PERCENTAGE.MIN}
        maxValue={DISCOUNT_PERCENTAGE.MAX}
        readOnly={readOnly}
        {...sapProps}
      />
    </Box>
  );

  const discountOffsetField = (
    <Box
      ref={discountOffsetToolTip.triggerRef}
      {...(shouldUseSapPaymentConditions
        ? discountOffsetToolTip.triggerProps
        : {})}
    >
      <HookFormDayField
        name="discountOffset"
        label={t('paymentConditions.form.fields.discountOffset.label')}
        placeholder={t(
          'paymentConditions.form.fields.discountOffset.placeholder'
        )}
        minValue={DATE_OFFSET.MIN}
        maxValue={DATE_OFFSET.MAX}
        readOnly={readOnly}
        {...sapProps}
      />
    </Box>
  );

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        onSubmit={!readOnly ? onSubmit : undefined}
        id={formId}
        gap="space16"
      >
        {action === 'edit' && (
          <Box
            ref={numberToolTip.triggerRef}
            {...(shouldUseSapPaymentConditions
              ? numberToolTip.triggerProps
              : {})}
          >
            <HookFormIntegerField
              name="conditionNumber"
              readOnly
              {...sapProps}
              label={t('paymentConditions.form.fields.conditionNumber.label')}
            />
          </Box>
        )}
        {layout === 'compact' ? (
          <Grid columns={3} gap="space16">
            {dueDateOffsetField}
            {discountPercentageField}
            {discountOffsetField}
          </Grid>
        ) : (
          <>
            {dueDateOffsetField}
            <Grid templateColumns="1fr 2fr" gap="space16">
              {discountPercentageField}
              {discountOffsetField}
            </Grid>
          </>
        )}
      </Grid>
      {numberToolTip.isOpen && (
        <Tooltip {...numberToolTip.tooltipProps} ref={numberToolTip.tooltipRef}>
          {t('paymentConditions.form.readOnlySyncHint')}
        </Tooltip>
      )}
      {dueDateOffsetToolTip.isOpen && (
        <Tooltip
          {...dueDateOffsetToolTip.tooltipProps}
          ref={dueDateOffsetToolTip.tooltipRef}
        >
          {t('paymentConditions.form.readOnlySyncHint')}
        </Tooltip>
      )}
      {discountPercentageToolTip.isOpen && (
        <Tooltip
          {...discountPercentageToolTip.tooltipProps}
          ref={discountPercentageToolTip.tooltipRef}
        >
          {t('paymentConditions.form.readOnlySyncHint')}
        </Tooltip>
      )}
      {discountOffsetToolTip.isOpen && (
        <Tooltip
          {...discountOffsetToolTip.tooltipProps}
          ref={discountOffsetToolTip.tooltipRef}
        >
          {t('paymentConditions.form.readOnlySyncHint')}
        </Tooltip>
      )}
    </FormProvider>
  );
};

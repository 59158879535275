import { HeaderGroup } from 'react-table';
import { TableRow } from '../../Atoms/TableElement/TableRow';
import { BaseTableDataType } from './Table';
import { Th } from './Th';

export interface TableHeaderProps<TableDataType extends BaseTableDataType> {
  headerGroups: HeaderGroup<TableDataType>[];
  isResizable: boolean;
  tableTranslations?: {
    sortAscendingLabel: string;
    sortDescendingLabel: string;
    resetSortLabel: string;
  };
  hasRowOverlay?: boolean;
}

export const TableHeader = <TableDataType extends BaseTableDataType>({
  headerGroups,
  tableTranslations,
  isResizable,
  hasRowOverlay,
}: TableHeaderProps<TableDataType>) => {
  return (
    <>
      {headerGroups.map(headerGroup => (
        <TableRow
          {...headerGroup.getHeaderGroupProps()}
          key={headerGroup.getHeaderGroupProps().key}
        >
          {headerGroup.headers.map((column, index) => {
            const isLastColumn = headerGroup.headers.length - 1 === index;

            return (
              <Th
                column={column}
                tableTranslations={tableTranslations}
                key={column.getHeaderProps().key}
                isLastColumn={isLastColumn}
                isResizable={isResizable}
              />
            );
          })}

          {
            // Show an empty table header at the end of the table that accounts for row overlay
            hasRowOverlay && (
              <th
                style={{
                  padding: 0,
                  width: 0,
                  height: 0,
                  maxWidth: 0,
                  maxHeight: 0,
                }}
              ></th>
            )
          }
        </TableRow>
      ))}
    </>
  );
};

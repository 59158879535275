import { Grid, ScrollBox } from '@candisio/design-system';
import clsx from 'clsx';
import {
  LeftSectionContentWrapper,
  LeftSectionOuterWrapper,
} from 'components/Layouts/styles';
import { ListNavigator } from 'components/ListNavigator/ListNavigator';
import { dataToTransactionDetails } from 'components/Transactions/TransactionDetailsCard/utils';
import {
  DocumentHistoryComponent,
  DocumentSummarySection,
} from 'containers/document-summary/DocumentSummarySection';
import { useUserRoles } from 'hooks/useUserRoles';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useTranslation } from 'react-i18next';
import {
  ExpandingDrawer,
  MAX_DRAWER_WIDTH,
} from 'views/DocumentDetails/ExpandingDrawer';
import { PurchaseOrderSectionContainer } from 'views/DocumentDetails/components/PurchaseOrderSection/PurchaseOrderSectionContainer';
import { TransactionSection } from 'views/DocumentDetails/components/TransactionSection';
import { useInboxContext } from '../Context';
import { AttachmentsSegment } from './components/AttachmentsSegment';
import { DocumentRelationsSegment } from './components/DocumentRelationsSegment';
import { DocumentSummaryInformationAdapter } from './components/DocumentSummaryInformation';

export interface LeftSectionProps {
  setSelectedRelationId?: (id: string) => void;
  selectedRelationId?: string | undefined;
}

export const LeftSection = ({
  selectedRelationId,
  setSelectedRelationId,
}: LeftSectionProps) => {
  const [t] = useTranslation();

  const { isOnlyApprover } = useUserRoles();

  const {
    globalDocumentId,
    documentId,
    mainDocumentFile,
    documentStatus,
    documentAmount,
    contactItem,
    contactName,
    transaction,
    accountsPayableNumber,
    showTransactionSection,
    useDocumentNavigationResult: {
      listNavigatorTitle,
      gotoNextDocument,
      gotoPrevDocument,
      handleBackToList,
      isNavigationLoading,
    },
    useAttachmentsResult: {
      attachments,
      attachPermissions,
      selectedPdf,
      setSelectedPdf,
    },
    isDocumentDataLoading,
    isDocumentFileLoading,
    canUseStoredContactInfo,
    showPurchaseOrderSection,
    canAddTransaction,
    view,
    setView,
  } = useInboxContext();

  const { showDocumentRelationsImproved } = useEcm();

  const attachmentsTitle = t('document.tabs.oldAttachments.title');
  const relationsTitle = t('document.tabs.relationships.title');

  const transactionSection = (
    <TransactionSection
      loading={
        transaction ? isDocumentDataLoading || isDocumentFileLoading : false
      }
      documentId={documentId}
      transaction={dataToTransactionDetails(transaction)}
      canAddTransaction={canAddTransaction}
      documentStatus={documentStatus}
    />
  );

  return (
    <>
      <LeftSectionOuterWrapper>
        <ListNavigator
          backToListText={t('document.backToDocumentList')}
          arrowLeftTooltip={t('document.previousDocument')}
          arrowRightTooltip={t('document.nextDocument')}
          listNavigatorCounter={listNavigatorTitle}
          onBackToList={handleBackToList}
          onNext={gotoNextDocument}
          onPrev={gotoPrevDocument}
          loading={isNavigationLoading}
        />
        <LeftSectionContentWrapper>
          {/*
        // cursed: Chrome browser renders scrollbars outside component's border
        // we add static ScrollBox to reserve same gutter space as for
        // scrollable DocumentSummarySection 
        */}
          <ScrollBox scrollDirection="none" scrollbarGutter="stable">
            <DocumentSummaryInformationAdapter
              transactionSection={transactionSection}
            />
          </ScrollBox>
          <ScrollBox
            scrollDirection="y"
            scrollbarGutter="stable"
            style={
              showDocumentRelationsImproved
                ? { display: 'flex', flexDirection: 'column' }
                : undefined
            }
          >
            <div
              className={clsx('gap-2', {
                grid: !showDocumentRelationsImproved,
                'flex flex-col min-h-full': showDocumentRelationsImproved,
              })}
            >
              {showPurchaseOrderSection && (
                <PurchaseOrderSectionContainer
                  loading={isDocumentDataLoading}
                  documentId={documentId}
                  documentStatus={documentStatus}
                  documentAmount={documentAmount}
                  contactName={
                    canUseStoredContactInfo
                      ? contactName
                      : contactItem?.children
                  }
                  accountsPayableNumber={
                    canUseStoredContactInfo
                      ? accountsPayableNumber
                      : contactItem?.accountsPayableNumber
                  }
                />
              )}
              {showTransactionSection && transactionSection}
              {!showDocumentRelationsImproved && (
                <DocumentSummarySection
                  globalDocumentId={globalDocumentId}
                  documentId={documentId}
                  documentName={mainDocumentFile.name}
                  documentStatus={documentStatus}
                  canAddTransaction={canAddTransaction}
                />
              )}
              <DocumentHistoryComponent documentId={documentId} />
            </div>
          </ScrollBox>
        </LeftSectionContentWrapper>
      </LeftSectionOuterWrapper>
      {showDocumentRelationsImproved && (
        <Grid
          key={documentId}
          maxWidth={MAX_DRAWER_WIDTH}
          height="100cqh"
          overflow="hidden"
        >
          <ExpandingDrawer drawer="ATTACHMENTS" title={attachmentsTitle}>
            <AttachmentsSegment
              documentId={documentId}
              documentFile={mainDocumentFile}
              attachments={attachments}
              attachPermissions={attachPermissions}
              onAttachmentClick={setSelectedPdf}
              selectedPdf={selectedPdf}
              view={view}
              setView={setView}
            />
          </ExpandingDrawer>
          <ExpandingDrawer drawer="RELATIONSHIPS" title={relationsTitle}>
            <DocumentRelationsSegment
              globalDocumentId={globalDocumentId}
              selectedRelationId={selectedRelationId}
              setSelectedRelationId={setSelectedRelationId}
              readOnly={isOnlyApprover}
            />
          </ExpandingDrawer>
        </Grid>
      )}
    </>
  );
};

import { Button, Text } from '@candisio/design-system';
import { ProvisionsPromotionExportInfoCard } from 'components/ProductPromotions/Provisions/ProvisionsPromotionExportInfoCard';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { ActionBar } from 'components/Sidebar/ActionBar';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { useDatev } from 'orgConfig/datev';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportContext } from 'views/Integrations/Export/Context';
import {
  csvExportTypesDatev,
  csvExportTypesNonDatev,
} from 'views/Integrations/Export/consts';
import { useIsExporting } from 'views/Integrations/Export/toolkit/hooks/useIsExporting';
import { useShowEntitlementPromo } from 'views/Integrations/Export/toolkit/hooks/useShowEntitlementPromo';
import { Table } from 'views/Integrations/Export/types';

export const DatevXmlExportButton = ({
  onExport,
  hasExportableEntity,
  onlyUnsupportedDatevDocumentsAvailable,
  loading,
}: {
  onExport: () => void;
  hasExportableEntity: boolean;
  onlyUnsupportedDatevDocumentsAvailable: boolean;
  loading?: boolean;
}) => {
  const [t] = useTranslation();
  const { exportType, table } = useContext(ExportContext);
  const { isExporting } = useIsExporting();
  const [isExportTriggered, setIsExportTriggered] = useState<boolean>(false);
  const { exportProvisionsFF } = useDatev(); // BDS-checked

  const showEntitlementPromo = useShowEntitlementPromo();

  const shouldShowUnsupportedDatevDocumentsError =
    exportType &&
    (csvExportTypesDatev.includes(
      exportType as (typeof csvExportTypesDatev)[number]
    ) ||
      csvExportTypesNonDatev.includes(
        exportType as (typeof csvExportTypesNonDatev)[number]
      )) &&
    onlyUnsupportedDatevDocumentsAvailable;

  const hasError =
    (!hasExportableEntity && isExportTriggered) ||
    shouldShowUnsupportedDatevDocumentsError;

  const provisionsOpened =
    table === Table.PROVISIONS || table === Table.REVERSALS;

  if (isExporting) {
    return (
      <ActionBar>
        <ProgressBar completion={0}>
          {t('export.exportForm.actionBar.exporting')}
        </ProgressBar>
      </ActionBar>
    );
  }

  if (hasError) {
    return (
      <ActionBar>
        <Text color="red500" fontSize="xsmall">
          {t('export.exportForm.actionBar.noExportableDocuments')}
        </Text>
        <Button disabled>{t('export.exportForm.actionBar.export')}</Button>
      </ActionBar>
    );
  }

  if (showEntitlementPromo) {
    return (
      <ActionBar>
        <UpsellPromo
          feature={FEATURE.PROVISIONS}
          utmSource={PROVISIONS_UTM_SOURCE.EXPORT_SIDEBAR}
        />
        <Button disabled>{t('export.exportForm.actionBar.export')}</Button>
      </ActionBar>
    );
  }

  return (
    <ActionBar>
      <ProvisionsPromotionExportInfoCard />
      <Button
        onClick={() => {
          setIsExportTriggered(true);
          if (hasExportableEntity) {
            void onExport();
          }
        }}
        disabled={!exportProvisionsFF && provisionsOpened}
        data-cy="submit"
        loading={loading}
      >
        {t('export.exportForm.actionBar.export')}
      </Button>
    </ActionBar>
  );
};

import {
  Box,
  Button,
  Flex,
  Grid,
  Popover,
  PopoverProps,
  Tag,
  Text,
} from '@candisio/design-system';
import {
  LottieAnimation,
  manageRelationshipsPreviewDE,
  manageRelationshipsPreviewEN,
} from 'components/Lottie/Lottie';
import { Locale } from 'generated-types/graphql.types';
import { motion } from 'motion/react';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { Trans } from 'providers/LocaleProvider';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

const MotionPopover = motion.create(Popover);

interface ManageRelationshipsActivationPopoverProps {
  popoverProps: PopoverProps;
  popoverRef: RefObject<HTMLDivElement>;
  onClose: () => void;
}

const animation = {
  [Locale.De]: manageRelationshipsPreviewDE,
  [Locale.En]: manageRelationshipsPreviewEN,
};

export const ManageRelationshipsActivationPopover = ({
  popoverProps,
  popoverRef,
  onClose,
}: ManageRelationshipsActivationPopoverProps) => {
  const [t] = useTranslation();

  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const animationData = animation[locale];

  return (
    <MotionPopover
      {...(popoverProps as any)}
      ref={popoverRef}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: -10, opacity: 0 }}
      transition={{ type: 'spring', duration: 2, bounce: 0.5 }}
      paddingY="space24"
      paddingX="space32"
      dismissWithOutsideClick={false}
      background="blue700"
      maxWidth="544px"
    >
      <Grid gap="space16">
        <Box background="white" width="max-content" borderRadius="4px">
          <Tag color="blue" variant="secondary">
            {t('tag.new')}
          </Tag>
        </Box>
        <Trans
          parent="span"
          i18nKey="manageRelationships.activationPopover.title"
        >
          <Text
            color="white"
            fontSize="xlarge"
            fontWeight="bold"
            lineHeight="paragraph"
          >
            Verknüpfungen erstellen leicht gemacht -{' '}
          </Text>
          <Text color="white" fontSize="xlarge" lineHeight="paragraph">
            Mit unserer Dokumentenvorschau
          </Text>
        </Trans>
        <LottieAnimation
          options={{ animationData, loop: true }}
          height="100%"
          width="100%"
        />
        <Flex
          direction="column"
          gap="space8"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            color="whiteBlue"
            size="small"
            variant="tertiary"
            onClick={onClose}
          >
            {t('manageRelationships.activationPopover.closePopoverButton')}
          </Button>
        </Flex>
      </Grid>
    </MotionPopover>
  );
};

import { Grid } from '@candisio/design-system';
import { FormProvider, useForm } from 'react-hook-form';
import { CodeInputField } from './CodeInputField';
import { NameInputField } from './NameInputField';
import { ProjectCodeFormValues } from './projectCodeSchema';

interface ProjectCodeFormProps {
  /** Initial values when the form is first rendered  */
  defaultValues?: ProjectCodeFormValues;
  /** Form is read-only */
  readOnly?: boolean;
}

export const ProjectCodeForm = ({
  defaultValues,
  readOnly = true,
}: ProjectCodeFormProps) => {
  // since form readonly, set values to defaultValues
  const form = useForm<ProjectCodeFormValues>({
    values: defaultValues,
  });

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        gap="space24"
        paddingY="space8"
        id={`projectCode-${defaultValues?.projectCode}`}
      >
        <Grid gap="space16">
          <CodeInputField readOnly={readOnly} />
          <NameInputField readOnly={readOnly} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

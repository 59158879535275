import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';
import { useEffect, useRef } from 'react';
import { useDrop } from 'react-aria';
import { onDropExit } from './shared';
import { setUploadDropZoneActivated } from './state';

type UseGlobalDropZone = {
  onDropEnter?: () => void;
};

export const useGlobalDropZone = ({ onDropEnter }: UseGlobalDropZone = {}) => {
  const ref = useRef(null);
  const documentUploadReworkFF = useCandisFeatureFlags(
    FEATURE_FLAGS.documentUploadRework
  );

  const { dropProps } = useDrop({
    isDisabled: !documentUploadReworkFF,
    onDrop: () => setUploadDropZoneActivated(false),
    onDropEnter: () => {
      setUploadDropZoneActivated(true);
      onDropEnter?.();
    },
    onDropExit,
    ref,
  });

  useEffect(() => () => setUploadDropZoneActivated(false), []);

  return { dropProps, ref };
};

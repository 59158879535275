import { SwimlaneTileAmount } from 'components/Swimlane/content/SwimlaneTileAmount';
import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import {
  DocumentCurrency,
  ReimbursementCasesSummaryFragmentFragment,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom-v5-compat';
import { useReimbursementPath } from '../hooks/useReimbursementPath';
import { ReimbursementSummaryTile } from './ReimbursementSummaryTile';
import { ReimbursementSummaryTileTagline } from './ReimbursementSummaryTileTagline';

export const ReimbursementSummaryDraft = ({
  caseSummary,
}: {
  caseSummary?: ReimbursementCasesSummaryFragmentFragment['draft'];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const path = useReimbursementPath('draft');
  const { documentMoneyFormatter } = useMoneyFormatter();

  if (!caseSummary) {
    return null;
  }

  const { totalAmount, withoutInvoiceCount = 0, totalCount = 0 } = caseSummary;

  return (
    <ReimbursementSummaryTile asChild data-cy="draftSummary">
      <NavLink to={path}>
        {withoutInvoiceCount > 0 && (
          <ReimbursementSummaryTileTagline>
            {t(
              withoutInvoiceCount > 1
                ? 'dashboard.swimlanes.overview.draftTile.tag.plural'
                : 'dashboard.swimlanes.overview.draftTile.tag.singular',
              {
                count: withoutInvoiceCount,
              }
            )}
          </ReimbursementSummaryTileTagline>
        )}
        <div>
          <SwimlaneTileDescription>
            {t(
              totalCount > 1
                ? 'dashboard.swimlanes.overview.draftTile.description.plural'
                : 'dashboard.swimlanes.overview.draftTile.description.singular',
              {
                count: totalCount,
              }
            )}
          </SwimlaneTileDescription>
          <SwimlaneTileAmount variant="gray">
            {documentMoneyFormatter({
              amount: totalAmount?.amount || 0,
              currency: totalAmount?.currency || DocumentCurrency.Eur,
              precision: totalAmount?.precision || 2,
            })}
          </SwimlaneTileAmount>
        </div>
      </NavLink>
    </ReimbursementSummaryTile>
  );
};

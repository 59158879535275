import { UNTAGGED_ID } from 'components/DocumentsTable/constants';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { DocumentFilterInput } from 'generated-types/graphql.types';
import { Filters } from 'react-table';

const filterValueToBoolean = (value: string[]): boolean | null => {
  if (value.length === 2) {
    return null;
  }

  if (value[0] === 'true') {
    return true;
  }

  if (value[0] === 'false') {
    return false;
  }

  return null;
};

const filterKeysMapping = {
  contact: 'contactIds',
  documentType: 'documentTypes',
  requester: 'requesterIds',
  approvers: 'approverIds',
  generalLedgerAccount: 'generalLedgerAccountIds',
  costCenter: 'costCenterIds',
  costObject: 'costObjectIds',
  extraCostInfo: 'extraCostInfoIds',
  artistSocialInsuranceCode: 'artistSocialInsuranceCodes',
  accountsPayableNumber: 'accountsPayableNumber',
  accountsReceivableNumber: 'accountsReceivableNumber',
};

function isKeyOfFilterKeysMapping(
  key: string
): key is keyof typeof filterKeysMapping {
  return key in filterKeysMapping;
}

export const mapToDocumentFilterInput = (
  filters: Filters<DocumentsTableData>
): DocumentFilterInput => {
  const reducedFilters = filters.reduce<DocumentFilterInput>(
    (docFilterInput, filter) => {
      if (isKeyOfFilterKeysMapping(filter.id)) {
        return {
          ...docFilterInput,
          [filterKeysMapping[filter.id]]: filter.value,
        };
      }

      switch (filter.id) {
        case 'isInvoiceCorrection': {
          const filterValue = filterValueToBoolean(filter.value);

          return { ...docFilterInput, isInvoiceCorrection: filterValue };
        }

        case 'status': {
          const allRegularStatuses = filter.value.filter(
            (stat: string) =>
              stat !== 'OVERDUE' &&
              stat !== 'DUPLICATE' &&
              stat !== 'WAITING_FOR_CLARIFICATION'
          );

          return {
            ...docFilterInput,
            status: allRegularStatuses,
            isOverdue: filter.value.includes('OVERDUE') ? true : undefined,
            isDuplicate: filter.value.includes('DUPLICATE') ? true : undefined,
            isWaitingForClarification: filter.value.includes(
              'WAITING_FOR_CLARIFICATION'
            )
              ? true
              : undefined,
          };
        }

        case 'isPayable':
        case 'paymentStatus':
        case 'creditCardPayment': {
          const filterValue = filterValueToBoolean(filter.value);
          const fieldMapping = {
            isPayable: 'isPayable',
            paymentStatus: 'isPaid',
            creditCardPayment: 'hasTransactionLinked',
          };

          return { ...docFilterInput, [fieldMapping[filter.id]]: filterValue };
        }

        case 'tags': {
          if (filter.value.includes(UNTAGGED_ID)) {
            return { ...docFilterInput, tags: [] };
          } else {
            return { ...docFilterInput, tags: filter.value };
          }
        }

        case 'accountingArea': {
          return { ...docFilterInput, accountingAreaIds: filter.value };
        }
      }

      return docFilterInput;
    },
    {}
  );

  return reducedFilters;
};

import {
  DirectDocumentAccess,
  DocumentAccessSubjectType,
} from 'generated-types/graphql.types';
import { ManageAccessFormValues } from '../../ManageAccess/manageAccessFormSchema';
import {
  AccessLevelRoleName,
  useDocumentAccessLevelsData,
} from './useDocumentAccessLevelsData';

interface UseInitialDirectAccessProps {
  globalDocumentId: string;
}

export const useInitialDirectAccess = ({
  globalDocumentId,
}: UseInitialDirectAccessProps) => {
  const {
    accessByEditorsData,
    accessByViewersData,
    membershipsWithIrrevocableAccess,
    loading,
  } = useDocumentAccessLevelsData({ globalDocumentId });

  const memberships: ManageAccessFormValues['memberships'] = [];
  for (const data of accessByEditorsData) {
    if (data.roleName === AccessLevelRoleName.Editors) {
      data.users.forEach(user => {
        memberships.push({
          id: user.id,
          name: user.name,
          avatarUrl: user.avatarUrl ?? undefined,
          directAccess: DirectDocumentAccess.Edit,
          subjectType: DocumentAccessSubjectType.Membership,
        });
      });
    }
  }

  for (const data of accessByViewersData) {
    if (data.roleName === AccessLevelRoleName.Viewers) {
      data.users.forEach(user => {
        memberships.push({
          id: user.id,
          name: user.name,
          avatarUrl: user.avatarUrl ?? undefined,
          directAccess: DirectDocumentAccess.View,
          subjectType: DocumentAccessSubjectType.Membership,
        });
      });
    }
  }

  return { loading, memberships, membershipsWithIrrevocableAccess };
};

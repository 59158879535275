import gql from 'graphql-tag';
import { projectCodeData } from 'views/queries/fragments';

export const projectCodeInfiniteScrollPagination = gql`
  query projectCodeInfiniteScrollPagination(
    $input: ProjectCodePaginationInput
    $filters: ProjectCodeFilterInput
    $queries: ProjectCodeQueryInput
    $sortBy: ProjectCodeSortInput
  ) {
    projectCodePagination(
      input: $input
      filters: $filters
      queries: $queries
      sortBy: $sortBy
    )
      @connection(
        key: "projectCodeInfiniteScrollPagination"
        filter: ["filters", "queries", "sortBy"]
      ) {
      records {
        ...ProjectCodeData
      }
      pageInfo {
        currentPage
        pageCount
        pageSize
        totalCount
      }
    }
  }

  ${projectCodeData}
`;

export const projectCodeQuery = gql`
    query projectCode($id: ID!) {
        projectCode(id: $id) {
        ...ProjectCodeData
        }
    }
    ${projectCodeData}
`;

export const countProjectCodes = gql`
  query countProjectCodes($filters: ProjectCodeFilterInput) {
    countProjectCodes(filters: $filters)
  }
`;

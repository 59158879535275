import { useOverflowGrid } from 'components/OverflowGrid/useOverflowGrid';
import { ReactNode } from 'react';
import { SwimlaneTileRow } from './SwimlaneTileRow';
import { MAX_COLUMNS, TILE_GAP, TILE_HEIGHT, TILE_WIDTH } from './constants';
import { useSwimlaneTileRules } from './useSwimlaneTileRules';

export type SwimlaneTilesProps = {
  actionTileCount?: 1 | 2;
  children:
    | ReactNode
    | ((values: ReturnType<typeof useSwimlaneTileRules>) => ReactNode);
  contentTileCount?: number;
  promoTileCount?: 1 | 2;
  maxColumns?: number;
};

export const SwimlaneTiles = (props: SwimlaneTilesProps) => {
  const {
    actionTileCount,
    children,
    contentTileCount = 0,
    promoTileCount,
    maxColumns = MAX_COLUMNS,
  } = props;
  const { colCount, ref } = useOverflowGrid<HTMLDivElement>(
    TILE_WIDTH,
    TILE_HEIGHT,
    TILE_GAP
  );
  const totalColumnCount = Math.min(colCount, maxColumns);
  const rules = useSwimlaneTileRules({
    actionTileCount,
    contentTileCount,
    contentTileLimit: totalColumnCount,
    promoTileCount,
  });

  return (
    <SwimlaneTileRow colCount={totalColumnCount} ref={ref}>
      {totalColumnCount > 0
        ? typeof children === 'function'
          ? children(rules)
          : children
        : null}
    </SwimlaneTileRow>
  );
};

import { forwardRef, ComponentProps, ReactNode } from 'react';
import clsx from 'clsx';
import { motion } from 'motion/react';

export const Layout = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    className?: string;
  } & ComponentProps<typeof motion.div>
>(({ children, className, ...restProps }, ref) => {
  return (
    <motion.div
      ref={ref}
      className={clsx(
        'h-full pl-16 pr-12.5 pb-6 grid grid-rows-[1fr_auto] bg-gray-200 rounded-medium ',
        className
      )}
      exit={{
        opacity: 0,
      }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      {...restProps}
    >
      {children}
    </motion.div>
  );
});

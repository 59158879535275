import { CardType } from 'generated-types/graphql.types';
import { noop } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import {
  CreateCreditCardModalState,
  useModalInitialState,
} from './utils/utils';

export const CreateCreditCardModalDataContext =
  createContext<CreateCreditCardModalState>({
    cardType: CardType.Virtual,
    formStep: 'CardTypeAndLimits',
  });

export const CreateCreditCardModalSetDataContext =
  createContext<Dispatch<SetStateAction<CreateCreditCardModalState>>>(noop);

export const CreateCreditCardModalDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initialState = useModalInitialState();

  const [modalData, setModalData] =
    useState<CreateCreditCardModalState>(initialState);

  return (
    <CreateCreditCardModalDataContext.Provider value={modalData}>
      <CreateCreditCardModalSetDataContext.Provider value={setModalData}>
        {children}
      </CreateCreditCardModalSetDataContext.Provider>
    </CreateCreditCardModalDataContext.Provider>
  );
};

export const useCreateCreditCardModalState = () => {
  const context = useContext(CreateCreditCardModalDataContext);
  if (context === undefined) {
    throw new Error(
      'useCreateCreditCardModalState must be used within a CreditCardsModalStateProvider '
    );
  }

  return context;
};

export const useCreateCreditCardModalSetState = () => {
  return useContext(CreateCreditCardModalSetDataContext);
};

import { ISSUE_NEW_CARD_HASH } from 'containers/credit-cards/constants';
import { Routes } from 'models';

export const jumpToCreatedCreditCard = ({
  cardId,
  organizationId,
}: {
  cardId: string;
  organizationId: string | null;
}) => {
  const creditCardLink = `/${organizationId}${Routes.DASHBOARD}/credit_card?cardId=${cardId}#${ISSUE_NEW_CARD_HASH}`;

  return { creditCardLink };
};

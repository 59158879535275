import { Locale } from 'generated-types/graphql.types';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { LOCALE_NAME_SPACE, resources } from './consts';

import { SanitisedTrans as Trans } from './Trans';

export const getFallbackLocale = () => {
  return Locale.En;
};

void i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    defaultNS: 'common',
    resources,
    fallbackLng: getFallbackLocale().toLowerCase(),
    returnEmptyString: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      bindI18n: 'languageChanged',
    },
    detection: {
      order: ['localStorage', 'cookie'],
      lookupLocalStorage: 'i18nextLng',
      lookupCookie: 'i18next',
      caches: ['localStorage', 'cookie'],
    },
  });

export { i18n, Trans, LOCALE_NAME_SPACE };

import { useQuery } from '@apollo/client';
import {
  ArchiveReimbursementCasesListItem,
  ArchiveReimbursementCasesQuery,
  GetReimbursementCasesSummaryQuery,
  ReimbursementCaseStatus,
  ReimbursementCasesSortOrder,
} from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useRef } from 'react';
import { getDateRangeForLastNDays } from 'utils/getDateRange';
import {
  aggregatedReimbursementCasesQuery,
  reimbursementCasesSummaryQuery,
} from '../toolkit/queries';

export const useGetReimbursementCasesSummary = () => {
  const { id: userId } = useCurrentUser() || {};

  const { data, loading, error } = useQuery<GetReimbursementCasesSummaryQuery>(
    reimbursementCasesSummaryQuery,
    { fetchPolicy: 'no-cache', variables: { targetMembershipIds: [userId] } }
  );
  return { data, loading, error };
};

export const DRAFT_STATUSES = [ReimbursementCaseStatus.Draft];

export const OPEN_STATUSES = [
  ReimbursementCaseStatus.Approving,
  ReimbursementCaseStatus.Check,
  ReimbursementCaseStatus.Review,
];

export const CLOSED_STATUSES = [
  ReimbursementCaseStatus.Archived,
  ReimbursementCaseStatus.Exported,
  ReimbursementCaseStatus.Rejected,
  ReimbursementCaseStatus.Approved,
  ReimbursementCaseStatus.Exporting,
  ReimbursementCaseStatus.PartiallyExported,
];

export const APPROVED_STATUSES = [
  ReimbursementCaseStatus.Approved,
  ReimbursementCaseStatus.Exporting,
  ReimbursementCaseStatus.PartiallyExported,
  ReimbursementCaseStatus.Exported,
];

export type ArchiveReimbursementCase = Pick<
  ArchiveReimbursementCasesListItem,
  | 'id'
  | 'title'
  | 'createdAt'
  | 'isPaid'
  | 'paidAt'
  | 'firstSubmittedForReviewAt'
  | 'status'
  | 'totalAmount'
  | 'contact'
> & {
  __typename?: 'ArchiveReimbursementCasesListItem';
};

const extractCaseNodes = (
  cases: ArchiveReimbursementCasesQuery['archiveReimbursementCases']['edges']
) => {
  return cases?.map(edge => edge.node);
};

/**
 * Fetches reimbursement cases with optional filters.
 */
export const useGetAggregatedReimbursementCases = () => {
  const { id: userId } = useCurrentUser() || {};
  const { fromDateISOString: fromDate, toDateISOString: toDate } = useRef(
    getDateRangeForLastNDays({ days: 30 })
  ).current;

  const variables = {
    targetMembershipIds: [userId],
    draftStatuses: DRAFT_STATUSES,
    openStatuses: OPEN_STATUSES,
    closedStatuses: CLOSED_STATUSES,
    lastClosedAt: { fromDate, toDate },
    sortOrder: ReimbursementCasesSortOrder.Desc,
  };

  const { data, loading, error } = useQuery(aggregatedReimbursementCasesQuery, {
    fetchPolicy: 'no-cache',
    variables,
  });

  return {
    draftReimbursementCases: extractCaseNodes(data?.draftCases.edges),
    openReimbursementCases: extractCaseNodes(data?.openCases.edges),
    closedReimbursementCases: extractCaseNodes(data?.closedCases.edges),
    totalCount: {
      draft: data?.draftCases.pageInfo.totalCount,
      open: data?.openCases.pageInfo.totalCount,
      closed: data?.closedCases.pageInfo.totalCount,
    },
    loading,
    error,
  };
};

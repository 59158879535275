import {
  Box,
  Flex,
  Grid,
  Icon,
  IconKey,
  Image,
  Text,
} from '@candisio/design-system';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ComponentProps, ReactElement, ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { VideoCard } from './VideoCard';

type PromoCardProps = {
  headerText: ReactNode;
  infoText: ReactNode;
  icon?: IconKey;
  additionalText?: string;
} & Pick<ComponentProps<typeof Grid>, 'gridArea'>;

export const PromotionCard = ({
  icon = 'check',
  headerText,
  infoText,
  additionalText,
  ...rest
}: PromoCardProps) => (
  <Grid
    {...rest}
    background="purplebg"
    borderRadius="medium"
    alignContent="start"
    gap="space16"
    padding="space12"
  >
    <Flex alignItems="center" gap="space8" height="fit-content">
      {icon === 'check' ? (
        <Icon icon={icon} color="purple700" size="space16" />
      ) : (
        <Flex
          alignItems="center"
          background="purple100"
          padding="space5"
          borderRadius="small"
        >
          <Icon icon={icon} color="purple700" size="space16" />
        </Flex>
      )}
      <Text fontWeight="semibold" fontSize="basic">
        {headerText}
      </Text>
    </Flex>
    <Box paddingRight="space32">
      <Text fontSize="basic" color="gray600">
        {infoText}
        {additionalText && (
          <>
            {' '}
            <Text fontStyle="italic" color="gray500">
              {additionalText}
            </Text>
          </>
        )}
      </Text>
    </Box>
  </Grid>
);

const translationsMap = {
  [FEATURE.CONTRACTS]: {
    title: 'promo.contractTable.modal.demo.title',
    videoLength: 'promo.contractTable.modal.demo.videoLength',
  },
  [FEATURE.ECM]: {
    title: 'promo.ecm.modal.demo.title',
    videoLength: 'promo.ecm.modal.demo.videoLength',
  },
} as const;

type SupportedFeature = keyof typeof translationsMap;

type TourPromotionCardProps = {
  children: ReactElement;
  feature: SupportedFeature;
};

export const TourPromotionCard = ({
  children,
  feature,
}: TourPromotionCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);
  const { title, videoLength } = translationsMap[feature];

  return (
    <Grid
      gridArea="video-tour"
      templateRows="auto 1fr"
      background="purplebg"
      borderRadius="medium"
      alignContent="start"
      gap="space12"
      padding="space16"
    >
      <Flex gap="1ch" alignItems="baseline">
        <Text fontSize="xlarge" fontWeight="semibold">
          {t(title)}
        </Text>
        <Text fontSize="xsmall">{t(videoLength)}</Text>
      </Flex>
      <VideoCard feature={feature} withFilter alternative>
        {children}
      </VideoCard>
    </Grid>
  );
};

export type FeatureBenefit = {
  icon?: IconKey;
  heading: string;
  description: string;
  isUpcoming?: boolean;
};

export const PromotionCards = (props: {
  feature: SupportedFeature;
  benefits: FeatureBenefit[];
  videoTour?: string;
}) => {
  if (props.videoTour)
    return (
      <PromotionWithTour
        feature={props.feature}
        benefits={props.benefits}
        videoTour={props.videoTour}
      />
    );

  return <PromotionNoTour benefits={props.benefits} />;
};

const PromotionWithTour = memo(
  (props: {
    feature: SupportedFeature;
    benefits: FeatureBenefit[];
    videoTour: string;
  }) => {
    const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

    return (
      <Grid
        templateColumns="repeat(auto-fill, minmax(30%, 1fr))"
        autoRows="minmax(max-content, 1fr)"
        gap="space12"
        templateAreas={`
        "benefit1 benefit2 video-tour"
        "benefit3 benefit4 video-tour"
      `}
      >
        {props.benefits.map(({ heading, description, isUpcoming }, i) => {
          return (
            <PromotionCard
              key={heading}
              gridArea={`benefit${i + 1}`}
              headerText={t(heading)}
              infoText={t(description)}
              additionalText={isUpcoming ? t('promo.upcoming') : ''}
            />
          );
        })}
        <TourPromotionCard feature={props.feature}>
          <Image
            src={props.videoTour}
            alt=""
            width="100%"
            height="100%"
            maxHeight="12rem"
          />
        </TourPromotionCard>
      </Grid>
    );
  }
);

const PromotionNoTour = memo((props: { benefits: FeatureBenefit[] }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  return (
    <Grid autoFlow="column" autoColumns="1fr" gap="space16">
      {props.benefits.map(({ icon, heading, description, isUpcoming }) => (
        <PromotionCard
          key={heading}
          icon={icon}
          headerText={t(heading)}
          infoText={t(description)}
          additionalText={isUpcoming ? t('promo.upcoming') : ''}
        />
      ))}
    </Grid>
  );
});

import {
  Box,
  ScrollBox,
  Switch,
  TableBody,
  TableElement,
  TableHead,
  TableRow,
  useTheme,
} from '@candisio/design-system';
import { AnimatePresence, motion } from 'motion/react';
import { NormalisedDestinations } from 'providers/AnalyticsProvider/utils/preferences';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider/index';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IntercomCookieBanner } from './IntercomCookieBanner';

interface DataSource {
  id: 'functional' | 'analytics' | 'essential' | 'intercom-cookie-banner';
  category: string | null;
  allow?: boolean | null;
  disabled?: boolean;
  toggle?: ReactNode | null;
  purpose?: string | ReactNode;
  tools?: string;
  showRow: boolean;
}

interface Column {
  title?: string;
  textAlign?: 'center';
  showIntercomToggle?: boolean;
}

interface PrivacyConsentTableProps {
  destinations: NormalisedDestinations | null;
  trackingEnabled: boolean;
  setTrackingEnabled: (shouldTrackingBeEnabled: boolean) => void;
  intercom: boolean;
  setIntercomEnabled: (shouldIntercomBeEnabled: boolean) => void;
}

const MotionBox = motion.create(Box);

export const PrivacyConsentTable = ({
  trackingEnabled,
  intercom,
  destinations,
  setIntercomEnabled,
  setTrackingEnabled,
}: PrivacyConsentTableProps) => {
  const [tConsentManager] = useTranslation(LOCALE_NAME_SPACE.CONSENT_MANAGER);

  const hasTools = destinations !== null;
  const { functionalDestinations = [], marketingDestinations = [] } =
    destinations ?? {};

  // Doesn't include an "Advertising" category.
  const dataSource: DataSource[] = [
    {
      id: 'functional',
      category: tConsentManager(
        'preferencesModal.table.category.functional.name'
      ),
      allow: intercom,
      disabled: false,
      purpose: tConsentManager(
        'preferencesModal.table.category.functional.purpose'
      ),
      tools: functionalDestinations.map(({ name }) => name).join(', '),
      showRow: true,
      toggle: (
        <Switch
          label={tConsentManager(
            'preferencesModal.table.category.functional.name'
          )}
          checked={Boolean(intercom)}
          disabled={false}
          onChange={() => {
            setIntercomEnabled(!intercom);
          }}
        />
      ),
    },
    {
      id: 'intercom-cookie-banner',
      showRow: !intercom,
      purpose: (
        <IntercomCookieBanner
          intercom={intercom}
          setIntercomEnabled={setIntercomEnabled}
        />
      ),
      category: null,
    },
    {
      id: 'analytics',
      toggle: (
        <Switch
          label={tConsentManager(
            'preferencesModal.table.category.analytics.name'
          )}
          checked={Boolean(trackingEnabled)}
          disabled={false}
          onChange={() => setTrackingEnabled(!trackingEnabled)}
        />
      ),
      category: tConsentManager(
        'preferencesModal.table.category.analytics.name'
      ),
      allow: trackingEnabled,
      disabled: false,
      purpose: tConsentManager(
        'preferencesModal.table.category.analytics.purpose'
      ),
      tools: marketingDestinations.map(({ name }) => name).join(', '),
      showRow: true,
    },
    {
      id: 'essential',
      toggle: false,
      category: tConsentManager(
        'preferencesModal.table.category.essential.name'
      ),
      allow: null,
      disabled: true,
      purpose: tConsentManager(
        'preferencesModal.table.category.essential.purpose'
      ),
      showRow: true,
    },
  ];

  const columns: Column[] = [
    {
      title: tConsentManager('preferencesModal.table.header.category'),
      textAlign: 'center',
    },
    {
      title: tConsentManager('preferencesModal.table.header.purpose'),
    },
    ...(hasTools
      ? [
          {
            title: tConsentManager('preferencesModal.table.header.tools'),
          },
        ]
      : []),
    {
      title: tConsentManager('preferencesModal.table.header.allow'),
      textAlign: 'center',
    },
  ];

  const { space } = useTheme();

  const motionAccordion = {
    hidden: { height: 0, opacity: 0, padding: '0' },
    show: {
      height: 'auto',
      opacity: 1,
      padding: space.space16,
      transition: { duration: 0.3, easeInOut: [0.42, 0, 0.58, 1] },
    },
  };

  const renderTableRow = (destination: DataSource) =>
    destination.id === 'intercom-cookie-banner' ? (
      <TableRow
        key={destination.id}
        style={{
          borderBottom: 'none',
          background: 'white',
        }}
      >
        <td colSpan={4} style={{ padding: '0' }}>
          <MotionBox
            variants={motionAccordion}
            initial="hidden"
            animate="show"
            exit="hidden"
          >
            {destination.purpose}
          </MotionBox>
        </td>
      </TableRow>
    ) : (
      <TableRow key={destination.id} style={{ background: 'white' }}>
        {destination.category && <th>{destination.category}</th>}
        <td>{destination.purpose}</td>
        {hasTools && <td>{destination.tools}</td>}
        <td
          style={{
            textAlign: 'center',
          }}
        >
          {destination.toggle
            ? destination.toggle
            : tConsentManager('preferencesModal.table.notApplicable')}
        </td>
      </TableRow>
    );

  return (
    <ScrollBox scrollDirection="y">
      <TableElement>
        <TableHead>
          <TableRow style={{ background: 'white' }}>
            {columns.map(header => (
              <th
                style={{
                  textAlign: header.textAlign ?? 'left',
                }}
                key={header.title}
              >
                {header.title}
              </th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <AnimatePresence initial={false}>
            {dataSource.map(
              destination => destination.showRow && renderTableRow(destination)
            )}
          </AnimatePresence>
        </TableBody>
      </TableElement>
    </ScrollBox>
  );
};

import {
  Card,
  Flex,
  Grid,
  Icon,
  Link,
  Skeleton,
  Text,
} from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  useImportDatevCoreDataMutation,
  useNew_DatevSettingsQuery,
  useOrganizationQuery,
} from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { refetchDatevData } from '../../gql';
import {
  ExportConfiguration,
  ExportConfigurationFields,
} from './components/ExportConfigurationFields';

export const AccountingCoreDataContainer = () => {
  const [t] = useTranslation();
  const { success } = useToastMessage();
  const { bdsConnected } = useDatev();
  const fullOrganization = useFullOrganization();

  const { data: organizationData, loading: loadingOrganizationData } =
    useOrganizationQuery({
      fetchPolicy: 'network-only',
    });

  const { data: settingsData, loading: loadingSettingsData } =
    useNew_DatevSettingsQuery({
      fetchPolicy: 'network-only',
    });

  const [importDatevCoreData] = useImportDatevCoreDataMutation();

  const [exportConfiguration, setExportConfiguration] =
    useState<ExportConfiguration>({
      chartOfAccountCode:
        settingsData?.new_datevSettings?.chartOfAccount?.code ?? null,
      accountLength:
        settingsData?.new_datevSettings?.chartOfAccount?.accountLength ?? null,
      fiscalYearStartDay:
        fullOrganization?.exportConfiguration?.fiscalYearStartDay ??
        organizationData?.organization?.exportConfiguration
          ?.fiscalYearStartDay ??
        null,
      fiscalYearStartMonth:
        fullOrganization?.exportConfiguration?.fiscalYearStartMonth ??
        organizationData?.organization?.exportConfiguration
          ?.fiscalYearStartMonth ??
        null,
    });

  useEffect(() => {
    setExportConfiguration({
      chartOfAccountCode:
        settingsData?.new_datevSettings?.chartOfAccount?.code ?? null,
      accountLength:
        settingsData?.new_datevSettings?.chartOfAccount?.accountLength ?? null,
      fiscalYearStartDay:
        fullOrganization?.exportConfiguration?.fiscalYearStartDay ??
        organizationData?.organization?.exportConfiguration
          ?.fiscalYearStartDay ??
        null,
      fiscalYearStartMonth:
        fullOrganization?.exportConfiguration?.fiscalYearStartMonth ??
        organizationData?.organization?.exportConfiguration
          ?.fiscalYearStartMonth ??
        null,
    });
  }, [fullOrganization, organizationData, settingsData]);

  const hasNoCoreAccountingData =
    !exportConfiguration.accountLength &&
    !exportConfiguration.chartOfAccountCode &&
    !exportConfiguration.fiscalYearStartDay &&
    !exportConfiguration.fiscalYearStartMonth;

  const hideConfigurationFields = !bdsConnected && hasNoCoreAccountingData;

  const [isReloadingCoreData, setIsReloadingCoreData] = useState(false);
  const reloadCoreData = useCallback(async () => {
    setIsReloadingCoreData(true);
    await importDatevCoreData({
      refetchQueries: [
        { query: getFullOrganizationQuery },
        ...refetchDatevData,
      ],
    });

    setTimeout(() => {
      setIsReloadingCoreData(false);
      success(
        t('settings.datev.connect.connectedClient.refreshCoreData.success')
      );
    }, 2000);
  }, [importDatevCoreData, success, t]);

  const loading = loadingOrganizationData || loadingSettingsData;

  if (loading) {
    return (
      <Card alignSelf="start" background="gray100" padding="space16">
        <Grid gap="space12">
          <Grid gap="space24" columns={4} width="100%">
            <Skeleton minWidth="space200" height="space40" width="100%" />
            <Skeleton height="space40" width="100%" />
            <Skeleton height="space40" width="100%" />
            <Skeleton height="space40" width="100%" />
          </Grid>
          <Skeleton height="space16" width="100%" />
          <Skeleton height="space16" width="100%" />
        </Grid>
      </Card>
    );
  }

  return (
    <Card alignSelf="start" background="gray100" padding="space16">
      <Text fontSize="basic">{t('exports:connectedClient.title')}</Text>
      {!hideConfigurationFields && (
        <Grid gap="space24" width="100%" alignItems="center">
          <ExportConfigurationFields
            settingsData={settingsData}
            organization={fullOrganization}
            exportConfiguration={exportConfiguration}
            setExportConfiguration={setExportConfiguration}
          />
        </Grid>
      )}

      <Grid autoFlow="column" justifyContent="space-between">
        <Link
          external
          href={t(
            'settings.datev.connect.connectedClient.updateCoreDataInDuo.url'
          )}
        >
          {t(
            'settings.datev.connect.connectedClient.updateCoreDataInDuo.textContent'
          )}
        </Link>
        <Flex
          gap="space4"
          alignItems="center"
          onClick={() => {
            if (!isReloadingCoreData) {
              void reloadCoreData();
            }
          }}
        >
          <Link as="button">
            {t(
              'settings.datev.connect.connectedClient.refreshCoreData.successDesc'
            )}
          </Link>
          <Icon
            icon="rotateLeft"
            color="blue700"
            style={{
              rotate: '-90deg',
            }}
          />
        </Flex>
      </Grid>
    </Card>
  );
};

import { IntegrationName } from 'generated-types/graphql.types';
import {
  Entitlements,
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';

interface UseReimbursementResult {
  canUseReimbursement: boolean;
  canUseEmployeeAPNSettings: boolean;
  showSearchOverlayPromo: boolean;
  canUsePerDiemReimbursement: boolean;
}

export const supportedEmployeeAPNIntegrations = [
  IntegrationName.Datev,
  IntegrationName.DatevAdjacent,
];

export const useReimbursement = (): UseReimbursementResult => {
  const entitlements = useEntitlementsFlag();
  const { ft, promo, show } = useFeatureToggle();
  const integration = useIntegrationSettings();
  const [
    travelExpenseManagementFF,
    enterpriseContentManagementFF,
    perDiemReimbursementFF,
  ] = useCandisFeatureFlags([
    FEATURE_FLAGS.travelExpenseManagement,
    FEATURE_FLAGS.enterpriseContentManagement,
    FEATURE_FLAGS.perDiemReimbursement,
  ]);

  const isEmployeeAPNSupported =
    supportedEmployeeAPNIntegrations.includes(integration);

  const canUseReimbursement =
    show(ft.REIMBURSEMENT) && travelExpenseManagementFF;

  const canUseEmployeeAPNSettings =
    canUseReimbursement && isEmployeeAPNSupported;

  const canUsePerDiem = show(ft.TRAVEL) && perDiemReimbursementFF;

  const canUsePerDiemReimbursement = canUseReimbursement && canUsePerDiem;

  if (entitlements === Entitlements.LEGACY) {
    return {
      canUseReimbursement: false,
      canUseEmployeeAPNSettings: false,
      canUsePerDiemReimbursement: false,
      showSearchOverlayPromo:
        !enterpriseContentManagementFF && promo(ft.SEARCH_OVERLAY),
    };
  }

  return {
    canUseReimbursement,
    canUseEmployeeAPNSettings,
    canUsePerDiemReimbursement,
    showSearchOverlayPromo: promo(ft.SEARCH_OVERLAY),
  };
};

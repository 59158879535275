import { Flex, Heading, Modal, Tag, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { BaselineSearchPromotionSlider } from './BaselineSearchPromotionSlider/BaselineSearchPromotionSlider';
import { PaginationDots } from './BaselineSearchPromotionSlider/PaginationDots';
import { useBaselineSearchPromotionSlides } from './BaselineSearchPromotionSlider/useBaselineSearchPromotionSliderContent';

interface BaselineSearchPromotionProps {
  isOpen: boolean;
  close: () => void;
}

export const BaselineSearchPromotion = ({
  isOpen,
  close,
}: BaselineSearchPromotionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);

  const {
    totalSlideImages,
    currentSlideIndex,
    setCurrentSlideIndex,
    lastSlideIndex,
    goToNextSlide,
  } = useBaselineSearchPromotionSlides();

  const handleOverlayCTAClick = () => {
    if (currentSlideIndex === lastSlideIndex) {
      close();
    }
    if (currentSlideIndex < lastSlideIndex) {
      goToNextSlide();
    }
  };

  const handlePaginationDotClick = (index: number) => {
    setCurrentSlideIndex(index);
  };

  const isLastSlide = currentSlideIndex === lastSlideIndex;

  return (
    <Modal
      width="953px"
      background="white"
      padding="0"
      titleInfo={
        <>
          <Tag variant="callout">{t('common:tag.new')}</Tag>
          <ModalHeader />
        </>
      }
      isOpen={isOpen}
      onClose={close}
      closeLabel={isLastSlide ? t('common:button.close') : undefined}
      isDismissable={isLastSlide ? true : false}
    >
      <Flex
        direction="column"
        background="gray100"
        padding="space32"
        gap="space30"
        alignItems="center"
        borderBottomRadius="basic"
      >
        <BaselineSearchPromotionSlider
          currentIndex={currentSlideIndex}
          handleCTAClick={handleOverlayCTAClick}
        />
        <PaginationDots
          length={totalSlideImages}
          currentIndex={currentSlideIndex}
          onClick={handlePaginationDotClick}
        />
      </Flex>
    </Modal>
  );
};

const ModalHeader = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);

  return (
    <Flex
      direction="column"
      background="white"
      paddingTop="space16"
      paddingBottom="space40"
    >
      <Heading as="h1">
        <Trans t={t} i18nKey="baselineSearch.title">
          <Text as="strong" fontWeight="bold"></Text>
        </Trans>
      </Heading>
      <Text as="p" color="gray600" fontSize="large">
        <Trans t={t} i18nKey="baselineSearch.subtitle">
          <Text as="strong" fontWeight="bold"></Text>
          <Text as="strong" fontWeight="bold"></Text>
          <br />
        </Trans>
      </Text>
    </Flex>
  );
};

import React, { ComponentProps, useMemo } from 'react';
import { mergeProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { Tooltip, useTooltip } from '../../../../Atoms/Tooltip';
import { isChromium } from '../../../../utils/browserHelpers';
import { useOverflow } from '../useOverflow';
import styles from './TruncatedText.module.css';
import clsx from 'clsx';

interface TruncatedTextProps extends Omit<ComponentProps<'span'>, 'color'> {
  charsAfterEllipsis?: number;
  children: string | string[];
  lineClamp?: number;
}

/**
 * Text that cuts off after a given number of lines. An ellipsis (…) is
 * displayed at the cut-off point. A tooltip displays the full text on hover.
 *
 * @param {number} [lineClamp = 1] Number of lines after which to cut off the text
 * @param {number} [charsAfterEllipsis = 0] Number of characters you want to display after truncation
 */
export const TruncatedText = React.forwardRef(
  (
    {
      charsAfterEllipsis = 0,
      children,
      lineClamp = 1,
      className,
      ...restProps
    }: TruncatedTextProps,
    forwardedRef: React.Ref<HTMLElement>
  ) => {
    const { overflowing, overflowRef } = useOverflow();

    const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
      useTooltip({ passiveTrigger: true });

    // only truncate in the middle if there are at least 3 more chars in the full string than should be displayed
    // after ellipsis and if browser is Chromium based (current implementation caused flickering in other browsers)
    if ((children.length ?? 0) - 2 <= charsAfterEllipsis || !isChromium) {
      charsAfterEllipsis = 0;
    }

    const textPreTruncation = useMemo(
      () => children.slice(0, children.length - charsAfterEllipsis),
      [children, charsAfterEllipsis]
    );

    const textPostTruncation = useMemo(
      () => (charsAfterEllipsis ? children.slice(-charsAfterEllipsis) : ''),
      [children, charsAfterEllipsis]
    );

    // Determine word-break class based on conditions
    const wordBreakValue =
      charsAfterEllipsis || lineClamp <= 1 ? 'break-all' : 'break-word';

    const style = useMemo(
      () => ({
        '--word-break': wordBreakValue,
        '--line-clamp': lineClamp,
        minWidth:
          textPostTruncation.length > 0
            ? `${textPostTruncation.length + 3}ch`
            : undefined,
      }),
      [lineClamp, textPostTruncation, wordBreakValue]
    );

    const content = useMemo(
      () =>
        overflowing ? (
          <>
            {/* Create spacer elements for multi-line truncation */}
            {[...Array(lineClamp - 1)].map((_, index) => (
              <span key={index} className="float-right clear-both">
                &nbsp;
              </span>
            ))}
            <span className="whitespace-nowrap float-right clear-both">
              {textPostTruncation}
            </span>
            <span>{textPreTruncation}</span>
          </>
        ) : (
          <span>{children}</span>
        ),
      [children, overflowing, lineClamp, textPostTruncation, textPreTruncation]
    );

    return (
      <>
        <span
          {...(overflowing
            ? (mergeProps(restProps, triggerProps) as Record<string, unknown>)
            : restProps)}
          className={clsx(
            styles['truncated-text'],
            'line-clamp-[var(--line-clamp)]',
            className
          )}
          style={style}
          ref={mergeRefs([forwardedRef, triggerRef, overflowRef])}
        >
          {content}
        </span>
        {overflowing && isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {children}
          </Tooltip>
        )}
      </>
    );
  }
);

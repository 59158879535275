import { Grid, Skeleton } from '@candisio/design-system';
import { ProvisionsPromotionCoreDataContactTable } from 'components/ProductPromotions/Provisions/ProvisionsPromotionCoreDataTable';
import { dummyContactData } from 'components/ProductPromotions/Provisions/tableData';
import { useDatev } from 'orgConfig/datev';
import { useEffect } from 'react';
import { faker } from 'utils/faker';
import { useExportContext } from 'views/Integrations/Export/Context';
import { useExportContactsFactory } from 'views/Integrations/Export/toolkit/hooks/useExportContactsFactory';
import { ExportContactTable } from './ContactTable';

const WARNING_TRESHOLD = 100;

export const ContactsContainer = () => {
  const { view, includeAllContacts } = useExportContext();
  const {
    isMasked,
    isAllShown,
    contacts: { tableData, refetch, isLoading },
    count: { all, newAndUpdated },
  } = useExportContactsFactory();
  const { bdsBought } = useDatev();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void refetch();
  }, [refetch, view]);

  const hasWarning = includeAllContacts
    ? all > WARNING_TRESHOLD
    : newAndUpdated > WARNING_TRESHOLD;

  if (isLoading) {
    return (
      <Grid gap="space10">
        {Array.from({ length: 15 }, () => (
          <Skeleton width="100%" height="space40" key={faker.string()} />
        ))}
      </Grid>
    );
  }

  if (!bdsBought) {
    return (
      <ProvisionsPromotionCoreDataContactTable
        data={tableData.length > 0 ? tableData : dummyContactData}
      />
    );
  }

  return (
    <ExportContactTable
      data={tableData}
      hasWarning={hasWarning}
      isMasked={isMasked}
      showAll={isAllShown}
    />
  );
};

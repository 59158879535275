export const DOCUMENT_PROCESSING_SEARCH_PARAMS = {
  CURSOR: 'cursor',
};

export const NONE_PAYMENT_CONDITION_ID = 'none';

export const DOCUMENT_TYPES = {
  RECHNUNGSEINGANG: 'Rechnungseingang',
  RECHNUNGSAUSGANG: 'Rechnungsausgang',
  KASSE: 'Kasse',
  SONSTIGE: 'Sonstige',
  RECHNUNGSKORREKTUR: 'Rechnungskorrektur',
  EINGANGSGUTSCHRIFT: 'Eingangsgutschrift',
} as const;

const {
  RECHNUNGSAUSGANG,
  RECHNUNGSEINGANG,
  RECHNUNGSKORREKTUR,
  EINGANGSGUTSCHRIFT,
} = DOCUMENT_TYPES;

export const SAP_DOCUMENT_TYPES = [RECHNUNGSEINGANG, EINGANGSGUTSCHRIFT];

export const OTHER_INTEGRATION_DOCUMENT_TYPES_DEPRECATED = [
  RECHNUNGSEINGANG,
  RECHNUNGSKORREKTUR,
];

export const OTHER_INTEGRATION_DOCUMENT_TYPES = [
  RECHNUNGSAUSGANG,
  RECHNUNGSEINGANG,
  RECHNUNGSKORREKTUR,
];

import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { useGetDocumentForDraftQuery } from 'generated-types/graphql.types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useIsSapGoodsInvoice,
  useShouldShowField,
} from 'views/utils/useShouldShowField';

import { BookingsFormContainerProps, BookingsFormProps } from './types';
import { useInitialValues } from './hooks/useInitialValues';
import { useGetBookingsSubmitActions } from './hooks/useGetBookingsSubmit';
import { BookingsForm } from './BookingsForm';
import { BookingsFormContainerLayout } from './layout/BookingsFormContainerLayout';

// WIP !!!
// First will be released only for SAP
// Other inputs for other organizations will be added later

export const BookingsFormContainer = ({
  documentId,
  readOnly,
  taxCodeFieldItems,
  typeFieldItems,
  onAcceptBookings,
}: BookingsFormContainerProps) => {
  const [t] = useTranslation('split-bookings');

  const { data: documentData } = useGetDocumentForDraftQuery({
    variables: { id: documentId },
  });

  const isGoodsPurchaseOrderLinked = useIsSapGoodsInvoice(documentId);

  const document = documentData?.getDocument ?? undefined;
  const { showAccountingDataField } = useShouldShowField(document ?? {});

  const processingForm = useFormContext<ProcessingFormValues>();
  const processingFormValues = processingForm?.getValues();

  const typeFieldItem = typeFieldItems?.find(
    item => item.key === processingFormValues.type
  );

  const { documentLevelValues, bookings } = useInitialValues({
    taxCodeFieldItems,
  });

  const mode = processingFormValues?.mode;

  const initialValues = { ...documentLevelValues, bookings };

  const hasTransactions = document?.hasTransactionLinked ?? false;

  const { handleAcceptBookings } = useGetBookingsSubmitActions({
    initialBookingsLength: initialValues.bookings?.length ?? 0,
    initialCurrency: initialValues.currency ?? '',
    initialGrossAmount: initialValues.grossAmount ?? 0,
    isGoodsPurchaseOrderLinked,
    hasTransactions,
    onAcceptBookings, // close drawer
  });
  const splitBookingsFormProps: BookingsFormProps = {
    initialValues,
    documentDirection: typeFieldItem?.direction,
    mode: mode || 'requestApproval',
    readOnly: readOnly,
    onAcceptBookings: handleAcceptBookings,
    isGoodsPurchaseOrderLinked,
  };

  return (
    <BookingsFormContainerLayout>
      <BookingsForm {...splitBookingsFormProps} />
    </BookingsFormContainerLayout>
  );
};

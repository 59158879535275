import { IntegrationName } from 'generated-types/graphql.types';
import { z } from 'zod';
import { BookingsFormSchemaContext } from '../types';
import { formSchema as _bookingSchema } from '../../SplitBookingsForm/schema';
import { validateTaxCode } from 'utils/validateTaxCode';
import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';

const requiredTranslationKey = 'errors.fieldRequired';

export const rowSchema = _bookingSchema.extend({
  bookingId: z.string().nullish(),
});

const taxCodeSchema = ({
  mode,
  integration,
  shouldRequireTaxCode,
  availableTaxCodes,
  hasReversedTaxCodes,
  documentDirection,
}: BookingsFormSchemaContext) => {
  return rowSchema.pick({ taxCode: true }).superRefine(({ taxCode }, ctx) => {
    if (mode === 'approve' && shouldRequireTaxCode && !taxCode) {
      ctx.addIssue({
        code: 'custom',
        path: ['taxCode'],
        params: {
          translationKey: requiredTranslationKey,
        },
      });
    }

    // Check for reversed tax codes
    const currentTaxCode = availableTaxCodes?.find(
      bookingKey => taxCode === bookingKey.id
    );

    if (
      currentTaxCode &&
      hasReversedTaxCodes &&
      !currentTaxCode?.isAcquisitionReverse
    ) {
      ctx.addIssue({
        code: 'custom',
        path: ['taxCode'],
        params: {
          translationKey: 'inputs.bookingKey.notAcquisitionReverseError',
        },
      });
    }

    // Skip validations for certain integrations
    const simpleValidation = [
      IntegrationName.DatevAdjacent,
      IntegrationName.Other,
      IntegrationName.Sap,
    ];

    if (integration && simpleValidation.includes(integration)) {
      return;
    }

    // Skip validations if no documentDirection or taxCode is missing
    if (!documentDirection || !currentTaxCode) {
      return;
    }

    // Validate tax code with document direction
    const isCombinationValid = validateTaxCode(
      currentTaxCode.taxCode,
      documentDirection
    );

    if (!isCombinationValid) {
      ctx.addIssue({
        code: 'custom',
        path: ['taxCode'],
        params: {
          translationKey: 'errors.bookingKeyId',
        },
      });
    }
  });
};
const vatRateSchema = ({ integration }: BookingsFormSchemaContext) =>
  rowSchema
    .pick({ taxPresentation: true, vatRate: true })
    .superRefine(({ taxPresentation, vatRate }, ctx) => {
      if (
        taxPresentation === TaxPresentation.Net &&
        vatRate == null &&
        integration !== IntegrationName.Sap
      ) {
        ctx.addIssue({
          code: 'custom',
          path: ['vatRate'],
          params: {
            translationKey: requiredTranslationKey,
          },
        });
      }
    });

const dueDateSchema = ({ invoiceDate }: BookingsFormSchemaContext) => {
  return rowSchema.pick({ dueDate: true }).superRefine(({ dueDate }, ctx) => {
    if (dueDate && invoiceDate && new Date(dueDate) < new Date(invoiceDate)) {
      ctx.addIssue({
        code: 'custom',
        path: ['dueDate'],
        params: {
          translationKey: 'errors.dueDate',
        },
      });
    }
  });
};

const generalLedgerAccountSchema = ({
  shouldRequireGeneralLedgerAccount,
  mode,
  integration,
}: BookingsFormSchemaContext) => {
  return rowSchema
    .pick({ generalLedgerAccount: true, sapExpenseType: true })
    .superRefine(({ generalLedgerAccount, sapExpenseType }, ctx) => {
      if (mode === 'approve') {
        if (integration === IntegrationName.Sap && sapExpenseType) {
          return;
        }

        if (shouldRequireGeneralLedgerAccount && !generalLedgerAccount?.value) {
          ctx.addIssue({
            code: 'custom',
            path: ['generalLedgerAccount', 'value'],
            params: {
              translationKey: requiredTranslationKey,
            },
          });
        }
      }
    });
};

const costCenterSchema = ({
  integration,
  mode,
  hasCostCenters,
}: BookingsFormSchemaContext) => {
  return rowSchema
    .pick({ costCenter: true })
    .superRefine(({ costCenter }, ctx) => {
      if (
        integration !== IntegrationName.Sap &&
        mode === 'approve' &&
        hasCostCenters &&
        !costCenter?.value
      ) {
        ctx.addIssue({
          code: 'custom',
          path: ['costCenter', 'value'],
          params: {
            translationKey: requiredTranslationKey,
          },
        });
      }
    });
};

const costObjectSchema = ({
  integration,
  mode,
  hasCostObjects,
  hasCostCenters,
}: BookingsFormSchemaContext) => {
  return rowSchema
    .pick({ costObject: true })
    .superRefine(({ costObject }, ctx) => {
      if (
        integration !== IntegrationName.Sap &&
        mode === 'approve' &&
        hasCostObjects &&
        !hasCostCenters &&
        !costObject?.value
      ) {
        ctx.addIssue({
          code: 'custom',
          path: ['costObject', 'value'],
          params: {
            translationKey: requiredTranslationKey,
          },
        });
      }
    });
};

const quantitySchema = ({
  shouldRequireQuantity,
}: BookingsFormSchemaContext) => {
  return rowSchema
    .pick({ quantity: true, sapExpenseType: true })
    .superRefine(({ quantity, sapExpenseType }, ctx) => {
      if (sapExpenseType || !shouldRequireQuantity) {
        return;
      }

      if (quantity == null) {
        ctx.addIssue({
          code: 'custom',
          path: ['quantity'],
          params: {
            translationKey: requiredTranslationKey,
          },
        });
      } else if (quantity === 0) {
        ctx.addIssue({
          code: 'custom',
          path: ['quantity'],
          params: {
            translationKey: 'inputs.quantity.nonZeroError',
          },
        });
      }
    });
};

const netAmountSchema = ({
  shouldUseSapNetAmount,
  isFormReadOnly,
}: BookingsFormSchemaContext) => {
  return rowSchema
    .pick({ netAmount: true })
    .superRefine(({ netAmount }, ctx) => {
      if (!shouldUseSapNetAmount || isFormReadOnly) {
        return;
      }

      if (!netAmount) {
        ctx.addIssue({
          code: 'custom',
          path: ['netAmount'],
          params: {
            translationKey: 'inputs.netAmount.nonZeroError',
          },
        });
      }
    });
};

const unitPriceSchema = ({
  shouldUseSapNetAmount,
  shouldRequireQuantity,
  isFormReadOnly,
}: BookingsFormSchemaContext) => {
  return rowSchema
    .pick({ unitPrice: true, sapExpenseType: true })
    .superRefine(({ unitPrice, sapExpenseType }, ctx) => {
      if (
        !shouldUseSapNetAmount ||
        isFormReadOnly ||
        !shouldRequireQuantity ||
        sapExpenseType
      ) {
        return;
      }

      if (!unitPrice) {
        ctx.addIssue({
          code: 'custom',
          path: ['unitPrice'],
          params: {
            translationKey: 'inputs.unitPrice.nonZeroError',
          },
        });
      }
    });
};

export const createRowSchema = (options: BookingsFormSchemaContext) => {
  return rowSchema
    .and(taxCodeSchema(options))
    .and(vatRateSchema(options))
    .and(dueDateSchema(options))
    .and(generalLedgerAccountSchema(options))
    .and(costCenterSchema(options))
    .and(costObjectSchema(options))
    .and(quantitySchema(options))
    .and(netAmountSchema(options))
    .and(unitPriceSchema(options));
};

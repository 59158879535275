import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import {
  defaultUIConfig,
  UIConfigProps,
} from '../hooks/useReimbursementUIConfig';
import {
  ApprovalFieldData,
  ApprovalMode,
  FormRegistration,
  initialApprovalData,
  ReimbursementFormsContext,
} from './ReimbursementFormsContext';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';

export const APPROVING_EDIT_MODE = {
  PARAM: 'approvingEditMode',
  VALUE: 'true',
} as const;

interface ReimbursementFormsProviderProps {
  children: ReactNode;
  initialUIConfig?: UIConfigProps;
}

export const ReimbursementFormsProvider = ({
  children,
  initialUIConfig,
}: ReimbursementFormsProviderProps) => {
  const { searchParams } = useMutateSearchParams();
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<{ [key: string]: HTMLElement | null }>({});
  const formsRef = useRef<Record<string, FormRegistration>>({});
  const [approvalMode, setApprovalMode] =
    useState<ApprovalMode>('requestApproval');

  const [isReimbursementUpdatePending, setIsReimbursementUpdatePending] =
    useState<boolean>(false);
  const [lastModifiedReimbursementItem, setLastModifiedReimbursementItem] =
    useState<null | number>(null);

  const [visibleItemId, setVisibleItemId] = useState<string | null>(null);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [hasReimbursementErrors, setHasReimbursementErrors] =
    useState<boolean>(false);
  const [hasApprovalFieldError, setHasApprovalFieldError] =
    useState<boolean>(false);

  const isApprovingEditMode =
    searchParams.get(APPROVING_EDIT_MODE.PARAM) === APPROVING_EDIT_MODE.VALUE;

  const [approvalData, setApprovalData] =
    useState<ApprovalFieldData>(initialApprovalData);

  const uiConfig = initialUIConfig || defaultUIConfig;

  const [reimbursementUIConfig, setReimbursementUIConfig] =
    useState<UIConfigProps>({
      ...uiConfig,
      isReimbursementFormEditable: isApprovingEditMode,
    });

  const registerForm = (formKey: string, registration: FormRegistration) => {
    formsRef.current = {
      ...formsRef.current,
      [formKey]: registration,
    };
  };

  const updateLastModifiedReimbursementItem = useCallback(
    (reimbursementItemFormIndex: number) => {
      setLastModifiedReimbursementItem(reimbursementItemFormIndex);
    },
    []
  );

  const triggerAllValidations = useCallback(() => {
    Object.values(formsRef.current).forEach(form => form.trigger?.());
  }, []);

  const handleSetSelectedItemId = useCallback((id: string | null) => {
    setSelectedItemId(id);
  }, []);

  const handleIsReimbursementUpdatePending = useCallback((val: boolean) => {
    setIsReimbursementUpdatePending(val);
  }, []);

  const updateHasReimbursementErrors = useCallback((val: boolean) => {
    setHasReimbursementErrors(val);
  }, []);

  const updateApprovalMode = useCallback((approval: ApprovalMode) => {
    setApprovalMode(approval);
  }, []);

  const updateApprovalData = useCallback((values: ApprovalFieldData) => {
    setApprovalData(values);
  }, []);

  const updateApprovalFieldErrorStatus = useCallback((val: boolean) => {
    setHasApprovalFieldError(val);
  }, []);

  useEffect(() => {
    setReimbursementUIConfig(uiConfig);
  }, [uiConfig]);

  return (
    <ReimbursementFormsContext.Provider
      value={{
        lastModifiedReimbursementItem,
        updateLastModifiedReimbursementItem,
        registerForm,
        triggerAllValidations,
        reimbursementUIConfig,
        setReimbursementUIConfig,
        visibleItemId,
        setVisibleItemId,
        itemsContainerRef,
        itemRefs,
        selectedItemId,
        setSelectedItemId: handleSetSelectedItemId,
        isReimbursementUpdatePending,
        handleIsReimbursementUpdatePending,
        hasReimbursementErrors,
        setHasReimbursementErrors: updateHasReimbursementErrors,
        approvalMode,
        setApprovalMode: updateApprovalMode,
        approvalData,
        setApprovalData: updateApprovalData,
        isApprovingEditMode,
        hasApprovalFieldError,
        setHasApprovalFieldError: updateApprovalFieldErrorStatus,
      }}
    >
      {children}
    </ReimbursementFormsContext.Provider>
  );
};

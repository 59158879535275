import { Spinner } from '@candisio/design-system';
import { CheckmarkIcon, ErrorIcon } from 'components/Icons/DefaultIcons';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const StateKey = {
  checkingService: 'checkingService',
  isClientConnectedSuccess: 'isClientConnectedSuccess',
  isClientConnectedError: 'isClientConnectedError',
  isGeneralLedgerAccountConnectedSuccess:
    'isGeneralLedgerAccountConnectedSuccess',
  isGeneralLedgerAccountConnectedError: 'isGeneralLedgerAccountConnectedError',
  isMoneyTransitSetSuccess: 'isMoneyTransitSetSuccess',
  isMoneyTransitSetError: 'isMoneyTransitSetError',
  isCreditCardSetSuccess: 'isCreditCardSetSuccess',
  isCreditCardSetError: 'isCreditCardSetError',
  isCreditCardIdSetSuccess: 'isCreditCardIdSetSuccess',
  isCreditCardIdSetError: 'isCreditCardIdSetError',
  serviceConnected: 'serviceConnected',
  //! unused enrty
  networkFailure: 'networkFailure',
} as const;

export type StateKey = keyof typeof StateKey;

const iLoading = <Spinner />;
const iSuccess = <CheckmarkIcon flex="none" size="space16" />;
const iError = <ErrorIcon flex="none" size="space16" />;

export const useCheckItem = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const keysToDataMap = {
    [StateKey.checkingService]: {
      icon: iLoading,
      text: t('creditCardExportingSap.checks.checkingService'),
    },
    [StateKey.isClientConnectedSuccess]: {
      icon: iSuccess,
      text: t('creditCardExportingSap.checks.isClientConnected.success'),
    },
    [StateKey.isClientConnectedError]: {
      icon: iError,
      text: t('creditCardExportingSap.checks.isClientConnected.error'),
    },
    [StateKey.isGeneralLedgerAccountConnectedSuccess]: {
      icon: iSuccess,
      text: t(
        'creditCardExportingSap.checks.isGeneralLedgerAccountConnected.success'
      ),
    },
    [StateKey.isGeneralLedgerAccountConnectedError]: {
      icon: iError,
      text: t(
        'creditCardExportingSap.checks.isGeneralLedgerAccountConnected.error'
      ),
    },
    [StateKey.isMoneyTransitSetSuccess]: {
      icon: iSuccess,
      text: t('creditCardExportingSap.checks.isMoneyTransitSet.success'),
    },
    [StateKey.isMoneyTransitSetError]: {
      icon: iError,
      text: t('creditCardExportingSap.checks.isMoneyTransitSet.error'),
    },
    [StateKey.isCreditCardSetSuccess]: {
      icon: iSuccess,
      text: t('creditCardExportingSap.checks.isCreditCardSet.success'),
    },
    [StateKey.isCreditCardSetError]: {
      icon: iError,
      text: t('creditCardExportingSap.checks.isCreditCardSet.error'),
    },
    [StateKey.isCreditCardIdSetSuccess]: {
      icon: iSuccess,
      text: t('creditCardExportingSap.checks.isCreditCardIdSet.success'),
    },
    [StateKey.isCreditCardIdSetError]: {
      icon: iError,
      text: t('creditCardExportingSap.checks.isCreditCardIdSet.error'),
    },
    [StateKey.serviceConnected]: {
      icon: iSuccess,
      text: t('creditCardExportingSap.checks.serviceConnected'),
    },
    //! unused enrty
    [StateKey.networkFailure]: {
      icon: iError,
      text: t('settings.datev.connect.exportTypes.dxsoJobs.networkFailure'),
    },
  } as const;

  const getCheckItemProps = (stateKey: keyof typeof keysToDataMap) => {
    return keysToDataMap[stateKey];
  };

  return getCheckItemProps;
};

import { Button } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ConnectionStatus,
  useReconnectAndVerifySapB1ConnectionMutation,
} from 'generated-types/graphql.types';
import i18next from 'i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { refetchSapSettings } from '../../queries';

interface ReconnectButtonProps {
  isDisabled?: boolean;
}

export const ReconnectButton = ({ isDisabled }: ReconnectButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);
  const { success, error } = useToastMessage();
  const [reconnectAndVerifySapB1Connection, { loading: isReconnecting }] =
    useReconnectAndVerifySapB1ConnectionMutation({
      refetchQueries: refetchSapSettings,
    });

  const onReconnect = async () => {
    const { data } = await reconnectAndVerifySapB1Connection();

    const connectionStatus =
      data?.reconnectAndVerifySapB1Connection?.connectionDetails
        ?.connectionStatus;

    if (connectionStatus === ConnectionStatus.ConnectedSuccessfully) {
      success(t('settings.summary.reconnect.success'));
    } else {
      const connectionError =
        data?.reconnectAndVerifySapB1Connection?.connectionDetails
          ?.connectionError;

      const currentLanguage = i18next.language === 'de' ? 'de' : 'en';
      const errorMessage =
        connectionError?.errorMessage[currentLanguage] ||
        t('settings.summary.reconnect.errors.unexpected');

      error(errorMessage);
    }
  };

  return (
    <Button
      variant="secondary"
      size="small"
      width="fit-content"
      loading={isReconnecting}
      disabled={isDisabled || isReconnecting}
      onClick={onReconnect}
    >
      {t('settings.summary.reconnectCTA.text')}
    </Button>
  );
};

import { CardLimitRenewFrequency } from 'generated-types/graphql.types';

export const textDescriptionLimitInterval = {
  [CardLimitRenewFrequency.Annual]:
    'dashboard.drawer.form.limitInterval.annual.text',
  [CardLimitRenewFrequency.Monthly]:
    'dashboard.drawer.form.limitInterval.monthly.text',
  [CardLimitRenewFrequency.Quarterly]:
    'dashboard.drawer.form.limitInterval.quarterly.text',
  [CardLimitRenewFrequency.Total]:
    'dashboard.drawer.form.limitInterval.total.text',
};

export const CARD_ERROR_TRANSLATIONS: Record<string, string> = {
  CANNOT_ISSUE_CARD_FOR_INACTIVE_CARDHOLDER:
    'dashboard.drawer.form.failureUserInactive',
  MFA: 'dashboard.drawer.form.failureMfa',
  PHYSICAL_CARD_LIMIT_REACHED_BY_CARDHOLDER:
    'dashboard.drawer.form.phyCardLimitReachedErr',
} as const;

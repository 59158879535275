import {
  Button,
  Card,
  Grid,
  Popover,
  Text,
  usePopover,
} from '@candisio/design-system';
import { StatusType } from 'components/DocumentsTable/types';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface AssociateCardProps {
  selectedDocumentStatus: StatusType | undefined;
  isAssociating: boolean;
  onAssociate?: () => void;
}

const popoverTranslations: Record<
  Extract<StatusType, 'approved' | 'open'>,
  { title: string; text: string }
> = {
  approved: {
    title:
      'transactionAssociation.documentsModal.confirmationPopover.documentApproved.title',
    text: 'transactionAssociation.documentsModal.confirmationPopover.documentApproved.text',
  },
  open: {
    text: 'transactionAssociation.documentsModal.confirmationPopover.documentApproving.text',
    title:
      'transactionAssociation.documentsModal.confirmationPopover.documentApproving.title',
  },
};

const MotionCard = motion.create(Card);

export const AssociateBanner = ({
  selectedDocumentStatus,
  isAssociating,
  onAssociate,
}: AssociateCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const shouldShowConfirmationPopover =
    selectedDocumentStatus === 'open' || selectedDocumentStatus === 'approved';

  const translations = shouldShowConfirmationPopover
    ? popoverTranslations[selectedDocumentStatus]
    : undefined;

  const {
    isOpen,
    triggerProps,
    triggerRef,
    popoverProps,
    popoverRef,
    open,
    close,
  } = usePopover();

  const handleClick = () => {
    if (shouldShowConfirmationPopover) {
      open();

      return;
    }

    onAssociate?.();
  };

  return (
    <>
      <MotionCard
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={{
          visible: { y: 0, opacity: 1 },
          hidden: { y: 0.5, opacity: 0 },
        }}
        background="blue100"
        corners="all"
        boxShadow="elevatedShadow5"
        paddingX="space36"
        flex="none"
        width="100%"
        position="absolute"
        bottom={0}
        maxHeight="space80"
      >
        <Grid
          alignItems="center"
          autoFlow="column"
          justifyContent="space-between"
        >
          <Text>
            {t('transactionAssociation.documentsModal.footerDescription')}
          </Text>
          <Button
            {...triggerProps}
            ref={triggerRef}
            onClick={handleClick}
            disabled={isAssociating}
            loading={isAssociating && !shouldShowConfirmationPopover}
          >
            {t('transactionAssociation.documentsModal.ctaButton')}
          </Button>
        </Grid>
      </MotionCard>
      {isOpen && shouldShowConfirmationPopover && translations && (
        <Popover {...popoverProps} ref={popoverRef} width="350px">
          <Grid gap="space16">
            <Text fontWeight="semibold">{t(translations.title)}</Text>
            <Text>{t(translations.text)}</Text>
            <Grid templateColumns="auto 1fr" gap="space8" alignSelf="center">
              <Button
                onClick={close}
                variant="secondary"
                disabled={isAssociating}
              >
                {t(
                  'transactionAssociation.documentsModal.confirmationPopover.closeButton'
                )}
              </Button>
              <Button
                onClick={onAssociate}
                loading={isAssociating}
                disabled={isAssociating}
              >
                {t(
                  'transactionAssociation.documentsModal.confirmationPopover.cta'
                )}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};

import { Box, Grid } from '@candisio/design-system';
import { BoxWrapper } from 'components/Layouts/styles';
import {
  StickyCardRefProvider,
  useStickyCardRefContext,
} from 'providers/StickyCardRefProvider';
import { ReactNode } from 'react';

interface ReimbursementLayoutProps {
  leftSection: ReactNode;
  middleSection: ReactNode;
  rightSection: ReactNode;
}

export const ReimbursementLayout = ({
  leftSection,
  middleSection,
  rightSection,
}: ReimbursementLayoutProps) => {
  const cardRef = useStickyCardRefContext();

  return (
    <Grid
      // @ts-ignore
      style={{ containerType: 'size', willChange: 'grid-template-columns' }}
      height="100%"
      paddingBottom={0}
      paddingRight={0}
      paddingLeft="space16"
      maxWidth="100%"
      overflow="hidden"
      alignItems="start"
      gap="space20"
      templateColumns="auto 2fr 1fr"
    >
      <Box overflow="hidden" height="100%" width="clamp(20cqw, 20rem, 25cqw)">
        {leftSection}
      </Box>
      {middleSection}
      <StickyCardRefProvider>
        <BoxWrapper data-scroll-padding={cardRef?.current?.offsetHeight}>
          {rightSection}
        </BoxWrapper>
      </StickyCardRefProvider>
    </Grid>
  );
};

import { BoxProps } from '@candisio/design-system';
import {
  CardBrand,
  CardIssuerTransaction,
  CardType,
  DocumentStatus,
  InvoiceAssociationStatus,
  Maybe,
  MemberInfo,
  TransactionAmount,
  TransactionCategory,
  TransactionDeclinedReason,
  TransactionDetailsDataFragment,
  TransactionStatus,
  TransactionType,
} from 'generated-types/graphql.types';
import { ReactNode } from 'react';

export type Transaction = {
  id: string;
  cardId: string;
  cardRefNum?: string;
  cardName?: string;
  type?: TransactionType;
  status?: TransactionStatus;
  member?: MemberInfo;
  merchantName?: string;
  merchantLegalName?: string;
  transactionCreatedAt?: string;
  billingAmount?: TransactionAmount;
  transactionAmount?: TransactionAmount;
  localizedDeclineReason?: TransactionDeclinedReason;
  invoiceAssociationStatus?: InvoiceAssociationStatus;
  merchantLogoUrl: string | undefined;
  category: TransactionCategory | undefined;
  cardType: CardType | undefined;
  cardBrand: CardBrand | undefined;
};
export interface TransactionDetailsCardProps {
  loading?: boolean;
  transaction?: Transaction;
  contextMenu?: ReactNode;
  contextMenuProps?: BoxProps;
  headerWidth?: BoxProps['width'];
}

export const transactionAmount = {
  value: 349.5,
  currency: 'Eur',
};

export const transactionDetailsCardMockData = {
  loading: false,
  transaction: {
    id: '1',
    member: {
      firstName: 'Lisa',
      lastName: 'Smith',
    },
    cardName: 'Marketing Kreditkarte',
    cardRefNum: '1234',
    confirmedAt: '2021.11.08',
    status: TransactionStatus.Confirmed,
    merchantName: 'CND XX-3333',
    transactionAmount,
    documents: [],
  },
};

export type PopoverDescription = {
  NEW: string;
  REJECTED: string;
  APPROVED: string;
  OPEN: string;
};

export const popoverDescription: PopoverDescription = {
  [DocumentStatus.New]:
    'transactionDetailsCardContextMenu.popoverDescription.new',
  [DocumentStatus.Rejected]:
    'transactionDetailsCardContextMenu.popoverDescription.rejected',
  [DocumentStatus.Approved]:
    'transactionDetailsCardContextMenu.popoverDescription.approved',
  [DocumentStatus.Open]:
    'transactionDetailsCardContextMenu.popoverDescription.open',
};

enum AmountVariant {
  REFUND_AMOUNT = 'REFUND_AMOUNT',
  STATUS_INQUIRY_AMOUNT = 'STATUS_INQUIRY_AMOUNT',
  DECLINED_AMOUNT = 'DECLINED_AMOUNT',
  OTHER_AMOUNT = 'OTHER_AMOUNT',
}

type TextStyle = {
  color: string;
  textDecoration: string;
};

const textStyleVariant: Record<AmountVariant, TextStyle> = {
  [AmountVariant.OTHER_AMOUNT]: {
    color: 'gray800',
    textDecoration: 'none',
  },
  [AmountVariant.REFUND_AMOUNT]: {
    color: 'green500',
    textDecoration: 'none',
  },
  [AmountVariant.STATUS_INQUIRY_AMOUNT]: {
    color: 'gray600',
    textDecoration: 'none',
  },
  [AmountVariant.DECLINED_AMOUNT]: {
    color: 'gray500',
    textDecoration: 'line-through',
  },
};

export const getAmountTextStyle = (
  status: Maybe<TransactionStatus>,
  type: Maybe<TransactionType>
) => {
  if (type === TransactionType.Refund) {
    return textStyleVariant.REFUND_AMOUNT;
  }

  if (type === TransactionType.StatusInquiry) {
    return textStyleVariant.STATUS_INQUIRY_AMOUNT;
  }

  if (status === TransactionStatus.Declined) {
    return textStyleVariant.DECLINED_AMOUNT;
  }

  return textStyleVariant.OTHER_AMOUNT;
};

export const dataToTransactionDetails = (
  data?: Omit<TransactionDetailsDataFragment, 'documents'> &
    Pick<CardIssuerTransaction, 'merchantLogoUrl' | 'category'>
): Transaction | undefined => {
  if (!data) return;

  return {
    id: data.id ?? undefined,
    cardId: data.cardId,
    type: data.type ?? undefined,
    status: data.status ?? undefined,
    cardName: data.cardName ?? undefined,
    cardRefNum: data.cardRefNum ?? undefined,
    member: data.member ?? undefined,
    merchantName: data.merchantName ?? undefined,
    billingAmount: data.billingAmount ?? undefined,
    merchantLegalName: data.merchantLegalName ?? undefined,
    transactionAmount: data.transactionAmount ?? undefined,
    transactionCreatedAt: data.transactionCreatedAt ?? undefined,
    localizedDeclineReason: data.localizedDeclineReason ?? undefined,
    invoiceAssociationStatus: data.invoiceAssociationStatus ?? undefined,
    merchantLogoUrl: data.merchantLogoUrl ?? undefined,
    category: data.category ?? undefined,
    cardType: data.cardType ?? undefined,
    cardBrand: data.cardBrand ?? undefined,
  };
};

import { Box, Grid, Text, TruncatedText } from '@candisio/design-system';

interface CreditCardFilterMenuItemProps {
  label: string;
  cardNumber: string;
  firstName: string;
  lastname: string;
}

export const CreditCardFilterMenuItem = ({
  label,
  cardNumber,
  firstName,
  lastname,
}: CreditCardFilterMenuItemProps) => {
  return (
    <Grid autoFlow="column" gap="space4">
      <Box>
        <TruncatedText width="space128">
          {`${firstName} ${lastname}`}
        </TruncatedText>
        <TruncatedText color="gray500" width="space128">
          {label}
        </TruncatedText>
      </Box>
      <Text>{`••• ${cardNumber}`}</Text>
    </Grid>
  );
};

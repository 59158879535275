import { Text, useModal } from '@candisio/design-system';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { InfoPanelPromo } from 'containers/Entitlements/components/Upsell/components/InfoPanelPromo';
import { useDatev } from 'orgConfig/datev';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ExportContext } from 'views/Integrations/Export/Context';
import { ExportType, Table } from 'views/Integrations/Export/types';
import { BDSPromotionModal } from './BDSPromotionModal';

export const ProvisionsPromotionExportInfoCard = () => {
  const [t] = useTranslation();
  const { exportType, table } = useContext(ExportContext);
  const { bdsBought } = useDatev(); // BDS-checked

  const shouldShowProvisionsPromo =
    table === Table.PROVISIONS || table === Table.REVERSALS;

  const shouldShowBdsPromo =
    !shouldShowProvisionsPromo && exportType === ExportType.DATEV_BDS;

  const { triggerProps, isOpen, close, open } = useModal();

  if (bdsBought || (!shouldShowProvisionsPromo && !shouldShowBdsPromo)) {
    return null;
  }

  const getProvisionText = () => {
    if (shouldShowProvisionsPromo) {
      return (
        <Text
          fontSize="small"
          lineHeight="paragraph"
          data-cy="provisionPromoText"
        >
          <Trans
            t={t}
            i18nKey="export.exportForm.exportDatevBds.provisionsPromotion"
          >
            Um Rückstellungen zu exportieren, ist ein Upgrade auf
            <Text fontWeight="semibold">Candis Plus</Text>und der
            <Text fontWeight="semibold">Export zu DATEV Rechnungswesen</Text>
            erforderlich.
          </Trans>
        </Text>
      );
    }
    if (shouldShowBdsPromo) {
      return (
        <Text fontSize="small" lineHeight="paragraph" data-cy="bdsPromoText">
          <Trans
            t={t}
            i18nKey={'export.exportForm.exportDatevBds.bdsPromotion'}
          >
            is required to be able to use this export.
            <Text fontWeight="semibold">Candis Plus</Text>
          </Trans>
        </Text>
      );
    }
    return undefined;
  };

  return (
    <>
      <InfoPanelPromo
        linkText={t('export.exportForm.exportDatevBds.learnMore')}
        message={getProvisionText()}
        {...triggerProps}
        onClick={open}
      />

      {isOpen && (
        <BDSPromotionModal
          utmSource={PROVISIONS_UTM_SOURCE.EXPORT_SIDEBAR}
          isOpen={isOpen}
          close={close}
        />
      )}
    </>
  );
};

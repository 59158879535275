import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Paragraph,
  ScrollBox,
  Tag,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { DOCUMENT_MANAGEMENT_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { DocumentRelationItem } from './DocumentRelations';
import {
  DocumentSummaryCardContainerImproved,
  ErrorDocumentSummaryCardList,
  HelpCenterLink,
} from './DocumentSummaryCard';
import { SidebarSegmentPromo } from './SidebarSegmentPromo';
import { useDocumentRelations } from './hooks/useDocumentRelations';
import { useRelationsSidebarPromo } from './sidebarPromoHooks';

type DocumentRelationsDrawerProps = {
  globalDocumentId: string;
  readOnly?: boolean;
  selectedRelationId?: string;
  setSelectedRelationId?: (id: string) => void;
};

export const DocumentRelationsSegment = ({
  globalDocumentId,
  readOnly = false,
  selectedRelationId,
  setSelectedRelationId,
}: DocumentRelationsDrawerProps) => {
  const [t] = useTranslation();
  const { showDocumentRelations } = useEcm();
  const { isSeen: isPromotionSeen, markAsSeen } = useRelationsSidebarPromo();

  const { isOnlyApprover } = useUserRoles();

  const navigate = useNavigate();
  const location = useLocation();

  const goToManageRelationships = useCallback(() => {
    navigate(location.pathname + Routes.RELATIONSHIPS);
  }, [location.pathname, navigate]);

  const {
    isLoading: isLoadingRelations,
    isEmpty: hasNoRelations,
    hasError,
    relatedDocuments,
    isLinkingDocumentsPending,
    isUnlinkingDocumentsPending,
    handleUnlinkDocuments,
  } = useDocumentRelations(globalDocumentId);

  const isEmpty = hasNoRelations;
  const isLoading = isLoadingRelations;

  const showError = !isLoading && hasError;
  const showEmptyList = !isLoading && !hasError && isEmpty;
  const showLoadingItem = isLoadingRelations || isLinkingDocumentsPending;

  const relationsContent = (
    <>
      {showError && <ErrorDocumentSummaryCardList key="error-item" />}
      {showEmptyList && <EmptyRelationshipsList key="empty-item" />}
      {relatedDocuments.map(relatedDocument => (
        <DocumentRelationItem
          readOnly={readOnly}
          key={relatedDocument.relationId}
          documentName={relatedDocument.name ?? ''}
          handleUnlinkDocuments={handleUnlinkDocuments}
          isUnlinkingDocumentsPending={isUnlinkingDocumentsPending}
          selectedRelationId={selectedRelationId}
          setSelectedRelationId={setSelectedRelationId}
          mainDocumentId={globalDocumentId}
          {...relatedDocument}
        />
      ))}
      {showLoadingItem && <LoadingRelatonshipItem />}
    </>
  );

  const relationsFooter = (
    <>
      {!isLoading && (
        <Button
          disabled={readOnly}
          label={
            readOnly && isOnlyApprover
              ? t(
                  'document.tabs.relationships.manageRelationshipsDisabled.onlyApprover'
                )
              : undefined
          }
          onClick={goToManageRelationships}
          variant={showEmptyList ? 'primary' : 'secondary'}
          color="blue"
          icon="plus"
        >
          <TruncatedText>
            {t('document.tabs.relationships.manageRelationships')}
          </TruncatedText>
        </Button>
      )}
      {showEmptyList && <HelpCenterLink />}
    </>
  );

  return (
    <ScrollBox scrollDirection="y" scrollbarGutter="stable" paddingX="space8">
      <Grid
        as="ul"
        rowGap="space8"
        listStyle="none"
        alignContent="start"
        justifyItems="stretch"
        borderRadius="medium"
        height="100%"
        padding="0"
        {...(showEmptyList && {
          padding: 'space12',
          background: 'gray50',
        })}
      >
        {!isPromotionSeen && !isEmpty && (
          <SidebarSegmentPromo
            markAsSeen={markAsSeen}
            i18nKey="ecm:promo.documentRelationsImproved.relations.segment"
          />
        )}
        {showDocumentRelations ? relationsContent : <PromoRelationships />}
        <Grid
          position="sticky"
          bottom="0"
          paddingTop="space8"
          justifyItems="stretch"
          gap="space8"
          background={
            showEmptyList
              ? undefined
              : 'linear-gradient(to top, gray200 80%, transparent 95% 100%)'
          }
        >
          {showDocumentRelations ? (
            relationsFooter
          ) : (
            <PromoRelationshipsButton />
          )}
        </Grid>
      </Grid>
    </ScrollBox>
  );
};

const PromoRelationshipsButton = () => {
  const [t] = useTranslation();

  return (
    <UpsellPromo
      feature={FEATURE.DOCUMENT_RELATIONS}
      utmSource={DOCUMENT_MANAGEMENT_UTM_SOURCE.DOCUMENTS_RELATIONS}
    >
      <Button
        icon="newTab"
        iconPosition="right"
        variant="primary"
        color="promo"
      >
        {t('document.tabs.relationships.helpCenterArticle.label')}
      </Button>
    </UpsellPromo>
  );
};

const PromoRelationships = () => {
  const [t] = useTranslation();

  return (
    <>
      <div className="grid gap-4 pb-2 justify-self-start">
        <Tag variant="secondary" color="purple">
          {t('tag.addOn')}
        </Tag>
        <div className="grid gap-1">
          <Text
            color="gray800"
            fontWeight="semibold"
            fontSize="large"
            lineHeight="140%"
          >
            {t('document.tabs.relationships.promo.title')}
          </Text>
          <Text fontSize="medium" color="gray600" lineHeight="140%">
            {t('document.tabs.relationships.promo.text')}
          </Text>
        </div>
      </div>
      <LoadingRelatonshipItem />
      <LoadingRelatonshipItem />
    </>
  );
};

const EmptyRelationshipsList = () => {
  const [t] = useTranslation();
  return (
    <>
      <Grid gap="space16" paddingBottom="space8" justifySelf="start">
        <Tag variant="callout">{t('tag.new')}</Tag>
        <Paragraph fontSize="large" width="12rem">
          {t('document.tabs.relationships.newViewInfo')}
        </Paragraph>
      </Grid>
      <LoadingRelatonshipItem />
      <LoadingRelatonshipItem />
    </>
  );
};

const LoadingRelatonshipItem = () => (
  <DocumentSummaryCardContainerImproved background="gray200" width="100%">
    <Grid
      padding="0"
      autoColumns="4rem 1fr"
      gap="space8"
      autoFlow="column"
      color="gray500"
    >
      <Grid
        background="gray300"
        border="1px soldi gray250"
        borderRadius="small"
        justifyContent="center"
        alignItems="center"
        style={{ aspectRatio: Math.SQRT1_2 + '' }}
      >
        <Flex borderRadius="full" background="gray200" padding="space8">
          <Icon icon="search" size="space16" color="gray800" />
        </Flex>
      </Grid>
      <Grid alignContent="space-between">
        <Grid gap="space8">
          <Box
            width="space96"
            height="space12"
            background="gray400"
            borderRadius="full"
          />
          <Box
            width="space128"
            height="space12"
            background="gray250"
            borderRadius="full"
          />
          <Box
            width="space80"
            height="space12"
            background="gray250"
            borderRadius="full"
          />
        </Grid>
        <Grid alignSelf="end" justifyContent="space-between" autoFlow="column">
          <Box
            width="space64"
            height="space12"
            background="gray250"
            borderRadius="full"
          />
          <Box
            width="space32"
            height="space12"
            background="gray400"
            borderRadius="full"
          />
        </Grid>
      </Grid>
    </Grid>
  </DocumentSummaryCardContainerImproved>
);

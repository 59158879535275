import {
  SplitDS,
  TaxPresentation,
} from 'components/Form/SplitBookingsForm/types';
import { useSplitBookingsContext } from 'containers/SplitBookings/SplitBookingsContext';
import { SapFreightPackageBookingFields } from 'containers/SplitBookings/components/NewSplit/NewSplitForPurchaseOrders';
import {
  bookingWithNetAmount,
  calculateRestAmount,
  grossToNet,
} from 'containers/SplitBookings/toolkit/utils';
import { useSap } from 'orgConfig/sap';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';
import { useGetIsReverseChargeTaxCode } from './useHasReverseChargeTaxCode';

export const useAddNewSplit = ({
  isCurrentSplitValid,
}: {
  isCurrentSplitValid: boolean;
}) => {
  const { shouldUseSapPurchaseOrder } = useSap();

  const {
    bookings,
    grossAmount,
    setBookings,
    setOpenedIndex,
    showSubmitErrors,
    setShowSubmitErrors,
  } = useSplitBookingsContext();

  const getIsReverseChargeTaxCode = useGetIsReverseChargeTaxCode();

  const remainingAmount = calculateRestAmount({
    grossAmount,
    bookings: bookings.map(bookingWithNetAmount),
  });

  const onAddNewSplit = (
    packageFreightValues?: SapFreightPackageBookingFields
  ) => {
    if (!isCurrentSplitValid) {
      if (!showSubmitErrors) {
        setShowSubmitErrors(true);
      }

      return;
    }

    const { id, ...lastBooking } = bookings[bookings.length - 1];

    const isReverseChargeTaxCode = getIsReverseChargeTaxCode(
      lastBooking.taxCode ?? ''
    );

    const updatedAmount =
      lastBooking.taxPresentation === TaxPresentation.Gross
        ? remainingAmount
        : grossToNet(remainingAmount, lastBooking.vatRate ?? 0);

    const netAmount = isReverseChargeTaxCode
      ? updatedAmount
      : grossToNet(remainingAmount, lastBooking.vatRate ?? 0);

    const taxAmount = isReverseChargeTaxCode
      ? 0
      : roundToCurrencyPrecision(remainingAmount - netAmount);

    const newBooking: SplitDS = {
      ...lastBooking,
      amount: updatedAmount,
      taxAmount,
      netAmount,
      ...(packageFreightValues &&
        shouldUseSapPurchaseOrder && {
          ...packageFreightValues,
          quantity: undefined,
          generalLedgerAccount: {
            value: undefined,
          },
          unitPrice: undefined,
        }),
    };

    setBookings([...bookings, newBooking]);
    setOpenedIndex(bookings.length);
  };

  return {
    onAddNewSplit,
  };
};

import { SearchType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const useSearchTypesLabel = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const startsWithLabel = t('powerSearch.filterPopover.filterType.startsWith');
  const containsLabel = t('powerSearch.filterPopover.filterType.contains');
  const exactMatchLabel = t('powerSearch.filterPopover.filterType.exact');

  return {
    [SearchType.PrefixMatch]: startsWithLabel,
    [SearchType.Contains]: containsLabel,
    [SearchType.ExactMatch]: exactMatchLabel,
    [SearchType.PostfixMatch]: '',
  };
};

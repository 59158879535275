import {
  Button,
  Card,
  Flex,
  Grid,
  Image,
  Popover,
  Separator,
  Text,
  usePopover,
} from '@candisio/design-system';
import iconCard from 'assets/credit-cards/icon-card.svg';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import {
  Validity,
  validityFieldOptions,
} from 'containers/credit-cards/CreditCardDrawer/Tabs/Request/utils/validityFieldOptions';
import { CardType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { ReplaceCardConfirmationDialog } from './ReplaceCardConfirmationDialog';
import { ReplaceReasonRadioGroup } from './ReplaceReasonRadioGroup';
import { TransferredItemInfo } from './TransferredItemInfo';
import { replaceCardFormErrorMessages } from './utils/replaceCardFormErrorMessages';
import {
  ReplaceCardFormOutput,
  ReplaceCardFormSchemaOptions,
  ReplaceCardFormValues,
  replaceCardFormSchema,
} from './utils/replaceCardFormSchema';

export interface ReplaceCardFormProps {
  cardType: CardType;
  defaultValues: ReplaceCardFormValues;
  replacing: boolean;
  onSubmit: (formValues: ReplaceCardFormOutput) => Promise<void>;
}

export const ReplaceCardForm = ({
  cardType,
  defaultValues,
  replacing,
  onSubmit,
}: ReplaceCardFormProps) => {
  const context: ReplaceCardFormSchemaOptions = { cardType };
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef, close } =
    usePopover({
      placement: 'top',
    });

  const form = useForm<ReplaceCardFormOutput>({
    context,
    mode: 'onTouched',
    defaultValues,
    resolver: zodResolver({
      zodSchema: replaceCardFormSchema,
      errorMessages: replaceCardFormErrorMessages,
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
    }),
  });

  const { replaceReason } = form.watch();

  return (
    <FormProvider {...form}>
      <Grid
        templateRows="auto auto 1fr"
        alignItems="start"
        as="form"
        gap="space16"
      >
        <Card background="gray100" padding="0">
          <Grid templateColumns="auto 1fr" padding="space16" gap="space12">
            <Image
              src={iconCard}
              alt={t('creditCardFormDataPreview.replaceCard.altIcon')}
            />
            <Flex alignItems="baseline" gap="space8">
              <Text fontSize="large">
                {t('creditCardFormDataPreview.replaceCard.title')}
              </Text>
              <Text>{t('creditCardFormDataPreview.replaceCard.subtitle')}</Text>
            </Flex>
          </Grid>
          <Separator />
          <Grid padding="space16" gap="space16">
            <Text fontSize="large">
              {t('creditCardFormDataPreview.replaceCard.formHeader')}
            </Text>
            <Grid autoFlow="row">
              <ReplaceReasonRadioGroup cardType={cardType} />
            </Grid>
          </Grid>
        </Card>
        {cardType === CardType.Virtual && (
          <Card background="gray100">
            <Grid
              templateColumns="auto 256px"
              gap="space32"
              alignItems="center"
              width="400px"
            >
              <Text fontSize="large">
                {t('ccRequestForm.fields.validity.label')}
              </Text>
              <HookFormSelectField
                name="validity"
                defaultSelectedKey={Validity.months36}
                label={t('ccRequestForm.fields.validity.label')}
                items={validityFieldOptions.map(option => ({
                  ...option,
                  children: t(option.children),
                }))}
              />
            </Grid>
          </Card>
        )}
        <TransferredItemInfo cardType={cardType} />
        <Button
          justifySelf="start"
          alignSelf="end"
          {...triggerProps}
          ref={triggerRef}
        >
          {t('creditCardFormDataPreview.replaceCard.title')}
        </Button>
        {isOpen && (
          <Popover
            {...popoverProps}
            ref={popoverRef}
            width="370px"
            padding="space16"
          >
            <ReplaceCardConfirmationDialog
              onClose={close}
              loading={replacing}
              replaceReason={replaceReason}
              onSubmit={form.handleSubmit(onSubmit)}
            />
          </Popover>
        )}
      </Grid>
    </FormProvider>
  );
};

import React, { CSSProperties } from 'react';
import {
  AvatarCircle,
  AvatarCircleProps,
  AvatarProps,
} from '../../Atoms/Avatar';
import { ScrollBox } from '../../Atoms/ScrollBox/next';
import { Tooltip, useTooltip } from '../../Atoms/Tooltip';

interface MorePlaceholderProps {
  children: React.ReactElement<AvatarProps>[];
  size?: AvatarCircleProps['size'];
  tooltipDelay?: number;
  tooltipMaxHeight?: CSSProperties['height'];
}

export const MorePlaceholder = ({
  children,
  size = 'medium',
  tooltipDelay = 200,
  tooltipMaxHeight,
}: MorePlaceholderProps) => {
  const { isOpen, triggerProps, triggerRef, tooltipProps, tooltipRef } =
    useTooltip({ delay: tooltipDelay });

  const maxHeightScroller = tooltipProps.style?.maxHeight;
  const heightScroller = tooltipProps.style?.height;

  return (
    <>
      <AvatarCircle
        size={size}
        className="bg-gray-200 text-gray-700"
        {...triggerProps}
        tabIndex={0}
        ref={triggerRef}
      >
        +{children.length}
      </AvatarCircle>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <ScrollBox
            style={{
              maxHeight: maxHeightScroller,
              height: heightScroller,
            }}
          >
            <div
              className="flex flex-col gap-1"
              style={{
                maxHeight: tooltipMaxHeight,
              }}
            >
              {children.map((child, index) => {
                return (
                  <div key={index} className="flex items-center gap-2">
                    {React.cloneElement(child, {
                      hasTooltip: false,
                      size: 'small',
                    })}
                    {child.props.name}
                  </div>
                );
              })}
            </div>
          </ScrollBox>
        </Tooltip>
      )}
    </>
  );
};

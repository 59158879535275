import { Button } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateReimbursementWithRedirect } from 'views/Reimbursement/hooks/useCreateReimbursementWithRedirect';

export const CreateReimbursementButton = (
  props: Omit<HTMLProps<HTMLButtonElement>, 'as' | 'color' | 'shape' | 'type'>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { createReimbursement, createReimbursementPending } =
    useCreateReimbursementWithRedirect();

  return (
    <Button
      {...props}
      onClick={createReimbursement}
      icon="plus"
      size="small"
      variant="tertiary"
      loading={createReimbursementPending}
    >
      {t('dashboard.createExpense.createCTA')}
    </Button>
  );
};

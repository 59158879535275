import { Flex, Skeleton, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { LimitTextProps } from './types';
import { InfoPopupButton } from 'components/Icons/InfoPopupButton';

export const AmountLoader = <Skeleton height="2.325rem" width="4.5rem" />;

export const LimitText = ({ loading, limit }: LimitTextProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);

  return (
    <Flex direction="column">
      <Flex gap="space4" alignItems="center">
        <Text fontSize="basic" fontWeight="regular" color="gray600">
          {t('ccOverviewCard.limit')}
        </Text>
        <InfoPopupButton message={t('ccOverviewCard.limitTooltip')} />
      </Flex>
      {loading ? (
        AmountLoader
      ) : (
        <Text fontSize="xxlarge" color="gray500">
          {limit}
        </Text>
      )}
    </Flex>
  );
};

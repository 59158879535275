import { Button, Text } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import styles from '../RightSection.module.css';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useUserRoles } from 'hooks/useUserRoles';
import { useFormContext } from 'react-hook-form';
import { ReimbursementFormValues } from 'views/Reimbursement/toolkit/reimbursementFormSchema';
import { useEffect } from 'react';

export const MissingAccountsPayableNumberCard = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { searchParams, setSearchParams } = useMutateSearchParams();
  const { isAdmin, isAccountant } = useUserRoles();
  const { trigger } = useFormContext<ReimbursementFormValues>();
  const handleOpenTeamMemberDrawer = () => {
    searchParams.set('teamMemberDrawer', 'true');
    setSearchParams(searchParams);
  };

  // Permission not available yet for this use case
  const isButtonVisible = isAdmin || isAccountant;

  // Surface accountsPayableNumber error field immediately on first render
  useEffect(() => {
    void trigger('accountsPayableNumber');
  }, [trigger]);

  return (
    <div className={styles['alert']}>
      <Text>{t('accountsPayableNumberField.missingInfo')}</Text>
      {isButtonVisible && (
        <Button
          variant="secondary"
          color="error"
          onClick={handleOpenTeamMemberDrawer}
        >
          {t('accountsPayableNumberField.openTeamMember')}
        </Button>
      )}
    </div>
  );
};

import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useMemo } from 'react';
import { ReimbursementFormValues } from '../toolkit/reimbursementFormSchema';
import { Reimbursement } from './useFormattedReimbursement';
import { useApprovalFieldData } from './useApprovalFieldData';
import { ReimbursementWorkflow } from './useWorkflowForReimbursementCaseById';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';

export const ApprovalType = {
  APPROVERS: 'approvers',
  WORKFLOW: 'workflow',
} as const;

const fallbackDefaultValues = {
  title: '',
  note: undefined,
  targetMembership: undefined,
  suggestedCostCenter: undefined,
  iban: '',
  accountsPayableNumber: undefined,
  approvalType: ApprovalType.APPROVERS,
  approvers: [],
  workflow: undefined,
  isAdvancedAccountingEnabled: false,
};

const getSuggestedAccountingField = (
  suggestedAccountingField?:
    | Reimbursement['suggestedCostCenter']
    | Reimbursement['suggestedCostObject']
    | Reimbursement['suggestedGeneralLedgerAccount']
) => {
  if (suggestedAccountingField?.id && suggestedAccountingField?.readableName) {
    return {
      value: suggestedAccountingField.id,
      inputValue: suggestedAccountingField.readableName,
    };
  }

  return null;
};

interface UseInitialReimbursementFormValuesReturn {
  defaultValues: ReimbursementFormValues;
}

interface UseInitialReimbursementFormValuesParams {
  reimbursement?: Reimbursement;
  accountsPayableNumber: string | undefined;
  workflow?: ReimbursementWorkflow;
}
export const useInitialReimbursementFormValues = ({
  reimbursement,
  accountsPayableNumber,
  workflow,
}: UseInitialReimbursementFormValuesParams): UseInitialReimbursementFormValuesReturn => {
  const currentUserId = useCurrentUser()?.id ?? '';

  const defaultApprovalData = useApprovalFieldData(workflow);

  const defaultValues: ReimbursementFormValues = useMemo(() => {
    const {
      note,
      title,
      status,
      targetMembership,
      suggestedCostCenter,
      suggestedGeneralLedgerAccount,
      iban,
      suggestedCostObject,
      isAdvancedAccountingEnabled: isAdvancedAccountingEnabledData,
    } = reimbursement ?? {};

    const isAdvancedAccountingEnabled =
      status === ReimbursementCaseStatus.Draft
        ? (isAdvancedAccountingEnabledData ?? false)
        : false;

    return {
      ...fallbackDefaultValues,
      title: title ?? '',
      note: note ?? null,
      iban: iban ?? '',
      targetMembership: targetMembership?.id
        ? [targetMembership?.id]
        : [currentUserId],
      suggestedCostCenter: getSuggestedAccountingField(suggestedCostCenter),
      suggestedCostObject: getSuggestedAccountingField(suggestedCostObject),
      suggestedGeneralLedgerAccount: getSuggestedAccountingField(
        suggestedGeneralLedgerAccount
      ),
      accountsPayableNumber,
      isAdvancedAccountingEnabled,
      ...defaultApprovalData,
    };
  }, [
    reimbursement,
    currentUserId,
    accountsPayableNumber,
    defaultApprovalData,
  ]);

  return {
    defaultValues,
  };
};

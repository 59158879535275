import { faker } from 'utils/faker';
import { ContactsTableData } from 'views/Integrations/Export/elements/table/contacts/types';
import { PaymentConditionsTableData } from 'views/Integrations/Export/elements/table/paymentConditions/types';
import { ExportEntityRow } from 'views/Integrations/Export/types';

export const dummyProvisionData = Array.from({ length: 14 }, () => ({
  type: 'PROVISION',
  id: faker.uuid(),
  contact: faker.companyName(),
  amount: {
    amount: 0,
    currency: 'EUR',
    precision: 0,
  },
  netAmount: {
    __typename: 'Money',
    currency: 'EUR',
    amount: 77350,
    precision: 2,
  },
  invoiceDate: faker.date(),
  deliveryDate: faker.date(),
  invoiceNumber: '',
  paymentState: {
    isPaid: false,
  },
  hasDocument: false,
  generalLedgerAccount: ['6600'],
  account: ['1354'],
  provisionEntityType: 'DOCUMENT',
  provisionEntityId: faker.string(),
  provisionStatus: 'OPEN',
  isDisabled: true,
  exportStatus: null,
  disableReason: 'Not part of the selected posting period',
})) as ExportEntityRow[];

export const dummyReversalData = Array.from({ length: 14 }, () => ({
  type: 'PROVISION_REVERSAL',
  id: faker.uuid(),
  contact: faker.companyName(),
  amount: {
    amount: 0,
    currency: 'EUR',
    precision: 0,
  },
  netAmount: {
    __typename: 'Money',
    currency: 'EUR',
    amount: 909,
    precision: 2,
  },
  invoiceDate: faker.date(),
  deliveryDate: faker.date(),
  invoiceNumber: '',
  paymentState: {
    isPaid: false,
  },
  hasDocument: false,
  generalLedgerAccount: ['7888', '7888', '7888'],
  provisionEntityType: 'DOCUMENT',
  provisionEntityId: faker.string(),
  provisionStatus: 'EXPORTING',
  isDisabled: true,
  exportStatus: null,
  disableReason: 'This export type does not support provisions or reversals',
})) as ExportEntityRow[];

export const dummyContactData = Array.from({ length: 14 }, () => ({
  id: faker.string(),
  name: faker.companyName(),
  status: 'NEW',
  membershipId: null,
  relationshipType: 'SUPPLIER',
})) as ContactsTableData;

export const dummyPaymentConditionsData = Array.from({ length: 14 }, () => ({
  id: faker.uuid(),
  value: `${faker.number(1, 99)} %`,
  status: 'NEW',
  paymentConditionNumber: faker.number(1, 99),
})) as PaymentConditionsTableData;

import {
  Button,
  Card,
  Grid,
  Skeleton,
  TruncatedText,
} from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  Locale,
  WorkflowStepResolutionTypes,
} from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ApprovalFieldData,
  useReimbursementFormsContext,
} from 'views/Reimbursement/context/ReimbursementFormsContext';
import { APPROVING_EDIT_MODE } from 'views/Reimbursement/context/ReimbursementFormsProvider';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useSubmitReimbursmentWorkflowStep } from 'views/Reimbursement/hooks/useSubmitReimbursmentWorkflowStep';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { ReimbursementWorkflow } from 'views/Reimbursement/hooks/useWorkflowForReimbursementCaseById';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { CommentFormOutput } from '../SendBackToEmploy/utils';
import { WorkflowVisualization } from '../WorkflowVisualization/WorkflowVisualization';
import { ApproveNowAction } from './ApproveNowAction';
import { CommentFormContainer } from './CommentFormContainer';
import { EditModeAction } from './EditModeAction';
import { ErrorSection } from './ErrorSection';
import { FastApproveReturn } from './FooterActionBox';
import { FooterHeader } from './FooterHeader';
import { ReimbursementApproverActions } from './ReimbursementApproverActions';
import { useSuccessToastWithDeepLink } from './ReimbursementSuccessMessageWithDeepLink';
import { RequestApprovalAction } from './RequestApprovalAction';
import { useExportLimitCheck } from '../ReimbursementSplitBookings/hooks/useExportLimitCheck';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';

export type ApprovingFooterMode = 'view' | 'edit';

const MIN_COLUMN_WIDTH = 151;
const MIN_COLUMN_WIDTH_OTHER = 191;

export const TRANSLATION = {
  TITLE: 'reimbursementView.rightSection.footerActions.title',
  EDIT: 'reimbursementView.rightSection.approvedFooterAction.edit',
  CANCEL_EDIT: 'reimbursementView.rightSection.approvedFooterAction.cancelEdit',
  REJECT_ERROR:
    'reimbursementView.rightSection.approvingFooterAction.rejectError',
  REJECT_SUCCESS:
    'reimbursementView.rightSection.approvingFooterAction.rejectSuccess',
  APPROVE_ERROR:
    'reimbursementView.rightSection.approvingFooterAction.approveError',
  APPROVE_SUCCESS:
    'reimbursementView.rightSection.approvingFooterAction.approveSuccess',
  APPROVAL_LIMIT_ERROR:
    'reimbursementView.rightSection.approvingFooterAction.approvalLimitError',
} as const;

const getInitialMode = (isEditMode: boolean): ApprovingFooterMode =>
  isEditMode ? 'edit' : 'view';

const toggleMode = (currentMode: ApprovingFooterMode): ApprovingFooterMode =>
  currentMode === 'view' ? 'edit' : 'view';
interface ApprovingStatusFooterActionBoxProps {
  reimbursement?: Reimbursement;
  isWorkflowLoading: boolean;
  createdById?: string;
  isRequestAmendmentPending: boolean;
  isFastApprovePending: boolean;
  onFastApprove: () => Promise<FastApproveReturn | undefined>;
  getExpenses: UseFormGetValues<ReimbursementItemsFormOutput>;
  onCycleReimbursments: () => void;
  workflow?: ReimbursementWorkflow;
  isSubmitForApprovalPending: boolean;
  reimbursementItems: ReimbursementItemForList[];
  refetchReimbursement?: () => void;
  refetchReimbursementItems: () => void;
  onRequestAmendment: ({ comment }: CommentFormOutput) => Promise<void>;
  onSubmitForApproval: (
    values: ApprovalFieldData
  ) => Promise<'error' | 'success'>;
}

const mainKey = 'reimbursementView.rightSection.reviewfooterAction';

export const ApprovingStatusFooterActionBox = ({
  createdById,
  isWorkflowLoading,
  reimbursement,
  isFastApprovePending,
  isSubmitForApprovalPending,
  onFastApprove,
  getExpenses,
  onCycleReimbursments,
  workflow,
  reimbursementItems,
  refetchReimbursement,
  refetchReimbursementItems,
  onSubmitForApproval,
  onRequestAmendment,
  isRequestAmendmentPending,
}: ApprovingStatusFooterActionBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();
  const { track } = useAnalytics();

  const {
    triggerAllValidations,
    setApprovalMode,
    lastModifiedReimbursementItem,
    isApprovingEditMode,
    setHasApprovalFieldError,
    hasReimbursementErrors,
    setReimbursementUIConfig,
    setHasReimbursementErrors,
    isReimbursementUpdatePending,
  } = useReimbursementFormsContext();

  const [isCommentFormVisible, setIsCommentFormVisible] = useState(false);
  const [isSendBackFormVisible, setIsSendBackFormVisible] = useState(false);

  const [approvingFooterMode, setApprovingFooterMode] =
    useState<ApprovingFooterMode>(getInitialMode(isApprovingEditMode));

  const { id: currentUserId, locale } = useCurrentUser() ?? {};
  const { isAccountant, isAdmin, isOnlyRequester, isOnlyApprover } =
    useUserRoles();

  const { triggerBookingsValidation, hasBookingsErrors } =
    useReimbursementSplitBookingHelpers();

  const successMessageWithDeepLink = useSuccessToastWithDeepLink(reimbursement);
  const { error } = useToastMessage();

  const { isApprovalBlocked } = useExportLimitCheck();

  const isReimbursementOwner = createdById === (currentUserId ?? '');
  const canFastApprove = isAdmin || isAccountant;
  const canRequestApproval = canFastApprove || isOnlyRequester;

  const { updateGeneralExpense, isUpdateGeneralExpensePending } =
    useUpdateGeneralExpense({ shouldDebounce: false });

  const { isUpdateHospitalityExpensePending, updateHospitalityExpense } =
    useUpdateHospitalityExpense({ shouldDebounce: false });
  const { isWorkflowStepPending, submitWorkflowStep } =
    useSubmitReimbursmentWorkflowStep();

  const backTextKey = isReimbursementOwner
    ? `${mainKey}.backMain`
    : `${mainKey}.back`;

  const minColumnWidth =
    isReimbursementOwner || locale === Locale.En
      ? MIN_COLUMN_WIDTH
      : MIN_COLUMN_WIDTH_OTHER;

  const handleModeToggle = useCallback(() => {
    setApprovingFooterMode(currentMode => {
      const newMode = toggleMode(currentMode);

      // sync URL with mode change
      updateSearchParam(
        APPROVING_EDIT_MODE.PARAM,
        newMode === 'edit' ? APPROVING_EDIT_MODE.VALUE : ''
      );

      // update UI config
      setReimbursementUIConfig(prevConfig => ({
        ...prevConfig,
        isReimbursementFormEditable: newMode === 'edit',
      }));

      // reset all validation state to default when exiting edit mode
      if (newMode === 'view') {
        setHasApprovalFieldError(false);
        setHasReimbursementErrors(false);
        setApprovalMode('requestApproval');
      }

      return newMode;
    });
  }, [
    setReimbursementUIConfig,
    updateSearchParam,
    setHasApprovalFieldError,
    setHasReimbursementErrors,
    setApprovalMode,
  ]);

  const isErrorSectionVisible = hasReimbursementErrors || hasBookingsErrors;

  const updateExpenseItems = async () => {
    if (!lastModifiedReimbursementItem) return;

    const expense =
      getExpenses('reimbursementItems')[lastModifiedReimbursementItem];
    const updateFn = {
      general: updateGeneralExpense,
      hospitality: updateHospitalityExpense,
      perDiem: null,
    }[expense?.reimbursementItemType];

    if (updateFn) await updateFn(expense);
  };

  const rejectReimbursement = async (comment: string) => {
    await updateExpenseItems();
    if (!workflow?.currentStepId) return;
    const submitResponse = await submitWorkflowStep({
      resolution: WorkflowStepResolutionTypes.Rejected,
      stepId: workflow.currentStepId,
      comment,
    });

    if (submitResponse === 'error') {
      error(t(TRANSLATION.REJECT_ERROR));

      return;
    }

    track(TrackingEvents.EXPENSE_COLLECTION_REJECTED, {
      reimbursement_id: reimbursement?.id,
    });
    successMessageWithDeepLink({
      key: TRANSLATION.REJECT_SUCCESS,
    });
    onCycleReimbursments();
  };

  const approveReimbursement = async () => {
    if (isApprovalBlocked) {
      error(t(TRANSLATION.APPROVAL_LIMIT_ERROR));
      return;
    }

    setApprovalMode('approve');
    await updateExpenseItems();

    if (!workflow?.currentStepId) return;

    const submitResponse = await submitWorkflowStep({
      resolution: WorkflowStepResolutionTypes.Approved,
      stepId: workflow.currentStepId,
    });

    if (submitResponse === 'error') {
      triggerAllValidations();
      triggerBookingsValidation();
      setHasReimbursementErrors(true);

      error(t(TRANSLATION.APPROVE_ERROR));

      return;
    }

    setHasReimbursementErrors(false);
    track(TrackingEvents.EXPENSE_COLLECTION_APPROVED, {
      reimbursement_id: reimbursement?.id,
    });
    successMessageWithDeepLink({
      key: TRANSLATION.APPROVE_SUCCESS,
    });
    onCycleReimbursments();
  };

  const isUpdatePending =
    isUpdateGeneralExpensePending || isUpdateHospitalityExpensePending;

  const isSubmitting = isUpdatePending || isWorkflowStepPending;

  const isCurrentUserReimbursementApprover =
    currentUserId && workflow?.currentStepApproverIds?.includes(currentUserId);

  if (isCommentFormVisible || isSendBackFormVisible) {
    return (
      <CommentFormContainer
        onSetIsCommentFormVisible={setIsCommentFormVisible}
        onRejectReimbursement={rejectReimbursement}
        isRejectPending={isSubmitting}
        reimbursement={reimbursement}
        reimbursementItems={reimbursementItems}
        refetchReimbursement={refetchReimbursement}
        refetchReimbursementItems={refetchReimbursementItems}
        onSetIsSendBackFormVisible={setIsSendBackFormVisible}
        onRequestAmendment={onRequestAmendment}
        isRequestAmendmentPending={isRequestAmendmentPending}
        approvingFooterMode={approvingFooterMode}
        onEditModeToggle={handleModeToggle}
        isCommentFormVisible={isCommentFormVisible}
        isSendBackFormVisible={isSendBackFormVisible}
      />
    );
  }

  if (isOnlyApprover && approvingFooterMode === 'edit') {
    return null;
  }

  return (
    <Card corners="top" boxShadow="elevatedShadow3" padding="space20">
      {approvingFooterMode === 'view' && (
        <Grid gap="space8">
          <FooterHeader
            reimbursement={reimbursement}
            reimbursementItems={reimbursementItems}
            refetchReimbursement={refetchReimbursement}
            refetchReimbursementItems={refetchReimbursementItems}
            toggleAction={
              <EditModeAction
                approvingFooterMode={approvingFooterMode}
                onClick={handleModeToggle}
              />
            }
          />
          {isWorkflowLoading ? (
            <Skeleton width="100%" height="space64" />
          ) : (
            <>
              {isErrorSectionVisible && <ErrorSection />}
              {isCurrentUserReimbursementApprover && (
                <ReimbursementApproverActions
                  onSetIsCommentFormVisible={setIsCommentFormVisible}
                  isCommentFormVisible={isCommentFormVisible}
                  isSubmitPending={isSubmitting}
                  onApproveReimbursement={approveReimbursement}
                />
              )}
              {workflow && <WorkflowVisualization workflow={workflow} />}
            </>
          )}
        </Grid>
      )}
      {approvingFooterMode === 'edit' && (
        <Grid gap="space8">
          <FooterHeader
            reimbursement={reimbursement}
            reimbursementItems={reimbursementItems}
            refetchReimbursement={refetchReimbursement}
            refetchReimbursementItems={refetchReimbursementItems}
            toggleAction={
              <EditModeAction
                approvingFooterMode={approvingFooterMode}
                onClick={handleModeToggle}
              />
            }
          />
          {isErrorSectionVisible && <ErrorSection />}
          <Grid gap="space8">
            {canRequestApproval && (
              <RequestApprovalAction
                reimbursement={reimbursement}
                isFastApprovePending={isFastApprovePending}
                isSubmitForApprovalPending={isSubmitForApprovalPending}
                onCycleReimbursments={onCycleReimbursments}
                getExpenses={getExpenses}
                onSubmitForApproval={onSubmitForApproval}
              />
            )}
            <Grid
              gap="space8"
              templateColumns={`repeat(auto-fit, minmax(${minColumnWidth}px, 1fr))`}
            >
              {canFastApprove && (
                <ApproveNowAction
                  btnVariant="secondary"
                  onFastApprove={onFastApprove}
                  getExpenses={getExpenses}
                  isFastApprovePending={isFastApprovePending}
                  isSubmitForApprovalPending={isSubmitForApprovalPending}
                />
              )}
              <Button
                color="blue"
                variant="secondary"
                disabled={
                  isReimbursementUpdatePending ||
                  isFastApprovePending ||
                  isSubmitForApprovalPending
                }
                onClick={() => setIsSendBackFormVisible(true)}
              >
                <TruncatedText>{t(backTextKey)}</TruncatedText>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

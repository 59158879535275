import { ComponentProps, forwardRef, useRef } from 'react';
import {
  AriaGridListItemOptions,
  mergeProps,
  useFocusRing,
  useGridListItem,
  useGridListSelectionCheckbox,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { ListState } from 'react-stately';
import { Checkbox } from '../../Atoms/Checkbox';
import { Flex, FlexProps } from '../../Atoms/Flex';
import styles from './ListItem.module.css';

interface ListItemProps {
  item: AriaGridListItemOptions['node'];
  state: ListState<any>;
  rowStyles?: Omit<FlexProps<'div'>, 'as' | 'children'>;
}

type ListCheckboxProps = Pick<ListItemProps, 'item' | 'state'> &
  ComponentProps<typeof Checkbox>;

const ListCheckbox = ({ item, state }: ListCheckboxProps) => {
  let { checkboxProps } = useGridListSelectionCheckbox(
    { key: item.key },
    state
  );

  return <Checkbox {...checkboxProps} isListCheckbox />;
};

export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  ({ item, state, rowStyles, ...restProps }, forwardedRef) => {
    const ref = useRef<HTMLLIElement | null>(null);
    const { rowProps, gridCellProps } = useGridListItem(
      { node: item },
      state,
      ref
    );

    const { isFocusVisible, focusProps } = useFocusRing();
    const showCheckbox = state.selectionManager.selectionMode !== 'none';

    return (
      <li
        className={styles.item}
        data-focusvisible={isFocusVisible}
        {...mergeProps(rowProps, focusProps, restProps)}
        ref={mergeRefs([ref, forwardedRef])}
      >
        {showCheckbox ? (
          <Flex {...mergeProps(gridCellProps, rowStyles)}>
            <ListCheckbox item={item} state={state} />
            {item.rendered}
          </Flex>
        ) : (
          <div {...gridCellProps}>{item.rendered}</div>
        )}
      </li>
    );
  }
);

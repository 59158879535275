import { Grid } from '@candisio/design-system';
import { ReactNode } from 'react';

interface SectionLayoutProps {
  headerElement?: ReactNode;
  children?: ReactNode;
  overflow?: 'auto' | 'hidden';
  id?: string;
}

export const SectionLayout = ({
  headerElement,
  children,
  overflow = 'auto',
  id,
}: SectionLayoutProps) => {
  return (
    <Grid
      id={id}
      gap="space8"
      paddingTop="space32"
      height="100%"
      overflowY={overflow}
      templateRows="auto 1fr"
    >
      {headerElement}
      <Grid gap="space10" overflowY="auto">
        {children}
      </Grid>
    </Grid>
  );
};

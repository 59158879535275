import moment from 'moment';

const MIN_HOURS = 8;

export const isLessThan8Hours = (
  startingDate: Date,
  endingDate: Date,
  timeZone: string = 'Europe/Berlin'
) => {
  const startingDateMoment = moment.tz(startingDate, timeZone);
  const endingDateMoment = moment.tz(endingDate, timeZone);

  const millisecondDifference =
    endingDateMoment.valueOf() - startingDateMoment.valueOf();

  const hoursDifference = millisecondDifference / (1000 * 60 * 60);

  if (hoursDifference >= 0 && hoursDifference < MIN_HOURS) {
    return true;
  }

  return false;
};

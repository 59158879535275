import { FilterOptionsAccessor, Grid } from '@candisio/design-system';

import { collectErrorMessages } from 'components/Form/utils';
import { ImportPreviewTable } from 'components/ImportPreviewTable/ImportPreviewTable';
import { ImportPreviewTableData } from 'components/ImportPreviewTable/types';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { WizardModal } from 'components/WizardModal/WizardModal';
import { WizardModalGenerationFooter } from 'components/WizardModal/WizardModalGenerationFooter';
import { WizardModalPreviewFooter } from 'components/WizardModal/WizardModalPreviewFooter';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { LayoutGroup } from 'motion/react';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { zodResolver } from 'utils/zodFormValidation';
import { BOOKING_ACCOUNT_ROUTE_HASH } from '../../consts';
import {
  ERROR_PREVIEW_DELAY,
  PREVIEW_DELAY,
  initialState,
  reducer,
} from './BookingAccountImport.helper';
import {
  SelectFileFormValues,
  errorMessages,
  schema,
} from './BookingAccountImport.schema';
import { ConfirmationModal } from './components/ConfirmationModal';
import { InfoList } from './components/InfoList';
import { SelectFileForm } from './components/SelectFileForm';
import { MAX_DIGITS, MAX_NUMBER_OF_BOOKING_ACCOUNTS } from './consts';
import {
  BookingAccountsWithoutAccountNumberError,
  BookingAccountsWithoutNameError,
  CSVMaxBookingAccountsError,
} from './errors';
import {
  BOOKING_ACCOUNT_IMPORT_ACTION,
  FilterState,
  ImportSteps,
} from './types';
import {
  ParsedBookingAccount,
  parseBookingAccountCSV,
} from './utils/parseBookingAccountCSV';
import { useCheckDuplicates } from './utils/useCheckDuplicates';
import { useImportBookingAccounts } from './utils/useImportBookingAccounts';

export const BookingAccountImport = ({
  errorPreviewDelay = ERROR_PREVIEW_DELAY,
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.BOOKING_ACCOUNTS);
  const { success, error } = useToastMessage();
  const [importBookingAccounts, { loading: importing }] =
    useImportBookingAccounts();

  const validateBasedOffIntegrationFF = useCandisFeatureFlags(
    FEATURE_FLAGS.validateBookingAccountBaseOffIntegration
  );

  const integrationName = useIntegrationSettings();

  const { markDuplicateBookingAccounts } = useCheckDuplicates();

  const [state, updateState] = useReducer(reducer, initialState);
  const history = useHistory();
  const visible = history.location.hash === BOOKING_ACCOUNT_ROUTE_HASH.import;

  const {
    handleSubmit,
    reset,
    clearErrors,
    control,
    watch,
    setError,
    formState,
  } = useForm<SelectFileFormValues>({
    defaultValues: {
      maxDigitsGeneralLedger: MAX_DIGITS.FOUR,
    },
    resolver: zodResolver({
      zodSchema: schema,
      errorMessages: errorMessages(t),
      translationNamespace: LOCALE_NAME_SPACE.SETTINGS,
    }),
    mode: 'onSubmit',
  });

  const resetForm = () => {
    reset();
    clearErrors();
    updateState({ action: BOOKING_ACCOUNT_IMPORT_ACTION.RESET });
  };

  const closeModal = () => {
    resetForm();
    history.push({ hash: '' });
  };

  const { isSubmitting, isValid, errors } = formState;
  const collectedErrors = collectErrorMessages(errors);

  const onSubmit = async ({
    file,
    maxDigitsGeneralLedger,
  }: SelectFileFormValues) => {
    if (!file || !maxDigitsGeneralLedger || !isValid) {
      return;
    }

    const fileData = watch('file') as File;

    let parsedBookingAccounts: ParsedBookingAccount[];

    try {
      ({ parsedBookingAccounts } = await parseBookingAccountCSV(
        fileData,
        Number(maxDigitsGeneralLedger),
        validateBasedOffIntegrationFF,
        integrationName
      ));
    } catch (error) {
      switch (true) {
        case error instanceof CSVMaxBookingAccountsError:
          setError('file', {
            message: t(
              'import.fileSelectionStep.validation.fileHasTooManyRecords',
              {
                recordsCountLimit: MAX_NUMBER_OF_BOOKING_ACCOUNTS,
              }
            ),
          });
          break;

        case error instanceof BookingAccountsWithoutAccountNumberError:
          setError('file', {
            message: t(
              'import.fileSelectionStep.validation.fileHasEmptyAccountNumbers'
            ),
          });
          break;

        case error instanceof BookingAccountsWithoutNameError:
          setError('file', {
            message: t('import.fileSelectionStep.validation.fileHasEmptyName'),
          });
          break;
        default:
          setError('file', {
            message: t('import.fileSelectionStep.validation.csvParseError'),
          });
      }

      return;
    }

    parsedBookingAccounts = await markDuplicateBookingAccounts(
      parsedBookingAccounts
    );

    updateState({
      action: BOOKING_ACCOUNT_IMPORT_ACTION.SET_BOOKING_ACCOUNTS,
      payload: {
        parsedBookingAccounts,
        selectedFile: fileData,
      },
    });

    // fake a delay
    // this should not be here. we should trigger the SHOW_PREVIEW action
    // when the animation completes using onAnimationComplete
    await new Promise(resolve => setTimeout(resolve, PREVIEW_DELAY));
    updateState({ action: BOOKING_ACCOUNT_IMPORT_ACTION.SHOW_PREVIEW });
  };

  const handleImport = async () => {
    if (state.parsedBookingAccounts) {
      const { successCount, errorCount } = await importBookingAccounts(
        state.parsedBookingAccounts
          .filter(ba => ba.errors.length === 0)
          .map(({ errors, key, ...ba }) => ba),
        state.selectedFile
      );

      closeModal();

      if (errorCount) {
        error(
          t('import.actions.importedFailure', {
            count: errorCount,
          })
        );
      } else {
        success(
          t('import.actions.importedSuccess', {
            count: successCount,
          })
        );
      }
    }
  };

  const [defaultFilters, setDefaultFilters] =
    useState<{ id: string; value: string[] | undefined }[]>();

  const onUpdateFilter = (filters: FilterState) => {
    updateState({
      action: BOOKING_ACCOUNT_IMPORT_ACTION.UPDATE_FILTERS,
      payload: {
        filters,
      },
    });

    setDefaultFilters([
      {
        id: 'generalLedgerAccountsNumber',
        value: filters.accountCode ?? undefined,
      },
    ]);
  };

  const canImport = state.parsedBookingAccounts.some(
    ({ errors }) => errors.length === 0
  );

  const organization = useOrganizationId();

  const bookingAccountsImportData: ImportPreviewTableData[] = (
    state.parsedBookingAccounts ?? []
  ).map(item => {
    return {
      id: item.key,
      generalLedgerAccountsNumber: {
        value: item.accountCode,
        errors: item.errors,
      },
      generalLedgerAccountsName: { value: item.name, errors: item.errors },
    };
  });

  const filterOptions: FilterOptionsAccessor<ImportPreviewTableData> = {
    generalLedgerAccountsNumber: {
      data: bookingAccountsImportData
        .filter(({ generalLedgerAccountsNumber }) =>
          Boolean(generalLedgerAccountsNumber?.value)
        )
        .map(item => ({
          id: item.generalLedgerAccountsNumber?.value ?? '',
          label: item.generalLedgerAccountsNumber?.value ?? '',
        })),
    },
    generalLedgerAccountsName: {
      data: bookingAccountsImportData.map(item => ({
        id: item.generalLedgerAccountsName?.value ?? '',
        label: item.generalLedgerAccountsName?.value ?? '',
      })),
    },
  };

  return (
    <WizardModal
      onClose={closeModal}
      isOpen={visible}
      steps={[t('import.steps.selectFile'), t('import.steps.preview')]}
      currentIndex={state.importStep}
      title={t('import.title')}
      footer={
        (isSubmitting ||
          collectedErrors.length > 0 ||
          state.importStep > 0) && (
          <LayoutGroup>
            {state.importStep === ImportSteps.SelectFile ? (
              <WizardModalGenerationFooter
                canGoBack={!isSubmitting}
                onGoBack={resetForm}
              />
            ) : (
              <WizardModalPreviewFooter
                canImport={canImport}
                importing={importing}
                onGoBack={() => {
                  updateState({
                    action: BOOKING_ACCOUNT_IMPORT_ACTION.RESET,
                  });
                }}
                onImport={() => {
                  updateState({
                    action: BOOKING_ACCOUNT_IMPORT_ACTION.SHOW_CONFIRMATION,
                  });
                }}
                infoList={
                  <InfoList
                    bookingAccounts={state.parsedBookingAccounts}
                    onUpdateFilter={onUpdateFilter}
                    errors={collectedErrors}
                  />
                }
              />
            )}
          </LayoutGroup>
        )
      }
    >
      {state.importStep === ImportSteps.SelectFile && (
        <Grid as="form" onSubmit={handleSubmit(onSubmit)} gap="space16">
          <SelectFileForm
            onUpdateFilter={onUpdateFilter}
            bookingAccounts={state.parsedBookingAccounts}
            showSummary={state.showSummary}
            errorPreviewDelay={errorPreviewDelay}
            control={control}
            isSubmitting={isSubmitting}
            errors={collectedErrors}
            disabledButton={!isValid}
          />
        </Grid>
      )}
      {state.importStep === ImportSteps.Preview && (
        <ImportPreviewTable
          data={bookingAccountsImportData}
          columns={['generalLedgerAccountsName', 'generalLedgerAccountsNumber']}
          defaultFilters={defaultFilters}
          filterOptions={filterOptions}
        />
      )}
      <ConfirmationModal
        visible={state.showConfirmation}
        importing={importing}
        successCount={
          state.parsedBookingAccounts.filter(ba => !ba.errors.length).length
        }
        organizationName={organization ?? ''}
        handleImport={handleImport}
        onClose={() => {
          updateState({
            action: BOOKING_ACCOUNT_IMPORT_ACTION.RESET,
          });
        }}
      />
    </WizardModal>
  );
};

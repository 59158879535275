import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import {
  PromotionWrapper,
  TableWrapper,
  Wrapper,
} from 'components/ProductPromotions/Wrapper';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { ExportPaymentConditionsTable } from 'views/Integrations/Export/elements/table/paymentConditions/PaymentConditionsTable';
import { PaymentConditionsTableData } from 'views/Integrations/Export/elements/table/paymentConditions/types';
import { useGetCustomPromotionDetails } from '../hooks/useGetCustomPromotionDetails';

export const ProvisionsPromotionCoreDataPaymentConditionTable = ({
  data,
}: { data: PaymentConditionsTableData }) => {
  const { provisionsPromotion } = usePromotionContent();

  const customPromotionDetails = useGetCustomPromotionDetails(
    'datevSelfBooker',
    PROVISIONS_UTM_SOURCE.EXPORT_CORE_DATA_PAYMENT_CONDITIONS
  );

  return (
    <Wrapper>
      <TableWrapper>
        <ExportPaymentConditionsTable
          data={data}
          hasWarning={false}
          isMasked={false}
          showAll={false}
        />
      </TableWrapper>
      <PromotionWrapper width="34rem" height="24rem">
        <PromotionsProvider promotions={[provisionsPromotion]}>
          <PromotionCard
            promotion={provisionsPromotion}
            closable={false}
            customPromotionDetails={customPromotionDetails}
            trackOption={{
              eventLocation:
                PROVISIONS_UTM_SOURCE.EXPORT_CORE_DATA_PAYMENT_CONDITIONS,
            }}
          />
        </PromotionsProvider>
      </PromotionWrapper>
    </Wrapper>
  );
};

import { Box, Flex } from '@candisio/design-system';

interface PaginationDotsProps {
  length: number;
  currentIndex: number;
  onClick: (index: number) => void;
}

export const PaginationDots = ({
  length,
  currentIndex,
  onClick,
}: PaginationDotsProps) => {
  return (
    <Flex gap="space10" justifyContent="center">
      {Array.from({ length }, (_, i) => i + 1).map((_, index) => (
        <Box
          as="button"
          key={index}
          width="space12"
          height="space12"
          borderRadius="full"
          background={currentIndex === index ? 'blue500' : 'gray300'}
          cursor="pointer"
          border="none"
          padding="0"
          onClick={() => onClick(index)}
        />
      ))}
    </Flex>
  );
};

import {
  Box,
  Flex,
  Icon,
  Tooltip,
  mergeProps,
  mergeRefs,
  useTooltip,
} from '@candisio/design-system';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { CountDisplay } from './CountDisplay/CountDisplay';
import { IconLabel } from './IconLabel/IconLabel';
import { LinkWrapper } from './LinkWrapper/LinkWrapper';
import { IconLinkProps } from './types';

export const IconLink = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  IconLinkProps
>(
  (
    {
      icon,
      avatar,
      as: Component,
      size = 'space20',
      count,
      countLoading = false,
      label = '',
      showLabelInTooltip = true,
      expanded = false,
      to,
      description,
      isActive,
      pulseIndicator,
      'aria-label': ariaLabel,
      children,
      height,
      ...restProps
    }: IconLinkProps,
    ref
  ) => {
    const { isOpen, triggerProps, triggerRef, tooltipProps, tooltipRef } =
      useTooltip({
        placement: 'right',
        passiveTrigger: true,
        delay: 0,
      });

    const LinkOrButton = Component === 'button' ? 'button' : Link;

    const showLabelTooltip = !expanded && showLabelInTooltip;

    const getGapValue = () => {
      if (icon) return 'space12';
      if (avatar) return 'space2';
    };

    return (
      <>
        <Flex alignItems="center">
          <LinkWrapper
            as={LinkOrButton}
            padding={avatar ? 'space2' : 'space10 space12'}
            isActive={isActive}
            gap={count || pulseIndicator || children ? 'space8' : undefined}
            width={expanded ? '100%' : 'fit-content'}
            height={height}
            to={to}
            aria-label={ariaLabel ?? label}
            ref={mergeRefs([triggerRef, ref])}
            {...mergeProps(triggerProps, restProps)}
          >
            <Flex
              alignItems="center"
              gap={getGapValue()}
              justifyContent="center"
            >
              {icon && <Icon icon={icon} size={size} flexShrink={0} />}
              {avatar}
              {expanded && (
                <IconLabel
                  expanded={expanded}
                  label={label}
                  ariaLabel={ariaLabel}
                  description={description}
                />
              )}
            </Flex>

            {expanded && (
              <Flex alignItems="center" gap="space4" justifyContent="center">
                {children}
                <CountDisplay count={count} countLoading={countLoading} />
                {pulseIndicator && <Box>{pulseIndicator}</Box>}
              </Flex>
            )}

            {!expanded && (
              <>
                {children}
                <CountDisplay count={count} countLoading={countLoading} />
              </>
            )}
          </LinkWrapper>
          {!expanded && pulseIndicator}
        </Flex>
        {isOpen && label && showLabelTooltip && (
          <Tooltip ref={tooltipRef} {...tooltipProps}>
            {label}
          </Tooltip>
        )}
      </>
    );
  }
);

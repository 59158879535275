import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Paragraph,
  Text,
  useTheme,
} from '@candisio/design-system';
import logoCandis from 'assets/logo-white.svg';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const MotionGrid = motion.create(Grid);
const MotionBox = motion.create(Box);

const modalTransition = { duration: 0.35, type: 'tween' };

export const IntercomPrivacyModalContent = ({
  handlePrivacyModalOpen,
  handleIntercomToggle,
}: {
  handlePrivacyModalOpen: () => void;
  handleIntercomToggle: () => void;
}) => {
  const { space } = useTheme();
  const [tCommon] = useTranslation(LOCALE_NAME_SPACE.COMMON);
  const [tConsentManager] = useTranslation(LOCALE_NAME_SPACE.CONSENT_MANAGER);

  return (
    <>
      <MotionGrid
        padding="30px 28px 120px"
        gap="space18"
        initial={{ translateY: space.space36 }}
        animate={{ translateY: 0, transition: modalTransition }}
      >
        <img src={logoCandis} alt={tCommon('pageTitle')} />
        <Text
          as="h3"
          fontSize={space.space36}
          lineHeight="space32"
          color="white"
          whiteSpace="pre-line"
        >
          <Trans
            ns={LOCALE_NAME_SPACE.CONSENT_MANAGER}
            i18nKey="intercomModal.heading"
          >
            Hello
            <Text
              fontSize="xxlarge"
              lineHeight="paragraph"
              style={{ marginTop: space.space8, display: 'block' }}
            >
              Our conversation is unfortunately paused! ⏸
            </Text>
          </Trans>
        </Text>
      </MotionGrid>
      <Flex background="white" paddingY={0} paddingX="28px" overflow="visible">
        <MotionBox
          borderRadius="small"
          boxShadow="0 8px 16px rgba(0, 0, 0, 0.16)"
          background="white"
          bottom="space48"
          position="relative"
          padding="22px 28px 26px"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: modalTransition }}
        >
          <Grid gap="space20">
            <Text
              as="h4"
              fontSize="basic"
              lineHeight="heading"
              fontWeight="bold"
              data-cy="intercom-title"
            >
              {tConsentManager('intercomModal.title')}
            </Text>
            <Paragraph whiteSpace="pre-line">
              <Trans
                ns={LOCALE_NAME_SPACE.CONSENT_MANAGER}
                i18nKey="intercomModal.paragraph"
              >
                The intercom cookie enables you to communicate with us via live
                chat. To see what exactly this cookie does and to disable it
                please visit the
                <Link
                  as="button"
                  onClick={handlePrivacyModalOpen}
                  data-cy="intercom-link-button"
                >
                  privacy settings
                </Link>
                .
              </Trans>
            </Paragraph>
            <Button size="medium" onClick={handleIntercomToggle}>
              {tConsentManager('intercomModal.buttonLink')}
            </Button>
          </Grid>
        </MotionBox>
      </Flex>
    </>
  );
};

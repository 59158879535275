import {
  Grid,
  Image,
  Item,
  ListView,
  ScrollBox,
  Text,
  useTheme,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CardHolderCCInsightsWidgetData } from 'views/CreditCards/hooks/useTransactionsInsights';
import emptyPlaceholder from '../media/empty-list.svg';
import { CardHolderListItem } from './CardholderListItem';
import { DateOptionMissingInvoicesWidget, DateRange } from './utils';

interface CardHoldersListProps {
  mappedCardholdersCCInsights: CardHolderCCInsightsWidgetData[];
  dateRange: DateRange;
  dateOption: DateOptionMissingInvoicesWidget;
}

export const CardHoldersList = ({
  dateRange,
  mappedCardholdersCCInsights,
  dateOption,
}: CardHoldersListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { colors } = useTheme();
  if (mappedCardholdersCCInsights.length === 0) {
    return (
      <Grid placeContent="center" height="100%" gap="space8">
        <Image
          src={emptyPlaceholder}
          alt="empty-list-placeholder"
          justifySelf="center"
        />
        <Text whiteSpace="pre-wrap" textAlign="center" fontSize="basic">
          {t('insights.missingInvoicesWidget.emptyState')}
        </Text>
      </Grid>
    );
  }

  return (
    <ScrollBox borderBottomRadius="basic" height="100%" background="gray50">
      <ListView style={{ background: colors.gray50 }}>
        {mappedCardholdersCCInsights.map(mappedCardholder => (
          <Item
            key={mappedCardholder.cardholder.id}
            textValue={mappedCardholder.cardholder.id}
          >
            <CardHolderListItem
              dateRange={dateRange}
              dateOption={dateOption}
              cardholder={mappedCardholder.cardholder}
              invoicesWithoutTransaction={
                mappedCardholder.invoicesWithoutTransaction
              }
            />
          </Item>
        ))}
      </ListView>
    </ScrollBox>
  );
};

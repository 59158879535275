import {
  Box,
  Card,
  Flex,
  Grid,
  Icon,
  Tag,
  TruncatedText,
} from '@candisio/design-system';
import {
  ReimbursementCaseStatus,
  ReimbursementItemStatus,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { amountFormatNonNull } from 'utils/format';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useReimbursementSplitBookingHelpers } from 'views/Reimbursement/context/ReimbursementSplitBookingsContext';
import styles from './ReimbursementItemsSummary.module.css';
import clsx from 'clsx';

export type ReimbursementItem = 'hospitality' | 'general' | 'perDiem';

interface ReimbursementItemLayoutProps {
  totalAmount: number | null | undefined;
  reason: string | null | undefined;
  reimbursementItemType: ReimbursementItem;
  expenseId: string;
  reimbursementStatus?: ReimbursementCaseStatus;
  itemStatus?: ReimbursementItemStatus;
  isExcluded: boolean;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  footer: ReactNode;
  children?: ReactNode;
}

const gradientBorderStyle = {
  border: '2px solid transparent',
  background: `
          linear-gradient(transparent, transparent) padding-box,
          linear-gradient(180deg, blue300 0%, transparent 50%) border-box
        `,
};

export const mappedTypesToItem: Record<
  ReimbursementItem,
  { placeholder: string; icon: JSX.Element }
> = {
  general: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.general',
    icon: (
      <div className={clsx(styles.icon, styles.green)}>
        <Icon icon="receipt" size="space18" />
      </div>
    ),
  },
  hospitality: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality',
    icon: (
      <div className={clsx(styles.icon, styles.pink)}>
        <Icon icon="hospitality" size="space18" />
      </div>
    ),
  },
  perDiem: {
    placeholder: 'reimbursementView.middleSection.form.perDiem.title',
    icon: (
      <div className={clsx(styles.icon, styles.blue)}>
        <Icon icon="calendarDays" size="space18" />
      </div>
    ),
  },
};

export const ReimbursementItemLayout = ({
  totalAmount,
  reimbursementItemType,
  reason,
  expenseId,
  reimbursementStatus,
  itemStatus,
  isExcluded,
  leftSection,
  footer,
  rightSection,
  children,
}: ReimbursementItemLayoutProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { itemRefs } = useReimbursementFormsContext();
  const { drawerView, activeBooking } = useReimbursementSplitBookingHelpers();

  const formattedAmount = totalAmount
    ? amountFormatNonNull(totalAmount, 'EUR')
    : amountFormatNonNull(0, 'EUR');

  const expense = mappedTypesToItem[reimbursementItemType];

  const title = reason ? reason : t(expense.placeholder);

  const showExportStatusInfo =
    reimbursementStatus === ReimbursementCaseStatus.PartiallyExported &&
    itemStatus === ReimbursementItemStatus.ReadyToExport;

  const shouldAddHighlight =
    drawerView === 'split-bookings' &&
    activeBooking.reimbursementItemId === expenseId;

  const renderSectionContent = () => {
    if (children) {
      return children;
    }

    return (
      <div className="grid grid-cols-[1fr_1.5fr] gap-4 px-6">
        {leftSection}
        {rightSection}
      </div>
    );
  };

  return (
    <Box
      as="form"
      id={expenseId}
      data-testid={`expense-${reimbursementItemType}`}
      ref={(elm: HTMLFormElement) => {
        if (itemRefs?.current) {
          itemRefs.current[expenseId] = elm;
        }
      }}
      borderRadius="medium"
      {...(shouldAddHighlight && gradientBorderStyle)}
    >
      <Card
        background="gray0"
        padding="space16 space24"
        borderBottom="1px solid gray250"
        borderBottomRadius="none"
      >
        {showExportStatusInfo && (
          <Tag variant="secondary" color="red" className="justify-self-end">
            {t('splitBookings.notExported')}
          </Tag>
        )}
        <Grid gap="space4">
          <Grid templateColumns="2fr 1fr" gap="space16" alignItems="center">
            <Flex gap="space8" alignItems="center">
              {expense.icon}
              <TruncatedText fontSize="xxlarge" height="fit-content">
                {title}
              </TruncatedText>
            </Flex>
            <Box justifySelf="end" fontSize="large">
              <TruncatedText
                fontSize="large"
                fontWeight="semibold"
                textDecoration={isExcluded ? 'line-through' : undefined}
              >
                {formattedAmount}
              </TruncatedText>
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Card borderTopRadius="none" paddingY="space24" paddingX={0}>
        <div className="grid gap-4">
          {renderSectionContent()}
          {footer}
        </div>
      </Card>
    </Box>
  );
};

import { TimelineEventWithMobileIndication } from 'components/DocumentHistory/history-components/TimelineEventWithMobileIndication';
import closeImage from 'components/DocumentHistory/history-components/images/iconApproveNot.svg';
import {
  CommentView,
  ItemApprovalStatus,
  RejectedIcon,
} from 'components/DocumentHistory/styles';
import { ReimbursementCaseAmendmentRequestedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ApprovalStatus } from 'views/Inbox/models';

export const ReimbursementCaseAmendmentRequested = ({
  properties,
}: ReimbursementCaseAmendmentRequestedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  return (
    <TimelineEventWithMobileIndication
      iconName="mobileComment"
      eventComponent={
        <CommentView>
          <ItemApprovalStatus status={ApprovalStatus.DECLINED}>
            <RejectedIcon src={closeImage} alt={ApprovalStatus.DECLINED} />
            {t('timeline.sentBackToEmployee')}
          </ItemApprovalStatus>
          {properties.comment?.text}
        </CommentView>
      }
    />
  );
};

import { Icon, IconProps } from '@candisio/design-system';

export type DefaultIconProps = Omit<IconProps, 'icon'>;

export const WarningIcon = (props: DefaultIconProps) => {
  return <Icon icon="warning" color="yellow700" {...props} />;
};

export const ErrorIcon = (props: DefaultIconProps) => {
  return <Icon icon="failCircle" color="red700" {...props} />;
};

export const CheckmarkIcon = (props: DefaultIconProps) => {
  return <Icon icon="checkCircle" color="green700" {...props} />;
};

export const InfoIcon = (props: DefaultIconProps) => {
  return <Icon icon="infoCircle" color="blue700" {...props} />;
};

export const ClockIcon = (props: DefaultIconProps) => {
  return <Icon icon="clock" color="red700" {...props} />;
};

import { appHistory } from 'router';
import {
  getRouteInfoFromLocation,
  sanitizePathname,
} from 'router/segmentLocationListener';
import { CandisPage, CandisTrackedPage, Payload } from './types';

export const sourceMiddleware = ({
  payload,
  next,
}: {
  payload: Payload;
  next: Function;
}) => {
  const { properties, context, type } = payload.obj;
  const location = appHistory.location;

  const { category, sanitizedPathname, organization } = sanitizePathname(
    location.pathname
  );

  const routeInfo = getRouteInfoFromLocation({
    organizationSlug: organization,
    location,
  });

  if (type === 'track') {
    const page: CandisPage = {
      page_name: routeInfo?.path ?? undefined,
      page_url: context.page.url,
      page_category: category,
      page_path: sanitizedPathname,
      page_search: context?.page?.search,
      page_referrer: context?.page?.referrer,
      page_pathname: location.pathname,
    };

    payload.obj.properties = {
      ...properties,
      ...page,
      platform: 'web',
    };
  } else if (type === 'page') {
    const {
      name,
      url,
      category,
      path,
      search,
      referrer,
      title,
      pathname,
      ...otherProperties
    } = payload.obj.properties as CandisTrackedPage;

    const page: CandisPage = {
      page_name: name,
      page_url: url,
      page_category: category,
      page_path: path,
      page_search: search,
      page_referrer: referrer,
      page_pathname: pathname,
    };

    payload.obj.properties = {
      ...otherProperties,
      ...page,
      platform: 'web',
    };
  }

  next(payload);
};

import { Box } from '@candisio/design-system';
import { CreditCardsDetailsDrawer } from '../CreditCardDetailsDrawer';
import { CreditCardsIssueNewCardModal } from '../CreditCardsInsights/CreditCardsIssueNewCardModal';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { CreditCardsTableContainer } from './CreditCardsTableContainer';

export const CardholderOverview = () => {
  return (
    <CreditCardsProvider>
      <Box height="100%" overflow="hidden">
        <CreditCardsTableContainer />
      </Box>
      <CreditCardsIssueNewCardModal />
      <CreditCardsDetailsDrawer />
    </CreditCardsProvider>
  );
};

import {
  DocumentCurrency,
  DocumentWithIncompletePaymentData,
  Money,
  PayableDocument,
  PayableDocumentEdge,
  PayableDocumentEsEdge,
  SepaExportableDocument,
} from 'generated-types/graphql.types';

export const mapPayableDocumentEsEdgeToPayableDocumentEdge = (
  documents: PayableDocumentEsEdge[]
): PayableDocumentEdge[] => {
  if (!documents.length) {
    return [];
  }
  const mappedDocuments: PayableDocumentEdge[] = documents.map(document => {
    const mappedDocument: PayableDocumentEdge = {
      cursor: document.cursor,
      node: {
        ...document.node,
        currency: document.node.amount?.currency as DocumentCurrency,
        amount: document.node.amount as Money,
        contact: {
          name: {
            value: document.node.contact?.name ?? '',
            confidence: 0,
          },
          id: document.node.contact?.id ?? '',
        },
        status: document.node.status,
        invoiceDate: document.node.invoiceDate as string,
        invoiceDueDate: document.node.invoiceDueDate as string,
      } satisfies PayableDocument,
    };
    if (document.node.isSEPAExportable) {
      mappedDocument.node = {
        ...mappedDocument.node,
        discountAmount: document.node.discountAmount,
        discountPaymentDate: document.node.discountPaymentDate,
        discountPercentage: document.node.discountPercentage,
        creditorIban: document.node.iban ?? '',
        __typename: 'SepaExportableDocument',
      } as SepaExportableDocument;
    } else {
      mappedDocument.node = {
        ...mappedDocument.node,
        __typename: 'DocumentWithIncompletePaymentData',
      } as DocumentWithIncompletePaymentData;
    }
    return mappedDocument;
  });
  return mappedDocuments;
};

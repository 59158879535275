import { Box, Menu, Text, Tooltip, useTooltip } from '@candisio/design-system';
import { useUploadDocumentOptions } from 'components/UploadDocumentMenuButton';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useEcm } from 'orgConfig/ecm/useEcm';
import {
  useDocumentUploadDispatch,
  useDocumentUploadState,
} from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentUploadModal } from 'views/AppContainer/components/Header/components/DocumentUploadModal/DocumentUploadModal';
import { NavigationIcon } from '../../NavigationIcon';
import { useUserRoles } from 'hooks/useUserRoles';

export const MenuUploadDocument = ({ expanded }: { expanded: boolean }) => {
  const { showEcmSensitiveDocuments } = useEcm();
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const showUploadDocumentMenuFF = useCandisFeatureFlags(
    FEATURE_FLAGS.wipShowUploadDocumentMenuIcon
  );
  const { modalVisibility } = useDocumentUploadState();

  const { isAdmin, isAccountant, isRequester } = useUserRoles();

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef, close } =
    useTooltip({
      placement: 'right top',
      passiveTrigger: true,
      closeDelay: 500,
    });

  const uploadOptions = useUploadDocumentOptions(close);

  const dispatch = useDocumentUploadDispatch();

  const openUploadDocumentModal = () => {
    dispatch('showModal');
  };

  if (!showUploadDocumentMenuFF) return null;

  if (!showEcmSensitiveDocuments) {
    return (
      <>
        <NavigationIcon
          icon="menuUpload"
          label={t('menuLabels.upload')}
          isActive={modalVisibility}
          onClick={openUploadDocumentModal}
          showLabelInTooltip={true}
          showMenuIcon={isAdmin || isRequester || isAccountant}
          expanded={expanded}
        />
        <DocumentUploadModal />
      </>
    );
  }

  return (
    <>
      <NavigationIcon
        icon="menuUpload"
        label={t('menuLabels.upload')}
        ref={triggerRef}
        isActive={isOpen || modalVisibility}
        showLabelInTooltip={false}
        showMenuIcon={isAdmin || isRequester || isAccountant}
        expanded={expanded}
        data-cy="nav-item-menuUpload"
        {...triggerProps}
      />

      {isOpen && !modalVisibility && (
        <Tooltip
          {...tooltipProps}
          ref={tooltipRef}
          paddingX={0}
          paddingY={0}
          arrowStyle={{ display: 'none' }}
        >
          <>
            {!expanded && (
              <Box padding="space16 space16 0 space16">
                <Text color="gray450">{t('menuLabels.upload')}</Text>
              </Box>
            )}
            <Menu
              items={uploadOptions}
              style={expanded ? undefined : { paddingTop: '4px' }}
            />
          </>
        </Tooltip>
      )}
      <DocumentUploadModal />
    </>
  );
};

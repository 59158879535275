import { Swimlane } from 'components/Swimlane/Swimlane';
import { SwimlaneHeader } from 'components/Swimlane/SwimlaneHeader';
import { SwimlaneHeaderCount } from 'components/Swimlane/SwimlaneHeaderCount';
import { SwimlaneTiles } from 'components/Swimlane/SwimlaneTiles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ArchiveReimbursementCase } from 'views/Reimbursement/hooks/useReimbursementDashboardInsights';
import { ReimbursementTileCreate } from './content/ReimbursementTileCreate';
import { ReimbursementTileShowAll } from './content/ReimbursementTileShowAll';
import { SwimlaneTileExpenseContent } from './content/SwimlaneTileExpenseContent';
import { PromoExpenseMobile } from './promos/PromoExpenseMobile';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';

export const ReimbursementDraftSwimlane = ({
  draftReimbursementCases,
  isVisible,
  totalCount,
}: {
  draftReimbursementCases?: ArchiveReimbursementCase[];
  isVisible: boolean;
  totalCount?: number;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { isPromotionSeen, setPromotionIsSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.EXPENSE_MOBILE
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Swimlane data-cy="draftSwimlane">
      <SwimlaneTiles
        actionTileCount={1}
        contentTileCount={totalCount}
        promoTileCount={!isPromotionSeen ? 2 : undefined}
      >
        {({
          contentTileVisibleCount,
          isShowAllTileVisible,
          isPromoTileVisible,
        }) => {
          return (
            <>
              <SwimlaneHeader
                icon="walletPen"
                label={t('dashboard.swimlanes.draft')}
              >
                <SwimlaneHeaderCount
                  count={contentTileVisibleCount}
                  totalCount={totalCount}
                />
              </SwimlaneHeader>
              <ReimbursementTileCreate />
              {draftReimbursementCases &&
                draftReimbursementCases.length > 0 &&
                Array.from({ length: contentTileVisibleCount }, (_, index) => (
                  <SwimlaneTileExpenseContent
                    key={draftReimbursementCases[index].id}
                    expenseCase={draftReimbursementCases[index]}
                  />
                ))}
              {isShowAllTileVisible && (
                <ReimbursementTileShowAll status="draft" />
              )}

              {isPromoTileVisible && !isPromotionSeen && (
                <PromoExpenseMobile onClose={setPromotionIsSeen} />
              )}
            </>
          );
        }}
      </SwimlaneTiles>
    </Swimlane>
  );
};

import { IconName } from '@candis/icons';
import { Icon } from '@candisio/design-system';
import clsx from 'clsx';
import { HTMLProps } from 'react';
import styles from './SwimlaneHeader.module.css';

type SwimlaneProps = HTMLProps<HTMLDivElement> & {
  icon: IconName;
  label: string;
};

export const SwimlaneHeader = (props: SwimlaneProps) => {
  const { children, className, icon, label, ...otherProps } = props;

  return (
    <div className={clsx(styles.header, className)} {...otherProps}>
      <Icon className={styles.icon} icon={icon} size="" />
      <span className={styles.label}>{label}</span>
      {children}
    </div>
  );
};

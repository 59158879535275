import { Badge, useTheme } from '@candisio/design-system';
import clsx from 'clsx';
import { ToggleButtonItem } from 'components/ToggleButtonGroup/ToggleButton';
import { ReactNode, useMemo } from 'react';
import styles from 'components/ToggleButtonGroup/ToggleButtonGroup.module.css';

interface ApprovalViewToggleButtonProps {
  id: string;
  count: number;
  children: ReactNode;
  isSelected: boolean; // @TODO: replace with css selecting aria data attribute
  // after Badge is migrated to new styling approach
}

export const ApprovalViewToggleButton = ({
  id,
  count,
  children,
  isSelected,
}: ApprovalViewToggleButtonProps) => {
  const { colors } = useTheme();

  const className = clsx(styles['toggle-button'], 'flex items-center gap-2');

  const badgeStyles = useMemo(
    () => ({
      background: isSelected ? colors.gray800 : colors.gray300,
      color: isSelected ? 'white' : colors.gray800,
      border: 'none',
    }),
    [isSelected, colors]
  );

  return (
    <ToggleButtonItem id={id} className={className}>
      {children}
      {(count ?? 0) > 0 && <Badge style={badgeStyles}>{count}</Badge>}
    </ToggleButtonItem>
  );
};

import {
  Flex,
  Grid,
  Image,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import candisPhysicalCardLogo from 'assets/credit-cards/candis-physical.svg';
import candisVirtualCardLogo from 'assets/credit-cards/candis-virtual.svg';
import candisVirtualX1CardLogo from 'assets/credit-cards/candis-virtual1x.svg';
import pliantVirtualX1CardLogo from 'assets/credit-cards/insights-dashboard/1xvitual-pliant.svg';
import pliantBlueCardLogo from 'assets/credit-cards/insights-dashboard/pliant-blue-card.svg';
import pliantVirtualCardLogo from 'assets/credit-cards/insights-dashboard/vitual-pliant.svg';
import { CardBrand, CardType } from 'generated-types/graphql.types';
import { Row } from 'react-table';
import { CreditCardsTableData } from 'views/CreditCards/types';

export const cardTypes = {
  [CardType.Virtual]: {
    candis: candisVirtualCardLogo,
    pliant: pliantVirtualCardLogo,
  },
  [CardType.SingleUse]: {
    candis: candisVirtualX1CardLogo,
    pliant: pliantVirtualX1CardLogo,
  },
  [CardType.Physical]: {
    candis: candisPhysicalCardLogo,
    pliant: pliantBlueCardLogo,
  },
  [CardType.Black]: {
    candis: candisPhysicalCardLogo,
    pliant: pliantBlueCardLogo,
  },
};

interface CardNumberCellProps {
  value: string | null;
  row: Row<CreditCardsTableData>;
}

export const CardNumberCell = ({ value, row }: CardNumberCellProps) => {
  const label = row.original.cardLabel;
  const brand = row.original.brand;
  const type = row.original.cardType;
  const purpose = row.original.purpose;

  if (!brand) {
    return null;
  }

  const displayedLabel = label ?? '-';
  const displayedPrupose = purpose ?? '-';

  const isCandisBranded = brand === CardBrand.Candis;

  return (
    <Grid
      autoFlow="column"
      gap="space12"
      height="44px"
      alignItems="center"
      justifyContent="start"
    >
      <Image
        src={isCandisBranded ? cardTypes[type].candis : cardTypes[type].pliant}
        height="40px"
        alt=""
      />
      <Flex direction="column">
        <Text>{value ?? '••••'}</Text>
        <TruncatedText wordBreak="break-word" color="gray500">
          {type === CardType.SingleUse ? displayedPrupose : displayedLabel}
        </TruncatedText>
      </Flex>
    </Grid>
  );
};

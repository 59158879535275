import { Suspense, lazy } from 'react';

const GraphiQLEditor = lazy(async () => {
  const module = await import('views/Settings/GraphiQL');

  return { default: module.GraphiQLEditor };
});

export const GraphiQL = () => {
  return (
    <Suspense fallback={null}>
      <GraphiQLEditor />
    </Suspense>
  );
};

import gql from 'graphql-tag';

export const reimbursementListDataFragment = gql`
  fragment ReimbursementListData on ReimbursementCase {
    id
    createdAt
    title
    status
    targetMembership {
      id
      firstName
      lastName
      avatarUrl
    }
    totalAmount {
      amount
      currency
      precision
    }
  }
`;

export const inboxReimbursementsQuery = gql`
  query inboxReimbursementCases(
    $search: String
    $sorts: ReimbursementCasesSortInput
    $filters: ReimbursementCasesFilterInput
    $limit: Int
    $next: String
    $searchInput: SearchInput
  ) {
    inboxReimbursementCases(
      search: $search
      sorts: $sorts
      filters: $filters
      limit: $limit
      next: $next
      searchInput: $searchInput
    ) {
      pageInfo {
        totalCount
        hasNextPage
        endCursor: nextPageCursor
      }
      edges {
        cursor
        node {
          id
          createdAt
          firstSubmittedForReviewAt
          title
          status
          targetMembership {
            id
            firstName
            lastName
            avatarUrl
          }
          totalAmount {
            amount
            currency
            precision
          }
        }
      }
    }
  }
`;

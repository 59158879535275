import {
  FileUploadAttempt,
  FileUploadAttemptStatus,
} from 'generated-types/graphql.types';
import { useGetFileUploadAttempts } from '../useGetFileUploadAttempts';

export const processingStates = [
  FileUploadAttemptStatus.DocumentClassified,
  FileUploadAttemptStatus.DocumentCreated,
  FileUploadAttemptStatus.Pending,
];

export const getHasProcessingState = (data?: FileUploadAttempt[]) => {
  return data?.some(item => processingStates.includes(item.status)) ?? false;
};

export const useHasProcessingState = () => {
  const { data } = useGetFileUploadAttempts();
  return getHasProcessingState(data);
};

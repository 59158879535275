import { i18n } from 'providers/LocaleProvider';

type AmountFormatOptions = {
  // if the amount is provided in cents for example, the flag convertToBasicMonetaryUnit
  // will convert 1000 Cent to 10.00 EUR
  convertToBasicMonetaryUnit?: boolean;
  locale?: string;
  // this will render the amount without decimal places (e.g. 18.00EUR -> 18EUR)
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};

export const toBasicMonetaryUnit = (
  currencyDefinition: Intl.NumberFormat,
  amount: number
) => {
  const currencyDetails = currencyDefinition.resolvedOptions();
  amount = amount / Math.pow(10, currencyDetails.maximumFractionDigits ?? 2);

  return amount;
};

export const amountFormatNonNull = (
  amount: number,
  currency: string,
  options?: AmountFormatOptions
) => {
  const currencyDefinition = new Intl.NumberFormat(
    options?.locale || i18n.language,
    {
      style: 'currency',
      currency,
      minimumFractionDigits: options?.minimumFractionDigits,
      maximumFractionDigits: options?.maximumFractionDigits,
    }
  );

  if (options?.convertToBasicMonetaryUnit) {
    amount = toBasicMonetaryUnit(currencyDefinition, amount);
  }

  return currencyDefinition.format(amount);
};

export const amountFormatWithoutCurrency = (
  amount: number,
  locale?: string
) => {
  const formatDefinition = new Intl.NumberFormat(locale ?? i18n.language, {
    minimumFractionDigits: 2,
  });

  return formatDefinition.format(amount);
};

export const amountFormat = (
  amount: number | null | undefined,
  currency: string | null | undefined,
  options?: AmountFormatOptions
) => {
  // to prevent endless null checks everywhere
  // the checks are handled within the utility
  if (amount == null || !currency) {
    return null;
  }

  try {
    return amountFormatNonNull(amount, currency, options);
  } catch (error) {
    if (error instanceof RangeError) {
      // if currency string is invalid, `Intl.NumberFormat` throws RangeError. This edge case will be handled in a way that we return a string without currency in that case
      return amountFormatWithoutCurrency(amount, options?.locale);
    }

    throw error;
  }
};

export const percentageFormat = (number: number) => {
  return new Intl.NumberFormat(i18n.language, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number / 100);
};

import { Grid, Text } from '@candisio/design-system';
import {
  AssociationSource,
  DocumentAssociationsRemovedEvent,
} from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getGoodsReceiptData,
  getPurchaseOrderData,
} from './DocumentAssociationsCreatedEventComponent';

export const DocumentAssociationsRemovedEventComponent = ({
  disconnectedPurchaseOrdersWithGoodsReceipts,
  disconnectedGoodsReceiptsWithPurchaseOrders,
}: DocumentAssociationsRemovedEvent) => {
  const [t] = useTranslation();

  const disconnectedPurchaseOrdersLength =
    disconnectedPurchaseOrdersWithGoodsReceipts?.length ?? 0;
  const disconnectedGoodsReceiptsLength =
    disconnectedGoodsReceiptsWithPurchaseOrders?.length ?? 0;
  const totalLength =
    disconnectedPurchaseOrdersLength + disconnectedGoodsReceiptsLength;

  const linkingSource = useMemo(() => {
    if (!totalLength) return null;
    return disconnectedPurchaseOrdersLength
      ? AssociationSource.PurchaseOrder
      : AssociationSource.GoodsReceipt;
  }, [totalLength, disconnectedPurchaseOrdersLength]);

  const { orderNumbers, relatedGoodsReceiptNumbers } = getPurchaseOrderData(
    disconnectedPurchaseOrdersWithGoodsReceipts
  );
  const { receiptNumbers, relatedPurchaseOrderNumbers } = getGoodsReceiptData(
    disconnectedGoodsReceiptsWithPurchaseOrders
  );

  const eventText = useMemo(() => {
    if (linkingSource === AssociationSource.PurchaseOrder) {
      return t(
        'document.compact.history.documentAssociationsRemovedEvent.purchaseOrderWithGoodsReceipt',
        {
          orderNumber: orderNumbers.join(', '),
          goodsReceiptLabel: t(
            'document.compact.history.documentAssociationsRemovedEvent.relatedGoodsReceipt',
            {
              receiptNumber: relatedGoodsReceiptNumbers.join(', '),
              count: relatedGoodsReceiptNumbers.length,
            }
          ),
          count: orderNumbers.length,
        }
      );
    } else {
      const relatedPurchaseOrdersLabel = relatedPurchaseOrderNumbers.length
        ? t(
            'document.compact.history.documentAssociationsRemovedEvent.relatedPurchaseOrder',
            {
              orderNumber: relatedPurchaseOrderNumbers.join(', '),
              count: relatedPurchaseOrderNumbers.length,
            }
          )
        : '';

      return t(
        relatedPurchaseOrdersLabel
          ? 'document.compact.history.documentAssociationsRemovedEvent.goodsReceiptWithPurchaseOrder'
          : 'document.compact.history.documentAssociationsRemovedEvent.standAloneGoodsReceipt',
        {
          receiptNumber: receiptNumbers.join(', '),
          purchaseOrderLabel: relatedPurchaseOrdersLabel,
          count: receiptNumbers.length,
        }
      );
    }
  }, [
    linkingSource,
    orderNumbers,
    relatedGoodsReceiptNumbers,
    receiptNumbers,
    relatedPurchaseOrderNumbers,
    t,
  ]);
  if (!totalLength) return null;

  return (
    <Grid>
      <Text>{eventText}</Text>
    </Grid>
  );
};

import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Paragraph,
  Skeleton,
} from '@candisio/design-system';
import { BankAccountDrawer } from 'components/BankAccountListView/BankAccountDrawer';
import { BankAccountListView } from 'components/BankAccountListView/BankAccountListView';
import { useBankAccounts } from 'components/BankAccountListView/useBankAccounts';
import { PulseIndicator } from 'components/PulseIndicator/PulseIndicator';
import { useTranslation } from 'react-i18next';

interface BankAccountsProps {
  cardDescription?: string;
  showPulseIndicator?: boolean;
}

/** Displays list of bank accounts associated with current organization */
export const BankAccounts = ({
  cardDescription = 'payments:bankAccounts.description',
  showPulseIndicator,
}: BankAccountsProps) => {
  const [t] = useTranslation();

  const {
    ibanItems,
    isIbansListDataLoading,
    creating,
    updating,
    deleting,
    handleSubmit,
    handleDelete,
    handleSetDefault,
    isOpen,
    setOpenId,
    openId,
    onDrawerClose,
    selectedItem,
    defaultValues,
  } = useBankAccounts();

  return (
    <>
      <Card>
        <Grid gap="space16">
          <Grid gap="space8" templateColumns="1fr auto" alignItems="center">
            <Flex alignItems="center" gap="space8">
              <Heading as="h3">{t('payments:bankAccounts.title')} </Heading>

              {showPulseIndicator && <PulseIndicator />}
            </Flex>
            <Button
              icon="plus"
              size="small"
              variant="secondary"
              onClick={() => {
                setOpenId('new');
              }}
            >
              {t('payments:bankAccounts.createNew')}
            </Button>
          </Grid>

          <Paragraph>{t(cardDescription)}</Paragraph>

          {!isIbansListDataLoading ? (
            <BankAccountListView
              onAction={setOpenId}
              items={ibanItems}
              selectedItem={ibanItems?.find(item => item.isDefault)?.key}
              onSelectionChange={handleSetDefault}
            />
          ) : (
            <Skeleton width="100%" height="space128" />
          )}
        </Grid>
      </Card>

      <BankAccountDrawer
        submitting={creating || updating}
        deleting={deleting}
        action={selectedItem ? 'edit' : 'create'}
        isOpen={isOpen}
        onDelete={() => handleDelete(openId)}
        onClose={onDrawerClose}
        defaultValues={defaultValues}
        onSubmit={values => handleSubmit(values, selectedItem)}
      />
    </>
  );
};

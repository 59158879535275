import { useApolloClient } from '@apollo/client';
import { DELAY_REFETCH_TIME } from 'constants/graphql';
import { Query } from 'generated-types/graphql.types';
import { delay } from 'lodash';

export const CLASSIFICATION_QUERIES_TO_REFETCH: (keyof Query)[] = [
  'listInboxDocuments',
  'getEcmDocuments',
  'inboxInvoiceDocuments',
  'countDocumentsByStatus',
  'countClassifyingDocuments',
];

/**
 * Refetches classification queries after a delay
 * @param client Apollo client instance
 */
export const refetchClassificationQueries = (
  client: ReturnType<typeof useApolloClient>
) => {
  delay(() => {
    CLASSIFICATION_QUERIES_TO_REFETCH.map(query => {
      client.cache.evict({ fieldName: query });
    });
  }, DELAY_REFETCH_TIME);
};

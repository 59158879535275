import {
  Flex,
  Grid,
  InlineSkeleton,
  Separator,
  Tag,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import {
  DocumentCurrency,
  ReimbursementCaseStatus,
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { some } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemSplitBookingsList } from './ReimbursementItemSplitBookingsList';
import { useReimbursementSplitBookingsData } from 'views/Reimbursement/hooks/useReimbursementSplitBookingsData';

export interface ReimbursementItemsSplitBookingsContainerProps {
  isLoading: boolean;
  isCurrentUserCaseApprover: boolean;
  reimbursementCaseStatus?: ReimbursementCaseStatus;
}

export const mappedTypesToItem: Record<ReimbursementItemType, string> = {
  GENERAL_EXPENSE:
    'reimbursementView.leftSection.reimbursementItems.placeholder.general',
  HOSPITALITY_EXPENSE:
    'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality',
  MILEAGE: '',
  PER_DIEM: '',
};

export const ReimbursementItemsSplitBookingsContainer = ({
  isLoading,
  isCurrentUserCaseApprover,
  reimbursementCaseStatus,
}: ReimbursementItemsSplitBookingsContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { reimbursementItemSplitBookings, loadingSplits } =
    useReimbursementSplitBookingsData();

  const reimbursementItemSplitData = useMemo(
    () => reimbursementItemSplitBookings,
    [reimbursementItemSplitBookings]
  );

  const isSplitLoading = isLoading || loadingSplits;

  const isPartiallyExported =
    reimbursementCaseStatus === ReimbursementCaseStatus.PartiallyExported;

  const hasReimbursementItemBookings = some(
    reimbursementItemSplitData,
    entry => entry.bookings.length > 0
  );

  return (
    <Grid gap="space8">
      {hasReimbursementItemBookings && (
        <Grid templateColumns="auto 1fr" alignItems="center" gap="space8">
          <Text fontSize="basic" fontWeight="semibold">
            {t('splitBookings.listTitle')}
          </Text>
          <Separator />
        </Grid>
      )}
      <Grid gap="space16">
        {reimbursementItemSplitData.map((entry, entryIndex) => {
          const {
            title,
            bookings,
            totalAmount,
            currency,
            type,
            id,
            status: itemStatus,
          } = entry ?? {};

          const expense = mappedTypesToItem[type];
          const titleData = title ? title : t(expense);
          const showExportStatusInfo =
            isPartiallyExported &&
            itemStatus === ReimbursementItemStatus.ReadyToExport;
          const isExcluded = itemStatus === ReimbursementItemStatus.Excluded;

          return (
            <InlineSkeleton
              key={`bookings-${id}`}
              isLoading={isSplitLoading}
              isBlockLevel
            >
              <Grid gap="space8" data-testid={`items-section-${entryIndex}`}>
                <Flex alignItems="center" justifyContent="space-between">
                  <Flex gap="space4">
                    <TruncatedText color="gray600">{titleData}</TruncatedText>
                    {isExcluded && (
                      <Text color="gray600">
                        {t('splitBookings.notIncluded')}
                      </Text>
                    )}
                  </Flex>
                  {showExportStatusInfo && (
                    <Tag variant="secondary" color="red">
                      {t('splitBookings.notExported')}
                    </Tag>
                  )}
                </Flex>
                <ReimbursementItemSplitBookingsList
                  shouldShowFullSummary
                  reimbursementItemId={id}
                  isLoading={isSplitLoading}
                  bookings={bookings}
                  entryIndex={entryIndex}
                  currency={currency ?? DocumentCurrency.Eur}
                  totalAmount={totalAmount ?? 0}
                  reimbursementCaseStatus={reimbursementCaseStatus}
                  itemStatus={itemStatus}
                  isCurrentUserCaseApprover={isCurrentUserCaseApprover}
                  reimbursementItemType={type}
                />
              </Grid>
            </InlineSkeleton>
          );
        })}
      </Grid>
    </Grid>
  );
};

import { HighlightResult } from '@candisio/design-system';
import { HighlightChunk } from 'generated-types/graphql.types';

interface Highlight {
  field: string;
  chunks: HighlightChunk[];
}

export const parseHighlight = <ColumnKeys extends string>(
  highlights: Highlight[],
  highlightFieldToColumnMapper: Partial<Record<string, ColumnKeys>>
) => {
  const validEntries: Array<[ColumnKeys, HighlightResult]> = [];

  for (const highlight of highlights) {
    const columnId = highlightFieldToColumnMapper[highlight.field];
    if (!columnId) continue;

    const firstChunk = getHighlightResult(highlight);
    if (!firstChunk) continue;

    validEntries.push([
      columnId,
      { text: firstChunk.text, matches: firstChunk.matches },
    ]);
  }
  return Object.fromEntries(validEntries);
};

function getHighlightResult(highlight: Highlight): HighlightResult | null {
  const firstChunk = highlight.chunks[0];
  if (!firstChunk) return null;

  return {
    text: firstChunk.text,
    matches: firstChunk.matches,
  };
}

import { Grid, Spinner } from '@candisio/design-system';
import { ProcessingForm } from 'components/Form/ProcessingForm/ProcessingForm';
import { ProcessingFormContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { DocumentTagsFieldContainer } from 'containers/Tags/DocumentTagsFieldContainer';
import { useAvailableDocumentCategoriesQuery } from 'generated-types/graphql.types';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { AppRouteParams, Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useSap } from 'orgConfig/sap';
import { useCallback, useMemo, useState } from 'react';
import {
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import { ProcessingFormActionsContainer } from './ProcessingFormActionsContainer';
import { SplitBookingsFormContainer } from './SplitBookingsFormContainer';
import { AddContactContainer } from './components/AddContact';
import { EditContactContainer } from './components/EditContact/EditContactContainer';
import { useFastApprove } from './useFastApprove';
import { useProcessingFormFieldOptions } from './useProcessingFormFieldOptions';
import { useProcessingFormInitialData } from './useProcessingFormInitialData';
import { useRequestApproval } from './useRequestApproval';
import { generateDefaultValuesForNewContact } from './util/generateDefaultValuesForNewContact';
import { BookingsFormContainer } from 'components/Form/BookingsForm/BookingsFormContainer';

export interface ProcessingFormContainerProps {
  /** Document to fetch */
  documentId: string;
  /** Called document successfully (fast) approved */
  onApprove?: () => void;
  /** Called when document successfully deleted */
  onDeleteDocument?: () => void;
  /** Called when approval successfully requested for document */
  onRequestApproval?: () => void;
  /** Called when contact was changed */
  onContactChange?: (contactId: string | null) => void;
  /** Called when cancel edit was clicked */
  onCancelEdit?: () => void;
  /** Called when contact item was fetched */
  onGetContactItem?: (contactItem?: ProcessingFormContactFieldItem) => void;
  /** Called when document type is changed between invoice and ecm */
  onDocumentTypeConversion?: () => void;
}

/** Handles data fetching for `ProcessingForm` component */
export const ProcessingFormContainer = ({
  documentId,
  onApprove,
  onDeleteDocument,
  onRequestApproval,
  onContactChange: onContactChangeProps,
  onCancelEdit,
  onGetContactItem,
  onDocumentTypeConversion,
}: ProcessingFormContainerProps) => {
  const sap = useSap();
  const integration = useIntegrationSettings();
  const { showDocumentTags } = useEcm();
  const bookingsTableFormFF = sap.shouldUseBookingsTableForm;

  const { data: availableDocumentCategoriesData } =
    useAvailableDocumentCategoriesQuery();

  const availableDocumentCategories =
    availableDocumentCategoriesData?.availableDocumentCategories ?? [];

  const { bdsBought } = useDatev();

  const { organizationSlug } = useParams<AppRouteParams>();
  const navigate = useNavigate();

  const {
    defaultValues,
    defaultMetadata,
    isDocumentReadOnly: readOnly,
    loading: loadingInitialFormData,
    extractedContact,
    onContactChange,
    globalDocumentId,
    tags,
    isEInvoice,
    status,
    transactionDetails,
  } = useProcessingFormInitialData({ documentId });

  const { fieldOptions, loading: loadingFieldOptions } =
    useProcessingFormFieldOptions({
      documentId,
      defaultValues,
      status,
    });

  const requestApproval = useRequestApproval(documentId);
  const approve = useFastApprove(documentId);

  const [mode, setMode] = useState<
    'default' | 'add-contact' | 'edit-contact' | 'split-bookings'
  >('default');

  // @TODO maybe combine state with useReducer?
  const [defaultSplitIndex, setDefaultSplitIndex] = useState(-1);

  const handleSubmit = async (values: ProcessingFormValues) => {
    const result = await requestApproval(values);
    if (result.status === 'success') {
      onRequestApproval?.();
    } else {
      return result.submitErrors;
    }
  };

  const handleApprove = async (values: ProcessingFormValues) => {
    const result = await approve(values);
    if (result.status === 'success') {
      onApprove?.();
    } else {
      return result.submitErrors;
    }
  };

  const handleContactChange = (newContactId: string | null) => {
    onContactChange(newContactId);
    onContactChangeProps?.(newContactId);
  };

  const isLoading = loadingInitialFormData || loadingFieldOptions;

  const tagIds = useMemo(() => tags?.map(t => t.id) ?? [], [tags]);

  const resetSubformMode = useCallback(() => {
    setMode('default');
  }, []);

  let subform = null;

  if (mode === 'add-contact') {
    subform = (
      <AddContactContainer
        defaultValues={generateDefaultValuesForNewContact({
          extractedContact,
          bdsBought,
        })}
        onClose={resetSubformMode}
      />
    );
  }

  if (mode === 'edit-contact') {
    subform = <EditContactContainer onClose={resetSubformMode} />;
  }

  if (mode === 'split-bookings') {
    const FormContainer = bookingsTableFormFF
      ? BookingsFormContainer
      : SplitBookingsFormContainer;

    subform = (
      <FormContainer
        defaultSplitIndex={defaultSplitIndex}
        documentId={documentId}
        onAcceptBookings={resetSubformMode}
        onDiscardBookings={resetSubformMode}
        readOnly={readOnly}
        taxCodeFieldItems={
          fieldOptions.taxCode?.props?.defaultItems ?? undefined
        }
        typeFieldItems={fieldOptions.type?.items}
      />
    );
  }

  return isLoading ? (
    <Grid alignContent="center" height="100%" justifyContent="center">
      <Spinner size="space64" />
    </Grid>
  ) : (
    <ProcessingForm
      key={documentId} // Reset form state when switching document
      documentId={documentId}
      isEInvoice={isEInvoice}
      globalDocumentId={globalDocumentId ?? ''}
      onGetContactItem={onGetContactItem}
      actions={
        !readOnly ? (
          <ProcessingFormActionsContainer
            documentId={documentId}
            onApprove={handleApprove}
            onDeleteDocument={onDeleteDocument}
            onCancelEdit={onCancelEdit}
          />
        ) : undefined
      }
      defaultMetadata={defaultMetadata}
      defaultValues={defaultValues}
      fieldOptions={fieldOptions}
      integration={integration}
      availableDocumentCategories={availableDocumentCategories}
      isLoading={isLoading}
      onAddContact={() => {
        if (sap.shouldUseSapContacts && organizationSlug) {
          const contactImportHistoryPath = generatePath(
            `/:${AppRouteParams.organizationSlug}${Routes.CONTACT_IMPORT_HISTORY}`,
            { organizationSlug }
          );

          navigate(contactImportHistoryPath);
        } else {
          setMode('add-contact');
        }
      }}
      onContactChange={handleContactChange}
      onCreateWorkflow={() => {
        if (!organizationSlug) {
          return;
        }

        const workflowSettingsPath = generatePath(
          `/:${AppRouteParams.organizationSlug}${Routes.SETTINGS}${Routes.WORKFLOWS}`,
          { organizationSlug }
        );

        window.open(workflowSettingsPath, '_blank');
      }}
      onEditContact={() => {
        setMode('edit-contact');
      }}
      onSubFormClose={() => {
        setMode('default');
      }}
      onSplitDocument={splitIndex => {
        setDefaultSplitIndex(splitIndex ?? -1);
        setMode('split-bookings');
      }}
      onSubmit={handleSubmit}
      readOnly={readOnly}
      subForm={subform}
      tagsField={
        showDocumentTags &&
        globalDocumentId && (
          <DocumentTagsFieldContainer
            isInvoice
            documentTags={tagIds}
            globalDocumentId={globalDocumentId}
            documentId={documentId}
          />
        )
      }
      status={status}
      onDocumentTypeConversion={onDocumentTypeConversion}
      transactionDetails={transactionDetails}
    />
  );
};

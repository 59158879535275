import clsx from 'clsx';
import { HTMLProps, forwardRef } from 'react';
import styles from './SwimlaneTileRow.module.css';

export type SwimlaneTileRowProps = Omit<HTMLProps<HTMLDivElement>, 'style'> & {
  colCount: number;
};

export const SwimlaneTileRow = forwardRef<HTMLDivElement, SwimlaneTileRowProps>(
  (props, ref) => {
    const { className, colCount, ...otherProps } = props;

    return (
      <div
        className={clsx(styles.row, className)}
        ref={ref}
        style={{
          gridTemplateColumns: `repeat(${colCount}, minmax(0, 12rem))`,
        }}
        {...otherProps}
      />
    );
  }
);

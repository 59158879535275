import { CellContext } from '@tanstack/react-table';
import type { WithHighlights as WithHighlightsType } from '../types';
import { HighlightedTruncatedText } from '@candisio/design-system';

export interface WithHighlightsCellProps<T extends WithHighlightsType> {
  cellProps: CellContext<T, unknown>;
  FallbackCell: React.ComponentType<CellContext<T, any>>;
}

export const WithHighlightsCell = <T extends WithHighlightsType>({
  cellProps,
  FallbackCell,
}: WithHighlightsCellProps<T>) => {
  const highlights = getCellHighlights(cellProps);
  if (!highlights) {
    return <FallbackCell {...cellProps} />;
  }

  const value = cellProps.getValue();

  return (
    <HighlightedTruncatedText highlight={highlights} className="max-w-[50ch]">
      {String(value)}
    </HighlightedTruncatedText>
  );
};

export const getCellHighlights = <T extends WithHighlightsType>(
  cell: Pick<CellContext<T, unknown>, 'row' | 'column'>
) => {
  const { row, column } = cell;
  const highlights = row.original?.highlights;
  const columnId = column.id;
  const columnHighlights = highlights?.[columnId] ?? undefined;

  return columnHighlights;
};

import { MessageBox } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

export const NotifyPersonFieldInfoBox = () => {
  const [t] = useTranslation();

  return (
    <MessageBox
      message={t('ecm:storageForm.fields.notifyPerson.fieldMovedLabel')}
      variant="info"
    />
  );
};

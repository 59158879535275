import { Buffer } from 'buffer';
import { Box } from '@candisio/design-system';
import { ErrorBoundary } from '@sentry/react';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { CreditCardContainer } from 'containers/credit-cards/CreditCard/CreditCard';
import { useGetCardById } from 'containers/credit-cards/utils';
import { CardBrand } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMeaWalletErrorToSentryIntegration } from '../../hooks/useMeaWalletErrorToSentryIntegration';
import { LoadingContainer } from './LoadingContainer';
import { meaWalletErrors, useEventHandlers } from './events';
import { MeaWalletMessageEvent } from './events/types';

const apiKeyId = import.meta.env.REACT_APP_MEA_WALLET_API_KEY_ID;
const apiKey = import.meta.env.REACT_APP_MEA_WALLET_API_KEY;
export const meaWalletBaseUrl = import.meta.env.REACT_APP_MEA_WALLET_URL;

const styleUrl =
  'https://assets.my.candis.io/credit-cards/mea-wallet-style_v12.css';

type CreditCardSensitiveDataProps = {
  cardId: string;
  keys: {
    token?: string | null;
    uniqueToken?: string | null;
  };
};

export const CreditCardSensitiveData = ({
  keys,
  cardId,
  isCandisCreditCard,
}: CreditCardSensitiveDataProps & {
  isCandisCreditCard: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { error } = useToastMessage();
  const eventHandlers = useEventHandlers();
  const random = Math.floor(Math.random() * 1000000);
  const handleError = useMeaWalletErrorToSentryIntegration();

  const { token, uniqueToken } = keys;

  const title = isCandisCreditCard
    ? 'Candis Credit Card'
    : 'Pliant Credit Card';

  // The dictionary keys below should not be changed because they are defined by MeaWallet
  const dictionary = {
    pan: t('meaWallet.pan'),
    emboss: t('meaWallet.cardholder'),
    exp: t('meaWallet.exp'),
    cvv: t('meaWallet.cvv'),
  };

  const payload = {
    apiKeyId,
    apiKey,
    cardId: token,
    uniqueToken,
    styleUrl,
    loader: 'dots',
    showCopy: true,
    fieldNames: JSON.stringify(dictionary),
  };

  const data = Buffer.from(JSON.stringify(payload, undefined, 2)).toString(
    'base64'
  );

  const url = `${meaWalletBaseUrl}/easylaunch?var=${random}&data=${data}`;

  const listener = useCallback(
    (event: MeaWalletMessageEvent) => {
      if (event.origin === meaWalletBaseUrl && event.data.event) {
        // https://stackoverflow.com/a/46986927/4299313
        // XXX I was not able to prevent multiple listeners from registering
        // so we are ensuring the listener is executed just once
        event.stopImmediatePropagation();
        eventHandlers[event.data.event]();

        if (meaWalletErrors.includes(event.data.event)) {
          error(t('dashboard.genericErrorMessage'));
          handleError({ cardId, event, token });
        }
      }
    },
    [cardId, error, eventHandlers, handleError, t, token]
  );

  useEffect(() => {
    return () => {
      window.removeEventListener('message', listener, false);
    };
  }, [listener]);

  return (
    <>
      <LoadingContainer />
      <ErrorBoundary>
        <Box
          as="iframe"
          position="absolute"
          borderRadius="medium"
          src={url}
          width="180px"
          height="280px"
          frameBorder="0"
          title={title}
          allow={`clipboard-write ${meaWalletBaseUrl}`}
          onLoad={() => {
            // https://developer.meawallet.com/mcd/web/implementation-guide/#listener
            window.addEventListener('message', listener, false);
          }}
        />
      </ErrorBoundary>
    </>
  );
};

export const CreditCardSensitiveDataContainer = ({
  cardId,
  keys: { token, uniqueToken },
}: CreditCardSensitiveDataProps) => {
  const { card } = useGetCardById({ cardId });
  const isCandisCreditCard = card?.brand === CardBrand.Candis;

  const credentials = [token, uniqueToken, apiKey, apiKeyId, meaWalletBaseUrl];

  const isAnyCredentialMissing = credentials.some(isNil);

  return isAnyCredentialMissing ? (
    <CreditCardContainer cardId={cardId} />
  ) : (
    <CreditCardSensitiveData
      cardId={cardId}
      keys={{ token, uniqueToken }}
      isCandisCreditCard={isCandisCreditCard}
    />
  );
};

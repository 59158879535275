import { Icon, Text } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import styles from './PerDiem/PerDiem.module.css';
import clsx from 'clsx';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';

interface PerDiemDetailsCardProps {
  reimbursementItems?: ReimbursementItemForList[];
}

export const PerDiemDetailsCard = ({
  reimbursementItems = [],
}: PerDiemDetailsCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();
  const { setSelectedItemId } = useReimbursementFormsContext();

  const perDiemId = reimbursementItems.find(
    item => item.type === ReimbursementItemType.PerDiem
  )?.id;

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!perDiemId) return;
    e.stopPropagation();
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    setSelectedItemId(perDiemId);
  };

  return (
    <div
      className={styles.card}
      data-clickable={!!perDiemId}
      onClick={handleClick}
    >
      <div className="grid grid-cols-[auto_1fr] gap-6 place-items-start">
        <div className={clsx(styles.iconWrapper)}>
          <Icon icon="calendarDays" size="space20" />
        </div>
        <div className="grid gap-2 justify-self-start">
          <Text fontSize="xlarge" fontWeight="semibold">
            {t('reimbursementView.middleSection.form.perDiem.title')}
          </Text>
          <Text fontSize="basic">
            {t('reimbursementView.middleSection.form.perDiem.description')}
          </Text>
        </div>
      </div>
    </div>
  );
};

import {
  Button,
  Card,
  Flex,
  Grid,
  defaultTheme,
} from '@candisio/design-system';
import {
  HookFormSelectField,
  HookFormSelectFieldProps,
} from 'components/HookFormFields/HookFormSelectField';
import { DocumentType } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { compiledRoutes } from 'models';
import { Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DOCUMENT_TYPE_TRANSLATION_MAP } from 'views/Settings/DocumentTypes/toolkit/consts';
import { AddDocumentTypeActionBox } from '../AddActionBox/AddDocumentTypeActionBox';
import { StorageFormValues } from './storageFormSchema';

export interface StorageFormDocumentTypeFieldProps
  extends HookFormSelectFieldProps<StorageFormValues> {}

export const StorageFormDocumentTypeSelectField = ({
  name,
  items,
  ...restProps
}: StorageFormDocumentTypeFieldProps) => {
  const { isAdmin, isAccountant } = useUserRoles();
  const isAdminOrAccountant = isAdmin || isAccountant;

  const type = useWatch<StorageFormValues, 'documentType'>({
    name: 'documentType',
  });
  let hasInvalidType = false;
  if (type) {
    const validItemName = Array.from(items ?? [])?.map(item => item.key);
    hasInvalidType = !validItemName.includes(type);
  }

  return (
    <Grid gap="space8">
      <HookFormSelectField
        name={name}
        items={items}
        {...restProps}
        dataCy={name}
        renderCustomDropdown={list => (
          <>
            {list}
            {isAdminOrAccountant && <AddDocumentTypeActionBox />}
          </>
        )}
      />
      {hasInvalidType && (
        <InvalidDocumentTypeMessage
          isAdminOrAccountant={isAdminOrAccountant}
          invalidType={type}
        />
      )}
    </Grid>
  );
};

const InvalidDocumentTypeMessage = ({
  invalidType,
  isAdminOrAccountant,
}: { invalidType: DocumentType; isAdminOrAccountant: boolean }) => {
  const organizationId = useOrganizationId();
  const [t] = useTranslation();

  const translationKey = DOCUMENT_TYPE_TRANSLATION_MAP[invalidType];

  const name = t(translationKey);

  if (isAdminOrAccountant) {
    const link = `/${organizationId}${compiledRoutes.settingsDocumentTypesRoute}`;
    return (
      <Card padding="space20 space16" background="redbg">
        <Flex gap="space8" direction="column">
          <Trans
            i18nKey="ecm:storageForm.fields.documentType.invalidTypeMessage.admin"
            values={{ name }}
          >
            {name}
          </Trans>

          <Button
            as="a"
            href={link}
            target="_blank"
            variant="secondary"
            style={{ fontSize: defaultTheme.fontSizes.small }}
          >
            {t('ecm:storageForm.fields.documentType.actionBox.button')}
          </Button>
        </Flex>
      </Card>
    );
  }

  return (
    <Card padding="space20 space16" background="redbg">
      <Trans
        i18nKey="ecm:storageForm.fields.documentType.invalidTypeMessage.nonAdmin"
        values={{ name }}
      >
        {name}
      </Trans>
    </Card>
  );
};

import gql from 'graphql-tag';

export const approvalReimbursementsQuery = gql`
  query approvalReimbursementCases(
    $search: String
    $sorts: ReimbursementCasesSortInput
    $filters: ReimbursementCasesFilterInput
    $limit: Int
    $next: String
    $searchInput: SearchInput
  ) {
    approvalReimbursementCases(
      search: $search
      sorts: $sorts
      filters: $filters
      limit: $limit
      next: $next
      searchInput: $searchInput
    ) {
      pageInfo {
        totalCount
        hasNextPage
        endCursor: nextPageCursor
      }
      edges {
        cursor
        node {
          id
          createdAt
          title
          status
          requestedAt
          requester {
            id
            firstName
            lastName
            avatarUrl
          }
          approvers {
            id
            firstName
            lastName
            avatarUrl
          }
          targetMembership {
            id
            firstName
            lastName
            avatarUrl
          }
          totalAmount {
            amount
            currency
            precision
          }
        }
      }
    }
  }
`;

export const approvalReimbursementsCount = gql`
  query approvalReimbursementCasesCount(
    $filters: ReimbursementCasesFilterInput
  ) {
    approvalReimbursementCasesCount(
      filters: $filters
    ) {
      totalCount
    }
  }
`;

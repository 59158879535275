import * as Sentry from '@sentry/react';
import { User } from 'generated-types/graphql.types';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { isTestUserEmailAddress } from 'providers/utils';
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { UserAttributeProperties } from './attributes';
import { EventProperties, TrackingEvents } from './events';
import { sourceMiddleware } from './sourceMiddleware';
import {
  Analytics,
  Destination,
  SegmentIntegrations,
  TrackFunction,
} from './types';
import { useCandisAppCookie } from './useCandisAppCookie';
import { useSegmentDestinations } from './useSegmentDestinations';
import { TagManager, TagManagerArgs } from './utils/TagManager';

declare global {
  interface Window {
    analytics: Analytics;
  }
}

export const AnalyticsContext = createContext<Analytics>(window.analytics);

interface AnalyticsProviderProps {
  children: ReactNode;
}

const trackLoginEvent = (track: TrackFunction) => {
  const loginEvent = localStorage.getItem(TrackingEvents.LOGIN);

  if (loginEvent) {
    try {
      track(TrackingEvents.LOGIN, JSON.parse(loginEvent));
    } catch (error) {
      console.error('Failed to parse login event data:', error);
    }
    localStorage.removeItem(TrackingEvents.LOGIN);
  }
};

const initialIdentifyUser = (user: User, integrations: SegmentIntegrations) => {
  window.analytics.identify(
    user.analyticsId as string,
    {
      name: user.name,
      email: user.email,
    },
    { integrations }
  );
};

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const currentUser = useCurrentUser();
  const organization = useFullOrganization();
  const destinations = useSegmentDestinations();

  // Initial analytics object is the stub one from src/segment.js
  const [analytics, setAnalytics] = useState(window.analytics);
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false);

  const isTestUser =
    currentUser?.email &&
    isTestUserEmailAddress(currentUser?.email) &&
    import.meta.env.REACT_APP_STAGE === 'prod' &&
    !localStorage.getItem('look_ma_tracking');

  const analyticsId = currentUser?.analyticsId || undefined;

  const intercomIsEnabled = currentUser?.trackingConfiguration?.intercom;
  const trackingEnabled = currentUser?.trackingConfiguration
    ?.trackingEnabled as boolean;

  useCandisAppCookie(analyticsId, trackingEnabled);

  const trackWithoutProxy = useCallback(
    <K extends keyof EventProperties>(
      event: K,
      properties?: EventProperties[K]
    ) => {
      if (organization?.id && analyticsId) {
        const extendedProperties = {
          organization_id: organization.id,
          ...properties,
        } as EventProperties[K] & { organization_id: string };

        analytics.track(event, extendedProperties);

        TagManager.dataLayer({
          dataLayer: {
            event,
            eventModel: {
              platform: 'web', // We add this to all events on each platform (web|ios|android|server).
              timestamp: new Date().toISOString(),
              user_id: analyticsId,
              organization_id: organization.id,
              ...properties,
            },
          },
        });
      }
    },
    [analytics, analyticsId, organization?.id]
  );

  const track: TrackFunction = useCallback(
    (event, properties) => {
      try {
        if (
          import.meta.env.REACT_APP_STAGE !== 'prod' ||
          localStorage.getItem('show_tracking_logs')
        ) {
          // You need to also set the log level in your console to verbose to see it
          console.debug(`Track event ${event} was fired`, {
            properties,
          });
        }

        trackWithoutProxy(event, properties);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [trackWithoutProxy]
  );

  const identify = (
    traits: Partial<UserAttributeProperties>,
    callback?: () => void
  ) => {
    analytics.identify(traits, callback);
  };

  useEffect(() => {
    if (
      // Check is User is loaded
      analyticsId &&
      // Check if destinations are loaded
      destinations &&
      !destinations.loading &&
      // Check analytics should be loaded
      analytics?.load &&
      import.meta.env.REACT_APP_SEGMENT_KEY &&
      !analyticsLoaded
    ) {
      const {
        marketingDestinations = [],
        advertisingDestinations = [],
        functionalDestinations = [{ id: 'Intercom' } as Destination],
      } = destinations.data || {};

      const integrations = {
        'Segment.io': trackingEnabled,
        ...marketingDestinations.reduce(
          (acc, current) => ({
            ...acc,
            [current.id]: trackingEnabled,
          }),
          {}
        ),
        ...advertisingDestinations.reduce(
          (acc, current) => ({
            ...acc,
            [current.id]: trackingEnabled,
          }),
          {}
        ),
        ...functionalDestinations.reduce(
          (acc, current) => ({
            ...acc,
            [current.id]: intercomIsEnabled,
          }),
          {}
        ),
        ...(isTestUser
          ? {
              'Segment.io': false,
            }
          : {}),
      };

      // add default properties to all track and page events
      analytics.addSourceMiddleware(sourceMiddleware);

      // load all analytics integrations
      // This loads the real analytics.js script into the DOM
      analytics.load(import.meta.env.REACT_APP_SEGMENT_KEY, {
        integrations,
      });

      setAnalyticsLoaded(true);

      // Once the real analytics.js has finished loading, update the window.analytics
      // object reference in the consumer.
      analytics.ready(() => {
        setAnalytics(window.analytics);

        if (currentUser && currentUser.analyticsId) {
          initialIdentifyUser(currentUser, integrations);
          trackLoginEvent(track);
        }
      });
    }
  }, [
    analyticsId,
    analytics,
    analyticsLoaded,
    destinations,
    intercomIsEnabled,
    isTestUser,
    trackingEnabled,
    currentUser,
    track,
  ]);

  useEffect(() => {
    if (
      // Check is User is loaded
      analyticsId &&
      // Check if destinations are loaded
      import.meta.env.REACT_APP_GTM_KEY
    ) {
      if (trackingEnabled) {
        const tagManagerArgs: TagManagerArgs = {
          gtmId: import.meta.env.REACT_APP_GTM_KEY,
          url:
            import.meta.env.REACT_APP_GTM_URL ||
            'https://www.googletagmanager.com',
        };

        TagManager.initialize(tagManagerArgs);
        TagManager.dataLayer({
          dataLayer: {
            event: 'initialised',
            eventModel: {
              analyticsId,
              intercomIsEnabled,
            },
          },
        });
      }
    }
  }, [analyticsId, trackingEnabled, intercomIsEnabled]);

  return (
    <AnalyticsContext.Provider value={{ ...analytics, track, identify }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

import { ExportStatus, ExportType } from 'generated-types/graphql.types';
import { noop } from 'lodash';
import { ReactNode, createContext, useEffect, useState } from 'react';

const storageKey = 'exports_notifications';

export type ExportItem = {
  hash: string;
  organization: string;
  type: ExportType;
  status: ExportStatus;
  documents: number;
  errorDetails?: string;
  id?: string;
};

type ExportContextType = {
  notifications: ExportItem[];
  addExport: (data: ExportItem) => void;
  clearExports: () => void;
  updateExport: (
    data: Partial<ExportItem> & Pick<ExportItem, 'status'>
  ) => void;
};

export const ExportNotificationsContext = createContext<ExportContextType>({
  notifications: [],
  addExport: noop,
  clearExports: noop,
  updateExport: noop,
});

interface ExportNotificationsProviderProps {
  children: ReactNode;
}

export const ExportNotificationsProvider = ({
  children,
}: ExportNotificationsProviderProps) => {
  const [notifications, setNotifications] = useState<ExportItem[]>([]);

  // TODO: remove this temporary workaround
  useEffect(() => {
    const mobxKey = 'cnds:mobx_cache';
    const oldNotifications = localStorage.getItem(`${mobxKey}/${storageKey}`);

    if (oldNotifications) {
      localStorage.setItem(`${storageKey}`, oldNotifications);
      localStorage.removeItem(`${mobxKey}/${storageKey}`);
    }
  }, []);

  useEffect(() => {
    const initialNotifications = localStorage.getItem(`${storageKey}`);
    if (initialNotifications)
      setNotifications(JSON.parse(initialNotifications));
  }, []);

  useEffect(() => {
    localStorage.setItem(`${storageKey}`, JSON.stringify(notifications));
  }, [notifications]);

  const addExport = (data: ExportItem) => {
    setNotifications([data].concat(notifications));
  };

  const clearExports = () => {
    setNotifications([]);
  };

  const updateExport = ({
    hash,
    errorDetails,
    status,
    id,
  }: Partial<ExportItem> & Pick<ExportItem, 'status'>) => {
    setNotifications(
      notifications.map(notification => {
        if (hash === notification.hash) {
          return {
            ...notification,
            errorDetails,
            status,
            id,
          };
        }

        return notification;
      })
    );
  };

  return (
    <ExportNotificationsContext.Provider
      value={{
        notifications,
        addExport,
        clearExports,
        updateExport,
      }}
    >
      {children}
    </ExportNotificationsContext.Provider>
  );
};

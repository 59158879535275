import {
  Notification,
  NotificationData,
  UploadFileItem,
  useFileUploadsQuery,
  useGetNotificationsQuery,
} from 'generated-types/graphql.types';
import { usePolling } from 'hooks/usePolling';
import { useMemo } from 'react';
import { IndicatorStatus } from 'views/AppContainer/components/Header/components/ProgressIndicator';
import { getUploadStatus } from 'views/AppContainer/components/Header/utils';
import { ImportNotificationsContent } from './ImportNotificationsContent/ImportNotificationsContent';

const useCustomNotificationsQuery = () => {
  const {
    data: { fileUploads = [] } = {},
  } = useFileUploadsQuery();
  const { data, startPolling, stopPolling } = useGetNotificationsQuery();

  usePolling({ pollInterval: 10000, startPolling, stopPolling });

  const mappedFileUploads: Notification[] = fileUploads.map(file => {
    const item: UploadFileItem = { ...file, __typename: 'UploadFileItem' };
    const record: Notification = {
      id: file.id,
      data: item,
    };

    return record;
  });

  // result type from useGetNotificationsQuery isn't Notification because we're
  // extending the NotificationData type in the FE site, therefore casting is required
  const emailErrors = (data?.notifications?.records || []) as Notification[];

  const notifications: Notification[] = [...emailErrors, ...mappedFileUploads];

  return { notifications };
};

const isUploadFileItem = (data: NotificationData): data is UploadFileItem =>
  data.__typename === 'UploadFileItem';

export const ImportNotifications = ({ onClose }: { onClose: () => void }) => {
  const { notifications } = useCustomNotificationsQuery();

  const hasFailedEmails = useMemo(
    () =>
      notifications.some(
        notification =>
          notification.data.__typename === 'EmailImportErrorNotification'
      ),
    [notifications]
  );

  /**
   * The overall notification status is calculated following the same rules:
   * - If there are uploads in progress, show processing
   * - If there are other types of notifications (EmailImportError), show error
   * - Otherwise, show the upload status
   *
   * IMPORTANT: it currently assumes that all notification should display the error indicator
   */
  const overallStatus = useMemo(() => {
    const files = notifications
      .filter(({ data }) => isUploadFileItem(data))
      .map(({ data }) => data as UploadFileItem);

    const uploadStatus = getUploadStatus(files);

    if (
      uploadStatus !== IndicatorStatus.PROCESSING &&
      files.length !== notifications.length
    ) {
      return IndicatorStatus.ERROR;
    }

    return uploadStatus;
  }, [notifications]);

  return (
    <ImportNotificationsContent
      onClose={onClose}
      notifications={notifications}
      hasFailedEmails={hasFailedEmails}
      fileUploadsInProgress={overallStatus === IndicatorStatus.PROCESSING}
    />
  );
};

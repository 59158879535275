import { Flex, Heading, Switch, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface NotificationsPopoverHeaderProps {
  showOnlyUnread: boolean;
  onShowOnlyUnread: (toogled: boolean) => void;
}

export const NotificationsPopoverHeader = ({
  showOnlyUnread,
  onShowOnlyUnread,
}: NotificationsPopoverHeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);

  return (
    <Flex padding="space16" justifyContent="space-between">
      <Heading as="h2">{t('popover.title')}</Heading>
      <Flex alignItems="center" gap="space10" justifySelf="end">
        <Text color="gray800">{t('popover.showOnlyUnread')}</Text>
        <Switch
          checked={showOnlyUnread}
          label={t('popover.showOnlyUnread')}
          onChange={onShowOnlyUnread}
        />
      </Flex>
    </Flex>
  );
};

import { Link, Tooltip, TooltipProps } from '@candisio/design-system';
import { ScrollBox } from '@candisio/design-system/next';
import { CustomDatevWarningCode } from 'components/DocumentsTable/types';
import { ExportableEntityType } from 'generated-types/graphql.types';
import i18next from 'i18next';
import { ComponentRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportErrorCodes } from 'views/Integrations/Export/consts';
import {
  DocumentExportStatus,
  ExportError,
} from 'views/Integrations/Export/types';
import { parseExportStatusDetails } from './utils/parseExportStatusDetails';

export interface ExportStatusTooltipProps extends TooltipProps {
  exportStatus: DocumentExportStatus;
}
type DetailsListProps = {
  exportWarnings: string[];
  exportDetails: {
    filename?: string;
    details: string;
  }[];
};

const DetailsList = ({
  exportDetails,
  exportWarnings: warnings,
}: DetailsListProps) => (
  <ul className="grid gap-1 list-none p-0">
    {exportDetails.map(({ filename, details }) => (
      <li key={details}>
        {filename && <span className="font-bold">{filename} </span>}
        {/* whitespace is inteded                          ^ */}
        <span>{details}</span>
      </li>
    ))}
    {warnings.map(warning => {
      if (warning === CustomDatevWarningCode.DOCUMENT_ALREADY_EXIST) {
        return (
          <li key={warning}>
            <DetailsText details={warning} />
          </li>
        );
      }

      return (
        <li key={warning}>
          <WarningText warning={warning} />
        </li>
      );
    })}
  </ul>
);

type AllowedEntityType = Exclude<
  ExportableEntityType,
  'AGGREGATED_CONTACTS' | 'AGGREGATED_PAYMENT_CONDITIONS' | 'PAYMENT_CONDITION'
>;

const isAllowedEntityType = (
  entityType: ExportableEntityType
): entityType is AllowedEntityType =>
  ![
    ExportableEntityType.AggregatedContacts,
    ExportableEntityType.AggregatedPaymentConditions,
    ExportableEntityType.PaymentCondition,
  ].includes(entityType);

export const ExportStatusTooltip = forwardRef<
  ComponentRef<typeof Tooltip>,
  ExportStatusTooltipProps
>(({ exportStatus, ...restProps }, ref) => {
  const details = (exportStatus.details ?? []).map(parseExportStatusDetails);
  const warnings = exportStatus.warnings ?? [];
  const exportError = exportStatus.exportError ?? undefined;
  const entityType = exportStatus.entityType;

  return (
    <Tooltip paddingX={0} paddingY={0} {...restProps} ref={ref}>
      <ScrollBox className="p-4 max-h-[inherit]" scrollDirection="y">
        <div className="grid gap-2 text-small">
          {(details.length > 0 || warnings.length > 0) && (
            <DetailsList exportDetails={details} exportWarnings={warnings} />
          )}
          {exportError && entityType && isAllowedEntityType(entityType) && (
            <ErrorText exportError={exportError} entityType={entityType} />
          )}
          {exportError && <HelpLink exportError={exportError} />}
        </div>
      </ScrollBox>
    </Tooltip>
  );
});

const DetailsText = ({ details }: { details: string }) => {
  const [t] = useTranslation();
  const detailsKey = `export.table.columns.status.details.${details?.toLowerCase()}`;
  const detailsMessage = i18next.exists(detailsKey) ? t(detailsKey) : details;

  return <span>{detailsMessage}</span>;
};

const WarningText = ({ warning }: { warning: string }) => {
  const [t] = useTranslation();
  const warningKey = `export.table.columns.status.warnings.${warning?.toLowerCase()}`;
  const warningMessage = i18next.exists(warningKey) ? t(warningKey) : warning;

  return <span className="text-yellow-500">{warningMessage}</span>;
};

const exportableEntityTypeTranslationMap = {
  CARD_SETTLEMENT: 'export.exportableEntityType.card_settlement',
  CARD_TRANSACTION: 'export.exportableEntityType.card_transaction',
  CONTACT: 'export.exportableEntityType.contact',
  DEFAULT: 'export.exportableEntityType.default',
  DOCUMENT: 'export.exportableEntityType.document',
  PROVISION: 'export.exportableEntityType.provision',
  PROVISION_REVERSAL: 'export.exportableEntityType.provision_reversal',
  REIMBURSEMENT_ITEM: 'export.exportableEntityType.reimbursement_item',
} as const;

const ErrorText = ({
  exportError,
  entityType,
}: {
  exportError: ExportError;
  entityType: AllowedEntityType;
}) => {
  const [t] = useTranslation();

  const getErrorMessage = (): string => {
    const failedDocuments = exportError.failedDocuments || [];
    switch (exportError.errorCode) {
      case ExportErrorCodes.ABORTED_BECAUSE_OF_OTHER_DOCUMENT: {
        if (failedDocuments.length === 0) {
          return t(
            'export.table.columns.status.errors.aborted_because_of_other_document_but_documents_has_deleted'
          );
        }

        const invoiceNumber = failedDocuments
          .map(d => d.invoiceNumber)
          .join(', ');

        return t(
          'export.table.columns.status.errors.aborted_because_of_other_document',
          { invoiceNumber }
        );
      }

      case ExportErrorCodes.NON_EXPORTABLE: {
        const translationKey = entityType ?? 'default';

        const translation = exportableEntityTypeTranslationMap[translationKey];

        return t('export.table.columns.status.errors.non_exportable', {
          entityType: t(translation),
        });
      }

      default: {
        const errorCode = exportError.errorCode?.toLowerCase() ?? 'unknown';
        const errorTranslationKey = `export.table.columns.status.errors.${errorCode}`;

        if (i18next.exists(errorTranslationKey)) {
          return t(errorTranslationKey);
        }

        return t('export.table.columns.status.errors.unknown');
      }
    }
  };

  return <span className="text-red-500">{getErrorMessage()}</span>;
};

const errorCodeLinkMap = {
  '#dco01004': 'export.table.columns.status.help.links.#dco01004',
  '#dco01005': 'export.table.columns.status.help.links.#dco01005',
  '#dco01010': 'export.table.columns.status.help.links.#dco01010',
  '#dco01012': 'export.table.columns.status.help.links.#dco01012',
  '#dco01015': 'export.table.columns.status.help.links.#dco01015',
  '#dco01016': 'export.table.columns.status.help.links.#dco01016',
  dco01007_problematic_document:
    'export.table.columns.status.help.links.dco01007_problematic_document',
  forbidden_403: 'export.table.columns.status.help.links.forbidden_403',
} as const;

const isKnownError = (error: string): error is keyof typeof errorCodeLinkMap =>
  Object.keys(errorCodeLinkMap).includes(error);

const HelpLink = ({ exportError }: { exportError: ExportError }) => {
  const [t] = useTranslation();

  const errorCode = exportError.errorCode?.toLowerCase();

  if (!errorCode || !isKnownError(errorCode)) return null;

  const errorCodeLinkKey = errorCodeLinkMap[errorCode];

  if (!i18next.exists(errorCodeLinkKey)) return null;

  return (
    <Link href={t(errorCodeLinkKey)} external>
      {t('export.table.columns.status.help.text')}
    </Link>
  );
};

import {
  useCreditCardsLedgerQuery,
  useNew_DatevSettingsQuery,
  useUpdateCreditCardsLedgerMutation,
  useUpdateDatevCreditCardsLedgerMutation,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

export function useCreditCardsLedgerUpdate() {
  const [newCreditCardsLedgerSettingsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.newCreditCardsLedgerSettings,
  ]);

  const mutation = useUpdateCreditCardsLedgerMutation();
  const oldMutation = useUpdateDatevCreditCardsLedgerMutation();

  return newCreditCardsLedgerSettingsFF ? mutation : oldMutation;
}

export function useCreditCardsLedgerData() {
  const [newCreditCardsLedgerSettingsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.newCreditCardsLedgerSettings,
  ]);

  const { data: datevData, loading: datevLoading } = useNew_DatevSettingsQuery({
    skip: newCreditCardsLedgerSettingsFF,
  });

  const { data: ledgerData, loading: ledgerLoading } =
    useCreditCardsLedgerQuery({ skip: !newCreditCardsLedgerSettingsFF });

  const creditCardsLedger = newCreditCardsLedgerSettingsFF
    ? (ledgerData?.creditCardsLedger ?? {})
    : (datevData?.new_datevSettings?.creditCardsLedger ?? {});

  return { creditCardsLedger, loading: datevLoading || ledgerLoading };
}

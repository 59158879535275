import { ToggleButtonGroup } from 'components/ToggleButtonGroup/ToggleButtonGroup';
import { SearchScope } from 'generated-types/graphql.types';
import { PowerSearchScopeProps } from '../types';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { PowerSearchToggleButton } from './PowerSearchToggleButton';

export const PowerSearchToggles = ({
  searchScope,
  onSearchScopeChange,
  isFullTextSearchAvailable = true,
}: PowerSearchScopeProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);

  return (
    <ToggleButtonGroup
      selectedKeys={[searchScope]}
      onChange={onSearchScopeChange}
    >
      <PowerSearchToggleButton id={SearchScope.DataFields} icon="tableView">
        {t('powerSearch.filterToggle.dataFields')}
      </PowerSearchToggleButton>
      <PowerSearchToggleButton
        id={SearchScope.EntireDocument}
        icon="fullText"
        variant={isFullTextSearchAvailable ? 'default' : 'upgrade'}
      >
        {t('powerSearch.filterToggle.fullText')}
      </PowerSearchToggleButton>
    </ToggleButtonGroup>
  );
};

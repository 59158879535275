import { useSubmitReimbursementCaseForApprovalMutation } from 'generated-types/graphql.types';
import { useCallback } from 'react';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsErrorsQuery,
} from '../toolkit/queries';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { ReimbursementRouteParams } from './useReimbursementNavigation';
import { ApprovalFieldData } from '../context/ReimbursementFormsContext';

export const useSubmitReimbursementForApproval = () => {
  const { reimbursementId } = useParams<ReimbursementRouteParams>();

  const [
    submitForApprovalMutation,
    { loading: isSubmitForApprovalPending, client },
  ] = useSubmitReimbursementCaseForApprovalMutation();

  const submitForApproval = useCallback(
    async (values: ApprovalFieldData) => {
      const approvers = (values.approvers ?? [])?.map(input => input.approvers);
      const approverIds =
        values.approvalType === 'approvers' ? approvers : null;
      const workflowTemplateId =
        values.approvalType === 'workflow' ? values.workflow : null;

      const response = await submitForApprovalMutation({
        variables: {
          input: {
            approverIds,
            workflowTemplateId,
            reimbursementCaseId: reimbursementId,
          },
        },
        onCompleted: () => {
          // Instead of refetching the 3 different queries with different params we evict the cache
          client.cache.evict({ fieldName: 'approvalReimbursementCasesCount' });
        },
        refetchQueries: [
          'inboxReimbursementCasesCount',
          'archiveReimbursementCasesCount',
          'getCountExportableEntities',
          {
            query: reimbursementHistoryQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
          {
            query: reimbursementItemsErrorsQuery,
            variables: { filters: { reimbursementCaseId: reimbursementId } },
          },
        ],
      });

      const isReponseInvalid =
        !response.data?.submitReimbursementCaseForApproval.success;

      if (isReponseInvalid) {
        return 'error';
      }

      return 'success';
    },
    [submitForApprovalMutation, reimbursementId, client]
  );

  return { submitForApproval, isSubmitForApprovalPending };
};

import { ComponentType, useMemo } from 'react';
import { useReimbursementFormsContext } from '../context/ReimbursementFormsContext';
import { Reimbursement } from './useFormattedReimbursement';
import { ReimbursementItemSummaryWithActionContainer } from '../components/MiddleSection/ReimbursementItemSummaryWithActionContainer';
import { ReimbursementItemSummaryContainer } from '../components/MiddleSection/ReimbursementItemSummary';

export const useReimbursementItemsSectionComponent = (
  reimbursement?: Reimbursement
): ComponentType<any> => {
  const { status } = reimbursement ?? {};

  const { reimbursementUIConfig } = useReimbursementFormsContext();
  const { canManageReimbursementItems } = reimbursementUIConfig;

  const getItemsSectionForStatus = useMemo(() => {
    switch (status) {
      case 'DRAFT':
      case 'CHECK':
        return canManageReimbursementItems
          ? ReimbursementItemSummaryWithActionContainer
          : ReimbursementItemSummaryContainer;
      default:
        return ReimbursementItemSummaryContainer;
    }
  }, [status, canManageReimbursementItems]);

  return getItemsSectionForStatus;
};

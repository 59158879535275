import { Card, Flex, Grid, Heading } from '@candisio/design-system';
import { DateFilterInput } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ActiveCards } from './ActiveCards';
import { DeclinedTransactions } from './DeclinedTransactions';
import { ExpiringCards } from './ExpiringCards';
import { NoActiveCards } from './NoActiveCards';
import { PendingRequests } from './PendingRequests';

export interface CardsAndRequestsOverviewWidgetProps {
  countExpiringCards: number;
  declinedTransactionsCount: number;
  pendingCardRequests: number;
  cardLimitChangeRequests: number;
  totalNumberOfActiveCards: number;
  lastWeekFilter: DateFilterInput;
}

export const CardsAndRequestsOverviewWidget = ({
  cardLimitChangeRequests,
  countExpiringCards,
  declinedTransactionsCount,
  pendingCardRequests,
  totalNumberOfActiveCards,
  lastWeekFilter,
}: CardsAndRequestsOverviewWidgetProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  return (
    <Card background="gray50" boxShadow="none">
      <Grid templateRows="auto 1fr" height="100%">
        <Heading as="h3">{t('insights.requestsOverview.title')}</Heading>
        <Flex alignItems="center" height="100%" gap="space16">
          {countExpiringCards > 0 && (
            <ExpiringCards value={countExpiringCards} />
          )}
          {declinedTransactionsCount > 0 && (
            <DeclinedTransactions
              value={declinedTransactionsCount}
              lastWeekFilter={lastWeekFilter}
            />
          )}
          {(pendingCardRequests > 0 || cardLimitChangeRequests > 0) && (
            <PendingRequests
              creditCardRequests={pendingCardRequests}
              limitRequests={cardLimitChangeRequests}
            />
          )}
          {totalNumberOfActiveCards > 0 ? (
            <ActiveCards value={totalNumberOfActiveCards} />
          ) : (
            <NoActiveCards />
          )}
        </Flex>
      </Grid>
    </Card>
  );
};

import { Link, Text } from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useStoreSensitiveEcmDocumentMutation } from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDateConverter } from 'hooks/useDateConverter';
import { isNil } from 'lodash';
import { AppRouteParams, Routes } from 'models';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom-v5-compat';
import { DOCUMENT_ALREADY_STORED_ERROR } from 'views/Inbox/DocumentProcessing/components/Ecm/useStoreEcmDocument';
import { ecmErrorCodeMap } from 'views/Inbox/DocumentProcessing/components/Ecm/useUpdateEcmDocument';
import { getEcmSensitiveDocumentsCountQuery } from 'views/Inbox/EcmSensitiveDocuments/gql';
import { delayRefetchedSensitiveContractsQueries } from './delayRefetchedSensitiveContractsQueries';
import { mapStorageFormValues } from './mapStorageFormValues';
import { StorageFormValues } from './storageFormSchema';

interface UseStoreEcmDocumentOptions {
  documentId: string;
  isNewSensitiveContract?: boolean;
}

type StoreSensitiveEcmDocumentResult =
  | { status: 'success'; id: string }
  | { status: 'error' };

type StoreSensitiveEcmDocument = (
  values: StorageFormValues
) => Promise<StoreSensitiveEcmDocumentResult>;

interface UseStoreEcmDocumentReturn {
  /** Store document */
  storeSensitiveEcmDocument: StoreSensitiveEcmDocument;
  /** Loading state */
  loading: boolean;
}

export const useStoreSensitiveEcmDocument = ({
  documentId,
  isNewSensitiveContract,
}: UseStoreEcmDocumentOptions): UseStoreEcmDocumentReturn => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { success, error } = useToastMessage();
  const organizationSlug = useOrganizationId();
  const { dateStringToDateTimeString } = useDateConverter();

  const counterQueries = useCounterQueries();
  const [mutation, { loading }] = useStoreSensitiveEcmDocumentMutation({
    refetchQueries: [...counterQueries, getEcmSensitiveDocumentsCountQuery],
    onQueryUpdated: delayRefetchedSensitiveContractsQueries,
  });

  const storeSensitiveEcmDocument: StoreSensitiveEcmDocument =
    async formValues => {
      if (!documentId) {
        return { status: 'error' };
      }

      const mappedStorageFormValues = mapStorageFormValues(
        formValues,
        dateStringToDateTimeString,
        isNewSensitiveContract
      );

      const { data, errors } = await mutation({
        variables: {
          input: {
            id: documentId,
            ...mappedStorageFormValues,
          },
        },
      });

      if (
        data?.storeSensitiveEcmDocument.__typename ===
          'StoreEcmDocumentResponse' &&
        !isNil(data.storeSensitiveEcmDocument.ecmDocumentId)
      ) {
        const id = data?.storeSensitiveEcmDocument?.ecmDocumentId;

        const path = organizationSlug
          ? generatePath(
              `/:${AppRouteParams.organizationSlug}${Routes.ECM_CONTRACTS}/:documentId`,
              { organizationSlug, documentId: id }
            )
          : undefined;

        success(
          <Text>
            <Trans
              i18nKey="storageForm.actions.sensitiveContractStoredSuccessfully"
              ns={LOCALE_NAME_SPACE.ECM}
              values={{ name: mappedStorageFormValues.documentName }}
            >
              Contract "
              <Link href={path}>
                {{ name: mappedStorageFormValues.documentName } as any}
              </Link>
              " stored successfully.
            </Trans>
          </Text>
        );

        return { status: 'success', id };
      } else {
        const hasDocumentStoredError = errors?.some(error =>
          error.message.includes(DOCUMENT_ALREADY_STORED_ERROR)
        );

        const errorCodes = errors?.map(error => error.extensions?.code);

        if (hasDocumentStoredError) {
          error(t('documentAlreadyStoredError'));
        } else {
          const message =
            errorCodes
              ?.map(code => ecmErrorCodeMap[code as string])
              .find(msg => msg) || 'genericErrorMessage';
          error(t(message));
        }
        return { status: 'error' };
      }
    };

  return { storeSensitiveEcmDocument, loading };
};

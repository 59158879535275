import { RouterLink } from 'components/RouterLink/RouterLink';
import {
  getLinkToInvoice,
  getLinkToInvoiceOrECMDocument,
} from 'containers/notifications/utils/getLinkToDocument';
import {
  InAppNotificationSubscription,
  InAppNotificationType,
} from 'generated-types/graphql.types';
import { ComponentProps, FunctionComponent } from 'react';
import {
  CardRequestApprovedInAppNotification,
  CardRequestApprovedInAppNotificationToast,
} from './events/CardRequestApprovedInAppNotification';
import {
  CardRequestCreatedInAppNotification,
  CardRequestCreatedInAppNotificationToast,
} from './events/CardRequestCreatedInAppNotification';
import {
  CardRequestRejectedInAppNotification,
  CardRequestRejectedInAppNotificationToast,
} from './events/CardRequestRejectedInAppNotification';
import {
  DocumentCommentCreatedInAppNotification,
  DocumentCommentCreatedInAppNotificationToast,
} from './events/DocumentCommentCreatedInAppNotification';
import {
  DocumentRejectedInAppNotification,
  DocumentRejectedInAppNotificationToast,
} from './events/DocumentRejectedInAppNotification';
import {
  TransactionCommentCreatedInAppNotification,
  TransactionCommentCreatedInAppNotificationToast,
} from './events/TransactionCommentCreatedInAppNotification';
import {
  TransactionDeclinedInAppNotification,
  TransactionDeclinedInAppNotificationIcon,
  TransactionDeclinedInAppNotificationToast,
} from './events/TransactionDeclinedInAppNotification';
import { getLinkToApprovedCardRequest } from './utils/getLinkToApprovedCardRequest';
import { getLinkToCardRequest } from './utils/getLinkToCardRequest';
import { getLinkToTransaction } from './utils/getLinkToDeclinedTransaction';
import { getLinkToWallet } from './utils/getLinkToWallet';
import { UserIconNotification } from './events/UserIconNotification';
import {
  ReimbursementCaseRejectedInAppNotification,
  ReimbursementCaseRejectedInAppNotificationToast,
} from './events/ReimbursementCaseRejectedInAppNotification';
import {
  ReimbursementCaseApprovedInAppNotification,
  ReimbursementCaseApprovedInAppNotificationToast,
} from './events/ReimbursementCaseApprovedInAppNotification';
import {
  ReimbursementCaseAmendmentRequestedInAppNotification,
  ReimbursementCaseAmendmentRequestedInAppNotificationToast,
} from './events/ReimbursementCaseAmendmentRequestedInAppNotification';
import {
  ReimbursementCaseCommentCreated,
  ReimbursementCaseCommentCreatedInAppNotificationToast,
} from './events/ReimbursementCaseCommentCreatedInAppNotification';
import {
  ReimbursementCaseItemExcludedInAppNotification,
  ReimbursementCaseItemExcludedInAppNotificationToast,
} from './events/ReimbursementCaseItemExcludedInAppNotification';
import { getLinkToArchiveReimbursement } from './utils/getLinkToArchiveReimbursement';

export type NotificationType = NonNullable<
  NonNullable<InAppNotificationSubscription['inAppNotification']>['__typename']
>;

export type NotificationProps<T extends NotificationType> = Extract<
  NonNullable<NonNullable<InAppNotificationSubscription['inAppNotification']>>,
  { __typename: T }
>;

type Link = ComponentProps<typeof RouterLink>['to'];

export interface NotificationInfo {
  // biome-ignore lint/nursery/noRestrictedTypes: <explanation>
  content: FunctionComponent<any>;
  // biome-ignore lint/nursery/noRestrictedTypes: <explanation>
  icon: FunctionComponent<any>;
  // biome-ignore lint/nursery/noRestrictedTypes: <explanation>
  toast: FunctionComponent<any>;
  getLinkTo: (params: any) => { link: Link };
}

const typeToNotificationInfo: { [key in NotificationType]?: NotificationInfo } =
  {
    CardRequestApprovedInAppNotification: {
      content: CardRequestApprovedInAppNotification,
      icon: props => <UserIconNotification user={props.cardManager} />,
      toast: CardRequestApprovedInAppNotificationToast,
      getLinkTo: getLinkToApprovedCardRequest,
    },
    CardRequestCreatedInAppNotification: {
      content: CardRequestCreatedInAppNotification,
      icon: props => <UserIconNotification user={props.requester} />,
      toast: CardRequestCreatedInAppNotificationToast,
      getLinkTo: getLinkToCardRequest,
    },
    CardRequestRejectedInAppNotification: {
      content: CardRequestRejectedInAppNotification,
      icon: props => <UserIconNotification user={props.cardManager} />,
      toast: CardRequestRejectedInAppNotificationToast,
      getLinkTo: getLinkToWallet,
    },
    DocumentCommentCreatedInAppNotification: {
      content: DocumentCommentCreatedInAppNotification,
      icon: props => <UserIconNotification user={props.author} />,
      toast: DocumentCommentCreatedInAppNotificationToast,
      getLinkTo: getLinkToInvoiceOrECMDocument,
    },
    DocumentRejectedInAppNotification: {
      content: DocumentRejectedInAppNotification,
      icon: props => <UserIconNotification user={props.rejectedBy} />,
      toast: DocumentRejectedInAppNotificationToast,
      getLinkTo: getLinkToInvoice,
    },
    TransactionCommentCreatedInAppNotification: {
      content: TransactionCommentCreatedInAppNotification,
      icon: props => <UserIconNotification user={props.author} />,
      toast: TransactionCommentCreatedInAppNotificationToast,
      getLinkTo: getLinkToTransaction,
    },
    TransactionDeclinedInAppNotification: {
      icon: TransactionDeclinedInAppNotificationIcon,
      toast: TransactionDeclinedInAppNotificationToast,
      content: TransactionDeclinedInAppNotification,
      getLinkTo: getLinkToTransaction,
    },
    ReimbursementCaseAmendmentRequestedInAppNotification: {
      icon: props => <UserIconNotification user={props.reviewedBy} />,
      toast: ReimbursementCaseAmendmentRequestedInAppNotificationToast,
      content: ReimbursementCaseAmendmentRequestedInAppNotification,
      getLinkTo: getLinkToArchiveReimbursement,
    },
    ReimbursementCaseApprovedInAppNotification: {
      icon: props => <UserIconNotification user={props.approvedBy} />,
      toast: ReimbursementCaseApprovedInAppNotificationToast,
      content: ReimbursementCaseApprovedInAppNotification,
      getLinkTo: getLinkToArchiveReimbursement,
    },
    ReimbursementCaseCommentCreatedInAppNotification: {
      icon: props => <UserIconNotification user={props.author} />,
      toast: ReimbursementCaseCommentCreatedInAppNotificationToast,
      content: ReimbursementCaseCommentCreated,
      getLinkTo: getLinkToArchiveReimbursement,
    },
    ReimbursementCaseItemExcludedInAppNotification: {
      icon: props => <UserIconNotification user={props.updatedBy} />,
      toast: ReimbursementCaseItemExcludedInAppNotificationToast,
      content: ReimbursementCaseItemExcludedInAppNotification,
      getLinkTo: getLinkToArchiveReimbursement,
    },
    ReimbursementCaseRejectedInAppNotification: {
      icon: props => <UserIconNotification user={props.rejectedBy} />,
      toast: ReimbursementCaseRejectedInAppNotificationToast,
      content: ReimbursementCaseRejectedInAppNotification,
      getLinkTo: getLinkToArchiveReimbursement,
    },
  };

export const getNotificationInfo = (notificationType: NotificationType) => {
  const notificationInfo = typeToNotificationInfo[notificationType];

  return notificationInfo;
};

export const getNotificationTypes = () => {
  const notificationTypes = Object.keys(typeToNotificationInfo);

  return notificationTypes as InAppNotificationType[];
};

import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import {
  ConnectionStatus,
  useSapB1SettingsQuery,
  useVerifySapB1CredentialsQuery,
} from '../../../../generated-types/graphql.types';
import { useSap } from '../../../../orgConfig/sap';
import { SapFormValues } from './components/Form/SapConnectionForm';

export const adminModalHash = '#contactAdmin';

export const sanitizeFormData = (formData: SapFormValues) => {
  return {
    ...formData,
    username: formData.username.replace(/\\/, '\\'),
  };
};

export const useNeedsSapConfig = () => {
  const { shouldUseSapUdfCheck: isSapUdfCheckEnabled, isActiveIntegration } =
    useSap();

  const { data: sapConfigData } = useVerifySapB1CredentialsQuery({
    skip: !isActiveIntegration,
  });

  const { data: sapB1Settings } = useSapB1SettingsQuery({
    skip: !isActiveIntegration || !isSapUdfCheckEnabled,
  });

  const sapB1SettingsFaulty =
    isSapUdfCheckEnabled &&
    sapB1Settings?.sapB1Settings?.connectionDetails?.connectionStatus !==
      ConnectionStatus.ConnectedSuccessfully;

  // If we have a code, it means some kind of validation error happened, but we don't care about the
  // details here
  const sapConfigFaulty = Boolean(sapConfigData?.verifySapB1Credentials?.code);

  return isActiveIntegration && (sapConfigFaulty || sapB1SettingsFaulty);
};

export const useNeedsSapCreditCardConfig = () => {
  const { isActiveIntegration } = useSap();
  const creditCardsSetup = useCreditCardsSetup();
  const { data: sapB1Settings, loading } = useSapB1SettingsQuery({
    skip: !isActiveIntegration,
  });

  const {
    moneyInTransitGeneralLedgerAccount,
    creditCardId,
    creditCardGeneralLedgerAccount,
  } = sapB1Settings?.sapB1Settings || {};

  const sapB1SettingsFaulty = !(
    moneyInTransitGeneralLedgerAccount &&
    creditCardId &&
    creditCardGeneralLedgerAccount
  );
  const showCreditCardSetupRequires =
    creditCardsSetup.isAddonBought &&
    isActiveIntegration &&
    sapB1SettingsFaulty;

  return loading ? false : showCreditCardSetupRequires;
};

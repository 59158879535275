import { Button, Drawer, Grid, Heading, useId } from '@candisio/design-system';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useUpdateOrganizationSettings } from './hooks/useUpdateOrganizationSettings';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { SettingsFormContainer } from './SettingsFormContainer';
import { BILLING_HASH } from 'containers/credit-cards/constants';

export const OrganizationSettingsDrawer = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { update, updating } = useUpdateOrganizationSettings();
  const navigate = useNavigate();
  const location = useLocation();

  const formId = useId();

  const handleClose = () => {
    navigate(location.pathname);
  };

  const isOpen = location.hash === `#${BILLING_HASH}`;

  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      <DrawerLayout
        onClose={handleClose}
        header={
          <Heading as="h3">
            {t('insights.organizationSettingsDrawer.title')}
          </Heading>
        }
        footer={
          <Grid>
            <Button
              justifySelf="start"
              form={formId}
              type="submit"
              loading={updating}
              disabled={updating}
            >
              {t('insights.organizationSettingsDrawer.form.cta')}
            </Button>
          </Grid>
        }
      >
        <SettingsFormContainer
          formId={formId}
          update={update}
          onClose={handleClose}
        />
      </DrawerLayout>
    </Drawer>
  );
};

import { Locale } from 'generated-types/graphql.types';

type Error = {
  code?: string | null;
  message?: string | null;
  location?: string | null;
  codeHelpCenterUrl?: {
    en?: string;
    de?: string;
  } | null;
};

export const extractEInvoiceErrorProps = (
  error: Error,
  locale?: Locale | null
) => {
  return {
    code: error.code || '',
    message: error.message || '',
    location: error.location || '',
    helpCenterArticleUrl:
      locale === 'EN'
        ? error.codeHelpCenterUrl?.en || ''
        : error.codeHelpCenterUrl?.de || '',
  };
};

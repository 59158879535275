import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useCallback, useMemo } from 'react';
import { approvalButtonGroupKeys } from '../utils';
import { Key } from 'react-aria-components';

interface UseUrlMenuButtonsActionsProps {
  approvalFilterButtonKey: {
    filter: string;
  };
  setApprovalFilterButtonKey: (value: { filter: string }) => void;
}

export const useUrlMenuButtonsActions = ({
  approvalFilterButtonKey,
  setApprovalFilterButtonKey,
}: UseUrlMenuButtonsActionsProps) => {
  const user = useCurrentUser();
  const { searchParams, setSearchParams } = useMutateSearchParams();

  const selectedMenuButtonKeysFromLocalStorage = useMemo(() => {
    const appliedFilters: string[] = [];

    if (
      approvalFilterButtonKey.filter === approvalButtonGroupKeys.assignedToMe
    ) {
      appliedFilters.push(approvalButtonGroupKeys.assignedToMe);
      return appliedFilters;
    }

    if (
      approvalFilterButtonKey.filter === approvalButtonGroupKeys.requestedByMe
    ) {
      appliedFilters.push(approvalButtonGroupKeys.requestedByMe);
      return appliedFilters;
    }

    if (approvalFilterButtonKey.filter === approvalButtonGroupKeys.all) {
      appliedFilters.push(approvalButtonGroupKeys.all);
      return appliedFilters;
    }

    return appliedFilters;
  }, [approvalFilterButtonKey]);

  const applyMenuButtonFilterToUrl = useCallback(
    (value: Key[]) => {
      if (value) {
        searchParams.delete('approvers');
        searchParams.delete('requester');
      }

      value.forEach(filter => {
        if (filter === approvalButtonGroupKeys.assignedToMe) {
          searchParams.set('approvers', user?.id ?? '');
          setApprovalFilterButtonKey({
            filter: approvalButtonGroupKeys.assignedToMe,
          });
          return;
        }

        if (filter === approvalButtonGroupKeys.requestedByMe) {
          searchParams.set('requester', user?.id ?? '');
          setApprovalFilterButtonKey({
            filter: approvalButtonGroupKeys.requestedByMe,
          });
          return;
        }

        if (filter === approvalButtonGroupKeys.all) {
          setApprovalFilterButtonKey({ filter: approvalButtonGroupKeys.all });
          return;
        }
      });

      setSearchParams(searchParams);
    },
    [searchParams, user, setSearchParams, setApprovalFilterButtonKey]
  );

  return {
    selectedMenuButtonKeys: selectedMenuButtonKeysFromLocalStorage,
    applyMenuButtonFilterToUrl,
  };
};

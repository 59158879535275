import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { DocumentsTableData } from '../types';
import { SplitsCell } from './SplitsCell';

export type GLACellValue = (undefined | string)[];

export const GLACell = (props: CellProps<DocumentsTableData, GLACellValue>) => {
  const formattedGLA = useFormattedGLA(props.value);
  return <SplitsCell {...props} value={formattedGLA} />;
};

export const useFormattedGLA = (values: GLACellValue) => {
  const { friendlyFormatGLA, normalizeGLA } = useAccountingNumberFormatters();
  return useMemo(() => {
    if (!values) return values;

    return values.map(value => {
      if (!value) return value;
      const [code, name] = normalizeGLA(value) ?? [];
      const formattedGLACode = friendlyFormatGLA(code);

      return name ? `${formattedGLACode} - ${name}` : formattedGLACode;
    });
  }, [friendlyFormatGLA, normalizeGLA, values]);
};

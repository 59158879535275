import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  reimbursementCaseByIdQuery,
  reimbursementHistoryQuery,
  reimbursementItemsListQuery,
  reimbursementItemsSplitBookingsQuery,
} from '../toolkit/queries';
import { useCreatePerDiemItemMutation } from 'generated-types/graphql.types';
import { ReimbursementRouteParams } from './useReimbursementNavigation';

export const useCreatePerDiem = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<ReimbursementRouteParams>();
  const { error } = useToastMessage();
  const [createPerDiemItem, { loading: isCreatePerDiemPending }] =
    useCreatePerDiemItemMutation({
      onError: () => error(t('reimbursementView.createErr')),
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: reimbursementCaseByIdQuery,
          variables: {
            id: reimbursementId,
          },
        },
        {
          query: reimbursementItemsListQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
        {
          query: reimbursementHistoryQuery,
          variables: { id: reimbursementId },
        },
        {
          query: reimbursementItemsSplitBookingsQuery,
          variables: { filters: { reimbursementCaseId: reimbursementId } },
        },
      ],
    });

  const handleCreatePerDiem = async () => {
    const { data, errors } = await createPerDiemItem({
      variables: {
        input: {
          reimbursementCaseId: reimbursementId,
        },
      },
    });

    if (errors?.length) {
      error(t('reimbursementView.createErr'));

      return;
    }

    if (data?.createPerDiemItem.id) {
      return data.createPerDiemItem.id;
    }
  };

  return {
    isCreatePerDiemPending,
    createPerDiem: handleCreatePerDiem,
  };
};

import {
  EcmDocumentTypeSettingsSortOrder,
  useEcmDocumentTypeSettingsQuery,
} from 'generated-types/graphql.types';
import { DEFAULT_SORT_BY } from './consts';

export function useDocumentTypesData(
  params: { isVisible?: boolean; includeNonEcmTypes?: boolean } = {}
) {
  return useEcmDocumentTypeSettingsQuery({
    variables: {
      input: {
        sort: {
          [DEFAULT_SORT_BY]: EcmDocumentTypeSettingsSortOrder.Desc,
        },
        isVisible: params.isVisible,
        includeNonEcmTypes: params.includeNonEcmTypes,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
}

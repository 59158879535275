import { KeyboardEvent, ReactNode } from 'react';
import { ScrollBox } from '@candisio/design-system/next';
import styles from './BookingsFormLayout.module.css';

type BookingsFormLayoutProps = {
  header: ReactNode;
  children: ReactNode;
  footer: ReactNode;
  onFormSubmit?: () => void;
};

export const BookingsFormLayout = ({
  header,
  children,
  footer,
  onFormSubmit,
}: BookingsFormLayoutProps) => {
  return (
    <form
      className={styles.layout}
      data-cy="bookings-table-form"
      onKeyDownCapture={(e: KeyboardEvent<HTMLFormElement>) => {
        if (
          e.key === 'Enter' &&
          e.target instanceof HTMLElement &&
          e.target.nodeName !== 'TEXTAREA' &&
          e.target.nodeName !== 'BUTTON'
        ) {
          e.preventDefault();
        }
      }}
      onSubmit={onFormSubmit}
    >
      <div className={styles.header}>{header}</div>
      <div className={styles.content}>
        <div className={styles.contentWrapper}>
          <ScrollBox className="h-full">
            <div className="min-w-full">{children}</div>
          </ScrollBox>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerContent}>{footer}</div>
      </div>
    </form>
  );
};

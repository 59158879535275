import React from 'react';
import { PolymorphicComponentProps } from 'react-polymorphic-box';
import { ColorProps, LayoutProps, TypographyProps } from '../../../types';
import { Box } from '../../Box';

const DEFAULT_ELEMENT = 'span';

interface TextOwnProps extends LayoutProps, ColorProps, TypographyProps {
  as?: keyof JSX.IntrinsicElements;
}

export type TextProps<
  TElement extends React.ElementType = typeof DEFAULT_ELEMENT,
> = PolymorphicComponentProps<TElement, TextOwnProps>;

type TextType = <TElement extends React.ElementType = typeof DEFAULT_ELEMENT>(
  props: TextProps<TElement>
) => React.ReactElement | null;

/**
 *
 * @deprecated Use CSS modules or tailwind instead. @example `<span className="...">...</span>`
 *
 * Text is the most generic typography component. Use it for generic text.
 *
 * @param {keyof JSX.IntrinsicElements} as Type of element to be rendered as text. Default `span`.
 *
 * @see [Storybook](https://candisio.github.io/design-system/?path=/docs/atoms-typography-text)
 */
export const Text = React.forwardRef(
  <TElement extends React.ElementType>(
    props: TextProps<TElement>,
    ref: typeof props.ref
  ) => {
    return <Box as={DEFAULT_ELEMENT} {...props} ref={ref} />;
  }
) as TextType;

import { Box, Button, Grid, Paragraph } from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { DocumentRequestErrors } from 'components/ProcessSidebar/styles';
import { WorkflowVisualization } from 'components/WorkflowVisualization/WorkflowVisualization';
import {
  UserRole,
  WorkflowVisualizationFragment,
  useCountDocumentsByStatusApprovedForApprovalBlockQuery,
} from 'generated-types/graphql.types';
import { ReactNode, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormAction } from 'views/Inbox/DocumentProcessing/components/FormAction';
import { DocumentApprovalFormValues } from '../toolkit/approvalFormSchema';

type ApprovalsFormActionsProps = {
  isRejected: boolean;
  isSubmitting: boolean;
  onReadonly?: (state: boolean) => void;
  onSubmitRejectionComment?: () => void;
  setRejectionState: (isRejection: boolean) => void;
  toggleEditMode?: () => void;
  onSubmit: () => void;
  workflow?: WorkflowVisualizationFragment;
};

interface ActionTitleEditProps {
  error?: boolean;
  children: ReactNode;
}

interface ButtonsWrapperProps {
  children: ReactNode;
}

interface CommentWrapperProps {
  children: ReactNode;
}

export const ActionTitleEdit = ({ children }: ActionTitleEditProps) => {
  return (
    <Grid
      autoFlow="column"
      alignItems="center"
      justifyContent="space-between"
      textAlign="center"
      fontSize="small"
      color="gray600"
    >
      {children}
    </Grid>
  );
};

export const ButtonsWrapper = ({ children }: ButtonsWrapperProps) => {
  return (
    <Grid autoFlow="column" justifyContent="stretch" gap="space8" flex={1}>
      {children}
    </Grid>
  );
};

export const CommentWrapper = ({ children }: CommentWrapperProps) => {
  return (
    <Box padding="0 -space12" paddingBottom="space4">
      {children}
    </Box>
  );
};

export const MAXIMUM_EXPORT_STACK_SIZE_LENGTH = 800;

export const Actions = ({
  isRejected,
  isSubmitting,
  onReadonly,
  setRejectionState,
  toggleEditMode,
  workflow,
  onSubmit,
}: ApprovalsFormActionsProps) => {
  const [t] = useTranslation();
  const approvalForm = useFormContext<DocumentApprovalFormValues>();
  const errors = approvalForm.formState.errors;
  const hasError = Object.entries(errors).length > 0;
  const formIsInErrorState = hasError;

  const {
    data: {
      countDocumentsByStatus: { count: approvedDocuments = null } = {},
    } = {},
  } = useCountDocumentsByStatusApprovedForApprovalBlockQuery({
    fetchPolicy: 'no-cache',
  });

  let blockApprovalOnExportCountLimit = false;

  if (
    approvedDocuments &&
    approvedDocuments > MAXIMUM_EXPORT_STACK_SIZE_LENGTH
  ) {
    blockApprovalOnExportCountLimit = true;
  }

  const toggleDenialDialog = useCallback(() => {
    setRejectionState(!isRejected);
    onReadonly?.(!isRejected);
  }, [setRejectionState, isRejected, onReadonly]);

  const errorMessage = useMemo(() => {
    if (blockApprovalOnExportCountLimit) {
      return t('document.approveDocument.errors.approvalBlocked');
    }

    if (hasError) {
      return (
        errors.root?.message ?? t('document.approveDocument.errors.required')
      );
    }

    return '';
  }, [blockApprovalOnExportCountLimit, errors.root?.message, hasError, t]);

  return (
    <FormAction status={formIsInErrorState ? 'error' : 'default'}>
      <Grid gap="space8">
        <ActionTitleEdit>
          <Paragraph textTransform="uppercase">
            {t('document.requestApproval.actions.title')}
          </Paragraph>
          <UserRoleAccessWrapper
            allowedRoles={[
              UserRole.Accountant,
              UserRole.Admin,
              UserRole.Requester,
            ]}
          >
            <Button
              data-cy="admin-edit-invoice-button"
              variant="tertiary"
              size="small"
              disabled={formIsInErrorState || isSubmitting}
              onClick={toggleEditMode}
            >
              {t('document.requestApproval.actions.edit')}
            </Button>
          </UserRoleAccessWrapper>
        </ActionTitleEdit>
        {(blockApprovalOnExportCountLimit ||
          (formIsInErrorState && !isRejected)) && (
          <DocumentRequestErrors>{errorMessage}</DocumentRequestErrors>
        )}
        {!isRejected && (
          <>
            <ButtonsWrapper>
              <Button
                color="blue"
                flexGrow={1}
                data-cy="submit-approval-button"
                disabled={
                  blockApprovalOnExportCountLimit ||
                  (isSubmitting && formIsInErrorState)
                }
                loading={isSubmitting && !isRejected}
                onClick={onSubmit}
              >
                {t('document.approveDocument.actions.approve')}
              </Button>
              <Button
                flexGrow={1}
                color="blue"
                variant="secondary"
                disabled={blockApprovalOnExportCountLimit || isSubmitting}
                loading={isSubmitting && isRejected}
                onClick={toggleDenialDialog}
                data-cy="submit-rejection-button"
              >
                {t('document.approveDocument.actions.deny')}
              </Button>
            </ButtonsWrapper>
            {workflow && <WorkflowVisualization workflow={workflow} />}
          </>
        )}

        {isRejected && (
          <>
            <CommentWrapper>
              <HookFormTextareaField
                autoFocus
                variant={formIsInErrorState ? 'error' : 'default'}
                data-cy="rejection-reason-input"
                name="comment"
                placeholder={t(
                  'document.approveDocument.inputs.comment.placeholder'
                )}
              />
            </CommentWrapper>

            <ButtonsWrapper>
              <Button
                onClick={toggleDenialDialog}
                disabled={blockApprovalOnExportCountLimit || isSubmitting}
                variant="tertiary"
              >
                {t(
                  'document.approveDocument.actions.cancelRejectionButtonTitle'
                )}
              </Button>
              <Button
                disabled={blockApprovalOnExportCountLimit || isSubmitting}
                loading={isSubmitting}
                onClick={onSubmit}
                variant="secondary"
                color="red"
                data-cy="send-rejection-button"
              >
                {t('document.approveDocument.actions.sendComment')}
              </Button>
            </ButtonsWrapper>
          </>
        )}
      </Grid>
    </FormAction>
  );
};

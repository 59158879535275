import { DateValue, parseDate } from '@internationalized/date';
import { FocusEventHandler, ReactNode, forwardRef, useRef } from 'react';
import { mergeProps, useDatePicker } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { useDatePickerState } from 'react-stately';
import { Calendar } from '../../Atoms/Calendar';
import { DateInput, parseValue } from '../../Atoms/DateInput';
import { Variant, useFieldContext } from '../../Atoms/FieldContainer';
import { Grid } from '../../Atoms/Grid';
import { Popover, usePopover } from '../../Atoms/Popover';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { getReactAriaSecretProps } from '../../utils/getReactAriaSecretProps';
import { Icon } from '../Icon/next';
import styles from './Datepick.module.css';
import { useDatePickerPlaceholderSync } from './useDatePickerPlaceholderSync';

export interface DatePickerProps
  extends LayoutProps,
    Omit<StandardHTMLAttributes<HTMLDivElement>, 'defaultValue' | 'onChange'> {
  /** Should picker receive focus on render? */
  autoFocus?: boolean;
  /** Label for calendar button */
  calendarLabel?: string;
  /** Allow to clear value */
  clearable?: boolean;
  /** message to display in popover of clear button */
  clearLabel?: string;
  /** (Uncontrolled) initial value of the picker in ISO 8601 date format */
  defaultValue?: string;
  /** Is picker disabled? */
  disabled?: boolean;
  /** Locale to use to format date */
  locale?: string;
  /** Called when the value is changed by the user */
  onChange?: (value: string) => void;
  /** Is picker read only? */
  readOnly?: boolean;
  /** (Controlled) value of the picker in ISO 8601 date format */
  value?: string;
  /** Label for today button */
  todayLabel?: string;
  /** Label for next month button */
  nextMonthLabel?: string;
  /** Label for previous month button */
  prevMonthLabel?: string;
  /** Minimum date that can be selected in ISO 8601 date format */
  minValue?: string;
  /** Maximum date that can be selected in ISO 8601 date format */
  maxValue?: string;
  /** status variant i.e success, warning, error */
  variant?: Variant;
  /** message to display in popover of status */
  message?: ReactNode;
  /** Show message on focus*/
  showMessageOnFocus?: boolean;
  /** Should show error even read only */
  showStatusOnReadOnly?: boolean;
}

/** An input for picking a date */
export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
  (
    {
      'aria-describedby': ariaDescribedby,
      'aria-labelledby': ariaLabelledby,
      autoFocus = false,
      calendarLabel = 'Calendar',
      clearable,
      clearLabel,
      defaultValue,
      disabled,
      id,
      locale,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyUp,
      readOnly,
      value,
      todayLabel,
      nextMonthLabel,
      prevMonthLabel,
      minValue: minValueProps,
      maxValue: maxValueProps,
      message,
      variant,
      showMessageOnFocus = false,
      showStatusOnReadOnly = false,
      ...restProps
    },
    forwardedRef
  ) => {
    const { inputProps, inputRef } = useFieldContext();

    const minValue = minValueProps ? parseDate(minValueProps) : undefined;
    const maxValue = maxValueProps ? parseDate(maxValueProps) : undefined;

    const { handleOpenChange, placeholderValue } =
      useDatePickerPlaceholderSync();

    const state = useDatePickerState({
      defaultValue:
        typeof defaultValue === 'string' ? parseValue(defaultValue) : undefined,
      granularity: 'day',
      onChange: (value: DateValue | null) => {
        const isoString = value?.toString() ?? '';
        onChange?.(isoString);
      },
      value: typeof value === 'string' ? parseValue(value) : undefined,
      minValue,
      maxValue,
      placeholderValue,
      onOpenChange: handleOpenChange,
    });

    const ref = useRef<HTMLDivElement>(null);
    const { fieldProps, groupProps, dialogProps, buttonProps, calendarProps } =
      useDatePicker(
        {
          'aria-describedby': ariaDescribedby ?? inputProps['aria-describedby'],
          'aria-labelledby': ariaLabelledby ?? inputProps['aria-labelledby'],
          autoFocus,
          granularity: 'day',
          id: id ?? inputProps.id,
          isDisabled: disabled,
          isReadOnly: readOnly,
          onBlur: onBlur as FocusEventHandler<Element>,
          onFocus: onFocus as FocusEventHandler<Element>,
          onKeyDown,
          onKeyUp,
          minValue,
          maxValue,
          placeholderValue,
          onOpenChange: handleOpenChange,
        },
        state,
        ref
      );

    const { secretProps } = getReactAriaSecretProps(fieldProps);

    const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
      usePopover({
        isOpen: state.isOpen,
        onOpenChange: state.toggle,
        placement: 'bottom',
      });

    return (
      <>
        <Grid
          gap="space8"
          templateColumns="1fr auto"
          alignItems="center"
          {...mergeProps(inputProps, groupProps, restProps)}
          // Manually set id to work around React Aria bug:
          // https://github.com/adobe/react-spectrum/issues/3969
          id={id ?? inputProps.id ?? groupProps.id}
          ref={mergeRefs([ref, inputRef, forwardedRef])}
        >
          <DateInput
            aria-describedby={fieldProps['aria-describedby']}
            aria-labelledby={fieldProps['aria-labelledby']}
            autoFocus={fieldProps.autoFocus}
            clearable={clearable}
            clearLabel={clearLabel}
            disabled={fieldProps.isDisabled}
            id={fieldProps.id}
            locale={locale}
            onChange={value => {
              // https://github.com/adobe/react-spectrum/issues/3187
              fieldProps.onChange?.(parseValue(value));
            }}
            readOnly={fieldProps.isReadOnly}
            value={fieldProps.value?.toString() ?? ''}
            message={message}
            variant={variant}
            showMessageOnFocus={showMessageOnFocus}
            showStatusOnReadOnly={showStatusOnReadOnly}
            // React Aria passes a secret prop that, when present, allows focus
            // to be managed by `useDatePicker` here instead of `useDateField`
            // in DateInput component
            // https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/datepicker/src/useDateField.ts#L52
            {...secretProps}
          />
          {!disabled && !readOnly && (
            <Grid
              className={styles.trigger}
              aria-describedby={buttonProps['aria-describedby']}
              aria-haspopup={buttonProps['aria-haspopup']}
              aria-labelledby={buttonProps['aria-labelledby']}
              id={buttonProps.id}
              {...triggerProps}
              ref={triggerRef}
            >
              <Icon icon="caretDown" className="size-4 text-gray-450" />
            </Grid>
          )}
        </Grid>

        {isOpen && (
          <Popover
            padding={0}
            {...mergeProps(popoverProps, dialogProps)}
            ref={popoverRef}
          >
            <Calendar
              autoFocus={calendarProps.autoFocus}
              disabled={calendarProps.isDisabled}
              locale={locale}
              onChange={value => {
                // @ts-expect-error `DateValue` type should allow `null`
                // https://github.com/adobe/react-spectrum/issues/3187
                calendarProps.onChange?.(parseValue(value));
              }}
              todayLabel={todayLabel}
              nextMonthLabel={nextMonthLabel}
              prevMonthLabel={prevMonthLabel}
              readOnly={calendarProps.isReadOnly}
              value={calendarProps.value?.toString() ?? ''}
              minValue={calendarProps.minValue ?? undefined}
              maxValue={calendarProps.maxValue ?? undefined}
            />
          </Popover>
        )}
      </>
    );
  }
);

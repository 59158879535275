import { useInAppNotificationsPageBasedData } from 'containers/notifications/hooks/useInAppNotificationsPageBasedData';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { NotificationSummary } from './NotificationSummary/NotificationSummary';

interface NotificationSummaryContainerProps {
  onClose: () => void;
  currentCompanyOnly: boolean;
  showOnlyUnread: boolean;
}

export const InAppNotifications = ({
  onClose,
  currentCompanyOnly,
  showOnlyUnread,
}: NotificationSummaryContainerProps) => {
  const currentOrganizationId = useOrganizationId() ?? undefined;

  const { notificationDetails, hasMore, onLoadMore, loading } =
    useInAppNotificationsPageBasedData({
      organizationId: currentCompanyOnly ? currentOrganizationId : undefined,
      showOnlyUnread,
    });

  const { notifications, totalCount, unreadNotifications } =
    notificationDetails;

  const notificationList = showOnlyUnread ? unreadNotifications : notifications;

  return (
    <NotificationSummary
      loading={loading}
      hasMore={hasMore}
      totalCount={totalCount}
      onLoadMore={onLoadMore}
      onClose={onClose}
      showOnlyUnread={showOnlyUnread}
      notifications={notificationList}
      currentCompanyOnly={currentCompanyOnly}
    />
  );
};

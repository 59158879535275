import { useToastMessage } from 'components/Toast/useToastMessage';
import { CARD_ERROR_TRANSLATIONS } from 'containers/credit-cards/CreditCardDrawer/Tabs/Request/consts';
import { RecurringPaymentVendorSource } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useAccountingInfoFormDefaultValues } from 'views/CreditCards/hooks/useAccountingInfoFormDefaultValues';
import { useAccountingInfoFormFieldOptions } from 'views/CreditCards/hooks/useAccountingInfoFormFieldOptions';
import { useGetVendorCardholderSuggestion } from 'views/CreditCards/hooks/useGetVendorCardholderSuggestion';
import { useGetVendorForRecurringPaymentsById } from 'views/CreditCards/hooks/useGetVendorForRecurringPaymentsById';
import { useCreateCreditCardModalState } from '../CreateCreditCardModalDataProvider';
import { useCreateCreditCardAction } from '../hooks/useCreateCreditCardAction';
import { AccountingInfoForm } from './AccountingInfoForm';
import { AccountingInfoFormSchemaFormValues } from './utils/accountingInfoFormSchema';
import { transformFormValuesToAccountingData } from './utils/utils';

interface AccountingInfoFormContainerProps {
  onCardCreatedSuccess: (cardId: string) => void;
  suggestedAmount?: number;
}

export const AccountingInfoFormContainer = ({
  onCardCreatedSuccess,
  suggestedAmount,
}: AccountingInfoFormContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success, error } = useToastMessage();
  const user = useCurrentUser();
  const { isCardManager } = useUserRoles();
  const { vendorId } = useParams<{ vendorId?: string }>();

  const { vendorDetails, loading: loadingVendor } =
    useGetVendorForRecurringPaymentsById({
      vendorId,
      fetchPolicy: 'cache-first',
    });

  const modalState = useCreateCreditCardModalState();
  const action = useCreateCreditCardAction(onCardCreatedSuccess);

  const { defaultValues } = useAccountingInfoFormDefaultValues({
    values: modalState,
  });

  const { suggestedCardholder } = useGetVendorCardholderSuggestion({
    skip:
      loadingVendor ||
      vendorDetails.source === RecurringPaymentVendorSource.ManuallyAdded,
  });

  const fieldOptions = useAccountingInfoFormFieldOptions();

  const suggestions = {
    suggestedAmount,
    suggestedCardholder,
  };

  const handleSubmit = async (
    formValues: AccountingInfoFormSchemaFormValues
  ) => {
    const handler = isCardManager ? action.issue : action.request;

    const accountingData = transformFormValuesToAccountingData({
      formValues,
    });

    const { status, errors } = await handler({
      ...modalState,
      ...modalState.cardDetails,
      cardType: modalState.cardType,
      teamMember: modalState.teamMemberId ?? '',
      transactionLimit: modalState.cardDetails?.transactionLimit ?? 0,
      currentUserId: user?.id,
      accountingData,
      suggestions,
      vendorId,
      noInvoiceNeeded: formValues.noInvoiceNeeded,
    });

    if (status === 'error') {
      errors?.forEach(err => {
        // XXX This is a hack. Error should be handled properly through the _typename in the response but BE does not support this at the moment.
        for (const [code, message] of Object.entries(CARD_ERROR_TRANSLATIONS)) {
          if (err.message.includes(code)) {
            error(t(message));
            return;
          }
        }
      });
    } else {
      const successToastMessage = isCardManager
        ? 'dashboard.drawer.form.issuedCardSuccessToastMessage'
        : 'dashboard.drawer.form.requestSuccessToastMessage';

      success(t(successToastMessage));
    }
  };

  return (
    <AccountingInfoForm
      onSubmit={handleSubmit}
      fieldOptions={fieldOptions}
      defaultValues={defaultValues}
      isActionLoading={action.loading}
    />
  );
};

import { Grid, Paragraph } from '@candisio/design-system';
import { PaymentConditionsStatusIcon } from 'components/ImportPreviewTable/components/cells/PaymentConditionsStatusIcon';
import { motion } from 'motion/react';
import { useTranslation } from 'react-i18next';

const MotionGrid = motion.create(Grid);

interface PaymentConditionsImportSummaryProps {
  invalidCount: number;
  newCount: number;
  updatedCount: number;
}

export const PaymentConditionsImportSummary = ({
  invalidCount,
  newCount,
  updatedCount,
}: PaymentConditionsImportSummaryProps) => {
  const [t] = useTranslation('settings');

  const totalCount = invalidCount + newCount + updatedCount;

  return (
    <MotionGrid initial={{ opacity: 0 }} animate={{ opacity: 1 }} gap="space8">
      <Paragraph fontWeight="bold">
        {t(
          'paymentConditions.import.importSummaryInfo.totalPaymentConditions',
          { count: totalCount }
        )}
      </Paragraph>
      <Grid as="ul" gap="space4" padding="0" listStyle="none">
        {updatedCount > 0 && (
          <Grid
            as="li"
            templateColumns="auto 1fr"
            gap="space4"
            alignItems="center"
          >
            <PaymentConditionsStatusIcon status="updated" />
            {t(
              'paymentConditions.import.importSummaryInfo.updatedPaymentConditions',
              { count: updatedCount }
            )}
          </Grid>
        )}
        {invalidCount > 0 && (
          <Grid
            as="li"
            templateColumns="auto 1fr"
            gap="space4"
            alignItems="center"
          >
            <PaymentConditionsStatusIcon status="invalid" />
            {t(
              'paymentConditions.import.importSummaryInfo.invalidPaymentConditions',
              { count: invalidCount }
            )}
          </Grid>
        )}
        {newCount > 0 && (
          <Grid
            as="li"
            templateColumns="auto 1fr"
            gap="space4"
            alignItems="center"
          >
            <PaymentConditionsStatusIcon status="new" />
            {t(
              'paymentConditions.import.importSummaryInfo.newPaymentConditions',
              { count: newCount }
            )}
          </Grid>
        )}
      </Grid>
    </MotionGrid>
  );
};

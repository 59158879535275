import moment from 'moment';

export const isSameDate = (
  startingDate?: string | null,
  endingDatePreviousSegment?: string | null,
  timeZone: string = 'Europe/Berlin'
) => {
  const momentStartingDate = moment.tz(startingDate, timeZone);
  const momentEndingDatePreviousSegment = moment.tz(
    endingDatePreviousSegment,
    timeZone
  );

  return momentStartingDate.isSame(momentEndingDatePreviousSegment);
};

import { CellContext } from '@tanstack/react-table';
import { TruncatedText } from '../../../Typography/TruncatedText/next/TruncatedText';

export function Cell<T>({
  getValue,
}: Pick<CellContext<T, unknown>, 'getValue'>) {
  const value = getValue();
  if (!value) {
    return <span>-</span>;
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return <TruncatedText>{String(value)}</TruncatedText>;
  }

  return <>{value}</>;
}

import { useQuery } from '@apollo/client';
import {
  Icon,
  Popover,
  ScrollBox,
  Text,
  usePopover,
} from '@candisio/design-system';
import { Query } from 'generated-types/graphql.types';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { OrganizationsListView } from 'views/AppContainer/components/Header/components/MultiOrganizationOverview/OrganizationsListView';
import { getOrganizationsQuery } from 'views/AppContainer/components/Header/components/MultiOrganizationOverview/getOrganizationsQuery';
import { OrganizationAvatar } from './OrganizationAvatar/OrganizationAvatar';
import styles from './OrganizationSwitcher.module.css';

interface OrganizationSwitcherProps {
  isNavigationOpen: boolean;
}

export const OrganizationSwitcher = ({
  isNavigationOpen,
}: OrganizationSwitcherProps) => {
  const organization = useFullOrganization();
  const organizationName = organization?.name;
  const organizationsQuery = useQuery<Pick<Query, 'getOrganizations'>>(
    getOrganizationsQuery
  );

  const organizations = organizationsQuery.data?.getOrganizations || [];
  const hasOnlyOneOrganization = organizations.length === 1;

  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'right' });

  const isActive = isOpen && !hasOnlyOneOrganization;

  return (
    <>
      <div
        {...triggerProps}
        className={styles.switcher}
        data-active={isActive}
        data-clickable={!hasOnlyOneOrganization}
        data-expanded={isNavigationOpen}
        ref={triggerRef}
        tabIndex={hasOnlyOneOrganization ? -1 : 0}
      >
        <OrganizationAvatar
          organizationName={organizationName}
          showTooltip={!isNavigationOpen && !isOpen}
        />
        {isNavigationOpen && (
          <>
            <Text className={styles.orgName} fontSize="basic" fontWeight="500">
              {organizationName ?? ''}
            </Text>
            {!hasOnlyOneOrganization && (
              <Icon
                flexShrink={0}
                icon={isOpen ? 'caretRight' : 'caretDown'}
                className="text-gray-600"
                size={20}
              />
            )}
          </>
        )}
      </div>

      {isOpen && !hasOnlyOneOrganization && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          paddingX="0"
          paddingY="0"
          width="440px"
        >
          <ScrollBox maxHeight="inherit" padding="0">
            <OrganizationsListView handleCurrentOrganization={close} />
          </ScrollBox>
        </Popover>
      )}
    </>
  );
};

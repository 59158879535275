import { renderIcon } from 'views/AppContainer/components/Header/components/MultiOrganizationOverview/components/OrganizationDetails';
import { useInboxReimbursementCount } from 'hooks/useCounterQueries';
import { useApprovalReimbursementsCounter } from 'views/Approvals/Reimbursements/toolkit/hooks/useApprovalReimbursementsCounter';
import { usePath } from 'hooks/usePath';
import { useCurrentUser } from 'providers/CurrentUserProvider';

interface ReimbursementTask {
  total: number;
  path: string;
  icon: JSX.Element;
}

interface ReimbursementTasks {
  inbox: ReimbursementTask;
  monitoring: ReimbursementTask;
  approving: ReimbursementTask;
  exporting: ReimbursementTask;
}

export const useOrganizationReimbursementTasks = () => {
  const currentUser = useCurrentUser();

  const { inboxReimbursementCount } = useInboxReimbursementCount();

  const {
    approvalsAssignedToCurrentUserCount,
    approvalsRequestedByCurrentUserCount,
  } = useApprovalReimbursementsCounter();

  const ReimbursementIcon = renderIcon('menuTravel');

  const monitoringPath = usePath({
    pathname: '/approvals/reimbursements',
    search: {
      requester: currentUser?.id ?? '',
    },
  });
  const approvingPath = usePath({
    pathname: '/approvals/reimbursements',
    search: {
      approvers: currentUser?.id ?? '',
    },
  });

  const inboxPath = usePath({ pathname: '/inbox/reimbursements' });

  const reimbursementTasks: ReimbursementTasks = {
    inbox: {
      total: inboxReimbursementCount,
      icon: ReimbursementIcon,
      path: inboxPath,
    },
    monitoring: {
      total: Number(approvalsRequestedByCurrentUserCount ?? 0),
      icon: ReimbursementIcon,
      path: monitoringPath,
    },
    approving: {
      total: Number(approvalsAssignedToCurrentUserCount ?? 0),
      icon: ReimbursementIcon,
      path: approvingPath,
    },
    exporting: {
      total: 0,
      icon: ReimbursementIcon,
      path: '',
    },
  };

  return { reimbursementTasks };
};

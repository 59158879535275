import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { noop } from 'lodash';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const SidebarContext = createContext<{
  isNavigationOpen: boolean;
  toggleSidebar: (open?: boolean) => void;
}>({
  isNavigationOpen: false,
  toggleSidebar: () => noop,
});

export const LARGE_SCREEN_WIDTH_THRESHOLD = 1640;
export const MAIN_NAVIGATION_OPEN_ONLOAD = 'main-navigation-open-onload';

export const useNavigationSidebarContext = () => useContext(SidebarContext);

export const NavigationSidebarProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const isLargeDesktop = window.innerWidth >= LARGE_SCREEN_WIDTH_THRESHOLD;
  const previousWidth = useRef(window.innerWidth);

  const [isOpenOnload, setIsOpenOnload] = useLocalStorage(
    MAIN_NAVIGATION_OPEN_ONLOAD,
    isLargeDesktop
  );

  const [isNavigationOpen, setIsNavigationOpen] = useState(
    isLargeDesktop ? isOpenOnload : false
  );

  const [userToggled, setUserToggled] = useState(false);

  const toggleSidebar = useCallback(
    (open?: boolean) => {
      setUserToggled(true);
      setIsNavigationOpen(isNavigationOpen => open ?? !isNavigationOpen);
      setIsOpenOnload(isOpen => open ?? !isOpen);
    },
    [setIsOpenOnload]
  );

  const handleResize = useCallback(() => {
    const currentWidth = window.innerWidth;

    const sizingDown =
      currentWidth < previousWidth.current &&
      currentWidth < LARGE_SCREEN_WIDTH_THRESHOLD;

    const sizingUp =
      currentWidth > previousWidth.current &&
      currentWidth > LARGE_SCREEN_WIDTH_THRESHOLD;

    // Resizing to smaller: Close the sidebar if it's open
    if (sizingDown) setIsNavigationOpen(false);

    // Resizing to larger: Open the sidebar if user has not manually closed it
    if (sizingUp && !userToggled) setIsNavigationOpen(true);

    previousWidth.current = currentWidth;
  }, [userToggled]);

  useEffect(() => {
    handleResize();

    const controller = new AbortController();
    window.addEventListener('resize', handleResize, {
      signal: controller.signal,
    });

    return () => controller.abort();
  }, [handleResize]);

  const value = useMemo(
    () => ({ isNavigationOpen, toggleSidebar }),
    [isNavigationOpen, toggleSidebar]
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};

import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Link,
} from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { useCreateCreditCardModalSetState } from './CreateCreditCardModalDataProvider';
import { singleUseCardLimitsFormErrorMessages } from './utils/singleUseCardLimitsFormErrorMessages';
import {
  SingleUseCardLimitsFormOutput,
  SingleUseCardLimitsFormValues,
  singleUseCardLimitsFormSchema,
} from './utils/singleUseCardLimitsFormSchema';

export interface SingleUseCardLimitsFormProps {
  defaultValues: SingleUseCardLimitsFormValues;
  onSubmit: (ormValues: SingleUseCardLimitsFormOutput) => void;
}

export const SingleUseCardLimitsForm = ({
  defaultValues,
  onSubmit,
}: SingleUseCardLimitsFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { isCardManager } = useUserRoles();
  const organization = useFullOrganization();
  const setModalState = useCreateCreditCardModalSetState();

  const form = useForm<SingleUseCardLimitsFormOutput>({
    defaultValues,
    mode: 'onTouched',
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
      zodSchema: singleUseCardLimitsFormSchema,
      errorMessages: singleUseCardLimitsFormErrorMessages,
    }),
  });

  return (
    <FormProvider {...form}>
      <Grid
        gap="space16"
        as="form"
        alignItems="start"
        onSubmit={form.handleSubmit(onSubmit)}
        height="100%"
      >
        <Grid gap="space16">
          <Heading as="h2">{t('ccRequestForm.limitsForm.cardLimits')}</Heading>
          <Box width="200px">
            <HookFormAmountField
              autoFocus
              name="transactionLimit"
              label={t('ccRequestForm.fields.transactionLimit.label')}
              placeholder={t(
                'ccRequestForm.fields.transactionLimit.placeholder'
              )}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              currency="EUR"
              minValue={0}
            />
          </Box>
          {!isCardManager && organization?.name && (
            <Box justifySelf="start">
              <Trans
                t={t}
                parent="span"
                i18nKey="ccRequestForm.usageAgreement.text"
                values={{ orgName: organization?.name }}
              >
                I accept
                <Link
                  href={t('ccRequestForm.usageAgreement.url')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  the usage agreement
                </Link>
                of Candis
              </Trans>
            </Box>
          )}
        </Grid>

        <Flex
          justifyContent="space-between"
          alignSelf="end"
          paddingY="space16"
          justifySelf={isCardManager ? 'normal' : 'end'}
        >
          {isCardManager && (
            <Button
              variant="secondary"
              icon="arrowLeft"
              iconPosition="left"
              onClick={() =>
                setModalState(prevState => ({
                  ...prevState,
                  formStep: 'TeamMember',
                }))
              }
            >
              {t('ccRequestForm.goBackCTA')}
            </Button>
          )}
          <Button type="submit" icon="arrowRight" iconPosition="right">
            {t('ccRequestForm.limitsForm.cta')}
          </Button>
        </Flex>
      </Grid>
    </FormProvider>
  );
};

import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { usePath } from 'hooks/usePath';
import { NavLink } from 'react-router-dom-v5-compat';
import styles from './CardExpiresSoonTile.module.css';
import { useGetCountExpiringSoonCards } from 'views/CreditCards/hooks/useGetCountExpiringSoonCards';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Count } from '../../shared/Count/Count';
import { IconBadge } from 'components/IconBadge/IconBadge';
import { getCardExpiringSearchParams } from './utils';

export const CardExpiresSoonTile = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { countExpiringCards } = useGetCountExpiringSoonCards();

  const href = usePath({
    pathname: '/credit_cards/dashboard/card_manager_overview/:creditCardId?',
    search: getCardExpiringSearchParams(),
  });

  if (!countExpiringCards) {
    return null;
  }

  return (
    <SwimlaneTile asChild variant="red">
      <NavLink
        data-cy="cardExpiresSoonTile"
        to={href}
        className={styles.content}
      >
        <IconBadge icon="creditCardBang" variant="red" />
        <div className="flex flex-col gap-1">
          <p className={styles.description}>
            {countExpiringCards === 1
              ? t('insights.requestsOverview.expiringCards')
              : t('insights.requestsOverview.expiringCards_plural')}
          </p>
          <Count count={countExpiringCards} />
        </div>
      </NavLink>
    </SwimlaneTile>
  );
};

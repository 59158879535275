import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import { SapFreightPackageBookingFields } from 'containers/SplitBookings/components/NewSplit/NewSplitForPurchaseOrders';
import { useGetIsReverseChargeTaxCode } from 'containers/SplitBookings/toolkit/hooks/useHasReverseChargeTaxCode';
import { useSap } from 'orgConfig/sap';
import { UseFieldArrayAppend } from 'react-hook-form';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';
import { BookingsFormValues, TableFormRow } from '../schema/bookingsFormSchema';
import {
  calculateRestAmount,
  grossToNet,
} from 'containers/SplitBookings/toolkit/utils';
import { bookingWithNetAmount } from '../utils/amountUtils';
import { createRowSchema } from '../schema/rowSchema';
import { BookingsFormSchemaContext } from '../types';
import { useCallback } from 'react';

type Props = {
  grossAmount: number;
  bookings: TableFormRow[];
  schemaContext: BookingsFormSchemaContext;
  packageFreightValues?: SapFreightPackageBookingFields;
};

export const useGetAddNewBooking = ({
  append,
}: {
  append: UseFieldArrayAppend<BookingsFormValues, 'bookings'>;
}) => {
  const { shouldUseSapPurchaseOrder } = useSap();

  const getIsReverseChargeTaxCode = useGetIsReverseChargeTaxCode();

  const handleAddNewBooking = useCallback(
    ({ grossAmount, bookings, schemaContext, packageFreightValues }: Props) => {
      const remainingAmount = calculateRestAmount({
        grossAmount,
        bookings: [...bookings].map(bookingWithNetAmount),
      });
      const { bookingId, ...lastBooking } = bookings[bookings.length - 1];
      const isLastBookingValid = schemaContext
        ? createRowSchema(schemaContext).safeParse(lastBooking).success
        : true;
      if (!isLastBookingValid) {
        return;
      }
      const isReverseChargeTaxCode = getIsReverseChargeTaxCode(
        lastBooking.taxCode ?? ''
      );

      const updatedAmount =
        lastBooking.taxPresentation === TaxPresentation.Gross
          ? remainingAmount
          : grossToNet(remainingAmount, lastBooking.vatRate ?? 0);

      const netAmount = isReverseChargeTaxCode
        ? updatedAmount
        : grossToNet(remainingAmount, lastBooking.vatRate ?? 0);

      const taxAmount = isReverseChargeTaxCode
        ? 0
        : roundToCurrencyPrecision(remainingAmount - netAmount);

      const newBooking: TableFormRow = {
        ...lastBooking,
        amount: updatedAmount,
        taxAmount,
        netAmount,
        ...(packageFreightValues &&
          shouldUseSapPurchaseOrder && {
            ...packageFreightValues,
            quantity: undefined,
            generalLedgerAccount: {
              value: undefined,
            },
            unitPrice: undefined,
          }),
      };
      append(newBooking);
    },
    [append, getIsReverseChargeTaxCode, shouldUseSapPurchaseOrder]
  );

  return handleAddNewBooking;
};

import { TruncatedText } from '@candisio/design-system/next';
import { castArray } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { DocumentsTableData } from '../../types';
import { CellContext } from '@tanstack/react-table';

type Value = (Date | undefined)[] | (Date | undefined);

export const DateCell = ({
  getValue,
}: CellContext<DocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const value = getValue();

  if (!value) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  const values = castArray(value);

  if (values.every(v => !v)) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  const allValuesAreTheSame = values.every(
    v => v?.getTime() === values?.[0]?.getTime()
  );

  if (values.length > 1 && !allValuesAreTheSame) {
    return (
      <TruncatedText className="italic text-gray-600">
        {t('seeSplitBookings')}
      </TruncatedText>
    );
  }

  return (
    <TruncatedText>{DateFormatters.compact(values[0] as Date)}</TruncatedText>
  );
};

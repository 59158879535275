import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { ReimbursementCaseStatus } from 'types/ReimbursementCaseStatusEnum';
import styles from './SwimlaneTileExpenseContent.module.css';
import { ReimbursementStatusTag } from 'views/Reimbursement/components/ReimbursementStatusTag/ReimbursementStatusTag';
import { NavLink } from 'react-router-dom-v5-compat';
import { SwimlaneTileAmount } from 'components/Swimlane/content/SwimlaneTileAmount';
import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { usePath } from 'hooks/usePath';
import { ArchiveReimbursementCase } from 'views/Reimbursement/hooks/useReimbursementDashboardInsights';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

export const SwimlaneTileExpenseContent = ({
  expenseCase,
}: { expenseCase: ArchiveReimbursementCase }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.COMMON);
  const { status, title, totalAmount } = expenseCase;

  const href = usePath({
    pathname: '/reimbursements_dashboard/:reimbursementId',
    params: {
      reimbursementId: expenseCase.id,
    },
  });

  const variant = status === ReimbursementCaseStatus.Check ? 'red' : 'white';

  const { documentMoneyFormatter } = useMoneyFormatter();

  return (
    <SwimlaneTile
      asChild
      variant={variant}
      className={styles.column}
      data-cy="visibleContentTile"
    >
      <NavLink to={href}>
        <ReimbursementStatusTag status={status} />
        <div>
          <SwimlaneTileDescription>
            {title ?? t('common.untitled')}
          </SwimlaneTileDescription>
          <SwimlaneTileAmount>
            {documentMoneyFormatter({
              amount: totalAmount.amount,
              currency: totalAmount.currency,
              precision: totalAmount.precision,
            })}
          </SwimlaneTileAmount>
        </div>
      </NavLink>
    </SwimlaneTile>
  );
};

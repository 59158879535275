import { SlotProps, Slot } from '@candis/utils';
import { ReactNode } from 'react';
import { WistiaModal } from './WistiaModal';
import { useModal } from '@candisio/design-system';

type FlexibleCTA =
  | ReactNode
  | ((triggerProps: Record<string, any>) => ReactNode);

interface WistiaVideoModalLauncherProps extends SlotProps {
  wistiaId: string;
  children: FlexibleCTA;
}

export const WistiaVideoModalLauncher = ({
  wistiaId,
  children,
  ...restProps
}: WistiaVideoModalLauncherProps) => {
  const { modalProps, triggerProps } = useModal();

  const renderedCTA =
    typeof children === 'function' ? (
      children(triggerProps)
    ) : (
      <Slot {...triggerProps} {...restProps}>
        {children}
      </Slot>
    );

  return (
    <>
      {renderedCTA}
      <WistiaModal wistiaId={wistiaId} {...modalProps} />
    </>
  );
};

import { Grid, Link, Separator, Text } from '@candisio/design-system';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';

export const CardManagerContent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const organization = useFullOrganization();

  const insightsCCLink = `/${organization?.id}${Routes.CREDIT_CARDS_DASHBOARD}`;

  return (
    <Grid>
      <Grid padding="space16" gap="space4" justifyItems="left">
        <Text fontSize="xlarge">
          {t('successPage.cardManager.firstSection.text')}
        </Text>
        <Link
          external
          fontSize="basic"
          icon="arrowRight"
          iconPosition="left"
          href={insightsCCLink}
        >
          {t('successPage.cardManager.firstSection.toInsightsCreditCards.text')}
        </Link>
      </Grid>
      <Separator />

      <Grid padding="space16" gap="space4" justifyItems="left">
        <Text fontSize="xlarge">
          {t('successPage.cardManager.secondSection.text')}
        </Text>
        <Link
          fontSize="basic"
          external
          iconPosition="right"
          icon="newTab"
          href={t('successPage.cardManager.secondSection.toBlogArticle.link')}
        >
          {t('successPage.cardManager.secondSection.toBlogArticle.text')}
        </Link>
      </Grid>
    </Grid>
  );
};

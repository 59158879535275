import { Icon } from '@candisio/design-system';
import { useMissingInvoicesEmailText } from 'views/CreditCards/hooks/useMissingInvoicesEmailText';
import { CardHolderCCInsightsWidgetData } from 'views/CreditCards/hooks/useTransactionsInsights';
import { DateOptionMissingInvoicesWidget } from '../../../MissingInvoicesWidget/utils';
import styles from './MissingInvoiceEmail.module.css';

export const MissingInvoiceEmail = ({
  cardholderInsight,
  href,
  dateOption,
}: {
  cardholderInsight: CardHolderCCInsightsWidgetData;
  href: string;
  dateOption: DateOptionMissingInvoicesWidget;
}) => {
  const { cardholder, invoicesWithoutTransaction } = cardholderInsight;
  const { body, subjectEmail } = useMissingInvoicesEmailText({
    cardholder,
    invoicesWithoutTransaction,
    emailLink: href,
    dateOption,
  });

  const email = cardholder.membershipEmail;
  const url = `mailto:${email}?subject=${subjectEmail}&body=${encodeURIComponent(body)}`;

  const openEmail = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(url, '_blank');
  };

  return (
    <div>
      <div
        className={styles.email}
        role="button"
        tabIndex={0}
        onClick={openEmail}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            openEmail(event);
          }
        }}
      >
        <span className="line-clamp-1 break-all">{email}</span>
        <Icon icon="mail" className={styles.icon} />
      </div>
    </div>
  );
};

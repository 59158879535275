import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SummaryStatus } from './types';

export const ReimbursementCaseEmptyTile = ({
  status,
}: { status: SummaryStatus }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <SwimlaneTile className="items-end">
      <SwimlaneTileDescription>
        {t(`dashboard.swimlanes.emptyTile.${status}`)}
      </SwimlaneTileDescription>
    </SwimlaneTile>
  );
};

import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { approvalButtonGroupKeys, localStorageFilterButtonKey } from '../utils';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useEffect } from 'react';

/**
 * Depending if the local storage value has a value, it pushes a filter in the URL on first render so that the table will be filtered.
 * It also makes sure that if there are already some filters applied we do not interfere with them and do not push anything in the URL even
 * if we have a value in local storage to make sure that deep links from other views work correctly.
 */
export const useApplyLocalStorageFilter = () => {
  const [approvalFilterButtonKey, setApprovalFilterButtonKey] =
    useLocalStorage<{ filter: string }>(localStorageFilterButtonKey, {
      filter: approvalButtonGroupKeys.assignedToMe,
    });

  const currentUser = useCurrentUser();

  const { searchParams, setSearchParams } = useMutateSearchParams();

  // We want to make sure this runs only once after the component has mounted
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    const isApproversAlreadyApplied = !!searchParams.get('approvers');
    const isRequesterAlreadyApplied = !!searchParams.get('requester');

    if (
      approvalFilterButtonKey?.filter ===
        approvalButtonGroupKeys.assignedToMe &&
      isRequesterAlreadyApplied
    ) {
      searchParams.set('requester', currentUser?.id ?? '');

      setSearchParams(searchParams);
      setApprovalFilterButtonKey({
        filter: approvalButtonGroupKeys.requestedByMe,
      });
      return;
    }

    if (
      approvalFilterButtonKey?.filter ===
        approvalButtonGroupKeys.assignedToMe ||
      isApproversAlreadyApplied
    ) {
      searchParams.set('approvers', currentUser?.id ?? '');
      setSearchParams(searchParams);
      setApprovalFilterButtonKey({
        filter: approvalButtonGroupKeys.assignedToMe,
      });
      return;
    }

    if (
      approvalFilterButtonKey?.filter ===
        approvalButtonGroupKeys.requestedByMe ||
      isRequesterAlreadyApplied
    ) {
      searchParams.set('requester', currentUser?.id ?? '');

      setSearchParams(searchParams);
      setApprovalFilterButtonKey({
        filter: approvalButtonGroupKeys.requestedByMe,
      });
      return;
    }
  }, []);

  return { approvalFilterButtonKey, setApprovalFilterButtonKey };
};

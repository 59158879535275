import { Button, Tag, Text, useModal } from '@candisio/design-system';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { useOptimisticPromoPreference } from 'hooks/useOptimisticPromoPreference';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentClassificationPromoModal } from './DocumentClassificationPromoModal';

export const DocumentClassificationPromoPanel = () => {
  const [t] = useTranslation();
  const { isOpen, close, triggerProps, modalProps } = useModal({
    defaultOpen: false,
  });
  const { isSeen, markAsSeen } = useOptimisticPromoPreference(
    TOUR_POPOVER_IDS.CLASSIFICATION_DOCUMENT_TYPE
  );

  if (isSeen) return null;

  return (
    <div className="grid relative bg-blue-bg rounded-small py-4 px-3 leading-5 border border-blue-200 text-basic gap-3 justify-items-start">
      <div className="grid gap-2 w-full">
        <div className="grid grid-flow-col items-start justify-between">
          <Tag variant="callout">{t('tag.new')}</Tag>

          <Button
            variant="tertiary"
            color="gray"
            icon="close"
            size="xsmall"
            onClick={markAsSeen}
          />
        </div>

        <Trans
          parent="span"
          i18nKey={'classification.promoPanel.description'}
          className="text-large"
        >
          Wo ist der Button für{' '}
          <Text fontWeight="bold">Freigabe und Ablage</Text> hin?
        </Trans>
      </div>

      <Button variant="secondary" color="blue" {...triggerProps}>
        {t('classification.promoPanel.button')}
      </Button>

      {isOpen && (
        <DocumentClassificationPromoModal
          isOpen={isOpen}
          close={() => {
            markAsSeen();
            close();
          }}
          modalProps={modalProps}
        />
      )}
    </div>
  );
};

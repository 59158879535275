import { Button, Grid, Link, Modal, Paragraph } from '@candisio/design-system';
import { NormalisedDestinations } from 'providers/AnalyticsProvider/utils/preferences';
import { Trans } from 'providers/LocaleProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider/index';
import { useTranslation } from 'react-i18next';
import { PrivacyConsentTable } from 'views/AppContainer/components/ConsentManager/PrivacyConsentTable';

interface Props {
  visible: boolean;
  onCancel: VoidFunction;
  onSaveSelected: () => void;
  destinations: NormalisedDestinations | null;
  trackingEnabled: boolean;
  setTrackingEnabled: (shouldTrackingBeEnabled: boolean) => void;
  setIntercomEnabled: (shouldIntercomBeEnabled: boolean) => void;
  intercom: boolean;
}

export const PreferencesModal = ({
  visible,
  onCancel,
  destinations,
  trackingEnabled,
  onSaveSelected,
  setTrackingEnabled,
  intercom,
  setIntercomEnabled,
}: Props) => {
  const [tConsentManager] = useTranslation(LOCALE_NAME_SPACE.CONSENT_MANAGER);
  const [tCommon] = useTranslation();

  return (
    <Modal
      title={tConsentManager('preferencesModal.title')}
      closeLabel={tCommon('common.close')}
      isOpen={visible}
      onClose={onCancel}
      width="50rem"
      maxHeight="calc(100vh - space64)"
      maxWidth="calc(100vw - space64)"
      scrollDirection="none"
      data-cy="preferences-modal"
    >
      <Grid
        gap="space16"
        templateRows="auto 1fr auto"
        height="100%"
        overflow="hidden"
      >
        <Paragraph>
          <Trans
            t={tConsentManager}
            i18nKey="preferencesModal.description"
            parent="span"
          >
            We use the data collected by cookies and JavaScript libraries to
            improve your product experience, analyze site traffic, and increase
            the overall performance of our site. By using our website, you agree
            to our privacy policy. The table below outlines how we use this data
            by category. To opt out of a category of data collection, toggle it
            off and save your preferences.
            <br />
            Our
            <Link href={tConsentManager('termsAndConditions.url')} external>
              privacy policy
            </Link>
            .
          </Trans>
        </Paragraph>
        <PrivacyConsentTable
          setIntercomEnabled={setIntercomEnabled}
          intercom={intercom}
          trackingEnabled={trackingEnabled}
          destinations={destinations}
          setTrackingEnabled={setTrackingEnabled}
        />
        <Button size="small" onClick={onSaveSelected} justifySelf="end">
          {tConsentManager('preferencesModal.actions.saveSelected')}
        </Button>
      </Grid>
    </Modal>
  );
};

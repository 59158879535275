import {
  Button,
  Card,
  Grid,
  Heading,
  Link,
  Paragraph,
  Switch,
  Text,
  useModal,
} from '@candisio/design-system';
import { BDSPromotionModal } from 'components/ProductPromotions/Provisions/BDSPromotionModal';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { useUpdateExportConfigurationMutation } from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { getFullOrganizationQuery } from 'providers/OrganizationProvider/queries';
import { useTranslation } from 'react-i18next';

export interface ReWeActivationFormProps {
  isActive: boolean;
}

export const ReWeActivationForm = ({ isActive }: ReWeActivationFormProps) => {
  const [t] = useTranslation('settings');
  const moreDetailsURL = t('payments.reWeActivation.helpUrl');
  const [toggleDatevRewe, { loading }] = useUpdateExportConfigurationMutation({
    refetchQueries: [{ query: getFullOrganizationQuery }],
    awaitRefetchQueries: true,
  });

  const updateDatevReweSettings = async () => {
    await toggleDatevRewe({
      variables: {
        datevSettings: {
          isReweEnabled: !isActive,
        },
      },
    });
  };

  const { bdsBought } = useDatev(); // BDS-checked
  const showBdsPromotionModal = !bdsBought;
  const { triggerProps, isOpen, close } = useModal();

  return (
    <Card>
      <Grid gap="space16">
        <Grid gap="space16" templateColumns="1fr auto" alignItems="center">
          <Heading as="h3">{t('payments.reWeActivation.title')}</Heading>
          <Switch
            name="reweConnection"
            disabled={loading}
            label="switch paying with rechnungswesen on and off"
            checked={isActive}
            onChange={updateDatevReweSettings}
          />
          <Paragraph whiteSpace="pre-line">
            {t('payments.reWeActivation.info')}{' '}
            <Link external href={moreDetailsURL}>
              {t('payments.reWeActivation.moreDetails')}
            </Link>
          </Paragraph>
        </Grid>
        {showBdsPromotionModal && (
          <Card background="purplebg" paddingY="space12">
            <Grid templateColumns="1fr auto" alignItems="center" gap="space4">
              <Grid gap="space4">
                <Text fontWeight="semibold" fontSize="large">
                  {t('payments.add-on.title')}
                </Text>
                <Text fontSize="basic" lineHeight="paragraph">
                  {t('payments.add-on.description')}
                </Text>
              </Grid>
              <Button color="purple" {...triggerProps}>
                {t('payments.add-on.button')}
              </Button>
            </Grid>
            {isOpen && (
              <BDSPromotionModal
                utmSource={PROVISIONS_UTM_SOURCE.PAYMENT_SETTINGS}
                isOpen={isOpen}
                close={close}
              />
            )}
          </Card>
        )}
      </Grid>
    </Card>
  );
};

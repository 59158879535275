import {
  InvoicePermission,
  useInvoiceDocumentPermissionsQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { CamelizeSnakeCase } from 'types/camelize';

export type PermissionForInvoiceDocument = Partial<
  // Not to be confused with other "CAN" prefixes, this means "can" perform the action
  Record<CamelizeSnakeCase<`CAN_${InvoicePermission}`>, boolean>
>;

/**
 * Returns an object with the actions the current membership can perform
 * on the Invoice Document.
 */
export const useInvoiceDocumentPermissions = ({
  id,
  skip,
}: { id: string; skip?: boolean }): PermissionForInvoiceDocument => {
  const { data } = useInvoiceDocumentPermissionsQuery({
    variables: { id },
    skip: !id || skip,
    fetchPolicy: 'cache-first',
  });

  const modifyDirectEditAccessFF = useCandisFeatureFlags(
    FEATURE_FLAGS.modifyEcmDocumentEditAccess
  );

  const { isOnlyApprover } = useUserRoles();

  const permissions = new Set<`${InvoicePermission}`>(
    data?.getDocument?.permissionsForMembership ?? []
  );

  // These two are fallback while we transition to the new permissions system
  const isDocumentReadOnly = data?.getDocument?.isReadOnly ?? true;
  const canManageAccess =
    data?.getDocument?.accessModificationPermissions.viewAccess ?? false;

  const canAddRelationship = modifyDirectEditAccessFF
    ? permissions.has('ADD_RELATIONSHIP')
    : permissions.has('ADD_RELATIONSHIP') || !isDocumentReadOnly;
  const canRemoveRelationship = modifyDirectEditAccessFF
    ? permissions.has('REMOVE_RELATIONSHIP')
    : permissions.has('REMOVE_RELATIONSHIP') || !isDocumentReadOnly;

  return {
    canDelete:
      permissions.has('DELETE') || (!isDocumentReadOnly && !isOnlyApprover),
    canManageAccess: permissions.has('MANAGE_ACCESS') || canManageAccess,
    canManageTags: permissions.has('MANAGE_TAGS'),
    canAddRelationship,
    canRemoveRelationship,
    canComment: permissions.has('COMMENT'),
  };
};

import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';

interface UseIsSplitVisibleProps {
  isAdvancedAccountingEnabled: boolean;
  reimbursementStatus?: ReimbursementCaseStatus;
}

export const useIsSplitBookingsVisible = ({
  isAdvancedAccountingEnabled,
  reimbursementStatus,
}: UseIsSplitVisibleProps) => {
  const { isOnlyApprover } = useUserRoles();

  const { isApprovingEditMode } = useReimbursementFormsContext();

  const isInApprovingEditMode =
    reimbursementStatus === ReimbursementCaseStatus.Approving &&
    isApprovingEditMode;

  const canShowInReviewOrApproving =
    !isOnlyApprover &&
    (reimbursementStatus === ReimbursementCaseStatus.Review ||
      reimbursementStatus === ReimbursementCaseStatus.Rejected ||
      isInApprovingEditMode);

  const canShowInDraft =
    isAdvancedAccountingEnabled &&
    reimbursementStatus === ReimbursementCaseStatus.Draft;

  const showReimbursmentItemSplitBookings =
    canShowInReviewOrApproving || canShowInDraft;

  return showReimbursmentItemSplitBookings;
};

import { DEVTOOLS_KEY, IS_PROD } from 'constants/env';
import { Locale } from 'generated-types/graphql.types';
import { useUpdateCurrentUserLocale } from 'hooks/useCurrentUserLocale';
import { GlobalHotKeys as HotKeys, KeyMap } from 'react-hotkeys';
import { useNavigate } from 'react-router-dom-v5-compat';
import { isActiveNodeAllowed } from './utils';

const keyMap: KeyMap = {
  DEVTOOLS: { sequence: 'd+e+v', action: 'keyup' },
  DEVTOOLS_ALT: { sequence: 'ctrl+q+a', action: 'keyup' },
  LOCALE_TOGGLE: { sequence: 'ctrl+shift+d', action: 'keydown' },
};

export const GlobalHotKeys = () => {
  const navigate = useNavigate();
  const { locale, updateLocale } = useUpdateCurrentUserLocale();

  if (IS_PROD) {
    return null;
  }

  const openDevTools = () => {
    if (isActiveNodeAllowed()) {
      navigate(
        {
          hash: DEVTOOLS_KEY,
          search: window.location.search,
        },
        { replace: true }
      );
    }
  };

  const handlers = {
    DEVTOOLS: openDevTools,
    DEVTOOLS_ALT: openDevTools,
    LOCALE_TOGGLE: (event?: KeyboardEvent) => {
      event?.preventDefault();
      updateLocale(locale === Locale.En ? Locale.De : Locale.En);
    },
  };

  return <HotKeys handlers={handlers} keyMap={keyMap} />;
};

import { useMemo } from 'react';
import { useApprovalReimbursementsCounter } from '../toolkit/hooks/useApprovalReimbursementsCounter';
import { ToggleButtonGroup } from 'components/ToggleButtonGroup/ToggleButtonGroup';
import { approvalButtonGroupKeys } from 'views/Approvals/shared/utils';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useUrlMenuButtonsActions } from 'views/Approvals/shared/hooks/useUrlMenuButtonsActions';
import { useApplyLocalStorageFilter } from 'views/Approvals/shared/hooks/useApplyLocalStorageFilter';
import { ApprovalViewToggleButton } from 'views/Approvals/shared/ApprovalViewToggleButton';

export const ApprovalReimbursementsFilterButtonsContainer = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.APPROVALS);
  const { approvalFilterButtonKey, setApprovalFilterButtonKey } =
    useApplyLocalStorageFilter();

  const {
    approvalsAssignedToCurrentUserCount,
    approvalsRequestedByCurrentUserCount,
    allReimbursementsCount,
  } = useApprovalReimbursementsCounter();

  const { applyMenuButtonFilterToUrl, selectedMenuButtonKeys } =
    useUrlMenuButtonsActions({
      approvalFilterButtonKey,
      setApprovalFilterButtonKey,
    });

  const buttons = useMemo(() => {
    return [
      {
        id: approvalButtonGroupKeys.assignedToMe,
        label: t('outsideFilter.showAssignedToMe'),
        count: Number(approvalsAssignedToCurrentUserCount),
      },
      {
        id: approvalButtonGroupKeys.requestedByMe,
        label: t('outsideFilter.showMonitoring'),
        count: Number(approvalsRequestedByCurrentUserCount),
      },
      {
        id: approvalButtonGroupKeys.all,
        label: t('outsideFilter.all'),
        count: Number(allReimbursementsCount),
      },
    ];
  }, [
    approvalsAssignedToCurrentUserCount,
    approvalsRequestedByCurrentUserCount,
    allReimbursementsCount,
    t,
  ]);

  return (
    <ToggleButtonGroup
      selectedKeys={selectedMenuButtonKeys}
      onChange={applyMenuButtonFilterToUrl}
    >
      {buttons.map(({ id, label, count }) => (
        <ApprovalViewToggleButton
          key={id}
          id={id}
          count={count}
          isSelected={selectedMenuButtonKeys.includes(id)}
        >
          {label}
        </ApprovalViewToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

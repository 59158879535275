import {
  DocumentCurrency,
  ExportableEntityType,
  PageInfo,
} from 'generated-types/graphql.types';
import { createCardIssuerTransaction } from 'tests/valueObjectFactories/cardIssuerTransaction';
import { createExportDocument } from 'tests/valueObjectFactories/exportDocument';
import { createExportableEntityInfo } from 'tests/valueObjectFactories/exportableEntityInfo';

export const generateId = () =>
  Date.now().toString() + Math.floor(Math.random() * 10000);

export const createPageInfo = (pageInfo: Partial<PageInfo> = {}) => {
  return {
    startCursor: 'Hello World',
    hasPreviousPage: true,
    endCursor: 'Hello World',
    hasNextPage: false,
    totalCount: 91,
    ...pageInfo,
  };
};

export const createContact = (name: string) => {
  return {
    id: generateId(),
    name: { value: name, confidence: 1 },
  };
};

export const createAmount = (value: number) => {
  return {
    amount: value * 100,
    currency: DocumentCurrency.Eur,
    precision: 2,
  };
};

export const createExportRecordInfo = (
  entityType: ExportableEntityType = ExportableEntityType.Document
) => {
  const id = generateId();
  const exportDocument = createExportDocument({
    exportDocument: { document: { id } },
  });

  const exportTransaction = createCardIssuerTransaction({
    cardIssuerTransaction: { id },
  });

  const exportableEntityInfo = createExportableEntityInfo({
    exportableEntityInfo: { id, type: entityType },
  });

  const exportableEntity = { id, type: entityType };

  return {
    transaction: exportTransaction,
    document: exportDocument,
    exportableEntity,
    exportableEntityInfo,
  };
};

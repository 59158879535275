import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { paymentFrequencyTranslation } from 'views/Settings/CreditCards/CreditCardSettlements/utils';

export const useGetCreditCardFrequency = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const organization = useFullOrganization();

  const paymentFrequency = organization?.creditCardsDetails?.paymentFrequency;

  const readableFrequency = paymentFrequency
    ? t(paymentFrequencyTranslation[paymentFrequency])
    : undefined;

  return readableFrequency;
};

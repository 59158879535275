import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useApprovalData } from '../Invoices/hooks/useApprovalData';
import { Tab } from '../Tabs/AppprovalsTabs';
import { ApprovalsHeader } from './ApprovalsHeader';
import { useApprovalReimbursementsData } from '../Reimbursements/toolkit/hooks/useApprovalReimbursementsData';

interface ApprovalsHeaderContainerProps {
  activeTab: Tab;
}

export const ApprovalsHeaderContainer = ({
  activeTab,
}: ApprovalsHeaderContainerProps) => {
  const { approvalDocumentsBasicsQueryResult } = useApprovalData({
    skip: activeTab !== 'Invoices',
  });
  const { reimbursements } = useApprovalReimbursementsData({
    skip: activeTab !== 'Reimbursements',
  });

  const { organizationSlug } = useParams<{ organizationSlug: string }>();
  const { searchParams } = useMutateSearchParams();
  const navigate = useNavigate();

  const firstDocToApprove = approvalDocumentsBasicsQueryResult?.edges?.[0];
  const firstReimbursementToApprove = reimbursements?.[0];

  const onClick = () => {
    const cursor =
      activeTab === 'Invoices'
        ? firstDocToApprove?.cursor
        : firstReimbursementToApprove?.cursor;

    if (!cursor) {
      return;
    }
    searchParams.set('cursor', cursor);

    if (activeTab === 'Invoices') {
      const approvalLinkInvoice = `/${organizationSlug}${Routes.APPROVALS}/${firstDocToApprove?.node.id}?${searchParams.toString()}`;
      navigate(approvalLinkInvoice);
      return;
    }
    const approvalLinkReimbursement = `/${organizationSlug}${Routes.APPROVALS}${Routes.REIMBURSEMENTS}/${firstReimbursementToApprove?.id}?${searchParams.toString()}`;
    navigate(approvalLinkReimbursement);
  };

  return (
    <ApprovalsHeader
      isButtonDisabled={!firstDocToApprove && !firstReimbursementToApprove}
      onClick={onClick}
    />
  );
};

import {
  DocumentBookingAssociation,
  Money,
  SapExpenseType,
} from 'generated-types/graphql.types';

import { BookingsFormPartialData } from 'views/DocumentDetails/BookingsFormContext';
import {
  BookingAssocGoodsReceiptInfo,
  BookingAssocPurchaseOrderInfo,
  GoodsReceiptInfoExtended,
  ThreeWayMatchBookingData,
  ThreeWayMatchGoodsReceiptData,
  ThreeWayMatchPurchaseOrderData,
} from './types';
import { GoodsReceipt, PurchaseOrder } from '../types';

type GoodsReceiptsByBookingId = Record<
  string,
  {
    totalQuantity: number;
    description: string;
    items: GoodsReceiptInfoExtended[];
    articleId: string;
    unitPrice?: Money | null;
  }
>;

export const getBookingItemQuantity = (
  bookingId: string,
  bookingAssociations?: DocumentBookingAssociation[]
) => {
  const bookingAssoc = bookingAssociations?.find(
    association => association?.bookingId === bookingId
  );

  if (bookingAssoc?.additionalExpenseInfo?.expenseType) {
    return undefined;
  }

  return bookingAssoc?.quantity ?? 1;
};

const aggregateGoodsReceiptsByBookingId = (
  goodsReceiptsInfo: GoodsReceiptInfoExtended[]
) => {
  return goodsReceiptsInfo.reduce((acc, grInfo) => {
    const {
      bookingId,
      quantity = 0,
      description,
      articleId,
      unitPrice,
    } = grInfo;

    if (bookingId) {
      if (!acc[bookingId]) {
        acc[bookingId] = {
          totalQuantity: 0,
          items: [],
          description,
          articleId,
          unitPrice,
        };
      }
      acc[bookingId].totalQuantity += quantity;
      acc[bookingId].items.push(grInfo);
    }

    return acc;
  }, {} as GoodsReceiptsByBookingId);
};

export const getPurchaseOrdersData = (
  purchaseOrders: PurchaseOrder[],
  purchaseOrdersInfo: BookingAssocPurchaseOrderInfo[]
): ThreeWayMatchPurchaseOrderData[] => {
  const goodsLineItems = purchaseOrders.flatMap(po => po?.goodsLineItems ?? []);

  // Enrich and override purchase orders info with purchase order goods line items
  return purchaseOrdersInfo.map(poInfo => {
    const goodsLine = (goodsLineItems ?? []).find(
      po =>
        po.identifier ===
        `${poInfo?.purchaseOrderId}-${poInfo?.purchaseOrderLineNumber}`
    );
    return {
      ...poInfo,
      articleNumber: poInfo?.articleId,
      unitPrice: goodsLine?.unitPrice ?? poInfo?.unitPrice,
      quantity: goodsLine?.quantity ?? 0,
      description: goodsLine?.description ?? '',
      orderNumber: goodsLine?.orderNumber ?? '',
    };
  });
};

export const getGoodsReceiptsData = (
  goodsReceipts: GoodsReceipt[],
  goodsReceiptsInfo: BookingAssocGoodsReceiptInfo[]
): ThreeWayMatchGoodsReceiptData[] => {
  const goodsLineItems = goodsReceipts.flatMap(gr => gr?.goodsLineItems ?? []);

  // Enrich and override goods receipts info with goods receipt goods line items
  const enrichedGoodsReceipts: GoodsReceiptInfoExtended[] =
    goodsReceiptsInfo.map(grInfo => {
      const goodsLine = goodsLineItems.find(
        receipt =>
          receipt.identifier ===
          `${grInfo.goodsReceiptId}-${grInfo.goodsReceiptLineNumber}`
      );
      return {
        ...grInfo,
        receiptNumber: goodsLine?.receiptNumber ?? '',
        description: goodsLine?.description ?? '',
        quantity: goodsLine?.quantity ?? 0,
        unitPrice: goodsLine?.unitPrice,
      };
    });
  // for the case 1 PO with 2 GRs, need to aggregate the quantity:
  const goodsReceiptLinesGruopedByBookingId = aggregateGoodsReceiptsByBookingId(
    enrichedGoodsReceipts
  );

  return Object.entries(goodsReceiptLinesGruopedByBookingId).map(
    ([
      bookingId,
      { totalQuantity, description, articleId, unitPrice, items },
    ]) => {
      return {
        bookingId,
        quantity: totalQuantity,
        description,
        articleNumber: articleId,
        unitPrice,
        items: items.map(item => ({
          receiptNumber:
            enrichedGoodsReceipts.find(
              gr => gr.goodsReceiptId === item?.goodsReceiptId
            )?.receiptNumber ?? '',
          quantity: item?.quantity ?? 0,
        })),
      };
    }
  );
};

const isBookingAdditionalExpenseOnProcessingForm = (
  booking: BookingsFormPartialData[number]
) => {
  const temproryBookingIdsAsAdditionalExpense = [
    SapExpenseType.Freight,
    SapExpenseType.Packaging,
  ];

  return temproryBookingIdsAsAdditionalExpense.includes(
    booking.bookingId as SapExpenseType
  );
};

export const getBookingsData = (
  bookings?: BookingsFormPartialData,
  bookingAssociations?: DocumentBookingAssociation[]
): ThreeWayMatchBookingData[] => {
  return (bookings ?? []).map(booking => {
    const bookingAssoc = bookingAssociations?.find(
      association => association?.bookingId === booking.bookingId
    );

    let quantity;

    if (
      isBookingAdditionalExpenseOnProcessingForm(booking) ||
      bookingAssoc?.additionalExpenseInfo?.expenseType // UI displays empty for additional expense even actual value is 1
    ) {
      quantity = undefined;
    } else if (!booking.quantity) {
      quantity = getBookingItemQuantity(booking.bookingId, bookingAssociations);
    } else {
      quantity = booking.quantity;
    }

    return {
      ...booking,
      quantity,
    };
  });
};

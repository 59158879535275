import { useEcmContractStatusOptions } from 'components/EcmDocumentsTable/hooks/useEcmContractStatusOptions';
import { useEcmDocumentStatusOptions } from 'components/EcmDocumentsTable/hooks/useEcmDocumentStatusOptions';
import {
  Contact,
  DateFilter,
  DocumentType,
  EcmDocumentContractStatus,
  EcmDocumentFilters,
  EcmDocumentStatus,
  EcmDocumentSubCategoryField,
  User,
} from 'generated-types/graphql.types';
import { isEmpty, isNil, omitBy } from 'lodash';
import moment from 'moment';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useEcmContractTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmContractTypeItems';
import { useEcmDocumentTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmDocumentTypeItems';

type EcmDocumentFiltersKeys = keyof Pick<
  EcmDocumentFilters,
  | 'contactId'
  | 'contractStatus'
  | 'documentDate'
  | 'documentStatus'
  | 'documentSubCategory'
  | 'documentType'
  | 'endDate'
  | 'notifyPerson'
  | 'responsiblePerson'
  | 'startDate'
  | 'terminationDate'
  | 'terminationReminderDate'
>;

const translateFilterNamesToReadableList = <
  T extends keyof M,
  M extends Record<string, string>,
>(
  filterList: Array<T>,
  translationMap: M
) => {
  return filterList
    .filter((filter: T) => {
      return !isNil(filter);
    })
    .map((filter: T) => {
      return translationMap[filter];
    });
};

const formatDate = (date: Date) => {
  return moment(date).format('DD.MM.YYYY');
};

export const useGetReadableEcmAppliedFilterNames = (
  appliedFilters?: EcmDocumentFilters
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const { ecmContractStatusTranslationMap } = useEcmContractStatusOptions();
  const { getContractTypeLabel } = useEcmContractTypeOptions();
  const { ecmDocumentStatusTranslationMap } = useEcmDocumentStatusOptions();
  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();

  if (!appliedFilters) {
    return {
      readableAppliedEcmFilters: [],
    };
  }

  const translatedFilterLabel: Record<EcmDocumentFiltersKeys, string> = {
    contactId: t('tableColumns.contact'),
    contractStatus: t('tableColumns.contractStatus'),
    documentSubCategory: t('tableColumns.contractType'),
    documentDate: t('tableColumns.documentDate'),
    documentStatus: t('tableColumns.documentStatus'),
    documentType: t('tableColumns.documentType'),
    endDate: t('tableColumns.endDate'),
    notifyPerson: t('tableColumns.notifyPerson'),
    responsiblePerson: t('tableColumns.responsiblePerson'),
    startDate: t('tableColumns.startDate'),
    terminationDate: t('tableColumns.terminationDate'),
    terminationReminderDate: t('tableColumns.terminationReminderDate'),
  };

  const sanitizedEcmFilters = omitBy(appliedFilters, value => {
    return isNil(value) || isEmpty(value);
  });

  let readableAppliedEcmFilters: Array<string> = [];

  for (const filter of Object.entries(sanitizedEcmFilters)) {
    // Because with conversion to an array via the Object.entries method,
    // TS can't infer the type of the key and we need to cast it here again.
    const [key, value] = filter as [
      EcmDocumentFiltersKeys,
      EcmDocumentFilters[EcmDocumentFiltersKeys],
    ];

    // The connection between the key and the value is also unfortunately lost.
    // We need cast the type of the value because of that.
    switch (key) {
      case 'contactId':
        const contactNames = value as Array<Contact>;

        readableAppliedEcmFilters = [
          ...readableAppliedEcmFilters,
          ...contactNames.map(contact => contact.name.value),
        ];
        break;

      case 'contractStatus':
        const contractStatuses = value as Array<EcmDocumentContractStatus>;

        readableAppliedEcmFilters = [
          ...readableAppliedEcmFilters,
          ...translateFilterNamesToReadableList(
            contractStatuses,
            ecmContractStatusTranslationMap
          ),
        ];
        break;

      case 'documentStatus':
        const documentStatuses = value as Array<EcmDocumentStatus>;

        readableAppliedEcmFilters = [
          ...readableAppliedEcmFilters,
          ...translateFilterNamesToReadableList(
            documentStatuses,
            ecmDocumentStatusTranslationMap
          ),
        ];
        break;

      case 'documentType':
        const documentTypes = value as Array<DocumentType>;

        readableAppliedEcmFilters = [
          ...readableAppliedEcmFilters,
          ...translateFilterNamesToReadableList(
            documentTypes,
            ecmDocumentTypeTranslationMap
          ),
        ];
        break;

      case 'notifyPerson':
      case 'responsiblePerson':
        const persons = value as User[];
        const label = translatedFilterLabel[key];

        readableAppliedEcmFilters = [
          ...readableAppliedEcmFilters,
          ...persons.map(user => `${label}: ${user.name}`),
        ];
        break;

      case 'documentSubCategory':
        const documentSubCategory = value as Array<
          EcmDocumentSubCategoryField['id']
        >;

        const translatedDocumentSubCategories = documentSubCategory.map(
          subCategoryId => getContractTypeLabel(subCategoryId) ?? ''
        );

        readableAppliedEcmFilters = [
          ...readableAppliedEcmFilters,
          ...translatedDocumentSubCategories,
        ];
        break;

      case 'documentDate':
      case 'endDate':
      case 'startDate':
      case 'terminationDate':
      case 'terminationReminderDate':
        const { dateFrom, dateTo } = value as DateFilter;
        const formattedDateFrom = formatDate(new Date(dateFrom));
        const formattedDateTo = formatDate(new Date(dateTo));

        readableAppliedEcmFilters.push(
          `${translatedFilterLabel[key]}: ${formattedDateFrom} - ${formattedDateTo}`
        );
        break;

      default:
        break;
    }
  }

  return {
    readableAppliedEcmFilters,
  };
};

import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { ReactNode } from 'react';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useInitialReimbursementFormValues } from 'views/Reimbursement/hooks/useInitialReimbursementFormValues';
import { useReimbursementUpdatePending } from 'views/Reimbursement/hooks/useReimbursementUpdatePending';
import { ReimbursementFormSchemaContext } from 'views/Reimbursement/toolkit/reimbursementFormSchema';
import { useGetAccountsPayableNumber } from 'views/Settings/TeamMembers/hooks/useGetAccountsPayableNumber';
import { useReimbursementFormFieldOptions } from '../../hooks/useReimbursementFormFieldOptions';
import { ReadonlyReimbursementForm } from './ReadonlyReimbursementForm';
import { ReimbursementForm } from './ReimbursementForm';
import { ReimbursementWorkflow } from 'views/Reimbursement/hooks/useWorkflowForReimbursementCaseById';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useLocation } from 'react-router-dom';
import { useUpdateReimbursement } from 'views/Reimbursement/hooks/useUpdateReimbursement';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useShouldRequireAccountsPayableNumber } from 'views/utils/useShouldRequireField';
import { useOtherIntegration } from 'orgConfig/other';

interface ReimbursementFormContainerProps {
  isLoading: boolean;
  reimbursement?: Reimbursement;
  splits: ReactNode;
  workflow?: ReimbursementWorkflow;
  reimbursementItemsCount: number;
}

export const ReimbursementFormContainer = ({
  isLoading,
  reimbursement,
  splits,
  workflow,
  reimbursementItemsCount,
}: ReimbursementFormContainerProps) => {
  const { hash } = useLocation();
  const { searchParams } = useMutateSearchParams();
  const isProfileOpen = hash === '#userProfile';
  const isTeamMemberDrawerOpen = searchParams.get('teamMemberDrawer');

  const shouldRequireAccountsPayableNumber =
    useShouldRequireAccountsPayableNumber();

  const { shouldUseCoreDataApi } = useOtherIntegration();

  const { accountsPayableNumber, isLoadingAccountsPayableNumber } =
    useGetAccountsPayableNumber({
      userId: reimbursement?.targetMembership?.id ?? '',
    });
  const { defaultValues } = useInitialReimbursementFormValues({
    reimbursement,
    workflow,
    accountsPayableNumber,
  });

  const integrationSetting = useIntegrationSettings();

  const {
    reimbursementUIConfig,
    approvalMode,
    setApprovalData,
    setHasApprovalFieldError,
  } = useReimbursementFormsContext();
  const { isReimbursementFormEditable } = reimbursementUIConfig;

  const { updateReimbursement, updateReimbursementPending } =
    useUpdateReimbursement();

  const fieldOptions = useReimbursementFormFieldOptions({
    reimbursement,
    reimbursementItemsCount,
    accountsPayableNumber,
  });

  useReimbursementUpdatePending(updateReimbursementPending);

  const isLoadingData = isLoadingAccountsPayableNumber || isLoading;

  if (!isReimbursementFormEditable) {
    return (
      <ReadonlyReimbursementForm
        values={defaultValues}
        key={`reimbursement-readOnly-${isLoadingData}`}
        fieldOptions={fieldOptions}
        isLoading={isLoading}
        splits={splits}
        reimbursementStatus={reimbursement?.status}
      />
    );
  }

  const isAccountPayableNumberRequired =
    shouldRequireAccountsPayableNumber || shouldUseCoreDataApi;

  const context: ReimbursementFormSchemaContext = {
    approvalMode,
    reimbursementStatus: reimbursement?.status,
    integration: integrationSetting,
    shouldRequireAccountsPayableNumber: isAccountPayableNumberRequired,
  };

  return (
    <ReimbursementForm
      key={`userProfileopen-${isProfileOpen}-${isLoadingData}-${isTeamMemberDrawerOpen}`}
      isLoading={isLoading}
      formContext={context}
      defaultValues={defaultValues}
      fieldOptions={fieldOptions}
      reimbursementStatus={reimbursement?.status}
      splits={splits}
      onSetApprovalData={setApprovalData}
      onSetHasApprovalFieldError={setHasApprovalFieldError}
      onUpdateReimbursement={updateReimbursement}
    />
  );
};

import styles from './PerDiem.module.css';
import { PerDiemItemDetails } from 'views/Reimbursement/hooks/usePerDiemItemDetails';
import { Skeleton } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { LocationSummary } from './LocationSummary';

interface PerDiemSegmentSummaryProps {
  isLoading: boolean;
  perDiemItemDetails?: PerDiemItemDetails;
}

export const PerDiemSegmentSummary = ({
  isLoading,
  perDiemItemDetails,
}: PerDiemSegmentSummaryProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  if (isLoading) {
    return (
      <div className="grid gap-3 border-b border-gray-250 pb-4">
        <span className={styles.segmentTitle}>
          {t('reimbursementView.middleSection.form.perDiem.segmentDesc')}
        </span>
        <div className={styles.segmentSummaryContainer}>
          <Skeleton height="80px" width="100%" />
        </div>
      </div>
    );
  }

  return (
    <div className="grid gap-3 border-b border-gray-250 pb-4">
      <span className={styles.segmentTitle}>
        {t('reimbursementView.middleSection.form.perDiem.segmentDesc')}
      </span>
      <div className={styles.segmentSummaryContainer}>
        <div className="grid gap-2">
          {perDiemItemDetails?.segments.map(({ id, location }) => (
            <LocationSummary key={id} {...location} />
          ))}
        </div>
      </div>
    </div>
  );
};

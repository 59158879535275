import {
  HookFormNumberField,
  HookFormNumberFieldProps,
} from 'components/HookFormFields/HookFormNumberField';
import { useNumberFormatOptions } from 'components/HookFormInputs/useFormatOptions';
import { FieldValues } from 'react-hook-form';

export interface HookFormAmountFieldProps<TFormValues extends FieldValues>
  extends HookFormNumberFieldProps<TFormValues> {
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}

/**
 * A `HookFormNumberField` for entering monetary amounts
 */
export const HookFormAmountField = <TFormValues extends FieldValues>({
  currency,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
  ...restProps
}: HookFormAmountFieldProps<TFormValues>) => {
  // useNumberField's formatOptions must be memoized:
  // https://github.com/adobe/react-spectrum/issues/1893
  const formatOptions = useNumberFormatOptions({
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
  });

  return <HookFormNumberField formatOptions={formatOptions} {...restProps} />;
};

import { Grid } from '@candisio/design-system';
import {
  ExportStatus,
  ExportableEntityInfo,
  ExportableEntityType,
} from 'generated-types/graphql.types';

import { useTranslation } from 'react-i18next';
import { StatusDesc, StatusTitle, getColors } from '../styles';

type ExportInfoSectionProps = {
  active: boolean;
  mainStatus: ExportStatus;
  exportableEntities?: ExportableEntityInfo[];
};

const translationMap: Record<ExportStatus, string> = {
  ABORTED: 'export.exportHistory.status.aborted',
  EXPORTED: 'export.exportHistory.status.exported',
  EXPORTING: 'export.exportHistory.status.exporting',
  FAILED: 'export.exportHistory.status.failed',
  PARTIALY_EXPORTED: 'export.exportHistory.status.partialy_exported',
  UNKNOWN: 'export.exportHistory.status.unknown',
  PENDING: 'export.exportHistory.status.pending',
} as const;

export const ExportInfoSection = ({
  active,
  mainStatus,
  exportableEntities,
}: ExportInfoSectionProps) => {
  const [t] = useTranslation();

  const exportableEntitiesCountExcludingPaymentConditionsContacts =
    exportableEntities?.filter(
      entity =>
        entity.type !== ExportableEntityType.AggregatedPaymentConditions &&
        entity.type !== ExportableEntityType.AggregatedContacts
    ) ?? [];

  const statusCount =
    mainStatus === ExportStatus.Exporting
      ? exportableEntitiesCountExcludingPaymentConditionsContacts.length
      : exportableEntitiesCountExcludingPaymentConditionsContacts.filter(
          entity =>
            entity.status &&
            [ExportStatus.Exporting, ExportStatus.Pending].includes(
              entity.status
            )
        ).length;

  const approvalsStatusText = t(
    translationMap[mainStatus],
    statusCount ? { count: statusCount } : {}
  );

  return (
    <Grid>
      <StatusTitle
        active={active}
        color={getColors(active, mainStatus).titleColor}
      >
        {t('export.exportHistory.counter.posting', {
          count:
            exportableEntitiesCountExcludingPaymentConditionsContacts.length,
        })}
      </StatusTitle>
      <StatusDesc color={getColors(active, mainStatus).statusColor}>
        {mainStatus !== ExportStatus.Exported && approvalsStatusText}
      </StatusDesc>
    </Grid>
  );
};

import { Card, Flex, Grid, Skeleton } from '@candisio/design-system';

export const LoadingReimbursementDetails = () => {
  return (
    <Card padding="space12 space16 space14">
      <Grid gap="space4">
        <Flex justifyContent="space-between">
          <Skeleton height="space24" width="120px" />
          <Skeleton height="space24" width="80px" />
        </Flex>
        <Flex justifyContent="space-between">
          <Skeleton height="space24" width="60px" />
          <Skeleton height="space24" width="50px" />
        </Flex>
      </Grid>
    </Card>
  );
};

import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { Locale } from 'generated-types/graphql.types';
import { useReimbursementsPromotions } from 'orgConfig/reimbursement/useReimbursementsPromotions';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { ExportReimbursementItemsTable } from 'views/Integrations/Export/elements/table/reimbursementItems/ExportReimbursementItemsTable';
import { ExportReimbursementItemsTableColumnKeys } from 'views/Integrations/Export/elements/table/reimbursementItems/types';
import { getReimbursementExportData } from 'views/Integrations/Export/elements/table/reimbursementItems/utils';
import { PromotionWrapper, TableWrapper, Wrapper } from '../Wrapper';
import { EventLocationsBase } from 'providers/AnalyticsProvider/events';

const columnIds: ExportReimbursementItemsTableColumnKeys[] = [
  'reimbursementItemDate',
  'reasonExpense',
  'type',
  'grossAmount',
] as const;

export const ReimbursementPromotionExport = () => {
  const { reimbursementsPromotionLegacy, reimbursementsPromotionNonLegacy } =
    usePromotionContent();

  const user = useCurrentUser();

  const { variant } = useReimbursementsPromotions();

  const promotionVariant =
    variant === 'promotionForEntitlements'
      ? reimbursementsPromotionNonLegacy
      : reimbursementsPromotionLegacy;

  return (
    <Wrapper>
      <TableWrapper>
        <ExportReimbursementItemsTable
          data={getReimbursementExportData(user?.locale ?? Locale.En)}
          columnIds={columnIds}
        />
      </TableWrapper>
      <PromotionWrapper width="34rem" height="24rem">
        <PromotionsProvider promotions={[promotionVariant]}>
          <PromotionCard
            promotion={promotionVariant}
            closable={false}
            trackOption={{ eventLocation: EventLocationsBase.EXPORT }}
          />
        </PromotionsProvider>
      </PromotionWrapper>
    </Wrapper>
  );
};

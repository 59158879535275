import {
  searchScopeParameter,
  searchTypeParameter,
} from 'components/Table/consts';
import { SearchScope, SearchType } from 'generated-types/graphql.types';
import { Entitlements, useEntitlementsFlag } from 'hooks/useCandisFeatureFlags';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';

export const usePowerSearchInputsState = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTypeInQueryParam =
    (searchParams.get(searchTypeParameter) as SearchType) ?? null;
  const searchScopeInQueryParam =
    (searchParams.get(searchScopeParameter) as SearchScope) ?? null;

  const entitlement = useEntitlementsFlag();
  const isLegacy = entitlement === Entitlements.LEGACY;
  const defaultSearchTypeValue = isLegacy
    ? SearchType.Contains
    : (SearchType.PrefixMatch as SearchType);

  const [searchType, setSearchType] = useState<SearchType>(
    () => searchTypeInQueryParam ?? defaultSearchTypeValue
  );
  const [searchScope, setSearchScope] = useState<SearchScope>(
    () => searchScopeInQueryParam ?? SearchScope.DataFields
  );

  const setSearchTypeChange = (value: string) => {
    setSearchType(value as SearchType);
  };

  const setSearchScopeChange = (value: string) => {
    setSearchScope(value as SearchScope);
  };

  const applySearchInputs = ({
    type = searchType,
    scope = searchScope,
  }: { type?: SearchType; scope?: SearchScope }) => {
    searchParams.set(searchTypeParameter, type);
    searchParams.set(searchScopeParameter, scope);
    setSearchParams(searchParams, { replace: true });
  };

  const resetSearchInputs = () => {
    setSearchType(defaultSearchTypeValue);
    setSearchScope(SearchScope.DataFields);
  };

  return {
    searchType,
    searchScope,
    setSearchTypeChange,
    setSearchScopeChange,
    applySearchInputs,
    resetSearchInputs,
  };
};

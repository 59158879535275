import {
  DocumentCurrency,
  ExportableEntityType,
  InvoiceAssociationStatus,
  PaymentMethod,
  TransactionCategory,
  TransactionStatus,
  TransactionType,
} from 'generated-types/graphql.types';
import { createCardIssuerPaymentSettlement } from 'tests/valueObjectFactories/cardSettlementsReadyForExport';
import { faker } from 'utils/faker';

export const transactionData = Array.from({ length: 15 }, (_, index) => ({
  cursor: `eyJpdiI6Ii1wRGJPblBaWUFYbFdnaXVMbEcyWEEiLCJkYXRhIjoiVTh3QnFiaTlkLVljX2NLanlRbktDai11cjBJQkEwNFZPMXd0TWlvMW1BRkJHOTB${index}`,
  status:
    index % 7 === 0 ? TransactionStatus.Declined : TransactionStatus.Confirmed,
  id: `f703b614-4821-4398-88ad-d9e2a51d65${index}`,
  merchantName: faker.companyName(),
  transactionAmount: {
    value: faker.number(),
    precision: 2,
    currency: 'EUR',
  },
  grossAmount: {
    value: faker.number(),
    currency: 'EUR',
  },
  transactionCreatedAt: faker.date(),
  cardRefNum: `•••• ${Math.floor(1000 + Math.random() * 9000)}`,
  cardholderName: {
    firstName: faker.indexedFirstName(index),
    lastName: faker.indexedLastName(index),
    avatarUrl: null,
    membershipId: faker.uuid(),
  },
  invoiceAssociationStatus:
    index % 3 === 0
      ? InvoiceAssociationStatus.Uploaded
      : InvoiceAssociationStatus.NotNeeded,
  type: index % 5 === 0 ? TransactionType.Refund : TransactionType.Purchase,
  category:
    index % 3 === 0
      ? TransactionCategory.AdvertisingAndMarketing
      : TransactionCategory.EducationAndTraining,
}));

const contacts = Array.from({ length: 15 }, () => faker.companyName());

export const approvalsData = contacts.map((contact, index) => ({
  id: faker.uuid(),
  amount: {
    amount: faker.number(),
    currency: DocumentCurrency.Eur,
    precision: 2,
  },
  invoiceDate: faker.date(),
  invoiceNumber: `DE12345678${index}`,
  contact: contact,
  exportGuid: faker.string(),
  paymentState: {
    isPaid: true,
    paidAt: faker.date(),
    method: PaymentMethod.CardTransaction,
  },
  transactionStatus: TransactionStatus.Confirmed,
  hasDocument: true,
  type: ExportableEntityType.Document,
  direction: null,
  documentType: null,
}));

export const cardSettlementsData = Array.from({ length: 14 }, () => ({
  node: createCardIssuerPaymentSettlement(),
}));

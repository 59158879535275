import { Card, Flex, TruncatedText } from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { ISSUE_NEW_CARD_HASH } from 'containers/credit-cards/constants';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';

export const NoActiveCards = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { isCardManager } = useUserRoles();
  const organizationSlug = useOrganizationId();

  const link = `/${organizationSlug}${Routes.CREDIT_CARDS_DASHBOARD}?onCreditCardsView=true#${ISSUE_NEW_CARD_HASH}`;

  return (
    <Card
      background="gray50"
      border="1px solid gray250"
      paddingY="space8"
      paddingX="space12"
      width="136px"
    >
      <Flex alignItems="start" direction="column">
        <Flex alignItems="center" gap="space4">
          <TruncatedText fontSize="xlarge">
            {t('insights.requestsOverview.noActiveCards')}
          </TruncatedText>
        </Flex>
        <RouterLink fontSize="basic" to={link} color="red">
          <TruncatedText color="gray800">
            {isCardManager
              ? t('insights.requestsOverview.createCard')
              : t('insights.requestsOverview.requestCard')}
          </TruncatedText>
        </RouterLink>
      </Flex>
    </Card>
  );
};

import gql from 'graphql-tag';

export const projectCodesImportHistoryPaginationQuery = gql`
  query projectCodesImportHistoryPagination(
    $input: ListImportHistoryEntriesInput
  ) {
    importHistories(
      type: PROJECT_CODE
      importHistoryInput: $input
    )
    @connection(key: "projectCodesImportHistoryPagination", filter: ["type"]) {
      records {
        _id
        status
        createdAt
        createdBy {
          name
        }
        importErrorDetails {
          errorCode
          errorMessage
          formattedErrorMessage
        }
        successfullyCreatedEntitiesCount
        successfullyUpdatedEntitiesCount
        failedEntities {
          ... on ProjectCodeFailedEntity {
            rawEntity {
              code
              name
            }
          }
        }
      }
      pageInfo {
        totalCount
        pageSize
        currentPage
        pageCount
      }
    }
  }
`;

export const getProjectCodeImportHistoryByIdQuery = gql`
  query getProjectCodeImportHistoryById($id: String!) {
    importHistoryById(type: PROJECT_CODE, id: $id) {
      _id
      status
      createdAt
      createdBy {
        name
      }
      importErrorDetails {
        errorCode
        errorMessage
        formattedErrorMessage
      }
      successfullyCreatedEntitiesCount
      successfullyUpdatedEntitiesCount
      failedEntities {
      ... on ProjectCodeFailedEntity {
        rawEntity {
          code
          name
        }
        errorDetails {
          errorCode
          errorMessage
          formattedErrorMessage
        }
      }
    }
    }
  }
`;

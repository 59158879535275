import {
  GetOrganizationsInsightsDetailsQuery,
  Maybe,
  useGetOrganizationsInsightsDetailsQuery,
} from 'generated-types/graphql.types';

export interface SortedOrganization {
  id: string;
  overdueCount: number;
  taskCount: number;
  title: string;
  slug?: Maybe<string>;
  toApproveTotal?: Maybe<number>;
  toApproveOverdue?: Maybe<number>;
  toExportTotal?: Maybe<number>;
  toExportOverdue?: Maybe<number>;
  toProcessTotal?: Maybe<number>;
  toProcessOverdue?: Maybe<number>;
}

export interface OrganizationSummaries {
  organizations?: SortedOrganization[];
  loading: boolean;
}

type OrganizationDetails =
  GetOrganizationsInsightsDetailsQuery['getOrganizationsInsightsDetails'][number];

const mapOrganizationsTasks = (orgDetail: OrganizationDetails) => {
  const reimbursementCasesTasks = {
    toApproveTotal: orgDetail.reimbursementCases?.toApprove?.total ?? 0,
    toApproveOverdue: orgDetail.reimbursementCases?.toApprove?.overdue ?? 0,
    toExportTotal: orgDetail.reimbursementCases?.toExport?.total ?? 0,
    toExportOverdue: orgDetail.reimbursementCases?.toExport?.overdue ?? 0,
    toProcessTotal:
      (orgDetail.reimbursementCases?.toReview?.total ?? 0) +
      (orgDetail.reimbursementCases?.toCheck?.total ?? 0),
    toProcessOverdue:
      (orgDetail.reimbursementCases?.toReview?.overdue ?? 0) +
      (orgDetail.reimbursementCases?.toCheck?.overdue ?? 0),
  };
  return {
    id: orgDetail.organization.id,
    overdueCount: orgDetail.summary.overdue,
    taskCount: orgDetail.summary.total,
    title: orgDetail.organization.name,
    toApproveTotal:
      (orgDetail.toApprove?.total ?? 0) +
      reimbursementCasesTasks.toApproveTotal,
    toApproveOverdue:
      (orgDetail.toApprove?.overdue ?? 0) +
      reimbursementCasesTasks.toApproveOverdue,
    toExportTotal:
      (orgDetail.toExport?.total ?? 0) + reimbursementCasesTasks.toExportTotal,
    toExportOverdue:
      (orgDetail.toExport?.overdue ?? 0) +
      reimbursementCasesTasks.toExportOverdue,
    toProcessTotal:
      (orgDetail.toProcess?.total ?? 0) +
      reimbursementCasesTasks.toProcessTotal,
    toProcessOverdue:
      (orgDetail.toProcess?.overdue ?? 0) +
      reimbursementCasesTasks.toProcessOverdue,
    slug: orgDetail.organization.slug,
  };
};

export const useOrganizationSummary = (): OrganizationSummaries => {
  const { data, loading } = useGetOrganizationsInsightsDetailsQuery();

  const organizations = data?.getOrganizationsInsightsDetails
    ? data?.getOrganizationsInsightsDetails.map(mapOrganizationsTasks)
    : undefined;

  return { organizations, loading };
};

import { ReactNode } from 'react';
import { WistiaVideoModalLauncher } from './WistiaModalLauncher';
import { mergeProps, Link } from '@candisio/design-system';

export interface WistiaModalLinkProps {
  wistiaId: string;
  isLink?: boolean;
  onClick?: () => void;
  children: ReactNode;
}

export const WistiaModalLink = ({
  children,
  wistiaId,
  isLink,
  ...restProps
}: WistiaModalLinkProps) => {
  return (
    <WistiaVideoModalLauncher wistiaId={wistiaId}>
      {triggerProps => (
        <Link
          as={isLink ? 'a' : 'button'}
          icon="playOutline"
          iconPosition="left"
          {...mergeProps(triggerProps, restProps)}
        >
          {children}
        </Link>
      )}
    </WistiaVideoModalLauncher>
  );
};

import {
  HighlightResult,
  HighlightedTruncatedText,
} from '@candisio/design-system';
import { TruncatedText } from '@candisio/design-system/next';
import { friendlyFormatIBAN } from 'ibantools';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  PaymentInfoCellValue,
  getPaymentInfoDisplayValue,
} from '../../Cells/PaymentInfoCell';
import { CellContext } from '@tanstack/react-table';
import { getCellHighlights } from 'components/WithHighlightsCell/next/WithHighlightsCell';

/** Payment Info cell */
export const PaymentInfoCellHighlightable = <TTableData extends object>(
  cell: CellContext<TTableData, PaymentInfoCellValue>
) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  const { getValue } = cell;
  const value = getValue();
  if (isNil(value)) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  const { iban, bankAccountNumber } = value;
  if (isNil(iban) && isNil(bankAccountNumber)) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  const displayValue = getPaymentInfoDisplayValue(value);
  const highlights = getCellHighlights(cell);
  const updatedHighlights = getFormattedHighlights(displayValue, highlights);

  if (!iban || !updatedHighlights) {
    return <TruncatedText charsAfterEllipsis={5}>{displayValue}</TruncatedText>;
  }

  return (
    <HighlightedTruncatedText
      highlight={updatedHighlights}
      charsAfterEllipsis={5}
    />
  );
};

const getFormattedHighlights = (
  displayValue: string,
  highlight?: HighlightResult
): HighlightResult | null => {
  if (!highlight) {
    return null;
  }

  const highlightText = highlight.text.slice(
    highlight.matches[0].offset,
    highlight.matches[0].length
  );
  const formattedHighlightedText = friendlyFormatIBAN(highlightText);
  if (!formattedHighlightedText) {
    return null;
  }
  const offset = displayValue.indexOf(formattedHighlightedText);
  const length = formattedHighlightedText.length;
  if (offset === -1 || length === undefined) {
    return null;
  }

  return {
    text: displayValue,
    matches: [
      {
        offset,
        length,
      },
    ],
  };
};

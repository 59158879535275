import { TruncatedText } from '@candisio/design-system/next';

import { getTranslationContext } from 'orgConfig';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentsTableData } from '../types';
import { HeaderContext } from '@tanstack/react-table';
import { ComponentProps } from 'react';

export const Header = ({
  column,
  className,
}: HeaderContext<DocumentsTableData, unknown> &
  Pick<ComponentProps<typeof TruncatedText>, 'className'>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);

  return (
    <TruncatedText className={className}>
      {t(`columns.${column.id}`, {
        context: getTranslationContext(),
      })}
    </TruncatedText>
  );
};

import { Drawer } from '@candisio/design-system';
import { CreditCardDrawer } from 'containers/credit-cards/CreditCardDrawer';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';

export const CreditCardsDetailsDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { creditCardId } = useParams<{ creditCardId: string }>();
  const isOpen = !!creditCardId;

  const goBack = () => {
    navigate({ pathname: '..', search: location.search }, { relative: 'path' });
  };

  return (
    <Drawer width="40.5rem" isOpen={isOpen} onClose={goBack}>
      <CreditCardDrawer cardId={creditCardId ?? ''} onClose={goBack} />
    </Drawer>
  );
};

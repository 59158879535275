import { SwimlaneTilePromo } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromo';
import { SwimlaneTilePromoCTAButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCTAButton';
import {
  SwimlaneTilePromoContent,
  SwimlaneTilePromoContentBody,
} from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoContent';
import { SwimlaneTilePromoCloseButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCloseButton';
import { SwimlaneTileTitle } from 'components/Swimlane/content/SwimlaneTileTitle';
import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';

export const PromoCreditCardDeclinedTransactions = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isPromotionSeen, setPromotionIsSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.CREDIT_CARD_DECLINED_TRANSACTIONS
  );

  if (isPromotionSeen) return null;

  return (
    <SwimlaneTilePromo size="small">
      <SwimlaneTilePromoContent>
        <SwimlaneTilePromoCloseButton onClose={setPromotionIsSeen} />
        <SwimlaneTilePromoContentBody>
          <SwimlaneTileDescription>
            {t(
              'insights.swimlanes.promotions.promoCreditCardDeclinedTransactions.description'
            )}
          </SwimlaneTileDescription>
          <SwimlaneTileTitle>
            {t(
              'insights.swimlanes.promotions.promoCreditCardDeclinedTransactions.title'
            )}
          </SwimlaneTileTitle>
          <SwimlaneTilePromoCTAButton
            icon="arrowRight"
            iconPosition="right"
            as="a"
            target="_blank"
            href={t(
              'insights.swimlanes.promotions.promoCreditCardDeclinedTransactions.ctaButton.link'
            )}
          >
            {t(
              'insights.swimlanes.promotions.promoCreditCardDeclinedTransactions.ctaButton.text'
            )}
          </SwimlaneTilePromoCTAButton>
        </SwimlaneTilePromoContentBody>
      </SwimlaneTilePromoContent>
    </SwimlaneTilePromo>
  );
};

import { Heading } from '@candisio/design-system';
import { DocumentAccessCard } from 'components/DocumentAccess/DocumentAcccessCard';
import { useDocumentAccessLevelsData } from './toolkit/hooks/useDocumentAccessLevelsData';
import { useTranslation } from 'react-i18next';

interface ContractAccessCardContainerProps {
  globalDocumentId: string;
}

export const ContractAccessCardContainer = ({
  globalDocumentId,
}: ContractAccessCardContainerProps) => {
  const { accessDataForContracts, loading } = useDocumentAccessLevelsData({
    globalDocumentId,
  });

  const [t] = useTranslation();

  return (
    <DocumentAccessCard
      accessData={accessDataForContracts}
      loading={loading}
      title={
        <Heading as="h3">
          {t('common:documentAccess.membership.access.contracts.title')}
        </Heading>
      }
    />
  );
};

import {
  ReimbursementCaseFiltersInput,
  ReimbursementCasesSortInput,
  ReimbursementCasesSortOrder,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { RouteType } from './useReimbursementNavigation';
import { defaultReimbursementInboxSort } from 'views/Inbox/Reimbursements/toolkit/hooks/useInboxReimbursementsData';
import { defaultReimbursementArchiveSort } from 'views/Archive/Reimbursements/toolkit/hooks/useArchiveReimbursementsData';
import { defaultReimbursementApprovalSort } from 'views/Approvals/Reimbursements/toolkit/hooks/useApprovalReimbursementsData';

type DEFAULT_PARAMETERS =
  | {
      filter?: ReimbursementCaseFiltersInput;
      sort?: ReimbursementCasesSortInput;
    }
  | undefined;

export const openReimbursementStatuses = [
  ReimbursementCaseStatus.Approving,
  ReimbursementCaseStatus.Check,
  ReimbursementCaseStatus.Draft,
  ReimbursementCaseStatus.Review,
];

const completedStatuses = [
  ReimbursementCaseStatus.Approved,
  ReimbursementCaseStatus.Archived,
  ReimbursementCaseStatus.Exported,
  ReimbursementCaseStatus.Rejected,
  ReimbursementCaseStatus.PartiallyExported,
  ReimbursementCaseStatus.Exporting,
];

const defaultReimbursementDashboardSort: ReimbursementCasesSortInput = {
  createdAt: ReimbursementCasesSortOrder.Desc,
};

export const useDefaultQueryParameters = (
  props: RouteType,
  dashboardListParam: string | null
) => {
  const currentUserId = useCurrentUser()?.id ?? '';

  const parameters: Record<RouteType, DEFAULT_PARAMETERS> = {
    [Routes.ARCHIVE]: {
      sort: defaultReimbursementArchiveSort,
    },
    [Routes.INBOX]: {
      sort: defaultReimbursementInboxSort,
    },
    [Routes.APPROVALS]: {
      sort: defaultReimbursementApprovalSort,
    },
    [Routes.REIMBURSEMENTS_DASHBOARD]: {
      filter: {
        targetMembershipIds: [currentUserId],
        statuses:
          dashboardListParam === 'openReimbursements'
            ? openReimbursementStatuses
            : completedStatuses,
      },
      sort: defaultReimbursementDashboardSort,
    },
  };

  return parameters[props];
};

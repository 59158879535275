import { Flex, Link, MessageBox, Text } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';

interface EInvoicesFeedbackInfoBoxProps {
  isLoading: boolean;
  isEInvoice: boolean;
}

export const EInvoicesFeedbackInfoBox = ({
  isLoading,
  isEInvoice,
}: EInvoicesFeedbackInfoBoxProps) => {
  const [t] = useTranslation();

  if (!isEInvoice) {
    return null;
  }

  return (
    <MessageBox
      message=""
      variant="info"
      isLoading={isLoading}
      additionalContent={
        <Flex direction="column" gap="space4">
          <Text color={isLoading ? 'transparent' : 'gray800'}>
            {t(
              'document.requestApproval.fieldRecognition.eInvoicesFeedback.message'
            )}
          </Text>
          <Link
            id="e-invoices-feedback-button"
            icon="megaphone"
            iconPosition="left"
          >
            {t(
              'document.requestApproval.fieldRecognition.eInvoicesFeedback.buttonLabel'
            )}
          </Link>
        </Flex>
      }
    />
  );
};

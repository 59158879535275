import { Icon, Spinner } from '@candisio/design-system';
import clsx from 'clsx';
import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useCreateReimbursementWithRedirect } from 'views/Reimbursement/hooks/useCreateReimbursementWithRedirect';
import styles from './ReimbursementTileCreate.module.css';

export const ReimbursementTileCreate = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { createReimbursement, createReimbursementPending } =
    useCreateReimbursementWithRedirect();

  return (
    <SwimlaneTile
      asChild
      className={clsx(styles.tile, {
        [styles.loading]: createReimbursementPending,
      })}
      variant="gray"
    >
      <button
        data-cy="createExpenseTile"
        disabled={createReimbursementPending}
        onClick={createReimbursement}
      >
        {createReimbursementPending && <Spinner width={24} height={24} />}
        <span className={styles.content}>
          <Icon icon="plus" size={16} />
          {t('dashboard.createExpense.createCTA')}
        </span>
      </button>
    </SwimlaneTile>
  );
};

import { useTabs } from 'components/Tabs/useTabs';
import { useCountTeamsQuery } from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { TEAM_STATUS_URL_PARAM } from '../../Teams';
import { NAME_SEARCH_PARAM } from '../../elements/List/TeamsListContainer';
import { TeamListTabItem } from '../utils/types';

interface UseTeamsListTabItemsProps {
  teamsList: ReactNode;
}

export const useTeamsListTabItems = ({
  teamsList,
}: UseTeamsListTabItemsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const navigate = useNavigate();
  const { searchParams } = useMutateSearchParams();
  const status = searchParams.get(TEAM_STATUS_URL_PARAM);

  const { data: dataCountActive, loading: isLoadingActiveCount } =
    useCountTeamsQuery({
      variables: { filters: { isArchived: false } },
    });

  const { data: dataCountArchived, loading: isLoadingArchivedCount } =
    useCountTeamsQuery({
      variables: { filters: { isArchived: true } },
    });

  const activeTeamsCount = dataCountActive?.countTeams?.total ?? 0;
  const archivedTeamCount = dataCountArchived?.countTeams?.total ?? 0;

  const items: TeamListTabItem[] = [
    {
      key: 'active',
      title: t('teams.tabs.active'),
      children: teamsList,
      badge:
        !isLoadingActiveCount && activeTeamsCount > 0
          ? activeTeamsCount.toString()
          : undefined,
    },
    {
      key: 'archived',
      title: t('teams.tabs.archived'),
      children: teamsList,
      badge:
        !isLoadingArchivedCount && archivedTeamCount > 0
          ? archivedTeamCount.toString()
          : undefined,
    },
  ];

  const handleSelectionChange = (key: Key) => {
    searchParams.delete(NAME_SEARCH_PARAM);
    searchParams.set(TEAM_STATUS_URL_PARAM, key.toString());

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const { tabPanelProps, tabsProps } = useTabs({
    items,
    onSelectionChange: handleSelectionChange,
    selectedKey: status,
    defaultSelectedKey: 'active',
  });

  return {
    tabsProps,
    tabPanelProps,
  };
};

import { FieldPolicy, TypePolicies } from '@apollo/client';
import {
  ExportableDocumentConnection,
  ExportDocumentsEsResponse,
} from 'generated-types/graphql.types';
import {
  paginationWithRecordConnectionsMerge,
  paginationWithRecordConnectionsMergeNoNodes,
} from '../pagination-results-merge';

/**
 * @deprecated
 */
const exportPaginationDeprecated: FieldPolicy<ExportableDocumentConnection> = {
  keyArgs: ['sort', 'filters'],
  merge: paginationWithRecordConnectionsMerge('ExportableDocumentConnection'),
};

const exportPagination: FieldPolicy<ExportDocumentsEsResponse> = {
  keyArgs: ['sort', 'filters'],
  merge: paginationWithRecordConnectionsMergeNoNodes(
    'ExportDocumentsEsResponse'
  ),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      documentsForExport: exportPaginationDeprecated,
      exportDocumentsEs: exportPagination,
    },
  },
};

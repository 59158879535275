import {
  eInvoiceErrorTranslationKey,
  eInvoiceFormatTranslationKey,
} from 'components/DocumentHistory/history-components/InvalidEInvoiceDetectedComponent';
import { extractEInvoiceErrorProps } from 'components/EInvoiceErrorDetails/utils';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGetEInvoiceValidationProtocolDataQuery } from 'generated-types/graphql.types';
import { useUpdateCurrentUserLocale } from 'hooks/useCurrentUserLocale';
import { useTranslation } from 'react-i18next';

type UseEInvoiceValidationDetailsProps = {
  documentId: string;
};

export const useEInvoiceValidationDetails = ({
  documentId,
}: UseEInvoiceValidationDetailsProps) => {
  const [t] = useTranslation();
  const { success, error: toastError } = useToastMessage();

  const { locale: currentUserLocale } = useUpdateCurrentUserLocale();

  const { data, loading: isDataLoading } =
    useGetEInvoiceValidationProtocolDataQuery({
      variables: {
        id: documentId ?? '',
      },
      skip: !documentId,
    });

  const eInvoiceValidationErrors = data?.getDocument?.eInvoiceErrors;

  const eInvoiceTimelineData = data?.getDocument?.timeline?.find(
    event => event.__typename === 'InvalidEInvoiceDetectedEvent'
  );

  const isInvalidEInvoiceDetectedEvent =
    eInvoiceTimelineData?.__typename === 'InvalidEInvoiceDetectedEvent';

  const eInvoiceVersion =
    isInvalidEInvoiceDetectedEvent && eInvoiceTimelineData.eInvoiceVersion
      ? `V${eInvoiceTimelineData.eInvoiceVersion}`
      : '';

  const eInvoiceFormat = isInvalidEInvoiceDetectedEvent
    ? eInvoiceTimelineData?.eInvoiceFormat
    : undefined;

  const eInvoiceError = isInvalidEInvoiceDetectedEvent
    ? eInvoiceTimelineData?.eInvoiceError
    : undefined;

  const eInvoiceFormatTranslated = eInvoiceFormatTranslationKey(eInvoiceFormat);
  const eInvoiceGlobalErrorTranslated = t(
    eInvoiceErrorTranslationKey(eInvoiceError)
  );

  const eInvoiceFormatWithVersion = t(eInvoiceFormatTranslated, {
    version: eInvoiceVersion,
  });

  const validationErrorsReadable = eInvoiceValidationErrors?.map(error => {
    const { code, message, location, helpCenterArticleUrl } =
      extractEInvoiceErrorProps(error, currentUserLocale);

    return t(
      'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.errors',
      {
        code,
        message,
        location,
        helpCenterArticleUrl,
      }
    );
  });

  const isZUGFeRDV1 = eInvoiceFormat === 'ZUGFERD_V1';
  const infoTranslationKey = isZUGFeRDV1 ? 'introZugferdV1' : 'intro';

  const intro = t(
    `common:document.compact.history.invalidEInvoice.validationProtocolModal.content.${infoTranslationKey}`,
    {
      error: eInvoiceGlobalErrorTranslated,
    }
  );

  const requestNewInvoice = t(
    'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.requestNewInvoice'
  );

  const format = t(
    'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.format',
    {
      format: eInvoiceFormatWithVersion,
    }
  );

  const errorDetailsToCopy = t(
    'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.details',
    {
      intro: intro,
      format: format,
      errors: validationErrorsReadable,
    }
  );

  const handleCopy = () => {
    if (errorDetailsToCopy) {
      void navigator.clipboard.writeText(errorDetailsToCopy);
      success(
        t(
          'common:document.compact.history.invalidEInvoice.validationProtocolModal.actions.copyToClipboard.success'
        )
      );
    } else {
      toastError(
        t(
          'common:document.compact.history.invalidEInvoice.validationProtocolModal.actions.copyToClipboard.error'
        )
      );
    }
  };

  return {
    handleCopy,
    eInvoiceValidationErrors,
    isDataLoading,
    documentId,
    intro,
    requestNewInvoice,
    format,
    currentUserLocale,
    eInvoice: data?.getDocument?.eInvoice,
  };
};

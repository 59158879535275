import {
  CustomEmptyStateProps,
  FilterOptionsAccessor,
} from '@candisio/design-system';
import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { queryParameter } from 'components/Table/consts';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { OnLoadMore } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { ReactNode, useCallback, useMemo } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { generatePath } from 'react-router-dom';
import { Filters, SortingRule } from 'react-table';
import { ReimbursementsTable } from 'views/Reimbursement/elements/Table/ReimbursementsTable';
import { ReimbursementsTableEmptyState } from 'views/Reimbursement/elements/Table/ReimbursementsTableEmptyState';
import { REIMBURSEMENT_VIEW_ROUTES } from 'views/Reimbursement/hooks/useReimbursementNavigation';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/utils/constants';
import {
  ReimbursementTableColumnKeys,
  ReimbursementTableRow,
} from 'views/Reimbursement/toolkit/types';

interface ApprovalReimbursementsProps {
  reimbursements: ReimbursementTableRow[];
  filters: Filters<ReimbursementTableRow>;
  sortBy: SortingRule<ReimbursementTableRow>[];
  isLoadingReimbursements?: boolean;
  isLoadingConfigurations?: boolean;
  visibleColumnIds?: ReimbursementTableColumnKeys[];
  filterOptions?: FilterOptionsAccessor<ReimbursementTableRow>;
  isTableEmpty: boolean;
  isTableFiltered: boolean;
  configurations: Configuration[];
  toolbarFilter?: ReactNode;
  onFilter: (filters: Filters<ReimbursementTableRow>) => void;
  onSort: (sortBy: SortingRule<ReimbursementTableRow>[]) => void;
  onDebounceSearch: (search: string) => void;
  onResetTableConfigurations: () => void;
  onUpdateConfigurations: (newConfigurations: Configuration[]) => void;
  onLoadMore: OnLoadMore;
}

export const ApprovalReimbursements = ({
  configurations,
  filters,
  isTableEmpty,
  isTableFiltered,
  reimbursements,
  sortBy,
  filterOptions,
  isLoadingConfigurations,
  isLoadingReimbursements,
  visibleColumnIds,
  toolbarFilter,
  onDebounceSearch,
  onFilter,
  onLoadMore,
  onResetTableConfigurations,
  onSort,
  onUpdateConfigurations,
}: ApprovalReimbursementsProps) => {
  const { searchParams } = useMutateSearchParams();
  const queryStringFilter = searchParams.get(queryParameter) ?? '';
  const organizationSlug = useOrganizationId();

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <ReimbursementsTableEmptyState
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    ) : null;

  const getPath = useCallback(
    ({ id, cursor }: ReimbursementTableRow) => {
      searchParams.set(REIMBURSEMENT_URL_PARAM.CURSOR, cursor ?? '');
      const pathname = generatePath(
        REIMBURSEMENT_VIEW_ROUTES[Routes.APPROVALS],
        {
          organizationSlug: organizationSlug ?? '',
          reimbursementId: id,
        }
      );

      return `${pathname}?${searchParams.toString()}`;
    },
    [searchParams, organizationSlug]
  );

  const cellWrapper = useMemo(() => getCellWrapperLink(getPath), [getPath]);

  const cellStyle = () => ({
    padding: 'unset',
  });

  return (
    <ReimbursementsTable
      data={reimbursements}
      visibleColumns={visibleColumnIds}
      initialFilters={filters}
      initialSortBy={sortBy}
      isLoading={isLoadingReimbursements}
      filterOptions={filterOptions}
      configurations={configurations}
      search={queryStringFilter}
      isLoadingConfigurations={isLoadingConfigurations}
      toolbarFilter={toolbarFilter}
      onEndReached={onLoadMore}
      onFilter={onFilter}
      onResetTableConfigurations={onResetTableConfigurations}
      onSearch={onDebounceSearch}
      onSort={onSort}
      onUpdateTableConfigurations={onUpdateConfigurations}
      customEmptyState={customEmptyState}
      getCellStyles={cellStyle}
      cellWrapper={cellWrapper}
    />
  );
};

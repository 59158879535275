import {
  Flex,
  Icon,
  Image,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { CardIssuerCardRequest } from 'generated-types/graphql.types';
import i18next from 'i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SupportedPendingRequests } from 'views/CreditCards/types';
import cardIcon from '../../../../../media/card-icon.svg';

export enum RequestType {
  VirtualCardRequest = 'VirtualCardRequest',
  SingleUseVirtualCardRequest = 'SingleUseVirtualCardRequest',
  PhysicalCardRequest = 'PhysicalCardRequest',
  CardLimitChangeRequest = 'CardLimitChangeRequest',
}

const mappedRequestToUIElement = (
  t: i18next.TFunction
): Record<RequestType, JSX.Element> => ({
  CardLimitChangeRequest: (
    <Flex gap="space4" color="orange700" alignItems="center">
      <Icon width="16px" height="16px" icon="edit" />
      <TruncatedText>
        {t('cardManagerView.pendingRequest.limitRequest')}
      </TruncatedText>
    </Flex>
  ),
  PhysicalCardRequest: (
    <Flex gap="space4" alignItems="center">
      <Image src={cardIcon} width="16px" height="16px" alt="cardIcon" />
      <TruncatedText>
        {t('cardManagerView.pendingRequest.cardRequest')}
      </TruncatedText>
    </Flex>
  ),
  SingleUseVirtualCardRequest: (
    <Flex gap="space4" alignItems="center">
      <Image src={cardIcon} width="16px" height="16px" alt="cardIcon" />
      <TruncatedText>
        {t('cardManagerView.pendingRequest.cardRequest')}
      </TruncatedText>
    </Flex>
  ),
  VirtualCardRequest: (
    <Flex gap="space4" alignItems="center">
      <Image src={cardIcon} width="16px" height="16px" alt="cardIcon" />
      <TruncatedText>
        {t('cardManagerView.pendingRequest.cardRequest')}
      </TruncatedText>
    </Flex>
  ),
});

export const PendingRequestCell = ({
  value,
}: {
  value: (SupportedPendingRequests & CardIssuerCardRequest) | null;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  if (!value) {
    return <Text> - </Text>;
  }

  if (!value.__typename) {
    return <Text> - </Text>;
  }

  const request = mappedRequestToUIElement(t);

  return request[value.__typename];
};

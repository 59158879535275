import { Grid } from '@candisio/design-system';
import {
  StickyCardRefProvider,
  useStickyCardRefContext,
} from 'providers/StickyCardRefProvider';
import { ReactNode } from 'react';
import { BookingsFormContextProvider } from 'views/DocumentDetails/BookingsFormContext';
import { BoxWrapper } from './styles';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';

export const DetailsLayout = ({
  children,
  leftSection,
  middleSection,
  rightSection,
}: {
  children?: ReactNode;
  leftSection: ReactNode;
  middleSection: ReactNode;
  rightSection: ReactNode;
}) => {
  const cardRef = useStickyCardRefContext();

  const { isComparisonView } = useComparisonView();
  const templateColumns = isComparisonView ? 'auto 2fr 0fr' : 'auto 2fr 1fr';

  return (
    <Grid
      // @ts-ignore
      style={{ containerType: 'size', willChange: 'grid-template-columns' }}
      height="100%"
      paddingBottom="0"
      paddingRight="0"
      paddingLeft="space16"
      overflow="hidden"
      alignItems="start"
      gap="space16"
      transition=".3s"
      templateColumns={templateColumns}
    >
      <BookingsFormContextProvider>
        <Grid autoFlow="column" overflow="hidden" height="100%">
          {leftSection}
        </Grid>
        {middleSection}
        <StickyCardRefProvider>
          <BoxWrapper data-scroll-padding={cardRef?.current?.offsetHeight}>
            {rightSection}
          </BoxWrapper>
        </StickyCardRefProvider>
        {children}
      </BookingsFormContextProvider>
    </Grid>
  );
};

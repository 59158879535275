import { DocumentsTableData } from 'components/DocumentsTable/types';
import { queryParameter } from 'components/Table/consts';
import {
  DocumentEdge,
  ListApprovalsDocumentsQuery,
} from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { debounce } from 'lodash';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useComputeQueryFilters } from 'views/Archive/ArchiveDocumentsTable/hooks/useComputeQueryFilters';
import { mapToDocumentSortInput } from 'views/utils/mapToDocumentSortInput';
import { mapToDocumentsTableData } from 'views/utils/mapToDocumentsTableData';
import { useIncludePurchaseOrderData } from 'views/utils/useShouldShowField';
import { getApprovalDocumentsQuery } from '../queries';
import { availableFilters } from './consts';
import { useApprovalDocumentsDataFilters } from './useApprovalDocumentsDataFilters';

/**
 * @deprecated
 */
export const useApprovalDocumentsDataDeprecated = () => {
  const [t] = useTranslation();

  const { searchParams } = useMutateSearchParams();

  const computedFilters = useComputeQueryFilters();

  const includePurchaseOrderData = useIncludePurchaseOrderData();

  const searchQuery = searchParams.get(queryParameter) ?? '';

  const { filters, onFilter, onSort, sortBy } =
    useUrlBasedSortAndFilter<DocumentsTableData>({ availableFilters });

  const gqlSort = mapToDocumentSortInput(sortBy);

  const [debounceSearchValue, setDebounceSearchValue] = useState(searchQuery);

  const queryOptions = {
    variables: {
      ...computedFilters,
      ...(gqlSort ? { sort: gqlSort } : {}),
      query: debounceSearchValue,
      withPurchaseOrderData: includePurchaseOrderData,
    },
  };

  const { loading, onLoadMore, ...result } =
    usePagination<ListApprovalsDocumentsQuery>(
      getApprovalDocumentsQuery,
      'listApprovalsDocuments',
      queryOptions
    );

  const isTableFiltered = filters.length > 0;

  const approvalsTableData = mapToDocumentsTableData(
    (result.data?.listApprovalsDocuments?.edges as DocumentEdge[]) || [],
    t
  );

  const hasMoreData =
    result.data?.listApprovalsDocuments?.pageInfo?.hasNextPage ?? false;

  const isTableEmpty = approvalsTableData.length === 0 && !loading;

  const { filterOptions, filtersLoading } = useApprovalDocumentsDataFilters();

  const showLoadingMoreSpinner = approvalsTableData.length > 1;

  const handleDebounceSearch = useMemo(() => {
    return debounce(setDebounceSearchValue, DEFAULT_DEBOUNCE_TIME, {
      trailing: true,
    });
  }, []);

  useEffect(() => {
    return () => {
      handleDebounceSearch.cancel();
    };
  }, [handleDebounceSearch]);

  const documentsCount =
    result.data?.listApprovalsDocuments.pageInfo?.totalCount ?? 0;

  return {
    approvalsTableData,
    filtersLoading,
    filters,
    filterOptions,
    hasMoreData,
    isTableEmpty,
    isTableFiltered,
    loading,
    onFilter,
    onLoadMore,
    onSort,
    result,
    showLoadingMoreSpinner,
    sortBy,
    handleDebounceSearch,
    totalCount: documentsCount,
    searchQuery,
  };
};

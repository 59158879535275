import clsx from 'clsx';
import styles from './SwimlaneTileDescription.module.css';
import { HTMLProps } from 'react';

type SwimlaneTileDescriptionProps = HTMLProps<HTMLParagraphElement>;

export const SwimlaneTileDescription = ({
  className,
  ...props
}: SwimlaneTileDescriptionProps) => {
  return <p className={clsx(styles.description, className)} {...props} />;
};

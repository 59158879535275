import {
  DocumentEInvoiceField,
  DocumentPaymentState,
  DocumentStatus,
  EcmDocumentStatus,
  PaymentFrequencyType,
  TagFragment,
  TransactionStatus,
} from 'generated-types/graphql.types';
import { DocumentExportStatus } from 'views/Integrations/Export/types';
import { PaymentInfoCellValue } from './Cells/PaymentInfoCell';
import { WithHighlights } from 'components/WithHighlightsCell/types';

export type DocumentTableColumnKeys = Exclude<
  keyof DocumentsTableData,
  'id' | 'cursor' | 'skipped' | 'isDisabled' | 'hasDocument' | 'highlights'
>;

export type StatusType = Lowercase<EcmDocumentStatus>;

export interface Status {
  status: StatusType;
  isOverdue?: boolean;
  isDuplicate?: boolean;
  isWaitingForClarification?: boolean;
  hasAttachments?: boolean;
  isEInvoice?: boolean;
  eInvoice?: DocumentEInvoiceField | null;
}

export interface Amount {
  amount: number;
  currency: string;
  isBasicMonetaryUnit?: boolean;
}

export interface OpenInDatev {
  consultantNumber?: string;
  clientNumber?: string;
  exportGuid?: string | null;
  exportStatus?: DocumentExportStatus;
}

export type DiscountDateWithPercentage = {
  discountPaymentDate: Date | undefined;
  discountPercentage: number | undefined;
  discountAmount?: number | undefined;
};

export interface DocumentsTableData extends WithHighlights {
  id: string;
  transactionStatus?: TransactionStatus | null;
  cursor?: string;
  status?: Status;
  documentType?: string | null;
  contact?: string;
  isEInvoice?: boolean;
  eInvoice?: DocumentEInvoiceField | null;
  grossAmount?: Amount;
  invoiceNumber?: string;
  invoiceDate?: Date | undefined;
  isInvoiceCorrection?: boolean;
  dueDate?: (Date | undefined)[];
  deliveryDate?: Date;
  accountingArea?: string;
  costCenter?: (string | undefined)[];
  costObject?: (string | undefined)[];
  extraCostInfo?: (string | undefined)[];
  artistSocialInsuranceCode?: (string | undefined)[];
  createdAt?: Date;
  requester?: User | null;
  approvers?: User[] | null;
  fileName?: string;
  fileSize?: number;
  requestedAt?: Date;
  // specific props for exportable or exported documents
  exportMethod?: string | null;
  skipped?: boolean;
  exportStatus?: DocumentExportStatus;
  openInDatev?: OpenInDatev;
  isDisabled?: boolean;
  hasDocument?: boolean;
  settlementPaymentStatus?: string;
  period?: PaymentFrequencyType | 'UNKNOWN';
  netAmount?: Amount;
  account?: (string | undefined)[];
  generalLedgerAccount?: (string | undefined)[];
  provisionStatus?: TransactionStatus | DocumentStatus;
  iban?: string | undefined;
  note?: (string | undefined)[];
  postingText?: (string | undefined)[];
  paidAt?: Date | undefined;
  discountDateWPercentage?: DiscountDateWithPercentage | undefined;
  isPayable?: boolean;
  paymentStatus?: DocumentPaymentState['__typename'];
  creditCardPayment?: boolean | undefined;
  paymentInfo?: PaymentInfoCellValue;
  statement?: string | undefined;
  purchaseOrderNumber?: string;
  sapPurchaseOrderNumber?: string[];
  goodsReceiptNumber?: string[];
  tags?: Pick<TagFragment, 'id' | 'name' | 'isActive' | 'description'>[];
  selected?: boolean;
  accountsPayableNumber?: string;
  accountsReceivableNumber?: string;
}

interface User {
  name: string;
  imageUrl?: string;
}

export type NavigationTableParams = {
  cursor?: string;
  hasDocument?: boolean;
  id: string;
};

export enum CustomDatevWarningCode {
  DOCUMENT_ALREADY_EXIST = 'DOCUMENT_ALREADY_EXIST',
}

export type ColumnWidths = Partial<Record<DocumentTableColumnKeys, string>>;

export type ColumnFilter = (columnName: DocumentTableColumnKeys) => boolean;

import {
  DocumentCurrency,
  GeneralExpenseItem,
  HospitalityExpenseItem,
  ReimbursementItemsSplitBookingsQuery,
  useReimbursementCaseWorkflowQuery,
  useReimbursementItemsSplitBookingsQuery,
  PerDiemItem,
} from 'generated-types/graphql.types';
import { formatIntegerAmountToDecimal } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { mappedTypesToItem } from '../components/RightSection/ReimbursementSplitBookings/ReimbursementItemsSplitBookingsContainer';
import { calculateSumOfBookingsSplitAmounts } from '../components/RightSection/ReimbursementSplitBookings/hooks/useFormatAmountHelpers';
import { ReimbursementSplitBookingsFormValues } from '../toolkit/reimbursementSplitBookingsFormSchema';
import { useFormattedBookings } from './useFormattedBookings';
import { useCurrentUser } from 'providers/CurrentUserProvider';

export type HospitalityExpense = Omit<HospitalityExpenseItem, '__typename'>;
export type GeneralExpense = Omit<GeneralExpenseItem, '__typename'>;

export type ReimbursementItemSplitBookings =
  ReimbursementSplitBookingsFormValues['reimbursementItemBookings'];

type AllItemsFragments =
  ReimbursementItemsSplitBookingsQuery['reimbursementItemsSplitBookings'][number];

type ExpenseFragment =
  | GeneralExpenseItem
  | HospitalityExpenseItem
  | PerDiemItem;

export const useReimbursementSplitBookingsFormData = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUseReimbursement } = useReimbursement();

  const { id: currentUserId } = useCurrentUser() ?? {};

  const shouldSkipQuery = !reimbursementId || !canUseReimbursement;

  const { data: caseData, loading } = useReimbursementCaseWorkflowQuery({
    variables: { id: reimbursementId ?? '' },
    skip: shouldSkipQuery,
  });

  const { data, loading: loadingSplits } =
    useReimbursementItemsSplitBookingsQuery({
      variables: { filters: { reimbursementCaseId: reimbursementId } },
      skip: shouldSkipQuery,
      fetchPolicy: 'network-only',
    });

  const workflowData = caseData?.reimbursementCaseWorkflow.workflow;
  const currentStepApproverIds = (
    workflowData?.currentStep?.approvers ?? []
  )?.map(approver => approver.id);

  const isCurrentUserCaseApprover = Boolean(
    currentUserId && currentStepApproverIds.includes(currentUserId)
  );

  const fetchedItems = data?.reimbursementItemsSplitBookings ?? [];

  const filteredExpensesItems = fetchedItems.filter(
    (item: AllItemsFragments): item is ExpenseFragment => {
      return (
        item.__typename === 'GeneralExpenseItem' ||
        item.__typename === 'HospitalityExpenseItem' ||
        item.__typename === 'PerDiemItem'
      );
    }
  );

  const formattedBooking = useFormattedBookings();

  const reimbursementItemSplitBookings: ReimbursementItemSplitBookings =
    filteredExpensesItems.map(item => {
      const grossAmount = !isNil(item.totalAmount?.amount)
        ? formatIntegerAmountToDecimal(
            item.totalAmount?.amount,
            item.totalAmount?.precision
          )
        : 0;

      const bookings = (item.bookings ?? []).map(formattedBooking);
      const remainingAmount =
        grossAmount - calculateSumOfBookingsSplitAmounts(bookings);

      const reimbursementItemTitle = item.title
        ? item.title
        : t(mappedTypesToItem[item.type]);

      return {
        isCurrentUserCaseApprover,
        bookings,
        grossAmount,
        remainingAmount,
        reimbursementItemTitle,
        reimbursementItemStatus: item.status,
        reimbursementItemType: item.type,
        reimbursementItemId: item.id,
        currency: item.totalAmount?.currency ?? DocumentCurrency.Eur,
      };
    });

  return {
    loadingSplits: loadingSplits || loading,
    reimbursementItemSplitBookings,
  };
};

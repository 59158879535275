import { useApolloClient } from '@apollo/client';
import { useCountClassifyingDocumentsQuery } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { usePolling } from 'hooks/usePolling';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { refetchClassificationQueries } from 'utils/refetchClassificationQueries';

export const useClassificationCountPolling = () => {
  const client = useApolloClient();
  const [llmClassificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.llmClassification,
  ]);

  const { data, startPolling, stopPolling } = useCountClassifyingDocumentsQuery(
    {
      skip: !llmClassificationFF,
      onCompleted: data => {
        if (data.countClassifyingDocuments.count === 0) {
          refetchClassificationQueries(client);
        }
      },
    }
  );

  const count = data?.countClassifyingDocuments?.count ?? 0;

  usePolling({
    pollInterval: 5000,
    pollOnlyOnFocus: true,
    startPolling,
    stopPolling,
    skip: !llmClassificationFF || count <= 0,
  });

  return {
    count,
  };
};

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useUpdateEffect } from 'react-use';
import { useHasProcessingState } from './useHasProcessingState';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

export const useProcessingToast = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const hasProcessingStatus = useHasProcessingState();
  const toast = useToastMessage();

  useUpdateEffect(() => {
    if (!hasProcessingStatus) {
      toast.success(t('documentUpload.toastMessage.success'));
    }
  }, [hasProcessingStatus, toast.success]);
};

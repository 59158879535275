import { GlobalDocumentType } from 'components/Form/hooks/useDocumentTypeFieldOptions';
import { DocumentType } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { noop } from 'lodash';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

interface DocumentTypeContextState {
  documentType: GlobalDocumentType | undefined | null;
  documentTypeOld: string | undefined | null;
  setDocumentType: Dispatch<
    SetStateAction<DocumentTypeContextState['documentType']>
  >;
  isInvoiceDocumentType: boolean;
  showConversionNeededWarning: boolean;
  setDocumentTypeOld: Dispatch<
    SetStateAction<DocumentTypeContextState['documentTypeOld']>
  >;
}

const initialState: DocumentTypeContextState = {
  documentTypeOld: undefined,
  documentType: undefined,
  isInvoiceDocumentType: true,
  showConversionNeededWarning: false,
  setDocumentType: noop,
  setDocumentTypeOld: noop,
};

export const DocumentTypeContext =
  createContext<DocumentTypeContextState>(initialState);

export const DocumentTypeContextProvider = ({
  children,
  initiallyAnInvoice,
}: {
  children: ReactNode;
  initiallyAnInvoice: boolean;
}) => {
  const { showEcmContractManagement } = useEcm();
  const [llmClassificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.llmClassification,
  ]);

  const [documentType, setDocumentType] =
    useState<DocumentTypeContextState['documentType']>();

  const isInvoiceDocumentType = Boolean(
    !documentType || documentType?.documentCategory
  );
  // can be removed once classificationFrontend FF is enabled
  const [documentTypeOld, setDocumentTypeOld] =
    useState<DocumentTypeContextState['documentTypeOld']>();

  const hasTypeChangeFromInvoiceToNonInvoiceOrViceVersa =
    isInvoiceDocumentType !== initiallyAnInvoice;

  const hasTypeChangedToContractAndIsNotContractManagement =
    !showEcmContractManagement &&
    documentType?.documentType === DocumentType.Contract;

  let showConversionNeededWarning = false;
  switch (true) {
    case !llmClassificationFF:
    case hasTypeChangedToContractAndIsNotContractManagement:
      showConversionNeededWarning = false;
      break;
    case hasTypeChangeFromInvoiceToNonInvoiceOrViceVersa:
      showConversionNeededWarning = true;
      break;

    default:
      showConversionNeededWarning = false;
      break;
  }

  return (
    <DocumentTypeContext.Provider
      value={{
        documentType,
        setDocumentType,
        isInvoiceDocumentType,
        showConversionNeededWarning,
        documentTypeOld,
        setDocumentTypeOld,
      }}
    >
      {children}
    </DocumentTypeContext.Provider>
  );
};

export const useDocumentTypeContext = () => useContext(DocumentTypeContext);

import { ActiveFormContainer } from 'views/Settings/TeamMembers/TeamMemberDetails/containers/active/ActiveFormContainer';
import { useGetMembershipById } from './hooks/useGetMembershipById';
import { MembershipStatus } from 'generated-types/graphql.types';
import { InactiveFormContainer } from 'views/Settings/TeamMembers/TeamMemberDetails/containers/inactive/InactiveFormContainer';

interface TargetMemberContainerProps {
  targetMembershipId: string | undefined;
  isOpen: boolean;
  onCloseDrawer: () => void;
}

export const TargetMemberContainer = ({
  targetMembershipId,
  isOpen,
  onCloseDrawer,
}: TargetMemberContainerProps) => {
  const { user, loading } = useGetMembershipById({
    membershipId: targetMembershipId,
  });

  if ((loading && !user) || !isOpen) {
    return null;
  }

  if (!user) {
    return null;
  }

  const isInactive = user.status === MembershipStatus.Inactive;

  return (
    <>
      {isInactive ? (
        <InactiveFormContainer
          selectedUser={user}
          closeDrawer={onCloseDrawer}
        />
      ) : (
        <ActiveFormContainer
          selectedUser={user}
          closeDrawer={onCloseDrawer}
          firstFocusedField="accountsPayableNumber"
        />
      )}
    </>
  );
};

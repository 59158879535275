import { ProcessingForm } from 'components/Form/ProcessingForm/ProcessingForm';
import { ProcessingFormContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import { Loader } from 'components/Loader';
import { DocumentTagsFieldContainer } from 'containers/Tags/DocumentTagsFieldContainer';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { noop } from 'lodash';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useState } from 'react';
import { SplitBookingsFormContainer } from 'views/Inbox/DocumentProcessing/SplitBookingsFormContainer';
import { useProcessingFormFieldOptions } from 'views/Inbox/DocumentProcessing/useProcessingFormFieldOptions';
import { useProcessingFormInitialData } from 'views/Inbox/DocumentProcessing/useProcessingFormInitialData';

export interface MonitoringFormContainerProps {
  /** Document to fetch */
  documentId: string;
  contactItem?: ProcessingFormContactFieldItem;
  onGetContactItem?: (value?: ProcessingFormContactFieldItem) => void;
}

/** Handles data fetching for read-only (“monitoring”) processing form */
export const MonitoringFormContainer = ({
  documentId,
  contactItem,
  onGetContactItem,
}: MonitoringFormContainerProps) => {
  const integration = useIntegrationSettings();
  const { showDocumentTags } = useEcm();

  const { defaultValues, loading, globalDocumentId, tags } =
    useProcessingFormInitialData({
      documentId,
      readOnly: true,
    });

  const { fieldOptions } = useProcessingFormFieldOptions({
    defaultValues,
  });

  const [splitIndex, setSplitIndex] = useState(-1);

  return loading ? (
    <Loader />
  ) : (
    <ProcessingForm
      key={documentId}
      defaultValues={defaultValues}
      fieldOptions={fieldOptions}
      integration={integration}
      documentId={documentId}
      onSplitDocument={index => setSplitIndex(index ?? -1)}
      onSubFormClose={() => setSplitIndex(-1)}
      onSubmit={async () => noop()}
      contactItem={contactItem}
      onGetContactItem={onGetContactItem}
      readOnly
      subForm={
        splitIndex > -1 ? (
          <SplitBookingsFormContainer
            defaultSplitIndex={splitIndex}
            documentId={documentId}
            taxCodeFieldItems={
              fieldOptions?.taxCode?.props
                ? fieldOptions.taxCode?.props.defaultItems
                : undefined
            }
            typeFieldItems={fieldOptions.type?.items}
            readOnly
          />
        ) : undefined
      }
      tagsField={
        showDocumentTags &&
        globalDocumentId && (
          <DocumentTagsFieldContainer
            isInvoice
            documentTags={tags?.map(t => t.id) ?? []}
            globalDocumentId={globalDocumentId}
            documentId={documentId}
          />
        )
      }
    />
  );
};

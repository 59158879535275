import { useModal } from '@candisio/design-system';
import { queryParameter } from 'components/Table/consts';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { DownloadMenuButton } from '../shared/DownloadMenuButton';
import { DownloadOriginalFilesModal } from './DownloadOriginalFilesModal';
import { useDownloadDocumentFilesEcm } from './hooks/useDownloadDocumentFilesEcm';
import { useDownloadPdfFilesForAllDocuments } from './hooks/useDownloadPdfFilesForAllDocuments';

interface DownloadMenuButtonEcmDocumentsContainerProps {
  selectedDocumentsCount: number;
  isTableLoading: boolean;
  isTableFiltered: boolean;
}

export const DownloadMenuButtonEcmDocumentsContainer = ({
  isTableLoading,
  selectedDocumentsCount,
  isTableFiltered,
}: DownloadMenuButtonEcmDocumentsContainerProps) => {
  const [searchParams] = useSearchParams();
  const { triggerProps, modalProps, close, open } = useModal();

  const { isDownloadPdfFilesPending, onDownloadPdfFilesForAllDocuments } =
    useDownloadPdfFilesForAllDocuments({
      isTableLoading,
      handleClosePdfDownloadModal: close,
    });

  const isMenuButtonDisabled =
    isTableLoading || !selectedDocumentsCount || isDownloadPdfFilesPending;

  const hasSearchQuery = searchParams.has(queryParameter);

  return (
    <>
      <DownloadMenuButton
        {...triggerProps}
        isDisabled={isMenuButtonDisabled}
        isLoading={isTableLoading}
        isTableFiltered={isTableFiltered}
        isTableSearched={hasSearchQuery}
        count={selectedDocumentsCount}
        onClick={open}
      />
      <DownloadOriginalFilesModal
        {...modalProps}
        isTableLoading={isTableLoading}
        selectedDocumentsCount={selectedDocumentsCount}
        createOriginalFilesZipFileLoading={isDownloadPdfFilesPending}
        onPrepareDownload={onDownloadPdfFilesForAllDocuments}
      />
    </>
  );
};

export const DownloadMenuButtonEcmDocumentsDeprecatedContainer = ({
  isTableLoading,
  selectedDocumentsCount,
  isTableFiltered,
}: DownloadMenuButtonEcmDocumentsContainerProps) => {
  const [searchParams] = useSearchParams();
  const { triggerProps, modalProps, close, open } = useModal();

  const { onPrepareDownloadDocument, createDocumentZipFileLoading } =
    useDownloadDocumentFilesEcm({
      isTableLoading,
      handleClosePdfDownloadModal: close,
    });

  const isMenuButtonDisabled =
    isTableLoading || !selectedDocumentsCount || createDocumentZipFileLoading;

  const hasSearchQuery = searchParams.has(queryParameter);

  return (
    <>
      <DownloadMenuButton
        {...triggerProps}
        isDisabled={isMenuButtonDisabled}
        isLoading={isTableLoading}
        isTableFiltered={isTableFiltered}
        isTableSearched={hasSearchQuery}
        count={selectedDocumentsCount}
        onClick={open}
      />
      <DownloadOriginalFilesModal
        {...modalProps}
        isTableLoading={isTableLoading}
        selectedDocumentsCount={selectedDocumentsCount}
        createOriginalFilesZipFileLoading={createDocumentZipFileLoading}
        onPrepareDownload={onPrepareDownloadDocument}
      />
    </>
  );
};

import { TruncatedText } from '@candisio/design-system/next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentsTableData } from '../../types';
import { CellContext } from '@tanstack/react-table';

type Value = string | undefined;

export const FileNameCell = ({
  getValue,
}: CellContext<DocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const value = getValue();

  if (!value) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  return <TruncatedText charsAfterEllipsis={7}>{value}</TruncatedText>;
};

import { Grid, useTheme } from '@candisio/design-system';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useArchiveReimbursementCasesCount } from 'views/Reimbursement/hooks/useArchiveReimbursementCasesCount';
import travelIcon from '../../images/travelIcon.svg';
import { MenuItemCount, MenuItemIcon } from '../../style';
import { openReimbursementStatuses } from 'views/Reimbursement/hooks/useDefaultQueryParameters';

export const TravelAndExpenses = () => {
  const { fontSizes } = useTheme();
  const currentUserId = useCurrentUser()?.id ?? '';

  const { count, loadingCount } = useArchiveReimbursementCasesCount({
    filters: {
      statuses: openReimbursementStatuses,
      targetMembershipIds: [currentUserId],
    },
  });

  const isCountHidden = loadingCount || !count;

  return (
    <Grid alignItems="center" justifyItems="center">
      <MenuItemCount
        aria-label="reimbursement-menu-item-count"
        style={{
          fontSize: isCountHidden ? 0 : fontSizes.xsmall,
          padding: isCountHidden ? 0 : '0.05rem 0.35rem',
        }}
      >
        {count}
      </MenuItemCount>
      <MenuItemIcon>
        <img src={travelIcon} alt="travel icon" />
      </MenuItemIcon>
    </Grid>
  );
};

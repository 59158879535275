import { Flex, Grid, Spinner } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { CreditCardsPromotionSettings } from 'components/ProductPromotions/CreditCards/CreditCardsPromotionSettings';
import {
  CardIssuerOnboardingStatus,
  CardIssuerOrganizationOnboarding,
  IntegrationName,
  useGetCardIssuerOrganizationOnboardingQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';
import { SETTINGS_VIEW_DEFAULT_WIDTH } from '../utils';
import { CreditCardExportingCard } from './CreditCardExportingCard';
import { CreditCardExportingCardSap } from './CreditCardExportingCardSap';
import { CreditCardSettlements } from './CreditCardSettlements';
import { CreditCardSettlementsBanner } from './CreditCardSettlements/CreditCardSettlementsBanner';
import { CreditCardsEmailSent } from './CreditCardsEmailSent';
import { CreditCardsInitialState } from './CreditCardsInitialState';

interface ViewLayoutProps {
  children: ReactNode;
}

const ViewLayout = ({ children }: ViewLayoutProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const integration = useIntegrationSettings();
  const context = integration === IntegrationName.Other ? 'other' : '';

  return (
    <SettingsLayout title={t('header')} width={SETTINGS_VIEW_DEFAULT_WIDTH}>
      {children}
      {integration !== IntegrationName.Sap && (
        <InfoPanel
          externalLink={t('infoPanel.externalLink', {
            returnObjects: true,
            context,
          })}
          sections={t('infoPanel.sections', {
            returnObjects: true,
            context,
          })}
          title={t('infoPanel.title', { context })}
        />
      )}
    </SettingsLayout>
  );
};

export const CreditCards = () => {
  const creditCardsSetup = useCreditCardsSetup();
  const isCCAddonBought = creditCardsSetup.isAddonBought;

  const { data, loading } = useGetCardIssuerOrganizationOnboardingQuery({
    skip: !isCCAddonBought,
  });

  const creditCardsStatementsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsStatements
  );

  const { isActive: isSapActive } = useSap();

  if (!isCCAddonBought) {
    return (
      <ViewLayout>
        <CreditCardsPromotionSettings />
      </ViewLayout>
    );
  }

  const cardIssuerOrganizationData =
    data?.getCardIssuerOrganizationOnboarding as CardIssuerOrganizationOnboarding;

  const { status, requestedBy, requestedAt } = cardIssuerOrganizationData ?? {};

  const landingViewVariant: Partial<
    Record<CardIssuerOnboardingStatus, JSX.Element>
  > = {
    [CardIssuerOnboardingStatus.Pending]: <CreditCardsInitialState />,
    [CardIssuerOnboardingStatus.OnboardingEmailFailed]: (
      <CreditCardsInitialState />
    ),
    [CardIssuerOnboardingStatus.OnboardingEmailSent]: (
      <CreditCardsEmailSent user={requestedBy} date={requestedAt} />
    ),
    [CardIssuerOnboardingStatus.Active]: (
      <Grid alignContent="start" gap="space32">
        {isSapActive ? (
          <CreditCardExportingCardSap />
        ) : (
          <CreditCardExportingCard />
        )}
        {creditCardsStatementsFF ? (
          <CreditCardSettlementsBanner />
        ) : (
          <CreditCardSettlements />
        )}
      </Grid>
    ),
  };

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="space80" color="gray500" />
      </Flex>
    );
  }

  const currentView =
    landingViewVariant[status ?? CardIssuerOnboardingStatus.Pending];

  return <ViewLayout>{currentView}</ViewLayout>;
};

import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { TruncatedText } from '@candisio/design-system/next';
import { EcmDocumentsTableData } from '../../types';
import { CellContext } from '@tanstack/react-table';

type Value = Date | undefined;

export const DateCell = ({
  getValue,
}: CellContext<EcmDocumentsTableData, Value>) => {
  const value = getValue();
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  if (!value) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  return <TruncatedText>{DateFormatters.compact(value)}</TruncatedText>;
};

import {
  Button,
  Card,
  Grid,
  Icon,
  Modal,
  ModalProps,
  Text,
} from '@candisio/design-system';
import { TokenOrCSSValue } from '@candisio/design-system';
import { LottieAnimation, reportDE, reportEN } from 'components/Lottie/Lottie';
import i18next from 'i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useNavigateToCreateReport } from './hooks/useNavigateToCreateReport';

const lottieAnimationMap: Record<string, any> = {
  de: reportDE,
  en: reportEN,
};

export interface DownloadedCSVModalProps extends ModalProps {}

export interface TipCardProps {
  background?: TokenOrCSSValue<'colors', 'background'>;
  onClick: () => void;
}

export const TipCard = ({ background = 'gray100', onClick }: TipCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ARCHIVE);

  return (
    <Card background={background} alignSelf="start">
      <Grid fontSize="basic" gap="space24">
        <Grid>
          <Text>{t('download.history.tip.title')}</Text>
          <Text fontWeight="semibold">
            {t('download.history.tip.description')}
          </Text>
        </Grid>
        <Grid
          boxShadow="elevatedShadow4"
          borderRadius="medium"
          background="gray50"
          style={{ scale: '1.2' }}
        >
          <LottieAnimation
            height="100%"
            width="100%"
            options={{ animationData: lottieAnimationMap[i18next.language] }}
          />
        </Grid>

        <Button variant="tertiary" justifySelf="center" onClick={onClick}>
          {t('download.history.tip.cta')}
        </Button>
        <Text textAlign="center" color="gray500">
          {t('download.history.tip.extraInfo')}
        </Text>
      </Grid>
    </Card>
  );
};

export const DownloadedCSVModal = (props: DownloadedCSVModalProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ARCHIVE);

  const { handleNavigateToCreateReport } = useNavigateToCreateReport();

  const handleClick = () => {
    handleNavigateToCreateReport();
  };

  return (
    <Modal
      {...props}
      closeLabel="Close"
      onClose={props.onClose}
      title={t('download.downloadedCSVModal.title')}
      paddingX="space32"
    >
      <Grid gap="space24">
        <Card background="gray100">
          <Grid
            templateColumns="auto 1fr"
            alignItems="center"
            gap="space12"
            fontWeight="semibold"
          >
            <Icon size="space24" icon="checkCircle" color="green300" />
            <Text fontSize="basic">
              {t('download.downloadedCSVModal.description')}
            </Text>
          </Grid>
        </Card>
        <TipCard onClick={handleClick} />
      </Grid>
    </Modal>
  );
};

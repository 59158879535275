import { CellWrapperProps } from '@candisio/design-system';
import { Link, LinkProps, To } from 'react-router-dom-v5-compat';
import styles from './CellWrapperLink.module.css';

export function getCellWrapperLink<T extends object>(
  getLink: (arg: T) => To,
  linkProps?: Omit<LinkProps, 'to'>
) {
  return ({ row, children }: CellWrapperProps<T>) => {
    return (
      <Link
        to={getLink(row.original)}
        className={styles['cell-wrapper']}
        {...linkProps}
      >
        {children}
      </Link>
    );
  };
}

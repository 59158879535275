import { InfoPanel, Text, useTheme } from '@candisio/design-system';

interface PerDiemLengthErrorInfoBoxProps {
  travelLengthError: string;
}

export const PerDiemLengthErrorInfoBox = ({
  travelLengthError,
}: PerDiemLengthErrorInfoBoxProps) => {
  const { colors } = useTheme();
  return (
    <div className="px-6">
      <InfoPanel
        variant="error"
        style={{ border: `1px solid ${colors.red200}` }}
      >
        <Text>{travelLengthError}</Text>
      </InfoPanel>
    </div>
  );
};

import { forwardRef } from 'react';
import { Button } from '@candisio/design-system';
import { Icon } from '@candisio/design-system';
import { Layout } from './Layout';
import { useTranslation } from 'react-i18next';

type PromoPartTwoProps = {
  onNext: () => void;
  onClose: () => void;
};

export const PromoPartTwo = forwardRef<HTMLDivElement, PromoPartTwoProps>(
  ({ onNext, onClose }, ref) => {
    const [t] = useTranslation();

    const groupOneItems = t(
      'classification.promoModal.stepTwo.dropdown.groups.one.items',
      {
        returnObjects: true,
      }
    ) as string[]; // This key is an array in translation file
    const groupTwoItems = t(
      'classification.promoModal.stepTwo.dropdown.groups.two.items',
      {
        returnObjects: true,
      }
    ) as string[]; // This key is an array in translation file

    return (
      <Layout ref={ref}>
        <div className="grid grid-cols-2 whitespace-pre-line">
          <div className="grid justify-start items-center">
            <div className="grid gap-10">
              <span className="text-6xl -tracking-[4px] font-light leading-14">
                {t('classification.promoModal.stepTwo.title')}
              </span>
              <span className="font-light text-basic tracking-tight leading-[21px]">
                {t('classification.promoModal.stepTwo.description')}
              </span>
            </div>
          </div>
          <div className="grid items-center justify-end pr-12 pointer-events-none select-none">
            <div className="grid gap-2.5 min-w-80">
              <div>
                <span className="text-gray-600 font-normal ">
                  {t('classification.promoModal.stepTwo.dropdown.label')}
                </span>
                <div className="bg-white rounded-medium border-[2.5px] border-blue-300">
                  <div className="grid grid-flow-col justify-between items-center px-3.5 py-3">
                    <span className="text-gray-600 text-xlarge">
                      {t(
                        'classification.promoModal.stepTwo.dropdown.placeholder'
                      )}
                    </span>
                    <Icon icon="caretDown" color="gray450" size="space20" />
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-medium shadow-[0px_1.26px_5.05px_0px_#00000033] px-3.5 py-3 grid gap-4">
                <div className="grid gap-1.5">
                  <span className="text-gray-500 text-basic">
                    {t(
                      'classification.promoModal.stepTwo.dropdown.groups.one.label'
                    )}
                  </span>
                  <div className="grid gap-1">
                    {groupOneItems.map(type => (
                      <span key={type} className="text-gray-700 text-xlarge">
                        {type}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="grid gap-1.5">
                  <span className="text-gray-500 text-basic">
                    {t(
                      'classification.promoModal.stepTwo.dropdown.groups.two.label'
                    )}
                  </span>
                  <div className="grid gap-1">
                    {groupTwoItems.map(type => (
                      <span key={type} className="text-gray-700 text-xlarge">
                        {type}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          color="gray"
          variant="secondary"
          onClick={onNext}
          justifySelf="end"
        >
          {t('classification.promoModal.stepTwo.button')}
        </Button>
        <div className="absolute top-0 right-0 pr-6 pt-6 z-10">
          <Button
            variant="tertiary"
            color="gray"
            icon="close"
            onClick={onClose}
          />
        </div>
      </Layout>
    );
  }
);

import gql from 'graphql-tag';
import { refetchSapB1Settings } from 'views/Settings/CreditCards/gql';

export const sapB1CredentialsQuery = gql`
  query sapB1Credentials {
    sapB1Credentials {
      companyDbId
      serviceLayerUrl
      username
    }
  }
`;

export const verifySapB1CredentialsQuery = gql`
  query verifySapB1Credentials($sapB1Credentials: SapB1CredentialsInput) {
    verifySapB1Credentials(sapB1Credentials: $sapB1Credentials) {
      code
    }
  }
`;

export const createSapB1CredentialsMutation = gql`
  mutation createSapB1Credentials($sapB1Credentials: SapB1CredentialsInput!) {
    createSapB1Credentials(sapB1Credentials: $sapB1Credentials) {
      code
    }
  }
`;

export const deleteSapB1CredentialsMutation = gql`
  mutation deleteSapB1Credentials {
    deleteSapB1Credentials {
      code
    }
  }
`;

export const refetchSapSettings = [
  ...refetchSapB1Settings,
  { query: sapB1CredentialsQuery },
  { query: verifySapB1CredentialsQuery },
];

export const reconnectAndVerifySapB1ConnectionMutation = gql`
  mutation reconnectAndVerifySapB1Connection {
    reconnectAndVerifySapB1Connection {
      purchaseInvoiceSeries
      outgoingPaymentSeries
      moneyInTransitGeneralLedgerAccount
      creditCardId
      creditCardGeneralLedgerAccount
      sapB1Version
      connectionDetails {
        connectionStatus
        connectionError {
          errorMessage {
            de
            en
          }
          resolutionMessage {
            de
            en
          }
        }
      }
    }
  }
`;

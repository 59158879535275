import {
  IntegrationName,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import {
  createDatevSchema,
  datevAccountsPayableNumberSchema,
  datevAdjacentSchema,
  otherAccountsPayableNumberSchema,
} from 'utils/zodFormValidation/Schemas/accountsPayableNumber';
import { nonEmptyString } from 'utils/zodFormValidation/Schemas/nonEmptyString';
import { CheckAccountsNumber } from 'views/Contacts/ContactDetails/ContactForm/accountsNumberSchema';
import { RefinementCtx, z, ZodIssueCode } from 'zod';
import { ApprovalMode } from '../context/ReimbursementFormsContext';
import { EDITABLE_STATUSES } from '../hooks/useReimbursementFormFieldOptions';

export interface ReimbursementFormSchemaContext {
  approvalMode?: ApprovalMode;
  reimbursementStatus?: ReimbursementCaseStatus;
  integration?: IntegrationName;
  shouldRequireAccountsPayableNumber?: boolean;
}

export interface AccountsNumberSchemaWithDuplicateCheckProps {
  approvalMode?: ApprovalMode;
  checkAccountsPayableNumber?: CheckAccountsNumber;
  integration?: IntegrationName;
  shouldRequireAccountsPayableNumber?: boolean;
  glaLength?: number;
  reimbursementStatus?: ReimbursementCaseStatus;
}

const selectFieldSchema = z.object({
  value: z.string().nullish(),
  inputValue: z.string().nullish(),
});

const approversSchema = z.array(
  z.object({
    approvers: z.array(z.string()),
  })
);

// Common form fields
const commonFormFields = z.object({
  title: nonEmptyString,
  note: z.string().nullish(),
  iban: nonEmptyString,
  targetMembership: z.array(z.string()).nullish(),
  suggestedCostCenter: selectFieldSchema.nullish(),
  suggestedCostObject: selectFieldSchema.nullish(),
  suggestedGeneralLedgerAccount: selectFieldSchema.nullish(),
  isAdvancedAccountingEnabled: z.boolean(),
});

const approvalFields = z.object({
  approvalType: z.enum(['approvers', 'workflow']),
  workflow: z.string().nullish(),
  approvers: approversSchema.optional(),
});

type ApprovalFields = z.infer<typeof approvalFields>;

// to determine if accounts payable number should be nullable
const shouldMakeAccountsPayableNumberNullable = (
  status?: ReimbursementCaseStatus
) => {
  const isDraftOrCheck =
    status === ReimbursementCaseStatus.Draft ||
    status === ReimbursementCaseStatus.Check;
  return isDraftOrCheck;
};

// to get integration-specific schema for accounts payable number
const getAccountsPayableSchemaByIntegration = (
  integration: IntegrationName,
  glaLength?: number,
  shouldRequireAccountsPayableNumber?: boolean
) => {
  switch (integration) {
    case IntegrationName.Sap:
      return datevAccountsPayableNumberSchema();

    case IntegrationName.Other:
      return otherAccountsPayableNumberSchema(
        shouldRequireAccountsPayableNumber
      );

    case IntegrationName.DatevAdjacent:
      return datevAccountsPayableNumberSchema(datevAdjacentSchema).nullish();

    case IntegrationName.Datev:
    default:
      return datevAccountsPayableNumberSchema(
        createDatevSchema(glaLength)
      ).nullish();
  }
};

export const accountsPayableNumberSchema = (
  integration: IntegrationName,
  glaLength?: number,
  reimbursementStatus?: ReimbursementCaseStatus,
  shouldRequireAccountsPayableNumber?: boolean
) => {
  const schema = getAccountsPayableSchemaByIntegration(
    integration,
    glaLength,
    shouldRequireAccountsPayableNumber
  );

  return shouldMakeAccountsPayableNumberNullable(reimbursementStatus)
    ? schema.nullish()
    : schema;
};

export const accountsNumberSchemaWithDuplicateCheck = ({
  integration = IntegrationName.Datev,
  glaLength,
  reimbursementStatus,
  checkAccountsPayableNumber,
  shouldRequireAccountsPayableNumber,
}: AccountsNumberSchemaWithDuplicateCheckProps) => {
  const schema = accountsPayableNumberSchema(
    integration,
    glaLength,
    reimbursementStatus,
    shouldRequireAccountsPayableNumber
  );

  return schema.superRefine(async (value, context) => {
    if (!checkAccountsPayableNumber || !value) {
      return;
    }

    const { isAvailable, contactName } =
      await checkAccountsPayableNumber(value);

    if (!isAvailable) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        params: { contactName },
      });
    }
  });
};

const validateApprovalFields = (
  value: ApprovalFields,
  context: RefinementCtx
) => {
  const approvalType = value.approvalType;
  const hasNoApprovers = (value?.approvers?.length ?? 0) < 1;
  const hasNoWorkflow = !value.workflow?.length;

  if (approvalType === 'workflow' && hasNoWorkflow) {
    context.addIssue({
      code: ZodIssueCode.invalid_type,
      path: ['workflow'],
      expected: 'string',
      received: 'null',
    });
    return;
  }

  if (approvalType === 'approvers' && hasNoApprovers) {
    context.addIssue({
      code: ZodIssueCode.invalid_type,
      path: ['approvers'],
      expected: 'array',
      received: 'null',
    });
    return;
  }

  // Validate individual approvers
  value?.approvers?.forEach((approver, index) => {
    if (approver.approvers.length < 1) {
      context.addIssue({
        code: ZodIssueCode.invalid_type,
        path: ['approvers', index, 'approvers'],
        expected: 'array',
        received: 'null',
      });
    }
  });
};

const createBaseFormSchema = (context: ReimbursementFormSchemaContext) => {
  const accountsPayableNumber = accountsNumberSchemaWithDuplicateCheck(context);
  return z.object({
    ...commonFormFields.shape,
    ...approvalFields.shape,
    accountsPayableNumber,
  });
};

const createApprovalValidationSchema = (
  context: ReimbursementFormSchemaContext
) => {
  const isDraftOrCheck = context.reimbursementStatus
    ? EDITABLE_STATUSES.includes(context.reimbursementStatus)
    : false;

  const shouldSkipApprovalValidation =
    context.approvalMode === 'approve' ||
    context.integration === IntegrationName.Sap ||
    isDraftOrCheck;

  return createBaseFormSchema(context).superRefine((value, ctx) => {
    if (shouldSkipApprovalValidation) {
      return z.NEVER;
    }

    validateApprovalFields(value, ctx);
  });
};

export const reimbursementFormSchema = (
  context: ReimbursementFormSchemaContext
) => {
  const baseSchema = createBaseFormSchema(context);
  const approvalValidationSchema = createApprovalValidationSchema(context);

  return baseSchema.and(approvalValidationSchema);
};

export type ReimbursementFormValues = z.infer<
  ReturnType<typeof reimbursementFormSchema>
>;

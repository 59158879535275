import { motion } from 'motion/react';
import { forwardRef } from 'react';
import { mergeProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { Grid } from '../Grid';
import { Icon } from '../Icon/next';
import { Tooltip, useTooltip } from '../Tooltip';

export interface ClearButtonProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLElement> {
  clearLabel?: string;
  onClear?: () => void;
}

const MotionIcon = motion.create(Icon);

export const ClearButton = forwardRef<HTMLDivElement, ClearButtonProps>(
  ({ onClear, clearLabel, ...restProps }, ref) => {
    const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
      useTooltip();

    return (
      <>
        <Grid
          cursor="pointer"
          placeContent="center"
          ref={mergeRefs([triggerRef, ref])}
          {...mergeProps({ ...restProps, ...triggerProps })}
        >
          <MotionIcon
            initial={{ scale: 0.4, opacity: 0 }}
            animate={{ scale: 1, opacity: 1, transition: { duration: 0.3 } }}
            icon="close"
            className="w-4 h-4 text-gray-450"
            onClick={onClear}
          />
        </Grid>
        {isOpen && clearLabel && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {clearLabel}
          </Tooltip>
        )}
      </>
    );
  }
);

import { defaultTheme } from '@candisio/design-system';
import { hoverScrollbar } from 'styles/scrollbar';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';

export const HistoryLayout = styled.section`
  overflow: hidden;
  // Document history is an exception from general styles and has smaller spaces and font
  font-size: ${defaultTheme.fontSizes.small};

  a svg {
    width: ${defaultTheme.space.space14};
    height: ${defaultTheme.space.space14};
  }
  ${hoverScrollbar}
`;

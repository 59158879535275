import { Box, Card, Grid, Icon, Image } from '@candisio/design-system';
import { INFO_PANEL_WIDTH } from 'components/InfoPanel/InfoPanel';

type InfoPanelProps = {
  closePromotion: () => void;
  imageUrl: string;
  children: React.ReactNode;
};

export const PromotionInfoPanel = ({
  closePromotion,
  imageUrl,
  children,
}: InfoPanelProps) => {
  return (
    <Card
      padding="0"
      borderRadius="medium"
      overflow="hidden"
      width={INFO_PANEL_WIDTH}
    >
      <Grid
        height="170px"
        overflow="hidden"
        alignContent="center"
        justifyContent="center"
      >
        <Grid
          zIndex={1}
          width="100%"
          position="absolute"
          padding="space12 space16"
          justifyContent="flex-end"
        >
          <Box cursor="pointer" onClick={closePromotion}>
            <Icon icon="close" size="space16" color="gray800" />
          </Box>
        </Grid>
        <Image
          src={imageUrl}
          style={{ position: 'absolute' }}
          width="100%"
          alt=""
        />
      </Grid>
      <Grid gap="space10" padding="space20 space16">
        {children}
      </Grid>
    </Card>
  );
};

import {
  Button,
  Flex,
  Grid,
  GridProps,
  ScrollBox,
  Text,
} from '@candisio/design-system';
import { type ReactNode, useCallback } from 'react';
import { Drawers, useDrawerState } from './useDrawerState';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';
import { BackButton } from 'components/BackButton/BackButton';
import { useTranslation } from 'react-i18next';

// TODO: consider better defaults
export const MAX_DRAWER_WIDTH = '17.5rem';

const StyledGrid = styled(Grid)`
  transition-timing-function: ease-in-out;
  transition-duration: .2s;
  transition-property: width, opacity;

  grid-area: 1 / 1;

  &[data-expanded=true] {
    width: ${MAX_DRAWER_WIDTH};
    
  }

  &:not([data-expanded=true]) {
    width: 0;
  }

  &[data-current=true] {
    opacity: 1;
    z-index: 1;
  }

  &:not([data-current=true]) {
    opacity: 0;
    z-index: 0;
  }
`;

type ExpandingDrawerProps = Omit<GridProps, 'as' | 'ref'> & {
  drawer: keyof typeof Drawers;
  title: ReactNode;
};

export const ExpandingDrawer = ({
  drawer,
  children,
  title,
  ...props
}: ExpandingDrawerProps) => {
  const { isComparisonView, setComparisonMode } = useComparisonView();
  const { isDrawerOpen, isOtherDrawerOpen, toggleDrawer } =
    useDrawerState(drawer);

  const onCloseAction = useCallback(() => {
    isComparisonView ? setComparisonMode(false) : toggleDrawer();
  }, [isComparisonView, setComparisonMode, toggleDrawer]);

  return (
    <StyledGrid
      as="aside"
      data-expanded={isDrawerOpen || isOtherDrawerOpen}
      data-current={isDrawerOpen}
      maxWidth={MAX_DRAWER_WIDTH}
      {...props}
    >
      <Grid
        templateRows="auto 1fr"
        height="100cqh"
        rowGap="space10"
        paddingTop="space32"
        paddingBottom="space16"
        overflow="hidden"
      >
        <DrawerHeader onCloseAction={onCloseAction}>{title}</DrawerHeader>
        {children}
      </Grid>
    </StyledGrid>
  );
};

const DrawerHeader = ({
  children,
  onCloseAction,
}: {
  children: ReactNode;
  onCloseAction: () => void;
}) => {
  const { isComparisonView } = useComparisonView();

  return (
    <ScrollBox
      scrollDirection="none"
      scrollbarGutter="stable"
      paddingLeft="space8"
      fontWeight="semibold"
      fontSize="basic"
    >
      <Flex justifyContent="space-between" alignItems="center">
        {isComparisonView && (
          <ComparisonViewHeader onCloseAction={onCloseAction} />
        )}
        {!isComparisonView && (
          <DefaultHeader onCloseAction={onCloseAction}>
            {children}
          </DefaultHeader>
        )}
      </Flex>
    </ScrollBox>
  );
};

const ComparisonViewHeader = ({
  onCloseAction,
}: {
  onCloseAction: () => void;
}) => {
  const [t] = useTranslation();

  return (
    <BackButton onClick={onCloseAction}>
      {t('common.backToDocument')}
    </BackButton>
  );
};

const DefaultHeader = ({
  children,
  onCloseAction,
}: {
  children: ReactNode;
  onCloseAction: () => void;
}) => (
  <>
    <Text>{children}</Text>
    {onCloseAction && (
      <Button
        variant="tertiary"
        size="small"
        icon="close"
        iconPosition="right"
        onClick={onCloseAction}
        alignSelf="end"
      />
    )}
  </>
);

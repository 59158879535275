import { TruncatedText } from '@candisio/design-system/next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { EcmDocumentsTableData } from '../../types';
import { CellContext } from '@tanstack/react-table';

type Value = string | undefined;

export type TruncatedTextCellProps = CellContext<EcmDocumentsTableData, Value>;

export const TruncatedTextCell = ({ getValue }: TruncatedTextCellProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const value = getValue();

  if (!value) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  return <TruncatedText>{value}</TruncatedText>;
};

import {
  ReimbursementCasesFilterInput,
  useArchiveReimbursementCasesCountQuery,
} from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';

interface UseArchiveReimbursementCasesCountProps {
  filters?: ReimbursementCasesFilterInput;
}

export const useArchiveReimbursementCasesCount = ({
  filters,
}: UseArchiveReimbursementCasesCountProps) => {
  const { canUseReimbursement } = useReimbursement();

  const { data, loading: loadingCount } =
    useArchiveReimbursementCasesCountQuery({
      variables: { filters },
      skip: !canUseReimbursement,
    });

  const count = data?.archiveReimbursementCasesCount?.totalCount ?? 0;

  return {
    count,
    loadingCount,
  };
};

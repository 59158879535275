import { TruncatedText } from '@candisio/design-system/next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentsTableData } from '../../types';
import { CellContext } from '@tanstack/react-table';

export const StatementCell = ({
  getValue,
  row,
}: CellContext<DocumentsTableData, DocumentsTableData['statement']>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const value = getValue();
  if (!value || !row.original.hasDocument) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  return <TruncatedText>{t('statementAvailable')}</TruncatedText>;
};

import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import styles from './CardRequestContentTile.module.css';
import { SwimlaneTileAmount } from 'components/Swimlane/content/SwimlaneTileAmount';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { NavLink } from 'react-router-dom-v5-compat';
import { usePath } from 'hooks/usePath';
import { Tag } from '@candisio/design-system';
import { CreditCardImage } from './CreditCardImage';
import { CardIssuerCardEdgeDataFragment } from 'generated-types/graphql.types';

export const CardRequestContentTile = ({
  request,
}: {
  request: CardIssuerCardEdgeDataFragment['node'];
}) => {
  const { cardholder, purpose, limit, transactionLimit, brand, type, id } =
    request;
  const { transactionAmountFormatter } = useMoneyFormatter();

  const href = usePath({
    pathname: '/credit_cards/dashboard/cards/:creditCardId',
    params: {
      creditCardId: id,
    },
  });

  return (
    <SwimlaneTile
      asChild
      variant="white"
      className={styles.column}
      data-cy="cardRequestTile"
    >
      <NavLink to={href}>
        <div className={styles.header}>
          <Tag color="yellow" variant="secondary">
            New card
          </Tag>
        </div>
        <div className={styles.details}>
          <div className={styles.memberInfo}>
            <CreditCardImage type={type} brand={brand ?? undefined} />
            <div>
              <p className={styles.name}>
                {`${cardholder.firstName} ${cardholder.lastName}`}
              </p>
              <p className={styles.purpose}>{purpose || '–'}</p>
            </div>
          </div>

          {limit ? (
            <SwimlaneTileAmount>
              {transactionAmountFormatter(limit)}
            </SwimlaneTileAmount>
          ) : transactionLimit ? (
            <SwimlaneTileAmount>
              {transactionAmountFormatter(transactionLimit)}
            </SwimlaneTileAmount>
          ) : null}
        </div>
      </NavLink>
    </SwimlaneTile>
  );
};

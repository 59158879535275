import { useLayoutEffect, useRef, useState } from 'react';

export const useOverflowGrid = <T extends Element>(
  colSize: number,
  rowSize: number,
  gap: number
) => {
  const ref = useRef<T>(null);
  const [colCount, setColCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(entries => {
      const [entry] = entries;
      const { width, height } = entry.contentRect;

      const cols = Math.floor(width / (colSize + gap));
      const remainderX = width % (colSize + gap);

      const rows = Math.floor(height / (rowSize + gap));
      const remainderY = height % (rowSize + gap);

      setColCount(Math.floor(cols + remainderX / colSize));
      setRowCount(Math.floor(rows + remainderY / rowSize));
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [colSize, gap, rowSize]);

  return { colCount, rowCount, ref };
};

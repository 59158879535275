import { PulseIndicator } from 'components/PulseIndicator/PulseIndicator';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { useSap } from 'orgConfig/sap';
import { useCheckDatevSetup } from 'views/Settings/Integrations/DATEV/setup-checks';

import {
  useNeedsSapConfig,
  useNeedsSapCreditCardConfig,
} from 'views/Settings/Integrations/SAP/utils';
import { useOrganizationData } from 'views/Settings/Organization/hooks/useOrganizationData';
import { useOrganizationOwnIbansData } from 'views/Settings/PaymentsSettings/useOrganizationOwnIbansData';
import { NavigationIcon } from '../../NavigationIcon';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

export const MenuSettings = ({ expanded }: { expanded: boolean }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);

  const {
    hasReadClientsButNotSelected,
    hasProvisionsAndRequiresSetup,
    accountingCoreDataMissing,
    indicateDatevCreditCardSetupNeeded,
  } = useCheckDatevSetup();

  const { isAdmin, isAccountant } = useUserRoles();

  const { hasOrgTaxDetails } = useOrganizationData();

  const { showMissingIBANIndicator } = useOrganizationOwnIbansData();

  const { isActiveIntegration: isSapIntegration } = useSap();
  const indicateSapSetupNeeded = useNeedsSapConfig();
  const indicateSapCreditCardSetupNeeded = useNeedsSapCreditCardConfig();

  const { bdsBoughtButNotConnected } = useDatev();

  const isSapOrDatevConfigured = isSapIntegration
    ? indicateSapCreditCardSetupNeeded
    : indicateDatevCreditCardSetupNeeded;

  const showMissingConfigurationIndicator =
    isSapOrDatevConfigured ||
    hasReadClientsButNotSelected ||
    hasProvisionsAndRequiresSetup ||
    indicateSapSetupNeeded ||
    accountingCoreDataMissing ||
    bdsBoughtButNotConnected ||
    !hasOrgTaxDetails ||
    showMissingIBANIndicator;

  return (
    <>
      <NavigationIcon
        icon="menuSettingsAlt"
        label={t('menuLabels.settings')}
        expanded={expanded}
        data-cy="nav-item-settings"
        pulseIndicator={
          showMissingConfigurationIndicator ? (
            <PulseIndicator
              status="default"
              source="main-settings-navigation"
            />
          ) : undefined
        }
        route={`${Routes.SETTINGS}/organization`}
        showMenuIcon={isAccountant || isAdmin}
      />
    </>
  );
};

import { z } from 'zod';
import { ErrorMessages } from 'utils/zodFormValidation';
import { getTranslationContext } from 'orgConfig';
import { BookingsFormSchemaContext } from '../types';
import { createRowSchema, rowSchema } from './rowSchema';
import { headerFormSchema, BookingHeaderValues } from './headerSchema';

const tableFormSchema = z.array(rowSchema).min(1);

export const createBookingsFormSchema = (
  options: BookingsFormSchemaContext
) => {
  const validatedRowSchema = createRowSchema(options);
  return z.object({
    ...headerFormSchema.shape,
    bookings: z.array(validatedRowSchema).min(1),
  });
};

type ExcludeKeys =
  | 'bookings.0.bookingId'
  | 'bookings.0.generalLedgerAccount'
  | 'bookings.0.generalLedgerAccount.inputValue'
  | 'bookings.0.costCenter'
  | 'bookings.0.costCenter.inputValue'
  | 'bookings.0.costObject'
  | 'bookings.0.costObject.inputValue'
  | 'bookings.0.extraCostInfo'
  | 'bookings.0.extraCostInfo.inputValue'
  | 'bookings.0.sapExpenseType'
  | 'bookings.0.projectCode'
  | 'bookings.0.projectCode.inputValue'
  | 'roundingAmount'
  | 'bookings.0.invoiceCorrection'
  | 'invoiceDate';

export const getBookingsFormErrorMessages = (): ErrorMessages<
  ReturnType<typeof createBookingsFormSchema>,
  ExcludeKeys
> => {
  return {
    grossAmount: {
      label:
        getTranslationContext() === 'sap'
          ? 'inputs.totalAmount.label'
          : 'inputs.grossAmount.label',
    },
    currency: { label: 'inputs.currency.label' },
    'bookings.0.amount': { label: 'inputs.amount.label' },
    'bookings.0.netAmount': { label: 'inputs.netAmount.label' },
    'bookings.0.taxAmount': { label: 'inputs.taxAmount.label' },
    'bookings.0.vatRate': {
      label: 'inputs.vatRate.label',
    },
    'bookings.0.taxPresentation': {
      label: 'inputs.taxPresentation.label',
    },
    'bookings.0.dueDate': {
      label: 'inputs.invoiceDueDate.label',
    },
    'bookings.0.postingText': {
      label: 'inputs.postingText.label',
    },
    'bookings.0.note': {
      label:
        getTranslationContext() === 'sap'
          ? 'inputs.note.label_sap'
          : 'inputs.note.label',
    },
    'bookings.0.generalLedgerAccount.value': {
      label: 'inputs.generalLedger.label',
    },
    'bookings.0.taxCode': {
      label: 'inputs.bookingKey.label',
    },
    'bookings.0.costCenter.value': {
      label:
        getTranslationContext() === 'sap'
          ? 'inputs.costCenter.label_sap'
          : 'inputs.costCenter.label',
    },
    'bookings.0.costObject.value': {
      label:
        getTranslationContext() === 'sap'
          ? 'inputs.costObject.label_sap'
          : 'inputs.costObject.label',
    },
    'bookings.0.artistSocialInsuranceCode': {
      label: 'inputs.artistSocialInsuranceCode.label',
    },
    'bookings.0.extraCostInfo.value': {
      label: 'inputs.extraCostInfo.label',
    },
    'bookings.0.unitPrice': { label: 'inputs.unitPrice.label' },
    'bookings.0.quantity': {
      label: 'inputs.quantity.label',
    },
    'bookings.0.projectCode.value': {
      label: 'inputs.projectCode.label',
    },
  };
};

export type TableFormData = z.infer<typeof tableFormSchema>;
export type TableFormRow = TableFormData[number];
export type BookingsFormValues = BookingHeaderValues & {
  bookings: TableFormData;
};

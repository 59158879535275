import { Color } from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';

export const VIEW_PARAM_VALUE = 'reimbursement_items';

export const REIMBURSEMENT_URL_PARAM = {
  VIEW: 'view',
  CURSOR: 'cursor',
  DASHBOARDLIST: 'dashboardList',
} as const;

export const DASHBOARDLIST_PARAM_VALUES = {
  openReimbursements: 'openReimbursements',
  completedReimbursements: 'completedReimbursements',
} as const;

export const mappedReimbursementStatuses: Record<
  ReimbursementCaseStatus,
  { color: Color; key: string }
> = {
  APPROVED: { color: 'green', key: 'status.approved' },
  APPROVING: { color: 'yellow', key: 'status.approving' },
  ARCHIVED: { color: 'gray', key: 'status.archived' },
  CHECK: { color: 'red', key: 'status.check' },
  DRAFT: { color: 'gray', key: 'status.draft' },
  EXPORTED: { color: 'blue', key: 'status.exported' },
  REJECTED: { color: 'red', key: 'status.rejected' },
  REVIEW: { color: 'yellow', key: 'status.review' },
  PARTIALLY_EXPORTED: { color: 'yellow', key: 'status.partiallyExported' },
  EXPORTING: { color: 'yellow', key: 'status.exporting' },
};

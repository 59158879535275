import { ApolloError, QueryHookOptions, useQuery } from '@apollo/client';
import { Query } from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { getCountExportableEntities } from '../../../../../hooks/useCounterQueries';

interface ExportCounterProps {
  loading: boolean;
  readyToExportEntitiesCount: number;
  notReadyToExportEntitiesCount: number;
  provisionsCount: number;
  error: ApolloError | undefined;
  refetch: () => void;
}
interface ExportCounterQueryProps extends QueryHookOptions {}

export const useExportableEntitiesCounter = (
  queryOptions: ExportCounterQueryProps
): ExportCounterProps => {
  const { data, loading, error, refetch } = useQuery<
    Pick<Query, 'getCountExportableEntities'>
  >(getCountExportableEntities, {
    ...queryOptions,
  });

  const { exportProvisionsFF, bdsConnected } = useDatev(); // BDS-checked

  const { canUseReimbursement } = useReimbursement();

  const {
    documentReadyForExportCount,
    transactionReadyForExportCount,
    pendingTransactionCount,
    settlementsReadyForExportCount,
    settlementsNotReadyForExportCount,
    provisionsReadyForExportCount,
    reversalsReadyForExportCount,
    reimbursementItemsReadyForExportCount,
  } = data?.getCountExportableEntities || {};

  const reimbursementItemsCount = canUseReimbursement
    ? (reimbursementItemsReadyForExportCount?.count ?? 0)
    : 0;

  return {
    refetch,
    loading,
    readyToExportEntitiesCount:
      (documentReadyForExportCount?.count ?? 0) +
      (transactionReadyForExportCount?.count ?? 0) +
      (settlementsReadyForExportCount?.count ?? 0) +
      reimbursementItemsCount +
      (exportProvisionsFF && bdsConnected
        ? (provisionsReadyForExportCount?.count ?? 0) +
          (reversalsReadyForExportCount?.count ?? 0)
        : 0),
    notReadyToExportEntitiesCount:
      (pendingTransactionCount?.count || 0) +
      (settlementsNotReadyForExportCount?.count ?? 0),
    provisionsCount:
      (provisionsReadyForExportCount?.count ?? 0) +
      (reversalsReadyForExportCount?.count ?? 0),
    error,
  };
};

import { CSSObject } from '@emotion/react';
import { motion } from 'motion/react';
import React, {
  ComponentProps,
  FocusEvent,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react';
import {
  AriaButtonProps,
  mergeProps,
  useButton,
  useComboBox,
  useFilter,
} from 'react-aria';
import mergeRefs from 'react-merge-refs';
import { ComboBoxStateOptions, useComboBoxState } from 'react-stately';
import { Box } from '../../Atoms/Box';
import {
  FieldContainer,
  FieldContainerProps,
  Variant,
  useFieldContext,
} from '../../Atoms/FieldContainer';
import { ClearButton } from '../../Atoms/FieldContainer/ClearButton';
import { StatusIndicator } from '../../Atoms/FieldContainer/StatusIndicator';
import { Flex } from '../../Atoms/Flex';
import { Grid } from '../../Atoms/Grid';
import { Icon } from '../../Atoms/Icon/next';
import { GroupedListBox } from '../../Atoms/ListBox/GroupedList/GroupedListBox';
import { ListBox } from '../../Atoms/ListBox/ListBox';
import { ListBoxPopup } from '../../Atoms/ListBoxPopup';
import { Spinner } from '../../Atoms/Spinner';
import { LayoutProps, StandardHTMLAttributes } from '../../types';
import { Input } from '../TextField';

const MotionIcon = motion.create(Icon);

export interface ComboBoxFieldProps
  extends LayoutProps,
    StandardHTMLAttributes<HTMLDivElement>,
    Omit<FieldContainerProps, 'children'> {
  disabled?: boolean;
  label?: string;
  message?: React.ReactNode;
  optionalHint?: string;
  readOnly?: boolean;
  select: ComboBoxProps;
  popoverHeight?: string;
  showMessageOnFocus?: boolean;
  showStatusOnReadOnly?: boolean;
}

/**
A combo box combines a text input with a listbox, allowing users to filter a list of options to items matching a query.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/molecules-forms-comboboxfield}
 *
 * @typedef {Object} ComboBoxProps
 * @property {string} [placeholder] The list of combo box items (uncontrolled)
 * @property {Iterable<any>} [defaultItems] The list of combo box items (uncontrolled)
 * @property {Iterable<any>} [defaultItems] The list of combo box items (uncontrolled)
 * @property {Iterable<any>} [items] The list of combo box items (controlled)
 * @property {(isOpen: boolean, menuTrigger?: MenuTriggerAction) => void} [onOpenChange] Method that is called when the open state of the menu changes. Returns the new open state and the action that caused the opening of the menu
 * @property {string} [inputValue] The value of the combo box input (controlled)
 * @property {string} [defaultInputValue] The default value of the combo box input (uncontrolled)
 * @property {(value: string) => void} [onInputChange] Handler that is called when the combo box input value changes
 * @property {MenuTriggerAction} [menuTrigger] The interaction required to display the combo box menu
 * @property {React.Key} [selectedKey] The currently selected key in the collection (controlled)
 * @property {React.Key} [defaultSelectedKey] The initial selected key in the collection (uncontrolled)
 * @property {(key: React.Key) => any} [onSelectionChange] Handler that is called when the selection changes
 * @property {boolean} [autoFocus] Automatically focus on first render  
 * @param {ComboBoxProps} select Props for input, list and button composition
 * @param {React.ReactNode} [message] Tooltip content
 * @param {ComboBoxStateOptions<any>['children']} children Items passed to combo box list
 * @param {boolean} [disabled] Puts component in disabled state
 * @param {boolean} [readOnly] Puts component in readOnly state
 * @param {boolean} [label] Field label
 * @param {string} [optionalHint] Hint displayed next to the label about the field being optional
 */
export const ComboBoxField = React.forwardRef<
  HTMLDivElement,
  ComboBoxFieldProps
>(
  (
    {
      disabled,
      label,
      message,
      optionalHint,
      readOnly,
      select,
      variant,
      popoverHeight,
      showMessageOnFocus,
      showStatusOnReadOnly,
      ...restProps
    },
    ref
  ) => {
    return (
      <FieldContainer
        disabled={disabled}
        label={label}
        message={message}
        variant={variant}
        optionalHint={optionalHint}
        readOnly={readOnly}
        ref={ref}
        {...restProps}
      >
        <ComboBox
          message={message}
          variant={variant}
          popoverHeight={popoverHeight}
          showMessageOnFocus={showMessageOnFocus}
          showStatusOnReadOnly={showStatusOnReadOnly}
          {...select}
        />
      </FieldContainer>
    );
  }
);

type MenuTriggerAction = 'focus' | 'input' | 'manual';

export interface ComboBoxProps extends ComboBoxStateOptions<any> {
  autoFocus?: boolean;
  defaultInputValue?: string;
  defaultItems?: Iterable<any>;
  defaultSelectedKey?: string | number;
  disabled?: boolean;
  emptyListPlaceholder?: ReactNode;
  inputValue?: string;
  /** Use together with `isVirtualized` to specify initial scroll position of selected item */
  initialTopMostItemIndex?: ComponentProps<
    typeof ListBox | typeof GroupedListBox
  >['initialTopMostItemIndex'];
  /** Use list virtualization? */
  isVirtualized?: boolean;
  items?: Iterable<any>;
  loading?: boolean;
  menuTrigger?: MenuTriggerAction;
  /** Called when user scrolls to the end of the list */
  onEndReached?: (index: number) => void;
  onInputChange?: (value: string) => void;
  onOpenChange?: (isOpen: boolean, menuTrigger?: MenuTriggerAction) => void;
  onSelectionChange?: (key: string | number | null) => any;
  placeholder?: string;
  readOnly?: boolean;
  /** Render function that can be passed to render custom content in the dropdown */
  renderCustomDropdown?: (list: ReactElement) => ReactNode;
  /** Necessary if we need to render inside the dropdown items taller than 32px */
  itemHeight?: LayoutProps['height'];
  selectedKey?: string | number | null;
  /** Optional prop that can be passed to keep dropdown open after blur */
  persistDropdown?: boolean;
  /** status variant i.e success, warning, error */
  variant?: Variant;
  /** message to display in popover of status */
  message?: ReactNode;
  showSeparator?: boolean;
  /** Allow to clear selection */
  clearable?: boolean;
  /** Enable to control from outside whether we want to show the clear action and how it behaves */
  onClear?: () => void;
  /** message to display in popover of clear button */
  clearLabel?: string;
  /** Set the height of the the popover */
  popoverHeight?: string;
  /** Show error message on focus */
  showMessageOnFocus?: boolean;
  /** These are optonal props to be used for showing grouped lists in the dropdown */
  /**  boolean to determine if to show grouped lists */
  isGroupedListMode?: boolean;
  /**  list of grouped items */
  groupedLists?: Array<Array<any>>;
  /**  headers for the grouped items */
  groupHeaders?: string[];
  /** allows to show status even the form readonly or disabled */
  showStatusOnReadOnly?: boolean;
}

export const ComboBox = React.forwardRef<HTMLDivElement, ComboBoxProps>(
  (
    {
      allowsCustomValue,
      allowsEmptyCollection,
      disabled,
      emptyListPlaceholder,
      initialTopMostItemIndex,
      isVirtualized,
      loading,
      menuTrigger,
      onEndReached,
      itemHeight = 'space32',
      onKeyDown,
      readOnly,
      renderCustomDropdown,
      persistDropdown,
      showSeparator,
      message,
      variant,
      clearable,
      onClear,
      clearLabel,
      popoverHeight,
      showMessageOnFocus = false,
      isGroupedListMode,
      groupHeaders,
      groupedLists,
      showStatusOnReadOnly = false,
      ...restProps
    },
    forwardedRef
  ) => {
    const {
      fieldContainerElement,
      inputCss,
      inputProps: fieldContainerInputProps,
      inputRef: fieldContainerInputRef,
    } = useFieldContext();

    const { contains } = useFilter({ sensitivity: 'base' });

    const isReadOnly = readOnly || fieldContainerInputProps.readOnly;
    const isDisabled = disabled || fieldContainerInputProps.disabled;

    const state = useComboBoxState({
      allowsCustomValue,
      allowsEmptyCollection:
        allowsEmptyCollection ?? emptyListPlaceholder !== undefined,
      defaultFilter: contains,
      isDisabled,
      isReadOnly,
      menuTrigger,
      onKeyDown,
      ...restProps,
    });

    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const listBoxRef = React.useRef<HTMLDivElement>(null);
    const popoverRef = React.useRef<HTMLDivElement>(null);

    const { buttonProps, inputProps, listBoxProps } = useComboBox(
      {
        allowsCustomValue,
        buttonRef,
        inputRef,
        isDisabled,
        isReadOnly,
        listBoxRef,
        popoverRef,
        ...restProps,
      },
      { ...state, focusStrategy: 'first' }
    );

    const [comboBoxElement, setComboBoxElement] =
      useState<HTMLDivElement | null>(null);

    const triggerElement = fieldContainerElement ?? comboBoxElement;

    // here come the hacks!
    const customInputProps = {
      onClick: () => {
        // open listbox and display *all* options
        state.open('first', 'manual');
      },
      onBlur: (e: FocusEvent<HTMLInputElement>) => {
        const blurIntoPopover = popoverRef.current?.contains(e.relatedTarget);
        // Ignore blur if triggered within popover
        if (blurIntoPopover) {
          return;
        }

        state.setFocused(false);

        if (persistDropdown) return;
        state.close();
      },
      onKeyDownCapture: (e: KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;

        if (key === 'Tab' || key === 'Enter') {
          // If only one item in listbox, select the item
          if (
            state.isOpen &&
            state.inputValue.length > 0 &&
            state.collection.size === 1
          ) {
            const firstKey = state.collection.getFirstKey();
            if (firstKey) {
              state.selectionManager.select(firstKey);
            }
          }
        }
      },
      tabIndex: isDisabled || isReadOnly ? -1 : undefined,
    };

    const renderEmptyListPlaceholder = () => {
      return typeof emptyListPlaceholder === 'string' ? (
        <Box color="gray500" paddingX="space16" paddingY="space24">
          {emptyListPlaceholder}
        </Box>
      ) : (
        emptyListPlaceholder
      );
    };

    const showClearButton = clearable && !isDisabled && !isReadOnly;
    const showStatus = !isDisabled && !isReadOnly && (message || variant);

    const showFooter = !isReadOnly && !isDisabled;

    const showStatusWithoutFooter =
      showStatusOnReadOnly && (message || variant) && !showFooter;

    return (
      <>
        <Grid
          gap={showFooter ? 'space8' : 'space0'}
          templateColumns="1fr auto"
          ref={mergeRefs([setComboBoxElement, forwardedRef])}
        >
          <Input
            css={inputCss}
            {...mergeProps(
              inputProps,
              fieldContainerInputProps,
              customInputProps
            )}
            ref={mergeRefs([inputRef, fieldContainerInputRef])}
          />
          {loading ? (
            <Spinner color="gray700" size="space16" flex="none" />
          ) : (
            <>
              {showStatusWithoutFooter && (
                <StatusIndicator
                  message={message}
                  //  @ts-expect-error BE returns default variant, which we have no use for here
                  variant={variant}
                  showMessage={false}
                />
              )}
              {showFooter && (
                <Flex
                  gap={showClearButton || showStatus ? 'space8' : 'space0'}
                  alignItems="center"
                >
                  <StatusIndicator
                    message={message}
                    //  @ts-expect-error BE returns default variant, which we have no use for here
                    variant={variant}
                    showMessage={state.isFocused && showMessageOnFocus}
                  />
                  {(onClear || (clearable && state.inputValue)) && (
                    <ClearButton
                      clearLabel={clearLabel}
                      onClear={() => {
                        if (onClear) {
                          onClear();
                        } else {
                          state.setInputValue('');
                        }
                      }}
                    />
                  )}
                  <CaretButton isOpen={state.isOpen} {...buttonProps} />
                </Flex>
              )}
            </>
          )}
        </Grid>
        {!isReadOnly && state.isOpen && triggerElement && (
          <ListBoxPopup
            listBoxProps={{
              ...listBoxProps,
              isVirtualized,
              onEndReached,
              itemHeight,
              initialTopMostItemIndex,
              showSeparator,
              height: popoverHeight,
              groupHeaders,
              groupedLists,
            }}
            isGroupedListMode={isGroupedListMode}
            renderCustomDropdown={
              state.collection.size === 0
                ? renderEmptyListPlaceholder
                : renderCustomDropdown
            }
            state={state}
            triggerElement={triggerElement}
            ref={popoverRef}
          />
        )}
      </>
    );
  }
);

interface CaretButtonProps extends AriaButtonProps {
  isOpen?: boolean;
}

const CaretButton = React.forwardRef<HTMLButtonElement, CaretButtonProps>(
  ({ isOpen = false, ...restProps }, forwardedRef) => {
    const ref = useRef(null);
    const { buttonProps } = useButton(restProps, ref);

    const css: CSSObject = {
      all: 'unset',
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      alignItems: 'center',
      cursor: 'pointer',
      gap: 'space8',
    };

    return (
      <button css={css} {...buttonProps} ref={mergeRefs([ref, forwardedRef])}>
        <MotionIcon
          animate={isOpen ? 'open' : 'closed'}
          variants={{
            open: { rotate: 180 },
            closed: { rotate: 0 },
          }}
          transition={{
            rotate: { duration: 0.25 },
            default: { ease: 'easeInOut' },
          }}
          className="w-4 h-4 text-gray-450"
          aria-hidden="true"
          icon="caretDown"
        />
      </button>
    );
  }
);

import {
  useBookingKeysActiveQuery,
  useOrganizationQuery,
} from 'generated-types/graphql.types';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { useSap } from 'orgConfig/sap';
import { useOrganizationId } from 'providers/OrganizationProvider';
import {
  useShouldRequireGeneralLedgerAccounts,
  useShouldRequireTaxCodes,
} from 'views/utils/useShouldRequireField';
import { BookingsFormSchemaContext } from '../types';

export const useFormSchemaContext = (): BookingsFormSchemaContext => {
  const { shouldUseSapNetAmount, shouldUseSapPurchaseOrder } = useSap();
  const shouldRequireGeneralLedgerAccount =
    useShouldRequireGeneralLedgerAccounts();

  const shouldRequireTaxCode = useShouldRequireTaxCodes();
  const organizationId = useOrganizationId();
  const { data: organizationData } = useOrganizationQuery({
    variables: { id: organizationId },
  });

  const hasCostCenters =
    organizationData?.organization?.hasCostCenters ?? false;

  const hasCostObjects =
    organizationData?.organization?.hasCostObjects ?? false;

  const { data: bookingKeysActiveData } = useBookingKeysActiveQuery();
  const availableBookingKeys = bookingKeysActiveData?.bookingKeysActive ?? [];
  const integration = useIntegrationSettings();
  //   const bookings = useBookingsValues({ skipWatch: false });
  //   const hasReversedTaxCodes = useHasReverseChargeTaxCode(bookings);

  return {
    shouldUseSapNetAmount,
    shouldUseSapPurchaseOrder,
    shouldRequireGeneralLedgerAccount,
    shouldRequireTaxCode,
    hasCostCenters,
    hasCostObjects,
    availableTaxCodes: availableBookingKeys,
    integration,
    hasReversedTaxCodes: false, // TODO will add context for this
  };
};

import {
  CardIssuerPaymentSettlement,
  CardIssuerStatement,
  ExportableEntityInfo,
  ExportableEntityType,
  Maybe,
} from 'generated-types/graphql.types';
import { ExportEntity } from '../../types';
import { mapCardSettlementToExportEntity } from './mapCardSettlementToExportEntity';

export type Settlement = Pick<
  CardIssuerPaymentSettlement,
  | 'id'
  | 'actualPaymentDate'
  | 'amount'
  | 'paymentCreatedAt'
  | 'publicPaymentId'
  | 'plannedPaymentDate'
  | 'status'
> & { statement?: Maybe<Pick<CardIssuerStatement, 'id' | 'hasFile'>> };

export const toSettlementExportEntity =
  ({
    exportableEntitiesInfo,
  }: {
    exportableEntitiesInfo: ExportableEntityInfo[];
  }) =>
  (settlement: Settlement): ExportEntity => {
    const exportDocument = mapCardSettlementToExportEntity(settlement);
    const exportableEntityInfo = exportableEntitiesInfo?.find(
      exportableEntity =>
        exportableEntity.id === settlement.id &&
        exportableEntity.type === ExportableEntityType.CardSettlement
    );

    if (exportableEntityInfo)
      exportDocument.exportStatus = {
        ...exportableEntityInfo,
        entityType: ExportableEntityType.CardSettlement,
        details:
          (exportableEntityInfo.details?.filter(Boolean) as string[]) ?? [],
        warnings:
          (exportableEntityInfo.warnings?.filter(Boolean) as string[]) ?? [],
      };

    return exportDocument;
  };

import { Slot, SlotProps } from '@candis/utils';
import clsx from 'clsx';
import { HTMLProps } from 'react';
import styles from './SwimlaneTile.module.css';

export type SwimlaneTileVariant =
  | 'blue'
  | 'gray'
  | 'purple'
  | 'red'
  | 'white'
  | 'yellow';

type SwimlaneTileProps = HTMLProps<HTMLDivElement> &
  SlotProps & {
    cols?: 1 | 2;
    variant?: SwimlaneTileVariant;
  };

export const SwimlaneTile = ({
  asChild,
  cols = 1,
  className,
  variant = 'white',
  ...otherProps
}: SwimlaneTileProps) => {
  const Child = asChild ? Slot : 'div';
  const classes = clsx(styles.tile, className);

  return (
    <Child
      className={classes}
      {...otherProps}
      data-cols={cols}
      data-variant={variant}
    />
  );
};

import { Grid } from '@candisio/design-system';
import { GlobalHotKeys } from 'components/GlobalHotKeys/GlobalHotKeys';
import { ReauthenticationModal } from 'components/ReauthenticationModal/ReauthenticationModal';
import { ToastProvider } from 'components/Toast/ToastProvider';
import { useGlobalDropZone } from 'components/UploadDropZone/useGlobalDropZone';
import { GraphQLLoadingIndicator } from 'containers/GraphQLLoadingIndicator/GraphQLLoadingIndicator';
import { NotificationsContainer } from 'containers/notifications/NotificationsProvider';
import { Locale } from 'generated-types/graphql.types';
import moment from 'moment';
import { AnalyticsProvider } from 'providers/AnalyticsProvider';
import { AppLayoutProvider, useAppLayout } from 'providers/AppLayoutProvider';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { DocumentUploadContextProvider } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { FeatureFlagProvider } from 'providers/FeatureFlagProvider';
import { FeatureToggleProvider } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { IntercomProvider } from 'providers/IntercomProvider/IntercomProvider';
import { i18n } from 'providers/LocaleProvider';
import {
  NavigationSidebarProvider,
  useNavigationSidebarContext,
} from 'providers/NavigationSidebarProvider/NavigationSidebarProvider';
import { useSelectOrganization } from 'providers/OrganizationProvider';
import { OrganizationContext } from 'providers/OrganizationProvider/OrganizationContext';
import { PrivacyConsentProvider } from 'providers/PrivacyConsentProvider/PrivacyConsentProvider';
import { ReauthenticationProvider } from 'providers/ReauthenticationProvider/ReauthenticationProvider';
import { ThemeProvider } from 'providers/ThemeProvider';
import { VWOAnalyticProvider } from 'providers/VMOAnalyticProvider/VWOAnalyticProvider';
import { ReactNode, useContext, useEffect } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import {
  languageToMomentLocale,
  updateAppLanguage,
} from 'utils/update-app-locale';
import { ExportManifest } from '../Integrations/Export/Manifest';
import { AppRoutes } from './AppRoutes';
import { AppBanner } from './components/AppBanner/AppBanner';
import { NavigationSidebar } from './components/NavigationSidebar/NavigationSidebar';
import { AppLayoutDeprecated, ContentLayout, HeaderLayout } from './styles';

interface AppLayoutProps {
  children: ReactNode;
}

export const AppLayout = ({ children }: AppLayoutProps) => {
  const { useLayoutValue } = useAppLayout();
  const { open: docUploadPopover } = useLayoutValue('docUploadPopover');
  const { toggleSidebar } = useNavigationSidebarContext();
  const { dropProps, ref } = useGlobalDropZone({
    onDropEnter: () => {
      if (!docUploadPopover) {
        toggleSidebar(true);
      }
    },
  });

  return (
    <>
      <GlobalHotKeys />
      <Grid
        {...dropProps}
        ref={ref}
        as="main"
        background="gray200"
        gap="space20"
        height="100vh"
        overflow="hidden"
        style={{
          gridTemplateColumns: 'auto 1fr',
          gridTemplateAreas: "'sidebar content'",
        }}
      >
        {children}
      </Grid>
    </>
  );
};

export const RenderApp = ({
  path,
  selectedOrganization,
}: {
  path: string;
  selectedOrganization: string;
}) => {
  return (
    <AppLayout>
      <AppBanner />
      <ReauthenticationModal />
      <NavigationSidebar />
      <ContentLayout id="main_content">
        {/** CAN-1126 Must be refactored to be more generic */}
        <AppRoutes path={path} selectedOrganization={selectedOrganization} />
        {/* TODO replace with NavigationSidebar */}

        <ExportManifest />
        <NotificationsContainer />
      </ContentLayout>
    </AppLayout>
  );
};

type Props = {} & RouteComponentProps<{ organizationSlug: string }>;

export const AppContainer = ({
  match: {
    path,
    params: { organizationSlug },
  },
}: Props) => {
  useSelectOrganization(organizationSlug);

  const currentUser = useCurrentUser();

  const { selectedOrganization } = useContext(OrganizationContext);

  // XXX we cannot use FFs in here

  const locale = currentUser?.locale ?? '';
  const htmlElement: HTMLElement | null = document.querySelector('html');

  useEffect(() => {
    moment.locale(languageToMomentLocale[i18n.language as Lowercase<Locale>]);
    if (locale && i18n.language !== locale.toLowerCase()) {
      updateAppLanguage(locale);
    }

    htmlElement?.setAttribute('lang', locale.toLowerCase());
  }, [htmlElement, locale]);

  if (
    !selectedOrganization ||
    !organizationSlug ||
    organizationSlug !== selectedOrganization
  ) {
    // no selected organization on initial render so do not render;
    // wait till useSelectOrganization sets selectedOrganization with useEffect
    return (
      <AppLayoutDeprecated>
        <HeaderLayout />
      </AppLayoutDeprecated>
    );
  }

  return (
    /** @ts-expect-error TODO: React upgrade props types mismatch */
    <FeatureFlagProvider>
      <FeatureToggleProvider>
        <AppLayoutProvider>
          <ThemeProvider>
            <PrivacyConsentProvider>
              <NavigationSidebarProvider>
                <AnalyticsProvider>
                  <IntercomProvider>
                    <VWOAnalyticProvider>
                      <GraphQLLoadingIndicator>
                        <DocumentUploadContextProvider>
                          <ReauthenticationProvider>
                            <ToastProvider>
                              <RenderApp
                                path={path}
                                selectedOrganization={selectedOrganization}
                              />
                            </ToastProvider>
                          </ReauthenticationProvider>
                        </DocumentUploadContextProvider>
                      </GraphQLLoadingIndicator>
                    </VWOAnalyticProvider>
                  </IntercomProvider>
                </AnalyticsProvider>
              </NavigationSidebarProvider>
            </PrivacyConsentProvider>
          </ThemeProvider>
        </AppLayoutProvider>
      </FeatureToggleProvider>
    </FeatureFlagProvider>
  );
};

import moment from 'moment';

export const isInTheFuture = (date: string | null | undefined) => {
  const valueMoment = moment(date, moment.ISO_8601);
  const now = moment.tz('Europe/Berlin');

  if (valueMoment.isAfter(now)) {
    return true;
  }

  return false;
};

import { DownloadDocumentPdFsErrorKind } from 'generated-types/graphql.types';

interface GetArchiveDocumentZipErrorMessageProps {
  errorType?: DownloadDocumentPdFsErrorKind;
}

const errorMessagesMap: Record<
  Partial<DownloadDocumentPdFsErrorKind>,
  string
> = {
  [DownloadDocumentPdFsErrorKind.LimitReached]:
    'archive:download.error.limitReached',
  [DownloadDocumentPdFsErrorKind.Forbidden]: 'archive:download.error.forbidden',
  [DownloadDocumentPdFsErrorKind.NoDocumentsFound]:
    'archive:download.error.noDocumentsSelected',
  [DownloadDocumentPdFsErrorKind.Unknown]:
    'archive:download.error.unknownError',
};

export const getArchiveDocumentZipDownloadErrorMessage = ({
  errorType,
}: GetArchiveDocumentZipErrorMessageProps) => {
  return errorType
    ? errorMessagesMap[errorType]
    : 'archive:download.error.unknownError';
};

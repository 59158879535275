import {
  DatevClientExportType,
  useNew_DatevSettingsQuery,
} from 'generated-types/graphql.types';

export const useDatevSettings = () => {
  const { data } = useNew_DatevSettingsQuery();

  const exportType = data?.new_datevSettings?.client?.exportType;
  const connectionType = data?.new_datevSettings?.connectionType;

  const isDatevConnected =
    exportType === DatevClientExportType.DxsoJobs ||
    exportType === DatevClientExportType.AccountingDocuments;

  return {
    data,
    connectionType,
    isDatevConnected,
  };
};

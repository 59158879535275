import { useCallback, useEffect, useRef, useState } from 'react';

type UseDeferredState<T> = [
  value: T,
  setValue: (nextValue: T, waitMs?: number) => void,
];

export const useDeferredState = <T>(initialValue: T): UseDeferredState<T> => {
  const [value, setValue] = useState<T>(initialValue);
  const timer = useRef<NodeJS.Timeout>();

  const setValueDeferred = useCallback((nextValue: T, waitMs?: number) => {
    clearTimeout(timer.current);

    if (typeof waitMs === 'undefined') {
      setValue(nextValue);
      return;
    }

    timer.current = setTimeout(() => {
      setValue(nextValue);
    }, waitMs);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return [value, setValueDeferred];
};

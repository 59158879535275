import { useToastMessage } from 'components/Toast/useToastMessage';
import { useCreateDataFileForDocumentsMutation } from 'generated-types/graphql.types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils/downloadFile';
import { useComputeQueryFilters } from 'views/Archive/ArchiveDocumentsTable/hooks/useComputeQueryFilters';
import { refetchDocumentDataFileRecords } from 'views/Archive/Documents/queries';

interface UseDownloadCSVFileReturn {
  handleCSVDownload: () => Promise<
    | {
        status: 'error';
      }
    | undefined
  >;
  isLoading: boolean;
}

export const useDownloadCSVFile = (
  archiveOrSearchQueryIsLoading: boolean
): UseDownloadCSVFileReturn => {
  const [t] = useTranslation();
  const { success, error, info } = useToastMessage();

  const [createDownloadFile, { loading: isLoading }] =
    useCreateDataFileForDocumentsMutation();

  const { filters, dateRangeFilters, query, searchInput } =
    useComputeQueryFilters();

  const handleCSVDownload = useCallback(async (): Promise<
    { status: 'error' } | undefined
  > => {
    if (archiveOrSearchQueryIsLoading) {
      info(t('insights:download.waitForLoading'));
    }

    const results = await createDownloadFile({
      refetchQueries: refetchDocumentDataFileRecords,
      variables: {
        dateRangeFilters,
        filters,
        query,
        searchInput,
      },
    });

    if (!results.data?.createDataFileForDocuments?.file?.url) {
      error(t('insights:download.errorWhenDownloading'));

      return { status: 'error' };
    } else {
      try {
        const fileName = results.data?.createDataFileForDocuments?.file?.name;
        const fileUrl = results.data?.createDataFileForDocuments?.file?.url;

        downloadFile(fileUrl, fileName);
        success(t('insights:download.successfullyDownloaded'));
      } catch (_err) {
        error(t('insights:download.errorWhenDownloading'));

        return { status: 'error' };
      }
    }
  }, [
    archiveOrSearchQueryIsLoading,
    createDownloadFile,
    dateRangeFilters,
    error,
    filters,
    searchInput,
    info,
    query,
    success,
    t,
  ]);

  return {
    handleCSVDownload,
    isLoading,
  };
};

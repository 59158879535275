import { Flex, Icon, Paragraph, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Trans, useTranslation } from 'react-i18next';

type LinkingRestrictedToTypeProps = {
  linkedDocumentType: 'goodsReceipt' | 'purchaseOrder';
};

export const LinkingRestrictedToType = ({
  linkedDocumentType,
}: LinkingRestrictedToTypeProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  return (
    <Flex
      direction="column"
      height="100%"
      background="gray0"
      borderRadius="basic"
      justifyContent="center"
      alignItems="center"
      gap="space16"
    >
      <Icon icon="close" color="gray400" size="space80" />
      <Text fontSize="basic" fontWeight="semibold" color="gray800">
        {t('addPurchaseOrderModal.restrictedToType.title')}
      </Text>
      <Paragraph
        fontSize="small"
        color="gray800"
        maxWidth="space256"
        textAlign="center"
      >
        <Trans
          i18nKey={`addPurchaseOrderModal.restrictedToType.${linkedDocumentType}`}
          ns={LOCALE_NAME_SPACE.PURCHASE_ORDERS}
        />
      </Paragraph>
    </Flex>
  );
};

import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import {
  EcmDocumentsTable,
  EcmDocumentsTableProps,
  defaultSortByFields,
} from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { DocumentPreviewDrawer } from 'containers/DocumentPreview/DocumentPreviewDrawer';
import { DocumentTableRowActionsOverlay } from 'containers/DocumentPreview/DocumentTableRowActionsOverlay';
import { DocumentPreviewUpsellDrawer } from 'containers/DocumentPreviewUpsell/DocumentPreviewUpsellDrawer';
import { useEcmDocumentsTableData } from 'containers/document-relationships/useEcmDocumentsTableData';
import { DocumentType } from 'generated-types/graphql.types';
import { AppRouteParams, Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { DocumentPreviewEventLocations } from 'providers/AnalyticsProvider/events';
import { useCallback, useMemo, useState } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import { generatePath } from 'react-router-dom-v5-compat';
import { TabView } from 'views/Inbox/models';
import { isEcmDocumentId } from 'views/utils/isEcmDocumentId';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';
import { getRawContentMatchesCount } from 'components/WithHighlightsCell/getRawContent';

type Props = RouteComponentProps<{ organizationSlug: string }>;

export const EcmDocumentsDeprecated = ({
  match: {
    params: { organizationSlug },
  },
}: Props) => {
  const {
    showDocumentPreview,
    showDocumentPreviewPromo,
    showConsistentSortAndFiltering,
  } = useEcm();
  const showPreview = showDocumentPreview || showDocumentPreviewPromo;

  const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(
    null
  );

  const {
    availableDocumentColumnIds,
    searchQuery,
    searchParams,
    data,
    isLoading,
    filters,
    isTableFiltered,
    configurationsTable,
    totalCount,
    isSavingConfigurations,
    isResetPending,
    customEmptyState,
    sortBy,
    handleSearchChange,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    onFilter,
    onLoadMore,
    onSort,
  } = useEcmDocumentsTableData({
    filterParamsSource: 'url',
    previewDocumentId,
  });

  const getTableRowDocumentType = useCallback(
    (documentId: string) => {
      const tableRow = data.find(
        row => row.id === documentId || row.invoiceId === documentId
      );

      if (
        tableRow === undefined ||
        tableRow.documentType === DocumentType.Invoice
      ) {
        return 'invoice';
      }

      return 'ecmDocument';
    },
    [data]
  );

  const documentPreviewRowOverlay: EcmDocumentsTableProps['rowOverlay'] =
    useMemo(
      () =>
        showPreview
          ? ({ data }) => {
              if (data.selected) return <></>; // TODO: Fix DS type to allow to return null
              const rawContentHighlightCount = getRawContentMatchesCount(
                data.highlights
              );

              const isSelected = previewDocumentId
                ? data.id === previewDocumentId ||
                  data.invoiceId === previewDocumentId
                : false;

              return (
                <DocumentTableRowActionsOverlay
                  data={data}
                  onQuickViewClick={data => {
                    const isEcmDocument =
                      getTableRowDocumentType(data.id) === 'ecmDocument';

                    setPreviewDocumentId(
                      isEcmDocument ? data.id : (data.invoiceId ?? '')
                    );
                  }}
                  isSelected={isSelected}
                  previewHighlightsCount={rawContentHighlightCount}
                />
              );
            }
          : undefined,
      [showPreview, getTableRowDocumentType, previewDocumentId]
    );

  const openDocument = useCallback(
    ({
      documentId,
      cursor,
    }: {
      documentId: string | null;
      cursor: string | null;
    }): void => {
      if (!documentId) return;

      const params = new URLSearchParams();
      if (cursor) params.set('cursor', cursor);
      if (isEcmDocumentId(documentId)) params.set('isInvoice', 'true');

      const path = `/${organizationSlug}${Routes.ECM_DOCUMENTS}/${documentId}?${params}`;

      window.open(path, '_blank');
    },
    [organizationSlug]
  );

  const closePreview = useCallback(() => {
    setPreviewDocumentId(null);
  }, []);

  const cursor =
    data.find(
      d => d.id === previewDocumentId || d.invoiceId === previewDocumentId
    )?.cursor ?? null;

  const documentPreviewDrawer = useMemo(() => {
    if (showDocumentPreviewPromo) {
      return (
        <DocumentPreviewUpsellDrawer
          isOpen={!!previewDocumentId}
          closePreviewDrawer={closePreview}
          promotionLocation={DocumentPreviewEventLocations.ARCHIVE}
          documentId={previewDocumentId}
        />
      );
    }

    if (showDocumentPreview) {
      return (
        <DocumentPreviewDrawer
          documentPreviewEventLocation={DocumentPreviewEventLocations.ARCHIVE}
          closePreviewDrawer={closePreview}
          openDocument={() =>
            openDocument({ documentId: previewDocumentId, cursor })
          }
          documentId={previewDocumentId}
        />
      );
    }

    return null;
  }, [
    showDocumentPreviewPromo,
    showDocumentPreview,
    previewDocumentId,
    closePreview,
    openDocument,
    cursor,
  ]);

  const getPath = useCallback(
    ({ id, cursor, documentType, invoiceId }: EcmDocumentsTableData) => {
      if (!organizationSlug) return '';

      const isInvoice = documentType === DocumentType.Invoice;

      const documentId = invoiceId && isInvoice ? invoiceId : id;

      const pathname = generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.ECM_DOCUMENTS}/:documentId`,
        { organizationSlug, documentId }
      );

      if (isInvoice) {
        searchParams.set('isInvoice', 'true');
      } else {
        searchParams.delete('isInvoice');
      }

      if (cursor) searchParams.set('cursor', cursor);

      return { pathname, search: searchParams.toString() };
    },
    [organizationSlug, searchParams]
  );

  const cellWrapper = useMemo(() => getCellWrapperLink(getPath), [getPath]);
  const cellStyle = () => ({ padding: 'unset' });

  const sortByFields: (keyof EcmDocumentsTableData)[] =
    showConsistentSortAndFiltering
      ? [...defaultSortByFields, 'contact', 'documentNumber', 'documentName']
      : defaultSortByFields;

  return (
    <ArchiveViewLayout
      activeTab={TabView.ARCHIVE_ECM_DOCUMENTS}
      rightSide={documentPreviewDrawer}
    >
      <EcmDocumentsTable
        context="documents"
        columns={availableDocumentColumnIds}
        data={data}
        isLoading={isLoading}
        defaultFilters={filters}
        isTableFiltered={isTableFiltered}
        configurationsTable={configurationsTable}
        searchQuery={searchQuery}
        selectedDocumentsCount={totalCount}
        defaultSortBy={sortBy}
        isLoadingConfigurations={isSavingConfigurations || isResetPending}
        onSearchChange={handleSearchChange}
        onUpdateConfigurations={handleUpdateConfigurations}
        onFilter={onFilter}
        onEndReached={onLoadMore}
        onSort={onSort}
        customEmptyState={customEmptyState}
        onResetTableConfigurations={handleResetTableConfigurations}
        rowOverlay={documentPreviewRowOverlay}
        cellWrapper={cellWrapper}
        getCellStyles={cellStyle}
        sortByFields={sortByFields}
      />
    </ArchiveViewLayout>
  );
};

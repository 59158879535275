import { useCallback, useMemo } from 'react';
import { useLocalStorage } from './LocalStorage/useLocalStorage';
import {
  useDeletePromoPreferences,
  usePromoPreferences,
  useSavePromoPreferences,
} from './usePromoPreferences';

export const useSyncedPromoPreference = (promoId: string) => {
  const [localStorageState, setLocalStorageState] = useLocalStorage(
    promoId,
    false
  );
  const { getIsPromoSeen } = usePromoPreferences();
  const { savePromoPreferences } = useSavePromoPreferences();
  const { deletePromoPreferences } = useDeletePromoPreferences();

  const userPreferenceState = getIsPromoSeen(promoId);

  const isPromotionSeenState = useMemo(
    () => localStorageState || userPreferenceState,
    [localStorageState, userPreferenceState]
  );

  const setPromotionIsSeen = useCallback(
    (isSeen: boolean = true) => {
      if (isSeen) savePromoPreferences(promoId);
      else deletePromoPreferences(promoId);
      setLocalStorageState(isSeen);
    },
    [
      deletePromoPreferences,
      promoId,
      savePromoPreferences,
      setLocalStorageState,
    ]
  );

  return useMemo(
    () => ({
      isPromotionSeen: isPromotionSeenState,
      setPromotionIsSeen,
    }),
    [isPromotionSeenState, setPromotionIsSeen]
  );
};

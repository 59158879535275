import {
  FilterOption,
  PaginationWithSearchFilterHook,
  fetchPolicy,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  ContactSortField,
  ContactsInfiniteScrollPaginationQuery,
  ContactsInfiniteScrollPaginationQueryVariables,
  SortDirection,
  useContactsPaginationQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';
import { contactsInfiniteScrollPaginationQuery } from 'views/Contacts/queries';

export const useGetSapContactNameFilter: PaginationWithSearchFilterHook = ({
  filteredValues,
  searchStr,
}) => {
  const computeVariables = useCallback(
    (inputString?: string): ContactsInfiniteScrollPaginationQueryVariables => ({
      queries: {
        name: inputString,
      },
      filters: {
        isArchived: false,
      },
      sortBy: {
        direction: SortDirection.Asc,
        field: ContactSortField.Name,
      },
    }),
    []
  );

  const {
    data: allContacts,
    loading: allContactsLoading,
    loadMore,
    handleDebounceSearch,
  } = useDebouncedSearchPagination<
    ContactsInfiniteScrollPaginationQuery,
    ContactsInfiniteScrollPaginationQueryVariables
  >({
    query: contactsInfiniteScrollPaginationQuery,
    queryRootKey: 'contactsPagination',
    computeVariables,
  });

  const { data: selectedContacts } = useContactsPaginationQuery({
    variables: {
      input: { page: 1, limit: filteredValues.length },
      filters: { accountsPayableNumber: filteredValues },
    },
    fetchPolicy,
    skip: filteredValues.length === 0 || Boolean(searchStr),
  });

  const byAccountPayableNumbers =
    selectedContacts?.contactsPagination.records ?? [];

  const byName = allContacts?.contactsPagination.records ?? [];

  const filterOptions: FilterOption[] = [...byAccountPayableNumbers, ...byName]
    .filter(contact => Boolean(contact.accountsPayableNumber))
    .map(contact => ({
      label: contact.name.value,
      id: contact.accountsPayableNumber as string,
    }));

  return {
    filterOptions: uniqBy(filterOptions, 'id'),
    handleDebounceSearch,
    loading: allContactsLoading,
    loadMore,
  };
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { DropEvent, useDrop } from 'react-aria';
import { onDropExit } from './shared';
import {
  setUploadDropZoneActivated,
  useUploadDropZoneActivated,
} from './state';

type UploadDropZoneParams = {
  isDisabled?: boolean;
  isRestrictedContent?: boolean;
  onDrop: (event: DropEvent) => void;
};

export const validateFiles = (
  event: DragEvent,
  isRestrictedContent: boolean
) => {
  if (!event.dataTransfer?.items) {
    return true;
  }

  const items = Array.from(event.dataTransfer.items);

  return items.every(item => {
    const type = item.type.toLowerCase();

    if (isRestrictedContent) {
      return type === 'application/pdf';
    }

    return (
      type === 'application/pdf' ||
      type === 'application/xml' ||
      type === 'text/xml'
    );
  });
};

export const useUploadDropZone = ({
  isDisabled = false,
  isRestrictedContent = false,
  onDrop,
}: UploadDropZoneParams) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useUploadDropZoneActivated();
  const [isDropValid, setDropValid] = useState(true);

  const handleDrop = useCallback(
    (event: DropEvent) => {
      if (isDropValid) {
        onDrop(event);
      } else {
        setDropValid(true);
      }
      setUploadDropZoneActivated(false);
    },
    [isDropValid, onDrop]
  );

  const { dropProps, isDropTarget } = useDrop({
    isDisabled,
    onDrop: handleDrop,
    onDropExit(event) {
      setDropValid(true);
      onDropExit(event);
    },
    ref,
  });

  useEffect(() => {
    const currentRef = ref.current;
    if (!currentRef) return;

    const handleDragOver = (event: DragEvent) => {
      const isValid = validateFiles(event, isRestrictedContent);
      setDropValid(isValid);
    };

    currentRef.addEventListener('dragenter', handleDragOver);

    return () => {
      currentRef.removeEventListener('dragenter', handleDragOver);
    };
  }, [isRestrictedContent]);

  return {
    dropProps,
    isDropValid,
    isTarget: isDropTarget,
    isVisible: isVisible && !isDisabled,
    ref,
  };
};

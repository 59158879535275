import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from 'utils/zodFormValidation';
import { reimbursementItemsFormErrorMessages } from 'views/Reimbursement/toolkit/reimbursementItemsFormErrorMessages';
import {
  ReimbursementItemsFormOutput,
  reimbursementItemsFormSchema,
} from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';

export const useExpenseFormsHelpers = ({
  defaultValues,
}: {
  defaultValues: ReimbursementItemsFormOutput;
}) => {
  const form = useForm<ReimbursementItemsFormOutput>({
    defaultValues,
    resetOptions: {
      keepErrors: true,
    },
    mode: 'all',
    shouldFocusError: false,
    resolver: zodResolver({
      zodSchema: reimbursementItemsFormSchema,
      errorMessages: reimbursementItemsFormErrorMessages,
      translationNamespace: LOCALE_NAME_SPACE.REIMBURSEMENT,
    }),
  });

  const { control } = form;

  const { fields, append, remove } = useFieldArray<
    ReimbursementItemsFormOutput,
    'reimbursementItems'
  >({
    control,
    name: 'reimbursementItems',
  });

  return {
    forms: fields,
    formMethods: form,
    onAppendForm: append,
    onRemoveForm: remove,
  };
};

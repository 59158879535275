import { Button, Grid, Skeleton, Text } from '@candisio/design-system';

import { useBankAccounts } from 'components/BankAccountListView/useBankAccounts';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { collectErrorMessages } from 'components/Form/utils';
import { ActionBar } from 'components/Sidebar/ActionBar';
import {
  DocumentCurrency,
  Money,
  useGenerateSepaTransferXmlMutation,
  useListIbansQuery,
} from 'generated-types/graphql.types';
import { countPayableDocuments } from 'hooks/useCounterQueries';
import { sum } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { BankAccountCTA } from 'views/Payments/PaymentSidebar/BankAccountCTA/BankAccountCTA';
import { BankAccountSelectField } from 'views/Payments/PaymentSidebar/BankAccountSelectField/BankAccountSelectField';
import {
  CreatePaymentFormValues,
  createPaymentErrorMessages,
  createPaymentSchema,
} from 'views/Payments/PaymentSidebar/CreatePayment/createPaymentSchema';
import { toBankAccountItem } from 'views/Settings/PaymentsSettings/toBankAccountItem';
import { SimplifiedPayableDocument } from '../../types';
import { PaymentSummary } from '../PaymentSummary';
import { SepaImage } from '../SepaImage';
import { sumCashDiscounts } from '../utils/sumCashDiscounts';
import { downloadFile } from 'utils/downloadFile';

export interface CreatePaymentProps {
  onSepaXmlExported: () => void;
  selectedDocuments: SimplifiedPayableDocument[];
  exportAll: boolean;
  totalPayableCount: number;
  payableTotalAmount?: Money;
  discountTotalAmount?: Money;
}

const moneyFallback = {
  currency: DocumentCurrency.Eur,
  precision: 2,
};

export const CreatePayment = ({
  onSepaXmlExported,
  selectedDocuments,
  exportAll,
  totalPayableCount,
  payableTotalAmount,
  discountTotalAmount,
}: CreatePaymentProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);
  const { handleSubmit: handleCreateBankAccountSubmit } = useBankAccounts();

  /** Total invoice amount before discount */
  const selectedDocumentsGrossTotal = sum(
    selectedDocuments.map(document => document.amount.amount ?? 0)
  );

  const grossAmountFromDocs = exportAll
    ? (payableTotalAmount?.amount ?? 0)
    : selectedDocumentsGrossTotal;

  const grossAmount: Money = {
    ...(selectedDocuments[0]?.amount ?? moneyFallback),
    amount: selectedDocuments.length > 0 ? grossAmountFromDocs : 0,
  };

  /** Total cash discount amount */
  const selectedDocsCashDiscount = sumCashDiscounts(selectedDocuments);

  const discountAmountFromDocs = exportAll
    ? (discountTotalAmount?.amount ?? 0)
    : selectedDocsCashDiscount;

  const discountAmount: Money = {
    ...(selectedDocuments[0]?.discountAmount ?? moneyFallback),
    amount: selectedDocuments.length > 0 ? discountAmountFromDocs : 0,
  };

  /** Total payment amount after discount */

  const paymentAmount: Money = {
    ...grossAmount,
    amount: exportAll
      ? (payableTotalAmount?.amount ?? 0)
      : grossAmount.amount - discountAmount.amount,
  };

  const [generateSepaTransferXml] = useGenerateSepaTransferXmlMutation({
    onCompleted: ({ generateSepaTransferXml }) => {
      // currently not displaying the error to the user
      // but eventually we will (tcp-395)
      if (generateSepaTransferXml?.__typename === 'SepaXmlValidationError')
        return;

      if (generateSepaTransferXml?.__typename === 'SepaXml') {
        const file = new Blob([generateSepaTransferXml.content], {
          type: 'text/xml',
        });

        downloadFile(
          URL.createObjectURL(file),
          generateSepaTransferXml.fileName
        );

        onSepaXmlExported();
      }
    },
    /**
     * remove the paid documents from the cache, so further requests
     * get the most up to date state from back-end
     */
    update: cache => {
      selectedDocuments.forEach(({ id }) => {
        cache.modify({
          id: cache.identify({ __typename: 'Document', id }),
          fields: (_, { DELETE }) => DELETE,
        });
      });
    },
    refetchQueries: [{ query: countPayableDocuments }],
  });

  const { data: listIbansData, loading: loadingIbans } = useListIbansQuery();

  const items = listIbansData?.listIbans.map(toBankAccountItem) ?? [];
  const defaultBankAccount = items.find(item => item.isDefault);

  const form = useForm<CreatePaymentFormValues>({
    defaultValues: {
      // TODO: understand why we are using key here and not iban
      iban: defaultBankAccount?.key,
    },
    mode: 'onTouched',
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.PAYMENTS,
      zodSchema: createPaymentSchema,
      errorMessages: createPaymentErrorMessages,
    }),
  });

  const onSubmit = async (values: CreatePaymentFormValues) => {
    if (!values.iban) return;

    if (selectedDocuments.length < 1) {
      return form.setError('root', {
        message: t('details.noInvoicesSelected'),
      });
    }

    const debtorIban = items.find(item => item.key === values.iban)?.iban;
    if (!debtorIban) {
      return;
    }

    await generateSepaTransferXml({
      variables: {
        input: {
          documentIds: exportAll ? [] : selectedDocuments.map(({ id }) => id),
          debtorIban,
          exportAll,
        },
      },
    });

    form.reset();
  };

  const { isValid, isSubmitting, isSubmitSuccessful, errors } = form.formState;

  const errorTexts = collectErrorMessages(errors);

  const isReadOnly = items.length === 0;

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        alignContent="space-between"
        gap="space48"
        height="100%"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Grid gap="space24">
          {loadingIbans ? (
            <Skeleton width="100%" height="80px" />
          ) : (
            <Grid>
              <BankAccountSelectField
                name="iban"
                aria-labelledby={t('details.ibanHeading')}
                aria-label={t('details.ibanHeading')}
                label={t('details.ibanHeading')}
                placeholder={t('details.ibanPlaceholder')}
                items={items}
                readOnly={isReadOnly}
                onCreateBankAccount={handleCreateBankAccountSubmit}
              />
              {isReadOnly && <BankAccountCTA />}
            </Grid>
          )}

          <SepaImage />

          <PaymentSummary
            discountAmount={discountAmount}
            grossAmount={grossAmount}
            numDocuments={
              exportAll ? totalPayableCount : selectedDocuments.length
            }
            paymentAmount={paymentAmount}
          />
        </Grid>

        <ActionBar>
          <Grid gap="space12">
            {!isValid &&
              !isSubmitSuccessful &&
              (errorTexts.length > 0 ? (
                errorTexts.map(error => (
                  <Text color="red500" fontSize="xsmall" key={error}>
                    {error}
                  </Text>
                ))
              ) : (
                <Text color="red500" fontSize="xsmall">
                  {t('details.noValidIban')}
                </Text>
              ))}

            {!isValid ? (
              <Button
                loading={isSubmitting}
                data-cy="submit-payment-form"
                type="submit"
              >
                {t('details.action')}
              </Button>
            ) : (
              <ConfirmationButton
                type="submit"
                confirmationTitle={t('details.exportConfirmation.question')}
                onConfirm={() => onSubmit({ iban: form.watch('iban') })}
                disabled={isSubmitting}
                closeText={t('details.exportConfirmation.cancellation')}
                confirmText={t('details.exportConfirmation.confirmation')}
              >
                {t('details.action')}
              </ConfirmationButton>
            )}
          </Grid>
        </ActionBar>
      </Grid>
    </FormProvider>
  );
};

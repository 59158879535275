import { ProcessingFormTextField } from 'components/Form/ProcessingForm/ProcessingFormTextField';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DocumentDirection } from 'views/utils/DocumentDirection';
import { ProcessingFormContactFieldItem } from './ProcessingFormContactField';
import { ProcessingFormTypeFieldItem } from './ProcessingFormTypeField';
import { ProcessingFormValues } from './processingFormSchema';

export interface ProcessingFormContactDetailsProps {
  /** Gets a contact field item from its value */
  getContactFieldItem?: (
    value: string
  ) => Promise<ProcessingFormContactFieldItem | undefined>;
  /** Document type field items */
  typeFieldItems?: ProcessingFormTypeFieldItem[];
  /** Callback function to get contact item */
  onGetContactItem?: (contactItem?: ProcessingFormContactFieldItem) => void;
  /** Boolean to display loading skeleton */
  isLoading?: boolean;
}

export const getAccountNumberType = (
  typeFieldItems: ProcessingFormTypeFieldItem[],
  type: string
) => {
  const typeItem = typeFieldItems.find(({ key }) => key === type);
  const direction = typeItem?.direction;

  if (!direction) return;

  const isIncoming = direction === DocumentDirection.invoices_received;
  const isOutgoing = direction === DocumentDirection.outgoing_invoices;

  let name: 'accountsPayableNumber' | 'accountsReceivableNumber' | undefined;

  if (isIncoming) name = 'accountsPayableNumber';
  if (isOutgoing) name = 'accountsReceivableNumber';

  return name;
};

/**
 * Displays accounts payable/receivable number for the currently-selected
 * contact in processing form.
 *
 * Returns `null` if direction of currently-selected document type is other
 */
export const ProcessingFormContactDetails = ({
  getContactFieldItem,
  typeFieldItems = [],
  onGetContactItem,
  isLoading,
}: ProcessingFormContactDetailsProps) => {
  const [t] = useTranslation();
  const { friendlyFormatAccountsNumber } = useAccountingNumberFormatters();
  const { setValue } = useFormContext<ProcessingFormValues>();

  const [contact, type] =
    useWatch<ProcessingFormValues, ['contact', 'type']>({
      name: ['contact', 'type'],
    }) ?? [];

  const accountNumberType = getAccountNumberType(typeFieldItems, type);
  const label = t(`settings.contacts.details.edit.${accountNumberType}.label`);

  useEffect(() => {
    if (!contact || !getContactFieldItem) return;

    let abort = false;

    getContactFieldItem(contact?.value)
      .then(item => {
        if (abort) return;

        onGetContactItem?.(item);

        if (!accountNumberType || !item) return;

        const value = friendlyFormatAccountsNumber(
          item[accountNumberType] ?? ''
        );
        setValue(accountNumberType, value, { shouldValidate: true });
      })
      .catch(() => {
        console.error('Failed to get contact field item', contact);
      });

    return () => {
      abort = true;
    };
  }, [
    accountNumberType,
    contact,
    getContactFieldItem,
    onGetContactItem,
    friendlyFormatAccountsNumber,
    setValue,
  ]);

  if (!accountNumberType) return null;

  return (
    <ProcessingFormTextField
      name={accountNumberType}
      label={label}
      isLoading={isLoading}
      readOnly
    />
  );
};

import { useSearchInput } from 'components/AdvancedSearchFieldFilterOverlay/useSearchInput';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import {
  queryParameter,
  searchScopeParameter,
  searchTypeParameter,
} from 'components/Table/consts';
import { Query } from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce } from 'lodash';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInboxInvoiceDocumentsQuery } from 'views/queries';
import { mapInboxInvoicesToDocumentsTableData } from 'views/utils/mapInboxInvoicesToDocumentsTableData';
import { mapToDocumentDateFilterInput } from 'views/utils/mapToDocumentDateFilterInput';
import { mapToDocumentFilterInput } from 'views/utils/mapToDocumentFilterInput';
import { mapToDocumentSortInput } from 'views/utils/mapToDocumentSortInput';
import { getApproximateNumberOfRowForTable } from 'views/utils/pagination-helper';
import { useIncludePurchaseOrderData } from 'views/utils/useShouldShowField';
import { availableFilters } from '../util';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { useOtherIntegration } from 'orgConfig/other';

export const useInboxDocumentsData = () => {
  const [t] = useTranslation();
  const { shouldUseCandisApi } = useOtherIntegration();
  const pageSize = getApproximateNumberOfRowForTable();

  const [searchParams, setSearchParam] = useSearchParams();

  const searchQuery = searchParams.get(queryParameter) ?? '';
  const [debounceSearchValue, setDebounceSearchValue] = useState(searchQuery);

  const { filters, onFilter, onSort, sortBy } =
    useUrlBasedSortAndFilter<DocumentsTableData>({ availableFilters });

  const gqlFilters = mapToDocumentFilterInput(filters);
  const gqlSort = mapToDocumentSortInput(sortBy);
  const dateFilters = mapToDocumentDateFilterInput(filters);

  const includePurchaseOrderData = useIncludePurchaseOrderData();

  const { searchInputVariable } = useSearchInput();

  const {
    data,
    loading: paginatedLoadingState,
    onLoadMore,
  } = usePagination<Pick<Query, 'inboxInvoiceDocuments'>>(
    getInboxInvoiceDocumentsQuery,
    'inboxInvoiceDocuments',
    {
      variables: {
        limit: pageSize,
        filters: { ...gqlFilters, ...dateFilters },
        ...(gqlSort ? { sort: gqlSort } : {}),
        query: debounceSearchValue,
        withPurchaseOrderData: includePurchaseOrderData,
        withAccountsReceivableNumber: shouldUseCandisApi,
        searchInput: searchInputVariable,
      },
    }
  );

  const listInboxDocuments = data?.inboxInvoiceDocuments;

  const documentEdges = listInboxDocuments?.edges ?? [];
  const documentsCount = listInboxDocuments?.pageInfo?.totalCount;
  const isTableEmpty = documentEdges.length === 0 && !paginatedLoadingState;
  const hasMoreData = listInboxDocuments?.pageInfo?.hasNextPage ?? false;
  const endCursor = listInboxDocuments?.pageInfo?.endCursor;
  const documentsTableData = mapInboxInvoicesToDocumentsTableData(
    documentEdges,
    t
  );

  const isTableFiltered = filters.length > 0;

  const handleDebounceSearch = useMemo(() => {
    return debounce(
      (value: string) => {
        setDebounceSearchValue(value);
        if (value) {
          searchParams.set(queryParameter, value);
        } else {
          searchParams.delete(queryParameter);
          searchParams.delete(searchTypeParameter);
          searchParams.delete(searchScopeParameter);
        }
        setSearchParam(searchParams, { replace: true });
      },
      DEFAULT_DEBOUNCE_TIME,
      {
        trailing: true,
      }
    );
  }, [searchParams, setSearchParam]);

  useEffect(() => {
    return () => {
      handleDebounceSearch.cancel();
    };
  }, [handleDebounceSearch]);

  return {
    loading: paginatedLoadingState,
    onLoadMore,
    endCursor,
    isTableEmpty,
    hasMoreData,
    documentsCount,
    documentsTableData,
    filters,
    sortBy,
    onFilter,
    onSort,
    documentEdges,
    isTableFiltered,
    handleDebounceSearch,
    searchQuery,
  };
};

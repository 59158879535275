import { css } from '@emotion/react';
import { CSSProperties, ReactNode, useMemo } from 'react';
import { Row } from 'react-table';
import { Grid } from '../../Atoms/Grid';
import { useTheme } from '../../Theme';

interface TableCellProps {
  cellType: string;
  handleTextSelection:
    | React.MouseEventHandler<HTMLTableCellElement>
    | undefined;
  onRowClick: ((row: Row<any>) => void) | undefined;
  isRowDisabled?: boolean;
  isSelectionDisabled?: boolean;
  style: CSSProperties | undefined;
  children: ReactNode;
}

export const TableCell = ({
  cellType,
  handleTextSelection,
  onRowClick,
  isRowDisabled,
  children,
  style,
  isSelectionDisabled,
}: TableCellProps) => {
  const { colors } = useTheme();

  const disabledRowStyle = useMemo(
    () => css`
            color: ${colors.gray500};
            cursor: default;
          `,
    [colors.gray500]
  );

  const cell = useMemo(() => {
    switch (cellType) {
      case 'selection':
        return (
          <td
            css={
              isRowDisabled || isSelectionDisabled
                ? disabledRowStyle
                : undefined
            }
            style={{
              ...style,
              padding: 0,
              height: '1px',
            }}
          >
            <Grid
              as="label"
              paddingY="space10"
              paddingX="space16"
              alignItems="center"
              onClick={(e: React.MouseEvent<HTMLLabelElement>) => {
                e.stopPropagation();
                if (isRowDisabled || isSelectionDisabled) {
                  e.preventDefault();
                }
              }}
              style={{
                cursor:
                  isRowDisabled || isSelectionDisabled
                    ? 'not-allowed'
                    : 'pointer',
              }}
            >
              {children}
            </Grid>
          </td>
        );
      case 'openInDatev':
        return <td>{children}</td>;
      default:
        return (
          <td
            style={{
              ...style,
            }}
            onClick={() => {
              !onRowClick ? handleTextSelection : undefined;
            }}
            css={isRowDisabled ? disabledRowStyle : undefined}
          >
            {children}
          </td>
        );
    }
  }, [
    cellType,
    children,
    disabledRowStyle,
    handleTextSelection,
    isRowDisabled,
    isSelectionDisabled,
    onRowClick,
    style,
  ]);

  return cell;
};

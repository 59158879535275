import { EcmDocumentsTable } from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import {
  PromotionWrapper,
  TableWrapper,
  Wrapper,
} from 'components/ProductPromotions/Wrapper';
import { documentsData } from 'containers/Entitlements/components/Upsell/dummyData';
import { useEcmDocumentsTableData } from 'containers/document-relationships/useEcmDocumentsTableData';
import { noop } from 'lodash';
import { EventLocationsBase } from 'providers/AnalyticsProvider/events';
import { ArchiveViewLayout } from 'views/Archive/components/ArchiveViewLayout';
import { TabView } from 'views/Inbox/models';

export const EcmPromotionArchive = () => {
  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_ECM_DOCUMENTS}>
      <NewPromotions />
    </ArchiveViewLayout>
  );
};

const tableData = documentsData.map((el, j) => ({
  ...el,
  id: j + '',
})) as EcmDocumentsTableData[];

const NewPromotions = () => {
  const { documentManagementPromotion } = usePromotionContent();
  const { availableDocumentColumnIds, configurationsTable } =
    useEcmDocumentsTableData({ filterParamsSource: 'url' });

  return (
    <Wrapper>
      <TableWrapper>
        <EcmDocumentsTable
          context="documents"
          columns={availableDocumentColumnIds}
          configurationsTable={configurationsTable}
          data={tableData}
          selectedDocumentsCount={0}
          onSearchChange={noop}
          onRowClick={noop}
          onFilter={noop}
          onEndReached={noop}
          onUpdateConfigurations={noop}
          onSort={noop}
          onResetTableConfigurations={noop}
        />
      </TableWrapper>
      <PromotionWrapper>
        <PromotionsProvider promotions={[documentManagementPromotion]}>
          <PromotionCard
            promotion={documentManagementPromotion}
            closable={false}
            trackOption={{ eventLocation: EventLocationsBase.ARCHIVE }}
          />
        </PromotionsProvider>
      </PromotionWrapper>
    </Wrapper>
  );
};

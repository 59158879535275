import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { ReactNode } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { scrollToTarget } from 'views/CreditCards/utils/utils';
import { ReimbursementRouteParams } from 'views/Reimbursement/hooks/useReimbursementNavigation';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';

interface NavigateToItemWrapperProps {
  id: string;
  children: ReactNode;
}

export const NavigateToItemWrapper = ({
  id,
  children,
}: NavigateToItemWrapperProps) => {
  const { reimbursementId } = useParams<ReimbursementRouteParams>();

  const { updateSearchParam } = useMutateSearchParams();

  const canNavigateToItem = Boolean(id && id !== reimbursementId);

  const handleClick = (id: string) => {
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    scrollToTarget(id);
  };

  return (
    <div
      className={canNavigateToItem ? 'cursor-pointer' : 'cursor-default'}
      onClick={canNavigateToItem ? () => handleClick(id) : undefined}
    >
      {children}
    </div>
  );
};

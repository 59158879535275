import {
  Button,
  Paragraph,
  Tag,
  Text,
  useModal,
} from '@candisio/design-system';
import { PromotionInfoPanel } from 'components/NewPromotions/PromotionInfoPanel/PromotionInfoPanel';
import { BDSPromotionModal } from 'components/ProductPromotions/Provisions/BDSPromotionModal';
import { PROVISIONS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { useTranslation } from 'react-i18next';

type BdsInfoPanelProps = {
  closePromotion: () => void;
};
export const BdsInfoPanel = ({ closePromotion }: BdsInfoPanelProps) => {
  const [t] = useTranslation();

  const { triggerProps, isOpen, close } = useModal();

  return (
    <PromotionInfoPanel
      imageUrl={t('settings.datev.connect.connectedClient.promoCard.imageUrl')}
      closePromotion={closePromotion}
    >
      <Tag color="purple" variant="secondary">
        {t('tag.addOn')}
      </Tag>
      <Text fontSize="large" color="gray800">
        {t('settings.datev.connect.connectedClient.promoCard.title')}
      </Text>
      <Paragraph fontSize="small" color="gray700" lineHeight="space20">
        {t('settings.datev.connect.connectedClient.promoCard.description')}
      </Paragraph>
      <Button
        color="gray"
        variant="secondary"
        {...triggerProps}
        width="space128"
      >
        {t('settings.datev.connect.connectedClient.promoCard.button')}
      </Button>

      {isOpen && (
        <BDSPromotionModal
          utmSource={PROVISIONS_UTM_SOURCE.SETTINGS_DATEV_INTEGRATION_DUO}
          isOpen={isOpen}
          close={close}
        />
      )}
    </PromotionInfoPanel>
  );
};

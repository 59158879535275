import { MAXIMUM_EXPORT_STACK_SIZE_LENGTH } from 'components/Form/DocumentApprovalsForm/elements/Actions';
import {
  ReimbursementCaseStatus,
  useArchiveReimbursementCasesCountQuery,
  useCountDocumentsByStatusApprovedForApprovalBlockQuery,
} from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';

const EXPORT_LIMIT = MAXIMUM_EXPORT_STACK_SIZE_LENGTH;

export const useExportLimitCheck = () => {
  const { canUseReimbursement } = useReimbursement();

  const { data: approvedDocumentsData } =
    useCountDocumentsByStatusApprovedForApprovalBlockQuery({
      skip: !canUseReimbursement,
      fetchPolicy: 'no-cache',
    });

  const { data } = useArchiveReimbursementCasesCountQuery({
    variables: { filters: { statuses: [ReimbursementCaseStatus.Approved] } },
    skip: !canUseReimbursement,
    fetchPolicy: 'no-cache',
  });

  const approvedDocumentsCount =
    approvedDocumentsData?.countDocumentsByStatus?.count ?? 0;

  const approvedReimbursementCount =
    data?.archiveReimbursementCasesCount?.totalCount ?? 0;

  const consolidatedApprovalCount =
    approvedDocumentsCount + approvedReimbursementCount;

  const isExportLimitExceeded =
    approvedDocumentsCount > EXPORT_LIMIT ||
    approvedReimbursementCount > EXPORT_LIMIT;

  const isConsolidatedExportLimitExceeded =
    consolidatedApprovalCount > EXPORT_LIMIT;

  const isApprovalBlocked =
    isExportLimitExceeded || isConsolidatedExportLimitExceeded;

  return { isApprovalBlocked };
};

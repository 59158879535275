import { useDocumentDataFileRecordsQuery } from 'generated-types/graphql.types';
import { DocumentDataFileStatus } from 'generated-types/resolvers-types';
import { usePolling } from 'hooks/usePolling';

const DOCUMENT_DATA_FILE_RECORDS_QUERY_POLL_INTERVAL = 7000;

export const useDocumentDownloadHistory = () => {
  const {
    data,
    loading: isLoading,
    startPolling: startPollingDocumentDataFileRecordsQuery,
    stopPolling: stopPollingDocumentDataFileRecordsQuery,
  } = useDocumentDataFileRecordsQuery();

  const isLastDownloadProcessing =
    data?.documentDataFileRecords &&
    data?.documentDataFileRecords[0]?.status ===
      DocumentDataFileStatus.Processing;

  usePolling({
    pollInterval: DOCUMENT_DATA_FILE_RECORDS_QUERY_POLL_INTERVAL,
    startPolling: startPollingDocumentDataFileRecordsQuery,
    stopPolling: stopPollingDocumentDataFileRecordsQuery,
    skip: !isLastDownloadProcessing,
    pollOnlyOnFocus: false,
  });

  const documentHistoryItems = data
    ? data.documentDataFileRecords?.map(item => {
        return {
          createdAt: item.createdAt,
          dateRangeFilters: item.dateRangeFilters,
          file: {
            name: item.file?.name,
            url: item.file?.url,
            size: item.file?.size,
          },
          fileType: item.fileType,
          filters: item.filters,
          ecmFilters: item.ecmFilters,
          isExpired: item.isExpired,
          query: item.query,
          status: item.status,
          username: item.creator?.name,
        };
      })
    : [];

  return {
    documentHistoryItems,
    isLoading,
  };
};

import { DocumentNode, useQuery } from '@apollo/client';
import { useSearchInput } from 'components/AdvancedSearchFieldFilterOverlay/useSearchInput';
import {
  ReimbursementCasesFilterInput,
  ReimbursementCasesSortInput,
  ReimbursementsCursorBasedNavigationQuery,
} from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { availableFiltersAndSorts } from 'views/Inbox/Reimbursements/toolkit/utils';
import {
  reimbursementsApprovalsNavigationQuery,
  reimbursementsCursorBasedNavigationQuery,
  reimbursementsInboxNavigationQuery,
} from '../toolkit/queries';
import { mapToReimbursementSortInput } from '../utils/mapToReimbursementSortInput';
import { useMapToReimbursementsFilterInput } from '../utils/useMapToReimbursementsFilterInput';
import { RouteType } from './useReimbursementNavigation';

const QUERY_PARAM = 'query';
interface ReimbursementsCursorBasedNavigationDataProps {
  skip?: boolean;
  cursor?: string | null;
  filter?: ReimbursementCasesFilterInput;
  sort?: ReimbursementCasesSortInput;
}

const mappedNavigationQueries: Record<RouteType, DocumentNode> = {
  [Routes.ARCHIVE]: reimbursementsCursorBasedNavigationQuery,
  [Routes.REIMBURSEMENTS_DASHBOARD]: reimbursementsCursorBasedNavigationQuery,
  [Routes.INBOX]: reimbursementsInboxNavigationQuery,
  [Routes.APPROVALS]: reimbursementsApprovalsNavigationQuery,
};

export const useReimbursementsCursorBasedNavigationData = ({
  skip,
  cursor,
  filter: defaultFilters,
  sort: defaultSortBy,
}: ReimbursementsCursorBasedNavigationDataProps = {}) => {
  const { canUseReimbursement } = useReimbursement();
  const { searchParams } = useMutateSearchParams();
  const { searchInputVariable } = useSearchInput();
  const searchQuery = searchParams.get(QUERY_PARAM);
  const { type } = useParams<{
    type: RouteType;
  }>();

  const { sortBy, filters } = useUrlBasedSortAndFilter({
    availableFilters: availableFiltersAndSorts,
  });

  const mappedFilters = useMapToReimbursementsFilterInput(
    filters,
    defaultFilters
  );

  const hasFilters = Boolean(filters.length || defaultFilters);

  const query = mappedNavigationQueries[type];

  const { data, loading } = useQuery<ReimbursementsCursorBasedNavigationQuery>(
    query,
    {
      variables: {
        next: cursor,
        previous: cursor,
        filters: hasFilters ? mappedFilters : undefined,
        sorts: sortBy.length
          ? mapToReimbursementSortInput(sortBy)
          : defaultSortBy,
        search: !!searchQuery ? searchQuery : undefined,
        searchInput:
          type !== Routes.REIMBURSEMENTS_DASHBOARD
            ? searchInputVariable
            : undefined,
      },
      skip: !canUseReimbursement || skip,
      fetchPolicy: 'no-cache',
    }
  );

  const prevEdges = data?.prevReimbursement;
  const nextEdges = data?.nextReimbursement;
  const pageInfo = data?.nextReimbursement?.pageInfo;

  return {
    isLoadingNavigationData: loading,
    nextReimbursementEdge: nextEdges?.edges?.[0],
    prevReimbursementEdge: prevEdges?.edges?.[0],
    reimbursementCount: pageInfo?.totalCount ?? 0,
  };
};

import { PDFDetails } from 'components/DocumentViewer/utils';
import { useDocumentRelationshipsFilesQuery } from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { useGlobalDocumentId } from 'hooks/useGlobalDocumentId';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';

type DocumentFileWithAttachments = {
  documentFile: PDFDetails;
  attachments?: PDFDetails[];
};

export const useRelatedDocumentFiles = (documentId: string) => {
  const globalDocumentId = useGlobalDocumentId(documentId);
  const { isComparisonView } = useComparisonView();

  const { data: relationsData } = useDocumentRelationshipsFilesQuery({
    variables: { input: { globalDocumentId } },
    skip: !globalDocumentId || !isComparisonView,
  });

  const relationships = relationsData?.documentRelationships.relationships;

  const result = useMemo(() => {
    const files: DocumentFileWithAttachments[] = [];

    (relationships ?? []).forEach(({ linkedDocument: link }) => {
      if (!link || link.__typename !== 'RelatedDocument' || !link.document)
        return;

      if (
        link.document.__typename === 'AggregatedEcmDocument' &&
        link.document.fileSource
      ) {
        const { url, name, id } = link.document.fileSource;

        return files.push({ documentFile: { url, name, id: id ?? undefined } });
      }

      if (
        link.document.__typename === 'Document' &&
        link.document.documentFile
      ) {
        const { url, name, id } = link.document.documentFile;

        const file: DocumentFileWithAttachments = {
          documentFile: { url, name, id },
          attachments: [],
        };
        if (link.document.attachments) {
          file.attachments = link.document.attachments.map(
            ({ url, name, id }) => {
              return {
                url,
                name,
                id,
                mainDocumentFileId: file.documentFile.id,
              };
            }
          );
        }

        files.push(file);
      }
    });

    return files;
  }, [relationships]);

  return result;
};

import { Button, Card, Flex, Grid, Heading } from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SingleDetail } from './SingleDetail';
import { TopupSkeleton } from './TopupSkeleton';
import { ProcessingAccountData } from './hooks/useGetProcessingAccountDataCardManager';

export interface TopupDetailsProps extends ProcessingAccountData {
  reference: string;
  loading?: boolean;
}

const details = [
  'beneficiary',
  'bankName',
  'iban',
  'bic',
  'reference',
] as const;

export type Detail = (typeof details)[number];

export const TopupDetails = ({
  bankName,
  beneficiary,
  bic,
  iban,
  reference,
  loading,
}: TopupDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success } = useToastMessage();
  const detailsValues: {
    id: Detail;
    value: string | undefined;
    translation: string;
  }[] = [
    {
      id: 'beneficiary',
      value: beneficiary,
      translation: 'organizationLimit.topupDrawer.details.beneficiary',
    },
    {
      id: 'bankName',
      value: bankName,
      translation: 'organizationLimit.topupDrawer.details.bank',
    },
    {
      id: 'iban',
      value: iban,
      translation: 'organizationLimit.topupDrawer.details.iban',
    },
    {
      id: 'bic',
      value: bic,
      translation: 'organizationLimit.topupDrawer.details.bic',
    },
    {
      id: 'reference',
      value: reference,
      translation: 'organizationLimit.topupDrawer.details.reference',
    },
  ];

  const copyAllToClipboard = () => {
    let text: string = '';
    details.forEach(detail => {
      text += (document.getElementById(detail)?.textContent ?? '') + '  ';
    });
    void navigator.clipboard.writeText(text);
    success(t('organizationLimit.topupDrawer.successToastMessage'));
  };

  return (
    <Card boxShadow="none" paddingX="0">
      <Grid gap="space20">
        <Flex gap="space2" alignItems="center" justifyContent="space-between">
          <Heading as="h2">{t('organizationLimit.topupDrawer.title')}</Heading>
          <Button
            onClick={copyAllToClipboard}
            size="small"
            variant="secondary"
            disabled={loading}
          >
            {t('organizationLimit.topupDrawer.copyAll')}
          </Button>
        </Flex>
        <Grid gap="space16">
          {loading ? (
            <TopupSkeleton />
          ) : (
            detailsValues.map(detail => (
              <SingleDetail {...detail} key={detail.id} />
            ))
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useCreateReimbursementCaseMutation } from 'generated-types/graphql.types';
import { GraphQLError } from 'graphql';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CREATE_REIMBURSEMENT_ERROR = {
  CONTACT_NAME_DUPLICATE: 'CONTACT_NAME_DUPLICATE',
};

export interface UseCreateReimbursementReturn {
  createReimbursementPending: boolean;
  createReimbursement: () => Promise<string | undefined>;
  isDuplicateErrorVisible: boolean;
}

type Params = {
  onSuccess?: (reimbursementId: string) => void;
  onFailure?: (errors: readonly GraphQLError[]) => void;
};

export const useCreateReimbursement = ({
  onFailure,
  onSuccess,
}: Params = {}): UseCreateReimbursementReturn => {
  const [isDuplicateErrorVisible, setIsDuplicateErrorVisible] = useState(false);
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const currentUserId = useCurrentUser()?.id ?? '';
  const { error } = useToastMessage();
  const [createReimbursement, { loading: createReimbursementPending, client }] =
    useCreateReimbursementCaseMutation({
      onError: () => error(t('reimbursementView.createErr')),
      refetchQueries: [
        'archiveReimbursementCasesCount',
        'inboxReimbursementCasesCount',
      ],
      onCompleted: () => {
        client.cache.evict({ fieldName: 'inboxReimbursementCases' });
        client.cache.evict({ fieldName: 'archiveReimbursementCases' });

        // Instead of refetching the 3 different queries with different params we evict the cache
        client.cache.evict({ fieldName: 'approvalReimbursementCasesCount' });
      },
    });

  const handleCreateReimbursement = async () => {
    const { data, errors } = await createReimbursement({
      variables: {
        input: {
          targetMembershipId: currentUserId,
        },
      },
    });

    if (errors?.length) {
      error(t('reimbursementView.createErr'));
      const hasDuplicateContactError = errors.some(error =>
        error.message.includes(
          CREATE_REIMBURSEMENT_ERROR.CONTACT_NAME_DUPLICATE
        )
      );
      setIsDuplicateErrorVisible(hasDuplicateContactError);
      onFailure?.(errors);

      return;
    }

    setIsDuplicateErrorVisible(false);

    if (data) {
      onSuccess?.(data.createReimbursementCase.id);
    }

    return data?.createReimbursementCase.id;
  };

  return {
    createReimbursementPending,
    createReimbursement: handleCreateReimbursement,
    isDuplicateErrorVisible,
  };
};

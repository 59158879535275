import { Card, Paragraph } from '@candisio/design-system';
import { ContactSupplierSendEmailEventDataFragment } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { Collapse } from '../../History/items/Collapse';

export const ContactSupplierComponent = ({
  emailPayload,
}: ContactSupplierSendEmailEventDataFragment) => {
  const [t] = useTranslation();

  const recipient = emailPayload?.recipients && emailPayload?.recipients[0];

  if (!emailPayload?.htmlBody) {
    return (
      <Paragraph>
        {t('contactSupplierFromCandis.history.content', { recipient })}
      </Paragraph>
    );
  }

  return (
    <>
      <Paragraph>
        {t('contactSupplierFromCandis.history.content', { recipient })}
      </Paragraph>
      <Collapse>
        <br />
        <Card>
          <div dangerouslySetInnerHTML={{ __html: emailPayload?.htmlBody }} />
        </Card>
      </Collapse>
    </>
  );
};

import { Loader } from 'components/Loader';
import { EcmDocumentStatus } from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { useCallback, useState } from 'react';
import { useInvoiceDocumentPermissions } from 'views/Inbox/DocumentProcessing/useInvoiceDocumentPermissions';
import { useDeleteDocument } from '../../useDeleteDocument';
import { generateDefaultValuesForNewContact } from '../../util/generateDefaultValuesForNewContact';
import { AddContactContainer } from '../AddContact';
import { EditContactContainer } from '../EditContact/EditContactContainer';
import { StorageForm } from './StorageForm';
import { StorageFormValues } from './storageFormSchema';
import { useEcmDocumentPermissions } from './useEcmDocumentPermissions';
import { useRemoveEcmDocument } from './useRemoveEcmDocument';
import { useStorageFormFieldOptions } from './useStorageFormFieldOptions';
import { useStorageFormInitialData } from './useStorageFormInitialData';
import { useStoreEcmDocument } from './useStoreEcmDocument';
import { useStoreSensitiveEcmDocument } from './useStoreSensitiveEcmDocument';
import { useUpdateEcmDocument } from './useUpdateEcmDocument';

interface StorageFormContainerProps {
  /** Current document ID */
  documentId: string;
  /** Determines which document data should be fetched/updated, invoice or ECM */
  isInvoice?: boolean;
  /** Called when document is successfully deleted */
  onDeleteDocument?: () => void;
  /** Called when document is successfully stored */
  onStoreDocument?: () => void;
  /** Called when document type is changed between invoice and ecm */
  onDocumentTypeConversion?: () => void;
}

/** Handles data fetching for StorageForm component */
export const StorageFormContainer = ({
  documentId,
  isInvoice,
  onDeleteDocument,
  onStoreDocument,
  onDocumentTypeConversion,
}: StorageFormContainerProps) => {
  const { bdsBought } = useDatev();

  const [isAddContactOpen, setIsAddContactOpen] = useState(false);
  const [isEditContactOpen, setIsEditContactOpen] = useState(false);

  const openNewContactForm = useCallback(() => setIsAddContactOpen(true), []);
  const closeNewContactForm = useCallback(() => setIsAddContactOpen(false), []);

  const openEditContactForm = useCallback(() => setIsEditContactOpen(true), []);
  const closeEditContactForm = useCallback(
    () => setIsEditContactOpen(false),
    []
  );

  const {
    defaultMetadata,
    defaultValues,
    extractedContact,
    createdBy,
    documentStatus,
    loading: loadingInitialData,
    globalDocumentId,
    tags,
    isDocumentReadOnly,
  } = useStorageFormInitialData({ documentId, isInvoice });

  const { canDelete: canDeleteEcmDocument } = useEcmDocumentPermissions({
    id: documentId,
    skip: isInvoice,
  });

  const { canDelete: canDeleteInvoice } = useInvoiceDocumentPermissions({
    id: documentId,
    skip: !isInvoice,
  });

  const canRemoveDocument = Boolean(
    isInvoice ? canDeleteInvoice : canDeleteEcmDocument
  );

  const fieldOptions = useStorageFormFieldOptions(defaultValues);

  // Only documents uploaded as sensitive contracts have status "New",
  // the rest has status "Stored". Because it's not yet stored, it doesn't
  // have `isSensitive: true`, so we need to rely on the status here.
  const isNewSensitiveContract =
    defaultValues.isSensitive && documentStatus === EcmDocumentStatus.New;

  const { updateEcmDocument, loading: loadingUpdate } = useUpdateEcmDocument({
    documentId,
  });

  const { storeEcmDocument, loading: loadingStore } = useStoreEcmDocument({
    globalDocumentId,
  });

  const { storeSensitiveEcmDocument, loading: loadingStoreSensitive } =
    useStoreSensitiveEcmDocument({
      documentId,
      isNewSensitiveContract,
    });

  const { removeEcmDocument } = useRemoveEcmDocument(documentId);
  const { deleteDocument } = useDeleteDocument(documentId);

  const handleSubmit = async (values: StorageFormValues) => {
    let result;
    if (documentStatus === EcmDocumentStatus.Stored)
      result = await updateEcmDocument(values);
    else if (isNewSensitiveContract)
      result = await storeSensitiveEcmDocument(values);
    else result = await storeEcmDocument(values);

    if (result.status === 'success') {
      onStoreDocument?.();
    }
  };

  const handleDeleteDocument = async () => {
    const { status } = isInvoice
      ? await deleteDocument()
      : await removeEcmDocument();

    if (status === 'success') {
      onDeleteDocument?.();
    }
  };

  const isLoading =
    loadingInitialData ||
    loadingUpdate ||
    loadingStore ||
    loadingStoreSensitive;

  if (isLoading) return <Loader />;

  let subform;

  if (isAddContactOpen) {
    const defaultValues = generateDefaultValuesForNewContact({
      extractedContact,
      bdsBought,
    });

    subform = (
      <AddContactContainer
        defaultValues={defaultValues}
        onAddContact={closeNewContactForm}
        onClose={closeNewContactForm}
      />
    );
  }

  if (isEditContactOpen)
    subform = <EditContactContainer onClose={closeEditContactForm} />;

  return (
    <StorageForm
      key={documentId} // Reset form state when switching document
      createdBy={createdBy}
      defaultMetadata={defaultMetadata}
      defaultValues={defaultValues}
      documentStatus={documentStatus}
      fieldOptions={fieldOptions}
      isInvoice={isInvoice}
      onAddContact={openNewContactForm}
      onDeleteDocument={handleDeleteDocument}
      onEditContact={openEditContactForm}
      onSubmit={handleSubmit}
      globalDocumentId={globalDocumentId}
      documentId={documentId}
      readOnly={isDocumentReadOnly}
      canRemoveDocument={canRemoveDocument}
      subform={subform}
      tags={tags}
      onDocumentTypeConversion={onDocumentTypeConversion}
    />
  );
};

import {
  DateFilterOption,
  useGetCountCardsQuery,
} from 'generated-types/graphql.types';
import { getDateRangeForNextNDays } from 'utils/getDateRange';
import {
  CARD_EXPIRING_STATUES,
  CARD_EXPIRING_TYPES,
} from '../CreditCardsInsights/constants/ExpiringCards';

export const useGetCountExpiringSoonCards = () => {
  const { fromDateFormatted: dateFrom, toDateFormatted: dateTo } =
    getDateRangeForNextNDays({ days: 30 });

  const { data, loading, previousData } = useGetCountCardsQuery({
    // We need to have cache-and-network policy because this query is used in the credit cards dashboard and we need to rely as much as possible on new data
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        statuses: CARD_EXPIRING_STATUES,
        types: CARD_EXPIRING_TYPES,
        dateRangeFilters: {
          expiryDate: {
            dateFrom,
            dateTo,
            filterOption: DateFilterOption.Custom,
          },
        },
      },
    },
  });

  const isLoading = loading && !data && !previousData;

  const countExpiringCards =
    data?.countCards?.total ?? previousData?.countCards?.total ?? 0;

  return { countExpiringCards, loading: isLoading };
};

import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePerDiemFormFieldNames } from '../utils/generatePerDiemFieldNames';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { useState } from 'react';
import { PerDiemSegmentItem } from '../PerDiemSegmentItem';
import { PerDiemSegmentActions } from '../PerDiemSegmentActions';
import { PerDiemLengthErrorInfoBox } from '../PerDiemLengthErrorInfoBox';
import { v4 as uuidV4 } from 'uuid';
import { Text } from '@candisio/design-system';

interface SegmentsStepProps {
  onAddPerDiemSegment: (index: number) => () => Promise<void>;
  onUpdatePerDiem: (index: number) => () => Promise<void>;
  onUpdatePerDiemStep: (index: number) => Promise<void>;
  onCleareableField: (field?: string) => () => void;
  clearableField: string | undefined;
  formIndex: number;
  isExcluded: boolean;
  formMethods: UseFormReturn<ReimbursementItemsFormOutput>;
  isAddSegmentPending: boolean;
  isUpdatePerDiemStepPending: boolean;
  isReimbursementItemFormEditable: boolean;
}

export const SegmentsStep = ({
  onCleareableField,
  onUpdatePerDiem,
  onAddPerDiemSegment,
  onUpdatePerDiemStep,
  formIndex,
  clearableField,
  isExcluded,
  formMethods,
  isAddSegmentPending,
  isUpdatePerDiemStepPending,
  isReimbursementItemFormEditable,
}: SegmentsStepProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [travelLengthError, setTravelLengthError] = useState<null | string>(
    null
  );

  const fields = generatePerDiemFormFieldNames(formIndex);
  const { watch, control } = formMethods;
  const currentSegments = watch(fields.segments);

  const {
    append,
    remove,
    fields: segmentFields = [],
  } = useFieldArray<
    ReimbursementItemsFormOutput,
    `reimbursementItems.${number}.segments`
  >({
    control,
    name: fields.segments,
  });

  const onAddSegmentClick = () => {
    // we get the last segment's index
    const lastSegmentIndex = currentSegments.length - 1;

    // we get the last segment's end date and time
    const lastSegmentEndDate = currentSegments[lastSegmentIndex].endDate ?? '';
    const lastSegmentEndTime = currentSegments[lastSegmentIndex].endTime ?? '';

    append({
      location: '',
      endDate: '',
      endTime: '',
      startDate: lastSegmentEndDate,
      startTime: lastSegmentEndTime,
      segmentId: uuidV4(),
    });

    onAddPerDiemSegment(formIndex)();
  };

  const onRemoveSegmentClick = (index: number) => {
    remove(index);
    onUpdatePerDiem(formIndex)();
  };

  const shouldShowPerDiemActions =
    isReimbursementItemFormEditable && !isExcluded;

  return (
    <div className="grid gap-4">
      <div>
        <Text fontSize="large" fontWeight="semibold" className="pl-6">
          {t('reimbursementView.middleSection.form.perDiem.segmentDesc')}
        </Text>
        <div data-testid="per-diem-segments">
          {segmentFields.map((itm, index) => {
            const isLastSegment = index === segmentFields.length - 1;
            const canRemoveSegment = segmentFields.length > 1 && isLastSegment;

            return (
              <PerDiemSegmentItem
                key={itm.id}
                formIndex={formIndex}
                isExcluded={isExcluded}
                clearableField={clearableField}
                onCleareableField={onCleareableField}
                onUpdatePerDiem={onUpdatePerDiem}
                canRemoveSegment={canRemoveSegment}
                onRemoveSegment={onRemoveSegmentClick}
                formMethods={formMethods}
                segmentIndex={index}
                isLastSegment={isLastSegment}
              />
            );
          })}
        </div>
        {!!travelLengthError && (
          <PerDiemLengthErrorInfoBox travelLengthError={travelLengthError} />
        )}
      </div>

      {shouldShowPerDiemActions && (
        <PerDiemSegmentActions
          isAddSegmentLoading={isAddSegmentPending}
          isUpdatePerDiemStepPending={isUpdatePerDiemStepPending}
          onAddSegmentClick={onAddSegmentClick}
          onSetTravelLengthError={setTravelLengthError}
          onCalculateAllowanceClick={onUpdatePerDiemStep}
          formIndex={formIndex}
          segmentsLength={currentSegments.length}
          formMethods={formMethods}
        />
      )}
    </div>
  );
};

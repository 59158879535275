import { Badge, Button } from '@candisio/design-system';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DocumentTableRowActionsOverlay.module.css';

interface DocumentTableRowActionsOverlayProps<T> {
  data: T;
  onQuickViewClick: (data: T) => void;
  previewHighlightsCount?: number;
  isSelected?: boolean;
  disabledText?: string;
}

export const DocumentTableRowActionsOverlay = <T extends object>({
  data,
  onQuickViewClick,
  isSelected,
  disabledText,
  previewHighlightsCount,
}: DocumentTableRowActionsOverlayProps<T>) => {
  const [t] = useTranslation();
  const hasHighlights = previewHighlightsCount !== undefined;

  return (
    <div className={styles['row-overlay--wrapper']}>
      <div
        className={styles['row-overlay--content']}
        data-selected={isSelected}
      >
        <Button
          data-preview-highlights={hasHighlights}
          size="small"
          variant="secondary"
          color={hasHighlights ? 'yellow' : 'gray'}
          disabled={Boolean(disabledText)}
          label={disabledText}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onQuickViewClick(data);
          }}
        >
          {t('documentPreview.quickView')}
          {hasHighlights && (
            <Badge color="yellow">{previewHighlightsCount}</Badge>
          )}
        </Button>
      </div>
    </div>
  );
};

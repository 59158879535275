import { Box, Theme, useTheme } from '@candisio/design-system';
import { clamp, debounce } from 'lodash';
import {
  AnimatePresence,
  DragHandlers,
  motion,
  useMotionValue,
} from 'motion/react';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  DocumentPreviewEventLocations,
  TrackingEvents,
} from 'providers/AnalyticsProvider/events';
import { useEffect, useMemo, useState } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import styled from 'styled-components';
import { prefix } from 'utils/localStorage';
import {
  DocumentPreviewContainer,
  DocumentPreviewContainerProps,
} from './DocumentPreviewContainer';

const MotionBox = motion.create(Box);

const DragHandleWrapperBox = styled(MotionBox)<{ ['data-theme']: Theme }>`
  .drag-handle {
    width: 1px;
  }

  &:hover {
    .drag-handle {
      width: ${({ 'data-theme': theme }) => theme.space.space2};
      background-color: ${({ 'data-theme': theme }) => theme.colors.blue100};
    }
  }
`;

const vwToPx = (vw: number) => (vw / 100) * window.innerWidth;

const DEFAULT_DRAWER_WIDTH = Math.min(vwToPx(30), 464);
const MIN_DRAWER_WIDTH = Math.min(vwToPx(25), 440);
const MAX_DRAWER_WIDTH = Math.max(vwToPx(45), 750);

interface DocumentPreviewDrawerProps {
  documentId: string | null;
  openDocument: () => void;
  closePreviewDrawer: () => void;
  documentPreviewView?: (props: DocumentPreviewContainerProps) => JSX.Element;
  documentPreviewEventLocation: DocumentPreviewEventLocations;
}

interface DocumentPreviewDrawerContentProps
  extends Omit<DocumentPreviewDrawerProps, 'documentId'> {
  documentId: string;
}

export const DocumentPreviewDrawer = ({
  documentId,
  closePreviewDrawer,
  openDocument,
  documentPreviewView,
  documentPreviewEventLocation,
}: DocumentPreviewDrawerProps) => (
  <AnimatePresence>
    {documentId && (
      <DocumentPreviewDrawerContent
        openDocument={openDocument}
        closePreviewDrawer={closePreviewDrawer}
        documentId={documentId}
        documentPreviewView={documentPreviewView}
        documentPreviewEventLocation={documentPreviewEventLocation}
      />
    )}
  </AnimatePresence>
);

const DOCUMENT_PREVIEW_WIDTH_KEY = `${prefix}-document-preview-width`;

export const DocumentPreviewDrawerContent = ({
  closePreviewDrawer,
  openDocument,
  documentId,
  documentPreviewView: DocumentPreviewView = DocumentPreviewContainer,
}: DocumentPreviewDrawerContentProps) => {
  const { track } = useAnalytics();

  useEffect(() => {
    track(TrackingEvents.DOCUMENT_PREVIEW_OPENED, { document_id: documentId });
  }, [documentId, track]);
  const [animationFinished, setAnimationFinished] = useState(false);
  const updateAnimationFinished = useMemo(
    () => debounce(setAnimationFinished, 300),
    []
  );

  const theme = useTheme();

  const getStoredWidth = () => {
    const stored = localStorage.getItem(DOCUMENT_PREVIEW_WIDTH_KEY);

    if (!stored) return DEFAULT_DRAWER_WIDTH;

    const parsed = parseInt(stored, 10);

    return isNaN(parsed) ? DEFAULT_DRAWER_WIDTH : parsed;
  };

  const initialWidth = getStoredWidth();

  const motionWidth = useMotionValue(initialWidth);

  const changeWidth: DragHandlers['onDrag'] = (_event, info) => {
    const currentWidth = motionWidth.get();
    const delta = Math.floor(info.delta.x);

    const newWidth = clamp(
      currentWidth - delta,
      MIN_DRAWER_WIDTH,
      MAX_DRAWER_WIDTH
    );

    motionWidth.set(newWidth);
    localStorage.setItem(DOCUMENT_PREVIEW_WIDTH_KEY, String(newWidth));
  };

  const resetWidth = () => {
    motionWidth.set(DEFAULT_DRAWER_WIDTH);
    localStorage.setItem(
      DOCUMENT_PREVIEW_WIDTH_KEY,
      String(DEFAULT_DRAWER_WIDTH)
    );
  };

  return (
    <MotionBox
      onAnimationStart={() => {
        updateAnimationFinished(false);
      }}
      onAnimationComplete={() => {
        updateAnimationFinished(true);
      }}
      key="previewWrapper"
      id="previewWrapper"
      initial="closed"
      animate="open"
      exit="closed"
      variants={{
        open: { width: initialWidth },
        closed: { width: 0 },
      }}
      transition={{ ease: 'easeOut' }}
      height="100%"
      maxWidth={MAX_DRAWER_WIDTH}
      minWidth="0"
      minHeight="0"
      style={{ width: motionWidth }}
    >
      <DragHandleWrapperBox
        drag="x"
        onDrag={changeWidth}
        onDoubleClick={resetWidth}
        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
        dragSnapToOrigin
        dragElastic="0"
        dragMomentum={false}
        position="absolute"
        zIndex="10"
        height="100%"
        paddingX="space10"
        left="-space10"
        cursor="col-resize"
        data-theme={theme}
      >
        <Box className="drag-handle" height="100%" background="gray250" />
      </DragHandleWrapperBox>
      <DocumentPreviewView
        documentId={documentId}
        onOpen={openDocument}
        onClose={closePreviewDrawer}
        showPdf={animationFinished}
      />
    </MotionBox>
  );
};

import { Icon, Text } from '@candisio/design-system';
import clsx from 'clsx';
import { Trans } from 'providers/LocaleProvider';
import styles from './DropZoneContent.module.css';

export const DropZoneContent = ({
  isDropValid,
  isRestrictedContent,
}: { isDropValid: boolean; isRestrictedContent: boolean }) => {
  return (
    <div className={styles.dropzone}>
      {isDropValid ? (
        <>
          <Icon icon="menuUpload" size={28} />
          <Text className={clsx(styles.text, 'px-16')}>
            {isRestrictedContent ? (
              <Trans
                i18nKey="navigation:documentUpload.dropZone.restricted.content"
                components={{ strong: <strong /> }}
              />
            ) : (
              <Trans
                i18nKey="navigation:documentUpload.dropZone.nonRestricted.content"
                components={{ strong: <strong /> }}
              />
            )}
          </Text>
        </>
      ) : (
        <>
          <Icon icon="warning" size={28} />
          <Text className={styles.text}>
            <Trans
              i18nKey="navigation:documentUpload.error.fileType"
              components={{ strong: <strong /> }}
            />
          </Text>
        </>
      )}
      {isDropValid && isRestrictedContent && (
        <div className={styles.subtext}>
          <Icon icon="eyeLock" size={12} />
          <Text>Restricted contract</Text>
        </div>
      )}
    </div>
  );
};

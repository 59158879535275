import { Box } from '@candisio/design-system';
import EmptyDocIcon from 'assets/file-icons/icon-empty-doc.svg';
import PdfIcon from 'assets/file-icons/icon-pdf.svg';
import XmlIcon from 'assets/file-icons/icon-xml.svg';
import { Filename } from 'components/Filename/Filename';
import { ProgressMessage } from 'components/ProgressMessage/ProgressMessage';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { FileState } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { RoutePath } from 'utils/path_generator';
import { IndicatorStatus } from '../ProgressIndicator';
import { MAX_FILE_SIZE_MB } from './fileUploadUtil';

export type FileUpload = {
  fileName: string;
  state: FileState;
  // TODO: This can be cleaned up if we decoupled the component from the backend.
  documentId?: string | null;
  errorDetails?: string | null;
};

interface FileUploadsListProps {
  fileUploads: FileUpload[];
  isSensitive?: boolean;
  onModalClose: () => void;
}

const inboxPathPattern = new RoutePath(
  `/:organizationSlug${Routes.INBOX}/:documentId`
);

const contractsPathPattern = new RoutePath(
  `/:organizationSlug${Routes.ECM_SENSITIVE_CONTRACTS}/:documentId`
);

const errorTranslationMap: Record<string, string> = {
  NotAnXRechnungXmlFile: 'uploads.status.errors.notAnXRechnungXmlFile',
  InvalidXml: 'uploads.status.errors.invalidXml',
  InvalidXmlContent: 'uploads.status.errors.invalidXmlContent',
  InvalidXmlFile: 'uploads.status.errors.invalidXmlFile',
  OutdatedFormat: 'uploads.status.errors.outdatedFormat',
};

export const FileUploadsList = ({
  fileUploads,
  isSensitive = false,
  onModalClose,
}: FileUploadsListProps) => {
  const [t] = useTranslation();
  const [llmClassificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.llmClassification,
  ]);
  const organizationSlug = useOrganizationId();

  const pathPattern = isSensitive ? contractsPathPattern : inboxPathPattern;

  const fileStateMap: Record<FileState, IndicatorStatus> = {
    [FileState.Uploaded]: IndicatorStatus.DONE,
    [FileState.Uploading]: IndicatorStatus.PROCESSING,
    [FileState.Error]: IndicatorStatus.ERROR,
  };

  const fileIconMap: { [key: string]: string } = {
    pdf: PdfIcon,
    xml: XmlIcon,
    default: EmptyDocIcon,
  };

  return (
    <Box
      as="ul"
      listStyle="none"
      padding="unset"
      data-cy="document-upload-list-modal"
    >
      {fileUploads.map(({ fileName, state, errorDetails, documentId }) => {
        const fileIcon =
          fileIconMap[fileName.split('.').pop() || ''] || fileIconMap.default;

        const translatedErrorDetails = errorDetails
          ? t(errorTranslationMap[errorDetails] || errorDetails)
          : errorDetails;

        return (
          <Box as="li" padding="space4 0" data-cy={fileName} key={fileName}>
            <ProgressMessage
              state={fileStateMap[state]}
              statusLabel={t(`uploads.status.${state.toLowerCase()}`)}
              errorDetails={t(translatedErrorDetails ?? '', {
                size: MAX_FILE_SIZE_MB,
              })}
              icon={fileIcon}
              label={
                isNil(documentId) || llmClassificationFF ? (
                  <Filename>{fileName}</Filename>
                ) : (
                  <RouterLink
                    onClick={onModalClose}
                    to={pathPattern.stringify({
                      documentId,
                      organizationSlug,
                    })}
                  >
                    <Filename>{fileName}</Filename>
                  </RouterLink>
                )
              }
            />
          </Box>
        );
      })}
    </Box>
  );
};

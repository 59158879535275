import { HistoryLoading } from 'components/History/HistoryLoading';
import { TransactionHistory } from '../TransactionHistory';
import { useTransactionHistory } from './useTransactionHistory';

interface TransactionHistoryContainerProps {
  transactionId: string;
}

export const TransactionHistoryContainer = ({
  transactionId,
}: TransactionHistoryContainerProps) => {
  const { loading, transaction } = useTransactionHistory(transactionId);

  if (!loading && !transaction) {
    // TODO add error state
    return null;
  }

  if (loading) {
    return (
      <div key="history-wrapper" className="p-4 pt-2">
        <HistoryLoading itemCount={4} />
      </div>
    );
  }

  const supportedEvents = transaction?.timeline ?? [];

  return (
    <div key="history-wrapper" className="px-2">
      <TransactionHistory events={supportedEvents} />
    </div>
  );
};

import clsx from 'clsx';
import { ReactNode } from 'react';
import { SwimlaneTile } from '../../SwimlaneTile';
import styles from './SwimlaneTilePromo.module.css';

type SwimlaneTilePromoProps = {
  children: ReactNode;
  size?: 'small' | 'large';
  variant?: 'blue' | 'purple';
};

export const SwimlaneTilePromo = ({
  children,
  size = 'large',
  variant = 'blue',
}: SwimlaneTilePromoProps) => {
  const classes = clsx(styles.promo, {
    [styles['promo--large']]: size === 'large',
  });

  return (
    <SwimlaneTile
      className={classes}
      cols={size === 'large' ? 2 : 1}
      variant={variant}
    >
      {children}
    </SwimlaneTile>
  );
};

import { Avatar, Grid } from '@candisio/design-system';
import {
  FilterOption,
  PaginationWithSearchFilterHook,
} from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import {
  MembershipStatus,
  useMembersByRoleQuery,
} from 'generated-types/graphql.types';
import { escapeRegex } from 'utils/regex';

export const useTargetMembershipsPaginatedFilterOptions: PaginationWithSearchFilterHook =
  ({ searchStr }) => {
    const { data, loading } = useMembersByRoleQuery({
      variables: {
        filters: {
          emailVerified: true,
          status: MembershipStatus.Active,
        },
      },
    });

    const memberships = data?.organizationMemberships ?? [];

    const searchedMemberships: FilterOption[] = memberships
      .filter(({ name }) => name.match(new RegExp(escapeRegex(searchStr), 'i')))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ id, name, avatarUrl }) => ({
        id: id,
        label: (
          <Grid
            templateColumns="max-content auto"
            gap="space8"
            alignItems="center"
          >
            <Avatar
              size="small"
              name={name}
              img={avatarUrl ?? undefined}
              hasTooltip={false}
            />
            {name}
          </Grid>
        ),
      }));

    return {
      filterOptions: searchedMemberships,
      loading,
    };
  };

import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { CellProps } from 'react-table';
import { GoodsReceiptsTableData } from '../types';
import { maximumPrecisionForSapItemInvoices } from 'constants/sap';
import { useTranslation } from 'react-i18next';
import { AmountCell } from 'components/Table/Cells/Amount';

export type GoodsReceiptsAmountCellProps = CellProps<
  GoodsReceiptsTableData,
  GoodsReceiptsTableData['amount']
>;

export const GoodsReceiptsAmountCell = ({
  value,
}: GoodsReceiptsAmountCellProps) => {
  const [t] = useTranslation();
  const { documentMoneyFormatter } = useMoneyFormatter();

  const formattedAmount = value
    ? documentMoneyFormatter(value, maximumPrecisionForSapItemInvoices)
    : t('emptyCellPlaceholder');

  return <AmountCell formattedAmount={formattedAmount} />;
};

import { BankAccountItem } from 'components/BankAccountListView/BankAccountListView';
import { BankAccountsIbanDataFragment } from 'generated-types/graphql.types';

export const toBankAccountItem = ({
  iban,
  swiftCode,
  id,
  isDefault,
  logoUrl,
  name,
}: BankAccountsIbanDataFragment): BankAccountItem => ({
  iban,
  swiftCode,
  isDefault,
  key: id,
  logoUrl: logoUrl ?? undefined,
  name,
});

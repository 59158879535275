import { HeaderProps, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ContactsTableData } from './types';

const mapColumnIdToTranslationKey: Record<string, string> = {
  name: 'contacts.table.columns.name',
  status: 'contacts.table.columns.status',
};

export const Header = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<ContactsTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.EXPORTS);

  return (
    <TruncatedText
      wordBreak="break-all"
      color={isFilteredOrSorted ? 'gray800' : 'gray500'}
    >
      {t(mapColumnIdToTranslationKey[column.id])}
    </TruncatedText>
  );
};

import {
  ContactRelationshipType,
  ContactType,
} from 'generated-types/graphql.types';
import { electronicFormatIBAN } from 'ibantools';
import { ContactFormOutput } from 'views/Contacts/ContactDetails/ContactForm/contactFormSchema';

export const getContactFieldValuesToSubmit = (
  initialValues: ContactFormOutput
) => {
  const { teamMember, ...formValues } = initialValues;
  const inputCompany: ContactFormOutput = {
    ...formValues,
    individualFirstName: null,
    individualLastName: null,
    notSpecifiedName: null,
    iban: initialValues.iban ? electronicFormatIBAN(initialValues.iban) : null,
    addressInfoType: initialValues.addressInfoType || 'STREET',
    postOfficeBox:
      initialValues.addressInfoType === 'POST_OFFICE_BOX'
        ? initialValues.postOfficeBox
        : null,
    street:
      initialValues.addressInfoType === 'STREET' ? initialValues.street : null,
  };

  const inputNotSpecified: ContactFormOutput = {
    ...formValues,
    companyName: null,
    individualFirstName: null,
    individualLastName: null,
    iban: initialValues.iban ? electronicFormatIBAN(initialValues.iban) : null,
    addressInfoType: initialValues.addressInfoType || 'STREET',
    postOfficeBox:
      initialValues.addressInfoType === 'POST_OFFICE_BOX'
        ? initialValues.postOfficeBox
        : null,
    street:
      initialValues.addressInfoType === 'STREET' ? initialValues.street : null,
  };

  const inputIndividual: ContactFormOutput = {
    ...formValues,
    companyName: null,
    notSpecifiedName: null,
    iban: initialValues.iban ? electronicFormatIBAN(initialValues.iban) : null,
    addressInfoType: initialValues.addressInfoType || 'STREET',
    postOfficeBox:
      initialValues.addressInfoType === 'POST_OFFICE_BOX'
        ? initialValues.postOfficeBox
        : null,
    street:
      initialValues.addressInfoType === 'STREET' ? initialValues.street : null,
  };

  const inputEmployee: ContactFormOutput = {
    ...initialValues,
    relationshipType: ContactRelationshipType.Employee,
    contactType: ContactType.Individual,
    //XXX DO NOT COPY YIKES!!! We have to do this because of the woraround we have in ContactTypeRadioButtonField
    companyName: formValues.name === 'empty' ? null : formValues.companyName,
    name: formValues.name === 'empty' ? '' : formValues.name,
    individualLastName:
      formValues.name === 'empty' ? null : formValues.individualLastName,
    notSpecifiedName:
      formValues.name === 'empty' ? null : formValues.notSpecifiedName,
  };

  const contactType = initialValues.contactType;

  switch (contactType) {
    case ContactType.Company:
      return inputCompany;
    case ContactType.Individual:
      return inputIndividual;
    case ContactType.NotSpecified:
      return inputNotSpecified;
    case 'EMPLOYEE':
      return inputEmployee;
    default:
      return inputNotSpecified;
  }
};

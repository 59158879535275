import { Grid, Image, Text } from '@candisio/design-system';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkflowStepResolutionTypes } from 'generated-types/graphql.types';
import { ReimbursementWorkflowStep } from 'views/Reimbursement/hooks/useWorkflowForReimbursementCaseById';
import checkImage from 'assets/icon-check.svg';
import closeImage from 'assets/icon-close-x-red.svg';
import pendingImage from 'assets/icon-pending.svg';
import skipImage from 'assets/icon-skip.svg';

interface StatusIconConfig {
  type?: WorkflowStepResolutionTypes;
  isSkipped: boolean;
  matched: boolean;
}

interface ApproverInfo {
  id: string;
  fullName: string;
  substitute?: {
    id: string;
    fullName: string;
  };
}

interface ApprovalStepProps {
  step: ReimbursementWorkflowStep;
  isActive: boolean;
  orderNumber: number;
}

interface ApproverRowProps {
  approver: ApproverInfo;
  isActive: boolean;
  isSkipped: boolean;
  matched: boolean;
  type?: WorkflowStepResolutionTypes;
  customText?: string;
}

const STATUS_ICONS = {
  [WorkflowStepResolutionTypes.Approved]: checkImage,
  [WorkflowStepResolutionTypes.Skipped]: skipImage,
  [WorkflowStepResolutionTypes.Rejected]: closeImage,
} as const;

const getResolutionTypeIcon = ({
  isSkipped,
  matched,
  type,
}: StatusIconConfig): string => {
  if (isSkipped) return skipImage;
  if (!matched && type !== WorkflowStepResolutionTypes.Skipped)
    return pendingImage;
  return STATUS_ICONS[type as keyof typeof STATUS_ICONS] || pendingImage;
};

const getSkippedApproverIds = (step: ReimbursementWorkflowStep) => {
  const approversIds = step.resolutionApprovers?.map(user => user.id);

  return step.approvers
    ?.filter(user => approversIds?.length && !approversIds.includes(user.id))
    .map(user => user.id);
};

const getSkippedApproversWithSubstitutesIds = (
  step: ReimbursementWorkflowStep
) => {
  const approversIds = step.resolutionApprovers?.map(user => user.id);

  return step.approvers
    ?.flatMap(approver =>
      approver.substitute
        ? [approver.id, approver.substitute.id]
        : [approver.id]
    )
    .filter(id => approversIds?.length && !approversIds.includes(id));
};

const ApproverRow = ({
  approver,
  isActive,
  isSkipped,
  matched,
  type,
  customText,
}: ApproverRowProps) => (
  <Grid
    gap="space4"
    templateColumns="auto 1fr"
    alignItems="center"
    textDecoration={isSkipped ? 'line-through' : 'none'}
    color={isActive ? 'gray800' : 'gray600'}
    textAlign="left"
  >
    <Image
      src={getResolutionTypeIcon({ isSkipped, matched, type })}
      alt="resolution-type"
      width="space24"
      height="space24"
    />
    <Text>{customText ?? approver.fullName}</Text>
  </Grid>
);

export const ApprovalStep = ({
  step,
  isActive,
  orderNumber,
}: ApprovalStepProps) => {
  const [t] = useTranslation();
  const type = step.resolutionType;
  const isSkipped = type === WorkflowStepResolutionTypes.Skipped;
  const shouldAddShadow =
    !isActive && type === WorkflowStepResolutionTypes.Pending;

  const skippedApproverIds = getSkippedApproverIds(step);
  const skippedApproversWithSubstitutesIds =
    getSkippedApproversWithSubstitutesIds(step);

  return (
    <Grid
      gap="space8"
      templateColumns="auto 1fr"
      borderRadius="basic"
      fontSize="small"
      paddingX="space32"
      paddingY="space12"
      background={shouldAddShadow ? 'gray100' : undefined}
      textDecoration={isSkipped ? 'line-through' : 'none'}
    >
      <Text color={isActive ? 'gray800' : 'gray600'} lineHeight="space24">
        {t('document.approveDocument.inputs.step')} {orderNumber}
      </Text>

      <Grid gap="space4">
        {step.approvers?.map(approver => {
          const matched =
            step.resolutionApprovers?.some(a => a.id === approver.id) ?? false;
          const isApproverSkipped =
            skippedApproverIds?.includes(approver.id) ?? false;
          const substitute = approver.substitute;

          const substituteMatched =
            substitute &&
            (step.resolutionApprovers?.some(a => a.id === substitute.id) ??
              false);
          const isSubstituteSkipped =
            substitute &&
            (skippedApproversWithSubstitutesIds?.includes(substitute.id) ??
              false);

          return (
            <Fragment key={approver.id}>
              <ApproverRow
                approver={approver}
                isActive={isActive}
                matched={matched}
                type={type}
                isSkipped={isApproverSkipped}
              />
              {substitute && (
                <ApproverRow
                  key={`${approver.id}-substituted-by-${substitute.id}`}
                  approver={substitute}
                  isActive={isActive}
                  isSkipped={isSubstituteSkipped ?? false}
                  matched={substituteMatched ?? false}
                  type={type}
                  customText={t(
                    'document.approveDocument.inputs.substituteApprover',
                    {
                      substituteApprover: substitute.fullName,
                      absentApprover: approver.fullName,
                    }
                  )}
                />
              )}
            </Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};

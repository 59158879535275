import { Flex, Popover, TextField, usePopover } from '@candisio/design-system';
import { AdvancedSearchFieldFilterOverlayContainer } from 'components/AdvancedSearchFieldFilterOverlay/AdvancedSearchFieldFilterOverlayContainer';
import { SearchFilterUpsellModal } from 'components/AdvancedSearchFieldFilterOverlay/SearchFilterUpsellModal';
import { Entitlements, useEntitlementsFlag } from 'hooks/useCandisFeatureFlags';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdvancedSearchFieldClearButton } from './components/AdvancedSearchFieldClearButton';
import { FilterButton } from './components/AdvancedSearchFieldFilterButton';
import { AdvancedSearchFieldSearchIcon } from './components/AdvancedSearchFieldSearchIcon';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  EventLocationsBase,
  TrackingEvents,
} from 'providers/AnalyticsProvider/events';

export interface AdvancedSearchFieldProps {
  autoFocus?: boolean;
  clearTooltip?: string;
  inputMaxLength?: number;
  width?: number;
  placeholder: string;
  initialValue?: string;
  readOnly?: boolean;
  onQueryChange: (filter: string) => void;
}
export const AdvancedSearchField = ({
  autoFocus,
  clearTooltip,
  inputMaxLength,
  width,
  placeholder,
  initialValue,
  readOnly = false,
  onQueryChange,
}: AdvancedSearchFieldProps) => {
  const [value, setValue] = useState(initialValue ?? '');
  const [t] = useTranslation();
  const { showSearchOverlayPromo } = useEcm();
  const currentEntitlement = useEntitlementsFlag();

  const showUpsellModal =
    showSearchOverlayPromo || currentEntitlement === Entitlements.BASE;

  const {
    close,
    setOpen,
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({ placement: 'bottom' });

  const { track } = useAnalytics();
  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false);
  const openPromoModal = () => {
    close();
    setIsPromoModalOpen(true);

    track(TrackingEvents.PROMOTION_MODAL_OPENED, {
      feature: '',
      event_location: EventLocationsBase.GLOBAL_SEARCH,
    });
  };
  const closePromoModal = () => {
    setIsPromoModalOpen(false);
    setOpen(true);
  };

  const handleOnChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      onQueryChange(newValue);
    },
    [onQueryChange]
  );

  return (
    <Flex alignItems="center" alignContent="center" position="relative">
      <TextField
        flex={1}
        aria-label={t('search.label')}
        readOnly={readOnly}
        input={{
          autoFocus,
          size: width,
          placeholder,
          readOnly,
          value,
          maxLength: inputMaxLength,
          style: { maxWidth: width ? 'auto' : '85%' },
          onChange: e => handleOnChange(e.target.value),
        }}
      />

      <FilterButton
        isOverlayOpen={isOpen}
        triggerProps={triggerProps}
        triggerRef={triggerRef}
      />

      {value.length === 0 && <AdvancedSearchFieldSearchIcon />}
      {value.length > 0 && (
        <AdvancedSearchFieldClearButton
          clearTooltip={clearTooltip}
          onClear={() => handleOnChange('')}
        />
      )}

      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef} autoFocus restoreFocus>
          <AdvancedSearchFieldFilterOverlayContainer
            onApply={close}
            onShowPromoModal={openPromoModal}
          />
        </Popover>
      )}
      {showUpsellModal && (
        <SearchFilterUpsellModal
          isOpen={isPromoModalOpen}
          onClose={closePromoModal}
          isBaseEntitlement={currentEntitlement === Entitlements.BASE}
        />
      )}
    </Flex>
  );
};

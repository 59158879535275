import { forwardRef, useMemo, useCallback, ComponentProps } from 'react';
import { mergeProps } from 'react-aria';
import mergeRefs from 'react-merge-refs';
import clsx from 'clsx';
import { Tooltip, useTooltip } from '../../../Atoms/Tooltip';
import { useOverflow } from '../../../Molecules/Typography/TruncatedText/useOverflow';
import { isChromium } from '../../../utils/browserHelpers';
import styles from './HighlightedTruncatedText.module.css';
import { HighlightedText } from './HighlightedText';

export interface HighlightResult {
  text: string;
  matches: {
    offset: number;
    length: number;
  }[];
}

interface HighlightedTruncatedTextProps extends ComponentProps<'span'> {
  charsAfterEllipsis?: number;
  lineClamp?: number;
  highlight: HighlightResult;
}

/**
 * Text that cuts off after a given number of lines. An ellipsis (…) is
 * displayed at the cut-off point. A tooltip displays the full text on hover.
 *
 * Highlights search results with `highlight` prop.
 *
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/molecules-typography-truncatedtext}
 *
 * @param {number} [lineClamp = 1] Number of lines after which to cut off the text
 * @param {number} [charsAfterEllipsis = 0] Number of characters you want to display after truncation
 */
export const HighlightedTruncatedText = forwardRef(
  (
    {
      lineClamp = 1,
      charsAfterEllipsis = 0,
      highlight,
      className,
      ...restProps
    }: HighlightedTruncatedTextProps,
    forwardedRef
  ) => {
    const { overflowing, overflowRef } = useOverflow();
    const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
      useTooltip({ passiveTrigger: true, delay: 1000 });

    const searchQuery = highlight.text;

    // only truncate in the middle if there are at least 3 more chars in the full string than should be displayed
    // after ellipsis and if browser is Chromium based (current implementation caused flickering in other browsers)
    const effectiveCharsAfterEllipsis = useMemo(() => {
      if ((searchQuery.length ?? 0) - 2 <= charsAfterEllipsis || !isChromium) {
        return 0;
      }
      return charsAfterEllipsis;
    }, [searchQuery.length, charsAfterEllipsis]);

    const searchResults = useMemo(() => {
      const results: string[] = [];
      for (let i = 0; i < highlight.matches.length; i++) {
        results.push(
          searchQuery.slice(
            highlight.matches[i].offset,
            highlight.matches[i].offset + highlight.matches[i].length
          )
        );
      }
      return results;
    }, [highlight.matches, searchQuery]);

    const searchResultsRegEx = useMemo(() => {
      return new RegExp(`(${searchResults.join('|') ?? ''})`, 'gi');
    }, [searchResults]);

    const textPreTruncation = useMemo(
      () =>
        searchQuery.slice(0, searchQuery.length - effectiveCharsAfterEllipsis),
      [searchQuery, effectiveCharsAfterEllipsis]
    );

    const textPostTruncation = useMemo(
      () =>
        effectiveCharsAfterEllipsis
          ? searchQuery.slice(-effectiveCharsAfterEllipsis)
          : '',
      [searchQuery, effectiveCharsAfterEllipsis]
    );

    const querySlices = useMemo(
      () => searchQuery.split(searchResultsRegEx),
      [searchQuery, searchResultsRegEx]
    );

    const querySlicesPreTrunc = useMemo(
      () => textPreTruncation.split(searchResultsRegEx),
      [textPreTruncation, searchResultsRegEx]
    );

    const querySlicesPostTrunc = useMemo(
      () => textPostTruncation.split(searchResultsRegEx),
      [textPostTruncation, searchResultsRegEx]
    );

    // Determine word-break class based on conditions
    const wordBreakValue =
      effectiveCharsAfterEllipsis || lineClamp <= 1
        ? 'break-all'
        : 'break-word';

    const style = useMemo(
      () => ({
        '--word-break': wordBreakValue,
        '--line-clamp': lineClamp,
        minWidth:
          textPostTruncation.length > 0
            ? `${textPostTruncation.length + 3}ch`
            : undefined,
      }),
      [lineClamp, textPostTruncation, wordBreakValue]
    );

    const renderHighlightedSlices = useCallback(
      (slices: string[]) => {
        return slices.map((slice, index) => (
          <HighlightedText
            key={index}
            isHighlighted={searchResults.includes(slice)}
          >
            {slice}
          </HighlightedText>
        ));
      },
      [searchResults]
    );

    const content = useMemo(() => {
      const shouldShowTruncated =
        overflowing ||
        querySlicesPreTrunc.length ||
        querySlicesPostTrunc.length;

      if (shouldShowTruncated) {
        return (
          <>
            {/* Create spacer elements for multi-line truncation */}
            {[...Array(lineClamp - 1)].map((_, index) => (
              <span key={index} className="float-right clear-both">
                &nbsp;
              </span>
            ))}
            <span className="whitespace-nowrap float-right clear-both">
              {renderHighlightedSlices(querySlicesPostTrunc)}
            </span>
            <span>{renderHighlightedSlices(querySlicesPreTrunc)}</span>
          </>
        );
      }

      return <span>{renderHighlightedSlices(querySlices)}</span>;
    }, [
      overflowing,
      querySlicesPreTrunc,
      querySlicesPostTrunc,
      querySlices,
      lineClamp,
      renderHighlightedSlices,
    ]);

    return (
      <>
        <span
          {...(overflowing
            ? (mergeProps(restProps, triggerProps) as Record<string, unknown>)
            : restProps)}
          className={clsx(
            styles['highlighted-text'],
            'line-clamp-[var(--line-clamp)]',
            'overflow-hidden',
            'text-left',
            className
          )}
          style={style}
          ref={mergeRefs([forwardedRef, triggerRef, overflowRef])}
        >
          {content}
        </span>
        {overflowing && isOpen && (
          <Tooltip {...tooltipProps} ref={tooltipRef}>
            {renderHighlightedSlices(querySlices)}
          </Tooltip>
        )}
      </>
    );
  }
);

import { SwimlaneTileAmount } from 'components/Swimlane/content/SwimlaneTileAmount';
import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import {
  DocumentCurrency,
  ReimbursementCasesSummaryFragmentFragment,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom-v5-compat';
import { useReimbursementPath } from '../hooks/useReimbursementPath';
import { ReimbursementSummaryTile } from './ReimbursementSummaryTile';
import { ReimbursementSummaryTileTagline } from './ReimbursementSummaryTileTagline';
import { useRef } from 'react';
import { getDateRangeForLastNDays } from 'utils/getDateRange';
import { formatDateRange } from 'utils/time';

export const ReimbursementSummaryApproved = ({
  caseSummary,
}: {
  caseSummary?: ReimbursementCasesSummaryFragmentFragment['approved'];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { fromDate, toDate } = useRef(
    getDateRangeForLastNDays({ days: 30 })
  ).current;

  const path = useReimbursementPath('approved', {
    lastClosedAt: formatDateRange(fromDate, toDate),
  });

  const { documentMoneyFormatter } = useMoneyFormatter();

  if (!caseSummary) {
    return null;
  }

  const { approvedInLast30DaysCount = 0, approvedInLast30DaysAmount } =
    caseSummary;

  return (
    <ReimbursementSummaryTile asChild data-cy="approvedSummary">
      <NavLink to={path}>
        <ReimbursementSummaryTileTagline>
          {t('dashboard.swimlanes.overview.closedTile.tag')}
        </ReimbursementSummaryTileTagline>
        <div>
          <SwimlaneTileDescription>
            {t(
              approvedInLast30DaysCount > 1
                ? 'dashboard.swimlanes.overview.closedTile.description.plural'
                : 'dashboard.swimlanes.overview.closedTile.description.singular',
              {
                count: approvedInLast30DaysCount,
              }
            )}
          </SwimlaneTileDescription>
          <SwimlaneTileAmount variant="green">
            {documentMoneyFormatter({
              amount: approvedInLast30DaysAmount?.amount || 0,
              currency:
                approvedInLast30DaysAmount?.currency || DocumentCurrency.Eur,
              precision: approvedInLast30DaysAmount?.precision || 2,
            })}
          </SwimlaneTileAmount>
        </div>
      </NavLink>
    </ReimbursementSummaryTile>
  );
};

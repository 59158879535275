import { ReactNode, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { MESSAGE_TOAST_CONTAINER_ID } from './ToastProvider';
import { ToastWrapper } from './ToastWrapper';
import {
  ERROR_TOAST_CLASS_NAME,
  INFO_TOAST_CLASS_NAME,
  SUCCESS_TOAST_CLASS_NAME,
  WARNING_TOAST_CLASS_NAME,
} from './consts';

export const useToastMessage = () => {
  const success = useCallback(
    (message: ReactNode, durationInSeconds: number = 5) => {
      toast(<ToastWrapper variant="success">{message}</ToastWrapper>, {
        containerId: MESSAGE_TOAST_CONTAINER_ID,
        autoClose: durationInSeconds * 1000,
        className: `toast ${SUCCESS_TOAST_CLASS_NAME}`,
      });
    },
    []
  );

  const error = useCallback(
    (message: ReactNode, durationInSeconds: number = 5) => {
      toast(<ToastWrapper variant="error">{message}</ToastWrapper>, {
        containerId: MESSAGE_TOAST_CONTAINER_ID,
        autoClose: durationInSeconds * 1000,
        className: `toast ${ERROR_TOAST_CLASS_NAME}`,
      });
    },
    []
  );

  const info = useCallback(
    (message: ReactNode, durationInSeconds: number = 5) => {
      toast(<ToastWrapper variant="info">{message}</ToastWrapper>, {
        containerId: MESSAGE_TOAST_CONTAINER_ID,
        autoClose: durationInSeconds * 1000,
        className: `toast ${INFO_TOAST_CLASS_NAME}`,
      });
    },
    []
  );

  const warning = useCallback(
    (message: ReactNode, durationInSeconds: number = 5) => {
      toast(<ToastWrapper variant="warning">{message}</ToastWrapper>, {
        containerId: MESSAGE_TOAST_CONTAINER_ID,
        autoClose: durationInSeconds * 1000,
        className: `toast ${WARNING_TOAST_CLASS_NAME}`,
      });
    },
    []
  );

  const dismiss = useCallback(() => {
    toast.dismiss();
  }, []);

  const retVal = useMemo(
    () => ({
      success,
      error,
      info,
      warning,
      dismiss,
    }),
    [error, info, success, warning, dismiss]
  );

  return retVal;
};

import { DocumentType } from 'generated-types/graphql.types';
import moment from 'moment';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSetStorageFormMetadata } from './StorageFormSetMetadataContext';
import { StorageFormValues } from './storageFormSchema';
import { useRelativeReminderDateItems } from './useRelativeReminderDateItems';

export interface UseWatchStorageFormOptions {
  form: UseFormReturn<StorageFormValues>;
}

/**
 * Watches for storage form changes and updates field metadata
 * or linked fields accordingly
 */
export const useWatchStorageForm = ({ form }: UseWatchStorageFormOptions) => {
  const setMetadata = useSetStorageFormMetadata();
  const relativeReminderDate = useRelativeReminderDateItems();

  useEffect(() => {
    const subscription = form.watch((values, { name, type }) => {
      if (!name) {
        return;
      }

      if (type === 'change') {
        // clear field’s metadata when its value was changed by user
        setMetadata(name, undefined);
      }

      const {
        documentType,
        relativeDate,
        responsiblePerson,
        terminationDate,
        terminationReminderDate,
        isSensitive,
      } = values;

      const terminationReminderDateMoment = moment(terminationReminderDate);

      const relativeTerminationReminderDate = relativeReminderDate.find(
        time => time.key === relativeDate
      );

      const newTerminationReminderDate = moment(terminationDate)
        .subtract(
          relativeTerminationReminderDate?.amount,
          relativeTerminationReminderDate?.unit
        )
        .format(moment.HTML5_FMT.DATE);

      switch (name) {
        case 'documentType': {
          if (documentType !== DocumentType.Contract) {
            form.clearErrors();
          }

          break;
        }

        case 'isSensitive': {
          if (!isSensitive) {
            form.clearErrors(['responsiblePerson', 'notifyPerson']);
          }

          break;
        }

        case 'startDate': {
          // Trigger end date validation when start date changes
          void form.trigger('endDate');
          break;
        }

        case 'endDate': {
          // Reset reminder date if it's not in the future
          if (!terminationReminderDateMoment.isAfter()) {
            form.setValue('terminationReminderDate', null);
          }

          break;
        }

        case 'terminationDate': {
          if (!terminationDate || !relativeDate || relativeDate === 'custom') {
            return;
          }

          form.setValue('terminationReminderDate', newTerminationReminderDate);

          break;
        }

        case 'relativeDate': {
          if (!terminationDate || relativeDate === 'custom') {
            return;
          }

          form.setValue('terminationReminderDate', newTerminationReminderDate);

          break;
        }

        case 'responsiblePerson': {
          if (responsiblePerson && terminationReminderDate) {
            form.clearErrors('responsiblePerson');
          }

          break;
        }

        case 'terminationReminderDate': {
          // Set 'relativeDate' as 'custom' when user changes
          // 'terminationReminderDate' directly
          if (type === 'change') {
            form.setValue('relativeDate', 'custom');
          }

          break;
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [form, relativeReminderDate, setMetadata]);
};

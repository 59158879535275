import { Box, Button, Flex, Grid, Image, Text } from '@candisio/design-system';
import filter from 'assets/icon-filter-filled.svg';
import { HookFormNumberField } from 'components/HookFormFields/HookFormNumberField';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import {
  Maybe,
  WorkflowStepConditionOperators,
} from 'generated-types/graphql.types';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const MotionGrid = motion.create(Grid);

interface ConditionFieldsProps {
  stepsIndex: number;
  isArchived?: Maybe<boolean>;
}

export const ConditionFields = ({
  stepsIndex,
  isArchived,
}: ConditionFieldsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const form = useFormContext();

  const handleOperatorChange = (event: Key | null, index: number) => {
    form.setValue(`steps[${stepsIndex}].conditions[${index}].operator`, event);
  };

  const {
    fields: conditions,
    append,
    remove,
  } = useFieldArray({
    name: `steps.[${stepsIndex}].conditions`,
    control: form.control,
  });

  return (
    conditions && (
      <Grid
        background="gray100"
        padding="space16 space18"
        borderRadius="medium medium 0 0"
      >
        <Grid gap="space18">
          {conditions.map((condition: { id: string }, index: number) => {
            return (
              <MotionGrid
                alignItems="center"
                gap="space18"
                justifyContent="center"
                templateColumns="auto 1fr auto"
                padding="space18 0"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ ease: 'easeOut', duration: 0.3 }}
                key={condition.id}
              >
                <Box paddingTop="space16">
                  {index === 0 ? (
                    <Image alt="filter icon" height="space30" src={filter} />
                  ) : (
                    <Text textTransform="uppercase" fontSize="large">
                      {t('workflows.details.edit.and')}
                    </Text>
                  )}
                </Box>
                <Grid gap="space8" templateColumns="0.5fr 1fr 1fr">
                  <HookFormTextField
                    name=""
                    label={t('workflows.details.edit.conditions.field.label')}
                    inputProps={{
                      value: t(
                        'workflows.details.edit.conditions.field.options.amount'
                      ),
                    }}
                    readOnly
                  />
                  <HookFormSelectField
                    name={`steps[${stepsIndex}].conditions[${index}].operator`}
                    onChange={event => handleOperatorChange(event, index)}
                    readOnly={!!isArchived}
                    items={[
                      {
                        key: WorkflowStepConditionOperators.GreaterThan,

                        children: t(
                          'workflows.details.edit.conditions.operator.options.greaterThan'
                        ),
                      },
                      {
                        key: WorkflowStepConditionOperators.GreaterThanEquals,
                        children: t(
                          'workflows.details.edit.conditions.operator.options.greaterThanEquals'
                        ),
                      },
                      {
                        key: WorkflowStepConditionOperators.LessThan,
                        children: t(
                          'workflows.details.edit.conditions.operator.options.lessThan'
                        ),
                      },
                      {
                        key: WorkflowStepConditionOperators.LessThanEquals,
                        children: t(
                          'workflows.details.edit.conditions.operator.options.lessThanEquals'
                        ),
                      },
                    ]}
                    label={t(
                      'workflows.details.edit.conditions.operator.label'
                    )}
                  />
                  <HookFormNumberField
                    name={`steps[${stepsIndex}].conditions[${index}].values`}
                    readOnly={!!isArchived}
                    label={t(
                      'workflows.details.edit.conditions.values.amount.label'
                    )}
                  />
                </Grid>
                <Box paddingTop="space16">
                  {!isArchived && (
                    <Button
                      icon="trash"
                      variant="tertiary"
                      label={t('workflows.details.edit.conditions.remove')}
                      onClick={() => remove(index)}
                    />
                  )}
                </Box>
              </MotionGrid>
            );
          })}
          <Flex justifyContent="center">
            {!isArchived && (
              <Button
                variant="secondary"
                icon="plus"
                iconPosition="left"
                onClick={() =>
                  append({
                    operator: undefined,
                    values: undefined,
                  })
                }
              >
                {t('workflows.details.edit.condition')}
              </Button>
            )}
          </Flex>
        </Grid>
      </Grid>
    )
  );
};

import { createSearchParams, generatePath } from 'react-router-dom-v5-compat';
import { NotificationProps } from '../getNotificationInfo';
import { AppRouteParams, Routes } from 'models';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/utils/constants';

export const getLinkToArchiveReimbursement = (
  notification: NotificationProps<
    | 'ReimbursementCaseAmendmentRequestedInAppNotification'
    | 'ReimbursementCaseApprovedInAppNotification'
    | 'ReimbursementCaseCommentCreatedInAppNotification'
    | 'ReimbursementCaseRejectedInAppNotification'
    | 'ReimbursementCaseItemExcludedInAppNotification'
  >
) => {
  const searchParams = createSearchParams({
    [REIMBURSEMENT_URL_PARAM.VIEW]: VIEW_PARAM_VALUE,
  });

  const link = {
    pathname: generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}${Routes.REIMBURSEMENTS}/:reimbursementId`,
      {
        organizationSlug: notification.organizationId,
        reimbursementId: notification.reimbursementCaseId,
      }
    ),
    search: searchParams.toString(),
  };

  return { link };
};

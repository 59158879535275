import { Box, Grid, Image, Text, TruncatedText } from '@candisio/design-system';
import {
  CardBrand,
  CardIssuerCardSortField,
  CardStatus,
  CardType,
  GetCardsInfiniteScrollPaginationQuery,
  GetCardsInfiniteScrollPaginationQueryVariables,
  SortDirection,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';
import { cardTypes } from '../CardManagerView/components/CreditCardsTable/Cells/CardNumberCell';
import { mapRecordsToCardDetails } from '../CreditCardsInsights/SpentOverview/CreditCardFilter/util';
import { getCardsInfiniteScrollPaginationQuery } from '../CreditCardsInsights/gql';

const defaultSort = {
  direction: SortDirection.Asc,
  field: CardIssuerCardSortField.CardholderFullName,
};

const sortForInputString = {
  direction: SortDirection.Desc,
  field: CardIssuerCardSortField.Query,
};

export const useAvailableCardsForLinkingRecurringPayments = (
  defaultInputValue?: string,
  defaultCreditCardId?: string
) => {
  const computeVariables = useCallback(
    (inputString?: string): GetCardsInfiniteScrollPaginationQueryVariables => ({
      input: {},
      queries: {
        query: inputString,
      },
      filters: {
        types: [CardType.Virtual, CardType.Physical],
        statuses: [CardStatus.Active, CardStatus.Locked],
        includedIds:
          defaultCreditCardId && defaultInputValue?.includes(inputString ?? '')
            ? [defaultCreditCardId]
            : undefined,
      },
      sortBy: inputString ? sortForInputString : defaultSort,
    }),
    [defaultInputValue, defaultCreditCardId]
  );

  const { data, loading, loadMore, handleDebounceSearch } =
    useDebouncedSearchPagination<
      GetCardsInfiniteScrollPaginationQuery,
      GetCardsInfiniteScrollPaginationQueryVariables
    >({
      query: getCardsInfiniteScrollPaginationQuery,
      queryRootKey: 'getPageBasedCardIssuerCards',
      computeVariables,
      initialSearchValue: defaultInputValue,
    });

  const entries = data?.getPageBasedCardIssuerCards?.records || [];

  const mappedUnselectedCreditCards = mapRecordsToCardDetails(entries);

  const options = mappedUnselectedCreditCards.map(cc => {
    const isCandisBranded = cc.brand === CardBrand.Candis;

    return {
      key: cc.id,
      textValue: `${cc.cardNumber}`,
      children: (
        <Grid templateColumns="auto 1fr" gap="space4">
          <Image
            src={
              isCandisBranded
                ? cardTypes[cc.type].candis
                : cardTypes[cc.type].pliant
            }
            height="40px"
            alt=""
          />
          <Grid autoFlow="column" gap="space4">
            <Box>
              <TruncatedText width="space128">
                {`${cc.firstName} ${cc.lastname}`}
              </TruncatedText>
              <TruncatedText color="gray500" width="space128">
                {cc.label}
              </TruncatedText>
            </Box>
            <Text>{`•••${cc.cardNumber}`}</Text>
          </Grid>
        </Grid>
      ),
    };
  });

  return {
    comboBoxOptions: uniqBy(options, 'key'),
    loading,
    loadMore,
    handleDebounceSearch,
  };
};

import { Tag } from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'types/ReimbursementCaseStatusEnum';
import { useReimbursementTags } from 'views/Reimbursement/hooks/useReimbursementTags';

export const ReimbursementStatusTag = ({
  status,
}: { status: ReimbursementCaseStatus }) => {
  const tags = useReimbursementTags();

  return (
    <Tag variant="secondary" color={tags[status].color}>
      {tags[status].text}
    </Tag>
  );
};

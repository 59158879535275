import { SwimlaneTilePromo } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromo';
import { SwimlaneTilePromoCTAButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCTAButton';
import {
  SwimlaneTilePromoContent,
  SwimlaneTilePromoContentBody,
} from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoContent';
import { SwimlaneTilePromoCloseButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCloseButton';
import { SwimlaneTileTitle } from 'components/Swimlane/content/SwimlaneTileTitle';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import {} from 'hooks/useCandisFeatureFlags';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';

export const PromoCreditCardLimitRequests = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isPromotionSeen, setPromotionIsSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.CREDIT_CARD_LIMIT_REQUESTS
  );

  if (isPromotionSeen) return null;

  return (
    <SwimlaneTilePromo>
      <SwimlaneTilePromoContent>
        <SwimlaneTilePromoCloseButton onClose={setPromotionIsSeen} />
        <SwimlaneTilePromoContentBody>
          <SwimlaneTileTitle>
            {t(
              'insights.swimlanes.promotions.promoCreditCardLimitRequests.title'
            )}
          </SwimlaneTileTitle>
          <SwimlaneTilePromoCTAButton
            icon="arrowRight"
            iconPosition="right"
            as="a"
            target="_blank"
            href={t(
              'insights.swimlanes.promotions.promoCreditCardLimitRequests.ctaButton.link'
            )}
          >
            {t(
              'insights.swimlanes.promotions.promoCreditCardLimitRequests.ctaButton.text'
            )}
          </SwimlaneTilePromoCTAButton>
        </SwimlaneTilePromoContentBody>
      </SwimlaneTilePromoContent>
    </SwimlaneTilePromo>
  );
};

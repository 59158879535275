import { Box, Grid } from '@candisio/design-system';
import { Collaborators } from 'components/DocumentViewer/PdfViewer/Collaborators/Collaborators';
import { AnimatePresence, motion } from 'motion/react';
import { ReactNode } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import styled from 'styled-components';
import { useSubscribeDocumentCollabs } from 'views/DocumentDetails/hooks/useSubscribeDocumentCollabs';

const StyledGrid = styled(Grid)`
  & > * {
    grid-area: 1 / 1;
  }
`;

const MotionBox = motion.create(Box);

interface CollaboratorsContainerProps {
  children: ReactNode;
  hasLoaded: boolean;
  documentId?: string;
}

export const CollaboratorsContainer = ({
  children,
  documentId,
  hasLoaded,
}: CollaboratorsContainerProps) => {
  const { mappedViewers } = useSubscribeDocumentCollabs({
    documentId,
  });

  const collaborators = mappedViewers;

  return (
    <StyledGrid position="relative" overflow="hidden">
      <AnimatePresence>
        {hasLoaded && collaborators.length ? (
          <MotionBox
            alignSelf="start"
            justifySelf="center"
            background="green700"
            borderTopRadius="none"
            borderBottomRadius="large"
            padding="space5 space12"
            zIndex={1}
            initial={{
              y: '-100%',
            }}
            animate={{
              y: '0%',
            }}
            exit={{
              y: '-100%',
            }}
            transition={{
              duration: 0.5,
            }}
          >
            <Collaborators collaborators={collaborators} />
          </MotionBox>
        ) : null}
      </AnimatePresence>
      {children}
    </StyledGrid>
  );
};

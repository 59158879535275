import { GetTransactionsInsightsQuery } from 'generated-types/graphql.types';

export const getMappedCardHolderInsights = (
  insights?: GetTransactionsInsightsQuery['getTransactionsInsights']['cardholders']
) => {
  if (!insights) {
    return [];
  }

  return insights.map(insight => ({
    cardholder: {
      id: insight.cardholder.id,
      membershipId: insight.cardholder.membershipId ?? undefined,
      membershipEmail: insight.cardholder.membershipEmail ?? undefined,
      firstName: insight.cardholder.firstName ?? undefined,
      lastName: insight.cardholder.lastName ?? undefined,
      avatarUrl: insight.cardholder.avatarUrl ?? undefined,
      cardsWithMissingInvoices: insight.cards.length
        ? insight.cards.map(card => ({
            refNum: card.card.refNum ?? undefined,
            invoicesWithoutTransaction: card.aggregate.count,
            label: card.card.label ?? undefined,
          }))
        : undefined,
    },
    invoicesWithoutTransaction: insight.aggregate.count,
  }));
};

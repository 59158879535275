import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { Reimbursement } from './Reimbursement';
import { useExpensesFormDefaultValues } from './components/MiddleSection/utils/useExpensesFormDefaultValues';
import { ReimbursementFormsProvider } from './context/ReimbursementFormsProvider';
import { ReimbursementSplitBookingsProvider } from './context/ReimbursementSplitBookingsProvider';
import { useGetReimbursementExpenseItems } from './hooks/useGetReimbursementExpenseItems';
import {
  ReimbursementRouteParams,
  usePrefetchReimbursementQueries,
  useReimbursementNavigation,
} from './hooks/useReimbursementNavigation';
import { useReimbursementRouteValidator } from './hooks/useReimbursementRouteValidator';
import { useReimbursementUIConfig } from './hooks/useReimbursementUIConfig';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/utils/constants';
import { useGetReimbursementById } from './hooks/useGetReimbursementById';

export const ReimbursementContainer = () => {
  const { organizationSlug, type, reimbursementId } =
    useParams<ReimbursementRouteParams>();

  const { searchParams } = useMutateSearchParams();
  const cursor = searchParams.get(REIMBURSEMENT_URL_PARAM.CURSOR);
  const { reimbursement } = useGetReimbursementById({
    reimbursementId,
  });
  const { mappedItems, loadingItems } = useGetReimbursementExpenseItems();

  const defaultValues = useExpensesFormDefaultValues({
    reimbursementItems: mappedItems,
  });

  const {
    isLoadingNavigationData,
    nextReimbursementId,
    nextReimbursementLink,
    prevReimbursementId,
    prevReimbursementLink,
    reimbursementCount,
    cycleReimbursements,
  } = useReimbursementNavigation({
    cursor,
    type,
    organizationSlug,
  });

  useReimbursementRouteValidator();

  usePrefetchReimbursementQueries(nextReimbursementId, prevReimbursementId);

  const defaultUIConfig = useReimbursementUIConfig();

  return (
    <ReimbursementFormsProvider
      key={`loading-${reimbursementId}-${!!mappedItems.length}`}
      initialUIConfig={defaultUIConfig}
    >
      <ReimbursementSplitBookingsProvider reimbursement={reimbursement}>
        <Reimbursement
          isLoading={isLoadingNavigationData || loadingItems}
          defaultValues={defaultValues}
          cycleReimbursements={cycleReimbursements}
          nextReimbursementLink={nextReimbursementLink}
          prevReimbursementLink={prevReimbursementLink}
          reimbursementCount={reimbursementCount}
        />
      </ReimbursementSplitBookingsProvider>
    </ReimbursementFormsProvider>
  );
};

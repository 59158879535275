import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import {
  PromotionWrapper,
  TableWrapper,
  Wrapper,
} from 'components/ProductPromotions/Wrapper';
import { CREDIT_CARDS_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { CreditCardSettlementsTable } from 'views/Settings/CreditCards/CreditCardSettlements/CreditCardSettlementsTable';
import { toTableData } from 'views/Settings/CreditCards/CreditCardSettlements/utils';
import { useGetCustomPromotionDetails } from '../hooks/useGetCustomPromotionDetails';
import { cardSettlementsData } from './tableData';

export const CreditCardsPromotionExportCardSettlement = () => {
  const { creditCardsPromotion } = usePromotionContent();

  const customPromotionDetails = useGetCustomPromotionDetails(
    'creditCards',
    CREDIT_CARDS_UTM_SOURCE.EXPORT_CARD_SETTLEMENT
  );

  return (
    <Wrapper>
      <TableWrapper>
        <CreditCardSettlementsTable
          data={toTableData(cardSettlementsData)}
          loading={false}
        />
      </TableWrapper>
      <PromotionWrapper width="34rem" height="24rem">
        <PromotionsProvider promotions={[creditCardsPromotion]}>
          <PromotionCard
            promotion={creditCardsPromotion}
            closable={false}
            customPromotionDetails={customPromotionDetails}
            trackOption={{
              eventLocation: CREDIT_CARDS_UTM_SOURCE.EXPORT_CARD_SETTLEMENT,
            }}
          />
        </PromotionsProvider>
      </PromotionWrapper>
    </Wrapper>
  );
};

import { Grid, Separator, Text } from '@candisio/design-system';
import { HookFormPaginatedComboBoxField } from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { HookFormUsersField } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { noop } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { FormProvider as HookFormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { ReimbursementFormFieldOptions } from 'views/Reimbursement/hooks/types';
import { useFormValidationTrigger } from 'views/Reimbursement/hooks/useFormValidationTrigger';
import { reimbursementFormErrorMessages } from 'views/Reimbursement/toolkit/reimbursementFormErrorMessages';
import {
  ReimbursementFormSchemaContext,
  ReimbursementFormValues,
  reimbursementFormSchema,
} from 'views/Reimbursement/toolkit/reimbursementFormSchema';
import { IbanField } from './components/IbanField';
import { ReimbursementApprovalWorkflowFields } from './ApprovalAndWorkflow/ReimbursementApprovalWorkflowFields';
import { ApprovalFieldData } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { AdvancedAccountingDataSwitch } from './components/AdvancedAccountingDataSwitch';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { useIsSplitBookingsVisible } from './utils/useIsSplitBookingsVisible';
import { MissingAccountsPayableNumberCard } from './components/MissingAccountsPayableNumberCard';
import { FormLayout } from './components/FormLayout';

const FORM_KEY = 'reimbursementForm';
type ClearableField = keyof ReimbursementFormValues;

interface ReimbursementFormProps {
  defaultValues: ReimbursementFormValues;
  fieldOptions: ReimbursementFormFieldOptions;
  formContext: ReimbursementFormSchemaContext;
  isLoading: boolean;
  splits: ReactNode;
  reimbursementStatus?: ReimbursementCaseStatus;
  onSetApprovalData: (values: ApprovalFieldData) => void;
  onSetHasApprovalFieldError: (val: boolean) => void;
  onUpdateReimbursement: (values: ReimbursementFormValues) => Promise<void>;
}

export const ReimbursementForm = ({
  defaultValues,
  fieldOptions,
  isLoading,
  formContext,
  splits,
  reimbursementStatus,
  onSetApprovalData,
  onSetHasApprovalFieldError,
  onUpdateReimbursement,
}: ReimbursementFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [clearable, setClearable] = useState<ClearableField>();

  const {
    targetMembership,
    suggestedCostCenter,
    suggestedCostObject,
    suggestedGeneralLedgerAccount,
    accountsPayableNumberInfo,
    accountPayableNumberField,
    iban,
    approvers: approversOptions,
    workflow: workflowOptions,
    advancedAccountingData: advancedAccountingDataOptions,
  } = fieldOptions;

  const form = useForm<ReimbursementFormValues>({
    defaultValues,
    mode: 'onBlur',
    shouldFocusError: false,
    resetOptions: {
      keepErrors: true,
    },
    resolver: zodResolver({
      zodSchema: reimbursementFormSchema(formContext),
      errorMessages: reimbursementFormErrorMessages,
      translationNamespace: LOCALE_NAME_SPACE.REIMBURSEMENT,
    }),
  });

  const { getValues, handleSubmit, formState, watch } = form;
  const errors = form.formState.errors;
  const [approvalType, approvers, workflow, isAdvancedAccountingEnabled] =
    watch([
      'approvalType',
      'approvers',
      'workflow',
      'isAdvancedAccountingEnabled',
    ]);

  const hasApprovalErrors = Boolean(errors.approvers || errors.workflow);

  const isSubmitted = formState.isSubmitted;

  const handleUpdateReimbursement = () => {
    setClearable(undefined);
    void onUpdateReimbursement(getValues());
  };

  const updateApprovalData = useCallback(() => {
    onSetApprovalData({
      approvalType,
      workflow,
      approvers,
    });
  }, [approvalType, approvers, workflow, onSetApprovalData]);

  // initialize reference to form for triggering validation
  useFormValidationTrigger(FORM_KEY, () => void handleSubmit(noop)());

  // When we switch between approval modes we need to manually trigger
  // form validation for this form to updated errors
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    updateApprovalData();

    if (isSubmitted) {
      form.trigger();
      onSetHasApprovalFieldError(hasApprovalErrors);
    }
  }, [
    updateApprovalData,
    formContext.approvalMode,
    hasApprovalErrors,
    isSubmitted,
  ]);

  const showReimbursmentItemSplitBookings = useIsSplitBookingsVisible({
    isAdvancedAccountingEnabled,
    reimbursementStatus,
  });

  const showAdvancedAccountingHeader =
    reimbursementStatus === ReimbursementCaseStatus.Draft;

  return (
    <HookFormProvider {...form}>
      <FormLayout>
        <HookFormTextField
          name="title"
          label={t('reimbursementView.rightSection.form.title.label')}
          ariaLabel={t('reimbursementView.rightSection.form.title.label')}
          placeholder={t(
            'reimbursementView.rightSection.form.title.placeholder'
          )}
          clearable={clearable === 'title'}
          onFocus={() => setClearable('title')}
          inputProps={{
            onBlur: handleUpdateReimbursement,
          }}
          isLoading={isLoading}
        />
        <HookFormUsersField
          name="targetMembership"
          readOnly
          label={t('reimbursementView.rightSection.form.targetMember.label')}
          placeholder={t('teams.createTeam.teamMembers.field.placeholder')}
          emptyListPlaceholder={t(
            'reimbursementView.rightSection.form.targetMember.noUserFound'
          )}
          {...targetMembership?.options}
          isLoading={isLoading}
        />

        <IbanField
          label={t('reimbursementView.rightSection.form.iban.label')}
          isAddButtonVisible={iban.isAddButtonVisible}
          isEditButtonVisible={iban.isEditButtonVisible}
          isLoading={isLoading}
        />
        {accountPayableNumberField.isVisible && (
          <HookFormTextField
            name="accountsPayableNumber"
            readOnly
            label={t(
              'reimbursementView.rightSection.form.accountsPayableNumber.label'
            )}
          />
        )}
        {accountsPayableNumberInfo.isVisible && (
          <MissingAccountsPayableNumberCard />
        )}
        {showAdvancedAccountingHeader && (
          <Grid
            templateColumns="auto 1fr"
            alignItems="center"
            gap="space8"
            paddingTop="space8"
          >
            <Text fontSize="basic" fontWeight="semibold">
              {t(
                'reimbursementView.rightSection.form.advancedAccounting.title'
              )}
            </Text>
            <Separator />
          </Grid>
        )}

        <HookFormTextareaField
          name="note"
          label={t('reimbursementView.rightSection.form.note.label')}
          placeholder={t(
            'reimbursementView.rightSection.form.note.placeholder'
          )}
          infoTooltip={{
            message: t('reimbursementView.rightSection.form.note.tooltip'),
          }}
          onBlur={handleUpdateReimbursement}
          isLoading={isLoading}
        />
        {advancedAccountingDataOptions.isVisible && (
          <AdvancedAccountingDataSwitch
            isLoading={isLoading}
            onChange={handleUpdateReimbursement}
          />
        )}

        {suggestedCostCenter?.isVisible && !isAdvancedAccountingEnabled && (
          <HookFormPaginatedComboBoxField
            name="suggestedCostCenter"
            clearable={clearable === 'suggestedCostCenter'}
            label={t('reimbursementView.rightSection.form.costCenter.label')}
            placeholder={t(
              'reimbursementView.rightSection.form.costCenter.placeholder'
            )}
            {...suggestedCostCenter?.options}
            onFocus={() => setClearable('suggestedCostCenter')}
            onBlur={handleUpdateReimbursement}
            isLoading={isLoading}
          />
        )}
        {suggestedCostObject?.isVisible && !isAdvancedAccountingEnabled && (
          <HookFormPaginatedComboBoxField
            name="suggestedCostObject"
            clearable={clearable === 'suggestedCostObject'}
            label={t('reimbursementView.rightSection.form.costObject.label')}
            placeholder={t(
              'reimbursementView.rightSection.form.costObject.placeholder'
            )}
            {...suggestedCostObject?.options}
            onFocus={() => setClearable('suggestedCostObject')}
            onBlur={handleUpdateReimbursement}
            isLoading={isLoading}
          />
        )}
        {suggestedGeneralLedgerAccount?.isVisible &&
          !isAdvancedAccountingEnabled && (
            <HookFormPaginatedComboBoxField
              name="suggestedGeneralLedgerAccount"
              clearable={clearable === 'suggestedGeneralLedgerAccount'}
              label={t(
                'reimbursementView.rightSection.form.generalLedgerAccount.label'
              )}
              placeholder={t(
                'reimbursementView.rightSection.form.generalLedgerAccount.placeholder'
              )}
              {...suggestedGeneralLedgerAccount?.options}
              onFocus={() => setClearable('suggestedGeneralLedgerAccount')}
              onBlur={handleUpdateReimbursement}
              isLoading={isLoading}
            />
          )}
        {showReimbursmentItemSplitBookings && splits}
        {(approversOptions?.isVisible || workflowOptions?.isVisible) && (
          <Grid gap="space8">
            <Grid alignItems="center" gap="space8" templateColumns="auto 1fr">
              <Text fontSize="basic" fontWeight="semibold">
                {t('reimbursementView.rightSection.form.approvalFields.title')}
              </Text>
              <Separator />
            </Grid>
            <ReimbursementApprovalWorkflowFields
              approversFieldOptions={approversOptions}
              workflowFieldOptions={workflowOptions}
            />
          </Grid>
        )}
      </FormLayout>
    </HookFormProvider>
  );
};

import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateOptionMissingInvoicesWidget } from 'views/CreditCards/CreditCardsInsights/MissingInvoicesWidget/utils';

export const useMissingInvoiceDateRangeFilterOptions = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const items: { id: DateOptionMissingInvoicesWidget; label: string }[] =
    useMemo(() => {
      return [
        {
          id: 'All',
          label: t('insights.missingInvoicesWidget.filter.all'),
        },
        {
          id: 'Last7Days',
          label: t('insights.missingInvoicesWidget.filter.last7Days'),
        },
        {
          id: 'Last14Days',
          label: t('insights.missingInvoicesWidget.filter.last14Days'),
        },
        {
          id: 'CurrentMonth',
          label: t('insights.missingInvoicesWidget.filter.currentMonth'),
        },
        {
          id: 'PreviousMonth',
          label: t('insights.missingInvoicesWidget.filter.previousMonth'),
        },
        {
          id: 'OlderThan2Months',
          label: t('insights.missingInvoicesWidget.filter.olderThan2Months'),
        },
      ];
    }, [t]);

  return { items };
};

import { mergeProps } from '@candisio/design-system';
import { HookFormTextareaFieldProps } from 'components/HookFormFields/HookFormTextareaField';
import { useDatev } from 'orgConfig/datev';
import { useSap } from 'orgConfig/sap';
import { useTranslation } from 'react-i18next';
import { NOTE_FIELD_MAX_LENGTH } from 'views/consts';
import { ProcessingFormTextField } from './ProcessingFormTextField';
import { ProcessingFormTextareaField } from './ProcessingFormTextareaField';
import { ProcessingFormValues } from './processingFormSchema';
import { useProcessingFormField } from './useProcessingFormField';
import { useProcessingFormLengthWarning } from './useProcessingFormLengthWarning';

/** A `ProcessingFormTextareaField` for entering a note */

export const ProcessingFormNoteField = ({
  name,
  readOnly,
  ...restProps
}: HookFormTextareaFieldProps<ProcessingFormValues>) => {
  const { fieldProps } = useProcessingFormField(name);
  const [t] = useTranslation();
  const { client } = useDatev();
  const { isActiveIntegration: isSap } = useSap();
  const maxLength = client ? NOTE_FIELD_MAX_LENGTH : Infinity;
  const { lengthExceeded } = useProcessingFormLengthWarning({
    name: 'bookings.0.note',
    maxLength,
  });

  const commonProps = {
    name,
    ...mergeProps(fieldProps, restProps),
    readOnly: readOnly || fieldProps?.readOnly,
  };

  return isSap ? (
    <ProcessingFormTextField {...commonProps} />
  ) : (
    <ProcessingFormTextareaField
      {...commonProps}
      warning={
        lengthExceeded
          ? t('document.requestApproval.inputs.note.maxLengthWarning', {
              maxLength,
            })
          : undefined
      }
    />
  );
};

import { MenuItem, useModal } from '@candisio/design-system';
import { queryParameter } from 'components/Table/consts';
import { DocumentType } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { DownloadMenuButton } from '../shared/DownloadMenuButton';
import { DownloadOriginalFilesModal } from './DownloadOriginalFilesModal';
import { useDownloadCSVFileEcm } from './hooks/useDownloadCSVFileEcm';
import { useDownloadDocumentFilesEcm } from './hooks/useDownloadDocumentFilesEcm';

interface DownloadMenuButtonContractsContainerProps {
  selectedDocumentsCount: number;
  isTableLoading: boolean;
  isTableFiltered: boolean;
}

export const DownloadMenuButtonContractsContainer = ({
  isTableLoading,
  selectedDocumentsCount,
  isTableFiltered,
}: DownloadMenuButtonContractsContainerProps) => {
  const [searchParams] = useSearchParams();
  const { modalProps, close, open } = useModal();
  const { handleCSVDownload, isLoading } = useDownloadCSVFileEcm({
    isTableLoading,
    defaultFilters: {
      documentType: [DocumentType.Contract],
    },
  });

  const { onPrepareDownloadDocument, createDocumentZipFileLoading } =
    useDownloadDocumentFilesEcm({
      isTableLoading,
      handleClosePdfDownloadModal: close,
      defaultFilters: {
        documentType: [DocumentType.Contract],
      },
    });

  const [t] = useTranslation();

  const items: Array<MenuItem> = [
    {
      id: 'download-CSV',
      label: t('archive:download.menuButton.options.downloadCSV'),
      onAction: !isLoading ? handleCSVDownload : undefined,
    },
    {
      id: 'download-Invoices',
      label: t('archive:download.menuButton.options.downloadOriginalFiles'),
      onAction: open,
    },
  ];

  const isMenuButtonDisabled =
    isLoading ||
    isTableLoading ||
    !selectedDocumentsCount ||
    createDocumentZipFileLoading;

  const hasSearchQuery = searchParams.has(queryParameter);

  return (
    <>
      <DownloadMenuButton
        items={items}
        isDisabled={isMenuButtonDisabled}
        isLoading={isTableLoading}
        isTableFiltered={isTableFiltered}
        isTableSearched={hasSearchQuery}
        count={selectedDocumentsCount}
      />
      <DownloadOriginalFilesModal
        {...modalProps}
        isTableLoading={isLoading}
        selectedDocumentsCount={selectedDocumentsCount}
        createOriginalFilesZipFileLoading={createDocumentZipFileLoading}
        onPrepareDownload={onPrepareDownloadDocument}
      />
    </>
  );
};

import {
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
} from 'react-hook-form';
import { BookingsFormValues } from '../schema/bookingsFormSchema';
import { createAtomicStoreProvider } from '@candis/utils';

interface FieldArrayMethodsProps {
  remove?: UseFieldArrayRemove;
  append?: UseFieldArrayAppend<BookingsFormValues, 'bookings'>;
}

const [Provider, useStore] = createAtomicStoreProvider<FieldArrayMethodsProps>(
  'BookingsFormArray',
  'useFieldArrayContext'
);

interface FieldArrayFormProviderProps<TFieldValues extends FieldValues> {
  children: React.ReactNode;
  remove?: UseFieldArrayRemove;
  append?: UseFieldArrayAppend<BookingsFormValues, 'bookings'>;
}

export const FieldArrayFormProvider = <TFieldValues extends FieldValues>({
  children,
  remove,
  append,
}: FieldArrayFormProviderProps<TFieldValues>) => {
  return <Provider initialValue={{ remove, append }}>{children}</Provider>;
};

export const useFieldArrayContext = () => {
  const [useValue] = useStore();
  const value = useValue();
  return value;
};

import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { UserPreferences } from 'generated-types/graphql.types';

export const createUserPreferences = (
  userPreferences?: Partial<UserPreferences>
): UserPreferences => ({
  tables: [],
  promos: Object.values(TOUR_POPOVER_IDS).map(promoId => ({
    promoId,
    seenAt: new Date().toISOString(),
  })),
  ...userPreferences,
});

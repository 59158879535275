import { useApolloClient } from '@apollo/client';
import { useTheme } from '@candisio/design-system';
import { useInAppNotificationSubscription } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useRef } from 'react';
import { Id, ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NotificationToast } from './NotificationToast';
import { getNotificationInfo } from './getNotificationInfo';

export const AUTO_CLOSE_TOAST_DEFAULT = 5000;

const NOTIFICATION_TOAST_CONTAINER_ID = 'notification-toast-container';

export const NotificationsContainer = () => {
  const { space } = useTheme();

  const [inAppNotificationsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.inAppNotifications,
  ]);

  if (!inAppNotificationsFF) return null;

  return (
    <>
      <NotificationsProvider />
      <ToastContainer
        key="new-inapp-notification"
        enableMultiContainer
        containerId={NOTIFICATION_TOAST_CONTAINER_ID}
        closeButton={false}
        closeOnClick={false}
        hideProgressBar={true}
        transition={Zoom}
        autoClose={AUTO_CLOSE_TOAST_DEFAULT}
        toastStyle={{ borderRadius: space.space8 }}
        style={{
          width: '400px',
          zIndex: 100001,
        }}
      />
    </>
  );
};

const NotificationsProvider = () => {
  const [inAppNotificationsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.inAppNotifications,
  ]);

  const { cache } = useApolloClient();

  const toastId = useRef<Id>();

  const handleDismiss = async () => {
    toast.dismiss(toastId.current);
  };

  useInAppNotificationSubscription({
    skip: !inAppNotificationsFF,

    onData: options => {
      const { data } = options;

      const notificationType = data.data?.inAppNotification?.__typename;

      const notificationData = data?.data?.inAppNotification ?? undefined;

      cache.evict({ fieldName: 'inAppNotifications' });
      cache.evict({ fieldName: 'inAppNotificationsCount' });

      if (notificationType) {
        const notificationInfo = getNotificationInfo(notificationType);
        if (notificationInfo) {
          toastId.current = toast(
            <NotificationToast
              notificationInfo={notificationInfo}
              notificationData={notificationData}
              onDismiss={handleDismiss}
            />,
            {
              containerId: NOTIFICATION_TOAST_CONTAINER_ID,
            }
          );
        }
      }
    },
  });

  return <></>;
};

import { Button, Flex, Grid, Text } from '@candisio/design-system';
import {
  EcmDocumentsTable,
  defaultSortByFields,
} from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { DocumentPreviewDrawer } from 'containers/DocumentPreview/DocumentPreviewDrawer';
import { DocumentTableRowActionsOverlay } from 'containers/DocumentPreview/DocumentTableRowActionsOverlay';
import { DocumentType } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { AnimatePresence } from 'motion/react';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { DocumentPreviewEventLocations } from 'providers/AnalyticsProvider/events';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { isEcmDocumentId } from 'views/utils/isEcmDocumentId';
import { CreateDocumentRelationAction } from './CreateDocumentRelationAction';
import { useManageRelationshipsData } from './useManageRelationshipsData';
import { getRawContentMatchesCount } from 'components/WithHighlightsCell/getRawContent';

type ManageRelationshipsViewProps = RouteComponentProps<{
  organizationSlug: string;
  documentId: string;
}>;

export const ManageRelationshipsView = ({
  match: {
    params: { documentId, organizationSlug },
  },
}: ManageRelationshipsViewProps) => {
  const [t] = useTranslation();
  const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(
    documentId
  );
  const { showConsistentSortAndFiltering } = useEcm();

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
  };

  const {
    availableDocumentColumnIds,
    canBeSelected,
    configurationsTable,
    customEmptyState,
    filters,
    handleSearchChange,
    handleUpdateConfigurations,
    isLinkingDocumentsPending,
    isLoading,
    linkDocuments,
    onFilter,
    onLoadMore,
    onSort,
    searchQuery,
    selectedDocuments,
    setSelectedDocuments,
    sortBy,
    tableData,
    totalCount,
    isSelectVisible,
  } = useManageRelationshipsData({
    documentId,
    onLinkDocuments: navigateBack,
    previewDocumentId,
  });

  const getTableRowDocumentType = useCallback(
    (documentId: string) => {
      const tableRow = tableData.find(
        row => row.id === documentId || row.invoiceId === documentId
      );

      if (tableRow === undefined) {
        return 'invoice';
      }

      const result =
        tableRow.documentType !== DocumentType.Invoice
          ? 'ecmDocument'
          : 'invoice';

      return result;
    },
    [tableData]
  );

  const openDocument = useCallback(
    ({
      documentId,
      cursor,
    }: {
      documentId: string | null;
      cursor: string | null;
    }): void => {
      if (!documentId) return;

      const params = new URLSearchParams();
      if (cursor) params.set('cursor', cursor);
      if (!isEcmDocumentId(documentId)) params.set('isInvoice', 'true');

      const path = `/${organizationSlug}${Routes.ECM_DOCUMENTS}/${documentId}?${params}`;

      window.open(path, '_blank');
    },
    [organizationSlug]
  );

  const cursor =
    tableData.find(
      d => d.id === previewDocumentId || d.invoiceId === previewDocumentId
    )?.cursor ?? null;

  const closePreviewDrawer = () => setPreviewDocumentId(null);
  const handleOpenDocument = () =>
    openDocument({ documentId: previewDocumentId, cursor });

  const sortByFields: (keyof EcmDocumentsTableData)[] =
    showConsistentSortAndFiltering
      ? [...defaultSortByFields, 'contact', 'documentName', 'documentNumber']
      : defaultSortByFields;

  return (
    <Grid
      autoFlow="column"
      width="100%"
      height="100%"
      templateColumns="1fr auto"
      overflow="hidden"
    >
      <Flex
        direction="column"
        width="100%"
        height="100%"
        padding="space40 space24 space24 space32"
        gap="space16"
      >
        <Grid gap="space24">
          <Grid justifyContent="space-between">
            <Button
              icon="arrowLeft"
              iconPosition="left"
              variant="secondary"
              color="gray"
              onClick={navigateBack}
            >
              {t('common.back')}
            </Button>
          </Grid>

          <Grid justifyContent="space-between" autoFlow="column">
            <Text fontSize="xxxlarge" lineHeight="paragraph">
              {t('manageRelationships.title')}
            </Text>
          </Grid>
        </Grid>
        <Grid height="100%" width="100%">
          <EcmDocumentsTable
            context="documentRelationships"
            columns={availableDocumentColumnIds}
            data={tableData}
            isLoading={isLoading}
            defaultFilters={filters}
            isTableFiltered={filters.length > 0}
            searchQuery={searchQuery}
            selectedDocumentsCount={totalCount}
            configurationsTable={configurationsTable}
            onUpdateConfigurations={handleUpdateConfigurations}
            defaultSortBy={sortBy}
            onSearchChange={handleSearchChange}
            onFilter={onFilter}
            onEndReached={onLoadMore}
            selectionOptions={{
              selectedRowsIds: selectedDocuments.map(doc => doc.id),
              onSelectionRowChanged: setSelectedDocuments,
              canBeSelected: canBeSelected,
              canBeSelectedTooltipText: t(
                'ecm:documentRelationship.modal.existingRelationshipHint'
              ),
              selectCheckboxProps: row => ({
                isVisible: isSelectVisible(row),
                forceSelected: !canBeSelected(row),
                isReadOnly: !canBeSelected(row),
              }),
            }}
            sortByFields={sortByFields}
            onSort={onSort}
            customEmptyState={customEmptyState}
            rowOverlay={({ data }) => {
              if (data.selected) return <></>; // TODO: Fix DS type to allow to return null
              const rawContentHighlightCount = getRawContentMatchesCount(
                data.highlights
              );
              const isSelected = previewDocumentId
                ? (data.id === previewDocumentId ||
                    data.invoiceId === previewDocumentId) &&
                  previewDocumentId !== documentId
                : false;

              return (
                <DocumentTableRowActionsOverlay
                  data={data}
                  onQuickViewClick={data => {
                    const isEcmDocument =
                      getTableRowDocumentType(data.id) === 'ecmDocument';

                    setPreviewDocumentId(
                      isEcmDocument ? data.id : (data.invoiceId ?? '')
                    );
                  }}
                  isSelected={isSelected}
                  previewHighlightsCount={rawContentHighlightCount}
                />
              );
            }}
          />
          <AnimatePresence>
            {selectedDocuments.length > 0 && (
              <CreateDocumentRelationAction
                onClick={linkDocuments}
                isLinkingDocuments={isLinkingDocumentsPending}
                selectedDocuments={selectedDocuments}
                totalCount={totalCount}
              />
            )}
          </AnimatePresence>
        </Grid>
      </Flex>
      <DocumentPreviewDrawer
        documentPreviewEventLocation={
          DocumentPreviewEventLocations.MANAGE_RELATIONSHIPS
        }
        documentId={previewDocumentId}
        openDocument={handleOpenDocument}
        closePreviewDrawer={closePreviewDrawer}
      />
    </Grid>
  );
};

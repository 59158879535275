import { DocumentApprovalsFormContainer } from 'components/Form/DocumentApprovalsForm/DocumentApprovalsFormContainer';
import { Loader } from 'components/Loader';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { DOCUMENT_MANAGEMENT_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { Switcher } from 'components/Switcher/Switcher';
import { EntitlementsModal } from 'containers/Entitlements/components/Modal/EntitlementsModal';
import {
  FeatureBenefit,
  PromotionCards,
} from 'containers/Entitlements/components/Upsell/components/PromotionCard';
import { videoTours } from 'containers/Entitlements/components/Upsell/utils';
import {
  DocumentStatus,
  GetDocumentForDraftQuery,
  Locale,
  useGetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { memo, useMemo } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { userIsMonitoring } from 'views/Approvals/Invoices';
import { useDocumentTypeContext } from 'views/DocumentDetails/components/DocumentTypeContext';
import { Exported } from 'views/DocumentDetails/components/Exported/Exported';
import { Monitoring } from 'views/DocumentDetails/components/Monitoring';
import { RouteParams, useInboxContext } from '../Context';
import { ProcessingFormContainer } from './ProcessingFormContainer';
import { StorageFormContainer } from './components/Ecm/StorageFormContainer';
import { useDocumentProcessingSwitcher } from './useDocumentProcessingSwitcher';

const ecmBenefits: FeatureBenefit[] = [
  {
    heading: 'promo.ecm.modal.benefit1.heading',
    description: 'promo.ecm.modal.benefit1.description',
  },
  {
    heading: 'promo.ecm.modal.benefit2.heading',
    description: 'promo.ecm.modal.benefit2.description',
  },
  {
    heading: 'promo.ecm.modal.benefit3.heading',
    description: 'promo.ecm.modal.benefit3.description',
  },
  {
    heading: 'promo.ecm.modal.benefit4.heading',
    description: 'promo.ecm.modal.benefit4.description',
  },
] as const;

export const EcmBenefitsPromo = memo(() => {
  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const videoTour = videoTours[FEATURE.ECM][locale];

  return (
    <PromotionCards
      feature={FEATURE.ECM}
      videoTour={videoTour}
      benefits={ecmBenefits}
    />
  );
});

export const RightSection = () => {
  const {
    contactItem,
    handleGetContactItem,
    useDocumentNavigationResult: {
      cycleDocument,
      disableEditMode,
      enableEditMode,
    },
  } = useInboxContext();

  const [llmClassificationFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.llmClassification,
  ]);

  const { isInvoiceDocumentType } = useDocumentTypeContext();

  const currentUser = useCurrentUser();
  const { showEcm } = useEcm();
  const { documentId, type: routesType } = useParams<RouteParams>();

  const isInboxView = routesType === Routes.INBOX;

  const { data } = useGetDocumentForDraftQuery({
    variables: { id: documentId },
  });

  const document = data?.getDocument;
  const transaction = document?.transactions?.[0];
  const transactionId = transaction?.id;
  const purchaseOrderId = document?.purchaseOrderData?.purchaseOrderId;

  const { formType, setFormType, switcherProps, switcherShown } =
    useDocumentProcessingSwitcher(document);

  const inboxViewDeprecated = useMemo(
    () => (
      <ProcessSidebar
        // we need to force reset the form on linking/unlinking transaction
        // to ensure we display the latest data
        // @TODO alternative approach using Hook Form `values` prop?
        accessModificationPermissions={
          document?.accessModificationPermissions as NonNullable<
            GetDocumentForDraftQuery['getDocument']
          >['accessModificationPermissions']
        }
        key={transactionId ?? purchaseOrderId}
        documentId={documentId}
        globalDocumentId={document?.globalDocumentId ?? undefined}
        switcher={switcherShown ? <Switcher {...switcherProps} /> : undefined}
        documentStatus={document?.status}
      >
        {showEcm && formType === 'storage' ? (
          <StorageFormContainer
            documentId={documentId}
            isInvoice
            onDeleteDocument={cycleDocument}
            onStoreDocument={cycleDocument}
            onDocumentTypeConversion={cycleDocument}
          />
        ) : (
          <ProcessingFormContainer
            documentId={documentId}
            onApprove={cycleDocument}
            onDeleteDocument={cycleDocument}
            onRequestApproval={cycleDocument}
            onGetContactItem={handleGetContactItem}
            onCancelEdit={disableEditMode}
            onDocumentTypeConversion={cycleDocument}
          />
        )}
        {!showEcm && (
          <EntitlementsModal
            feature={FEATURE.ECM}
            isOpen={formType === 'storage'}
            onClose={() => setFormType('approval')}
            utmSource={DOCUMENT_MANAGEMENT_UTM_SOURCE.STORAGE}
          />
        )}
      </ProcessSidebar>
    ),
    [
      cycleDocument,
      disableEditMode,
      document?.globalDocumentId,
      document?.status,
      documentId,
      formType,
      handleGetContactItem,
      purchaseOrderId,
      setFormType,
      showEcm,
      switcherProps,
      switcherShown,
      transactionId,
      document?.accessModificationPermissions,
    ]
  );

  const inboxView = useMemo(() => {
    return (
      <ProcessSidebar
        // we need to force reset the form on linking/unlinking transaction
        // to ensure we display the latest data
        // @TODO alternative approach using Hook Form `values` prop?
        key={transactionId ?? purchaseOrderId}
        accessModificationPermissions={
          document?.accessModificationPermissions as NonNullable<
            GetDocumentForDraftQuery['getDocument']
          >['accessModificationPermissions']
        }
        documentId={documentId}
        globalDocumentId={document?.globalDocumentId ?? undefined}
        documentStatus={document?.status}
      >
        {showEcm && !isInvoiceDocumentType ? (
          <StorageFormContainer
            documentId={documentId}
            isInvoice
            onDeleteDocument={cycleDocument}
            onStoreDocument={cycleDocument}
            onDocumentTypeConversion={cycleDocument}
          />
        ) : (
          <ProcessingFormContainer
            documentId={documentId}
            onApprove={cycleDocument}
            onDeleteDocument={cycleDocument}
            onRequestApproval={cycleDocument}
            onGetContactItem={handleGetContactItem}
            onCancelEdit={disableEditMode}
            onDocumentTypeConversion={cycleDocument}
          />
        )}
        {!showEcm && (
          <EntitlementsModal
            feature={FEATURE.ECM}
            isOpen={!isInvoiceDocumentType}
            onClose={() => setFormType('approval')}
            utmSource={DOCUMENT_MANAGEMENT_UTM_SOURCE.INBOX_DOCUMENT_PROCESSING}
          />
        )}
      </ProcessSidebar>
    );
  }, [
    cycleDocument,
    disableEditMode,
    document?.globalDocumentId,
    document?.status,
    document?.accessModificationPermissions,
    documentId,
    handleGetContactItem,
    isInvoiceDocumentType,
    purchaseOrderId,
    setFormType,
    showEcm,
    transactionId,
  ]);

  // If there's no next document and no previous document mark as all Done!
  if (!document) {
    return <Loader />;
  }

  if (document.status === DocumentStatus.Exported) {
    return <Exported key={documentId} document={document} />;
  }

  if (isInboxView) {
    return llmClassificationFF ? inboxView : inboxViewDeprecated;
  }

  if (userIsMonitoring(document, currentUser)) {
    return (
      <Monitoring
        document={document}
        toggleEditMode={enableEditMode}
        purchaseOrderId={purchaseOrderId}
        contactItem={contactItem}
        onGetContactItem={handleGetContactItem}
      />
    );
  }

  return (
    <DocumentApprovalsFormContainer
      key={documentId + purchaseOrderId}
      document={document}
      cycleDocument={cycleDocument}
      toggleEditMode={enableEditMode}
    />
  );
};

import { Flex } from '@candisio/design-system';
import { SearchScopeSelect } from './SearchScopeSelect';
import { SearchTypeSelect } from './SearchTypeSelect';
import { SearchOverlayUpsell } from './SearchFilterUpsell';
import { SearchScope, SearchType } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

interface AdvancedSearchFieldFilterOverlayPromoProps {
  onShowPromoModal: () => void;
  onApply: () => void;
}

export function AdvancedSearchFieldFilterOverlayPromo({
  onShowPromoModal,
  onApply,
}: AdvancedSearchFieldFilterOverlayPromoProps) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  const handleOnChange = (item: string) => {
    if (item === SearchType.Contains || item === SearchScope.DataFields) {
      onApply();
      return;
    }
    onShowPromoModal();
  };

  const searchTypeItems = [
    {
      id: SearchType.Contains,
      name: t('searchFilter.popover.fields.searchType.containsSearch'),
    },
    {
      id: SearchType.PrefixMatch,
      name: t('searchFilter.popover.fields.searchType.prefixSearch'),
      upgrade: true,
    },
    {
      id: SearchType.ExactMatch,
      name: t('searchFilter.popover.fields.searchType.exactSearch'),
      upgrade: true,
    },
  ];

  const searchScopeItems = [
    {
      id: SearchScope.DataFields,
      name: t('searchFilter.popover.fields.searchScope.fieldSearch'),
    },
    {
      id: SearchScope.EntireDocument,
      name: t('searchFilter.popover.fields.searchScope.fullTextSearch'),
      upgrade: true,
    },
  ];

  return (
    <Flex direction="column" gap="space12" width="22.25rem">
      <Flex direction="column" gap="space14">
        <SearchTypeSelect
          items={searchTypeItems}
          onChange={handleOnChange}
          value={SearchType.Contains}
          query=""
        />
        <SearchScopeSelect
          items={searchScopeItems}
          onChange={handleOnChange}
          value={SearchScope.DataFields}
        />
      </Flex>
      <SearchOverlayUpsell openModal={onShowPromoModal} />
    </Flex>
  );
}

import { Text } from '@candisio/design-system';
import { ReimbursementCaseItemIncludedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ReimbursementCaseItemIncluded = ({
  reimbursementItemMetadata,
}: ReimbursementCaseItemIncludedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const expenseTitle = reimbursementItemMetadata?.title
    ? reimbursementItemMetadata?.title
    : undefined;

  const text = expenseTitle
    ? t('timeline.itemIncluded', { title: expenseTitle })
    : t('timeline.itemIncludedNoTitle');

  return <Text>{text}</Text>;
};

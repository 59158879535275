import React from 'react';
import styles from '../BookingsTable.module.css';
import { CellContext } from '@tanstack/react-table';
import { TableFormRow } from '../../schema/bookingsFormSchema';
import { Icon } from '@candisio/design-system';
import { useFieldArrayContext } from '../../utils/FieldArrayContext';

export const DeleteActionCell: React.FC<CellContext<TableFormRow, any>> = ({
  row: { index },
}) => {
  const { remove } = useFieldArrayContext();

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    remove?.(index);
  };

  return (
    <button
      type="button"
      data-cy={`delete-booking-${index}`}
      className={styles.deleteButton}
      onClick={handleDelete}
    >
      <Icon icon="trash" size={20} />
    </button>
  );
};

// TODO improve styling
// TODO use design system button

import gql from 'graphql-tag';
import { documentDataFileRecords } from 'views/DocumentDownloadHistory/queries';
import {
  archiveInvoiceDocumentsDataFragment,
  documentListDataFragment,
  pageInfoDataFragment,
} from 'views/queries/fragments';

export const generalExpenseItemFragment = gql`
  fragment GeneralExpenseItemData on GeneralExpenseItem {
    files{
      name
      url
      id
    }
    type
  }
`;

export const hospitalityExpenseItemFragment = gql`
  fragment HospitalityExpenseItemData on HospitalityExpenseItem {
    files{
      name
      url
      id
    }
    type
  }
`;

export const getReimbursementForPreviewQuery = gql`
  query getExpenseForPreview($id: ID!) {
    reimbursementItem(id: $id) {
      __typename
      id
      ...GeneralExpenseItemData
      ...HospitalityExpenseItemData
    }
  }
  ${generalExpenseItemFragment}
  ${hospitalityExpenseItemFragment}
`;

export const documentTableQuery = gql`
  query _listArchiveDocuments(
    $limit: Int
    $before: String
    $after: String
    $filters: DocumentFilterInput
    $dateRangeFilters: DocumentDateFilterInput
    $sort: SortInput
    $withPurchaseOrderData: Boolean = false
  ) {
    archivedDocuments(
      limit: $limit
      before: $before
      after: $after
      filters: $filters
      dateRangeFilters: $dateRangeFilters
      sort: $sort
    )
      @connection(
        key: "archivedDocumentsPagination"
        filter: ["filters", "dateRangeFilters", "sort"]
      ) {
      edges {
        cursor
        node {
          ...DocumentListData
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }

  ${pageInfoDataFragment}
  ${documentListDataFragment}
`;

export const searchDocumentsTableQuery = gql`
  query listMatchedArchiveDocuments(
    $query: String!
    $limit: Int
    $before: String
    $after: String
    $dateRangeFilters: DocumentDateFilterInput
    $filters: DocumentFilterInput
    $withPurchaseOrderData: Boolean = false
  ) {
    searchDocuments(
      query: $query
      limit: $limit
      before: $before
      after: $after
      filters: $filters
      dateRangeFilters: $dateRangeFilters
    )
      @connection(
        key: "archivedSearchDocuments"
        filter: ["filters", "dateRangeFilters", "query"]
      ) {
      edges {
        cursor
        node {
          ...DocumentListData
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalCount
      }
    }
  }

  ${documentListDataFragment}
`;

export const archiveInvoiceDocumentsTableQuery = gql`
  query archiveInvoiceDocuments(
    $query: String!
    $limit: Int
    $before: String
    $after: String
    $filters: ArchiveInvoiceDocumentsFilterInput
    $sort: SortInput
    $withPurchaseOrderData: Boolean = false
    $withAccountsReceivableNumber: Boolean = false
    $searchInput: SearchInput
  ) {
    archiveInvoiceDocuments(
      query: $query
      limit: $limit
      before: $before
      after: $after
      filters: $filters
      sort: $sort
      searchInput: $searchInput
    )
      @connection(
        key: "archiveInvoiceDocuments"
        filter: ["filters", "query", "sort", "searchInput"]
      ) {
      edges {
        cursor
        node {
          ...ArchiveInvoiceDocumentsData
        }  
        highlights {
          field
          chunks {
            text
            matches {
              offset
              length
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalCount
      }
    }
  }

  ${archiveInvoiceDocumentsDataFragment}
`;

export const unacknowledgedDocumentDataFileRecordsQuery = gql`
  query unacknowledgedDocumentDataFileRecords {
    unacknowledgedDocumentDataFileRecords {
      id
      isAcknowledged
    }
  }
`;

export const markDocumentDataFileRecordsAsAcknowledgedMutation = gql`
  mutation markDocumentDataFileRecordsAsAcknowledged($ids: [ID!]!) {
    markDocumentDataFileRecordsAsAcknowledged(ids: $ids)
  }
`;

export const refetchDocumentDataFileRecords = [
  { query: unacknowledgedDocumentDataFileRecordsQuery },
  { query: documentDataFileRecords },
];

// Download documents
export const createDataFileForDocuments = gql`
  mutation createDataFileForDocuments(
    $type: DocumentDataFileType
    $filters: DocumentFilterInput
    $query: String
    $dateRangeFilters: DocumentDateFilterInput
    $searchInput: SearchInput
  ) {
    createDataFileForDocuments(
      type: $type
      filters: $filters
      query: $query
      dateRangeFilters: $dateRangeFilters
      searchInput: $searchInput
    ) {
      id
      recordCount
      file {
        id
        name
        url
      }
    }
  }
`;

// Download ECM documents
export const createDataFileForEcmDocuments = gql`
  mutation createDataFileForEcmDocuments(
    $type: DocumentDataFileType
    $search: String
    $sort: EcmSortInput
    $filter: EcmFilterInput
    $searchInput: SearchInput
  ) {
    createDataFileForEcmDocuments(
      type: $type
      search: $search
      sort: $sort
      filter: $filter
      searchInput: $searchInput
    ) {
      id
      recordCount
      file {
        id
        name
        url
      }
    }
  }
`;

export const createZipFileForDocumentsMutation = gql`
  mutation createZipFileForDocuments(
    $filters: DocumentFilterInput
    $query: String
    $dateRangeFilters: DocumentDateFilterInput
    $searchInput: SearchInput
  ) {
    createPDFZipFileForDocuments(
      filters: $filters
      query: $query
      dateRangeFilters: $dateRangeFilters
      searchInput: $searchInput
    ) {
      ... on DownloadDocumentPDFsError {
        message
        kind
      }
      ... on DownloadDocumentPDFsResult {
        response
      }
    }
  }
`;

export const createPDFZipFileForEcmDocumentsMutation = gql`
  mutation createPDFZipFileForEcmDocuments(
    $filters: EcmFilterInput
    $search: String
    $sort: EcmSortInput
    $searchInput: SearchInput
  ) {
    createPDFZipFileForEcmDocuments(
      filter: $filters
      search: $search
      sort: $sort
      searchInput: $searchInput
    ) {
      ... on DownloadDocumentPDFsError {
        kind
        message
      }
      ... on DownloadDocumentPDFsResult {
        response
      }
    }
  }
`;

export const downloadPdfFilesForArchiveAllDocumentsMutation = gql`
  mutation downloadPdfFilesForArchiveAllDocuments(
    $search: String
    $sort: ArchiveAllDocumentsSortInput
    $filter: ArchiveAllDocumentsFilterInput
    $searchInput: SearchInput
  ) {
    downloadPdfFilesForArchiveAllDocuments(
      search: $search
      filter: $filter
      sort: $sort
      searchInput: $searchInput
    ) {
      ... on DownloadDocumentPDFsError {
        kind
        message
      }
      ... on DownloadDocumentPDFsResult {
        response
      }
    }
  }
`;

export const getDocumentForPreviewQuery = gql`
  query getDocumentForPreview($id: ID!) {
    getDocument(id: $id) {
      id
      documentFile {
        id
        name
        url
      }
      attachments {
        id
        name
        url
      }
      contact {
        value {
          id
          name {
            value
          }
        }
      }
    }
  }
`;

export const getEcmDocumentForPreviewQuery = gql`
  query getEcmDocumentForPreview($id: String!) {
    getAggregatedEcmDocument(id: $id) {
      id
      fileSource {
        name
        url
      }
      type
      contactName
    }
  }
`;

import { Spinner } from '@candisio/design-system';
import { CheckmarkIcon, ErrorIcon } from 'components/Icons/DefaultIcons';
import { useOtherIntegration } from 'orgConfig/other';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const StateKey = {
  checkingService: 'checkingService',
  isClientConnectedSuccess: 'isClientConnectedSuccess',
  isClientConnectedError: 'isClientConnectedError',
  isRDSConnectedSuccess: 'isRDSConnectedSuccess',
  isRDSConnectedError: 'isRDSConnectedError',
  isBDSConnectedSuccess: 'isBDSConnectedSuccess',
  isBDSConnectedError: 'isBDSConnectedError',
  isCashLedgerConnectedSuccess: 'isCashLedgerConnectedSuccess',
  isCashLedgerConnectedError: 'isCashLedgerConnectedError',
  isCreditCardAccountNumberSetSuccess: 'isCreditCardAccountNumberSetSuccess',
  isCreditCardAccountNumberSetError: 'isCreditCardAccountNumberSetError',
  isCreditCardAccountNumberSetFFSuccess:
    'isCreditCardAccountNumberSetFFSuccess',
  isCreditCardAccountNumberSetFFError: 'isCreditCardAccountNumberSetFFError',
  serviceConnected: 'serviceConnected',
  //! unused enrty
  networkFailure: 'networkFailure',
} as const;

export type StateKey = keyof typeof StateKey;

const iLoading = <Spinner />;
const iSuccess = <CheckmarkIcon flex="none" size="space16" />;
const iError = <ErrorIcon flex="none" size="space16" />;

export const useCheckItem = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const otherIntegration = useOtherIntegration();
  const tOptions = otherIntegration.isActive ? { context: 'other' } : '';

  const keysToDataMap = {
    [StateKey.checkingService]: {
      icon: iLoading,
      text: t('creditCardExporting.checks.checkingService'),
    },
    [StateKey.isClientConnectedSuccess]: {
      icon: iSuccess,
      text: t('creditCardExporting.checks.isClientConnected.success'),
    },
    [StateKey.isClientConnectedError]: {
      icon: iError,
      text: t('creditCardExporting.checks.isClientConnected.error'),
    },
    [StateKey.isRDSConnectedSuccess]: {
      icon: iSuccess,
      text: t('creditCardExporting.checks.isRDSConnected.success'),
    },
    [StateKey.isRDSConnectedError]: {
      icon: iError,
      text: t('creditCardExporting.checks.isRDSConnected.error'),
    },
    [StateKey.isBDSConnectedSuccess]: {
      icon: iSuccess,
      text: t('creditCardExporting.checks.isBDSConnected.success'),
    },
    [StateKey.isBDSConnectedError]: {
      icon: iError,
      text: t('creditCardExporting.checks.isBDSConnected.error'),
    },
    [StateKey.isCashLedgerConnectedSuccess]: {
      icon: iSuccess,
      text: t('creditCardExporting.checks.isCashLedgerConnected.success'),
    },
    [StateKey.isCashLedgerConnectedError]: {
      icon: iError,
      text: t('creditCardExporting.checks.isCashLedgerConnected.error'),
    },
    [StateKey.isCreditCardAccountNumberSetSuccess]: {
      icon: iSuccess,
      text: t(
        'creditCardExporting.checks.isCreditCardAccountNumberSet.success'
      ),
    },
    [StateKey.isCreditCardAccountNumberSetError]: {
      icon: iError,
      text: t('creditCardExporting.checks.isCreditCardAccountNumberSet.error'),
    },
    [StateKey.isCreditCardAccountNumberSetFFSuccess]: {
      icon: iSuccess,
      text: t(
        'creditCardExporting.checks.isCreditCardAccountNumberSetFF.success',
        tOptions
      ),
    },
    [StateKey.isCreditCardAccountNumberSetFFError]: {
      icon: iError,
      text: t(
        'creditCardExporting.checks.isCreditCardAccountNumberSetFF.error',
        tOptions
      ),
    },
    [StateKey.serviceConnected]: {
      icon: iSuccess,
      text: t('creditCardExportingSap.checks.serviceConnected'),
    },
    //! unused enrty
    [StateKey.networkFailure]: {
      icon: iError,
      text: t('settings.datev.connect.exportTypes.dxsoJobs.networkFailure'),
    },
  } as const;

  const getCheckItemProps = (stateKey: keyof typeof keysToDataMap) => {
    return keysToDataMap[stateKey];
  };

  return getCheckItemProps;
};

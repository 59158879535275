import { Button, Heading } from '@candisio/design-system';
import { getMemberships } from 'components/Comment/gql';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { getOrganizationMembershipsQuery } from 'containers/credit-cards/CreateCreditCard/gql';
import { usePermissionsForCreditCards } from 'containers/credit-cards/hooks/usePermissionsForCreditCards';
import {
  Locale,
  useCreateUserMutation,
  useCreateUserSsoMutation,
} from 'generated-types/graphql.types';
import { membershipsQueryFilter } from 'hooks/useUsersFieldOptions';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useSSO } from 'orgConfig/sso/sso';
import { useTeams } from 'orgConfig/teams/useTeams';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import useRouter from 'use-react-router';
import { zodResolver } from 'utils/zodFormValidation';
import { LocalMembershipStatus } from 'views/Settings/TeamMembers/UsersListView';
import { useGetAllUsersEmails } from 'views/Settings/TeamMembers/hooks/useGetAllUsersEmails';
import { useGetTeamFieldOptions } from 'views/Settings/TeamMembers/hooks/useGetTeamFieldOptions';
import {
  getApproversQuery,
  organizationMembershipCountByStatusQuery,
} from 'views/Settings/TeamMembers/queries';
import {
  generateTeamMembersPath,
  getUserInfo,
  sanitizeInviteFormData,
} from 'views/Settings/TeamMembers/utils';
import { ToastMessage } from '../components/ToastMessage';
import { teamMemberFormErrorMessages } from '../teamMemberFormErrorMessages';
import {
  TeamMemberFormOutput,
  TeamMemberFormSchemaOptions,
  TeamMemberFormValues,
  teamMemberFormSchema,
} from '../teamMemberFormSchema';
import { TeamMemberDetails } from './TeamMemberDetails';

export interface InviteFormContainerProps {
  closeDrawer: () => void;
  loading?: boolean;
  organizationLanguage: Locale | null;
  onOpenIssueCardModal: (createdUserId: string) => void;
}

const INVITE_TEAM_MEMBER_FORM_ID = 'invite-team-member';

export const InviteFormContainer = ({
  closeDrawer,
  loading,
  organizationLanguage,
  onOpenIssueCardModal,
}: InviteFormContainerProps) => {
  const { isTeamsFeatureEnabled } = useTeams();
  const {
    handleDebounceSearch,
    loadMore,
    loading: isLoadingTeamOptions,
    teamOptions,
    handleSetSelectedTeams,
    selectedMappedTeams,
  } = useGetTeamFieldOptions();

  const {
    history,
    match: {
      url,
      path,
      params: { organizationSlug },
    },
  } = useRouter<{ organizationSlug: string }>();

  const sso = useSSO();

  const allEmails = useGetAllUsersEmails();
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const { isInUse, showAllExtraFeatures } = useCreditCardsSetup();
  const { canIssueCards } = usePermissionsForCreditCards();

  const [createUserSSO, { loading: isSubmittingCreateUserSSO }] =
    useCreateUserSsoMutation();

  const [createUser, { loading: isSubmittingCreateUser }] =
    useCreateUserMutation();

  const canInviterIssueCard = isInUse && canIssueCards && showAllExtraFeatures;

  const handleSubmit = useCallback(
    async (values: TeamMemberFormOutput) => {
      const input = sanitizeInviteFormData(values);

      // TODO Use optimistic UI updates
      try {
        const result = await createUser({
          variables: { input },
          awaitRefetchQueries: true,
          refetchQueries: [
            'organizationMemberships',
            { query: organizationMembershipCountByStatusQuery },
            {
              query: getOrganizationMembershipsQuery,
              variables: membershipsQueryFilter.ActiveAndPending,
            },
            {
              query: getMemberships,
              variables: membershipsQueryFilter.Active,
            },
            {
              query: getApproversQuery,
              variables: { name: '' },
            },
          ],
        });

        const nameAndEmail = getUserInfo(values);
        const pathname = generateTeamMembersPath({
          organizationSlug,
          path,
          url,
          userId: result.data?.createUser?.id,
        });

        const content = (
          <ToastMessage
            history={history}
            nameAndEmail={nameAndEmail}
            pathname={pathname}
            status={LocalMembershipStatus.PENDING}
            translationKey="settings.teamMembers.actions.inviteSent"
          />
        );

        success(content);

        closeDrawer();
        const createdUserId = result.data?.createUser?.id;
        const { errors } = result;
        const canNavigateToIssueCardModal =
          !errors?.length &&
          createdUserId &&
          canInviterIssueCard &&
          values.issueCreditCard;

        if (canNavigateToIssueCardModal) {
          onOpenIssueCardModal(createdUserId);
        }
      } catch (e) {
        error(t(`${(e as Error).message}`));

        return e;
      }

      return;
    },
    [
      canInviterIssueCard,
      closeDrawer,
      createUser,
      error,
      history,
      onOpenIssueCardModal,
      organizationSlug,
      path,
      success,
      t,
      url,
    ]
  );

  const handleSubmitSsoUser = useCallback(
    async (values: TeamMemberFormOutput) => {
      const input = sanitizeInviteFormData(values);

      // TODO Use optimistic UI updates
      try {
        const result = await createUserSSO({
          variables: { input },
          awaitRefetchQueries: true,
          refetchQueries: [
            'organizationMemberships',
            { query: organizationMembershipCountByStatusQuery },
            {
              query: getOrganizationMembershipsQuery,
              variables: membershipsQueryFilter.ActiveAndPending,
            },
            {
              query: getMemberships,
              variables: membershipsQueryFilter.Active,
            },
            {
              query: getApproversQuery,
              variables: { name: '' },
            },
          ],
        });

        const nameAndEmail = getUserInfo(values);
        const pathname = generateTeamMembersPath({
          organizationSlug,
          path,
          url,
          userId: result.data?.createUserSSO?.id,
        });

        const content = (
          <ToastMessage
            history={history}
            nameAndEmail={nameAndEmail}
            pathname={pathname}
            status={LocalMembershipStatus.PENDING}
            translationKey="settings.teamMembers.actions.inviteSent"
          />
        );

        success(content);

        closeDrawer();
        const createdUserId = result.data?.createUserSSO?.id;
        const { errors } = result;
        const canNavigateToIssueCardModal =
          !errors?.length &&
          createdUserId &&
          canInviterIssueCard &&
          values.issueCreditCard;

        if (canNavigateToIssueCardModal) {
          onOpenIssueCardModal(createdUserId);
        }
      } catch (e) {
        error(t(`${(e as Error).message}`));

        return e;
      }

      return;
    },
    [
      canInviterIssueCard,
      closeDrawer,
      createUserSSO,
      error,
      history,
      onOpenIssueCardModal,
      organizationSlug,
      path,
      success,
      t,
      url,
    ]
  );

  const initialValues: TeamMemberFormValues = {
    firstName: '',
    lastName: '',
    locale: organizationLanguage ?? Locale.De,
    email: '',
    roles: [],
    issueCreditCard: canInviterIssueCard,
    teams: [],
  };

  const hide = {
    roles: sso.provides.roles,
    firstName: sso.provides.firstName,
    lastName: sso.provides.lastName,
  };

  const context: TeamMemberFormSchemaOptions = {
    allUserEmails: allEmails,
    hiddenFields: hide,
  };

  const form = useForm<TeamMemberFormOutput>({
    mode: 'onBlur',
    context,
    defaultValues: initialValues,
    resolver: zodResolver({
      zodSchema: teamMemberFormSchema,
      errorMessages: teamMemberFormErrorMessages,
    }),
  });

  const teamFieldOptions = {
    isLoading: isLoadingTeamOptions,
    onInputChange: handleDebounceSearch,
    onEndReached: loadMore,
    defaultItems: teamOptions,
    onChange: handleSetSelectedTeams,
    selectedOptions: selectedMappedTeams,
  };

  const isTeamsFieldHidden = !isTeamsFeatureEnabled;

  return (
    <FormProvider {...form}>
      <DrawerLayout
        onClose={closeDrawer}
        header={
          <Heading as="h3">
            {isTeamsFeatureEnabled
              ? t('settings.users.details.tabs.invite')
              : t('settings.teamMembers.details.tabs.invite')}
          </Heading>
        }
        footer={
          <Button
            form={INVITE_TEAM_MEMBER_FORM_ID}
            type="submit"
            loading={isSubmittingCreateUser || isSubmittingCreateUserSSO}
            disabled={
              loading || isSubmittingCreateUser || isSubmittingCreateUserSSO
            }
          >
            {t('settings.teamMembers.form.actions.invite')}
          </Button>
        }
      >
        <TeamMemberDetails
          hide={{
            firstName: sso.provides.firstName,
            lastName: sso.provides.lastName,
            teams: isTeamsFieldHidden,
          }}
          onSubmit={sso.isActive ? handleSubmitSsoUser : handleSubmit}
          formId={INVITE_TEAM_MEMBER_FORM_ID}
          canInviterIssueCard={canInviterIssueCard}
          teamFieldOptions={teamFieldOptions}
        />
      </DrawerLayout>
    </FormProvider>
  );
};

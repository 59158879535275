import { useTheme } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { InfoPopupButton } from 'components/Icons/InfoPopupButton';
import { useTranslation } from 'react-i18next';

interface ReadonlyEmailFieldProps {
  fieldName: string;
}

/**
 * Displays the email as read only in the form with a tooltip.
 * Needs to be mounted within a ReactHookForm context.
 */
export const ReadonlyEmailField = ({ fieldName }: ReadonlyEmailFieldProps) => {
  const [t] = useTranslation();

  const { colors } = useTheme();

  return (
    <>
      <HookFormTextField
        name={fieldName}
        label={t('settings.teamMembers.form.email.label')}
        inputProps={{
          type: 'email',
          style: {
            color: colors.gray500, // TODO this should be possible from top lvl prop
          },
        }}
        disabled={true}
        readOnly={true}
      />
      <div className="pt-4">
        <InfoPopupButton
          message={t('settings.teamMembers.form.email.description')}
        />
      </div>
    </>
  );
};

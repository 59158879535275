import { useGeneralLedgerAccountFieldOptions } from 'components/Form/hooks/useGeneralLedgerAccountFieldOptions';
import {
  useShowCostCenterField,
  useShowCostObjectField,
  useShowGeneralLedgerAccountField,
  useShowProjectCodeField,
  useShowTaxCodeField,
} from 'views/utils/useShouldShowField';
import { BookingsFormProps, BookingsFormSelectCellOptions } from '../types';
import { useMemo } from 'react';
import { useCostCenterFieldOptions } from 'components/Form/hooks/useCostCenterFieldOptions';
import {
  CostCenterTypes,
  useProcessingFormFieldItemsQuery,
} from 'generated-types/graphql.types';
import { useProjectCodeFieldOptions } from 'components/Form/hooks/useGetProjectCodeFieldOptions';
import { toAccountingDataFieldItem } from 'views/Inbox/DocumentProcessing/toAccountingDataFieldItem';

export const useSelectCellProps = ({
  defaultValues,
}: {
  defaultValues: BookingsFormProps['initialValues'];
}): BookingsFormSelectCellOptions => {
  const showGeneralLedgerAccountField = useShowGeneralLedgerAccountField();
  const showCostCenterField = useShowCostCenterField();
  const showCostObjectField = useShowCostObjectField();
  const showProjectCodeField = useShowProjectCodeField();
  const showTaxCodeField = useShowTaxCodeField();

  const defaultGLAs = (defaultValues?.bookings || [])
    .map(b => b?.generalLedgerAccount?.value)
    .filter(Boolean) as string[];

  const generalLedgerAccountFieldOptions = useGeneralLedgerAccountFieldOptions({
    skip: !showGeneralLedgerAccountField,
    ids:
      !showGeneralLedgerAccountField && defaultGLAs.length
        ? defaultGLAs
        : undefined,
  });

  const defaultCCs = (defaultValues?.bookings || [])
    .map(b => b?.costCenter?.value)
    .filter(Boolean) as string[];

  const costCenterFieldOptions = useCostCenterFieldOptions({
    skip: !showCostCenterField,
    type: CostCenterTypes.CostCenter,
    ids: !showCostCenterField && defaultCCs.length ? defaultCCs : undefined,
  });

  const defaultCOs = (defaultValues?.bookings || [])
    .map(b => b?.costObject?.value)
    .filter(Boolean) as string[];

  const costObjectFieldOptions = useCostCenterFieldOptions({
    skip: !showCostObjectField,
    type: CostCenterTypes.CostObject,
    ids: !showCostObjectField && defaultCOs.length ? defaultCOs : undefined,
  });

  const defaultProjectCodes = (defaultValues?.bookings || [])
    .map(b => b?.projectCode?.value)
    .filter(Boolean) as string[];
  const projectCodeFieldOptions = useProjectCodeFieldOptions({
    skip: !showProjectCodeField,
    ids:
      !showProjectCodeField && defaultProjectCodes.length
        ? defaultProjectCodes
        : undefined,
  });

  const { data: fieldItemsData, loading } = useProcessingFormFieldItemsQuery();
  const { taxCodes = [] } = fieldItemsData ?? {};

  const taxCodeOptions = useMemo(() => {
    if (!showTaxCodeField && !defaultValues?.bookings?.[0]?.taxCode) {
      return [];
    }
    return taxCodes.map(toAccountingDataFieldItem);
  }, [taxCodes, showTaxCodeField, defaultValues]);

  return useMemo(
    () => ({
      generalLedgerAccount: generalLedgerAccountFieldOptions?.props ?? {},
      costCenter: costCenterFieldOptions?.props ?? {},
      costObject: costObjectFieldOptions?.props ?? {},
      projectCode: projectCodeFieldOptions?.props ?? {},
      taxCode: {
        items: taxCodeOptions,
        disabledKeys: [], // Add DATEV disabled keys here
        onEndReached: undefined,
      },
    }),
    [
      generalLedgerAccountFieldOptions,
      costCenterFieldOptions,
      costObjectFieldOptions,
      projectCodeFieldOptions,
      taxCodeOptions,
    ]
  );
};

import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { PerDiemDetailsCard } from './PerDiemDetailsCard';
import { ExpenseDetailsCard } from './ExpenseDetailsCard';
import { ReimbursementItemType } from 'generated-types/graphql.types';

interface ReimbursementItemSummaryContainerProps {
  totalAmount?: string;
  reimbursementItems: ReimbursementItemForList[];
}

export const ReimbursementItemSummaryContainer = ({
  totalAmount,
  reimbursementItems,
}: ReimbursementItemSummaryContainerProps) => {
  const { canUsePerDiemReimbursement } = useReimbursement();

  const filteredReimbursementItems = reimbursementItems.filter(item =>
    [
      ReimbursementItemType.GeneralExpense,
      ReimbursementItemType.HospitalityExpense,
    ].includes(item.type)
  );

  const expenseDetailsProps = {
    totalAmount,
    expenseCount: filteredReimbursementItems.length ?? 0,
    reimbursementItems: filteredReimbursementItems,
  };

  return (
    <div className="grid gap-4">
      <ExpenseDetailsCard {...expenseDetailsProps} />
      {canUsePerDiemReimbursement && (
        <PerDiemDetailsCard reimbursementItems={reimbursementItems} />
      )}
    </div>
  );
};

import VisaLogo from 'containers/credit-cards/CreditCard/media/visa-black.svg';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentsTableData } from '../../types';
import { CellContext } from '@tanstack/react-table';

export const CreditCardPayment = ({
  getValue,
}: CellContext<DocumentsTableData, boolean | undefined>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const value = getValue();
  if (!value) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  return <img alt="Visa" src={VisaLogo} />;
};

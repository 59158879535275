import { Card, Grid } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from 'utils/zodFormValidation';
import { BankAccount } from './BankAccount/BankAccount';
import { BillingAddress } from './BillingAddress';
import { BillingEmailAddress } from './BillingEmailAddress';
import { DeliveryAddress } from './DeliveryAddress/DeliveryAddress';
import { NationalSettings } from './NationalSettings';
import { TradeName } from './TradeName';
import { VatIdFields } from './VatIdFields/VatIdFields';
import { ReadOnlySettings } from './hooks/useGetOrganizationSettings';
import { organizationSettingsFormErrorMessages } from './utils/organizationSettingsFormErrorMessages';
import {
  OrganizationSettingsFormValues,
  organizationSettingsFormSchema,
} from './utils/organizationSettingsFormSchema';

export interface SettingsFormProps {
  formId: string;
  defaultValues: OrganizationSettingsFormValues;
  readOnlySettings: ReadOnlySettings;
  onSubmit: (formData: OrganizationSettingsFormValues) => Promise<void>;
}

export const SettingsForm = ({
  defaultValues,
  formId,
  readOnlySettings,
  onSubmit,
}: SettingsFormProps) => {
  const form = useForm<OrganizationSettingsFormValues>({
    defaultValues,
    // We need to set values in order for the fields to be update when re-fetching the query
    // after saving
    values: defaultValues,
    mode: 'onBlur',
    resolver: zodResolver({
      zodSchema: organizationSettingsFormSchema,
      errorMessages: organizationSettingsFormErrorMessages,
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
    }),
  });

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        gap="space16"
        id={formId}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Card background="gray100">
          <Grid gap="space24">
            <Grid columns={2}>
              <BillingAddress {...readOnlySettings.billingAddress} />
              <NationalSettings
                nationalRegisterCourt={readOnlySettings.nationalRegisterCourt}
                nationalRegisterId={readOnlySettings.nationalRegisterId}
                nationalRegisterType={readOnlySettings.nationalRegisterType}
              />
            </Grid>
            <TradeName />
            <BillingEmailAddress />
            <VatIdFields />
            <DeliveryAddress />
          </Grid>
        </Card>
        <BankAccount {...readOnlySettings.processingAccount} />
      </Grid>
    </FormProvider>
  );
};

import { useToastMessage } from 'components/Toast/useToastMessage';
import { useCreateZipFileForDocumentsMutation } from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { useComputeQueryFilters } from 'views/Archive/ArchiveDocumentsTable/hooks/useComputeQueryFilters';
import { refetchDocumentDataFileRecords } from 'views/Archive/Documents/queries';
import { getArchiveDocumentZipDownloadErrorMessage } from 'views/Archive/Documents/utils';
import { useOrganizationData } from 'views/Settings/Organization/hooks/useOrganizationData';

interface UseDownloadPDFFileParams {
  isTableLoading: boolean;
  handleCloseInvoicesDownloadModal: () => void;
}

export const useDownloadInvoiceFiles = ({
  isTableLoading,
  handleCloseInvoicesDownloadModal,
}: UseDownloadPDFFileParams) => {
  const [t] = useTranslation();
  const { success, error, info } = useToastMessage();

  const history = useHistory();

  const [
    triggerCreateZipFileForDocuments,
    { loading: createOriginalFileZipFileLoading },
  ] = useCreateZipFileForDocumentsMutation();

  const { organizationSlug } = useOrganizationData();
  const { filters, dateRangeFilters, query, searchInput } =
    useComputeQueryFilters();

  const onPrepareDownloadInvoices = useCallback(async () => {
    if (isTableLoading) {
      info(t('archive:download.waitForLoading'));
    }

    const results = await triggerCreateZipFileForDocuments({
      refetchQueries: refetchDocumentDataFileRecords,
      variables: {
        dateRangeFilters,
        filters,
        query,
        searchInput,
      },
    });

    const data = results.data?.createPDFZipFileForDocuments;

    const type = data?.__typename;
    const errorType =
      type === 'DownloadDocumentPDFsError' ? data?.kind : undefined;

    if (type === 'DownloadDocumentPDFsResult') {
      handleCloseInvoicesDownloadModal();
      success(t('archive:download.pdFZipDownloadInitiated'));

      history.push(
        `/${organizationSlug}${Routes.DOCUMENT_DATA_DOWNLOAD_HISTORY}${window.location.search}`
      );

      return;
    }

    if (errorType) {
      const errorMessageTranslationKey =
        getArchiveDocumentZipDownloadErrorMessage({
          errorType,
        });

      error(t(errorMessageTranslationKey));
    }
  }, [
    isTableLoading,
    triggerCreateZipFileForDocuments,
    dateRangeFilters,
    filters,
    query,
    searchInput,
    info,
    t,
    handleCloseInvoicesDownloadModal,
    success,
    error,
    history,
    organizationSlug,
  ]);

  return {
    createOriginalFileZipFileLoading,
    onPrepareDownloadInvoices,
  };
};

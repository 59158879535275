import {
  Input as TextInput,
  mergeProps,
  useLabel,
} from '@candisio/design-system';
import { identity, isEmpty } from 'lodash';
import { ComponentProps, FocusEventHandler, ReactNode } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface HookFormTextInputProps<TFormValues extends FieldValues> {
  /** `control` prop returned by `useForm` hook */
  control?: UseControllerProps<TFormValues>['control'];
  /** Field name */
  name: UseControllerProps<TFormValues>['name'];
  /** Is field disabled? */
  disabled?: boolean;
  /** Custom props for input */
  inputProps?: ComponentProps<typeof TextInput>;
  /** Should field receive focus on mount? */
  autoFocus?: boolean;
  /** Field label */
  label?: string | ReactNode;
  /** Field label in case label is not just text */
  ariaLabel?: string;
  /** Placeholder text shown when no value is set */
  placeholder?: string;
  /** Is field read only? */
  readOnly?: boolean;
  /** Message to display in tooltip */
  message?: ReactNode;
  /** Called when field value changes */
  onChange?: (newValue: string | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  /** Field variant */
  variant?: 'default' | 'error' | 'warning' | 'success';
  /** Max number of chars inside the field */
  maxLength?: number;
  /** Transform functions for field input (`value`) and output (`onChange`) */
  transform?: {
    input: (value: string | null) => string | null;
    output: (value: string | null) => string | null;
  };
  clearable?: boolean;
  ignorePasswordManager?: boolean;
  dataCy?: string;
}

/**
 * Controlled text field for React Hook Form
 *
 * To connect to your form you must either:
 * - ensure the field is inside a `FormProvider`, or
 * - explicitly pass the `control` prop returned by `useForm`
 */
export const HookFormTextInput = <TFormValues extends FieldValues>({
  autoFocus,
  control,
  disabled,
  label,
  ariaLabel,
  message,
  name,
  onChange: onChangeProp,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  placeholder,
  readOnly: readOnlyProp,
  variant,
  inputProps,
  maxLength,
  transform = { input: identity, output: identity },
  clearable = true,
  ignorePasswordManager,
  dataCy,
}: HookFormTextInputProps<TFormValues>) => {
  const [t] = useTranslation();
  const { field, fieldState, formState } = useController({ control, name });
  const { onChange, onBlur, value, ...fieldProps } = field;

  const labelInput = typeof label === 'string' && label ? label : name;

  const { fieldProps: labelFieldProps } = useLabel({
    label: labelInput,
    'aria-label': ariaLabel ?? labelInput,
  });

  const errorMessage = fieldState.error?.message;
  const hasError = errorMessage !== undefined;
  const readOnly = readOnlyProp || formState.isSubmitting;

  const handleFocus: FocusEventHandler<HTMLInputElement> = event => {
    event.target.select();
    onFocusProp?.();
  };

  return (
    <TextInput
      autoFocus={autoFocus}
      disabled={disabled}
      onChange={e => {
        const newValue =
          e.target.value !== '' ? transform.output(e.target.value) : null;

        onChange(newValue);
        onChangeProp?.(newValue);
      }}
      placeholder={readOnly ? '–' : placeholder}
      data-cy={dataCy}
      readOnly={readOnly}
      maxLength={maxLength}
      value={transform.input(value) ?? ''}
      variant={hasError ? 'error' : variant}
      message={errorMessage ?? message}
      clearable={clearable && !isEmpty(value)}
      clearLabel={t('common.clear')}
      onClear={() => {
        onChange(null);
        onChangeProp?.(null);
      }}
      onFocus={handleFocus}
      showMessageOnFocus={hasError || variant === 'error'}
      ignorePasswordManager={ignorePasswordManager}
      onBlur={() => {
        onBlur();
        onBlurProp?.();
      }}
      {...(inputProps
        ? mergeProps(fieldProps, inputProps, labelFieldProps)
        : mergeProps(fieldProps, labelFieldProps))}
    />
  );
};

import {
  Box,
  Button,
  Flex,
  Grid,
  Item,
  ListView,
  MenuButton,
  MenuItem,
} from '@candisio/design-system';
import { LottieAnimation, noresultsData } from 'components/Lottie/Lottie';
import { SearchField } from 'components/SearchField/SearchField';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { get } from 'lodash';
import { Variants, motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { OrganizationHistoryService } from 'services/OrganizationHistoryService';
import { escapeRegex } from 'utils/regex';
import { OrganizationItem } from './OrganizationItem';
import { OrganizationSkeleton } from './OrganizationSkeleton';
import {
  SortedOrganization,
  useOrganizationSummary,
} from './useOrganizationSummary';

const ALPHABETICALLY = 'alphabetically';
const BY_PRIORITY = 'by-priority';
const RECENTLY_USED = 'recently-used';
export const ALL = 'all';
export const INBOX = 'inbox';
export const APPROVALS = 'approvals';
export const EXPORT = 'export';

type SortBy = 'alphabetically' | 'by-priority' | 'recently-used';
export type FilterBy = 'all' | 'inbox' | 'approvals' | 'export';

interface Filter {
  value: FilterBy;
  label: string;
  totalOrganizations: number | undefined;
}

const MotionFlex = motion.create(Flex);
const MotionGrid = motion.create(Grid);
const MotionListView = motion.create(ListView);

const sortOrganizations = (
  organizations: SortedOrganization[],
  sortBy: SortBy
) => {
  switch (sortBy) {
    case ALPHABETICALLY: {
      const collator = new Intl.Collator();

      return [...(organizations || [])]?.sort(
        (a: SortedOrganization, b: SortedOrganization) =>
          collator.compare(get(a, 'title'), get(b, 'title'))
      );
    }

    case BY_PRIORITY: {
      return [...(organizations || [])]?.sort(
        (a: SortedOrganization, b: SortedOrganization) =>
          b.overdueCount - a.taskCount
      );
    }

    case RECENTLY_USED: {
      const organizationSortOrder =
        OrganizationHistoryService.getStoredOrganizations();

      const filteredOrganizations = [...(organizations || [])]?.filter(
        (organization: SortedOrganization) =>
          organizationSortOrder.includes(organization.id)
      );

      const recentOrganizations: SortedOrganization[] = [];

      organizationSortOrder.forEach(item => {
        filteredOrganizations.forEach(organization => {
          if (item === organization.id) {
            recentOrganizations.push(organization);
          }
        });
      });

      organizations?.sort(
        (a: SortedOrganization, b: SortedOrganization): number => {
          if (a.id < b.id) {
            return -1;
          }

          if (a.id > b.id) {
            return 1;
          }

          return 0;
        }
      );

      const otherOrganizations = [...(organizations || [])]?.filter(
        (organization: SortedOrganization) =>
          !organizationSortOrder.includes(organization.id)
      );

      return recentOrganizations.concat(otherOrganizations);
    }

    default: {
      return organizations;
    }
  }
};

const filterOrganizations = (
  organizations: SortedOrganization[],
  filterBy: FilterBy
) => {
  switch (filterBy) {
    case INBOX:
      return organizations.filter(
        organization =>
          organization?.toProcessTotal && organization?.toProcessTotal > 0
      );

    case APPROVALS:
      return organizations.filter(
        organization =>
          organization?.toApproveTotal && organization?.toApproveTotal > 0
      );

    case EXPORT:
      return organizations.filter(
        organization =>
          organization?.toExportTotal && organization?.toExportTotal > 0
      );

    default:
      return organizations;
  }
};

interface OrganizationsListViewProps {
  handleCurrentOrganization: () => void;
}

export const OrganizationsListView = ({
  handleCurrentOrganization,
}: OrganizationsListViewProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const { organizations, loading } = useOrganizationSummary();
  const history = useHistory();
  const [queryString, setQueryString] = useState('');

  const [filterBy, setFilterBy] = useLocalStorage<FilterBy>(
    'organizationSwitcherCurrentFilterBy',
    ALL
  );

  const [sortBy, setSortBy] = useLocalStorage<SortBy>(
    'organizationSwitcherCurrentSortType',
    ALPHABETICALLY
  );

  const menuButtonItems: MenuItem[] = [
    {
      id: ALPHABETICALLY,
      label: t('multiOrganization.sortBy.alphabetically'),
      onAction: () => setSortBy(ALPHABETICALLY),
    },
    {
      id: BY_PRIORITY,
      label: t('multiOrganization.sortBy.urgency'),
      onAction: () => setSortBy(BY_PRIORITY),
    },
    {
      id: RECENTLY_USED,
      label: t('multiOrganization.sortBy.recent'),
      onAction: () => setSortBy(RECENTLY_USED),
    },
  ];

  const totalOrganizations = (filterBy: FilterBy) => {
    const totalOrganizations = filterOrganizations(
      organizations as SortedOrganization[],
      filterBy
    );

    return totalOrganizations.length;
  };

  const filters: Filter[] = [
    {
      value: ALL,
      label: t('multiOrganization.filterBy.all'),
      totalOrganizations: organizations && organizations?.length,
    },
    {
      value: INBOX,
      label: t('multiOrganization.filterBy.inbox'),
      totalOrganizations: organizations && totalOrganizations(INBOX),
    },
    {
      value: APPROVALS,
      label: t('multiOrganization.filterBy.approvals'),
      totalOrganizations: organizations && totalOrganizations(APPROVALS),
    },
    {
      value: EXPORT,
      label: t('multiOrganization.filterBy.export'),
      totalOrganizations: organizations && totalOrganizations(EXPORT),
    },
  ];

  const sortedOrganizationlist = sortOrganizations(
    organizations as SortedOrganization[],
    sortBy
  );

  const filteredOrganizations = filterOrganizations(
    sortedOrganizationlist,
    filterBy
  );

  const queriedOrganizations = filteredOrganizations?.filter(organization =>
    organization.title.match(new RegExp(escapeRegex(queryString), 'i'))
  );

  const listViewVariants: Variants = {
    hidden: { opacity: 0, translateX: -5 },
    show: {
      opacity: 1,
      translateX: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  const showSearchbar = organizations ? organizations.length > 4 : false;
  const showFilters = organizations ? organizations.length > 2 : false;

  const renderFilterLabel = (filter: Filter) => {
    if (filter.totalOrganizations && filter.totalOrganizations > 0) {
      return `${filter.label} (${filter.totalOrganizations})`;
    }

    return filter.label;
  };

  return (
    <Grid>
      <Box
        position="sticky"
        top="0"
        background="gray0"
        zIndex="1"
        borderRadius="medium medium 0 0"
      >
        {showSearchbar ? (
          <>
            <MotionGrid
              gap="space16"
              padding="space16"
              templateColumns="1fr auto"
              autoFlow="column"
              justifyContent="start"
              alignItems="center"
              variants={listViewVariants}
              initial="hidden"
              animate="show"
            >
              <SearchField
                placeholder={t('multiOrganization.searchPlaceholder')}
                initialValue={queryString}
                onChange={setQueryString}
                autoFocus
              />
              <MenuButton
                icon="sortInactive"
                items={menuButtonItems}
                label={t('multiOrganization.filterLabel')}
                value={[sortBy]}
                variant="tertiary"
              />
            </MotionGrid>
          </>
        ) : null}
        {showFilters ? (
          <MotionFlex
            padding={!showSearchbar ? 'space16' : '0 space16 space16'}
            gap="space10"
            variants={listViewVariants}
            initial="hidden"
            animate="show"
          >
            {filters.map((filter, index) => (
              <Button
                key={index}
                variant="secondary"
                size="small"
                isPressed={filter.value === filterBy}
                onClick={() => setFilterBy(filter.value)}
              >
                {renderFilterLabel(filter)}
              </Button>
            ))}
          </MotionFlex>
        ) : null}
      </Box>
      <Grid
        background="gray0"
        paddingY="space16"
        borderRadius={showFilters ? '0 0 medium medium' : 'medium'}
        overflow="hidden"
      >
        {loading ? (
          <OrganizationSkeleton />
        ) : queriedOrganizations?.length ? (
          <MotionListView
            onAction={slug => {
              const { pathname: currentSlug } = history.location;
              const isCurrentOrganization = currentSlug.split('/')[1] === slug;

              if (isCurrentOrganization) {
                handleCurrentOrganization();
              } else {
                history.push(`/${slug}`);
              }
            }}
            variants={listViewVariants}
            initial="hidden"
            animate="show"
          >
            {queriedOrganizations.map(organization => (
              <Item key={organization.id} textValue={organization.title}>
                <OrganizationItem
                  organization={organization}
                  queryString={queryString}
                  filterBy={filterBy}
                />
              </Item>
            ))}
          </MotionListView>
        ) : (
          <Grid padding="space18">
            <LottieAnimation
              height="80px"
              width="80px"
              options={{ animationData: noresultsData }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

import {
  Button,
  Flex,
  Icon,
  IconKey,
  Skeleton,
  TruncatedText,
} from '@candisio/design-system';
import { DOCUMENT_QUERY_PROPS } from 'components/DocumentsTable/Filters/filters';
import { CheckmarkIcon } from 'components/Icons/DefaultIcons';
import { useNewDashboardFF } from 'components/NewPromotions/hooks/useNewDashboardFF';
import { useInboxTransactionCount } from 'hooks/useCounterQueries';
import { Routes, compiledRoutes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useCardholderId } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { useOrganizationReimbursementTasks } from 'views/Dashboard/hooks/useOrganizationReimbursementTasks';
import { TRANSACTION_COLUMNS } from 'views/Inbox/Transactions/useTransactionListFilters';
import { OrganizationDetailProps, OrganizationDetailsProps } from './types';

const LOADING_BUTTON_HEIGHT = '36px';
const LOADING_BUTTON_WIDTH = '58px';

export const renderIcon = (icon: IconKey, color?: string) => (
  <Icon icon={icon} color={color ? color : 'gray800'} size="space16" />
);

const OverDueIcon = renderIcon('clock', 'red700');
const ApproveIcon = renderIcon('approvals');
const MonitorIcon = renderIcon('view');
const ExportIcon = renderIcon('exports');
const ProcessIcon = renderIcon('inbox');
const TransactionsIcon = renderIcon('payments');

const isTaskEmpty = (count?: number) => (count ?? 0) === 0;

const OrganizationDetailsLoader = () => {
  return (
    <>
      {[1, 2].map(i => (
        <Skeleton
          key={i}
          height={LOADING_BUTTON_HEIGHT}
          width={LOADING_BUTTON_WIDTH}
        />
      ))}
    </>
  );
};

export const OrganizationDetail = ({
  loading,
  title,
  counts: { overdue, total, transactions, reimbursements },
}: OrganizationDetailProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const areAllTasksCompleted =
    isTaskEmpty(total?.count) &&
    isTaskEmpty(transactions?.count) &&
    isTaskEmpty(reimbursements?.count);

  const overdueCounter = overdue?.count ?? 0;
  const totalCounter = total?.count ?? 0;
  const transactionsCounter = transactions?.count ?? 0;
  const reimbursementsCount = reimbursements?.count ?? 0;

  const { showNewDashboardPromotions } = useNewDashboardFF();

  const newDashboardStyle = showNewDashboardPromotions
    ? {
        background: 'gray0',
        padding: 'space16',
        width: '200px',
        borderRadius: 'medium',
        border: '1px solid gray300',
      }
    : {};

  return (
    <Flex direction="column" gap="space8" {...newDashboardStyle}>
      <span className="text-basic font-medium text-gray-800">{title}</span>

      {/* Icon 'checkCircle' and Button parent Flex should have the same height to ensure
          they're vertically aligned. If we change the size of Button, this height should
          also be updated to match the size of the bigger element of these 2 */}
      <Flex gap="space4" height="36px" alignItems="center">
        {loading ? (
          <OrganizationDetailsLoader />
        ) : areAllTasksCompleted ? (
          <>
            <CheckmarkIcon size="space24" />
            <Flex>{t('organizationTaskPanel.allTaskCompleted')}</Flex>
          </>
        ) : (
          <>
            {overdueCounter > 0 && (
              <Button
                label={overdue?.label}
                size="small"
                variant="secondary"
                color="red"
                onClick={overdue?.onClick}
              >
                <Flex justifyContent="center" gap="space4" alignItems="center">
                  <TruncatedText
                    maxWidth="4.5ch"
                    whiteSpace="normal"
                    overflowWrap="break-word"
                  >
                    {overdueCounter.toString()}
                  </TruncatedText>
                  {overdue?.icon}
                </Flex>
              </Button>
            )}
            {totalCounter > 0 && (
              <Button
                label={total?.label}
                size="small"
                variant="secondary"
                onClick={total?.onClick}
              >
                <Flex justifyContent="center" gap="space4" alignItems="center">
                  <TruncatedText
                    maxWidth="4.5ch"
                    whiteSpace="normal"
                    overflowWrap="break-word"
                  >
                    {totalCounter.toString()}
                  </TruncatedText>
                  {total?.icon}
                </Flex>
              </Button>
            )}

            {transactions && transactionsCounter > 0 && (
              <Button
                label={transactions.label}
                size="small"
                variant="secondary"
                onClick={transactions?.onClick}
              >
                <Flex justifyContent="center" gap="space4" alignItems="center">
                  <TruncatedText
                    maxWidth="4.5ch"
                    whiteSpace="normal"
                    overflowWrap="break-word"
                  >
                    {transactionsCounter.toString()}
                  </TruncatedText>
                  {transactions?.icon}
                </Flex>
              </Button>
            )}

            {reimbursements && reimbursementsCount > 0 && (
              <Button
                label={reimbursements.label}
                size="small"
                variant="secondary"
                onClick={reimbursements?.onClick}
              >
                <Flex justifyContent="center" gap="space4" alignItems="center">
                  <TruncatedText
                    maxWidth="4.5ch"
                    whiteSpace="normal"
                    overflowWrap="break-word"
                  >
                    {reimbursementsCount.toString()}
                  </TruncatedText>
                  {reimbursements?.icon}
                </Flex>
              </Button>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export const OrganizationDetails = ({
  loading,
  membershipId,
  onClickTask,
  organizationId,
  showTransactionCounts,
  taskBreakdown,
}: OrganizationDetailsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const creditCardsSetup = useCreditCardsSetup();
  const { showNewDashboardPromotions } = useNewDashboardFF();
  const { cardholderId } = useCardholderId();
  const { canUseReimbursement } = useReimbursement();

  const { reimbursementTasks } = useOrganizationReimbursementTasks();

  const { inboxTransactionCount: cardholderInboxTransactionCount } =
    useInboxTransactionCount({
      cardholderIds: cardholderId ? [cardholderId] : undefined,
      skip: !cardholderId,
      fetchPolicy: 'cache-and-network',
    });

  const { inboxTransactionCount: totalInboxTransactionCount } =
    useInboxTransactionCount({
      skip: !creditCardsSetup.isInUse,
      fetchPolicy: 'cache-and-network',
    });

  const processingTitle = 'multiOrganization.table.taskBreakdown.inbox.title';

  const tasks: OrganizationDetailProps[] = [
    {
      title: t(processingTitle),
      counts: {
        overdue: {
          count: taskBreakdown?.toProcess?.overdue,
          label: t('multiOrganization.table.taskBreakdown.inbox.overdue'),
          icon: OverDueIcon,
          onClick: () => {
            onClickTask(
              `/${organizationId}${Routes.INBOX}?${qs.stringify({
                [DOCUMENT_QUERY_PROPS.status]: 'OVERDUE',
              })}`
            );
          },
        },
        total: {
          count: taskBreakdown?.toProcess?.total,
          label: t('multiOrganization.table.taskBreakdown.inbox.total'),
          icon: ProcessIcon,
          onClick: () => {
            onClickTask(`/${organizationId}${Routes.INBOX}`);
          },
        },
        ...(creditCardsSetup.isInUse &&
          showTransactionCounts &&
          cardholderId && {
            transactions: {
              count: cardholderInboxTransactionCount,
              label: t(
                'multiOrganization.table.taskBreakdown.inbox.transactions'
              ),
              icon: TransactionsIcon,
              onClick: () => {
                onClickTask(
                  `/${organizationId}${compiledRoutes.inboxTransactions}?${TRANSACTION_COLUMNS.cardholderName}=${cardholderId}`
                );
              },
            },
          }),
        ...(canUseReimbursement && {
          reimbursements: {
            count: reimbursementTasks.inbox.total,
            label: t(
              'multiOrganization.table.taskBreakdown.inbox.reimbursements'
            ),
            icon: reimbursementTasks.inbox.icon,
            onClick: () => {
              onClickTask(reimbursementTasks.inbox.path);
            },
          },
        }),
      },
    },
    {
      title: t('multiOrganization.table.taskBreakdown.monitoring.title'),
      counts: {
        overdue: {
          count: taskBreakdown?.monitoringMyRequests?.toApprove?.overdue,
          label: t('multiOrganization.table.taskBreakdown.monitoring.overdue'),
          icon: OverDueIcon,
          onClick: () => {
            onClickTask(
              `/${organizationId}${Routes.APPROVALS}?${qs.stringify({
                [DOCUMENT_QUERY_PROPS.status]: 'OVERDUE',
                [DOCUMENT_QUERY_PROPS.requester]: membershipId,
              })}`
            );
          },
        },
        total: {
          count: taskBreakdown?.monitoringMyRequests?.toApprove?.total,
          label: t('multiOrganization.table.taskBreakdown.monitoring.total'),
          icon: MonitorIcon,
          onClick: () => {
            onClickTask(
              `/${organizationId}${Routes.APPROVALS}?${qs.stringify({
                [DOCUMENT_QUERY_PROPS.requester]: membershipId,
              })}`
            );
          },
        },
        ...(creditCardsSetup.isInUse &&
          showTransactionCounts && {
            transactions: {
              count: totalInboxTransactionCount,
              label: t(
                'multiOrganization.table.taskBreakdown.inbox.transactions'
              ),
              icon: TransactionsIcon,
              onClick: () => {
                onClickTask(
                  `/${organizationId}${compiledRoutes.inboxTransactions}`
                );
              },
            },
          }),
        ...(canUseReimbursement && {
          reimbursements: {
            count: reimbursementTasks.monitoring.total,
            label: t(
              'multiOrganization.table.taskBreakdown.monitoring.reimbursements'
            ),
            icon: reimbursementTasks.monitoring.icon,
            onClick: () => {
              onClickTask(reimbursementTasks.monitoring.path);
            },
          },
        }),
      },
    },
    {
      title: t('multiOrganization.table.taskBreakdown.approving.title'),
      counts: {
        overdue: {
          count: taskBreakdown?.toApprove?.overdue,
          label: t('multiOrganization.table.taskBreakdown.approving.overdue'),
          icon: OverDueIcon,
          onClick: () => {
            onClickTask(
              `/${organizationId}${Routes.APPROVALS}?${qs.stringify({
                [DOCUMENT_QUERY_PROPS.status]: 'OVERDUE',
                [DOCUMENT_QUERY_PROPS.approvers]: membershipId,
              })}`
            );
          },
        },
        total: {
          count: taskBreakdown?.toApprove?.total,
          label: t('multiOrganization.table.taskBreakdown.approving.total'),
          icon: ApproveIcon,
          onClick: () => {
            onClickTask(
              `/${organizationId}${Routes.APPROVALS}?${qs.stringify({
                [DOCUMENT_QUERY_PROPS.approvers]: membershipId,
              })}`
            );
          },
        },
        ...(canUseReimbursement && {
          reimbursements: {
            count: reimbursementTasks.approving.total,
            label: t(
              'multiOrganization.table.taskBreakdown.approving.reimbursements'
            ),
            icon: reimbursementTasks.approving.icon,
            onClick: () => {
              onClickTask(reimbursementTasks.approving.path);
            },
          },
        }),
      },
    },
    {
      title: t('multiOrganization.table.taskBreakdown.exporting.title'),
      counts: {
        overdue: {
          count: taskBreakdown?.toExport?.overdue,
          label: t('multiOrganization.table.taskBreakdown.exporting.overdue'),
          icon: OverDueIcon,
          onClick: () => {
            onClickTask(`/${organizationId}${Routes.EXPORTS}`);
          },
        },
        total: {
          count: taskBreakdown?.toExport?.total,
          label: t('multiOrganization.table.taskBreakdown.exporting.total'),
          icon: ExportIcon,
          onClick: () => {
            onClickTask(`/${organizationId}${Routes.EXPORTS}`);
          },
        },
      },
    },
  ];

  return (
    <Flex
      gap="space16"
      padding={showNewDashboardPromotions ? 'space16' : '21px space16'}
      alignItems="center"
      justifyContent={showNewDashboardPromotions ? undefined : 'space-between'}
      borderRadius="medium"
      background={showNewDashboardPromotions ? 'gray0' : 'gray50'}
      wrap="wrap"
    >
      {tasks.map(task => (
        <OrganizationDetail {...task} key={task.title} loading={loading} />
      ))}
    </Flex>
  );
};

import { extractEInvoiceErrorProps } from 'components/EInvoiceErrorDetails/utils';
import {
  EInvoiceError,
  GetEInvoiceValidationProtocolDataQuery,
} from 'generated-types/graphql.types';
import { useUpdateCurrentUserLocale } from 'hooks/useCurrentUserLocale';
import { useTranslation } from 'react-i18next';

interface EInvoiceErrorDetailsForContactSupplierProps {
  eInvoiceError?: EInvoiceError | null;
  eInvoiceErrors?: NonNullable<
    GetEInvoiceValidationProtocolDataQuery['getDocument']
  >['eInvoiceErrors'];
}

export const useEInvoiceErrorDetailsForContactSupplier = ({
  eInvoiceError,
  eInvoiceErrors,
}: EInvoiceErrorDetailsForContactSupplierProps) => {
  const [t] = useTranslation();
  const { locale: currentUserLocale } = useUpdateCurrentUserLocale();

  if (eInvoiceErrors?.length) {
    return eInvoiceErrors.length === 1 &&
      eInvoiceErrors[0].message === EInvoiceError.OutdatedFormat
      ? `<li>${t(`common:contactSupplierFromCandis.draftText.eInvoice.error.${EInvoiceError.OutdatedFormat}`)}</li>`
      : eInvoiceErrors
          ?.map(error => {
            const { code, message, location, helpCenterArticleUrl } =
              extractEInvoiceErrorProps(error, currentUserLocale);
            return `<li>
              ${code.length ? `${error.code}<br>` : ''}
              ${message.length ? `${error.message}<br>` : ''}
              ${location.length ? `${error.location}<br>` : ''}
              ${
                helpCenterArticleUrl.length
                  ? `<a href="${helpCenterArticleUrl}" target="_blank">${t(
                      'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.learnMore.helpCenterArticle.title'
                    )}</a>`
                  : ''
              }
            </li>`;
          })
          .join('');
  }

  if (eInvoiceError) {
    return `<li>${t(`common:contactSupplierFromCandis.draftText.eInvoice.error.${eInvoiceError}`)}</li>`;
  }

  return `<li> </li>`;
};

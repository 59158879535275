import { ISSUE_NEW_CARD_HASH } from 'containers/credit-cards/constants';
import { CreateCreditCardModal } from 'containers/credit-cards/CreateCreditCard/CreateCreditCardModal';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

export const CreditCardsIssueNewCardModal = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isOpen = location.hash === `#${ISSUE_NEW_CARD_HASH}`;

  const closeModal = () => {
    navigate(location.pathname);
  };

  return (
    <CreditCardsProvider>
      <CreateCreditCardModal isOpen={isOpen} onClose={closeModal} />
    </CreditCardsProvider>
  );
};

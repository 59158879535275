import { Flex, Grid, Heading, Link } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { useGetRolesForUser } from 'views/Settings/TeamMembers/hooks/useGetRolesForUser';
import { RolesField } from './RolesField';

interface RolesSectionProps {
  readOnly?: boolean;
  readOnlyReason?: string;
}

export const RolesSection = ({
  readOnly,
  readOnlyReason,
}: RolesSectionProps) => {
  const [t] = useTranslation();

  const { roles, loading: rolesLoading } = useGetRolesForUser();

  if (rolesLoading) {
    return null;
  }

  return (
    <Grid alignContent="space-between" height="100%">
      <Flex direction="column" gap="space8">
        <Flex columnGap="space16" alignItems="baseline" wrap="wrap">
          <Heading as="h3">
            {t('settings.teamMembers.form.rolesForm.heading')}
          </Heading>
          <Link
            external
            href={t('settings.teamMembers.form.rolesForm.linkUrl')}
          >
            {t('settings.teamMembers.form.rolesForm.linkText')}
          </Link>
        </Flex>

        <RolesField
          roles={roles}
          name="roles"
          isReadOnly={readOnly}
          readOnlyReason={readOnly ? readOnlyReason : undefined}
        />
      </Flex>
    </Grid>
  );
};

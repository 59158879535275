import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'components/History/items/Collapse';
import { ReimbursementCaseSubmittedForApprovalEventDataFragment } from 'generated-types/graphql.types';

export const ReimbursementCaseSubmittedForApprovalEvent = ({
  properties: { approvers },
}: ReimbursementCaseSubmittedForApprovalEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  if (!approvers?.length) return null;

  if (approvers?.length === 1 && approvers[0]) {
    return t('timeline.requestApproval', {
      name: approvers[0].name,
    });
  }

  const isLessThan10Approvers = approvers?.length < 10;

  return (
    <>
      {t('timeline.requestApproval', {
        count: approvers?.length,
      })}
      <Collapse>
        <ol className={isLessThan10Approvers ? 'pl-4' : 'pl-6'}>
          {approvers?.map((approver, index) =>
            approver ? (
              <li key={`${approver.name}-${index}`}>{approver.name}</li>
            ) : null
          )}
        </ol>
      </Collapse>
    </>
  );
};

import { useMemo } from 'react';

type BookingsColumnKey = 'actions';

type ColumnVisibility = Record<BookingsColumnKey, boolean>;

// dynamic column visibility here
export const useColumnVisibility = ({
  dataLength,
  isReadOnly,
}: { dataLength: number; isReadOnly?: boolean }) => {
  const columnVisibility: ColumnVisibility = useMemo(() => {
    return {
      // hide actions column if there is only one row:
      actions: dataLength > 1 && !isReadOnly,
    };
  }, [dataLength, isReadOnly]);
  return columnVisibility;
};

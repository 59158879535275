import {
  Button,
  Drawer,
  Flex,
  Grid,
  Item,
  Paragraph,
} from '@candisio/design-system';
import { DrawerLayout } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TopupDetails } from './TopupDetails';
import { ProcessingAccountData } from './hooks/useGetProcessingAccountDataCardManager';

export interface TopupDrawerProps {
  isOpen: boolean;
  processingAccountData: ProcessingAccountData;
  isLoading: boolean;
  onClose: () => void;
}

export const TopupDrawer = ({
  isOpen,
  isLoading,
  processingAccountData,
  onClose,
}: TopupDrawerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const displayedProcessingData = {
    ...processingAccountData,
    reference: t('prefunded.drawer.reference', {
      beneficiary: processingAccountData.beneficiary,
    }),
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerLayout onClose={onClose}>
        <Item
          key="Tab"
          title={t('prefunded.topUpButtonCTA')}
          textValue={t('prefunded.topUpButtonCTA')}
        >
          <Flex
            direction="column"
            paddingY="space16"
            height="100%"
            justifyContent="space-between"
            paddingX="space24"
          >
            <Flex direction="column" gap="space32">
              <Flex direction="column">
                <Flex direction="column" gap="space32">
                  <Paragraph fontSize="basic" fontWeight="regular">
                    {t('prefunded.drawer.para1')}
                  </Paragraph>
                </Flex>

                <TopupDetails
                  {...displayedProcessingData}
                  loading={isLoading}
                />
              </Flex>
              <Paragraph fontSize="basic" fontWeight="regular">
                {t('prefunded.drawer.para2')}
              </Paragraph>
            </Flex>

            <Grid placeItems="start" gap="space16">
              <Button onClick={onClose}>{t('prefunded.closeDrawerCTA')}</Button>
            </Grid>
          </Flex>
        </Item>
      </DrawerLayout>
    </Drawer>
  );
};

import { useTranslation } from 'react-i18next';
import { ComponentProps } from 'react';
import clsx from 'clsx';

const Rectangle = ({ className, ...props }: ComponentProps<'div'>) => (
  <div className={clsx('h-[4.72px] bg-gray-400', className)} {...props} />
);

export const DummyDocument = (props: ComponentProps<'div'>) => {
  const [t] = useTranslation();

  return (
    <div {...props}>
      {/* Main invoice card */}
      <div className="backdrop-blur-[30px] rounded-lg shadow-lg px-4 pt-8 pb-6 relative rounded-medium grid grid-rows-[auto_1fr_auto]">
        <div className="grid gap-1.75">
          <div className="grid justify-end">
            <Rectangle className="w-11" />
          </div>
          <div className="grid justify-items-end gap-0.5">
            <Rectangle className="w-6.5" />
            <Rectangle className="w-9" />
            <Rectangle className="w-6" />
            <Rectangle className="w-7" />
          </div>
          <div className="grid grid-flow-col items-center justify-between">
            <div className="text-gray-800 font-bold  text-xxsmall">
              {t('classification.promoModal.stepOne.dummyDocument.title')}
            </div>
            <div className="grid grid-flow-col gap-1.25 pt-1.25">
              <Rectangle className="w-19" />
              <Rectangle className="w-5.5" />
            </div>
          </div>
        </div>
        <div className="grid gap-1 py-2">
          <div className="pb-4">
            <Rectangle className="w-18.5" />
          </div>
          <Rectangle className="w-11" />
          <Rectangle className="w-10" />
          <Rectangle className="w-11.5" />
          <div className="grid grid-flow-col justify-start gap-[4.73px]">
            <Rectangle className="w-3" />

            <Rectangle className="w-6" />
          </div>
          <div className="grid grid-flow-col pt-5">
            <div className="flex flex-col gap-0.5">
              <Rectangle className="w-4" />
              <div className="grid gap-0.5 grid-flow-col items-center justify-start">
                <Rectangle className="w-8" />
                <Rectangle className="w-3 !h-0.5" />
                <Rectangle className="w-6" />
              </div>
              <div className="grid gap-0.5 grid-flow-col items-center justify-start">
                <Rectangle className="w-7.5" />
                <Rectangle className="w-3.5 !h-0.5" />
                <Rectangle className="w-5.5" />
              </div>
              <div className="grid gap-0.5 grid-flow-col items-center justify-start">
                <Rectangle className="w-7" />
                <Rectangle className="w-4 !h-0.5" />
                <Rectangle className="w-8" />
              </div>
              <div className="grid gap-0.5 grid-flow-col items-center justify-start">
                <Rectangle className="w-6.5" />
                <Rectangle className="w-4.5 !h-0.5" />
                <Rectangle className="w-5.5" />
              </div>
            </div>
            <div className="grid gap-1">
              <div className="grid grid-flow-col gap-1 justify-start">
                <Rectangle className="w-6" />

                <Rectangle className="w-3.5" />
              </div>

              <Rectangle className="w-full !h-0.5" />
              <Rectangle className="w-10.5" />
              <div className="grid grid-flow-col justify-end">
                <span className="text-gray-800 font-bold text-xxsmall">
                  {t('classification.promoModal.stepOne.dummyDocument.amount')}
                </span>
              </div>
              <div className="grid gap-1 w-full">
                <Rectangle className="w-20" />

                <Rectangle className="w-full !h-0.5" />
                <div className="grid gap-1">
                  <div className="grid grid-flow-col justify-between items-center">
                    <Rectangle className="w-12" />
                    <Rectangle className="w-4" />
                  </div>
                  <div className="grid grid-flow-col justify-between items-center">
                    <Rectangle className="w-9" />
                    <Rectangle className="w-4" />
                  </div>
                  <div className="grid grid-flow-col justify-between items-center">
                    <Rectangle className="w-11" />
                    <Rectangle className="w-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1 py-4">
            <Rectangle className="w-50" />
            <Rectangle className="w-41" />
            <Rectangle className="w-46" />
          </div>
        </div>
        <div className="grid grid-flow-col justify-between">
          <div className="grid gap-1">
            <Rectangle className="w-12" />
            <Rectangle className="w-9" />
            <Rectangle className="w-11" />
          </div>
          <div className="grid gap-1">
            <Rectangle className="w-12" />
            <Rectangle className="w-9" />
            <Rectangle className="w-11" />
          </div>
          <div className="grid gap-1 ">
            <Rectangle className="w-12" />
            <Rectangle className="w-9" />
            <Rectangle className="w-11" />
          </div>
        </div>
      </div>
    </div>
  );
};

import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { removeWhitespace } from 'utils/removeWhitespace';
import { z } from 'zod';

export const phoneNumberSchema = z
  .string()
  .superRefine((phoneNumber, ctx) => {
    if (!phoneNumber) {
      return;
    }

    try {
      const parsedNumber = parsePhoneNumber(phoneNumber);

      if (!parsedNumber?.isValid()) {
        return ctx.addIssue({
          code: 'custom',
        });
      }

      const isValidNumber = isValidPhoneNumber(parsedNumber.number);

      if (!isValidNumber) {
        return ctx.addIssue({
          code: 'custom',
        });
      }
    } catch (_) {
      return ctx.addIssue({
        code: 'custom',
      });
    }
  })
  .transform(removeWhitespace);

import { TruncatedText } from '@candisio/design-system';
import { Dispatch, SetStateAction, memo, useCallback, useRef } from 'react';
import { useOption } from 'react-aria';
import { ListState, Node } from 'react-stately';
import { CCFilterOption } from '../../utils/paginationFilterHooks';
import styles from './ListItemIconMemoized.module.css';

interface ListItemIconMemoizedProps {
  state: ListState<CCFilterOption>;
  item: Node<CCFilterOption>;
  currentFilters: string[];
  onSetCurrentFilters: Dispatch<SetStateAction<string[]>>;
}

export const ListItemIconMemoized = memo(
  ({
    item,
    state,
    currentFilters,
    onSetCurrentFilters,
  }: ListItemIconMemoizedProps) => {
    const ref = useRef(null);
    const isSelected = currentFilters.includes(item.key.toString());

    const { optionProps } = useOption(
      {
        key: item.key,
        shouldFocusOnHover: true,
        isSelected,
      },
      state,
      ref
    );

    const onSelect = useCallback(() => {
      if (isSelected) {
        onSetCurrentFilters(
          currentFilters.filter(
            idSelected => idSelected !== item.key.toString()
          )
        );
      } else {
        onSetCurrentFilters([...currentFilters, item.key.toString()]);
      }
    }, [currentFilters, isSelected, item.key, onSetCurrentFilters]);

    return (
      <li
        className={styles.item}
        data-selected={isSelected}
        ref={ref}
        {...optionProps}
        onClick={onSelect}
      >
        {item?.value?.icon}
        {typeof item?.value?.label === 'string' ? (
          <TruncatedText>{item.value.label}</TruncatedText>
        ) : (
          item?.value?.label
        )}
      </li>
    );
  }
);

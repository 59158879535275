import { useMemo } from 'react';
import { HistoryLoading } from '../History/HistoryLoading';
import { DocumentHistory } from './DocumentHistory';
import { supportedEventKeys } from './common';
import { useDocumentHistory } from './useDocumentHistory';

export interface DocumentHistoryContainerProps {
  documentId: string;
  isEcmDocument?: boolean;
  isSensitiveDocument?: boolean;
}

export const DocumentHistoryContainer = ({
  documentId,
}: DocumentHistoryContainerProps) => {
  const { documentData, loading } = useDocumentHistory(documentId);

  const supportedEvents: any[] = useMemo(() => {
    if (!documentData) return [];

    const filteredEvents = documentData.timeline ?? [];

    // Keep only events supported by DocumentHistory
    return filteredEvents.filter(e =>
      supportedEventKeys.includes(e.__typename)
    );
  }, [documentData]);

  if (!loading && !document) {
    // TODO add error state
    return null;
  }

  if (loading) {
    return (
      <div key="history-wrapper" className="p-4 pt-2">
        <HistoryLoading itemCount={4} />
      </div>
    );
  }

  return (
    <div key="history-wrapper" className="px-2">
      <DocumentHistory events={supportedEvents} documentId={documentId} />
    </div>
  );
};

import {
  PerDiemStep,
  ReimbursementItemStatusInput,
} from 'generated-types/graphql.types';
import { ReimbursementItemSchemaOutput } from '../toolkit/reimbursementItemsFormSchema';
import moment from 'moment';

const formatDateTime = (date?: string | null, time?: string | null) =>
  date ? `${date}, ${time || '00:00'}` : undefined;

export const sanitizePerDiemFormInput = (
  values: ReimbursementItemSchemaOutput,
  currentStep?: PerDiemStep
) => {
  if (values.reimbursementItemType === 'perDiem') {
    return {
      status: values.isExpenseExcluded
        ? ReimbursementItemStatusInput.Excluded
        : ReimbursementItemStatusInput.Included,
      currentStep: currentStep ?? values.currentStep,
      segments:
        (values.segments ?? [])?.map(segment => {
          const startDateTime = formatDateTime(
            segment.startDate,
            segment.startTime
          )
            ? moment
                .utc(
                  formatDateTime(segment.startDate, segment.startTime),
                  'YYYY-MM-DD HH:mm'
                )
                .format()
            : null;
          const endDateTime = formatDateTime(segment.endDate, segment.endTime)
            ? moment
                .utc(
                  formatDateTime(segment.endDate, segment.endTime),
                  'YYYY-MM-DD HH:mm'
                )
                .format()
            : null;

          return {
            id: segment.segmentId,
            startAt: startDateTime,
            endAt: endDateTime,
            locationId: segment.location ?? null,
          };
        }) ?? [],
    };
  }
};

import { ComponentProps, forwardRef } from 'react';
import styles from './DocumentTag.module.css';
import clsx from 'clsx';

export interface DocumentTagProps
  extends Omit<ComponentProps<'div'>, 'color'> {}

/**
 * A component similar to `Tag` component from DS that has some additional
 * styles applied to it and provides support for `onClick` events
 */
export const DocumentTag = forwardRef<HTMLDivElement, DocumentTagProps>(
  ({ children, onClick, className, ...restProps }, ref) => {
    return (
      <div
        className={clsx(styles['tag'], className)}
        data-clickable={Boolean(onClick)}
        onClick={onClick}
        ref={ref}
        {...restProps}
      >
        {children}
      </div>
    );
  }
);

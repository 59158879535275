import { Spinner } from '../../../../Atoms/Spinner';

export const InitialDataLoadingSpinner = () => {
  return (
    <div className="absolute bg-white top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
      <Spinner
        size="space64"
        color="gray400"
        data-testid="generic-table-loading"
      />
    </div>
  );
};

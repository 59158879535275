import { useCreditCardsLedgerData } from 'views/Settings/useCreditCardsLedger';

export const useCreditCardExportRequirements = () => {
  const { creditCardsLedger } = useCreditCardsLedgerData();

  const hasAccountNumbers =
    !!creditCardsLedger.transitAccount && !!creditCardsLedger.bookingAccount;

  return { hasAccountNumbers };
};

import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import styles from './DeclinedTransactionContentTile.module.css';
import { SwimlaneTileAmount } from 'components/Swimlane/content/SwimlaneTileAmount';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { NavLink } from 'react-router-dom-v5-compat';
import { usePath } from 'hooks/usePath';
import { TransactionListDataFragment } from 'generated-types/graphql.types';
import { DateFormatters } from 'utils/date_formatter';
import { useReadableTransactionCategory } from 'views/TransactionDownloadHistory/hooks/useReadableTransactionCategory';
import { CardInfo } from '../shared/CardInfo/CardInfo';
import { Category } from '../shared/Category/Category';

export const DeclinedTransactionContentTile = ({
  transaction,
}: {
  transaction: TransactionListDataFragment;
}) => {
  const {
    merchantLegalName,
    transactionAmount,
    cardRefNum,
    member,
    transactionCreatedAt,
    category,
  } = transaction;

  const getReadableCategory = useReadableTransactionCategory();

  const { transactionAmountFormatter } = useMoneyFormatter();
  const formattedDate = DateFormatters.compact(
    new Date(transactionCreatedAt ?? '')
  );

  const href = usePath({
    pathname: '/archive/transactions/:transactionId',
    params: {
      transactionId: transaction.id,
    },
  });

  const name = `${member.firstName} ${member.lastName}`;

  return (
    <SwimlaneTile
      asChild
      variant="red"
      className={styles.column}
      data-cy="declinedTransactionTile"
    >
      <NavLink to={href}>
        <div className={styles.header}>
          <p className={styles.text}>
            {merchantLegalName ?? 'Unknown Merchant'}
          </p>
          <p className={styles.text}>{formattedDate}</p>
        </div>
        <div className={styles.details}>
          <CardInfo name={name} refNum={cardRefNum} />
          {category && <Category category={getReadableCategory(category)} />}
          {transactionAmount && (
            <SwimlaneTileAmount className="line-through">
              {transactionAmountFormatter(transactionAmount)}
            </SwimlaneTileAmount>
          )}
        </div>
      </NavLink>
    </SwimlaneTile>
  );
};

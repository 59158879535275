import { CreateCostCenterInput } from 'generated-types/graphql.types';
import Papa, { ParseResult } from 'papaparse';
import { v4 as uuidV4 } from 'uuid';
import { MAX_NUMBER_OF_COST_CENTERS } from './constants';
import {
  CSVFileParseError,
  CSVMaxCostCentersError,
  CostCentersWithoutCodeError,
} from './errors';

export type ParsedCostCenter = Omit<CreateCostCenterInput, 'type'> & {
  includeInImport: boolean;
  isDuplicate?: boolean;
  key: string; // Just used to keep unique identifies for each row in DOM/when manipulating, not saved with the record
};
export type ParsedCostCenters = {
  parsedCostCenters: ParsedCostCenter[];
  isDatevFormat: boolean;
};

export async function parseCostCenterCSV(
  file: File
): Promise<ParsedCostCenters> {
  const { data } = await parseFile(file);

  const parsedCostCenters = parseCostCenters(data);
  const isDatevFormat = hasCollectiveCostCenter(data);

  return {
    parsedCostCenters,
    isDatevFormat,
  };
}

async function parseFile(file: File) {
  return new Promise<ParseResult<string[]>>((resolve, reject) =>
    Papa.parse<string[]>(file, {
      skipEmptyLines: 'greedy',
      complete: result => {
        if (result.errors.length) {
          return reject(new CSVFileParseError(result.errors));
        }

        return resolve(result);
      },
      encoding: 'windows-1252',
      error: reject,
    })
  );
}

function removeDatevHeaderAndLinesAbove(csvData: string[][]): string[][] {
  const headerRowIndex = csvData.findIndex(isDatevHeader);
  if (headerRowIndex === -1) {
    return csvData;
  }

  return csvData.slice(headerRowIndex + 1);
}

function isDatevHeader(row: string[]) {
  return DATEV_HEADER_VARIATIONS.some(headerVariation =>
    headerVariation.every((column, idx) => row[idx] === column)
  );
}

const DATEV_HEADER_VARIATIONS = [
  [
    'Kostenstelle/-träger',
    'Kurzbezeichnung',
    'Langbezeichnung',
    'Verantwortlicher',
  ],
  [
    'Kostenstelle/-träger',
    'Langbezeichnung',
    'Kurzbezeichnung',
    'Verantwortlicher',
  ],
];

function hasCollectiveCostCenter(csvData: string[][]): boolean {
  return csvData.some(isCollectiveCostCenter);
}

function isCollectiveCostCenter(row: string[]): boolean {
  return DATEV_COLLECTIVE_COST_CENTER.every((cell, i) => row[i] === cell);
}

const DATEV_COLLECTIVE_COST_CENTER = [
  '9999',
  'Sammelkst.',
  'Sammelkostenstelle/-träger',
];

function parseCostCenters(data: string[][]): ParsedCostCenter[] {
  const parsedCostCenters =
    removeDatevHeaderAndLinesAbove(data).map(parseCostCenter);

  if (parsedCostCenters.length > MAX_NUMBER_OF_COST_CENTERS) {
    throw new CSVMaxCostCentersError();
  }

  return parsedCostCenters;
}

function parseCostCenter([
  code,
  description,
  name,
]: string[]): ParsedCostCenter {
  if (code.trim() === '') {
    throw new CostCentersWithoutCodeError();
  }

  return {
    code,
    name,
    includeInImport: !isCollectiveCostCenter([code, description, name]),
    key: uuidV4(),
  };
}

import {
  Button,
  Flex,
  Grid,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useNavigationSidebarContext } from 'providers/NavigationSidebarProvider/NavigationSidebarProvider';
import { useTranslation } from 'react-i18next';
import styles from './NavigationSidebar.module.css';
import { NavigationSidebarDropZone } from './NavigationSidebarDropZone';
import { OrganizationSwitcher } from './OrganizationSwitcher/OrganizationSwitcher';
import { MenuDashboard } from './components/MenuItems/MenuDashboard/MenuDashboard';
import { MenuDocumentUpload } from './components/MenuItems/MenuDocumentUpload/MenuDocumentUpload';
import { MenuDocumentUploadAccessWrapper } from './components/MenuItems/MenuDocumentUpload/MenuDocumentUploadAccessWrapper';
import { MenuIntercom } from './components/MenuItems/MenuIntercom/MenuIntercom';
import { MenuNotifications } from './components/MenuItems/MenuNotifications/MenuNotifications';
import { MenuSettings } from './components/MenuItems/MenuSettings/MenuSettings';
import { MenuUploadDocument as MenuDocumentUploadDeprecated } from './components/MenuItems/MenuUploadDocument/MenuUploadDocument';
import { UserProfile } from './components/MenuItems/UserProfile/UserProfile';
import { OverflowNavigationIcons } from './components/OverflowNavigationIcons';
import { useIconOverflow } from './hooks/useIconOverflow';
import { useNavigationIcons } from './hooks/useNavigationIcons';

export const NavigationSidebar = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const { isNavigationOpen, toggleSidebar } = useNavigationSidebarContext();
  const documentUploadReworkFF = useCandisFeatureFlags(
    FEATURE_FLAGS.documentUploadRework
  );

  const {
    isOpen: isTooltipOpen,
    tooltipProps,
    tooltipRef,
    triggerProps,
    triggerRef,
  } = useTooltip({
    placement: 'right',
    passiveTrigger: true,
    delay: 2000,
  });

  const { initialOverflowIcons } = useNavigationIcons();

  const {
    visibleIcons,
    hiddenIcons,
    navigationSidebarRef,
    overflowRef,
    headerRef,
    bottomRef,
    itemRef,
  } = useIconOverflow(initialOverflowIcons);

  const navigationLabel = isNavigationOpen
    ? t('toggleLabelClosed')
    : t('toggleLabelOpen');

  return (
    <Flex
      className={styles.sidebar}
      id="menu"
      data-open={isNavigationOpen}
      role="menu"
      justifyContent="space-between"
      as="aside"
      padding="space12 space16 space24 space8"
      position="relative"
      height="100vh"
    >
      <NavigationSidebarDropZone />
      <Button
        id="menuButton"
        className={styles.menuButton}
        aria-controls="menu"
        aria-haspopup="true"
        aria-label={navigationLabel}
        aria-pressed={isNavigationOpen}
        size="small"
        icon={isNavigationOpen ? 'menuExpanded' : 'menuCollapsed'}
        variant="tertiary"
        onClick={() => toggleSidebar()}
        position="absolute"
        right={isNavigationOpen ? '8px' : '-20px'}
        top="29px"
        zIndex={1}
        ref={triggerRef}
        {...triggerProps}
      />
      {isTooltipOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {navigationLabel}
        </Tooltip>
      )}
      <Flex
        as="nav"
        gap="space20"
        direction="column"
        justifyContent="space-between"
        height="100%"
        width="100%"
        ref={navigationSidebarRef}
      >
        <Grid gap="space8">
          <Grid ref={headerRef} gap="space24">
            <OrganizationSwitcher isNavigationOpen={isNavigationOpen} />
            <Grid paddingLeft="space8">
              <MenuDashboard expanded={isNavigationOpen} />
            </Grid>
          </Grid>

          <Grid paddingLeft="space8" gap="space8" ref={overflowRef}>
            <OverflowNavigationIcons
              icons={visibleIcons}
              isNavigationOpen={isNavigationOpen}
              hiddenIcons={hiddenIcons}
              itemRef={itemRef}
            />
          </Grid>
        </Grid>

        <Grid paddingLeft="space8" gap="space8" ref={bottomRef}>
          {documentUploadReworkFF ? (
            <MenuDocumentUploadAccessWrapper>
              <MenuDocumentUpload expanded={isNavigationOpen} />
            </MenuDocumentUploadAccessWrapper>
          ) : (
            <MenuDocumentUploadDeprecated expanded={isNavigationOpen} />
          )}

          <MenuIntercom expanded={isNavigationOpen} />
          <MenuNotifications expanded={isNavigationOpen} />
          <MenuSettings expanded={isNavigationOpen} />
          <UserProfile expanded={isNavigationOpen} />
        </Grid>
      </Flex>
    </Flex>
  );
};

import { CellContext } from '@tanstack/react-table';
import { HookFormAmountInput } from 'components/HookFormInputs/HookFormAmountInput';
import { useWatch } from 'react-hook-form';
import {
  BookingsFormValues,
  TableFormRow,
} from '../../schema/bookingsFormSchema';
import { useGetNetAmountChange } from '../../hooks/useOnChangeFactory';

type Value = TableFormRow['netAmount'];

export const NetAmountCell: React.FC<CellContext<TableFormRow, Value>> = ({
  row: { index },
}) => {
  const currency = useWatch<BookingsFormValues, 'currency'>({
    name: 'currency',
  });
  const handleNetAmountChange = useGetNetAmountChange(index);

  return (
    <HookFormAmountInput
      name={`bookings.${index}.netAmount`}
      dataCy={`bookings.${index}.netAmount`}
      currency={currency}
      onChange={handleNetAmountChange}
    />
  );
};

import {
  CustomEmptyStateProps,
  Filter,
  FilterOptionsAccessor,
  Flex,
  Table,
  TableProps,
} from '@candisio/design-system';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { DateRangeFilter } from 'components/Table/Filters/DateRangeFilter/DateRangeFilter';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Filters, SortingRule, TableState } from 'react-table';
import {
  ReimbursementTableColumnKeys,
  ReimbursementTableRow,
} from 'views/Reimbursement/toolkit/types';
import { AmountCellReimbursements } from './Cells/AmountCellReimbursements';
import { DateCell } from './Cells/DateCell';
import { DefaultCell } from './Cells/DefaultCell';
import { Header } from './Cells/Header';
import { IsPaidCell } from './Cells/IsPaidCell';
import { ReimbursementStatusCell } from './Cells/ReimbursementStatusCell';
import { ReimbursementTableToolbar } from './ReimbursementTableToolbar';
import { paginationFiltersHooks } from 'views/Reimbursement/toolkit/paginationFiltersHooks';
import { FilterWithSearchAndPagination } from 'components/Table/Filters/FilterWithSearchAndPagination/FilterWithSearchAndPagination';
import { MembersCell } from './Cells/MembersCell';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';

export type ReimbursementColumns = Record<
  ReimbursementTableColumnKeys,
  Column<ReimbursementTableRow>
>;
export interface ReimbursementsTableProps {
  data: Array<ReimbursementTableRow>;
  filterOptions?: FilterOptionsAccessor<ReimbursementTableRow>;
  visibleColumns?: Array<ReimbursementTableColumnKeys>;
  initialFilters?: TableState<ReimbursementTableRow>['filters'];
  initialSortBy?: TableState<ReimbursementTableRow>['sortBy'];
  isLoadingFilterOptions?: boolean;
  isLoadingConfigurations?: boolean;
  isLoading?: boolean;
  search: string;
  configurations: Configuration[];
  toolbarFilter?: ReactNode;
  downloadReimbursementsActions?: ReactNode;
  onEndReached: (index: number) => void;
  onSearch: (search: string) => void;
  onResetTableConfigurations: () => void;
  onUpdateTableConfigurations: (configurations: Configuration[]) => void;
  customEmptyState?: (props: CustomEmptyStateProps) => JSX.Element | null;
  onSort: (sortBy: SortingRule<ReimbursementTableRow>[]) => void;
  onFilter: (filters: Filters<ReimbursementTableRow>) => void;
  cellWrapper?: TableProps<ReimbursementTableRow>['cellWrapper'];
  getCellStyles?: TableProps<ReimbursementTableRow>['getCellStyles'];
}

export const ReimbursementsTable = ({
  data,
  filterOptions,
  initialFilters = [],
  initialSortBy = [],
  isLoadingFilterOptions = false,
  isLoadingConfigurations = false,
  isLoading = false,
  visibleColumns = [],
  configurations,
  search,
  toolbarFilter,
  downloadReimbursementsActions,
  onEndReached,
  onResetTableConfigurations,
  onUpdateTableConfigurations,
  onSearch,
  getCellStyles,
  onFilter,
  onSort,
  customEmptyState,
  cellWrapper: CellWrapper,
}: ReimbursementsTableProps) => {
  const [t] = useTranslation();
  const { searchParams } = useMutateSearchParams();

  const requesterFilter = searchParams.get('requester');

  const approversFilter = searchParams.get('approvers');

  const defaultColumn = useMemo((): Partial<Column<ReimbursementTableRow>> => {
    return {
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header,
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Cell: DefaultCell,
      Filter: filterOptions
        ? ({ column, handleUpdateIsFilterBeingUsed }) => {
            const filterOption =
              filterOptions[column.id as keyof ReimbursementTableRow];

            if (typeof filterOption === 'object' && 'data' in filterOption) {
              return (
                <Filter<ReimbursementTableRow>
                  column={column}
                  options={filterOption.data}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                  filterLabel={t('table:filterLabel')}
                  applyFilterButton={t('table:filterApply')}
                  resetFilterButton={t('table:filterReset')}
                  searchFieldPlaceholder={t('table:filterSearchPlaceholder')}
                />
              );
            }

            if (paginationFiltersHooks?.[column.id]) {
              return (
                <FilterWithSearchAndPagination
                  column={column}
                  customPaginationFilterHooks={paginationFiltersHooks}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            if (filterOption === true) {
              return (
                <DateRangeFilter
                  column={column}
                  onUpdateIsFilterBeingUsed={handleUpdateIsFilterBeingUsed}
                />
              );
            }

            return null;
          }
        : undefined,
      disableFilters: !Boolean(filterOptions),
    };
  }, [filterOptions, t]);

  const columns = useMemo(() => {
    const allColumns: ReimbursementColumns = {
      createdAt: {
        accessor: 'createdAt',
        Cell: DateCell,
      },
      lastClosedAt: {
        accessor: 'lastClosedAt',
        Cell: DateCell,
      },
      firstSubmittedForReviewAt: {
        accessor: 'firstSubmittedForReviewAt',
        Cell: DateCell,
      },
      grossAmount: {
        accessor: 'grossAmount',
        Cell: AmountCellReimbursements,
      },
      isPaid: {
        accessor: 'isPaid',
        disableSortBy: true,
        Cell: IsPaidCell,
      },
      paidAt: {
        accessor: 'paidAt',
        Cell: DateCell,
      },
      requesterOfTheReimbursement: {
        accessor: 'requesterOfTheReimbursement',
        Cell: MembersCell,
      },
      lastApprovedByMembership: {
        accessor: 'lastApprovedByMembership',
        Cell: MembersCell,
      },
      status: {
        accessor: 'status',
        Cell: ReimbursementStatusCell,
      },
      title: {
        accessor: 'title',
        disableFilters: true,
      },
      approvalRequestedAt: {
        accessor: 'approvalRequestedAt',
        Cell: DateCell,
      },
      requester: {
        accessor: 'requester',
        disableSortBy: true,
        Cell: MembersCell,
      },
      approvers: {
        disableSortBy: true,
        accessor: 'approvers',
        Cell: MembersCell,
      },
    };

    if (!visibleColumns.length) {
      return Object.values(allColumns);
    }

    return visibleColumns.map(accessor => allColumns[accessor]);
  }, [visibleColumns]);

  const tableKey = `reimbursements-table-${isLoadingFilterOptions}-${requesterFilter}-${approversFilter}-${visibleColumns.join(
    '-'
  )}`;

  return (
    <Flex height="100%" overflow="hidden" direction="column">
      <ReimbursementTableToolbar
        configurations={configurations}
        search={search}
        onResetTableConfigurations={onResetTableConfigurations}
        onUpdateConfigurations={onUpdateTableConfigurations}
        onSearch={onSearch}
        isLoading={isLoadingConfigurations}
        toolbarFilter={toolbarFilter}
        downloadReimbursementsActions={downloadReimbursementsActions}
        key={`reimbursements-table-toolbar-${visibleColumns.join('-')}`}
      />
      <Table
        key={tableKey}
        data={data}
        columns={columns}
        defaultColumn={defaultColumn}
        showDefaultEmptyState
        initialState={{
          filters: initialFilters,
          sortBy: initialSortBy,
        }}
        customEmptyState={customEmptyState}
        onEndReached={onEndReached}
        onFilter={onFilter}
        onSort={onSort}
        cellWrapper={CellWrapper}
        getCellStyles={getCellStyles}
        isLoading={isLoading}
        borderTopRadius="none"
      />
    </Flex>
  );
};

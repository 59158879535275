import { Flex } from '@candisio/design-system';
import { TokenOrCSSValue } from '@candisio/design-system';
import { ReactNode, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom-v5-compat';
import styles from './LinkWrapper.module.css';

export interface LinkWrapperProps {
  as: typeof Link | 'button';
  isActive?: boolean;
  children: ReactNode;
  padding?: TokenOrCSSValue<'space', 'padding'> | undefined;
  gap?: TokenOrCSSValue<'space', 'gap'> | undefined;
  width?: TokenOrCSSValue<'space', 'width'> | undefined;
  height?: TokenOrCSSValue<'space', 'height'> | undefined;
  to?: LinkProps['to'];
}

export const LinkWrapper = forwardRef<HTMLAnchorElement, LinkWrapperProps>(
  ({ as, isActive, ...props }, ref) => {
    return (
      <Flex
        as={as as keyof JSX.IntrinsicElements}
        className={styles.linkWrapper}
        data-active={isActive}
        ref={ref}
        {...props}
      />
    );
  }
);

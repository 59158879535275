import { Item, Icon } from '@candisio/design-system';
import { TruncatedText } from '@candisio/design-system/next';
import {
  HookFormComboBoxField,
  HookFormComboBoxFieldProps,
} from 'components/HookFormFields/HookFormComboBoxField';
import * as AllFlags from 'country-flag-icons/react/3x2';
import { useMemo } from 'react';
import { FieldValues, Path, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './PerDiemLocationField.module.css';
import { ComboBoxFieldOptions } from 'views/Reimbursement/hooks/types';
import { PerDiemLocationItem } from './utils/usePerDiemLocationFieldOptions';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

interface PerDiemLocationFieldProps<TFormValues extends FieldValues>
  extends HookFormComboBoxFieldProps<TFormValues> {
  name: Path<TFormValues>;
}

const isValidFlag = (code: string): code is keyof typeof AllFlags =>
  code in AllFlags;

const PlaceholderIcon = () => <Icon icon="worldGlobe" size="space24" />;

interface GetCountryFlagProps {
  isoCode?: string;
  className?: string;
}
export const getCountryFlag = ({
  isoCode,
  className = 'w-[24px] h-[20px] flex-shrink-0',
}: GetCountryFlagProps) => {
  if (!isoCode || !isValidFlag(isoCode)) {
    return <PlaceholderIcon />;
  }

  const FlagComponent = AllFlags[isoCode];
  return <FlagComponent className={className} />;
};

export const PerDiemLocationField = <TFormValues extends FieldValues>({
  name,
  defaultItems = [],
  ...props
}: PerDiemLocationFieldProps<TFormValues> &
  ComboBoxFieldOptions<PerDiemLocationItem>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const inputValue = useWatch<TFormValues>({
    name,
  });

  const selectedItem = useMemo(
    () => defaultItems.find(item => item.key === inputValue),
    [defaultItems, inputValue]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.flagIcon}>
        {selectedItem ? (
          getCountryFlag({ isoCode: selectedItem.countryIsoCode })
        ) : (
          <PlaceholderIcon />
        )}
      </div>
      <div className={styles.locationField}>
        <HookFormComboBoxField
          name={name}
          isVirtualized={false}
          placeholder={t(
            'reimbursementView.middleSection.form.perDiem.segmentFields.location.placeholder'
          )}
          isGroupedListMode
          itemHeight="space36"
          groupedLists={props.groupedLists}
          groupHeaders={props.groupHeaders}
          defaultItems={defaultItems}
          {...props}
        >
          {(item: PerDiemLocationItem) => (
            <Item key={item.key} textValue={item.children as string}>
              <div className="flex items-center gap-2">
                {getCountryFlag({ isoCode: item.countryIsoCode })}
                <TruncatedText>{item.children as string}</TruncatedText>
              </div>
            </Item>
          )}
        </HookFormComboBoxField>
      </div>
    </div>
  );
};

import { WithHighlights } from './types';

export const getRawContent = (highlights: WithHighlights['highlights']) => {
  return highlights?.rawContentHighlight;
};

export const getRawContentMatchesCount = (
  highlights: WithHighlights['highlights']
) => {
  return getRawContent(highlights)?.matches.length ?? undefined;
};

import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { TopupDrawer } from './TopupDrawer';
import { useGetProcessingAccountDataCardManager } from './hooks/useGetProcessingAccountDataCardManager';
import { TOP_UP_HASH } from 'containers/credit-cards/constants';

export const TopupDrawerContainer = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const { processingAccountData, loading } =
    useGetProcessingAccountDataCardManager();

  const isOpen = hash === `#${TOP_UP_HASH}`;

  const handleClose = () => {
    navigate({
      search: location.search,
      hash: undefined,
    });
  };

  return (
    <TopupDrawer
      isOpen={isOpen}
      isLoading={loading}
      processingAccountData={processingAccountData}
      onClose={handleClose}
    />
  );
};

import { Paragraph, Text } from '@candisio/design-system';
import { Collapse } from 'components/History/items/Collapse';
import { ExpenseItemGeneralDataExtractedEventDataFragment } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { FieldConfig } from './ExpenseItemHospitalityDataExtractedComponent';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { NavigateToItemWrapper } from '../NavigateToItemWrapper';

export const ExpenseItemGeneralDataExtractedComponent = ({
  resourceId,
  properties,
}: ExpenseItemGeneralDataExtractedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { transactionAmountFormatter } = useMoneyFormatter();

  const { expenseDate, invoiceNumber, totalAmount } = properties;

  const formattedDate = expenseDate
    ? DateFormatters.compact(new Date(expenseDate))
    : null;

  const formattedAmount = !isNil(totalAmount?.amount)
    ? transactionAmountFormatter({
        currency: totalAmount.currency,
        precision: totalAmount.precision,
        value: totalAmount.amount,
      })
    : null;

  const fields: FieldConfig[] = useMemo(
    () => [
      {
        key: 'expenseDate',
        value: formattedDate,
        translationKey:
          'reimbursementView.middleSection.form.general.date.label',
      },
      {
        key: 'invoiceNumber',
        value: invoiceNumber ?? null,
        translationKey:
          'reimbursementView.middleSection.form.general.invoiceNumber.label',
      },
      {
        key: 'totalAmount',
        value: formattedAmount,
        translationKey:
          'reimbursementView.middleSection.form.general.totalAmount.label',
      },
    ],
    [formattedDate, invoiceNumber, formattedAmount]
  );

  const fieldsWithValues = fields.filter(field => field.value);
  const extractedFieldsCount = fieldsWithValues.length;

  if (extractedFieldsCount === 0) {
    return null;
  }

  const headline = t('timeline.extractedDataInfo', {
    count: extractedFieldsCount,
  });

  return (
    <NavigateToItemWrapper id={resourceId}>
      <Text>{headline}</Text>
      <Collapse>
        <div className="pt-2 grid gap-2">
          {fieldsWithValues.map(field => (
            <div key={field.key}>
              <Text fontWeight="regular" color="gray500">
                {t(field.translationKey)}
              </Text>
              <Paragraph>{field.value}</Paragraph>
            </div>
          ))}
        </div>
      </Collapse>
    </NavigateToItemWrapper>
  );
};

import { PowerSearchFieldContainer } from 'components/PowerSearchField/PowerSearchFieldContainer';
import { PowerSearchToolbar } from 'components/PowerSearchToolbar/PowerSearchToolbar';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import styles from 'components/PowerSearchToolbar/power-search-toolbar.module.css';

interface EcmDocumentsToolbarProps {
  searchQuery: string;
  onSearchChange: (searchQuery: string) => void;
}

export const EcmDocumentsToolbar = ({
  searchQuery,
  onSearchChange,
}: EcmDocumentsToolbarProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  return (
    <PowerSearchToolbar>
      <PowerSearchFieldContainer
        className={styles.powerSearchToolbarSearchField}
        clearTooltip={t('search.clearTooltip')}
        placeholder={t('search.placeholder')}
        initialValue={searchQuery}
        onQueryChange={onSearchChange}
        inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
      />
    </PowerSearchToolbar>
  );
};

export const loadVWO = VWOAccountId => {
  return (
    window._vwo_code ||
    (function () {
      var account_id = VWOAccountId,
        version = 2.1,
        settings_tolerance = 2000,
        hide_element = 'body',
        hide_element_style =
          'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;transition:none !important;',
        /* DO NOT EDIT BELOW THIS LINE */
        f = false,
        w = window,
        d = document,
        v = d.querySelector('#vwoCode'),
        cK = '_vwo_' + account_id + '_settings',
        cc = {};
      try {
        var c = JSON.parse(
          localStorage.getItem('_vwo_' + account_id + '_config')
        );
        cc = c && typeof c === 'object' ? c : {};
      } catch (_e) {}

      var stT = cc.stT === 'session' ? w.sessionStorage : w.localStorage;
      //@ts-ignore
      const code = {
        //@ts-ignore
        nonce: v && v.nonce,
        use_existing_jquery: function () {
          //@ts-ignore
          return typeof use_existing_jquery !== 'undefined'
            ? //@ts-ignore
              use_existing_jquery
            : undefined;
        },
        library_tolerance: function () {
          //@ts-ignore
          return typeof library_tolerance !== 'undefined'
            ? //@ts-ignore
              library_tolerance
            : undefined;
        },
        settings_tolerance: function () {
          //@ts-ignore
          return cc.sT || settings_tolerance;
        },
        hide_element_style: function () {
          //@ts-ignore
          return '{' + (cc.hES || hide_element_style) + '}';
        },
        hide_element: function () {
          if (performance.getEntriesByName('first-contentful-paint')[0]) {
            return '';
          }
          //@ts-ignore
          return typeof cc.hE === 'string' ? cc.hE : hide_element;
        },
        getVersion: function () {
          return version;
        },
        //@ts-ignore
        finish: function (e) {
          if (!f) {
            f = true;
            var t = d.getElementById('_vis_opt_path_hides');
            //@ts-ignore
            if (t) t.parentNode.removeChild(t);
            if (e)
              new Image().src =
                'https://dev.visualwebsiteoptimizer.com/ee.gif?a=' +
                account_id +
                e;
          }
        },
        finished: function () {
          return f;
        },
        //@ts-ignore
        addScript: function (e) {
          var t = d.createElement('script');
          t.type = 'text/javascript';
          if (e.src) {
            t.src = e.src;
          } else {
            t.text = e.text;
          }
          //@ts-ignore
          v && t.setAttribute('nonce', v.nonce);
          d.getElementsByTagName('head')[0].appendChild(t);
        },
        //@ts-ignore
        load: function (e, t) {
          var n = this.getSettings(),
            i = d.createElement('script'),
            r = this;
          t = t || {};
          if (n) {
            i.textContent = n;
            d.getElementsByTagName('head')[0].appendChild(i);
            //@ts-ignore
            if (!w.VWO || VWO.caE) {
              stT.removeItem(cK);
              r.load(e);
            }
          } else {
            var o = new XMLHttpRequest();
            o.open('GET', e, true);
            o.withCredentials = !t.dSC;
            o.responseType = t.responseType || 'text';
            o.onload = function () {
              if (t.onloadCb) {
                return t.onloadCb(o, e);
              }
              if (o.status === 200 || o.status === 304) {
                //@ts-ignore
                _vwo_code.addScript({ text: o.responseText });
              } else {
                //@ts-ignore
                _vwo_code.finish('&e=loading_failure:' + e);
              }
            };
            o.onerror = function () {
              if (t.onerrorCb) {
                return t.onerrorCb(e);
              }
              //@ts-ignore
              _vwo_code.finish('&e=loading_failure:' + e);
            };
            o.send();
          }
        },
        getSettings: function () {
          try {
            var e = stT.getItem(cK);
            if (!e) {
              return;
            }
            e = JSON.parse(e);
            //@ts-ignore
            if (Date.now() > e.e) {
              stT.removeItem(cK);
              return;
            }
            //@ts-ignore
            return e.s;
          } catch (_e) {
            return;
          }
        },
        init: function () {
          if (d.URL.indexOf('__vwo_disable__') > -1) return;
          var e = this.settings_tolerance();
          //@ts-ignore
          w._vwo_settings_timer = setTimeout(function () {
            //@ts-ignore
            _vwo_code.finish();
            stT.removeItem(cK);
          }, e);
          var t;
          if (this.hide_element() !== 'body') {
            t = d.createElement('style');
            var n = this.hide_element(),
              i = n ? n + this.hide_element_style() : '',
              r = d.getElementsByTagName('head')[0];
            t.setAttribute('id', '_vis_opt_path_hides');
            //@ts-ignore
            v && t.setAttribute('nonce', v.nonce);
            t.setAttribute('type', 'text/css');
            //@ts-ignore
            if (t.styleSheet) t.styleSheet.cssText = i;
            else t.appendChild(d.createTextNode(i));
            r.appendChild(t);
          } else {
            t = d.getElementsByTagName('head')[0];
            var i = d.createElement('div');
            i.style.cssText =
              'z-index: 2147483647 !important;position: fixed !important;left: 0 !important;top: 0 !important;width: 100% !important;height: 100% !important;background: white !important;';
            i.setAttribute('id', '_vis_opt_path_hides');
            i.classList.add('_vis_hide_layer');
            //@ts-ignore
            t.parentNode.insertBefore(i, t.nextSibling);
          }
          //@ts-ignore
          var o = window._vis_opt_url || d.URL,
            s =
              'https://dev.visualwebsiteoptimizer.com/j.php?a=' +
              account_id +
              '&u=' +
              encodeURIComponent(o) +
              '&vn=' +
              version;
          if (w.location.search.indexOf('_vwo_xhr') !== -1) {
            this.addScript({ src: s });
          } else {
            this.load(s + '&x=true');
          }
        },
      };
      //@ts-ignore
      w._vwo_code = code;
      //@ts-ignore
      code.init();
    })()
  );
};

import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { UseFormReturn } from 'react-hook-form';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { ReimbursementItemFooter } from './ReimbursementItemFooter';
import { ReimbursementItemLayout } from './ReimbursementItemLayout';
import { InvoiceSection } from './InvoiceSection';
import {
  HospitalityFieldNames,
  generateFieldNames,
} from './utils/generateHospitalityFormFieldNames';
import { BooleanMap } from './utils/useReimbursementItemsFormActions';
import { FieldsOptions } from './utils/useFieldsOptions';
import { useHospitalityRightSectionView } from './utils/useRightSectionView';

interface HospitalityExpenseFormProps {
  isExpenseFormEditable: boolean;
  reimbursementStatus?: ReimbursementCaseStatus;
  onRemoveForm: (index: number, expenseId: string) => () => Promise<void>;
  onCleareableField: (field?: string) => () => void;
  onUpdateHospitality: (index: number) => () => Promise<void>;
  onInvoiceChange: (file: File, index: number) => Promise<void>;
  onUpdateTotalAmount: (index: number) => void;
  formIndex: number;
  isDeletingForm: BooleanMap;
  formMethods: UseFormReturn<ReimbursementItemsFormOutput>;
  isCreatingInvoice: BooleanMap;
  clearableField: string | undefined;
  expenseId: string;
  canManageReimbursementItems: boolean;
  fieldOptions: FieldsOptions;
  isExtractingInvoiceData: BooleanMap;
  onHospitalityInvoiceDataExtraction: (
    index: number,
    fields: HospitalityFieldNames
  ) => Promise<void>;
}

export const HospitalityExpenseForm = ({
  isExpenseFormEditable,
  reimbursementStatus,
  formIndex,
  clearableField,
  formMethods,
  isCreatingInvoice,
  expenseId,
  isDeletingForm,
  canManageReimbursementItems,
  fieldOptions,
  isExtractingInvoiceData,
  onHospitalityInvoiceDataExtraction,
  onRemoveForm,
  onCleareableField,
  onUpdateHospitality,
  onInvoiceChange,
  onUpdateTotalAmount,
}: HospitalityExpenseFormProps) => {
  const { watch, getValues } = formMethods;
  const { isExcluded: isExcludedFieldOptions } = fieldOptions;
  const fields = generateFieldNames(formIndex);

  const invoices = watch(fields.files);
  const isExcluded = watch(fields.isExpenseExcluded);

  const invoice = invoices?.[0];

  const totalAmount = getValues(fields.totalAmount);
  const reason = getValues(fields.reason);
  const itemStatus = getValues(fields.itemStatus);

  const showInvoicePreview = isCreatingInvoice[formIndex] || !!invoice;

  const isExtractedDataAccepted = getValues(fields.isExtractedDataAccepted);

  const isExcludedInfoVisible =
    isExcluded && isExcludedFieldOptions.showInfoIsExcluded;

  const handleInvoiceUpdate = async () => {
    await onHospitalityInvoiceDataExtraction(formIndex, fields);
  };

  const fieldsSectionProps = {
    formIndex,
    isExcluded,
    isExpenseFormEditable,
    isExtractedDataAccepted,
    reimbursementStatus,
    clearableField,
    isCreatingInvoice,
    isExtractingInvoiceData,
    invoice,
    onCleareableField,
    onUpdateExpense: onUpdateHospitality,
    onUpdateTotalAmount,
  };

  const FieldsSection = useHospitalityRightSectionView({
    isExpenseFormEditable,
  });

  return (
    <ReimbursementItemLayout
      reimbursementItemType="hospitality"
      expenseId={expenseId}
      totalAmount={totalAmount}
      reason={reason}
      isExcluded={isExcluded}
      reimbursementStatus={reimbursementStatus}
      itemStatus={itemStatus}
      leftSection={<FieldsSection {...fieldsSectionProps} />}
      rightSection={
        <InvoiceSection
          canManageReimbursementItems={canManageReimbursementItems}
          formIndex={formIndex}
          handleInvoiceUpdate={handleInvoiceUpdate}
          invoice={invoice}
          isCreatingInvoice={isCreatingInvoice}
          isExcluded={isExcluded}
          onInvoiceChange={onInvoiceChange}
          showInvoicePreview={showInvoicePreview}
        />
      }
      footer={
        <ReimbursementItemFooter
          canManageReimbursementItems={canManageReimbursementItems}
          expenseId={expenseId}
          formIndex={formIndex}
          isDeletingForm={isDeletingForm}
          isExcludedFieldOptions={isExcludedFieldOptions}
          isExcludedInfoVisible={isExcludedInfoVisible}
          onRemoveForm={onRemoveForm}
          onUpdateExpense={onUpdateHospitality}
          onTriggerValidation={formMethods.trigger}
        />
      }
    />
  );
};

import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Paragraph,
} from '@candisio/design-system';
import { HelpCenterPanel } from 'containers/credit-cards/components/HelpCenterPanel';
import { User } from 'generated-types/graphql.types';
import moment from 'moment';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { pliantLoginLink } from '../utils';

interface CreditCardsEmailSentProps {
  user: User | null | undefined;
  date: string | null | undefined;
}

export const CreditCardsEmailSent = ({
  date,
  user,
}: CreditCardsEmailSentProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { firstName, lastName } = user || {};

  const userName = `${firstName}${' '}${lastName}`;
  const dateInput = moment(date).format('L');

  return (
    <Grid gap="space32" alignSelf="start">
      <Card background="white" borderRadius="medium" boxShadow="noShadow">
        <Grid gap="space24" alignContent="start">
          <Flex direction="column" gap="space14">
            <Heading as="h3">
              {t('creditCardRequestedState.pendingState.title', {
                date: dateInput,
                userName: userName,
              })}
            </Heading>
            <Paragraph>
              {t('creditCardRequestedState.pendingState.description')}
            </Paragraph>
          </Flex>
          <Paragraph>
            {t('creditCardRequestedState.pendingState.subDescription')}
          </Paragraph>
          <Card boxShadow="noShadow" background="bluebg" padding="space16">
            <Grid templateColumns="1fr auto" gap="space16">
              <Paragraph alignSelf="start">
                {t('creditCardRequestedState.pendingState.helpText')}
              </Paragraph>
              <Button
                alignSelf="center"
                justifySelf="end"
                as="a"
                target="_blank"
                title={t('creditCardRequestedState.pendingState.ctaButton')}
                variant="secondary"
                href={pliantLoginLink}
              >
                {t('creditCardRequestedState.pendingState.ctaButton')}
              </Button>
            </Grid>
          </Card>
        </Grid>
      </Card>
      <HelpCenterPanel />
    </Grid>
  );
};

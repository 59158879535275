import { Locale } from 'generated-types/graphql.types';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useState } from 'react';
import {
  baselineSearchPromotionImages,
  maxBaselineSearchPromotionImages,
} from './const';

export const useBaselineSearchPromotionSlides = () => {
  const user = useCurrentUser();
  const { showSearchOverlayPromo } = useEcm();
  const slideImages = baselineSearchPromotionImages[
    user?.locale ?? Locale.De
  ].slice(
    0,
    showSearchOverlayPromo
      ? maxBaselineSearchPromotionImages - 1
      : maxBaselineSearchPromotionImages
  );

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const goToNextSlide = () => {
    setCurrentSlideIndex(prev => (prev + 1) % slideImages.length);
  };

  const lastSlideIndex = slideImages.length - 1;

  return {
    slideImages,
    currentSlideIndex,
    setCurrentSlideIndex,
    goToNextSlide,
    totalSlideImages: slideImages.length,
    lastSlideIndex,
  };
};

import {
  Flex,
  Popover,
  UsePopoverConfig,
  usePopover,
} from '@candisio/design-system';
import { AnimatePresence, motion } from 'motion/react';
import { useEffect } from 'react';
import { BaselineSearchPromotionOverlayMessage } from './BaselineSearchPromotionOverlayMessage';
import {
  popoverTransition,
  popoverVariants,
  sliderTransition,
  sliderVariants,
} from './const';
import { useBaselineSearchPromotionSlides } from './useBaselineSearchPromotionSliderContent';

const popoverContainer: {
  x: number;
  y: number;
  placement: UsePopoverConfig['placement'];
}[] = [
  { x: 630, y: 110, placement: 'top' },
  { x: 730, y: 138, placement: 'right' },
  { x: 405, y: 86, placement: 'right' },
];

const MotionPopover = motion.create(Popover);

interface BaselineSearchPromotionSliderProps {
  currentIndex: number;
  handleCTAClick: () => void;
}

export const BaselineSearchPromotionSlider = ({
  currentIndex,
  handleCTAClick,
}: BaselineSearchPromotionSliderProps) => {
  const currentPopoverContainer = popoverContainer[currentIndex];
  const { slideImages, totalSlideImages } = useBaselineSearchPromotionSlides();

  const { open, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({
      placement: popoverContainer[currentIndex].placement ?? 'top',
      updatePlacementOnTriggerMovement: true,
    });

  useEffect(() => {
    const timeout = setTimeout(() => {
      open();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  return (
    <>
      <Flex width="100%" height="428px" position="relative" overflow="hidden">
        <Flex
          ref={triggerRef}
          {...triggerProps}
          height="5px"
          width="5px"
          top={currentPopoverContainer.y + 'px'}
          left={currentPopoverContainer.x + 'px'}
        />
        <AnimatePresence initial={false}>
          <motion.div
            key={currentIndex}
            style={{
              backgroundImage: `url(${slideImages[currentIndex]})`,
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              willChange: 'transform, opacity',
            }}
            variants={sliderVariants}
            transition={sliderTransition}
            initial="incoming"
            animate="active"
            exit="exit"
          />
        </AnimatePresence>
      </Flex>
      <MotionPopover
        key={currentIndex}
        {...(popoverProps as any)}
        ref={popoverRef}
        variants={popoverVariants}
        animate="animate"
        initial="initial"
        transition={popoverTransition}
        padding="space16"
        dismissWithOutsideClick={false}
        background="blue700"
        maxWidth="18.125rem"
        autoFocus
        restoreFocus
      >
        <BaselineSearchPromotionOverlayMessage
          index={currentIndex}
          onClick={handleCTAClick}
          totalOverlays={totalSlideImages}
        />
      </MotionPopover>
    </>
  );
};

import { Grid, Item } from '@candisio/design-system';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { EmptyDataState } from 'components/GridList/components/EmptyDataState';
import { HISTORY_LIST_WIDTH } from 'components/HistoryList/HistoryList';
import { CoreDataTypes } from 'generated-types/graphql.types';
import { motion } from 'motion/react';
import {
  CoreDataImportHistoryEntry,
  CoreDataImportHistoryListItem,
} from 'views/CoreDataImportHistory/elements/CoreDataImportHistoryListItem/CoreDataImportHistoryListItem';

export interface ImportHistoryListCoreData {
  importHistoryData: CoreDataImportHistoryEntry[];
  emptyState?: {
    translation: string;
    url?: string;
  };
  coreDataType: CoreDataTypes;
  isLoading: boolean;
  width?: string;
}

const MotionGrid = motion.create(Grid);

export const CoreDataImportHistoryList = ({
  importHistoryData,
  coreDataType,
  emptyState,
  isLoading,
  width = HISTORY_LIST_WIDTH,
}: ImportHistoryListCoreData) => {
  return (
    <FilterableList
      children={importHistoryData.map(
        (item: CoreDataImportHistoryEntry, index: number) => (
          <Item key={index} textValue={item.processId}>
            <MotionGrid
              borderBottom="1px solid gray200"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ease: 'easeOut', duration: 0.3 }}
            >
              <CoreDataImportHistoryListItem
                item={item}
                coreDataType={coreDataType}
              />
            </MotionGrid>
          </Item>
        )
      )}
      emptyDataState={
        <EmptyDataState
          translation={
            emptyState?.translation ?? 'importHistoryList.emptyState.title'
          }
          url={emptyState?.url}
        />
      }
      isLoading={isLoading}
      width={width}
    />
  );
};

import { zodResolver } from 'utils/zodFormValidation';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { BookingsFormProps, BookingsFormSchemaContext } from './types';
import { BookingsTable } from './BookingsTable/BookingsTable';
import {
  BookingsFormValues,
  createBookingsFormSchema,
  getBookingsFormErrorMessages,
} from './schema/bookingsFormSchema';
import { BookingsHeaderForm } from './BookingsHeaderForm/BookingsHeaderForm';
import { BookingsFormFooter } from './BookingsFormFooter/BookingsFormFooter';
import { BookingsFormLayout } from './layout/BookingsFormLayout';
import { useFormSchemaContext } from './schema/useFormSchemaContext';
import { useGetAddNewBooking } from './hooks/useGetAddNewBooking';
import { FieldArrayFormProvider } from './utils/FieldArrayContext';

export const BookingsForm = ({
  initialValues,
  readOnly,
  documentDirection,
  isGoodsPurchaseOrderLinked,
  onAcceptBookings,
  mode,
}: BookingsFormProps) => {
  const schemaContextProps = useFormSchemaContext();
  const schemaContext = {
    ...schemaContextProps,
    documentDirection,
    invoiceDate: initialValues.invoiceDate,
    mode,
    isFormReadOnly: readOnly,
    shouldRequireQuantity: isGoodsPurchaseOrderLinked,
  } satisfies BookingsFormSchemaContext;

  const formMethods = useForm<BookingsFormValues>({
    defaultValues: initialValues,
    shouldUnregister: false,
    mode: 'all',
    context: schemaContext,
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.SPLIT_BOOKINGS,
      zodSchema: createBookingsFormSchema,
      errorMessages: getBookingsFormErrorMessages(),
    }),
    shouldFocusError: true,
  });
  const { control } = formMethods;
  const { fields, append, remove } = useFieldArray({
    name: 'bookings',
    control,
    shouldUnregister: false,
    keyName: 'bookingId',
  });
  const getAppendRow = useGetAddNewBooking({ append });

  const appendRow = () => {
    const grossAmount = formMethods.getValues('grossAmount');
    const bookings = formMethods.getValues('bookings');
    getAppendRow({
      grossAmount,
      bookings,
      schemaContext,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <FieldArrayFormProvider remove={remove}>
        <BookingsFormLayout
          header={<BookingsHeaderForm />}
          footer={<BookingsFormFooter />}
          onFormSubmit={formMethods.handleSubmit(d => {
            onAcceptBookings?.(d);
          })}
        >
          <BookingsTable
            data={fields}
            initialValues={initialValues}
            isReadOnly={readOnly}
            appendRow={appendRow}
          />
        </BookingsFormLayout>
      </FieldArrayFormProvider>
    </FormProvider>
  );
};
// TODO trigger if mode approve to show errors on open

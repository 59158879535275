import { Flex, Image } from '@candisio/design-system';

import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ExportStatus,
  ExportType as GqlExportType,
  SapB1ExportErrorResponseCode,
  useExportSapB1SagaMutation,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { ExportNotificationsContext } from 'providers/ExportNotificationsProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { v4 as uuidV4 } from 'uuid';
import { ExportContext } from 'views/Integrations/Export/Context';
import { defineExportView } from 'views/Integrations/Export/Manifest';
import { SapB1ExportButton } from 'views/Integrations/Export/elements/sidebar/Init/api/sap-b1/SapB1ExportButton';
import { ExportInfo } from 'views/Integrations/Export/toolkit/components/ExportInfo';
import { InitExportTypeLabel } from 'views/Integrations/Export/toolkit/containers/ExportEntitiesSummary/InitExportTypeLabel';
import { ApprovalInvoicesSummary } from 'views/Integrations/Export/toolkit/containers/ExportEntitiesSummary/components/ApprovalsSummary';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import sapB1Icon from 'views/Integrations/Export/toolkit/images/icon-sap-b1.svg';
import { getRefetchExportsQueries } from 'views/Integrations/Export/toolkit/queries';
import { Sidebar, View } from 'views/Integrations/Export/types';

const apiErrorMap: Record<SapB1ExportErrorResponseCode, string> = {
  [SapB1ExportErrorResponseCode.AuthError]:
    'export.exportForm.exportSapB1.apiErrors.authError',
  [SapB1ExportErrorResponseCode.UnexpectedError]:
    'export.exportForm.exportSapB1.apiErrors.unexpectedError',
  [SapB1ExportErrorResponseCode.BadRequest]:
    'export.exportForm.exportSapB1.apiErrors.badRequest',
};

export const SapB1 = () => {
  const state = useContext(ExportContext);
  const counterQueries = useCounterQueries();
  const { addExport } = useContext(ExportNotificationsContext);
  const orgId = useOrganizationId();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { error } = useToastMessage();

  const { exportType, exportId } = state;

  const {
    readyToExportEntitiesCountByType,
    exportEntities: {
      ready: { all: allEntities },
    },
    exportEntitiesIds,
    exportAll,
    exportDetails,
  } = useExportDataGigaFactory(state);

  const { documentIds, transactionIds, cardSettlementIds } = exportEntitiesIds;

  const hasExportableEntity =
    exportAll ||
    !!documentIds.length ||
    !!transactionIds.length ||
    !!cardSettlementIds.length;

  const enableNewIndexInExportsViewFF = useCandisFeatureFlags(
    FEATURE_FLAGS.enableNewIndexInExportsView
  );

  const [exportSapB1Saga, { loading }] = useExportSapB1SagaMutation({
    refetchQueries: [
      ...getRefetchExportsQueries({
        documentIds,
        enableNewIndexInExportsViewFF,
      }),
      ...counterQueries,
    ],
    awaitRefetchQueries: true,
  });

  const onExport = async () => {
    const { data } = await exportSapB1Saga({
      variables: {
        exportGuid: exportId ?? uuidV4(),
        isReexport: undefined,
      },
    });

    const res = data?.exportSapB1Saga;

    if (res?.__typename === 'SapB1ExportResponse') {
      const newExportId = res.exportGuid;
      if (newExportId) {
        addExport({
          hash: newExportId,
          organization: orgId || '',
          status: ExportStatus.Exporting,
          documents: documentIds.length,
          type: GqlExportType.SapB1,
        });

        const viewDef = defineExportView(
          {
            ...state,
            sidebar: Sidebar.HISTORY,
            view: View.HISTORY,
            exportId: newExportId,
          },
          { orgId }
        );

        navigate(viewDef.navigate);
      }
    } else if (res?.__typename === 'SapB1ExportErrorResponse') {
      const errorCode = res.code;
      error(t(apiErrorMap[errorCode]));
    }
  };

  return (
    <Flex direction="column" justifyContent="space-between">
      <Flex direction="column" gap="space24">
        <ExportInfo>
          <Image
            src={sapB1Icon}
            alt={exportType || ''}
            height="84px"
            width="84px"
            alignSelf="center"
          />
          <InitExportTypeLabel />
        </ExportInfo>
        <ApprovalInvoicesSummary
          readyToExportEntitiesCount={readyToExportEntitiesCountByType}
        />
      </Flex>
      <SapB1ExportButton
        loading={loading || exportDetails?.status === ExportStatus.Exporting}
        onExport={onExport}
        entities={allEntities}
        hasExportableEntity={hasExportableEntity}
      />
    </Flex>
  );
};

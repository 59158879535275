import {
  Box,
  Flex,
  Grid,
  Link,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { AppRouteParams, Routes } from 'models';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom-v5-compat';
import { SETTINGS_ROUTES } from 'views/Settings/types';
import { Team } from '../hooks/useTeamInvolvements';

interface TeamInvolvementsProps {
  teams: Team[];
}

export const TeamInvolvements = ({ teams }: TeamInvolvementsProps) => {
  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'top',
      delay: 500,
    });

  const createTeamUrl = (id?: string) => {
    if (!id) {
      return;
    }

    return generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.SETTINGS}/${SETTINGS_ROUTES.TEAMS}/:id?`,
      { organizationSlug, id }
    );
  };

  const hasOneTeam = teams.length === 1;

  if (hasOneTeam) {
    return (
      <Link fontSize="basic" href={createTeamUrl(teams[0].id)} external>
        {t('settings.teamMembers.form.involvement.links.team', {
          name: teams[0].name,
        })}
      </Link>
    );
  }

  return (
    <Grid>
      <Box
        fontSize="basic"
        cursor="pointer"
        color="blue500"
        {...triggerProps}
        ref={triggerRef}
      >
        <Text>
          {t('settings.teamMembers.form.involvement.links.team_plural', {
            count: teams.length,
          })}
        </Text>
      </Box>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef} maxWidth="150px">
          <Flex wrap="wrap" gap="space8">
            {teams.map(team => (
              <Grid
                key={team.id}
                templateColumns="auto 1fr"
                alignItems="center"
              >
                <Link
                  href={createTeamUrl(team.id)}
                  style={{ display: 'contents' }}
                  external
                >
                  <TruncatedText fontSize="basic" lineHeight="paragraph">
                    {team.name}
                  </TruncatedText>
                </Link>
              </Grid>
            ))}
          </Flex>
        </Tooltip>
      )}
    </Grid>
  );
};

import { forwardRef } from 'react';
import clsx from 'clsx';
import { motion } from 'motion/react';
import { Button } from '@candisio/design-system';
import styles from './PromoPartOne.module.css';
import { DummyDocument } from './Invoice';
import { useTranslation } from 'react-i18next';
import star from './assets/star.svg';

type PromoPartOneProps = {
  onNext: () => void;
  onClose: () => void;
};

export const PromoPartOne = forwardRef<HTMLDivElement, PromoPartOneProps>(
  ({ onNext, onClose }, ref) => {
    const [t] = useTranslation();
    const documentTypes = t('classification.promoModal.stepOne.documentTypes', {
      returnObjects: true,
    }) as string[]; // This key is an array in translation file
    const targetDocumentType = t(
      'classification.promoModal.stepOne.targetDocumentType'
    );

    return (
      <motion.div
        ref={ref}
        className="grid grid-cols-[6fr_3fr] h-full w-full bg-gray-800 rounded-medium whitespace-pre-line"
        exit={{
          x: '-100%',
        }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
      >
        <div></div>
        <div className="bg-gray-200 grid justify-end items-start ">
          <div className="pr-6 pt-6 z-10">
            <Button
              variant="tertiary"
              color="gray"
              icon="close"
              onClick={onClose}
            />
          </div>
        </div>
        <div className="absolute w-full h-full grid grid-cols-[4fr_3fr_3fr]">
          <div className="flex flex-col gap-8 text-white pl-16 justify-center">
            <span className="text-xlarge">
              <img src={star} alt="" />
            </span>
            <span className="text-5xl -tracking-[0.06em] font-light leading-14 w-82">
              {t('classification.promoModal.stepOne.title')}
            </span>
            <span className="font-light text-basic tracking-tight opacity-80 w-82">
              {t('classification.promoModal.stepOne.description')}
            </span>
          </div>
          <div className="flex justify-end items-center">
            <DummyDocument className="w-full max-w-67 max-h-95 pointer-events-none select-none overflow-hidden bg-[#FFFFFFCC] rounded-medium" />
          </div>
          <div className="flex justify-center items-center">
            <div className={styles['list-wrapper']}>
              <div className="absolute w-full h-full bg-gradient-to-b from-gray-200 via-transparent to-gray-200 z-10" />
              <div className={styles['list-container']}>
                {documentTypes.map(type => (
                  <div key={`${type}`} className={styles['list-item']}>
                    {type}
                  </div>
                ))}
                <div
                  className={clsx(
                    styles['list-item'],
                    styles['list-item--target']
                  )}
                >
                  {targetDocumentType}
                </div>
                {documentTypes.map(type => (
                  <div key={`${type}`} className={styles['list-item']}>
                    {type}
                  </div>
                ))}
              </div>
            </div>
            <div className="absolute bottom-0 right-0 pr-12.5 pb-6">
              <Button color="gray" variant="secondary" onClick={onNext}>
                {t('classification.promoModal.stepOne.button')}
              </Button>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }
);

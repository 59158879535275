import { CellContext } from '@tanstack/react-table';
import { HookFormPaginatedComboBox } from 'components/HookFormInputs/HookFormPaginatedComboBox';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { TableFormRow } from '../../schema/bookingsFormSchema';

type Value = TableFormRow['generalLedgerAccount'];

export const GeneralLedgerAccountCell: React.FC<
  CellContext<TableFormRow, Value>
> = ({
  column: {
    columnDef: { meta },
  },
  row: { index },
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);
  const { options, readOnly } = meta ?? {}; // TODO add types if possible without effecting global table instance

  return (
    <HookFormPaginatedComboBox
      name={`bookings.${index}.generalLedgerAccount`}
      data-cy={`bookings.${index}.generalLedgerAccount`}
      readOnly={readOnly}
      placeholder={
        readOnly
          ? t('inputs.generalLedger.placeholder.notSet')
          : t('inputs.generalLedger.placeholder.enabled')
      }
      {...options}
    />
  );
};

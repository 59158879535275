import {
  CoreDataTypes,
  useCandisApiCoreDataImportHistoryQuery,
} from 'generated-types/graphql.types';

interface CandisApiCoreDataImportHistory {
  coreDataType: CoreDataTypes;
}

export const useCoreDataImportHistory = ({
  coreDataType,
}: CandisApiCoreDataImportHistory) => {
  const { data, loading: isImportHistoryDataLoading } =
    useCandisApiCoreDataImportHistoryQuery({
      variables: { type: coreDataType },
    });

  const historyItems = data?.candisApiCoreDataImportHistory ?? [];

  return {
    historyItems,
    isImportHistoryDataLoading,
  };
};

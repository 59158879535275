import { DetailsBox } from 'components/PurchaseOrders/PurchaseOrderDetailsCard/DetailsBox';
import { Divider } from 'components/PurchaseOrders/PurchaseOrderDetailsCard/Divider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { goodsReceiptSapStatuses } from 'views/Archive/GoodsReceipts/consts';
import { statusInSapFilters } from 'views/Archive/PurchaseOrders/utils';
import {
  AssociatedGoodsReceipt,
  AssociatedPurchaseOrder,
  AssociatedDocumentsObject,
} from './types';

const GoodsReceiptInfoBox = ({
  goodsReceipt,
}: {
  goodsReceipt?: Partial<AssociatedGoodsReceipt>;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { receiptNumber, deliveryDate, status, amount, supplierRefNumber } =
    goodsReceipt || {};

  const statusLabel = useMemo(() => {
    return goodsReceiptSapStatuses.find(option => option.id === status)?.label;
  }, [status]);

  return (
    <DetailsBox
      itemNumber={receiptNumber}
      date={deliveryDate}
      statusLabel={statusLabel ? t('' + statusLabel) : ''}
      boxType="goodsReceipt"
      amount={amount}
      supplierRefNumber={supplierRefNumber}
    />
  );
};

const PurchaseOrderInfoBox = ({
  purchaseOrder,
}: {
  purchaseOrder?: Partial<AssociatedPurchaseOrder>;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { orderNumber, postingDate, amount, status, supplierRefNumber } =
    purchaseOrder || {};

  const statusLabel = useMemo(() => {
    return statusInSapFilters.find(option => option.id === status)?.label;
  }, [status]);

  return (
    <DetailsBox
      boxType="purchaseOrder"
      itemNumber={orderNumber}
      date={postingDate}
      statusLabel={statusLabel ? t('' + statusLabel) : ''}
      amount={amount}
      supplierRefNumber={supplierRefNumber}
    />
  );
};

const renderRelatedGoodsReceipts = (purchaseOrder: AssociatedPurchaseOrder) => {
  const goodsReceipts = purchaseOrder?.goodsReceipts;

  return goodsReceipts?.length
    ? goodsReceipts.map((goodsReceipt, index) => (
        <Fragment key={goodsReceipt?.id}>
          {index === 0 && <Divider showLinkedIcon />}
          <GoodsReceiptInfoBox goodsReceipt={goodsReceipt} />
          {index !== goodsReceipts.length - 1 && <Divider showLinkedIcon />}
        </Fragment>
      ))
    : null;
};

const renderRelatedPurchaseOrders = (goodsReceipt: AssociatedGoodsReceipt) => {
  const purchaseOrders = goodsReceipt.purchaseOrders;

  return purchaseOrders?.length
    ? purchaseOrders.map((po, index) => (
        <Fragment key={po?.id}>
          {index === 0 && <Divider showLinkedIcon />}
          <PurchaseOrderInfoBox purchaseOrder={po} />
          {index !== purchaseOrders.length - 1 && <Divider showLinkedIcon />}
        </Fragment>
      ))
    : null;
};

const PurchaseOrderDetailsCard = ({
  purchaseOrder,
  hasNextPurchaseOrder,
}: {
  purchaseOrder: AssociatedPurchaseOrder;
  hasNextPurchaseOrder?: boolean;
}) => {
  return (
    <>
      {hasNextPurchaseOrder && <Divider />}
      <PurchaseOrderInfoBox purchaseOrder={purchaseOrder} />
      {renderRelatedGoodsReceipts(purchaseOrder)}
    </>
  );
};
const GoodsReceiptsDetailsCard = ({
  goodsReceipt,
  hasNextGoodsReceipt,
}: {
  goodsReceipt: AssociatedGoodsReceipt;
  hasNextGoodsReceipt?: boolean;
}) => {
  return (
    <>
      {hasNextGoodsReceipt && <Divider />}
      <GoodsReceiptInfoBox goodsReceipt={goodsReceipt} />
      {renderRelatedPurchaseOrders(goodsReceipt)}
    </>
  );
};
// TODO TPX better to show placeholder while loading
export const PurchaseOrderSection = ({
  associatedDocuments,
}: {
  associatedDocuments: AssociatedDocumentsObject;
}) => {
  const { associatedGoodsReceipts, associatedPurchaseOrders } =
    associatedDocuments;

  if (associatedPurchaseOrders.length) {
    return associatedPurchaseOrders.map((purchaseOrder, index) => (
      <PurchaseOrderDetailsCard
        key={purchaseOrder.id}
        purchaseOrder={purchaseOrder}
        hasNextPurchaseOrder={
          index !== 0 && !!purchaseOrder?.goodsReceipts?.length
        }
      />
    ));
  }

  if (associatedGoodsReceipts.length) {
    return associatedGoodsReceipts.map((goodsReceipt, index) => (
      <GoodsReceiptsDetailsCard
        key={goodsReceipt.id}
        goodsReceipt={goodsReceipt}
        hasNextGoodsReceipt={index !== 0}
      />
    ));
  }
  return null;
};

import { Avatar, Grid } from '@candisio/design-system';
import { AvatarUpload } from 'components/AvatarUpload/AvatarUpload';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { Locale } from 'generated-types/graphql.types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserTeamsWithLinks } from 'views/AppContainer/components/Header/components/UserProfile/UserTeams/UserTeamsWithLinks';
import { RolesSection } from 'views/Settings/TeamMembers/TeamMemberDetails/containers/invite/RolesSection/RolesSection';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';
import { Team } from 'views/Settings/Teams/toolkit/hooks/useGetTeams';
import { ReadonlyEmailField } from '../components/ReadonlyEmailField/ReadonlyEmailField';
import { TeamMemberFormOutput } from '../teamMemberFormSchema';
import { PendingStatusBar } from './PendingStatusBar';

export interface PendingTeamMemberFormProps {
  isSubmittingCancelInvite: boolean;
  isSubmittingResendInvite: boolean;
  isSubmittingUpdateUser: boolean;
  loading: boolean;
  onSubmit: (data: TeamMemberFormOutput) => Promise<any>;
  onResendInvite: () => void;
  onCancelInvite: () => void;
  selectedUser: User;
  formId: string;
  hide?: { firstName?: boolean; lastName?: boolean; roles?: boolean };
  teams: Team[];
}

export const PendingTeamMemberForm = ({
  isSubmittingCancelInvite,
  isSubmittingResendInvite,
  isSubmittingUpdateUser,
  loading,
  onSubmit,
  onCancelInvite,
  onResendInvite,
  selectedUser,
  formId,
  hide,
  teams,
}: PendingTeamMemberFormProps) => {
  const [t] = useTranslation();

  const isSubmitting =
    isSubmittingCancelInvite ||
    isSubmittingResendInvite ||
    isSubmittingUpdateUser;

  const disabled = loading || isSubmitting;
  const locales = Object.values(Locale).map(value => ({
    children: t(`locales.${value}`),
    key: value,
  }));

  const form = useFormContext<TeamMemberFormOutput>();

  return (
    <Grid
      id={formId}
      as="form"
      onSubmit={form.handleSubmit(onSubmit)}
      gap="space24"
      templateRows="1fr auto"
    >
      <PendingStatusBar
        disabled={disabled}
        onSendInviteAgain={onResendInvite}
        onCancelInvite={onCancelInvite}
        sendInviteLoading={isSubmittingResendInvite}
        cancelInviteLoading={isSubmittingCancelInvite}
      />
      <Grid gap="space24">
        <AvatarUpload readonly>
          <Avatar
            size="large"
            name={selectedUser?.name}
            img={selectedUser?.avatarUrl ?? undefined}
            hasTooltip={false}
          />
        </AvatarUpload>
        <Grid
          background="gray100"
          borderRadius="8px"
          padding="space16"
          rowGap="space12"
          columnGap="space32"
          templateColumns="1fr 1fr 1fr 1fr"
          templateRows="auto auto auto"
        >
          {hide?.firstName ? null : (
            <Grid gridRow="1" gridColumn="1/3">
              <HookFormTextField
                label={t('settings.teamMembers.form.firstName.label')}
                name="firstName"
                autoFocus
              />
            </Grid>
          )}
          {hide?.lastName ? null : (
            <Grid gridRow="1" gridColumn="3/5">
              <HookFormTextField
                name="lastName"
                label={t('settings.teamMembers.form.lastName.label')}
              />
            </Grid>
          )}
          <Grid
            gridRow="2"
            gridColumn="1/4"
            autoFlow="column"
            gap="space12"
            alignItems="center"
          >
            <ReadonlyEmailField fieldName="email" />
          </Grid>
          <Grid gridRow="3" gridColumn="1/3">
            <HookFormSelectField
              name="locale"
              label={t('settings.teamMembers.form.locale')}
              items={locales}
            />
          </Grid>
        </Grid>
        {hide?.roles ? null : <RolesSection />}

        <UserTeamsWithLinks teams={teams} />
      </Grid>
    </Grid>
  );
};

import { useApolloClient } from '@apollo/client';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { getOrgMembersAbsenceQuery } from 'containers/absence/gql';
import {
  Absence,
  Maybe,
  Query,
  SetAbsenceError,
  useGetCurrentUserAbsenceQuery,
  useSetCurrentUserAbsenceMutation,
} from 'generated-types/graphql.types';
import { useDateConverter } from 'hooks/useDateConverter';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useTranslation } from 'react-i18next';
import { isOnlyRequester } from 'utils/authorization';
import { AbsenceFormOutput, AbsenceFormValues } from './absenceFormSchema';
import { getCurrentUserAbsenceQuery, getMemberAbsenceQuery } from './gql';
import { useGetSetAbsenceInput } from './useGetSetAbsenceInput';
import { useEcm } from 'orgConfig/ecm/useEcm';

export const useCurrentUserAbsenceManagementUpdate = () => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();
  const currentUser = useCurrentUser();

  const client = useApolloClient();

  const { dateTimeStringToDateString } = useDateConverter();

  const { useNewApprovalQuery } = useEcm();

  const [setAbsence, { loading: setAbsenceLoading }] =
    useSetCurrentUserAbsenceMutation({
      refetchQueries: [
        getOrgMembersAbsenceQuery,
        getCurrentUserAbsenceQuery,
        {
          query: getMemberAbsenceQuery,

          variables: {
            id: currentUser?.id,
          },
        },
      ],
      onCompleted: async data => {
        if (data.setCurrentUserAbsence?.__typename !== 'Absence') return;

        // Documents may be affected by substitute being assigned to them. To be safe, we force refetch of below queries.
        (
          [
            'getDocument',
            'archivedDocuments',
            useNewApprovalQuery
              ? 'approvalInvoiceDocuments'
              : 'listApprovalsDocuments',
          ] as (keyof Query)[]
        ).forEach(fieldName => {
          client.cache.evict({ fieldName });
        });
      },
    });

  const {
    data: currentUserAbsenceData,
    loading: currentUserAbsenceDataLoading,
  } = useGetCurrentUserAbsenceQuery();

  const currentUserAbsence = currentUserAbsenceData?.getCurrentUserAbsence;

  const { getSetAbsenceInput } = useGetSetAbsenceInput();

  const handleSubmit = async (
    formOutput: AbsenceFormOutput
  ): Promise<Maybe<Absence> | Maybe<SetAbsenceError>> => {
    const input = getSetAbsenceInput(formOutput);

    const result = await setAbsence({
      variables: {
        input,
      },
    });

    if (result.data?.setCurrentUserAbsence.__typename === 'Absence') {
      if (!Boolean(input.fromDate) || !Boolean(input.toDate)) {
        success(t('header.profile.tabs.update.absence.form.reset'));

        return result.data?.setCurrentUserAbsence;
      }

      success(t('header.profile.tabs.update.absence.form.success'));

      return result.data?.setCurrentUserAbsence;
    }

    if (result.data?.setCurrentUserAbsence.__typename === 'SetAbsenceError') {
      error(t('header.profile.tabs.update.absence.form.error'));

      return result.data?.setCurrentUserAbsence;
    }
  };

  const defaultFromDate = currentUserAbsence?.fromDate
    ? dateTimeStringToDateString(currentUserAbsence.fromDate)
    : null;

  const defaultToDate = currentUserAbsence?.toDate
    ? dateTimeStringToDateString(currentUserAbsence.toDate)
    : null;

  const defaultValues: AbsenceFormValues = {
    fromDate: defaultFromDate,
    toDate: defaultToDate,
    note: currentUserAbsence?.note ?? null,
    substitute: isOnlyRequester(currentUser)
      ? null
      : (currentUserAbsence?.substituteMembershipId ?? null),
  };

  return {
    defaultValuesLoading: currentUserAbsenceDataLoading,
    setAbsence: handleSubmit,
    defaultValues,
    setAbsenceLoading,
  };
};

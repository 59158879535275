import { Button, Flex, Heading } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ApprovalsHeaderProps {
  isButtonDisabled: boolean;
  onClick: () => void;
}

export const ApprovalsHeader = ({
  isButtonDisabled,
  onClick,
}: ApprovalsHeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.APPROVALS);
  return (
    <Flex alignItems="center" gap="space16">
      <Heading as="h1">{t('title')}</Heading>
      <Button size="small" disabled={isButtonDisabled} onClick={onClick}>
        {t('actions.startApproving')}
      </Button>
    </Flex>
  );
};

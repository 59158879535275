import styles from './CreditCardDashboardLayout.module.css';
import { CreditCardsHeader } from './CreditCardsHeader';
import {
  CreditCardsTab,
  CreditCardsTabs,
} from './CreditCardsTabs/CreditCardsTabs';
import { ReactNode } from 'react';

export const CreditCardDashboardLayout = ({
  children,
  selectedTab,
}: { children: ReactNode; selectedTab: CreditCardsTab }) => {
  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <CreditCardsHeader />
        <CreditCardsTabs defaultSelectedKey={selectedTab} />
      </div>
      {children}
    </div>
  );
};

export const CreditCardCardOverviewLayout = ({
  children,
}: { children: ReactNode }) => {
  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <CreditCardsHeader />
      </div>
      {children}
    </div>
  );
};

import { SapExpenseType } from 'generated-types/graphql.types';
import { isValidBIC, isValidIBAN } from 'ibantools';
import { isNil } from 'lodash';
import { ValuesType } from 'utility-types';
import { ibanSchema } from 'utils/zodFormValidation';
import {
  isSwiftCountryCodeMatch,
  swiftCodeSchema,
} from 'utils/zodFormValidation/Schemas/swiftCodeSchema';
import { PaymentStatus } from 'views/Payments/types';
import { z } from 'zod';

export interface DocumentFormSchemaOptions {
  hasTransactionLinked?: boolean;
}

const formSchema = z.object({
  accountingArea: z.object({
    value: z.string().nullish(),
    inputValue: z.string().nullish(),
  }),
  accountsPayableNumber: z.string().nullish(),
  accountsReceivableNumber: z.string().nullish(),
  bookings: z.array(
    z.object({
      amount: z.number().nullish(),
      artistSocialInsuranceCode: z.string().nullish(),
      /**
       * We use `bookingId` instead of `id` to avoid clashing with the `id`
       * property set by React Hook Form’s `useFieldArray` hook
       */
      bookingId: z.string().nullish(),
      bookingKey: z.object({
        value: z.string().nullish(),
        inputValue: z.string().nullish(),
      }),
      costCenter: z.object({
        value: z.string().nullish(),
        inputValue: z.string().nullish(),
      }),
      costObject: z.object({
        value: z.string().nullish(),
        inputValue: z.string().nullish(),
      }),
      dueDate: z.date().nullish(),
      extraCostInfo: z.object({
        value: z.string().nullish(),
        inputValue: z.string().nullish(),
      }),
      generalLedgerAccount: z.object({
        value: z.string().nullish(),
        inputValue: z.string().nullish(),
      }),
      note: z.string().nullish(),
      postingText: z.string().nullish(),
      quantity: z.union([z.number().min(1), z.undefined()]),
      vatRate: z.number().min(0).max(99.99).step(0.01).nullish(),
      netAmount: z.number().nullish(),
      taxAmount: z.number().nullish(),
      sapExpenseType: z.nativeEnum(SapExpenseType).nullish(),
      unitPrice: z.number().nullish(),
      projectCode: z
        .object({
          value: z.string().nullish(),
          inputValue: z.string().nullish(),
        })
        .nullish(),
    })
  ),
  category: z.object({
    type: z.string().nullish(),
    direction: z.string().nullish(),
  }),
  cashDiscount: z.object({
    amount: z.number().nullish(),
    dueDate: z.date().nullish(),
    percentage: z
      .number()
      .min(0.01)
      .max(99.99)
      .step(0.01)
      .nullable()
      .optional(),
  }),
  contact: z.string().nullish(),
  contactId: z.string().nullish(),
  createTransfer: z.boolean(),
  currency: z.string(),
  grossAmount: z
    .number()
    .step(0.01)
    .and(z.number().min(0.01).or(z.number().max(-0.01))),
  hasTransactions: z.boolean(),
  iban: ibanSchema.nullish(),
  invoiceCorrection: z.boolean(),
  invoiceDate: z.date().nullish(),
  invoiceDeliveryDate: z.date().nullish(),
  invoicePostingDate: z.date().nullish(),
  invoiceDelivery: z.date().nullish(),
  invoiceNumber: z.string().nullish(),
  purchaseOrderNumber: z.string().nullish(),
  paidAt: z.string().nullish(),
  paymentStatus: z.enum([PaymentStatus.PAID, PaymentStatus.UNPAID]),
  swiftCode: swiftCodeSchema.nullish(),
  vatRate: z.number().nullish(),
  roundingAmount: z.number().nullish(),
  netAmount: z.number().nullish(),
  taxAmount: z.number().nullish(),
});

export const paidAtSchema = formSchema
  .pick({ paymentStatus: true, paidAt: true })
  .superRefine(({ paymentStatus, paidAt }, ctx) => {
    if (paymentStatus === PaymentStatus.PAID && paidAt === null) {
      ctx.addIssue({
        code: 'custom',
        path: ['paidAt'],
        params: {
          translationKey: 'document.requestApproval.inputs.errors.paidAt',
        },
      });
    }
  });

export const bankInfoSchema = ({
  hasTransactionLinked = false,
}: DocumentFormSchemaOptions) => {
  return formSchema
    .pick({ iban: true, swiftCode: true })
    .superRefine(({ iban, swiftCode }, ctx) => {
      if (isNil(iban) && swiftCode) {
        ctx.addIssue({
          code: 'custom',
          path: ['iban'],
          params: {
            translationKey:
              'document.requestApproval.inputs.errors.ibanEmptySwiftPresent',
          },
        });

        return;
      }

      if (
        !hasTransactionLinked &&
        iban &&
        iban.startsWith('GB') &&
        !swiftCode
      ) {
        ctx.addIssue({
          code: 'custom',
          path: ['swiftCode'],
          params: {
            translationKey:
              'document.requestApproval.inputs.errors.swiftCodeRequiredForGBIban',
          },
        });

        return;
      }

      if (
        iban &&
        swiftCode &&
        isValidIBAN(iban) &&
        isValidBIC(swiftCode) &&
        !isSwiftCountryCodeMatch({ iban, swiftCode })
      ) {
        ctx.addIssue({
          code: 'custom',
          path: ['swiftCode'],
          params: {
            translationKey:
              'document.requestApproval.inputs.errors.swiftCodeIbanCountryCodeMismatch',
          },
        });

        return;
      }
    });
};

export const documentFormSchema = (
  { hasTransactionLinked }: DocumentFormSchemaOptions = {
    hasTransactionLinked: false,
  }
) => formSchema.and(bankInfoSchema({ hasTransactionLinked })).and(paidAtSchema);

export type DocumentFormValues = z.infer<ReturnType<typeof documentFormSchema>>;

export type BookingFieldValues = ValuesType<DocumentFormValues['bookings']>;

import { Box, Button, Flex, Grid, useTheme } from '@candisio/design-system';
import { useNew_DatevSettingsQuery } from 'generated-types/graphql.types';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { ExportItem } from 'providers/ExportNotificationsProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { buildDatevProfileLink } from 'utils/build_datev_link';
import { useDefineExportView } from 'views/Integrations/Export/Manifest';
import { Sidebar, View } from 'views/Integrations/Export/types';
import { EmptyNotificationList } from '../../EmptyNotificationList/EmptyNotificationList';
import { ExportNotificationItem } from './ExportNotificationItem/ExportNotificationItem';

export const ExportNotificationsContent = ({
  exportItems,
  onClose,
  onClear,
}: {
  exportItems: ExportItem[];
  onClose: () => void;
  onClear: () => void;
}) => {
  const [t] = useTranslation();
  const organizationSlug = useOrganizationId();
  const { isActive: isSapActive } = useSap();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const { colors } = useTheme();

  const {
    data: { new_datevSettings: datevSettings = null } = {},
    loading,
  } = useNew_DatevSettingsQuery();

  const { consultantNumber, clientNumber } = datevSettings?.client || {};

  const datevProfileLink = buildDatevProfileLink({
    consultantNumber,
    clientNumber,
  });

  const navigate = useNavigate();
  const viewDef = useDefineExportView({
    sidebar: Sidebar.INIT_NEW_EXPORT,
    view: View.READY,
  });

  const startExportLink = `/${organizationSlug}${viewDef.url}`;

  const showDatevAction = !shouldUseCoreDataApi && !isSapActive;
  const showExportAction = !shouldUseCoreDataApi;

  return (
    <Flex
      height="100%"
      padding={0}
      justifyContent="space-between"
      direction="column"
      data-cy="exportNotificationContent"
    >
      {!!exportItems.length ? (
        <Box
          as="ul"
          listStyle="none"
          padding="0px space10 space32 space16"
          overflowY="auto"
        >
          {exportItems.map(exportItem => (
            <ExportNotificationItem
              key={exportItem.hash}
              exportItem={exportItem}
              onClose={onClose}
            />
          ))}
        </Box>
      ) : (
        <EmptyNotificationList
          title={t(
            'navigation:notifications.emptyList.currentCompany.export.title'
          )}
          description={t(
            'navigation:notifications.emptyList.currentCompany.export.description'
          )}
        />
      )}
      <Grid
        position="sticky"
        bottom={0}
        templateColumns="1fr auto"
        justifyContent="space-between"
        alignItems="center"
        padding="space10 space12"
        background="gray100"
      >
        <Button
          size="small"
          variant="tertiary"
          justifySelf={showExportAction ? 'start' : 'end'}
          onClick={onClear}
          disabled={!exportItems.length}
        >
          {t('uploads.clear')}
        </Button>
        <Flex justifyContent="space-between" alignItems="center" gap="space24">
          {showDatevAction && (
            <Button
              as="a"
              href={datevProfileLink}
              rel="noopener noreferrer"
              target="_blank"
              size="small"
              loading={loading}
              variant="tertiary"
              style={{ color: colors.gray800 }}
            >
              {t('exports.datevLabel')}
            </Button>
          )}
          {showExportAction && (
            <Button
              size="small"
              variant="tertiary"
              onClick={() => {
                onClose();
                navigate(startExportLink);
              }}
            >
              {t('exports.start')}
            </Button>
          )}
        </Flex>
      </Grid>
    </Flex>
  );
};

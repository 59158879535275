import { PaginationWithSearchFilterHook } from 'components/Table/Filters/FilterWithSearchAndPagination/utils';
import { useApproversPaginatedFilterOptions } from '../hooks/useApproversPaginatedFilterOptions';
import { useTargetMembershipsPaginatedFilterOptions } from '../hooks/useTargetmembershipsPaginatedFilterOptions';
import { useRequesterFilter } from 'components/Table/Filters/FilterWithSearchAndPagination/hooks/useRequesterFilter';

export const paginationFiltersHooks: Record<
  string,
  PaginationWithSearchFilterHook
> = {
  requesterOfTheReimbursement: useTargetMembershipsPaginatedFilterOptions,
  lastApprovedByMembership: useApproversPaginatedFilterOptions,
  requester: useRequesterFilter,
  approvers: useApproversPaginatedFilterOptions,
};

import { Item } from '@candisio/design-system';
import nomembers from 'assets/illustration-no-member.svg';
import { FilterableList } from 'components/FilterableList/FilterableList';
import { EmptyDataState } from 'components/FilterableList/components/EmptyDataState';
import { EmptySearchState } from 'components/FilterableList/components/EmptySearchState';
import { MembershipsKebabMenu } from 'components/Menu/MembershipsKebabMenu/MembershipsKebabMenu';
import { BuiltinRoleName } from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useTeams } from 'orgConfig/teams/useTeams';
import Papa from 'papaparse';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { Key, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils/downloadFile';
import { defaultRolesTranslations } from 'views/Settings/Roles/toolkit/utils/defaultRolesTranslations';
import { Role } from '../hooks/useGetRolesForUser';
import { Tab, User, UsersSortBy } from '../hooks/useGetUsers';
import { usePermissionsForTeamMembers } from '../hooks/usePermissionsForTeamMembers';
import { UserItem } from './components/UserItem';

const translationsTeams = {
  emptyState: 'settings.users.list.emptyState',
  search: 'settings.users.actions.search',
  create: 'settings.users.actions.new',
};

const translationsDefault = {
  emptyState: 'settings.teamMembers.list.emptyState',
  search: 'settings.teamMembers.actions.search',
  create: 'settings.teamMembers.actions.new',
};

function downloadBlob(
  content: string[],
  filename: string,
  contentType: string
) {
  const blob = new Blob(content, { type: contentType });
  const url = URL.createObjectURL(blob);

  downloadFile(url, filename);

  URL.revokeObjectURL(url);
}

interface UsersListViewProps {
  users: User[];
  roles: Role[];
  queryString: string;
  isLoadingUsers: boolean;
  isLoadingRoles: boolean;
  sortBy: UsersSortBy;
  activeTab: Tab;
  onSearch: (search: string) => void;
  onSetSortBy: (sortBy: Key[]) => void;
  onOpenInviteForm: () => void;
  toggleDrawer: (
    id: string & {
      emailVerified: boolean;
    }
  ) => void;
}

export enum LocalMembershipStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
}

export const DEFAULT_ROLE_FILTER = 'allRoles';
export const ROLE_FILTER_PARAM = 'roleName';

export const UsersListView = ({
  users,
  activeTab,
  onSearch,
  onSetSortBy,
  onOpenInviteForm,
  toggleDrawer,
  isLoadingRoles,
  isLoadingUsers,
  queryString,
  roles,
  sortBy,
}: UsersListViewProps) => {
  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const orgId = useOrganizationId();
  const [t] = useTranslation();
  const { canInviteMember } = usePermissionsForTeamMembers();
  const roleFilter = searchParams.get(ROLE_FILTER_PARAM) ?? DEFAULT_ROLE_FILTER;

  const { isTeamsFeatureEnabled } = useTeams();

  const searchReset = () => {
    onSearch('');
  };

  const creditCardsSetup = useCreditCardsSetup();

  const handleExport = () => {
    const data = users.map(member => {
      const roleNames = (member.membershipRoles ?? []).map(role => role.name);

      return {
        [t('settings:memberships.contextMenu.csvHeader.name')]: member.name,
        [t('settings:memberships.contextMenu.csvHeader.email')]: member.email,
        [t('settings:memberships.contextMenu.csvHeader.isAdmin')]:
          roleNames.includes(BuiltinRoleName.Admin),
        [t('settings:memberships.contextMenu.csvHeader.isApprover')]:
          roleNames.includes(BuiltinRoleName.Approver),
        [t('settings:memberships.contextMenu.csvHeader.isRequester')]:
          roleNames.includes(BuiltinRoleName.Requester),
        [t('settings:memberships.contextMenu.csvHeader.isOwner')]:
          roleNames.includes(BuiltinRoleName.Owner),
        [t('settings:memberships.contextMenu.csvHeader.isAccountant')]:
          roleNames.includes(BuiltinRoleName.Accountant),
        ...(creditCardsSetup.isInUse
          ? {
              [t('settings:memberships.contextMenu.csvHeader.isCardAdmin')]:
                roleNames.includes(BuiltinRoleName.CardManager),
            }
          : {}),
      };
    });

    const csv = Papa.unparse(data);

    const statusMap: Record<'ACTIVE' | 'INACTIVE' | 'PENDING', string> = {
      ACTIVE: 'settings.teamMembers.actions.tabs.ACTIVE',
      INACTIVE: 'settings.teamMembers.actions.tabs.INACTIVE',
      PENDING: 'settings.teamMembers.actions.tabs.PENDING',
    };

    const readableMembershipStatus = t(statusMap[activeTab]).toLowerCase();

    downloadBlob(
      [csv],
      t('settings:memberships.contextMenu.csvName', {
        org: orgId,
        membershipStatus: readableMembershipStatus,
      }),
      'text/csv;charset=utf-8;'
    );
  };

  const sortItems: Array<{ id: UsersSortBy; label: string }> = [
    {
      id: 'alphabetically',
      label: t('settings.teamMembers.filters.sortAlphabetically'),
    },
    {
      id: 'lastAddedFirst',
      label: t('settings.teamMembers.filters.lastAddedFirst'),
    },
  ];

  const roleItems = useMemo(() => {
    return [
      {
        id: DEFAULT_ROLE_FILTER,
        label: t('settings.teamMembers.filters.showAllRoles'),
      },
      ...roles.map(role => ({
        id: role.name,
        label: t(`settings:${defaultRolesTranslations[role.name]}`),
      })),
    ];
  }, [roles, t]);

  const sortButtonText = sortItems.find(item => sortBy === item.id)?.label;

  const sortRolesByButtonText =
    roleFilter === DEFAULT_ROLE_FILTER
      ? t('settings.teamMembers.filters.showAllRoles')
      : roleItems.find(item => roleFilter === item.id)?.label;

  const handleRoleChange = (filter: Key[]) => {
    const filterValue = filter[0] as string;
    const selectedRole = filterValue === DEFAULT_ROLE_FILTER ? '' : filterValue;

    updateSearchParam(ROLE_FILTER_PARAM, selectedRole);
  };

  const translations = isTeamsFeatureEnabled
    ? translationsTeams
    : translationsDefault;

  return (
    <FilterableList
      children={users.map(user => (
        <Item textValue={user.name} key={user.id}>
          <UserItem
            activeTab={activeTab}
            user={user}
            queryString={queryString}
            toggleDrawer={toggleDrawer}
          />
        </Item>
      ))}
      emptyDataState={
        <EmptyDataState
          translation={translations.emptyState}
          url={
            activeTab !== LocalMembershipStatus.INACTIVE
              ? `.${Routes.TEAM_MEMBERS}/invite`
              : undefined
          }
          imageUrl={nomembers}
        />
      }
      emptySearchState={<EmptySearchState searchReset={searchReset} />}
      onCreate={
        canInviteMember
          ? {
              value: onOpenInviteForm,
              text: t(translations.create),
            }
          : undefined
      }
      isLoading={isLoadingUsers || isLoadingRoles}
      menuButtons={[
        {
          actionValue: [sortBy],
          onClick: onSetSortBy,
          text: sortButtonText ?? '',
          menuButtonItems: sortItems,
        },
        {
          actionValue: [roleFilter],
          onClick: handleRoleChange,
          text: sortRolesByButtonText ?? '',
          menuButtonItems: roleItems,
          loading: isLoadingRoles,
        },
      ]}
      searchField={{
        onSearchFilter: onSearch,
        placeholder: t(translations.search),
        searchQuery: queryString,
      }}
      kebabMenu={<MembershipsKebabMenu onExport={handleExport} />}
    />
  );
};

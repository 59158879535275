import { FieldValues } from 'react-hook-form';
import {
  HookFormNumberInput,
  HookFormNumberInputProps,
} from './HookFormNumberInput';
import { useNumberFormatOptions } from './useFormatOptions';

export interface HookFormAmountInputProps<TFormValues extends FieldValues>
  extends HookFormNumberInputProps<TFormValues> {
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}

/**
 * A `HookFormAmountInput` for entering monetary amounts
 */
export const HookFormAmountInput = <TFormValues extends FieldValues>({
  currency,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
  ...restProps
}: HookFormAmountInputProps<TFormValues>) => {
  // useNumberField's formatOptions must be memoized:
  // https://github.com/adobe/react-spectrum/issues/1893
  const formatOptions = useNumberFormatOptions({
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
  });

  return <HookFormNumberInput formatOptions={formatOptions} {...restProps} />;
};

import { useSearchInput } from 'components/AdvancedSearchFieldFilterOverlay/useSearchInput';
import { queryParameter } from 'components/Table/consts';
import {
  GetApprovalInvoiceDocumentsBasicsQuery,
  useGetApprovalInvoiceDocumentsBasicsQuery,
} from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useComputeQueryFilters } from 'views/Archive/ArchiveDocumentsTable/hooks/useComputeQueryFilters';
import { computePaginationVariables } from 'views/queries';
import { getApprovalInvoiceDocumentsBasicsQuery } from '../queries';

const useApprovalsData = ({
  filters = {},
  skip,
}: { filters?: Record<string, any>; skip: boolean }) => {
  return useGetApprovalInvoiceDocumentsBasicsQuery({
    variables: {
      filters,
    },
    skip,
  });
};

export const useApprovalTabNavigationData = ({ skip }: { skip: boolean }) => {
  const currentUser = useCurrentUser();
  const currentUserId = currentUser?.id || '';
  const { searchParams } = useMutateSearchParams();

  const searchQuery = searchParams.get(queryParameter) ?? '';

  const { searchInputVariable } = useSearchInput();

  // Compose variables that ensure the user is an Approver for the first document on startWork
  const startWorkVariables = computePaginationVariables();
  startWorkVariables.limit = 1;

  if (currentUser?.id && !startWorkVariables.filters.approverIds) {
    startWorkVariables.filters.approverIds = [currentUser.id];
  }

  const computedFilters = useComputeQueryFilters();

  const approvalDocumentsBasicsQueryResult =
    usePagination<GetApprovalInvoiceDocumentsBasicsQuery>(
      getApprovalInvoiceDocumentsBasicsQuery,
      'approvalInvoiceDocuments',
      {
        skip,
        variables: {
          ...computedFilters,
          query: searchQuery,
          searchInput: searchInputVariable,
        },
      }
    );

  const approvalsRequestedByCurrentUser = useApprovalsData({
    filters: {
      requesterIds: [currentUserId],
    },
    skip,
  });

  const approvalsAssignedToCurrentUser = useApprovalsData({
    filters: { approverIds: [currentUserId] },
    skip,
  });

  const allApprovals = useApprovalsData({ skip });

  const approvalsRequestedByCurrentUserCount =
    approvalsRequestedByCurrentUser.loading ||
    approvalsRequestedByCurrentUser.data?.approvalInvoiceDocuments.pageInfo
      ?.totalCount === 0
      ? undefined
      : approvalsRequestedByCurrentUser.data?.approvalInvoiceDocuments.pageInfo?.totalCount?.toString();

  const approvalsAssignedToCurrentUserCount =
    approvalsAssignedToCurrentUser.loading ||
    approvalsAssignedToCurrentUser.data?.approvalInvoiceDocuments.pageInfo
      ?.totalCount === 0
      ? undefined
      : approvalsAssignedToCurrentUser.data?.approvalInvoiceDocuments.pageInfo?.totalCount?.toString();

  const allDocumentsCount =
    allApprovals.loading ||
    allApprovals.data?.approvalInvoiceDocuments.pageInfo?.totalCount === 0
      ? undefined
      : allApprovals.data?.approvalInvoiceDocuments.pageInfo?.totalCount?.toString();

  return {
    approvalDocumentsBasicsQueryResult,
    approvalsRequestedByCurrentUserCount,
    approvalsAssignedToCurrentUserCount,
    allDocumentsCount,
  };
};

import gql from 'graphql-tag';
import {
  goodsReceiptsImportHistoryFragment,
  pageInfoDataFragment,
} from 'views/queries/fragments';

export const getGoodsReceiptQuery = gql`
  query getGoodsReceipts(
    $input: GoodsReceiptPaginationInput!
    $query: String
    $queryFields: [GoodsReceiptQueryField!]
    $filters: GoodsReceiptFilterInput
    $sortBy: GoodsReceiptSortInput
  ) {
    inboxGoodsReceipts(
      input: $input
      query: $query
      queryFields: $queryFields
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          _id
          accountsPayableNumber
          contactName
          connectedPurchaseOrders {
            purchaseOrderId
            purchaseOrderNumber
          }
          deliveryDate
          status
          receiptNumber
          organizationId
          goodsLineItems {
            quantity
            itemNumber
            description
          }
          connectedInvoices {
            invoiceId
            invoiceNumber
          }
          amount {
            __typename
            amount
            currency
            precision
          }
          sapB1Data {
            supplierRefNumber
          }
        }
      }
      pageInfo {
        ...PageInfoData
      }
    }
  }
  ${pageInfoDataFragment}
`;

export const goodsReceiptsImportHistoryPaginationQuery = gql`
  query goodsReceiptsImportHistoryPagination(
    $input: ListGoodsReceiptImportHistoryEntriesInput
  ) {
    listGoodsReceiptImportHistoryEntries(input: $input)
      @connection(key: "goodsReceiptsImportHistoryPagination") {
      records {
        ...GoodsReceiptsImportHistoryData
      }
      pageInfo {
        totalCount
        pageSize
        currentPage
        pageCount
      }
    }
  }
  ${goodsReceiptsImportHistoryFragment}
`;

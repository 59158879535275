import { useCallback } from 'react';
import {
  GeneralExpenseItem,
  HospitalityExpenseItem,
  PerDiemItem,
  ReimbursementItemStatus,
  ReimbursementItemType,
  ReimbursementItemsListQuery,
  useReimbursementItemsListQuery,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';

type AllItemsFragments =
  ReimbursementItemsListQuery['reimbursementItemsList'][number];

type ExpenseFragment =
  | GeneralExpenseItem
  | HospitalityExpenseItem
  | PerDiemItem;

export interface ReimbursementItemForList {
  type: ReimbursementItemType;
  reason: string | undefined;
  totalAmount: string | undefined;
  status: ReimbursementItemStatus;
  updatedAt: string;
  id: string;
}

export const useReimbursementExpenseItemsList = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUseReimbursement } = useReimbursement();
  const { transactionAmountFormatter } = useMoneyFormatter();

  const {
    data,
    loading: loadingItems,
    refetch,
  } = useReimbursementItemsListQuery({
    variables: { filters: { reimbursementCaseId: reimbursementId } },
    skip: !reimbursementId || !canUseReimbursement,
  });

  const fetchedItems = data?.reimbursementItemsList ?? [];

  const filteredExpensesItems = fetchedItems.filter(
    (item: AllItemsFragments): item is ExpenseFragment => {
      return (
        item.__typename === 'GeneralExpenseItem' ||
        item.__typename === 'HospitalityExpenseItem' ||
        item.__typename === 'PerDiemItem'
      );
    }
  );

  const reimbursementItemsCount = (fetchedItems ?? []).length ?? 0;

  const reimbursementItems = (filteredExpensesItems ?? []).map(item => ({
    type: item.type,
    reason: item.title ?? undefined,
    id: item.id,
    status: item.status,
    updatedAt: item.updatedAt,
    totalAmount: !isNil(item.totalAmount?.amount)
      ? transactionAmountFormatter({
          currency: item.totalAmount.currency,
          precision: item.totalAmount.precision,
          value: item.totalAmount.amount,
        })
      : undefined,
  }));

  const refetchReimbursementItemsList = useCallback(() => refetch(), [refetch]);

  return {
    loadingItems,
    reimbursementItemsCount,
    reimbursementItems,
    refetchReimbursementItemsList,
  };
};

import { Text, TruncatedText } from '@candisio/design-system';
import { castArray } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const DefaultCell = ({
  value,
}: {
  value?: string | number | [string, ...string[]];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  if (!value) {
    return <Text>{t('emptyCellPlaceholder')}</Text>;
  }

  const values = castArray(value);

  return (
    <TruncatedText
      wordBreak="break-word"
      fontStyle={values.length > 1 ? 'italic' : undefined}
    >
      {String(values[0])}
    </TruncatedText>
  );
};

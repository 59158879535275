import { useApolloClient } from '@apollo/client';
import {
  DocumentType,
  useAddFileUploadMutation,
  useDocumentFileUploadUrlMutation,
  useProcessDocumentMutation,
  useProcessEcmDocumentMutation,
  useProcessFileMutation,
  useUpdateFileUploadMutation,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { getFileUploadAttemptsQuery } from 'views/AppContainer/components/NavigationSidebar/components/MenuItems/MenuDocumentUpload/queries';
import { delayRefetchedSensitiveContractsQueries } from 'views/Inbox/DocumentProcessing/components/Ecm/delayRefetchedSensitiveContractsQueries';
import {
  getEcmSensitiveDocumentsCountQuery,
  getEcmSensitiveDocumentsQuery,
} from 'views/Inbox/EcmSensitiveDocuments/gql';
import { List, useGetRefetchListQueries } from 'views/queries';
import { fileUploadUtil } from './fileUploadUtil';

type FileUploadHook = {
  onCompleted?: () => void;
};

export const useFileUpload = ({ onCompleted }: FileUploadHook = {}) => {
  const client = useApolloClient();
  const [addFile] = useAddFileUploadMutation();
  const [documentFileUploadUrl] = useDocumentFileUploadUrlMutation();
  const [processDocument] = useProcessDocumentMutation();
  const [processFile] = useProcessFileMutation();
  const [processEcmDocument] = useProcessEcmDocumentMutation();
  const [updateFile] = useUpdateFileUploadMutation();
  const counterQueries = useCounterQueries();
  const queriesToRefetch = useGetRefetchListQueries(List.INBOX_TABLE);

  const llmClassificationFF = useCandisFeatureFlags(
    FEATURE_FLAGS.llmClassification
  );

  const uploadFile = async (file: File, isSensitiveDocument?: boolean) => {
    const isXmlDocumentUploadAllowed = !isSensitiveDocument;

    await fileUploadUtil(file, isXmlDocumentUploadAllowed, {
      addFile,
      documentFileUploadUrl,
      updateFile,
      onUploaded: async fileId => {
        if (isSensitiveDocument) {
          const { data: processRes, errors } = await processEcmDocument({
            variables: {
              input: {
                fileId: fileId,
                documentType: DocumentType.Contract,
                isSensitive: true,
              },
            },
            refetchQueries: [
              getFileUploadAttemptsQuery,
              getEcmSensitiveDocumentsQuery,
              getEcmSensitiveDocumentsCountQuery,
            ],
            onCompleted,
            onQueryUpdated: delayRefetchedSensitiveContractsQueries,
          });

          if (errors) {
            throw new Error(errors[0].message);
          }

          return { documentId: processRes?.processEcmDocument?.ecmDocumentId };
        }

        if (llmClassificationFF) {
          const { data: processFileResponse, errors } = await processFile({
            variables: {
              input: {
                fileId,
              },
            },
            refetchQueries: [
              getFileUploadAttemptsQuery,
              ...queriesToRefetch,
              ...counterQueries,
            ],
            onCompleted() {
              client.cache.evict({
                fieldName: 'listInboxDocuments',
              });
              onCompleted?.();
            },
          });

          if (errors) {
            throw new Error(errors[0].message);
          }

          return {
            documentId: processFileResponse?.processFile?.globalDocumentId,
          };
        }

        // TODO: Remove this block after removing the LLM Classification feature flag
        const { data: processRes, errors } = await processDocument({
          variables: {
            fileId: fileId,
          },
          refetchQueries: [
            getFileUploadAttemptsQuery,
            ...queriesToRefetch,
            ...counterQueries,
          ],
          onCompleted() {
            client.cache.evict({
              fieldName: 'listInboxDocuments',
            });
            onCompleted?.();
          },
        });

        if (errors) {
          throw new Error(errors[0].message);
        }

        return { documentId: processRes?.processDocument?.id };
      },
    });
  };

  const uploadFiles = (
    files: FileList | null,
    isSensitiveDocument?: boolean
  ) => {
    if (!files) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      uploadFile(files[i], isSensitiveDocument);
    }
  };

  return { uploadFile, uploadFiles };
};

import {
  Flex,
  Icon,
  IconKey,
  Theme,
  TruncatedText,
} from '@candisio/design-system';
import styles from './Infocard.module.css';

interface InfoCardProps {
  value: number;
  text: string;
  color: keyof Theme['colors'];
  icon?: IconKey;
  onClick?: () => void;
  minWidth?: string;
}

export const InfoCard = ({
  color,
  icon,
  text,
  value,
  onClick,
}: InfoCardProps) => {
  return (
    <div className={styles.card} data-clickable={!!onClick} onClick={onClick}>
      <Flex alignItems="start" direction="column">
        <Flex alignItems="center" gap="space4">
          {icon ? <Icon icon={icon} color={color} size="space14" /> : null}
          <TruncatedText color={color} fontSize="xlarge">
            {String(value)}
          </TruncatedText>
        </Flex>
        <TruncatedText color={color} fontSize="basic">
          {text}
        </TruncatedText>
      </Flex>
    </div>
  );
};

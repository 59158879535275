import { forwardRef } from 'react';
import { Button, Tag, Badge, Link } from '@candisio/design-system';
import { Icon } from '@candisio/design-system';
import { Layout } from './Layout';
import styles from './PromoPartThree.module.css';
import { useTranslation } from 'react-i18next';

type PromoPartThreeProps = {
  onNext: () => void;
  onClose: () => void;
};

export const PromoPartThree = forwardRef<HTMLDivElement, PromoPartThreeProps>(
  ({ onNext, onClose }, ref) => {
    const [t] = useTranslation();

    const tabs = t('classification.promoModal.stepThree.table.tabs', {
      returnObjects: true,
    }) as { label: string; count: number }[]; // This key is an array in translation file

    const rows = t('classification.promoModal.stepThree.table.rows', {
      returnObjects: true,
    }) as {
      status: string;
      fileName: string;
      importedOn: string;
      documentType: string;
    }[]; // This key is an array in translation file

    return (
      <Layout
        ref={ref}
        className="whitespace-pre-line"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className="grid grid-cols-2">
          <div className="flex flex-col justify-center items-start gap-2">
            <div className="grid gap-10">
              <span className="text-6xl -tracking-[0.06em] font-light leading-14 w-[85%]">
                {t('classification.promoModal.stepThree.title')}
              </span>
              <span className="text-basic font-light tracking-tight leading-5 w-[85%]">
                {t('classification.promoModal.stepThree.description')}
              </span>
            </div>
            <Link
              external
              href={t('classification.promoModal.stepThree.helpCenterUrl')}
            >
              {t('classification.promoModal.stepThree.helpCenterText')}
            </Link>
          </div>
          <div className="grid items-center pointer-events-none select-none">
            <div className="grid gap-5">
              <span className="text-xxlarge">
                {t('classification.promoModal.stepThree.table.title')}
              </span>
              <div className={styles['tabs']}>
                {tabs.map(({ label, count }) => (
                  <div key={label} className={styles['tab']}>
                    {label}{' '}
                    <Badge color="darkGray" className={styles['badge']}>
                      {count}
                    </Badge>
                  </div>
                ))}
              </div>
              <div className="bg-white rounded-tl-medium pt-2 grid gap-2">
                <div className="pl-2">
                  <div className="rounded-medium grid grid-flow-col items-center max-w-75 justify-between p-2 bg-white border-1 border-gray-250">
                    <span className="text-gray-450 text-small">
                      {t(
                        'classification.promoModal.stepThree.table.searchFieldPlaceholder'
                      )}
                    </span>
                    <Icon icon="search" color="gray800" size="space14" />
                  </div>
                </div>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>
                        {t(
                          'classification.promoModal.stepThree.table.columns.status'
                        )}
                      </th>
                      <th>
                        {t(
                          'classification.promoModal.stepThree.table.columns.fileName'
                        )}
                      </th>
                      <th>
                        {t(
                          'classification.promoModal.stepThree.table.columns.importedOn'
                        )}
                      </th>
                      <th>
                        {t(
                          'classification.promoModal.stepThree.table.columns.documentType'
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map(
                      (
                        { documentType, fileName, importedOn, status },
                        index
                      ) => (
                        <tr key={index}>
                          <td>
                            <div>
                              <Tag variant="secondary" color="gray">
                                {status}
                              </Tag>
                            </div>
                          </td>
                          <td>
                            <div>{fileName}</div>
                          </td>
                          <td>
                            <div>{importedOn}</div>
                          </td>
                          <td>
                            <div>{documentType}</div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="justify-self-end">
          <Button color="gray" variant="secondary" onClick={onNext}>
            {t('classification.promoModal.stepThree.button')}
          </Button>
        </div>

        <div className="absolute top-0 right-0 pr-6 pt-6 z-10">
          <Button
            variant="tertiary"
            color="gray"
            icon="close"
            onClick={onClose}
          />
        </div>
      </Layout>
    );
  }
);

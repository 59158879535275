import {
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
  Icon,
} from '@candisio/design-system';
import { NotificationProps } from '../getNotificationInfo';
import { DateFormatters } from 'utils/date_formatter';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { Comment } from './Comment/Comment';
import { useTranslation } from 'react-i18next';
import { Header } from './Header/Header';

export const ReimbursementCaseRejectedInAppNotification = ({
  rejectedBy,
  rejectedAt,
  reimbursementCaseTitle,
  commentText,
}: NotificationProps<'ReimbursementCaseRejectedInAppNotification'>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });
  const rejectedByName = rejectedBy.name;
  return (
    <div
      className="grid grid-rows-[auto 1fr auto] gap-1"
      style={{ height: '100%' }}
    >
      <Header>
        <Trans
          i18nKey="notifications:events.reimbursementCaseRejected.header"
          values={{
            rejectedByName,
            timeAgo: DateFormatters.fromNow(new Date(rejectedAt)),
          }}
        >
          <TruncatedText color="gray600" maxWidth="15ch">
            {rejectedByName}
          </TruncatedText>
          <Text fontSize="small" color="gray500">
            rejected approval of reimbursement
          </Text>
          <Text
            fontSize="small"
            color="gray500"
            {...triggerProps}
            ref={triggerRef}
          >
            3 minutes ago
          </Text>
        </Trans>
      </Header>

      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {DateFormatters.compactDatetime(new Date(rejectedAt))}
        </Tooltip>
      )}

      <Comment>
        <div className="flex flex-col">
          <div className="flex gap-1 items-center text-red-500">
            <Icon icon="close" color="red500" />
            {t('common:document.compact.history.documentRejected')}
          </div>

          <Text color="gray800" fontSize="basic">
            {t('events.commentText', { commentText })}
          </Text>
        </div>
      </Comment>
      <Text color="gray500" fontSize="small">
        {reimbursementCaseTitle}
      </Text>
    </div>
  );
};

export const ReimbursementCaseRejectedInAppNotificationToast = ({
  rejectedBy,
  commentText,
  reimbursementCaseTitle,
}: NotificationProps<'ReimbursementCaseRejectedInAppNotification'>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);

  const rejectedByName = rejectedBy.name;

  return (
    <div
      className="grid grid-rows-[auto 1fr auto] gap-1"
      style={{ height: '100%' }}
    >
      <Header>
        <Trans
          i18nKey="notifications:events.reimbursementCaseRejected.toast.header"
          values={{
            rejectedByName,
          }}
        >
          <TruncatedText color="gray800">{rejectedByName}</TruncatedText>
          <Text color="gray500">rejected approval of reimbursement</Text>
        </Trans>
      </Header>

      <Text color="gray800">{reimbursementCaseTitle}</Text>
      <Comment>
        <div className="flex flex-col">
          <div className="flex gap-1 items-center text-red-500">
            <Icon icon="close" color="red500" />
            {t('common:document.compact.history.documentRejected')}
          </div>

          <Text color="gray800" fontSize="basic">
            {t('events.commentText', { commentText })}
          </Text>
        </div>
      </Comment>
    </div>
  );
};

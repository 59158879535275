import {
  Chip,
  Flex,
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Member } from '../../toolkit/hooks/useGetTeams';
import { HiddenMembers } from './HiddenMembers';

interface MembersProps {
  members: Member[];
}

export const MAX_NUMBER_VISIBLE_MEMBERS = 2;

export const Members = ({ members }: MembersProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { tooltipProps, tooltipRef, isOpen, triggerProps, triggerRef } =
    useTooltip();

  const isMembersListShortened = members.length > MAX_NUMBER_VISIBLE_MEMBERS;
  const shortenedListOfMembers = members.slice(0, MAX_NUMBER_VISIBLE_MEMBERS);
  const membersInTooltip = members.slice(MAX_NUMBER_VISIBLE_MEMBERS);

  return (
    <>
      <Flex gap="space8" alignItems="center" wrap="wrap">
        {shortenedListOfMembers.map(member => (
          <Chip
            key={member.id}
            avatar={
              <AvatarWithStatusContainer
                name={member.fullName}
                size="xsmall"
                img={member.avatarUrl}
                userId={member.id}
                hasTooltip
              />
            }
            variant="lightGray"
            type="basic"
            size="xsmall"
          >
            <TruncatedText maxWidth="space80">{member.fullName}</TruncatedText>
          </Chip>
        ))}
        {isMembersListShortened && (
          <Text {...triggerProps} ref={triggerRef}>
            {t('teams.list.moreMembers', {
              count: membersInTooltip.length,
            })}
          </Text>
        )}
      </Flex>
      {isOpen && (
        <Tooltip overflow="hidden" {...tooltipProps} ref={tooltipRef}>
          <HiddenMembers hiddenMembers={membersInTooltip} />
        </Tooltip>
      )}
    </>
  );
};

import { useToastMessage } from 'components/Toast/useToastMessage';
import { noop } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MEA_WALLET_EVENT } from './types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

export const useEventHandlers = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { success } = useToastMessage();

  const handleCopyCardholder = useCallback(() => {
    success(t('meaWallet.feedback.copied.cardholder'));
  }, [success, t]);

  const handleCopyPan = useCallback(() => {
    success(t('meaWallet.feedback.copied.pan'));
  }, [success, t]);

  const handleCopyExpiry = useCallback(() => {
    success(t('meaWallet.feedback.copied.exp'));
  }, [success, t]);

  const handleCopyCVV = useCallback(() => {
    success(t('meaWallet.feedback.copied.cvv'));
  }, [success, t]);

  const eventHandlers: Record<MEA_WALLET_EVENT, () => void> = {
    [MEA_WALLET_EVENT.CARD_DATA_CARDHOLDER_COPIED]: handleCopyCardholder,
    [MEA_WALLET_EVENT.CARD_DATA_CLEARED]: noop,
    [MEA_WALLET_EVENT.CARD_DATA_COPYING_FAILED]: noop,
    [MEA_WALLET_EVENT.CARD_DATA_CVV_COPIED]: handleCopyCVV,
    [MEA_WALLET_EVENT.CARD_DATA_EXPIRY_COPIED]: handleCopyExpiry,
    [MEA_WALLET_EVENT.CARD_DATA_LOADING_FAILED]: noop,
    [MEA_WALLET_EVENT.CARD_DATA_LOADING_STARTED]: noop,
    [MEA_WALLET_EVENT.CARD_DATA_LOADING_SUCCESS]: noop,
    [MEA_WALLET_EVENT.CARD_DATA_PAN_COPIED]: handleCopyPan,
    [MEA_WALLET_EVENT.LOADER_OVERLAY_ADDED]: noop,
    [MEA_WALLET_EVENT.LOADER_OVERLAY_REMOVED]: noop,
    [MEA_WALLET_EVENT.MISSING_PARAMETERS]: noop,
    [MEA_WALLET_EVENT.STYLE_URL_ADDED]: noop,
  };

  return eventHandlers;
};

export const meaWalletErrors: MEA_WALLET_EVENT[] = [
  MEA_WALLET_EVENT.CARD_DATA_LOADING_FAILED,
  MEA_WALLET_EVENT.CARD_DATA_COPYING_FAILED,
  MEA_WALLET_EVENT.MISSING_PARAMETERS,
];

import {
  HookFormTextareaField,
  HookFormTextareaFieldProps,
} from 'components/HookFormFields/HookFormTextareaField';
import { getTranslationContext } from 'orgConfig';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NOTE_FIELD_MAX_LENGTH } from 'views/consts';

interface VatRateFieldProps<TFormValues extends FieldValues>
  extends HookFormTextareaFieldProps<TFormValues> {
  name: Path<TFormValues>;
  readOnly?: boolean;
  currentValueLength?: number;
}

export const NoteField = <TFormValues extends FieldValues>({
  name,
  readOnly,
  currentValueLength,
  ...props
}: VatRateFieldProps<TFormValues>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { client } = useDatev();
  const maxLength = client ? NOTE_FIELD_MAX_LENGTH : Infinity;

  return (
    <HookFormTextareaField
      name={name}
      {...props}
      placeholder={t('splitBookings.note.placeholder.enabled')}
      readOnly={readOnly}
      warning={
        (currentValueLength ?? 0) > maxLength
          ? t('splitBookings.note.maxLengthWarning', {
              maxLength,
            })
          : undefined
      }
      infoTooltip={
        readOnly
          ? undefined
          : {
              message: t('splitBookings.note.infoTooltip.enabled', {
                context: getTranslationContext(),
              }),
            }
      }
    />
  );
};

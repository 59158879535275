import { EventEmitter } from 'events';
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Modal,
  Paragraph,
  Text,
  useTheme,
} from '@candisio/design-system';
import logoIntercomClose from 'assets/logo-intercom-close.svg';
import logoIntercom from 'assets/logo-intercom.svg';
import logoCandis from 'assets/logo-white.svg';
import { AnimatePresence, motion } from 'motion/react';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IntercomModalProps {
  showConsentManager: () => void;
  handleIntercomOn: () => void;
}

const MotionFlex = motion.create(Flex);
const MotionGrid = motion.create(Grid);
const MotionBox = motion.create(Box);

const emitter = new EventEmitter();

const listenerName = 'openIntercomModal';
export function openIntercomModal() {
  emitter.emit(listenerName);
}

/**@deprecated after new navigation */
export const IntercomModal = ({
  showConsentManager,
  handleIntercomOn,
}: IntercomModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tCommon] = useTranslation(LOCALE_NAME_SPACE.COMMON);
  const [tConsentManager] = useTranslation(LOCALE_NAME_SPACE.CONSENT_MANAGER);
  const { space } = useTheme();

  const modalTransition = { duration: 0.35, type: 'tween' };
  const iconTransition = { duration: 0.2, type: 'tween' };

  const handleModalToggle = () => {
    setIsOpen(!isOpen);
  };

  const handlePrivacyModalOpen = () => {
    handleModalToggle();
    showConsentManager();
  };

  const handleIntercomToggle = () => {
    handleModalToggle();
    handleIntercomOn();
  };

  useEffect(() => {
    emitter.on(listenerName, handleModalToggle);

    return () => {
      emitter.removeListener(listenerName, handleModalToggle);
    };
  });

  return (
    <>
      <MotionFlex
        as="button"
        aria-label={tConsentManager('intercomModal.intercomOpen')}
        aria-pressed={isOpen}
        aria-disabled={isOpen}
        onClick={handleModalToggle}
        bottom="30px"
        left="40px"
        boxShadow="0 1px 6px 0 rgb(0 0 0 / 6%), 0 2px 32px 0 rgb(0 0 0 / 16%)"
        padding={0}
        border="none"
        width="48px"
        height="48px"
        borderRadius="50%"
        background="gray800"
        position="fixed"
        zIndex="99"
        style={{ cursor: 'pointer' }}
        initial={{ scale: 0.6, opacity: 0 }}
        animate={{ scale: 1, opacity: 1, transition: modalTransition }}
      >
        <AnimatePresence initial={false}>
          {!isOpen ? (
            <MotionFlex
              as="span"
              key="closed"
              width="100%"
              height="100%"
              position="absolute"
              alignItems="center"
              top={0}
              left={0}
              initial={{ scale: 0, rotate: '45deg' }}
              animate={{ scale: 0.475, rotate: 0 }}
              exit={{ scale: 0, rotate: 0, transition: iconTransition }}
            >
              <img
                src={logoIntercom}
                alt={tConsentManager('intercomModal.intercomOpen')}
              />
            </MotionFlex>
          ) : (
            <MotionFlex
              as="span"
              key="open"
              width="100%"
              height="100%"
              position="absolute"
              alignItems="center"
              top={0}
              left={0}
              initial={{ scale: 0.275, rotate: '-90deg' }}
              animate={{ scale: 0.275, rotate: 0, transition: iconTransition }}
              exit={{ scale: 0.275, rotate: 0, transition: iconTransition }}
            >
              <img
                src={logoIntercomClose}
                alt={tConsentManager('intercomModal.intercomClose')}
              />
            </MotionFlex>
          )}
        </AnimatePresence>
      </MotionFlex>
      <Modal
        isOpen={isOpen}
        onClose={handleModalToggle}
        padding={0}
        borderRadius="medium"
        data-cy="intercom-modal"
        maxWidth="378px"
        position="absolute"
        left="30px"
        bottom="97px"
        overflow="hidden"
        background="gray800"
        overlayColor="transparent"
        style={{ filter: 'drop-shadow(0 8px 24px rgba(0, 0, 0, 0.2))' }}
      >
        <MotionGrid
          padding="30px 28px 120px"
          gap="space18"
          initial={{ translateY: space.space36 }}
          animate={{ translateY: 0, transition: modalTransition }}
        >
          <img src={logoCandis} alt={tCommon('pageTitle')} />
          <Text
            as="h3"
            fontSize={space.space36}
            lineHeight="space32"
            color="white"
            whiteSpace="pre-line"
          >
            <Trans
              ns={LOCALE_NAME_SPACE.CONSENT_MANAGER}
              i18nKey="intercomModal.heading"
            >
              Hello
              <Text
                fontSize="xxlarge"
                lineHeight="paragraph"
                style={{ marginTop: space.space8, display: 'block' }}
              >
                Our conversation is unfortunately paused! ⏸
              </Text>
            </Trans>
          </Text>
        </MotionGrid>
        <Flex
          background="white"
          paddingY={0}
          paddingX="28px"
          overflow="visible"
        >
          <MotionBox
            borderRadius="small"
            boxShadow="0 8px 16px rgba(0, 0, 0, 0.16)"
            background="white"
            bottom="space48"
            position="relative"
            padding="22px 28px 26px"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: modalTransition }}
          >
            <Grid gap="space20">
              <Text
                as="h4"
                fontSize="basic"
                lineHeight="heading"
                fontWeight="bold"
                data-cy="intercom-title"
              >
                {tConsentManager('intercomModal.title')}
              </Text>
              <Paragraph whiteSpace="pre-line">
                <Trans
                  ns={LOCALE_NAME_SPACE.CONSENT_MANAGER}
                  i18nKey="intercomModal.paragraph"
                >
                  The intercom cookie enables you to communicate with us via
                  live chat. To see what exactly this cookie does and to disable
                  it please visit the
                  <Link
                    as="button"
                    onClick={handlePrivacyModalOpen}
                    data-cy="intercom-link-button"
                  >
                    privacy settings
                  </Link>
                  .
                </Trans>
              </Paragraph>
              <Button size="medium" onClick={handleIntercomToggle}>
                {tConsentManager('intercomModal.buttonLink')}
              </Button>
            </Grid>
          </MotionBox>
        </Flex>
      </Modal>
    </>
  );
};

import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { useDocumentRelationshipsActions } from 'containers/document-relationships/useDocumentRelationshipsActions';
import { useEcmDocumentsTableData } from 'containers/document-relationships/useEcmDocumentsTableData';
import {
  EcmHighlightableFields,
  GetEcmDocumentsQuery,
  useDocumentRelationshipsQuery,
} from 'generated-types/graphql.types';
import { useMemo, useState } from 'react';
import { isEcmDocumentId } from 'views/utils/isEcmDocumentId';
import { mapRelationshipToTableRows } from './mapRelationshipToTableRows';
import { useSourceDocument } from './useSourceDocument';
import { parseHighlight } from 'components/WithHighlightsCell/parseHighlight';

const isSameDocument = (
  invoiceId?: string | null,
  ecmDocumentId?: string | null,
  sourceDocumentId?: string | null
) => {
  return ecmDocumentId === sourceDocumentId || invoiceId === sourceDocumentId;
};

interface UseManageRelationshipsDataParams {
  documentId: string;
  onLinkDocuments: () => void;
  previewDocumentId: string | null;
}

export const useManageRelationshipsData = ({
  documentId,
  onLinkDocuments,
  previewDocumentId,
}: UseManageRelationshipsDataParams) => {
  const isEcmDocument = isEcmDocumentId(documentId);

  const { documentName, documentType, sourceDocument } = useSourceDocument({
    documentId,
  });

  const { data: existingRelationshipsData, refetch } =
    useDocumentRelationshipsQuery({
      variables: {
        input: {
          globalDocumentId: sourceDocument?.globalDocumentId ?? '',
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: !sourceDocument?.globalDocumentId,
    });

  const { handleLinkDocuments, isLinkingDocumentsPending } =
    useDocumentRelationshipsActions(async () => {
      await refetch();
      onLinkDocuments();
    });

  const [tableData, setTableData] = useState<EcmDocumentsTableData[]>([]);

  const handleOnCompleted = (data: GetEcmDocumentsQuery): void => {
    if (!sourceDocument) return;

    const ecmDocumentsList = data?.getEcmDocuments;

    const newData = (ecmDocumentsList.edges ?? [])
      // To be on the safe side, we check both invoiceId and id. We cannot
      // guarantee that if invoiceId is present, the id will be different.
      // This should be fixed once we use globalDocumentId everywhere
      .filter(({ record: d }) => {
        if (
          sourceDocument.id === d.id ||
          sourceDocument.invoiceId === d.invoiceId
        )
          return false;

        const isExistingRelationship = existingRelationships.some(
          ({ id, invoiceId }) => id === d.id || invoiceId === d.invoiceId
        );

        return !isExistingRelationship;
      })
      .map(
        ({ record, cursor, highlights }): EcmDocumentsTableData => ({
          cursor,
          id: record?.id ?? '',
          globalDocumentId: record?.globalDocumentId ?? '',
          documentType: record?.documentType ?? '',
          contact: record?.contactName ?? '',
          invoiceId: record?.invoiceId ?? '',
          notes: record?.notes ?? '',
          documentName: record?.documentName ?? '',
          documentNumber: record?.documentNumber ?? '',
          documentDate: record?.documentDate
            ? new Date(record?.documentDate)
            : undefined,
          documentStatus: {
            status: record?.documentStatus ?? undefined,
            isSensitive: record?.isSensitive ?? false,
            isEInvoice: record?.isEInvoice ?? false,
          },
          tags: record?.tags ?? [],
          selected: previewDocumentId
            ? record?.id === previewDocumentId ||
              record?.invoiceId === previewDocumentId
            : false,
          highlights: parseHighlight(highlights, highlightFieldToColumIdMapper),
        })
      );

    const newTableData = [sourceDocument, ...existingRelationships, ...newData];

    setTableData(newTableData);
  };

  const {
    handleSearchChange,
    handleUpdateConfigurations,
    isLoading,
    onLoadMore,
    searchQuery,
    selectedDocuments,
    setSelectedDocuments,
    totalCount,
    availableDocumentColumnIds,
    configurationsTable,
    customEmptyState,
    filters,
    onFilter,
    onSort,
    sortBy,
  } = useEcmDocumentsTableData({
    filterParamsSource: 'url',
    queryOptions: {
      onCompleted: handleOnCompleted,
      skip: !sourceDocument,
    },
  });

  const existingRelationships = useMemo(
    () =>
      (existingRelationshipsData?.documentRelationships?.relationships
        .map(mapRelationshipToTableRows)
        // TODO: remove the `as` cast once we upgrade TS to properly infer .filter(Boolean)
        .filter(Boolean) as EcmDocumentsTableData[]) ?? [],
    [existingRelationshipsData?.documentRelationships?.relationships]
  );

  const linkDocuments = () => {
    handleLinkDocuments({
      globalDocumentId: sourceDocument?.globalDocumentId ?? '',
      documentName: documentName ?? '',
      selectedDocuments,
    });
  };

  const canBeSelected = (row: EcmDocumentsTableData): boolean => {
    const isExistingRelationship = existingRelationships.some(
      e => e.id === row.id || e.invoiceId === row.invoiceId
    );

    return !isExistingRelationship;
  };

  const isSelectVisible = (row: EcmDocumentsTableData): boolean => {
    return row.id !== documentId;
  };

  tableData.forEach(d => {
    d.selected = isSameDocument(d.invoiceId, d.id, previewDocumentId);
  });

  return {
    handleSearchChange,
    handleUpdateConfigurations,
    linkDocuments,
    isLoading,
    onLoadMore,
    searchQuery,
    selectedDocuments,
    setSelectedDocuments,
    totalCount,
    availableDocumentColumnIds,
    configurationsTable,
    tableData,
    customEmptyState,
    filters,
    onFilter,
    onSort,
    isLinkingDocumentsPending,
    canBeSelected,
    sortBy,
    documentType,
    documentName,
    isEcmDocument,
    isSelectVisible,
  };
};

const highlightFieldToColumIdMapper: Partial<
  Record<EcmHighlightableFields, keyof EcmDocumentsTableData>
> = {
  [EcmHighlightableFields.Amount]: 'amount',
  [EcmHighlightableFields.ContactName]: 'contact',
  [EcmHighlightableFields.DocumentName]: 'documentName',
  [EcmHighlightableFields.DocumentNumber]: 'documentNumber',
  [EcmHighlightableFields.Notes]: 'notes',
  [EcmHighlightableFields.RawContent]: 'rawContentHighlight',
};

import { Flex, Grid, Icon, Text } from '@candisio/design-system';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { CandisApiSession } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';

interface SessionListItemProps {
  apiSession: CandisApiSession;
  onRevokeSession: (sessionId: string) => void;
  isRevokeSessionLoading: boolean;
}
export const SessionListItem = ({
  apiSession,
  onRevokeSession,
  isRevokeSessionLoading,
}: SessionListItemProps) => {
  const [t] = useTranslation();

  const handleRevokeApiSession = () => {
    onRevokeSession(apiSession.sessionId);
  };

  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      padding="space12"
      borderRadius="12px"
      alignItems="center"
    >
      <Grid alignItems="center" gap="space8">
        <Text fontWeight="bold" fontSize="large">
          {apiSession.clientName}
        </Text>
        <Flex alignItems="center" gap="space8">
          <Icon icon="checkCircle" color="green700" />
          <Text color="gray600" overflowWrap="anywhere">
            {t('settings:apiSettings.actionCard.sessionListItem.details', {
              connectionDate: DateFormatters.compact(
                new Date(apiSession.connectionDate)
              ),
              authorizedBy: apiSession.authorizationUser?.name,
            })}
          </Text>
        </Flex>
      </Grid>
      <ConfirmationButton
        confirmationPopoverWidth="space200"
        minWidth="max-content"
        confirmationTitle={t(
          'settings:apiSettings.actionCard.sessionListItem.action.revokeSession.doubleConfirmation'
        )}
        onConfirm={handleRevokeApiSession}
        loading={isRevokeSessionLoading}
        disabled={isRevokeSessionLoading}
        variant="secondary"
      >
        {t(
          'settings:apiSettings.actionCard.sessionListItem.action.revokeSession.buttonLabel'
        )}
      </ConfirmationButton>
    </Flex>
  );
};

import { Box } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Carousel } from '../Carousel/Carousel';
import { PromotionCard } from '../PromotionCard/PromotionCard';
import { usePromotionsContext } from '../context/PromotionsProvider';
import { EventLocationsBase } from 'providers/AnalyticsProvider/events';

export const PromotionsCarousel = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  const { visiblePromotions, goToSlide, currentCardIndex } =
    usePromotionsContext();

  if (!visiblePromotions.length) return null;

  const cards = visiblePromotions.map(promotion => (
    <Box key={promotion.id} height="331px">
      <PromotionCard
        promotion={promotion}
        trackOption={{
          eventLocation: EventLocationsBase.MAIN_DASHBOARD,
        }}
      />
    </Box>
  ));

  return (
    <Box paddingBottom="50px">
      <Carousel
        cards={cards}
        title={t('title')}
        currentCardIndex={currentCardIndex}
        goToSlide={goToSlide}
      />
    </Box>
  );
};

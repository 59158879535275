import { Swimlane } from 'components/Swimlane/Swimlane';
import { SwimlaneHeader } from 'components/Swimlane/SwimlaneHeader';
import { SwimlaneTiles } from 'components/Swimlane/SwimlaneTiles';
import { PromoExpenseExplained } from './promos/PromoExpenseExplained';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { ReimbursementSummaryDraft } from './content/ReimbursementSummaryDraft';
import { ReimbursementSummaryApproved } from './content/ReimbursementSummaryApproved';
import { ReimbursementSummaryOpen } from './content/ReimbursementSummaryOpen';
import { GetReimbursementCasesSummaryQuery } from 'generated-types/graphql.types';
import { ReimbursementSummaryCreate } from './ReimbursementSummaryCreate';

const PROMO_TILE_COUNT = 2;

export const ReimbursementSummarySwimlane = ({
  reimbursementCasesSummary,
  showCTATile,
}: {
  reimbursementCasesSummary?: GetReimbursementCasesSummaryQuery['reimbursementCasesSummary'];
  showCTATile: boolean;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { draft, open, approved } = reimbursementCasesSummary || {};

  const { isPromotionSeen, setPromotionIsSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.EXPENSE_EXPLAINED_PROMO
  );

  if (showCTATile) {
    return <ReimbursementSummaryCreate />;
  }

  return (
    <Swimlane data-cy="summary-swimlane">
      <SwimlaneTiles
        contentTileCount={3}
        promoTileCount={isPromotionSeen ? undefined : PROMO_TILE_COUNT}
      >
        {({ isPromoTileVisible, contentTileVisibleCount }) => {
          const showPromoTile =
            !isPromotionSeen &&
            isPromoTileVisible &&
            // Adding this rule out of the useSwimlaneTileRules to ensure that summary tiles
            // won't get reduced as they should be always shown.
            contentTileVisibleCount >= PROMO_TILE_COUNT;

          return (
            <>
              <SwimlaneHeader
                icon="wallet"
                label={t('dashboard.swimlanes.overview.header')}
              />
              <ReimbursementSummaryDraft caseSummary={draft} />
              <ReimbursementSummaryOpen caseSummary={open} />
              <ReimbursementSummaryApproved caseSummary={approved} />
              {showPromoTile && (
                <PromoExpenseExplained onClose={setPromotionIsSeen} />
              )}
            </>
          );
        }}
      </SwimlaneTiles>
    </Swimlane>
  );
};

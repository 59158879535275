import {
  ApolloClient,
  ApolloLink,
  ErrorPolicy,
  Operation,
  ApolloProvider as Provider,
  ServerError,
  createHttpLink,
  split,
} from '@apollo/client';
import { InMemoryCache, StoreObject } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import { captureMessage, getCurrentHub } from '@sentry/react';
import { Span } from '@sentry/types';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { ReactNode } from 'react';
import { AuthService } from 'services/AuthService';
import { resolvers, typePolicies } from './LocalState';
import { FileUploadsSchema } from './LocalState/FileUploads';
import { mockResolvers, mockSchemas } from './Mocking';
import { SubscriptionLink } from './Subscriptions/SubscriptionLink';
import { networkLink } from './networkStatusLink';

const sseLink = new SubscriptionLink({
  endpoint: import.meta.env.REACT_APP_GRAPHQL_SUBSCRIPTIONS_ENDPOINT,
});

const httpLink = createHttpLink({
  uri: import.meta.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const terminationLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  sseLink,
  httpLink
);

/**
 * Checks whether the given operation contains a graphql mutation
 */
const includesMutation = (operation: Operation) => {
  return operation.query.definitions.some(
    def => def.kind === 'OperationDefinition' && def.operation === 'mutation'
  );
};

/**
 * Predicate to check if a request could be retried based on a given error.
 */
const isRequestRetryable = (error: Error) => {
  const retryCodes = [503];

  return (
    error.message === 'Failed to fetch' ||
    retryCodes.includes((error as ServerError).statusCode)
  );
};

const useIdOrCachingKey = (object: Readonly<StoreObject>) => {
  return `${object.__typename}:${object.id || object.cachingKey}`;
};

const useIdNameOrCachingKey = (object: Readonly<StoreObject>) => {
  return `${object.__typename}:${object.id || object.cachingKey}:${object.name || ''}`;
};

export const createCache = () =>
  new InMemoryCache({
    typePolicies: {
      ...typePolicies,
      InboxInvoiceDocumentContact: {
        keyFields: useIdNameOrCachingKey,
      },
      InboxInvoiceDocumentCostCenter: {
        keyFields: useIdOrCachingKey,
      },
      InboxInvoiceDocumentPayment: {
        keyFields: useIdOrCachingKey,
      },
      ApprovalInvoiceDocumentContact: {
        keyFields: useIdNameOrCachingKey,
      },
      ApprovalInvoiceDocumentCostCenter: {
        keyFields: useIdOrCachingKey,
      },
      ApprovalInvoiceDocumentPayment: {
        keyFields: useIdOrCachingKey,
      },
      ArchiveInvoiceDocumentContact: {
        keyFields: useIdNameOrCachingKey,
      },
      ArchiveInvoiceDocumentCostCenter: {
        keyFields: useIdOrCachingKey,
      },
      ArchiveInvoiceDocumentPayment: {
        keyFields: useIdOrCachingKey,
      },
      ArchiveInvoiceDocumentGeneralLedgerAccount: {
        keyFields: useIdOrCachingKey,
      },
      Contact: {
        keyFields: ['id', 'name', ['value']],
      },
      ExtractedContact: {
        keyFields: ['cachingKey'],
      },
      ExportDocumentStatus: {
        keyFields: false,
      },
      ExportableEntityInfo: {
        keyFields: false,
      },
      WorkflowUser: {
        keyFields: false,
      },
      SubstituteUser: {
        keyFields: false,
      },
      ActivityUser: {
        keyFields: false,
      },
    },
    // Please try to generate these automatically at some point
    // https://www.apollographql.com/docs/react/migrating/apollo-client-3-migration/#apollo-link-
    possibleTypes: {
      DocumentTimeLineItem: [
        'FileUploadedEvent',
        'FileUploadedByEmailEvent',
        'DocumentUpdatedEvent',
        'CommentEvent',
        'DocumentCommentEvent',
        'ApprovedEvent',
        'ApproverExtractedEvent',
        'RejectedEvent',
        'RequestApprovalEvent',
        'ExportedEvent',
        'ProvisionExportedEvent',
        'ProvisionCreatedEvent',
        'ProvisionDeletedEvent',
        'ReversalCreatedEvent',
        'ReversalExportedEvent',
        'ExtractionEvent',
        'MetaDataExtractionEvent',
        'ContactExtractionEvent',
        'CostCenterExtractionEvent',
        'UserApprovalDocumentWorkflowStep',
        'UserRejectedDocumentWorkflowStep',
        'WorkflowStepSkippedEvent',
        'WorkflowCreatedForDocumentEvent',
        'WorkflowTemplateAppliedToDocumentEvent',
        'DocumentSepaTransferGeneratedEvent',
        'FileAttachedToDocumentEvent',
        'FileDetachedFromDocumentEvent',
        'SplitBookingUpdatedEvent',
        'SingleBookingUpdatedEvent',
        'DocumentImportedByMigrationEvent',
      ],
      PayableDocument: [
        'DocumentWithIncompletePaymentData',
        'SepaExportableDocument',
      ],
    },
  });

export const createAuthLink = (realm: string | null) =>
  setContext(() => {
    const headers: any = {};

    return AuthService.authorizedToken()
      .then(token => {
        headers.Authorization = `Bearer ${token}`;

        if (realm) {
          headers['X-Realm-name'] = realm;
        }

        // Adding this to enable testing db responses from user-management
        // TODO: Delete after user-management migration
        const setRequestDbResponseHeader = !!localStorage.getItem(
          'setRequestDbResponseHeader'
        );

        if (setRequestDbResponseHeader) {
          headers['x-db-response'] = 'true';
        }

        // TODO: Delete after user-management migration
        const setSkipKcKeader = !!localStorage.getItem('setSkipKcKeader');
        if (setSkipKcKeader) {
          headers['x-skip-kc-handler'] = 'true';
        }

        return { headers };
        // TODO Need fix this better in the future
      })
      .catch(_e => {
        if (realm) {
          headers['X-Realm-name'] = realm;
        }

        headers['X-Org-ID'] = realm;

        return { headers };
      });
  });

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 2,
    retryIf: (error, operation) => {
      // do not retry on mutation or operations that include a mutation
      if (!includesMutation(operation) && error && isRequestRetryable(error)) {
        console.log(
          `Retrying for - ${JSON.stringify({
            error,
            graphQLOperation: operation.operationName,
            graphQLVariables:
              import.meta.env.REACT_APP_STAGE === 'prod'
                ? {}
                : operation.variables,
          })}`
        );

        return true;
      }

      return false;
    },
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (
        import.meta.env.REACT_APP_STAGE !== 'prod' &&
        import.meta.env.REACT_APP_STAGE !== 'rc'
      ) {
        console.log('extensions', extensions);
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
            null,
            2
          )}, Path: ${path}`
        );

        // TODO implement proper feedback
        console.log('stack', (extensions?.exception as any)?.stacktrace);
      }
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // Handle 401 from server
    const serverError = networkError as ServerError;
    if (serverError && serverError.statusCode === 401) {
      AuthService.forceLogout();
    }

    // Send response to sentry for debugging 5XX error codes
    if (serverError && 500 <= serverError.statusCode) {
      captureMessage(JSON.stringify(serverError), 'debug');
    }
  }
});

const sentrySpanLink = new ApolloLink((operation, forward) => {
  const currentTransaction = getCurrentHub().getScope()?.getTransaction();

  const operationType = includesMutation(operation) ? 'mutation' : 'query';
  const span = currentTransaction?.startChild({
    description: operation.operationName,
    op: `gql-${operationType}`,
  });

  operation.setContext({ span });

  return forward(operation).map(data => {
    const returnedSpan: Span | undefined = operation.getContext().span;
    returnedSpan?.finish();

    return data;
  });
});

export const createApolloClient = ({
  organizationId,
}: {
  organizationId: string | null;
}) => {
  // We are resetting cache over and over again
  // This is probably not ideal. but there is no other
  // Solution to refetch all queries
  const cache = createCache();
  const defaultOptions = {
    // React Apollo's `useQuery` uses Apollo Client's `watchQuery` internally
    // https://github.com/apollographql/react-apollo/issues/3163#issuecomment-505007841
    watchQuery: {
      errorPolicy: 'all' as ErrorPolicy,
    },
    query: {
      errorPolicy: 'all' as ErrorPolicy,
    },
    mutate: {
      errorPolicy: 'all' as ErrorPolicy,
    },
  };

  return new ApolloClient({
    link: ApolloLink.from([
      retryLink,
      errorLink,
      networkLink,
      sentrySpanLink,
      createAuthLink(organizationId),
      terminationLink,
    ]),
    cache,
    connectToDevTools: true,
    resolvers: [resolvers, ...mockResolvers],
    typeDefs: [FileUploadsSchema, ...mockSchemas],
    defaultOptions,
    name: import.meta.env.REACT_APP_APOLLO_CLIENT_NAME,
    version: import.meta.env.REACT_APP_APOLLO_CLIENT_VERSION,
  });
};

interface GraphQLProviderProps {
  children: ReactNode;
}

export const GraphQLProvider = ({ children }: GraphQLProviderProps) => {
  const organizationId = useOrganizationId();
  const client = createApolloClient({
    organizationId,
  });

  return <Provider client={client}>{children}</Provider>;
};

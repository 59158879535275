import {
  GeneralExpenseItem,
  HospitalityExpenseItem,
  ReimbursementItemsSplitBookingsInitialSnapshotQuery,
  useReimbursementItemsSplitBookingsInitialSnapshotQuery,
  PerDiemItem,
} from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useFormattedBookings } from './useFormattedBookings';

type AllItemsFragments =
  ReimbursementItemsSplitBookingsInitialSnapshotQuery['reimbursementItemsSplitBookingsInitialSnapshot'][number];

type ExpenseFragment =
  | GeneralExpenseItem
  | HospitalityExpenseItem
  | PerDiemItem;

export const useReimbursementSplitBookingsInitialSnapshot = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUseReimbursement } = useReimbursement();
  const shouldSkipQuery = !reimbursementId || !canUseReimbursement;
  const { data } = useReimbursementItemsSplitBookingsInitialSnapshotQuery({
    variables: { filters: { reimbursementCaseId: reimbursementId } },
    skip: shouldSkipQuery,
    fetchPolicy: 'no-cache',
  });

  const fetchedItems =
    data?.reimbursementItemsSplitBookingsInitialSnapshot ?? [];

  const filteredExpensesItems = fetchedItems.filter(
    (item: AllItemsFragments): item is ExpenseFragment => {
      return (
        item.__typename === 'GeneralExpenseItem' ||
        item.__typename === 'HospitalityExpenseItem' ||
        item.__typename === 'PerDiemItem'
      );
    }
  );

  const formattedBooking = useFormattedBookings();

  const mappedBookings = (filteredExpensesItems ?? []).map(item => {
    const bookings = item.bookings ?? [];

    return { bookings: bookings.map(formattedBooking) };
  });

  return { mappedBookingsSnapshot: mappedBookings };
};

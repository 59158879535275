import { HeaderProps, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { PaymentTableData } from './types';

const mapColumnIdToTranslationKey: Record<string, string> = {
  status: 'table.headers.status',
  dueDateWithCashDiscount: 'table.headers.dueDateWithCashDiscount',
  contact: 'table.headers.contact',
  creditorIban: 'table.headers.iban',
  invoiceNumber: 'table.headers.invoiceNumber',
  invoiceDueDate: 'table.headers.dueDate',
  paymentInfo: 'table.headers.paymentInfo',
};

export const Header = ({
  column,
  isFilteredOrSorted,
}: HeaderProps<PaymentTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  return (
    <TruncatedText
      wordBreak="break-all"
      color={isFilteredOrSorted ? 'gray800' : 'gray500'}
    >
      {t(mapColumnIdToTranslationKey[column.id])}
    </TruncatedText>
  );
};

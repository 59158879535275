/**
 * TODO: use library instead if such exists and work
 */
import {
  ApolloLink,
  NextLink,
  Observable,
  Operation,
  ServerError,
} from '@apollo/client';
import { useEffect, useState } from 'react';
import { AuthService } from 'services/AuthService';
enum ActionTypes {
  REQUEST = 'REQUEST',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  CANCEL = 'CANCEL',
}
type Listener = (loading: boolean) => void;

class Dispatcher {
  listeners: Listener[] = [];
  _loadingCount = 0;
  addListener(fn: Listener) {
    this.listeners.push(fn);
  }

  removeListener(fn: Listener) {
    this.listeners = this.listeners.filter(cur => cur !== fn);
  }

  dispatch(action: ActionTypes) {
    this._loadingCount =
      action === ActionTypes.REQUEST
        ? this._loadingCount + 1
        : this._loadingCount - 1;
    this.listeners.forEach(currentListener => {
      currentListener(!!this._loadingCount);
    });
  }
}

// TODO: temporarily disable cancelling requests when component unmounts, until we identify issue on the BE TSS-708
const SHOULD_CANCEL_REQUESTS_WHEN_COMPONENT_UNMOUNTS = false;

class ApolloLinkNetworkStatus extends ApolloLink {
  dispatcher: Dispatcher;
  constructor({ dispatcher }: { dispatcher: Dispatcher }) {
    super();
    this.dispatcher = dispatcher;
  }

  request(operation: Operation, forward: NextLink) {
    const skip = operation.getContext().skipLoading;

    const operationType =
      'operation' in operation.query.definitions[0]
        ? operation.query.definitions[0].operation
        : undefined;

    if (skip || (operationType && operationType === 'subscription')) {
      return forward(operation);
    }

    this.dispatcher.dispatch(ActionTypes.REQUEST);
    const subscriber = forward(operation);

    return new Observable<any>(observer => {
      let isPending = true;

      const subscription = subscriber.subscribe({
        next: result => {
          isPending = false;
          if (result?.errors) {
            this.dispatcher.dispatch(ActionTypes.ERROR);
          } else {
            this.dispatcher.dispatch(ActionTypes.SUCCESS);
          }

          observer.next(result);
        },

        error: networkError => {
          isPending = false;
          this.dispatcher.dispatch(ActionTypes.ERROR);
          observer.error(networkError);
          // Handle 401 from server
          const serverError = networkError as ServerError;
          if (serverError && serverError.statusCode === 401) {
            AuthService.forceLogout();
          }
        },

        complete: observer.complete.bind(observer),
      });

      return () => {
        if (!SHOULD_CANCEL_REQUESTS_WHEN_COMPONENT_UNMOUNTS) return;
        if (isPending) {
          this.dispatcher.dispatch(ActionTypes.CANCEL);
        }

        if (subscription) {
          subscription.unsubscribe();
        }
      };
    });
  }
}

export const dispatcher = new Dispatcher();
export const useNetworkStatus = () => {
  const [isLoading, setLoading] = useState(false);
  const onLoadingChange = (loading: boolean) => setLoading(loading);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    dispatcher.addListener(onLoadingChange);

    return () => {
      dispatcher.removeListener(onLoadingChange);
    };
  }, []);

  return { isLoading };
};

export const networkLink = new ApolloLinkNetworkStatus({
  dispatcher,
});

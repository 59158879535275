import { Grid } from '@candisio/design-system';
import { getTranslationContext } from 'orgConfig';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReimbursementSplitBookingsFormValues } from 'views/Reimbursement/toolkit/reimbursementSplitBookingsFormSchema';
import { generateBookingsArrayFieldNames } from '../utils/generateSplitBookingsFormFieldNames';
import { AmountField } from './Fields/AmountField';
import { ArtistSocialInsuranceField } from './Fields/ArtistSocialInsuranceField';
import { NoteField } from './Fields/NoteField';
import { PaginatedComboBoxField } from './Fields/PaginatedComboBoxField';
import { PostingTextField } from './Fields/PostingTextField';
import { TaxCodeField } from './Fields/TaxCodeField';
import { TaxRepresentationField } from './Fields/TaxRepresentationField';
import { VatRateField } from './Fields/VatRateField';
import { useFormFieldOptions } from './hooks/useFormFieldOptions';
import { BookingFieldRules } from './hooks/useReimbursementBookingsFieldRules';

interface ReimbursementSplitBookingsFormProps {
  hasOneBooking?: boolean;
  bookingId: string;
  entryIndex: number;
  bookingIndex: number;
  fieldRules?: BookingFieldRules;
}

export const ReimbursementSplitBookingsForm = ({
  hasOneBooking = false,
  bookingId,
  entryIndex,
  bookingIndex,
  fieldRules,
}: ReimbursementSplitBookingsFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const formMethods = useFormContext<ReimbursementSplitBookingsFormValues>();

  const {
    taxCode,
    costCenter,
    costObject,
    extraCostInfo,
    generalLedgerAccount,
    artistSocialInsuranceCode,
    postingText,
  } = useFormFieldOptions();

  const { getValues, trigger } = formMethods;

  const fields = generateBookingsArrayFieldNames(entryIndex, bookingIndex);
  const [noteValue, postingTextValue] = getValues([
    fields.note,
    fields.postingText,
  ]);

  const handleTaxRepresentationChange = useCallback(() => {
    void trigger(fields.vatRate, { shouldFocus: true });
  }, [fields.vatRate, trigger]);

  return (
    <Grid
      id={bookingId}
      gap="space12"
      width="100%"
      background="gray50"
      padding="space16"
      borderRadius={hasOneBooking ? 'medium' : 'none'}
    >
      <Grid
        templateColumns="minmax(63px, 95px) minmax(72px, 1fr) minmax(50px, 60px)"
        gap="space8"
      >
        <TaxRepresentationField
          name={fields.taxPresentation}
          readOnly={fieldRules?.taxPresentation.isReadOnly}
          label={t('splitBookings.taxPresentation.label')}
          onChange={handleTaxRepresentationChange}
        />
        <AmountField
          name={fields.splitAmount}
          readOnly={fieldRules?.splitAmount.isReadOnly}
          label={t('splitBookings.splitAmount.label')}
        />
        <VatRateField
          name={fields.vatRate}
          readOnly={fieldRules?.vatRate.isReadOnly}
          label={t('splitBookings.vatRate.label')}
        />
      </Grid>
      {generalLedgerAccount.isVisible && (
        <PaginatedComboBoxField
          name={fields.generalLedgerAccount}
          readOnly={fieldRules?.generalLedgerAccount.isReadOnly}
          label={t('splitBookings.generalLedgerAccount.label')}
          {...generalLedgerAccount.options}
        />
      )}
      {taxCode.isVisible && (
        <TaxCodeField
          name={fields.taxCode}
          readOnly={fieldRules?.taxCode.isReadOnly}
          label={t('splitBookings.taxCode.label')}
          {...taxCode.options}
        />
      )}
      {costCenter.isVisible && (
        <PaginatedComboBoxField
          name={fields.costCenter}
          readOnly={fieldRules?.costCenter.isReadOnly}
          label={t('splitBookings.costCenter.label')}
          {...costCenter.options}
        />
      )}
      {costObject.isVisible && (
        <PaginatedComboBoxField
          name={fields.costObject}
          readOnly={fieldRules?.costObject.isReadOnly}
          label={t('splitBookings.costObject.label')}
          {...costObject.options}
        />
      )}
      {artistSocialInsuranceCode.isVisible && (
        <ArtistSocialInsuranceField
          name={fields.artistSocialInsuranceCode}
          readOnly={fieldRules?.artistSocialInsuranceCode.isReadOnly}
          label={t('splitBookings.artistSocialInsuranceCode.label')}
          {...artistSocialInsuranceCode.options}
        />
      )}
      {extraCostInfo.isVisible && (
        <PaginatedComboBoxField
          name={fields.extraCostInfo}
          readOnly={fieldRules?.extraCostInfo.isReadOnly}
          label={t('splitBookings.extraCostInfo.label')}
          {...extraCostInfo.options}
        />
      )}
      {postingText.isVisible && (
        <PostingTextField
          name={fields.postingText}
          readOnly={fieldRules?.postingText.isReadOnly}
          label={t('splitBookings.postingText.label')}
          currentValueLength={postingTextValue?.length ?? 0}
        />
      )}
      <NoteField
        name={fields.note}
        readOnly={fieldRules?.note.isReadOnly}
        label={t('splitBookings.note.label', {
          context: getTranslationContext(),
        })}
        currentValueLength={noteValue?.length ?? 0}
      />
    </Grid>
  );
};

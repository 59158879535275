import moment from 'moment';

export const isStartingDateGreater = (
  startingDate?: string | null,
  endingDate?: string | null,
  timeZone: string = 'Europe/Berlin'
) => {
  const momentStartingDate = moment.tz(startingDate, timeZone);
  const momentEndingDate = moment.tz(endingDate, timeZone);

  return momentStartingDate.isAfter(momentEndingDate);
};

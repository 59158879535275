import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Icon,
  Link,
  Paragraph,
  Text,
} from '@candisio/design-system';
import {
  DatevClientExportType,
  useNew_DatevSettingsQuery,
} from 'generated-types/graphql.types';
import { Routes } from 'models';
import { useDatev } from 'orgConfig/datev';
import { useOtherIntegration } from 'orgConfig/other';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { exportCardId, scrollToTarget } from 'views/CreditCards/utils/utils';
import { useCreditCardsLedgerData } from '../../useCreditCardsLedger';
import { CheckList } from './CheckList/CheckList';
import { CreditCardExportingForm } from './CreditCardExportingForm/CreditCardExportingForm';
import { StateKey, type StateKey as StateKeyType } from './states';

export const CreditCardExportingSection = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  //this is required to fetch CC ledger for Datev not connected users
  const { data: datevSettings, loading: datevSettingsLoading } =
    useNew_DatevSettingsQuery();

  const { creditCardsLedger, loading: ccSettingsLoading } =
    useCreditCardsLedgerData();

  const loading = datevSettingsLoading || ccSettingsLoading;

  const { rdsConnected, bdsConnected, bdsSetupCompleted } = useDatev(); // BDS-checked
  const otherIntegration = useOtherIntegration();
  const tOptions = otherIntegration.isActive ? { context: 'other' } : '';

  const location = useLocation();

  const [checks, setChecks] = useState<StateKeyType[]>([
    StateKey.checkingService,
  ]);

  const [showForm, setShowForm] = useState(false);

  const { client, hasAuthTokenForExportDocuments } =
    datevSettings?.new_datevSettings ?? {};

  const { exportType, isDxsoCashLedgerConfigured } = client ?? {};

  const isDxsoJobsExport = exportType === DatevClientExportType.DxsoJobs;
  const isDatevDuoSetupComplete =
    rdsConnected && isDxsoJobsExport && isDxsoCashLedgerConfigured;

  const isDatevReweSetupComplete = bdsConnected && bdsSetupCompleted;
  const isSetupComplete = isDatevDuoSetupComplete || isDatevReweSetupComplete;

  const areCreditCardAccountsPresent =
    !!creditCardsLedger.bookingAccount && !!creditCardsLedger.transitAccount;

  let creditCardExportingDescription = t(
    'creditCardExporting.description',
    tOptions
  );
  let creditCardExportingLink = t('creditCardExporting.linkUrl');

  if (bdsConnected) {
    creditCardExportingDescription = t('creditCardExporting.bds.description');
    creditCardExportingLink = t('creditCardExporting.bds.linkUrl');
  }

  useEffect(() => {
    if (loading) return;

    // client is not connected
    if (!hasAuthTokenForExportDocuments) {
      // user should be able to configure account number when Datev is not
      // connected this is required for Datev CSV export users
      setShowForm(true);
      setChecks([
        areCreditCardAccountsPresent
          ? StateKey.isCreditCardAccountNumberSetFFSuccess
          : StateKey.isCreditCardAccountNumberSetFFError,
      ]);
      return;
    }

    // client is connected
    if (isSetupComplete) {
      // RDS & Cash Ledger activated user is able to configure account number
      setShowForm(true);
    }

    if (isSetupComplete && areCreditCardAccountsPresent) {
      // all done, service connected and configured with booking account number
      setChecks([StateKey.isClientConnectedSuccess]);
      return;
    }

    if (bdsConnected) {
      setChecks([
        StateKey.isClientConnectedSuccess,
        bdsSetupCompleted
          ? StateKey.isBDSConnectedSuccess
          : StateKey.isBDSConnectedError,
        areCreditCardAccountsPresent
          ? StateKey.isCreditCardAccountNumberSetFFSuccess
          : StateKey.isCreditCardAccountNumberSetFFError,
      ]);
      return;
    }

    setChecks([
      StateKey.isClientConnectedSuccess,
      isDxsoJobsExport
        ? StateKey.isRDSConnectedSuccess
        : StateKey.isRDSConnectedError,
      isDxsoCashLedgerConfigured
        ? StateKey.isCashLedgerConnectedSuccess
        : StateKey.isCashLedgerConnectedError,
      areCreditCardAccountsPresent
        ? StateKey.isCreditCardAccountNumberSetFFSuccess
        : StateKey.isCreditCardAccountNumberSetFFError,
    ]);
  }, [
    bdsConnected,
    bdsSetupCompleted,
    isSetupComplete,
    isDxsoJobsExport,
    isDxsoCashLedgerConfigured,
    areCreditCardAccountsPresent,
    hasAuthTokenForExportDocuments,
    loading,
  ]);

  useEffect(() => {
    const hash = location.hash;
    if (hash !== '') scrollToTarget(hash);
  }, [location.hash]);

  return (
    <Card
      id={exportCardId}
      padding="space16"
      borderRadius="medium"
      alignSelf="start"
    >
      <Grid gap="space14" paddingBottom="space12">
        {!areCreditCardAccountsPresent && <MissingAccountMessage />}
        <Heading as="h3">{t('creditCardExporting.title', tOptions)}</Heading>
        <Paragraph>{creditCardExportingDescription}</Paragraph>
        {!otherIntegration.isActive && (
          <Flex>
            <Link external href={creditCardExportingLink}>
              {t('creditCardExporting.linkText')}
            </Link>
          </Flex>
        )}
        <CheckList checks={checks} />
        {showForm ? <CreditCardExportingForm /> : <DatevSettingsNavButton />}
      </Grid>
    </Card>
  );
};

function MissingAccountMessage() {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const otherIntegration = useOtherIntegration();
  const tOptions = otherIntegration.isActive ? { context: 'other' } : '';

  return (
    <Grid
      autoFlow="column"
      background="redbg"
      gap="space4"
      paddingY="space12"
      paddingX="space16"
      borderRadius="medium"
    >
      <Icon icon="infoCircle" color="red500" size="space16" />
      <Text color="red500" fontSize="small">
        {t('creditCardExporting.missingAccountNumber', tOptions)}
      </Text>
    </Grid>
  );
}

function DatevSettingsNavButton() {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();

  // TODO: replace with a regular RRouter.Link
  const toSettings = useCallback(
    () => navigate(`/${organizationSlug}${Routes.SETTINGS}${Routes.DATEV}`),
    [organizationSlug, navigate]
  );

  return (
    <Button
      width="max-content"
      size="small"
      variant="secondary"
      onClick={toSettings}
    >
      {t('creditCardExporting.accountNumbers.crossLink')}
    </Button>
  );
}

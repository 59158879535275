import { Grid } from '@candisio/design-system';
import {
  DatevClientExportType,
  useUpdateDatevClientCashLedgerConfiguredMutation,
} from 'generated-types/graphql.types';
import { useDatev } from 'orgConfig/datev';
import { i18n } from 'providers/LocaleProvider';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { refetchDatevSettingsQueries } from '../DatevSettings';
import { DatevClient } from '../types';
import { ExportTypesDescription } from './ExportTypesDescription';
import { AccountingDocuments } from './components/AccountingDocuments';
import { CashLedger } from './components/CashLedger';
import { CashLedgerNotAvailable } from './components/CashLedgerNotAvailable';
import { DxsoJobs } from './components/DxsoJobs';
import { DataBookingService } from './components/dataBookingService';
import { useCreditCardsLedgerData } from 'views/Settings/useCreditCardsLedger';

type ExportTypesProps = {
  cashLedgerDocTypesMissingConfig: boolean;
  client: DatevClient;
  exportType: DatevClientExportType;
  onChangeExportType: (exportType: DatevClientExportType) => void;
  setIsCashLedgerSwitchTriggered: Dispatch<SetStateAction<boolean>>;
  isCreditCardActivated: boolean;
  isProvisionsLedgerConfigured?: boolean;
  setIsDatevReconnecting: (flag: boolean) => void;
};

export const ExportTypes = ({
  cashLedgerDocTypesMissingConfig,
  exportType,
  client,
  onChangeExportType,
  setIsCashLedgerSwitchTriggered,
  isCreditCardActivated,
  isProvisionsLedgerConfigured,
  setIsDatevReconnecting,
}: ExportTypesProps) => {
  const { bdsBought, bdsConnected } = useDatev(); // BDS-checked

  const clientIsConnectedToDXSO = exportType === DatevClientExportType.DxsoJobs;
  const clientHasPermissionForDXSO = client.isDxsoJobsAvailable ?? false;
  const currentLanguage = i18n.language;

  const [updateCashLedgerConfig] =
    useUpdateDatevClientCashLedgerConfiguredMutation();

  const onUpdateCashLedgerConfig = useCallback(
    async (isConfigured: boolean) => {
      await updateCashLedgerConfig({
        variables: {
          cashLedgerConfigured: isConfigured,
        },
        refetchQueries: refetchDatevSettingsQueries,
      });
    },
    [updateCashLedgerConfig]
  );

  const { creditCardsLedger } = useCreditCardsLedgerData();
  const creditCardMissingBookingAccount = !creditCardsLedger?.bookingAccount;

  // If datevConnectionType is DatevRewe, then DataBookingService would handle the check for if BDS is successfully connected
  return bdsBought && bdsConnected ? (
    <Grid gap="space24">
      <ExportTypesDescription />
      <DataBookingService
        client={client}
        language={currentLanguage}
        isProvisionsLedgerConfigured={isProvisionsLedgerConfigured}
        setIsDatevReconnecting={setIsDatevReconnecting}
      />
      <CashLedgerNotAvailable />
      <div
        // 👇 an invisible CashLedger is still part of the Grid so we're offsetting its grid gap
        style={clientIsConnectedToDXSO ? undefined : { marginTop: '-3rem' }}
      ></div>
    </Grid>
  ) : (
    <Grid gap="space24">
      <ExportTypesDescription />
      <AccountingDocuments language={currentLanguage} />
      <DxsoJobs
        clientHasPermissionForDXSO={clientHasPermissionForDXSO}
        clientIsConnectedToDXSO={clientIsConnectedToDXSO}
        onEnabled={() => onChangeExportType(DatevClientExportType.DxsoJobs)}
        onDisabled={() => {
          onChangeExportType(DatevClientExportType.AccountingDocuments);
          void onUpdateCashLedgerConfig(false);
        }}
      />
      <CashLedger
        client={client}
        cashLedgerDocTypesMissingConfig={cashLedgerDocTypesMissingConfig}
        onEnabled={() => onUpdateCashLedgerConfig(true)}
        onDisabled={() => onUpdateCashLedgerConfig(false)}
        language={currentLanguage}
        setIsCashLedgerSwitchTriggered={setIsCashLedgerSwitchTriggered}
        clientIsConnectedToDXSO={clientIsConnectedToDXSO}
        isCreditCardActivated={isCreditCardActivated}
        creditCardMissingBookingAccount={creditCardMissingBookingAccount}
      />
    </Grid>
  );
};

import { Heading } from '@candisio/design-system';
import { SwimlaneGroup } from 'components/Swimlane/SwimlaneGroup';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import styles from './ReimbursementDashboard.module.css';
import { ReimbursementDashboardSpinner } from './ReimbursementDashboardSpinner';
import { ReimbursementClosedSwimlane } from './components/Dashboard/ReimbursementClosedSwimlane';
import { ReimbursementDraftSwimlane } from './components/Dashboard/ReimbursementDraftSwimlane';
import { ReimbursementOpenSwimlane } from './components/Dashboard/ReimbursementOpenSwimlane';
import { ReimbursementSummarySwimlane } from './components/Dashboard/ReimbursementSummarySwimlane';
import {
  useGetAggregatedReimbursementCases,
  useGetReimbursementCasesSummary,
} from './hooks/useReimbursementDashboardInsights';
import { ReimbursementCreateDuplicateError } from './components/Dashboard/ReimbursementSummaryCreateDuplicateError';

export const ReimbursementDashboard = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { data, loading: summaryLoading } = useGetReimbursementCasesSummary();

  const {
    draftReimbursementCases,
    openReimbursementCases,
    closedReimbursementCases,
    totalCount,
    loading,
  } = useGetAggregatedReimbursementCases();

  if (loading || summaryLoading) {
    return <ReimbursementDashboardSpinner />;
  }

  const hasAnyExpenses =
    totalCount.draft > 0 || totalCount.open > 0 || totalCount.closed > 0;

  return (
    <div className={styles.layout} data-cy="reimbursementDashboard">
      <Heading as="h1">{t('dashboard.newHeader')}</Heading>
      <SwimlaneGroup>
        <ReimbursementCreateDuplicateError />
        <ReimbursementSummarySwimlane
          reimbursementCasesSummary={data?.reimbursementCasesSummary}
          showCTATile={!hasAnyExpenses}
        />
        <ReimbursementDraftSwimlane
          draftReimbursementCases={draftReimbursementCases}
          isVisible={hasAnyExpenses}
          totalCount={totalCount.draft}
        />
        <ReimbursementOpenSwimlane
          isVisible={!hasAnyExpenses || !!openReimbursementCases?.length}
          openReimbursementCases={openReimbursementCases}
          totalCount={totalCount.open}
        />
        <ReimbursementClosedSwimlane
          closedReimbursementCases={closedReimbursementCases}
          isVisible={!hasAnyExpenses || !!closedReimbursementCases?.length}
          totalCount={totalCount.closed}
        />
      </SwimlaneGroup>
    </div>
  );
};

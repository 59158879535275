import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useDeleteSapB1CredentialsMutation } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { refetchSapSettings } from '../../queries';

interface DisconnectButtonProps {
  isDisabled?: boolean;
}

export const DisconnectButton = ({ isDisabled }: DisconnectButtonProps) => {
  const { success, error } = useToastMessage();

  const [deleteSapB1Credentials, { loading: isDisconnecting }] =
    useDeleteSapB1CredentialsMutation({
      refetchQueries: refetchSapSettings,
    });

  const onDisconnect = async () => {
    const { data } = await deleteSapB1Credentials();

    if (!data?.deleteSapB1Credentials) {
      success(t('settings.summary.disconnect.success'));
    } else {
      error(t('settings.summary.disconnect.errors.unexpected'));
    }
  };
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);
  return (
    <ConfirmationButton
      loading={isDisconnecting}
      disabled={isDisconnecting || isDisabled}
      color="red"
      variant="tertiary"
      size="small"
      closeText={t('settings.summary.disconnectCTA.confirmNo')}
      onConfirm={onDisconnect}
      title={t('settings.summary.disconnectCTA.confirmText')}
      confirmText={t('settings.summary.disconnectCTA.confirmYes')}
    >
      {t('settings.summary.disconnectCTA.text')}
    </ConfirmationButton>
  );
};

import { Grid } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ReimbursementDetailsContainer } from './ReimbursementDetailsContainer';
import { Header } from './components/LeftSection/Header';
import { HistoryAndCommentSectionContainer } from './components/LeftSection/HistoryAndCommentSectionContainer';
import { ReimbursementNavigator } from './components/LeftSection/ReimbursementNavigator';
import { useExpenseFormsHelpers } from './components/MiddleSection/utils/useExpenseFormsHelpers';
import { SectionLayout } from './components/SectionLayout';
import { ReimbursementNavigationResult } from './hooks/useReimbursementNavigation';
import { APPROVING_EDIT_MODE } from './context/ReimbursementFormsProvider';
import { REIMBURSEMENT_URL_PARAM } from './utils/constants';

interface LeftSectionProps extends Partial<ReimbursementNavigationResult> {
  formHelpers: ReturnType<typeof useExpenseFormsHelpers>;
  isLoading: boolean;
}

const ecmParams = [
  APPROVING_EDIT_MODE.PARAM,
  REIMBURSEMENT_URL_PARAM.CURSOR,
  REIMBURSEMENT_URL_PARAM.VIEW,
];

const params = [
  APPROVING_EDIT_MODE.PARAM,
  REIMBURSEMENT_URL_PARAM.CURSOR,
  REIMBURSEMENT_URL_PARAM.DASHBOARDLIST,
];

export const LeftSection = ({
  formHelpers,
  isLoading,
  nextReimbursementLink,
  prevReimbursementLink,
  reimbursementCount,
}: LeftSectionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const navigate = useNavigate();
  const organizationId = useOrganizationId();
  const { state, pathname } = useLocation<{ from: string; search: string }>();
  const { searchParams, updateSearchParam } = useMutateSearchParams();

  const isItemsViewActive = !!searchParams.get(REIMBURSEMENT_URL_PARAM.VIEW);
  const cursor = searchParams.get(REIMBURSEMENT_URL_PARAM.CURSOR);
  const isNavigatorVisible = !isItemsViewActive && !!cursor;

  // XXX Yikes ! We need to parse the URL in order to understand if we are in ECM_DOCUMENTS Route

  const isCyclingThroughECM = pathname.includes(Routes.ECM_DOCUMENTS);

  const isAllowedToCycleInDetailsView =
    isCyclingThroughECM && !!cursor && isItemsViewActive;

  const backText =
    isItemsViewActive && !isCyclingThroughECM
      ? 'reimbursementView.leftSection.header.backAction'
      : 'reimbursementView.leftSection.header.backToList';

  const handleBack = () => {
    const updatedUrlSearchParams = new URLSearchParams(searchParams);
    if (isCyclingThroughECM) {
      ecmParams.forEach(param => updatedUrlSearchParams.delete(param));

      navigate({
        pathname: `/${organizationId}${Routes.ECM_DOCUMENTS}`,
        search: updatedUrlSearchParams.toString(),
      });
      return;
    }

    if (isItemsViewActive) {
      updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, '');
      if (state?.from) {
        return navigate({
          pathname: state.from,
          search: state?.search,
        });
      }

      return;
    }

    params.forEach(param => searchParams.delete(param));

    navigate(
      { pathname: '..', search: searchParams.toString() },
      { relative: 'path' }
    );
  };

  return (
    <SectionLayout
      headerElement={
        <Grid templateColumns="1fr .95fr">
          <Header onBackClick={handleBack} backText={t(backText)} />
          {(isNavigatorVisible || isAllowedToCycleInDetailsView) && (
            <ReimbursementNavigator
              loading={isLoading}
              reimbursementCount={reimbursementCount}
              prevReimbursementLink={prevReimbursementLink}
              nextReimbursementLink={nextReimbursementLink}
            />
          )}
        </Grid>
      }
    >
      <Grid gap="space8" overflowY="auto" templateRows="auto auto 1fr">
        <ReimbursementDetailsContainer
          isLoading={isLoading}
          formHelpers={formHelpers}
        />
        <HistoryAndCommentSectionContainer />
      </Grid>
    </SectionLayout>
  );
};

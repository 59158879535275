import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { KeyStore, KeyStoreReturn } from './createKeyStore.types';

export const createKeyStore = <T extends object, K extends keyof T = keyof T>(
  initialValue: T
): KeyStoreReturn<T> => {
  const store = create<KeyStore<T>>(set => ({
    data: initialValue ?? ({} as Record<K, T[K]>),
    setValue: (key, value) =>
      set(state => {
        const data = {
          ...state.data,
          [key]: value instanceof Function ? value(state.data[key]) : value,
        };
        return { data };
      }),
  }));

  return [
    key => store(state => state.data[key]),
    store.getState().setValue,
    () => store.setState({ data: initialValue }),
    selectorFn =>
      useStoreWithEqualityFn(store, store => selectorFn(store.data), shallow),
  ];
};

import {
  FileState,
  FileUploadAttemptStatus,
} from 'generated-types/graphql.types';
import { useGetFileUploadAttempts } from '../useGetFileUploadAttempts';
import { usePendingFileUploads } from './usePendingFileUploads';

export const useDocumentUploadErrors = () => {
  const pendingData = usePendingFileUploads();
  const { data } = useGetFileUploadAttempts();

  const hasClientError = pendingData.some(
    item => item.state === FileState.Error && !item.isDeleted
  );

  if (hasClientError) {
    return true;
  }

  const hasServerError = !!data?.some(
    item => item.status === FileUploadAttemptStatus.Failed
  );

  return hasServerError;
};

/**
 * When adding a new tour popover, add a enum entry with its id here - this will
 * automatically disable the popover in E2E tests.
 */
export enum TOUR_POPOVER_IDS {
  EXAMPLE_TOUR_POPOVER = 'example-tour-popover',
  MANAGE_RELATIONSHIPS_SEEN = 'manage-relationships-activation-seen',
  BASELINE_SEARCH_PROMOTION_SEEN = 'baseline-search-promotion-seen',
  FILTER_APPROVAL_VIEW_NEW = 'filter-approval-view-new',
  EXPENSE_EXPLAINED_PROMO = 'expense-explained-promo',
  EXPENSE_MOBILE = 'expense-mobile',
  EXPENSE_TIPS = 'expense-tips',
  EXPENSE_REJECTED = 'expense-rejected',
  EXPENSE_UPCOMING_TRAVEL = 'expense-upcoming-travel',
  NEW_DOCUMENT_DETAILS_CARD = 'new-document-details-card',
  ATTACHMENTS_SIDEBAR = 'attachments-sidebar',
  RELATIONS_SIDEBAR = 'relations-sidebar',
  CLASSIFICATION_DOCUMENT_TYPE = 'classification-document-type',
  CREDIT_CARD_EXPLAINED = 'credit-card-explained',
  CREDIT_CARD_CARD_REQUESTS = 'credit-card-card-requests',
  CREDIT_CARD_LIMIT_REQUESTS = 'credit-card-limit-requests',
  CREDIT_CARD_DECLINED_TRANSACTIONS = 'credit-card-declined-transactions',
  CREDIT_CARD_MISSING_INVOICES = 'credit-card-missing-invoices',
  CREDIT_CARD_CHART = 'credit-card-chart',
}

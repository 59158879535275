import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useInitialExportUrl } from 'views/Integrations/Export/Manifest';
import { useExportableEntitiesCounter } from 'views/Integrations/Export/toolkit/hooks/useExportableEntitiesCounter';
import { NavigationIcon } from '../../NavigationIcon';
import { NavIconProps } from '../../../hooks/useNavigationIcons';

export const MenuExport = (iconProps: NavIconProps) => {
  const { isAdmin, isAccountant } = useUserRoles();
  const exportViewDef = useInitialExportUrl();

  const {
    readyToExportEntitiesCount,
    loading: loadingExportableEntitiesCount,
  } = useExportableEntitiesCounter({
    fetchPolicy: 'cache-first',
    skip: !(isAdmin || isAccountant),
  });

  return (
    <NavigationIcon
      {...iconProps}
      count={readyToExportEntitiesCount}
      countLoading={loadingExportableEntitiesCount}
      route={exportViewDef.url || Routes.EXPORTS}
    />
  );
};

import { Icon, Text } from '@candisio/design-system';
import { UploadDropZone } from 'components/UploadDropZone/UploadDropZone';
import { useAppLayout } from 'providers/AppLayoutProvider';
import { Trans } from 'providers/LocaleProvider';
import { useNavigationSidebarContext } from 'providers/NavigationSidebarProvider/NavigationSidebarProvider';
import { usePrevious } from 'react-use';
import { useFileUpload } from '../Header/components/DocumentUploadModal/useFileUpload';
import styles from './NavigationSidebarDropZone.module.css';

export const NavigationSidebarDropZone = () => {
  const { useLayoutValue, setLayoutValue } = useAppLayout();
  const { uploadFiles } = useFileUpload();
  const { open: docUploadPopover } = useLayoutValue('docUploadPopover');
  const { isNavigationOpen } = useNavigationSidebarContext();
  const wasNavigationOpen = usePrevious(isNavigationOpen);

  return (
    <UploadDropZone
      className="rounded-medium inset-4 top-24"
      isDisabled={docUploadPopover}
      onDrop={files => {
        uploadFiles(files);
        setLayoutValue('docUploadPopover', { open: true });
      }}
    >
      <div className={styles.dropzone} data-delay={!wasNavigationOpen}>
        <Icon icon="menuUpload" size={28} />
        <Text className="text-large">
          <Trans
            i18nKey="navigation:dropzoneText"
            components={{ strong: <strong /> }}
          />
        </Text>
      </div>
    </UploadDropZone>
  );
};

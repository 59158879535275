import { Grid } from '@candisio/design-system';
import {
  BankAccountFormValues,
  bankAccountFormSchema,
} from 'components/BankAccountListView/bankAccountFormSchema';
import { HookFormIbanField } from 'components/HookFormFields/HookFormIbanField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';

export interface BankAccountFormProps {
  /** Unique identifier for form */
  id?: string;
  /** Initial values */
  defaultValues?: DefaultValues<BankAccountFormValues>;
  /** Called when submitted */
  onSubmit: (values: BankAccountFormValues) => void;
}

/** Form for creating/editing BankAccounts */
export const BankAccountForm = ({
  id,
  defaultValues,
  onSubmit,
}: BankAccountFormProps) => {
  const [t] = useTranslation('payments');

  const [addBicToOrganizationIbanListFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.addBicToOrganizationIbanList,
  ]);

  const form = useForm({
    defaultValues,
    resolver: zodResolver({
      zodSchema: bankAccountFormSchema,
      translationNamespace: 'payments',
      errorMessages: {
        name: { label: 'bankAccounts.fields.name.label' },
        iban: { label: 'bankAccounts.fields.iban.label' },
        swiftCode: { label: 'bankAccounts.fields.swiftCode.label' },
      },
    }),
    mode: 'onTouched',
  });

  return (
    <FormProvider {...form}>
      <Grid
        as="form"
        id={id}
        onSubmit={form.handleSubmit(values => {
          onSubmit(values as BankAccountFormValues);
        })}
        gap="space16"
      >
        <HookFormTextField
          name="name"
          label={t('bankAccounts.fields.name.label')}
        />
        <HookFormIbanField
          name="iban"
          label={t('bankAccounts.fields.iban.label')}
        />
        {addBicToOrganizationIbanListFF && (
          <HookFormTextField
            name="swiftCode"
            label={t('bankAccounts.fields.swiftCode.label')}
          />
        )}
      </Grid>
    </FormProvider>
  );
};

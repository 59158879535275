import { Button } from '@candisio/design-system';
import styles from './SwimlaneTilePromoCloseButton.module.css';

type SwimlaneTilePromoCloseButtonProps = {
  onClose: () => void;
};

export const SwimlaneTilePromoCloseButton = ({
  onClose,
}: SwimlaneTilePromoCloseButtonProps) => {
  return (
    <Button
      className={styles.closeButton}
      icon="close"
      variant="tertiary"
      color="gray"
      onClick={onClose}
    />
  );
};

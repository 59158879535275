import styles from './ReimbursementInitialCTATile.module.css';
import { CreateReimbursementButton } from './CreateReimbursementButton';
import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { IconBadge } from 'components/IconBadge/IconBadge';

export const ReimbursementInitialCTATile = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <SwimlaneTile cols={2} className={styles.initialTile}>
      <div className="flex flex-col gap-1">
        <div className="flex gap-1">
          <IconBadge icon="receipt" variant="green" />
          <IconBadge icon="hospitality" variant="pink" />
        </div>
        <SwimlaneTileDescription>
          {t('dashboard.createExpense.description')}
        </SwimlaneTileDescription>
      </div>

      <CreateReimbursementButton className="self-end" />
    </SwimlaneTile>
  );
};

import { Grid, Text } from '@candisio/design-system';
import { i18n } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DcsContainer } from '../../DcsContainers/DcsContainer/DcsContainer';
import { DETAILS_MAX_WIDTH } from '../../consts';
import { EXPORT_TYPE_IMAGES } from '../../images';

export const CashLedgerNotAvailable = () => {
  const [t] = useTranslation();
  const language = i18n.language;

  return (
    <DcsContainer
      icon={EXPORT_TYPE_IMAGES.cashLedger[language]}
      iconHeight="114.4px"
      iconWidth="100.8px"
      iconAlt={t('settings.datev.imageAltTexts.cashLedger')}
      title={t(
        'settings.datev.connect.exportTypes.cashLedgerNotAvailable.title'
      )}
      details={
        <Grid justifyItems="left" gap="space8">
          <Text maxWidth={DETAILS_MAX_WIDTH}>
            {t(
              'settings.datev.connect.exportTypes.cashLedgerNotAvailable.info'
            )}
          </Text>
        </Grid>
      }
    />
  );
};

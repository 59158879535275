import {
  useReimbursementCaseByIdQuery,
  useReimbursementItemsListQuery,
} from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useCallback, useEffect } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/utils/constants';

export const useReimbursementRouteValidator = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const navigate = useNavigate();
  const { searchParams } = useMutateSearchParams();
  const { canUseReimbursement } = useReimbursement();

  const skip = !reimbursementId || !canUseReimbursement;

  const { data: reimbursement, loading } = useReimbursementCaseByIdQuery({
    variables: { id: reimbursementId },
    skip,
  });

  const { data: reimbursementItems, loading: loadingItems } =
    useReimbursementItemsListQuery({
      variables: { filters: { reimbursementCaseId: reimbursementId } },
      skip,
    });

  const reimbursementItemsCount =
    reimbursementItems?.reimbursementItemsList.length ?? 0;

  const deleteSearchParams = useCallback(
    (...params: string[]) => {
      params.forEach(param => searchParams.delete(param));
    },
    [searchParams]
  );

  const validateAndNavigate = useCallback(() => {
    if (!loadingItems && !reimbursementItemsCount) {
      deleteSearchParams(REIMBURSEMENT_URL_PARAM.VIEW);
      navigate(
        { pathname: '.', search: searchParams.toString() },
        { replace: true, relative: 'path' }
      );
    }

    if (!loading && !reimbursement) {
      deleteSearchParams(
        REIMBURSEMENT_URL_PARAM.CURSOR,
        REIMBURSEMENT_URL_PARAM.VIEW
      );

      navigate(
        { pathname: '..', search: searchParams.toString() },
        { replace: true, relative: 'path' }
      );
    }
  }, [
    loadingItems,
    reimbursementItemsCount,
    loading,
    reimbursement,
    deleteSearchParams,
    navigate,
    searchParams,
  ]);

  useEffect(() => {
    validateAndNavigate();
  }, [validateAndNavigate]);
};

import clsx from 'clsx';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../DaysStep.module.css';
import { Text } from '@candisio/design-system';
import { Button } from 'react-aria-components';
import {
  GetMultiSelectorStatePerDiemParams,
  SelectAllColumnPerDiemParams,
} from 'views/Reimbursement/hooks/types';

interface MultiSelectorButtonProps {
  displayValue: ReactNode;
  id: string;
  onChange?: (params: SelectAllColumnPerDiemParams) => void;
  onGetMultiSelectorState: (
    params: GetMultiSelectorStatePerDiemParams
  ) => 'allSelected' | 'someSelected' | 'noneSelected';
}

export const MultiSelectorButton = ({
  displayValue,
  id,
  onChange,
  onGetMultiSelectorState,
}: MultiSelectorButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const isDisplayValueString = typeof displayValue === 'string';
  const variant = isDisplayValueString ? 'small' : 'regular';

  const status = onGetMultiSelectorState({ id });

  const handleChange = () => {
    if (status === 'someSelected' || status === 'noneSelected') {
      onChange?.({ value: true, id });
      return;
    }
    onChange?.({ value: false, id });
  };
  return (
    <Button
      id={id}
      data-partial-selected={status === 'someSelected' ? true : undefined}
      data-all-selected={status === 'allSelected' ? true : undefined}
      data-none-selected={status === 'noneSelected' ? true : undefined}
      onPress={handleChange}
      className={clsx(
        styles['toggle-button'],
        styles[`toggle-button--${variant}`]
      )}
    >
      {isDisplayValueString ? (
        <Text fontSize="basic">{t(displayValue)}</Text>
      ) : (
        displayValue
      )}
    </Button>
  );
};

import { Grid, Icon, Text } from '@candisio/design-system';
import clsx from 'clsx';
import { FilePicker } from 'components/FilePicker/FilePicker';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { UploadDropZone } from 'components/UploadDropZone/UploadDropZone';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent, useCallback, useMemo, useRef } from 'react';
import {
  FieldValues,
  Path,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useDragAndDrop } from 'views/TransactionAssociation/component/InvoiceUploadContainer/useDragAndDrop';
import { getColorCode } from 'views/TransactionAssociation/component/InvoiceUploadContainer/util';
import styles from './ExpenseInvoiceInput.module.css';
import { useDropZoneFileStatus } from './utils/useDropZoneFileStatus';

const ACCEPTED_FILE_TYPE = 'application/pdf';

interface ExpenseInvoiceInputFieldProps<TFormValues extends FieldValues> {
  id: string;
  name: Path<TFormValues>;
  control?: UseControllerProps<TFormValues>['control'];
  onChange: (file: File) => Promise<void>;
  onInvoiceUpdate: () => Promise<void>;
  disabled?: boolean;
}

export const ExpenseInvoiceInputField = <TFormValues extends FieldValues>({
  id,
  name,
  control,
  onChange,
  onInvoiceUpdate,
  disabled = false,
}: ExpenseInvoiceInputFieldProps<TFormValues>) => {
  const documentUploadReworkFF = useCandisFeatureFlags(
    FEATURE_FLAGS.documentUploadRework
  );
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const { error } = useToastMessage();
  const { track } = useAnalytics();
  const { fieldState } = useController<TFormValues>({
    name,
    control,
    disabled,
  });

  const isInvalid = !!fieldState.error?.message;

  const onFileSelected = useCallback(
    async (files: File[]) => {
      if (files.some(file => file.type !== ACCEPTED_FILE_TYPE)) {
        error(t('reimbursementView.middleSection.form.fileNotSupportedError'));
        return;
      }
      for (const file of files) {
        await onChange(file);
      }

      await onInvoiceUpdate();

      track(TrackingEvents.REIMBURSEMENT_DOCUMENT_UPLOADED, {
        reimbursement_id: reimbursementId,
      });
    },
    [onChange, onInvoiceUpdate, error, t, track, reimbursementId]
  );

  const {
    draggedFileCount,
    isAcceptedFileType,
    isFileDraggable,
    onFileDrop,
    onDragLeave,
    onDragOver,
    onDropZoneClick,
    onFilesChanged,
  } = useDragAndDrop({ fileUploadRef, onFileSelected, isDisabled: disabled });

  const handleFileSelect = useCallback(
    (files: FileList | null) => {
      if (files) {
        onFileSelected(Array.from(files));
      }
    },
    [onFileSelected]
  );

  const { statusText, canUserDropFile } = useDropZoneFileStatus(
    draggedFileCount,
    isAcceptedFileType
  );

  const style = useMemo(() => {
    const baseColor = canUserDropFile ? 'gray700' : 'red700';

    const getFileDraggableBgColor = () =>
      isFileDraggable ? getColorCode('background', canUserDropFile) : 'gray0';

    return {
      textColor: isInvalid ? 'red700' : baseColor,
      bgColor: getFileDraggableBgColor(),
      iconColor: disabled ? 'gray400' : 'gray500',
    };
  }, [canUserDropFile, disabled, isFileDraggable, isInvalid]);

  return documentUploadReworkFF ? (
    <FilePicker onSelect={handleFileSelect}>
      <div className="flex flex-col items-center gap-2 py-6">
        <Icon size={28} icon="menuUpload" />
        <span>
          <Trans
            i18nKey="reimbursement:reimbursementView.middleSection.form.dropzoneText"
            components={{ strong: <strong /> }}
          />
        </span>
      </div>
      <UploadDropZone onDrop={handleFileSelect}>
        <Icon size={28} icon="menuUpload" />
        <span>
          <Trans
            i18nKey="reimbursement:reimbursementView.middleSection.form.dropzoneDragDropText"
            components={{ strong: <strong /> }}
          />
        </span>
      </UploadDropZone>
    </FilePicker>
  ) : (
    <Grid background={style.bgColor}>
      <div
        className={clsx(
          styles.dropZone,
          disabled ? styles.dropZoneDisabled : styles.dropZoneEnabled
        )}
        draggable={isFileDraggable}
        onDrop={onFileDrop}
        onDragOver={onDragOver}
        onClick={onDropZoneClick}
        onDragLeave={onDragLeave}
      >
        <Grid placeItems="center">
          <Icon
            size="56px"
            icon={canUserDropFile ? 'uploadOutlined' : 'warningOutlined'}
            color={style.iconColor}
          />
          <Trans
            as="label"
            htmlFor={id}
            key={statusText()}
            onClick={(e: MouseEvent<HTMLLabelElement>) => e.preventDefault()}
          >
            <Text
              lineHeight="19.5px"
              fontSize="basic"
              color={style.textColor}
              textAlign="center"
            >
              {statusText()}
            </Text>
          </Trans>
        </Grid>
        <input
          id={id}
          name={name}
          disabled={disabled}
          data-cy="file-upload-expense"
          type="file"
          accept={ACCEPTED_FILE_TYPE}
          ref={fileUploadRef}
          onChange={onFilesChanged}
          hidden
        />
      </div>
    </Grid>
  );
};

import { Flex, Paragraph } from '@candisio/design-system';
import { CardStatus } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { useCreditCards } from 'providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import {} from 'react-router-dom-v5-compat';
import { CreditCardCarouselContainer } from '../CreditCardCarousel/CreditCardCarousel';
import { CreditCardRequestViewLoader } from '../components/CreditCardsSection';
import { getVariantMap } from '../components/CreditCardsSection/utils';
import { CreditCardsDetailsDrawer } from 'views/CreditCards/CreditCardDetailsDrawer';
import { CreditCardsIssueNewCardModal } from 'views/CreditCards/CreditCardsInsights/CreditCardsIssueNewCardModal';
import { useTranslation } from 'react-i18next';

interface WalletProps {
  userName: string | undefined;
}

export const cardStatusesForWallet = [
  CardStatus.Active,
  CardStatus.Locked,
  CardStatus.LockedPin,
  CardStatus.Pending,
  CardStatus.Requested,
  CardStatus.Processing,
];

export const Wallet = ({ userName }: WalletProps) => {
  const { isAdmin } = useUserRoles();
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { currentCardHolderCards: cards, loading } = useCreditCards();

  const activeStateWithNoCards = getVariantMap({
    userName: userName || '',
    isWalletEnabled: true,
    isAdmin,
  }).ACTIVE;

  if (loading) {
    return <CreditCardRequestViewLoader />;
  }

  return (
    <CreditCardsProvider>
      {cards.length === 0 ? (
        <Flex direction="column" gap="space8">
          {activeStateWithNoCards}
          <Paragraph color="gray600">
            {t('dashboard.drawer.form.footerText')}
          </Paragraph>
        </Flex>
      ) : (
        <CreditCardCarouselContainer />
      )}

      <CreditCardsIssueNewCardModal />
      <CreditCardsDetailsDrawer />
    </CreditCardsProvider>
  );
};

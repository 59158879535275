import { Swimlane } from 'components/Swimlane/Swimlane';
import { SwimlaneHeader } from 'components/Swimlane/SwimlaneHeader';
import { SwimlaneHeaderCount } from 'components/Swimlane/SwimlaneHeaderCount';
import { SwimlaneTiles } from 'components/Swimlane/SwimlaneTiles';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { CardRequestContentTile } from './CardRequestContentTile';
import { CardIssuerCardEdgeDataFragment } from 'generated-types/graphql.types';
import { ShowAllRequests } from '../shared/ShowAllRequests/ShowAllRequests';
import { pendingCardRequestsStatues } from '../hooks/useCreditCardInsights';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { PromoCreditCardCardRequests } from '../promos/PromoCreditCardCardRequests';

export const CardRequestsSwimlane = ({
  isVisible,
  cardRequests,
  totalCount = 0,
}: {
  isVisible: boolean;
  cardRequests?: CardIssuerCardEdgeDataFragment['node'][];
  totalCount?: number;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isPromotionSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.CREDIT_CARD_CARD_REQUESTS
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Swimlane data-cy="cardRequestsSwimlane">
      <SwimlaneTiles
        contentTileCount={totalCount}
        promoTileCount={!isPromotionSeen && totalCount > 0 ? 1 : undefined}
      >
        {({
          contentTileVisibleCount,
          isShowAllTileVisible,
          isPromoTileVisible,
        }) => (
          <>
            <SwimlaneHeader
              icon="creditCardAdd"
              label={
                totalCount === 1
                  ? t('insights.requestsOverview.cardRequests')
                  : t('insights.requestsOverview.cardRequests_plural')
              }
            >
              <SwimlaneHeaderCount
                count={contentTileVisibleCount}
                totalCount={totalCount}
              />
            </SwimlaneHeader>

            {cardRequests &&
              cardRequests.length > 0 &&
              Array.from({ length: contentTileVisibleCount }, (_, index) => (
                <CardRequestContentTile
                  key={cardRequests[index].id}
                  request={cardRequests[index]}
                />
              ))}

            {isShowAllTileVisible && (
              <ShowAllRequests statues={pendingCardRequestsStatues} />
            )}

            {isPromoTileVisible && <PromoCreditCardCardRequests />}
          </>
        )}
      </SwimlaneTiles>
    </Swimlane>
  );
};

import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';
import { SwimlaneTilePromo } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromo';
import { SwimlaneTilePromoCTAButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCTAButton';
import {
  SwimlaneTilePromoContent,
  SwimlaneTilePromoContentBody,
} from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoContent';
import { SwimlaneTilePromoCloseButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCloseButton';
import { SwimlaneTilePromoImage } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoImage';
import { SwimlaneTileTitle } from 'components/Swimlane/content/SwimlaneTileTitle';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { WistiaVideoModalLauncher } from 'components/WistiaModal/WistiaModalLauncher';

export const PromoExpenseExplained = ({ onClose }: { onClose: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <SwimlaneTilePromo>
      <SwimlaneTilePromoImage
        src={t('dashboard.swimlanes.promotions.promoExpenseExplained.imageUrl')}
      />
      <SwimlaneTilePromoContent>
        <SwimlaneTilePromoCloseButton onClose={onClose} />
        <SwimlaneTilePromoContentBody>
          <SwimlaneTileDescription>
            {t(
              'dashboard.swimlanes.promotions.promoExpenseExplained.description'
            )}
          </SwimlaneTileDescription>
          <SwimlaneTileTitle>
            {t('dashboard.swimlanes.promotions.promoExpenseExplained.title')}
          </SwimlaneTileTitle>

          <WistiaVideoModalLauncher
            wistiaId={t(
              'dashboard.swimlanes.promotions.promoExpenseExplained.ctaButton.wistiaId'
            )}
          >
            <SwimlaneTilePromoCTAButton icon="arrowRight" iconPosition="right">
              {t(
                'dashboard.swimlanes.promotions.promoExpenseExplained.ctaButton.text'
              )}
            </SwimlaneTilePromoCTAButton>
          </WistiaVideoModalLauncher>
        </SwimlaneTilePromoContentBody>
      </SwimlaneTilePromoContent>
    </SwimlaneTilePromo>
  );
};

import { Box, Flex } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { useCreditCardsLedgerUpdate } from 'views/Settings/useCreditCardsLedger';
import { refetchDatevData } from 'views/Settings/Integrations/DATEV/gql';
import { useDatevBookingAccountValidation } from 'views/Settings/Integrations/DATEV/useDatevBookingAccountValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import { getPlaceholderBookingAccount } from '../BookingAccountForm/BookingAccountForm.helper';
import { useOtherIntegration } from 'orgConfig/other';

interface TransitAccountFormProps {
  accountLength: number;
  transitAccount: string;
}

type TransitAccountFormData = {
  transitAccount: string;
};

export const TransitAccountForm = ({
  accountLength,
  transitAccount,
}: TransitAccountFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const otherIntegration = useOtherIntegration();
  const { schema, errorMessages } =
    useDatevBookingAccountValidation('transitAccount');

  const form = useForm({
    defaultValues: { transitAccount },
    context: { t },
    resolver: zodResolver({
      zodSchema: schema({ t }),
      errorMessages: errorMessages({
        t,
        label: 'creditCardExporting.transitAccount.textInput.ariaLabel',
      }),
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
    }),
    mode: 'onSubmit',
  });

  const [updateCreditCardsLedger, { loading: isLoading }] =
    useCreditCardsLedgerUpdate();

  const onUpdateBookingAccount = useCallback(
    async ({ transitAccount }: TransitAccountFormData) => {
      const result = await updateCreditCardsLedger({
        variables: { bookingAccount: undefined, transitAccount },
        refetchQueries: refetchDatevData,
      });

      return result;
    },
    [updateCreditCardsLedger]
  );

  const handleSubmit = async ({
    transitAccount: value,
  }: TransitAccountFormData) => {
    const transitAccount = !value ? '' : value;
    const result = await onUpdateBookingAccount({ transitAccount });

    if (result.data) {
      form.resetField('transitAccount', {
        defaultValue: transitAccount,
      });
    }
  };

  const isFormDirty = form.formState.isDirty;

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        onSubmit={form.handleSubmit(handleSubmit)}
        gap="space16"
        alignItems="end"
      >
        <HookFormTextField
          autoFocus
          label={t('creditCardExporting.transitAccount.textInput.ariaLabel', {
            context: otherIntegration.isActive ? 'other' : '',
          })}
          name="transitAccount"
          placeholder={t(
            'creditCardExporting.transitAccount.textInput.placeholder',
            { accountNumber: getPlaceholderBookingAccount(accountLength) }
          )}
          clearable={false}
        />
        <Box paddingTop="space16">
          <QuickSaveButtons
            isFormDirty={isFormDirty}
            resetField={() => form.resetField('transitAccount')}
            isLoading={isLoading}
          />
        </Box>
      </Flex>
    </FormProvider>
  );
};

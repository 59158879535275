import { AssociationSource } from 'generated-types/graphql.types';
import { formatIntegerAmountToDecimal } from 'hooks/useMoneyFormatter';
import { isEmpty, keyBy } from 'lodash';
import { BookingsFormPartialData } from 'views/DocumentDetails/BookingsFormContext';
import {
  MatchState,
  ThreeWayMatchBookingData,
  ThreeWayMatchGoodsReceiptData,
  ThreeWayMatchPurchaseOrderData,
  ThreeWayMatchRowData,
  matchState,
} from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/types';

const isBookingAdditionalExpense = (
  booking: ThreeWayMatchBookingData
): boolean => {
  return (
    !booking?.quantity &&
    (booking?.description?.toLowerCase().includes('freight') ||
      booking?.description?.toLowerCase().includes('packaging'))
  );
};

export const getThreeWayMatchResult = ({
  bookingsData,
  purchaseOrdersData,
  goodsReceiptsData,
  linkingSource,
}: {
  bookingsData: ThreeWayMatchBookingData[];
  purchaseOrdersData: ThreeWayMatchPurchaseOrderData[];
  goodsReceiptsData: ThreeWayMatchGoodsReceiptData[];
  linkingSource?:
    | AssociationSource.GoodsReceipt
    | AssociationSource.PurchaseOrder;
}): ThreeWayMatchRowData[] => {
  const result: ThreeWayMatchRowData[] = [];

  const hasAnyAssociatedDocuments =
    purchaseOrdersData.length + goodsReceiptsData.length > 0;

  if (!bookingsData.length || !hasAnyAssociatedDocuments) {
    return result;
  }

  const purchaseOrderDataHashMap = keyBy(purchaseOrdersData, 'bookingId');
  const goodsReceiptsDataHashMap = keyBy(goodsReceiptsData, 'bookingId');

  bookingsData.forEach(booking => {
    const isBookingIsAdditionalExpense = isBookingAdditionalExpense(booking);
    const { bookingId: key, description, price, quantity } = booking;
    result.push({
      id: key,
      description,
      goodsReceiptQuantity: goodsReceiptsDataHashMap[key]?.quantity,
      invoiceAmount: price,
      invoiceQuantity: quantity ?? 0,
      purchaseOrderAmount: purchaseOrderDataHashMap[key]?.unitPrice,
      purchaseOrderQuantity: purchaseOrderDataHashMap[key]?.quantity,
      articleNumber:
        purchaseOrderDataHashMap[key]?.articleNumber ??
        goodsReceiptsDataHashMap[key]?.articleNumber ??
        '-',
      isAmountMatched: isBookingIsAdditionalExpense
        ? false
        : isAmountMatched(
            purchaseOrderDataHashMap[key],
            booking,
            goodsReceiptsDataHashMap[key],
            linkingSource
          ),
      isQuantityMatched: isBookingIsAdditionalExpense
        ? true
        : isQuantityMatched(
            purchaseOrderDataHashMap[key],
            goodsReceiptsDataHashMap[key],
            quantity ?? 0
          ),
      goodsReceiptsDetails: goodsReceiptsDataHashMap[key]?.items,
      goodsReceiptAmount: goodsReceiptsDataHashMap[key]?.unitPrice,
    });
  });

  return result;
};

export const getThreeWayMatchGeneralState = (
  data: ThreeWayMatchRowData[]
): MatchState => {
  if (isEmpty(data)) return matchState.default;
  const isAnyQuantityMisMatch = data.some(row => !row.isQuantityMatched);
  const isAnyAmountMisMatch = data.some(row => !row.isAmountMatched);

  return isAnyQuantityMisMatch || isAnyAmountMisMatch
    ? matchState.warning
    : matchState.success;
};

const isAmountMatched = (
  purchaseOrder: ThreeWayMatchPurchaseOrderData | undefined,
  bookingFormData: BookingsFormPartialData[number] | undefined,
  goodsReceipt: ThreeWayMatchGoodsReceiptData | undefined,
  linkingSource?: AssociationSource
): boolean => {
  if (!bookingFormData) {
    return false;
  }
  const bookingFormDecimalAmount = bookingFormData.price
    ? formatIntegerAmountToDecimal(bookingFormData.price.amount, 0)
    : 0;
  const goodsReceiptDecimalAmount = goodsReceipt?.unitPrice
    ? formatIntegerAmountToDecimal(
        goodsReceipt.unitPrice.amount,
        goodsReceipt.unitPrice.precision
      )
    : 0;
  const purchaseOrderDecimalAmount = purchaseOrder?.unitPrice
    ? formatIntegerAmountToDecimal(
        purchaseOrder.unitPrice.amount,
        purchaseOrder.unitPrice.precision
      )
    : 0;

  if (linkingSource === AssociationSource.GoodsReceipt) {
    if (!purchaseOrder) {
      return bookingFormDecimalAmount === goodsReceiptDecimalAmount;
    }

    return (
      bookingFormDecimalAmount === goodsReceiptDecimalAmount &&
      goodsReceiptDecimalAmount === purchaseOrderDecimalAmount
    );
  }
  return bookingFormDecimalAmount === purchaseOrderDecimalAmount;
};

const isQuantityMatched = (
  purchaseOrder: ThreeWayMatchPurchaseOrderData,
  goodsReceipt: ThreeWayMatchGoodsReceiptData,
  quantity: number
) => {
  if (!purchaseOrder?.quantity) {
    return goodsReceipt?.quantity === quantity;
  }
  return (
    purchaseOrder?.quantity === quantity && goodsReceipt?.quantity === quantity
  );
};

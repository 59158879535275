import { Box, Flex, Grid } from '@candisio/design-system';
import { WidgetFormContainer } from 'components/Form/WidgetFormContainer';
import { NewsCarousel, PromotionsCarousel } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionsWithEntitlements } from 'components/NewPromotions/hooks/usePromotionsWithEntitlements';
import {
  OrganizationsInsightsDetails,
  useGetOrganizationsInsightsDetailsQuery,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useDocumentUploadDispatch } from 'providers/DocumentUploadProvider/DocumentUploadProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useEffect } from 'react';
// Use v5 version of useParams because we’re inside a v5 route
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { useCalculateTotalTaskAndOverdue } from 'views/AppContainer/components/Header/components/MultiOrganizationOverview/components/DataTable/utils';
import { InsightsWidgets } from '../InsightsWidgets/InsightsWidgets';
import { HeaderGreeter } from './components/HeaderGreeter';
import { OrganizationTodosPanel } from './components/OrganizationTodosPanel';
import { RecurringPaymentsContainer } from './components/RecurringPayments/RecurringPaymentsContainter';

export type DashboardProps = {
  onOpenWidgetModal: () => void;
  onCloseWidgetModal: () => void;
};

export const NewDashboardComponent = ({
  onOpenWidgetModal,
  onCloseWidgetModal,
}: DashboardProps) => {
  const history = useHistory();
  const user = useCurrentUser();
  const creditCardsSetup = useCreditCardsSetup();
  const selectedOrganization = useFullOrganization();
  const { isAdmin, isCardManager } = useUserRoles();
  const { identify } = useAnalytics();

  const { promotions } = usePromotionsWithEntitlements();

  const shouldShowRecurringPayments =
    creditCardsSetup.showAllExtraFeatures && (isAdmin || isCardManager);

  const organizationId = selectedOrganization?.id;
  const { data, loading } = useGetOrganizationsInsightsDetailsQuery({
    fetchPolicy: 'no-cache',
    skip: !organizationId,
    variables: organizationId ? { ids: [organizationId] } : undefined,
  });

  const organizationList = (data?.getOrganizationsInsightsDetails ||
    []) as OrganizationsInsightsDetails[];

  const selectedOrganizationData = organizationList?.find(
    ({ organization }) => organization?.slug === organizationId
  );

  const transformedOrganizationData = useCalculateTotalTaskAndOverdue(
    selectedOrganizationData
  );

  const { membershipId, taskBreakdown, slug } = transformedOrganizationData;

  const dispatch = useDocumentUploadDispatch();

  const visible = history.location.pathname.includes(Routes.DASHBOARD_WIDGET);

  const handleClickUploadDocument = () => {
    dispatch('showModal');
  };

  useEffect(() => {
    if (loading) return;

    identify({
      inbox_documents_count: taskBreakdown.toProcess?.total,
      open_approvals_count: taskBreakdown.toApprove?.total,
    });
  }, [
    identify,
    loading,
    taskBreakdown.toApprove?.total,
    taskBreakdown.toProcess?.total,
  ]);

  return (
    <Box
      data-cy="dashboard-view"
      height="100%"
      padding="space16 space32 space16 space16"
      width="100%"
    >
      <HeaderGreeter firstName={user?.firstName} />
      <Grid gap="space32" paddingLeft="space16" templateColumns="auto 32rem">
        <Flex direction="column" gap="space24" flexGrow="1">
          <Flex direction="column" gap="space24">
            <OrganizationTodosPanel
              loading={loading}
              organizationId={slug}
              membershipId={membershipId}
              taskBreakdown={taskBreakdown}
              onClickTask={(path: string) => history.push(path)}
              onClickUploadDocument={handleClickUploadDocument}
            />
            {shouldShowRecurringPayments && <RecurringPaymentsContainer />}
          </Flex>
          <InsightsWidgets onOpenWidgetModal={onOpenWidgetModal} />
        </Flex>

        <Grid>
          <PromotionsProvider promotions={promotions}>
            <PromotionsCarousel />
          </PromotionsProvider>
          <NewsCarousel />
        </Grid>
      </Grid>
      <WidgetFormContainer
        visible={visible}
        onCloseWidgetModal={onCloseWidgetModal}
      />
    </Box>
  );
};

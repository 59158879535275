import { Button } from '@candisio/design-system';
import { Icon, TruncatedText } from '@candisio/design-system/next';
import clsx from 'clsx';
import DocumentIcon from '../../assets/document.svg?react';
import styles from './UploadHistoryItem.module.css';

type Props = {
  error: string;
  fileName: string;
  onRemove: () => void;
};

export const UploadHistoryItemError = ({
  error,
  fileName,
  onRemove,
}: Props) => {
  return (
    <div className={clsx(styles.wrapper, styles.item)}>
      <div className="relative">
        <DocumentIcon className={styles.documentIcon} data-variant="error" />
        <Icon
          className="absolute bottom-1 left-2.5 text-red-600 size-4"
          icon="failCircle"
        />
      </div>
      <div className="flex flex-col grow gap-0.5">
        <TruncatedText className={styles.title}>{fileName}</TruncatedText>
        <span className={styles.errorText}>{error}</span>
        <Button
          className={styles.removeButton}
          icon="close"
          onClick={onRemove}
          variant="tertiary"
        />
      </div>
    </div>
  );
};

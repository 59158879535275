import { Box, FieldContainer, RadioGroup } from '@candisio/design-system';
import { RadioWithTooltip } from 'components/RadioGroup/RadioWithTooltip';
import { PurchaseOrderType } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

interface ProcessingFormPurchaseOrderTypeFieldProps {
  isGoodsInvoice: boolean;
}
// TODO: TPX update messages in the tooltips
export function ProcessingFormPurchaseOrderTypeField({
  isGoodsInvoice = false,
}: ProcessingFormPurchaseOrderTypeFieldProps) {
  const [t] = useTranslation();

  return (
    <FieldContainer readOnly>
      <RadioGroup
        orientation="horizontal"
        value={
          isGoodsInvoice ? PurchaseOrderType.Goods : PurchaseOrderType.Service
        }
      >
        <RadioWithTooltip
          isReadOnly
          value={PurchaseOrderType.Service}
          tooltipChildren={
            !isGoodsInvoice ? (
              <Box width="space256">
                {t(
                  'document.requestApproval.invoiceType.serviceInvoiceTooltip'
                )}
              </Box>
            ) : undefined
          }
        >
          {t('document.requestApproval.invoiceType.labels.service')}
        </RadioWithTooltip>
        <RadioWithTooltip
          isReadOnly
          value={PurchaseOrderType.Goods}
          tooltipChildren={
            isGoodsInvoice ? (
              <Box width="space256">
                {t('document.requestApproval.invoiceType.goodsInvoiceTooltip')}
              </Box>
            ) : undefined
          }
        >
          {t('document.requestApproval.invoiceType.labels.goods')}
        </RadioWithTooltip>
      </RadioGroup>
    </FieldContainer>
  );
}

import { createAtomicStore } from './createAtomicStore';
import { AtomicStoreReturn } from './createAtomicStore.types';
import {
  StoreProviderAndHook,
  createStoreProvider,
} from './createStoreProvider';

export const createAtomicStoreProvider = <T>(
  providerName?: string,
  hookName?: string
): StoreProviderAndHook<T, AtomicStoreReturn<T>> => {
  return createStoreProvider(createAtomicStore, providerName, hookName);
};

import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom-v5-compat';
import { InfoCard } from './InfoCard';
import { getCardExpiringSearchParams } from '../CreditCardsInsightSwimlanes/OverviewSwimlane/CardExpiresSoonTile/utils';
import { useGetPath } from 'hooks/usePath';

interface ExpiringCardsProps {
  value: number;
}

export const ExpiringCards = ({ value }: ExpiringCardsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const navigate = useNavigate();
  const { isCardManager } = useUserRoles();
  const getPath = useGetPath();

  const handleClick = () => {
    navigate(
      getPath({
        pathname:
          '/credit_cards/dashboard/card_manager_overview/:creditCardId?',
        search: getCardExpiringSearchParams(),
      })
    );
  };

  return (
    <InfoCard
      value={value}
      text={t('insights.requestsOverview.expiringCards', { count: value })}
      color="red500"
      icon="warning"
      onClick={isCardManager ? handleClick : undefined}
    />
  );
};

import { Flex, Image } from '@candisio/design-system';
import {
  ExportStatus,
  ExportType as GqlExportType,
  useExportDatevDuoMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDatev } from 'orgConfig/datev';
import { ExportNotificationsContext } from 'providers/ExportNotificationsProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useExportContext } from 'views/Integrations/Export/Context';
import { defineExportView } from 'views/Integrations/Export/Manifest';
import { BdsSetupHintPanel } from 'views/Integrations/Export/toolkit/components/BdsSetupHint';
import { ExportInfo } from 'views/Integrations/Export/toolkit/components/ExportInfo';
import { InitExportTypeLabel } from 'views/Integrations/Export/toolkit/containers/ExportEntitiesSummary/InitExportTypeLabel';
import { InitExportSummary } from 'views/Integrations/Export/toolkit/containers/ExportableEntitiesSummary/Init';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import datevApiIcon from 'views/Integrations/Export/toolkit/images/icon-datev-api.svg';
import { getRefetchExportsQueries } from 'views/Integrations/Export/toolkit/queries';
import { Sidebar, View } from 'views/Integrations/Export/types';
import { RdsExportButton } from './RdsExportButton';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

export const RDS = () => {
  const state = useExportContext();
  const counterQueries = useCounterQueries();
  const { addExport } = useContext(ExportNotificationsContext);
  const orgId = useOrganizationId();
  const navigate = useNavigate();

  const { bdsBoughtButNotConnected } = useDatev(); // BDS-checked

  const { exportType, exportId } = state;

  const {
    exportEntities: {
      ready: { all: allEntities },
    },
    exportEntitiesIds,
    exportAll,
    readyToExportEntitiesCountByType,
  } = useExportDataGigaFactory(state);

  const {
    documentIds,
    transactionIds,
    cardSettlementIds,
    reimbursementItemsIds,
  } = exportEntitiesIds;

  const hasExportableEntity =
    exportAll ||
    !!documentIds.length ||
    !!transactionIds.length ||
    !!cardSettlementIds.length ||
    !!reimbursementItemsIds.length;

  const enableNewIndexInExportsViewFF = useCandisFeatureFlags(
    FEATURE_FLAGS.enableNewIndexInExportsView
  );

  const [requestDatevDuoExport, { loading: loadingDatevDuoExport }] =
    useExportDatevDuoMutation({
      refetchQueries: [
        ...getRefetchExportsQueries({
          documentIds,
          enableNewIndexInExportsViewFF,
        }),
        ...counterQueries,
      ],
      awaitRefetchQueries: true,
    });

  const onExport = async () => {
    const getDatevExportResponse = async () => {
      const { data: datevExportRes } = await requestDatevDuoExport({
        variables: {
          documentIds: exportAll ? [] : documentIds,
          transactionIds: exportAll ? [] : transactionIds,
          cardSettlementIds: exportAll ? [] : cardSettlementIds,
          reimbursementIds: exportAll ? [] : reimbursementItemsIds,
          originalExportGuid: exportId,
          exportAll,
        },
      });

      return datevExportRes?.exportDatevDuo;
    };

    const exportDatevResponse = await getDatevExportResponse();

    if (!exportDatevResponse) return;

    addExport({
      hash: exportDatevResponse,
      organization: orgId ?? '',
      status: ExportStatus.Exporting,
      documents: allEntities.length,
      type: GqlExportType.Datev,
    });

    const viewDef = defineExportView(
      {
        ...state,
        sidebar: Sidebar.HISTORY,
        view: View.HISTORY,
        exportId: exportDatevResponse,
        shouldTriggerDownload: true,
      },
      { orgId }
    );

    navigate(viewDef.navigate);
  };

  return (
    <Flex direction="column" justifyContent="space-between">
      <Flex direction="column" gap="space24">
        <ExportInfo>
          <Image
            src={datevApiIcon}
            alt={exportType ?? ''}
            height="84px"
            width="84px"
            alignSelf="center"
          />
          <InitExportTypeLabel />
        </ExportInfo>
        <InitExportSummary
          readyToExportEntitiesCount={readyToExportEntitiesCountByType}
        />
        {bdsBoughtButNotConnected && <BdsSetupHintPanel />}
      </Flex>
      <RdsExportButton
        loading={loadingDatevDuoExport}
        entities={allEntities}
        hasExportableEntity={hasExportableEntity}
        onExport={onExport}
      />
    </Flex>
  );
};

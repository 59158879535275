import { Box } from '@candisio/design-system';
import { SearchField } from 'components/SearchField/SearchField';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';

type SearchLinkableProps = {
  searchValue: string;
  linkType: 'goodsReceipts' | 'purchaseOrders';
  contactName?: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
};

export const LinkableSearchField = ({
  linkType,
  searchValue,
  contactName,
  setSearchValue,
}: SearchLinkableProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  const searchPlaceholder = useMemo(() => {
    if (linkType === 'goodsReceipts') {
      return contactName
        ? t('goodsReceipts.search.placeholderWithContact', { contactName })
        : t('goodsReceipts.search.placeholder');
    } else {
      return contactName
        ? t('search.placeholderWithContact', { contactName })
        : t('search.placeholder');
    }
  }, [contactName, linkType, t]);

  const handleDebounceSearch = useMemo(() => {
    return debounce((value: string) => {
      setSearchValue(value);
    }, DEFAULT_DEBOUNCE_TIME);
  }, [setSearchValue]);

  useEffect(() => {
    return () => {
      handleDebounceSearch.cancel();
    };
  }, [handleDebounceSearch]);

  return (
    <Box width="24rem">
      <SearchField
        clearTooltip={t('search.clearTooltip')}
        placeholder={searchPlaceholder}
        initialValue={searchValue}
        value={searchValue}
        onChange={query => {
          handleDebounceSearch(query);
        }}
        inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
      />
    </Box>
  );
};

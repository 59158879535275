import { usePermissionsForMembership } from 'hooks/usePermissionsForMembership';
import { useUserRoles } from 'hooks/useUserRoles';

interface PermissionsForTeamMembers {
  canInviteMember: boolean;
  canDeactivateMember: boolean;
  canModifyPersonalInfo: boolean;
  canModifyMemberRoles: boolean;
  canModifyMemberAbsence: boolean;
}

export const usePermissionsForTeamMembers = (): PermissionsForTeamMembers => {
  const { isAdmin } = useUserRoles();
  const permission = usePermissionsForMembership();

  return {
    canInviteMember: permission['organization.invite_member'] ?? false,
    canModifyMemberAbsence:
      permission['organization.modify_any_member_absence'] ?? false,
    canModifyMemberRoles: permission['organization.manage_any_role'] ?? false,
    // Permission in backend is not implemented yet
    canModifyPersonalInfo: isAdmin ?? false,
    canDeactivateMember: isAdmin ?? false,
  };
};

import { Tag, TagProps } from '@candisio/design-system';
import { CellContext } from '@tanstack/react-table';
import { DocumentsTableData } from 'components/DocumentsTable/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useExportContext } from 'views/Integrations/Export/Context';
import { View } from 'views/Integrations/Export/types';

type Value = string | undefined;
type CellProps = CellContext<DocumentsTableData, Value>;

export const SettlementPaymentStatusCell = ({ getValue }: CellProps) => {
  // TODO: find out a way to distinct Not Ready settlements
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const { view } = useExportContext();
  const value = getValue();
  const isPaid = value === 'PAID';
  const isAwaitingPdf = isPaid && view === View.NOT_READY;

  let readable = isPaid ? t('isPaid.paid') : t('isPaid.notPaid');

  if (isAwaitingPdf) {
    readable = t('isPaid.awaitingPdf');
  }

  const color: TagProps['color'] = isAwaitingPdf ? 'yellow' : 'gray';

  return (
    <Tag color={color} variant="secondary">
      {readable}
    </Tag>
  );
};

import { Modal } from '@candisio/design-system';
import { PulseIndicator } from 'components/PulseIndicator/PulseIndicator';
import { useSegmentDestinations } from 'providers/AnalyticsProvider/useSegmentDestinations';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useIntercomContext } from 'providers/IntercomProvider/IntercomProvider';
import { usePrivacyContext } from 'providers/PrivacyConsentProvider/PrivacyConsentProvider';
import { useTrackingPreferences } from 'providers/PrivacyConsentProvider/hooks/useTrackingPreferences';
import { useCallback, useState } from 'react';
import { NavigationIcon } from '../../NavigationIcon';
import { IntercomPrivacyModalContent } from './IntercomPrivacyModalContent/IntercomPrivacyModalContent';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

export const MenuIntercom = ({ expanded }: { expanded: boolean }) => {
  const destinations = useSegmentDestinations();
  const currentUser = useCurrentUser();
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);

  const { showConsentManager, handleSavePreferences } = usePrivacyContext();
  const { unreadCount, intercomIsEnabled } = useIntercomContext();
  const { trackingEnabled } = useTrackingPreferences();

  const [isIntercomPrivacyModalOpen, setIsIntercomPrivacyModalOpen] =
    useState(false);

  const handleIntercomToggle = () =>
    handleSavePreferences(trackingEnabled, true);

  const toggleModal = useCallback(
    () => setIsIntercomPrivacyModalOpen(isOpen => !isOpen),
    []
  );

  const openPrivacyModal = useCallback(() => {
    toggleModal();
    showConsentManager();
  }, [toggleModal, showConsentManager]);

  if (!currentUser || destinations.loading) return null;

  const hasUnreadMessages = unreadCount > 0;

  return (
    <>
      <NavigationIcon
        icon="menuIntercomAlternative"
        label={t('menuLabels.support')}
        expanded={expanded}
        data-cy="nav-item-support"
        onClick={intercomIsEnabled ? undefined : toggleModal}
        data-intercom={intercomIsEnabled ? 'intercom-chat' : undefined}
        pulseIndicator={
          hasUnreadMessages ? (
            <PulseIndicator status="urgent" source="customIntercom" />
          ) : undefined
        }
        count={hasUnreadMessages ? unreadCount : undefined}
        showLabelInTooltip={!isIntercomPrivacyModalOpen}
      />
      <Modal
        isOpen={isIntercomPrivacyModalOpen}
        onClose={toggleModal}
        padding="0"
        borderRadius="medium"
        data-cy="intercom-privacy-modal"
        maxWidth="378px"
        position="absolute"
        left={expanded ? '300px' : '80px'}
        bottom="40px"
        overflow="hidden"
        background="gray800"
        overlayColor="transparent"
        style={{ filter: 'drop-shadow(0 8px 24px rgba(0, 0, 0, 0.2))' }}
      >
        <IntercomPrivacyModalContent
          handlePrivacyModalOpen={openPrivacyModal}
          handleIntercomToggle={handleIntercomToggle}
        />
      </Modal>
    </>
  );
};

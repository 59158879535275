import { DocumentCurrency } from 'generated-types/graphql.types';
import {
  CardIssuerPaymentSettlement,
  PaymentSettlementStatus,
} from 'generated-types/resolvers-types';
import { faker } from 'utils/faker';

type CreateCardSettlementsReadyForExport = {
  cardSettlementsReadyForExport?: Partial<CardIssuerPaymentSettlement>;
};
export const createCardIssuerPaymentSettlement = ({
  cardSettlementsReadyForExport,
}: CreateCardSettlementsReadyForExport = {}): CardIssuerPaymentSettlement => {
  return {
    __typename: 'CardIssuerPaymentSettlement',
    id: faker.uuid(),
    status: PaymentSettlementStatus.Paid,
    amount: {
      __typename: 'PaymentSettlementAmount',
      value: faker.number(),
      precision: 2,
      currency: DocumentCurrency.Eur,
    },
    actualPaymentDate: faker.date().toISOString(),
    paymentCreatedAt: faker.date().toISOString(),
    plannedPaymentDate: faker.date().toISOString(),
    publicPaymentId: faker.uuid(),
    ...cardSettlementsReadyForExport,
    statement: cardSettlementsReadyForExport?.statement ?? null,
  };
};

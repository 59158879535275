import { Box } from '@candisio/design-system';

export const CurrentDocumentIndicator = ({
  isCurrentDocument,
}: {
  isCurrentDocument: boolean;
}) => {
  return (
    <Box
      top="0"
      left="0"
      position="absolute"
      borderRadius="small"
      borderTopRightRadius="none"
      borderBottomRightRadius="none"
      background="blue200"
      transition="width .25s ease-in"
      width={isCurrentDocument ? 'space4' : '0'}
      height="100%"
    />
  );
};

import { Grid, Icon, Tooltip, useTooltip } from '@candisio/design-system';
import { motion } from 'motion/react';
import { useTranslation } from 'react-i18next';
import { transition, variants } from '../const';

const MotionGrid = motion.create(Grid);

interface ClearButtonProps {
  onClear: () => void;
  clearTooltip?: string;
}

export const AdvancedSearchFieldClearButton = ({
  onClear,
  clearTooltip,
}: ClearButtonProps) => {
  const [t] = useTranslation();

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();

  return (
    <>
      {/** @ts-expect-error framer-motion upgrade type mismatch */}
      <MotionGrid
        position="absolute"
        right="0"
        as="button"
        type="button"
        borderRadius="full"
        aria-label={t('search.clear')}
        alignContent="center"
        justifySelf="end"
        background="transparent"
        border="none"
        opacity="0"
        padding="space10"
        animate="visible"
        whileHover="hover"
        transition={transition}
        variants={variants}
        {...triggerProps}
        onClick={onClear}
        ref={triggerRef}
      >
        <Icon icon="close" size="space14" />
      </MotionGrid>
      {clearTooltip && isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {clearTooltip}
        </Tooltip>
      )}
    </>
  );
};

import {
  AddFileUploadMutationFn,
  FileState,
  FileUploadContext,
  GetFileUploadUrlMutationFn,
  UpdateFileUploadMutationFn,
  UploadFileItem,
  UploadedFile,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';
import {
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_MB,
} from 'views/AppContainer/components/Header/components/DocumentUploadModal/fileUploadUtil';
import { isSupportedFile } from 'views/AppContainer/components/Header/utils';
import { uploadFileToS3 } from './uploadFileToS3';

type FileUploadDependencies = {
  getFileUploadUrl: GetFileUploadUrlMutationFn;
  onUploaded?: (fileId: string) => Promise<{ documentId?: string }>;
};

type FileUploadDependenciesWithImport = FileUploadDependencies & {
  addFile: AddFileUploadMutationFn;
  updateFile: UpdateFileUploadMutationFn;
};

type FileUploadResult = {
  fileData?: UploadedFile;
  errorMessage?: string;
};

const createFileMetadata = (file: File): UploadFileItem => ({
  id: uuidV4(),
  createdAt: new Date().toISOString(),
  fileName: file.name,
  state: FileState.Uploading,
  documentId: null,
});

export const useFileUpload = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.COMMON);

  /**
   * Handles the file upload process for pdf document viewer
   * This function uploads the file to S3, validates the file and returns the file data or an error message.
   *
   * @param file - The file to be uploaded.
   * @param fileUploadDeps - Dependencies required for the file upload.
   * @returns A promise that resolves to the result of the file upload.
   */
  const handleFileUpload = useCallback(
    async (
      file: File,
      fileUploadDeps: FileUploadDependencies
    ): Promise<FileUploadResult> => {
      // validate file size before uploading
      if (file.size > MAX_FILE_SIZE) {
        const errorMessage = t('uploads.errors.fileSize', {
          size: MAX_FILE_SIZE_MB,
        });

        return { errorMessage };
      }

      try {
        const data = await handleFileUploadToS3(
          file,
          fileUploadDeps.getFileUploadUrl,
          t
        );

        return { fileData: data.file };
      } catch (err) {
        const errorMessage =
          (err as Error).message ?? t('uploads.errors.baseError');

        return { errorMessage };
      }
    },
    [t]
  );

  /**
   * Handles the file upload process for pdf document viewer as well as the import view in the header navigation.
   * This function uploads the file to S3, validates the file, and returns the file data or an error message.
   *
   * @param file - The file to be uploaded.
   * @param fileUploadDeps - Dependencies required for the file upload.
   * @returns A promise that resolves to the result of the file upload.
   */
  const handleDocumentAndImportFileUpload = useCallback(
    async (
      file: File,
      fileUploadDeps: FileUploadDependenciesWithImport
    ): Promise<FileUploadResult> => {
      const fileMetadata = createFileMetadata(file);
      const { onUploaded, addFile, getFileUploadUrl, updateFile } =
        fileUploadDeps;

      await addFile({ variables: { file: fileMetadata } });

      const errorMessage = await validateFile(
        file,
        fileMetadata,
        updateFile,
        t
      );

      if (errorMessage) {
        return { errorMessage };
      }

      try {
        const data = await handleFileUploadToS3(file, getFileUploadUrl, t);

        const fileId = data.id;
        const { documentId } = (await onUploaded?.(fileId)) ?? {};

        await updateFile({
          variables: {
            file: {
              ...fileMetadata,
              state: FileState.Uploaded,
              documentId,
              errorDetails: null,
            },
          },
        });

        return { fileData: data.file };
      } catch (err) {
        const errorMessage =
          (err as Error).message ?? t('uploads.errors.baseError');

        await updateFile({
          variables: {
            file: {
              ...fileMetadata,
              state: FileState.Error,
              errorDetails: errorMessage,
            },
          },
        });

        return { errorMessage };
      }
    },
    [t]
  );

  return { handleFileUpload, handleDocumentAndImportFileUpload };
};

const validateFile = async (
  file: File,
  fileMetadata: ReturnType<typeof createFileMetadata>,
  updateFile: UpdateFileUploadMutationFn,
  t: TFunction
): Promise<string | null> => {
  const setErrorState = async (message: string) => {
    await updateFile({
      variables: {
        file: {
          ...fileMetadata,
          state: FileState.Error,
          errorDetails: message,
        },
      },
    });

    return message;
  };

  if (!isSupportedFile(file, false)) {
    return setErrorState(t('uploads.errors.wrongFileType'));
  }

  if (file.size > MAX_FILE_SIZE) {
    return setErrorState(
      t('uploads.errors.fileSize', { size: MAX_FILE_SIZE_MB })
    );
  }

  return null;
};

const handleFileUploadToS3 = async (
  file: File,
  getFileUploadUrl: GetFileUploadUrlMutationFn,
  t: TFunction
) => {
  const { data } = await getFileUploadUrl({
    variables: {
      params: {
        contentType: file.type,
        fileName: file.name,
        size: file.size,
        context: FileUploadContext.Documents,
      },
    },
  });

  if (!data?.getFileUploadUrl?.id) {
    throw new Error(t('uploads.errors.uploadFailed'));
  }

  await uploadFileToS3({
    file,
    url: data.getFileUploadUrl.url,
    options: data.getFileUploadUrl.postOptions,
  });

  return data.getFileUploadUrl;
};

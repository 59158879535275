import { ComponentProps, ReactNode, useEffect, useState } from 'react';
import { DocumentsTable } from './DocumentsTable';
import { SortingRule } from 'react-table';
import { DocumentsTableData } from '../types';

interface DocumentsTableAdapterProps
  extends Omit<
    ComponentProps<typeof DocumentsTable>,
    'sorting' | 'setSorting'
  > {
  sortBy: SortingRule<object>[];
  initialSorting?: NonNullable<
    ComponentProps<typeof DocumentsTable>['sorting']
  >;
  onSort: (sortBy: SortingRule<DocumentsTableData>[]) => void;
  children?: ReactNode;
}

export const DocumentsTableAdapter = ({
  sortBy,
  initialSorting,
  onSort,
  children,
  ...props
}: DocumentsTableAdapterProps) => {
  const [sorting, setSorting] = useState<
    NonNullable<ComponentProps<typeof DocumentsTable>['sorting']>
  >(
    initialSorting ??
      sortBy.flatMap(sort => {
        if (sort.desc === undefined) return [];

        return [
          {
            id: sort.id,
            desc: sort.desc,
          },
        ];
      })
  );

  useEffect(() => {
    onSort(sorting);
  }, [sorting, onSort]);

  return (
    <DocumentsTable {...props} sorting={sorting} setSorting={setSorting} />
  );
};

import { ErrorMessages } from 'utils/zodFormValidation';
import { reimbursementFormSchema } from './reimbursementFormSchema';

type ExcludeKeys =
  | 'note'
  | 'approvalType'
  | 'targetMembership'
  | 'isAdvancedAccountingEnabled'
  | 'suggestedCostCenter'
  | 'suggestedCostCenter.value'
  | 'suggestedCostCenter.inputValue'
  | 'suggestedCostObject'
  | 'suggestedCostObject.value'
  | 'suggestedCostObject.inputValue'
  | 'suggestedGeneralLedgerAccount'
  | 'suggestedGeneralLedgerAccount.value'
  | 'suggestedGeneralLedgerAccount.inputValue';

/** Gets reimbursement form validation error messages */
export const reimbursementFormErrorMessages: ErrorMessages<
  ReturnType<typeof reimbursementFormSchema>,
  ExcludeKeys
> = {
  title: {
    label: 'reimbursementView.rightSection.form.title.label',
  },
  iban: {
    label: 'reimbursementView.rightSection.form.iban.label',
  },
  workflow: {
    label: 'reimbursementView.rightSection.form.approvalFields.workflowLabel',
  },
  approvers: {
    label: 'reimbursementView.rightSection.form.approvalFields.approversLabel',
  },
  accountsPayableNumber: {
    label: 'reimbursementView.rightSection.form.accountsPayableNumber.label',
  },
  'approvers.0.approvers': {
    label: 'reimbursementView.rightSection.form.approvalFields.approversLabel',
  },
};

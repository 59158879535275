import { Flex, Image } from '@candisio/design-system';
import {
  ExportStatus,
  ExportType as GqlExportType,
  useNewExportSapCsvMutation,
} from 'generated-types/graphql.types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { ExportNotificationsContext } from 'providers/ExportNotificationsProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ExportContext } from 'views/Integrations/Export/Context';
import { defineExportView } from 'views/Integrations/Export/Manifest';
import { ExportInfo } from 'views/Integrations/Export/toolkit/components/ExportInfo';
import { InitExportAvailableMonths } from 'views/Integrations/Export/toolkit/components/InitExportAvailableMonths';
import { InitExportTypeLabel } from 'views/Integrations/Export/toolkit/containers/ExportEntitiesSummary/InitExportTypeLabel';
import { InitExportSummary } from 'views/Integrations/Export/toolkit/containers/ExportableEntitiesSummary/Init';
import { useExportDataGigaFactory } from 'views/Integrations/Export/toolkit/hooks/useExportDataGigaFactory';
import { useGetReadyToExportCountByTimeframe } from 'views/Integrations/Export/toolkit/hooks/useGetReadyToExportCountByTimeframe';
import { useSelectedTimeFrame } from 'views/Integrations/Export/toolkit/hooks/useSelectedTimeFrame';
import sapCsvIcon from 'views/Integrations/Export/toolkit/images/icon-sap-csv.svg';
import { getRefetchExportsQueries } from 'views/Integrations/Export/toolkit/queries';
import { getDatevExportableEntities } from 'views/Integrations/Export/toolkit/utils';
import { getTimeFrameInputFromTimeFrameOption } from 'views/Integrations/Export/toolkit/utils/timeframe';
import { Sidebar, View } from 'views/Integrations/Export/types';
import { SapCsvExportButton } from './SapCsvExportButton';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

export const SapCsv = () => {
  const state = useContext(ExportContext);
  const counterQueries = useCounterQueries();
  const { addExport } = useContext(ExportNotificationsContext);
  const orgId = useOrganizationId();
  const navigate = useNavigate();
  const { exportType, exportId, includeEntitiesWithoutDoc } = state;

  const {
    exportEntities: {
      ready: { all: allEntities },
    },
    exportAll,
  } = useExportDataGigaFactory(state);

  const { selectedTimeFrame, timeFrames } = useSelectedTimeFrame({
    entities: allEntities,
    exportAll,
    exportType,
    exportId,
  });

  const documentIds = selectedTimeFrame?.documentIds;

  const enableNewIndexInExportsViewFF = useCandisFeatureFlags(
    FEATURE_FLAGS.enableNewIndexInExportsView
  );

  const [requestSapCsvExport, { loading }] = useNewExportSapCsvMutation({
    refetchQueries: [
      ...getRefetchExportsQueries({
        documentIds,
        enableNewIndexInExportsViewFF,
      }),
      ...counterQueries,
    ],
    awaitRefetchQueries: true,
  });

  const onExport = async () => {
    const exportableEntities = getDatevExportableEntities(
      selectedTimeFrame,
      exportId ?? '',
      includeEntitiesWithoutDoc,
      false,
      []
    );

    const { data: sapCsvExportResp } = await requestSapCsvExport({
      variables: {
        documentIds: exportAll ? [] : exportableEntities.documentIds,
        timeFrame: getTimeFrameInputFromTimeFrameOption(selectedTimeFrame),
        originExportId: exportId,
        exportAll,
      },
    });

    if (sapCsvExportResp?.newExportSapCsv) {
      addExport({
        hash: sapCsvExportResp.newExportSapCsv.hash,
        organization: orgId ?? '',
        status: ExportStatus.Exporting,
        documents: allEntities.length,
        type: GqlExportType.SapCsv,
      });

      const viewDef = defineExportView(
        {
          ...state,
          sidebar: Sidebar.HISTORY,
          view: View.HISTORY,
          exportId: sapCsvExportResp.newExportSapCsv.id,
          shouldTriggerDownload: true,
        },
        { orgId }
      );

      navigate(viewDef.navigate);
    }
  };

  const readyToExportEntitiesCountByTimeframe =
    useGetReadyToExportCountByTimeframe({
      selectedTimeFrame,
      entities: allEntities,
    });

  return (
    <Flex direction="column" justifyContent="space-between">
      <Flex direction="column" gap="space24">
        <ExportInfo>
          <Image
            src={sapCsvIcon}
            alt={exportType ?? ''}
            height="84px"
            width="84px"
            alignSelf="center"
          />
          <InitExportTypeLabel />
        </ExportInfo>
        {timeFrames.length > 0 && (
          <InitExportAvailableMonths
            exportType={exportType}
            timeFrames={timeFrames}
          />
        )}
        <InitExportSummary
          readyToExportEntitiesCount={readyToExportEntitiesCountByTimeframe}
        />
      </Flex>
      <SapCsvExportButton onExport={onExport} loading={loading} />
    </Flex>
  );
};

import {
  ContractSubCategoriesSortInput,
  ContractSubCategoriesSortOrder,
  ContractSubCategoriesWithDocumentCountQuery,
  ContractSubCategory,
  useContractSubCategoriesWithDocumentCountQuery,
} from 'generated-types/graphql.types';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { staticContractSubCategories } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmContractTypeItems';
import { ContractSubcategoriesSortBy } from './utils';

export const mapSortToGql = (
  sortBy: ContractSubcategoriesSortBy
): ContractSubCategoriesSortInput => {
  if (sortBy === ContractSubcategoriesSortBy.mostUsed) {
    return { documentCount: ContractSubCategoriesSortOrder.Desc };
  }

  if (sortBy === ContractSubcategoriesSortBy.newestFirst) {
    return { createdAt: ContractSubCategoriesSortOrder.Desc };
  }

  return { name: ContractSubCategoriesSortOrder.Asc };
};

type UseContractCustomSubCategoriesOptions = {
  search?: string;
  sortBy: ContractSubcategoriesSortBy;
};

export type ContractSubCategoryType = Pick<
  ContractSubCategory,
  'name' | 'description' | 'id' | 'documentCount' | 'isActive'
> & { isStatic?: boolean };

export type ContractCustomSubCategories = ContractSubCategoryType[];

export const useContractCustomSubCategoryData = ({
  search,
  sortBy = ContractSubcategoriesSortBy.alphabetically,
}: UseContractCustomSubCategoriesOptions) => {
  const [searchParams] = useSearchParams();
  const isArchived = searchParams.get('archived') === 'true';

  const [searchValue, setSearchValue] = useState(search);
  const handleDebounceSearch = useMemo(() => {
    return debounce(setSearchValue, DEFAULT_DEBOUNCE_TIME, {
      trailing: true,
    });
  }, []);

  const staticCategories = useStaticContractSubCategories();
  const [activeWithStatic, setActiveWithStatic] =
    useState<ContractCustomSubCategories>(staticCategories);

  const appendStaticCategories = (
    data: ContractSubCategoriesWithDocumentCountQuery
  ) => {
    const customSubcategories = data.contractSubCategories;
    const staticSubcategories = staticCategories.filter(
      ({ name }) =>
        typeof searchValue === 'string' &&
        name.toLowerCase().includes(searchValue.toLowerCase())
    );

    setActiveWithStatic([...customSubcategories, ...staticSubcategories]);
  };

  const { data: active, loading: loadingActive } =
    useContractSubCategoriesWithDocumentCountQuery({
      variables: {
        input: {
          isActive: true,
          includeDocumentCount: true,
          search: searchValue,
          sort: mapSortToGql(sortBy),
        },
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: appendStaticCategories,
    });

  const { data: archived, loading: loadingArchived } =
    useContractSubCategoriesWithDocumentCountQuery({
      variables: {
        input: {
          isActive: false,
          includeDocumentCount: true,
          search: searchValue,
          sort: mapSortToGql(sortBy),
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const activeCategories = active?.contractSubCategories;
  const archiveCategories = archived?.contractSubCategories;

  const categoriesToDisplay: ContractSubCategoryType[] = useMemo(() => {
    if (isArchived) return archiveCategories ?? [];

    return activeWithStatic ?? [];
  }, [activeWithStatic, archiveCategories, isArchived]);

  const countLoading = loadingActive || loadingArchived;
  const activeCount = (activeCategories?.length ?? 0) + staticCategories.length;
  const archivedCount = archiveCategories?.length ?? 0;

  return {
    activeCount,
    archivedCount,
    countLoading,
    handleDebounceSearch,
    contractSubCategories: categoriesToDisplay,
  };
};

const useStaticContractSubCategories = () => {
  const [t] = useTranslation();

  const mappedStaticCategories = useMemo(
    () =>
      staticContractSubCategories
        .map(({ id, translationKey }) => ({
          id,
          name: t(translationKey),
          isActive: true,
          isStatic: true,
          documentCount: undefined,
        }))
        .toSorted((a, b) => a.name.localeCompare(b.name)),
    [t]
  );

  return mappedStaticCategories;
};

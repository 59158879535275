import { ScrollBox } from '@candisio/design-system/next';
import { ReactNode, useEffect, useRef } from 'react';
import { usePreviousDistinct } from 'react-use';
import { usePendingFileUploads } from '../hooks/usePendingFileUploads';
import styles from './UploadHistory.module.css';

export const UploadHistoryScollbox = ({
  children,
}: { children: ReactNode }) => {
  const pendingUploads = usePendingFileUploads();
  const ref = useRef<HTMLDivElement>(null);
  const previousDataLength = usePreviousDistinct(pendingUploads?.length) ?? 0;

  useEffect(() => {
    if (
      !!pendingUploads?.length &&
      ref.current &&
      previousDataLength < pendingUploads.length
    ) {
      ref.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [pendingUploads?.length, previousDataLength]);

  return (
    <ScrollBox className={styles.scrollbox} ref={ref}>
      {children}
    </ScrollBox>
  );
};

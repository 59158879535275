import { mergeRefs, useId } from '@candisio/design-system';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useScrollContainer } from 'react-indiana-drag-scroll';
import { getPageId } from './utils';

const DEFAULT_PDF_RESIZE_WAIT_TIME_IN_MS = 300;
const SCROLLBAR_WIDTH = 10;
const ZOOM_STEP = 0.1;
const INITIAL_SCALE = 1;
const MIN_SCALE = 0.1;
const MAX_SCALE = 4;
const ROTATION_OPTIONS = [0, 270, 180, 90] as const;

export interface UseReactPdfControlsProps {
  smoothScroll?: boolean;
  initialScale?: number;
}

export const SMOOTH = { behavior: 'smooth' } as const;

export type UseReactPdfControlsResult = ReturnType<typeof useReactPdfControls>;
export const useReactPdfControls = (
  props: UseReactPdfControlsProps | undefined
) => {
  const smoothScroll = props?.smoothScroll ?? false;
  const initialScale = props?.initialScale ?? INITIAL_SCALE;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [scale, setScale] = useState(initialScale);
  const [rotationIndex, setRotationIndex] = useState<number>(0);

  const rotation = ROTATION_OPTIONS[rotationIndex];
  const [fitType, setFitType] = useState<'width' | 'height'>('width');
  const pdfViewerInstanceId = useId();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollContainer = useScrollContainer({
    mouseScroll: {
      ignoreElements: 'span, a',
      inertia: false,
      overscroll: false,
      rubberBand: false,
    },
  });

  const scrollOptions: ScrollIntoViewOptions | undefined = useMemo(() => {
    return smoothScroll ? SMOOTH : undefined;
  }, [smoothScroll]);

  const onZoomIn = useCallback(() => {
    setScale(prevScale => {
      if (prevScale >= MAX_SCALE) return prevScale;
      const newScale = prevScale + ZOOM_STEP;

      return newScale;
    });
  }, []);

  const onZoomOut = useCallback(() => {
    setScale(prevScale => {
      if (prevScale <= MIN_SCALE) return prevScale;
      const newScale = prevScale - ZOOM_STEP;

      return newScale;
    });
  }, []);

  const onFitPageWidth = useCallback(() => {
    setScale(initialScale);
    setFitType('width');
  }, [initialScale]);

  const onFitPageHeight = useCallback(() => {
    setScale(initialScale);
    setFitType('height');
  }, [initialScale]);

  const onRotateLeft = useCallback(() => {
    const nextRotationIndex = (rotationIndex + 1) % ROTATION_OPTIONS.length;
    setRotationIndex(nextRotationIndex);
  }, [rotationIndex]);

  const goToPage = useCallback(
    (pageNumber: number) => {
      const pageId = getPageId(pdfViewerInstanceId, pageNumber);
      const element = document.getElementById(pageId);

      element?.scrollIntoView(scrollOptions);
    },
    [pdfViewerInstanceId, scrollOptions]
  );

  const onGoToPreviousPage = useCallback(() => {
    goToPage(currentPage - 1);
  }, [currentPage, goToPage]);

  const onGoToNextPage = useCallback(() => {
    goToPage(currentPage + 1);
  }, [currentPage, goToPage]);

  const handleResize = useCallback(
    debounce(() => {
      if (!wrapperRef.current) return;

      const newWidth = wrapperRef.current.offsetWidth - SCROLLBAR_WIDTH;
      const newHeight = wrapperRef.current.offsetHeight;

      setWidth(() => newWidth);
      setHeight(() => newHeight);
    }, DEFAULT_PDF_RESIZE_WAIT_TIME_IN_MS),
    []
  );

  useEffect(() => {
    if (!wrapperRef.current) return;

    handleResize();

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(wrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [handleResize]);

  const roundedScale = Math.round((scale + Number.EPSILON) * 100) / 100;
  const maxZoomInReached = roundedScale >= MAX_SCALE;
  const maxZoomOutReached = roundedScale <= MIN_SCALE;

  const memoizedRef = useMemo(() => {
    return mergeRefs([wrapperRef, scrollContainer.ref]);
  }, [scrollContainer.ref]);

  return {
    currentPage,
    setCurrentPage,
    scale,
    width,
    height,
    rotation,
    fitType,
    onZoomIn,
    maxZoomInReached,
    maxZoomOutReached,
    onZoomOut,
    onRotateLeft,
    onGoToPreviousPage,
    onGoToNextPage,
    goToPage,
    onFitPageWidth,
    onFitPageHeight,
    wrapperRef: memoizedRef,
    pdfViewerInstanceId,
  };
};

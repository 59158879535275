import {
  DocumentDateFilterInput,
  DocumentFilterInput,
} from 'generated-types/graphql.types';
import { Filters } from 'react-table';
import { mapToDocumentDateFilterInput } from 'views/utils/mapToDocumentDateFilterInput';
import { DocumentsTableData } from '../types';

/**
 * @todo Filters<DocumentsTableData> are not tightly coupled with DocumentsTableData, hence we
 * have to do this 'if' checks with magic strings. It would be nice to refactor it so that we
 * are explicitly stating which column are we filtering
 */

export const mapToFilterInput = (
  filters: Filters<DocumentsTableData>
): DocumentFilterInput & DocumentDateFilterInput => {
  const dateFilters = mapToDocumentDateFilterInput(filters);
  const documentFilterInput = filters.reduce<
    DocumentFilterInput & DocumentDateFilterInput
  >((documentFilterInput, { id, value }) => {
    if (id === 'status') documentFilterInput.status = value;
    if (id === 'contact') documentFilterInput.contactIds = value;
    if (id === 'tags') documentFilterInput.tags = value;

    return documentFilterInput;
  }, dateFilters);

  return documentFilterInput;
};

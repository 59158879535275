import { SwimlaneTileShowAll } from 'components/Swimlane/content/SwimlaneTileShowAll';
import { TransactionStatus } from 'generated-types/graphql.types';
import { usePath } from 'hooks/usePath';

export const ShowAllDeclinedTransactions = () => {
  const showAllLink = usePath({
    pathname: '/archive/transactions',
    search: {
      status: TransactionStatus.Declined,
    },
  });

  return <SwimlaneTileShowAll to={showAllLink} />;
};

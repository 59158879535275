import {
  CellProps,
  Flex,
  TruncatedText,
  Text,
  AvatarStack,
} from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ReimbursementUser } from 'views/Reimbursement/toolkit/types';

export const MembersCell = <TableDataType extends { id: string }>({
  value,
}: CellProps<TableDataType, ReimbursementUser | ReimbursementUser[]>) => {
  const [t] = useTranslation();
  if (!value || (Array.isArray(value) && isEmpty(value))) {
    return <Text>{t('table:emptyCellPlaceholder')}</Text>;
  }

  if (Array.isArray(value)) {
    return (
      <AvatarStack size="small">
        {value.map((user, index) => (
          <AvatarWithStatusContainer
            img={user.avatarUrl ?? undefined}
            key={index}
            size="small"
            name={`${user.firstName} ${user.lastName}`}
            userId={user.membershipId}
          />
        ))}
      </AvatarStack>
    );
  }

  const { avatarUrl, firstName, lastName, membershipId } = value;

  if (!membershipId || (!firstName && !lastName)) {
    return <Text>{t('table:emptyCellPlaceholder')}</Text>;
  }

  const fullName = `${firstName ?? ''} ${lastName ?? ''}`;

  return (
    <Flex alignItems="center" gap="space8">
      <AvatarWithStatusContainer
        className="flex-none"
        name={fullName}
        img={avatarUrl}
        userId={membershipId}
        size="small"
        hasTooltip={false}
      />

      <TruncatedText wordBreak="break-word" color="gray800">
        {fullName}
      </TruncatedText>
    </Flex>
  );
};

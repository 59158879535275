import { Card, Flex, Grid, Image, Paragraph } from '@candisio/design-system';
import { CreditCardDetailsProps } from 'containers/credit-cards/CreditCard/types';
import {
  overlayVariant,
  statuseWithOverlay,
} from 'containers/credit-cards/utils';
import { CardType } from 'generated-types/graphql.types';
import { CardStatus } from 'generated-types/resolvers-types';
import { motion } from 'motion/react';
import { Overlay } from '../Overlay';
import { cardVariantPliant, variants } from '../utils';
import { Details } from './sections/Details';
import { Header } from './sections/Header';

type CreditCardLayoutProps = {
  initialState: CardStatus;
  cardType: CardType;
  cardDetails: CreditCardDetailsProps;
};

export const PliantLayout = ({
  initialState,
  cardType,
  cardDetails,
}: CreditCardLayoutProps) => {
  const hasOverlay = statuseWithOverlay.includes(initialState);

  const overlay = hasOverlay ? (
    <Overlay overlayType={overlayVariant[initialState]} />
  ) : null;

  const visaLogo = cardVariantPliant[cardType].visaLogo;

  const color = cardVariantPliant[cardType].color;

  return (
    <Card
      position="relative"
      background={cardVariantPliant[cardType].background}
      borderRadius="medium"
      width="180px"
      border={cardVariantPliant[cardType].border}
      height="280px"
      boxShadow="noShadow"
    >
      <motion.div
        style={{ height: '100%' }}
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        {overlay}
        <Grid
          templateRows="1fr auto 1fr"
          height="100%"
          placeContent="inherit"
          justifyContent="space-between"
        >
          <Header cardType={cardType} />
          <Details cardType={cardType} cardDetails={cardDetails} />
          <Flex
            direction="column"
            alignSelf="end"
            justifySelf="end"
            alignItems="end"
          >
            <Image
              alt="visa logo"
              src={visaLogo}
              justifySelf="end"
              alignSelf="end"
              width="66px"
              height="24px"
            />
            <Paragraph fontSize="7px" fontWeight="semibold" color={color}>
              PLATINUM BUSINESS
            </Paragraph>
          </Flex>
        </Grid>
      </motion.div>
    </Card>
  );
};

import { Flex, Grid, Paragraph } from '@candisio/design-system';
import {
  CardStatus,
  CardType,
  GetCardIssuerCardsForCardholderQuery,
  User,
} from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { Routes } from 'models';
import { motion } from 'motion/react';
import { useOrganizationId } from 'providers/OrganizationProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { useCreditCards } from '../../../providers/EntityLoader/EntityLoader';
import { LOCALE_NAME_SPACE } from '../../../providers/LocaleProvider';
import { useShowSensitiveData } from '../CreditCard/useShowSensitiveData';
import { variants } from '../CreditCard/utils';
import { AvailableBalanceContainer } from './components/AvailableBalance/AvailableBalance';
import { CardColumn } from './components/CardColumn';
import { Header } from './components/Header';
import { Cycler } from './components/Header/Cycler';
import { LimitsContainer } from './components/Limits/Limits';
import { Loading } from './components/Loading';
import { ActivateContainer } from './components/States/Activate/Activate';
import { DefaultContainer } from './components/States/Default/Default';
import { ExpiredContainer } from './components/States/Expired/Expired';
import { LockedContainer } from './components/States/Locked/Locked';
import { ProcessingContainer } from './components/States/Processing/ProcessingContainer';
import { RequestedContainer } from './components/States/Requested/Requested';
import { useSubscribeToCreditCard } from './hooks/useSubscribeToCreditCard';
import { Wrapper } from './styles';
import { CarouselStates } from './types';
import {
  extractCreditCardState,
  useCreditCardCarouselNavigation,
  usePrefetchQueries,
} from './utils';
import { ISSUE_NEW_CARD_HASH } from '../constants';

type StateComponentsDefinition = {
  [key: string]: (cardId: string) => ReactNode;
};

type CreditCardCyclerProps = {
  cards: GetCardIssuerCardsForCardholderQuery['getCardIssuerCardsForCardholder']['edges'];
  states: StateComponentsDefinition;
  token?: null | string;
  uniqueToken?: null | string;
  sensitiveData: {
    isFetchingSensitiveData: boolean;
    onShowCardNumbers: (cardId: string) => void;
    resetTokens: () => void;
    sensitiveDataIsShown: boolean;
  };
  user: Pick<User, 'name'>;
};

export const CreditCardCarousel = ({
  cards,
  sensitiveData,
  states,
  token,
  uniqueToken,
}: CreditCardCyclerProps) => {
  const [tCreditCards] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const history = useHistory();
  const navigate = useNavigate();
  const organizationId = useOrganizationId();

  const cardId =
    (qs.parse(location.search)['cardId'] as string) || cards?.[0].node.id;

  const {
    nextCardLink,
    prevCardLink,
    currentCard,
    nextCardId,
    prevCardId,
    currentCardIndex,
    isLoadingNavigationData,
  } = useCreditCardCarouselNavigation({
    organizationSlug: organizationId ?? '',
    cardId,
    cards,
  });

  usePrefetchQueries(prevCardId, nextCardId);

  const { resetTokens } = sensitiveData;

  if (!cards?.length || isNil(currentCard)) {
    navigate(`/${organizationId}${Routes.DASHBOARD}`);

    return null;
  }

  const cardCount = cards.length;

  const state = extractCreditCardState(currentCard);

  const Content = states[state](currentCard.id);

  const cyclerProps = {
    onNext: () => {
      nextCardLink && history.push(nextCardLink);
      resetTokens();
    },
    onPrev: () => {
      prevCardLink && history.push(prevCardLink);
      resetTokens();
    },
    position: {
      total: cardCount,
      current: currentCardIndex + 1,
    },
    loading: isLoadingNavigationData,
  };

  const openRequestCardDrawer = () => {
    history.push({
      hash: `#${ISSUE_NEW_CARD_HASH}`,
      search: history.location.search,
    });
  };

  const shouldHideWallet = currentCard.status === CardStatus.Processing;

  return (
    <Flex direction="column" gap="space16">
      <Grid gap="space16">
        <Header
          newCard={{
            onClick: () => openRequestCardDrawer(),
          }}
        />

        <Wrapper>
          <Flex key={`${cardCount}_${currentCard.status}`}>
            <CardColumn
              cardId={currentCard.id}
              sensitiveData={sensitiveData}
              token={token}
              uniqueToken={uniqueToken}
              cardState={state}
              totalCards={cardCount}
            />
            <Flex
              flexGrow={1}
              justifyContent="space-between"
              height="261px"
              width="max-content"
            >
              <Flex direction="column" gap="space18">
                <Cycler {...cyclerProps} />
                <motion.div
                  variants={variants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  {shouldHideWallet ? (
                    <Grid height="100%">{Content}</Grid>
                  ) : (
                    <Flex direction="column" gap="space8">
                      {currentCard.type !== CardType.SingleUse && (
                        <AvailableBalanceContainer cardId={currentCard.id} />
                      )}
                      <LimitsContainer cardId={currentCard.id} />
                    </Flex>
                  )}
                </motion.div>
              </Flex>
              {!shouldHideWallet && <Grid height="100%">{Content}</Grid>}
            </Flex>
          </Flex>
        </Wrapper>
        <Flex paddingLeft="195px">
          <Paragraph color="gray600" fontSize="9px" maxWidth="80ch">
            {tCreditCards('dashboard.drawer.form.footerText')}
          </Paragraph>
        </Flex>
      </Grid>
    </Flex>
  );
};

export const CreditCardCarouselContainer = () => {
  const user = useCurrentUser();
  const { currentCardHolderCards: cards, loading: listCardsLoading } =
    useCreditCards();

  const { token, uniqueToken, sensitiveData } = useShowSensitiveData();

  // This hook will subscribe to any change in the card making it possible to update the card in real time
  // and without the need of refetching

  useSubscribeToCreditCard();

  const failedToFetchError = !listCardsLoading && !cards;
  if (!user || failedToFetchError) {
    console.error('Failed to fetch credit cards');

    return <Loading />;
  }

  if (listCardsLoading) {
    return <Loading />;
  }

  const states: StateComponentsDefinition = {
    [CarouselStates.PROCESSING]: cardId => (
      <ProcessingContainer cardId={cardId} />
    ),
    [CarouselStates.ACTIVATE]: cardId => <ActivateContainer cardId={cardId} />,
    [CarouselStates.DEFAULT]: cardId => <DefaultContainer cardId={cardId} />,
    [CarouselStates.EXPIRED]: cardId => <ExpiredContainer cardId={cardId} />,
    [CarouselStates.LOCKED]: cardId => <LockedContainer cardId={cardId} />,
    [CarouselStates.LOCKEDPIN]: cardId => <LockedContainer cardId={cardId} />,
    [CarouselStates.REQUESTED]: cardId => (
      <RequestedContainer cardId={cardId} />
    ),
  };

  return (
    <CreditCardCarousel
      cards={cards}
      sensitiveData={sensitiveData}
      states={states}
      token={token}
      uniqueToken={uniqueToken}
      user={user}
    />
  );
};

import { CSSObject } from '@emotion/react';
import React from 'react';
import { useFocusVisible } from 'react-aria';
import { IconKey } from '../../Particles/Spritemap';
import { useTheme } from '../../Theme';
import { Box } from '../Box';
import { Icon } from '../Icon/next';
import { TextProps } from '../Typography';

const DEFAULT_ELEMENT = 'a';

type Position = 'left' | 'right';
type Variant = 'primary' | 'secondary';

export const LinkColors = [
  'gray',
  'blue',
  'purple',
  'yellow',
  'green',
  'red',
] as const;

type LinkColor = (typeof LinkColors)[number];

export type LinkProps<
  TElement extends React.ElementType = typeof DEFAULT_ELEMENT,
> = TextProps<TElement> & {
  as?: 'a' | 'button';
  variant?: Variant;
  disabled?: boolean;
  external?: boolean;
  icon?: IconKey;
  iconPosition?: Position;
  color?: LinkColor;
};

type LinkType = <TElement extends React.ElementType = typeof DEFAULT_ELEMENT>(
  props: LinkProps<TElement>
) => React.ReactElement | null;

/**
 * Provides declarative, accessible navigation around an application.
 * [Storybook]{@link https://candisio.github.io/design-system/?path=/docs/atoms-navigation-link}
 *
 * @param {string} href Reference to URL
 * @param {IconKey} [icon] Icon to display
 * @param {Position} [iconPosition=left] Position of icon
 * @param {Size} [size="medium"] Size of font and icon
 * @param {boolean} [disabled=false] Sets the Link in a disabled state
 * @param {Variant} [variant="primary"] Color variant of the link
 * @param {boolean} [external] target attribute of the link
 *
 * ![Dancing Link](https://media2.giphy.com/media/144Q1gg0FkTEVG/giphy.gif)
 */
export const Link = React.forwardRef(
  <TElement extends React.ElementType>(
    {
      children,
      variant = 'primary',
      disabled = false,
      href,
      onClick,
      external,
      icon = external ? 'newTab' : undefined,
      iconPosition = external ? 'right' : 'left',
      target = external ? '_blank' : undefined,
      rel = external ? 'noopener noreferrer' : undefined,
      color = variant === 'primary' ? 'blue' : 'gray',
      ...restProps
    }: LinkProps<TElement>,
    ref: typeof restProps.ref
  ) => {
    const { link, space } = useTheme();
    const { isFocusVisible } = useFocusVisible();

    const baseStyle: CSSObject = {
      display: 'inline',
      textDecoration: 'none',
      cursor: 'pointer',
    };

    // CSS reset for button elements
    const buttonStyle: CSSObject = {
      appearance: 'none',
      background: 'transparent',
      border: 'none',
      padding: 0,
      font: 'inherit',
    };

    const focusStyle: CSSObject = {
      '&:focus': {
        outline: 'none',
        textDecoration: 'underline',
      },
    };

    const iconElement = icon ? (
      <Icon
        className="align-middle w-4 h-4 bottom-[0.09em]"
        style={{
          ...(iconPosition === 'left'
            ? { marginRight: space.space4 }
            : { marginLeft: space.space4 }),
        }}
        icon={icon}
      />
    ) : null;

    const isButton = restProps.as === 'button';

    return (
      /** @ts-ignore `as` type mismatch after react upgrade */
      <Box
        as={DEFAULT_ELEMENT}
        {...restProps}
        css={[
          baseStyle,
          link[variant][color as LinkColor],
          isFocusVisible && focusStyle,
          disabled && link[variant].disabled,
          isButton && buttonStyle,
        ]}
        aria-disabled={disabled}
        href={disabled ? undefined : href}
        onClick={disabled ? undefined : onClick}
        tabIndex={0}
        type={isButton ? 'button' : undefined}
        target={isButton ? undefined : target}
        rel={isButton ? undefined : rel}
        ref={ref}
      >
        {iconPosition === 'left' && iconElement}
        {children}
        {iconPosition === 'right' && iconElement}
      </Box>
    );
  }
) as LinkType;

import {
  Box,
  CustomEmptyStateProps,
  Flex,
  Grid,
  Paragraph,
  SelectionOptions,
  useTheme,
} from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { SearchField } from 'components/SearchField/SearchField';
import { AssociateCard } from 'components/Transactions/Table/AssociateCard/AssociateCard';
import { DocumentCurrency } from 'generated-types/graphql.types';
import { Maybe } from 'generated-types/resolvers-types';
import { useLocalSortAndFilters } from 'hooks/table/useLocalSortAndFilters';
import { compiledRoutes } from 'models';
import { AnimatePresence } from 'motion/react';
import {
  SAP_SYNC,
  SyncFromSap,
} from 'orgConfig/sap/containers/SyncFromSap/SyncFromSap';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';
import { PurchaseOrdersEmptyState } from 'views/Archive/PurchaseOrders/PurchaseOrdersEmptyState';
import {
  PURCHASE_ORDER_FILTER,
  usePurchaseOrderListFilters,
} from 'views/Archive/PurchaseOrders/usePurchaseOrderListFilters';
import { purchaseOrderTableColumns } from 'views/Archive/PurchaseOrders/utils';
import { useBookingsFormContext } from 'views/DocumentDetails/BookingsFormContext';
import { useSapPurchaseOrders } from 'views/DocumentDetails/components/PurchaseOrderSection/ThreeWayMatch/hooks/useSapPurchaseOrders';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import { PurchaseOrdersTable } from '../Table/PurchaseOrdersTable';
import { ColumnWidths, PurchaseOrdersTableData } from '../Table/types';
import { useLinkPurchaseOrderToDocumentDeprecated } from '../hooks/useLinkPurchaseOrderToDocument';
import { getFiltersWithContact } from './utils';

export interface PurchaseOrderListProps {
  documentId: string;
  documentAmount?: number | null;
  accountsPayableNumber?: Maybe<string>;
  closeModal: () => void;
  contactName?: string;
}

const isRowSelectable = (
  row: PurchaseOrdersTableData,
  linkedPurchaseOrderCurrency: DocumentCurrency,
  alreadyLinkedPurchaseOrderNumbers: string[]
) => {
  return !(
    row.goodsReceiptCount?.open === 0 ||
    (linkedPurchaseOrderCurrency &&
      row.grossAmount?.currency !== linkedPurchaseOrderCurrency) ||
    alreadyLinkedPurchaseOrderNumbers?.includes(row?.orderNumber as string)
  );
};

const getDisabledReasonKey = (
  row: PurchaseOrdersTableData,
  linkedPurchaseOrderCurrency: DocumentCurrency,
  alreadyLinkedPurchaseOrderNumbers: string[]
) => {
  if (!row.goodsReceiptCount?.total) {
    return 'cantSelectPurchaseOrderWithNoGoodsReceipt';
  }
  if (
    linkedPurchaseOrderCurrency &&
    row.grossAmount?.currency !== linkedPurchaseOrderCurrency
  ) {
    return 'cantSelectPurchaseOrderWithDifferentCurrency';
  }
  if (alreadyLinkedPurchaseOrderNumbers?.includes(row?.orderNumber as string)) {
    return 'cantSelectPurchaseOrderAlreadyLinked';
  }
  if (!row.goodsReceiptCount?.open) {
    return 'cantSelectPurchaseOrderWithNoOpenGoodsReceipt';
  }
  return '';
};
/**
 * @deprecated
 */
export const PurchaseOrderListDeprecated = ({
  documentId = '',
  documentAmount,
  accountsPayableNumber,
  closeModal,
  contactName,
}: PurchaseOrderListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { space } = useTheme();
  const [searchParam, setSearchParam] = useState('');

  const { filters, sortBy, onSort, onFilter } =
    useLocalSortAndFilters<PurchaseOrdersTableData>();

  const defaultFilters = getFiltersWithContact({
    filters,
    accountsPayableNumber,
  });
  const orgId = useOrganizationId();
  const { purchaseOrders: linkedPurchaseOrders } =
    useSapPurchaseOrders(documentId);

  const linkedPurchaseOrderCurrency = linkedPurchaseOrders?.[0]?.amount
    .currency as DocumentCurrency;

  const alreadyLinkedPurchaseOrderNumbers = (linkedPurchaseOrders ?? [])
    .map(po => po?.orderNumber)
    .filter(Boolean) as string[];

  const { filterOptions } = usePurchaseOrderListFilters([
    PURCHASE_ORDER_FILTER.invoiceAssociationStatus_archive,
  ]);

  const { setShouldResetForm } = useBookingsFormContext();
  const { linkPurchaseOrder, isLinkingLoading } =
    useLinkPurchaseOrderToDocumentDeprecated({
      documentId,
      onCompleted: () => {
        setShouldResetForm?.(true);
      },
    });

  const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
    useState<PurchaseOrdersTableData | null>(null);

  const handleLinkPurchaseOrderToDocument = async () => {
    if (!selectedPurchaseOrder) {
      return;
    }

    await linkPurchaseOrder(
      selectedPurchaseOrder.id,
      selectedPurchaseOrder?.orderNumber ?? ''
    );

    closeModal();
  };

  const selectionOptions: SelectionOptions<PurchaseOrdersTableData> =
    useMemo(() => {
      return {
        selectCheckboxProps: row => {
          if (
            !isRowSelectable(
              row,
              linkedPurchaseOrderCurrency,
              alreadyLinkedPurchaseOrderNumbers
            )
          ) {
            return {
              isDisabled: true,
              disabledReason: t(
                getDisabledReasonKey(
                  row,
                  linkedPurchaseOrderCurrency,
                  alreadyLinkedPurchaseOrderNumbers
                )
              ),
            };
          }

          return { isVisible: true };
        },
        onSelectionRowChanged: purchaseOrders => {
          setSelectedPurchaseOrder(purchaseOrders[0]);
        },
        selectedRowsIds: selectedPurchaseOrder
          ? [selectedPurchaseOrder.id]
          : [],
      };
    }, [
      alreadyLinkedPurchaseOrderNumbers,
      linkedPurchaseOrderCurrency,
      selectedPurchaseOrder,
      t,
    ]);

  const arePurchaseOrdersSelected = Boolean(selectedPurchaseOrder);

  const handleResetSearch = () => {
    setSearchParam('');
  };

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) => (
    <PurchaseOrdersEmptyState
      isTableFiltered={false}
      arePurchaseOrdersSelected={arePurchaseOrdersSelected}
      isUsingSearchQuery={!!searchParam}
      resetFilters={resetFilters}
      resetSearch={handleResetSearch}
    />
  );

  const handleFilter = useMemo(() => {
    return (filterVal: Filters<PurchaseOrdersTableData>) => {
      onFilter(filterVal);
    };
  }, [onFilter]);

  const handleSort = useMemo(() => {
    return (sortVal: SortingRule<PurchaseOrdersTableData>[]) => {
      onSort(sortVal);
    };
  }, [onSort]);

  const columnWidths: ColumnWidths = {
    connectedInvoices: space.space256,
    lineItems: space.space256,
    goodsReceiptCount: space.space200,
  };

  const columns = useMemo(() => {
    return accountsPayableNumber
      ? purchaseOrderTableColumns.filter(col => col !== 'contact')
      : purchaseOrderTableColumns;
  }, [accountsPayableNumber]);

  const searchPlaceholder = contactName
    ? t('purchase-orders:search.placeholderWithContact', {
        contactName,
      })
    : t('purchase-orders:search.placeholder');

  return (
    <Box height="100%" overflow="hidden">
      <Flex
        direction="column"
        height="100%"
        overflow="hidden"
        paddingTop="space16"
        paddingBottom="space32"
        paddingX="space32"
      >
        <Grid
          templateColumns="1fr auto"
          borderTopRadius="basic"
          overflow="hidden"
          alignItems="center"
          borderBottom="1px solid gray200"
          background="white"
          padding="space12"
        >
          <Box width="24rem">
            <SearchField
              clearTooltip={t('purchase-orders:search.clearTooltip')}
              placeholder={searchPlaceholder}
              initialValue={searchParam}
              onChange={query => {
                setSearchParam(query);
              }}
              inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
            />
          </Box>
          <SyncFromSap
            type={SAP_SYNC.PurchaseOrdersWithFilters}
            accountsPayableNumber={accountsPayableNumber ?? undefined}
          />
        </Grid>
        <PurchaseOrdersTable
          documentAmount={documentAmount}
          columns={columns}
          onFilter={handleFilter}
          onSort={handleSort}
          customEmptyState={emptyState}
          filterOptions={filterOptions}
          columnWidths={columnWidths}
          defaultFilters={defaultFilters}
          defaultSortBy={sortBy}
          selectionOptions={selectionOptions}
          borderTopRadius="none"
          searchValue={searchParam}
          showFooterText
          isSingleSelect
          tableFooter={
            <Grid placeContent="center" padding="space24">
              <Paragraph maxWidth="80%" textAlign="center">
                <Trans
                  i18nKey="addPurchaseOrderModal.footerText"
                  ns={LOCALE_NAME_SPACE.PURCHASE_ORDERS}
                >
                  Can't find the purchase order? Then please check the selected
                  contact. You can also see the latest orders from
                  <RouterLink
                    to={`/${orgId}${compiledRoutes.allPurchaseOrdersImportHistoryRoute}`}
                  >
                    SAP Import History
                  </RouterLink>
                </Trans>
              </Paragraph>
            </Grid>
          }
        />
      </Flex>
      <AnimatePresence>
        {selectedPurchaseOrder && (
          <AssociateCard
            onAssociate={handleLinkPurchaseOrderToDocument}
            footerText={t('addPurchaseOrderModal.footer.footerWhenSelected')}
            ctaText={t('addPurchaseOrderModal.footer.buttonCTA')}
            flex="none"
            width="100%"
            position="absolute"
            bottom={0}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: '50%', opacity: 0 },
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            loading={isLinkingLoading}
          />
        )}
      </AnimatePresence>
    </Box>
  );
};

import { Card, Flex, Image, Link, Paragraph } from '@candisio/design-system';
import { Locale, Maybe } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../../../providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from '../../../../../../providers/LocaleProvider';
import kontaktDE from '../media/Kontakt-DE@2x.png';
import kontaktEN from '../media/Kontakt-EN@2x.png';
import kostenstellenDE from '../media/Kostenstellen-DE@2x.png';
import kostenstellenEN from '../media/Kostenstellen-EN@2x.png';
import sachkontenDE from '../media/Sachkonten-DE@2x.png';
import sachkontenEN from '../media/Sachkonten-EN@2x.png';
import infoImage from '../media/info.png';
import {
  ContactEmptySelection,
  ContactSelection,
} from './TypeSelection/Contact';
import {
  CostCenterEmptySelection,
  CostCenterSelection,
} from './TypeSelection/CostCenter';
import {
  GeneralLedgerEmptySelection,
  GeneralLedgerSelection,
} from './TypeSelection/GeneralLedgerAccount';
import { WIDGET_TYPE } from './TypeSelection/types';

export const widgetTypeFilterMap = {
  costCenters: WIDGET_TYPE.COST_CENTER,
  generalLedgerAccounts: WIDGET_TYPE.GENERAL_LEDGER_ACCOUNT,
  contacts: WIDGET_TYPE.CONTACT,
};

const images = {
  costCenter: {
    [Locale.De]: kostenstellenDE,
    [Locale.En]: kostenstellenEN,
  },
  contact: {
    [Locale.De]: kontaktDE,
    [Locale.En]: kontaktEN,
  },
  generalLedger: {
    [Locale.De]: sachkontenDE,
    [Locale.En]: sachkontenEN,
  },
};

const InformationSection = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);

  return (
    <Card
      height="7.25rem"
      width="100%"
      justifySelf="center"
      borderRadius="medium"
      background="bluebg"
      boxShadow="none"
      style={{ margin: 'auto' }}
    >
      <Flex gap="1rem">
        <Image alt="" src={infoImage} height="84px" width="84px" />
        <Flex direction="column" padding="space8">
          <Paragraph>{t('widgets.typeSelection.info.text')}</Paragraph>
          <Link href={t('widgets.typeSelection.info.url')} external>
            {t('widgets.typeSelection.info.urlText')}
          </Link>
        </Flex>
      </Flex>
    </Card>
  );
};

type WidgetTypeSelectionProps = {
  hasActiveBookingAccounts?: Maybe<boolean>;
  hasContacts?: Maybe<boolean>;
  hasCostCenters?: Maybe<boolean>;
  hasCostObjects?: Maybe<boolean>;
  setSelectedType: (type: WIDGET_TYPE) => void;
};

export const WidgetTypeSelection = ({
  hasActiveBookingAccounts,
  hasContacts,
  hasCostCenters,
  hasCostObjects,
  setSelectedType,
}: WidgetTypeSelectionProps) => {
  const currentUser = useCurrentUser();

  const imageMap = {
    costCenter: images.costCenter[currentUser?.locale || Locale.De],
    generalLedger: images.generalLedger[currentUser?.locale || Locale.De],
    contact: images.contact[currentUser?.locale || Locale.De],
  };

  const shared = { setSelectedType };

  return (
    <Flex direction="column" gap="space24">
      <Flex gap="space8" justifyContent="space-between">
        {hasCostCenters || hasCostObjects ? (
          <CostCenterSelection
            image={imageMap.costCenter}
            {...shared}
            hasCostCenters={hasCostCenters}
            hasCostObjects={hasCostObjects}
          />
        ) : (
          <CostCenterEmptySelection />
        )}
        {hasActiveBookingAccounts ? (
          <GeneralLedgerSelection image={imageMap.generalLedger} {...shared} />
        ) : (
          <GeneralLedgerEmptySelection />
        )}
        {hasContacts ? (
          <ContactSelection image={imageMap.contact} {...shared} />
        ) : (
          <ContactEmptySelection />
        )}
      </Flex>
      <InformationSection />
    </Flex>
  );
};

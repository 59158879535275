import {
  Button,
  Flex,
  Grid,
  Popover,
  Tag,
  Text,
  usePopover,
} from '@candisio/design-system';
import { ActionBar } from 'components/Sidebar/ActionBar';
import { Sidebar as SideBarElement } from 'components/Sidebar/Sidebar';
import { ExportStatus } from 'generated-types/graphql.types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormatters } from 'utils/date_formatter';
import { ExportContext } from 'views/Integrations/Export/Context';
import { ExportInfo } from 'views/Integrations/Export/toolkit/components/ExportInfo';
import { SapB1Image } from 'views/Integrations/Export/toolkit/components/common';
import { ExportDetails, Sidebar } from 'views/Integrations/Export/types';
import { ExportHistorySummary } from '../../containers/ExportableEntitiesSummary/History';

export interface SapB1ExportDetailsProps {
  exportDetails: ExportDetails;
}
export const SapB1ExportDetails = ({
  exportDetails,
}: SapB1ExportDetailsProps) => {
  const [t] = useTranslation();
  const state = useContext(ExportContext);
  const {
    close,
    open,
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
  } = usePopover({
    placement: 'top',
  });

  const handleReExport = () => {
    state.setSidebar(Sidebar.INIT_NEW_EXPORT);
    state.setExportType(exportDetails.type);
    state.setIsReExport(true);
  };

  return (
    <SideBarElement
      heading={t('export.exportForm.exportDatev.title', {
        date: DateFormatters.compact(exportDetails?.createdAt || new Date()),
      })}
    >
      <Grid templateRows="auto auto 1fr" height="100%">
        <ExportInfo>
          <SapB1Image />
          <Flex padding="space12" justifyContent="center" columnGap="space8">
            <Text fontSize="basic">
              {t('export.exportForm.selectExportType.types.sap_b1')}
            </Text>
            <Tag color="blue" variant="secondary">
              {t('exports:sapPromotion.betaTag')}
            </Tag>
          </Flex>
        </ExportInfo>
        {exportDetails.status !== ExportStatus.Exporting ? (
          <ExportHistorySummary />
        ) : (
          <div />
        )}
      </Grid>
      <ActionBar>
        <Button onClick={open} {...triggerProps} ref={triggerRef}>
          {t('export.exportForm.exportSapB1.exportAgain')}
        </Button>

        {isOpen && (
          <Popover
            {...popoverProps}
            ref={popoverRef}
            padding="space16"
            width="294px"
          >
            <Grid gap="space8">
              <Text>
                {t('export.exportForm.exportSapB1.confirm_re_export')}
              </Text>
              <Grid gap="space8" autoFlow="column" templateColumns="auto 1fr">
                <Button onClick={handleReExport} color="red">
                  {t('export.exportForm.exportSapB1.re_export_button')}
                </Button>
                <Button variant="secondary" color="blue" onClick={close}>
                  {t('no')}
                </Button>
              </Grid>
            </Grid>
          </Popover>
        )}
      </ActionBar>
    </SideBarElement>
  );
};

import { Flex, Grid, Link, Text } from '@candisio/design-system';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ProcessingFormFieldMetadata } from './ProcessingFormMetadataContext';

const CONFIDENCE_LEVELS = {
  SAFE: { threshold: 1, color: 'green500' },
  RELIABLE: { threshold: 0.9, color: 'green500' },
  UNRELIABLE: { threshold: 0, color: 'yellow500' },
} as const;

const getConfidenceLevel = (confidence: number) => {
  if (confidence >= CONFIDENCE_LEVELS.SAFE.threshold) return 'SAFE';
  if (confidence >= CONFIDENCE_LEVELS.RELIABLE.threshold) return 'RELIABLE';

  return 'UNRELIABLE';
};

/** Tooltip message describing the metadata associated with the field */
export const ProcessingFormFieldMetadataMessage = ({
  confidence,
  source,
}: ProcessingFormFieldMetadata) => {
  return (
    <ProcessingFormFieldMetadataMessageWithCertainty
      confidence={confidence}
      source={source}
    />
  );
};

/** Tooltip message describing the metadata associated with the field */
export const ProcessingFormFieldMetadataMessageWithCertainty = ({
  confidence,
  source,
}: ProcessingFormFieldMetadata) => {
  const [t] = useTranslation();
  const confidenceLevel = getConfidenceLevel(confidence);

  const extractionType = t(
    `document.requestApproval.fieldRecognition.${source}.source.type`
  );

  return (
    <Grid fontSize="small">
      <Text fontWeight="bold">
        {t(`document.requestApproval.fieldRecognition.${source}.title`)}
      </Text>
      <Flex gap="space2">
        <Trans
          i18nKey={`document.requestApproval.confidenceScore.info.${confidenceLevel.toLowerCase()}`}
          values={{ source: extractionType }}
        >
          <Text color={CONFIDENCE_LEVELS[confidenceLevel].color}>
            {confidenceLevel.toLowerCase()}ly recognized
          </Text>
          <Link
            external
            href={t(
              `document.requestApproval.fieldRecognition.${source}.source.infoUrl`
            )}
          >
            {extractionType}
          </Link>
        </Trans>
      </Flex>
    </Grid>
  );
};

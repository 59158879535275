import { Box, Grid, ScrollBox, TruncatedText } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useEffect } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { ProcessingFormOverlay } from 'views/Inbox/DocumentProcessing/components/AddContact/ProcessingFormOverlay';
import { FooterActionBox } from './components/RightSection/FooterActions/FooterActionBox';
import { InfoBox } from './components/RightSection/InfoBox/InfoBox';
import { ReimbursementFormContainer } from './components/RightSection/ReimbursementFormContainer';
import { ReimbursmentItemBookingsFormContainer } from './components/RightSection/ReimbursementSplitBookings/ReimbursmentItemBookingsFormContainer';
import { CommentFormOutput } from './components/RightSection/SendBackToEmploy/utils';
import { SectionLayout } from './components/SectionLayout';
import { useReimbursementSplitBookingHelpers } from './context/ReimbursementSplitBookingsContext';
import { useFastApproveReimbursementCase } from './hooks/useFastApproveReimbursementCase';
import { useRequestAmendment } from './hooks/useRequestAmendment';
import { useSubmitReimbursementCaseForReview } from './hooks/useSubmitReimbursementCaseForReview';
import { ReimbursementItemsFormOutput } from './toolkit/reimbursementItemsFormSchema';
import { useSuccessToastWithDeepLink } from './components/RightSection/FooterActions/ReimbursementSuccessMessageWithDeepLink';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useReimbursementFormsContext } from './context/ReimbursementFormsContext';
import { useWorkflowForReimbursementCaseById } from './hooks/useWorkflowForReimbursementCaseById';
import { useReimbursementExpenseItemsList } from './hooks/useReimbursementExpenseItemsList';
import { BasicSplitBookingsFormContainer } from './components/RightSection/ReimbursementSplitBookings/BasicSplitBookingsFormContainer';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { ReimbursementBookingsListSection } from './components/RightSection/ReimbursementSplitBookings/ReimbursementBookingsListSection';
import { useGetReimbursementById } from './hooks/useGetReimbursementById';
import { ReimbursementTeamMemberDrawer } from './ReimbursementTeamMemberDrawer';
import { useSubmitReimbursementForApproval } from './hooks/useSubmitReimbursementForApproval';

interface RightSectionContainerProps {
  isLoading: boolean;
  onCycleReimbursments: () => void;
  getExpenses: UseFormGetValues<ReimbursementItemsFormOutput>;
}

export const RightSectionContainer = ({
  isLoading,
  onCycleReimbursments,
  getExpenses,
}: RightSectionContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { id: currentUserId } = useCurrentUser() ?? {};
  const { error } = useToastMessage();

  const { reimbursementId } = useParams<{ reimbursementId: string }>();

  const {
    reimbursementItems,
    refetchReimbursementItemsList,
    reimbursementItemsCount,
  } = useReimbursementExpenseItemsList();

  const {
    reimbursement,
    refetchReimbursementCase,
    loading: isLoadingReimbursement,
  } = useGetReimbursementById({
    reimbursementId,
  });

  const { submitForApproval, isSubmitForApprovalPending } =
    useSubmitReimbursementForApproval();

  const { workflow, loading } =
    useWorkflowForReimbursementCaseById(reimbursementId);

  const successMessageWithDeepLink = useSuccessToastWithDeepLink(reimbursement);

  const { fastApprove, isFastApprovePending } =
    useFastApproveReimbursementCase();

  const { requestAmendment, isRequestAmendmentActionPending } =
    useRequestAmendment();

  const { approvalMode } = useReimbursementFormsContext();

  const {
    drawerView,
    resetDrawerAndForm,
    formMethods,
    triggerBookingsValidation,
  } = useReimbursementSplitBookingHelpers();

  const currentStepApproverIds = workflow?.currentStepApproverIds ?? [];

  const targetMembershipId = reimbursement?.targetMembership?.id;

  const isCurrentUserCaseApprover = Boolean(
    currentUserId && currentStepApproverIds.includes(currentUserId)
  );

  const handleFastApprove = useCallback(async () => {
    const responseFastApprove = await fastApprove(reimbursementId);

    if (responseFastApprove.status === 'error') {
      error(
        t(
          'reimbursementView.rightSection.reviewfooterAction.approveToast.error'
        )
      );

      return responseFastApprove;
    }

    successMessageWithDeepLink({
      key: 'reimbursementView.rightSection.reviewfooterAction.approveToast.success',
    });
    onCycleReimbursments();
  }, [
    error,
    fastApprove,
    onCycleReimbursments,
    reimbursementId,
    successMessageWithDeepLink,
    t,
  ]);

  const isReimbursementOwner =
    currentUserId === reimbursement?.createdByMembership?.id;

  const handleRequestAmendment = async ({ comment }: CommentFormOutput) => {
    const responseRequestAmendment = await requestAmendment(
      reimbursementId,
      comment
    );

    if (responseRequestAmendment.status === 'success') {
      successMessageWithDeepLink({
        key: 'reimbursementView.rightSection.reviewfooterAction.sendBackToast.success',
        showDeepLink: isReimbursementOwner,
      });
      onCycleReimbursments();
    } else {
      error(
        t(
          'reimbursementView.rightSection.reviewfooterAction.sendBackToast.error'
        )
      );
    }
  };

  const {
    submitReimbursmentCaseForReview,
    isSubmitReimbursmentCaseForReviewPending,
  } = useSubmitReimbursementCaseForReview();

  const hasSubmittedSplitBookings = formMethods.formState.isSubmitted;

  const shouldShowBasicBookingsForm =
    reimbursement?.status === ReimbursementCaseStatus.Draft;

  // When we switch between approval modes we need to manually trigger
  // form validation for split bookings form to updated errors
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (hasSubmittedSplitBookings) {
      triggerBookingsValidation();
    }
  }, [approvalMode]);

  return (
    <>
      <SectionLayout
        headerElement={
          <Box paddingLeft="space10">
            <TruncatedText as="h1" lineHeight="normal" fontWeight="regular">
              {t('reimbursementView.rightSection.title')}
            </TruncatedText>
          </Box>
        }
      >
        <Grid overflow="hidden" templateRows="1fr auto">
          <ScrollBox scrollDirection="y" padding="0 space12 space10">
            <Grid gap="space24">
              <ReimbursementFormContainer
                isLoading={isLoading || isLoadingReimbursement}
                reimbursement={reimbursement}
                workflow={workflow}
                reimbursementItemsCount={reimbursementItemsCount}
                splits={
                  <ReimbursementBookingsListSection
                    isLoading={isLoading}
                    caseStatus={reimbursement?.status}
                    isCurrentUserCaseApprover={isCurrentUserCaseApprover}
                  />
                }
              />
              <InfoBox reimbursement={reimbursement} />
            </Grid>
          </ScrollBox>
          <FooterActionBox
            reimbursement={reimbursement}
            onCycleReimbursments={onCycleReimbursments}
            onSubmitForReview={submitReimbursmentCaseForReview}
            onFastApprove={handleFastApprove}
            onRequestAmendment={handleRequestAmendment}
            getExpenses={getExpenses}
            onSubmitForApproval={submitForApproval}
            isSubmitForApprovalPending={isSubmitForApprovalPending}
            isSubmittingForReview={isSubmitReimbursmentCaseForReviewPending}
            isFastApprovePending={isFastApprovePending}
            isRequestAmendmentPending={isRequestAmendmentActionPending}
            reimbursmentWorkflow={workflow}
            refetchReimbursement={refetchReimbursementCase}
            refetchReimbursementItems={refetchReimbursementItemsList}
            reimbursementItems={reimbursementItems}
            isWorkflowLoading={loading}
          />
        </Grid>
      </SectionLayout>
      {drawerView === 'split-bookings' && (
        <ProcessingFormOverlay onClose={resetDrawerAndForm} isOpen>
          {shouldShowBasicBookingsForm ? (
            <BasicSplitBookingsFormContainer status={reimbursement?.status} />
          ) : (
            <ReimbursmentItemBookingsFormContainer
              status={reimbursement?.status}
              isCurrentUserCaseApprover={isCurrentUserCaseApprover}
            />
          )}
        </ProcessingFormOverlay>
      )}

      <ReimbursementTeamMemberDrawer targetMembershipId={targetMembershipId} />
    </>
  );
};

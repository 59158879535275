import { Maybe, Money, PurchaseOrder } from 'generated-types/graphql.types';

export type ThreeWayMatchBookingData = {
  bookingId: string;
  price: Money;
  quantity?: number;
  description: string;
};

export type ThreeWayMatchPurchaseOrderData = {
  bookingId: string;
  unitPrice?: Money | null;
  quantity: number;
  description: string;
  articleNumber: string;
};

export type ThreeWayMatchGoodsReceiptDetail = {
  receiptNumber: string;
  quantity: number;
}[];
export type ThreeWayMatchGoodsReceiptData = {
  bookingId: string;
  quantity: number;
  description: string;
  articleNumber: string;
  items?: ThreeWayMatchGoodsReceiptDetail;
  unitPrice?: Money | null;
};

export type ThreeWayMatchRowData = {
  id: string;
  articleNumber: string;
  description: string;
  purchaseOrderQuantity: number;
  goodsReceiptQuantity: number;
  invoiceQuantity: number;
  purchaseOrderAmount?: Money | null;
  invoiceAmount: Money;
  isAmountMatched: boolean;
  isQuantityMatched: boolean;
  goodsReceiptsDetails?: ThreeWayMatchGoodsReceiptDetail;
  goodsReceiptAmount?: Money | null;
};

export type PurchaseOrders = Maybe<PurchaseOrder>[];
export enum matchState {
  success = 'success',
  warning = 'warning',
  default = 'default',
}
export type MatchState = keyof typeof matchState;

export interface BookingAssocGoodsReceiptInfo {
  bookingId: string;
  articleId: string;
  goodsReceiptId: string;
  goodsReceiptLineNumber: number;
  linkedPurchaseOrderId?: string;
  quantity: number;
  unitPrice?: Money | null;
}
export interface BookingAssocPurchaseOrderInfo {
  bookingId: string;
  articleId: string;
  purchaseOrderId: string;
  purchaseOrderLineNumber: number;
  quantity: number;
  unitPrice: Money;
}
export interface GoodsReceiptInfoExtended extends BookingAssocGoodsReceiptInfo {
  receiptNumber: string;
  description: string;
}

export interface PurchaseOrderInfoExtended
  extends BookingAssocPurchaseOrderInfo {
  orderNumber: string;
  description: string;
}

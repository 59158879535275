import { SearchScope, SearchType } from 'generated-types/graphql.types';
import {
  Entitlements,
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

export const useDefaultSearchInput = () => {
  const currentEntitlement = useEntitlementsFlag();
  const { showEcm } = useEcm();

  const wipPowerSearchFF = useCandisFeatureFlags(FEATURE_FLAGS.wipPowerSearch);
  if (wipPowerSearchFF) {
    return {
      DEFAULT_SEARCH_TYPE:
        currentEntitlement === Entitlements.LEGACY
          ? SearchType.Contains
          : SearchType.PrefixMatch,
      DEFAULT_SEARCH_SCOPE: SearchScope.DataFields,
    };
  }

  if (currentEntitlement === Entitlements.BASE) {
    return {
      DEFAULT_SEARCH_TYPE: SearchType.PrefixMatch,
      DEFAULT_SEARCH_SCOPE: SearchScope.DataFields,
    };
  }

  if (currentEntitlement === Entitlements.LEGACY && showEcm === false) {
    return {
      DEFAULT_SEARCH_TYPE: SearchType.Contains,
      DEFAULT_SEARCH_SCOPE: SearchScope.DataFields,
    };
  }

  return {
    DEFAULT_SEARCH_TYPE: SearchType.PrefixMatch,
    DEFAULT_SEARCH_SCOPE: SearchScope.EntireDocument,
  };
};

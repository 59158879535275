// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { LoadingReimbursementDetails } from './components/LeftSection/LoadingReimbursementDetails';
import { ReimbursementDetails } from './components/LeftSection/ReimbursementDetails';
import { useExpenseFormsHelpers } from './components/MiddleSection/utils/useExpenseFormsHelpers';
import { useGetReimbursementById } from './hooks/useGetReimbursementById';
import { useReimbursementExpenseItemsList } from './hooks/useReimbursementExpenseItemsList';
import { useReimbursementMenuButtonActions } from './hooks/useReimbursementMenuButtonActions';
import { ReimbursementRouteParams } from './hooks/useReimbursementNavigation';

interface ReimbursementDetailsContainerProps {
  isLoading: boolean;
  formHelpers: ReturnType<typeof useExpenseFormsHelpers>;
}

export const ReimbursementDetailsContainer = ({
  formHelpers,
  isLoading,
}: ReimbursementDetailsContainerProps) => {
  const { reimbursementId } = useParams<ReimbursementRouteParams>();
  const { reimbursementItems, reimbursementItemsCount } =
    useReimbursementExpenseItemsList();

  const { reimbursement, loading } = useGetReimbursementById({
    reimbursementId,
  });

  const { title, totalAmount, status } = reimbursement ?? {};

  const {
    isCreatingExpenseItem,
    handleCreateGeneralExpense,
    handleCreateHospitalityExpense,
  } = useReimbursementMenuButtonActions({ formHelpers });

  if (loading || isLoading) {
    return <LoadingReimbursementDetails />;
  }
  return (
    <ReimbursementDetails
      reimbursementItemsCount={reimbursementItemsCount}
      title={title}
      status={status}
      totalAmount={totalAmount}
      creatingReimbursementItem={isCreatingExpenseItem}
      items={reimbursementItems}
      onGeneralExpenseClick={handleCreateGeneralExpense}
      onHospitalityExpenseClick={handleCreateHospitalityExpense}
    />
  );
};

import { Box, Grid } from '@candisio/design-system';
import { Sidebar } from 'components/Sidebar/Sidebar';
import {
  useGetPayableAmountsQuery,
  useGetPayableDocumentsInfoQuery,
  usePaymentQuery,
} from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { Routes } from 'models';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import { DateFormatters } from 'utils/date_formatter';
import { CreatePayment } from 'views/Payments/PaymentSidebar/CreatePayment/CreatePayment';
import { PaymentHistory, PaymentsHistoryQuery } from './PaymentHistory';
import { Payment } from './PaymentSidebar';
import { PaymentTable } from './PaymentTable';
import { useGetPaymentsTableConfigs } from './PaymentTable/hooks/useGetPaymentsTableConfigs';
import { isDisabledRow } from './PaymentTable/tableUtils';
import { paymentsHistoryQuery } from './queries';
import { SimplifiedPayableDocument } from './types';
import { usePayableDocumentsListQueryDeprecated } from './utils/usePayableDocumentsListQueryDeprecated';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { usePayableDocumentsListQuery } from './utils/usePayableDocumentsListQuery';
import { mapPayableDocumentEsEdgeToPayableDocumentEdge } from './utils/mapPayableDocumentEsEdgeToPayableDocumentEdge';

const PAYMENT_HISTORY_LIMIT = 10;

export interface PaymentsProps
  extends RouteComponentProps<{ organizationSlug: string }> {}

export const Payments = ({
  history,

  match: {
    path,
    params: { organizationSlug },
  },
}: PaymentsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PAYMENTS);

  const match = useRouteMatch<{ paymentId: string }>(`${path}/:paymentId`);
  const currentPaymentId = match?.params.paymentId;
  const isPaid = currentPaymentId !== undefined;

  const [selectedDocuments, setSelectedDocuments] = useState<
    SimplifiedPayableDocument[]
  >([]);

  const [selectableDocuments, setSelectableDocuments] = useState<
    SimplifiedPayableDocument[]
  >([]);

  const [exportAll, setExportAll] = useState(true);
  useEffect(() => {
    if (selectedDocuments.length !== selectableDocuments.length) {
      setExportAll(false);
    }
  }, [selectedDocuments.length, selectableDocuments.length]);

  const { sortBy, onSort } = useUrlBasedSortAndFilter({ availableFilters: [] });

  const {
    availablePaymentsColumnIds,
    configurationsTable,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    isResetPending,
    isSavingConfigurations,
  } = useGetPaymentsTableConfigs({ isPaid, sortBy });

  const {
    data: payableAmounts,
    loading: loadingPayableAmounts,
    refetch: refetchPayableAmounts,
  } = useGetPayableAmountsQuery({
    fetchPolicy: 'no-cache',
    skip: isPaid,
  });

  const { data: payableDocumentsInfo, refetch: refetchCountPayableDocuments } =
    useGetPayableDocumentsInfoQuery({
      variables: { hasIban: true },
    });

  const { euValidIbanDocumentsCount, gbValidIbanDocumentsCount } =
    payableDocumentsInfo?.getPayableDocumentsInfo || {};

  const payableCount =
    (euValidIbanDocumentsCount ?? 0) + (gbValidIbanDocumentsCount ?? 0);

  const enableNewIndexInPaymentsViewFF = useCandisFeatureFlags(
    FEATURE_FLAGS.enableNewIndexInPaymentsView
  );

  const payableDocumentsDeprecated = usePayableDocumentsListQueryDeprecated({
    sortBy,
    onCompleted: data => {
      let documents: SimplifiedPayableDocument[] = (
        data.payableDocumentsPagination.edges || []
      ).map(
        record =>
          ({
            ...record.node,
            contact: record.node.contact.name.value,
          }) as SimplifiedPayableDocument
      );

      // revisit currency preselection when we do TCP-336 (Allow transfers to GB IBANS)
      const selectableDocs = documents.filter(
        doc => !isDisabledRow(doc) && doc.currency === 'EUR'
      );

      if (selectedDocuments.length === selectableDocuments.length) {
        // this means selectAll checkbox is checked
        setSelectedDocuments(selectableDocs);
      }

      setSelectableDocuments(selectableDocs);

      return Promise.all([
        refetchCountPayableDocuments(),
        refetchPayableAmounts(),
      ]);
    },
    skip: enableNewIndexInPaymentsViewFF,
  });

  const payableDocuments = usePayableDocumentsListQuery({
    sortBy,
    onCompleted: data => {
      //TODO: and remove extra mapping, while removing deprecated query
      const documents: SimplifiedPayableDocument[] =
        mapPayableDocumentEsEdgeToPayableDocumentEdge(
          data.payableDocumentsEs.edges
        ).map(
          record =>
            ({
              ...record.node,
              contact: record.node.contact.name.value,
            }) as SimplifiedPayableDocument
        );

      // revisit currency preselection when we do TCP-336 (Allow transfers to GB IBANS)
      const selectableDocs = documents.filter(
        doc => !isDisabledRow(doc) && doc.currency === 'EUR'
      );

      if (selectedDocuments.length === selectableDocuments.length) {
        // this means selectAll checkbox is checked
        setSelectedDocuments(selectableDocs);
      }

      setSelectableDocuments(selectableDocs);

      return Promise.all([
        refetchCountPayableDocuments(),
        refetchPayableAmounts(),
      ]);
    },
    skip: !enableNewIndexInPaymentsViewFF,
  });

  const {
    payableDocumentCount: totalCount,
    isLoading: loadingDocumentsCount,
    refetch: refetchPayableDocuments,
    onLoadMore,
  } = enableNewIndexInPaymentsViewFF
    ? payableDocuments
    : payableDocumentsDeprecated;

  const payableDocumentsPaginationData = enableNewIndexInPaymentsViewFF
    ? mapPayableDocumentEsEdgeToPayableDocumentEdge(
        payableDocuments.payableDocuments
      )
    : payableDocumentsDeprecated.payableDocuments;

  const { data: paymentData, loading: loadingPayment } = usePaymentQuery({
    variables: { id: currentPaymentId as string },
    skip: !isPaid,
  });

  const paymentsHistoryPaginationResponse = usePagination<PaymentsHistoryQuery>(
    paymentsHistoryQuery,
    'payments',
    {
      context: { skipLoading: true },
      variables: { limit: PAYMENT_HISTORY_LIMIT },
    }
  );

  const {
    data: paymentsHistoryData,
    refetch: refetchPaymentHistory,
    loading: paymentHistoryLoading,
  } = paymentsHistoryPaginationResponse;

  const hasProcessedPayment =
    (paymentsHistoryData?.payments.records || []).length > 0;

  const onSepaXmlExported = () => {
    setSelectedDocuments([]);

    return Promise.all([refetchPaymentHistory(), refetchPayableDocuments()]);
  };

  let documents: SimplifiedPayableDocument[] = (
    payableDocumentsPaginationData || []
  ).map(
    record =>
      ({
        ...record.node,
        contact: record.node.contact.name.value,
      }) as SimplifiedPayableDocument
  );

  const selectedCurrency = selectedDocuments[0]
    ? selectedDocuments[0].currency
    : undefined;

  let payment;
  if (isPaid && paymentData?.payment) {
    const { paidDocuments, ...rest } = paymentData.payment;

    // flatten out the paid documents object
    payment = {
      ...rest,
      paidDocuments: paidDocuments.map(
        ({ documentId, creditor, ...others }) => ({
          ...others,
          id: documentId,
          contact: creditor.name,
        })
      ),
    };
  }

  const handlePaymentSelected = (paymentId: string | null) => {
    const specificPaymentPath = paymentId ? `/${paymentId}` : '';

    return history.push(
      `/${organizationSlug}${Routes.PAYMENTS}${specificPaymentPath}`
    );
  };

  const showSidebar = isPaid || totalCount > 0;

  let sidebarHeading;

  if (!isPaid) {
    sidebarHeading = t('headers.newPayment');
  }
  if (payment) {
    sidebarHeading = t('headers.paymentSummary', {
      date: DateFormatters.compact(new Date(payment.creationDate)),
    });
  }

  const loading =
    loadingPayment ||
    loadingPayableAmounts ||
    isSavingConfigurations ||
    isResetPending;

  return (
    <Box height="100%">
      <Grid
        templateColumns="minmax(640px,1fr) 350px"
        height="100%"
        overflow="hidden"
      >
        <Grid
          gap="space24"
          templateColumns="320px 1fr"
          padding="space4 space32 space24 space32"
          overflow="hidden"
        >
          <PaymentHistory
            paymentHistoryLoading={paymentHistoryLoading}
            selectedPaymentId={currentPaymentId}
            numberOfDocumentsToBePaid={
              !loadingDocumentsCount ? totalCount : undefined
            }
            paginationResponse={paymentsHistoryPaginationResponse}
            onPaymentSelected={handlePaymentSelected}
          />
          <PaymentTable
            paymentDate={
              isPaid && payment?.creationDate
                ? new Date(payment?.creationDate)
                : undefined
            }
            totalDocumentCount={totalCount}
            payableCount={payableCount}
            onLoadMore={onLoadMore}
            loading={loading}
            documents={isPaid ? payment?.paidDocuments || [] : documents}
            selectedCurrency={selectedCurrency}
            selectedDocuments={selectedDocuments}
            hasProcessedPayment={hasProcessedPayment}
            setSelectedDocuments={setSelectedDocuments}
            selectableDocuments={selectableDocuments}
            isPaid={isPaid}
            onSort={!isPaid ? onSort : undefined}
            exportAll={exportAll}
            setExportAll={setExportAll}
            columns={availablePaymentsColumnIds}
            configurationsTable={configurationsTable}
            onUpdateConfigurations={handleUpdateConfigurations}
            onResetConfigurations={handleResetTableConfigurations}
          />
        </Grid>

        {showSidebar && (
          <Sidebar heading={sidebarHeading}>
            {!isPaid && (!loadingPayableAmounts || payableAmounts) && (
              <CreatePayment
                onSepaXmlExported={onSepaXmlExported}
                selectedDocuments={selectedDocuments}
                payableTotalAmount={
                  payableAmounts?.getPayableAmounts?.totalPayableAmount ??
                  undefined
                }
                discountTotalAmount={
                  payableAmounts?.getPayableAmounts?.discountedAmount ??
                  undefined
                }
                exportAll={exportAll}
                totalPayableCount={payableCount}
              />
            )}
            {isPaid && payment && <Payment payment={payment} />}
          </Sidebar>
        )}
      </Grid>
    </Box>
  );
};

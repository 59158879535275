import { useSearchInput } from 'components/AdvancedSearchFieldFilterOverlay/useSearchInput';
import { queryParameter } from 'components/Table/consts';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  DocumentDataFileType,
  useCreateDataFileForReimbursementCasesMutation,
} from 'generated-types/graphql.types';
import { useUrlBasedSortAndFilter } from 'hooks/table/useUrlSortAndFilters';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { availableFiltersAndSorts } from 'views/Inbox/Reimbursements/toolkit/utils';
import { ReimbursementTableRow } from 'views/Reimbursement/toolkit/types';
import { mapToReimbursementSortInput } from 'views/Reimbursement/utils/mapToReimbursementSortInput';
import { useMapToReimbursementsFilterInput } from 'views/Reimbursement/utils/useMapToReimbursementsFilterInput';
import { defaultReimbursementArchiveSort } from './useArchiveReimbursementsData';
import { downloadFile } from 'utils/downloadFile';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

interface UseDownloadReimbursementsCSVParams {
  isTableLoading: boolean;
}

export const useDownloadReimbursementsCSV = ({
  isTableLoading,
}: UseDownloadReimbursementsCSVParams) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { success, error, info } = useToastMessage();
  const { searchParams } = useMutateSearchParams();

  const { sortBy, filters } = useUrlBasedSortAndFilter<ReimbursementTableRow>({
    availableFilters: availableFiltersAndSorts,
  });
  const searchQueryParam = searchParams.get(queryParameter) ?? undefined;
  const { searchInputVariable } = useSearchInput();

  const mappedFilters = useMapToReimbursementsFilterInput(filters);

  const [createDownloadFile, { loading }] =
    useCreateDataFileForReimbursementCasesMutation();

  const handleCSVDownload = useCallback(async () => {
    if (isTableLoading) {
      info(t('table.downloadCSV.waitForLoading'));
    }

    const response = await createDownloadFile({
      variables: {
        filter: mappedFilters,
        searchInput: searchInputVariable,
        search: searchQueryParam,
        type: DocumentDataFileType.Csv,
        sort: sortBy.length
          ? mapToReimbursementSortInput(sortBy)
          : defaultReimbursementArchiveSort,
      },
    });
    if (!response.data?.createDataFileForReimbursementCases?.file?.url) {
      error(t('table.downloadCSV.errorWhenDownloading'));
    } else {
      try {
        const fileName =
          response.data?.createDataFileForReimbursementCases?.file?.name;

        const fileUrl =
          response.data?.createDataFileForReimbursementCases?.file?.url;
        downloadFile(fileUrl, fileName);
        success(t('table.downloadCSV.successfullyDownloaded'));
      } catch (_err) {
        error(t('table.downloadCSV.errorWhenDownloading'));
      }
    }
  }, [
    createDownloadFile,
    isTableLoading,
    mappedFilters,
    sortBy,
    searchInputVariable,
    searchQueryParam,
    success,
    info,
    error,
    t,
  ]);
  return {
    handleCSVDownload,
    isHandleCSVDownloadPending: loading,
  };
};

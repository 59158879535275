import { getFiltersWithContact } from 'components/PurchaseOrders/SelectPurchaseOrderContainer/utils';
import { usePurchaseOrdersData } from 'components/PurchaseOrders/Table/usePurchaseOrdersData';
import {
  GoodsReceiptStatus,
  PurchaserOrderQueryField,
} from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { toPurchaseOrderNumberFieldItem } from 'views/Inbox/DocumentProcessing/toPurchaseOrderNumberFieldItem';

interface UsePurchaseOrderFieldItemsProps {
  documentAmount?: number | null;
  accountsPayableNumber?: string | null;
  searchValue?: string;
}

/** Fetches items for purchase order field */
export const usePurchaseOrderFieldItems = ({
  documentAmount,
  accountsPayableNumber,
  searchValue = '',
}: UsePurchaseOrderFieldItemsProps) => {
  const filtersWithContact = getFiltersWithContact({
    accountsPayableNumber,
  });

  const {
    purchaseOrderList,
    hasMoreData,
    onLoadMore,
    isLoadingPurchaseOrderList,
  } = usePurchaseOrdersData({
    documentAmount,
    filters: filtersWithContact,
    sortBy: [],
    queryFields: [PurchaserOrderQueryField.OrderNumber],
    searchValue,
  });

  const items = purchaseOrderList.map(toPurchaseOrderNumberFieldItem);

  const disabledKeys = useMemo(() => {
    const disabledPurchaseOrderIds: string[] = [];
    purchaseOrderList.forEach(edge => {
      if (
        !edge.node?.goodsReceipts?.length ||
        !edge.node.goodsReceipts.find(
          goodsReceipt => goodsReceipt?.status === GoodsReceiptStatus.Open
        )
      ) {
        disabledPurchaseOrderIds.push(edge.node._id);
      }
    });

    return disabledPurchaseOrderIds;
  }, [purchaseOrderList]);

  const fetchMoreItems = async () => {
    if (hasMoreData) {
      void onLoadMore();
    }
  };

  return {
    items,
    fetchMoreItems,
    isLoading: isLoadingPurchaseOrderList,
    disabledKeys,
  };
};

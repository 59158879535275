import {
  DatevPermissionWarning,
  DatevService,
  useDatevAvailableServicesQuery,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const useDatevAvailableServices = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ERRORS);
  const { data, loading } = useDatevAvailableServicesQuery();

  const datevPermissionWarnings: Record<DatevPermissionWarning, string> = {
    [DatevPermissionWarning.MissingRdsPermission]: t(
      'datevErrors.dxsoServiceNotEnabled'
    ),
  };

  const availableServices = data?.datevAvailableServices.map(service => {
    return {
      ...service,
      warning: service.warning
        ? datevPermissionWarnings[service.warning]
        : null,
    };
  });

  const dxsoService = availableServices?.find(
    x => x.service === DatevService.DxsoJobs
  );

  return {
    dxsoService,
    hasDxsoWithoutPermissions: dxsoService && dxsoService.warning !== null,
    availableServices,
    availableServicesLoading: loading,
  };
};

import { Maybe } from 'generated-types/graphql.types';
import { useEffect, useState } from 'react';

export interface PDFDetails {
  name: string;
  url: string;
  size?: Maybe<number>;
  id?: string; // use it to get a new url via getDocumentFileQuery
  // Some PDF files will have an additional XML file containing the EInvoice
  eInvoice?: Maybe<{ url: string; name: string }>;
  // needed for comparison view to highlight main document summary card
  mainDocumentFileId?: string;
}

export const useSelectedPdf = (documentFile: PDFDetails) => {
  const [selectedPdf, setSelectedPdf] = useState<PDFDetails>(documentFile);

  useEffect(() => {
    setSelectedPdf(documentFile);
  }, [documentFile]);

  return [selectedPdf, setSelectedPdf] as const;
};

import { TruncatedText, useTheme } from '@candisio/design-system';
import { castArray, isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { DocumentsTableData } from '../types';

export type SplitsCellValue = (undefined | string)[];

export const SplitsCell = ({
  value,
}: CellProps<DocumentsTableData, SplitsCellValue>): JSX.Element => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const { colors } = useTheme();
  const render = getSplitsRenderType(value);

  if (render === 'split') {
    return (
      <TruncatedText
        color={colors.gray400}
        wordBreak="break-all"
        fontStyle="italic"
      >
        {t('seeSplitBookings')}
      </TruncatedText>
    );
  }

  if (render === 'single') {
    return <TruncatedText>{String(value[0])}</TruncatedText>;
  }

  return t('emptyCellPlaceholder');
};

type RenderType = 'empty' | 'single' | 'split';

export const getSplitsRenderType = (value: SplitsCellValue): RenderType => {
  if (isNil(value) || !value[0]) {
    return 'empty';
  }

  const values = castArray(value);
  if (values.every(v => !v)) {
    return 'empty';
  }

  const allValuesAreTheSame = values.every(v => v === values?.[0]);
  if (values.length > 1 && !allValuesAreTheSame) {
    return 'split';
  }

  return 'single';
};

import { Box } from '@candisio/design-system';
import { DocumentViewer } from 'components/DocumentViewer/DocumentViewer';
import { useRelatedDocumentFiles } from 'components/DocumentViewer/useRelatedDocumentFiles';
import { ComponentProps, useMemo } from 'react';

const DocumentLayout = (props: ComponentProps<typeof DocumentViewer>) => (
  <Box height="100%" overflowY="auto">
    <DocumentViewer hideAttachmentSection {...props} />
  </Box>
);

export const RelatedDocumentContainer = ({
  mainDocumentId,
  selectedPdfId,
}: {
  mainDocumentId: string;
  selectedPdfId?: string;
  setSelectedPdfId?: (id: string) => void;
}) => {
  const files = useRelatedDocumentFiles(mainDocumentId);

  const selectedPdf = useMemo(() => {
    if (!selectedPdfId) return files[0];

    return files.find(file => file.documentFile.id === selectedPdfId);
  }, [files, selectedPdfId]);

  return (
    <DocumentLayout
      key={selectedPdf?.documentFile.id}
      documentId={selectedPdf?.documentFile.id}
      documentFile={selectedPdf?.documentFile}
      attachments={selectedPdf?.attachments}
    />
  );
};

import { Button, Modal, ModalProps } from '@candisio/design-system';
import clsx from 'clsx';
import styles from './WistiaModal.module.css';

export const wistiaEmbedClass = 'wistia_embed';

interface WistiaModalProps extends ModalProps {
  wistiaId: string;
  className?: string;
}

export const WistiaModal = ({
  wistiaId,
  className,
  onClose,
  ...restProps
}: WistiaModalProps) => {
  const sanitizedWistiaId = wistiaId.split('wistia_async_').join('');
  const wistiaClasses = `${wistiaEmbedClass} wistia_async_${sanitizedWistiaId}`;

  const classes = clsx(styles.videoWrapper, className);

  return (
    <Modal width="max-content" {...restProps} padding="space0">
      <Button
        data-cy="closeButton"
        className="!absolute right-2 top-2 z-9"
        icon="close"
        variant="primary"
        color="gray"
        onClick={onClose}
      />
      <div
        data-cy="wistia-video-wrapper"
        className={`${wistiaClasses} ${classes}`}
      />
    </Modal>
  );
};

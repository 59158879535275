import { DocumentFormContainer } from 'components/Form/DocumentForm/DocumentFormContainer';
import { ProcessedDocument } from 'components/Form/DocumentForm/toolkit/types';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { DocumentStatus } from 'generated-types/graphql.types';
import { DocumentStatusFrontend } from 'models';
import { isPaidDocument } from 'utils/document_payment_data';

interface ApprovedProps {
  document: ProcessedDocument;
  toggleEditMode: () => void;
}

export const Approved = ({ document, toggleEditMode }: ApprovedProps) => (
  <ProcessSidebar
    documentId={document.id}
    accessModificationPermissions={document.accessModificationPermissions}
    globalDocumentId={document.globalDocumentId ?? undefined}
    documentStatus={
      isPaidDocument(document)
        ? DocumentStatusFrontend.ApprovedAndPaid
        : DocumentStatus.Approved
    }
  >
    <DocumentFormContainer
      document={document}
      allowInlineEditing
      reRequestApproval={toggleEditMode}
    />
  </ProcessSidebar>
);

import { Box, Flex } from '@candisio/design-system';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { useDatev } from 'orgConfig/datev';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { useCreditCardsLedgerUpdate } from 'views/Settings/useCreditCardsLedger';
import { useDatevBookingAccountValidation } from 'views/Settings/Integrations/DATEV/useDatevBookingAccountValidation';
import { QuickSaveButtons } from 'views/Settings/components/QuickSaveButtons/QuickSaveButtons';
import { refetchDatevData } from 'views/Settings/Integrations/DATEV/gql';
import { getPlaceholderBookingAccount } from './BookingAccountForm.helper';
import { useOtherIntegration } from 'orgConfig/other';

interface BookingAccountFormProps {
  bookingAccount: string;
  accountLength: number;
}

type BookingAccountFormData = {
  bookingAccount: string;
};

export const BookingAccountForm = ({
  accountLength,
  bookingAccount,
}: BookingAccountFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { schema, errorMessages } =
    useDatevBookingAccountValidation('bookingAccount');

  const { bdsBought } = useDatev(); // BDS-checked

  const otherIntegration = useOtherIntegration();
  const tOptions = otherIntegration.isActive ? { context: 'other' } : '';

  const labelTranslationKey = bdsBought
    ? 'creditCardExporting.creditCardAccount.textInput.ariaLabel'
    : 'creditCardExporting.cashLedgerAccount.textInput.ariaLabel';

  const placeholderTranslationKey = bdsBought
    ? 'creditCardExporting.creditCardAccount.textInput.placeholder'
    : 'creditCardExporting.cashLedgerAccount.textInput.placeholder';

  const form = useForm({
    defaultValues: { bookingAccount },
    context: { t },
    resolver: zodResolver({
      zodSchema: schema({ t }),
      errorMessages: errorMessages({ t, label: labelTranslationKey }),
      translationNamespace: LOCALE_NAME_SPACE.CREDIT_CARDS,
    }),
    mode: 'onSubmit',
  });

  const [updateCreditCardsLedger, { loading: isLoading }] =
    useCreditCardsLedgerUpdate();

  const onUpdateBookingAccount = useCallback(
    async ({ bookingAccount }: BookingAccountFormData) => {
      const result = await updateCreditCardsLedger({
        variables: { bookingAccount, transitAccount: undefined },
        refetchQueries: refetchDatevData,
      });

      return result;
    },
    [updateCreditCardsLedger]
  );

  const handleSubmit = async ({
    bookingAccount: value,
  }: BookingAccountFormData) => {
    const bookingAccount = !value ? '' : value;

    const result = await onUpdateBookingAccount({ bookingAccount });

    if (result.data) {
      form.resetField('bookingAccount', {
        defaultValue: bookingAccount,
      });
    }
  };

  const isFormDirty = form.formState.isDirty;

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        onSubmit={form.handleSubmit(handleSubmit)}
        gap="space16"
        alignItems="end"
      >
        <HookFormTextField
          autoFocus={true}
          label={t(labelTranslationKey, tOptions)}
          name="bookingAccount"
          placeholder={t(placeholderTranslationKey, {
            accountNumber: getPlaceholderBookingAccount(accountLength),
          })}
          clearable={false}
        />
        <Box paddingTop="space16">
          <QuickSaveButtons
            isFormDirty={isFormDirty}
            resetField={() => form.resetField('bookingAccount')}
            isLoading={isLoading}
          />
        </Box>
      </Flex>
    </FormProvider>
  );
};

import { Icon, Skeleton, Text } from '@candisio/design-system';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { CardStatus } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import styles from './AccountingSettingsItem.module.css';
import { CardItem } from './Item';

interface CreditCardFormDataPreviewProps {
  loading: boolean;
  hasAccountingData?: boolean;
  cardStatus?: CardStatus;
  onClose?: () => void;
  onSetMode: (mode: DrawerMode) => void;
}

export const AccountingSettingsItem = ({
  loading,
  cardStatus,
  hasAccountingData,
  onSetMode,
}: CreditCardFormDataPreviewProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const handleClick = () => {
    onSetMode('accountingSettings');
  };

  const isCardRequested = cardStatus === CardStatus.Requested;

  return (
    <CardItem icon="clipboard" onClick={handleClick}>
      <Text fontSize="large">
        {t('creditCardFormDataPreview.accounting.title')}
      </Text>
      {loading ? (
        <Skeleton width="space128" height="space20" />
      ) : hasAccountingData ? (
        <div
          className={styles.status}
          data-status={isCardRequested ? 'warning' : 'success'}
        >
          <Icon
            icon={isCardRequested ? 'warning' : 'checkCircle'}
            size="space18"
          />
          <Text fontSize="small">
            {t(
              isCardRequested
                ? 'creditCardFormDataPreview.accounting.checkSettings'
                : 'cardManagerView.status.active'
            )}
          </Text>
        </div>
      ) : (
        <Text fontSize="small">
          {t('creditCardFormDataPreview.accounting.subtitle')}
        </Text>
      )}
    </CardItem>
  );
};

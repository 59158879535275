export const breakpoints = {
  screen100: '767px',
  screen200: '1279px',
  screen300: '1439px',
  screen400: '1919px',
};

export const colors = {
  gray800: '#2B1A2E',
  gray700: '#4F4250',
  gray600: '#6A5F6B',
  gray500: '#887E87',
  gray450: '#B6A7B2',
  gray400: '#CEC4CC',
  gray300: '#DDD7DB',
  gray250: '#EAE5E6',
  gray200: '#F3EFEE',
  gray100: '#F8F6F5',
  gray50: '#FBFAF8',
  gray0: '#FFFFFF',
  graybg: '#F8F6F580',

  blue800: '#284AB0',
  blue700: '#3D64D9',
  blue600: '#587DE9',
  blue500: '#6F94FF',
  blue400: '#89A6FB',
  blue300: '#A3B7F7',
  blue200: '#BDC9F3',
  blue100: '#D7DAEF',
  blue50: '#EBECF7',
  bluebg: '#D7DAEF80',

  green800: '#046739',
  green700: '#4BAE7E',
  green600: '#57C08D',
  green500: '#63D09B',
  green400: '#85DAB0',
  green300: '#9ADEBC',
  green200: '#B5E2CA',
  green100: '#D0E3D8',
  greenbg: '#EFFBF380',

  yellow800: '#884E18',
  yellow700: '#D1900E',
  yellow600: '#F1AA1F',
  yellow500: '#F3B02D',
  yellow450: '#FFB92F',
  yellow400: '#FDC24F',
  yellow300: '#FBCC75',
  yellow200: '#F8D79A',
  yellow100: '#F6DEB6',
  yellowbg: '#F6E3C680',

  red800: '#B72A05',
  red700: '#C72B03',
  red600: '#E54014',
  red500: '#F04315',
  red400: '#F97E5E',
  red300: '#F7A38D',
  red200: '#F4C7BC',
  red100: '#F2DAD3',
  redbg: '#FFDADA80',

  pink800: '#AD1F96',
  pink700: '#CD30B4',
  pink600: '#F573F5',
  pink500: '#FF87FF',
  pink400: '#FC9BFB',
  pink300: '#F9AFF7',
  pink200: '#F7C4F3',
  pink100: '#F4D8EF',
  pinkbg: '#F4D8EF80',

  purple800: '#6A41B5',
  purple700: '#7F55CC',
  purple600: '#9166DB',
  purple500: '#A076E8',
  purple400: '#B48EF2',
  purple300: '#C7A6F5',
  purple200: '#D9C1F8',
  purple100: '#ECDFFB',
  purplebg: '#ECDFFB80',
};

export const focusStyle = { boxShadow: `0 0 0 3px ${colors.blue400}` };

export const fontFamilies = {
  body: 'Inter, sans-serif',
  heading: '"ITC Garamond", serif',
  monospace: 'Menlo, monospace',
};

export const fontSizes = {
  xsmall: '0.625rem',
  small: '0.75rem',
  basic: '0.875rem',
  large: '1rem',
  xlarge: '1.125rem',
  xxlarge: '1.5rem',
  xxxlarge: '1.875rem',
};

export const fontWeights = {
  regular: 400,
  semibold: 500,
  bold: 600,
};

export const lineHeights = {
  body: 1.5,
  paragraph: 1.5,
  heading: 1.5,
};

export const radii = {
  none: '0px',
  xsmall: '2px',
  small: '4px',
  medium: '8px',
  basic: '12px',
  large: '16px',
  massive: '24px',
  full: '999px',
};

export const shadows = {
  noShadow: 'none',
  elevatedShadow1: '0px 1px 4px  #00000033',
  elevatedShadow2: '0px 4px 8px  #0000001F',
  elevatedShadow3: '0px 8px 16px #0000001A',
  elevatedShadow4: '0px 8px 16px #00000029',
  elevatedShadow5: '0px 8px 24px #00000033',
};

export const space = {
  space2: '0.125rem',
  space3: '0.1875rem',
  space4: '0.25rem',
  space5: '0.375rem',
  space6: '0.375rem',
  space7: '0.4375rem',
  space8: '0.5rem',
  space10: '0.625rem',
  space12: '0.75rem',
  space14: '0.875rem',
  space16: '1rem',
  space18: '1.125rem',
  space20: '1.25rem',
  space24: '1.5rem',
  space30: '1.875rem',
  space32: '2rem',
  space36: '2.25rem',
  space40: '2.5rem',
  space48: '3rem',
  space64: '4rem',
  space80: '5rem',
  space96: '6rem',
  space128: '8rem',
  space200: '12.5rem',
  space256: '16rem',
};

export const timingFunctions = {
  ease: 'cubic-bezier(0.87, 0, 0.13, 1)',
};

export const themeValues = {
  breakpoints,
  colors,
  focusStyle,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  shadows,
  space,
  timingFunctions,
};

import { Locale, useMembershipByIdQuery } from 'generated-types/graphql.types';
import { User } from 'views/Settings/TeamMembers/hooks/useGetUsers';

export const useGetMembershipById = ({
  membershipId,
}: { membershipId: string | undefined }) => {
  const { data, loading } = useMembershipByIdQuery({
    variables: { membershipId: membershipId as string },
    skip: !membershipId,
  });

  const user = data?.membership;

  if (!user || loading) {
    return { user: undefined, loading };
  }

  const mappedUser: User = {
    id: user?.id,
    name: user?.name,
    firstName: user?.firstName,
    lastName: user?.lastName,
    username: user?.username,
    createdTimestamp: user?.createdTimestamp,
    locale: user?.locale ?? Locale.De,
    status: user?.status ?? undefined,
    // This will need to be removed. Right now there are some checks down the components in the drawer
    // that rely on these roles and we cannot modify it yet until permissions are established.
    roles: user?.roles ?? [],
    emailVerified: true,
    email: user?.email ?? undefined,
    avatarUrl: user?.avatarUrl ?? undefined,
    paymentInfo: {
      iban: user?.paymentInfo?.iban ?? undefined,
      swiftCode: user?.paymentInfo?.swiftCode ?? undefined,
    },
    membershipRoles: (user?.membershipRoles ?? []).map(role => ({
      isLegacyRole: role.isLegacyRole ?? false,
      isBuiltinRole: role.isBuiltinRole ?? false,
      name: role.name,
      id: role.id,
    })),
  };

  return { user: mappedUser, loading };
};

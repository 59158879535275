import { ProjectCode } from 'generated-types/graphql.types';

export const getProjectCodeReadableName = (
  projectCode?: Partial<ProjectCode>
) => {
  if (!projectCode) {
    return '';
  }
  const { code, name } = projectCode;
  return `${code} - ${name}`;
};

import { Heading } from '@candisio/design-system';
import { DocumentAccessCard } from 'components/DocumentAccess/DocumentAcccessCard';
import { useTranslation } from 'react-i18next';
import { useDocumentAccessLevelsData } from './toolkit/hooks/useDocumentAccessLevelsData';

interface AccessByRoleCardContainerProps {
  globalDocumentId?: string;
}

export const UploaderAccessCardContainer = ({
  globalDocumentId,
}: AccessByRoleCardContainerProps) => {
  const { accessByUploaderData, loading } = useDocumentAccessLevelsData({
    globalDocumentId,
  });

  const [t] = useTranslation();

  return (
    <DocumentAccessCard
      accessData={accessByUploaderData}
      loading={loading}
      title={
        <Heading as="h3">
          {t('common:documentAccess.membership.access.byUploader.title')}
        </Heading>
      }
    />
  );
};

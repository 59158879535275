import { Grid, Icon, Image, TruncatedText } from '@candisio/design-system';
import { BankAccountItem as IbanItemType } from 'components/BankAccountListView/BankAccountListView';
import { friendlyFormatIBAN } from 'ibantools';

export const BankAccountItem = ({ item }: { item: IbanItemType }) => {
  return (
    <Grid gap="space8" templateColumns="auto 1fr" alignItems="center">
      {item.logoUrl ? (
        <Image alt="" height="space24" src={item.logoUrl} width="space24" />
      ) : (
        <Icon icon="bank" size="space24" />
      )}
      <Grid>
        <TruncatedText fontSize="basic" fontWeight="semibold">
          {item.name}
        </TruncatedText>
        <TruncatedText color="gray500">
          {friendlyFormatIBAN(item.iban) as string}
        </TruncatedText>
      </Grid>
    </Grid>
  );
};

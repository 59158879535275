import { Icon } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Trans, useTranslation } from 'react-i18next';
import { CreateReimbursementDuplicateErrorAvatarStack } from './CreateReimbursementDuplicateErrorAvatarStack';
import styles from './CreateReimbursementDuplicateErrorContent.module.css';

export const CreateReimbursementDuplicateErrorContent = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <div className={styles.grid} data-cy="duplicateContactError">
      <Icon icon="failCircle" color="red500" size="space20" />
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1">
          <p className={styles.title}>
            {t('dashboard.createExpense.duplicateContactError.title')}
          </p>
          <Trans
            t={t}
            parent="span"
            i18nKey="dashboard.createExpense.duplicateContactError.text"
          >
            A new expense couldn't be created because a
            <span className="font-medium">contact</span> with the
            <span className="font-medium">same name</span> exists in Candis.
            Please contact a Candis Administrator to resolve this issue and try
            again.
          </Trans>
        </div>
        <div className="flex flex-col gap-1">
          <p className={styles.title}>
            {t('dashboard.createExpense.duplicateContactError.candisAdmins')}
          </p>
          <CreateReimbursementDuplicateErrorAvatarStack />
        </div>
      </div>
    </div>
  );
};

import {
  Flex,
  Grid,
  Icon,
  Link,
  Separator,
  Text,
} from '@candisio/design-system';
import moment from 'moment';
import { useDatevExportConfig } from 'orgConfig/datev/datevExportConfig';
import { useTranslation } from 'react-i18next';

interface ConnectionDetailsProps {
  connectedAt?: string | null;
  connectedBy?: string | null;
}
const ExpiredConnection = ({ connectedBy }: ConnectionDetailsProps) => {
  const [t] = useTranslation();

  return (
    <Flex gap="space4" alignItems="center">
      <Icon icon="failCircle" color="red500" size="space16" />
      <Text color="red500" whiteSpace="pre-wrap">
        {connectedBy
          ? t(
              'settings.datev.connect.connectedClient.connectionDetails.expiredBy',
              { name: connectedBy }
            )
          : t(
              'settings.datev.connect.connectedClient.connectionDetails.expired'
            )}
      </Text>
    </Flex>
  );
};

const ValidConnection = ({
  connectedAt,
  connectedBy,
}: ConnectionDetailsProps) => {
  const [t] = useTranslation();

  //if (!connectedAt) return null;

  const connectionValidUntil = moment(connectedAt)
    .add(2, 'y')
    .add(-1, 'd')
    .format('DD.MM.yyyy');

  const connectedText = connectedBy
    ? t(
        'settings.datev.connect.connectedClient.connectionDetails.connectedBy',
        { name: connectedBy }
      )
    : t('settings.datev.connect.connectedClient.connectionDetails.connected');

  const validationDateText = connectedAt
    ? t('settings.datev.connect.connectedClient.connectionDetails.validUntil', {
        date: connectionValidUntil,
      })
    : null;

  const connectionDetails = [connectedText, validationDateText]
    .filter(Boolean)
    .join(' - ');

  return (
    <Flex gap="space4" alignItems="center">
      <Icon icon="checkCircle" color="green600" size="space16" />
      <Text color="gray500">{connectionDetails}</Text>
    </Flex>
  );
};

export const ConnectionDetails = ({
  connectedAt,
  connectedBy,
}: ConnectionDetailsProps) => {
  const [t] = useTranslation();

  // valid until 2 years from creation date minus 1 day
  // check difference in minutes with today date and time
  const isExpiredConnection =
    moment(connectedAt).add(2, 'y').add(-1, 'd').diff(moment(), 'm') <= 0;

  const { hasInvalidGrant } = useDatevExportConfig();

  return (
    <>
      <Separator />
      <Grid templateColumns="1fr auto" columnGap="space12" width="100%">
        {isExpiredConnection || hasInvalidGrant ? (
          <ExpiredConnection connectedBy={connectedBy} />
        ) : (
          <ValidConnection
            connectedAt={connectedAt}
            connectedBy={connectedBy}
          />
        )}
        <Link
          external
          href={t(
            'settings.datev.connect.connectedClient.connectionDetails.link'
          )}
        >
          {t(
            'settings.datev.connect.connectedClient.connectionDetails.linkText'
          )}
        </Link>
      </Grid>
    </>
  );
};

import {
  BuiltinRoleName,
  GetRolesForUserQueryQuery,
  GetRolesForUserQueryQueryVariables,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { getRolesForUserQuery } from '../queries';

export interface Role {
  id: string;
  name: BuiltinRoleName | string;
  description: string | undefined;
  type: 'BuiltinRole' | 'CustomRole';
}

export const useGetRolesForUser = () => {
  const computeVariables = useCallback(
    (): GetRolesForUserQueryQueryVariables => ({
      input: {
        page: 1,
        limit: 50,
      },
      filters: {
        isArchived: false,
        isBuiltinRole: true,
      },
    }),
    []
  );

  const { data, loading, loadMore, handleDebounceSearch } =
    useDebouncedSearchPagination<
      GetRolesForUserQueryQuery,
      GetRolesForUserQueryQueryVariables
    >({
      query: getRolesForUserQuery,
      queryRootKey: 'roles',
      computeVariables,
    });

  const roles: Role[] = (data?.roles?.records ?? []).map(role => ({
    type: role.__typename,
    id: role.id,
    name:
      role.__typename === 'BuiltinRole'
        ? role.builtInRoleName
        : role.customRoleName,
    description: role.description ?? undefined,
  }));

  return {
    roles,
    loading,
    loadMore,
    handleDebounceSearch,
  };
};

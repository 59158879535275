import { MAX_DOCUMENT_FILE_SIZE_MB } from 'constants/uploads';
import {
  FileState,
  FileUploadAttemptStatus,
  UploadFileItem,
  useRemoveFileUploadByIdMutation,
} from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { UploadHistoryItemError } from '../UploadHistoryItem/UploadHistoryItemError';
import { UploadHistoryItemPending } from '../UploadHistoryItem/UploadHistoryItemPending';

export const ClientUploadHistoryItem = ({ item }: { item: UploadFileItem }) => {
  const [t] = useTranslation();
  const [removeById] = useRemoveFileUploadByIdMutation();

  const removeErroredItem = () => {
    removeById({ variables: { id: item.id } });
  };

  if (item.state === FileState.Error) {
    return (
      <UploadHistoryItemError
        error={t(item.errorDetails ?? '', { size: MAX_DOCUMENT_FILE_SIZE_MB })}
        fileName={item.fileName}
        onRemove={removeErroredItem}
      />
    );
  }

  return (
    <UploadHistoryItemPending
      fileName={item.fileName}
      status={FileUploadAttemptStatus.Pending}
    />
  );
};

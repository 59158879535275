import { Button, Card, Flex, Link, Paragraph } from '@candisio/design-system';
import { GetEInvoiceValidationProtocolDataQuery } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { ContactSupplierButton } from 'views/Inbox/DocumentProcessing/components/ContactSupplier/ContactSupplierButton';

type EInvoiceValidationDetailsModalFooterProps = {
  documentId: string;
  handleCopy: () => void;
  eInvoiceValidationErrors: NonNullable<
    GetEInvoiceValidationProtocolDataQuery['getDocument']
  >['eInvoiceErrors'];
};

export const EInvoiceValidationDetailsModalFooter = ({
  documentId,
  handleCopy,
  eInvoiceValidationErrors,
}: EInvoiceValidationDetailsModalFooterProps) => {
  const [t] = useTranslation();

  return (
    <Card width="100%" zIndex={1000} borderTopRadius="0">
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Paragraph whiteSpace="pre-line">
          {t(
            'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.learnMore.title'
          )}{' '}
          <Link
            external
            href={t(
              'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.learnMore.link.href'
            )}
          >
            {t(
              'common:document.compact.history.invalidEInvoice.validationProtocolModal.content.learnMore.link.label'
            )}
          </Link>
        </Paragraph>

        <Flex direction="row" gap="space8">
          <Button
            width="space256"
            variant="secondary"
            color="blue"
            onClick={handleCopy}
            maxWidth="max-content"
          >
            {t(
              'common:document.compact.history.invalidEInvoice.validationProtocolModal.actions.copyToClipboard.label'
            )}
          </Button>
          <ContactSupplierButton
            documentId={documentId}
            eInvoiceErrorCount={eInvoiceValidationErrors?.length}
            trackingEventLocation="e_invoice_error_details"
          />
        </Flex>
      </Flex>
    </Card>
  );
};

import {
  AvailableDocumentTypesQuery,
  DocumentStatus,
  DocumentType,
  ProcessingFormFieldItemsQuery,
  useAvailableDocumentTypesQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEcmDocumentTypeOptions } from 'views/Inbox/DocumentProcessing/components/Ecm/useEcmDocumentTypeItems';
import {
  OTHER_INTEGRATION_DOCUMENT_TYPES_DEPRECATED,
  OTHER_INTEGRATION_DOCUMENT_TYPES,
  SAP_DOCUMENT_TYPES,
} from 'views/Inbox/DocumentProcessing/consts';
import {
  documentTypeTranslationKeys,
  useToTypeFieldItem,
} from 'views/Inbox/DocumentProcessing/useToTypeFieldItem';
import { ProcessingFormTypeFieldItem } from '../ProcessingForm/ProcessingFormTypeField';
import { useEcm } from 'orgConfig/ecm/useEcm';

export type GlobalDocumentType =
  AvailableDocumentTypesQuery['availableDocumentTypes'][number];

const EXCLUDED_DOCUMENT_TYPES_FROM_INBOX: DocumentType[] = [
  DocumentType.ExpenseReimbursementItem,
  DocumentType.TravelReimbursementItem,
];

type SapIntegrationDocumentTypes = (typeof SAP_DOCUMENT_TYPES)[number];

type OtherIntegrationDocumentTypes =
  (typeof OTHER_INTEGRATION_DOCUMENT_TYPES)[number];

type OtherIntegrationDocumentTypesDeprecated =
  (typeof OTHER_INTEGRATION_DOCUMENT_TYPES_DEPRECATED)[number];

export const isSupportedDocumentType = (
  documentType: string
): documentType is DocumentType =>
  !EXCLUDED_DOCUMENT_TYPES_FROM_INBOX.includes(documentType as DocumentType) &&
  Object.values(DocumentType).includes(documentType as DocumentType);

export type DocumentTypesFieldItem = {
  key: string;
  label: string;
  value: GlobalDocumentType;
};

export interface UseDocumentTypeFieldOptionsOptions {
  types: ProcessingFormFieldItemsQuery['types'];
  autoFocus?: boolean;
  status?: DocumentStatus;
}

export interface UseDocumentTypeFieldOptionsResult {
  /** @deprecated */
  items?: ProcessingFormTypeFieldItem[];
  autoFocus?: boolean;
  groupedItems?: DocumentTypesFieldItem[][];
  newItems?: DocumentTypesFieldItem[];
}

export const useDocumentTypeFieldOptions = ({
  types = [],
  autoFocus,
  status,
}: UseDocumentTypeFieldOptionsOptions): UseDocumentTypeFieldOptionsResult => {
  const [t] = useTranslation();
  const [llmClassificationFF, allowOutgoingInvoiceForApiFF] =
    useCandisFeatureFlags([
      FEATURE_FLAGS.llmClassification,
      FEATURE_FLAGS.allowOutgoingInvoiceForApi,
    ]);

  const { showEcm } = useEcm();

  const { ecmDocumentTypeTranslationMap } = useEcmDocumentTypeOptions();
  const isNewDocument = status === DocumentStatus.New;

  const { data } = useAvailableDocumentTypesQuery({
    variables: { includeInactiveTypes: false },
    skip: !llmClassificationFF || !isNewDocument,
  });

  const sap = useSap();
  const otherIntegration = useOtherIntegration();
  const toTypeFieldItem = useToTypeFieldItem();

  let typeDeprecated: {
    items: ProcessingFormTypeFieldItem[];
    autoFocus?: boolean;
  };

  if (sap.isActive) {
    typeDeprecated = {
      items: types
        .filter(type =>
          SAP_DOCUMENT_TYPES.includes(
            type.documentType as SapIntegrationDocumentTypes
          )
        )
        .map(toTypeFieldItem),
      autoFocus,
    };
  } else if (otherIntegration.showDocumentTypesForOtherIntegrations) {
    typeDeprecated = {
      items: types
        .filter(type =>
          allowOutgoingInvoiceForApiFF
            ? OTHER_INTEGRATION_DOCUMENT_TYPES.includes(
                type.documentType as OtherIntegrationDocumentTypes
              )
            : OTHER_INTEGRATION_DOCUMENT_TYPES_DEPRECATED.includes(
                type.documentType as OtherIntegrationDocumentTypesDeprecated
              )
        )
        .map(toTypeFieldItem),
      autoFocus,
    };
  } else {
    typeDeprecated = {
      items: types.map(toTypeFieldItem),
      autoFocus,
    };
  }

  const [invoiceTypesArray, documentTypesArray] = useMemo(() => {
    const invoiceTypesArray: DocumentTypesFieldItem[] = [];
    const documentTypesArray: DocumentTypesFieldItem[] = [];

    (data?.availableDocumentTypes ?? []).forEach(item => {
      if (item.documentCategory) {
        const translationKey =
          documentTypeTranslationKeys[item.documentCategory.documentType];

        const translatedLabel = translationKey
          ? t(translationKey)
          : item.documentCategory.documentType;

        invoiceTypesArray.push({
          key: item.documentCategory.documentType,
          label: item.documentCategory.isDefault
            ? translatedLabel
            : item.documentCategory.documentType,
          value: item,
        });
      } else if (showEcm && isSupportedDocumentType(item.documentType))
        documentTypesArray.push({
          key: item.documentType,
          value: item,
          label: ecmDocumentTypeTranslationMap[item.documentType],
        });
    });

    return [invoiceTypesArray, documentTypesArray];
  }, [ecmDocumentTypeTranslationMap, t, data?.availableDocumentTypes, showEcm]);

  const groupedItems = useMemo(
    () => [invoiceTypesArray, documentTypesArray],
    [documentTypesArray, invoiceTypesArray]
  );

  const newItems = useMemo(() => {
    return [...invoiceTypesArray, ...documentTypesArray];
  }, [documentTypesArray, invoiceTypesArray]);

  if (!llmClassificationFF || !isNewDocument) return typeDeprecated;

  return {
    groupedItems,
    autoFocus,
    newItems,
    items: typeDeprecated.items,
  };
};

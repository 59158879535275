import { DocumentAccountingAreaUpdatedEventComponent } from 'components/DocumentHistory/history-components/DocumentAccountingAreaUpdatedEventComponent';
import { PurchaseOrderEventComponent } from 'components/DocumentHistory/history-components/PurchaseOrderEventComponent';
import {
  DocumentRelationshipCreatedEventDataFragment,
  DocumentRelationshipRemovedEventDataFragment,
} from 'generated-types/graphql.types';
import { History } from '../History/History';
import { ACTIVITY_SOURCE } from '../History/items/BaseItem';
import { ApproverExtractedComponent } from './history-components/ApproverExtractedComponent';
import { AttachmentConvertedToDocumentComponent } from './history-components/AttachmentConvertedToDocumentComponent';
import { AttachmentDeletedComponent } from './history-components/AttachmentDeletedComponent';
import { AttachmentDetachedComponent } from './history-components/AttachmentDetachedComponent';
import { CommentComponent } from './history-components/CommentComponent';
import { ContactExtractedComponent } from './history-components/ContactExtractedComponent';
import { ContactSupplierComponent } from './history-components/ContactSupplierComponent';
import { CostCenterExtractedComponent } from './history-components/CostCenterExtractedComponent';
import { DocumentApprovedComponent } from './history-components/DocumentApprovedComponent';
import { DocumentAssociationsCreatedEventComponent } from './history-components/DocumentAssociationsCreatedEventComponent';
import { DocumentAssociationsRemovedEventComponent } from './history-components/DocumentAssociationsRemovedEventComponent';
import { DocumentConvertedComponent } from './history-components/DocumentConvertedComponent';
import { DocumentConvertedToAttachmentComponent } from './history-components/DocumentConvertedToAttachmentComponent';
import { DocumentFileReplaced } from './history-components/DocumentFileReplaced';
import { DocumentGrossNetTaxRoundingChangedComponent } from './history-components/DocumentGrossNetTaxRoundingChangedComponent';
import { DocumentImportedbyMigrationComponent } from './history-components/DocumentImportedByMigrationComponent';
import { DocumentPaymentInfoUpdated } from './history-components/DocumentPaymentInfoUpdatedComponent';
import { DocumentRejectedComponent } from './history-components/DocumentRejectedComponent';
import { DocumentRelationshipCreatedComponent } from './history-components/DocumentRelationshipCreatedComponent';
import { DocumentRelationshipRemovedComponent } from './history-components/DocumentRelationshipRemovedComponent';
import { DocumentResetByCandisComponent } from './history-components/DocumentResetByCandisComponent';
import { DocumentResetManualComponent } from './history-components/DocumentResetManualComponent';
import {
  DocumentResetTransactionAmountUpdatedComponent,
  DocumentResetTransactionLinkedComponent,
  DocumentResetTransactionUnlinkedComponent,
} from './history-components/DocumentResetTransactionComponent';
import { DocumentResetUserDeactivationComponent } from './history-components/DocumentResetUserDeactivationComponent';
import { DocumentResetUserRoleChangedComponent } from './history-components/DocumentResetUserRoleChangedComponent';
import { DocumentTagUpdatedEventComponent } from './history-components/DocumentTagUpdatedEventComponent';
import { DocumentTransactionLinkedComponent } from './history-components/DocumentTransactionLinkedComponent';
import { DocumentTransactionReconciledComponent } from './history-components/DocumentTransactionReconciledComponent';
import { DocumentTransactionStatusUpdatedComponent } from './history-components/DocumentTransactionStatusUpdatedComponent';
import { DocumentTransactionUnlinkedComponent } from './history-components/DocumentTransactionUnlinkedComponent';
import { EcmEventsContainer } from './history-components/EcmEvents/EcmEventsContainer';
import { ExportedComponent } from './history-components/ExportedComponent';
import { FileAttachedComponent } from './history-components/FileAttachedComponent';
import { FileUploadByEmailComponent } from './history-components/FileImportedComponent';
import { FileUploadComponent } from './history-components/FileUploadComponent';
import { InvalidEInvoiceDetectedComponent } from './history-components/InvalidEInvoiceDetectedComponent';
import { MetaExtractedComponent } from './history-components/MetaExtractedComponent';
import { ProvisionCreatedComponent } from './history-components/ProvisionCreatedComponent';
import { ProvisionDeletedComponent } from './history-components/ProvisionDeletedComponent';
import { ProvisionExportedComponent } from './history-components/ProvisionExportedComponent';
import { RequestApprovalWithSubstituteEvent } from './history-components/RequestApprovalComponent';
import { ReversalCreatedComponent } from './history-components/ReversalCreatedComponent';
import { ReversalExportedComponent } from './history-components/ReversalExportedComponent';
import { SepaTransferGeneratedComponent } from './history-components/SepaTransferGeneratedComponent';
import { SingleBookingUpdatedComponent } from './history-components/SingleBookingUpdatedComponent';
import { SplitBookingUpdatedComponent } from './history-components/SplitBookingUpdatedComponent';
import { SubstituteAddedComponent } from './history-components/SubstituteAddedComponent';
import { SubstituteRemovedComponent } from './history-components/SubstituteRemovedComponent';
import { UserApprovalWorkflowStepComponent } from './history-components/UserApprovalWorkflowStepComponent';
import { WorkflowAppliedComponent } from './history-components/WorkflowAppliedComponent';
import { WorkflowStepSkippedComponent } from './history-components/WorkflowStepSkippedComponent';
import { SupportedEvent, mapWithSupportedEventKeys } from './types';
import { DocumentClassifiedComponent } from './history-components/DocumentClassifiedComponent';

/**
 * Mapping between each Document Event (activity) and the corresponding components to render
 */
export const activityTypeToHistoryComponent = mapWithSupportedEventKeys({
  ApproverExtractedEvent: {
    item: History.System,
    content: ApproverExtractedComponent,
  },
  DocumentCommentEvent: { item: History.User, content: CommentComponent },
  DocumentFileReplacedEvent: {
    item: History.System,
    content: DocumentFileReplaced,
  },
  DocumentTransactionLinkedEvent: {
    item: History.User,
    content: DocumentTransactionLinkedComponent,
    source: ACTIVITY_SOURCE.TRANSACTION,
  },
  DocumentTransactionUnlinkedEvent: {
    item: History.User,
    content: DocumentTransactionUnlinkedComponent,
    source: ACTIVITY_SOURCE.TRANSACTION,
  },
  DocumentTransactionCommentCreatedEvent: {
    item: History.User,
    content: CommentComponent,
    source: ACTIVITY_SOURCE.TRANSACTION,
  },
  ContactSupplierSendEmailEvent: {
    item: History.User,
    content: ContactSupplierComponent,
    collapsible: true,
  },
  DocumentTransactionStatusUpdatedEvent: {
    item: History.User,
    content: DocumentTransactionStatusUpdatedComponent,
    source: ACTIVITY_SOURCE.TRANSACTION,
  },
  DocumentTransactionReconciledEvent: {
    item: History.User,
    content: DocumentTransactionReconciledComponent,
    source: ACTIVITY_SOURCE.TRANSACTION,
    collapsible: true,
  },
  ContactExtractionEvent: {
    item: History.System,
    content: ContactExtractedComponent,
  },
  CostCenterExtractionEvent: {
    item: History.System,
    content: CostCenterExtractedComponent,
  },
  InvalidEInvoiceDetectedEvent: {
    item: History.System,
    content: InvalidEInvoiceDetectedComponent,
  },
  MetaDataExtractionEvent: {
    item: History.System,
    content: MetaExtractedComponent,
    collapsible: true,
  },
  ApprovedEvent: { item: History.User, content: DocumentApprovedComponent },
  RejectedEvent: {
    item: History.User,
    content: DocumentRejectedComponent,
  },
  ExportedEvent: {
    item: History.Neutral,
    content: ExportedComponent,
    collapsible: false,
  },
  ProvisionExportedEvent: {
    item: History.System,
    content: ProvisionExportedComponent,
    collapsible: false,
  },
  ProvisionCreatedEvent: {
    item: History.System,
    content: ProvisionCreatedComponent,
    collapsible: false,
  },
  ProvisionDeletedEvent: {
    item: History.System,
    content: ProvisionDeletedComponent,
    collapsible: false,
  },
  ReversalCreatedEvent: {
    item: History.System,
    content: ReversalCreatedComponent,
    collapsible: false,
  },
  ReversalExportedEvent: {
    item: History.System,
    content: ReversalExportedComponent,
    collapsible: false,
  },
  DocumentResetByCandis: {
    item: History.System,
    content: DocumentResetByCandisComponent,
  },
  DocumentResetManualEvent: {
    item: History.User,
    content: DocumentResetManualComponent,
  },
  DocumentResetUserDeactivationEvent: {
    item: History.User,
    content: DocumentResetUserDeactivationComponent,
    collapsible: true,
  },
  DocumentResetUserRoleChangedEvent: {
    item: History.User,
    content: DocumentResetUserRoleChangedComponent,
    collapsible: true,
  },
  FileUploadedByEmailEvent: {
    item: History.Email,
    content: FileUploadByEmailComponent,
  },
  FileUploadedEvent: { item: History.User, content: FileUploadComponent },
  RequestApprovalEvent: {
    item: History.User,
    content: RequestApprovalWithSubstituteEvent,
    collapsible: (event: SupportedEvent) => {
      if (event.__typename === 'RequestApprovalEvent') {
        return (event.approvers && event.approvers.length > 1) ?? false;
      }

      return false;
    },
  },
  DocumentImportedByMigrationEvent: {
    item: History.Neutral,
    content: DocumentImportedbyMigrationComponent,
    collapsible: true,
  },
  UserApprovalDocumentWorkflowStep: {
    item: History.User,
    content: UserApprovalWorkflowStepComponent,
  },
  WorkflowTemplateAppliedToDocumentEvent: {
    item: History.System,
    content: WorkflowAppliedComponent,
  },
  WorkflowStepSkippedEvent: {
    item: History.System,
    content: WorkflowStepSkippedComponent,
    collapsible: true,
  },
  DocumentSepaTransferGeneratedEvent: {
    item: History.User,
    content: SepaTransferGeneratedComponent,
  },
  DocumentPaymentInfoUpdatedEvent: {
    item: History.User,
    content: DocumentPaymentInfoUpdated,
    collapsible: true,
  },
  FileAttachedToDocumentEvent: {
    item: History.User,
    content: FileAttachedComponent,
  },
  AttachmentDeletedFromDocumentEvent: {
    item: History.User,
    content: AttachmentDeletedComponent,
  },
  AttachmentDetachedFromDocumentEvent: {
    item: History.User,
    content: AttachmentDetachedComponent,
  },
  DocumentConvertedToAttachmentEvent: {
    item: History.User,
    content: DocumentConvertedToAttachmentComponent,
  },
  AttachmentConvertedToDocumentEvent: {
    item: History.User,
    content: AttachmentConvertedToDocumentComponent,
  },
  SingleBookingUpdatedEvent: {
    item: History.User,
    content: SingleBookingUpdatedComponent,
    collapsible: true,
  },
  SplitBookingUpdatedEvent: {
    item: History.User,
    content: SplitBookingUpdatedComponent,
    collapsible: true,
  },
  SubstituteAddedEvent: {
    item: History.System,
    content: SubstituteAddedComponent,
  },
  SubstituteRemovedEvent: {
    item: History.System,
    content: SubstituteRemovedComponent,
  },
  DocumentStoredAsEcmDocumentEvent: {
    item: History.User,
    content: EcmEventsContainer,
    collapsible: true,
  },
  DocumentUpdatedAsEcmDocumentEvent: {
    item: History.User,
    content: EcmEventsContainer,
    collapsible: true,
  },
  DocumentRelationshipCreatedEvent: {
    item: History.User,
    content: DocumentRelationshipCreatedComponent,
    collapsible: true,
    skip: (e: DocumentRelationshipCreatedEventDataFragment) => {
      if (!e.linkedDocuments) return true;

      return e.linkedDocuments.every(
        doc => doc?.__typename === 'RelatedDocumentError'
      );
    },
  },
  DocumentRelationshipRemovedEvent: {
    item: History.User,
    content: DocumentRelationshipRemovedComponent,
    skip: (e: DocumentRelationshipRemovedEventDataFragment) => {
      if (!e.unlinkedDocument) return true;

      return e.unlinkedDocument.__typename !== 'RelatedDocument';
    },
  },
  DocumentResetTransactionUnLinkedEvent: {
    item: History.System,
    content: DocumentResetTransactionUnlinkedComponent,
  },
  DocumentResetTransactionLinkedEvent: {
    item: History.System,
    content: DocumentResetTransactionLinkedComponent,
  },
  DocumentResetTransactionAmountUpdatedEvent: {
    item: History.System,
    content: DocumentResetTransactionAmountUpdatedComponent,
  },
  PurchaseOrderLinkedEvent: {
    item: History.User,
    content: PurchaseOrderEventComponent,
  },
  PurchaseOrderUnlinkedEvent: {
    item: History.User,
    content: PurchaseOrderEventComponent,
  },
  DocumentTagUpdatedEvent: {
    item: History.User,
    content: DocumentTagUpdatedEventComponent,
  },
  DocumentGrossNetTaxRoundingAmountChangedEvent: {
    item: History.User,
    content: DocumentGrossNetTaxRoundingChangedComponent,
    collapsible: true,
  },
  AccountingAreaUpdatedEvent: {
    item: History.User,
    content: DocumentAccountingAreaUpdatedEventComponent,
  },
  DocumentAssociationsCreatedEvent: {
    item: History.User,
    content: DocumentAssociationsCreatedEventComponent,
  },
  DocumentAssociationsRemovedEvent: {
    item: History.User,
    content: DocumentAssociationsRemovedEventComponent,
  },
  DocumentConvertedEvent: {
    item: History.User,
    content: DocumentConvertedComponent,
  },
  DocumentClassifiedEvent: {
    item: History.System,
    content: DocumentClassifiedComponent,
  },
});
export const supportedEventKeys = Object.keys(activityTypeToHistoryComponent);

import { Grid, Icon } from '@candisio/design-system';
import { motion } from 'motion/react';
import { transition, variants } from '../const';

const MotionGrid = motion.create(Grid);

export const AdvancedSearchFieldSearchIcon = () => {
  return (
    <MotionGrid
      position="absolute"
      right="0"
      alignContent="center"
      justifySelf="end"
      padding="space10"
      animate="visible"
      transition={transition}
      variants={variants}
    >
      <Icon icon="search" size="space14" />
    </MotionGrid>
  );
};

import { Grid, Tag, Tooltip, useTooltip } from '@candisio/design-system';
import { CardType } from 'generated-types/graphql.types';
import { motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { RecurringPayment } from 'views/CreditCards/hooks/useRecurringPaymentData';

const MotionTooltip = motion.create(Tooltip);

export const PendingTag = ({
  isOpen,
  card,
}: {
  isOpen: boolean;
  card: RecurringPayment['linkedCard']['card'];
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { tooltipProps, tooltipRef, triggerProps, triggerRef } = useTooltip({
    isOpen,
    passiveTrigger: true,
  });

  if (card.type !== CardType.Physical) {
    return null;
  }

  return (
    <Grid {...triggerProps} ref={triggerRef}>
      <Tag color="yellow" variant="secondary">
        {t('recurringPaymentList.pending')}
      </Tag>
      {isOpen && (
        <MotionTooltip
          maxWidth="320px"
          // We cast as any because some props from tooltip give type errors
          {...(tooltipProps as any)}
          ref={tooltipRef}
          transition={{ delay: 1 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {t('recurringPaymentList.pendingTooltip', {
            cardholder: card.cardholder?.name,
          })}
        </MotionTooltip>
      )}
    </Grid>
  );
};

import { PDFDetails } from 'components/DocumentViewer/utils';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  AttachFilesMutationResult,
  useAttachFilesMutation,
  useDocumentAttachmentUploadUrlMutation,
} from 'generated-types/graphql.types';
import {
  getInboxDocumentNavigationQueries,
  getInboxInvoiceDocumentsNavigationQueries,
} from 'hooks/useFetchDocNavigationData/queries';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { uploadFile } from 'utils/upload';
import { isSupportedFile } from 'views/AppContainer/components/Header/utils';
import { documentHistoryQuery } from 'views/queries';
import { documentQueries } from '../queries';
import { useEcm } from 'orgConfig/ecm/useEcm';

export const MAX_FILE_NUMBER_DATEV = 47;

export const MAX_FILES_SIZE_MB = 19;
export const MAX_FILES_SIZE = MAX_FILES_SIZE_MB * 1024 * 1024;

const fileWasAttached = (
  fileId: string,
  document: AttachFilesMutationResult['data']
): boolean => {
  const attachments = document?.attachFiles?.attachments ?? [];
  const documentAttachmentIds = attachments.map(({ id }) => id);

  return documentAttachmentIds.includes(fileId);
};

export const useFilesSelected = (
  attachments: PDFDetails[] | undefined,
  documentFile: PDFDetails | undefined,
  documentId?: string
) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();

  const [searchParams] = useSearchParams();
  const currentDocumentCursor = searchParams.get('cursor');

  const { useNewInboxQuery } = useEcm();

  // @TODO define handle upload and use that implementation here and in DocumentViewer
  const [attachFile, { loading: isAttachingFiles }] = useAttachFilesMutation({
    refetchQueries: [
      {
        query: documentQueries.forDraftForm,
        variables: { id: documentId },
      },
      {
        query: documentHistoryQuery,
        variables: { id: documentId },
      },
      {
        query: useNewInboxQuery
          ? getInboxInvoiceDocumentsNavigationQueries
          : getInboxDocumentNavigationQueries,
        variables: { cursor: currentDocumentCursor },
      },
    ],
  });

  const [documentAttachmentUploadUrl] =
    useDocumentAttachmentUploadUrlMutation();

  const handleUploadAttachment = useCallback(
    async (file: File) => {
      if (!documentId) return;
      if (!isSupportedFile(file, false)) {
        error(t('uploads.errors.wrongFileType'));

        return;
      }

      if (file.size > MAX_FILES_SIZE) {
        error(t('uploads.errors.totalFilesSize', { size: MAX_FILES_SIZE_MB }));

        return;
      }

      // request url to upload file to S3
      const { data } = await documentAttachmentUploadUrl({
        variables: {
          options: {
            contentType: file.type,
            fileName: file.name,
            size: file.size,
          },
        },
      });

      if (!data?.documentAttachmentUploadUrl.id) {
        error(t('uploads.errors.baseError'));

        return;
      }

      await uploadFile(
        file,
        data.documentAttachmentUploadUrl.postOptions || [],
        data.documentAttachmentUploadUrl.url
      );

      const { errors, data: attachFileData } = await attachFile({
        variables: {
          input: {
            documentId,
            fileIds: [data.documentAttachmentUploadUrl.id],
          },
        },
      });

      // XXX We should modify/create a new mutation to return which file ids
      // were attached and which ones were not (any why).
      const successfullyAttached = fileWasAttached(
        data.documentAttachmentUploadUrl.id,
        attachFileData
      );

      // Keep error messages around slightly longer, in case many file are attached
      // the error message might disappear before its seen.
      const ERROR_MSG_TIMEOUT = 10;

      if (successfullyAttached) {
        success(
          t('document.attachments.success.attachedFileFromDisk', {
            fileName: file.name,
          })
        );

        return;
      }

      if (!errors?.length) {
        error(
          t('document.attachments.errors.couldNotAttachFile', {
            fileName: file.name,
          }),
          ERROR_MSG_TIMEOUT
        );

        return;
      }

      error(t('uploads.errors.baseError'), ERROR_MSG_TIMEOUT);
    },
    [attachFile, documentAttachmentUploadUrl, documentId, error, success, t]
  );

  const onAttachFiles = useCallback(
    (selectedFiles: File[]) => {
      // size of currently selected files
      let selectedFilesSize = 0;
      // size of currently attached files
      let attachedFilesSize = 0;

      selectedFiles.forEach(file => {
        selectedFilesSize += file.size;
      });

      attachments?.forEach(attachment => {
        attachedFilesSize += attachment.size ?? 0;
      });

      const totalSize =
        (documentFile?.size ?? 0) + selectedFilesSize + attachedFilesSize;

      if (totalSize > MAX_FILES_SIZE) {
        error(t('uploads.errors.totalFilesSize', { size: MAX_FILES_SIZE_MB }));

        return;
      }

      const filesCount = selectedFiles.length + (attachments?.length ?? 0);

      // DATEV has a restriction of max 50 files to upload:
      //    XML with document data
      //  + document
      //  + summary (audit trail)
      //  + 47 attachments
      // also consider existing attachments
      if (filesCount > MAX_FILE_NUMBER_DATEV) {
        error(t('uploads.errors.numberOfFiles'));

        return;
      }

      // TODO: call onUploadAttachment once with all attached files
      // instead of forEach
      selectedFiles.forEach(handleUploadAttachment);
    },
    [attachments, documentFile?.size, error, handleUploadAttachment, t]
  );

  return useMemo(
    () => ({ onAttachFiles, isAttachingFiles }),
    [onAttachFiles, isAttachingFiles]
  );
};

import { DocumentTableColumnKeys } from 'components/DocumentsTable/types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import {
  useShowAccountingAreaField,
  useShowArtistSocialInsuranceCodeField,
  useShowCostCenterField,
  useShowCostObjectField,
  useShowCreditCardPaymentField,
  useShowDeliveryDateField,
  useShowExtraCostInfoField,
  useShowGeneralLedgerAccountField,
  useShowInvoiceCorrectionField,
  useShowPostingTextField,
  useShowPurchaseOrderNumberField,
} from 'views/utils/useShouldShowField';

type GetAvailableColumnsParams = {
  columns: Array<DocumentTableColumnKeys>;
  showCostCenterColumn: boolean;
  showCostObjectColumn: boolean;
  showExtraCostInfoColumn: boolean;
  showGeneralLedgerAccountColumn: boolean;
  showArtistSocialInsuranceCodeColumn: boolean;
  showCreditCardPaymentColumn: boolean;
  showDeliveryDateColumn: boolean;
  showPurchaseOrderColumn: boolean;
  showInvoiceCorrectionColumn: boolean;
  showPostingTextColumn: boolean;
  showGoodsReceiptsCountColumn: boolean;
  showAccountingAreaColumn: boolean;
  showSapPONumber: boolean;
  showAccountsPayableNumber: boolean;
};

export const getAvailableDocumentsColumns = ({
  columns,
  showCostCenterColumn,
  showCostObjectColumn,
  showExtraCostInfoColumn,
  showGeneralLedgerAccountColumn,
  showCreditCardPaymentColumn,
  showArtistSocialInsuranceCodeColumn,
  showDeliveryDateColumn,
  showInvoiceCorrectionColumn,
  showPurchaseOrderColumn,
  showPostingTextColumn,
  showGoodsReceiptsCountColumn,
  showAccountingAreaColumn,
  showSapPONumber,
  showAccountsPayableNumber,
}: GetAvailableColumnsParams) => {
  const columnConditions: Record<string, boolean> = {
    costCenter: showCostCenterColumn,
    costObject: showCostObjectColumn,
    extraCostInfo: showExtraCostInfoColumn,
    artistSocialInsuranceCode: showArtistSocialInsuranceCodeColumn,
    deliveryDate: showDeliveryDateColumn,
    isInvoiceCorrection: showInvoiceCorrectionColumn,
    postingText: showPostingTextColumn,
    generalLedgerAccount: showGeneralLedgerAccountColumn,
    creditCardPayment: showCreditCardPaymentColumn,
    purchaseOrderNumber: showPurchaseOrderColumn,
    goodsReceiptNumber: showGoodsReceiptsCountColumn,
    accountingArea: showAccountingAreaColumn,
    sapPurchaseOrderNumber: showSapPONumber,
    accountsPayableNumber: showAccountsPayableNumber,
  };

  return columns.filter(column =>
    column in columnConditions ? columnConditions[column] : true
  );
};

export const useAvailableDocumentsColumns = (
  columns: Array<DocumentTableColumnKeys>
) => {
  const showCostCenterColumn = useShowCostCenterField();
  const showCostObjectColumn = useShowCostObjectField();
  const showExtraCostInfoColumn = useShowExtraCostInfoField();
  const showGeneralLedgerAccountColumn = useShowGeneralLedgerAccountField();
  const showCreditCardPaymentColumn = useShowCreditCardPaymentField();
  const showArtistSocialInsuranceCodeColumn =
    useShowArtistSocialInsuranceCodeField();

  const showDeliveryDateColumn = useShowDeliveryDateField();
  const showPurchaseOrderColumn = useShowPurchaseOrderNumberField();
  const showInvoiceCorrectionColumn = useShowInvoiceCorrectionField();
  const showPostingTextColumn = useShowPostingTextField();
  const showAccountingAreaColumn = useShowAccountingAreaField({
    isAccountingAreaOnDocument: false,
  });

  const showSapPONumber = useSap().shouldUseSapPurchaseOrder;

  const showGoodsReceiptsCountColumn = useSap().shouldUseSapPurchaseOrder;

  const showAccountsPayableNumber = useCandisFeatureFlags(
    FEATURE_FLAGS.consistentSortAndFiltering
  );

  return getAvailableDocumentsColumns({
    columns,
    showCostCenterColumn,
    showCostObjectColumn,
    showExtraCostInfoColumn,
    showGeneralLedgerAccountColumn,
    showCreditCardPaymentColumn,
    showArtistSocialInsuranceCodeColumn,
    showDeliveryDateColumn,
    showPurchaseOrderColumn,
    showInvoiceCorrectionColumn,
    showPostingTextColumn,
    showGoodsReceiptsCountColumn,
    showAccountingAreaColumn,
    showSapPONumber,
    showAccountsPayableNumber,
  });
};

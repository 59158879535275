import {
  Button,
  Card,
  Flex,
  Grid,
  Icon,
  IconProps,
  Image,
  Separator,
  Text,
} from '@candisio/design-system';
import { ColorProps } from '@candisio/design-system';
import accountingIcon from 'assets/credit-cards/icon-accouting.svg';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CardLimitRequestInfoBoxCardManager } from '../UpdateCardLabelAndLimit/CardLimitRequestInfoBoxCardManager';
import { CardRequestInfoBox } from '../UpdateCardLabelAndLimit/CardRequestInfoBox';
import { AccountingInfoBox } from './AccountingInfoBox';

interface IconSetting {
  icon: {
    icon: IconProps['icon'];
    color: IconProps['color'];
  };
  text: {
    color: ColorProps['color'];
    translationKey: string;
  };
}

export interface AccountingFormHeaderProps {
  hasAccountingData: boolean;
  hasPendingLimitRequest: boolean;
  isNewCardRequest: boolean;
  onClick: () => void;
}

const accountingDataIconSettings: Record<
  'newCardRequest' | 'default',
  IconSetting
> = {
  newCardRequest: {
    icon: {
      icon: 'warning',
      color: 'yellow500',
    },
    text: {
      color: 'yellow500',
      translationKey: 'creditCardFormDataPreview.accounting.checkSettings',
    },
  },
  default: {
    icon: {
      icon: 'checkCircle',
      color: 'green500',
    },
    text: {
      color: 'green500',
      translationKey: 'cardManagerView.status.active',
    },
  },
};

export const AccountingFormHeader = ({
  hasAccountingData,
  hasPendingLimitRequest,
  isNewCardRequest,
  onClick,
}: AccountingFormHeaderProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { isCardManager } = useUserRoles();

  const isInfoForCardManagerVisible =
    (hasPendingLimitRequest || isNewCardRequest) && isCardManager;

  const accountingDataIconSetting = isNewCardRequest
    ? accountingDataIconSettings.newCardRequest
    : accountingDataIconSettings.default;

  return (
    <Grid gap="space16">
      <Button
        justifySelf="start"
        icon="arrowLeft"
        variant="tertiary"
        onClick={onClick}
      >
        {t('ccRequestForm.goBackCTA')}
      </Button>
      {!isCardManager && <AccountingInfoBox />}
      {isInfoForCardManagerVisible && (
        <>
          {hasPendingLimitRequest ? (
            <CardLimitRequestInfoBoxCardManager />
          ) : (
            <CardRequestInfoBox />
          )}
        </>
      )}
      <Card padding={0} background="gray100" borderBottomRadius="none">
        <Grid
          templateColumns="auto 1fr"
          padding="space16"
          gap="space12"
          alignItems="center"
        >
          <Image src={accountingIcon} alt="accounting icon" />
          <Flex alignItems="baseline" gap="space8">
            <Text fontSize="large">
              {t('creditCardFormDataPreview.accounting.title')}
            </Text>
            {hasAccountingData ? (
              <Grid templateColumns="auto 1fr" gap="space4" alignSelf="center">
                <Icon
                  icon={accountingDataIconSetting.icon.icon}
                  color={accountingDataIconSetting.icon.color}
                  size="space18"
                />
                <Text color={accountingDataIconSetting.text.color}>
                  {t(accountingDataIconSetting.text.translationKey)}
                </Text>
              </Grid>
            ) : (
              <Text>{t('creditCardFormDataPreview.accounting.subtitle')}</Text>
            )}
          </Flex>
        </Grid>
        <Separator />
      </Card>
    </Grid>
  );
};

import { Avatar } from '@candisio/design-system';
import { User } from 'generated-types/graphql.types';

interface UserIconNotificationProps {
  user: Pick<User, 'id' | 'name' | 'avatarUrl'>;
}

export const UserIconNotification = ({ user }: UserIconNotificationProps) => {
  return (
    <Avatar
      name={user.name}
      img={user.avatarUrl ?? undefined}
      hasTooltip={false}
      size="medium"
    />
  );
};

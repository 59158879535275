import {
  PerDiemStep,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { UseFormReturn } from 'react-hook-form';
import { ReimbursementItemsFormOutput } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { ReimbursementItemFooter } from './ReimbursementItemFooter';
import { ReimbursementItemLayout } from './ReimbursementItemLayout';
import { BooleanMap } from './utils/useReimbursementItemsFormActions';
import { FieldsOptions } from './utils/useFieldsOptions';
import { generatePerDiemFormFieldNames } from './utils/generatePerDiemFieldNames';
import { Icon } from '@candisio/design-system';
import { PerDiemItemDetails } from 'views/Reimbursement/hooks/usePerDiemItemDetails';
import { SegmentsStep } from './PerDiem/SegmentStep';
import { DaysStep } from './PerDiem/DaysStep';

export const perDiemMealbuttons = [
  {
    id: 'breakfast',
    displayValue: <Icon icon="breakfast" size="space20" />,
  },
  {
    id: 'lunch',
    displayValue: <Icon icon="lunch" size="space20" />,
  },
  {
    id: 'dinner',
    displayValue: <Icon icon="dinner" size="space20" />,
  },
];

export const perDiemMealHeaderButtons = [
  {
    id: 'breakfast',
    displayValue:
      'reimbursementView.middleSection.form.perDiem.days.headerButtons.breakfast',
  },
  {
    id: 'lunch',
    displayValue:
      'reimbursementView.middleSection.form.perDiem.days.headerButtons.lunch',
  },
  {
    id: 'dinner',
    displayValue:
      'reimbursementView.middleSection.form.perDiem.days.headerButtons.dinner',
  },
];

interface PerDiemFormProps {
  isExpenseFormEditable: boolean;
  reimbursementStatus?: ReimbursementCaseStatus;
  onRemoveForm: (index: number, expenseId: string) => () => Promise<void>;
  onCleareableField: (field?: string) => () => void;
  onUpdatePerDiem: (index: number) => () => Promise<void>;
  onInvoiceChange: (file: File, index: number) => Promise<void>;
  onUpdatePerDiemStep: (index: number) => Promise<void>;
  formIndex: number;
  isDeletingForm: BooleanMap;
  formMethods: UseFormReturn<ReimbursementItemsFormOutput>;
  isCreatingInvoice: BooleanMap;
  fieldOptions: FieldsOptions;
  clearableField: string | undefined;
  reimbursementItemId: string;
  canManageReimbursementItems: boolean;
  isLoadingPerDiemItemDetails: boolean;
  perDiemItemDetails?: PerDiemItemDetails;
  isAddSegmentPending: boolean;
  isUpdatePerDiemStepPending: boolean;
  onAddPerDiemSegment: (index: number) => () => Promise<void>;
  onUpdatePerDiemDays: (index: number) => () => Promise<void>;
}

export const PerDiemForm = ({
  reimbursementStatus,
  clearableField,
  reimbursementItemId,
  formIndex,
  formMethods,
  isDeletingForm,
  fieldOptions,
  onCleareableField,
  onRemoveForm,
  canManageReimbursementItems,
  onUpdatePerDiem,
  onAddPerDiemSegment,
  onUpdatePerDiemStep,
  onUpdatePerDiemDays,
  isAddSegmentPending,
  perDiemItemDetails,
  isUpdatePerDiemStepPending,
  isExpenseFormEditable,
  isLoadingPerDiemItemDetails,
}: PerDiemFormProps) => {
  const { watch, getValues, trigger } = formMethods;
  const { isExcluded: isExcludedFieldOptions } = fieldOptions;
  const fields = generatePerDiemFormFieldNames(formIndex);
  const itemStatus = getValues(fields.itemStatus);

  const [isExcluded, currentStep] = watch([
    fields.isExpenseExcluded,
    fields.currentStep,
  ]);

  const isExcludedInfoVisible =
    isExcluded && isExcludedFieldOptions.showInfoIsExcluded;

  return (
    <ReimbursementItemLayout
      reimbursementItemType="perDiem"
      expenseId={reimbursementItemId}
      isExcluded={isExcluded}
      reimbursementStatus={reimbursementStatus}
      itemStatus={itemStatus}
      totalAmount={perDiemItemDetails?.totalAmount}
      reason={undefined}
      footer={
        <ReimbursementItemFooter
          canManageReimbursementItems={canManageReimbursementItems}
          expenseId={reimbursementItemId}
          formIndex={formIndex}
          isDeletingForm={isDeletingForm}
          isExcludedFieldOptions={isExcludedFieldOptions}
          isExcludedInfoVisible={isExcludedInfoVisible}
          onRemoveForm={onRemoveForm}
          onUpdateExpense={onUpdatePerDiem}
          onTriggerValidation={trigger}
        />
      }
    >
      {currentStep === PerDiemStep.Segments ? (
        <SegmentsStep
          clearableField={clearableField}
          formIndex={formIndex}
          formMethods={formMethods}
          isAddSegmentPending={isAddSegmentPending}
          isExcluded={isExcluded}
          isUpdatePerDiemStepPending={isUpdatePerDiemStepPending}
          onAddPerDiemSegment={onAddPerDiemSegment}
          onCleareableField={onCleareableField}
          onUpdatePerDiem={onUpdatePerDiem}
          onUpdatePerDiemStep={onUpdatePerDiemStep}
          isReimbursementItemFormEditable={isExpenseFormEditable}
        />
      ) : (
        <DaysStep
          formIndex={formIndex}
          formMethods={formMethods}
          onUpdatePerDiemDays={onUpdatePerDiemDays}
          perDiemItemDetails={perDiemItemDetails}
          isLoadingPerDiemItemDetails={isLoadingPerDiemItemDetails}
          totalAmount={perDiemItemDetails?.totalAmount}
        />
      )}
    </ReimbursementItemLayout>
  );
};

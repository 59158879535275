import {
  Text,
  Tooltip,
  TruncatedText,
  useTooltip,
} from '@candisio/design-system';
import { NotificationProps } from '../getNotificationInfo';
import { DateFormatters } from 'utils/date_formatter';
import { Trans } from 'providers/LocaleProvider';
import { Header } from './Header/Header';

export const ReimbursementCaseApprovedInAppNotification = ({
  approvedAt,
  reimbursementCaseTitle,
  approvedBy,
}: NotificationProps<'ReimbursementCaseApprovedInAppNotification'>) => {
  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });
  const approvedByName = approvedBy.name;
  return (
    <div className="grid grid-rows-[auto 1fr] gap-1" style={{ height: '100%' }}>
      <Header>
        <Trans
          i18nKey="notifications:events.reimbursementApproved.header"
          values={{
            approvedByName,
            timeAgo: DateFormatters.fromNow(new Date(approvedAt)),
          }}
        >
          <TruncatedText color="gray500" maxWidth="15ch">
            {approvedByName}
          </TruncatedText>
          <Text fontSize="small" color="gray500">
            approved reimbursement
          </Text>
          <Text
            fontSize="small"
            color="gray500"
            {...triggerProps}
            ref={triggerRef}
          >
            3 minutes ago
          </Text>
        </Trans>
      </Header>

      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {DateFormatters.compactDatetime(new Date(approvedAt))}
        </Tooltip>
      )}

      <Text color="gray800">{reimbursementCaseTitle}</Text>
    </div>
  );
};

export const ReimbursementCaseApprovedInAppNotificationToast = ({
  reimbursementCaseTitle,
  approvedBy,
}: NotificationProps<'ReimbursementCaseApprovedInAppNotification'>) => {
  const approvedByName = approvedBy.name;

  return (
    <div className="grid grid-rows-[auto 1fr] gap-1" style={{ height: '100%' }}>
      <Header>
        <Trans
          i18nKey="notifications:events.reimbursementApproved.toast.header"
          values={{
            approvedByName,
          }}
        >
          {/* @ts-expect-error we have to surpress typescript here because TruncatedText expects a string */}
          <TruncatedText color="gray800" maxWidth="15ch">
            {{ approvedByName }}
          </TruncatedText>
          <Text color="gray500">approved reimbursement</Text>
        </Trans>
      </Header>

      <Text color="gray800" fontSize="basic">
        {reimbursementCaseTitle}
      </Text>
    </div>
  );
};

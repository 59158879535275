import { Swimlane } from 'components/Swimlane/Swimlane';
import { SwimlaneTiles } from 'components/Swimlane/SwimlaneTiles';
import { ReimbursementInitialCTATile } from './content/ReimbursementInitialCTATile';
import { PromoExpenseExplained } from './promos/PromoExpenseExplained';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import {} from 'generated-types/graphql.types';

const PROMO_TILE_COUNT = 2;

export const ReimbursementSummaryCreate = () => {
  const { isPromotionSeen, setPromotionIsSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.EXPENSE_EXPLAINED_PROMO
  );

  return (
    <Swimlane data-cy="summaryInitialCTA">
      <SwimlaneTiles
        contentTileCount={2}
        promoTileCount={isPromotionSeen ? undefined : PROMO_TILE_COUNT}
      >
        {({ isPromoTileVisible }) => {
          const showPromoTile = !isPromotionSeen && isPromoTileVisible;

          return (
            <>
              <ReimbursementInitialCTATile />
              {showPromoTile && (
                <PromoExpenseExplained onClose={setPromotionIsSeen} />
              )}
            </>
          );
        }}
      </SwimlaneTiles>
    </Swimlane>
  );
};

import { Button, Flex, Link, defaultTheme } from '@candisio/design-system';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { queryParameter } from 'components/Table/consts';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useDeferredValue } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { SearchScopeSelect } from './SearchScopeSelect';
import { SearchTypeSelect } from './SearchTypeSelect';
import { useSearchFilter } from './useSearchFilter';
import { SearchScope, SearchType } from 'generated-types/graphql.types';

interface AdvancedSearchFieldFilterOverlayProps {
  onApply: () => void;
  onShowPromoModal: () => void;
}

export function AdvancedSearchFieldFilterOverlay({
  onApply,
}: AdvancedSearchFieldFilterOverlayProps) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const [search] = useSearchParams();
  const query = useDeferredValue(search.get(queryParameter) ?? '');

  const {
    searchType,
    searchScope,
    setSearchType,
    setSearchScope,
    applySearchInputFilters,
  } = useSearchFilter();

  const applyFilters = () => {
    applySearchInputFilters();
    onApply();
  };

  const searchTypeItems = [
    {
      id: SearchType.PrefixMatch,
      name: t('searchFilter.popover.fields.searchType.prefixSearch'),
    },
    {
      id: SearchType.ExactMatch,
      name: t('searchFilter.popover.fields.searchType.exactSearch'),
    },
  ];

  const searchScopeItems = [
    {
      id: SearchScope.EntireDocument,
      name: t('searchFilter.popover.fields.searchScope.fullTextSearch'),
    },
    {
      id: SearchScope.DataFields,
      name: t('searchFilter.popover.fields.searchScope.fieldSearch'),
    },
  ];

  return (
    <Flex direction="column" gap="space12" width="22.25rem">
      <Flex direction="column" gap="space14">
        <SearchTypeSelect
          items={searchTypeItems}
          query={query}
          value={searchType}
          onChange={setSearchType}
        />
        <SearchScopeSelect
          items={searchScopeItems}
          value={searchScope}
          onChange={setSearchScope}
        />
      </Flex>
      <FilterActions applyFilters={applyFilters} />
    </Flex>
  );
}

function FilterActions({ applyFilters }: { applyFilters: () => void }) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <Flex gap="space20" justifyContent="space-between">
      <Flex gap="space20" alignItems="center">
        <Link
          href={t('searchFilter.popover.help.url')}
          fontSize="small"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('searchFilter.popover.help.text')}
        </Link>
        <IntercomLink
          id="search-filter-overlay-intercom-link"
          variant="noIcon"
          style={{
            fontSize: defaultTheme.fontSizes.small,
            lineHeight: defaultTheme.lineHeights.body,
          }}
        >
          {t('searchFilter.popover.feedback.text')}
        </IntercomLink>
      </Flex>

      <Button size="small" color="blue" onClick={applyFilters}>
        {t('searchFilter.popover.actions.apply')}
      </Button>
    </Flex>
  );
}

import { usePermissionsForMembership } from 'hooks/usePermissionsForMembership';

/** Interface representing the overall return value of the useUserPermissions hook */
interface PermissionsForCreditCards {
  canManageCards: boolean;
  canIssueCards: boolean;
}

/**
 * Custom hook to retrieve and manage user permissions.
 * It checks if the user has permissions to manage credit cards based on organization settings and feature flags.
 * @returns {PermissionsForCreditCards} An object containing user permissions.
 */
export const usePermissionsForCreditCards = (): PermissionsForCreditCards => {
  const permissions = usePermissionsForMembership();

  return {
    canManageCards: permissions?.['organization.manage_cards'] ?? false,
    canIssueCards: permissions?.['organization.issue_new_card'] ?? false,
  };
};

import { Grid } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import {
  HookFormTextField,
  HookFormTextFieldProps,
} from '../../../../components/HookFormFields/HookFormTextField';
import { ContactFormValues } from './contactFormSchema';
import { InfoPopupButton } from 'components/Icons/InfoPopupButton';

/**
 * Customer number field for React Hook Form.
 */
export const CustomerNumberField = (
  props?: Omit<HookFormTextFieldProps<ContactFormValues>, 'name'>
) => {
  const [t] = useTranslation();

  return (
    <Grid
      autoFlow="column"
      alignItems="center"
      templateColumns="1fr auto"
      gap="space14"
    >
      <HookFormTextField<ContactFormValues>
        {...props}
        name="customerNumber"
        inputProps={{
          autoComplete: 'off',
        }}
        label={t('settings.contacts.details.edit.customerNumber.label')}
      />
      <div className="pt-4">
        <InfoPopupButton
          message={t('settings.contacts.details.edit.customerNumber.info')}
        />
      </div>
    </Grid>
  );
};

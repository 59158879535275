/**
 * Error details for project code import history item
 */
import { useMemo } from 'react';
import { Column } from 'react-table';
import {
  Cell,
  Table,
  HeaderProps,
  TruncatedText,
  Flex,
  Grid,
  Text,
} from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import {
  ImportHistoryDetailsData,
  ProjectCodeImportHistoryRouteParams as RouteParams,
} from '../types';
import { ProjectCodeImportHistoryItem } from '../ProjectCodeImportHistoryItem';
import { useParams } from 'react-router-dom-v5-compat';
import { useProjectCodeImportItemError } from './useGetProjectCodeImportItemError';

const getTranslationKey = (columnId: string): string => {
  const prefix = 'projectCodes.importHistory.errorTable.columns.';

  return prefix.concat(columnId);
};

const TableHeader = ({ column }: HeaderProps<ImportHistoryDetailsData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  return (
    <TruncatedText wordBreak="break-all" color="gray500">
      {t(getTranslationKey(column.id))}
    </TruncatedText>
  );
};

export const ProjectCodeImportHistoryErrorTable = () => {
  const params = useParams<RouteParams>();
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const defaultColumn = useMemo(
    (): Partial<Column<ImportHistoryDetailsData>> => ({
      /** @ts-expect-error TODO: React upgrade props types mismatch */
      Header: TableHeader,
      Cell,
      disableFilters: true,
      disableSortBy: true,
    }),
    []
  );

  const columns = useMemo(
    (): Array<Column<ImportHistoryDetailsData>> => [
      {
        accessor: 'code',
        Cell: ({ value }) =>
          value ? (
            <TruncatedText>{value}</TruncatedText>
          ) : (
            <Text>{t('emptyCellPlaceholder')}</Text>
          ),
      },
      {
        accessor: 'name',
        Cell: ({ value }) =>
          value ? (
            <TruncatedText>{value}</TruncatedText>
          ) : (
            <Text>{t('emptyCellPlaceholder')}</Text>
          ),
      },
      {
        accessor: 'error',
      },
    ],
    [t]
  );
  const { importHistoryItem, loading } = useProjectCodeImportItemError(
    params.id
  );

  const tableData = useMemo(
    () => importHistoryItem?.errorData || [],
    [importHistoryItem]
  );

  return (
    <Flex
      direction="column"
      borderTopRadius="basic"
      background="gray100"
      padding={0}
    >
      <Grid padding="space12 space20" alignItems="center">
        <ProjectCodeImportHistoryItem
          showStatus={false}
          data={importHistoryItem.item}
        />
      </Grid>

      <Table<ImportHistoryDetailsData>
        height="100%"
        borderBottomRadius="medium"
        borderTopRadius="none"
        defaultColumn={defaultColumn}
        columns={columns}
        data={tableData}
        isLoading={loading}
      />
    </Flex>
  );
};

import { Button } from '@candisio/design-system';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export const VIEW_SWITCH_DELAY = 1000;

export interface DocumentViewSwitcherProps {
  view?: 'pdf' | 'xml';
  setView?: (view: 'pdf' | 'xml') => void;
}

export const DocumentViewSwitcher = memo(
  ({ view, setView }: DocumentViewSwitcherProps) => {
    const [t] = useTranslation();
    return (
      <div className="flex gap-2">
        <Button
          label={t('document.switcher.pdf.tooltip')}
          onClick={() => setView?.('pdf')}
          variant={view === 'pdf' ? 'secondary' : 'tertiary'}
          size="small"
        >
          {t('document.switcher.pdf.label')}
        </Button>
        <Button
          label={t('document.switcher.xml.tooltip')}
          onClick={() => setView?.('xml')}
          variant={view === 'xml' ? 'secondary' : 'tertiary'}
          size="small"
        >
          {t('document.switcher.xml.label')}
        </Button>
      </div>
    );
  }
);

import { useCountPayableDocumentsQuery } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { MAX_PAYABLE_DOCUMENT_COUNT } from 'views/consts';
import { NavigationIcon } from '../../NavigationIcon';
import { NavIconProps } from '../../../hooks/useNavigationIcons';

export const MenuPayments = (iconProps: NavIconProps) => {
  const { isAdmin, isAccountant } = useUserRoles();

  const { data: paymentsCount, loading: paymentsCountLoading } =
    useCountPayableDocumentsQuery({
      skip: !(isAdmin || isAccountant),
      fetchPolicy: 'cache-first',
    });

  const payableDocumentsCount = paymentsCount?.countPayableDocuments.count
    ? Math.min(
        paymentsCount?.countPayableDocuments.count,
        MAX_PAYABLE_DOCUMENT_COUNT
      )
    : undefined;

  return (
    <NavigationIcon
      {...iconProps}
      count={payableDocumentsCount}
      countLoading={paymentsCountLoading}
    />
  );
};

import { SwimlaneTile } from 'components/Swimlane/SwimlaneTile';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { usePath } from 'hooks/usePath';
import { NavLink } from 'react-router-dom-v5-compat';
import { CardHolderCCInsightsWidgetData } from 'views/CreditCards/hooks/useTransactionsInsights';
import {
  DateOptionMissingInvoicesWidget,
  DateRange,
} from '../../MissingInvoicesWidget/utils';
import styles from './MissingInvoiceContentTile.module.css';
import { MissingInvoiceEmail } from './MissingInvoiceEmail/MissingInvoiceEmail';

export const MissingInvoiceContentTile = ({
  cardholderInsight,
  dateRange,
  dateOption,
}: {
  cardholderInsight: CardHolderCCInsightsWidgetData;
  dateRange: DateRange;
  dateOption: DateOptionMissingInvoicesWidget;
}) => {
  const { cardholder, invoicesWithoutTransaction } = cardholderInsight;

  const fullName = `${cardholder.firstName} ${cardholder.lastName}`;

  const { dateFrom, dateTo } = dateRange || {};

  const href = usePath({
    pathname: '/inbox/transactions',
    search: {
      cardholderName: cardholder.id,
      ...(dateFrom &&
        dateTo && { transactionCreatedAt: `${dateFrom}-${dateTo}` }),
    },
  });

  return (
    <SwimlaneTile asChild>
      <NavLink to={href} className={styles.wrapper}>
        <AvatarWithStatusContainer
          hasTooltip={false}
          size="medium"
          img={cardholder.avatarUrl}
          userId={cardholder.membershipId}
          name={fullName}
        />
        <div className={styles.content}>
          <span className={styles.name}>{fullName}</span>
          <MissingInvoiceEmail
            cardholderInsight={cardholderInsight}
            href={href}
            dateOption={dateOption}
          />
          <div className={styles.count}>{invoicesWithoutTransaction}</div>
        </div>
      </NavLink>
    </SwimlaneTile>
  );
};

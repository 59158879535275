import { Flex, Grid, Icon, Text } from '@candisio/design-system';
import { PromotionDetail } from 'components/NewPromotions/types';

export const UpgradeInfo = ({
  upgradeInfo,
}: {
  upgradeInfo: PromotionDetail['upgradeInfo'];
}) => {
  if (!upgradeInfo) return null;

  const { columns } = upgradeInfo;

  return (
    <Flex direction="column" gap="space24">
      <Flex direction="column" gap="space8">
        <Text fontSize="xxlarge" fontWeight={400}>
          {upgradeInfo.title}
        </Text>
        <Text fontWeight={400} fontSize="basic" color="gray500">
          {upgradeInfo.description}
        </Text>
      </Flex>

      <Flex
        alignItems="start"
        width="100%"
        direction="column"
        padding="space32 space0"
        gap="space8"
      >
        {columns.map((column, index) => (
          <Grid key={index} gap="space8">
            {column.items.map((item, _itemIndex) => (
              <Flex
                key={item}
                justifyContent="flex-start"
                alignItems="baseline"
                gap="space4"
              >
                <Icon icon="check" height="space10" color="gray800" />
                <Text
                  flex={1}
                  overflowWrap="break-word"
                  fontWeight={400}
                  lineHeight="space18"
                >
                  {item}
                </Text>
              </Flex>
            ))}
          </Grid>
        ))}
      </Flex>
    </Flex>
  );
};

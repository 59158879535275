import { SplitDS } from 'components/Form/SplitBookingsForm/types';
import { isEqual, isNil } from 'lodash';
import { useSap } from 'orgConfig/sap';
import { useEffect, useRef } from 'react';
import { DefaultValues, FieldPath, UseFormReturn, get } from 'react-hook-form';
import { useBookingsFormContext } from 'views/DocumentDetails/BookingsFormContext';
import { ProcessingFormValues } from '../processingFormSchema';

type Props = {
  defaultValues?: DefaultValues<ProcessingFormValues>;
  /** Processing form instance */
  form: UseFormReturn<ProcessingFormValues>;
  isReadOnly?: boolean; // po linking is not possible in read only form
};
/**
 *
 * Syncs processing form default values with purchase order(PO) linking
 * PO linking dynamically updates the bookings initial data, but react-hook-form does not update the form fields,
 * ... or if the second linking happens, initial data changes as bookings array length increases, but react-hook-form does not populate the new bookings to the split form
 * so we need to manually reset the form to force the form cache clear
 * see rules: https://react-hook-form.com/docs/useform#defaultValues
 *
 */

const registerAndResetField = (
  form: UseFormReturn<ProcessingFormValues>,
  fieldName: FieldPath<ProcessingFormValues>,
  defaultValue: number | string
) => {
  form.register(fieldName);
  form.resetField(fieldName, { defaultValue });
};

const syncBookingFields = (
  form: UseFormReturn<ProcessingFormValues>,
  defaultValues: DefaultValues<ProcessingFormValues> | undefined,
  booking: SplitDS,
  index: number
) => {
  const quantityField =
    `bookings.${index}.quantity` as FieldPath<ProcessingFormValues>;

  const unitPriceField =
    `bookings.${index}.unitPrice` as FieldPath<ProcessingFormValues>;

  const defaultBookingQuantity = get(defaultValues, quantityField);
  const defaultBookingUnitPrice = get(defaultValues, unitPriceField);

  const shouldRegisterQuantityField =
    !booking.sapExpenseType &&
    !isNil(defaultBookingQuantity) &&
    (isNil(booking?.quantity) || booking?.quantity !== defaultBookingQuantity);

  const shouldRegisterUnitPriceField =
    !isNil(defaultBookingUnitPrice) &&
    (isNil(booking?.unitPrice) ||
      booking?.unitPrice !== defaultBookingUnitPrice);

  if (shouldRegisterQuantityField) {
    registerAndResetField(form, quantityField, defaultBookingQuantity);
  }

  if (shouldRegisterUnitPriceField) {
    registerAndResetField(form, unitPriceField, defaultBookingUnitPrice);
  }
};

const syncExpenseTypeField = (
  form: UseFormReturn<ProcessingFormValues>,
  defaultValues: DefaultValues<ProcessingFormValues> | undefined,
  index: number
) => {
  const expenseTypeField =
    `bookings.${index}.sapExpenseType` as FieldPath<ProcessingFormValues>;

  const defaultExpenseType = get(defaultValues, expenseTypeField);
  const currentFormValue = form.getValues(expenseTypeField);

  if (defaultExpenseType !== currentFormValue) {
    form.setValue(expenseTypeField, defaultExpenseType);
  }
};

const syncFormWithPurchaseOrder = (
  form: UseFormReturn<ProcessingFormValues>,
  defaultValues: DefaultValues<ProcessingFormValues> | undefined,
  shouldResetForm?: boolean,
  setShouldResetForm?: ((value: boolean) => void) | undefined
) => {
  let shouldReset = false;

  defaultValues?.bookings?.forEach((booking, index) => {
    const formDefaultBooking = form.formState.defaultValues?.bookings?.find(
      _booking => _booking?.bookingId === booking?.bookingId
    );

    if (!formDefaultBooking) {
      // Form does not populate dynamically added new bookings(bookings array length is increased), so we need to manual reset to force form cache clear
      shouldReset = true;

      return;
    }

    if (!booking) return;

    syncBookingFields(
      form,
      defaultValues,
      formDefaultBooking as SplitDS,
      index
    );
  });
  // mutation onCompleted sets shouldResetForm to true, so we double check the new added booking is not there is only due to PO linking
  if (shouldReset && shouldResetForm) {
    form.reset(defaultValues, {
      keepDirtyValues: true,
      keepErrors: true,
    });
    setShouldResetForm?.(false); // reset the flag
  }
};

const syncReadOnlyFormWithPurchaseOrder = (
  form: UseFormReturn<ProcessingFormValues>,
  defaultValues: DefaultValues<ProcessingFormValues> | undefined
) => {
  form.formState.defaultValues?.bookings?.forEach((booking, index) => {
    syncBookingFields(form, defaultValues, booking as SplitDS, index);
    syncExpenseTypeField(form, defaultValues, index);
  });
};

export const useSyncFormDefaultValuesWithPurchaseOrder = ({
  defaultValues,
  form,
  isReadOnly,
}: Props) => {
  const prevDefaultValues = useRef(defaultValues);
  const { shouldUseSapPurchaseOrder } = useSap();
  const { shouldResetForm, setShouldResetForm } = useBookingsFormContext();

  useEffect(() => {
    if (
      !shouldUseSapPurchaseOrder ||
      isEqual(prevDefaultValues.current?.bookings, defaultValues?.bookings)
    ) {
      return;
    }

    if (isReadOnly) {
      syncReadOnlyFormWithPurchaseOrder(form, defaultValues);
    } else {
      syncFormWithPurchaseOrder(
        form,
        defaultValues,
        shouldResetForm,
        setShouldResetForm
      );
    }

    prevDefaultValues.current = defaultValues;
  });
};

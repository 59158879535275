import {
  ContactSortField,
  ContactsInfiniteScrollPaginationQuery,
  ContactsInfiniteScrollPaginationQueryVariables,
  SortDirection,
  useContactsPaginationQuery,
} from 'generated-types/graphql.types';
import { useDebouncedSearchPagination } from 'hooks/useDebouncedSearchPagination';
import { useCallback } from 'react';
import { contactsInfiniteScrollPaginationQuery } from 'views/Contacts/queries';

export const useContactFieldOptions = ({
  selectedValues,
}: {
  selectedValues: string[];
}) => {
  const computeVariables = useCallback(
    (inputString?: string): ContactsInfiniteScrollPaginationQueryVariables => ({
      queries: {
        name: inputString,
      },
      sortBy: {
        direction: SortDirection.Asc,
        field: ContactSortField.Name,
      },
    }),
    []
  );

  const {
    data: allContacts,
    loading: allContactsLoading,
    loadMore,
    handleDebounceSearch,
  } = useDebouncedSearchPagination<
    ContactsInfiniteScrollPaginationQuery,
    ContactsInfiniteScrollPaginationQueryVariables
  >({
    query: contactsInfiniteScrollPaginationQuery,
    queryRootKey: 'contactsPagination',
    computeVariables,
  });

  const { data: selectedContactItems, loading: selectedContactsLoading } =
    useContactsPaginationQuery({
      variables: {
        input: { page: 1, limit: selectedValues.length },
        filters: { id: selectedValues },
      },
      fetchPolicy: 'cache-and-network',
      skip: selectedValues.length === 0,
    });

  const mappedContacts = allContacts?.contactsPagination.records.map(item => ({
    id: item.id,
    name: item.name.value,
  }));

  const mappedSelectedContacts =
    selectedContactItems?.contactsPagination.records.map(item => ({
      id: item.id,
      name: item.name.value,
    }));

  return {
    defaultItems: mappedContacts,
    loading: allContactsLoading || selectedContactsLoading,
    loadMore,
    handleDebounceSearch,
    selectedContactItems: mappedSelectedContacts,
  };
};

import { CellContext } from '@tanstack/react-table';
import { HookFormPaginatedComboBox } from 'components/HookFormInputs/HookFormPaginatedComboBox';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { TableFormRow } from '../../schema/bookingsFormSchema';

type Value = TableFormRow['projectCode'];

export const ProjectCodeCell: React.FC<CellContext<TableFormRow, Value>> = ({
  column: {
    columnDef: { meta },
  },
  row: { index },
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);
  const { options, readOnly } = meta ?? {};

  return (
    <HookFormPaginatedComboBox
      name={`bookings.${index}.projectCode`}
      data-cy={`bookings.${index}.projectCode`}
      readOnly={readOnly}
      placeholder={
        readOnly
          ? t('inputs.projectCode.placeholder.notSet')
          : t('inputs.projectCode.placeholder.enabled')
      }
      {...options}
    />
  );
};

import { SwimlaneTilePromo } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromo';
import { SwimlaneTilePromoCTAButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCTAButton';
import {
  SwimlaneTilePromoContent,
  SwimlaneTilePromoContentBody,
} from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoContent';
import { SwimlaneTilePromoCloseButton } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoCloseButton';
import { SwimlaneTileTitle } from 'components/Swimlane/content/SwimlaneTileTitle';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Tag } from '@candisio/design-system';
import { SwimlaneTilePromoImage } from 'components/Swimlane/content/SwimlaneTilePromo/SwimlaneTilePromoImage';
import {
  Entitlements,
  useCandisFeatureFlags,
  useEntitlementsFlag,
} from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { SwimlaneTileDescription } from 'components/Swimlane/content/SwimlaneTileDescription';

export const PromoExpenseUpcomingTravel = ({
  onClose,
}: { onClose: () => void }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const travelExpenseManagementFF = useCandisFeatureFlags(
    FEATURE_FLAGS.travelExpenseManagement
  );
  const entitlement = useEntitlementsFlag();

  const isTravelExpenseEnabled =
    entitlement === Entitlements.PLUS ||
    entitlement === Entitlements.MAX ||
    travelExpenseManagementFF;

  return (
    <SwimlaneTilePromo variant={isTravelExpenseEnabled ? 'blue' : 'purple'}>
      <SwimlaneTilePromoImage
        src={t(
          'dashboard.swimlanes.promotions.promoExpenseUpcomingTravel.imageUrl'
        )}
      />
      <SwimlaneTilePromoContent>
        <Tag color={isTravelExpenseEnabled ? 'blue' : 'purple'}>
          {isTravelExpenseEnabled
            ? t('common:tag.soon')
            : t('common:tag.addOn')}
        </Tag>

        <SwimlaneTilePromoCloseButton onClose={onClose} />
        <SwimlaneTilePromoContentBody>
          <SwimlaneTileDescription>
            {t(
              'dashboard.swimlanes.promotions.promoExpenseUpcomingTravel.description'
            )}
          </SwimlaneTileDescription>
          <SwimlaneTileTitle>
            {t(
              'dashboard.swimlanes.promotions.promoExpenseUpcomingTravel.title'
            )}
          </SwimlaneTileTitle>
          <SwimlaneTilePromoCTAButton
            icon="arrowRight"
            iconPosition="right"
            as="a"
            target="_blank"
            href={t(
              'dashboard.swimlanes.promotions.promoExpenseUpcomingTravel.ctaButton.link'
            )}
          >
            {t(
              'dashboard.swimlanes.promotions.promoExpenseUpcomingTravel.ctaButton.text'
            )}
          </SwimlaneTilePromoCTAButton>
        </SwimlaneTilePromoContentBody>
      </SwimlaneTilePromoContent>
    </SwimlaneTilePromo>
  );
};

import { CommentProps } from 'components/Comment/types';
import { CommentCreateErrorKind } from 'generated-types/graphql.types';

export const getCommentCreateErrorTranslationKey = (
  errorKind: Partial<CommentCreateErrorKind>
) => {
  switch (errorKind) {
    case CommentCreateErrorKind.Unauthorized:
      return 'comment.creation.errors.unauthorized';
    case CommentCreateErrorKind.ResourceNotFound:
      return 'comment.creation.errors.resourceNotFound';
    case CommentCreateErrorKind.InvalidInput:
      return 'comment.creation.errors.invalidInput';
    case CommentCreateErrorKind.UnexpectedError:
      return 'comment.creation.errors.unexpected';
    default:
      return 'comment.creation.errors.unexpected';
  }
};

export const WAIT_BEFORE_REFETCHING_DOCUMENT_HISTORY_MS = 3000;

export const sendWithDelay = (
  ms: number,
  resolve: () => Promise<unknown> | void
) =>
  new Promise(res =>
    setTimeout(async () => {
      await resolve();
      res(null);
    }, ms)
  );

export const convertMentionsToTags = (
  comment: string,
  memberships: CommentProps['memberships']
) => {
  const text = comment.replace(
    /@\[(.+?)\]\((.+?)\)/g,
    (_, userName, userId) => {
      const user = memberships.find(user => user.id === userId);
      return user ? `@{userMembershipId:${user.id}}` : `@${userName}`;
    }
  );
  return text;
};

export const convertTagsToMentions = (
  comment: string,
  memberships: CommentProps['memberships']
) => {
  const text = comment.replace(
    /@\{userMembershipId:([\w-]+)\}/gi,
    (_, userMembershipId) => {
      const user = memberships.find(user => user.id === userMembershipId);
      return `@${user ? `${user.name}` : comment}`;
    }
  );
  return text;
};

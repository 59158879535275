import {
  Box,
  Button,
  ButtonProps,
  Grid,
  Popover,
  UsePopoverConfig,
  usePopover,
} from '@candisio/design-system';
import { ReactNode } from 'react';

type TriggerProps = Pick<
  ReturnType<typeof usePopover>,
  'triggerProps' | 'triggerRef'
>;

export interface ConfirmationPopoverDeprecatedProps {
  title: string;
  closeText: string;
  confirmText: string;
  onConfirm?: () => void;
  placement?: UsePopoverConfig['placement'];
  trigger: (props: TriggerProps) => JSX.Element;
  confirmButtonProps?: ButtonProps;
  closeButtonProps?: ButtonProps;
  children?: ReactNode;
}

/**
 * @deprecated Use either ConfirmationButton or ConfirmationPopover (together
 * with `usePopover` hook) instead, depending on your needs
 */
export const ConfirmationPopoverDeprecated = ({
  title,
  closeText,
  confirmText,
  onConfirm,
  placement,
  trigger,
  children,
  closeButtonProps,
  confirmButtonProps,
}: ConfirmationPopoverDeprecatedProps) => {
  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement });

  if (isOpen && confirmButtonProps?.disabled) {
    close();
  }

  return (
    <>
      {isOpen && (
        <Popover
          padding="space18"
          maxWidth={320}
          {...popoverProps}
          ref={popoverRef}
        >
          <Grid gap="space20">
            {title}
            {children && <Box>{children}</Box>}
            <Grid
              autoFlow="column"
              gap="space8"
              data-cy="confirmation-popover-buttons"
            >
              <Button variant="secondary" onClick={close} {...closeButtonProps}>
                {closeText}
              </Button>
              <Button onClick={onConfirm} color="red" {...confirmButtonProps}>
                {confirmText}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
      {trigger({ triggerProps, triggerRef })}
    </>
  );
};

import { mergeRefs, usePopover } from '@candisio/design-system';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useIsElementVisible } from 'hooks/useIsElementVisible';
import { useEffect, useState } from 'react';
import { AriaPositionProps } from 'react-aria';

export const useTourPopover = ({
  id,
  placement,
  updatePlacementOnTriggerMovement = true,
  delay = 1000,
}: {
  id: string;
  placement?: AriaPositionProps['placement'];
  delay?: number;
  updatePlacementOnTriggerMovement?: boolean;
}) => {
  const [activationSeen, setActivationSeen] = useLocalStorage(id, false);

  const [delayPassed, setDelayPassed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setDelayPassed(true), delay);

    return () => clearTimeout(timer);
  }, [delay]);

  const { ref, isIntersecting: isTriggerVisible } = useIsElementVisible();

  const shouldOpenActivationPopover =
    !activationSeen && isTriggerVisible && delayPassed;

  const {
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps,
    triggerRef,
    close,
    updatePosition,
  } = usePopover({
    placement,
    updatePlacementOnTriggerMovement,
    isOpen: shouldOpenActivationPopover,
  });

  // onPointerDown and onClick from trigger interfere with current props
  const { onPointerDown, onClick, ...restProps } = triggerProps;

  const handleClose = () => {
    close();
    setActivationSeen(true);
  };

  return {
    shouldOpenActivationPopover,
    targetProps: restProps,
    targetRef: mergeRefs([triggerRef, ref]),
    isOpen,
    popoverProps,
    popoverRef,
    close: handleClose,
    updatePosition,
  };
};

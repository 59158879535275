import { TruncatedText } from '@candisio/design-system';
import { maximumPrecisionForSapItemInvoices } from 'constants/sap';
import { Money } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { useGetMatchStateStyles } from '../hooks/useGetMatchStateStyles';
import { ThreeWayMatchRowData } from '../types';

type Value = Money | null | undefined;

export const ThreeWayMatchAmountCell = (
  props: CellProps<ThreeWayMatchRowData, Value>
) => {
  const getMatchingStyles = useGetMatchStateStyles();
  const { documentMoneyFormatter } = useMoneyFormatter();
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const formattedAmount = props.value
    ? documentMoneyFormatter(props.value, maximumPrecisionForSapItemInvoices)
    : t('emptyCellPlaceholder');

  const isMatched = props.row.original.isAmountMatched;

  return (
    <TruncatedText
      wordBreak="break-all"
      color={getMatchingStyles(isMatched ? 'success' : 'warning').cellTextColor}
    >
      {formattedAmount}
    </TruncatedText>
  );
};

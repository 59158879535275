import { Modal } from '@candisio/design-system';
import { IS_PROD } from 'constants/env';
import { DEVTOOLS_KEY } from 'constants/env';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { LanguageToggle } from './LanguageToggle';
import { QaUtilsModalContent } from './QaUtilsModalContent';

export const QaUtilsModal = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.QA_UTILS);
  const navigate = useNavigate();
  const visible = useLocation().hash === `#${DEVTOOLS_KEY}`;

  if (IS_PROD) {
    return null;
  }

  const closeModal = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    navigate(
      {
        hash: '',
        search: window.location.search,
      },
      { replace: true }
    );
  };

  return (
    <Modal
      onClose={closeModal}
      isOpen={visible}
      closeLabel={t('common:common.close')}
      title={
        <div className="flex items-center gap-8">
          Devtools
          <LanguageToggle />
        </div>
      }
      maxWidth="80rem"
      width="100%"
      height="90vh"
    >
      <QaUtilsModalContent />
    </Modal>
  );
};

import { Box, Grid, Item, Spinner } from '@candisio/design-system';
import { GridView } from 'components/GridView/GridView';
import { SearchField } from 'components/SearchField/SearchField';
import { xor } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocale } from 'utils/useLocale';
import { CreditCardOption } from '../../toolkit/hooks/useCreditCardOptions';
import { CreditCardGridItemCheckbox } from './CreditCardGridItemCheckbox';
import { CreditCardsFieldEmptyState } from './CreditCardsFieldEmptyState';

interface CreditCardsFieldProps {
  autoFocus?: boolean;
  creditCardOptions: CreditCardOption[];
  name: UseControllerProps['name'];
  control?: UseControllerProps['control'];
  search: string;
  isReadOnly?: boolean;
  isLoadingCreditCardOptions?: boolean;
  savedOptions?: string[];
  onEndReached?: (index: number) => void;
  onSearch: (search: string) => void;
}

export const CreditCardsField = ({
  autoFocus = false,
  creditCardOptions,
  savedOptions = [],
  name,
  control,
  search,
  isLoadingCreditCardOptions = false,
  isReadOnly = false,
  onEndReached,
  onSearch,
}: CreditCardsFieldProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const locale = useLocale();

  const { field } = useController({ name, control });

  const { value, onChange } = field;

  const handleChange = (checkBoxId: string) => {
    const previousSelectedCards = value ?? [];
    const newSelectedCards = xor(previousSelectedCards, [checkBoxId]);
    onChange(newSelectedCards);
  };

  const isLoading =
    isLoadingCreditCardOptions && creditCardOptions.length === 0;

  const isEmpty = !isLoadingCreditCardOptions && creditCardOptions.length === 0;

  const sortedSelected = creditCardOptions.filter(option =>
    savedOptions.includes(option.id)
  );

  const sortedUnselected = creditCardOptions.filter(
    option => !savedOptions.includes(option.id)
  );

  const options = [...sortedSelected, ...sortedUnselected];

  return (
    <Grid templateRows="auto 1fr" gap="space24">
      <Box justifySelf="start" paddingX="space16">
        <SearchField
          autoFocus={autoFocus}
          placeholder={t(
            'teams.createTeam.creditCardDetails.creditCardsField.searchPlaceholder'
          )}
          initialValue={search}
          readOnly={isReadOnly}
          onChange={onSearch}
          inputSize={locale.startsWith('en') ? 28 : 30}
        />
      </Box>
      {isLoading && (
        <Grid placeContent="center">
          <Spinner size="space48" />
        </Grid>
      )}
      {isEmpty && <CreditCardsFieldEmptyState />}
      <GridView
        itemWidth="200px"
        listGap="space8"
        onEndReached={onEndReached}
        children={options.map((creditCard, index) => {
          const isChecked = (value ?? []).includes(creditCard.id);

          return (
            <Item key={index} textValue={creditCard.id}>
              <CreditCardGridItemCheckbox
                creditCard={creditCard}
                isChecked={isChecked}
                isReadOnly={isReadOnly}
                onChange={handleChange}
              />
            </Item>
          );
        })}
      />
    </Grid>
  );
};

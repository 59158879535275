import { PurchaseOrdersTableData } from 'components/PurchaseOrders/Table/types';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useAssociatePurchaseOrdersToDocumentMutation } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getRefetchQueries } from '../utils/getRefetchQueries';

interface UseLinkPurchaseOrderToDocumentProps {
  documentId: string;
  onCompleted?: () => void;
}

export const useLinkPurchaseOrdersToDocument = ({
  documentId,
  onCompleted: onLinkingCompleted,
}: UseLinkPurchaseOrderToDocumentProps) => {
  const { success, error } = useToastMessage();
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  const [linkPurchaseOrderToDocumentMutation, { loading: isLinkingLoading }] =
    useAssociatePurchaseOrdersToDocumentMutation({
      awaitRefetchQueries: true,
      refetchQueries: result => {
        if (result.data?.associatePurchaseOrdersToDocument.length) {
          return getRefetchQueries(documentId);
        }
        return [];
      },
      onCompleted: data => {
        if (data?.associatePurchaseOrdersToDocument.length) {
          onLinkingCompleted?.();
        }
      },
    });

  const linkPurchaseOrder = useCallback(
    async (purchaseOrders: PurchaseOrdersTableData[]) => {
      if (documentId && purchaseOrders.length) {
        const selectedPurchaseOrderIds = purchaseOrders.map(po => po.id);
        const selectedPurchaseOrderNumbers = purchaseOrders.map(
          po => po.orderNumber
        );

        const { data, errors } = await linkPurchaseOrderToDocumentMutation({
          variables: {
            documentId,
            purchaseOrderIds: selectedPurchaseOrderIds,
          },
        });

        if (data?.associatePurchaseOrdersToDocument?.length) {
          success(
            t('actions.purchaseOrderLinkingSuccess', {
              orderNumber: selectedPurchaseOrderNumbers.join(', '),
              count: selectedPurchaseOrderNumbers.length,
            })
          );
        } else if (errors?.length) {
          error(
            t('actions.purchaseOrderLinkingError', {
              orderNumber: selectedPurchaseOrderNumbers.join(', '),
              count: selectedPurchaseOrderNumbers.length,
            })
          );
        }
      }
    },
    [documentId, linkPurchaseOrderToDocumentMutation, success, t, error]
  );

  return {
    linkPurchaseOrder,
    isLinkingLoading,
  };
};

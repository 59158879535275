import {
  Button,
  ButtonProps,
  mergeProps,
  Popover,
  usePopover,
  useTheme,
} from '@candisio/design-system';
import type { ReactNode } from 'react';

type InfoPopupButtonProps = {
  message: ReactNode;
} & Omit<ButtonProps<'button'>, 'variant'>;

/**
 * An info button which shows a popup on click.
 *
 * The button is skipped from keyboard navigation
 */
export const InfoPopupButton = ({
  message,
  ...restProps
}: InfoPopupButtonProps) => {
  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'top' });
  const { colors } = useTheme();

  return (
    <>
      <Button
        variant="tertiary"
        size="xsmall"
        icon="infoOutline"
        iconSize="space16"
        style={{ color: colors.gray500 }}
        tabIndex={-1}
        {...mergeProps(triggerProps, restProps)}
        ref={triggerRef}
      />
      {isOpen && (
        <Popover {...popoverProps} ref={popoverRef}>
          <div className="flex max-w-[350px]">{message}</div>
        </Popover>
      )}
    </>
  );
};

import { useCostCenterFieldOptions } from 'components/Form/hooks/useCostCenterFieldOptions';
import {
  CostCenterTypes,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { useUsersFieldOptions } from 'hooks/useUsersFieldOptions';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import {
  useShowCostCenterField,
  useShowCostObjectField,
  useShowGeneralLedgerAccountField,
} from 'views/utils/useShouldShowField';
import { ReimbursementFormFieldOptions } from './types';
import { Reimbursement } from './useFormattedReimbursement';
import {
  useApproversFieldOptions,
  useWorkFlowsFieldOptions,
} from 'views/CreditCards/hooks/AccountingInfoFormFieldData';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { useReimbursementFormsContext } from '../context/ReimbursementFormsContext';
import { useOtherIntegration } from 'orgConfig/other';
import { useShouldRequireAccountsPayableNumber } from 'views/utils/useShouldRequireField';
import { useGeneralLedgerAccountFieldOptions } from 'components/Form/hooks/useGeneralLedgerAccountFieldOptions';
import { useUserRoles } from 'hooks/useUserRoles';

export const EDITABLE_STATUSES = [
  ReimbursementCaseStatus.Draft,
  ReimbursementCaseStatus.Check,
];

export const APPROVERS_FIELD_VISIBILITY_STATUSES = [
  ReimbursementCaseStatus.Review,
  ReimbursementCaseStatus.Rejected,
];

interface ReimbursementFormFieldOptionsProps {
  reimbursement?: Reimbursement;
  reimbursementItemsCount: number;
  accountsPayableNumber: string | undefined;
}

export const useReimbursementFormFieldOptions = ({
  reimbursement,
  reimbursementItemsCount,
  accountsPayableNumber,
}: ReimbursementFormFieldOptionsProps): ReimbursementFormFieldOptions => {
  const currentUser = useCurrentUser();
  const { hasActiveWorkflowTemplates } = useFullOrganization() ?? {};
  const { isApprovingEditMode } = useReimbursementFormsContext();
  const { isOnlyApprover } = useUserRoles();

  const { loading: isLoadingTargetMembers, users: targetMemberOptions } =
    useUsersFieldOptions({
      status: 'ActiveAndInactive',
    });

  const showCostObjectField = useShowCostObjectField();
  const showCostCenterField = useShowCostCenterField();
  const showGeneralLedgerAccountField = useShowGeneralLedgerAccountField();

  const costCenter = useCostCenterFieldOptions({
    skip: !showCostCenterField,
    type: CostCenterTypes.CostCenter,
  });

  const costObjects = useCostCenterFieldOptions({
    skip: !showCostObjectField,
    type: CostCenterTypes.CostObject,
  });

  const generalLedgerAccountFieldOptions = useGeneralLedgerAccountFieldOptions({
    skip: !showGeneralLedgerAccountField,
  });

  const workflow = useWorkFlowsFieldOptions();
  const approvers = useApproversFieldOptions();
  const { shouldUseCoreDataApi } = useOtherIntegration();
  const shouldRequireAccountsPayableNumber =
    useShouldRequireAccountsPayableNumber();

  const status = reimbursement
    ? reimbursement.status
    : ReimbursementCaseStatus.Draft;
  const isDraftStatus = status === ReimbursementCaseStatus.Draft;
  const isOwner = reimbursement?.targetMembership?.id === currentUser?.id;
  const isReimbursementEditable =
    (status && EDITABLE_STATUSES.includes(status)) ?? false;
  const isInApprovingEditMode =
    status === ReimbursementCaseStatus.Approving && isApprovingEditMode;

  const showApproversOrWorkflows =
    !isOnlyApprover &&
    (APPROVERS_FIELD_VISIBILITY_STATUSES.includes(status) ||
      isInApprovingEditMode);

  const showWorkflows =
    (showApproversOrWorkflows && hasActiveWorkflowTemplates) ?? false;
  const showApprovers = showApproversOrWorkflows;

  const showCostCenter = showCostCenterField && isDraftStatus;
  const showCostObject = showCostObjectField && isDraftStatus;

  const showGeneralLedgerAccount =
    showGeneralLedgerAccountField && isDraftStatus;

  const showAdvancedAccountingData =
    isDraftStatus &&
    reimbursementItemsCount > 0 &&
    (showCostCenter || showCostObject || showGeneralLedgerAccount);

  const showAccountsPayableNumberInfo =
    status !== ReimbursementCaseStatus.Draft &&
    status !== ReimbursementCaseStatus.Check &&
    !accountsPayableNumber &&
    (shouldUseCoreDataApi || shouldRequireAccountsPayableNumber);

  const isAddIbanButtonVisible = isOwner && isReimbursementEditable;

  const isAccountPayableNumberFieldVisible =
    status !== ReimbursementCaseStatus.Draft &&
    status !== ReimbursementCaseStatus.Check;

  const fieldOptions: ReimbursementFormFieldOptions = {
    targetMembership: {
      isVisible: true,
      options: {
        defaultItems: targetMemberOptions,
        isLoading: isLoadingTargetMembers,
      },
    },
    suggestedCostCenter: {
      isVisible: showCostCenter,
      options: costCenter?.props,
    },
    suggestedCostObject: {
      isVisible: showCostObject,
      options: costObjects?.props,
    },
    suggestedGeneralLedgerAccount: {
      isVisible: showGeneralLedgerAccount,
      options: generalLedgerAccountFieldOptions?.props,
    },
    accountsPayableNumberInfo: {
      isVisible: showAccountsPayableNumberInfo,
    },
    accountPayableNumberField: {
      isVisible: isAccountPayableNumberFieldVisible,
    },
    iban: {
      isAddButtonVisible: isAddIbanButtonVisible,
      isEditButtonVisible:
        isAddIbanButtonVisible && Boolean(reimbursement?.iban),
    },
    approvers: {
      isVisible: showApprovers,
      options: approvers,
    },
    workflow: { isVisible: showWorkflows, options: workflow },
    advancedAccountingData: {
      isVisible: showAdvancedAccountingData,
    },
  };

  return fieldOptions;
};

import { Swimlane } from 'components/Swimlane/Swimlane';
import { SwimlaneHeader } from 'components/Swimlane/SwimlaneHeader';
import { SwimlaneTiles } from 'components/Swimlane/SwimlaneTiles';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { OverviewIssueCardTile } from './OverviewIssueCardTile/OverviewIssueCardTile';
import { CardInUseTile } from './CardInUseTile/CardInUseTile';
import { AvailableLimitTile } from './AvailableLimitTile/AvailableLimitTile';
import { PromoCreditCardExplained } from '../promos/PromoCreditCardExplained';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { CardExpiresSoonTile } from './CardExpiresSoonTile/CardExpiresSoonTile';
import { PromoCreditCardChart } from '../promos/PromoCreditCardChart';

const PROMO_TILE_COUNT = 2;

export const OverviewSwimlane = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);

  const { isPromotionSeen: isPromoExplainedSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.CREDIT_CARD_EXPLAINED
  );
  const { isPromotionSeen: isChartSeen } = useSyncedPromoPreference(
    TOUR_POPOVER_IDS.CREDIT_CARD_CHART
  );

  const isPromotionSeen = isPromoExplainedSeen && isChartSeen;

  return (
    <Swimlane data-cy="declinedTransactionsSwimlane">
      <SwimlaneTiles
        contentTileCount={6}
        promoTileCount={isPromotionSeen ? undefined : PROMO_TILE_COUNT}
        maxColumns={7}
      >
        {({ isPromoTileVisible, contentTileVisibleCount }) => {
          const showPromoTile =
            isPromoTileVisible && contentTileVisibleCount > PROMO_TILE_COUNT;

          return (
            <>
              <SwimlaneHeader
                icon="creditCardOutline"
                label={t('insights.requestsOverview.title')}
              />

              <OverviewIssueCardTile />
              <CardExpiresSoonTile />
              <CardInUseTile />
              <AvailableLimitTile />

              {showPromoTile && <PromoCreditCardExplained />}
              {showPromoTile && isPromoExplainedSeen && (
                <PromoCreditCardChart />
              )}
            </>
          );
        }}
      </SwimlaneTiles>
    </Swimlane>
  );
};

import { MenuButton } from '@candisio/design-system';
import { DateOptionMissingInvoicesWidget } from 'views/CreditCards/CreditCardsInsights/MissingInvoicesWidget/utils';
import { useMissingInvoiceDateRangeFilterOptions } from './useMissingInvoiceDateRangeFilterOptions';

type Props = {
  dateOption: DateOptionMissingInvoicesWidget;
  onOptionChange: (option: any) => void;
};

export const MissingInvoiceFilterButton = ({
  dateOption,
  onOptionChange,
}: Props) => {
  const { items } = useMissingInvoiceDateRangeFilterOptions();
  const sortButtonText = items.find(item => item.id === dateOption)?.label;

  return (
    <MenuButton
      items={items}
      value={[dateOption]}
      selectionMode="single"
      variant="tertiary"
      alignSelf="start"
      onChange={onOptionChange}
    >
      {sortButtonText}
    </MenuButton>
  );
};

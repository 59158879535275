import { FileState } from 'generated-types/graphql.types';
import { AnimatePresence } from 'motion/react';
import { useMount } from 'react-use';
import { useHistoryListItems } from '../hooks/useHistoryListItems';
import { useGetFileUploadAttempts } from '../useGetFileUploadAttempts';
import { ClientUploadHistoryItem } from './ClientUploadHistoryItem/ClientUploadHistoryItem';
import styles from './UploadHistory.module.css';
import { UploadHistoryItem } from './UploadHistoryItem/UploadHistoryItem';
import { UploadHistoryListAnimatedItem } from './UploadHistoryListAnimatedItem';
import { UploadHistoryScollbox } from './UploadHistoryScollbox';

export const UploadHistoryList = () => {
  const { refetch } = useGetFileUploadAttempts();
  const items = useHistoryListItems();

  useMount(refetch);

  return (
    <UploadHistoryScollbox>
      <ol className={styles.list}>
        <AnimatePresence initial={false}>
          {items.map((value, index) => (
            <UploadHistoryListAnimatedItem
              delay={index}
              enterAnimation={value.type === 'local'}
              exitAnimation={
                value.type === 'local'
                  ? value.entry.state === FileState.Error
                  : true
              }
              key={
                value.type === 'local'
                  ? value.entry.fileId
                  : value.entry.file.id
              }
            >
              {value.type === 'local' ? (
                <ClientUploadHistoryItem item={value.entry} />
              ) : (
                <UploadHistoryItem item={value.entry} />
              )}
            </UploadHistoryListAnimatedItem>
          ))}
        </AnimatePresence>
      </ol>
    </UploadHistoryScollbox>
  );
};

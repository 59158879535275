import { useApolloClient } from '@apollo/client';
import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Link,
  Paragraph,
  Switch,
  Text,
  useId,
} from '@candisio/design-system';
import { HookFormSelectField } from 'components/HookFormFields/HookFormSelectField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  NameIdPolicyFormat,
  PrincipalType,
  useCreateIdentityProviderMutation,
  useUpdateIdentityProviderMutation,
} from 'generated-types/graphql.types';
import { IdentityProviderInput } from 'generated-types/resolvers-types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { samlFormErrors } from './samlFormErrors';
import { IdpSamlFormValues, samlFormSchema } from './samlFormSchema';
import { mapSamlFormValues2GqlInput } from './utils/mapIdpValues2GqlInput';

const authServerUrl = import.meta.env.REACT_APP_KEYCLOAK_AUTH_ENDPOINT;
const authClient = import.meta.env.REACT_APP_KEYCLOAK_AUTH_CLIENT;

export interface IdpSamlFormProps {
  /** edit or create mode */
  action: string;
  organizationSlug: string;
  /** SSO configuration Id*/
  ssoConfigurationId?: string;
  idpValues?: IdpSamlFormValues;
  onCreated: (id: string) => void;
}

const fallbackDefaultValues: IdpSamlFormValues = {
  alias: 'default-idp',
  entityId: `${authServerUrl}/realms/${authClient}`,
  idpEntityId: 'your-idp-entity-id',
  singleSignOnServiceUrl: 'https://your-idp-url/saml',
  nameIDPolicyFormat: NameIdPolicyFormat.Persistent,
  principalType: PrincipalType.SubjectNameId,
  validateSignature: false,
  signingCertificate: null,
  syncMode: null,
  postBindingAuthnRequest: false,
  postBindingResponse: false,
};

export const IdpSamlForm = ({
  action,
  organizationSlug,
  ssoConfigurationId,
  idpValues,
  onCreated,
}: IdpSamlFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { success, error } = useToastMessage();
  const formId = useId();
  if (!idpValues) {
    idpValues = { ...fallbackDefaultValues };
    // create mode take the organization slug for default alias
    if (action !== 'edit') {
      idpValues.alias = organizationSlug;
    }
  }

  const apolloClientHook = useApolloClient();
  const buildAcsURL = (alias: string): string => {
    return `${authServerUrl}/realms/${authClient}/broker/${alias}/endpoint`;
  };

  const initialAlias = buildAcsURL(
    action === 'edit' ? idpValues?.alias : organizationSlug
  );

  const [acsURL, setAcsURL] = useState<string>(initialAlias);

  const [validateSignature, setValidateSignature] = useState(
    idpValues.validateSignature
  );

  const [postBindingAuthnRequest, setPostBindingAuthnRequest] = useState(
    idpValues.postBindingAuthnRequest
  );

  const [postBindingResponse, setPostBindingResponse] = useState(
    idpValues.postBindingResponse
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (action === 'edit' && idpValues) {
      setValidateSignature(idpValues.validateSignature);
      setPostBindingAuthnRequest(idpValues.postBindingAuthnRequest);
      setPostBindingResponse(idpValues.postBindingResponse);
      setAcsURL(buildAcsURL(idpValues.alias));
    }
  }, [idpValues, action]);

  const form = useForm<IdpSamlFormValues>({
    values: idpValues,
    resolver: zodResolver({
      zodSchema: samlFormSchema,
      errorMessages: samlFormErrors,
      translationNamespace: 'settings',
    }),
    mode: 'onTouched',
  });

  const [createIDP] = useCreateIdentityProviderMutation();
  const [updateIDP] = useUpdateIdentityProviderMutation();

  const handleCreateIDP = (idp: IdentityProviderInput) => {
    return createIDP({
      variables: {
        input: idp,
      },
      onCompleted: _data => {
        apolloClientHook.cache.evict({ fieldName: 'listSSOConfigurations' });
      },
    });
  };

  const handleUpdateIDP = (
    ssoConfigurationId: string,
    idp: IdentityProviderInput
  ) => {
    return updateIDP({
      variables: {
        ssoConfigurationId,
        input: idp,
      },
      onCompleted: _data => {
        apolloClientHook.cache.evict({ fieldName: 'getIdentitiyProvider' });
      },
    });
  };

  const handleSubmit = async () => {
    const values: IdpSamlFormValues = form.getValues();
    const idpValues = mapSamlFormValues2GqlInput(values);

    if (action === 'edit' && ssoConfigurationId) {
      const result = await handleUpdateIDP(ssoConfigurationId, idpValues);
      if (result?.errors) {
        error(t('sso.toastMessage.updateError'));
      }

      success(t('sso.toastMessage.updateSuccess'));
    } else {
      const result = await handleCreateIDP(idpValues);
      if (result?.errors) {
        error(t('sso.toastMessage.idpCreateError'));
      }

      if (result?.data?.createIdentityProvider) {
        onCreated(result.data.createIdentityProvider.ssoConfigurationId);
        success(t('sso.toastMessage.idpCreateSuccess'));
      }
    }
  };

  const onAliasChange = (alias: string | null) => {
    setAcsURL(buildAcsURL(alias ?? ''));
  };

  const handlePostBindingAuthnRequestToggle = () => {
    setPostBindingAuthnRequest((prevState: boolean) => !prevState);
  };

  const handleValidateSignatureToggle = () => {
    setValidateSignature((prevState: boolean) => !prevState);
  };

  const handlePostBindingResponseToggle = () => {
    setPostBindingResponse((prevState: boolean) => !prevState);
  };

  const copyEntityIdToClipboard = () => {
    const entityId = form.getValues('entityId');
    if (entityId) {
      void navigator.clipboard.writeText(entityId);
    }
  };

  return (
    <Card style={{ transition: 'height 0.5s cubic-bezier(0.87, 0, 0.13, 1)' }}>
      <FormProvider {...form}>
        <Grid
          gap="space24"
          as="form"
          onSubmit={form.handleSubmit(handleSubmit)}
          id={formId}
        >
          <Heading as="h3">{t('sso.saml.form.title')}</Heading>
          <Paragraph>{t('sso.saml.form.paragraph')}</Paragraph>
          <Grid>
            <Text as="h3" lineHeight="space24" whiteSpace="pre-line">
              {t('sso.saml.form.fields.entityId.label')}
            </Text>
            <Link
              as="button"
              icon="copy"
              iconPosition="right"
              textAlign="left"
              onClick={copyEntityIdToClipboard}
            >
              {form.getValues('entityId')}
            </Link>
          </Grid>
          <Grid>
            <Text as="h3" lineHeight="space24" whiteSpace="pre-line">
              {t('sso.saml.form.fields.alias.label')}
            </Text>
            <HookFormTextField
              ariaLabel=""
              name="alias"
              width="space256"
              layout="compact"
              readOnly={action === 'edit'}
              onChange={onAliasChange}
            />
          </Grid>
          <Grid>
            <Text as="h3" lineHeight="space24" whiteSpace="pre-line">
              {t('sso.saml.form.fields.AssertionConsumerService.label')}
            </Text>
            <Link
              as="button"
              icon="copy"
              iconPosition="right"
              textAlign="left"
              onClick={() => {
                void navigator.clipboard.writeText(acsURL);
              }}
            >
              {acsURL}
            </Link>
          </Grid>
          {action === 'edit' && (
            <Grid>
              <Text as="h3" lineHeight="space24" whiteSpace="pre-line">
                {t('sso.saml.form.fields.serviceProviderMetadata.label')}
              </Text>
              <Link
                as="button"
                icon="copy"
                iconPosition="right"
                textAlign="left"
                onClick={() => {
                  void navigator.clipboard.writeText(acsURL + '/descriptor');
                }}
              >
                {acsURL + '/descriptor'}
              </Link>
            </Grid>
          )}
          <Grid>
            <Text as="h3" lineHeight="space24" whiteSpace="pre-line">
              {t('sso.saml.form.fields.idpEntityId.label')}
            </Text>
            <HookFormTextField ariaLabel="" name="idpEntityId" width="90%" />
          </Grid>
          <Grid>
            <Text as="h3" lineHeight="space24" whiteSpace="pre-line">
              {t('sso.saml.form.fields.singleSignOnServiceUrl.label')}
            </Text>
            <HookFormTextField
              ariaLabel=""
              name="singleSignOnServiceUrl"
              width="90%"
            />
          </Grid>
          <Grid>
            <Text as="h3" lineHeight="space24" whiteSpace="pre-line">
              {t('sso.saml.form.fields.nameIDPolicyFormat.label')}
            </Text>
            <HookFormSelectField
              name="nameIDPolicyFormat"
              items={Object.entries(NameIdPolicyFormat).map(([key, value]) => ({
                key: value,
                children: t(key),
              }))}
              label=""
              width="space256"
            />
          </Grid>
          <Grid>
            <Text as="h3" lineHeight="space24" whiteSpace="pre-line">
              {t('sso.saml.form.fields.principalType.label')}
            </Text>
            <HookFormSelectField
              name="principalType"
              items={Object.entries(PrincipalType).map(([key, value]) => ({
                key: value,
                children: t(key),
              }))}
              label=""
              width="space256"
            />
          </Grid>
          <Grid>
            <Text as="h3" lineHeight="space32" whiteSpace="pre-line">
              {t('sso.saml.form.fields.postBindingAuthnRequest.label')}
            </Text>
            <Switch
              name="postBindingAuthnRequest"
              label=""
              aria-label=""
              checked={postBindingAuthnRequest}
              onChange={isValid => {
                handlePostBindingAuthnRequestToggle();
                form.setValue('postBindingAuthnRequest', isValid);
              }}
            />

            <Text as="h3" lineHeight="space32" whiteSpace="pre-line">
              {t('sso.saml.form.fields.postBindingResponse.label')}
            </Text>
            <Switch
              name="postBindingResponse"
              label=""
              aria-label=""
              checked={postBindingResponse}
              onChange={isValid => {
                handlePostBindingResponseToggle();
                form.setValue('postBindingResponse', isValid);
              }}
            />
          </Grid>
          <Grid>
            <Text as="h3" lineHeight="space32" whiteSpace="pre-line">
              {t('sso.saml.form.fields.validateSignature.label')}
            </Text>
            <Switch
              name="validateSignature"
              label=""
              aria-label=""
              checked={validateSignature}
              onChange={isValid => {
                handleValidateSignatureToggle();
                form.setValue('validateSignature', isValid);
              }}
            />
          </Grid>
          <Grid width="90%">
            <Text as="h3" lineHeight="space24" whiteSpace="pre-line">
              {t('sso.saml.form.fields.signingCertificate.label')}
            </Text>
            <HookFormTextareaField label="" name="signingCertificate" />
          </Grid>
        </Grid>
        <Flex
          paddingTop="space24"
          justifySelf="end"
          gap="space16"
          width="100%"
          justifyContent="left"
        >
          <Button onClick={handleSubmit} color="blue" variant="primary">
            {t('sso.buttons.saveSSOConfig')}
          </Button>
        </Flex>
      </FormProvider>
    </Card>
  );
};

import { Icon, IconProps, Text } from '@candisio/design-system';
import styles from './TravelPromotion.module.css';
import clsx from 'clsx';

interface PromotionTileProps {
  icon: IconProps['icon'];
  description: string;
  heading: string;
}

export const PromotionTile = ({
  icon,
  description,
  heading,
}: PromotionTileProps) => {
  return (
    <li className={styles['promotion-tile']}>
      <div
        className={clsx(
          styles['icon-wrapper'],
          styles[`icon-wrapper--${icon}`]
        )}
      >
        <Icon icon={icon} size="space20" />
      </div>
      <div className="flex gap-1 flex-col">
        <Text fontSize="xlarge">{heading}</Text>
        <Text fontSize="basic">{description}</Text>
      </div>
    </li>
  );
};

import { useGetSapDocumentsQuery } from 'generated-types/graphql.types';
import { useSap } from 'orgConfig/sap';
import {
  AssociatedDocumentsObject,
  AssociatedGoodsReceipt,
  AssociatedPurchaseOrder,
} from '../types';
import uniqBy from 'lodash/uniqBy';

export const useAssociatedDocuments = (documentId?: string) => {
  const { shouldUseSapGoodsReceipts } = useSap();
  const { data, loading: isLoading } = useGetSapDocumentsQuery({
    variables: { id: documentId ?? '' },
    skip: !documentId || !shouldUseSapGoodsReceipts,
  });
  const associatedDocuments: AssociatedDocumentsObject = {
    associatedGoodsReceipts: [],
    associatedPurchaseOrders: [],
  };

  (data?.getDocument?.associatedDocuments ?? [])
    .filter(Boolean)
    .forEach(doc => {
      switch (doc?.__typename) {
        case 'GoodsReceipt':
          const goodsReceiptItem: AssociatedGoodsReceipt = {
            id: doc._id,
            receiptNumber: doc.receiptNumber,
            deliveryDate: doc.deliveryDate,
            status: doc.goodsReceiptStatus,
            amount: doc.amount,
            goodsLineItems: (doc.goodsLineItems || [])?.map(item => ({
              description: item.description,
              quantity: item.quantity,
              itemNumber: item.itemNumber,
              identifier: `${doc._id}-${item.lineNumber}`, // unique identifier for each item later will be used in 3-way-match
              receiptNumber: doc.receiptNumber,
              unitPrice: item.unitPrice,
            })),
            supplierRefNumber: doc?.sapB1Data?.supplierRefNumber ?? undefined,
            purchaseOrders: uniqBy(
              (doc?.purchaseOrders || []).map(order => ({
                orderNumber: order?.orderNumber ?? '',
                id: order?._id ?? '',
                amount: order?.amount,
                status: order?.status,
                postingDate: order?.postingDate ?? '',
                goodsLineItems: (order?.goodsLineItems || [])?.map(item => ({
                  description: item.description ?? '',
                  quantity: item.quantity,
                  unitPrice: item.unitPrice,
                  itemNumber: item.itemNumber ?? '',
                  identifier: `${order?._id ?? ''}-${item.lineNumber}`,
                  orderNumber: order?.orderNumber ?? '',
                  purchaseOrderId: order?._id ?? '',
                })),
                supplierRefNumber:
                  order?.sapB1Data?.supplierRefNumber ?? undefined,
              })),
              'id'
            ),
          };
          associatedDocuments.associatedGoodsReceipts.push(goodsReceiptItem);
          break;
        case 'PurchaseOrder':
          const purchaseOrderItem: AssociatedPurchaseOrder = {
            id: doc._id,
            orderNumber: doc.orderNumber,
            postingDate: doc.postingDate,
            amount: doc.amount,
            status: doc.purchaseOrderStatus,
            goodsLineItems: (doc.goodsLineItems || [])?.map(item => ({
              description: item.description ?? '',
              quantity: item.quantity,
              unitPrice: item.unitPrice,
              itemNumber: item.itemNumber ?? '',
              identifier: `${doc._id}-${item.lineNumber}`,
              orderNumber: doc.orderNumber,
              purchaseOrderId: doc._id,
            })),
            purchaseOrderType: doc.purchaseOrderType,
            goodsReceipts: uniqBy(
              (doc?.goodsReceipts || []).map(receipt => ({
                id: receipt?._id ?? '',
                receiptNumber: receipt?.receiptNumber ?? '',
                deliveryDate: receipt?.deliveryDate ?? '',
                status: receipt?.status,
                amount: receipt?.amount,
                goodsLineItems: (receipt?.goodsLineItems || [])?.map(item => ({
                  description: item.description,
                  quantity: item.quantity,
                  itemNumber: item.itemNumber,
                  identifier: `${receipt?._id ?? ''}-${item.lineNumber}`,
                  receiptNumber: receipt?.receiptNumber ?? '',
                  unitPrice: item.unitPrice,
                })),
                supplierRefNumber:
                  receipt?.sapB1Data?.supplierRefNumber ?? undefined,
              })),
              'id'
            ),
            supplierRefNumber: doc?.sapB1Data?.supplierRefNumber ?? undefined,
          };
          associatedDocuments.associatedPurchaseOrders.push(purchaseOrderItem);
          break;

        default:
          break;
      }
    });

  const associatedDocumentsLength =
    associatedDocuments.associatedGoodsReceipts.length +
    associatedDocuments.associatedPurchaseOrders.length;

  return { associatedDocuments, associatedDocumentsLength, isLoading };
};

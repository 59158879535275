import { CustomEmptyStateProps, Flex } from '@candisio/design-system';
import { ExportStatus } from 'generated-types/graphql.types';
import { ComponentProps } from 'react';
import { ExportTableCustomEmptyState } from 'views/Integrations/Export/toolkit/components/EmptyState/components/TableCustomEmptyState';
import {
  Code,
  ErrorCode,
  ExportTableHeading,
  ExportTableHeadingProps,
  Variant,
  WarningCode,
  isErrorCode,
  isWarningCode,
} from 'views/Integrations/Export/toolkit/components/ExportTableHeading';
import { useExportTableMaxHeight } from 'views/Integrations/Export/toolkit/hooks/useExportTableMaxHeight';
import {
  ExportReimbursementItemsTable,
  ExportReimbursementItemsTableProps,
} from './ExportReimbursementItemsTable';
import { ExportReimbursementItemsTableRow } from './types';

type ExportedReimbursementItemsTableProps = Omit<
  ExportTableHeadingProps,
  'count'
> &
  ExportReimbursementItemsTableProps;

type HeadingProps = {
  [K in Variant]: ComponentProps<typeof ExportTableHeading>;
};

export type GroupedReimbursementItemsTableData = Partial<{
  [code in Code]: ExportReimbursementItemsTableRow[];
}>;

export const groupExportReimbursementItems = (
  tableData: ExportReimbursementItemsTableRow[]
) => {
  const failedReimbursementItems: GroupedReimbursementItemsTableData = {};
  const partiallyExportedReimbursementItems: GroupedReimbursementItemsTableData =
    {};

  const exportedReimbursementItems: GroupedReimbursementItemsTableData = {
    success: [],
  };

  for (const entry of tableData) {
    const { status, exportError } = entry.exportStatus ?? {};
    if (!status) continue;

    if (status === ExportStatus.Exported) {
      exportedReimbursementItems.success?.push(entry);
      continue;
    }

    if (status === ExportStatus.PartialyExported) {
      const code: WarningCode = isWarningCode(exportError?.errorCode)
        ? exportError?.errorCode
        : 'UNKNOWN';

      if (!partiallyExportedReimbursementItems[code]) {
        partiallyExportedReimbursementItems[code] = [entry];
        continue;
      }

      partiallyExportedReimbursementItems[code]?.push(entry);
      continue;
    }

    if (status === ExportStatus.Failed) {
      const code: ErrorCode = isErrorCode(exportError?.errorCode)
        ? exportError?.errorCode
        : 'UNKNOWN';

      if (!failedReimbursementItems[code]) {
        failedReimbursementItems[code] = [entry];
        continue;
      }

      failedReimbursementItems[code]?.push(entry);
    }
  }

  return {
    groupedFailedReimbursementItems: failedReimbursementItems,
    partiallyExportedReimbursementItems,
    exportedReimbursementItems,
  };
};

export const mapGroupToTablesReimbursementItems = (
  groupedExportedDocuments: GroupedReimbursementItemsTableData,
  Table: (props: ExportedReimbursementItemsTableProps) => JSX.Element,
  variant: Variant
) =>
  Object.entries(groupedExportedDocuments).map(([code, tableData]) => ({
    id: code,
    content: <Table data={tableData} code={code as Code} variant={variant} />,
  }));

export const ExportedReimbursementItemsTable = ({
  columnIds,
  code,
  data,
  variant,
  filterOptions,
  isLoading,
}: ExportedReimbursementItemsTableProps) => {
  const height = useExportTableMaxHeight(data.length);

  const headingProps: HeadingProps = {
    success: {
      variant: 'success',
      count: data.length as number,
      code: 'success',
    },
    warning: {
      variant: 'warning',
      count: data.length as number,
      code: code as WarningCode,
    },
    error: {
      variant: 'error',
      count: data.length as number,
      code: code as ErrorCode,
    },
    inProgress: {
      variant: 'inProgress',
      count: data.length as number,
      code: 'inProgress',
    },
  };

  const isEmpty = data.length === 0;

  if (isEmpty) {
    return null;
  }

  return (
    <Flex direction="column" flexGrow="1" rowGap="space16">
      <ExportTableHeading {...headingProps[variant]} />
      <ExportReimbursementItemsTable
        borderBottomRadius="none"
        borderTopRadius="none"
        columnIds={columnIds}
        isLoading={isLoading}
        height={height}
        data={data}
        filterOptions={filterOptions}
        customEmptyState={({ resetFilters }: CustomEmptyStateProps) => (
          <ExportTableCustomEmptyState
            resetFilters={resetFilters}
            isTableFiltered={false}
          />
        )}
      />
    </Flex>
  );
};

import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCallback } from 'react';

export const Drawers = {
  ATTACHMENTS: 'attachments',
  RELATIONSHIPS: 'relationships',
};

export const DRAWER = 'drawer';
export const useDrawerState = (drawerName: keyof typeof Drawers) => {
  const { searchParams, updateSearchParam } = useMutateSearchParams();

  const currentDrawer = searchParams.get(DRAWER);

  const isDrawerOpen = currentDrawer === Drawers[drawerName];
  const isOtherDrawerOpen = !isDrawerOpen && currentDrawer !== null;

  const toggleDrawer = useCallback(() => {
    const drawer = isDrawerOpen ? '' : Drawers[drawerName];
    updateSearchParam(DRAWER, drawer, { replace: true });
  }, [updateSearchParam, isDrawerOpen, drawerName]);

  return {
    isDrawerOpen,
    isOtherDrawerOpen,
    toggleDrawer,
  };
};

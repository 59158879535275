import { TruncatedText } from '@candisio/design-system/next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { DocumentsTableData } from '../../types';
import { CellContext } from '@tanstack/react-table';

type Value = number | undefined;

export const FileSizeCell = ({
  getValue,
}: CellContext<DocumentsTableData, Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const value = getValue();

  if (!value) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  const valueInMb = value / 1024 / 1024; // @TODO - should we always display size in MB?

  return <TruncatedText>{valueInMb.toFixed(2) + ' MB'}</TruncatedText>; // todo magic string MB
};

import { Button } from '@candisio/design-system';
import { ExportStatus } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import { buildDatevDocumentLink } from 'utils/build_datev_link_for_document';
import { DocumentsTableData, OpenInDatev } from '../../types';
import { CellContext } from '@tanstack/react-table';

const translationMap = {
  withLink: 'export.table.columns.openInDatev.tooltipForLink',
  noLink: 'export.table.columns.openInDatev.tooltipForDisabledLink',
};

export const OpenInDatevCell = ({
  getValue,
}: CellContext<DocumentsTableData, OpenInDatev>) => {
  const [t] = useTranslation();

  const value = getValue();

  const openInDatevLink = buildDatevDocumentLink({
    consultantNumber: value?.consultantNumber,
    clientNumber: value?.clientNumber,
    documentExportGuid: value?.exportGuid,
  });

  const isExportedStatus =
    value?.exportStatus?.status === ExportStatus.Exported ||
    value?.exportStatus?.status === ExportStatus.PartialyExported;

  const hasLink = openInDatevLink && isExportedStatus;

  const labelTranslationKey = hasLink
    ? translationMap.withLink
    : translationMap.noLink;

  const handleClick = () => {
    if (hasLink) window.open(openInDatevLink, '_blank', 'noopener noreferrer');
  };

  return (
    <Button
      style={{ display: 'flex', padding: 0 }}
      icon="newTab"
      disabled={!hasLink}
      label={t(labelTranslationKey)}
      onClick={handleClick}
      size="medium"
      variant="tertiary"
    />
  );
};

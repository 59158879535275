import { NavigationIcon } from '../../NavigationIcon';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MenuDivider } from '../MenuDivider/MenuDivider';

export const MenuDashboard = ({ expanded }: { expanded: boolean }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const { hasRoles } = useUserRoles();

  return (
    <>
      <NavigationIcon
        icon="menuDashboard"
        expanded={expanded}
        label={t('menuLabels.dashboard')}
        route={Routes.DASHBOARD}
        showMenuIcon={hasRoles}
      />
      <MenuDivider />
    </>
  );
};

import { Spinner } from '@candisio/design-system';
import { useDeferredState } from 'hooks/useDeferredState';
import { useEffect } from 'react';

export const ReimbursementDashboardSpinner = () => {
  const [isVisible, setVisible] = useDeferredState(false);

  useEffect(() => {
    setVisible(true, 250);
  }, [setVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="h-screen grid place-items-center">
      <Spinner size="space64" color="gray400" />
    </div>
  );
};

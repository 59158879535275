import {
  ArchivePurchaseOrdersQuery,
  PurchaseOrderEdge,
  PurchaserOrderQueryField,
} from 'generated-types/graphql.types';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import { useUserRoles } from 'hooks/useUserRoles';
import { useSap } from 'orgConfig/sap';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { Filters, SortingRule } from 'react-table';
import { archivePurchaseOrdersQuery } from 'views/Archive/PurchaseOrders/queries';
import { getApproximateNumberOfRowForTable } from 'views/utils/pagination-helper';
import { PurchaseOrdersTableData } from './types';
import { mapToFilterInput, mapToSortInput } from './util';

export const defaultSort: SortingRule<PurchaseOrdersTableData>[] = [
  {
    id: 'status',
    desc: true,
  },
];

const sortByOrderNumber = [
  {
    id: 'orderNumber',
    desc: true,
  },
];

export interface UsePurchaseOrdersDataProps {
  searchValue: string;
  documentAmount?: number | null;
  sortBy: SortingRule<PurchaseOrdersTableData>[];
  filters: Filters<PurchaseOrdersTableData>;
  queryFields?: PurchaserOrderQueryField[];
}

export const usePurchaseOrdersData = ({
  documentAmount,
  filters,
  sortBy,
  queryFields = [],
  searchValue = '',
}: UsePurchaseOrdersDataProps) => {
  const { isOnlyApprover } = useUserRoles();

  const [debouncedSearchValue] = useDebouncedValue(searchValue);
  const { shouldUseSapPurchaseOrder } = useSap();

  const sortParameters = documentAmount ? sortByOrderNumber : defaultSort;

  const computeVariables = (endCursor: string | undefined) => ({
    input: {
      limit: getApproximateNumberOfRowForTable(),
      after: endCursor,
    },
    query: debouncedSearchValue,
    queryFields,
    filters: mapToFilterInput(filters),
    sortBy: !sortBy.length
      ? mapToSortInput(sortParameters, documentAmount)
      : mapToSortInput(sortBy),
  });

  const queryOptions = {
    variables: computeVariables(undefined),
    skip: !shouldUseSapPurchaseOrder || isOnlyApprover,
  };

  const {
    data,
    loading: isLoadingPurchaseOrderList,
    onLoadMore,
    refetch,
  } = usePagination<ArchivePurchaseOrdersQuery>(
    archivePurchaseOrdersQuery,
    'inboxPurchaseOrders',
    queryOptions,
    {
      computeVariables,
    }
  );

  const listPurchaseOrders = data?.inboxPurchaseOrders || {};
  const { pageInfo, edges } = listPurchaseOrders;

  const purchaseOrderListCount = pageInfo?.totalCount || 0;
  const purchaseOrderList = (edges || []) as PurchaseOrderEdge[];
  const hasMoreData =
    (pageInfo?.hasNextPage ?? false) &&
    purchaseOrderListCount >= getApproximateNumberOfRowForTable();

  const isTableEmpty =
    purchaseOrderList.length === 0 && !isLoadingPurchaseOrderList;

  return {
    hasMoreData,
    purchaseOrderList,
    isLoadingPurchaseOrderList,
    onLoadMore,
    refetch,
    purchaseOrderListCount,
    endCursor:
      purchaseOrderList[purchaseOrderList.length - 1]?.cursor ||
      pageInfo?.endCursor,
    isTableEmpty,
  };
};

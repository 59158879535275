import { z } from 'zod';

export const headerFormSchema = z.object({
  grossAmount: z.number().superRefine((value, ctx) => {
    if (value == null) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey: 'errors.fieldRequired',
        },
      });
    }
    if (value === 0) {
      ctx.addIssue({
        code: 'custom',
        params: {
          translationKey: 'inputs.grossAmount.nonZeroError',
        },
      });
    }
  }),
  currency: z.string(),
  invoiceDate: z.string(),
  roundingAmount: z.number().nullish(),
});

export type BookingHeaderValues = z.infer<typeof headerFormSchema>;

import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import {
  ArtistSocialInsuranceCode,
  DocumentCurrency,
  IntegrationName,
  ReimbursementCaseStatus,
  ReimbursementItemStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { RefinementCtx, ZodIssueCode, z } from 'zod';
import { ApprovalMode } from '../context/ReimbursementFormsContext';
import { EDITABLE_STATUSES } from '../hooks/useReimbursementFormFieldOptions';

const MIN_VAT_RATE = 0;
const MAX_VAT_RATE = 99.99;
const VAT_RATE_STEP = 0.01;

const selectFieldSchema = z.object({
  value: z.string().nullish(),
  inputValue: z.string().nullish(),
});

export const bookingSchema = z.object({
  bookingId: z.string().uuid(),
  taxPresentation: z.nativeEnum(TaxPresentation).optional(),
  vatRate: z
    .number()
    .min(MIN_VAT_RATE)
    .max(MAX_VAT_RATE)
    .step(VAT_RATE_STEP)
    .nullish(),
  splitAmount: z.number(),
  note: z.string().nullish(),
  postingText: z.string().nullish(),
  taxCode: z.string().nullish(),
  costCenter: selectFieldSchema.nullish(),
  costObject: selectFieldSchema.nullish(),
  extraCostInfo: selectFieldSchema.nullish(),
  generalLedgerAccount: selectFieldSchema.nullish(),
  artistSocialInsuranceCode: z.nativeEnum(ArtistSocialInsuranceCode).nullish(),
});

export const reimbursementSplitBookingEntrySchema = z
  .object({
    reimbursementItemId: z.string().uuid(),
    reimbursementItemTitle: z.string(),
    reimbursementItemType: z.nativeEnum(ReimbursementItemType),
    reimbursementItemStatus: z.nativeEnum(ReimbursementItemStatus),
    grossAmount: z.number(),
    remainingAmount: z.number(),
    currency: z.nativeEnum(DocumentCurrency).optional(),
    bookings: z.array(bookingSchema).optional(),
  })
  .optional();

type ReimbusermentSplitBookingData = z.infer<
  typeof reimbursementSplitBookingEntrySchema
>[];

const validateVatRate = (
  ctx: RefinementCtx,
  data: ReimbusermentSplitBookingData,
  { integration }: ReimbursementSplitBookingsFormSchemaOptions
) => {
  data.forEach((entry, entryIndex) => {
    const isReimbursementItemExcluded =
      entry?.reimbursementItemStatus === ReimbursementItemStatus.Excluded;

    if (isReimbursementItemExcluded) return;

    entry?.bookings?.forEach((field, index) => {
      const isVatRateRequired =
        field.taxPresentation === TaxPresentation.Net &&
        field.vatRate == null &&
        integration !== IntegrationName.Sap;

      if (isVatRateRequired) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: [entryIndex, 'bookings', index, 'vatRate'],
        });
      }
    });
  });
};

const validateSplitAmount = (
  ctx: RefinementCtx,
  data: ReimbusermentSplitBookingData
) => {
  data.forEach((entry, entryIndex) => {
    const isReimbursementItemExcluded =
      entry?.reimbursementItemStatus === ReimbursementItemStatus.Excluded;

    if (isReimbursementItemExcluded) return;

    if (entry?.remainingAmount !== 0) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: [entryIndex, 'remainingAmount'],
      });
    }

    (entry?.bookings ?? [])?.forEach((field, index) => {
      if (field.splitAmount === 0) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: [entryIndex, 'bookings', index, 'splitAmount'],
        });
      }
    });
  });
};

/**
 * IMPORTANT: Any changes to `validateTaxCode` must be synchronized with the backend
 * * Check: https://github.com/CandisIO/service-api-graphql/blob/086b7c3aadea4a9d0e506f5e1f862e2dc7953831/src/graphql/reimbursements/validators/validate-bookings.ts
 * * This ensures accurate error reporting and field validation positioning in the UI.
 */
const validateTaxCode = (
  ctx: RefinementCtx,
  data: ReimbusermentSplitBookingData,
  {
    approvalMode,
    shouldRequireTaxCode,
  }: ReimbursementSplitBookingsFormSchemaOptions
) => {
  if (approvalMode === 'requestApproval' || !shouldRequireTaxCode) return;

  data.forEach((entry, entryIndex) => {
    const isReimbursementItemExcluded =
      entry?.reimbursementItemStatus === ReimbursementItemStatus.Excluded;

    if (isReimbursementItemExcluded) return;

    (entry?.bookings ?? [])?.forEach((field, index) => {
      if (!field.taxCode) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: [entryIndex, 'bookings', index, 'taxCode'],
        });
      }
    });
  });
};

/**
 * IMPORTANT: Any changes to `validateGeneralLedgerAccount` must be synchronized with the backend
 * * Check: https://github.com/CandisIO/service-api-graphql/blob/086b7c3aadea4a9d0e506f5e1f862e2dc7953831/src/graphql/reimbursements/validators/validate-bookings.ts
 * * This ensures accurate error reporting and field validation positioning in the UI.
 */
const validateGeneralLedgerAccount = (
  ctx: RefinementCtx,
  data: ReimbusermentSplitBookingData,
  {
    approvalMode,
    shouldRequireGeneralLedgerAccount,
  }: ReimbursementSplitBookingsFormSchemaOptions
) => {
  if (approvalMode === 'requestApproval' || !shouldRequireGeneralLedgerAccount)
    return;

  data.forEach((entry, entryIndex) => {
    const isReimbursementItemExcluded =
      entry?.reimbursementItemStatus === ReimbursementItemStatus.Excluded;

    if (isReimbursementItemExcluded) return;

    (entry?.bookings ?? [])?.forEach((field, index) => {
      if (!field.generalLedgerAccount?.value) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: [
            entryIndex,
            'bookings',
            index,
            'generalLedgerAccount',
            'value',
          ],
        });
      }
    });
  });
};

/**
 * IMPORTANT: Any changes to `validateCostCenter` must be synchronized with the backend
 * * Check: https://github.com/CandisIO/service-api-graphql/blob/086b7c3aadea4a9d0e506f5e1f862e2dc7953831/src/graphql/reimbursements/validators/validate-bookings.ts
 * * This ensures accurate error reporting and field validation positioning in the UI.
 */
const validateCostCenter = (
  ctx: RefinementCtx,
  data: ReimbusermentSplitBookingData,
  {
    approvalMode,
    integration,
    hasCostCenters,
  }: ReimbursementSplitBookingsFormSchemaOptions
) => {
  if (approvalMode === 'requestApproval' || !hasCostCenters) return;

  data.forEach((entry, entryIndex) => {
    const isReimbursementItemExcluded =
      entry?.reimbursementItemStatus === ReimbursementItemStatus.Excluded;

    if (isReimbursementItemExcluded) return;

    (entry?.bookings ?? [])?.forEach((field, index) => {
      const isCostCenterRequired =
        integration !== IntegrationName.Sap &&
        hasCostCenters &&
        !field.costCenter?.value;

      if (isCostCenterRequired) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: [entryIndex, 'bookings', index, 'costCenter', 'value'],
        });
      }
    });
  });
};

/**
 * IMPORTANT: Any changes to `validateCostObject` must be synchronized with the backend
 * * Check: https://github.com/CandisIO/service-api-graphql/blob/086b7c3aadea4a9d0e506f5e1f862e2dc7953831/src/graphql/reimbursements/validators/validate-bookings.ts
 * * This ensures accurate error reporting and field validation positioning in the UI.
 */
const validateCostObject = (
  ctx: RefinementCtx,
  data: ReimbusermentSplitBookingData,
  {
    approvalMode,
    integration,
    hasCostObjects,
    hasCostCenters,
  }: ReimbursementSplitBookingsFormSchemaOptions
) => {
  if (approvalMode === 'requestApproval' || !hasCostObjects) return;

  data.forEach((entry, entryIndex) => {
    const isReimbursementItemExcluded =
      entry?.reimbursementItemStatus === ReimbursementItemStatus.Excluded;

    if (isReimbursementItemExcluded) return;

    (entry?.bookings ?? [])?.forEach((field, index) => {
      const isCostObjectRequired =
        integration !== IntegrationName.Sap &&
        hasCostObjects &&
        !hasCostCenters &&
        !field.costObject?.value;

      if (isCostObjectRequired) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: [entryIndex, 'bookings', index, 'costObject', 'value'],
        });
      }
    });
  });
};

export const reimbursementSplitBookingsFormSchema = (
  options: ReimbursementSplitBookingsFormSchemaOptions
) => {
  return z.object({
    reimbursementItemBookings: z
      .array(reimbursementSplitBookingEntrySchema)
      .superRefine((data, ctx) => {
        const shouldSkipValidation = options.caseStatus
          ? EDITABLE_STATUSES.includes(options.caseStatus)
          : false;

        if (shouldSkipValidation) return z.NEVER;

        validateVatRate(ctx, data, options);
        validateSplitAmount(ctx, data);
        validateTaxCode(ctx, data, options);
        validateCostCenter(ctx, data, options);
        validateCostObject(ctx, data, options);
        validateGeneralLedgerAccount(ctx, data, options);
      }),
  });
};

export type BookingValues = z.infer<typeof bookingSchema>;
export type ReimbursementSplitBookingsFormValues = z.infer<
  ReturnType<typeof reimbursementSplitBookingsFormSchema>
>;

export interface ReimbursementSplitBookingsFormSchemaOptions {
  approvalMode?: ApprovalMode;
  integration?: IntegrationName;
  hasCostCenters?: boolean;
  hasCostObjects?: boolean;
  shouldRequireTaxCode?: boolean;
  shouldRequireGeneralLedgerAccount?: boolean;
  caseStatus?: ReimbursementCaseStatus;
}

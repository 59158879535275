import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import { TableFormRow } from '../schema/bookingsFormSchema';
import { grossToNet, netToGross } from 'containers/SplitBookings/toolkit/utils';

type SumBooking = {
  netAmount?: number;
  amount?: number;
  vatRate?: number | null;
  taxPresentation?: TaxPresentation;
};

export const bookingWithNetAmount = (b: TableFormRow): SumBooking => {
  return {
    amount:
      b.taxPresentation === TaxPresentation.Gross
        ? b.amount
        : netToGross(b.amount ?? 0, b.vatRate ?? 0),
    vatRate: b.vatRate,
    taxPresentation: b.taxPresentation,
    netAmount:
      b.taxPresentation === TaxPresentation.Net
        ? b.amount
        : grossToNet(b.amount ?? 0, b.vatRate ?? 0),
  };
};

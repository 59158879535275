// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { DRAWER } from 'views/DocumentDetails/useDrawerState';

/** @deprecated use `useSearchParams` from React Router instead */
export const appendParamsToQueryString = (
  queryString: string,
  params: { [index: string]: string | number | boolean | null }
): string => {
  return qs.stringify({ ...qs.parse(queryString), ...params });
};

/** @deprecated use `useSearchParams` from React Router instead */
export const extractCursorFromQs = (queryString: string): string | null => {
  const { cursor } = qs.parse(queryString);

  return Array.isArray(cursor) ? cursor[0] : cursor;
};

/** @deprecated use `useSearchParams` from React Router instead */
export const getFiltersAndSortFromUrl = (queryString: string) => {
  const { cursor, ...filtersAndSort } = qs.parse(queryString);

  return Object.keys(filtersAndSort).length ? qs.stringify(filtersAndSort) : '';
};

export const useFiltersAndSortFromUrl = () => {
  const [searchParams] = useSearchParams();
  const filtersAndSortFromUrl = useMemo(() => {
    const urlSearchParams = new URLSearchParams(searchParams);
    urlSearchParams.delete('cursor');
    urlSearchParams.delete('isInvoice');
    urlSearchParams.delete(DRAWER);

    return urlSearchParams;
  }, [searchParams]);

  return filtersAndSortFromUrl;
};

export const replaceUTMSource = (
  url?: string,
  newUtmSource?: string
): string => {
  if (!url || !newUtmSource) return url ?? '';

  try {
    const urlObj = new URL(url);

    urlObj.searchParams.set('utm_source', newUtmSource);

    return urlObj.toString();
  } catch (error) {
    console.error('Invalid URL:', error);
    return url;
  }
};

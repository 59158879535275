import { Drawer, Heading } from '@candisio/design-system';
import { DrawerLayout } from 'components/DrawerLayout/DrawerLayout';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useDrawerNavigation } from '../hooks/useDrawerNavigation';
import { ProjectCodeForm } from './ProjectCodeForm/ProjectCodeForm';
import { useProjectCodeData } from './ProjectCodeForm/useProjectCodeData';

export interface ProjectDrawerProps {
  isOpen: boolean;
}

export const ProjectCodeDrawer = ({ isOpen }: ProjectDrawerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const { navigateBackToList } = useDrawerNavigation();
  const { data } = useProjectCodeData();

  return (
    <Drawer isOpen={isOpen} onClose={navigateBackToList}>
      <DrawerLayout
        header={<Heading as="h3">{t('projectCodes.drawer.title')}</Heading>}
        onClose={navigateBackToList}
      >
        <ProjectCodeForm defaultValues={data} />
      </DrawerLayout>
    </Drawer>
  );
};

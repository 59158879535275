import { Flex, Grid, Heading, MenuButton } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { CardHolderCCInsightsWidgetData } from 'views/CreditCards/hooks/useTransactionsInsights';
import { CardHoldersList } from './CardHoldersList';
import { MissingInvoicesWidgetSkeleton } from './MissingInvoicesWidgetSkeleton';
import { DateOptionMissingInvoicesWidget, DateRange } from './utils';
import { useMissingInvoiceDateRangeFilterOptions } from '../CreditCardsInsightSwimlanes/MissingInvoices/MissingInvoiceFilterButton/useMissingInvoiceDateRangeFilterOptions';

export interface MissingInvoicesWidgetProps {
  loading?: boolean;
  dateRange?: DateRange;
  mappedCardholdersCCInsights: CardHolderCCInsightsWidgetData[];
  dateOption: DateOptionMissingInvoicesWidget;
  handleDateOptionChange: (key: Key[]) => void;
}

export const MissingInvoicesWidget = ({
  mappedCardholdersCCInsights,
  loading,
  dateRange,
  dateOption,
  handleDateOptionChange,
}: MissingInvoicesWidgetProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.CREDIT_CARDS);
  const { items } = useMissingInvoiceDateRangeFilterOptions();

  const sortButtonText = items.find(item => item.id === dateOption)?.label;

  return (
    <Grid
      gap="space8"
      height="100%"
      alignContent="start"
      templateRows="auto 1fr"
    >
      <Heading as="h2">{t('insights.missingInvoicesWidget.title')}</Heading>
      <Flex direction="column" overflow="hidden" height="100%" gap="space8">
        <MenuButton
          items={items}
          value={[dateOption]}
          selectionMode="single"
          variant="secondary"
          alignSelf="start"
          onChange={handleDateOptionChange}
        >
          {sortButtonText}
        </MenuButton>

        {loading ? (
          <MissingInvoicesWidgetSkeleton />
        ) : (
          <CardHoldersList
            dateRange={dateRange}
            mappedCardholdersCCInsights={mappedCardholdersCCInsights}
            dateOption={dateOption}
          />
        )}
      </Flex>
    </Grid>
  );
};

import { Grid, InlineSkeleton, TruncatedText } from '@candisio/design-system';
import {
  DocumentCurrency,
  ReimbursementCaseStatus,
  ReimbursementItemType,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemSplitBookingsList } from './ReimbursementItemSplitBookingsList';
import { useReimbursementSplitBookingsData } from 'views/Reimbursement/hooks/useReimbursementSplitBookingsData';

export interface BasicReimbursementItemsSplitBookingsContainerProps {
  isLoading: boolean;
  isCurrentUserCaseApprover: boolean;
  reimbursementCaseStatus?: ReimbursementCaseStatus;
}

export const mappedTypesToItem: Record<ReimbursementItemType, string> = {
  GENERAL_EXPENSE:
    'reimbursementView.leftSection.reimbursementItems.placeholder.general',
  HOSPITALITY_EXPENSE:
    'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality',
  MILEAGE: '',
  PER_DIEM: '',
};

export const BasicReimbursementItemsSplitBookingsContainer = ({
  isLoading,
  isCurrentUserCaseApprover,
  reimbursementCaseStatus,
}: BasicReimbursementItemsSplitBookingsContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { reimbursementItemSplitBookings, loadingSplits } =
    useReimbursementSplitBookingsData();

  const reimbursementItemSplitData = useMemo(
    () => reimbursementItemSplitBookings,
    [reimbursementItemSplitBookings]
  );

  const isSplitLoading = isLoading || loadingSplits;

  return (
    <Grid gap="space8">
      <Grid gap="space16">
        {reimbursementItemSplitData.map((entry, entryIndex) => {
          const {
            title,
            bookings,
            totalAmount,
            currency,
            type,
            id,
            status: itemStatus,
          } = entry ?? {};

          const expense = mappedTypesToItem[type];
          const titleData = title ? title : t(expense);

          return (
            <InlineSkeleton
              key={`bookings-${id}`}
              isLoading={isSplitLoading}
              isBlockLevel
            >
              <Grid gap="space8">
                <TruncatedText color="gray600">{titleData}</TruncatedText>

                <ReimbursementItemSplitBookingsList
                  reimbursementItemId={id}
                  isLoading={isSplitLoading}
                  bookings={bookings}
                  entryIndex={entryIndex}
                  currency={currency ?? DocumentCurrency.Eur}
                  totalAmount={totalAmount ?? 0}
                  reimbursementCaseStatus={reimbursementCaseStatus}
                  itemStatus={itemStatus}
                  isCurrentUserCaseApprover={isCurrentUserCaseApprover}
                />
              </Grid>
            </InlineSkeleton>
          );
        })}
      </Grid>
    </Grid>
  );
};

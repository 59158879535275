import { Button, InfoPanel } from '@candisio/design-system';
import { Routes } from 'models';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom-v5-compat';

export const SapB1ConfigureSapButton = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SAP);
  const organizationSlug = useOrganizationId();

  return (
    <>
      <InfoPanel variant="information">
        {t('export.form.actionBar.sapConfigInfoMessage')}
      </InfoPanel>
      {
        //TODO: Style NavLink to look like a Button
      }
      <NavLink to={`/${organizationSlug}${Routes.SETTINGS}${Routes.SAP}`}>
        <Button style={{ width: '100%' }}>
          {t('export.form.actionBar.configureSap')}
        </Button>
      </NavLink>
    </>
  );
};

import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import {
  DocumentType,
  EcmDocumentContractStatus,
  EcmDocumentStatus,
  Locale,
} from 'generated-types/graphql.types';
import { faker } from 'utils/faker';

export const documentsData = [
  {
    cursor: '',
    id: '',
    globalDocumentId: '',
    documentType: DocumentType.Invoice,
    contact: 'Getsafe Digital GmbH',
    invoiceId: '668bccdd400d7a8e03d98377',
    notes: '',
    documentName: '',
    documentNumber: '',
    documentDate: new Date('2024-02-03T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.New,
      isSensitive: false,
      isEInvoice: false,
    },
    tags: [
      {
        __typename: 'Tag',
        id: '107021ec-c727-401b-b254-8d3ef33706b3',
        name: 'test',
        description: null,
        isActive: true,
      },
      {
        __typename: 'Tag',
        id: '38920dd2-3412-41a3-9533-fa041e49174b',
        name: 'pest',
        description: null,
        isActive: true,
      },
      {
        __typename: 'Tag',
        id: 'df582970-a77a-404e-915c-56f411678718',
        name: 'test2',
        description: null,
        isActive: true,
      },
      {
        __typename: 'Tag',
        id: 'fa697765-53f6-4ad5-a932-d6ebfd038ebc',
        name: 'test3',
        description: null,
        isActive: true,
      },
    ],
  },
  {
    cursor: '',
    id: '',
    documentType: DocumentType.Invoice,
    contact: 'Jacobs Engineering Group Inc.',
    invoiceId: '65e1930d96b62960b1873c58',
    notes: '',
    documentName: '',
    documentNumber: '43957',
    documentDate: new Date('2024-02-29T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.Approved,
      isSensitive: false,
      isEInvoice: false,
    },
    tags: [],
  },
  {
    cursor: '',
    id: '',
    globalDocumentId: '',
    documentType: DocumentType.Invoice,
    contact: 'Berliner Verkehrsbetriebe (BVG)',
    invoiceId: '6602d04dba63eaf65685d10b',
    notes: '',
    documentName: '',
    documentNumber: 'STS2366099',
    documentDate: new Date('2024-03-01T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.New,
      isSensitive: false,
      isEInvoice: false,
    },
    tags: [],
  },
  {
    cursor: '',
    id: '',
    globalDocumentId: '',
    documentType: DocumentType.Invoice,
    contact: 'Regions Financial Corp.',
    invoiceId: '65e1930df75ecfb11c3e9a5a',
    notes: '',
    documentName: '',
    documentNumber: '25942',
    documentDate: new Date('2024-02-29T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.Approved,
      isSensitive: false,
      isEInvoice: false,
    },
    tags: [],
  },
  {
    cursor: '',
    id: '',
    documentType: DocumentType.Invoice,
    contact: 'Citigroup, Inc',
    invoiceId: '65e1934e96b62960b1873cd5',
    notes: '',
    documentName: '',
    documentNumber: '55562',
    documentDate: new Date('2024-02-29T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.Exported,
      isSensitive: false,
      isEInvoice: false,
    },
    tags: [],
    globalDocumentId: '',
  },
  {
    cursor: '',
    id: '',
    documentType: DocumentType.Invoice,
    contact: 'Public Service Enterprise Group Inc.',
    invoiceId: '65e1934e7e40cd407c02c1f3',
    globalDocumentId: '',
    notes: '',
    documentName: '',
    documentNumber: '12178',
    documentDate: new Date('2024-02-29T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.Exported,
      isSensitive: false,
      isEInvoice: false,
    },
    tags: [],
  },
  {
    cursor: '',
    id: '',
    globalDocumentId: '',
    documentType: DocumentType.Invoice,
    contact: 'Owens-Illinois Inc',
    invoiceId: '65e1930e96b62960b1873c5e',
    notes: '',
    documentName: '',
    documentNumber: '7464',
    documentDate: new Date('2024-02-29T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.Exported,
      isSensitive: false,
      isEInvoice: false,
    },
    tags: [],
  },
  {
    cursor: '',
    id: '',
    globalDocumentId: '',
    documentType: DocumentType.Invoice,
    contact: 'Artirigo AG 2',
    invoiceId: '65b6c852ed4a35b3babdbfa6',
    notes: '',
    documentName: '',
    documentNumber: '201811-041',
    documentDate: new Date('2024-01-27T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.Exported,
      isSensitive: false,
      isEInvoice: false,
    },
    tags: [],
  },
  {
    cursor: '',
    id: '',
    globalDocumentId: '',
    documentType: DocumentType.Invoice,
    contact: 'CenturyTel Inc.',
    invoiceId: '65e1934e96b62960b1873cdc',
    notes: '',
    documentName: '',
    documentNumber: '67256',
    documentDate: new Date('2024-02-29T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.Exported,
      isSensitive: false,
      isEInvoice: false,
    },
    tags: [],
  },
  {
    cursor: '',
    id: '',
    globalDocumentId: '',
    documentType: DocumentType.Invoice,
    contact: 'Anthem, Inc.',
    invoiceId: '65e1934e96b62960b1873cde',
    notes: '',
    documentName: '',
    documentNumber: '19261',
    documentDate: new Date('2024-02-29T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.Exported,
      isSensitive: false,
      isEInvoice: false,
    },
    tags: [],
  },
  {
    cursor: '',
    id: '',
    globalDocumentId: '',
    documentType: DocumentType.Invoice,
    contact: 'averto GmbH',
    invoiceId: '6595b7c12b74102fcbca336b',
    notes: '',
    documentName: '',
    documentNumber: '89',
    documentDate: new Date('2024-01-10T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.Exported,
      isSensitive: false,
    },
    tags: [],
  },
  {
    cursor: '',
    id: '',
    documentType: DocumentType.Invoice,
    contact: 'Artirigo AG',
    invoiceId: '6593e09ae10b0d77ed218649',
    notes: '',
    globalDocumentId: '',
    documentName: '',
    documentNumber: '201811-041',
    documentDate: new Date('2024-01-01T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.Exported,
      isSensitive: false,
    },
    tags: [],
  },
  {
    cursor: '',
    id: '',
    documentType: DocumentType.Invoice,
    contact: 'Artirigo AG',
    globalDocumentId: '',
    invoiceId: '6593e09ae10b0d77ed218649',
    notes: '',
    documentName: '',
    documentNumber: '201811-041',
    documentDate: new Date('2024-01-01T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.Exported,
      isSensitive: false,
    },
    tags: [],
  },
  {
    globalDocumentId: '',
    cursor: '',
    id: '',
    documentType: DocumentType.Invoice,
    contact: 'Artirigo AG',
    invoiceId: '6593e09ae10b0d77ed218649',
    notes: '',
    documentName: '',
    documentNumber: '201811-041',
    documentDate: new Date('2024-01-01T23:00:00.000Z'),
    documentStatus: {
      status: EcmDocumentStatus.Exported,
      isSensitive: false,
    },
    tags: [],
  },
] as EcmDocumentsTableData[];

export const documentNames: { EN: string; DE: string }[] = [
  { EN: 'Service Agreement', DE: 'Dienstleistungsvertrag' },
  {
    EN: 'Non-Disclosure Agreement (NDA)',
    DE: 'Geheimhaltungsvereinbarung (NDA)',
  },
  { EN: 'Employment Contract', DE: 'Arbeitsvertrag' },
  { EN: 'Partnership Agreement', DE: 'Partnerschaftsvereinbarung' },
  { EN: 'Sales Contract', DE: 'Kaufvertrag' },
  { EN: 'Lease Agreement', DE: 'Mietvertrag' },
  { EN: 'Consulting Agreement', DE: 'Beratungsvertrag' },
  { EN: 'Supply Agreement', DE: 'Liefervertrag' },
  { EN: 'Licensing Agreement', DE: 'Lizenzvertrag' },
  { EN: 'Joint Venture Agreement', DE: 'Joint-Venture-Vertrag' },
  { EN: 'Distribution Agreement', DE: 'Vertriebsvereinbarung' },
  {
    EN: 'Confidentiality Agreement',
    DE: 'Vertraulichkeitsvereinbarung',
  },
  { EN: 'Freelancer Contract', DE: 'Freelancer-Vertrag' },
  { EN: 'Shareholder Agreement', DE: 'Gesellschaftervertrag' },
  { EN: 'Maintenance Agreement', DE: 'Wartungsvertrag' },
];

export const getContractsData = (locale: Locale): EcmDocumentsTableData[] =>
  Array.from({ length: 15 }, (_, index) => ({
    cursor: `eyJpdiI6Ii1wRGJPblBaWUFYbFdnaXVMbEcyWEEiLCJkYXRhIjoiVTh3QnFiaTlkLVljX2NLanlRbktDai11cjBJQkEwNFZPMXd0TWlvMW1BRkJHOTB${index}`,
    id: faker.uuid(),
    contractType: {
      __typename: 'EcmDocumentSubCategoryField',
      id: 'COMPANY_AGREEMENT',
      name: 'Company agreements',
    },
    globalDocumentId: faker.uuid(),
    contact: faker.companyName(),
    contractStatus: {
      status:
        index % 5 === 0
          ? EcmDocumentContractStatus.Inactive
          : EcmDocumentContractStatus.Active,
      isOverdue: false,
      isSensitive: false,
    },
    invoiceId: faker.uuid(),
    documentName: documentNames[index % documentNames.length][locale],
    documentNumber: '125294',
    costCenter: `212 - Cost ${index}`,
    tags: [],
    startDate: faker.date(),
    endDate: faker.date(),
    terminationDate: faker.date(),
    terminationReminderDate: faker.date(),
    responsiblePerson: [
      {
        __typename: 'User',
        id: faker.uuid(),
        name: faker.name(),
        avatarUrl: null,
      },
    ],
  }));

import { useApprovalReimbursementCasesCountQuery } from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useCurrentUser } from 'providers/CurrentUserProvider';

export const useApprovalReimbursementAssignedToMeCount = () => {
  const user = useCurrentUser();
  const { canUseReimbursement } = useReimbursement();

  const {
    data: assignedReimbursementsToMe,
    loading: isAssignedReimbursementsToMeLoading,
  } = useApprovalReimbursementCasesCountQuery({
    variables: { filters: { approversIds: [user?.id as string] } },
    skip: !user?.id || !canUseReimbursement,
  });

  return {
    count:
      assignedReimbursementsToMe?.approvalReimbursementCasesCount.totalCount ??
      0,
    isAssignedReimbursementsToMeLoading,
  };
};

import { SwimlaneTileShowAll } from 'components/Swimlane/content/SwimlaneTileShowAll';
import { CardStatus } from 'generated-types/graphql.types';
import { usePath } from 'hooks/usePath';

export const ShowAllRequests = ({ statues }: { statues: CardStatus[] }) => {
  const showAllLink = usePath({
    pathname: '/credit_cards/dashboard/card_manager_overview/:creditCardId?',
    search: {
      pendingRequest: 'true',
      status: statues,
    },
  });

  return <SwimlaneTileShowAll to={showAllLink} />;
};

import { Button, Grid } from '@candisio/design-system';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { useGetIdentitiyProviderQuery } from 'generated-types/graphql.types';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider/consts';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from 'views/Settings/components/SettingsLayout/SettingsLayout';
import { IdpSamlForm, IdpSamlFormProps } from './IdentityProvider/IdpSamlForm';

export interface SingleSignOnContainerProps {
  organizationSlug: string | null;
  id?: string;
  backToOrganization: () => void;
  navigateToSSOConfiguration: (ssoConfigurationId: string) => void;
}

export const SingleSignOnContainer = ({
  organizationSlug,
  id,
  backToOrganization,
  navigateToSSOConfiguration,
}: SingleSignOnContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const action = id ? 'edit' : 'create';

  const { data } = useGetIdentitiyProviderQuery({
    variables: {
      ssoConfigurationId: id ?? '',
    },
    skip: isNil(id),
  });

  const handleCreate = (ssoConfigurationId: string) => {
    void navigateToSSOConfiguration(ssoConfigurationId);
  };

  const identityProvider = data?.getIdentitiyProvider;
  const identityProviderFormProperties: IdpSamlFormProps = {
    action,
    organizationSlug: organizationSlug ?? '',
    onCreated: handleCreate,
  };

  if (
    identityProvider &&
    identityProvider.config.__typename === 'IdentityProviderSAML'
  ) {
    identityProviderFormProperties.ssoConfigurationId = id;
    identityProviderFormProperties.idpValues = {
      alias: identityProvider.alias,
      entityId: identityProvider.config.entityId,
      idpEntityId: identityProvider.config.idpEntityId,
      singleSignOnServiceUrl: identityProvider.config.singleSignOnServiceUrl,
      nameIDPolicyFormat: identityProvider.config.nameIDPolicyFormat,
      principalType: identityProvider.config.principalType,
      validateSignature: identityProvider.config.validateSignature,
      postBindingAuthnRequest:
        identityProvider.config.postBindingAuthnRequest ?? false,
      postBindingResponse: identityProvider.config.postBindingResponse ?? false,
      signingCertificate: identityProvider.config?.signingCertificate ?? null,
      syncMode: identityProvider.config?.syncMode ?? null,
    };
  }

  return (
    <>
      <Button icon="arrowLeft" variant="tertiary" onClick={backToOrganization}>
        {t('sso.buttons.backToOrganization')}
      </Button>
      <SettingsLayout width="700px" title={t('sso.title')}>
        <Grid alignContent="start" gap="space32">
          <IdpSamlForm {...identityProviderFormProperties} />
        </Grid>
        <InfoPanel
          externalLink={t('sso.infoPanel.externalLink', {
            returnObjects: true,
          })}
          sections={t('sso.infoPanel.sections', {
            returnObjects: true,
          })}
        />
      </SettingsLayout>
    </>
  );
};

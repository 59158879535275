import { IamAuthType } from 'generated-types/graphql.types';
import { noop } from 'lodash';
import {
  Dispatch,
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useReducer,
} from 'react';

interface ShowModalAction {
  action: 'showModal';
  authType: IamAuthType;
  /**
   * How long the user will be able to perform the same sensitive action before
   * needing to reauthenticate themselves again.
   */
  validForSeconds: number;
  /**
   * Optional callback that will be called once the user has successfully
   * reauthenticated.
   *
   * Use this callback to retry the initial GQL query/mutation that returned the
   * `RecentAuthnRequiredError` error.
   */
  onReauthenticated?: ReauthenticationContextProps['onReauthenticated'];
  /**
   * Optional title for the reauthentication modal, otherwise the default title
   * will be used.
   */
  modalTitle?: ReauthenticationContextProps['modalTitle'];
  /**
   * Optional side pane content for the reauthentication modal, otherwise the
   * default side pane content will be used.
   */
  modalSidePaneContent?: ReauthenticationContextProps['modalSidePaneContent'];
}

interface HideModalAction {
  action: 'hideModal';
}

type Action = ShowModalAction | HideModalAction;

interface ReauthenticationContextProps {
  modalVisibility: boolean;
  authType: IamAuthType;
  validForSeconds: number;
  onReauthenticated?: () => void | Promise<void>;
  modalTitle?: string;
  modalSidePaneContent?: ReactElement;
}

const ReauthenticationContext =
  createContext<ReauthenticationContextProps | null>(null);

const ReauthenticationDispatchContext = createContext<Dispatch<Action>>(noop);

const initializer = (): ReauthenticationContextProps => {
  return {
    modalVisibility: false,
    authType: IamAuthType.Mfa,
    validForSeconds: 0,
    modalSidePaneContent: undefined,
    modalTitle: undefined,
    onReauthenticated: undefined,
  };
};

const reducer = (
  state: ReauthenticationContextProps,
  action: Action
): ReauthenticationContextProps => {
  switch (action.action) {
    case 'showModal':
      return {
        ...state,
        modalVisibility: true,
        authType: action.authType,
        validForSeconds: action.validForSeconds,
        onReauthenticated: action.onReauthenticated,
        modalSidePaneContent: action.modalSidePaneContent,
        modalTitle: action.modalTitle,
      };

    case 'hideModal':
      return initializer();

    default:
      return state;
  }
};

export const useReauthenticationState = () => {
  const context = useContext(ReauthenticationContext);

  if (!context) {
    throw new Error(
      '`useReauthenticationState` must be used within `<ReauthenticationContext.Provider>`'
    );
  }

  return context;
};

export const useReauthenticationDispatch = () =>
  useContext(ReauthenticationDispatchContext);

export const ReauthenticationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, null, initializer);

  return (
    <ReauthenticationContext.Provider value={state}>
      <ReauthenticationDispatchContext.Provider value={dispatch}>
        {children}
      </ReauthenticationDispatchContext.Provider>
    </ReauthenticationContext.Provider>
  );
};

import { Flex, Grid } from '@candisio/design-system';
import { BackButton } from 'components/BackButton/BackButton';
import { WidgetFormContainer } from 'components/Form/WidgetFormContainer';
import { DateRangePicker } from 'components/Insights/Widgets/DateRangePicker/DateRangePicker';
import { NetGrossSelectorContainer } from 'components/Insights/Widgets/NetGrossSelector/NetGrossSelectorContainer';
import { Routes } from 'models';
import { AnimatePresence, motion } from 'motion/react';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useHistory, useParams } from 'react-router-dom';
import {
  InsightsWidgetsFiltersFromUrl,
  WidgetModalTab,
} from 'views/InsightsWidgets/utils';
import { useInsightsWidgets } from '../../../components/Insights/hooks/useInsightsWidgets';
import { DashboardWidgetsSection } from './components/DashboardWidgetsSection';
import { SecondaryWidgetsSection } from './components/SecondaryWidgetsSection';
import { fadeView, totalDurationInSeconds } from './styles';

interface AllInsightsWidgetsRouteParams {
  organizationSlug: string;
  widgetId?: string;
}

export const AllInsightsWidgets = () => {
  const history = useHistory();
  const [t] = useTranslation(LOCALE_NAME_SPACE.INSIGHTS);
  const { widgetId, organizationSlug } =
    useParams<AllInsightsWidgetsRouteParams>();

  const { dashboard, secondary } = useInsightsWidgets();

  const [showAnimation, setShowAnimation] = useState(false);

  const visible = Boolean(widgetId);

  const filtersFromUrl = qs.parse(
    window.location.search
  ) as InsightsWidgetsFiltersFromUrl;

  const { onDashboard, landingTab, ...remainingQueryParams } = filtersFromUrl;
  const onAddWidgetForDashboardSection = () => {
    history.replace({
      pathname: `/${organizationSlug}${Routes.INSIGHTS_WIDGETS}/create`,
      search: qs.stringify({
        ...filtersFromUrl,
        onDashboard: 'true',
        landingTab: WidgetModalTab.DETAILS,
      }),
    });
  };

  const onAddWidgetForSecondarySection = () => {
    history.replace({
      pathname: `/${organizationSlug}${Routes.INSIGHTS_WIDGETS}/create`,
      search: qs.stringify({
        ...filtersFromUrl,
        onDashboard: 'false',
        landingTab: WidgetModalTab.DETAILS,
      }),
    });
  };

  const onCloseModal = () => {
    history.replace({
      pathname: `/${organizationSlug}${Routes.INSIGHTS_WIDGETS}`,
      search: qs.stringify(remainingQueryParams),
    });
  };

  const backToDashboardView = () => {
    setShowAnimation(false);
    // Wait for animation to complete before navigating away
    setTimeout(() => {
      history.replace({
        pathname: `/${organizationSlug}${Routes.DASHBOARD}`,
        search: qs.stringify(remainingQueryParams),
      });
    }, totalDurationInSeconds * 1000);
  };

  useEffect(() => {
    setShowAnimation(true);
  }, []);

  return (
    <AnimatePresence>
      {showAnimation && (
        <motion.div
          style={{ height: '100%' }}
          animate={showAnimation ? 'visible' : 'hidden'}
          exit="hidden"
          key="main-view"
          variants={fadeView}
          initial={fadeView.hidden}
        >
          <Grid
            templateColumns="1fr 1fr"
            gap="space12"
            height="100%"
            templateRows="auto 1fr"
            overflow="hidden"
            padding="space20"
            paddingBottom="space4"
          >
            <Flex
              justifyContent="space-between"
              gridColumn="span 2"
              alignItems="center"
            >
              <BackButton onClick={backToDashboardView}>
                {t('allWidgets.backToDashboardButton.title')}
              </BackButton>
              <Flex gap="space4">
                <DateRangePicker
                  onApplyDateRange={dateRange => {
                    const search = qs.parse(window.location.search);
                    history.push({
                      search: qs.stringify({
                        ...search,
                        invoiceDate: dateRange,
                      }),
                    });
                  }}
                />
                <NetGrossSelectorContainer />
              </Flex>
            </Flex>

            <DashboardWidgetsSection
              onAddWidget={onAddWidgetForDashboardSection}
              dashboardWidgets={dashboard.widgets}
            />

            <SecondaryWidgetsSection
              onAddWidget={onAddWidgetForSecondarySection}
              secondarySectionWidgets={secondary.widgets}
            />
          </Grid>

          <WidgetFormContainer
            visible={visible}
            onCloseWidgetModal={onCloseModal}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

import { Button } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export interface CreatePerDiemActionMenuButtonProps {
  onCreatePerDiem: () => void;
  creatingPerDiem: boolean;
  isDisabled: boolean;
}

export const CreatePerDiemActionMenuButton = ({
  onCreatePerDiem,
  creatingPerDiem,
  isDisabled,
}: CreatePerDiemActionMenuButtonProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Button
      icon="plus"
      iconPosition="left"
      size="small"
      variant={isDisabled ? 'tertiary' : 'primary'}
      loading={creatingPerDiem}
      disabled={isDisabled || creatingPerDiem}
      onClick={onCreatePerDiem}
    >
      {t('reimbursementView.middleSection.form.perDiem.callToAction')}
    </Button>
  );
};

// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { useCallback } from 'react';
const COMPARE = 'compare';

export const useComparisonView = () => {
  const params = useParams<{ documentId: string }>();

  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const match = !!searchParams.get(COMPARE);

  const setComparisonMode = useCallback(
    (enable = true) => {
      const state = enable ? 'true' : '';
      updateSearchParam(COMPARE, state, { relative: 'path' });
    },
    [updateSearchParam]
  );

  const isComparisonView = !!match;

  if (!isComparisonView) return { isComparisonView, setComparisonMode };

  let documentId = null;
  if (params.documentId) documentId = params.documentId;

  return {
    isComparisonView,
    params: { documentId },
    setComparisonMode,
  };
};

import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import { netToGross } from 'containers/SplitBookings/toolkit/utils';
import { ReimbursementBookingInput } from 'generated-types/graphql.types';
import { formatDecimalToIntegerAmount } from 'hooks/useMoneyFormatter';
import { BookingValues } from '../toolkit/reimbursementSplitBookingsFormSchema';

export const sanitizeReimbursementBookingInput = (
  values: BookingValues
): ReimbursementBookingInput => {
  const updatedSplitAmount =
    values.taxPresentation === TaxPresentation.Gross
      ? values.splitAmount
      : netToGross(values.splitAmount ?? 0, values.vatRate ?? 0);

  return {
    id: values.bookingId ?? null,
    amount: formatDecimalToIntegerAmount(updatedSplitAmount),
    note: values.note ?? null,
    artistSocialInsuranceCode: values.artistSocialInsuranceCode ?? null,
    bookingKeyId: values.taxCode ?? null,
    costCenterId: values.costCenter?.value ?? null,
    costObjectId: values.costObject?.value ?? null,
    generalLedgerAccountId: values.generalLedgerAccount?.value ?? null,
    extraCostInfoId: values.extraCostInfo?.value ?? null,
    vatRate: values.vatRate ?? null,
    dueDate: null,
    postingText: values.postingText ?? null,
  };
};

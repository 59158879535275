import { Button, Card, CardProps, Grid, Text } from '@candisio/design-system';
import { motion } from 'motion/react';
import { forwardRef } from 'react';

export interface AssociateCardProps extends CardProps {
  footerText: string;
  ctaText: string;
  onAssociate?: () => void;
  loading?: boolean;
  warningText?: string;
}

export const AssociateCard = motion.create(
  forwardRef<HTMLDivElement, AssociateCardProps>(
    (
      { onAssociate, footerText, ctaText, loading, warningText, ...restProps },
      ref
    ) => {
      return (
        <Card
          background="blue100"
          corners="all"
          boxShadow="elevatedShadow5"
          paddingX="space36"
          maxHeight="space80"
          ref={ref}
          {...restProps}
        >
          <Grid
            alignItems="center"
            autoFlow="column"
            justifyContent="space-between"
          >
            <div className="flex flex-col">
              {warningText && <Text color="red500">{warningText}</Text>}
              <Text>{footerText}</Text>
            </div>
            <Button onClick={onAssociate} loading={loading}>
              {ctaText}
            </Button>
          </Grid>
        </Card>
      );
    }
  )
);

import { Box, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ProjectCodeImportHistoryItemData } from './types';
import { useTranslation } from 'react-i18next';

interface ImportStatusProps {
  item: ProjectCodeImportHistoryItemData;
}

export const ImportStatus = ({ item }: ImportStatusProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);
  const failedCount = item?.failedCounts ?? 0;
  const successCreatedCount = item?.successfullyCreatedEntitiesCount ?? 0;
  const successUpdatedCount = item?.successfullyUpdatedEntitiesCount ?? 0;
  const successCount = successCreatedCount + successUpdatedCount;
  const importTotal = failedCount + successCount;

  const importWasSuccessful = failedCount === 0;

  const hasApiError = !!item?.apiErrorMessage && importTotal === 0;

  if (hasApiError) {
    return (
      <TruncatedText color="gray500">
        {item.apiErrorMessage ?? ''}
      </TruncatedText>
    );
  }

  return (
    <Box>
      {importWasSuccessful
        ? t('projectCodes.importHistory.status.countSuccessfullyCreated', {
            count: successCount,
            successCount,
          })
        : t('projectCodes.importHistory.status.countWithFailed', {
            failedCount,
            importTotal,
            count: failedCount,
          })}
    </Box>
  );
};

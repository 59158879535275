import { gql } from 'graphql-tag';

export const getFileUploadAttemptsQuery = gql`
  query getFileUploadAttempts {
    getFileUploadAttempts {
      id
      createdAt
      documentId
      documentType
      errorMessages
      file {
        id
        name
        size
        contentType
      }
      globalDocumentId
      status
      updatedAt
      wasDeleted
    }
  }
`;

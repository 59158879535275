import { SwimlaneTileShowAll } from 'components/Swimlane/content/SwimlaneTileShowAll';
import { useReimbursementPath } from '../hooks/useReimbursementPath';
import { SummaryStatus } from './types';
import { useRef } from 'react';
import { getDateRangeForLastNDays } from 'utils/getDateRange';
import { formatDateRange } from 'utils/time';

export const ReimbursementTileShowAll = ({
  status,
}: {
  status: SummaryStatus;
}) => {
  const { fromDate, toDate } = useRef(
    getDateRangeForLastNDays({ days: 30 })
  ).current;
  const showLastClosedAt = status === 'closed';

  const path = useReimbursementPath(
    status,
    showLastClosedAt ? { lastClosedAt: formatDateRange(fromDate, toDate) } : {}
  );

  return <SwimlaneTileShowAll to={path} />;
};

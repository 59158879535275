import { Spinner } from '@candisio/design-system';
import { TruncatedText } from '@candisio/design-system/next';
import { FileUploadAttemptStatus } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';
import DocumentIcon from '../../assets/document.svg?react';
import styles from './UploadHistoryItem.module.css';

function usePendingProgressLabel(status: FileUploadAttemptStatus) {
  const [t] = useTranslation('navigation');

  if (status === FileUploadAttemptStatus.DocumentCreated) {
    return t('documentUpload.progress.classifying');
  } else if (status === FileUploadAttemptStatus.DocumentClassified) {
    return t('documentUpload.progress.extracting');
  } else {
    return t('documentUpload.progress.uploading');
  }
}

export const UploadHistoryItemPending = ({
  fileName,
  status,
}: { fileName: string; status: FileUploadAttemptStatus }) => {
  const label = usePendingProgressLabel(status);
  return (
    <div className={styles.item}>
      <div className="relative">
        <DocumentIcon className={styles.documentIcon} data-variant="pending" />
        <Spinner className="!absolute !bottom-1 !left-2.5 !size-4" />
      </div>
      <div className="flex flex-col gap-0.5">
        <TruncatedText className={styles.title}>{fileName}</TruncatedText>
        <span className={styles.progressText}>{label}</span>
      </div>
    </div>
  );
};

import { Box, BoxProps } from '@candisio/design-system';
import {
  LottieAnimation,
  pulseBlue,
  pulsePurple,
  pulseRed,
} from 'components/Lottie/Lottie';

export type IndicatorStatus = 'default' | 'promo' | 'urgent';

export const PulseIndicator = ({
  status,
  source,
  ...rest
}: {
  status?: IndicatorStatus;
  source?: string;
} & BoxProps) => {
  let animationData;
  switch (status) {
    case 'promo': {
      animationData = pulsePurple;
      break;
    }

    case 'urgent': {
      animationData = pulseRed;
      break;
    }

    case 'default':
    default: {
      animationData = pulseBlue;
      break;
    }
  }

  return (
    <Box
      width="space16"
      height="space16"
      aria-label={`${source}-indicator`}
      role="img"
      {...rest}
    >
      <LottieAnimation
        height="100%"
        width="100%"
        options={{
          animationData,
          loop: true,
        }}
      />
    </Box>
  );
};

import { Grid } from '@candisio/design-system';
import { DrawerMode } from 'containers/credit-cards/CreditCardDrawer/CreditCardDrawer';
import { useGetCardById } from 'containers/credit-cards/utils';
import { CardStatus, CardType } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { AccountingSettingsItem } from './AccountingSettingsItem';
import { CardLimitsItem } from './CardLimitsItem';
import { ReplacementCardItem } from './ReplacementCardItem';

const replaceableCardStatuses = [
  CardStatus.Active,
  CardStatus.Locked,
  CardStatus.LockedPin,
  CardStatus.Expired,
  CardStatus.Pending,
  CardStatus.Terminated,
];

interface CreditCardFormDataPreviewProps {
  loading: boolean;
  isCardReplaceable: boolean;
  hasAccountingData?: boolean;
  cardStatus?: CardStatus;
  cardType?: CardType;
  onClose?: () => void;
  onSetMode: (mode: DrawerMode) => void;
}

interface CreditCardFormDataPreviewContainerProps {
  cardId: string;
  onSetMode: (value: DrawerMode) => void;
}

export const CreditCardFormDataPreview = ({
  isCardReplaceable,
  loading,
  hasAccountingData,
  cardStatus,
  cardType,
  onSetMode,
}: CreditCardFormDataPreviewProps) => {
  const { isCardManager } = useUserRoles();
  const { showAllExtraFeatures } = useCreditCardsSetup();

  return (
    <>
      <CardLimitsItem cardType={cardType} onSetMode={onSetMode} />
      {showAllExtraFeatures && (
        <AccountingSettingsItem
          loading={loading}
          onSetMode={onSetMode}
          cardStatus={cardStatus}
          hasAccountingData={hasAccountingData}
        />
      )}
      {isCardReplaceable && isCardManager && (
        <ReplacementCardItem onClick={onSetMode} />
      )}
    </>
  );
};

export const CreditCardFormDataPreviewContainer = ({
  cardId,
  onSetMode,
}: CreditCardFormDataPreviewContainerProps) => {
  const { card, loading } = useGetCardById({ cardId });

  const isValidReplaceableStatus = card
    ? replaceableCardStatuses.includes(card?.status)
    : false;

  const replacedByCardId = card ? card.replacedByCardId : undefined;

  const isCardAlreadyReplaced = Boolean(replacedByCardId);

  const isCardReplaceable =
    isValidReplaceableStatus &&
    !isCardAlreadyReplaced &&
    card?.type !== CardType.SingleUse;

  const props = {
    loading,
    onSetMode,
    cardStatus: card?.status,
    cardType: card?.type,
    hasAccountingData: card?.hasAccountingData,
    isCardReplaceable,
  };

  return (
    <Grid height="100%">
      <CreditCardFormDataPreview {...props} />
    </Grid>
  );
};

import { useCountOpenApprovalsQuery } from 'generated-types/graphql.types';
import { NavigationIcon } from '../../NavigationIcon';
import { NavIconProps } from '../../../hooks/useNavigationIcons';
import { useApprovalReimbursementAssignedToMeCount } from '../../../hooks/useApprovalReimbursementAssignedToMeCount';

export const MenuApprovals = (iconProps: NavIconProps) => {
  const { data: approvalCount, loading: approvalCountLoading } =
    useCountOpenApprovalsQuery({
      fetchPolicy: 'cache-first',
    });

  const {
    count: approvalReimbursementsCount,
    isAssignedReimbursementsToMeLoading,
  } = useApprovalReimbursementAssignedToMeCount();

  const totalCount =
    (approvalCount?.countOpenApprovals.count ?? 0) +
    approvalReimbursementsCount;

  const isCountLoading =
    isAssignedReimbursementsToMeLoading || approvalCountLoading;

  return (
    <NavigationIcon
      {...iconProps}
      count={totalCount}
      countLoading={isCountLoading}
    />
  );
};

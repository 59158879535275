import { ProcessingFormFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { useGeneralLedgerAccountFieldItems } from 'components/Form/hooks/useGeneralLedgerAccountFieldItems';
import { useBookingAccountLazyQuery } from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { DEFAULT_DEBOUNCE_TIME } from 'hooks/useDebouncedSearch';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toAccountingDataFieldItem } from 'views/Inbox/DocumentProcessing/toAccountingDataFieldItem';

export interface UseGeneralLedgerAccountFieldOptionsOptions {
  skip?: boolean;
  ids?: string[];
  readableName?: string;
}

/** Returns options for general ledger account field */
export const useGeneralLedgerAccountFieldOptions = ({
  skip,
  ids,
  readableName,
}: UseGeneralLedgerAccountFieldOptionsOptions): ProcessingFormFieldOptions['generalLedgerAccount'] => {
  const [redableNameFilter, setReadableNameFilter] = useState<
    string | undefined
  >(readableName);

  const handleDebounceSearch = useMemo(() => {
    return debounce((value: string) => {
      setReadableNameFilter(value);
    }, DEFAULT_DEBOUNCE_TIME);
  }, []);

  useEffect(() => {
    return () => {
      handleDebounceSearch.cancel();
    };
  }, [handleDebounceSearch]);

  const { friendlyFormatGLA, normalizeGLA } = useAccountingNumberFormatters();
  const [bookingAccountQuery] = useBookingAccountLazyQuery();
  const getItem = useCallback(
    async (id: string) => {
      const { data } = await bookingAccountQuery({ variables: { id } });

      return data?.bookingAccount.__typename === 'BookingAccount'
        ? toAccountingDataFieldItem({
            id: data.bookingAccount.id,
            readableName: data.bookingAccount.readableName.replace(
              data.bookingAccount.accountCode,
              friendlyFormatGLA(data.bookingAccount.accountCode)
            ),
            code: data.bookingAccount.accountCode,
            name: data.bookingAccount.name,
          })
        : undefined;
    },
    [bookingAccountQuery, friendlyFormatGLA]
  );

  const { fetchMoreItems, items } = useGeneralLedgerAccountFieldItems({
    skip,
    ids,
    readableName: redableNameFilter,
  });

  if (skip) {
    return { hidden: true };
  }

  return {
    hidden: false,
    props: {
      getItem,
      items,
      onEndReached: fetchMoreItems,
      onSearch: async (value: string) => {
        // Workaround for issue with querying by readableName (see TG-2082)
        const [code, name] = normalizeGLA(value);
        handleDebounceSearch(code || name);
      },
    },
  };
};

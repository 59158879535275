import { TimelineEventWithMobileIndication } from 'components/DocumentHistory/history-components/TimelineEventWithMobileIndication';
import { ReimbursementCaseRejectedEventDataFragment } from 'generated-types/graphql.types';
import closeImage from 'components/DocumentHistory/history-components/images/iconApproveNot.svg';
import {
  CommentView,
  ItemApprovalStatus,
  RejectedIcon,
} from 'components/DocumentHistory/styles';
import { ApprovalStatus } from 'views/Inbox/models';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';

export const ReimbursementCaseRejectedComponent = ({
  clientInfo,
  properties,
}: ReimbursementCaseRejectedEventDataFragment) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <TimelineEventWithMobileIndication
      biggerPadding
      iconName="mobileReject"
      clientName={clientInfo?.name}
      eventComponent={
        <CommentView>
          <ItemApprovalStatus status={ApprovalStatus.DECLINED}>
            <RejectedIcon src={closeImage} alt={t('timeline.caseRejected')} />
            {t('timeline.caseRejected')}
          </ItemApprovalStatus>
          {properties?.comment?.text}
        </CommentView>
      }
    />
  );
};

import { Icon } from '@candisio/design-system';
import styles from './CardInfo.module.css';

export const CardInfo = ({
  name,
  refNum,
}: { name: string; refNum?: string | null }) => {
  return (
    <div className={styles.cardInfo}>
      <div className="flex items-center gap-1 !h-4">
        <Icon icon="visa" size="1.5rem" />
        <span className={styles.dot}>••••</span>
        {refNum && <span className={styles.text}>{refNum}</span>}
      </div>
      <p className={styles.text}>{name}</p>
    </div>
  );
};

import { Item, SelectField, Tag, Text } from '@candisio/design-system';
import { SearchScope } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useDefaultSearchInput } from './useDefaultSearchInput';

interface SearchFilterScopeSelectItemsProps {
  id: string;
  name: string;
  upgrade?: boolean;
}

interface SearchFilterScopeSelectProps {
  value: string;
  onChange: (value: string) => void;
  items: SearchFilterScopeSelectItemsProps[];
}

export function SearchScopeSelect({
  value,
  onChange,
  items,
}: SearchFilterScopeSelectProps) {
  const { DEFAULT_SEARCH_SCOPE } = useDefaultSearchInput();
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);

  return (
    <SelectField
      label={t('searchFilter.popover.fields.searchScope.label')}
      aria-label={t('searchFilter.popover.fields.searchScope.placeholder')}
      select={{
        items,
        placeholder: t('searchFilter.popover.fields.searchScope.placeholder'),
        children: (item: SearchFilterScopeSelectItemsProps) => {
          return (
            <Item key={item.id} textValue={item.name}>
              {item.name}
              {item.id === DEFAULT_SEARCH_SCOPE && (
                <Text color="gray500">
                  &nbsp;({t('searchFilter.popover.fields.default')})
                </Text>
              )}
              {item.upgrade && (
                <>
                  &nbsp;
                  <Tag color="purple" variant="secondary">
                    {t('common:tag.addOn')}
                  </Tag>
                </>
              )}
            </Item>
          );
        },
        defaultSelectedKey: value || DEFAULT_SEARCH_SCOPE,
        onSelectionChange: v => onChange?.(v as SearchScope),
      }}
    />
  );
}

import { ComponentProps, ReactNode, useEffect, useState } from 'react';
import { SortingRule } from 'react-table';
import { EcmDocumentsTableV2 } from './EcmDocumentsTableV2';
import { EcmDocumentsTableData } from '../types';

interface EcmDocumentsTableV2AdapterProps
  extends Omit<
    ComponentProps<typeof EcmDocumentsTableV2>,
    'sorting' | 'setSorting'
  > {
  sortBy: SortingRule<object>[];
  onSort: (sortBy: SortingRule<EcmDocumentsTableData>[]) => void;
  children?: ReactNode;
}

export const EcmDocumentsTableV2Adapter = ({
  sortBy,
  onSort,
  children,
  ...props
}: EcmDocumentsTableV2AdapterProps) => {
  const [sorting, setSorting] = useState<
    NonNullable<ComponentProps<typeof EcmDocumentsTableV2>['sorting']>
  >(
    sortBy.flatMap(sort => {
      if (sort.desc === undefined) return [];

      return [
        {
          id: sort.id,
          desc: sort.desc,
        },
      ];
    })
  );

  useEffect(() => {
    onSort(sorting);
  }, [sorting, onSort]);

  return (
    <EcmDocumentsTableV2 {...props} sorting={sorting} setSorting={setSorting} />
  );
};

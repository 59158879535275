import { TruncatedText } from '@candisio/design-system/next';
import { CellContext } from '@tanstack/react-table';
import { friendlyFormatIBAN } from 'ibantools';
import { isNil } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type IbanCellProps<
  TTableData extends object,
  TCellValue = ReactNode,
> = CellContext<TTableData, TCellValue | undefined>;

/** IBAN cell */
export const IbanCell = <TTableData extends object>({
  getValue,
}: IbanCellProps<TTableData>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.DOCUMENTS_TABLE);
  const value = getValue();

  if (isNil(value)) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  if (typeof value === 'string') {
    const formattedIban = friendlyFormatIBAN(value);

    if (formattedIban) return <TruncatedText>{formattedIban}</TruncatedText>;
  }

  return <>{value}</>;
};

import {
  CustomEmptyStateProps,
  Grid,
  Paragraph,
  SelectionOptions,
  useTheme,
} from '@candisio/design-system';
import { RouterLink } from 'components/RouterLink/RouterLink';
import { AssociateCard } from 'components/Transactions/Table/AssociateCard/AssociateCard';
import { Maybe } from 'generated-types/resolvers-types';
import { useLocalSortAndFilters } from 'hooks/table/useLocalSortAndFilters';
import { compiledRoutes } from 'models';
import { AnimatePresence } from 'motion/react';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseOrdersEmptyState } from 'views/Archive/PurchaseOrders/PurchaseOrdersEmptyState';
import {
  PURCHASE_ORDER_FILTER,
  usePurchaseOrderListFilters,
} from 'views/Archive/PurchaseOrders/usePurchaseOrderListFilters';
import { purchaseOrderTableColumns } from 'views/Archive/PurchaseOrders/utils';
import { useBookingsFormContext } from 'views/DocumentDetails/BookingsFormContext';

import { getFiltersWithContact } from 'components/PurchaseOrders/SelectPurchaseOrderContainer/utils';
import { PurchaseOrdersTable } from 'components/PurchaseOrders/Table/PurchaseOrdersTable';
import {
  ColumnWidths,
  PurchaseOrdersTableData,
} from 'components/PurchaseOrders/Table/types';
import { LinkingFooterSource } from './LinkingFooter';
import { useLinkPurchaseOrdersToDocument } from './hooks/useLinkPurchaseOrdersToDocument';
import { AssociatedPurchaseOrder } from '../types';

export interface PurchaseOrderListProps {
  documentId: string;
  searchValue: string;
  maxSelectableItems: number;
  documentAmount?: number | null;
  accountsPayableNumber?: Maybe<string>;
  linkedPurchaseOrders: AssociatedPurchaseOrder[];
  closeModal: () => void;
  onResetSearch: () => void;
}

export const PurchaseOrderList = ({
  documentId = '',
  documentAmount,
  accountsPayableNumber,
  searchValue,
  maxSelectableItems,
  linkedPurchaseOrders,
  onResetSearch,
  closeModal,
}: PurchaseOrderListProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { space } = useTheme();
  const { filters, sortBy, onSort, onFilter } =
    useLocalSortAndFilters<PurchaseOrdersTableData>();

  const defaultFilters = useMemo(
    () =>
      getFiltersWithContact({
        filters,
        accountsPayableNumber,
      }),
    [filters, accountsPayableNumber]
  );
  const orgId = useOrganizationId();

  const linkedPurchaseOrderCurrency = linkedPurchaseOrders[0]?.amount?.currency;

  const alreadyLinkedPurchaseOrderNumbers = useMemo(
    () =>
      (linkedPurchaseOrders ?? [])
        .map(po => po?.orderNumber)
        .filter(Boolean) as string[],
    [linkedPurchaseOrders]
  );

  const { filterOptions } = usePurchaseOrderListFilters([
    PURCHASE_ORDER_FILTER.invoiceAssociationStatus_archive,
  ]);
  const getRowStatus = useCallback(
    (row: PurchaseOrdersTableData) => {
      if (
        !!linkedPurchaseOrderCurrency &&
        row.grossAmount?.currency !== linkedPurchaseOrderCurrency
      ) {
        return {
          isSelectable: false,
          disabledReason: t('cantSelectPurchaseOrderWithDifferentCurrency'),
        };
      }

      if (
        alreadyLinkedPurchaseOrderNumbers?.includes(row?.orderNumber as string)
      ) {
        return {
          isSelectable: false,
          disabledReason: t('cantSelectPurchaseOrderAlreadyLinked'),
        };
      }

      if (!row.goodsReceiptCount?.open) {
        return {
          isSelectable: false,
          disabledReason: t('cantSelectPurchaseOrderWithNoOpenGoodsReceipt'),
        };
      }

      return {
        isSelectable: true,
        disabledReason: '',
      };
    },
    [linkedPurchaseOrderCurrency, alreadyLinkedPurchaseOrderNumbers, t]
  );
  const { setShouldResetForm } = useBookingsFormContext();
  const { linkPurchaseOrder, isLinkingLoading } =
    useLinkPurchaseOrdersToDocument({
      documentId,
      onCompleted: () => {
        setShouldResetForm?.(true);
      },
    });

  const [selectedPurchaseOrders, setSelectedPurchaseOrders] = useState<
    PurchaseOrdersTableData[]
  >([]);
  const [showMaxCountWarning, setShowMaxCountWarning] = useState(false);

  const handleLinkPurchaseOrderToDocument = async () => {
    if (!selectedPurchaseOrders.length || isLinkingLoading) {
      return;
    }

    await linkPurchaseOrder(selectedPurchaseOrders);

    closeModal();
  };

  const selectionOptions: SelectionOptions<PurchaseOrdersTableData> =
    useMemo(() => {
      return {
        selectCheckboxProps: row => {
          const { isSelectable, disabledReason } = getRowStatus(row);
          if (!isSelectable) {
            return {
              isDisabled: true,
              disabledReason: disabledReason,
            };
          }

          return { isVisible: true };
        },
        onSelectionRowChanged: purchaseOrders => {
          if (isLinkingLoading || purchaseOrders.length > maxSelectableItems) {
            setShowMaxCountWarning(true);
            return;
          }
          setShowMaxCountWarning(false);
          setSelectedPurchaseOrders(purchaseOrders);
        },
        selectedRowsIds: [],
      };
    }, [isLinkingLoading, maxSelectableItems, getRowStatus]);

  const arePurchaseOrdersSelected = selectedPurchaseOrders.length > 0;

  const selectedRowIds = useMemo(
    () => selectedPurchaseOrders.map(po => po.id),
    [selectedPurchaseOrders]
  );

  const emptyState = ({ resetFilters }: CustomEmptyStateProps) => (
    <PurchaseOrdersEmptyState
      isTableFiltered={Boolean(filters.length)}
      arePurchaseOrdersSelected={arePurchaseOrdersSelected}
      isUsingSearchQuery={!!searchValue}
      resetFilters={resetFilters}
      resetSearch={onResetSearch}
    />
  );

  const columnWidths: ColumnWidths = {
    connectedInvoices: space.space256,
    lineItems: space.space256,
    goodsReceiptCount: space.space200,
  };

  const visibleColumns = useMemo(
    () =>
      accountsPayableNumber
        ? purchaseOrderTableColumns.filter(col => col !== 'contact')
        : purchaseOrderTableColumns,
    [accountsPayableNumber]
  );

  return (
    <>
      <PurchaseOrdersTable
        documentAmount={documentAmount}
        columns={visibleColumns}
        onFilter={onFilter}
        onSort={onSort}
        customEmptyState={emptyState}
        filterOptions={filterOptions}
        columnWidths={columnWidths}
        defaultFilters={defaultFilters}
        defaultSortBy={sortBy}
        selectionOptions={selectionOptions}
        searchValue={searchValue}
        borderTopRadius="none"
        showFooterText
        isSingleSelect={!accountsPayableNumber}
        selectedRowIds={selectedRowIds}
        tableFooter={
          <Grid placeContent="center" padding="space24">
            <Paragraph maxWidth="80%" textAlign="center">
              <Trans
                i18nKey="addPurchaseOrderModal.footerText"
                ns={LOCALE_NAME_SPACE.PURCHASE_ORDERS}
              >
                Can't find the purchase order? Then please check the selected
                contact. You can also see the latest orders from
                <RouterLink
                  to={`/${orgId}${compiledRoutes.allPurchaseOrdersImportHistoryRoute}`}
                >
                  SAP Import History
                </RouterLink>
              </Trans>
            </Paragraph>
          </Grid>
        }
      />
      <LinkingFooterSource>
        <AnimatePresence>
          {selectedPurchaseOrders.length && (
            <AssociateCard
              onAssociate={handleLinkPurchaseOrderToDocument}
              footerText={t(
                'addPurchaseOrderModal.footer.footerWhenDocumentSelected'
              )}
              ctaText={t('addPurchaseOrderModal.footer.buttonCTA')}
              flex="none"
              width="100%"
              position="absolute"
              bottom={0}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: '50%', opacity: 0 },
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              loading={isLinkingLoading}
              warningText={
                !!accountsPayableNumber && showMaxCountWarning
                  ? t('addPurchaseOrderModal.footer.allowedSelectCount', {
                      maxCount: maxSelectableItems,
                    })
                  : undefined
              }
            />
          )}
        </AnimatePresence>
      </LinkingFooterSource>
    </>
  );
};

import { PurchaseOrdersTableData } from 'components/PurchaseOrders/Table/types';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useGetHandleUpdateConfigurations } from 'hooks/table/useGetHandleUpdateConfigurations';
import { useGetTableConfigurations } from 'hooks/table/useGetTableConfigurations';
import { useUpdateColumnsBE } from 'hooks/table/useUpdateColumnsBE';
import { useResetTableConfigurations } from 'hooks/useResetTableConfigurations';
import { useSaveTableConfigurations } from 'hooks/useSaveTableConfigurations';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { Filters, SortingRule } from 'react-table';

interface Params {
  filters: Filters<PurchaseOrdersTableData>;
  sortBy: SortingRule<PurchaseOrdersTableData>[];
}

export const purchaseOrderTableId =
  'archive-purchase-order-table-configurations';

export const defaultColumnsConfigurations: Configuration[] = [
  {
    id: 'contact',
    isVisible: true,
    label: 'purchase-orders:columns.contact',
    isFixed: true,
  },
  {
    id: 'grossAmount',
    isVisible: true,
    label: 'purchase-orders:columns.grossAmount',
    isFixed: true,
  },
  {
    id: 'orderNumber',
    isVisible: true,
    label: 'purchase-orders:columns.orderNumber',
  },
  {
    id: 'supplierRefNumber',
    isVisible: true,
    label: 'purchase-orders:columns.supplierRefNumber',
  },
  {
    id: 'postingDate',
    isVisible: true,
    label: 'purchase-orders:columns.postingDate',
  },
  {
    id: 'status',
    isVisible: true,
    label: 'purchase-orders:columns.status',
  },
  {
    id: 'connectedInvoices',
    isVisible: true,
    label: 'purchase-orders:columns.connectedInvoices',
  },
  {
    id: 'lineItems',
    isVisible: true,
    label: 'purchase-orders:columns.lineItems',
  },
];

export const useGetPurchaseOrdersTableConfigs = ({
  filters,
  sortBy,
}: Params) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);
  const { success, error } = useToastMessage();
  const { configurations } = useGetTableConfigurations({
    tableId: purchaseOrderTableId,
    defaultColumnsConfigurations,
  });

  const { isSavingConfigurations, saveTableConfigurations } =
    useSaveTableConfigurations();

  const { isResetPending, resetTableConfigurations } =
    useResetTableConfigurations();

  const handleSaveTableConfigurations = async (
    configurations: Configuration[]
  ) => {
    const response = await saveTableConfigurations({
      configurations,
      tableId: purchaseOrderTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.successMessage'));
    } else {
      error(t('configurations.errorMessage'));
    }
  };

  const handleResetTableConfigurations = async () => {
    const response = await resetTableConfigurations({
      tableId: purchaseOrderTableId,
    });

    if (response.status === 'success') {
      success(t('configurations.resetSuccessMessage'));
    } else {
      error(t('configurations.resetErrorMessage'));
    }
  };

  const { handleUpdateConfigurations } = useGetHandleUpdateConfigurations({
    filters,
    sortBy,
    updateConfigurationsTable: handleSaveTableConfigurations,
  });

  const visibleColumnIds = configurations
    .filter(config => config.isVisible)
    .map(config => config.id as keyof PurchaseOrdersTableData);

  useUpdateColumnsBE({
    tableId: purchaseOrderTableId,
    defaultColumnsConfigurations,
  });

  return {
    availablePurchaseOrdersColumnIds: visibleColumnIds,
    configurationsTable: configurations,
    isSavingConfigurations,
    isResetPending,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  };
};

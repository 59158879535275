import { Tag, TagProps } from '@candisio/design-system';
import { StatusIcon } from 'components/DocumentsTable/Cells/StatusCell';
import {
  EcmDocumentStatus,
  ReimbursementCaseStatus,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { EInvoiceInfoIcon } from 'views/Inbox/DocumentProcessing/components/EInvoiceInfoIcon';
import { useEcmDocumentStatusOptions } from '../../hooks/useEcmDocumentStatusOptions';
import { EcmDocumentStatusWithTypeFlags } from '../../types';
import { memo } from 'react';

const statuses: Record<EcmDocumentStatus | string, TagProps['color']> = {
  PROCESSING: 'yellow',
  NEW: 'gray',
  OPEN: 'yellow',
  REJECTED: 'red',
  APPROVED: 'green',
  EXPORTED: 'blue',
  EXPORTING: 'yellow',
  STORED: 'gray',
  ARCHIVED: 'gray',
  [ReimbursementCaseStatus.Check]: 'red',
  [ReimbursementCaseStatus.Review]: 'yellow',
  [ReimbursementCaseStatus.Draft]: 'gray',
  [ReimbursementCaseStatus.PartiallyExported]: 'yellow',
  [ReimbursementCaseStatus.Approving]: 'yellow',
};

type Value = EcmDocumentStatusWithTypeFlags;

/**
 * A cell that displays the status of an ECM document.
 */
const StatusCellInner = ({
  isSensitive,
  isEInvoice,
  status,
}: Partial<Value>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const { ecmDocumentStatusTranslationMap } = useEcmDocumentStatusOptions();

  if (
    !status ||
    !ecmDocumentStatusTranslationMap?.[status] ||
    !statuses?.[status]
  ) {
    return <span>{t('emptyCellPlaceholder')}</span>;
  }

  return (
    <div className="grid items-center grid-flow-col justify-start gap-1">
      <Tag color={statuses[status]} variant="secondary">
        {ecmDocumentStatusTranslationMap[status]}
      </Tag>
      {isSensitive && (
        <StatusIcon
          label={t('contractStatus.sensitive')}
          icon="lockFilled"
          color="gray500"
        />
      )}
      {isEInvoice && <EInvoiceInfoIcon isEInvoice={isEInvoice} />}
    </div>
  );
};

export const StatusCell = memo(StatusCellInner);

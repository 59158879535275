import debounce from 'debounce-promise';
import {
  useProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery,
  useProcessingFormGeneralLedgerAccountFieldItemsQuery,
} from 'generated-types/graphql.types';
import { useAccountingNumberFormatters } from 'hooks/useAccountingNumberFormatters';
import { useMemo } from 'react';
import { toAccountingDataFieldItem } from 'views/Inbox/DocumentProcessing/toAccountingDataFieldItem';

export interface UseGeneralLedgerAccountFieldItemsOptions {
  readableName?: string;
  skip?: boolean;
  ids?: string[];
}

/** Fetches items for general ledger account field */
export const useGeneralLedgerAccountFieldItems = ({
  readableName,
  skip,
  ids,
}: UseGeneralLedgerAccountFieldItemsOptions) => {
  const { friendlyFormatGLA } = useAccountingNumberFormatters();
  const byName = useProcessingFormGeneralLedgerAccountFieldItemsQuery({
    variables: { readableName },
    skip,
  });

  const byIds = useProcessingFormGeneralLedgerAccountFieldItemsByIdsQuery({
    variables: { ids },
    skip: !ids,
  });

  const { data, refetch, fetchMore } = ids ? byIds : byName;

  const items = (data?.generalLedgerAccountsPagination?.records ?? []).map(
    item => {
      return toAccountingDataFieldItem({
        id: item.id,
        readableName: item.readableName.replace(
          item.accountCode,
          friendlyFormatGLA(item.accountCode)
        ),
      });
    }
  );

  const refetchItems = useMemo(() => debounce(refetch, 500), [refetch]);

  const pageCount =
    data?.generalLedgerAccountsPagination.pageInfo.pageCount ?? 1;

  const currentPage =
    data?.generalLedgerAccountsPagination.pageInfo.currentPage ?? 1;

  const nextPage = currentPage + 1;

  const fetchMoreItems = async () => {
    if (nextPage <= pageCount) {
      await fetchMore({ variables: { page: nextPage } });
    }
  };

  return { items, refetchItems, fetchMoreItems };
};

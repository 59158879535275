import { Grid, Text } from '@candisio/design-system';
import {
  AssociationSource,
  Maybe,
  PurchaseOrderAssociationWithGoodsReceipts,
  GoodsReceiptAssociationWithPurchaseOrders,
  DocumentAssociationsCreatedEvent,
} from 'generated-types/graphql.types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const getPurchaseOrderData = (
  associatedPurchaseOrdersWithGoodsReceipts: Maybe<
    Maybe<PurchaseOrderAssociationWithGoodsReceipts>[]
  >
) => {
  const orderNumbers = (associatedPurchaseOrdersWithGoodsReceipts ?? [])
    .map(item => item?.orderNumber ?? '')
    .filter(Boolean);

  const relatedGoodsReceiptNumbers = (
    associatedPurchaseOrdersWithGoodsReceipts ?? []
  )
    .flatMap(i => i?.associatedGoodsReceipts)
    .map(item => item?.receiptNumber ?? '')
    .filter(Boolean);

  return { orderNumbers, relatedGoodsReceiptNumbers };
};

export const getGoodsReceiptData = (
  associatedGoodsReceiptsWithPurchaseOrders: Maybe<
    Maybe<GoodsReceiptAssociationWithPurchaseOrders>[]
  >
) => {
  const receiptNumbers = (associatedGoodsReceiptsWithPurchaseOrders ?? [])
    .map(item => item?.receiptNumber ?? '')
    .filter(Boolean);

  const relatedPurchaseOrderNumbers = (
    associatedGoodsReceiptsWithPurchaseOrders ?? []
  )
    .flatMap(i => i?.associatedPurchaseOrders)
    .map(item => item?.orderNumber ?? '')
    .filter(Boolean);

  return { receiptNumbers, relatedPurchaseOrderNumbers };
};

export const DocumentAssociationsCreatedEventComponent = ({
  associatedPurchaseOrdersWithGoodsReceipts,
  associatedGoodsReceiptsWithPurchaseOrders,
}: DocumentAssociationsCreatedEvent) => {
  const [t] = useTranslation();

  const associatedPurchaseOrdersWithGoodsReceiptsLength =
    associatedPurchaseOrdersWithGoodsReceipts?.length ?? 0;
  const associatedGoodsReceiptsWithPurchaseOrdersLength =
    associatedGoodsReceiptsWithPurchaseOrders?.length ?? 0;

  const totalLength =
    associatedPurchaseOrdersWithGoodsReceiptsLength +
    associatedGoodsReceiptsWithPurchaseOrdersLength;

  const linkingSource = useMemo(() => {
    if (!totalLength) return null;
    return associatedPurchaseOrdersWithGoodsReceiptsLength
      ? AssociationSource.PurchaseOrder
      : AssociationSource.GoodsReceipt;
  }, [totalLength, associatedPurchaseOrdersWithGoodsReceiptsLength]);

  const { orderNumbers, relatedGoodsReceiptNumbers } = getPurchaseOrderData(
    associatedPurchaseOrdersWithGoodsReceipts
  );
  const { receiptNumbers, relatedPurchaseOrderNumbers } = getGoodsReceiptData(
    associatedGoodsReceiptsWithPurchaseOrders
  );

  const eventText = useMemo(() => {
    if (linkingSource === AssociationSource.PurchaseOrder) {
      return t(
        'document.compact.history.documentAssociationsCreatedEvent.purchaseOrderWithGoodsReceipt',
        {
          orderNumber: orderNumbers.join(', '),
          goodsReceiptLabel: t(
            'document.compact.history.documentAssociationsCreatedEvent.relatedGoodsReceipt',
            {
              receiptNumber: relatedGoodsReceiptNumbers.join(', '),
              count: relatedGoodsReceiptNumbers.length,
            }
          ),
          count: orderNumbers.length,
        }
      );
    } else {
      const relatedPurchaseOrdersLabel = relatedPurchaseOrderNumbers.length
        ? t(
            'document.compact.history.documentAssociationsCreatedEvent.relatedPurchaseOrder',
            {
              orderNumber: relatedPurchaseOrderNumbers.join(', '),
              count: relatedPurchaseOrderNumbers.length,
            }
          )
        : '';

      return t(
        relatedPurchaseOrdersLabel
          ? 'document.compact.history.documentAssociationsCreatedEvent.goodsReceiptWithPurchaseOrder'
          : 'document.compact.history.documentAssociationsCreatedEvent.standAloneGoodsReceipt',
        {
          receiptNumber: receiptNumbers.join(', '),
          purchaseOrderLabel: relatedPurchaseOrdersLabel,
          count: receiptNumbers.length,
        }
      );
    }
  }, [
    linkingSource,
    orderNumbers,
    relatedGoodsReceiptNumbers,
    receiptNumbers,
    relatedPurchaseOrderNumbers,
    t,
  ]);
  if (!totalLength) return null;

  return (
    <Grid>
      <Text>{eventText}</Text>
    </Grid>
  );
};

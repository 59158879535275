import { createKeyStoreProvider } from '@candis/utils';
import { ReactNode } from 'react';

const [Provider, useProvider] = createKeyStoreProvider<AppLayoutType>(
  'AppLayoutProvider',
  'useAppLayout'
);

type AppLayoutType = {
  docUploadPopover: {
    open: boolean;
    isRestrictedContent?: boolean;
  };
};

const initialValue = {
  docUploadPopover: {
    open: false,
    isRestrictedContent: undefined,
  },
};

export const AppLayoutProvider = ({ children }: { children: ReactNode }) => {
  return <Provider initialValue={initialValue}>{children}</Provider>;
};

export const useAppLayout = () => {
  const [useValue, setValue] = useProvider();
  return { useLayoutValue: useValue, setLayoutValue: setValue };
};

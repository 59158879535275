import { usePerDiemLocationsQuery } from 'generated-types/graphql.types';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useMemo } from 'react';

export type PerDiemLocationItem = {
  id: string;
  countryIsoCode: string;
  region: string;
  country: string;
  location?: string;
};

interface PerDiemLocationFilter {
  endDate: string;
}

export const usePerDiemLocations = ({ endDate }: PerDiemLocationFilter) => {
  const { canUsePerDiemReimbursement } = useReimbursement();

  const { data, loading } = usePerDiemLocationsQuery({
    variables: {
      input: {
        endAt: endDate,
      },
    },
    skip: !canUsePerDiemReimbursement || !endDate,
  });

  const locations = useMemo(
    (): PerDiemLocationItem[] =>
      (data?.perDiemLocations.locations ?? []).map(loc => ({
        id: loc.id,
        region: loc.region,
        country: loc.country,
        countryIsoCode: loc.countryIsoCode,
        location: loc.location ?? undefined,
      })),
    [data]
  );

  return {
    locations,
    isLoading: loading,
  };
};

import {
  CustomEmptyStateProps,
  Flex,
  Grid,
  useTheme,
} from '@candisio/design-system';
import { AdvancedSearchField } from 'components/AdvancedSearchField/AdvancedSearchField';
import { BaselineSearchPromotionContainer } from 'components/BaselineSearchPromotion/BaselineSearchPromotionContainer';
import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import {
  DocumentsTable,
  defaultSortByFields,
} from 'components/DocumentsTable/DocumentsTable';
import {
  ColumnWidths,
  DocumentTableColumnKeys,
  DocumentsTableData,
} from 'components/DocumentsTable/types';
import { ConfigurationsMenu } from 'components/Table/Configurations/ConfigurationsMenu';
import { Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { RouteComponentProps } from 'react-router-dom';
import { appendParamsToQueryString } from 'utils/url-helper';
import { MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH } from 'views/consts';
import { DocumentsHeader } from '../components/DocumentsHeader';
import { InboxViewContainer } from '../components/InboxViewContainer';
import { TabView } from '../models';
import { InboxDocumentsEmptyState } from './InboxDocumentsEmptyState/InboxDocumentsEmptyState';
import { useInboxDocumentsColumnsConfigurations } from './hooks/useInboxDocumentsColumnsConfigurations';
import { useInboxDocumentsData } from './hooks/useInboxDocumentsData';
import { useInboxDocumentsDataDeprecated } from './hooks/useInboxDocumentsDataDeprecated';
import { useInboxDocumentsFilters } from './hooks/useInboxDocumentsFilters';

type Props = RouteComponentProps<{
  organizationSlug: string;
}>;

export const InboxDocumentsContainer = (props: Props) => {
  const { useNewInboxQuery } = useEcm();

  return useNewInboxQuery ? (
    <InboxDocuments {...props} />
  ) : (
    <InboxDocumentsDeprecated {...props} />
  );
};

const InboxDocuments = (props: Props) => {
  const inboxDocumentDataProps = useInboxDocumentsData();
  return (
    <InboxDocumentsComponent
      {...props}
      inboxDocumentDataProps={inboxDocumentDataProps}
      shouldEnableSortByWithSearchQuery={true}
    />
  );
};

const InboxDocumentsDeprecated = (props: Props) => {
  const inboxDocumentDataProps = useInboxDocumentsDataDeprecated();

  return (
    <InboxDocumentsComponent
      {...props}
      inboxDocumentDataProps={inboxDocumentDataProps}
      shouldEnableSortByWithSearchQuery={false}
    />
  );
};

type InboxDocumentsComponentProps = Props & {
  inboxDocumentDataProps:
    | ReturnType<typeof useInboxDocumentsDataDeprecated>
    | ReturnType<typeof useInboxDocumentsData>;
  shouldEnableSortByWithSearchQuery: boolean;
};

const InboxDocumentsComponent = ({
  location: { search },
  match: {
    params: { organizationSlug },
  },
  inboxDocumentDataProps,
  shouldEnableSortByWithSearchQuery,
}: InboxDocumentsComponentProps) => {
  const [t] = useTranslation();
  const { space } = useTheme();
  const { showConsistentSortAndFiltering } = useEcm();
  const {
    documentEdges,
    loading,
    onLoadMore,
    documentsTableData,
    filters,
    hasMoreData,
    onFilter,
    isTableEmpty,
    isTableFiltered,
    onSort,
    sortBy,
    handleDebounceSearch,
    searchQuery,
  } = inboxDocumentDataProps;

  const {
    availableDocumentColumnIds,
    configurationsTable,
    isLoadingConfigs,
    isResetPending,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
  } = useInboxDocumentsColumnsConfigurations({ sortBy, filters });

  const firstDocumentEdge = documentEdges[0];
  const searchWithCursor = appendParamsToQueryString(search, {
    cursor: firstDocumentEdge?.cursor,
  });

  const columnWidths: ColumnWidths = {
    contact: space.space256,
    discountDateWPercentage: space.space256,
    iban: space.space200,
    invoiceNumber: space.space200,
    purchaseOrderNumber: space.space200,
    goodsReceiptNumber: space.space200,
    sapPurchaseOrderNumber: space.space200,
  };

  const filterOptions = useInboxDocumentsFilters();
  const startProcessingLink = `/${organizationSlug}${Routes.INBOX}/${firstDocumentEdge?.node.id}?${searchWithCursor}`;

  const customEmptyState = memo(({ resetFilters }: CustomEmptyStateProps) => (
    <InboxDocumentsEmptyState
      isTableEmpty={isTableEmpty}
      isTableFiltered={isTableFiltered}
      resetFilters={resetFilters}
    />
  ));

  const getPath = useCallback(
    ({ id, cursor }: DocumentsTableData) => {
      const searchWithCursor = appendParamsToQueryString(search, {
        cursor: cursor ?? null,
      });

      return `/${organizationSlug}${Routes.INBOX}/${id}?${searchWithCursor}`;
    },
    [search, organizationSlug]
  );

  const cellWrapper = useMemo(() => getCellWrapperLink(getPath), [getPath]);
  const cellStyle = () => ({
    padding: 'unset',
  });

  const showLoadingMoreSpinner = documentsTableData.length > 1;

  const infiniteScrollOptions = {
    dataLength: documentsTableData?.length,
    hasMore: hasMoreData,
    showLoadingMoreSpinner: !loading && showLoadingMoreSpinner,
    next: onLoadMore,
  };

  const columnsHash = availableDocumentColumnIds.join('-');
  const tableKey = `documents-inbox-table-filters-${columnsHash}`;

  const sortByFields: DocumentTableColumnKeys[] = showConsistentSortAndFiltering
    ? [
        ...defaultSortByFields,
        'invoiceNumber',
        'purchaseOrderNumber',
        'artistSocialInsuranceCode',
        'costObject',
        'costCenter',
        'extraCostInfo',
        'deliveryDate',
      ]
    : defaultSortByFields;

  return (
    <>
      <DocumentsHeader
        documentsLength={documentEdges.length}
        organizationSlug={organizationSlug}
        startProcessingLink={startProcessingLink}
      />

      <InboxViewContainer activeTab={TabView.INBOX_DOCUMENTS}>
        <Flex
          key={tableKey}
          height="100%"
          padding="0 space32 space24"
          overflow="hidden"
          direction="column"
        >
          <Grid
            templateColumns="minmax(12rem, 24rem) 1fr"
            gap="space8"
            borderTopRadius="basic"
            overflow="hidden"
            borderBottom="1px solid gray200"
            background="white"
            padding="space12"
          >
            <AdvancedSearchField
              clearTooltip={t('archive:search.clearTooltip')}
              placeholder={t('archive:search.placeholder')}
              initialValue={searchQuery}
              onQueryChange={query => {
                handleDebounceSearch(query);
              }}
              inputMaxLength={MAX_SUPPORTED_ELASTIC_SEARCH_INPUT_LENGTH}
            />

            <Grid justifySelf="end">
              <Flex gap="space8" alignItems="center">
                <ConfigurationsMenu
                  isLoading={isLoadingConfigs || isResetPending}
                  configurationItems={configurationsTable}
                  onUpdateConfigurations={handleUpdateConfigurations}
                  onResetConfigurations={handleResetTableConfigurations}
                />
              </Flex>
            </Grid>
          </Grid>

          <DocumentsTable
            columns={availableDocumentColumnIds}
            columnWidths={columnWidths}
            customEmptyState={customEmptyState}
            cellWrapper={cellWrapper}
            getCellStyles={cellStyle}
            data={documentsTableData}
            defaultFilters={filters}
            defaultSortBy={sortBy}
            filterOptions={filterOptions}
            infiniteScrollOptions={infiniteScrollOptions}
            isUsingSearchQuery={!!searchQuery}
            shouldEnableSortByWithSearchQuery={
              shouldEnableSortByWithSearchQuery
            }
            isLoading={loading}
            minWidth="100%"
            borderTopRadius="none"
            onEndReached={onLoadMore}
            onFilter={onFilter}
            onSort={onSort}
            width="max-content"
            sortByFields={sortByFields}
          />
        </Flex>

        <BaselineSearchPromotionContainer />
      </InboxViewContainer>
    </>
  );
};

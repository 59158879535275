import { useMemo } from 'react';
import { ReimbursementWorkflow } from './useWorkflowForReimbursementCaseById';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { ApprovalType } from './useInitialReimbursementFormValues';

export const useApprovalFieldData = (workflow?: ReimbursementWorkflow) => {
  const { hasActiveWorkflowTemplates: hasWorkflows } =
    useFullOrganization() ?? {};

  const workflowTemplate = workflow?.workflowTemplate;

  const defaultApprovers = useMemo(() => {
    const workflowApprovers = Boolean(workflow?.steps.length)
      ? workflow?.steps.map(step => step.approvers)
      : [];

    const templateApprovers = (workflowTemplate?.steps ?? [])?.map(step => ({
      approvers: step.approvers?.reduce<string[]>((result, approver) => {
        if (approver?.id) {
          result.push(approver.id);
        }
        return result;
      }, []),
    }));

    if (Boolean(templateApprovers.length)) {
      return templateApprovers;
    }

    const approvers = (workflowApprovers ?? [])?.map(step => ({
      approvers: step?.map(approver => approver.id) ?? [],
    }));

    return approvers;
  }, [workflow, workflowTemplate]);

  const defaultWorkflow = hasWorkflows
    ? (workflowTemplate?.id ?? undefined)
    : undefined;

  const approverData = {
    approvers: defaultApprovers,
    workflow: defaultWorkflow,
    approvalType: defaultWorkflow
      ? ApprovalType.WORKFLOW
      : ApprovalType.APPROVERS,
  };

  return approverData;
};

import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { Page } from 'react-pdf';
import { useDocumentPages } from './useDocumentPages';
import { usePageVisibility } from './usePageVisibility';
import { type UseReactPdfControlsResult } from './useReactPdfControls';
import { getPageId, intersectionOptions } from './utils';

interface PageWrapperProps
  extends Pick<
      UseReactPdfControlsResult,
      'height' | 'width' | 'scale' | 'rotation' | 'fitType'
    >,
    Pick<ReturnType<typeof useDocumentPages>, 'handleChangePageVisibility'> {
  pageNumber: number;
  pageNumberInTotal: number;
  uniqueId: string;
}

export const PageWrapper = ({
  pageNumber,
  rotation,
  scale,
  width,
  handleChangePageVisibility,
  fitType,
  height,
  pageNumberInTotal,
  uniqueId,
}: PageWrapperProps) => {
  const [initialRotation, setInitialRotation] = useState<number>();

  const handleOnIntersecting = useCallback<IntersectionObserverCallback>(
    ([entry]) => {
      handleChangePageVisibility(pageNumberInTotal, entry.intersectionRatio);
    },
    [handleChangePageVisibility, pageNumberInTotal]
  );

  const handlePageLoadSuccess: NonNullable<
    ComponentProps<typeof Page>['onLoadSuccess']
  > = useCallback(page => {
    setInitialRotation(page.rotate);
  }, []);

  const { ref } = usePageVisibility({
    onIntersecting: handleOnIntersecting,
    options: intersectionOptions,
  });

  const pageRotation = useMemo(() => {
    return (initialRotation ?? 0) + (rotation ?? 0);
  }, [initialRotation, rotation]);

  return (
    <div id={getPageId(uniqueId, pageNumberInTotal)} ref={ref}>
      <Page
        pageNumber={pageNumber}
        width={fitType === 'width' ? width : undefined}
        height={fitType === 'height' ? height : undefined}
        scale={scale}
        rotate={pageRotation}
        loading={null}
        onLoadSuccess={handlePageLoadSuccess}
      />
    </div>
  );
};

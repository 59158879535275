import { useTranslation } from 'react-i18next';
import { NotificationProps } from '../getNotificationInfo';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import {
  TruncatedText,
  useTooltip,
  Text,
  Tooltip,
} from '@candisio/design-system';
import { DateFormatters } from 'utils/date_formatter';
import { Comment } from './Comment/Comment';
import { Header } from './Header/Header';

export const ReimbursementCaseCommentCreated = ({
  commentCreatedAt,
  commentText,
  author,
  reimbursementCaseTitle,
  reimbursementCaseTotalAmount,
}: NotificationProps<'ReimbursementCaseCommentCreatedInAppNotification'>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);
  const { transactionAmountFormatter } = useMoneyFormatter();
  const formattedAmount = transactionAmountFormatter({
    currency: reimbursementCaseTotalAmount.currency,
    precision: reimbursementCaseTotalAmount.precision,
    value: reimbursementCaseTotalAmount.value,
  });

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  const authorName = author.name;
  return (
    <div
      className="grid grid-rows-[auto 1fr auto] gap-1"
      style={{ height: '100%' }}
    >
      <Header>
        <Trans
          i18nKey="notifications:events.reimbursementComment.header"
          values={{
            authorName,
            timeAgo: DateFormatters.fromNow(new Date(commentCreatedAt)),
          }}
        >
          <TruncatedText color="gray600" maxWidth="15ch">
            {authorName}
          </TruncatedText>
          <Text fontSize="small" color="gray500">
            mentioned you in a comment
          </Text>
          <Text
            fontSize="small"
            color="gray500"
            {...triggerProps}
            ref={triggerRef}
          >
            3 minutes ago
          </Text>
        </Trans>
      </Header>

      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {DateFormatters.compactDatetime(new Date(commentCreatedAt))}
        </Tooltip>
      )}

      <Comment>
        <Text color="gray800" fontSize="basic">
          {t('events.commentText', { commentText })}
        </Text>
      </Comment>
      <Text color="gray500" fontSize="small">
        {t('events.reimbursementComment.footer', {
          title: reimbursementCaseTitle,
          amount: formattedAmount,
        })}
      </Text>
    </div>
  );
};

export const ReimbursementCaseCommentCreatedInAppNotificationToast = ({
  author,
  commentText,
  reimbursementCaseTitle,
}: NotificationProps<'ReimbursementCaseCommentCreatedInAppNotification'>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NOTIFICATIONS);

  const authorName = author.name;

  return (
    <div
      className="grid grid-rows-[auto 1fr auto] gap-1"
      style={{ height: '100%' }}
    >
      <Header>
        <Trans
          i18nKey="notifications:events.reimbursementComment.toast.header"
          values={{
            authorName,
          }}
        >
          <TruncatedText color="gray800" maxWidth="15ch">
            {authorName}
          </TruncatedText>
          <Text color="gray500">mentioned you in a comment</Text>
        </Trans>
      </Header>

      <Text color="gray800">{reimbursementCaseTitle}</Text>
      <Comment>
        <Text color="gray800" fontSize="basic">
          {t('events.commentText', { commentText })}
        </Text>
      </Comment>
    </div>
  );
};

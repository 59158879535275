import { Grid, Icon } from '@candisio/design-system';
import { PdfThumbnailViewer } from 'components/DocumentViewer/PdfViewer/PdfThumbnailViewer';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { MouseEvent } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { styled } from 'styles/themes';
import styles from './DocumentThumbnail.module.css';

const StyledGrid = styled(Grid)`
  isolation: isolate;

  & > * {
    grid-area: 1 / 1;
    border-radius: inherit;
  }
`;

export interface DocumentThumbnailProps {
  handleClick: () => void;
  documentFile: PDFDetails;
}

export const DocumentThumbnail = ({
  documentFile,
  handleClick,
}: DocumentThumbnailProps) => {
  return (
    <StyledGrid
      background="gray50"
      paddingX="0"
      paddingY="0"
      height="inherit"
      width="inherit"
      borderRadius="small"
      border="1px solid gray200"
    >
      <PdfThumbnailViewer documentFile={documentFile} />
      <Grid
        as="button"
        className={styles.button}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          handleClick();
        }}
      >
        <Icon className={styles.icon} icon="newTab" size={20} />
      </Grid>
    </StyledGrid>
  );
};

import { FileState, useFileUploadsQuery } from 'generated-types/graphql.types';
import { useGetFileUploadAttempts } from '../useGetFileUploadAttempts';

const defaultAllowedStates = [
  FileState.Error,
  FileState.Uploaded,
  FileState.Uploading,
];

export const usePendingFileUploads = (allowedStates = defaultAllowedStates) => {
  const { data: attempts } = useGetFileUploadAttempts();
  const {
    data: { fileUploads = [] } = {},
  } = useFileUploadsQuery();
  const attemptIds = attempts?.map(item => item.globalDocumentId) ?? [];

  return fileUploads
    .filter(
      item =>
        allowedStates.includes(item.state) &&
        !attemptIds.includes(item.documentId)
    )
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
};

import { FieldPolicy, TypePolicies } from '@apollo/client';
import { PageBasedTeamList } from 'generated-types/graphql.types';
import { simplePaginationMergeWithoutDuplicates } from '../pagination-results-merge';

const teamsRecords: FieldPolicy<PageBasedTeamList> = {
  keyArgs: ['queries', 'filters', 'sortBy'],
  merge: simplePaginationMergeWithoutDuplicates('PageBasedTeamList'),
};

export const typePolicy: TypePolicies = {
  Query: {
    fields: {
      teams: teamsRecords,
    },
  },
};

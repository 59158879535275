import clsx from 'clsx';
import { Icon } from '@candisio/design-system';

type AddNewRowProps = {
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};

export const AddNewRow = ({ disabled, className, onClick }: AddNewRowProps) => {
  return (
    <button
      type="button"
      data-cy="add-new-booking-button"
      className={clsx(className)}
      disabled={disabled}
      onClick={e => {
        e.stopPropagation();
        if (disabled) return;
        onClick?.();
      }}
    >
      <Icon icon="plus" size={16} />
      <span>Add new</span>
    </button>
  );
};

// TODO translation

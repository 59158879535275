import { usePath } from 'hooks/usePath';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { summaryStatusMap } from '../constants';
import { SummaryStatus } from '../content/types';

export const useReimbursementPath = (
  status: SummaryStatus,
  search?: { lastClosedAt?: string }
) => {
  const { id: currentUserId } = useCurrentUser() || {};

  const path = usePath({
    pathname: '/archive/reimbursements',
    search: {
      ...search,
      status: summaryStatusMap[status],
      requesterOfTheReimbursement: currentUserId ?? '',
    },
  });

  return path;
};

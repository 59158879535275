import { Box, Grid, Text } from '@candisio/design-system';
import { useFormContext } from 'react-hook-form';
import { ContactTypeField } from 'views/Contacts/ContactDetails/ContactForm/ContactTypeFields/ContactTypeField';
import { ContactFormValues } from '../contactFormSchema';
import { getFullName } from './utils';
import { useTranslation } from 'react-i18next';
import { Trans } from 'providers/LocaleProvider';
import { useEffect } from 'react';

export interface ContactTypeFieldsProps {
  disabled?: boolean;
  isEmployeeContactTypeHidden: boolean;
}

export const ContactTypeRadioButtonField = ({
  disabled,
  isEmployeeContactTypeHidden,
}: ContactTypeFieldsProps) => {
  const [t] = useTranslation();
  const { watch, setValue } = useFormContext<ContactFormValues>();

  const [individualFirstName, individualLastName] = watch([
    'individualFirstName',
    'individualLastName',
    'contactType',
  ]);

  const fullName = getFullName(individualFirstName, individualLastName);

  // XXX WORKAROUND YIKES: When the radio button is set to employee contact type we do not show name fields anymore but in the schema it's required.
  // The schema has a discriminated union but not on contact type but on contact relationship type.
  useEffect(() => {
    if (!fullName) {
      setValue('name', 'empty', {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [setValue, fullName]);

  const translation =
    individualFirstName || individualLastName ? (
      <Trans
        t={t}
        as="span"
        values={{ fullName }}
        i18nKey="settings.contacts.details.edit.teamMember.textWithName"
      >
        Choose a team member to create employee contact for
        <Text fontWeight="bold">{fullName}</Text>
      </Trans>
    ) : (
      <Text>{t('settings.contacts.details.edit.teamMember.textNoName')}</Text>
    );

  return (
    <Grid gap="space16">
      <ContactTypeField
        disabled={disabled}
        isEmployeeContactTypeHidden={isEmployeeContactTypeHidden}
      />
      <Box fontSize="basic">{translation}</Box>
    </Grid>
  );
};

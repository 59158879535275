import { Box, Grid, Link, ScrollBox, Text } from '@candisio/design-system';

import { PdfInvoiceSkeleton } from 'components/DocumentViewer/PdfViewer/PdfInvoiceSkeleton';
import { PdfViewer } from 'components/DocumentViewer/PdfViewer/PdfViewer';
import { DetailsLayout } from 'components/Layouts/DetailsLayout';
import {
  LeftSectionContentWrapper,
  LeftSectionOuterWrapper,
} from 'components/Layouts/styles';
import { ListNavigator } from 'components/ListNavigator/ListNavigator';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { queryParams } from 'components/Transactions/Table/util';
import {
  TransactionDetailsCardSummary,
  TransactionsDetailsCardContainer,
} from 'components/Transactions/TransactionDetailsCard/TransactionDetailsCard';
import {
  Document,
  DocumentFile,
  DocumentStatus,
  EcmDocumentStatus,
  Money,
  useAddFileUploadMutation,
  useDeleteFileMutation,
  useDocumentFileUploadUrlMutation,
  useGetCardIssuerTransactionByIdQuery,
  useProcessDocumentMutation,
  useUpdateFileUploadMutation,
} from 'generated-types/graphql.types';
import { InvoiceAssociationStatus } from 'generated-types/resolvers-types';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useUserRoles } from 'hooks/useUserRoles';
import { AppRouteParams, Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  generatePath,
  useNavigate,
  useSearchParams,
} from 'react-router-dom-v5-compat';
import { fileUploadUtil } from 'views/AppContainer/components/Header/components/DocumentUploadModal/fileUploadUtil';
import { DocumentHistoryTabsContainer } from 'views/Inbox/DocumentProcessing/components/DocumentHistoryTabs/DocumentHistoryTabsContainer';
import { DOCUMENT_PROCESSING_SEARCH_PARAMS } from 'views/Inbox/DocumentProcessing/consts';
import { List, getRefetchListQueries } from 'views/queries';
import {
  DocumentDetailsPanel,
  TransactionViewEmptyState,
} from '../DocumentDetails/components/DocumentDetailsPanel';
import { InvoiceUploadDisabledState } from './InvoiceState/InvoiceUploadDisabledState';
import { RightInformationContent } from './RightInformationContent';
import { BottomActionBox } from './RightInformationContent/BottomActionBoxState';
import { RightSectionApprover } from './RightInformationContent/RightSectionApprover';
import { CommentTransactionContainer } from './component/CommentTransactionContainer';
import { InvoiceLayout } from './component/InvoiceUploadContainer';
import { InvoiceUploaderContainer } from './component/InvoiceUploadContainer/InvoiceUploaderContainer';
import { TransactionHistoryContainer } from './component/TransactionHistoryContainer';
import { getRefetchCardIssuerTransaction } from './gql';
import { ASSOCIATION } from './types';
import {
  TransactionAssociationView,
  usePrefetchQueries,
  useTransactionAssociationNavigation,
} from './utils';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { DocumentSummaryInformation } from 'views/Inbox/DocumentProcessing/components/DocumentSummaryInformation';
import { isPaidDocument } from 'utils/document_payment_data';
import { TransactionHistory } from './TransactionAssociationAdminRequester';
import { dataToTransactionDetails } from 'components/Transactions/TransactionDetailsCard/utils';
import { useDocumentFile } from 'hooks/useDocumentFile';
import { useAttachments } from 'hooks/useAttachments/useAttachments';
import { DocumentDetailsPromoContainer } from 'views/DocumentDetails/components/TransactionSection/DocumentDetailsPromo';

interface RouteParams {
  transactionId: string;
  organizationSlug: string;
  type: TransactionAssociationView;
}

export const TransactionAssociationApprover = () => {
  const { showDocumentRelationsImproved } = useEcm();

  return showDocumentRelationsImproved ? (
    <TransactionAssociationApproverImproved />
  ) : (
    <TransactionAssociationApproverDeprecated />
  );
};

const TransactionAssociationApproverDeprecated = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();
  const { showAllExtraFeatures } = useCreditCardsSetup();

  const isXmlDocumentUploadAllowed = true;

  const {
    transactionId,
    organizationSlug,
    type: routeType,
  } = useParams<RouteParams>();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isOnlyApprover } = useUserRoles();

  const [isDocumentChanging, setIsDocumentChanging] = useState<boolean>(false);
  const [dbFile, setDbFile] =
    useState<Pick<DocumentFile, 'id' | 'url' | 'name' | 'size'>>();

  const [addFile] = useAddFileUploadMutation();
  const [updateFile] = useUpdateFileUploadMutation();
  const [documentFileUploadUrl] = useDocumentFileUploadUrlMutation();
  const [processDocument, { loading: documentProcessingLoading }] =
    useProcessDocumentMutation();

  const [deleteFile, { loading: deleteFileLoading }] = useDeleteFileMutation({
    onCompleted() {
      setDbFile(undefined);
      setIsDocumentChanging(false);
    },
  });

  const counterQueries = useCounterQueries();

  const cursor = searchParams.get(DOCUMENT_PROCESSING_SEARCH_PARAMS.CURSOR);

  const {
    nextTransactionLink,
    prevTransactionLink,
    nextTransactionId,
    prevTransactionId,
    isLoadingNavigationData,
    transactionListCount,
    linkBackToList,
  } = useTransactionAssociationNavigation({
    cursor,
    organizationSlug,
    routeType,
  });

  usePrefetchQueries(prevTransactionId, nextTransactionId);

  const listNavigatorTitle: string = isLoadingNavigationData
    ? t('transactionAssociation.listNavigator.loading')
    : t('transactionAssociation.listNavigator.title', {
        count: transactionListCount,
      });

  const nextTransactionPage = () => {
    if (nextTransactionLink) {
      navigate(nextTransactionLink);
    }
  };

  const prevTransactionPage = () => {
    if (prevTransactionLink) {
      navigate(prevTransactionLink);
    }
  };

  const toAllDoneTransactionList = () => {
    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${routeType}${Routes.TRANSACTIONS}`,
      { organizationSlug }
    );

    navigate({
      pathname,
      search: new URLSearchParams({ [queryParams.allDone]: 'true' }).toString(),
    });
  };

  const { data, loading: isLoadingCurrentCardTransaction } =
    useGetCardIssuerTransactionByIdQuery({
      variables: { id: transactionId },
    });

  const ownerTxMembershipId =
    data?.getCardIssuerTransactionById.member.membershipId ?? undefined;

  const handleChange = useCallback(
    async (file: File) => {
      if (file) {
        setIsDocumentChanging(true);
        const selectedFile = file;
        const uploadResult = await fileUploadUtil(
          selectedFile,
          isXmlDocumentUploadAllowed,
          {
            addFile,
            documentFileUploadUrl,
            updateFile,
            onUploaded: async () => ({}),
          }
        );

        if (uploadResult?.errorMessage) {
          setIsDocumentChanging(false);
          const errorMessage = `common:${uploadResult.errorMessage}`;

          error(t(errorMessage));

          return;
        }

        if (uploadResult?.file) {
          setDbFile(uploadResult?.file);
          setIsDocumentChanging(false);
        }
      }
    },
    [addFile, documentFileUploadUrl, updateFile, error, t]
  );

  const onDeleteFile = async () => {
    const fileId = dbFile?.id;
    if (fileId) {
      setIsDocumentChanging(true);
      await deleteFile({
        variables: { fileId },
      });
    }

    success(
      t(
        'transactionAssociation.rightContent.actionBox.deleteUploadedFile.toastMessageFileDeleted'
      )
    );
  };

  const onAssociateDocument = async () => {
    const fileId = dbFile?.id;
    if (fileId && dbFile?.name && transactionId) {
      const { data } = await processDocument({
        variables: {
          fileId,
          transactionIds: [transactionId],
        },
        refetchQueries: [
          ...getRefetchCardIssuerTransaction(transactionId),
          ...getRefetchListQueries(List.INBOX_TABLE),
          ...counterQueries,
        ],
      });

      const docId = data?.processDocument?.id;
      const name = dbFile?.name;

      const content = isOnlyApprover ? (
        <Text>{t('transactionAssociation.feedback.successMsg')}</Text>
      ) : (
        <Trans
          t={t}
          i18nKey="transactionAssociation.feedback.link.success"
          values={{ name }}
        >
          Transaction was successfully linked to
          <Link
            href={`/${organizationSlug}${Routes.INBOX}/${docId}`}
            data-cy="transaction-document-link-notification"
          >
            {{ name } as any}
          </Link>
        </Trans>
      );

      success(content, 7);

      setDbFile(undefined);

      if (nextTransactionLink) {
        nextTransactionPage();
      } else if (prevTransactionLink) {
        prevTransactionPage();
      } else {
        toAllDoneTransactionList();
      }
    }
  };

  const document = data?.getCardIssuerTransactionById.documents[0];
  const documentFile = document?.documentFile;

  const transaction = data?.getCardIssuerTransactionById;

  const selectedFile = {
    name: dbFile?.name ?? '',
    id: dbFile?.id ?? '',
    url: dbFile?.url ?? '',
    size: dbFile?.size ?? 0,
  };

  const associationState = documentFile
    ? ASSOCIATION.DOCUMENT
    : dbFile
      ? ASSOCIATION.FILE
      : ASSOCIATION.NONE;

  useEffect(() => {
    if (documentFile) {
      setDbFile(documentFile);
    } else {
      setDbFile(undefined);
    }
  }, [documentFile]);

  const invoiceAssociationStatus =
    data?.getCardIssuerTransactionById?.invoiceAssociationStatus;

  const isInvoiceNeeded =
    invoiceAssociationStatus === InvoiceAssociationStatus.Missing;

  if (!isLoadingCurrentCardTransaction && !transaction) {
    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${routeType}${Routes.TRANSACTIONS}`,
      { organizationSlug }
    );

    navigate(pathname);
  }

  const linkedDocumentDetails = {
    id: data?.getCardIssuerTransactionById.documents[0]?.id,
    status: data?.getCardIssuerTransactionById.documents[0]?.status,
  };

  const isTransactionLoading =
    isLoadingCurrentCardTransaction || isDocumentChanging || deleteFileLoading;

  const linkedDocumentStatus =
    data?.getCardIssuerTransactionById.documents[0]?.status;

  const isTransactionExported =
    (linkedDocumentStatus &&
      [DocumentStatus.Exported, DocumentStatus.Exporting].includes(
        linkedDocumentStatus
      )) ??
    data?.getCardIssuerTransactionById?.isExported;

  const isInvoicedNotNeeded =
    transaction?.invoiceAssociationStatus ===
    InvoiceAssociationStatus.NotNeeded;

  const isInvoiceMissing =
    transaction?.invoiceAssociationStatus === InvoiceAssociationStatus.Missing;

  const isReadOnlyFormVisible =
    showAllExtraFeatures && (isInvoicedNotNeeded || isInvoiceMissing);

  const protocolTabTitle = t('common:document.tabs.history');

  return (
    <DetailsLayout
      key={transactionId}
      leftSection={
        <LeftSectionOuterWrapper>
          <ListNavigator
            backToListText={t('common:document.backToDocumentList')}
            arrowLeftTooltip={t(
              'transactionAssociation.listNavigator.prevTransaction'
            )}
            arrowRightTooltip={t(
              'transactionAssociation.listNavigator.nextTransaction'
            )}
            listNavigatorCounter={listNavigatorTitle}
            loading={isLoadingNavigationData}
            onBackToList={() => {
              navigate(linkBackToList);
            }}
            onNext={nextTransactionLink ? nextTransactionPage : undefined}
            onPrev={prevTransactionLink ? prevTransactionPage : undefined}
          />
          <LeftSectionContentWrapper>
            <DocumentDetailsPanel
              loadingDoc={isLoadingCurrentCardTransaction}
              document={document as Document}
              emptyState={<TransactionViewEmptyState />}
            />
            <TransactionsDetailsCardContainer
              transactionId={transactionId}
              documentId={linkedDocumentDetails.id}
              linkedDocumentStatus={linkedDocumentDetails.status}
            />
            <DocumentHistoryTabsContainer
              defaultSelectedKey={protocolTabTitle}
              showOnlyProtocol={isOnlyApprover && Boolean(transactionId)}
              tabItems={[
                {
                  title: protocolTabTitle,
                  children: (
                    <TransactionHistoryContainer
                      transactionId={transactionId}
                    />
                  ),
                },
              ]}
              chatBox={
                <CommentTransactionContainer
                  transactionId={transactionId}
                  ownerTxMembershipId={ownerTxMembershipId}
                />
              }
            />
          </LeftSectionContentWrapper>
        </LeftSectionOuterWrapper>
      }
      middleSection={
        <>
          {isTransactionLoading && (
            <Box paddingTop="72px" paddingX="space24" height="100%">
              <PdfInvoiceSkeleton />
            </Box>
          )}
          {!isTransactionLoading && dbFile && (
            <Box
              height="100%"
              width="100%"
              paddingX="space16"
              paddingTop="space32"
              overflowY="auto"
            >
              <PdfViewer documentFile={selectedFile} />
            </Box>
          )}
          {!isTransactionLoading && !dbFile && (
            <InvoiceLayout canUserLinkInvoice={isInvoiceNeeded}>
              {isInvoiceNeeded ? (
                <InvoiceUploaderContainer onChange={handleChange} />
              ) : (
                <InvoiceUploadDisabledState />
              )}
            </InvoiceLayout>
          )}
        </>
      }
      rightSection={
        isReadOnlyFormVisible ? (
          <RightSectionApprover
            // We need this component to re-render when the transactionId changes
            key={transactionId}
            isInvoicedNotNeeded={isInvoicedNotNeeded}
            isTxExported={isTransactionExported ?? false}
            associationBox={
              dbFile && (
                <BottomActionBox
                  associationState={associationState}
                  isDeletingLoading={deleteFileLoading}
                  isProcessingLoading={documentProcessingLoading}
                  onAssociateDocument={onAssociateDocument}
                  onDeleteFile={onDeleteFile}
                />
              )
            }
          />
        ) : (
          <RightInformationContent
            isLoadingCurrentCardTransaction={isTransactionLoading}
            isInvoiceNeeded={isInvoiceNeeded}
            associationState={associationState}
            bottomActionBox={
              <BottomActionBox
                associationState={associationState}
                isDeletingLoading={deleteFileLoading}
                isProcessingLoading={documentProcessingLoading}
                onAssociateDocument={onAssociateDocument}
                onDeleteFile={onDeleteFile}
              />
            }
          />
        )
      }
    />
  );
};

const TransactionAssociationApproverImproved = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const { success, error } = useToastMessage();
  const { showAllExtraFeatures } = useCreditCardsSetup();

  const isXmlDocumentUploadAllowed = true;

  const {
    transactionId,
    organizationSlug,
    type: routeType,
  } = useParams<RouteParams>();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isOnlyApprover } = useUserRoles();

  const [isDocumentChanging, setIsDocumentChanging] = useState<boolean>(false);
  const [dbFile, setDbFile] =
    useState<Pick<DocumentFile, 'id' | 'url' | 'name' | 'size'>>();

  const [addFile] = useAddFileUploadMutation();
  const [updateFile] = useUpdateFileUploadMutation();
  const [documentFileUploadUrl] = useDocumentFileUploadUrlMutation();
  const [processDocument, { loading: documentProcessingLoading }] =
    useProcessDocumentMutation();

  const [deleteFile, { loading: deleteFileLoading }] = useDeleteFileMutation({
    onCompleted() {
      setDbFile(undefined);
      setIsDocumentChanging(false);
    },
  });

  const counterQueries = useCounterQueries();

  const cursor = searchParams.get(DOCUMENT_PROCESSING_SEARCH_PARAMS.CURSOR);

  const {
    nextTransactionLink,
    prevTransactionLink,
    nextTransactionId,
    prevTransactionId,
    isLoadingNavigationData,
    transactionListCount,
    linkBackToList,
  } = useTransactionAssociationNavigation({
    cursor,
    organizationSlug,
    routeType,
  });

  usePrefetchQueries(prevTransactionId, nextTransactionId);

  const listNavigatorTitle: string = isLoadingNavigationData
    ? t('transactionAssociation.listNavigator.loading')
    : t('transactionAssociation.listNavigator.title', {
        count: transactionListCount,
      });

  const nextTransactionPage = () => {
    if (nextTransactionLink) {
      navigate(nextTransactionLink);
    }
  };

  const prevTransactionPage = () => {
    if (prevTransactionLink) {
      navigate(prevTransactionLink);
    }
  };

  const toAllDoneTransactionList = () => {
    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${routeType}${Routes.TRANSACTIONS}`,
      { organizationSlug }
    );

    navigate({
      pathname,
      search: new URLSearchParams({ [queryParams.allDone]: 'true' }).toString(),
    });
  };

  const { data, loading: isLoadingCurrentCardTransaction } =
    useGetCardIssuerTransactionByIdQuery({
      variables: { id: transactionId },
    });

  const ownerTxMembershipId =
    data?.getCardIssuerTransactionById.member.membershipId ?? undefined;

  const handleChange = useCallback(
    async (file: File) => {
      if (file) {
        setIsDocumentChanging(true);
        const selectedFile = file;
        const uploadResult = await fileUploadUtil(
          selectedFile,
          isXmlDocumentUploadAllowed,
          {
            addFile,
            documentFileUploadUrl,
            updateFile,
            onUploaded: async () => ({}),
          }
        );

        if (uploadResult?.errorMessage) {
          setIsDocumentChanging(false);
          const errorMessage = `common:${uploadResult.errorMessage}`;

          error(t(errorMessage));

          return;
        }

        if (uploadResult?.file) {
          setDbFile(uploadResult?.file);
          setIsDocumentChanging(false);
        }
      }
    },
    [addFile, documentFileUploadUrl, updateFile, error, t]
  );

  const onDeleteFile = async () => {
    const fileId = dbFile?.id;
    if (fileId) {
      setIsDocumentChanging(true);
      await deleteFile({
        variables: { fileId },
      });
    }

    success(
      t(
        'transactionAssociation.rightContent.actionBox.deleteUploadedFile.toastMessageFileDeleted'
      )
    );
  };

  const onAssociateDocument = async () => {
    const fileId = dbFile?.id;
    if (fileId && dbFile?.name && transactionId) {
      const { data } = await processDocument({
        variables: {
          fileId,
          transactionIds: [transactionId],
        },
        refetchQueries: [
          ...getRefetchCardIssuerTransaction(transactionId),
          ...getRefetchListQueries(List.INBOX_TABLE),
          ...counterQueries,
        ],
      });

      const docId = data?.processDocument?.id;
      const name = dbFile?.name;

      const content = isOnlyApprover ? (
        <Text>{t('transactionAssociation.feedback.successMsg')}</Text>
      ) : (
        <Trans
          t={t}
          i18nKey="transactionAssociation.feedback.link.success"
          values={{ name }}
        >
          Transaction was successfully linked to
          <Link
            href={`/${organizationSlug}${Routes.INBOX}/${docId}`}
            data-cy="transaction-document-link-notification"
          >
            {{ name } as any}
          </Link>
        </Trans>
      );

      success(content, 7);

      setDbFile(undefined);

      if (nextTransactionLink) {
        nextTransactionPage();
      } else if (prevTransactionLink) {
        prevTransactionPage();
      } else {
        toAllDoneTransactionList();
      }
    }
  };

  const document = data?.getCardIssuerTransactionById.documents[0];
  const documentId = document?.id;
  const documentFile = document?.documentFile;

  const transaction = data?.getCardIssuerTransactionById;

  const selectedFile = {
    name: dbFile?.name ?? '',
    id: dbFile?.id ?? '',
    url: dbFile?.url ?? '',
    size: dbFile?.size ?? 0,
  };

  const associationState = documentFile
    ? ASSOCIATION.DOCUMENT
    : dbFile
      ? ASSOCIATION.FILE
      : ASSOCIATION.NONE;

  useEffect(() => {
    if (documentFile) {
      setDbFile(documentFile);
    } else {
      setDbFile(undefined);
    }
  }, [documentFile]);

  const invoiceAssociationStatus =
    data?.getCardIssuerTransactionById?.invoiceAssociationStatus;

  const isInvoiceNeeded =
    invoiceAssociationStatus === InvoiceAssociationStatus.Missing;

  if (!isLoadingCurrentCardTransaction && !transaction) {
    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${routeType}${Routes.TRANSACTIONS}`,
      { organizationSlug }
    );

    navigate(pathname);
  }

  const isTransactionLoading =
    isLoadingCurrentCardTransaction || isDocumentChanging || deleteFileLoading;

  const linkedDocumentStatus =
    data?.getCardIssuerTransactionById.documents[0]?.status;

  const isTransactionExported =
    (linkedDocumentStatus &&
      [DocumentStatus.Exported, DocumentStatus.Exporting].includes(
        linkedDocumentStatus
      )) ??
    data?.getCardIssuerTransactionById?.isExported;

  const isInvoicedNotNeeded =
    transaction?.invoiceAssociationStatus ===
    InvoiceAssociationStatus.NotNeeded;

  const isInvoiceMissing =
    transaction?.invoiceAssociationStatus === InvoiceAssociationStatus.Missing;

  const isReadOnlyFormVisible =
    showAllExtraFeatures && (isInvoicedNotNeeded || isInvoiceMissing);

  const transactionParsed = dataToTransactionDetails(transaction);

  const invoiceNumber = document?.invoiceNumber?.value ?? undefined;
  const invoiceDate = document?.invoiceDate?.value ?? undefined;
  const documentAmount = document?.amount?.value ?? undefined;
  const documentCurrency = document?.currency?.value ?? undefined;
  const contactName =
    document?.contact?.value?.name?.value ??
    document?.extractedContact?.name?.value;

  const documentStatus: EcmDocumentStatus | DocumentStatus =
    document?.status ?? EcmDocumentStatus.New;

  const isEInvoice = Boolean(document?.isEInvoice);
  const eInvoice = document?.eInvoice;
  const eInvoiceValidationErrors = document?.eInvoiceValidationErrors;

  const hasDocumentAmount =
    documentCurrency !== undefined && documentAmount !== undefined;

  const amount = useMemo((): Money | null => {
    if (!hasDocumentAmount) return null;

    return {
      __typename: 'Money',
      amount: documentAmount,
      currency: documentCurrency,
      precision: 2,
    };
  }, [documentAmount, documentCurrency, hasDocumentAmount]);

  const { documentFile: docFile2, documentAttachments } = useDocumentFile({
    documentId: documentId ?? '',
    shouldPoll: isEInvoice,
  });

  const useAttachmentsResult = useAttachments({
    documentFile: docFile2,
    documentId,
    documentAttachments,
  });

  return (
    <DetailsLayout
      key={transactionId}
      leftSection={
        <>
          <LeftSectionOuterWrapper>
            <ListNavigator
              backToListText={t('common:document.backToDocumentList')}
              arrowLeftTooltip={t(
                'transactionAssociation.listNavigator.prevTransaction'
              )}
              arrowRightTooltip={t(
                'transactionAssociation.listNavigator.nextTransaction'
              )}
              listNavigatorCounter={listNavigatorTitle}
              loading={isLoadingNavigationData}
              onBackToList={() => {
                navigate(linkBackToList);
              }}
              onNext={nextTransactionLink ? nextTransactionPage : undefined}
              onPrev={prevTransactionLink ? prevTransactionPage : undefined}
            />
            <LeftSectionContentWrapper>
              <ScrollBox scrollDirection="none" scrollbarGutter="stable">
                <DocumentSummaryInformation
                  transactionSection={
                    <>
                      <DocumentDetailsPromoContainer />
                      <Grid border="1px solid gray300" borderRadius="medium">
                        <TransactionDetailsCardSummary
                          transaction={transactionParsed}
                          documentId={documentId}
                          documentStatus={linkedDocumentStatus}
                          loading={isLoadingCurrentCardTransaction}
                        />
                      </Grid>
                    </>
                  }
                  amount={amount}
                  attachPermissions={useAttachmentsResult.attachPermissions}
                  attachments={useAttachmentsResult.attachments}
                  contactName={contactName}
                  documentStatus={documentStatus}
                  eInvoice={eInvoice}
                  eInvoiceValidationErrors={eInvoiceValidationErrors}
                  invoiceDate={invoiceDate}
                  invoiceNumber={invoiceNumber}
                  isEInvoice={isEInvoice}
                  documentFile={docFile2}
                  documentId={documentId ?? ''}
                  isLoading={isLoadingCurrentCardTransaction}
                  onAttachmentClick={() => {}}
                  selectedPdf={docFile2}
                  isPaid={document ? isPaidDocument(document) : undefined}
                  selectedContactEmail={
                    document?.contact?.value?.email ?? undefined
                  }
                  withRelationships={false}
                />
              </ScrollBox>

              <ScrollBox scrollDirection="y" scrollbarGutter="stable">
                <TransactionHistory
                  transactionId={transactionId}
                  ownerTxMembershipId={ownerTxMembershipId}
                />
              </ScrollBox>
            </LeftSectionContentWrapper>
          </LeftSectionOuterWrapper>
        </>
      }
      middleSection={
        <>
          {isTransactionLoading && (
            <Box paddingTop="72px" paddingX="space24" height="100%">
              <PdfInvoiceSkeleton />
            </Box>
          )}
          {!isTransactionLoading && dbFile && (
            <Box
              height="100%"
              width="100%"
              paddingX="space16"
              paddingTop="space32"
              overflowY="auto"
            >
              <PdfViewer documentFile={selectedFile} />
            </Box>
          )}
          {!isTransactionLoading && !dbFile && (
            <InvoiceLayout canUserLinkInvoice={isInvoiceNeeded}>
              {isInvoiceNeeded ? (
                <InvoiceUploaderContainer onChange={handleChange} />
              ) : (
                <InvoiceUploadDisabledState />
              )}
            </InvoiceLayout>
          )}
        </>
      }
      rightSection={
        isReadOnlyFormVisible ? (
          <RightSectionApprover
            // We need this component to re-render when the transactionId changes
            key={transactionId}
            isInvoicedNotNeeded={isInvoicedNotNeeded}
            isTxExported={isTransactionExported ?? false}
            associationBox={
              dbFile && (
                <BottomActionBox
                  associationState={associationState}
                  isDeletingLoading={deleteFileLoading}
                  isProcessingLoading={documentProcessingLoading}
                  onAssociateDocument={onAssociateDocument}
                  onDeleteFile={onDeleteFile}
                />
              )
            }
          />
        ) : (
          <RightInformationContent
            isLoadingCurrentCardTransaction={isTransactionLoading}
            isInvoiceNeeded={isInvoiceNeeded}
            associationState={associationState}
            bottomActionBox={
              <BottomActionBox
                associationState={associationState}
                isDeletingLoading={deleteFileLoading}
                isProcessingLoading={documentProcessingLoading}
                onAssociateDocument={onAssociateDocument}
                onDeleteFile={onDeleteFile}
              />
            }
          />
        )
      }
    />
  );
};

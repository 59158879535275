import {
  DocumentCurrency,
  ReimbursementItemStatusInput,
} from 'generated-types/graphql.types';
import { getDateConverted } from 'hooks/useDateConverter';
import { formatDecimalToIntegerAmount } from 'hooks/useMoneyFormatter';
import { isNil } from 'lodash';
import { ReimbursementItemSchemaOutput } from '../toolkit/reimbursementItemsFormSchema';

export const sanitizeGeneralExpenseFormOutput = (
  values: ReimbursementItemSchemaOutput
) => {
  if (values.reimbursementItemType === 'general') {
    return {
      fileIds: (values.files ?? []).map(file => file.id),
      invoiceNumber: values.invoiceNumber?.length ? values.invoiceNumber : null,
      expenseDate: values.expenseDate?.length
        ? getDateConverted().dateStringToDateTimeString(values.expenseDate)
        : null,
      title: values.reason?.length ? values.reason : null,
      extractedDataAccepted: values.isExtractedDataAccepted,
      status: values.isExpenseExcluded
        ? ReimbursementItemStatusInput.Excluded
        : ReimbursementItemStatusInput.Included,
      totalAmount: !isNil(values.totalAmount)
        ? {
            amount: formatDecimalToIntegerAmount(values.totalAmount),
            currency: DocumentCurrency.Eur,
            precision: 2,
          }
        : null,
    };
  }
};

import { Grid } from '@candisio/design-system';
import { Loader } from 'components/Loader';
import { useAccountingInfoFormFieldOptions } from 'views/CreditCards/hooks/useAccountingInfoFormFieldOptions';
import { ReadOnlyTransactionForm } from './ReadOnlyTransactionForm';
import { useGetReadOnlyAccountingDataFormValues } from './hooks/useGetReadOnlyAccountingDataFormValues';
import { useTransactionFieldOptions } from './hooks/useTransactionFieldOptions';
import { ReadOnlyTransactionFormSchemaContext } from './utils/readOnlyTransactionFormSchema';

interface ReadOnlyTransactionFormContainerProps {
  isTransactionExported: boolean;
}

export const ReadOnlyTransactionFormContainer = ({
  isTransactionExported,
}: ReadOnlyTransactionFormContainerProps) => {
  const {
    defaultValues,
    loading,
    cardRefNumber,
    creditCardId,
    isNameComingFromCreditCard,
  } = useGetReadOnlyAccountingDataFormValues();

  const transactionFieldOptions = useTransactionFieldOptions();

  const fieldOptions = useAccountingInfoFormFieldOptions();

  const formContext: ReadOnlyTransactionFormSchemaContext = {
    isNameComingFromCreditCard,
  };

  if (loading) {
    return (
      <Grid alignItems="center" height="100%">
        <Loader />
      </Grid>
    );
  }

  return (
    <ReadOnlyTransactionForm
      defaultValues={defaultValues}
      fieldOptions={fieldOptions}
      transactionFieldOptions={transactionFieldOptions}
      creditCardId={creditCardId}
      cardRefNumber={cardRefNumber}
      isTransactionExported={isTransactionExported}
      formContext={formContext}
    />
  );
};
